import {
  withStyles,
  Typography,
  Grid,
  TextField,
  Button as ButtonLinkBase,
  Theme,
} from "@material-ui/core";

import {
  Form as FormBase,
  PasswordInputAdornment,
  IconButton,
  VisibilityIcon,
  VisibilityOffIcon,
  Button as ButtonBase,
  NoRegisteredUserInfo,
  Link,
} from "components/signin-form/signin-form.styles";

import MuiPhoneNumber from "material-ui-phone-number";

export const Form = withStyles({
  root: {
    paddingLeft: "32px",
    paddingRight: "32px",
  },
})(FormBase);

export const GridFieldContainer = withStyles((theme) => ({
  root: {
    marginBottom: "44px",
    "&:last-child": {
      marginBottom: "61px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "34px",
      "&:last-child": {
        marginBottom: "51px",
      },
    },
    "&.field-error": {
      marginBottom: "22px",
      "& label": {
        color: `${theme.palette.error.main} !important`,
        background: theme.authGeneralStyles.formContainer.backgroundColor,
      },
      "& fieldset": {
        borderColor: `${theme.palette.error.main} !important`,
        boxShadow: "0px 0px 4px rgba(254, 99, 119, 0.9)",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.error.main} !important`,
        boxShadow: "0px 0px 4px rgba(254, 99, 119, 0.9)",
      },
      "& .MuiFormHelperText-root": {
        height: "20px",
        color: `${theme.palette.error.main} !important`,
      },
      "&:last-child": {
        marginBottom: "39px",
      },
    },
    "& .phone-number-input": {
      "& .MuiFormLabel-root": {
        fontSize: "1.1667rem",
        background: theme.authGeneralStyles.formContainer.backgroundColor,
        // "&.Mui-focused": {
        //   color: `${theme.palette.primary.main} !important`,
        // },
      },
      // "& .MuiOutlinedInput-root.Mui-focused": {
      //   "& .MuiOutlinedInput-notchedOutline": {
      //     color: `${theme.palette.primary.main} !important`,
      //     borderColor: `${theme.palette.primary.main} !important`,
      //     borderWidth: "2px !important",
      //     "& span": {
      //       color: `${theme.palette.primary.main} !important`,
      //     },
      //   },
      // },
    },
  },
}))(Grid);

export const Text = withStyles(() => ({
  root: {
    // color: "#757575",
    fontSize: "16px",
    // lineHeight: "22px",
    // paddingTop: "29px",
    paddingTop: "40px",
    // paddingBottom: "68px",
    paddingBottom: "0px",
    fontWeight: 500,
  },
}))(Typography);

export const TextFieldCustom = withStyles(() => ({
  root: {},
}))(TextField);

export const PasswordField = withStyles(() => ({
  root: {
    marginBottom: "5px",
  },
}))(TextField);

export const Button = withStyles((theme: Theme) => ({
  root: {
    textTransform: "none",
    marginBottom: "21px",
    maxWidth: "316px",
    "&:disabled": {
      borderColor: "#D5CDE5",
    },
    [theme.breakpoints.down(540)]: {
      maxWidth: "unset",
    },
  },
}))(ButtonBase);

export const Footer = withStyles({
  root: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    color: "#757575",
  },
})(Typography);

export const ButtonLink = withStyles({
  root: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 800,
    textTransform: "inherit",
    textDecoration: "underline",
    color: "#B8CAC6",
    border: "none",
    padding: "0px",
    paddingLeft: "5px",
    "&:hover": {
      background: "unset",
    },
  },
})(ButtonLinkBase);

const MuiPhoneNumberField = withStyles((theme: Theme) => ({
  root: {},
}))(MuiPhoneNumber);

// passwordRules: {
//   fontSize: "14px",
//   lineHeight: "16px",
//   fontWeight: 400,
//   color: "rgba(0, 0, 0, 0.54)",
//   marginBottom: "48px",
//   textAlign: "left",
// },

export default {
  Form,
  Grid,
  GridFieldContainer,
  TextFieldCustom,
  PasswordField,
  PasswordInputAdornment,
  IconButton,
  VisibilityIcon,
  VisibilityOffIcon,
  Button,
  NoRegisteredUserInfo,
  Link,
  Footer,
  ButtonLink,
  MuiPhoneNumberField,
};
