/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React from "react";
import {
  createStyles,
  Theme,
  useTheme,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Typography,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Collapse,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableSortLabel,
  Popover,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import _ from "lodash";

import { useGetTargetBySourceIdQuery } from "graphql/types-and-hooks";

import Paginator from "components/paginator/paginator.component";
import AudionautCharts from "components/charts/charts.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import TableCellGenericItem from "components/tableCellGenericItem/tableCellGenericItem.component";
import CopyIconButton from "components/copy-icon-button/copy-icon-button.component";
import {
  BehaviorMediaResultsFragment,
  BehaviorResultsFragment,
  GetResultsByBehaviorIdQueryVariables,
  useGetResultsByBehaviorIdQuery,
} from "graphql/behaviors/types-and-hooks";
import {
  BehaviorPaginationVars,
  ResultsDataModifiedInterface,
} from "graphql/behaviors/behaviors.types";
import { CLIENT_NAME } from "graphql/client";
import { isMobileResolution } from "commons/utils/device-info.util";

const behaviorsResultsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    brBodyContainer: {
      width: "calc(100% + 60px) !important",
      marginLeft: "-30px",
      marginTop: "-30px",
      padding: "30px",
    },
    mediaGeneralInfo: {
      display: "flex",
      marginBottom: "22px",
    },
    labelInfoGeneral: {
      textAlign: "left",
    },
    boxStyle: {
      padding: "20px 16px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      marginRight: "22px",
      "&:last-child": {
        marginRight: "unset !important",
      },
      "& p": {
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#000000",
      },
    },
    boxBody: {
      padding: "9px 0px 20px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      [theme.breakpoints.down(540)]: {
        height: "100%",
        padding: "12px 0px",
      },
    },
    listHeader: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "21px",
      "& .title-list": {
        flex: "1",
        textAlign: "left",
      },
      "& img": {
        marginRight: "5px",
      },
      "& p": {
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: theme.fontGeneralStyles.medium.fontSize,
        lineHeight: theme.fontGeneralStyles.medium.lineHeight,
        color: "#000000",
      },
      [theme.breakpoints.down(540)]: {
        "& p": {
          fontSize: theme.fontGeneralStyles.small.fontSize,
          lineHeight: theme.fontGeneralStyles.small.lineHeight,
        },
        paddingBottom: "10px",
      },
    },
    tableContainer: {
      "& .tableTitle": {
        textAlign: "left",
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#000000",
        paddingLeft: "80px",
      },
    },
    tableHeader: {
      "& tr": {
        "& th": {
          padding: "10px 16px 11px",
          "& p, span": {
            fontFamily: "Gothic A1",
            fontStyle: "normal",
            fontWeight: "bold !important",
            fontSize: theme.fontGeneralStyles.small.fontSize,
            lineHeight: theme.fontGeneralStyles.small.lineHeight,
          },
        },
      },
      "& .headCentered": {
        textAlign: "center",
      },
      "& .borderLess": {
        border: "none",
      },
      [theme.breakpoints.down(540)]: {
        "& tr": {
          "& th": {
            padding: "10px 8px !important",
            "& p, span": {
              fontSize: theme.fontGeneralStyles.default.fontSize,
              lineHeight: theme.fontGeneralStyles.default.lineHeight,
            },
          },
        },
      },
    },
    tableBody: {
      "& tr": {
        "& td": {
          border: "none",
          paddingTop: "13px",
          paddingBottom: "13px",
          "&:last-child": {
            paddingTop: "7px",
            paddingBottom: "17px",
          },
          "& p": {
            fontFamily: "Gothic A1",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000",
          },
          "& .collapseIcon": {
            color: "#A2A2A2",
            padding: "0px",
          },
        },
        "& td:first-child": {
          paddingLeft: "0px",
        },
      },
      "& .mediaURLField": {
        maxWidth: "300px",
        overflow: "hidden",
        wordBreak: "break-word",
      },
    },
    entitiesTableHeader: {
      "& tr": {
        height: "28px !important",
        "& th": {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          "& p, span": {
            fontFamily: "Gothic A1",
            fontStyle: "normal",
            fontWeight: "bold !important",
            fontSize: "14px !important",
            lineHeight: "17px !important",
            color: "#000",
          },
          "&:first-child": {
            paddingLeft: "0px !important",
          },
        },
      },
    },
    entitiesTableBody: {
      "& tr": {
        height: "28px !important",
        "& td": {
          paddingTop: "0px",
          paddingBottom: "0px",
          border: "none",
          "& p": {
            fontFamily: "Gothic A1",
            fontStyle: "normal !important",
            fontWeight: "normal",
            fontSize: "12px !important",
            lineHeight: "15px !important",
            color: "#000",
          },
          "& .bgd-Joy": {
            background: "#86EBC299",
          },
          "& .bgd-Trust": {
            background: "#8DE9CB99",
          },
          "& .bgd-Anticipation": {
            background: "#96E8D799",
          },
          "& .bgd-Surprise": {
            background: "#9DF0E999",
          },
          "& .bgd-Sadness": {
            background: "#FCA29A99",
          },
          "& .bgd-Fear": {
            background: "#FD7B8499",
          },
          "& .bgd-Disgust": {
            background: "#FE637799",
          },
          "& .bgd-Anger": {
            background: "#FF295899",
          },
        },
        "& td:first-child": {
          paddingLeft: "0px",
        },
      },
    },
    targetBtn: {
      background: "#FFF",
      border: "2px solid #929292",
      color: "#929292",
      width: "100px",
      height: "30px",
      borderRadius: "15px",
      margin: "auto",
      "&:hover": {
        border: "2px solid #7E6FA2",
        color: "#7E6FA2",
      },
    },
    mediaDetail: {
      width: "100%",
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
      },
    },
    chartContainer: {
      maxWidth: "40%",
      minWidth: "410px",
      padding: "20px 25px",
      flex: "1",
      border: "1px solid #EFEFEF",
      boxSizing: "border-box",
      borderRadius: "10px",
      marginRight: "10px",
      "& .titleChart": {
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        fontWeight: "bold !important",
        fontSize: "16px !important",
        lineHeight: "20px !important",
        color: "#000000",
        marginBottom: "20px",
      },
    },
    entitiesTable: {
      minWidth: "410px",
      padding: "20px 25px",
      flex: "1",
      border: "1px solid #EFEFEF",
      boxSizing: "border-box",
      borderRadius: "10px",
      marginLeft: "10px",
      "& .title": {
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        fontWeight: "bold !important",
        fontSize: "16px !important",
        lineHeight: "20px !important",
        color: "#000000",
        marginBottom: "20px",
      },
    },
    chartTinny: {
      display: "flex",
      width: "130px",
      height: "24px",
      margin: "auto",
      alignItems: "center",
      background: "rgba(90, 227, 194, 0.1)",
      borderRadius: "12px",
    },
    sentimentChip: {
      width: "113px",
      height: "22px",
      display: "flex",
      borderRadius: "11px",
      alignItems: "center",
      justifyContent: "center",
    },
    hide: {
      display: "none !important",
    },
    loader: {
      margin: "auto",
    },
    actionsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    borderLess: {
      border: "none",
    },
    adviseMessage: {
      flex: "1",
      "& .MuiPaper-root": {
        height: "100%",
        paddingBottom: "30px",
        paddingLeft: "30px",
        paddingRight: "30px",
        boxShadow: "none",
        background: "transparent",
        "& div:first-child": {
          height: "100%",
          margin: "unset",
        },
      },
    },
    linkInCell: {
      color: "#A23A74",
      fontSize: "12px",
      lineHeight: "15px",
      textDecorationLine: "underline",
    },
    ellipsisText: {
      maxWidth: "300px",
      width: "calc(100vw / 7)",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  })
);

const NoResults = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "unset",
    [theme.breakpoints.down(540)]: {
      width: "calc(100vw - 40px)",
      margin: "10px 0px",
    },
  },
}))(GenericEmptyMessages);

const TableCellSticky = withStyles((theme: Theme) => ({
  root: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&.noPaddingLeft": {
      paddingLeft: "0px",
    },
    [theme.breakpoints.down(540)]: {
      position: "sticky",
      left: "0px",
      zIndex: "11",
      background: "#FFF",
      "&.lastCell": {
        maxWidth: "120px",
        left: "40px",
        borderRight: "1px solid #E1E1E1",
        boxShadow: "7px 0 5px -4px rgba(0,0,0,0.1)",
      },
      "&.firstCell": {
        left: "0px !important",
        maxWidth: "40px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  },
}))(TableCell);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface BRTableProps {
  title: string;
  ItemComponent: any;
  headers: any[];
  items: any[];
  thClass: string;
  tbClass: string;
  tableClass: string;
  toggleModalState?: any;
  setTargetData?: any;
  extracell?: boolean;
  extraSpaces: number;
  lastExtraSpaces?: boolean;
  sortEnable: boolean;
  deleteItemHandler?: any;
  ItemComponentProps?: any;
  deletedItem?: boolean;
  handleOnClickCopy?: () => void | null;
}
const BRTable: React.FC<BRTableProps> = ({
  title,
  ItemComponent,
  headers,
  items,
  thClass,
  tbClass,
  tableClass,
  toggleModalState,
  setTargetData,
  extracell,
  extraSpaces,
  lastExtraSpaces,
  sortEnable,
  deleteItemHandler,
  ItemComponentProps,
  deletedItem = false,
  handleOnClickCopy = null,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCopyClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    handleOnClickCopy?.();
  };

  return (
    <TableContainer className={tableClass}>
      {title ? <Typography className="tableTitle">{title}</Typography> : null}
      <Table
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={thClass}>
          <TableRow>
            {_.times(extraSpaces, (i) => (
              <TableCell
                hidden={isMobileResolution()}
                width="20px"
                padding="none"
              />
            ))}
            {/* <TableCell width="20px" /> */}
            {(extracell === undefined || extracell) &&
            ItemComponentProps === undefined ? (
              <TableCellSticky width="1rem" className="firstCell" />
            ) : null}
            {headers.map((headItem: any, index: number) =>
              isMobileResolution() && index === 0 ? (
                <TableCellSticky
                  colSpan={1}
                  className={`lastCell ${headItem.class}`}
                >
                  <TableSortLabel
                    active={orderBy === headItem.key}
                    direction={orderBy === headItem.key ? order : "asc"}
                    onClick={(event: any) => {
                      handleRequestSort(event, headItem.key);
                    }}
                  >
                    {headItem.text}
                    {headItem.icon ? (
                      <CopyIconButton handleOnClick={handleCopyClick} />
                    ) : null}
                  </TableSortLabel>
                  {/* <Typography>{headItem.text}</Typography> */}
                </TableCellSticky>
              ) : (
                <TableCell colSpan={1} className={headItem.class}>
                  <TableSortLabel
                    active={orderBy === headItem.key}
                    direction={orderBy === headItem.key ? order : "asc"}
                    onClick={(event: any) => {
                      handleRequestSort(event, headItem.key);
                    }}
                  >
                    {headItem.text}
                    {headItem.icon ? (
                      <CopyIconButton handleOnClick={handleCopyClick} />
                    ) : null}
                  </TableSortLabel>
                  {/* <Typography>{headItem.text}</Typography> */}
                </TableCell>
              )
            )}
            {lastExtraSpaces
              ? _.times(extraSpaces, (i) => (
                  <TableCell width="20px" padding="none" />
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody className={tbClass}>
          {items && items.length > 0 ? (
            stableSort(items, getComparator(order, orderBy)).map(
              (item: any, index) => (
                <ItemComponent
                  indexItem={index}
                  content={item}
                  parentHeaders={headers}
                  modalEvent={toggleModalState}
                  handleData={setTargetData}
                  extraSpaces={extraSpaces}
                  deleteItemHandler={deleteItemHandler}
                  deletedItem={deletedItem}
                  customProps={ItemComponentProps}
                />
              )
            )
          ) : (
            <TableRow id="row-empty-message">
              <TableCell
                colSpan={
                  extraSpaces +
                  headers.length +
                  (lastExtraSpaces ? extraSpaces : 0)
                }
              >
                <NoResults
                  show
                  title="No results"
                  description="You don't have any behavior registered."
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const headersColap: any[] = [
  {
    text: "Media Title",
    key: "mediaTitle",
  },
  {
    text: "Media URL",
    key: "mediaUrl",
  },
  {
    text: "Creation Date",
    key: "created",
    class: "headCentered",
  },
  {
    text: "Source",
    key: "source",
    class: "headCentered",
  },
  {
    text: "Duration",
    key: "duration",
    class: "headCentered",
  },
  {
    text: "Sentiment Score",
    key: "scoreSentiment",
    class: "headCentered",
  },
  {
    text: "Sentences",
    key: "sentences",
    class: "headCentered",
  },
  {
    text: "Entities",
    key: "entities",
    class: "headCentered",
  },
];

const entitesTableHeaders: any = [
  {
    text: "Entity",
    key: "text",
  },
  {
    text: "Entity Type",
    key: "type",
  },
  {
    text: "Associated Sentiment",
    key: "sentiment",
    class: "headCentered",
  },
  {
    text: "Frequency",
    key: "frecuency",
    class: "headCentered",
  },
];

const ButtonSecondary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.secondary.backgroundColor,
    // fontSize: theme.button.secondary.fontSize,
    // lineHeight: theme.button.secondary.lineHeight,
    color: theme.button.secondary.color,
    border: theme.button.secondary.border,
    textTransform: theme.button.secondary.textTransform,
    "&:hover": {
      color: theme.button.secondary.hoverColor,
      backgroundColor: theme.button.secondary.hoverBackground,
      border: theme.button.secondary.hoverBorder,
    },
    "&:disabled": {
      color: theme.button.secondary.disabledColor,
      backgroundColor: theme.button.secondary.disabledBackground,
      border: theme.button.secondary.disabledBorder,
    },
    [theme.breakpoints.down(540)]: {
      height: "30px",
      width: "120px",
    },
  },
}))(Button);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
    [theme.breakpoints.down(540)]: {
      height: "30px",
      width: "120px",
      "&.refresh-btn": {
        height: "30px",
        width: "30px",
        minWidth: "unset",
        borderRadius: "50%",
        padding: "6px",
        "& .MuiButton-startIcon": {
          margin: "unset",
        },
      },
    },
  },
}))(Button);

const ExistTarget = (id: string) => {
  const {
    data,
    loading: targetLoading,
    error,
  } = useGetTargetBySourceIdQuery({
    variables: {
      sourceId: id,
    },
  });
  return data?.GetTargetBySourceId;
};

interface SentimentsStaticsInterface {
  [key: string]: { label: string; color: string };
}

const sentimentsStatics: SentimentsStaticsInterface = {
  angerScore: {
    label: "Anger",
    color: "#FF2958",
  },
  disgustScore: {
    label: "Disgust",
    color: "#FE6377",
  },
  fearScore: {
    label: "Fear",
    color: "#FD7B84",
  },
  sadnessScore: {
    label: "Sadness",
    color: "#FCA29A",
  },
  surpriseScore: {
    label: "Surprice",
    color: "#9DF0E9",
  },
  joyScore: {
    label: "Joy",
    color: "#96E8D7",
  },
  anticipationScore: {
    label: "Anticipation",
    color: "#8DE9CB",
  },
  trustScore: {
    label: "Trust",
    color: "#86EBC2",
  },
};

interface BehaviorItemSimpleProps {
  parentHeaders: any;
  indexItem: number;
  content: any;
  modalEvent: any;
  handleData: any;
}
const BehaviorItemSimple: React.FC<BehaviorItemSimpleProps> = ({
  parentHeaders,
  indexItem,
  content,
  modalEvent,
  handleData,
}) => {
  // return <Typography>{JSON.stringify(content)}</Typography>;
  return (
    <>
      {parentHeaders !== null ? (
        <TableRow id={`row-${indexItem}`} style={{ height: 55 }}>
          {parentHeaders.map((item: any) => (
            <TableCell colSpan={1}>
              {content[item.key] !== undefined ? (
                <TableCellGenericItem
                  value={content[item.key]}
                  valueKey={item.key}
                />
              ) : (
                <Typography>-</Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
      ) : null}
    </>
  );
};

interface BehaviorItemCollapsableProps {
  parentHeaders: any;
  indexItem: string;
  content: any;
  extraSpaces: number;
  customProps: any;
}
const BehaviorItemCollapsable: React.FC<BehaviorItemCollapsableProps> = ({
  parentHeaders,
  indexItem,
  content,
  extraSpaces,
  customProps,
}) => {
  const classes = behaviorsResultsStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const contentCopy = content.node
    ? {
        ...content.node,
      }
    : content;

  const sentimentsValues: any = [];
  const labelsOrder: any = [];
  if (contentCopy.sentimentsScores) {
    Object.keys(sentimentsStatics).forEach((key) => {
      sentimentsValues.push({
        value: Math.round(contentCopy.sentimentsScores[key] * 100 * 10) / 10,
        itemStyle: {
          color: sentimentsStatics[key].color,
        },
      });
      labelsOrder.push(sentimentsStatics[key].label);
    });
  }

  return (
    <>
      {parentHeaders !== null ? (
        <TableRow id={`row-${indexItem}`}>
          {_.times(extraSpaces, (i) => (
            <TableCell
              hidden={isMobileResolution()}
              width="20px"
              padding="none"
            />
          ))}
          <TableCellSticky
            hidden={customProps ? customProps.hideCollapse : false}
            width="1rem"
            className={`firstCell ${
              isMobileResolution() ? "" : "noPaddingLeft"
            }`}
          >
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              className="collapseIcon"
            >
              {expanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
            </IconButton>
          </TableCellSticky>
          {parentHeaders.map((item: any, index: number) =>
            isMobileResolution() && index === 0 ? (
              <TableCellSticky colSpan={1}>
                {contentCopy[item.key] !== undefined ? (
                  <TableCellGenericItem
                    value={contentCopy[item.key]}
                    valueKey={item.key}
                    data={item.key === "tags" ? contentCopy.tags : contentCopy}
                    customClasses={
                      item.key === "tags"
                        ? JSON.stringify(customProps.classes)
                        : ""
                    }
                  />
                ) : (
                  <Typography>-</Typography>
                )}
              </TableCellSticky>
            ) : (
              <TableCell colSpan={1}>
                {contentCopy[item.key] !== undefined ? (
                  <TableCellGenericItem
                    value={contentCopy[item.key]}
                    valueKey={item.key}
                    data={item.key === "tags" ? contentCopy.tags : contentCopy}
                    customClasses={
                      item.key === "tags"
                        ? JSON.stringify(customProps.classes)
                        : ""
                    }
                  />
                ) : (
                  <Typography>-</Typography>
                )}
              </TableCell>
            )
          )}
        </TableRow>
      ) : null}
      {!customProps || !customProps.hideCollapse ? (
        <TableRow>
          {_.times(extraSpaces, (i) => (
            <TableCell width="20px" padding="none" />
          ))}
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headersColap.length + 1}
          >
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              className={classes.mediaDetail}
            >
              {/* <div>{JSON.stringify(content)}</div> */}
              <Box className={classes.chartContainer}>
                <Typography className="titleChart">
                  Score by Sentiment
                </Typography>
                <AudionautCharts
                  type={1}
                  data={{ values: sentimentsValues, labels: labelsOrder }}
                />
              </Box>
              <Box className={classes.entitiesTable}>
                <Typography className="title">Entities</Typography>
                <BRTable
                  title=""
                  headers={entitesTableHeaders}
                  items={contentCopy.entitiesDetail}
                  ItemComponent={BehaviorItemSimple}
                  thClass={classes.entitiesTableHeader}
                  tbClass={classes.entitiesTableBody}
                  tableClass={classes.tableContainer}
                  extracell={false}
                  extraSpaces={0}
                  lastExtraSpaces={false}
                  sortEnable
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

interface BehaviorItemSubTableProps {
  parentHeaders: any;
  indexItem: number;
  content: any;
  modalEvent: any;
  handleData: any;
  extraSpaces: number;
  deleteItemHandler: any;
  deletedItem: boolean;
}
const BehaviorItemSubTable: React.FC<BehaviorItemSubTableProps> = ({
  parentHeaders,
  indexItem,
  content,
  modalEvent,
  handleData,
  extraSpaces,
  deleteItemHandler,
  deletedItem = false,
}) => {
  const classes = behaviorsResultsStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [mediasResult, setMediasResult] = React.useState<
    ResultsDataModifiedInterface[]
  >([]);

  const [resultsPaginationOpts, setResultsPaginationOpts] =
    React.useState<GetResultsByBehaviorIdQueryVariables>({
      id: content.behaviorId,
      index: 0,
      total: 5,
    });

  const {
    data: resultsData,
    loading: resultsLoading,
    error: resultsError,
  } = useGetResultsByBehaviorIdQuery({
    variables: resultsPaginationOpts,
    context: {
      clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
    },
    notifyOnNetworkStatusChange: true,
    skip: !content.behaviorId,
  });

  const handleExpandClick = () => {
    if (expanded) {
      setResultsPaginationOpts((oldVars) => {
        return { ...oldVars, index: 0 };
      });
    }
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (resultsData && resultsData.getResults) {
      const { getResults } = resultsData as {
        getResults: BehaviorMediaResultsFragment[];
      };

      setMediasResult(
        getResults.map((mediaResult: BehaviorMediaResultsFragment) => {
          const { negativeSentimentRate, positiveSentimentRate } = mediaResult;

          let scoreSentiment = "0";
          if (!!negativeSentimentRate && !!positiveSentimentRate) {
            scoreSentiment =
              negativeSentimentRate > positiveSentimentRate
                ? (negativeSentimentRate * 100).toFixed(1)
                : (positiveSentimentRate * 100).toFixed(1);
          }

          const sentimentsScores = { ...sentimentsStatics };
          Object.keys(sentimentsStatics).forEach((key) => {
            sentimentsScores[key] =
              mediaResult[key as keyof BehaviorMediaResultsFragment];
          });

          return { ...mediaResult, scoreSentiment, sentimentsScores };
        }) as ResultsDataModifiedInterface[]
      );
    }
  }, [resultsData, setMediasResult]);

  const handleUpdateItems = (newPage: number, newRowsPerPage: number) => {
    setResultsPaginationOpts({
      id: content.behaviorId,
      total: newRowsPerPage,
      index: newRowsPerPage * newPage,
    });
  };

  content.actions = {
    target: !!ExistTarget(content.behaviorId),
    delete: true,
    edit: true,
  };

  return (
    <>
      {parentHeaders !== null ? (
        <TableRow key={`row-${indexItem}`} style={{ height: 55 }}>
          {_.times(extraSpaces, (i) => (
            <TableCell
              hidden={isMobileResolution()}
              width="20px"
              padding="none"
              className={classes.borderLess}
            />
          ))}
          <TableCellSticky
            width="1rem"
            className={`firstCell ${
              isMobileResolution() ? "" : "noPaddingLeft"
            }`}
          >
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              className="collapseIcon"
            >
              {expanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
            </IconButton>
          </TableCellSticky>
          {parentHeaders.map((item: any, index: number) => {
            return isMobileResolution() && index === 0 ? (
              <TableCellSticky colSpan={1} className="lastCell">
                {content[item.key] !== undefined ? (
                  <TableCellGenericItem
                    value={content[item.key]}
                    valueKey={item.key}
                    data={content}
                    targetData={mediasResult}
                    handleData={handleData}
                    modalEvent={modalEvent}
                    handlerDeleteItem={deleteItemHandler}
                  />
                ) : (
                  <Typography>-</Typography>
                )}
              </TableCellSticky>
            ) : (
              <TableCell colSpan={1}>
                {content[item.key] !== undefined ? (
                  <TableCellGenericItem
                    value={content[item.key]}
                    valueKey={item.key}
                    data={content}
                    targetData={mediasResult}
                    handleData={handleData}
                    modalEvent={modalEvent}
                    handlerDeleteItem={deleteItemHandler}
                  />
                ) : (
                  <Typography>-</Typography>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ) : null}
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headersColap.length + 2}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box hidden={mediasResult.length === 0}>
              <Paginator
                totalItems={content.mediaResults}
                getNewItems={handleUpdateItems}
              />
              <BRTable
                title="Media Results"
                headers={headersColap}
                items={mediasResult}
                ItemComponent={BehaviorItemCollapsable}
                thClass={classes.tableHeader}
                tbClass={classes.tableBody}
                tableClass={classes.tableContainer}
                extraSpaces={3}
                lastExtraSpaces={false}
                sortEnable
              />
            </Box>
            <NoResults
              show={mediasResult.length === 0}
              title="No results"
              description="The query behavior definated don't generated any result."
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AddTargetDialogContent = withStyles(() => ({
  root: {
    "& .MuiFormControl-root": {
      height: "45px",
      marginBottom: "26px",
      "& .MuiFormLabel-root": {
        color: "#B8CAC6",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 400,
        background: "#fff",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
      "& input": {
        height: "100%",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: 400,
      },
      "& fieldset": {
        borderColor: "#B8CAC6",
      },
      "& .MuiInputBase-root": {
        height: "100%",
        "& .MuiSelect-root:focus": {
          backgroundColor: "#fff",
        },
      },
    },
  },
}))(DialogContent);

interface NewTargetProps {
  targetData: any;
  openAddTarget: boolean;
  setOpenAddTarget: any;
  handleSubmit: any;
  groups: any;
}
const BRAddNewTarget: React.FC<NewTargetProps> = ({
  targetData,
  openAddTarget,
  setOpenAddTarget,
  handleSubmit,
  groups,
}) => {
  const [newTargetName, setNewTargetName] = React.useState<any>({});
  const [group, setGroup] = React.useState(0);

  const handleAddGroupSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();

      const behaviorMediasIds = targetData.mediasIds.map(
        (media: any) => media.mediaId
      );

      const newTarget = {
        sourceId: targetData.sourceId,
        targetName: newTargetName.target.value,
        groupId: group,
        source: "-",
        type: "behavior",
        mediasIds: JSON.stringify(behaviorMediasIds),
      };

      handleSubmit(newTarget);

      setOpenAddTarget(event);
    },
    [group, targetData, newTargetName, setOpenAddTarget, handleSubmit]
  );

  const handleSelectGroup = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGroup(event.target.value as number);
  };

  const numberId = Math.floor(Math.random() * (10000 - 100) + 100);

  return (
    <Dialog
      id={`addTargetDialog-${numberId}`}
      open={openAddTarget}
      onClose={setOpenAddTarget}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add new behavior target</DialogTitle>
      <Box component="form" onSubmit={handleAddGroupSubmit}>
        <AddTargetDialogContent>
          <DialogContentText>
            Please select a group and add a target name
          </DialogContentText>
          {/* <TextField
            autoFocus
            margin="dense"
            id="mediaIdField"
            label="Media ID"
            type="text"
            value={targetData ? targetData.id : ""}
            fullWidth
            disabled
          /> */}
          <FormControl variant="outlined" fullWidth>
            <InputLabel id={`select-lbl-${numberId}`}>Group</InputLabel>
            <Select
              labelId={`select-${numberId}`}
              id={`select-${numberId}`}
              fullWidth
              value={group}
              onChange={handleSelectGroup}
            >
              {groups
                ? groups.map((groupData: any) => (
                    <MenuItem value={groupData.id}>{groupData.name}</MenuItem>
                  ))
                : "no groups exists"}
              {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="nameTarget"
            label="Name"
            type="text"
            placeholder="Target name"
            fullWidth
            onChange={setNewTargetName}
            required
          />
        </AddTargetDialogContent>
        <DialogActions>
          <ButtonSecondary onClick={setOpenAddTarget}>Cancel</ButtonSecondary>
          <ButtonPrimary type="submit">Add</ButtonPrimary>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const BRContainer = withStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
  },
}))(Box);

const BRContent = withStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
  },
}))(Box);

const BRHeader = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    background: "#FAFAFA",
    minHeight: "159px",
    display: "flex",
    flexDirection: "column",
    padding: "30px 32px",
    paddingBottom: "0px",
    "& h1": {
      fontSize: "26px",
      lineHeight: "32px",
      fontWeight: "bold",
      flex: "1",
    },
    [theme.breakpoints.down(540)]: {
      minHeight: "unset",
      padding: "20px",
      paddingBottom: "0px",
    },
  },
}))(Box);

const BRBody = withStyles((theme: Theme) => ({
  root: {
    width: "100% !important",
    height: "calc(100% - 160px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    margin: "0px",
    "& ul": {
      borderRadius: "10px",
      padding: "0px",
      "& li": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px",
      paddingTop: "20px",
    },
  },
}))(Box);

const TitleContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: "1",
    [theme.breakpoints.down(540)]: {
      flex: "unset",
      "& h1": {
        fontSize: theme.fontGeneralStyles.large.fontSize,
        lineHeight: theme.fontGeneralStyles.large.lineHeight,
        fontWeight: 600,
      },
    },
  },
}))(Box);

export default {
  behaviorsResultsStyles,
  BRContainer,
  BRContent,
  BRHeader,
  BRBody,
  BRTable,
  BehaviorItemSubTable,
  BRAddNewTarget,
  sentimentsStatics,
  BehaviorItemCollapsable,
  ButtonPrimary,
  TitleContainer,
};
