/* eslint-disable */
// Dependencies
import React, { useEffect, useRef } from "react";
import moment from "moment";

export const capitalizeFLetter = (text) => {
  const newName = text.charAt(0).toUpperCase() + text.slice(1);
  return newName;
};

export const getUpperCamelCase = (str) => {
  if (!str) {
    return "";
  }
  const words = str.split("-");
  for (let i = 0; i < words.length; i += 1) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join("");
};

export const getCamelCase = (str) => {
  if (!str) {
    return "";
  }
  const words = str.split("-");
  for (let i = 0; i < words.length; i += 1) {
    if (i === 0) {
      words[i] = words[i].charAt(0).toLowerCase() + words[i].slice(1);
    } else {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
  }
  return words.join("");
};

/**
 * * Return the string representation of an array of objects
 * * from the filter array saved in Redux
 * @param {Object[]} filter
 * Each object of 'filter' contains:
 * - 'column' (string)
 * - 'values' (Object)
 * Each object of 'values' contains N (key,value) pairs, where
 * - key (string)
 * - value (Array of strings)
 */
export const objectToString = (filter) =>
  `[${
    filter
      ? filter.map((elem) => {
          const array = Object.values(elem.values);
          const items = [].concat.apply([], [...array]);
          return `{column: "${elem.column}", values: [${items.map(
            (val) => `"${val}"`
          )}]}`;
        })
      : ""
  }]`;

/**
 * * Return the parent route name
 * * from the location pathname
 * @param {string} slug
 */
export const routerNameCreator = (name) => {
  const newName = name.replace(/\s/g, "-").toLowerCase();
  return newName;
};

/**
 * * Returns a boolean that indicates if an object is empty
 * @param {Object} obj
 */
export const isEmpty = (obj) => Object.keys(obj).length === 0;

// Save Previous Location
/**
 * * Return the string previous saved value
 * * from the given value using useRef from React
 * @param {string} slug
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * * Return the string title for any tab selected
 * * from the location pathname
 * @param {string} slug
 */
export const makeTitle = (slug) => {
  const words = slug.slice(1).replace("/", " / ").split(/\s|\-/g); // eslint-disable-line
  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
};

/**
 * * Returns a reduced object from an array of objects
 * @param {Object[]} xs - An array of objects
 * @param {string} attrName - the name of the attribute of the values on the reduced object
 * @param {string} key - the name of the attribute of the keys on the reduced object
 * Example:
 *  xs = [
 *    {'name':'x','type':'ABC'},
 *    {'name':'y','type':'ABC'},
 *    {'name':'z','type':'DEF'},
 *  ]
 *  groupByKey(xs,'name','type') will return:
 *  {
 *    'ABC': ['x','y'],
 *    'DEF': ['z']
 *  }
 */
export const groupByKey = (xs, attrName, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x[attrName]);
    return rv;
  }, {});

/**
 * Returns a string with the specified date format
 * @param {String} date - The unformatted date string
 * Example:
 * input: '2020-05-16 17:04:33.735239-05'
 * output: '2020-05-16T17:04'
 */
// const dateFormat = 'YYYY-MM-DDTHH:mm';
// export const getFormattedDate = (date) => {
//   let formattedDate;
//   if (date) {
//     formattedDate = moment(date).format(dateFormat);
//   }
//   return formattedDate;
// };
const H_DATE_FORMAT = "YYYY-MM-DD hh:mm A";
export const getHFormattedDate = (date) => {
  let formattedDate;
  if (date) {
    formattedDate = moment(date).format(H_DATE_FORMAT);
  }
  return formattedDate;
};

/**
 * Returns a sorted array of objects in alphabetic and ascending order [A-Za-z]
 * Requires ALL objects of the array to have a 'name' attribute
 * @param {Object} a - Object with a 'name' attribute
 * @param {Object} b - Object with a 'name' attribute
 */
export const compareByName = (a, b) =>
  a.name > b.name ? 1 : b.name > a.name ? -1 : 0;

/**
 * Returns a sorted array of objects by date
 * Requires ALL objects of the array to have a 'creationDate' attribute
 */
export const compareByCreationDate = (a, b) =>
  new Date(b.creationDate) - new Date(a.creationDate);

export const titleCase = (str) => {
  if (!str) {
    return "";
  }
  const splitStr = str.toLowerCase().split("-" || " ");
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

// Comparing Deeply Two Objects
export const deepEqual = (object1, object2) => {
  const isObject = (object) => object != null && typeof object === "object";

  const keys1 = Object.keys(object1 && object1);
  const keys2 = Object.keys(object2 && object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

// Confirmation messages
export const getDeletePopoverMessage = () => (
  <span>
    Are you sure you want to delete this item?
    <br />
    You won&apos;t be able to recover it.
    <br />
  </span>
);

export const getCancelPopoverMessage = () => (
  <span>
    Are you sure you want to discard changes?
    <br />
    You won&apos;t be able to recover them.
    <br />
  </span>
);
export const getResetPopoverMessage = () => (
  <span>
    Are you sure you want to reset the job?
    <br />
    This delete all labels and you won&apos;t be able to recover them.
    <br />
  </span>
);
