// Dependencies
import {
  withStyles,
  Toolbar as ToolbarBase,
  Box,
  Typography,
  Button as ButtonBase,
  TablePagination as TablePaginationBase,
  Theme,
} from "@material-ui/core";

// Components
import TableToolbarActions from "components/table-toolbar-actions/table-toolbar-actions.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

const Toolbar = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // padding: "30px 30px",
    paddingBottom: "0px",
    justifyContent: "space-between",
    [theme.breakpoints.down(540)]: {
      padding: "20px",
      paddingBottom: "0px",
    },
  },
}))(ToolbarBase) as typeof ToolbarBase;

const TitleActionsContainer = withStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "red",
    marginBottom: "28px",
  },
})(Box);

const ActionsContainer = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down(540)]: {
      flexDirection: "column",
    },
  },
}))(Box);

const TitleWrapper = withStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(1),
    fontStyle: "normal !important",
    fontWeight: 800,
    fontSize: theme.fontGeneralStyles.large.fontSize,
    lineHeight: theme.fontGeneralStyles.large.fontSize,
  },
}))(Box);

const Title = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 800,
    fontSize: theme.fontGeneralStyles.large.fontSize,
    lineHeight: theme.fontGeneralStyles.large.fontSize,
    [theme.breakpoints.down(540)]: {
      fontSize: theme.fontGeneralStyles.large.fontSize,
      lineHeight: theme.fontGeneralStyles.large.fontSize,
      fontWeight: 600,
    },
  },
}))(Typography);

const TablePagination = withStyles((theme: Theme) => ({
  caption: theme.typography.subtitle1,
  input: {
    border: theme.table.tablePagination.inputBorder,
    backgroundColor: theme.palette.background.default,
    color: "#6D6D6D",
  },
  selectIcon: {
    color: theme.table.tablePagination.selectIconColor,
  },
  root: {
    color: "#ADADAD",
    "& .MuiTablePagination-caption": {
      fontSize: theme.table.toolbar.fontSize,
    },
    "& .MuiIconButton-root": {
      color: theme.generalStylesContainer.referrals.color.main,
      "&.Mui-disabled": {
        opacity: "0.3",
      },
    },
    [theme.breakpoints.down(540)]: {
      overflow: "hidden",
      "& .MuiTablePagination-toolbar": {
        display: "flex",
        flexWrap: "wrap",
      },
    },
  },
}))(TablePaginationBase) as typeof TablePaginationBase;

const ToolbarButton = withStyles((theme) => ({
  root: {
    textTransform: "capitalize",
    fontSize: theme.table.toolbar.fontSize,
    border: theme.table.tablePagination.inputBorder,
    marginLeft: theme.spacing(3),
    "&:hover": {
      border: theme.table.tablePagination.inputBorder,
    },
  },
}))(ButtonBase);

const BreadcrumbMod = withStyles((theme: Theme) => ({
  root: {
    paddingRight: "40px",
  },
}))(Breadcrumb);

const TableToolbarActionsMod = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      "&.filter": {
        position: "absolute",
        right: "20px",
      },
    },
  },
}))(TableToolbarActions);

export default {
  Toolbar,
  TitleActionsContainer,
  ActionsContainer,
  TitleWrapper,
  Title,
  ToolbarButton,
  TablePagination,
  TableToolbarActionsMod,
  BreadcrumbMod,
};
