/* eslint-disable */
import { toInteger } from "lodash";

const { REACT_APP_REPORT_SERVER: REPORT_SERVER } = process.env;

/* eslint-disable camelcase */
export const APP_BUILD_DATE = "";

const currDate = new Date(Date.now());
currDate.setHours(23, 59, 59, 999);
const tenDaysAgo = new Date(Date.now());
tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
tenDaysAgo.setHours(0, 0, 0, 0);
export const FILTER_INITIAL_DATE_RANGE = {
  startDate: tenDaysAgo,
  endDate: currDate,
};

// User status
export const USER_STATUS = {
  STAGED: "STAGED",
  PROVISIONED: "PROVISIONED",
  ACTIVE: "ACTIVE",
  DEACTIVATED: "DEACTIVATED",
  SUSPENDED: "SUSPENDED",
};

export const DEFAULT_TAB_PAGE = {
  PARENT_NAME: "stores",
  CHILD_NAME: "stores",
  DEFAULT_ROUTE: "/",
};

export const DEFAULT_API_HELP = {
  DOCUMENT: "schema",
  HELP_SELECTION: 0,
};

export const DEFAULT_API_SCHEMA = {
  JSON_SCHEMA: "event",
  JSON_SCHEMA_SELECTION: 0,
};

export const SERVICES = {
  REPORT_SERVER,
};

export const MODE = {
  STORES: 8,
  USERS: 12,
  ODIN: 10,
};

export const MODE_OPTION = {
  ORGANIZATION_MANAGEMENT: 801,
  STORES: 802,
  MY_PROFILE: 1301,
  USER_MANAGEMENT: 1202,
  ROLE_MANAGEMENT: 1203,
  GROUP_MANAGEMENT: 1204,
  IDENTITY_PROVIDER_MANAGEMENT: 1205,
  LABELING_JOBS: 1001,
  METRICS: 1002,
  LABELING_ENG: 1003,
  TRAINING_JOBS_CREATION: 1004,
  TENSORBOARD: 1005,
};

// Table column ID"s
export const COLUMN_ID = {
  NAME: "name",
  CREATION_DATE: "creationDate",
  ORGANIZATION: "organization",
  GROUPS: "groups",
  ROLES: "roles",
  SEQUENCE: "sequence",
  BRAND: "brandId",
  COUNTRY: "countryId",
  DISTRICT: "districtId",
  DIVISION: "divisionId",
  STORE_NAME: "name1",
  REGION: "regionId",
  TERRITORY: "territoryId",
  PROTOCOL: "protocol",
};
// Odin Jobs Table column ID"s
export const JOBS_COLUMN_ID = {
  COMMENTS: "comments",
  ID: "ID",
  STATUS: "status",
  DATE_RANGE_START: "DATE_RANGE_START",
  DATE_RANGE_END: "DATE_RANGE_END",
  END_DATE_RANGE_START: "END_DATE_RANGE_START",
  END_DATE_RANGE_END: "END_DATE_RANGE_END",
  CREATETIME: "jobCreatedDatetimeUTC",
  STORE: "storeID",
  STORE_NAME: "storeName",
  TECHNICIAN: "user",
  ENDTIME: "jobStopTimeUTC",
  UPDATEDTIME: "jobUpdatedDatetimeUTC",
  DEVICE: "deviceID",
  DEVICE_NAME: "deviceName",
  CLASS_TYPE: "labels",
  LABELED_CLASS_TYPE: "labeledClasses",
  IMAGE_DATE_RANGE: "imageDateRange",
};
// Odin Jobs Table column ID"s
export const TRAINING_MODELS_COLUMN_ID = {
  NAME: "name",
  STATUS: "status",
  CREATETIME: "updatedDatetimeUTC",
  DATE_RANGE_START: "DATE_RANGE_START",
  DATE_RANGE_END: "DATE_RANGE_END",
  STORE: "storeID",
  PARENT_MODEL: "parentModelId",
  DEVICE: "deviceID",
};
// Type Operations
export const TYPE = {
  QUERIES: "queries",
  MUTATIONS: "mutations",
};
// ODIN BUCKET NAME
export const ODIN_BUCKET_NAME = "gks-g2-dev-storage-image-us";
// Odin Graphql Querys
const addQuotes = (arr) => arr.map((item) => `"${item}"`);
const arrToInteger = (arr) => arr.map((item) => toInteger(item));
const addSlashesToString = (str) => {
  const resultedText = str.replace(/\\"/g, '\\\\"').replace(/"/g, '\\"');
  return resultedText;
};

export const ODIN_GRAPHQL = {
  QUERIES: {
    getMachineTypes: () => `
    query{
      GetMachineTypes(
        zone: "us-central1-a"
      ) {
        id
        creationTimestamp
        name
        description
        guestCpus
        memoryMb
        imageSpaceGb
        scratchDisks{
          diskGb
        }
        maximumPersistentDisks
        maximumPersistentDisksSizeGb
        deprecated{
          state
          replacement
          deprecated
          obsolete
          deleted
        }
        zone
        selfLink
        isSharedCpu
        accelerators{
          guestAcceleratorType
          guestAcceleratorCount
        }
        kind
      }
    }`,
    getLabelCountsForTrainingJobsCreation: ({
      labelingJobIDs,
      classesEnum,
      labelsFrom,
      labelsTo,
      ignoredElementsIds,
      deviceIDs,
      storeID,
    }) => `query
    {
      GetLabelCountsForTrainingJobCreation(
      ${
        // eslint-disable-next-line quotes
        ignoredElementsIds
          ? `ignoredElementsIDs
          :
          [${addQuotes(ignoredElementsIds)}],`
          : ``
      }
      ${
        // eslint-disable-next-line quotes
        deviceIDs ? `deviceIDs:[${addQuotes(deviceIDs)}],` : ``
      }
      ${
        // eslint-disable-next-line quotes
  storeID ? `storeID:"${storeID}",` : ``}
        labelingJobIDs:[${labelingJobIDs.map(ID => `"${ID}"`)}],
        classesEnum:[${classesEnum}],
        ${// eslint-disable-next-line quotes
  labelsFrom ? `labelsFrom:"${new Date(labelsFrom).toISOString()}",` : ``}
        ${// eslint-disable-next-line quotes
  labelsTo ? `labelsTo:"${new Date(labelsTo).toISOString()}"` : ``}),
        {
        classificationEnum
        cnt
      }
    }`,
    getTrainedModels: (

      {
        // modelId,
        // modelDeviceIds,
        // modelStatus,
        // modelVersion,
        modelsFrom,
        modelsTo,
      }


    ) => `query{
      GetTrainedModels${modelsFrom || modelsTo ? `(
          ${// eslint-disable-next-line quotes
  modelsFrom ? `modelsFrom:"${modelsFrom.toISOString()}}",` : ``}
            ${// eslint-disable-next-line quotes
  modelsTo ? `modelsTo:"${modelsTo.toISOString()}}",` : ``}
      )` : ""} {
        ID
        parentModelID
        name
        status
        gcsPath
        statusKeyName
        statusKeyEnum
        trainingName
      }
    }`,
    GetLabelJobsData: () => `query {
      GetLabelJobsData{
        ID
        sequence
        deviceID
        deviceName
        deviceSequence
        storeID
        storeName
        storeSequence
        commandID
        recordStartTimeUTC
        recordStopTimeUTC
        jobStartTimeUTC
        jobStopTimeUTC
        imageCount
        labelCount
        statusKeyName
        statusEnum
        labelObjectKeyName
        labelTypeEnum
        classificationEnum
        jobUpdatedDatetimeUTC
        jobCreatedDatetimeUTC
        keyName
        sku
        status
        avalaibleImages
        totalSpentTime
        lastSpentTime
        instructionsName
        maxIdleTime
        averageImageTime
        userID
        deletedImages
        labeledClasses
        comments
        labelCounts
      }
    }`,
    getImagesForLabel: ({ jobId, limit }) => (`mutation {
      UpdateToBusyAndRetrieveOdinImageForLabel(
        jobID: "${jobId}",
        queryLimit: ${limit}
      ) {
        ID
        odinLabelJobID
        objectName
        resolution
        fileSizeByte
        imageCreatedDatetimeUTC
        imageReceivedDatetimeUTC
        updatedDatetimeUTC
        createdDatetimeUTC
        statusKeyName
        statusEnum
        colorKeyName
        colorEnum
        triggerKeyName
        triggerEnum
        classificationEnum
        labelsInImages
        bucketImageName
        toReLabel
      }
    }`),
    getImagesForReview: ({ jobId, queryLimit, queryOffset }) => (`query {
      GetOdinImage(
        jobID: "${jobId}",
        queryLimit: ${queryLimit},
        queryOffset: ${queryOffset},
        mode: imageForReview
      ) {
        ID
        odinLabelJobID
        objectName
        resolution
        fileSizeByte
        imageCreatedDatetimeUTC
        imageReceivedDatetimeUTC
        updatedDatetimeUTC
        createdDatetimeUTC
        statusKeyName
        statusEnum
        colorKeyName
        colorEnum
        triggerKeyName
        triggerEnum
        classificationEnum
        labelsInImages
        labelHistory
        spentTime
        bucketImageName
        toReLabel
      }
    }`),
    GetSignedURLsToReadObjectsFromOdinBucket: ({
      objectNames, expirationTime
    }) => `query {GetSignedURL(
      bucketProperties: [${objectNames.map(obj => `{objectName:"${obj.objectName}",bucketName:"${obj.bucketName}"}`)}],
      expirationTime: ${expirationTime}
    )}`,
    getOdinLabelClases: () => `query {
      GetOdinLabelClases{
        keyEnum
        name
        hexColorFont
        hexColorCell
      }
    }`,
    getImagesForTrainingCreation: ({
      jobIDs, classTypes, labelsFrom, labelsTo
    }) => `query {
      GetImagesForTrainingCreation(
        from: 0,
        pageSize: 1,
        jobIds: [${jobIDs}],
        classTypes: [${classTypes}]${// eslint-disable-next-line quotes
  labelsTo ? `,labelsTo:"${labelsTo}"` : ``}${// eslint-disable-next-line quotes
  labelsFrom ? `,labelsFrom:"${labelsFrom}"` : ``}
      ) {
        ID
        odinLabelJobID
        objectName
        resolution
        fileSizeByte
        imageCreatedDatetimeUTC
        createdDatetimeUTC
        updatedDatetimeUTC
        createdDatetimeUTC
        statusKeyName
        statusEnum
        colorKeyName
        triggerEnum
        classificationEnum
        labelsInImages
        labelHistory
        spentTime
        bucketImageName
      }
    }`
  },
  MUTATIONS: {
    AddDummyCommand: ({ topicId = "g2_ps_iot_command", payload }) => `
    mutation{
      AddDummyCommand(
        topicId: "${topicId}"
        payload:"${addSlashesToString(payload)}")}`,
    addTrainingJob: ({
      storeId,
      deviceIdList,
      labelingJobsList,
      ignoredElementsList,
      classesEnumList,
      classMappingList,
      classTagMappingList,
      selectedModelId,
      generatedModelName,
      trainingJobName,
      comments,
      tfParams,
      pipelineConfig,
      // dateRange,
      instanceType = "n1-standard-16",
      dateRange,
      // userId
    }) => `mutation{
        AddTrainingJob(
          storeId:"${storeId}",
          deviceIdList:[${addQuotes(deviceIdList)}],
          labelingJobsList:[${addQuotes(labelingJobsList)}],
          ignoredElementsList:[${addQuotes(ignoredElementsList)}],
          classesEnumList:[${arrToInteger(classesEnumList)}],
          classMappingList:[${arrToInteger(classMappingList)}],
          classTagMappingList:[${arrToInteger(classTagMappingList)}],
          ${// eslint-disable-next-line quotes
  selectedModelId ? `selectedModelId:"${selectedModelId}",` : ``}
          generatedModelName:"${generatedModelName}",
          trainingJobName:"${trainingJobName}",
          comments:"${comments}",
          tfParams:"${addSlashesToString(JSON.stringify(tfParams))}",
          ${pipelineConfig ? `pipelineConfig: ${JSON.stringify(pipelineConfig)},` : ""}
          instanceType:"${instanceType}"${// eslint-disable-next-line quotes
  dateRange ? `, dateRange:[${addQuotes(dateRange)}]` : ``}
        )
      }
    `,
    addOdinLabels: ({
      odin_label_image_id,
      odin_label_job_id,
      x_min,
      y_min,
      x_max,
      y_max,
      status_enum,
      classification_enum,
      noLabels,
      startTime,
      userId,
    }) => `mutation {
      AddOdinLabels(
        imageIDs: [],
        method: "create_labels",
        odinLabelImageID: [${odin_label_image_id}],
        odinLabelJobID: "${odin_label_job_id}",
        xMin: [${x_min}],
        yMin: [${y_min}],
        xMax: [${x_max}],
        yMax: [${y_max}],
        statusEnum:[${status_enum}],
        classificationEnum:[${classification_enum}],
        noLabelsImages:[${noLabels}],
        odinLabelUuid: [],
        startTime:${!!startTime},
        imageTimes:[],
        userId:"${userId}"
      )
    }`,
    updateOdinLabels: `mutation(
      $x_min: [Float]!,
      $y_min: [Float]!,
      $x_max: [Float]!,
      $y_max: [Float]!,
      $label_ids: [String!]!,
      $classification_enum: [Int]!,
      $userId: String) {
      UpdateOdinLabels(
        xMin: $x_min,
        yMin: $y_min,
        xMax: $x_max,
        yMax: $y_max,
        odinLabelUuid: $label_ids,
        classificationEnum: $classification_enum,
        userId:$userId
      )
    }`,
    deleteOdinLabels: ({ odinLabelUuid, userId }) => (
      `mutation {
        DeleteOdinLabels(
          odinLabelUuid: [${odinLabelUuid}],
          userId:"${userId}"
        )
      }`),
    updateImageTimes: ({ imagesIds, imagesTimes }) => (
      `mutation {
        UpdateImageTimes(
          imageIds: [${imagesIds}],
          imagesTimes:[${imagesTimes}]
        )
      }`
    ),
    updateStartOdinJob: (jobId, userId, noTime) => (
      `mutation {
        UpdateStartOdinJob(
          jobId: "${jobId}",
          userId: "${userId}",
          noTime: ${noTime}
        )
      }`
    ),
    updateStopOdinJob: (jobId, userId, noTime, timedOut) => (
      `mutation {
        UpdateStopOdinJob(
          jobId: "${jobId}",
          userId: "${userId}"
          noTime: ${noTime},
          timedOut: ${timedOut}
        )
      }`
    ),
    updateResetNotLabeledImages: ({ imagesIds }) => (`mutation{
      UpdateResetNotLabeledImages(
        imageIds: [${imagesIds}]
      )
    }`),
    deleteOdinLabelImage: ({ imagesIds, userId }) => (`mutation{
      DeleteOdinLabelImage(
        imageIDs: [${imagesIds}],
        userID: "${userId}"
      )
    }`)
  }
};
