/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React from "react";
import TagManager from "react-gtm-module";
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";
import { ProgressIndicator } from "components/progress-indicator/progress-indicator.component";

// Graphql
import { useLogInLazyQuery } from "graphql/types-and-hooks";
import auth from "graphql/authentication";

import Form from "components/signin-form/signin-form.component";

// Assets
import SC from "./signin.styles";

export type UserCredentials = {
  email: string;
  password: string;
};

const SignInComponent: React.FC = () => {
  const [rememberMe, setRememberMe] = React.useState(true);
  const [userCredentials, setUserCredentials] = React.useState<UserCredentials>(
    { email: "", password: "" }
  );

  const handleUserCredentials = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setUserCredentials((oldUserCredentials) => ({
      ...oldUserCredentials,
      [name]: value,
    }));

    if (name === "email") {
      TagManager.dataLayer({
        dataLayer: {
          email: value,
        },
      });
    }
  };

  const [loginQuery, { loading, error, data }] = useLogInLazyQuery({
    fetchPolicy: "no-cache",
    context: {
      noAuthNeeded: true,
    },
  });

  const errorHandler = useAppErrorHandler();

  React.useEffect(() => {
    if (error) {
      TagManager.dataLayer({
        dataLayer: {
          event: "userLoginFail",
        },
      });

      if (isServerError(error)) {
        errorHandler(error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has occurred while logging. Try again later..."
          )
        );
      }
    }

    if (data) {
      auth.logIn(data.LogIn, rememberMe);

      TagManager.dataLayer({
        dataLayer: {
          event: "userLogin",
        },
      });
    }
  }, [data, error, rememberMe]);

  const onSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      errorHandler();
      loginQuery({ variables: userCredentials });
    },
    [loginQuery, userCredentials]
  );

  return (
    <SC.Container>
      <ProgressIndicator open={loading} />
      <SC.Content>
        <SC.Logo />
        <SC.Title>Sign in</SC.Title>
        <Form
          handleSubmit={onSubmit}
          credentials={userCredentials}
          handleOnChange={handleUserCredentials}
          setRememberMe={setRememberMe}
          rememberMe={rememberMe}
        />
      </SC.Content>
    </SC.Container>
  );
};

export default SignInComponent;
