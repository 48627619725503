import { Box as BoxBase, withStyles, Typography } from "@material-ui/core";

const Container = withStyles((theme) => ({
  root: {
    paddingLeft: "1.14rem",
    paddingRight: "1.14rem",
    textAlign: "left",
  },
}))(BoxBase);

const Label = withStyles((theme) => ({
  root: {
    paddingBottom: "2.28rem",
    fontSize: "1.44rem",
  },
}))(Typography);

const Box = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingTop: "2.28rem",
  },
}))(BoxBase);

export default { Container, Box, Label };
