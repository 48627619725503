// Dependencies
import React from "react";

// Assets
import SC from "./user-profile-picture.styles";

interface Props {
  name: string;
  urlPicture: string;
  isEditable: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const UserProfilePicture: React.FC<Props> = ({
  name,
  urlPicture,
  isEditable,
  onChange,
}) => (
  <SC.ProfileContainer>
    <SC.InputBasePicture
      type="file"
      id="imageinput"
      name={name}
      inputProps={{ accept: "image/*" }}
      onChange={onChange}
    />

    {isEditable && (
      <SC.LabelPicture htmlFor="imageinput">
        <SC.Tooltip title="Click to change image" aria-label="change-image">
          <SC.Fab component="span">
            <SC.CreateOutlinedIcon />
          </SC.Fab>
        </SC.Tooltip>
      </SC.LabelPicture>
    )}

    {urlPicture ? (
      <SC.UserAvatar src={urlPicture} color="primary" />
    ) : (
      <SC.DefaultAvatarIcon />
    )}
  </SC.ProfileContainer>
);

export default UserProfilePicture;
