/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  withStyles,
  Breadcrumbs,
  Link as LinkBase,
  Typography,
  Box as BoxBase,
  makeStyles,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link as LinkBreadcrumb, LinkProps } from "react-router-dom";

const Container = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    flex: 1,
  },
}))(BoxBase);

const Label = withStyles((theme) => ({
  root: {
    fontSize: theme.breadCrumps.fontSize,
  },
}))(Typography);

const CustomLink: React.ComponentType<LinkProps> =
  LinkBreadcrumb as React.ComponentType<LinkProps>;

type LinkBreadcrumbProps = LinkProps & { isActive?: boolean };

const CustomLinkStyles = makeStyles((theme) => ({
  root: {
    color: (isActive) => (isActive ? "#ADADAD" : theme.palette.primary.main),
    pointerEvents: (isActive) => (isActive ? "none" : "auto"),
    fontSize: theme.breadCrumps.fontSize,
    textTransform: "capitalize",
    textDecoration: "none",
  },
}));

const Link: React.FC<LinkBreadcrumbProps> = (props) => {
  const { isActive, to, ...muiProps } = props;
  const classes = CustomLinkStyles(isActive);

  return <CustomLink to={to} className={classes.root} {...muiProps} />;
};

const LinkSimple = withStyles((theme) => ({
  root: {
    fontSize: theme.breadCrumps.fontSize,
    textTransform: "capitalize",
    textDecoration: "none",
  },
}))(LinkBase);

export default {
  Breadcrumbs,
  Link,
  LinkSimple,
  Label,
  NavigateNextIcon,
  Container,
};
