import React from "react";
import {
  Box,
  Card,
  CardMedia,
  Chip,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  Select,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  SvgIcon,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";

import Table, { TableColumn } from "components/table/table.component";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LockIcon from "@material-ui/icons/Lock";
import SendIcon from "@material-ui/icons/Send";

import RewardImage from "assets/images/reawrd_gift_image.png";
import { ReactComponent as megaphoneIcon } from "assets/icons/megaphone_icon.svg";
import { ReactComponent as moneyBagIcon } from "assets/icons/moneyBag_icon.svg";
import { ReactComponent as discountIcon } from "assets/icons/discount_icon.svg";

import { Autocomplete } from "@mui/material";
import ButtonPrimary from "components/button-primary/button-primary.component";
import { transform } from "lodash";
import GeneralSearch from "components/general-search/general-search.component";
import { borderRadius } from "react-select/src/theme";

const useStylesGeneral = makeStyles((theme: Theme) =>
  createStyles({
    rewardsMain: {
      padding: "29px 18px",
    },
    rewardsTitle: {
      paddingLeft: "25px",
    },
    mainLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      fontWeight: 800,
      color: theme.generalStylesContainer.referrals.color.main,
      "& .discount": {
        color: theme.generalStylesContainer.referrals.color.highlight,
        marginLeft: "5px",
      },
    },
    stepperLabel: {
      fontSize: theme.fontGeneralStyles.default.fontSize,
      lineHeight: theme.fontGeneralStyles.default.lineHeight,
      color: theme.generalStylesContainer.referrals.color.stepper,
    },
    secondaryLabel: {
      fontSize: theme.fontGeneralStyles.xSmall.fontSize,
      lineHeight: theme.fontGeneralStyles.xSmall.lineHeight,
      color: theme.generalStylesContainer.referrals.color.main,
      fontWeight: 300,
    },
    earnedMain: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "154px",
      flex: "1",
      color: theme.generalStylesContainer.referrals.color.gold,
      background: "#FAF4EF",
      padding: "12px",
      "& .averange-title": {
        fontSize: theme.fontGeneralStyles.default.fontSize,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
        fontWeight: 600,
        textAlign: "start",
      },
      "& .averange": {
        fontSize: "40px",
        lineHeight: "50px",
        fontWeight: 600,
        margin: "auto",
      },
    },
    earnedSecondary: {
      flex: "1",
      padding: "12px",
      maxHeight: "90px",
      "& .secondary-title": {
        fontSize: theme.fontGeneralStyles.default.fontSize,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
        fontWeight: 300,
        textAlign: "start",
        color: theme.generalStylesContainer.referrals.color.green,
      },
      "& .total": {
        fontSize: "28px",
        lineHeight: "35px",
        fontWeight: 700,
        color: "#6EA1A3",
      },
    },
    boxBorder: {
      border: "1px solid #ABC8CA",
    },
    boxBorder8: {
      borderRadius: "8px",
    },
    boxBorder20: {
      borderRadius: "20px",
    },
  })
);
const PageContainer = withStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "hidden",
    gap: "10px",
    boxShadow: "0px 0px 10px 0px #0000001A",
  },
}))(Box);

const PageContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "30px 32px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px",
    },
  },
}))(Box);

const PageHeader = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    // minHeight: "130px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "5px",
    "& h1": {
      fontSize: "26px",
      lineHeight: "32px",
      fontWeight: "bold",
    },
    [theme.breakpoints.down(540)]: {
      minHeight: "60px",
    },
  },
}))(Box);

const PageTitle = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: "1",
  },
}))(Box);

const InfoSection = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    gap: "12px",
  },
}))(Box);

const CardElement = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    borderRadius: "10px",
    padding: "12px",
    "& table": {
      background: theme.generalStylesContainer.layers.primary.backgroundColor,
      border: "unset",
      // borderRadius: "10px",
      "& thead": {
        "& tr": {
          border: "1px solid #ABC8CA",
          borderBottom: "unset",
          borderRadius: "10px 10px 0px 0px",
          "& th": {
            borderBottom: "1px solid #ABC8CA !important",
          },
        },
      },
      "& tbody": {
        "& tr": {
          borderLeft: "1px solid #ABC8CA",
          borderRight: "1px solid #ABC8CA",
          "& td": {
            borderBottom: "1px solid #ABC8CA !important",
            color: theme.generalStylesContainer.referrals.color.cell,
            "&:first-child": {
              fontWeight: "600",
            },
            "&:last-child": {
              fontWeight: "600",
            },
          },
          "&.Complete": {
            background: theme.generalStylesContainer.referrals.color.complete,
          },
        },
        "& .noResultsMain": {
          border: "1px solid #ABC8CA",
          margin: "unset",
          borderRadius: "unset",
          borderTop: "unset",
          padding: "20px",
        },
      },
    },
    "& .topPanelContainer": {
      flexDirection: "row",
      justifyContent: "space-between",
      "& .MuiToolbar-regular": {
        minHeight: "unset",
      },
    },
    [theme.breakpoints.down(540)]: {
      minWidth: "unset",
      borderRadius: "0px 0px 10px 10px",
      boxShadow: "0px 7px 10px rgba(0, 0, 0, 0.1)",
    },
  },
}))(Card);

const CardTitle = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.fontGeneralStyles.medium.fontSize,
    lineHeight: theme.fontGeneralStyles.medium.lineHeight,
    fontWeight: 800,
    color: theme.generalStylesContainer.chat.brand,
  },
}))(Typography);

const CustomChip = withStyles((theme: Theme) => ({
  root: {
    height: "11px !important",
    flexFlow: "row-reverse",
    columnGap: "3px",
    padding: "11px 12px",
    color: theme.generalStylesContainer.chat.chip.color,
    width: "fit-content",
    "& span": {
      display: "list-item",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 600,
      padding: "unset",
    },
    "& svg": {
      fontSize: "18px",
      margin: "unset",
    },
    "&.complete": {
      background: "#0F542B33",
      color: "#034C20",
    },
    "&.pending": {
      background: "#FAF4EF",
      color: theme.generalStylesContainer.referrals.color.gold,
    },
    "&.color1": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor1,
      "& svg": {
        color: "#D9C5A6",
      },
    },
    "&.color2": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor2,
      "& svg": {
        color: "#5AE3C2",
      },
    },
    "&.color3": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor3,
      "& svg": {
        color: "#E37B5A",
      },
    },
    "&.color4": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor4,
      "& svg": {
        color: "#B1D1D4",
      },
    },
    "&.small": {
      maxWidth: "123px",
      width: "100%",
    },
    "&.big": {
      maxWidth: "160px",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "unset !important",
      maxWidth: "calc(100vw - 155px)",
      width: "fit-content !important",
      padding: "0px 10px",
      marginRight: "unset !important",
      ...theme.commons.textClipped,
    },
  },
}))(Chip);

const PapersTable = withStyles((theme: Theme) => ({
  root: {
    "&.MuiTable-root": {
      background: "#FFF",
    },
    "& thead": {
      "& th": {
        "& span": {
          fontSize: theme.fontGeneralStyles.default.fontSize,
          lineHeight: theme.fontGeneralStyles.default.lineHeight,
        },
        "&.MuiTableCell-root": {
          borderTop: "1px solid #ABC8CA",
          borderBottom: "1px solid #ABC8CA",
        },
        "&:fisrt-child": {
          borderLeft: "1px solid #ABC8CA",
          borderRadius: "8px 0px 0px",
        },
        "&:last-child": {
          borderRight: "1px solid #ABC8CA",
          borderRadius: "0px 0px 8px",
        },
      },
    },
  },
}))(Table);

const SearchInputContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "end",
  },
}))(Box);

const SearchInput = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "300px",
    padding: "0px",
    "& .MuiInputBase-root": {
      width: "100%",
      padding: "0px 8px",
      border: `1px solid #ABC8CA`,
      borderRadius: "4px",
      color: "#ABC8CA",
    },
  },
}))(GeneralSearch);

const CorporatusStepper = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: "unset",
    paddingRight: "unset",
    background: "transparent",
    "& .MuiStep-root:first-child": {
      "& .MuiStepLabel-root": {
        alignItems: "start",
        "& .MuiStepLabel-iconContainer": {
          paddingLeft: "25px",
        },
        "& .MuiStepLabel-labelContainer": {
          width: "fit-content",
          minWidth: "70px",
        },
      },
    },
    "& .MuiStep-root:nth-child(even)": {
      "& .MuiStepConnector-root:first-child": {
        left: "calc(-100% + 60px)",
      },
    },
    "& .MuiStep-root:last-child": {
      "& .MuiStepConnector-root": {
        right: "calc(50% - 70px)",
      },
      "& .MuiStepLabel-root": {
        alignItems: "end",
        "& .MuiStepLabel-iconContainer": {
          paddingRight: "25px",
        },
        "& .MuiStepLabel-labelContainer": {
          width: "fit-content",
          minWidth: "70px",
        },
      },
    },
  },
}))(Stepper);

const ReferrarButton = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "574px !important",
    width: "100%",
    margin: "auto",
    "& .send-icon": {
      transform: "rotate(310deg)",
      marginBottom: "4px",
    },
    "& .MuiCircularProgress-root": {
      height: "20px !important",
      width: "20px !important",
    },
  },
}))(ButtonPrimary);

const EmailAutocomplete = withStyles((theme: Theme) => ({
  root: {
    "& fieldset": {
      borderColor: "#0000005C !important",
    },
    "& label.MuiFormLabel-root": {
      color: "#0000008F",
    },
    "& .MuiChip-root": {
      background: "#FAF4EF",
      color: theme.generalStylesContainer.referrals.color.gold,
    },
  },
}))(Autocomplete);

const MainContainerWithBorder = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    borderRadius: "10px",
    padding: "12px",
    border: "1px solid #ABC8CA",
  },
}))(Box);

const useColorlibStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#FFF",
    zIndex: 1,
    color: theme.generalStylesContainer.referrals.color.highlight,
    width: 22,
    height: 22,
    display: "flex",
    border: "1.7px solid #B8CAC6",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fontSize: "16px",
    },
  },
  active: {
    backgroundImage: theme.generalStylesContainer.referrals.color.highlight,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    "& svg": {
      fontSize: "23px",
    },
  },
  completed: {
    backgroundImage: theme.generalStylesContainer.referrals.color.highlight,
  },
}));

const ColorlibConnector = withStyles((theme: Theme) => ({
  alternativeLabel: {
    top: 11,
  },
  active: {
    "& $line": {
      backgroundColor: theme.generalStylesContainer.referrals.color.highlight,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.generalStylesContainer.referrals.color.highlight,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    open: <CheckCircleIcon />,
    lock: <LockIcon />,
  };

  return (
    <Box
      className={`${classes.root} ${
        active || completed ? classes.active : ""
      } ${completed ? classes.completed : ""}`}
    >
      {active || completed ? icons.open : icons.lock}
    </Box>
  );
}

const steps = ["20%<br/>Discount", "30%<br/>Discount", "40%<br/>Discount"];

export interface ResumeInfoInterface {
  completeRewards: number;
  pendingRewards: number;
  signedUpRewards: number;
  signedInRewards: number;
  percentageApplied: number;
  percentageAvailable: number;
}

interface RewardsElementInterface {
  onClickSend: (param1: string) => void;
  resumeInfo: ResumeInfoInterface;
  loading?: boolean;
}

const RewardsElement: React.FC<RewardsElementInterface> = ({
  onClickSend,
  resumeInfo,
  loading = false,
}) => {
  const classes = useStylesGeneral();

  const [isLoading, setIsLoading] = React.useState(loading);
  const [activeStep, setActiveStep] = React.useState(
    resumeInfo.completeRewards - 1
  );
  const [emailAddresses, setEmailAddresses] = React.useState<string>("");

  const handleChangeEmail = (event: any) => {
    // console.debug("====> handleChangeEmail: ", event.target.value);
    setEmailAddresses(event.target.value as string);
  };

  React.useEffect(() => {
    if (resumeInfo) {
      setActiveStep(resumeInfo.completeRewards - 1);
    }
  }, [resumeInfo]);

  React.useEffect(() => {
    // console.debug("=====> Loading Redeem: ", loading);
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      <Box
        className={`${classes.boxBorder} ${classes.boxBorder8} ${classes.rewardsMain}`}
      >
        <CardTitle align="left" className={classes.rewardsTitle}>
          My Rewards
        </CardTitle>
        <CorporatusStepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Box
                  className={classes.stepperLabel}
                  dangerouslySetInnerHTML={{
                    __html: label,
                  }}
                />
              </StepLabel>
            </Step>
          ))}
        </CorporatusStepper>
        <Box className={classes.mainLabel}>
          Refer to 1 more friends to earn your next{" "}
          <Typography className="discount">Discount</Typography>
        </Box>
      </Box>
      <Typography className={classes.mainLabel}>
        Send and invitation vía email{" "}
      </Typography>
      <Typography className={classes.mainLabel}>
        Invite friends via email by entering their email addresses and we’ll
        send them a referral link.
      </Typography>
      <TextField
        variant="outlined"
        label="Email Addresses"
        placeholder="email"
        onChange={handleChangeEmail}
        value={emailAddresses}
      />
      {/* <EmailAutocomplete
        multiple
        id="email-addresses-autocomplete"
        options={[
          { id: 1, email: "email1@test.com" },
          { id: 2, email: "email2@test.com" },
        ]}
        getOptionLabel={(option) =>
          (option as { id: number; email: string }).email
        }
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Email Addresses"
            placeholder="email"
            onChange={handleChangeEmail}
          />
        )}
      /> */}
      <ReferrarButton
        disabled={emailAddresses.length === 0}
        startIcon={
          isLoading ? <CircularProgress /> : <SendIcon className="send-icon" />
        }
        onClick={() => {
          onClickSend(emailAddresses);
          setEmailAddresses("");
        }}
      >
        SEND
      </ReferrarButton>
    </>
  );
};

interface EarnedElementInterface {
  redeemClick: () => void;
  resumeInfo: ResumeInfoInterface;
  loading?: boolean;
}

const EarnedElement: React.FC<EarnedElementInterface> = ({
  redeemClick,
  resumeInfo,
  loading = false,
}) => {
  const classes = useStylesGeneral();

  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    // console.debug("=====> Loading Redeem: ", loading);
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      <Box className={`${classes.earnedMain} ${classes.boxBorder20}`}>
        <Typography className="averange-title">Discount Available</Typography>
        <Typography className="averange">
          {resumeInfo.percentageAvailable}%
        </Typography>
      </Box>
      <ReferrarButton
        onClick={redeemClick}
        disabled={resumeInfo.percentageAvailable === 0}
        startIcon={<CircularProgress hidden={!isLoading} />}
      >
        REDEEM NOW
      </ReferrarButton>
      <Box
        className={`${classes.earnedSecondary} ${classes.boxBorder} ${classes.boxBorder20}`}
      >
        <Typography className="secondary-title">
          Total friends who signed up
        </Typography>
        <Typography className="total">{resumeInfo.signedUpRewards}</Typography>
      </Box>
      <Box
        className={`${classes.earnedSecondary} ${classes.boxBorder} ${classes.boxBorder20}`}
      >
        <Typography className="secondary-title">
          Total friends who purchased a plan
        </Typography>
        <Typography className="total">
          {resumeInfo.completeRewards + resumeInfo.signedInRewards}
        </Typography>
      </Box>
    </>
  );
};

const RewardInfoContent = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "360px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "21px",
    overflowY: "auto",
    padding: "16px",
    boxShadow: "0px 0px 10px 0px #0000001A",
    "& .rewardImage": {
      maxWidth: "243px",
      paddingLeft: "47px",
      paddingRight: "47px",
      margin: "0 auto",
    },
    "& .title": {
      fontSize: "24px",
      lineHeight: "30.17px",
      fontWeight: 700,
      color: theme.generalStylesContainer.referrals.color.main,
    },
    "& .subTitle": {
      display: "flex",
      alignItems: "center",
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      fontWeight: 700,
      color: theme.generalStylesContainer.referrals.color.main,
      "& svg": {
        marginRight: "30px",
      },
    },
    "& .description": {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      fontWeight: 400,
      color: theme.generalStylesContainer.referrals.color.main,
    },
    "& .description-2": {
      fontSize: theme.fontGeneralStyles.default.fontSize,
      lineHeight: theme.fontGeneralStyles.default.lineHeight,
      fontWeight: 400,
      paddingLeft: "50px",
      textAlign: "justify",
      color: theme.generalStylesContainer.referrals.color.main,
    },
  },
}))(Box);

const RewardInfo: React.FC = () => {
  return (
    <RewardInfoContent>
      <CardMedia
        className="rewardImage"
        component="img"
        src={RewardImage}
        title="Main Chat Image"
      />
      <Typography className="title">Sharing is Rewarding</Typography>
      <Box component="p" className="description">
        Welcome to the Corporatus referral program! Here, you can easily share
        your unique referral link with friends and colleagues.
      </Box>
      <Typography className="subTitle">
        <SvgIcon component={megaphoneIcon} /> 1.Share Your Link
      </Typography>
      <Box component="p" className="description-2">
        Spread the word about Corporatus and invite others to join using your
        unique referral link.
      </Box>
      <Typography className="subTitle">
        <SvgIcon component={discountIcon} />
        2.Earn Discounts
      </Typography>
      <Box component="p" className="description-2">
        For every friend who purchases a plan, you&apos;ll receive a 15%
        discount applied to your next month&apos;s payment. You can refer as
        many people as you like, but only one discount code can be applied per
        month.
      </Box>
      <Typography className="subTitle">
        <SvgIcon component={moneyBagIcon} />
        3.Enjoy Savings
      </Typography>
      <Box component="p" className="description-2">
        Watch your monthly payments decrease as you continue to refer others to
        Corporatus. It&apos;s that simple!
      </Box>
    </RewardInfoContent>
  );
};

export default {
  PageContainer,
  PageContent,
  PageHeader,
  PageTitle,
  InfoSection,
  CardElement,
  CardTitle,
  RewardsElement,
  EarnedElement,
  CustomChip,
  PapersTable,
  SearchInputContainer,
  SearchInput,
  RewardInfo,
  MainContainerWithBorder,
};
