/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import { Maybe } from "graphql/hasura/types-and-hooks";
import React from "react";
import {
  FormattedDate,
  FormattedTime,
  CustomFormatConfig,
  FormattedNumber,
  FormatNumberOptions,
  FormattedDateParts,
  IntlShape,
} from "react-intl";

export const FORMAT_DATE_OPTIONS_DAY_MONTH_COMMA_YEAR: Intl.DateTimeFormatOptions =
  {
    year: "numeric",
    day: "2-digit",
    month: "short",
  };

export const FORMAT_NOTE_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
};

export const FORMAT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
export const FORMAT_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
};

export type RtcsFormattedDateTimeProps = Intl.DateTimeFormatOptions &
  CustomFormatConfig & {
    value: string | number | Date | undefined;
  };
export const RtcsFormattedDate: React.FC<RtcsFormattedDateTimeProps> = (
  props
) => <FormattedDate {...props} {...FORMAT_DATE_OPTIONS} />;
export const RtcsFormattedTime: React.FC<RtcsFormattedDateTimeProps> = (
  props
) => <FormattedTime {...props} {...FORMAT_TIME_OPTIONS} />;
export const RtcsFormattedDateTime: React.FC<{
  value: string | number | Date | undefined;
}> = (props) => (
  <>
    <RtcsFormattedDate {...props} />
    &nbsp;
    <RtcsFormattedTime {...props} />
  </>
);
export const FORMAT_NUMBER_OPTIONS: FormatNumberOptions = {
  style: "currency",
  currency: "USH",
  maximumFractionDigits: 0,
};

export type RtcsFormattedNumberProps = FormatNumberOptions &
  CustomFormatConfig & {
    value: number | bigint;
  };
export const RtcsFormattedNumber: React.FC<RtcsFormattedNumberProps> = (
  props
) => <FormattedNumber {...props} {...FORMAT_NUMBER_OPTIONS} />;

export const RtcsFormattedNoteDate: React.FC<{
  value: string | number | Date | undefined;
}> = (props) => (
  <>
    <FormattedDateParts {...props} {...FORMAT_NOTE_DATE_OPTIONS}>
      {(parts) => (
        <>
          {`${parts[0].value} ${parts[2].value}, ${parts[4].value} at ${parts[6].value}:${parts[8].value} GMT`}
        </>
      )}
    </FormattedDateParts>
  </>
);

export const RtcsFormattedPercentage = (
  value: Maybe<string> | undefined
): string => (value ? `${Number(parseFloat(value).toFixed(2))} %` : "");

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const RtcsFormattedCurrency = (
  intl: IntlShape,
  currency: Maybe<string> | undefined
) => {
  if (currency === undefined || currency === null) return "";
  if (currency === "nan" || currency === "") return "";

  return intl.formatNumber(Number(currency), FORMAT_NUMBER_OPTIONS);
};

export const RtcsDefaultFormattedDate: React.FC<{
  value: string | number | Date | undefined;
}> = (props) => (
  <>
    <FormattedDateParts
      {...props}
      {...FORMAT_DATE_OPTIONS_DAY_MONTH_COMMA_YEAR}
    >
      {(parts) => (
        <>
          {`${parts[0].value} ${parts[2].value}, ${parts[4].value} at ${parts[6].value}:${parts[8].value} GMT`}
        </>
      )}
    </FormattedDateParts>
  </>
);

export const RtcsFormattedDefaultDate = (
  intl: IntlShape,
  value: string
): string | undefined => {
  if (!value || value === "nan" || typeof value === undefined) return "";

  const dateFormatParts = intl.formatDateToParts(
    value,
    FORMAT_DATE_OPTIONS_DAY_MONTH_COMMA_YEAR
  );

  return `${dateFormatParts[0].value} ${dateFormatParts[2].value}, ${dateFormatParts[4].value}`;
};

export const AudionautCapitalizedWord = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};
