import React from "react";
import { Box, Snackbar } from "@material-ui/core";
import { Alert, AlertProps } from "components/alert/alert.component";

import { Redirect, useParams } from "react-router-dom";

import auth from "graphql/authentication";
import AuthContainer from "components/auth-container/auth-container.component";

import { useValidateEmailVerificationCodeMutation } from "graphql/types-and-hooks";
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";

import SC from "./email-verification.styles";

const EmailVerification: React.FC = () => {
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [emailVerified, setEmailVerified] = React.useState(true);
  const [redirect, setRedirect] = React.useState<boolean>(false);

  const currentParams: { code: string } = useParams();

  const planPreselect = React.useMemo(() => {
    return (
      !!localStorage.getItem("selectedPlan") &&
      localStorage.getItem("selectedPlan") !== "chatFree"
    );
  }, []);

  const [
    verifyEmail,
    {
      data: emailVerification,
      loading: verificationLoading,
      error: verificationError,
    },
  ] = useValidateEmailVerificationCodeMutation({
    context: {
      noAuthNeeded: true,
    },
  });

  // const errorHandler = useAppErrorHandler();
  const loading = verificationLoading;

  React.useEffect(() => {
    const { code: token } = currentParams;
    if (token) {
      try {
        verifyEmail({
          variables: {
            code: token,
          },
        });
      } catch (error) {
        // console.debug("ERRRO: ", error);
        setEmailVerified(false);
        // errorHandler(
        //   new UIError(
        //     UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
        //     "An error has ocurred while requesting password recovery."
        //   )
        // );
        setSnackBarMessage({
          message: "An error has ocurred while requesting password recovery",
          severity: "error",
        });
      }
    }
  }, [verifyEmail, currentParams]);

  React.useEffect(() => {
    if (emailVerification) {
      setEmailVerified(emailVerification.ValidateEmailVerificationCode);
      // console.debug("====> email verification: ", emailVerification);

      if (emailVerification.ValidateEmailVerificationCode) {
        // console.debug(
        //   "====> Your email already verified, you will be redirect to the login page!"
        // );
        const { code: token } = currentParams;
        auth.logIn(token, true);

        setSnackBarMessage({
          message:
            "Your email already verified, you will be redirect to the login page!",
          severity: "success",
        });

        setTimeout(() => {
          // console.debug("=====> Redirecting to the login page");
          setRedirect(true);
          // window.location.href = "/";
        }, 10000);
      }
    }
  }, [emailVerification, currentParams, planPreselect]);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  return (
    <>
      <AuthContainer
        loading={loading}
        title={emailVerified ? "Verify your email" : "Expired link"}
        content={
          <Box className="content">
            <SC.Text>
              {emailVerified
                ? "Verifying account email ..."
                : "Account activation link has expired, please reach out your administrator to resend you the email."}
            </SC.Text>
            <SC.Footer hidden={emailVerified}>
              Go to
              <SC.Button
                type="submit"
                variant="outlined"
                onClick={() => {
                  setRedirect(true);
                }}
              >
                Log in
              </SC.Button>
            </SC.Footer>
          </Box>
        }
      />
      {redirect ? <Redirect to={planPreselect ? "/" : "/login"} /> : null}
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
    </>
  );
};

export default EmailVerification;
