import React from "react";

import { Box, Grid, Paper, Typography } from "@material-ui/core";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";
import { MultimediaMetadataItemInterface } from "components/multimedia-metadata-item/multimedia-metadata-item.utils";
import MultimediaMetadataItem from "components/multimedia-metadata-item/multimedia-metadata-item.component";

import SC from "./medias-catalog.styles";

const tempSlides = [
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
  {
    title: "Current Videos",
    total: 400,
  },
];

const testMedias: MultimediaMetadataItemInterface[] = [
  {
    author: "Google Cloud Tech",
    created: "2023-08-01T19:40:22",
    description:
      "Kickstart your Kubernetes journey → https://goo.gle/3nHQSzX \n\nWelcome to the first episode of Kubernetes Essentials from Google Cloud! In this series, we’ll cover anything having to do with Kubernetes. In this first episode, we speak to what Kubernetes is, and how you can use it to resolve issues with monolithic applications. Watch to learn the basics of Kubernetes and how to integrate it into your own developer projects!\n\nChapters: \n0:00 - Intro \n0:17 - So what is Kubernetes\n1:15 - What problems does it solve?\n3:34 - When should people start using it?\n4:08 - Recap\n\nWatch more episodes of Kubernetes Essentials from Google Cloud → http://goo.gle/KubernetesEssentialsFromGoogleCloud \nSubscribe to Google Cloud Platform→ https://goo.gle/GCP  \n\nproduct: Kubernetes;  fullname: Carter Morgan, Kaslin Fields;\n\n#KubernetesEssentialsfromGoogleCloud\n\nDisclaimer: This is a Google Cloud series and is not affiliated with the Linux Foundation.",
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "What is Kubernetes?",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=cC46cg5FFAM",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Julien Miquel",
    created: "2023-04-27T16:22:21",
    description:
      'Learn more English word pronunciations: https://www.youtube.com/watch?v=dyncGi5eWz0&list=PLd_ydU7Boqa2TWjHeVDMd_w6b4bDlwA2- \nListen how to say Kubernetes correctly (English vocabulary) with Julien, "how do you pronounce" free pronunciation audio/video tutorials.\n\nWhat does Kubernetes mean? Word meaning, dictionary definition, explanation, information.\nKubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management. It was originally designed by Google and is now maintained by the Cloud Native Computing Foundation. \nSource, learn more about this word on: https://en.wikipedia.org/wiki/Kubernetes\n\nLearn how to say words in English, French, Spanish, German, Italian, and many other languages with Julien Miquel and his pronunciation tutorials! In the world of words and the diversity of accents and local dialects, some words can be extremely hard to pronounce.\n\nJulien’s instructional and educational videos make pronunciation easier as I detail the correct pronunciation as native French speaker but also fluent speaker of French, English, Spanish and Italian.\n-If you found this video helpful please like the video to support my work.\n-If you would like help with any future pronunciations please be sure to subscribe!\n-Thanks for Watching How To Pronounce with Julien and happy pronouncing.\n\n#EnglishWithJulien',
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "How to Pronounce Kubernetes? (CORRECTLY) Meaning & Pronunciation",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=vJQH4QsGtFU",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Google Cloud Tech",
    created: "2023-08-01T19:40:22",
    description:
      "Kickstart your Kubernetes journey → https://goo.gle/3nHQSzX \n\nWelcome to the first episode of Kubernetes Essentials from Google Cloud! In this series, we’ll cover anything having to do with Kubernetes. In this first episode, we speak to what Kubernetes is, and how you can use it to resolve issues with monolithic applications. Watch to learn the basics of Kubernetes and how to integrate it into your own developer projects!\n\nChapters: \n0:00 - Intro \n0:17 - So what is Kubernetes\n1:15 - What problems does it solve?\n3:34 - When should people start using it?\n4:08 - Recap\n\nWatch more episodes of Kubernetes Essentials from Google Cloud → http://goo.gle/KubernetesEssentialsFromGoogleCloud \nSubscribe to Google Cloud Platform→ https://goo.gle/GCP  \n\nproduct: Kubernetes;  fullname: Carter Morgan, Kaslin Fields;\n\n#KubernetesEssentialsfromGoogleCloud\n\nDisclaimer: This is a Google Cloud series and is not affiliated with the Linux Foundation.",
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "What is Kubernetes?",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=cC46cg5FFAM",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Julien Miquel",
    created: "2023-04-27T16:22:21",
    description:
      'Learn more English word pronunciations: https://www.youtube.com/watch?v=dyncGi5eWz0&list=PLd_ydU7Boqa2TWjHeVDMd_w6b4bDlwA2- \nListen how to say Kubernetes correctly (English vocabulary) with Julien, "how do you pronounce" free pronunciation audio/video tutorials.\n\nWhat does Kubernetes mean? Word meaning, dictionary definition, explanation, information.\nKubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management. It was originally designed by Google and is now maintained by the Cloud Native Computing Foundation. \nSource, learn more about this word on: https://en.wikipedia.org/wiki/Kubernetes\n\nLearn how to say words in English, French, Spanish, German, Italian, and many other languages with Julien Miquel and his pronunciation tutorials! In the world of words and the diversity of accents and local dialects, some words can be extremely hard to pronounce.\n\nJulien’s instructional and educational videos make pronunciation easier as I detail the correct pronunciation as native French speaker but also fluent speaker of French, English, Spanish and Italian.\n-If you found this video helpful please like the video to support my work.\n-If you would like help with any future pronunciations please be sure to subscribe!\n-Thanks for Watching How To Pronounce with Julien and happy pronouncing.\n\n#EnglishWithJulien',
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "How to Pronounce Kubernetes? (CORRECTLY) Meaning & Pronunciation",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=vJQH4QsGtFU",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Google Cloud Tech",
    created: "2023-08-01T19:40:22",
    description:
      "Kickstart your Kubernetes journey → https://goo.gle/3nHQSzX \n\nWelcome to the first episode of Kubernetes Essentials from Google Cloud! In this series, we’ll cover anything having to do with Kubernetes. In this first episode, we speak to what Kubernetes is, and how you can use it to resolve issues with monolithic applications. Watch to learn the basics of Kubernetes and how to integrate it into your own developer projects!\n\nChapters: \n0:00 - Intro \n0:17 - So what is Kubernetes\n1:15 - What problems does it solve?\n3:34 - When should people start using it?\n4:08 - Recap\n\nWatch more episodes of Kubernetes Essentials from Google Cloud → http://goo.gle/KubernetesEssentialsFromGoogleCloud \nSubscribe to Google Cloud Platform→ https://goo.gle/GCP  \n\nproduct: Kubernetes;  fullname: Carter Morgan, Kaslin Fields;\n\n#KubernetesEssentialsfromGoogleCloud\n\nDisclaimer: This is a Google Cloud series and is not affiliated with the Linux Foundation.",
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "What is Kubernetes?",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=cC46cg5FFAM",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Julien Miquel",
    created: "2023-04-27T16:22:21",
    description:
      'Learn more English word pronunciations: https://www.youtube.com/watch?v=dyncGi5eWz0&list=PLd_ydU7Boqa2TWjHeVDMd_w6b4bDlwA2- \nListen how to say Kubernetes correctly (English vocabulary) with Julien, "how do you pronounce" free pronunciation audio/video tutorials.\n\nWhat does Kubernetes mean? Word meaning, dictionary definition, explanation, information.\nKubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management. It was originally designed by Google and is now maintained by the Cloud Native Computing Foundation. \nSource, learn more about this word on: https://en.wikipedia.org/wiki/Kubernetes\n\nLearn how to say words in English, French, Spanish, German, Italian, and many other languages with Julien Miquel and his pronunciation tutorials! In the world of words and the diversity of accents and local dialects, some words can be extremely hard to pronounce.\n\nJulien’s instructional and educational videos make pronunciation easier as I detail the correct pronunciation as native French speaker but also fluent speaker of French, English, Spanish and Italian.\n-If you found this video helpful please like the video to support my work.\n-If you would like help with any future pronunciations please be sure to subscribe!\n-Thanks for Watching How To Pronounce with Julien and happy pronouncing.\n\n#EnglishWithJulien',
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "How to Pronounce Kubernetes? (CORRECTLY) Meaning & Pronunciation",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=vJQH4QsGtFU",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Google Cloud Tech",
    created: "2023-08-01T19:40:22",
    description:
      "Kickstart your Kubernetes journey → https://goo.gle/3nHQSzX \n\nWelcome to the first episode of Kubernetes Essentials from Google Cloud! In this series, we’ll cover anything having to do with Kubernetes. In this first episode, we speak to what Kubernetes is, and how you can use it to resolve issues with monolithic applications. Watch to learn the basics of Kubernetes and how to integrate it into your own developer projects!\n\nChapters: \n0:00 - Intro \n0:17 - So what is Kubernetes\n1:15 - What problems does it solve?\n3:34 - When should people start using it?\n4:08 - Recap\n\nWatch more episodes of Kubernetes Essentials from Google Cloud → http://goo.gle/KubernetesEssentialsFromGoogleCloud \nSubscribe to Google Cloud Platform→ https://goo.gle/GCP  \n\nproduct: Kubernetes;  fullname: Carter Morgan, Kaslin Fields;\n\n#KubernetesEssentialsfromGoogleCloud\n\nDisclaimer: This is a Google Cloud series and is not affiliated with the Linux Foundation.",
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "What is Kubernetes?",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=cC46cg5FFAM",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Julien Miquel",
    created: "2023-04-27T16:22:21",
    description:
      'Learn more English word pronunciations: https://www.youtube.com/watch?v=dyncGi5eWz0&list=PLd_ydU7Boqa2TWjHeVDMd_w6b4bDlwA2- \nListen how to say Kubernetes correctly (English vocabulary) with Julien, "how do you pronounce" free pronunciation audio/video tutorials.\n\nWhat does Kubernetes mean? Word meaning, dictionary definition, explanation, information.\nKubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management. It was originally designed by Google and is now maintained by the Cloud Native Computing Foundation. \nSource, learn more about this word on: https://en.wikipedia.org/wiki/Kubernetes\n\nLearn how to say words in English, French, Spanish, German, Italian, and many other languages with Julien Miquel and his pronunciation tutorials! In the world of words and the diversity of accents and local dialects, some words can be extremely hard to pronounce.\n\nJulien’s instructional and educational videos make pronunciation easier as I detail the correct pronunciation as native French speaker but also fluent speaker of French, English, Spanish and Italian.\n-If you found this video helpful please like the video to support my work.\n-If you would like help with any future pronunciations please be sure to subscribe!\n-Thanks for Watching How To Pronounce with Julien and happy pronouncing.\n\n#EnglishWithJulien',
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "How to Pronounce Kubernetes? (CORRECTLY) Meaning & Pronunciation",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=vJQH4QsGtFU",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Google Cloud Tech",
    created: "2023-08-01T19:40:22",
    description:
      "Kickstart your Kubernetes journey → https://goo.gle/3nHQSzX \n\nWelcome to the first episode of Kubernetes Essentials from Google Cloud! In this series, we’ll cover anything having to do with Kubernetes. In this first episode, we speak to what Kubernetes is, and how you can use it to resolve issues with monolithic applications. Watch to learn the basics of Kubernetes and how to integrate it into your own developer projects!\n\nChapters: \n0:00 - Intro \n0:17 - So what is Kubernetes\n1:15 - What problems does it solve?\n3:34 - When should people start using it?\n4:08 - Recap\n\nWatch more episodes of Kubernetes Essentials from Google Cloud → http://goo.gle/KubernetesEssentialsFromGoogleCloud \nSubscribe to Google Cloud Platform→ https://goo.gle/GCP  \n\nproduct: Kubernetes;  fullname: Carter Morgan, Kaslin Fields;\n\n#KubernetesEssentialsfromGoogleCloud\n\nDisclaimer: This is a Google Cloud series and is not affiliated with the Linux Foundation.",
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "What is Kubernetes?",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=cC46cg5FFAM",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Julien Miquel",
    created: "2023-04-27T16:22:21",
    description:
      'Learn more English word pronunciations: https://www.youtube.com/watch?v=dyncGi5eWz0&list=PLd_ydU7Boqa2TWjHeVDMd_w6b4bDlwA2- \nListen how to say Kubernetes correctly (English vocabulary) with Julien, "how do you pronounce" free pronunciation audio/video tutorials.\n\nWhat does Kubernetes mean? Word meaning, dictionary definition, explanation, information.\nKubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management. It was originally designed by Google and is now maintained by the Cloud Native Computing Foundation. \nSource, learn more about this word on: https://en.wikipedia.org/wiki/Kubernetes\n\nLearn how to say words in English, French, Spanish, German, Italian, and many other languages with Julien Miquel and his pronunciation tutorials! In the world of words and the diversity of accents and local dialects, some words can be extremely hard to pronounce.\n\nJulien’s instructional and educational videos make pronunciation easier as I detail the correct pronunciation as native French speaker but also fluent speaker of French, English, Spanish and Italian.\n-If you found this video helpful please like the video to support my work.\n-If you would like help with any future pronunciations please be sure to subscribe!\n-Thanks for Watching How To Pronounce with Julien and happy pronouncing.\n\n#EnglishWithJulien',
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "How to Pronounce Kubernetes? (CORRECTLY) Meaning & Pronunciation",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=vJQH4QsGtFU",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Google Cloud Tech",
    created: "2023-08-01T19:40:22",
    description:
      "Kickstart your Kubernetes journey → https://goo.gle/3nHQSzX \n\nWelcome to the first episode of Kubernetes Essentials from Google Cloud! In this series, we’ll cover anything having to do with Kubernetes. In this first episode, we speak to what Kubernetes is, and how you can use it to resolve issues with monolithic applications. Watch to learn the basics of Kubernetes and how to integrate it into your own developer projects!\n\nChapters: \n0:00 - Intro \n0:17 - So what is Kubernetes\n1:15 - What problems does it solve?\n3:34 - When should people start using it?\n4:08 - Recap\n\nWatch more episodes of Kubernetes Essentials from Google Cloud → http://goo.gle/KubernetesEssentialsFromGoogleCloud \nSubscribe to Google Cloud Platform→ https://goo.gle/GCP  \n\nproduct: Kubernetes;  fullname: Carter Morgan, Kaslin Fields;\n\n#KubernetesEssentialsfromGoogleCloud\n\nDisclaimer: This is a Google Cloud series and is not affiliated with the Linux Foundation.",
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "What is Kubernetes?",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=cC46cg5FFAM",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
  {
    author: "Julien Miquel",
    created: "2023-04-27T16:22:21",
    description:
      'Learn more English word pronunciations: https://www.youtube.com/watch?v=dyncGi5eWz0&list=PLd_ydU7Boqa2TWjHeVDMd_w6b4bDlwA2- \nListen how to say Kubernetes correctly (English vocabulary) with Julien, "how do you pronounce" free pronunciation audio/video tutorials.\n\nWhat does Kubernetes mean? Word meaning, dictionary definition, explanation, information.\nKubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management. It was originally designed by Google and is now maintained by the Cloud Native Computing Foundation. \nSource, learn more about this word on: https://en.wikipedia.org/wiki/Kubernetes\n\nLearn how to say words in English, French, Spanish, German, Italian, and many other languages with Julien Miquel and his pronunciation tutorials! In the world of words and the diversity of accents and local dialects, some words can be extremely hard to pronounce.\n\nJulien’s instructional and educational videos make pronunciation easier as I detail the correct pronunciation as native French speaker but also fluent speaker of French, English, Spanish and Italian.\n-If you found this video helpful please like the video to support my work.\n-If you would like help with any future pronunciations please be sure to subscribe!\n-Thanks for Watching How To Pronounce with Julien and happy pronouncing.\n\n#EnglishWithJulien',
    isVideo: false,
    showTitleCard: false,
    source: "YOUTUBE",
    title: "How to Pronounce Kubernetes? (CORRECTLY) Meaning & Pronunciation",
    type: "catalog",
    endTime: 0,
    startTime: 0,
    thumbnail: "https://www.youtube.com/watch?v=vJQH4QsGtFU",
    webUrl: "https://www.youtube.com/watch?v=cC46cg5FFAM",
  },
];

const MediasCatalog: React.FC = () => {
  document.body.style.backgroundColor = "transparent";
  return (
    <SC.CatalogContainer component="section">
      <SC.TopicsCarousel type="catalog" items={tempSlides} />
      <SC.GridContainer container spacing={2}>
        {testMedias.map((media) => (
          <SC.GridItem item xs={12} md={6} lg={4}>
            <MultimediaMetadataItem metadata={media} />
          </SC.GridItem>
        ))}
      </SC.GridContainer>
      <SC.MoreVideosContainer>
        <SC.MoreVideosButton>Load More</SC.MoreVideosButton>
      </SC.MoreVideosContainer>
    </SC.CatalogContainer>
  );
};

export default MediasCatalog;
