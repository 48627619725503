/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  timestamp: any;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  _nlike?: Maybe<Scalars["String"]>;
  _nsimilar?: Maybe<Scalars["String"]>;
  _similar?: Maybe<Scalars["String"]>;
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars["float8"]>;
  _gt?: Maybe<Scalars["float8"]>;
  _gte?: Maybe<Scalars["float8"]>;
  _in?: Maybe<Array<Scalars["float8"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["float8"]>;
  _lte?: Maybe<Scalars["float8"]>;
  _neq?: Maybe<Scalars["float8"]>;
  _nin?: Maybe<Array<Scalars["float8"]>>;
};

/** columns and relationships of "kcca.AssociateType" */
export type Kcca_AssociateType = {
  __typename?: "kcca_AssociateType";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.AssociateType" */
export type Kcca_AssociateType_Aggregate = {
  __typename?: "kcca_AssociateType_aggregate";
  aggregate?: Maybe<Kcca_AssociateType_Aggregate_Fields>;
  nodes: Array<Kcca_AssociateType>;
};

/** aggregate fields of "kcca.AssociateType" */
export type Kcca_AssociateType_Aggregate_Fields = {
  __typename?: "kcca_AssociateType_aggregate_fields";
  avg?: Maybe<Kcca_AssociateType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_AssociateType_Max_Fields>;
  min?: Maybe<Kcca_AssociateType_Min_Fields>;
  stddev?: Maybe<Kcca_AssociateType_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_AssociateType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_AssociateType_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_AssociateType_Sum_Fields>;
  var_pop?: Maybe<Kcca_AssociateType_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_AssociateType_Var_Samp_Fields>;
  variance?: Maybe<Kcca_AssociateType_Variance_Fields>;
};

/** aggregate fields of "kcca.AssociateType" */
export type Kcca_AssociateType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_AssociateType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.AssociateType" */
export type Kcca_AssociateType_Aggregate_Order_By = {
  avg?: Maybe<Kcca_AssociateType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_AssociateType_Max_Order_By>;
  min?: Maybe<Kcca_AssociateType_Min_Order_By>;
  stddev?: Maybe<Kcca_AssociateType_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_AssociateType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_AssociateType_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_AssociateType_Sum_Order_By>;
  var_pop?: Maybe<Kcca_AssociateType_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_AssociateType_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_AssociateType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.AssociateType" */
export type Kcca_AssociateType_Arr_Rel_Insert_Input = {
  data: Array<Kcca_AssociateType_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_AssociateType_Avg_Fields = {
  __typename?: "kcca_AssociateType_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Avg_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.AssociateType". All fields are combined with a logical 'AND'. */
export type Kcca_AssociateType_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_AssociateType_Bool_Exp>>>;
  _not?: Maybe<Kcca_AssociateType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_AssociateType_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.AssociateType" */
export type Kcca_AssociateType_Inc_Input = {
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.AssociateType" */
export type Kcca_AssociateType_Insert_Input = {
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_AssociateType_Max_Fields = {
  __typename?: "kcca_AssociateType_max_fields";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Max_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_AssociateType_Min_Fields = {
  __typename?: "kcca_AssociateType_min_fields";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Min_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.AssociateType" */
export type Kcca_AssociateType_Mutation_Response = {
  __typename?: "kcca_AssociateType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_AssociateType>;
};

/** input type for inserting object relation for remote table "kcca.AssociateType" */
export type Kcca_AssociateType_Obj_Rel_Insert_Input = {
  data: Kcca_AssociateType_Insert_Input;
};

/** ordering options when selecting data from "kcca.AssociateType" */
export type Kcca_AssociateType_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.AssociateType" */
export enum Kcca_AssociateType_Select_Column {
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.AssociateType" */
export type Kcca_AssociateType_Set_Input = {
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_AssociateType_Stddev_Fields = {
  __typename?: "kcca_AssociateType_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_AssociateType_Stddev_Pop_Fields = {
  __typename?: "kcca_AssociateType_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_AssociateType_Stddev_Samp_Fields = {
  __typename?: "kcca_AssociateType_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_AssociateType_Sum_Fields = {
  __typename?: "kcca_AssociateType_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Sum_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_AssociateType_Var_Pop_Fields = {
  __typename?: "kcca_AssociateType_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_AssociateType_Var_Samp_Fields = {
  __typename?: "kcca_AssociateType_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_AssociateType_Variance_Fields = {
  __typename?: "kcca_AssociateType_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.AssociateType" */
export type Kcca_AssociateType_Variance_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.County" */
export type Kcca_County = {
  __typename?: "kcca_County";
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.County" */
export type Kcca_County_Aggregate = {
  __typename?: "kcca_County_aggregate";
  aggregate?: Maybe<Kcca_County_Aggregate_Fields>;
  nodes: Array<Kcca_County>;
};

/** aggregate fields of "kcca.County" */
export type Kcca_County_Aggregate_Fields = {
  __typename?: "kcca_County_aggregate_fields";
  avg?: Maybe<Kcca_County_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_County_Max_Fields>;
  min?: Maybe<Kcca_County_Min_Fields>;
  stddev?: Maybe<Kcca_County_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_County_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_County_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_County_Sum_Fields>;
  var_pop?: Maybe<Kcca_County_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_County_Var_Samp_Fields>;
  variance?: Maybe<Kcca_County_Variance_Fields>;
};

/** aggregate fields of "kcca.County" */
export type Kcca_County_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_County_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.County" */
export type Kcca_County_Aggregate_Order_By = {
  avg?: Maybe<Kcca_County_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_County_Max_Order_By>;
  min?: Maybe<Kcca_County_Min_Order_By>;
  stddev?: Maybe<Kcca_County_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_County_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_County_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_County_Sum_Order_By>;
  var_pop?: Maybe<Kcca_County_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_County_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_County_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.County" */
export type Kcca_County_Arr_Rel_Insert_Input = {
  data: Array<Kcca_County_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_County_Avg_Fields = {
  __typename?: "kcca_County_avg_fields";
  country_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.County" */
export type Kcca_County_Avg_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.County". All fields are combined with a logical 'AND'. */
export type Kcca_County_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_County_Bool_Exp>>>;
  _not?: Maybe<Kcca_County_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_County_Bool_Exp>>>;
  country_id?: Maybe<Bigint_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  district_id?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.County" */
export type Kcca_County_Inc_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.County" */
export type Kcca_County_Insert_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_County_Max_Fields = {
  __typename?: "kcca_County_max_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.County" */
export type Kcca_County_Max_Order_By = {
  country_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_County_Min_Fields = {
  __typename?: "kcca_County_min_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.County" */
export type Kcca_County_Min_Order_By = {
  country_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.County" */
export type Kcca_County_Mutation_Response = {
  __typename?: "kcca_County_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_County>;
};

/** input type for inserting object relation for remote table "kcca.County" */
export type Kcca_County_Obj_Rel_Insert_Input = {
  data: Kcca_County_Insert_Input;
};

/** ordering options when selecting data from "kcca.County" */
export type Kcca_County_Order_By = {
  country_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.County" */
export enum Kcca_County_Select_Column {
  /** column name */
  CountryId = "country_id",
  /** column name */
  Description = "description",
  /** column name */
  DistrictId = "district_id",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.County" */
export type Kcca_County_Set_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_County_Stddev_Fields = {
  __typename?: "kcca_County_stddev_fields";
  country_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.County" */
export type Kcca_County_Stddev_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_County_Stddev_Pop_Fields = {
  __typename?: "kcca_County_stddev_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.County" */
export type Kcca_County_Stddev_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_County_Stddev_Samp_Fields = {
  __typename?: "kcca_County_stddev_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.County" */
export type Kcca_County_Stddev_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_County_Sum_Fields = {
  __typename?: "kcca_County_sum_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.County" */
export type Kcca_County_Sum_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_County_Var_Pop_Fields = {
  __typename?: "kcca_County_var_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.County" */
export type Kcca_County_Var_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_County_Var_Samp_Fields = {
  __typename?: "kcca_County_var_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.County" */
export type Kcca_County_Var_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_County_Variance_Fields = {
  __typename?: "kcca_County_variance_fields";
  country_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.County" */
export type Kcca_County_Variance_Order_By = {
  country_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.Customer" */
export type Kcca_Customer = {
  __typename?: "kcca_Customer";
  Visible_Level?: Maybe<Scalars["float8"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["float8"]>;
  birthdate?: Maybe<Scalars["date"]>;
  box_district_id?: Maybe<Scalars["float8"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["float8"]>;
  customer_business_type_id?: Maybe<Scalars["float8"]>;
  customer_type_id?: Maybe<Scalars["bigint"]>;
  date_of_incorporation?: Maybe<Scalars["date"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["float8"]>;
  have_alternate_primary_contact?: Maybe<Scalars["float8"]>;
  have_reference?: Maybe<Scalars["float8"]>;
  have_revenue_agent?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_minor?: Maybe<Scalars["float8"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_district_id?: Maybe<Scalars["float8"]>;
  previous_business_address_parish_id?: Maybe<Scalars["float8"]>;
  previous_business_address_plot_number?: Maybe<Scalars["float8"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["float8"]>;
  previous_business_name?: Maybe<Scalars["float8"]>;
  residential_address_county_id?: Maybe<Scalars["float8"]>;
  residential_address_district_id?: Maybe<Scalars["float8"]>;
  residential_address_parish_id?: Maybe<Scalars["float8"]>;
  residential_address_sub_county_id?: Maybe<Scalars["float8"]>;
  residential_address_village_id?: Maybe<Scalars["float8"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType = {
  __typename?: "kcca_CustomerApplicantType";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Aggregate = {
  __typename?: "kcca_CustomerApplicantType_aggregate";
  aggregate?: Maybe<Kcca_CustomerApplicantType_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerApplicantType>;
};

/** aggregate fields of "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Aggregate_Fields = {
  __typename?: "kcca_CustomerApplicantType_aggregate_fields";
  avg?: Maybe<Kcca_CustomerApplicantType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerApplicantType_Max_Fields>;
  min?: Maybe<Kcca_CustomerApplicantType_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerApplicantType_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerApplicantType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerApplicantType_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerApplicantType_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerApplicantType_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerApplicantType_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerApplicantType_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerApplicantType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerApplicantType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerApplicantType_Max_Order_By>;
  min?: Maybe<Kcca_CustomerApplicantType_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerApplicantType_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerApplicantType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerApplicantType_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerApplicantType_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerApplicantType_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerApplicantType_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerApplicantType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerApplicantType_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerApplicantType_Avg_Fields = {
  __typename?: "kcca_CustomerApplicantType_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Avg_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerApplicantType". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerApplicantType_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerApplicantType_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerApplicantType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerApplicantType_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Inc_Input = {
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Insert_Input = {
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerApplicantType_Max_Fields = {
  __typename?: "kcca_CustomerApplicantType_max_fields";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Max_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerApplicantType_Min_Fields = {
  __typename?: "kcca_CustomerApplicantType_min_fields";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Min_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Mutation_Response = {
  __typename?: "kcca_CustomerApplicantType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerApplicantType>;
};

/** input type for inserting object relation for remote table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerApplicantType_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerApplicantType" */
export enum Kcca_CustomerApplicantType_Select_Column {
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Set_Input = {
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerApplicantType_Stddev_Fields = {
  __typename?: "kcca_CustomerApplicantType_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerApplicantType_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerApplicantType_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerApplicantType_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerApplicantType_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerApplicantType_Sum_Fields = {
  __typename?: "kcca_CustomerApplicantType_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Sum_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerApplicantType_Var_Pop_Fields = {
  __typename?: "kcca_CustomerApplicantType_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerApplicantType_Var_Samp_Fields = {
  __typename?: "kcca_CustomerApplicantType_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerApplicantType_Variance_Fields = {
  __typename?: "kcca_CustomerApplicantType_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerApplicantType" */
export type Kcca_CustomerApplicantType_Variance_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate = {
  __typename?: "kcca_CustomerBusinessAssociate";
  acquired_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  effect_date?: Maybe<Scalars["timestamp"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_number?: Maybe<Scalars["String"]>;
  identification_type_id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  official_designation?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Aggregate = {
  __typename?: "kcca_CustomerBusinessAssociate_aggregate";
  aggregate?: Maybe<Kcca_CustomerBusinessAssociate_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerBusinessAssociate>;
};

/** aggregate fields of "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Aggregate_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_aggregate_fields";
  avg?: Maybe<Kcca_CustomerBusinessAssociate_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerBusinessAssociate_Max_Fields>;
  min?: Maybe<Kcca_CustomerBusinessAssociate_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerBusinessAssociate_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerBusinessAssociate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerBusinessAssociate_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerBusinessAssociate_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerBusinessAssociate_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerBusinessAssociate_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerBusinessAssociate_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerBusinessAssociate_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerBusinessAssociate_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerBusinessAssociate_Max_Order_By>;
  min?: Maybe<Kcca_CustomerBusinessAssociate_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerBusinessAssociate_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerBusinessAssociate_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerBusinessAssociate_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerBusinessAssociate_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerBusinessAssociate_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerBusinessAssociate_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerBusinessAssociate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerBusinessAssociate_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerBusinessAssociate_Avg_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  identification_type_id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerBusinessAssociate". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerBusinessAssociate_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerBusinessAssociate_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerBusinessAssociate_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerBusinessAssociate_Bool_Exp>>>;
  acquired_name?: Maybe<String_Comparison_Exp>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  effect_date?: Maybe<Timestamp_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  identification_number?: Maybe<String_Comparison_Exp>;
  identification_type_id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  mother_maiden_name?: Maybe<String_Comparison_Exp>;
  official_designation?: Maybe<String_Comparison_Exp>;
  other_title?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  title_id?: Maybe<Float8_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Inc_Input = {
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_type_id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  title_id?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Insert_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  effect_date?: Maybe<Scalars["timestamp"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_number?: Maybe<Scalars["String"]>;
  identification_type_id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  official_designation?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerBusinessAssociate_Max_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_max_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  effect_date?: Maybe<Scalars["timestamp"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_number?: Maybe<Scalars["String"]>;
  identification_type_id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  official_designation?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Max_Order_By = {
  acquired_name?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  effect_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  official_designation?: Maybe<Order_By>;
  other_title?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerBusinessAssociate_Min_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_min_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  effect_date?: Maybe<Scalars["timestamp"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_number?: Maybe<Scalars["String"]>;
  identification_type_id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  official_designation?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Min_Order_By = {
  acquired_name?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  effect_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  official_designation?: Maybe<Order_By>;
  other_title?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Mutation_Response = {
  __typename?: "kcca_CustomerBusinessAssociate_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerBusinessAssociate>;
};

/** input type for inserting object relation for remote table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerBusinessAssociate_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Order_By = {
  acquired_name?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  effect_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  official_designation?: Maybe<Order_By>;
  other_title?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerBusinessAssociate" */
export enum Kcca_CustomerBusinessAssociate_Select_Column {
  /** column name */
  AcquiredName = "acquired_name",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  EffectDate = "effect_date",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  Id = "id",
  /** column name */
  IdentificationNumber = "identification_number",
  /** column name */
  IdentificationTypeId = "identification_type_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  MotherMaidenName = "mother_maiden_name",
  /** column name */
  OfficialDesignation = "official_designation",
  /** column name */
  OtherTitle = "other_title",
  /** column name */
  StatusId = "status_id",
  /** column name */
  Surname = "surname",
  /** column name */
  TitleId = "title_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Set_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  effect_date?: Maybe<Scalars["timestamp"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_number?: Maybe<Scalars["String"]>;
  identification_type_id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  official_designation?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerBusinessAssociate_Stddev_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  identification_type_id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerBusinessAssociate_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  identification_type_id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerBusinessAssociate_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  identification_type_id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerBusinessAssociate_Sum_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_sum_fields";
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_type_id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  title_id?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerBusinessAssociate_Var_Pop_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  identification_type_id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerBusinessAssociate_Var_Samp_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  identification_type_id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerBusinessAssociate_Variance_Fields = {
  __typename?: "kcca_CustomerBusinessAssociate_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  identification_type_id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerBusinessAssociate" */
export type Kcca_CustomerBusinessAssociate_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType = {
  __typename?: "kcca_CustomerBusinessType";
  customer_applicant_type_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Aggregate = {
  __typename?: "kcca_CustomerBusinessType_aggregate";
  aggregate?: Maybe<Kcca_CustomerBusinessType_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerBusinessType>;
};

/** aggregate fields of "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Aggregate_Fields = {
  __typename?: "kcca_CustomerBusinessType_aggregate_fields";
  avg?: Maybe<Kcca_CustomerBusinessType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerBusinessType_Max_Fields>;
  min?: Maybe<Kcca_CustomerBusinessType_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerBusinessType_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerBusinessType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerBusinessType_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerBusinessType_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerBusinessType_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerBusinessType_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerBusinessType_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerBusinessType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerBusinessType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerBusinessType_Max_Order_By>;
  min?: Maybe<Kcca_CustomerBusinessType_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerBusinessType_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerBusinessType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerBusinessType_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerBusinessType_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerBusinessType_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerBusinessType_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerBusinessType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerBusinessType_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerBusinessType_Avg_Fields = {
  __typename?: "kcca_CustomerBusinessType_avg_fields";
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Avg_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerBusinessType". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerBusinessType_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerBusinessType_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerBusinessType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerBusinessType_Bool_Exp>>>;
  customer_applicant_type_id?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Inc_Input = {
  customer_applicant_type_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Insert_Input = {
  customer_applicant_type_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerBusinessType_Max_Fields = {
  __typename?: "kcca_CustomerBusinessType_max_fields";
  customer_applicant_type_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Max_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerBusinessType_Min_Fields = {
  __typename?: "kcca_CustomerBusinessType_min_fields";
  customer_applicant_type_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Min_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Mutation_Response = {
  __typename?: "kcca_CustomerBusinessType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerBusinessType>;
};

/** input type for inserting object relation for remote table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerBusinessType_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerBusinessType" */
export enum Kcca_CustomerBusinessType_Select_Column {
  /** column name */
  CustomerApplicantTypeId = "customer_applicant_type_id",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Set_Input = {
  customer_applicant_type_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerBusinessType_Stddev_Fields = {
  __typename?: "kcca_CustomerBusinessType_stddev_fields";
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Stddev_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerBusinessType_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerBusinessType_stddev_pop_fields";
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Stddev_Pop_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerBusinessType_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerBusinessType_stddev_samp_fields";
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Stddev_Samp_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerBusinessType_Sum_Fields = {
  __typename?: "kcca_CustomerBusinessType_sum_fields";
  customer_applicant_type_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Sum_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerBusinessType_Var_Pop_Fields = {
  __typename?: "kcca_CustomerBusinessType_var_pop_fields";
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Var_Pop_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerBusinessType_Var_Samp_Fields = {
  __typename?: "kcca_CustomerBusinessType_var_samp_fields";
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Var_Samp_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerBusinessType_Variance_Fields = {
  __typename?: "kcca_CustomerBusinessType_variance_fields";
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerBusinessType" */
export type Kcca_CustomerBusinessType_Variance_Order_By = {
  customer_applicant_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive = {
  __typename?: "kcca_CustomerCoinDeactivateReactive";
  acknowledgement_id?: Maybe<Scalars["bigint"]>;
  application_reason?: Maybe<Scalars["String"]>;
  application_type?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  deactivate_reactivate?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_forwarding_contact?: Maybe<Scalars["bigint"]>;
  is_new_owner?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Aggregate = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_aggregate";
  aggregate?: Maybe<Kcca_CustomerCoinDeactivateReactive_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerCoinDeactivateReactive>;
};

/** aggregate fields of "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Aggregate_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_aggregate_fields";
  avg?: Maybe<Kcca_CustomerCoinDeactivateReactive_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerCoinDeactivateReactive_Max_Fields>;
  min?: Maybe<Kcca_CustomerCoinDeactivateReactive_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerCoinDeactivateReactive_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerCoinDeactivateReactive_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerCoinDeactivateReactive_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerCoinDeactivateReactive_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerCoinDeactivateReactive_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerCoinDeactivateReactive_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerCoinDeactivateReactive_Max_Order_By>;
  min?: Maybe<Kcca_CustomerCoinDeactivateReactive_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerCoinDeactivateReactive_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerCoinDeactivateReactive_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerCoinDeactivateReactive_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerCoinDeactivateReactive_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerCoinDeactivateReactive_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerCoinDeactivateReactive_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerCoinDeactivateReactive_Avg_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_avg_fields";
  acknowledgement_id?: Maybe<Scalars["Float"]>;
  application_type?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_forwarding_contact?: Maybe<Scalars["Float"]>;
  is_new_owner?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Avg_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerCoinDeactivateReactive". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerCoinDeactivateReactive_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerCoinDeactivateReactive_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerCoinDeactivateReactive_Bool_Exp>>>;
  acknowledgement_id?: Maybe<Bigint_Comparison_Exp>;
  application_reason?: Maybe<String_Comparison_Exp>;
  application_type?: Maybe<Bigint_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  deactivate_reactivate?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_forced_action?: Maybe<Float8_Comparison_Exp>;
  is_forwarding_contact?: Maybe<Bigint_Comparison_Exp>;
  is_new_owner?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Inc_Input = {
  acknowledgement_id?: Maybe<Scalars["bigint"]>;
  application_type?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_forwarding_contact?: Maybe<Scalars["bigint"]>;
  is_new_owner?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Insert_Input = {
  acknowledgement_id?: Maybe<Scalars["bigint"]>;
  application_reason?: Maybe<Scalars["String"]>;
  application_type?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  deactivate_reactivate?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_forwarding_contact?: Maybe<Scalars["bigint"]>;
  is_new_owner?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerCoinDeactivateReactive_Max_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_max_fields";
  acknowledgement_id?: Maybe<Scalars["bigint"]>;
  application_reason?: Maybe<Scalars["String"]>;
  application_type?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  deactivate_reactivate?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_forwarding_contact?: Maybe<Scalars["bigint"]>;
  is_new_owner?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Max_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_reason?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deactivate_reactivate?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerCoinDeactivateReactive_Min_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_min_fields";
  acknowledgement_id?: Maybe<Scalars["bigint"]>;
  application_reason?: Maybe<Scalars["String"]>;
  application_type?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  deactivate_reactivate?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_forwarding_contact?: Maybe<Scalars["bigint"]>;
  is_new_owner?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Min_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_reason?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deactivate_reactivate?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Mutation_Response = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerCoinDeactivateReactive>;
};

/** input type for inserting object relation for remote table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerCoinDeactivateReactive_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_reason?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  deactivate_reactivate?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerCoinDeactivateReactive" */
export enum Kcca_CustomerCoinDeactivateReactive_Select_Column {
  /** column name */
  AcknowledgementId = "acknowledgement_id",
  /** column name */
  ApplicationReason = "application_reason",
  /** column name */
  ApplicationType = "application_type",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  DeactivateReactivate = "deactivate_reactivate",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  IsForcedAction = "is_forced_action",
  /** column name */
  IsForwardingContact = "is_forwarding_contact",
  /** column name */
  IsNewOwner = "is_new_owner",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Set_Input = {
  acknowledgement_id?: Maybe<Scalars["bigint"]>;
  application_reason?: Maybe<Scalars["String"]>;
  application_type?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  deactivate_reactivate?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_forwarding_contact?: Maybe<Scalars["bigint"]>;
  is_new_owner?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerCoinDeactivateReactive_Stddev_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_stddev_fields";
  acknowledgement_id?: Maybe<Scalars["Float"]>;
  application_type?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_forwarding_contact?: Maybe<Scalars["Float"]>;
  is_new_owner?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Stddev_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_stddev_pop_fields";
  acknowledgement_id?: Maybe<Scalars["Float"]>;
  application_type?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_forwarding_contact?: Maybe<Scalars["Float"]>;
  is_new_owner?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_stddev_samp_fields";
  acknowledgement_id?: Maybe<Scalars["Float"]>;
  application_type?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_forwarding_contact?: Maybe<Scalars["Float"]>;
  is_new_owner?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerCoinDeactivateReactive_Sum_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_sum_fields";
  acknowledgement_id?: Maybe<Scalars["bigint"]>;
  application_type?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_forwarding_contact?: Maybe<Scalars["bigint"]>;
  is_new_owner?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Sum_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerCoinDeactivateReactive_Var_Pop_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_var_pop_fields";
  acknowledgement_id?: Maybe<Scalars["Float"]>;
  application_type?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_forwarding_contact?: Maybe<Scalars["Float"]>;
  is_new_owner?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Var_Pop_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerCoinDeactivateReactive_Var_Samp_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_var_samp_fields";
  acknowledgement_id?: Maybe<Scalars["Float"]>;
  application_type?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_forwarding_contact?: Maybe<Scalars["Float"]>;
  is_new_owner?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Var_Samp_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerCoinDeactivateReactive_Variance_Fields = {
  __typename?: "kcca_CustomerCoinDeactivateReactive_variance_fields";
  acknowledgement_id?: Maybe<Scalars["Float"]>;
  application_type?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_forwarding_contact?: Maybe<Scalars["Float"]>;
  is_new_owner?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerCoinDeactivateReactive" */
export type Kcca_CustomerCoinDeactivateReactive_Variance_Order_By = {
  acknowledgement_id?: Maybe<Order_By>;
  application_type?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_forwarding_contact?: Maybe<Order_By>;
  is_new_owner?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson = {
  __typename?: "kcca_CustomerContactPerson";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Aggregate = {
  __typename?: "kcca_CustomerContactPerson_aggregate";
  aggregate?: Maybe<Kcca_CustomerContactPerson_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerContactPerson>;
};

/** aggregate fields of "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Aggregate_Fields = {
  __typename?: "kcca_CustomerContactPerson_aggregate_fields";
  avg?: Maybe<Kcca_CustomerContactPerson_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerContactPerson_Max_Fields>;
  min?: Maybe<Kcca_CustomerContactPerson_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerContactPerson_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerContactPerson_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerContactPerson_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerContactPerson_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerContactPerson_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerContactPerson_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerContactPerson_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerContactPerson_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerContactPerson_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerContactPerson_Max_Order_By>;
  min?: Maybe<Kcca_CustomerContactPerson_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerContactPerson_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerContactPerson_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerContactPerson_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerContactPerson_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerContactPerson_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerContactPerson_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerContactPerson_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerContactPerson_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerContactPerson_Avg_Fields = {
  __typename?: "kcca_CustomerContactPerson_avg_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Avg_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerContactPerson". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerContactPerson_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerContactPerson_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerContactPerson_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerContactPerson_Bool_Exp>>>;
  acquired_name?: Maybe<String_Comparison_Exp>;
  coin?: Maybe<Float8_Comparison_Exp>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  mother_maiden_name?: Maybe<String_Comparison_Exp>;
  other_title?: Maybe<String_Comparison_Exp>;
  relationship_designation?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Float8_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  title_id?: Maybe<Float8_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Inc_Input = {
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
  title_id?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Insert_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerContactPerson_Max_Fields = {
  __typename?: "kcca_CustomerContactPerson_max_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Max_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  other_title?: Maybe<Order_By>;
  relationship_designation?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerContactPerson_Min_Fields = {
  __typename?: "kcca_CustomerContactPerson_min_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Min_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  other_title?: Maybe<Order_By>;
  relationship_designation?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Mutation_Response = {
  __typename?: "kcca_CustomerContactPerson_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerContactPerson>;
};

/** input type for inserting object relation for remote table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerContactPerson_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  other_title?: Maybe<Order_By>;
  relationship_designation?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerContactPerson" */
export enum Kcca_CustomerContactPerson_Select_Column {
  /** column name */
  AcquiredName = "acquired_name",
  /** column name */
  Coin = "coin",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  Email = "email",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  Mobile = "mobile",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  MotherMaidenName = "mother_maiden_name",
  /** column name */
  OtherTitle = "other_title",
  /** column name */
  RelationshipDesignation = "relationship_designation",
  /** column name */
  StatusId = "status_id",
  /** column name */
  Surname = "surname",
  /** column name */
  Telephone = "telephone",
  /** column name */
  TitleId = "title_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Set_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  other_title?: Maybe<Scalars["String"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerContactPerson_Stddev_Fields = {
  __typename?: "kcca_CustomerContactPerson_stddev_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Stddev_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerContactPerson_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerContactPerson_stddev_pop_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Stddev_Pop_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerContactPerson_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerContactPerson_stddev_samp_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Stddev_Samp_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerContactPerson_Sum_Fields = {
  __typename?: "kcca_CustomerContactPerson_sum_fields";
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
  title_id?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Sum_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerContactPerson_Var_Pop_Fields = {
  __typename?: "kcca_CustomerContactPerson_var_pop_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Var_Pop_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerContactPerson_Var_Samp_Fields = {
  __typename?: "kcca_CustomerContactPerson_var_samp_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Var_Samp_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerContactPerson_Variance_Fields = {
  __typename?: "kcca_CustomerContactPerson_variance_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerContactPerson" */
export type Kcca_CustomerContactPerson_Variance_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem = {
  __typename?: "kcca_CustomerDocumentItem";
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  document_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  movement_type?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Aggregate = {
  __typename?: "kcca_CustomerDocumentItem_aggregate";
  aggregate?: Maybe<Kcca_CustomerDocumentItem_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerDocumentItem>;
};

/** aggregate fields of "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Aggregate_Fields = {
  __typename?: "kcca_CustomerDocumentItem_aggregate_fields";
  avg?: Maybe<Kcca_CustomerDocumentItem_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerDocumentItem_Max_Fields>;
  min?: Maybe<Kcca_CustomerDocumentItem_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerDocumentItem_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerDocumentItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerDocumentItem_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerDocumentItem_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerDocumentItem_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerDocumentItem_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerDocumentItem_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerDocumentItem_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerDocumentItem_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerDocumentItem_Max_Order_By>;
  min?: Maybe<Kcca_CustomerDocumentItem_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerDocumentItem_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerDocumentItem_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerDocumentItem_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerDocumentItem_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerDocumentItem_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerDocumentItem_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerDocumentItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerDocumentItem_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerDocumentItem_Avg_Fields = {
  __typename?: "kcca_CustomerDocumentItem_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  document_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  movement_type?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerDocumentItem". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerDocumentItem_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerDocumentItem_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerDocumentItem_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerDocumentItem_Bool_Exp>>>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  document_id?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  movement_type?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Inc_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  document_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  movement_type?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Insert_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  document_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  movement_type?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerDocumentItem_Max_Fields = {
  __typename?: "kcca_CustomerDocumentItem_max_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  document_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  movement_type?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Max_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerDocumentItem_Min_Fields = {
  __typename?: "kcca_CustomerDocumentItem_min_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  document_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  movement_type?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Min_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Mutation_Response = {
  __typename?: "kcca_CustomerDocumentItem_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerDocumentItem>;
};

/** input type for inserting object relation for remote table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerDocumentItem_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerDocumentItem" */
export enum Kcca_CustomerDocumentItem_Select_Column {
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  MovementType = "movement_type",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Set_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  document_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  movement_type?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerDocumentItem_Stddev_Fields = {
  __typename?: "kcca_CustomerDocumentItem_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  document_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  movement_type?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerDocumentItem_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerDocumentItem_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  document_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  movement_type?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerDocumentItem_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerDocumentItem_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  document_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  movement_type?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerDocumentItem_Sum_Fields = {
  __typename?: "kcca_CustomerDocumentItem_sum_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  document_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  movement_type?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerDocumentItem_Var_Pop_Fields = {
  __typename?: "kcca_CustomerDocumentItem_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  document_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  movement_type?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerDocumentItem_Var_Samp_Fields = {
  __typename?: "kcca_CustomerDocumentItem_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  document_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  movement_type?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerDocumentItem_Variance_Fields = {
  __typename?: "kcca_CustomerDocumentItem_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  document_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  movement_type?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerDocumentItem" */
export type Kcca_CustomerDocumentItem_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  document_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  movement_type?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian = {
  __typename?: "kcca_CustomerGuardian";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Aggregate = {
  __typename?: "kcca_CustomerGuardian_aggregate";
  aggregate?: Maybe<Kcca_CustomerGuardian_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerGuardian>;
};

/** aggregate fields of "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Aggregate_Fields = {
  __typename?: "kcca_CustomerGuardian_aggregate_fields";
  avg?: Maybe<Kcca_CustomerGuardian_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerGuardian_Max_Fields>;
  min?: Maybe<Kcca_CustomerGuardian_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerGuardian_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerGuardian_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerGuardian_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerGuardian_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerGuardian_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerGuardian_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerGuardian_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerGuardian_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerGuardian_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerGuardian_Max_Order_By>;
  min?: Maybe<Kcca_CustomerGuardian_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerGuardian_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerGuardian_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerGuardian_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerGuardian_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerGuardian_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerGuardian_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerGuardian_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerGuardian_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerGuardian_Avg_Fields = {
  __typename?: "kcca_CustomerGuardian_avg_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Avg_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerGuardian". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerGuardian_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerGuardian_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerGuardian_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerGuardian_Bool_Exp>>>;
  acquired_name?: Maybe<String_Comparison_Exp>;
  coin?: Maybe<Float8_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Inc_Input = {
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Insert_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerGuardian_Max_Fields = {
  __typename?: "kcca_CustomerGuardian_max_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Max_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerGuardian_Min_Fields = {
  __typename?: "kcca_CustomerGuardian_min_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Min_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Mutation_Response = {
  __typename?: "kcca_CustomerGuardian_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerGuardian>;
};

/** input type for inserting object relation for remote table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerGuardian_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerGuardian" */
export enum Kcca_CustomerGuardian_Select_Column {
  /** column name */
  AcquiredName = "acquired_name",
  /** column name */
  Coin = "coin",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  Surname = "surname",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Set_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerGuardian_Stddev_Fields = {
  __typename?: "kcca_CustomerGuardian_stddev_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Stddev_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerGuardian_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerGuardian_stddev_pop_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Stddev_Pop_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerGuardian_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerGuardian_stddev_samp_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Stddev_Samp_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerGuardian_Sum_Fields = {
  __typename?: "kcca_CustomerGuardian_sum_fields";
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Sum_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerGuardian_Var_Pop_Fields = {
  __typename?: "kcca_CustomerGuardian_var_pop_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Var_Pop_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerGuardian_Var_Samp_Fields = {
  __typename?: "kcca_CustomerGuardian_var_samp_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Var_Samp_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerGuardian_Variance_Fields = {
  __typename?: "kcca_CustomerGuardian_variance_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerGuardian" */
export type Kcca_CustomerGuardian_Variance_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerReference" */
export type Kcca_CustomerReference = {
  __typename?: "kcca_CustomerReference";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerReference" */
export type Kcca_CustomerReference_Aggregate = {
  __typename?: "kcca_CustomerReference_aggregate";
  aggregate?: Maybe<Kcca_CustomerReference_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerReference>;
};

/** aggregate fields of "kcca.CustomerReference" */
export type Kcca_CustomerReference_Aggregate_Fields = {
  __typename?: "kcca_CustomerReference_aggregate_fields";
  avg?: Maybe<Kcca_CustomerReference_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerReference_Max_Fields>;
  min?: Maybe<Kcca_CustomerReference_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerReference_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerReference_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerReference_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerReference_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerReference_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerReference_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerReference_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerReference" */
export type Kcca_CustomerReference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerReference_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerReference_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerReference_Max_Order_By>;
  min?: Maybe<Kcca_CustomerReference_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerReference_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerReference_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerReference_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerReference_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerReference_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerReference_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerReference_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerReference_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerReference_Avg_Fields = {
  __typename?: "kcca_CustomerReference_avg_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Avg_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerReference". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerReference_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerReference_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerReference_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerReference_Bool_Exp>>>;
  acquired_name?: Maybe<String_Comparison_Exp>;
  coin?: Maybe<Float8_Comparison_Exp>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  relationship_designation?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Float8_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Inc_Input = {
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Insert_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerReference_Max_Fields = {
  __typename?: "kcca_CustomerReference_max_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Max_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  relationship_designation?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerReference_Min_Fields = {
  __typename?: "kcca_CustomerReference_min_fields";
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Min_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  relationship_designation?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Mutation_Response = {
  __typename?: "kcca_CustomerReference_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerReference>;
};

/** input type for inserting object relation for remote table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerReference_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerReference" */
export type Kcca_CustomerReference_Order_By = {
  acquired_name?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  relationship_designation?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerReference" */
export enum Kcca_CustomerReference_Select_Column {
  /** column name */
  AcquiredName = "acquired_name",
  /** column name */
  Coin = "coin",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  Email = "email",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  Mobile = "mobile",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  RelationshipDesignation = "relationship_designation",
  /** column name */
  StatusId = "status_id",
  /** column name */
  Surname = "surname",
  /** column name */
  Telephone = "telephone",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Set_Input = {
  acquired_name?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  relationship_designation?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerReference_Stddev_Fields = {
  __typename?: "kcca_CustomerReference_stddev_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Stddev_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerReference_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerReference_stddev_pop_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Stddev_Pop_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerReference_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerReference_stddev_samp_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Stddev_Samp_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerReference_Sum_Fields = {
  __typename?: "kcca_CustomerReference_sum_fields";
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Sum_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerReference_Var_Pop_Fields = {
  __typename?: "kcca_CustomerReference_var_pop_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Var_Pop_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerReference_Var_Samp_Fields = {
  __typename?: "kcca_CustomerReference_var_samp_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Var_Samp_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerReference_Variance_Fields = {
  __typename?: "kcca_CustomerReference_variance_fields";
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerReference" */
export type Kcca_CustomerReference_Variance_Order_By = {
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason = {
  __typename?: "kcca_CustomerRegistrationReason";
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  revenue_system_code?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Aggregate = {
  __typename?: "kcca_CustomerRegistrationReason_aggregate";
  aggregate?: Maybe<Kcca_CustomerRegistrationReason_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerRegistrationReason>;
};

/** aggregate fields of "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Aggregate_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_aggregate_fields";
  avg?: Maybe<Kcca_CustomerRegistrationReason_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerRegistrationReason_Max_Fields>;
  min?: Maybe<Kcca_CustomerRegistrationReason_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerRegistrationReason_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerRegistrationReason_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerRegistrationReason_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerRegistrationReason_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerRegistrationReason_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerRegistrationReason_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerRegistrationReason_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerRegistrationReason_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerRegistrationReason_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerRegistrationReason_Max_Order_By>;
  min?: Maybe<Kcca_CustomerRegistrationReason_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerRegistrationReason_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerRegistrationReason_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerRegistrationReason_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerRegistrationReason_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerRegistrationReason_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerRegistrationReason_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerRegistrationReason_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerRegistrationReason_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerRegistrationReason_Avg_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerRegistrationReason". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerRegistrationReason_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerRegistrationReason_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerRegistrationReason_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerRegistrationReason_Bool_Exp>>>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  revenue_system_code?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Inc_Input = {
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Insert_Input = {
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  revenue_system_code?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerRegistrationReason_Max_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_max_fields";
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  revenue_system_code?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Max_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  revenue_system_code?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerRegistrationReason_Min_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_min_fields";
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  revenue_system_code?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Min_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  revenue_system_code?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Mutation_Response = {
  __typename?: "kcca_CustomerRegistrationReason_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerRegistrationReason>;
};

/** input type for inserting object relation for remote table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerRegistrationReason_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  revenue_system_code?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerRegistrationReason" */
export enum Kcca_CustomerRegistrationReason_Select_Column {
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  RevenueSystemCode = "revenue_system_code",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Set_Input = {
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  revenue_system_code?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerRegistrationReason_Stddev_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerRegistrationReason_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerRegistrationReason_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerRegistrationReason_Sum_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_sum_fields";
  created_by?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerRegistrationReason_Var_Pop_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerRegistrationReason_Var_Samp_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerRegistrationReason_Variance_Fields = {
  __typename?: "kcca_CustomerRegistrationReason_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerRegistrationReason" */
export type Kcca_CustomerRegistrationReason_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent = {
  __typename?: "kcca_CustomerRevenueAgent";
  agent_legal_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Aggregate = {
  __typename?: "kcca_CustomerRevenueAgent_aggregate";
  aggregate?: Maybe<Kcca_CustomerRevenueAgent_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerRevenueAgent>;
};

/** aggregate fields of "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Aggregate_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_aggregate_fields";
  avg?: Maybe<Kcca_CustomerRevenueAgent_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerRevenueAgent_Max_Fields>;
  min?: Maybe<Kcca_CustomerRevenueAgent_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerRevenueAgent_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerRevenueAgent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerRevenueAgent_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerRevenueAgent_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerRevenueAgent_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerRevenueAgent_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerRevenueAgent_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerRevenueAgent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerRevenueAgent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerRevenueAgent_Max_Order_By>;
  min?: Maybe<Kcca_CustomerRevenueAgent_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerRevenueAgent_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerRevenueAgent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerRevenueAgent_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerRevenueAgent_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerRevenueAgent_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerRevenueAgent_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerRevenueAgent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerRevenueAgent_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerRevenueAgent_Avg_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerRevenueAgent". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerRevenueAgent_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerRevenueAgent_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerRevenueAgent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerRevenueAgent_Bool_Exp>>>;
  agent_legal_name?: Maybe<String_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Inc_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Insert_Input = {
  agent_legal_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerRevenueAgent_Max_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_max_fields";
  agent_legal_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Max_Order_By = {
  agent_legal_name?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerRevenueAgent_Min_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_min_fields";
  agent_legal_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Min_Order_By = {
  agent_legal_name?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Mutation_Response = {
  __typename?: "kcca_CustomerRevenueAgent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerRevenueAgent>;
};

/** input type for inserting object relation for remote table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerRevenueAgent_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Order_By = {
  agent_legal_name?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerRevenueAgent" */
export enum Kcca_CustomerRevenueAgent_Select_Column {
  /** column name */
  AgentLegalName = "agent_legal_name",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  Email = "email",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Mobile = "mobile",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  StatusId = "status_id",
  /** column name */
  Telephone = "telephone",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Set_Input = {
  agent_legal_name?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerRevenueAgent_Stddev_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerRevenueAgent_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerRevenueAgent_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerRevenueAgent_Sum_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_sum_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerRevenueAgent_Var_Pop_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerRevenueAgent_Var_Samp_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerRevenueAgent_Variance_Fields = {
  __typename?: "kcca_CustomerRevenueAgent_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerRevenueAgent" */
export type Kcca_CustomerRevenueAgent_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.CustomerType" */
export type Kcca_CustomerType = {
  __typename?: "kcca_CustomerType";
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.CustomerType" */
export type Kcca_CustomerType_Aggregate = {
  __typename?: "kcca_CustomerType_aggregate";
  aggregate?: Maybe<Kcca_CustomerType_Aggregate_Fields>;
  nodes: Array<Kcca_CustomerType>;
};

/** aggregate fields of "kcca.CustomerType" */
export type Kcca_CustomerType_Aggregate_Fields = {
  __typename?: "kcca_CustomerType_aggregate_fields";
  avg?: Maybe<Kcca_CustomerType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_CustomerType_Max_Fields>;
  min?: Maybe<Kcca_CustomerType_Min_Fields>;
  stddev?: Maybe<Kcca_CustomerType_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_CustomerType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_CustomerType_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_CustomerType_Sum_Fields>;
  var_pop?: Maybe<Kcca_CustomerType_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_CustomerType_Var_Samp_Fields>;
  variance?: Maybe<Kcca_CustomerType_Variance_Fields>;
};

/** aggregate fields of "kcca.CustomerType" */
export type Kcca_CustomerType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_CustomerType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.CustomerType" */
export type Kcca_CustomerType_Aggregate_Order_By = {
  avg?: Maybe<Kcca_CustomerType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_CustomerType_Max_Order_By>;
  min?: Maybe<Kcca_CustomerType_Min_Order_By>;
  stddev?: Maybe<Kcca_CustomerType_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_CustomerType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_CustomerType_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_CustomerType_Sum_Order_By>;
  var_pop?: Maybe<Kcca_CustomerType_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_CustomerType_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_CustomerType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.CustomerType" */
export type Kcca_CustomerType_Arr_Rel_Insert_Input = {
  data: Array<Kcca_CustomerType_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_CustomerType_Avg_Fields = {
  __typename?: "kcca_CustomerType_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Avg_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.CustomerType". All fields are combined with a logical 'AND'. */
export type Kcca_CustomerType_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_CustomerType_Bool_Exp>>>;
  _not?: Maybe<Kcca_CustomerType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_CustomerType_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.CustomerType" */
export type Kcca_CustomerType_Inc_Input = {
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.CustomerType" */
export type Kcca_CustomerType_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_CustomerType_Max_Fields = {
  __typename?: "kcca_CustomerType_max_fields";
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Max_Order_By = {
  description?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_CustomerType_Min_Fields = {
  __typename?: "kcca_CustomerType_min_fields";
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Min_Order_By = {
  description?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.CustomerType" */
export type Kcca_CustomerType_Mutation_Response = {
  __typename?: "kcca_CustomerType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_CustomerType>;
};

/** input type for inserting object relation for remote table "kcca.CustomerType" */
export type Kcca_CustomerType_Obj_Rel_Insert_Input = {
  data: Kcca_CustomerType_Insert_Input;
};

/** ordering options when selecting data from "kcca.CustomerType" */
export type Kcca_CustomerType_Order_By = {
  description?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.CustomerType" */
export enum Kcca_CustomerType_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.CustomerType" */
export type Kcca_CustomerType_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_CustomerType_Stddev_Fields = {
  __typename?: "kcca_CustomerType_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_CustomerType_Stddev_Pop_Fields = {
  __typename?: "kcca_CustomerType_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_CustomerType_Stddev_Samp_Fields = {
  __typename?: "kcca_CustomerType_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_CustomerType_Sum_Fields = {
  __typename?: "kcca_CustomerType_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Sum_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_CustomerType_Var_Pop_Fields = {
  __typename?: "kcca_CustomerType_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_CustomerType_Var_Samp_Fields = {
  __typename?: "kcca_CustomerType_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_CustomerType_Variance_Fields = {
  __typename?: "kcca_CustomerType_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.CustomerType" */
export type Kcca_CustomerType_Variance_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregated selection of "kcca.Customer" */
export type Kcca_Customer_Aggregate = {
  __typename?: "kcca_Customer_aggregate";
  aggregate?: Maybe<Kcca_Customer_Aggregate_Fields>;
  nodes: Array<Kcca_Customer>;
};

/** aggregate fields of "kcca.Customer" */
export type Kcca_Customer_Aggregate_Fields = {
  __typename?: "kcca_Customer_aggregate_fields";
  avg?: Maybe<Kcca_Customer_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_Customer_Max_Fields>;
  min?: Maybe<Kcca_Customer_Min_Fields>;
  stddev?: Maybe<Kcca_Customer_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_Customer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_Customer_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_Customer_Sum_Fields>;
  var_pop?: Maybe<Kcca_Customer_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_Customer_Var_Samp_Fields>;
  variance?: Maybe<Kcca_Customer_Variance_Fields>;
};

/** aggregate fields of "kcca.Customer" */
export type Kcca_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_Customer_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.Customer" */
export type Kcca_Customer_Aggregate_Order_By = {
  avg?: Maybe<Kcca_Customer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_Customer_Max_Order_By>;
  min?: Maybe<Kcca_Customer_Min_Order_By>;
  stddev?: Maybe<Kcca_Customer_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_Customer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_Customer_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_Customer_Sum_Order_By>;
  var_pop?: Maybe<Kcca_Customer_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_Customer_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_Customer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.Customer" */
export type Kcca_Customer_Arr_Rel_Insert_Input = {
  data: Array<Kcca_Customer_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_Customer_Avg_Fields = {
  __typename?: "kcca_Customer_avg_fields";
  Visible_Level?: Maybe<Scalars["Float"]>;
  balance?: Maybe<Scalars["Float"]>;
  box_district_id?: Maybe<Scalars["Float"]>;
  citizenship_country_id?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  customer_business_type_id?: Maybe<Scalars["Float"]>;
  customer_type_id?: Maybe<Scalars["Float"]>;
  gender_id?: Maybe<Scalars["Float"]>;
  have_alternate_primary_contact?: Maybe<Scalars["Float"]>;
  have_reference?: Maybe<Scalars["Float"]>;
  have_revenue_agent?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_minor?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  previous_business_address_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_district_id?: Maybe<Scalars["Float"]>;
  previous_business_address_parish_id?: Maybe<Scalars["Float"]>;
  previous_business_address_plot_number?: Maybe<Scalars["Float"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_village_id?: Maybe<Scalars["Float"]>;
  previous_business_name?: Maybe<Scalars["Float"]>;
  residential_address_county_id?: Maybe<Scalars["Float"]>;
  residential_address_district_id?: Maybe<Scalars["Float"]>;
  residential_address_parish_id?: Maybe<Scalars["Float"]>;
  residential_address_sub_county_id?: Maybe<Scalars["Float"]>;
  residential_address_village_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
  transactional_status?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.Customer" */
export type Kcca_Customer_Avg_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.Customer". All fields are combined with a logical 'AND'. */
export type Kcca_Customer_Bool_Exp = {
  Visible_Level?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Kcca_Customer_Bool_Exp>>>;
  _not?: Maybe<Kcca_Customer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_Customer_Bool_Exp>>>;
  acquired_name?: Maybe<String_Comparison_Exp>;
  balance?: Maybe<Float8_Comparison_Exp>;
  birthdate?: Maybe<Date_Comparison_Exp>;
  box_district_id?: Maybe<Float8_Comparison_Exp>;
  box_number?: Maybe<String_Comparison_Exp>;
  building_name?: Maybe<String_Comparison_Exp>;
  business_name?: Maybe<String_Comparison_Exp>;
  citizenship_country_id?: Maybe<Float8_Comparison_Exp>;
  coin?: Maybe<Float8_Comparison_Exp>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<String_Comparison_Exp>;
  customer_applicant_type_id?: Maybe<Float8_Comparison_Exp>;
  customer_business_type_id?: Maybe<Float8_Comparison_Exp>;
  customer_type_id?: Maybe<Bigint_Comparison_Exp>;
  date_of_incorporation?: Maybe<Date_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  entity_legal_name?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  former_business_name?: Maybe<String_Comparison_Exp>;
  gender_id?: Maybe<Float8_Comparison_Exp>;
  have_alternate_primary_contact?: Maybe<Float8_Comparison_Exp>;
  have_reference?: Maybe<Float8_Comparison_Exp>;
  have_revenue_agent?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  identification_diplomatic_id?: Maybe<String_Comparison_Exp>;
  identification_driving_permit?: Maybe<String_Comparison_Exp>;
  identification_employee_id?: Maybe<String_Comparison_Exp>;
  identification_financial_card?: Maybe<String_Comparison_Exp>;
  identification_national_id?: Maybe<String_Comparison_Exp>;
  identification_nssf_number?: Maybe<String_Comparison_Exp>;
  identification_passport_no?: Maybe<String_Comparison_Exp>;
  identification_refugee_id?: Maybe<String_Comparison_Exp>;
  identification_village_id?: Maybe<String_Comparison_Exp>;
  identification_voter_id?: Maybe<String_Comparison_Exp>;
  identification_work_id?: Maybe<String_Comparison_Exp>;
  identification_work_permit?: Maybe<String_Comparison_Exp>;
  is_forced_action?: Maybe<Float8_Comparison_Exp>;
  is_minor?: Maybe<Float8_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<String_Comparison_Exp>;
  mother_maiden_name?: Maybe<String_Comparison_Exp>;
  plot_number?: Maybe<String_Comparison_Exp>;
  previous_business_address_building_name?: Maybe<String_Comparison_Exp>;
  previous_business_address_county_id?: Maybe<Float8_Comparison_Exp>;
  previous_business_address_district_id?: Maybe<Float8_Comparison_Exp>;
  previous_business_address_parish_id?: Maybe<Float8_Comparison_Exp>;
  previous_business_address_plot_number?: Maybe<Float8_Comparison_Exp>;
  previous_business_address_street_name?: Maybe<String_Comparison_Exp>;
  previous_business_address_sub_county_id?: Maybe<Float8_Comparison_Exp>;
  previous_business_address_trading_center?: Maybe<String_Comparison_Exp>;
  previous_business_address_village_id?: Maybe<Float8_Comparison_Exp>;
  previous_business_name?: Maybe<Float8_Comparison_Exp>;
  residential_address_county_id?: Maybe<Float8_Comparison_Exp>;
  residential_address_district_id?: Maybe<Float8_Comparison_Exp>;
  residential_address_parish_id?: Maybe<Float8_Comparison_Exp>;
  residential_address_sub_county_id?: Maybe<Float8_Comparison_Exp>;
  residential_address_village_id?: Maybe<Float8_Comparison_Exp>;
  second_mobile?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Float8_Comparison_Exp>;
  street_name?: Maybe<String_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  title_id?: Maybe<Float8_Comparison_Exp>;
  trading_center?: Maybe<String_Comparison_Exp>;
  transactional_status?: Maybe<Float8_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.Customer" */
export type Kcca_Customer_Inc_Input = {
  Visible_Level?: Maybe<Scalars["float8"]>;
  balance?: Maybe<Scalars["float8"]>;
  box_district_id?: Maybe<Scalars["float8"]>;
  citizenship_country_id?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  customer_applicant_type_id?: Maybe<Scalars["float8"]>;
  customer_business_type_id?: Maybe<Scalars["float8"]>;
  customer_type_id?: Maybe<Scalars["bigint"]>;
  gender_id?: Maybe<Scalars["float8"]>;
  have_alternate_primary_contact?: Maybe<Scalars["float8"]>;
  have_reference?: Maybe<Scalars["float8"]>;
  have_revenue_agent?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_minor?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  previous_business_address_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_district_id?: Maybe<Scalars["float8"]>;
  previous_business_address_parish_id?: Maybe<Scalars["float8"]>;
  previous_business_address_plot_number?: Maybe<Scalars["float8"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_village_id?: Maybe<Scalars["float8"]>;
  previous_business_name?: Maybe<Scalars["float8"]>;
  residential_address_county_id?: Maybe<Scalars["float8"]>;
  residential_address_district_id?: Maybe<Scalars["float8"]>;
  residential_address_parish_id?: Maybe<Scalars["float8"]>;
  residential_address_sub_county_id?: Maybe<Scalars["float8"]>;
  residential_address_village_id?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
  title_id?: Maybe<Scalars["float8"]>;
  transactional_status?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "kcca.Customer" */
export type Kcca_Customer_Insert_Input = {
  Visible_Level?: Maybe<Scalars["float8"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["float8"]>;
  birthdate?: Maybe<Scalars["date"]>;
  box_district_id?: Maybe<Scalars["float8"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["float8"]>;
  customer_business_type_id?: Maybe<Scalars["float8"]>;
  customer_type_id?: Maybe<Scalars["bigint"]>;
  date_of_incorporation?: Maybe<Scalars["date"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["float8"]>;
  have_alternate_primary_contact?: Maybe<Scalars["float8"]>;
  have_reference?: Maybe<Scalars["float8"]>;
  have_revenue_agent?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_minor?: Maybe<Scalars["float8"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_district_id?: Maybe<Scalars["float8"]>;
  previous_business_address_parish_id?: Maybe<Scalars["float8"]>;
  previous_business_address_plot_number?: Maybe<Scalars["float8"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["float8"]>;
  previous_business_name?: Maybe<Scalars["float8"]>;
  residential_address_county_id?: Maybe<Scalars["float8"]>;
  residential_address_district_id?: Maybe<Scalars["float8"]>;
  residential_address_parish_id?: Maybe<Scalars["float8"]>;
  residential_address_sub_county_id?: Maybe<Scalars["float8"]>;
  residential_address_village_id?: Maybe<Scalars["float8"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_Customer_Max_Fields = {
  __typename?: "kcca_Customer_max_fields";
  Visible_Level?: Maybe<Scalars["float8"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["float8"]>;
  birthdate?: Maybe<Scalars["date"]>;
  box_district_id?: Maybe<Scalars["float8"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["float8"]>;
  customer_business_type_id?: Maybe<Scalars["float8"]>;
  customer_type_id?: Maybe<Scalars["bigint"]>;
  date_of_incorporation?: Maybe<Scalars["date"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["float8"]>;
  have_alternate_primary_contact?: Maybe<Scalars["float8"]>;
  have_reference?: Maybe<Scalars["float8"]>;
  have_revenue_agent?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_minor?: Maybe<Scalars["float8"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_district_id?: Maybe<Scalars["float8"]>;
  previous_business_address_parish_id?: Maybe<Scalars["float8"]>;
  previous_business_address_plot_number?: Maybe<Scalars["float8"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["float8"]>;
  previous_business_name?: Maybe<Scalars["float8"]>;
  residential_address_county_id?: Maybe<Scalars["float8"]>;
  residential_address_district_id?: Maybe<Scalars["float8"]>;
  residential_address_parish_id?: Maybe<Scalars["float8"]>;
  residential_address_sub_county_id?: Maybe<Scalars["float8"]>;
  residential_address_village_id?: Maybe<Scalars["float8"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.Customer" */
export type Kcca_Customer_Max_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  acquired_name?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  box_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  date_of_incorporation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  former_business_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_diplomatic_id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_employee_id?: Maybe<Order_By>;
  identification_financial_card?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_number?: Maybe<Order_By>;
  identification_passport_no?: Maybe<Order_By>;
  identification_refugee_id?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  identification_voter_id?: Maybe<Order_By>;
  identification_work_id?: Maybe<Order_By>;
  identification_work_permit?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  plot_number?: Maybe<Order_By>;
  previous_business_address_building_name?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_street_name?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_trading_center?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_name?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  trading_center?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_Customer_Min_Fields = {
  __typename?: "kcca_Customer_min_fields";
  Visible_Level?: Maybe<Scalars["float8"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["float8"]>;
  birthdate?: Maybe<Scalars["date"]>;
  box_district_id?: Maybe<Scalars["float8"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["float8"]>;
  customer_business_type_id?: Maybe<Scalars["float8"]>;
  customer_type_id?: Maybe<Scalars["bigint"]>;
  date_of_incorporation?: Maybe<Scalars["date"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["float8"]>;
  have_alternate_primary_contact?: Maybe<Scalars["float8"]>;
  have_reference?: Maybe<Scalars["float8"]>;
  have_revenue_agent?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_minor?: Maybe<Scalars["float8"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_district_id?: Maybe<Scalars["float8"]>;
  previous_business_address_parish_id?: Maybe<Scalars["float8"]>;
  previous_business_address_plot_number?: Maybe<Scalars["float8"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["float8"]>;
  previous_business_name?: Maybe<Scalars["float8"]>;
  residential_address_county_id?: Maybe<Scalars["float8"]>;
  residential_address_district_id?: Maybe<Scalars["float8"]>;
  residential_address_parish_id?: Maybe<Scalars["float8"]>;
  residential_address_sub_county_id?: Maybe<Scalars["float8"]>;
  residential_address_village_id?: Maybe<Scalars["float8"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.Customer" */
export type Kcca_Customer_Min_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  acquired_name?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  box_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  date_of_incorporation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  former_business_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_diplomatic_id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_employee_id?: Maybe<Order_By>;
  identification_financial_card?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_number?: Maybe<Order_By>;
  identification_passport_no?: Maybe<Order_By>;
  identification_refugee_id?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  identification_voter_id?: Maybe<Order_By>;
  identification_work_id?: Maybe<Order_By>;
  identification_work_permit?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  plot_number?: Maybe<Order_By>;
  previous_business_address_building_name?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_street_name?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_trading_center?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_name?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  trading_center?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.Customer" */
export type Kcca_Customer_Mutation_Response = {
  __typename?: "kcca_Customer_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_Customer>;
};

/** input type for inserting object relation for remote table "kcca.Customer" */
export type Kcca_Customer_Obj_Rel_Insert_Input = {
  data: Kcca_Customer_Insert_Input;
};

/** ordering options when selecting data from "kcca.Customer" */
export type Kcca_Customer_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  acquired_name?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  box_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  date_of_incorporation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  former_business_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_diplomatic_id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_employee_id?: Maybe<Order_By>;
  identification_financial_card?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_number?: Maybe<Order_By>;
  identification_passport_no?: Maybe<Order_By>;
  identification_refugee_id?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  identification_voter_id?: Maybe<Order_By>;
  identification_work_id?: Maybe<Order_By>;
  identification_work_permit?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  plot_number?: Maybe<Order_By>;
  previous_business_address_building_name?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_street_name?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_trading_center?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_name?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  trading_center?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.Customer" */
export enum Kcca_Customer_Select_Column {
  /** column name */
  VisibleLevel = "Visible_Level",
  /** column name */
  AcquiredName = "acquired_name",
  /** column name */
  Balance = "balance",
  /** column name */
  Birthdate = "birthdate",
  /** column name */
  BoxDistrictId = "box_district_id",
  /** column name */
  BoxNumber = "box_number",
  /** column name */
  BuildingName = "building_name",
  /** column name */
  BusinessName = "business_name",
  /** column name */
  CitizenshipCountryId = "citizenship_country_id",
  /** column name */
  Coin = "coin",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerApplicantTypeId = "customer_applicant_type_id",
  /** column name */
  CustomerBusinessTypeId = "customer_business_type_id",
  /** column name */
  CustomerTypeId = "customer_type_id",
  /** column name */
  DateOfIncorporation = "date_of_incorporation",
  /** column name */
  Email = "email",
  /** column name */
  EntityLegalName = "entity_legal_name",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  FormerBusinessName = "former_business_name",
  /** column name */
  GenderId = "gender_id",
  /** column name */
  HaveAlternatePrimaryContact = "have_alternate_primary_contact",
  /** column name */
  HaveReference = "have_reference",
  /** column name */
  HaveRevenueAgent = "have_revenue_agent",
  /** column name */
  Id = "id",
  /** column name */
  IdentificationDiplomaticId = "identification_diplomatic_id",
  /** column name */
  IdentificationDrivingPermit = "identification_driving_permit",
  /** column name */
  IdentificationEmployeeId = "identification_employee_id",
  /** column name */
  IdentificationFinancialCard = "identification_financial_card",
  /** column name */
  IdentificationNationalId = "identification_national_id",
  /** column name */
  IdentificationNssfNumber = "identification_nssf_number",
  /** column name */
  IdentificationPassportNo = "identification_passport_no",
  /** column name */
  IdentificationRefugeeId = "identification_refugee_id",
  /** column name */
  IdentificationVillageId = "identification_village_id",
  /** column name */
  IdentificationVoterId = "identification_voter_id",
  /** column name */
  IdentificationWorkId = "identification_work_id",
  /** column name */
  IdentificationWorkPermit = "identification_work_permit",
  /** column name */
  IsForcedAction = "is_forced_action",
  /** column name */
  IsMinor = "is_minor",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  Mobile = "mobile",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  MotherMaidenName = "mother_maiden_name",
  /** column name */
  PlotNumber = "plot_number",
  /** column name */
  PreviousBusinessAddressBuildingName = "previous_business_address_building_name",
  /** column name */
  PreviousBusinessAddressCountyId = "previous_business_address_county_id",
  /** column name */
  PreviousBusinessAddressDistrictId = "previous_business_address_district_id",
  /** column name */
  PreviousBusinessAddressParishId = "previous_business_address_parish_id",
  /** column name */
  PreviousBusinessAddressPlotNumber = "previous_business_address_plot_number",
  /** column name */
  PreviousBusinessAddressStreetName = "previous_business_address_street_name",
  /** column name */
  PreviousBusinessAddressSubCountyId = "previous_business_address_sub_county_id",
  /** column name */
  PreviousBusinessAddressTradingCenter = "previous_business_address_trading_center",
  /** column name */
  PreviousBusinessAddressVillageId = "previous_business_address_village_id",
  /** column name */
  PreviousBusinessName = "previous_business_name",
  /** column name */
  ResidentialAddressCountyId = "residential_address_county_id",
  /** column name */
  ResidentialAddressDistrictId = "residential_address_district_id",
  /** column name */
  ResidentialAddressParishId = "residential_address_parish_id",
  /** column name */
  ResidentialAddressSubCountyId = "residential_address_sub_county_id",
  /** column name */
  ResidentialAddressVillageId = "residential_address_village_id",
  /** column name */
  SecondMobile = "second_mobile",
  /** column name */
  StatusId = "status_id",
  /** column name */
  StreetName = "street_name",
  /** column name */
  Surname = "surname",
  /** column name */
  Telephone = "telephone",
  /** column name */
  Tin = "tin",
  /** column name */
  TitleId = "title_id",
  /** column name */
  TradingCenter = "trading_center",
  /** column name */
  TransactionalStatus = "transactional_status",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.Customer" */
export type Kcca_Customer_Set_Input = {
  Visible_Level?: Maybe<Scalars["float8"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["float8"]>;
  birthdate?: Maybe<Scalars["date"]>;
  box_district_id?: Maybe<Scalars["float8"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["float8"]>;
  customer_business_type_id?: Maybe<Scalars["float8"]>;
  customer_type_id?: Maybe<Scalars["bigint"]>;
  date_of_incorporation?: Maybe<Scalars["date"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["float8"]>;
  have_alternate_primary_contact?: Maybe<Scalars["float8"]>;
  have_reference?: Maybe<Scalars["float8"]>;
  have_revenue_agent?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_minor?: Maybe<Scalars["float8"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_district_id?: Maybe<Scalars["float8"]>;
  previous_business_address_parish_id?: Maybe<Scalars["float8"]>;
  previous_business_address_plot_number?: Maybe<Scalars["float8"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["float8"]>;
  previous_business_name?: Maybe<Scalars["float8"]>;
  residential_address_county_id?: Maybe<Scalars["float8"]>;
  residential_address_district_id?: Maybe<Scalars["float8"]>;
  residential_address_parish_id?: Maybe<Scalars["float8"]>;
  residential_address_sub_county_id?: Maybe<Scalars["float8"]>;
  residential_address_village_id?: Maybe<Scalars["float8"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["float8"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_Customer_Stddev_Fields = {
  __typename?: "kcca_Customer_stddev_fields";
  Visible_Level?: Maybe<Scalars["Float"]>;
  balance?: Maybe<Scalars["Float"]>;
  box_district_id?: Maybe<Scalars["Float"]>;
  citizenship_country_id?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  customer_business_type_id?: Maybe<Scalars["Float"]>;
  customer_type_id?: Maybe<Scalars["Float"]>;
  gender_id?: Maybe<Scalars["Float"]>;
  have_alternate_primary_contact?: Maybe<Scalars["Float"]>;
  have_reference?: Maybe<Scalars["Float"]>;
  have_revenue_agent?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_minor?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  previous_business_address_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_district_id?: Maybe<Scalars["Float"]>;
  previous_business_address_parish_id?: Maybe<Scalars["Float"]>;
  previous_business_address_plot_number?: Maybe<Scalars["Float"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_village_id?: Maybe<Scalars["Float"]>;
  previous_business_name?: Maybe<Scalars["Float"]>;
  residential_address_county_id?: Maybe<Scalars["Float"]>;
  residential_address_district_id?: Maybe<Scalars["Float"]>;
  residential_address_parish_id?: Maybe<Scalars["Float"]>;
  residential_address_sub_county_id?: Maybe<Scalars["Float"]>;
  residential_address_village_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
  transactional_status?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.Customer" */
export type Kcca_Customer_Stddev_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_Customer_Stddev_Pop_Fields = {
  __typename?: "kcca_Customer_stddev_pop_fields";
  Visible_Level?: Maybe<Scalars["Float"]>;
  balance?: Maybe<Scalars["Float"]>;
  box_district_id?: Maybe<Scalars["Float"]>;
  citizenship_country_id?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  customer_business_type_id?: Maybe<Scalars["Float"]>;
  customer_type_id?: Maybe<Scalars["Float"]>;
  gender_id?: Maybe<Scalars["Float"]>;
  have_alternate_primary_contact?: Maybe<Scalars["Float"]>;
  have_reference?: Maybe<Scalars["Float"]>;
  have_revenue_agent?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_minor?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  previous_business_address_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_district_id?: Maybe<Scalars["Float"]>;
  previous_business_address_parish_id?: Maybe<Scalars["Float"]>;
  previous_business_address_plot_number?: Maybe<Scalars["Float"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_village_id?: Maybe<Scalars["Float"]>;
  previous_business_name?: Maybe<Scalars["Float"]>;
  residential_address_county_id?: Maybe<Scalars["Float"]>;
  residential_address_district_id?: Maybe<Scalars["Float"]>;
  residential_address_parish_id?: Maybe<Scalars["Float"]>;
  residential_address_sub_county_id?: Maybe<Scalars["Float"]>;
  residential_address_village_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
  transactional_status?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.Customer" */
export type Kcca_Customer_Stddev_Pop_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_Customer_Stddev_Samp_Fields = {
  __typename?: "kcca_Customer_stddev_samp_fields";
  Visible_Level?: Maybe<Scalars["Float"]>;
  balance?: Maybe<Scalars["Float"]>;
  box_district_id?: Maybe<Scalars["Float"]>;
  citizenship_country_id?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  customer_business_type_id?: Maybe<Scalars["Float"]>;
  customer_type_id?: Maybe<Scalars["Float"]>;
  gender_id?: Maybe<Scalars["Float"]>;
  have_alternate_primary_contact?: Maybe<Scalars["Float"]>;
  have_reference?: Maybe<Scalars["Float"]>;
  have_revenue_agent?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_minor?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  previous_business_address_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_district_id?: Maybe<Scalars["Float"]>;
  previous_business_address_parish_id?: Maybe<Scalars["Float"]>;
  previous_business_address_plot_number?: Maybe<Scalars["Float"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_village_id?: Maybe<Scalars["Float"]>;
  previous_business_name?: Maybe<Scalars["Float"]>;
  residential_address_county_id?: Maybe<Scalars["Float"]>;
  residential_address_district_id?: Maybe<Scalars["Float"]>;
  residential_address_parish_id?: Maybe<Scalars["Float"]>;
  residential_address_sub_county_id?: Maybe<Scalars["Float"]>;
  residential_address_village_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
  transactional_status?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.Customer" */
export type Kcca_Customer_Stddev_Samp_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_Customer_Sum_Fields = {
  __typename?: "kcca_Customer_sum_fields";
  Visible_Level?: Maybe<Scalars["float8"]>;
  balance?: Maybe<Scalars["float8"]>;
  box_district_id?: Maybe<Scalars["float8"]>;
  citizenship_country_id?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  created_by?: Maybe<Scalars["float8"]>;
  customer_applicant_type_id?: Maybe<Scalars["float8"]>;
  customer_business_type_id?: Maybe<Scalars["float8"]>;
  customer_type_id?: Maybe<Scalars["bigint"]>;
  gender_id?: Maybe<Scalars["float8"]>;
  have_alternate_primary_contact?: Maybe<Scalars["float8"]>;
  have_reference?: Maybe<Scalars["float8"]>;
  have_revenue_agent?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  is_forced_action?: Maybe<Scalars["float8"]>;
  is_minor?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  previous_business_address_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_district_id?: Maybe<Scalars["float8"]>;
  previous_business_address_parish_id?: Maybe<Scalars["float8"]>;
  previous_business_address_plot_number?: Maybe<Scalars["float8"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["float8"]>;
  previous_business_address_village_id?: Maybe<Scalars["float8"]>;
  previous_business_name?: Maybe<Scalars["float8"]>;
  residential_address_county_id?: Maybe<Scalars["float8"]>;
  residential_address_district_id?: Maybe<Scalars["float8"]>;
  residential_address_parish_id?: Maybe<Scalars["float8"]>;
  residential_address_sub_county_id?: Maybe<Scalars["float8"]>;
  residential_address_village_id?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
  title_id?: Maybe<Scalars["float8"]>;
  transactional_status?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "kcca.Customer" */
export type Kcca_Customer_Sum_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_Customer_Var_Pop_Fields = {
  __typename?: "kcca_Customer_var_pop_fields";
  Visible_Level?: Maybe<Scalars["Float"]>;
  balance?: Maybe<Scalars["Float"]>;
  box_district_id?: Maybe<Scalars["Float"]>;
  citizenship_country_id?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  customer_business_type_id?: Maybe<Scalars["Float"]>;
  customer_type_id?: Maybe<Scalars["Float"]>;
  gender_id?: Maybe<Scalars["Float"]>;
  have_alternate_primary_contact?: Maybe<Scalars["Float"]>;
  have_reference?: Maybe<Scalars["Float"]>;
  have_revenue_agent?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_minor?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  previous_business_address_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_district_id?: Maybe<Scalars["Float"]>;
  previous_business_address_parish_id?: Maybe<Scalars["Float"]>;
  previous_business_address_plot_number?: Maybe<Scalars["Float"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_village_id?: Maybe<Scalars["Float"]>;
  previous_business_name?: Maybe<Scalars["Float"]>;
  residential_address_county_id?: Maybe<Scalars["Float"]>;
  residential_address_district_id?: Maybe<Scalars["Float"]>;
  residential_address_parish_id?: Maybe<Scalars["Float"]>;
  residential_address_sub_county_id?: Maybe<Scalars["Float"]>;
  residential_address_village_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
  transactional_status?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.Customer" */
export type Kcca_Customer_Var_Pop_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_Customer_Var_Samp_Fields = {
  __typename?: "kcca_Customer_var_samp_fields";
  Visible_Level?: Maybe<Scalars["Float"]>;
  balance?: Maybe<Scalars["Float"]>;
  box_district_id?: Maybe<Scalars["Float"]>;
  citizenship_country_id?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  customer_business_type_id?: Maybe<Scalars["Float"]>;
  customer_type_id?: Maybe<Scalars["Float"]>;
  gender_id?: Maybe<Scalars["Float"]>;
  have_alternate_primary_contact?: Maybe<Scalars["Float"]>;
  have_reference?: Maybe<Scalars["Float"]>;
  have_revenue_agent?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_minor?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  previous_business_address_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_district_id?: Maybe<Scalars["Float"]>;
  previous_business_address_parish_id?: Maybe<Scalars["Float"]>;
  previous_business_address_plot_number?: Maybe<Scalars["Float"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_village_id?: Maybe<Scalars["Float"]>;
  previous_business_name?: Maybe<Scalars["Float"]>;
  residential_address_county_id?: Maybe<Scalars["Float"]>;
  residential_address_district_id?: Maybe<Scalars["Float"]>;
  residential_address_parish_id?: Maybe<Scalars["Float"]>;
  residential_address_sub_county_id?: Maybe<Scalars["Float"]>;
  residential_address_village_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
  transactional_status?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.Customer" */
export type Kcca_Customer_Var_Samp_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_Customer_Variance_Fields = {
  __typename?: "kcca_Customer_variance_fields";
  Visible_Level?: Maybe<Scalars["Float"]>;
  balance?: Maybe<Scalars["Float"]>;
  box_district_id?: Maybe<Scalars["Float"]>;
  citizenship_country_id?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  customer_applicant_type_id?: Maybe<Scalars["Float"]>;
  customer_business_type_id?: Maybe<Scalars["Float"]>;
  customer_type_id?: Maybe<Scalars["Float"]>;
  gender_id?: Maybe<Scalars["Float"]>;
  have_alternate_primary_contact?: Maybe<Scalars["Float"]>;
  have_reference?: Maybe<Scalars["Float"]>;
  have_revenue_agent?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  is_forced_action?: Maybe<Scalars["Float"]>;
  is_minor?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  previous_business_address_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_district_id?: Maybe<Scalars["Float"]>;
  previous_business_address_parish_id?: Maybe<Scalars["Float"]>;
  previous_business_address_plot_number?: Maybe<Scalars["Float"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["Float"]>;
  previous_business_address_village_id?: Maybe<Scalars["Float"]>;
  previous_business_name?: Maybe<Scalars["Float"]>;
  residential_address_county_id?: Maybe<Scalars["Float"]>;
  residential_address_district_id?: Maybe<Scalars["Float"]>;
  residential_address_parish_id?: Maybe<Scalars["Float"]>;
  residential_address_sub_county_id?: Maybe<Scalars["Float"]>;
  residential_address_village_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  title_id?: Maybe<Scalars["Float"]>;
  transactional_status?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.Customer" */
export type Kcca_Customer_Variance_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.District" */
export type Kcca_District = {
  __typename?: "kcca_District";
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.District" */
export type Kcca_District_Aggregate = {
  __typename?: "kcca_District_aggregate";
  aggregate?: Maybe<Kcca_District_Aggregate_Fields>;
  nodes: Array<Kcca_District>;
};

/** aggregate fields of "kcca.District" */
export type Kcca_District_Aggregate_Fields = {
  __typename?: "kcca_District_aggregate_fields";
  avg?: Maybe<Kcca_District_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_District_Max_Fields>;
  min?: Maybe<Kcca_District_Min_Fields>;
  stddev?: Maybe<Kcca_District_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_District_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_District_Sum_Fields>;
  var_pop?: Maybe<Kcca_District_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_District_Var_Samp_Fields>;
  variance?: Maybe<Kcca_District_Variance_Fields>;
};

/** aggregate fields of "kcca.District" */
export type Kcca_District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_District_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.District" */
export type Kcca_District_Aggregate_Order_By = {
  avg?: Maybe<Kcca_District_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_District_Max_Order_By>;
  min?: Maybe<Kcca_District_Min_Order_By>;
  stddev?: Maybe<Kcca_District_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_District_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_District_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_District_Sum_Order_By>;
  var_pop?: Maybe<Kcca_District_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_District_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_District_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.District" */
export type Kcca_District_Arr_Rel_Insert_Input = {
  data: Array<Kcca_District_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_District_Avg_Fields = {
  __typename?: "kcca_District_avg_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.District" */
export type Kcca_District_Avg_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.District". All fields are combined with a logical 'AND'. */
export type Kcca_District_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_District_Bool_Exp>>>;
  _not?: Maybe<Kcca_District_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_District_Bool_Exp>>>;
  country_id?: Maybe<Bigint_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.District" */
export type Kcca_District_Inc_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.District" */
export type Kcca_District_Insert_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_District_Max_Fields = {
  __typename?: "kcca_District_max_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.District" */
export type Kcca_District_Max_Order_By = {
  country_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_District_Min_Fields = {
  __typename?: "kcca_District_min_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.District" */
export type Kcca_District_Min_Order_By = {
  country_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.District" */
export type Kcca_District_Mutation_Response = {
  __typename?: "kcca_District_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_District>;
};

/** input type for inserting object relation for remote table "kcca.District" */
export type Kcca_District_Obj_Rel_Insert_Input = {
  data: Kcca_District_Insert_Input;
};

/** ordering options when selecting data from "kcca.District" */
export type Kcca_District_Order_By = {
  country_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.District" */
export enum Kcca_District_Select_Column {
  /** column name */
  CountryId = "country_id",
  /** column name */
  Description = "description",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.District" */
export type Kcca_District_Set_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_District_Stddev_Fields = {
  __typename?: "kcca_District_stddev_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.District" */
export type Kcca_District_Stddev_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_District_Stddev_Pop_Fields = {
  __typename?: "kcca_District_stddev_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.District" */
export type Kcca_District_Stddev_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_District_Stddev_Samp_Fields = {
  __typename?: "kcca_District_stddev_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.District" */
export type Kcca_District_Stddev_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_District_Sum_Fields = {
  __typename?: "kcca_District_sum_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.District" */
export type Kcca_District_Sum_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_District_Var_Pop_Fields = {
  __typename?: "kcca_District_var_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.District" */
export type Kcca_District_Var_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_District_Var_Samp_Fields = {
  __typename?: "kcca_District_var_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.District" */
export type Kcca_District_Var_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_District_Variance_Fields = {
  __typename?: "kcca_District_variance_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.District" */
export type Kcca_District_Variance_Order_By = {
  country_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.Division" */
export type Kcca_Division = {
  __typename?: "kcca_Division";
  address?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["bigint"]>;
  code?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.Division" */
export type Kcca_Division_Aggregate = {
  __typename?: "kcca_Division_aggregate";
  aggregate?: Maybe<Kcca_Division_Aggregate_Fields>;
  nodes: Array<Kcca_Division>;
};

/** aggregate fields of "kcca.Division" */
export type Kcca_Division_Aggregate_Fields = {
  __typename?: "kcca_Division_aggregate_fields";
  avg?: Maybe<Kcca_Division_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_Division_Max_Fields>;
  min?: Maybe<Kcca_Division_Min_Fields>;
  stddev?: Maybe<Kcca_Division_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_Division_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_Division_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_Division_Sum_Fields>;
  var_pop?: Maybe<Kcca_Division_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_Division_Var_Samp_Fields>;
  variance?: Maybe<Kcca_Division_Variance_Fields>;
};

/** aggregate fields of "kcca.Division" */
export type Kcca_Division_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_Division_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.Division" */
export type Kcca_Division_Aggregate_Order_By = {
  avg?: Maybe<Kcca_Division_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_Division_Max_Order_By>;
  min?: Maybe<Kcca_Division_Min_Order_By>;
  stddev?: Maybe<Kcca_Division_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_Division_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_Division_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_Division_Sum_Order_By>;
  var_pop?: Maybe<Kcca_Division_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_Division_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_Division_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.Division" */
export type Kcca_Division_Arr_Rel_Insert_Input = {
  data: Array<Kcca_Division_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_Division_Avg_Fields = {
  __typename?: "kcca_Division_avg_fields";
  city_id?: Maybe<Scalars["Float"]>;
  code?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.Division" */
export type Kcca_Division_Avg_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.Division". All fields are combined with a logical 'AND'. */
export type Kcca_Division_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_Division_Bool_Exp>>>;
  _not?: Maybe<Kcca_Division_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_Division_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  city_id?: Maybe<Bigint_Comparison_Exp>;
  code?: Maybe<Bigint_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.Division" */
export type Kcca_Division_Inc_Input = {
  city_id?: Maybe<Scalars["bigint"]>;
  code?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.Division" */
export type Kcca_Division_Insert_Input = {
  address?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["bigint"]>;
  code?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_Division_Max_Fields = {
  __typename?: "kcca_Division_max_fields";
  address?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["bigint"]>;
  code?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.Division" */
export type Kcca_Division_Max_Order_By = {
  address?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_Division_Min_Fields = {
  __typename?: "kcca_Division_min_fields";
  address?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["bigint"]>;
  code?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.Division" */
export type Kcca_Division_Min_Order_By = {
  address?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.Division" */
export type Kcca_Division_Mutation_Response = {
  __typename?: "kcca_Division_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_Division>;
};

/** input type for inserting object relation for remote table "kcca.Division" */
export type Kcca_Division_Obj_Rel_Insert_Input = {
  data: Kcca_Division_Insert_Input;
};

/** ordering options when selecting data from "kcca.Division" */
export type Kcca_Division_Order_By = {
  address?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.Division" */
export enum Kcca_Division_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  CityId = "city_id",
  /** column name */
  Code = "code",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  Email = "email",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  Telephone = "telephone",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.Division" */
export type Kcca_Division_Set_Input = {
  address?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["bigint"]>;
  code?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  email?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  telephone?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_Division_Stddev_Fields = {
  __typename?: "kcca_Division_stddev_fields";
  city_id?: Maybe<Scalars["Float"]>;
  code?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.Division" */
export type Kcca_Division_Stddev_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_Division_Stddev_Pop_Fields = {
  __typename?: "kcca_Division_stddev_pop_fields";
  city_id?: Maybe<Scalars["Float"]>;
  code?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.Division" */
export type Kcca_Division_Stddev_Pop_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_Division_Stddev_Samp_Fields = {
  __typename?: "kcca_Division_stddev_samp_fields";
  city_id?: Maybe<Scalars["Float"]>;
  code?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.Division" */
export type Kcca_Division_Stddev_Samp_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_Division_Sum_Fields = {
  __typename?: "kcca_Division_sum_fields";
  city_id?: Maybe<Scalars["bigint"]>;
  code?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.Division" */
export type Kcca_Division_Sum_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_Division_Var_Pop_Fields = {
  __typename?: "kcca_Division_var_pop_fields";
  city_id?: Maybe<Scalars["Float"]>;
  code?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.Division" */
export type Kcca_Division_Var_Pop_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_Division_Var_Samp_Fields = {
  __typename?: "kcca_Division_var_samp_fields";
  city_id?: Maybe<Scalars["Float"]>;
  code?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.Division" */
export type Kcca_Division_Var_Samp_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_Division_Variance_Fields = {
  __typename?: "kcca_Division_variance_fields";
  city_id?: Maybe<Scalars["Float"]>;
  code?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.Division" */
export type Kcca_Division_Variance_Order_By = {
  city_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.Parish" */
export type Kcca_Parish = {
  __typename?: "kcca_Parish";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.Parish" */
export type Kcca_Parish_Aggregate = {
  __typename?: "kcca_Parish_aggregate";
  aggregate?: Maybe<Kcca_Parish_Aggregate_Fields>;
  nodes: Array<Kcca_Parish>;
};

/** aggregate fields of "kcca.Parish" */
export type Kcca_Parish_Aggregate_Fields = {
  __typename?: "kcca_Parish_aggregate_fields";
  avg?: Maybe<Kcca_Parish_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_Parish_Max_Fields>;
  min?: Maybe<Kcca_Parish_Min_Fields>;
  stddev?: Maybe<Kcca_Parish_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_Parish_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_Parish_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_Parish_Sum_Fields>;
  var_pop?: Maybe<Kcca_Parish_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_Parish_Var_Samp_Fields>;
  variance?: Maybe<Kcca_Parish_Variance_Fields>;
};

/** aggregate fields of "kcca.Parish" */
export type Kcca_Parish_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_Parish_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.Parish" */
export type Kcca_Parish_Aggregate_Order_By = {
  avg?: Maybe<Kcca_Parish_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_Parish_Max_Order_By>;
  min?: Maybe<Kcca_Parish_Min_Order_By>;
  stddev?: Maybe<Kcca_Parish_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_Parish_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_Parish_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_Parish_Sum_Order_By>;
  var_pop?: Maybe<Kcca_Parish_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_Parish_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_Parish_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.Parish" */
export type Kcca_Parish_Arr_Rel_Insert_Input = {
  data: Array<Kcca_Parish_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_Parish_Avg_Fields = {
  __typename?: "kcca_Parish_avg_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.Parish" */
export type Kcca_Parish_Avg_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.Parish". All fields are combined with a logical 'AND'. */
export type Kcca_Parish_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_Parish_Bool_Exp>>>;
  _not?: Maybe<Kcca_Parish_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_Parish_Bool_Exp>>>;
  country_id?: Maybe<Float8_Comparison_Exp>;
  county_id?: Maybe<Bigint_Comparison_Exp>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  district_id?: Maybe<Bigint_Comparison_Exp>;
  division_id?: Maybe<Float8_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  sub_county_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.Parish" */
export type Kcca_Parish_Inc_Input = {
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.Parish" */
export type Kcca_Parish_Insert_Input = {
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_Parish_Max_Fields = {
  __typename?: "kcca_Parish_max_fields";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.Parish" */
export type Kcca_Parish_Max_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_Parish_Min_Fields = {
  __typename?: "kcca_Parish_min_fields";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.Parish" */
export type Kcca_Parish_Min_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.Parish" */
export type Kcca_Parish_Mutation_Response = {
  __typename?: "kcca_Parish_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_Parish>;
};

/** input type for inserting object relation for remote table "kcca.Parish" */
export type Kcca_Parish_Obj_Rel_Insert_Input = {
  data: Kcca_Parish_Insert_Input;
};

/** ordering options when selecting data from "kcca.Parish" */
export type Kcca_Parish_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.Parish" */
export enum Kcca_Parish_Select_Column {
  /** column name */
  CountryId = "country_id",
  /** column name */
  CountyId = "county_id",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  Description = "description",
  /** column name */
  DistrictId = "district_id",
  /** column name */
  DivisionId = "division_id",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  SubCountyId = "sub_county_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.Parish" */
export type Kcca_Parish_Set_Input = {
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_Parish_Stddev_Fields = {
  __typename?: "kcca_Parish_stddev_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.Parish" */
export type Kcca_Parish_Stddev_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_Parish_Stddev_Pop_Fields = {
  __typename?: "kcca_Parish_stddev_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.Parish" */
export type Kcca_Parish_Stddev_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_Parish_Stddev_Samp_Fields = {
  __typename?: "kcca_Parish_stddev_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.Parish" */
export type Kcca_Parish_Stddev_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_Parish_Sum_Fields = {
  __typename?: "kcca_Parish_sum_fields";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.Parish" */
export type Kcca_Parish_Sum_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_Parish_Var_Pop_Fields = {
  __typename?: "kcca_Parish_var_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.Parish" */
export type Kcca_Parish_Var_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_Parish_Var_Samp_Fields = {
  __typename?: "kcca_Parish_var_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.Parish" */
export type Kcca_Parish_Var_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_Parish_Variance_Fields = {
  __typename?: "kcca_Parish_variance_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.Parish" */
export type Kcca_Parish_Variance_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.Property" */
export type Kcca_Property = {
  __typename?: "kcca_Property";
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  current_rateable_value?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["float8"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  parish_id?: Maybe<Scalars["float8"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["float8"]>;
  property_type_id?: Maybe<Scalars["float8"]>;
  rateable_value?: Maybe<Scalars["float8"]>;
  rating_zone_id?: Maybe<Scalars["float8"]>;
  remisson?: Maybe<Scalars["float8"]>;
  road_others?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["float8"]>;
  supplementary?: Maybe<Scalars["float8"]>;
  village_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "kcca.PropertyFloors" */
export type Kcca_PropertyFloors = {
  __typename?: "kcca_PropertyFloors";
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Aggregate = {
  __typename?: "kcca_PropertyFloors_aggregate";
  aggregate?: Maybe<Kcca_PropertyFloors_Aggregate_Fields>;
  nodes: Array<Kcca_PropertyFloors>;
};

/** aggregate fields of "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Aggregate_Fields = {
  __typename?: "kcca_PropertyFloors_aggregate_fields";
  avg?: Maybe<Kcca_PropertyFloors_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_PropertyFloors_Max_Fields>;
  min?: Maybe<Kcca_PropertyFloors_Min_Fields>;
  stddev?: Maybe<Kcca_PropertyFloors_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_PropertyFloors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_PropertyFloors_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_PropertyFloors_Sum_Fields>;
  var_pop?: Maybe<Kcca_PropertyFloors_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_PropertyFloors_Var_Samp_Fields>;
  variance?: Maybe<Kcca_PropertyFloors_Variance_Fields>;
};

/** aggregate fields of "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_PropertyFloors_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Aggregate_Order_By = {
  avg?: Maybe<Kcca_PropertyFloors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_PropertyFloors_Max_Order_By>;
  min?: Maybe<Kcca_PropertyFloors_Min_Order_By>;
  stddev?: Maybe<Kcca_PropertyFloors_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_PropertyFloors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_PropertyFloors_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_PropertyFloors_Sum_Order_By>;
  var_pop?: Maybe<Kcca_PropertyFloors_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_PropertyFloors_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_PropertyFloors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Arr_Rel_Insert_Input = {
  data: Array<Kcca_PropertyFloors_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_PropertyFloors_Avg_Fields = {
  __typename?: "kcca_PropertyFloors_avg_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Avg_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.PropertyFloors". All fields are combined with a logical 'AND'. */
export type Kcca_PropertyFloors_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_PropertyFloors_Bool_Exp>>>;
  _not?: Maybe<Kcca_PropertyFloors_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_PropertyFloors_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  grade_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Inc_Input = {
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Insert_Input = {
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_PropertyFloors_Max_Fields = {
  __typename?: "kcca_PropertyFloors_max_fields";
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Max_Order_By = {
  file?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_PropertyFloors_Min_Fields = {
  __typename?: "kcca_PropertyFloors_min_fields";
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Min_Order_By = {
  file?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Mutation_Response = {
  __typename?: "kcca_PropertyFloors_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_PropertyFloors>;
};

/** input type for inserting object relation for remote table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Obj_Rel_Insert_Input = {
  data: Kcca_PropertyFloors_Insert_Input;
};

/** ordering options when selecting data from "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Order_By = {
  file?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.PropertyFloors" */
export enum Kcca_PropertyFloors_Select_Column {
  /** column name */
  File = "file",
  /** column name */
  GradeId = "grade_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Set_Input = {
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_PropertyFloors_Stddev_Fields = {
  __typename?: "kcca_PropertyFloors_stddev_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Stddev_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_PropertyFloors_Stddev_Pop_Fields = {
  __typename?: "kcca_PropertyFloors_stddev_pop_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Stddev_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_PropertyFloors_Stddev_Samp_Fields = {
  __typename?: "kcca_PropertyFloors_stddev_samp_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Stddev_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_PropertyFloors_Sum_Fields = {
  __typename?: "kcca_PropertyFloors_sum_fields";
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Sum_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_PropertyFloors_Var_Pop_Fields = {
  __typename?: "kcca_PropertyFloors_var_pop_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Var_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_PropertyFloors_Var_Samp_Fields = {
  __typename?: "kcca_PropertyFloors_var_samp_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Var_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_PropertyFloors_Variance_Fields = {
  __typename?: "kcca_PropertyFloors_variance_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.PropertyFloors" */
export type Kcca_PropertyFloors_Variance_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms = {
  __typename?: "kcca_PropertyNumberBedrooms";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Aggregate = {
  __typename?: "kcca_PropertyNumberBedrooms_aggregate";
  aggregate?: Maybe<Kcca_PropertyNumberBedrooms_Aggregate_Fields>;
  nodes: Array<Kcca_PropertyNumberBedrooms>;
};

/** aggregate fields of "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Aggregate_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_aggregate_fields";
  avg?: Maybe<Kcca_PropertyNumberBedrooms_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_PropertyNumberBedrooms_Max_Fields>;
  min?: Maybe<Kcca_PropertyNumberBedrooms_Min_Fields>;
  stddev?: Maybe<Kcca_PropertyNumberBedrooms_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_PropertyNumberBedrooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_PropertyNumberBedrooms_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_PropertyNumberBedrooms_Sum_Fields>;
  var_pop?: Maybe<Kcca_PropertyNumberBedrooms_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_PropertyNumberBedrooms_Var_Samp_Fields>;
  variance?: Maybe<Kcca_PropertyNumberBedrooms_Variance_Fields>;
};

/** aggregate fields of "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_PropertyNumberBedrooms_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Aggregate_Order_By = {
  avg?: Maybe<Kcca_PropertyNumberBedrooms_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_PropertyNumberBedrooms_Max_Order_By>;
  min?: Maybe<Kcca_PropertyNumberBedrooms_Min_Order_By>;
  stddev?: Maybe<Kcca_PropertyNumberBedrooms_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_PropertyNumberBedrooms_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_PropertyNumberBedrooms_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_PropertyNumberBedrooms_Sum_Order_By>;
  var_pop?: Maybe<Kcca_PropertyNumberBedrooms_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_PropertyNumberBedrooms_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_PropertyNumberBedrooms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Arr_Rel_Insert_Input = {
  data: Array<Kcca_PropertyNumberBedrooms_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_PropertyNumberBedrooms_Avg_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Avg_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.PropertyNumberBedrooms". All fields are combined with a logical 'AND'. */
export type Kcca_PropertyNumberBedrooms_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_PropertyNumberBedrooms_Bool_Exp>>>;
  _not?: Maybe<Kcca_PropertyNumberBedrooms_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_PropertyNumberBedrooms_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Inc_Input = {
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Insert_Input = {
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_PropertyNumberBedrooms_Max_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_max_fields";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Max_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_PropertyNumberBedrooms_Min_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_min_fields";
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Min_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Mutation_Response = {
  __typename?: "kcca_PropertyNumberBedrooms_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_PropertyNumberBedrooms>;
};

/** input type for inserting object relation for remote table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Obj_Rel_Insert_Input = {
  data: Kcca_PropertyNumberBedrooms_Insert_Input;
};

/** ordering options when selecting data from "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.PropertyNumberBedrooms" */
export enum Kcca_PropertyNumberBedrooms_Select_Column {
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Set_Input = {
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_PropertyNumberBedrooms_Stddev_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_PropertyNumberBedrooms_Stddev_Pop_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_PropertyNumberBedrooms_Stddev_Samp_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_PropertyNumberBedrooms_Sum_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Sum_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_PropertyNumberBedrooms_Var_Pop_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_PropertyNumberBedrooms_Var_Samp_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_PropertyNumberBedrooms_Variance_Fields = {
  __typename?: "kcca_PropertyNumberBedrooms_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.PropertyNumberBedrooms" */
export type Kcca_PropertyNumberBedrooms_Variance_Order_By = {
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus = {
  __typename?: "kcca_PropertyRentedStatus";
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Aggregate = {
  __typename?: "kcca_PropertyRentedStatus_aggregate";
  aggregate?: Maybe<Kcca_PropertyRentedStatus_Aggregate_Fields>;
  nodes: Array<Kcca_PropertyRentedStatus>;
};

/** aggregate fields of "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Aggregate_Fields = {
  __typename?: "kcca_PropertyRentedStatus_aggregate_fields";
  avg?: Maybe<Kcca_PropertyRentedStatus_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_PropertyRentedStatus_Max_Fields>;
  min?: Maybe<Kcca_PropertyRentedStatus_Min_Fields>;
  stddev?: Maybe<Kcca_PropertyRentedStatus_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_PropertyRentedStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_PropertyRentedStatus_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_PropertyRentedStatus_Sum_Fields>;
  var_pop?: Maybe<Kcca_PropertyRentedStatus_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_PropertyRentedStatus_Var_Samp_Fields>;
  variance?: Maybe<Kcca_PropertyRentedStatus_Variance_Fields>;
};

/** aggregate fields of "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_PropertyRentedStatus_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Aggregate_Order_By = {
  avg?: Maybe<Kcca_PropertyRentedStatus_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_PropertyRentedStatus_Max_Order_By>;
  min?: Maybe<Kcca_PropertyRentedStatus_Min_Order_By>;
  stddev?: Maybe<Kcca_PropertyRentedStatus_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_PropertyRentedStatus_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_PropertyRentedStatus_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_PropertyRentedStatus_Sum_Order_By>;
  var_pop?: Maybe<Kcca_PropertyRentedStatus_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_PropertyRentedStatus_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_PropertyRentedStatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Arr_Rel_Insert_Input = {
  data: Array<Kcca_PropertyRentedStatus_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_PropertyRentedStatus_Avg_Fields = {
  __typename?: "kcca_PropertyRentedStatus_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.PropertyRentedStatus". All fields are combined with a logical 'AND'. */
export type Kcca_PropertyRentedStatus_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_PropertyRentedStatus_Bool_Exp>>>;
  _not?: Maybe<Kcca_PropertyRentedStatus_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_PropertyRentedStatus_Bool_Exp>>>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  created_date?: Maybe<Date_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Inc_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Insert_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_PropertyRentedStatus_Max_Fields = {
  __typename?: "kcca_PropertyRentedStatus_max_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Max_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_PropertyRentedStatus_Min_Fields = {
  __typename?: "kcca_PropertyRentedStatus_min_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Min_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Mutation_Response = {
  __typename?: "kcca_PropertyRentedStatus_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_PropertyRentedStatus>;
};

/** input type for inserting object relation for remote table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Obj_Rel_Insert_Input = {
  data: Kcca_PropertyRentedStatus_Insert_Input;
};

/** ordering options when selecting data from "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Order_By = {
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.PropertyRentedStatus" */
export enum Kcca_PropertyRentedStatus_Select_Column {
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Set_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  created_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_PropertyRentedStatus_Stddev_Fields = {
  __typename?: "kcca_PropertyRentedStatus_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_PropertyRentedStatus_Stddev_Pop_Fields = {
  __typename?: "kcca_PropertyRentedStatus_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_PropertyRentedStatus_Stddev_Samp_Fields = {
  __typename?: "kcca_PropertyRentedStatus_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_PropertyRentedStatus_Sum_Fields = {
  __typename?: "kcca_PropertyRentedStatus_sum_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_PropertyRentedStatus_Var_Pop_Fields = {
  __typename?: "kcca_PropertyRentedStatus_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_PropertyRentedStatus_Var_Samp_Fields = {
  __typename?: "kcca_PropertyRentedStatus_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_PropertyRentedStatus_Variance_Fields = {
  __typename?: "kcca_PropertyRentedStatus_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.PropertyRentedStatus" */
export type Kcca_PropertyRentedStatus_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.PropertySecurity" */
export type Kcca_PropertySecurity = {
  __typename?: "kcca_PropertySecurity";
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Aggregate = {
  __typename?: "kcca_PropertySecurity_aggregate";
  aggregate?: Maybe<Kcca_PropertySecurity_Aggregate_Fields>;
  nodes: Array<Kcca_PropertySecurity>;
};

/** aggregate fields of "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Aggregate_Fields = {
  __typename?: "kcca_PropertySecurity_aggregate_fields";
  avg?: Maybe<Kcca_PropertySecurity_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_PropertySecurity_Max_Fields>;
  min?: Maybe<Kcca_PropertySecurity_Min_Fields>;
  stddev?: Maybe<Kcca_PropertySecurity_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_PropertySecurity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_PropertySecurity_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_PropertySecurity_Sum_Fields>;
  var_pop?: Maybe<Kcca_PropertySecurity_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_PropertySecurity_Var_Samp_Fields>;
  variance?: Maybe<Kcca_PropertySecurity_Variance_Fields>;
};

/** aggregate fields of "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_PropertySecurity_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Aggregate_Order_By = {
  avg?: Maybe<Kcca_PropertySecurity_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_PropertySecurity_Max_Order_By>;
  min?: Maybe<Kcca_PropertySecurity_Min_Order_By>;
  stddev?: Maybe<Kcca_PropertySecurity_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_PropertySecurity_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_PropertySecurity_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_PropertySecurity_Sum_Order_By>;
  var_pop?: Maybe<Kcca_PropertySecurity_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_PropertySecurity_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_PropertySecurity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Arr_Rel_Insert_Input = {
  data: Array<Kcca_PropertySecurity_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_PropertySecurity_Avg_Fields = {
  __typename?: "kcca_PropertySecurity_avg_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Avg_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.PropertySecurity". All fields are combined with a logical 'AND'. */
export type Kcca_PropertySecurity_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_PropertySecurity_Bool_Exp>>>;
  _not?: Maybe<Kcca_PropertySecurity_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_PropertySecurity_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  grade_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Inc_Input = {
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Insert_Input = {
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_PropertySecurity_Max_Fields = {
  __typename?: "kcca_PropertySecurity_max_fields";
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Max_Order_By = {
  file?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_PropertySecurity_Min_Fields = {
  __typename?: "kcca_PropertySecurity_min_fields";
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Min_Order_By = {
  file?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Mutation_Response = {
  __typename?: "kcca_PropertySecurity_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_PropertySecurity>;
};

/** input type for inserting object relation for remote table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Obj_Rel_Insert_Input = {
  data: Kcca_PropertySecurity_Insert_Input;
};

/** ordering options when selecting data from "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Order_By = {
  file?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.PropertySecurity" */
export enum Kcca_PropertySecurity_Select_Column {
  /** column name */
  File = "file",
  /** column name */
  GradeId = "grade_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Set_Input = {
  file?: Maybe<Scalars["String"]>;
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_PropertySecurity_Stddev_Fields = {
  __typename?: "kcca_PropertySecurity_stddev_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Stddev_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_PropertySecurity_Stddev_Pop_Fields = {
  __typename?: "kcca_PropertySecurity_stddev_pop_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Stddev_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_PropertySecurity_Stddev_Samp_Fields = {
  __typename?: "kcca_PropertySecurity_stddev_samp_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Stddev_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_PropertySecurity_Sum_Fields = {
  __typename?: "kcca_PropertySecurity_sum_fields";
  grade_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Sum_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_PropertySecurity_Var_Pop_Fields = {
  __typename?: "kcca_PropertySecurity_var_pop_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Var_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_PropertySecurity_Var_Samp_Fields = {
  __typename?: "kcca_PropertySecurity_var_samp_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Var_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_PropertySecurity_Variance_Fields = {
  __typename?: "kcca_PropertySecurity_variance_fields";
  grade_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.PropertySecurity" */
export type Kcca_PropertySecurity_Variance_Order_By = {
  grade_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.PropertySubType" */
export type Kcca_PropertySubType = {
  __typename?: "kcca_PropertySubType";
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.PropertySubType" */
export type Kcca_PropertySubType_Aggregate = {
  __typename?: "kcca_PropertySubType_aggregate";
  aggregate?: Maybe<Kcca_PropertySubType_Aggregate_Fields>;
  nodes: Array<Kcca_PropertySubType>;
};

/** aggregate fields of "kcca.PropertySubType" */
export type Kcca_PropertySubType_Aggregate_Fields = {
  __typename?: "kcca_PropertySubType_aggregate_fields";
  avg?: Maybe<Kcca_PropertySubType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_PropertySubType_Max_Fields>;
  min?: Maybe<Kcca_PropertySubType_Min_Fields>;
  stddev?: Maybe<Kcca_PropertySubType_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_PropertySubType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_PropertySubType_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_PropertySubType_Sum_Fields>;
  var_pop?: Maybe<Kcca_PropertySubType_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_PropertySubType_Var_Samp_Fields>;
  variance?: Maybe<Kcca_PropertySubType_Variance_Fields>;
};

/** aggregate fields of "kcca.PropertySubType" */
export type Kcca_PropertySubType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_PropertySubType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Aggregate_Order_By = {
  avg?: Maybe<Kcca_PropertySubType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_PropertySubType_Max_Order_By>;
  min?: Maybe<Kcca_PropertySubType_Min_Order_By>;
  stddev?: Maybe<Kcca_PropertySubType_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_PropertySubType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_PropertySubType_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_PropertySubType_Sum_Order_By>;
  var_pop?: Maybe<Kcca_PropertySubType_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_PropertySubType_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_PropertySubType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Arr_Rel_Insert_Input = {
  data: Array<Kcca_PropertySubType_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_PropertySubType_Avg_Fields = {
  __typename?: "kcca_PropertySubType_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.PropertySubType". All fields are combined with a logical 'AND'. */
export type Kcca_PropertySubType_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_PropertySubType_Bool_Exp>>>;
  _not?: Maybe<Kcca_PropertySubType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_PropertySubType_Bool_Exp>>>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Inc_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Insert_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_PropertySubType_Max_Fields = {
  __typename?: "kcca_PropertySubType_max_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Max_Order_By = {
  created_by?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_PropertySubType_Min_Fields = {
  __typename?: "kcca_PropertySubType_min_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Min_Order_By = {
  created_by?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Mutation_Response = {
  __typename?: "kcca_PropertySubType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_PropertySubType>;
};

/** input type for inserting object relation for remote table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Obj_Rel_Insert_Input = {
  data: Kcca_PropertySubType_Insert_Input;
};

/** ordering options when selecting data from "kcca.PropertySubType" */
export type Kcca_PropertySubType_Order_By = {
  created_by?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.PropertySubType" */
export enum Kcca_PropertySubType_Select_Column {
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Set_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_PropertySubType_Stddev_Fields = {
  __typename?: "kcca_PropertySubType_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_PropertySubType_Stddev_Pop_Fields = {
  __typename?: "kcca_PropertySubType_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_PropertySubType_Stddev_Samp_Fields = {
  __typename?: "kcca_PropertySubType_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_PropertySubType_Sum_Fields = {
  __typename?: "kcca_PropertySubType_sum_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_PropertySubType_Var_Pop_Fields = {
  __typename?: "kcca_PropertySubType_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_PropertySubType_Var_Samp_Fields = {
  __typename?: "kcca_PropertySubType_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_PropertySubType_Variance_Fields = {
  __typename?: "kcca_PropertySubType_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.PropertySubType" */
export type Kcca_PropertySubType_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.PropertyType" */
export type Kcca_PropertyType = {
  __typename?: "kcca_PropertyType";
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  payment_frequency_id?: Maybe<Scalars["float8"]>;
  reference_name?: Maybe<Scalars["String"]>;
  revenue_type_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  ura_code?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.PropertyType" */
export type Kcca_PropertyType_Aggregate = {
  __typename?: "kcca_PropertyType_aggregate";
  aggregate?: Maybe<Kcca_PropertyType_Aggregate_Fields>;
  nodes: Array<Kcca_PropertyType>;
};

/** aggregate fields of "kcca.PropertyType" */
export type Kcca_PropertyType_Aggregate_Fields = {
  __typename?: "kcca_PropertyType_aggregate_fields";
  avg?: Maybe<Kcca_PropertyType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_PropertyType_Max_Fields>;
  min?: Maybe<Kcca_PropertyType_Min_Fields>;
  stddev?: Maybe<Kcca_PropertyType_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_PropertyType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_PropertyType_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_PropertyType_Sum_Fields>;
  var_pop?: Maybe<Kcca_PropertyType_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_PropertyType_Var_Samp_Fields>;
  variance?: Maybe<Kcca_PropertyType_Variance_Fields>;
};

/** aggregate fields of "kcca.PropertyType" */
export type Kcca_PropertyType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_PropertyType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.PropertyType" */
export type Kcca_PropertyType_Aggregate_Order_By = {
  avg?: Maybe<Kcca_PropertyType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_PropertyType_Max_Order_By>;
  min?: Maybe<Kcca_PropertyType_Min_Order_By>;
  stddev?: Maybe<Kcca_PropertyType_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_PropertyType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_PropertyType_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_PropertyType_Sum_Order_By>;
  var_pop?: Maybe<Kcca_PropertyType_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_PropertyType_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_PropertyType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.PropertyType" */
export type Kcca_PropertyType_Arr_Rel_Insert_Input = {
  data: Array<Kcca_PropertyType_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_PropertyType_Avg_Fields = {
  __typename?: "kcca_PropertyType_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  payment_frequency_id?: Maybe<Scalars["Float"]>;
  revenue_type_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.PropertyType". All fields are combined with a logical 'AND'. */
export type Kcca_PropertyType_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_PropertyType_Bool_Exp>>>;
  _not?: Maybe<Kcca_PropertyType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_PropertyType_Bool_Exp>>>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payment_frequency_id?: Maybe<Float8_Comparison_Exp>;
  reference_name?: Maybe<String_Comparison_Exp>;
  revenue_type_id?: Maybe<Bigint_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  ura_code?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.PropertyType" */
export type Kcca_PropertyType_Inc_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  payment_frequency_id?: Maybe<Scalars["float8"]>;
  revenue_type_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.PropertyType" */
export type Kcca_PropertyType_Insert_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  payment_frequency_id?: Maybe<Scalars["float8"]>;
  reference_name?: Maybe<Scalars["String"]>;
  revenue_type_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  ura_code?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_PropertyType_Max_Fields = {
  __typename?: "kcca_PropertyType_max_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  payment_frequency_id?: Maybe<Scalars["float8"]>;
  reference_name?: Maybe<Scalars["String"]>;
  revenue_type_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  ura_code?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Max_Order_By = {
  created_by?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  reference_name?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  ura_code?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_PropertyType_Min_Fields = {
  __typename?: "kcca_PropertyType_min_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  payment_frequency_id?: Maybe<Scalars["float8"]>;
  reference_name?: Maybe<Scalars["String"]>;
  revenue_type_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  ura_code?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Min_Order_By = {
  created_by?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  reference_name?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  ura_code?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.PropertyType" */
export type Kcca_PropertyType_Mutation_Response = {
  __typename?: "kcca_PropertyType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_PropertyType>;
};

/** input type for inserting object relation for remote table "kcca.PropertyType" */
export type Kcca_PropertyType_Obj_Rel_Insert_Input = {
  data: Kcca_PropertyType_Insert_Input;
};

/** ordering options when selecting data from "kcca.PropertyType" */
export type Kcca_PropertyType_Order_By = {
  created_by?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  reference_name?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  ura_code?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.PropertyType" */
export enum Kcca_PropertyType_Select_Column {
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  PaymentFrequencyId = "payment_frequency_id",
  /** column name */
  ReferenceName = "reference_name",
  /** column name */
  RevenueTypeId = "revenue_type_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  UraCode = "ura_code",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.PropertyType" */
export type Kcca_PropertyType_Set_Input = {
  created_by?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  payment_frequency_id?: Maybe<Scalars["float8"]>;
  reference_name?: Maybe<Scalars["String"]>;
  revenue_type_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  ura_code?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_PropertyType_Stddev_Fields = {
  __typename?: "kcca_PropertyType_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  payment_frequency_id?: Maybe<Scalars["Float"]>;
  revenue_type_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_PropertyType_Stddev_Pop_Fields = {
  __typename?: "kcca_PropertyType_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  payment_frequency_id?: Maybe<Scalars["Float"]>;
  revenue_type_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_PropertyType_Stddev_Samp_Fields = {
  __typename?: "kcca_PropertyType_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  payment_frequency_id?: Maybe<Scalars["Float"]>;
  revenue_type_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_PropertyType_Sum_Fields = {
  __typename?: "kcca_PropertyType_sum_fields";
  created_by?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  payment_frequency_id?: Maybe<Scalars["float8"]>;
  revenue_type_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_PropertyType_Var_Pop_Fields = {
  __typename?: "kcca_PropertyType_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  payment_frequency_id?: Maybe<Scalars["Float"]>;
  revenue_type_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_PropertyType_Var_Samp_Fields = {
  __typename?: "kcca_PropertyType_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  payment_frequency_id?: Maybe<Scalars["Float"]>;
  revenue_type_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_PropertyType_Variance_Fields = {
  __typename?: "kcca_PropertyType_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  payment_frequency_id?: Maybe<Scalars["Float"]>;
  revenue_type_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.PropertyType" */
export type Kcca_PropertyType_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  payment_frequency_id?: Maybe<Order_By>;
  revenue_type_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregated selection of "kcca.Property" */
export type Kcca_Property_Aggregate = {
  __typename?: "kcca_Property_aggregate";
  aggregate?: Maybe<Kcca_Property_Aggregate_Fields>;
  nodes: Array<Kcca_Property>;
};

/** aggregate fields of "kcca.Property" */
export type Kcca_Property_Aggregate_Fields = {
  __typename?: "kcca_Property_aggregate_fields";
  avg?: Maybe<Kcca_Property_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_Property_Max_Fields>;
  min?: Maybe<Kcca_Property_Min_Fields>;
  stddev?: Maybe<Kcca_Property_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_Property_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_Property_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_Property_Sum_Fields>;
  var_pop?: Maybe<Kcca_Property_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_Property_Var_Samp_Fields>;
  variance?: Maybe<Kcca_Property_Variance_Fields>;
};

/** aggregate fields of "kcca.Property" */
export type Kcca_Property_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_Property_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.Property" */
export type Kcca_Property_Aggregate_Order_By = {
  avg?: Maybe<Kcca_Property_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_Property_Max_Order_By>;
  min?: Maybe<Kcca_Property_Min_Order_By>;
  stddev?: Maybe<Kcca_Property_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_Property_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_Property_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_Property_Sum_Order_By>;
  var_pop?: Maybe<Kcca_Property_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_Property_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_Property_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.Property" */
export type Kcca_Property_Arr_Rel_Insert_Input = {
  data: Array<Kcca_Property_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_Property_Avg_Fields = {
  __typename?: "kcca_Property_avg_fields";
  created_by?: Maybe<Scalars["Float"]>;
  current_rateable_value?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  property_rented_status_id?: Maybe<Scalars["Float"]>;
  property_type_id?: Maybe<Scalars["Float"]>;
  rateable_value?: Maybe<Scalars["Float"]>;
  rating_zone_id?: Maybe<Scalars["Float"]>;
  remisson?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_property_type_id?: Maybe<Scalars["Float"]>;
  supplementary?: Maybe<Scalars["Float"]>;
  village_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.Property" */
export type Kcca_Property_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.Property". All fields are combined with a logical 'AND'. */
export type Kcca_Property_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_Property_Bool_Exp>>>;
  _not?: Maybe<Kcca_Property_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_Property_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  block_no?: Maybe<String_Comparison_Exp>;
  camv_id?: Maybe<String_Comparison_Exp>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  current_rateable_value?: Maybe<Float8_Comparison_Exp>;
  customer_id?: Maybe<Bigint_Comparison_Exp>;
  division_id?: Maybe<Float8_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  flat_no?: Maybe<String_Comparison_Exp>;
  gprs_cordinate_x?: Maybe<String_Comparison_Exp>;
  gprs_cordinate_y?: Maybe<String_Comparison_Exp>;
  gross_value?: Maybe<String_Comparison_Exp>;
  house_no?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  img_path?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  latitude?: Maybe<Float8_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  location_address?: Maybe<String_Comparison_Exp>;
  longitude?: Maybe<Float8_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  parish_id?: Maybe<Float8_Comparison_Exp>;
  particulars_frontages?: Maybe<String_Comparison_Exp>;
  plot_no?: Maybe<String_Comparison_Exp>;
  property_no?: Maybe<String_Comparison_Exp>;
  property_rented_status_id?: Maybe<Float8_Comparison_Exp>;
  property_type_id?: Maybe<Float8_Comparison_Exp>;
  rateable_value?: Maybe<Float8_Comparison_Exp>;
  rating_zone_id?: Maybe<Float8_Comparison_Exp>;
  remisson?: Maybe<Float8_Comparison_Exp>;
  road_others?: Maybe<String_Comparison_Exp>;
  serial_no?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Float8_Comparison_Exp>;
  street_id?: Maybe<String_Comparison_Exp>;
  sub_property_type_id?: Maybe<Float8_Comparison_Exp>;
  supplementary?: Maybe<Float8_Comparison_Exp>;
  village_id?: Maybe<Float8_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.Property" */
export type Kcca_Property_Inc_Input = {
  created_by?: Maybe<Scalars["float8"]>;
  current_rateable_value?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  parish_id?: Maybe<Scalars["float8"]>;
  property_rented_status_id?: Maybe<Scalars["float8"]>;
  property_type_id?: Maybe<Scalars["float8"]>;
  rateable_value?: Maybe<Scalars["float8"]>;
  rating_zone_id?: Maybe<Scalars["float8"]>;
  remisson?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
  sub_property_type_id?: Maybe<Scalars["float8"]>;
  supplementary?: Maybe<Scalars["float8"]>;
  village_id?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "kcca.Property" */
export type Kcca_Property_Insert_Input = {
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  current_rateable_value?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["float8"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  parish_id?: Maybe<Scalars["float8"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["float8"]>;
  property_type_id?: Maybe<Scalars["float8"]>;
  rateable_value?: Maybe<Scalars["float8"]>;
  rating_zone_id?: Maybe<Scalars["float8"]>;
  remisson?: Maybe<Scalars["float8"]>;
  road_others?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["float8"]>;
  supplementary?: Maybe<Scalars["float8"]>;
  village_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_Property_Max_Fields = {
  __typename?: "kcca_Property_max_fields";
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  current_rateable_value?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["float8"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  parish_id?: Maybe<Scalars["float8"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["float8"]>;
  property_type_id?: Maybe<Scalars["float8"]>;
  rateable_value?: Maybe<Scalars["float8"]>;
  rating_zone_id?: Maybe<Scalars["float8"]>;
  remisson?: Maybe<Scalars["float8"]>;
  road_others?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["float8"]>;
  supplementary?: Maybe<Scalars["float8"]>;
  village_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.Property" */
export type Kcca_Property_Max_Order_By = {
  address?: Maybe<Order_By>;
  block_no?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  flat_no?: Maybe<Order_By>;
  gprs_cordinate_x?: Maybe<Order_By>;
  gprs_cordinate_y?: Maybe<Order_By>;
  gross_value?: Maybe<Order_By>;
  house_no?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img_path?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  plot_no?: Maybe<Order_By>;
  property_no?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  road_others?: Maybe<Order_By>;
  serial_no?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_Property_Min_Fields = {
  __typename?: "kcca_Property_min_fields";
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  current_rateable_value?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["float8"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  parish_id?: Maybe<Scalars["float8"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["float8"]>;
  property_type_id?: Maybe<Scalars["float8"]>;
  rateable_value?: Maybe<Scalars["float8"]>;
  rating_zone_id?: Maybe<Scalars["float8"]>;
  remisson?: Maybe<Scalars["float8"]>;
  road_others?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["float8"]>;
  supplementary?: Maybe<Scalars["float8"]>;
  village_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.Property" */
export type Kcca_Property_Min_Order_By = {
  address?: Maybe<Order_By>;
  block_no?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  flat_no?: Maybe<Order_By>;
  gprs_cordinate_x?: Maybe<Order_By>;
  gprs_cordinate_y?: Maybe<Order_By>;
  gross_value?: Maybe<Order_By>;
  house_no?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img_path?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  plot_no?: Maybe<Order_By>;
  property_no?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  road_others?: Maybe<Order_By>;
  serial_no?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.Property" */
export type Kcca_Property_Mutation_Response = {
  __typename?: "kcca_Property_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_Property>;
};

/** input type for inserting object relation for remote table "kcca.Property" */
export type Kcca_Property_Obj_Rel_Insert_Input = {
  data: Kcca_Property_Insert_Input;
};

/** ordering options when selecting data from "kcca.Property" */
export type Kcca_Property_Order_By = {
  address?: Maybe<Order_By>;
  block_no?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  flat_no?: Maybe<Order_By>;
  gprs_cordinate_x?: Maybe<Order_By>;
  gprs_cordinate_y?: Maybe<Order_By>;
  gross_value?: Maybe<Order_By>;
  house_no?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img_path?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  plot_no?: Maybe<Order_By>;
  property_no?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  road_others?: Maybe<Order_By>;
  serial_no?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.Property" */
export enum Kcca_Property_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  BlockNo = "block_no",
  /** column name */
  CamvId = "camv_id",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CurrentRateableValue = "current_rateable_value",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  DivisionId = "division_id",
  /** column name */
  File = "file",
  /** column name */
  FlatNo = "flat_no",
  /** column name */
  GprsCordinateX = "gprs_cordinate_x",
  /** column name */
  GprsCordinateY = "gprs_cordinate_y",
  /** column name */
  GrossValue = "gross_value",
  /** column name */
  HouseNo = "house_no",
  /** column name */
  Id = "id",
  /** column name */
  ImgPath = "img_path",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Latitude = "latitude",
  /** column name */
  Line = "line",
  /** column name */
  LocationAddress = "location_address",
  /** column name */
  Longitude = "longitude",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  ParishId = "parish_id",
  /** column name */
  ParticularsFrontages = "particulars_frontages",
  /** column name */
  PlotNo = "plot_no",
  /** column name */
  PropertyNo = "property_no",
  /** column name */
  PropertyRentedStatusId = "property_rented_status_id",
  /** column name */
  PropertyTypeId = "property_type_id",
  /** column name */
  RateableValue = "rateable_value",
  /** column name */
  RatingZoneId = "rating_zone_id",
  /** column name */
  Remisson = "remisson",
  /** column name */
  RoadOthers = "road_others",
  /** column name */
  SerialNo = "serial_no",
  /** column name */
  StatusId = "status_id",
  /** column name */
  StreetId = "street_id",
  /** column name */
  SubPropertyTypeId = "sub_property_type_id",
  /** column name */
  Supplementary = "supplementary",
  /** column name */
  VillageId = "village_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.Property" */
export type Kcca_Property_Set_Input = {
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  current_rateable_value?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["float8"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  parish_id?: Maybe<Scalars["float8"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["float8"]>;
  property_type_id?: Maybe<Scalars["float8"]>;
  rateable_value?: Maybe<Scalars["float8"]>;
  rating_zone_id?: Maybe<Scalars["float8"]>;
  remisson?: Maybe<Scalars["float8"]>;
  road_others?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["float8"]>;
  supplementary?: Maybe<Scalars["float8"]>;
  village_id?: Maybe<Scalars["float8"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_Property_Stddev_Fields = {
  __typename?: "kcca_Property_stddev_fields";
  created_by?: Maybe<Scalars["Float"]>;
  current_rateable_value?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  property_rented_status_id?: Maybe<Scalars["Float"]>;
  property_type_id?: Maybe<Scalars["Float"]>;
  rateable_value?: Maybe<Scalars["Float"]>;
  rating_zone_id?: Maybe<Scalars["Float"]>;
  remisson?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_property_type_id?: Maybe<Scalars["Float"]>;
  supplementary?: Maybe<Scalars["Float"]>;
  village_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.Property" */
export type Kcca_Property_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_Property_Stddev_Pop_Fields = {
  __typename?: "kcca_Property_stddev_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  current_rateable_value?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  property_rented_status_id?: Maybe<Scalars["Float"]>;
  property_type_id?: Maybe<Scalars["Float"]>;
  rateable_value?: Maybe<Scalars["Float"]>;
  rating_zone_id?: Maybe<Scalars["Float"]>;
  remisson?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_property_type_id?: Maybe<Scalars["Float"]>;
  supplementary?: Maybe<Scalars["Float"]>;
  village_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.Property" */
export type Kcca_Property_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_Property_Stddev_Samp_Fields = {
  __typename?: "kcca_Property_stddev_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  current_rateable_value?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  property_rented_status_id?: Maybe<Scalars["Float"]>;
  property_type_id?: Maybe<Scalars["Float"]>;
  rateable_value?: Maybe<Scalars["Float"]>;
  rating_zone_id?: Maybe<Scalars["Float"]>;
  remisson?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_property_type_id?: Maybe<Scalars["Float"]>;
  supplementary?: Maybe<Scalars["Float"]>;
  village_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.Property" */
export type Kcca_Property_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_Property_Sum_Fields = {
  __typename?: "kcca_Property_sum_fields";
  created_by?: Maybe<Scalars["float8"]>;
  current_rateable_value?: Maybe<Scalars["float8"]>;
  customer_id?: Maybe<Scalars["bigint"]>;
  division_id?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["bigint"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  parish_id?: Maybe<Scalars["float8"]>;
  property_rented_status_id?: Maybe<Scalars["float8"]>;
  property_type_id?: Maybe<Scalars["float8"]>;
  rateable_value?: Maybe<Scalars["float8"]>;
  rating_zone_id?: Maybe<Scalars["float8"]>;
  remisson?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["float8"]>;
  sub_property_type_id?: Maybe<Scalars["float8"]>;
  supplementary?: Maybe<Scalars["float8"]>;
  village_id?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "kcca.Property" */
export type Kcca_Property_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_Property_Var_Pop_Fields = {
  __typename?: "kcca_Property_var_pop_fields";
  created_by?: Maybe<Scalars["Float"]>;
  current_rateable_value?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  property_rented_status_id?: Maybe<Scalars["Float"]>;
  property_type_id?: Maybe<Scalars["Float"]>;
  rateable_value?: Maybe<Scalars["Float"]>;
  rating_zone_id?: Maybe<Scalars["Float"]>;
  remisson?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_property_type_id?: Maybe<Scalars["Float"]>;
  supplementary?: Maybe<Scalars["Float"]>;
  village_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.Property" */
export type Kcca_Property_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_Property_Var_Samp_Fields = {
  __typename?: "kcca_Property_var_samp_fields";
  created_by?: Maybe<Scalars["Float"]>;
  current_rateable_value?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  property_rented_status_id?: Maybe<Scalars["Float"]>;
  property_type_id?: Maybe<Scalars["Float"]>;
  rateable_value?: Maybe<Scalars["Float"]>;
  rating_zone_id?: Maybe<Scalars["Float"]>;
  remisson?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_property_type_id?: Maybe<Scalars["Float"]>;
  supplementary?: Maybe<Scalars["Float"]>;
  village_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.Property" */
export type Kcca_Property_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_Property_Variance_Fields = {
  __typename?: "kcca_Property_variance_fields";
  created_by?: Maybe<Scalars["Float"]>;
  current_rateable_value?: Maybe<Scalars["Float"]>;
  customer_id?: Maybe<Scalars["Float"]>;
  division_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  property_rented_status_id?: Maybe<Scalars["Float"]>;
  property_type_id?: Maybe<Scalars["Float"]>;
  rateable_value?: Maybe<Scalars["Float"]>;
  rating_zone_id?: Maybe<Scalars["Float"]>;
  remisson?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_property_type_id?: Maybe<Scalars["Float"]>;
  supplementary?: Maybe<Scalars["Float"]>;
  village_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.Property" */
export type Kcca_Property_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.RawProperties1" */
export type Kcca_RawProperties1 = {
  __typename?: "kcca_RawProperties1";
  Cordinate_X?: Maybe<Scalars["String"]>;
  Cordinate_Y?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  GV?: Maybe<Scalars["String"]>;
  Other_Boundary_Fencespecify?: Maybe<Scalars["String"]>;
  Owner_Individual_Company?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  RV?: Maybe<Scalars["float8"]>;
  Reference_Number_New?: Maybe<Scalars["float8"]>;
  Roads_OtherSpecify?: Maybe<Scalars["String"]>;
  SiteWorks_Boundary_Fence?: Maybe<Scalars["String"]>;
  SiteWorks_Building_Number?: Maybe<Scalars["String"]>;
  SiteWorks_Compound?: Maybe<Scalars["String"]>;
  SiteWorks_Court?: Maybe<Scalars["String"]>;
  SiteWorks_Gate?: Maybe<Scalars["String"]>;
  SiteWorks_Other_Facilities?: Maybe<Scalars["String"]>;
  SiteWorks_Swimming_Pool?: Maybe<Scalars["String"]>;
  accomodation_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_bedrooms?: Maybe<Scalars["float8"]>;
  accomodation_built_up_area?: Maybe<Scalars["String"]>;
  accomodation_comment?: Maybe<Scalars["String"]>;
  accomodation_dining_rooms?: Maybe<Scalars["float8"]>;
  accomodation_entertainment_room?: Maybe<Scalars["float8"]>;
  accomodation_enviromental_noise?: Maybe<Scalars["String"]>;
  accomodation_garage?: Maybe<Scalars["float8"]>;
  accomodation_kitchen?: Maybe<Scalars["float8"]>;
  accomodation_laundry?: Maybe<Scalars["float8"]>;
  accomodation_others?: Maybe<Scalars["String"]>;
  accomodation_security?: Maybe<Scalars["String"]>;
  accomodation_shops?: Maybe<Scalars["float8"]>;
  accomodation_shower?: Maybe<Scalars["float8"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["float8"]>;
  accomodation_store?: Maybe<Scalars["float8"]>;
  accomodation_study_room?: Maybe<Scalars["float8"]>;
  accomodation_verandah?: Maybe<Scalars["float8"]>;
  accomodation_wc?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_wc_shower?: Maybe<Scalars["float8"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  alternate_firstname?: Maybe<Scalars["String"]>;
  alternate_landline_with_code?: Maybe<Scalars["String"]>;
  alternate_middlename?: Maybe<Scalars["String"]>;
  alternate_mobile_1?: Maybe<Scalars["String"]>;
  alternate_mobile_2?: Maybe<Scalars["float8"]>;
  alternate_surname?: Maybe<Scalars["String"]>;
  alternate_tittle?: Maybe<Scalars["String"]>;
  alternative_driving_permit?: Maybe<Scalars["String"]>;
  alternative_financial_card?: Maybe<Scalars["String"]>;
  alternative_national_id?: Maybe<Scalars["String"]>;
  alternative_nssf_card?: Maybe<Scalars["float8"]>;
  alternative_passport?: Maybe<Scalars["String"]>;
  alternative_village_id?: Maybe<Scalars["String"]>;
  building_block_number?: Maybe<Scalars["String"]>;
  building_condition?: Maybe<Scalars["String"]>;
  building_flat_number?: Maybe<Scalars["String"]>;
  building_floor_number?: Maybe<Scalars["float8"]>;
  building_name?: Maybe<Scalars["String"]>;
  building_number?: Maybe<Scalars["String"]>;
  building_other_services?: Maybe<Scalars["String"]>;
  building_parking_slots?: Maybe<Scalars["String"]>;
  building_parking_space_available?: Maybe<Scalars["String"]>;
  building_parking_space_sufficient?: Maybe<Scalars["String"]>;
  building_power_supply?: Maybe<Scalars["String"]>;
  building_rent?: Maybe<Scalars["String"]>;
  building_sanitation_type?: Maybe<Scalars["String"]>;
  building_type?: Maybe<Scalars["String"]>;
  building_vertical_circulation?: Maybe<Scalars["String"]>;
  building_water_accessibility?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  construction_ceiling?: Maybe<Scalars["String"]>;
  construction_door_others?: Maybe<Scalars["String"]>;
  construction_doors?: Maybe<Scalars["String"]>;
  construction_floors_finish?: Maybe<Scalars["String"]>;
  construction_foors_construction?: Maybe<Scalars["String"]>;
  construction_other_details?: Maybe<Scalars["String"]>;
  construction_roof_covering?: Maybe<Scalars["String"]>;
  construction_wall_finish?: Maybe<Scalars["String"]>;
  construction_walls?: Maybe<Scalars["String"]>;
  construction_window_others?: Maybe<Scalars["String"]>;
  construction_windows?: Maybe<Scalars["String"]>;
  contact_address?: Maybe<Scalars["String"]>;
  contact_country?: Maybe<Scalars["String"]>;
  contact_county_municipality?: Maybe<Scalars["String"]>;
  contact_district?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  contact_landline?: Maybe<Scalars["float8"]>;
  contact_mobile_1?: Maybe<Scalars["float8"]>;
  contact_mobile_2?: Maybe<Scalars["float8"]>;
  contact_parish_ward?: Maybe<Scalars["String"]>;
  contact_subcountry_division?: Maybe<Scalars["String"]>;
  contact_village_cell_zone?: Maybe<Scalars["String"]>;
  data_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  grade?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_marital_certificate?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_card?: Maybe<Scalars["float8"]>;
  identification_passport?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name_contact?: Maybe<Scalars["String"]>;
  owner_coin?: Maybe<Scalars["String"]>;
  owner_firstname?: Maybe<Scalars["String"]>;
  owner_legal_surname_maiden?: Maybe<Scalars["String"]>;
  owner_middlename?: Maybe<Scalars["String"]>;
  owner_othernames?: Maybe<Scalars["String"]>;
  owner_tin?: Maybe<Scalars["String"]>;
  owner_title?: Maybe<Scalars["bigint"]>;
  particulars_access?: Maybe<Scalars["String"]>;
  particulars_block_name?: Maybe<Scalars["String"]>;
  particulars_block_number?: Maybe<Scalars["String"]>;
  particulars_folio_number?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["float8"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["float8"]>;
  particulars_house_number?: Maybe<Scalars["String"]>;
  particulars_is_titled?: Maybe<Scalars["String"]>;
  particulars_land_reference?: Maybe<Scalars["String"]>;
  particulars_neighborhood?: Maybe<Scalars["String"]>;
  particulars_plot_number?: Maybe<Scalars["String"]>;
  particulars_plot_shape?: Maybe<Scalars["String"]>;
  particulars_property_name?: Maybe<Scalars["String"]>;
  particulars_road_street?: Maybe<Scalars["String"]>;
  particulars_topography_Others?: Maybe<Scalars["String"]>;
  particulars_topograpy?: Maybe<Scalars["String"]>;
  particulars_type_of_land_interest?: Maybe<Scalars["String"]>;
  particulars_volume?: Maybe<Scalars["String"]>;
  property_owner_occupied_or_rented?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  pvillage?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["float8"]>;
  siteworks_others?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.RawProperties1" */
export type Kcca_RawProperties1_Aggregate = {
  __typename?: "kcca_RawProperties1_aggregate";
  aggregate?: Maybe<Kcca_RawProperties1_Aggregate_Fields>;
  nodes: Array<Kcca_RawProperties1>;
};

/** aggregate fields of "kcca.RawProperties1" */
export type Kcca_RawProperties1_Aggregate_Fields = {
  __typename?: "kcca_RawProperties1_aggregate_fields";
  avg?: Maybe<Kcca_RawProperties1_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_RawProperties1_Max_Fields>;
  min?: Maybe<Kcca_RawProperties1_Min_Fields>;
  stddev?: Maybe<Kcca_RawProperties1_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_RawProperties1_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_RawProperties1_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_RawProperties1_Sum_Fields>;
  var_pop?: Maybe<Kcca_RawProperties1_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_RawProperties1_Var_Samp_Fields>;
  variance?: Maybe<Kcca_RawProperties1_Variance_Fields>;
};

/** aggregate fields of "kcca.RawProperties1" */
export type Kcca_RawProperties1_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_RawProperties1_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Aggregate_Order_By = {
  avg?: Maybe<Kcca_RawProperties1_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_RawProperties1_Max_Order_By>;
  min?: Maybe<Kcca_RawProperties1_Min_Order_By>;
  stddev?: Maybe<Kcca_RawProperties1_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_RawProperties1_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_RawProperties1_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_RawProperties1_Sum_Order_By>;
  var_pop?: Maybe<Kcca_RawProperties1_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_RawProperties1_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_RawProperties1_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Arr_Rel_Insert_Input = {
  data: Array<Kcca_RawProperties1_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_RawProperties1_Avg_Fields = {
  __typename?: "kcca_RawProperties1_avg_fields";
  RV?: Maybe<Scalars["Float"]>;
  Reference_Number_New?: Maybe<Scalars["Float"]>;
  accomodation_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_bedrooms?: Maybe<Scalars["Float"]>;
  accomodation_dining_rooms?: Maybe<Scalars["Float"]>;
  accomodation_entertainment_room?: Maybe<Scalars["Float"]>;
  accomodation_garage?: Maybe<Scalars["Float"]>;
  accomodation_kitchen?: Maybe<Scalars["Float"]>;
  accomodation_laundry?: Maybe<Scalars["Float"]>;
  accomodation_shops?: Maybe<Scalars["Float"]>;
  accomodation_shower?: Maybe<Scalars["Float"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["Float"]>;
  accomodation_store?: Maybe<Scalars["Float"]>;
  accomodation_study_room?: Maybe<Scalars["Float"]>;
  accomodation_verandah?: Maybe<Scalars["Float"]>;
  accomodation_wc?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_wc_shower?: Maybe<Scalars["Float"]>;
  alternate_mobile_2?: Maybe<Scalars["Float"]>;
  alternative_nssf_card?: Maybe<Scalars["Float"]>;
  building_floor_number?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  contact_landline?: Maybe<Scalars["Float"]>;
  contact_mobile_1?: Maybe<Scalars["Float"]>;
  contact_mobile_2?: Maybe<Scalars["Float"]>;
  grade?: Maybe<Scalars["Float"]>;
  identification_nssf_card?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  owner_title?: Maybe<Scalars["Float"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["Float"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Avg_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.RawProperties1". All fields are combined with a logical 'AND'. */
export type Kcca_RawProperties1_Bool_Exp = {
  Cordinate_X?: Maybe<String_Comparison_Exp>;
  Cordinate_Y?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  GV?: Maybe<String_Comparison_Exp>;
  Other_Boundary_Fencespecify?: Maybe<String_Comparison_Exp>;
  Owner_Individual_Company?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  RV?: Maybe<Float8_Comparison_Exp>;
  Reference_Number_New?: Maybe<Float8_Comparison_Exp>;
  Roads_OtherSpecify?: Maybe<String_Comparison_Exp>;
  SiteWorks_Boundary_Fence?: Maybe<String_Comparison_Exp>;
  SiteWorks_Building_Number?: Maybe<String_Comparison_Exp>;
  SiteWorks_Compound?: Maybe<String_Comparison_Exp>;
  SiteWorks_Court?: Maybe<String_Comparison_Exp>;
  SiteWorks_Gate?: Maybe<String_Comparison_Exp>;
  SiteWorks_Other_Facilities?: Maybe<String_Comparison_Exp>;
  SiteWorks_Swimming_Pool?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Kcca_RawProperties1_Bool_Exp>>>;
  _not?: Maybe<Kcca_RawProperties1_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_RawProperties1_Bool_Exp>>>;
  accomodation_bathroom?: Maybe<Float8_Comparison_Exp>;
  accomodation_bathroom_shower?: Maybe<Float8_Comparison_Exp>;
  accomodation_bedrooms?: Maybe<Float8_Comparison_Exp>;
  accomodation_built_up_area?: Maybe<String_Comparison_Exp>;
  accomodation_comment?: Maybe<String_Comparison_Exp>;
  accomodation_dining_rooms?: Maybe<Float8_Comparison_Exp>;
  accomodation_entertainment_room?: Maybe<Float8_Comparison_Exp>;
  accomodation_enviromental_noise?: Maybe<String_Comparison_Exp>;
  accomodation_garage?: Maybe<Float8_Comparison_Exp>;
  accomodation_kitchen?: Maybe<Float8_Comparison_Exp>;
  accomodation_laundry?: Maybe<Float8_Comparison_Exp>;
  accomodation_others?: Maybe<String_Comparison_Exp>;
  accomodation_security?: Maybe<String_Comparison_Exp>;
  accomodation_shops?: Maybe<Float8_Comparison_Exp>;
  accomodation_shower?: Maybe<Float8_Comparison_Exp>;
  accomodation_sitting_room_lounge_number?: Maybe<Float8_Comparison_Exp>;
  accomodation_store?: Maybe<Float8_Comparison_Exp>;
  accomodation_study_room?: Maybe<Float8_Comparison_Exp>;
  accomodation_verandah?: Maybe<Float8_Comparison_Exp>;
  accomodation_wc?: Maybe<Float8_Comparison_Exp>;
  accomodation_wc_bathroom?: Maybe<Float8_Comparison_Exp>;
  accomodation_wc_bathroom_shower?: Maybe<Float8_Comparison_Exp>;
  accomodation_wc_shower?: Maybe<Float8_Comparison_Exp>;
  alternate_email?: Maybe<String_Comparison_Exp>;
  alternate_firstname?: Maybe<String_Comparison_Exp>;
  alternate_landline_with_code?: Maybe<String_Comparison_Exp>;
  alternate_middlename?: Maybe<String_Comparison_Exp>;
  alternate_mobile_1?: Maybe<String_Comparison_Exp>;
  alternate_mobile_2?: Maybe<Float8_Comparison_Exp>;
  alternate_surname?: Maybe<String_Comparison_Exp>;
  alternate_tittle?: Maybe<String_Comparison_Exp>;
  alternative_driving_permit?: Maybe<String_Comparison_Exp>;
  alternative_financial_card?: Maybe<String_Comparison_Exp>;
  alternative_national_id?: Maybe<String_Comparison_Exp>;
  alternative_nssf_card?: Maybe<Float8_Comparison_Exp>;
  alternative_passport?: Maybe<String_Comparison_Exp>;
  alternative_village_id?: Maybe<String_Comparison_Exp>;
  building_block_number?: Maybe<String_Comparison_Exp>;
  building_condition?: Maybe<String_Comparison_Exp>;
  building_flat_number?: Maybe<String_Comparison_Exp>;
  building_floor_number?: Maybe<Float8_Comparison_Exp>;
  building_name?: Maybe<String_Comparison_Exp>;
  building_number?: Maybe<String_Comparison_Exp>;
  building_other_services?: Maybe<String_Comparison_Exp>;
  building_parking_slots?: Maybe<String_Comparison_Exp>;
  building_parking_space_available?: Maybe<String_Comparison_Exp>;
  building_parking_space_sufficient?: Maybe<String_Comparison_Exp>;
  building_power_supply?: Maybe<String_Comparison_Exp>;
  building_rent?: Maybe<String_Comparison_Exp>;
  building_sanitation_type?: Maybe<String_Comparison_Exp>;
  building_type?: Maybe<String_Comparison_Exp>;
  building_vertical_circulation?: Maybe<String_Comparison_Exp>;
  building_water_accessibility?: Maybe<String_Comparison_Exp>;
  coin?: Maybe<Float8_Comparison_Exp>;
  construction_ceiling?: Maybe<String_Comparison_Exp>;
  construction_door_others?: Maybe<String_Comparison_Exp>;
  construction_doors?: Maybe<String_Comparison_Exp>;
  construction_floors_finish?: Maybe<String_Comparison_Exp>;
  construction_foors_construction?: Maybe<String_Comparison_Exp>;
  construction_other_details?: Maybe<String_Comparison_Exp>;
  construction_roof_covering?: Maybe<String_Comparison_Exp>;
  construction_wall_finish?: Maybe<String_Comparison_Exp>;
  construction_walls?: Maybe<String_Comparison_Exp>;
  construction_window_others?: Maybe<String_Comparison_Exp>;
  construction_windows?: Maybe<String_Comparison_Exp>;
  contact_address?: Maybe<String_Comparison_Exp>;
  contact_country?: Maybe<String_Comparison_Exp>;
  contact_county_municipality?: Maybe<String_Comparison_Exp>;
  contact_district?: Maybe<String_Comparison_Exp>;
  contact_email?: Maybe<String_Comparison_Exp>;
  contact_landline?: Maybe<Float8_Comparison_Exp>;
  contact_mobile_1?: Maybe<Float8_Comparison_Exp>;
  contact_mobile_2?: Maybe<Float8_Comparison_Exp>;
  contact_parish_ward?: Maybe<String_Comparison_Exp>;
  contact_subcountry_division?: Maybe<String_Comparison_Exp>;
  contact_village_cell_zone?: Maybe<String_Comparison_Exp>;
  data_date?: Maybe<Date_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  grade?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  identification_driving_permit?: Maybe<String_Comparison_Exp>;
  identification_marital_certificate?: Maybe<String_Comparison_Exp>;
  identification_national_id?: Maybe<String_Comparison_Exp>;
  identification_nssf_card?: Maybe<Float8_Comparison_Exp>;
  identification_passport?: Maybe<String_Comparison_Exp>;
  identification_village_id?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name_contact?: Maybe<String_Comparison_Exp>;
  owner_coin?: Maybe<String_Comparison_Exp>;
  owner_firstname?: Maybe<String_Comparison_Exp>;
  owner_legal_surname_maiden?: Maybe<String_Comparison_Exp>;
  owner_middlename?: Maybe<String_Comparison_Exp>;
  owner_othernames?: Maybe<String_Comparison_Exp>;
  owner_tin?: Maybe<String_Comparison_Exp>;
  owner_title?: Maybe<Bigint_Comparison_Exp>;
  particulars_access?: Maybe<String_Comparison_Exp>;
  particulars_block_name?: Maybe<String_Comparison_Exp>;
  particulars_block_number?: Maybe<String_Comparison_Exp>;
  particulars_folio_number?: Maybe<String_Comparison_Exp>;
  particulars_frontages?: Maybe<String_Comparison_Exp>;
  particulars_gps_eastings_utm?: Maybe<Float8_Comparison_Exp>;
  particulars_gps_northings_utm?: Maybe<Float8_Comparison_Exp>;
  particulars_house_number?: Maybe<String_Comparison_Exp>;
  particulars_is_titled?: Maybe<String_Comparison_Exp>;
  particulars_land_reference?: Maybe<String_Comparison_Exp>;
  particulars_neighborhood?: Maybe<String_Comparison_Exp>;
  particulars_plot_number?: Maybe<String_Comparison_Exp>;
  particulars_plot_shape?: Maybe<String_Comparison_Exp>;
  particulars_property_name?: Maybe<String_Comparison_Exp>;
  particulars_road_street?: Maybe<String_Comparison_Exp>;
  particulars_topography_Others?: Maybe<String_Comparison_Exp>;
  particulars_topograpy?: Maybe<String_Comparison_Exp>;
  particulars_type_of_land_interest?: Maybe<String_Comparison_Exp>;
  particulars_volume?: Maybe<String_Comparison_Exp>;
  property_owner_occupied_or_rented?: Maybe<String_Comparison_Exp>;
  property_sub_type?: Maybe<String_Comparison_Exp>;
  property_type?: Maybe<String_Comparison_Exp>;
  pvillage?: Maybe<String_Comparison_Exp>;
  reference?: Maybe<Float8_Comparison_Exp>;
  siteworks_others?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Inc_Input = {
  RV?: Maybe<Scalars["float8"]>;
  Reference_Number_New?: Maybe<Scalars["float8"]>;
  accomodation_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_bedrooms?: Maybe<Scalars["float8"]>;
  accomodation_dining_rooms?: Maybe<Scalars["float8"]>;
  accomodation_entertainment_room?: Maybe<Scalars["float8"]>;
  accomodation_garage?: Maybe<Scalars["float8"]>;
  accomodation_kitchen?: Maybe<Scalars["float8"]>;
  accomodation_laundry?: Maybe<Scalars["float8"]>;
  accomodation_shops?: Maybe<Scalars["float8"]>;
  accomodation_shower?: Maybe<Scalars["float8"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["float8"]>;
  accomodation_store?: Maybe<Scalars["float8"]>;
  accomodation_study_room?: Maybe<Scalars["float8"]>;
  accomodation_verandah?: Maybe<Scalars["float8"]>;
  accomodation_wc?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_wc_shower?: Maybe<Scalars["float8"]>;
  alternate_mobile_2?: Maybe<Scalars["float8"]>;
  alternative_nssf_card?: Maybe<Scalars["float8"]>;
  building_floor_number?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  contact_landline?: Maybe<Scalars["float8"]>;
  contact_mobile_1?: Maybe<Scalars["float8"]>;
  contact_mobile_2?: Maybe<Scalars["float8"]>;
  grade?: Maybe<Scalars["float8"]>;
  identification_nssf_card?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  owner_title?: Maybe<Scalars["bigint"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["float8"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["float8"]>;
  reference?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Insert_Input = {
  Cordinate_X?: Maybe<Scalars["String"]>;
  Cordinate_Y?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  GV?: Maybe<Scalars["String"]>;
  Other_Boundary_Fencespecify?: Maybe<Scalars["String"]>;
  Owner_Individual_Company?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  RV?: Maybe<Scalars["float8"]>;
  Reference_Number_New?: Maybe<Scalars["float8"]>;
  Roads_OtherSpecify?: Maybe<Scalars["String"]>;
  SiteWorks_Boundary_Fence?: Maybe<Scalars["String"]>;
  SiteWorks_Building_Number?: Maybe<Scalars["String"]>;
  SiteWorks_Compound?: Maybe<Scalars["String"]>;
  SiteWorks_Court?: Maybe<Scalars["String"]>;
  SiteWorks_Gate?: Maybe<Scalars["String"]>;
  SiteWorks_Other_Facilities?: Maybe<Scalars["String"]>;
  SiteWorks_Swimming_Pool?: Maybe<Scalars["String"]>;
  accomodation_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_bedrooms?: Maybe<Scalars["float8"]>;
  accomodation_built_up_area?: Maybe<Scalars["String"]>;
  accomodation_comment?: Maybe<Scalars["String"]>;
  accomodation_dining_rooms?: Maybe<Scalars["float8"]>;
  accomodation_entertainment_room?: Maybe<Scalars["float8"]>;
  accomodation_enviromental_noise?: Maybe<Scalars["String"]>;
  accomodation_garage?: Maybe<Scalars["float8"]>;
  accomodation_kitchen?: Maybe<Scalars["float8"]>;
  accomodation_laundry?: Maybe<Scalars["float8"]>;
  accomodation_others?: Maybe<Scalars["String"]>;
  accomodation_security?: Maybe<Scalars["String"]>;
  accomodation_shops?: Maybe<Scalars["float8"]>;
  accomodation_shower?: Maybe<Scalars["float8"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["float8"]>;
  accomodation_store?: Maybe<Scalars["float8"]>;
  accomodation_study_room?: Maybe<Scalars["float8"]>;
  accomodation_verandah?: Maybe<Scalars["float8"]>;
  accomodation_wc?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_wc_shower?: Maybe<Scalars["float8"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  alternate_firstname?: Maybe<Scalars["String"]>;
  alternate_landline_with_code?: Maybe<Scalars["String"]>;
  alternate_middlename?: Maybe<Scalars["String"]>;
  alternate_mobile_1?: Maybe<Scalars["String"]>;
  alternate_mobile_2?: Maybe<Scalars["float8"]>;
  alternate_surname?: Maybe<Scalars["String"]>;
  alternate_tittle?: Maybe<Scalars["String"]>;
  alternative_driving_permit?: Maybe<Scalars["String"]>;
  alternative_financial_card?: Maybe<Scalars["String"]>;
  alternative_national_id?: Maybe<Scalars["String"]>;
  alternative_nssf_card?: Maybe<Scalars["float8"]>;
  alternative_passport?: Maybe<Scalars["String"]>;
  alternative_village_id?: Maybe<Scalars["String"]>;
  building_block_number?: Maybe<Scalars["String"]>;
  building_condition?: Maybe<Scalars["String"]>;
  building_flat_number?: Maybe<Scalars["String"]>;
  building_floor_number?: Maybe<Scalars["float8"]>;
  building_name?: Maybe<Scalars["String"]>;
  building_number?: Maybe<Scalars["String"]>;
  building_other_services?: Maybe<Scalars["String"]>;
  building_parking_slots?: Maybe<Scalars["String"]>;
  building_parking_space_available?: Maybe<Scalars["String"]>;
  building_parking_space_sufficient?: Maybe<Scalars["String"]>;
  building_power_supply?: Maybe<Scalars["String"]>;
  building_rent?: Maybe<Scalars["String"]>;
  building_sanitation_type?: Maybe<Scalars["String"]>;
  building_type?: Maybe<Scalars["String"]>;
  building_vertical_circulation?: Maybe<Scalars["String"]>;
  building_water_accessibility?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  construction_ceiling?: Maybe<Scalars["String"]>;
  construction_door_others?: Maybe<Scalars["String"]>;
  construction_doors?: Maybe<Scalars["String"]>;
  construction_floors_finish?: Maybe<Scalars["String"]>;
  construction_foors_construction?: Maybe<Scalars["String"]>;
  construction_other_details?: Maybe<Scalars["String"]>;
  construction_roof_covering?: Maybe<Scalars["String"]>;
  construction_wall_finish?: Maybe<Scalars["String"]>;
  construction_walls?: Maybe<Scalars["String"]>;
  construction_window_others?: Maybe<Scalars["String"]>;
  construction_windows?: Maybe<Scalars["String"]>;
  contact_address?: Maybe<Scalars["String"]>;
  contact_country?: Maybe<Scalars["String"]>;
  contact_county_municipality?: Maybe<Scalars["String"]>;
  contact_district?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  contact_landline?: Maybe<Scalars["float8"]>;
  contact_mobile_1?: Maybe<Scalars["float8"]>;
  contact_mobile_2?: Maybe<Scalars["float8"]>;
  contact_parish_ward?: Maybe<Scalars["String"]>;
  contact_subcountry_division?: Maybe<Scalars["String"]>;
  contact_village_cell_zone?: Maybe<Scalars["String"]>;
  data_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  grade?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_marital_certificate?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_card?: Maybe<Scalars["float8"]>;
  identification_passport?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name_contact?: Maybe<Scalars["String"]>;
  owner_coin?: Maybe<Scalars["String"]>;
  owner_firstname?: Maybe<Scalars["String"]>;
  owner_legal_surname_maiden?: Maybe<Scalars["String"]>;
  owner_middlename?: Maybe<Scalars["String"]>;
  owner_othernames?: Maybe<Scalars["String"]>;
  owner_tin?: Maybe<Scalars["String"]>;
  owner_title?: Maybe<Scalars["bigint"]>;
  particulars_access?: Maybe<Scalars["String"]>;
  particulars_block_name?: Maybe<Scalars["String"]>;
  particulars_block_number?: Maybe<Scalars["String"]>;
  particulars_folio_number?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["float8"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["float8"]>;
  particulars_house_number?: Maybe<Scalars["String"]>;
  particulars_is_titled?: Maybe<Scalars["String"]>;
  particulars_land_reference?: Maybe<Scalars["String"]>;
  particulars_neighborhood?: Maybe<Scalars["String"]>;
  particulars_plot_number?: Maybe<Scalars["String"]>;
  particulars_plot_shape?: Maybe<Scalars["String"]>;
  particulars_property_name?: Maybe<Scalars["String"]>;
  particulars_road_street?: Maybe<Scalars["String"]>;
  particulars_topography_Others?: Maybe<Scalars["String"]>;
  particulars_topograpy?: Maybe<Scalars["String"]>;
  particulars_type_of_land_interest?: Maybe<Scalars["String"]>;
  particulars_volume?: Maybe<Scalars["String"]>;
  property_owner_occupied_or_rented?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  pvillage?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["float8"]>;
  siteworks_others?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_RawProperties1_Max_Fields = {
  __typename?: "kcca_RawProperties1_max_fields";
  Cordinate_X?: Maybe<Scalars["String"]>;
  Cordinate_Y?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  GV?: Maybe<Scalars["String"]>;
  Other_Boundary_Fencespecify?: Maybe<Scalars["String"]>;
  Owner_Individual_Company?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  RV?: Maybe<Scalars["float8"]>;
  Reference_Number_New?: Maybe<Scalars["float8"]>;
  Roads_OtherSpecify?: Maybe<Scalars["String"]>;
  SiteWorks_Boundary_Fence?: Maybe<Scalars["String"]>;
  SiteWorks_Building_Number?: Maybe<Scalars["String"]>;
  SiteWorks_Compound?: Maybe<Scalars["String"]>;
  SiteWorks_Court?: Maybe<Scalars["String"]>;
  SiteWorks_Gate?: Maybe<Scalars["String"]>;
  SiteWorks_Other_Facilities?: Maybe<Scalars["String"]>;
  SiteWorks_Swimming_Pool?: Maybe<Scalars["String"]>;
  accomodation_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_bedrooms?: Maybe<Scalars["float8"]>;
  accomodation_built_up_area?: Maybe<Scalars["String"]>;
  accomodation_comment?: Maybe<Scalars["String"]>;
  accomodation_dining_rooms?: Maybe<Scalars["float8"]>;
  accomodation_entertainment_room?: Maybe<Scalars["float8"]>;
  accomodation_enviromental_noise?: Maybe<Scalars["String"]>;
  accomodation_garage?: Maybe<Scalars["float8"]>;
  accomodation_kitchen?: Maybe<Scalars["float8"]>;
  accomodation_laundry?: Maybe<Scalars["float8"]>;
  accomodation_others?: Maybe<Scalars["String"]>;
  accomodation_security?: Maybe<Scalars["String"]>;
  accomodation_shops?: Maybe<Scalars["float8"]>;
  accomodation_shower?: Maybe<Scalars["float8"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["float8"]>;
  accomodation_store?: Maybe<Scalars["float8"]>;
  accomodation_study_room?: Maybe<Scalars["float8"]>;
  accomodation_verandah?: Maybe<Scalars["float8"]>;
  accomodation_wc?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_wc_shower?: Maybe<Scalars["float8"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  alternate_firstname?: Maybe<Scalars["String"]>;
  alternate_landline_with_code?: Maybe<Scalars["String"]>;
  alternate_middlename?: Maybe<Scalars["String"]>;
  alternate_mobile_1?: Maybe<Scalars["String"]>;
  alternate_mobile_2?: Maybe<Scalars["float8"]>;
  alternate_surname?: Maybe<Scalars["String"]>;
  alternate_tittle?: Maybe<Scalars["String"]>;
  alternative_driving_permit?: Maybe<Scalars["String"]>;
  alternative_financial_card?: Maybe<Scalars["String"]>;
  alternative_national_id?: Maybe<Scalars["String"]>;
  alternative_nssf_card?: Maybe<Scalars["float8"]>;
  alternative_passport?: Maybe<Scalars["String"]>;
  alternative_village_id?: Maybe<Scalars["String"]>;
  building_block_number?: Maybe<Scalars["String"]>;
  building_condition?: Maybe<Scalars["String"]>;
  building_flat_number?: Maybe<Scalars["String"]>;
  building_floor_number?: Maybe<Scalars["float8"]>;
  building_name?: Maybe<Scalars["String"]>;
  building_number?: Maybe<Scalars["String"]>;
  building_other_services?: Maybe<Scalars["String"]>;
  building_parking_slots?: Maybe<Scalars["String"]>;
  building_parking_space_available?: Maybe<Scalars["String"]>;
  building_parking_space_sufficient?: Maybe<Scalars["String"]>;
  building_power_supply?: Maybe<Scalars["String"]>;
  building_rent?: Maybe<Scalars["String"]>;
  building_sanitation_type?: Maybe<Scalars["String"]>;
  building_type?: Maybe<Scalars["String"]>;
  building_vertical_circulation?: Maybe<Scalars["String"]>;
  building_water_accessibility?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  construction_ceiling?: Maybe<Scalars["String"]>;
  construction_door_others?: Maybe<Scalars["String"]>;
  construction_doors?: Maybe<Scalars["String"]>;
  construction_floors_finish?: Maybe<Scalars["String"]>;
  construction_foors_construction?: Maybe<Scalars["String"]>;
  construction_other_details?: Maybe<Scalars["String"]>;
  construction_roof_covering?: Maybe<Scalars["String"]>;
  construction_wall_finish?: Maybe<Scalars["String"]>;
  construction_walls?: Maybe<Scalars["String"]>;
  construction_window_others?: Maybe<Scalars["String"]>;
  construction_windows?: Maybe<Scalars["String"]>;
  contact_address?: Maybe<Scalars["String"]>;
  contact_country?: Maybe<Scalars["String"]>;
  contact_county_municipality?: Maybe<Scalars["String"]>;
  contact_district?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  contact_landline?: Maybe<Scalars["float8"]>;
  contact_mobile_1?: Maybe<Scalars["float8"]>;
  contact_mobile_2?: Maybe<Scalars["float8"]>;
  contact_parish_ward?: Maybe<Scalars["String"]>;
  contact_subcountry_division?: Maybe<Scalars["String"]>;
  contact_village_cell_zone?: Maybe<Scalars["String"]>;
  data_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  grade?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_marital_certificate?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_card?: Maybe<Scalars["float8"]>;
  identification_passport?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name_contact?: Maybe<Scalars["String"]>;
  owner_coin?: Maybe<Scalars["String"]>;
  owner_firstname?: Maybe<Scalars["String"]>;
  owner_legal_surname_maiden?: Maybe<Scalars["String"]>;
  owner_middlename?: Maybe<Scalars["String"]>;
  owner_othernames?: Maybe<Scalars["String"]>;
  owner_tin?: Maybe<Scalars["String"]>;
  owner_title?: Maybe<Scalars["bigint"]>;
  particulars_access?: Maybe<Scalars["String"]>;
  particulars_block_name?: Maybe<Scalars["String"]>;
  particulars_block_number?: Maybe<Scalars["String"]>;
  particulars_folio_number?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["float8"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["float8"]>;
  particulars_house_number?: Maybe<Scalars["String"]>;
  particulars_is_titled?: Maybe<Scalars["String"]>;
  particulars_land_reference?: Maybe<Scalars["String"]>;
  particulars_neighborhood?: Maybe<Scalars["String"]>;
  particulars_plot_number?: Maybe<Scalars["String"]>;
  particulars_plot_shape?: Maybe<Scalars["String"]>;
  particulars_property_name?: Maybe<Scalars["String"]>;
  particulars_road_street?: Maybe<Scalars["String"]>;
  particulars_topography_Others?: Maybe<Scalars["String"]>;
  particulars_topograpy?: Maybe<Scalars["String"]>;
  particulars_type_of_land_interest?: Maybe<Scalars["String"]>;
  particulars_volume?: Maybe<Scalars["String"]>;
  property_owner_occupied_or_rented?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  pvillage?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["float8"]>;
  siteworks_others?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Max_Order_By = {
  Cordinate_X?: Maybe<Order_By>;
  Cordinate_Y?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  GV?: Maybe<Order_By>;
  Other_Boundary_Fencespecify?: Maybe<Order_By>;
  Owner_Individual_Company?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  Roads_OtherSpecify?: Maybe<Order_By>;
  SiteWorks_Boundary_Fence?: Maybe<Order_By>;
  SiteWorks_Building_Number?: Maybe<Order_By>;
  SiteWorks_Compound?: Maybe<Order_By>;
  SiteWorks_Court?: Maybe<Order_By>;
  SiteWorks_Gate?: Maybe<Order_By>;
  SiteWorks_Other_Facilities?: Maybe<Order_By>;
  SiteWorks_Swimming_Pool?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_built_up_area?: Maybe<Order_By>;
  accomodation_comment?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_enviromental_noise?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_others?: Maybe<Order_By>;
  accomodation_security?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_email?: Maybe<Order_By>;
  alternate_firstname?: Maybe<Order_By>;
  alternate_landline_with_code?: Maybe<Order_By>;
  alternate_middlename?: Maybe<Order_By>;
  alternate_mobile_1?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternate_surname?: Maybe<Order_By>;
  alternate_tittle?: Maybe<Order_By>;
  alternative_driving_permit?: Maybe<Order_By>;
  alternative_financial_card?: Maybe<Order_By>;
  alternative_national_id?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  alternative_passport?: Maybe<Order_By>;
  alternative_village_id?: Maybe<Order_By>;
  building_block_number?: Maybe<Order_By>;
  building_condition?: Maybe<Order_By>;
  building_flat_number?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  building_number?: Maybe<Order_By>;
  building_other_services?: Maybe<Order_By>;
  building_parking_slots?: Maybe<Order_By>;
  building_parking_space_available?: Maybe<Order_By>;
  building_parking_space_sufficient?: Maybe<Order_By>;
  building_power_supply?: Maybe<Order_By>;
  building_rent?: Maybe<Order_By>;
  building_sanitation_type?: Maybe<Order_By>;
  building_type?: Maybe<Order_By>;
  building_vertical_circulation?: Maybe<Order_By>;
  building_water_accessibility?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  construction_ceiling?: Maybe<Order_By>;
  construction_door_others?: Maybe<Order_By>;
  construction_doors?: Maybe<Order_By>;
  construction_floors_finish?: Maybe<Order_By>;
  construction_foors_construction?: Maybe<Order_By>;
  construction_other_details?: Maybe<Order_By>;
  construction_roof_covering?: Maybe<Order_By>;
  construction_wall_finish?: Maybe<Order_By>;
  construction_walls?: Maybe<Order_By>;
  construction_window_others?: Maybe<Order_By>;
  construction_windows?: Maybe<Order_By>;
  contact_address?: Maybe<Order_By>;
  contact_country?: Maybe<Order_By>;
  contact_county_municipality?: Maybe<Order_By>;
  contact_district?: Maybe<Order_By>;
  contact_email?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  contact_parish_ward?: Maybe<Order_By>;
  contact_subcountry_division?: Maybe<Order_By>;
  contact_village_cell_zone?: Maybe<Order_By>;
  data_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_marital_certificate?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  identification_passport?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name_contact?: Maybe<Order_By>;
  owner_coin?: Maybe<Order_By>;
  owner_firstname?: Maybe<Order_By>;
  owner_legal_surname_maiden?: Maybe<Order_By>;
  owner_middlename?: Maybe<Order_By>;
  owner_othernames?: Maybe<Order_By>;
  owner_tin?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_access?: Maybe<Order_By>;
  particulars_block_name?: Maybe<Order_By>;
  particulars_block_number?: Maybe<Order_By>;
  particulars_folio_number?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  particulars_house_number?: Maybe<Order_By>;
  particulars_is_titled?: Maybe<Order_By>;
  particulars_land_reference?: Maybe<Order_By>;
  particulars_neighborhood?: Maybe<Order_By>;
  particulars_plot_number?: Maybe<Order_By>;
  particulars_plot_shape?: Maybe<Order_By>;
  particulars_property_name?: Maybe<Order_By>;
  particulars_road_street?: Maybe<Order_By>;
  particulars_topography_Others?: Maybe<Order_By>;
  particulars_topograpy?: Maybe<Order_By>;
  particulars_type_of_land_interest?: Maybe<Order_By>;
  particulars_volume?: Maybe<Order_By>;
  property_owner_occupied_or_rented?: Maybe<Order_By>;
  property_sub_type?: Maybe<Order_By>;
  property_type?: Maybe<Order_By>;
  pvillage?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  siteworks_others?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_RawProperties1_Min_Fields = {
  __typename?: "kcca_RawProperties1_min_fields";
  Cordinate_X?: Maybe<Scalars["String"]>;
  Cordinate_Y?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  GV?: Maybe<Scalars["String"]>;
  Other_Boundary_Fencespecify?: Maybe<Scalars["String"]>;
  Owner_Individual_Company?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  RV?: Maybe<Scalars["float8"]>;
  Reference_Number_New?: Maybe<Scalars["float8"]>;
  Roads_OtherSpecify?: Maybe<Scalars["String"]>;
  SiteWorks_Boundary_Fence?: Maybe<Scalars["String"]>;
  SiteWorks_Building_Number?: Maybe<Scalars["String"]>;
  SiteWorks_Compound?: Maybe<Scalars["String"]>;
  SiteWorks_Court?: Maybe<Scalars["String"]>;
  SiteWorks_Gate?: Maybe<Scalars["String"]>;
  SiteWorks_Other_Facilities?: Maybe<Scalars["String"]>;
  SiteWorks_Swimming_Pool?: Maybe<Scalars["String"]>;
  accomodation_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_bedrooms?: Maybe<Scalars["float8"]>;
  accomodation_built_up_area?: Maybe<Scalars["String"]>;
  accomodation_comment?: Maybe<Scalars["String"]>;
  accomodation_dining_rooms?: Maybe<Scalars["float8"]>;
  accomodation_entertainment_room?: Maybe<Scalars["float8"]>;
  accomodation_enviromental_noise?: Maybe<Scalars["String"]>;
  accomodation_garage?: Maybe<Scalars["float8"]>;
  accomodation_kitchen?: Maybe<Scalars["float8"]>;
  accomodation_laundry?: Maybe<Scalars["float8"]>;
  accomodation_others?: Maybe<Scalars["String"]>;
  accomodation_security?: Maybe<Scalars["String"]>;
  accomodation_shops?: Maybe<Scalars["float8"]>;
  accomodation_shower?: Maybe<Scalars["float8"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["float8"]>;
  accomodation_store?: Maybe<Scalars["float8"]>;
  accomodation_study_room?: Maybe<Scalars["float8"]>;
  accomodation_verandah?: Maybe<Scalars["float8"]>;
  accomodation_wc?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_wc_shower?: Maybe<Scalars["float8"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  alternate_firstname?: Maybe<Scalars["String"]>;
  alternate_landline_with_code?: Maybe<Scalars["String"]>;
  alternate_middlename?: Maybe<Scalars["String"]>;
  alternate_mobile_1?: Maybe<Scalars["String"]>;
  alternate_mobile_2?: Maybe<Scalars["float8"]>;
  alternate_surname?: Maybe<Scalars["String"]>;
  alternate_tittle?: Maybe<Scalars["String"]>;
  alternative_driving_permit?: Maybe<Scalars["String"]>;
  alternative_financial_card?: Maybe<Scalars["String"]>;
  alternative_national_id?: Maybe<Scalars["String"]>;
  alternative_nssf_card?: Maybe<Scalars["float8"]>;
  alternative_passport?: Maybe<Scalars["String"]>;
  alternative_village_id?: Maybe<Scalars["String"]>;
  building_block_number?: Maybe<Scalars["String"]>;
  building_condition?: Maybe<Scalars["String"]>;
  building_flat_number?: Maybe<Scalars["String"]>;
  building_floor_number?: Maybe<Scalars["float8"]>;
  building_name?: Maybe<Scalars["String"]>;
  building_number?: Maybe<Scalars["String"]>;
  building_other_services?: Maybe<Scalars["String"]>;
  building_parking_slots?: Maybe<Scalars["String"]>;
  building_parking_space_available?: Maybe<Scalars["String"]>;
  building_parking_space_sufficient?: Maybe<Scalars["String"]>;
  building_power_supply?: Maybe<Scalars["String"]>;
  building_rent?: Maybe<Scalars["String"]>;
  building_sanitation_type?: Maybe<Scalars["String"]>;
  building_type?: Maybe<Scalars["String"]>;
  building_vertical_circulation?: Maybe<Scalars["String"]>;
  building_water_accessibility?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  construction_ceiling?: Maybe<Scalars["String"]>;
  construction_door_others?: Maybe<Scalars["String"]>;
  construction_doors?: Maybe<Scalars["String"]>;
  construction_floors_finish?: Maybe<Scalars["String"]>;
  construction_foors_construction?: Maybe<Scalars["String"]>;
  construction_other_details?: Maybe<Scalars["String"]>;
  construction_roof_covering?: Maybe<Scalars["String"]>;
  construction_wall_finish?: Maybe<Scalars["String"]>;
  construction_walls?: Maybe<Scalars["String"]>;
  construction_window_others?: Maybe<Scalars["String"]>;
  construction_windows?: Maybe<Scalars["String"]>;
  contact_address?: Maybe<Scalars["String"]>;
  contact_country?: Maybe<Scalars["String"]>;
  contact_county_municipality?: Maybe<Scalars["String"]>;
  contact_district?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  contact_landline?: Maybe<Scalars["float8"]>;
  contact_mobile_1?: Maybe<Scalars["float8"]>;
  contact_mobile_2?: Maybe<Scalars["float8"]>;
  contact_parish_ward?: Maybe<Scalars["String"]>;
  contact_subcountry_division?: Maybe<Scalars["String"]>;
  contact_village_cell_zone?: Maybe<Scalars["String"]>;
  data_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  grade?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_marital_certificate?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_card?: Maybe<Scalars["float8"]>;
  identification_passport?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name_contact?: Maybe<Scalars["String"]>;
  owner_coin?: Maybe<Scalars["String"]>;
  owner_firstname?: Maybe<Scalars["String"]>;
  owner_legal_surname_maiden?: Maybe<Scalars["String"]>;
  owner_middlename?: Maybe<Scalars["String"]>;
  owner_othernames?: Maybe<Scalars["String"]>;
  owner_tin?: Maybe<Scalars["String"]>;
  owner_title?: Maybe<Scalars["bigint"]>;
  particulars_access?: Maybe<Scalars["String"]>;
  particulars_block_name?: Maybe<Scalars["String"]>;
  particulars_block_number?: Maybe<Scalars["String"]>;
  particulars_folio_number?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["float8"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["float8"]>;
  particulars_house_number?: Maybe<Scalars["String"]>;
  particulars_is_titled?: Maybe<Scalars["String"]>;
  particulars_land_reference?: Maybe<Scalars["String"]>;
  particulars_neighborhood?: Maybe<Scalars["String"]>;
  particulars_plot_number?: Maybe<Scalars["String"]>;
  particulars_plot_shape?: Maybe<Scalars["String"]>;
  particulars_property_name?: Maybe<Scalars["String"]>;
  particulars_road_street?: Maybe<Scalars["String"]>;
  particulars_topography_Others?: Maybe<Scalars["String"]>;
  particulars_topograpy?: Maybe<Scalars["String"]>;
  particulars_type_of_land_interest?: Maybe<Scalars["String"]>;
  particulars_volume?: Maybe<Scalars["String"]>;
  property_owner_occupied_or_rented?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  pvillage?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["float8"]>;
  siteworks_others?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Min_Order_By = {
  Cordinate_X?: Maybe<Order_By>;
  Cordinate_Y?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  GV?: Maybe<Order_By>;
  Other_Boundary_Fencespecify?: Maybe<Order_By>;
  Owner_Individual_Company?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  Roads_OtherSpecify?: Maybe<Order_By>;
  SiteWorks_Boundary_Fence?: Maybe<Order_By>;
  SiteWorks_Building_Number?: Maybe<Order_By>;
  SiteWorks_Compound?: Maybe<Order_By>;
  SiteWorks_Court?: Maybe<Order_By>;
  SiteWorks_Gate?: Maybe<Order_By>;
  SiteWorks_Other_Facilities?: Maybe<Order_By>;
  SiteWorks_Swimming_Pool?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_built_up_area?: Maybe<Order_By>;
  accomodation_comment?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_enviromental_noise?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_others?: Maybe<Order_By>;
  accomodation_security?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_email?: Maybe<Order_By>;
  alternate_firstname?: Maybe<Order_By>;
  alternate_landline_with_code?: Maybe<Order_By>;
  alternate_middlename?: Maybe<Order_By>;
  alternate_mobile_1?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternate_surname?: Maybe<Order_By>;
  alternate_tittle?: Maybe<Order_By>;
  alternative_driving_permit?: Maybe<Order_By>;
  alternative_financial_card?: Maybe<Order_By>;
  alternative_national_id?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  alternative_passport?: Maybe<Order_By>;
  alternative_village_id?: Maybe<Order_By>;
  building_block_number?: Maybe<Order_By>;
  building_condition?: Maybe<Order_By>;
  building_flat_number?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  building_number?: Maybe<Order_By>;
  building_other_services?: Maybe<Order_By>;
  building_parking_slots?: Maybe<Order_By>;
  building_parking_space_available?: Maybe<Order_By>;
  building_parking_space_sufficient?: Maybe<Order_By>;
  building_power_supply?: Maybe<Order_By>;
  building_rent?: Maybe<Order_By>;
  building_sanitation_type?: Maybe<Order_By>;
  building_type?: Maybe<Order_By>;
  building_vertical_circulation?: Maybe<Order_By>;
  building_water_accessibility?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  construction_ceiling?: Maybe<Order_By>;
  construction_door_others?: Maybe<Order_By>;
  construction_doors?: Maybe<Order_By>;
  construction_floors_finish?: Maybe<Order_By>;
  construction_foors_construction?: Maybe<Order_By>;
  construction_other_details?: Maybe<Order_By>;
  construction_roof_covering?: Maybe<Order_By>;
  construction_wall_finish?: Maybe<Order_By>;
  construction_walls?: Maybe<Order_By>;
  construction_window_others?: Maybe<Order_By>;
  construction_windows?: Maybe<Order_By>;
  contact_address?: Maybe<Order_By>;
  contact_country?: Maybe<Order_By>;
  contact_county_municipality?: Maybe<Order_By>;
  contact_district?: Maybe<Order_By>;
  contact_email?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  contact_parish_ward?: Maybe<Order_By>;
  contact_subcountry_division?: Maybe<Order_By>;
  contact_village_cell_zone?: Maybe<Order_By>;
  data_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_marital_certificate?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  identification_passport?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name_contact?: Maybe<Order_By>;
  owner_coin?: Maybe<Order_By>;
  owner_firstname?: Maybe<Order_By>;
  owner_legal_surname_maiden?: Maybe<Order_By>;
  owner_middlename?: Maybe<Order_By>;
  owner_othernames?: Maybe<Order_By>;
  owner_tin?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_access?: Maybe<Order_By>;
  particulars_block_name?: Maybe<Order_By>;
  particulars_block_number?: Maybe<Order_By>;
  particulars_folio_number?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  particulars_house_number?: Maybe<Order_By>;
  particulars_is_titled?: Maybe<Order_By>;
  particulars_land_reference?: Maybe<Order_By>;
  particulars_neighborhood?: Maybe<Order_By>;
  particulars_plot_number?: Maybe<Order_By>;
  particulars_plot_shape?: Maybe<Order_By>;
  particulars_property_name?: Maybe<Order_By>;
  particulars_road_street?: Maybe<Order_By>;
  particulars_topography_Others?: Maybe<Order_By>;
  particulars_topograpy?: Maybe<Order_By>;
  particulars_type_of_land_interest?: Maybe<Order_By>;
  particulars_volume?: Maybe<Order_By>;
  property_owner_occupied_or_rented?: Maybe<Order_By>;
  property_sub_type?: Maybe<Order_By>;
  property_type?: Maybe<Order_By>;
  pvillage?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  siteworks_others?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Mutation_Response = {
  __typename?: "kcca_RawProperties1_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_RawProperties1>;
};

/** input type for inserting object relation for remote table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Obj_Rel_Insert_Input = {
  data: Kcca_RawProperties1_Insert_Input;
};

/** ordering options when selecting data from "kcca.RawProperties1" */
export type Kcca_RawProperties1_Order_By = {
  Cordinate_X?: Maybe<Order_By>;
  Cordinate_Y?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  GV?: Maybe<Order_By>;
  Other_Boundary_Fencespecify?: Maybe<Order_By>;
  Owner_Individual_Company?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  Roads_OtherSpecify?: Maybe<Order_By>;
  SiteWorks_Boundary_Fence?: Maybe<Order_By>;
  SiteWorks_Building_Number?: Maybe<Order_By>;
  SiteWorks_Compound?: Maybe<Order_By>;
  SiteWorks_Court?: Maybe<Order_By>;
  SiteWorks_Gate?: Maybe<Order_By>;
  SiteWorks_Other_Facilities?: Maybe<Order_By>;
  SiteWorks_Swimming_Pool?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_built_up_area?: Maybe<Order_By>;
  accomodation_comment?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_enviromental_noise?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_others?: Maybe<Order_By>;
  accomodation_security?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_email?: Maybe<Order_By>;
  alternate_firstname?: Maybe<Order_By>;
  alternate_landline_with_code?: Maybe<Order_By>;
  alternate_middlename?: Maybe<Order_By>;
  alternate_mobile_1?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternate_surname?: Maybe<Order_By>;
  alternate_tittle?: Maybe<Order_By>;
  alternative_driving_permit?: Maybe<Order_By>;
  alternative_financial_card?: Maybe<Order_By>;
  alternative_national_id?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  alternative_passport?: Maybe<Order_By>;
  alternative_village_id?: Maybe<Order_By>;
  building_block_number?: Maybe<Order_By>;
  building_condition?: Maybe<Order_By>;
  building_flat_number?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  building_number?: Maybe<Order_By>;
  building_other_services?: Maybe<Order_By>;
  building_parking_slots?: Maybe<Order_By>;
  building_parking_space_available?: Maybe<Order_By>;
  building_parking_space_sufficient?: Maybe<Order_By>;
  building_power_supply?: Maybe<Order_By>;
  building_rent?: Maybe<Order_By>;
  building_sanitation_type?: Maybe<Order_By>;
  building_type?: Maybe<Order_By>;
  building_vertical_circulation?: Maybe<Order_By>;
  building_water_accessibility?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  construction_ceiling?: Maybe<Order_By>;
  construction_door_others?: Maybe<Order_By>;
  construction_doors?: Maybe<Order_By>;
  construction_floors_finish?: Maybe<Order_By>;
  construction_foors_construction?: Maybe<Order_By>;
  construction_other_details?: Maybe<Order_By>;
  construction_roof_covering?: Maybe<Order_By>;
  construction_wall_finish?: Maybe<Order_By>;
  construction_walls?: Maybe<Order_By>;
  construction_window_others?: Maybe<Order_By>;
  construction_windows?: Maybe<Order_By>;
  contact_address?: Maybe<Order_By>;
  contact_country?: Maybe<Order_By>;
  contact_county_municipality?: Maybe<Order_By>;
  contact_district?: Maybe<Order_By>;
  contact_email?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  contact_parish_ward?: Maybe<Order_By>;
  contact_subcountry_division?: Maybe<Order_By>;
  contact_village_cell_zone?: Maybe<Order_By>;
  data_date?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_marital_certificate?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  identification_passport?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name_contact?: Maybe<Order_By>;
  owner_coin?: Maybe<Order_By>;
  owner_firstname?: Maybe<Order_By>;
  owner_legal_surname_maiden?: Maybe<Order_By>;
  owner_middlename?: Maybe<Order_By>;
  owner_othernames?: Maybe<Order_By>;
  owner_tin?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_access?: Maybe<Order_By>;
  particulars_block_name?: Maybe<Order_By>;
  particulars_block_number?: Maybe<Order_By>;
  particulars_folio_number?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  particulars_house_number?: Maybe<Order_By>;
  particulars_is_titled?: Maybe<Order_By>;
  particulars_land_reference?: Maybe<Order_By>;
  particulars_neighborhood?: Maybe<Order_By>;
  particulars_plot_number?: Maybe<Order_By>;
  particulars_plot_shape?: Maybe<Order_By>;
  particulars_property_name?: Maybe<Order_By>;
  particulars_road_street?: Maybe<Order_By>;
  particulars_topography_Others?: Maybe<Order_By>;
  particulars_topograpy?: Maybe<Order_By>;
  particulars_type_of_land_interest?: Maybe<Order_By>;
  particulars_volume?: Maybe<Order_By>;
  property_owner_occupied_or_rented?: Maybe<Order_By>;
  property_sub_type?: Maybe<Order_By>;
  property_type?: Maybe<Order_By>;
  pvillage?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  siteworks_others?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.RawProperties1" */
export enum Kcca_RawProperties1_Select_Column {
  /** column name */
  CordinateX = "Cordinate_X",
  /** column name */
  CordinateY = "Cordinate_Y",
  /** column name */
  Division = "Division",
  /** column name */
  Gv = "GV",
  /** column name */
  OtherBoundaryFencespecify = "Other_Boundary_Fencespecify",
  /** column name */
  OwnerIndividualCompany = "Owner_Individual_Company",
  /** column name */
  Parish = "Parish",
  /** column name */
  Rv = "RV",
  /** column name */
  ReferenceNumberNew = "Reference_Number_New",
  /** column name */
  RoadsOtherSpecify = "Roads_OtherSpecify",
  /** column name */
  SiteWorksBoundaryFence = "SiteWorks_Boundary_Fence",
  /** column name */
  SiteWorksBuildingNumber = "SiteWorks_Building_Number",
  /** column name */
  SiteWorksCompound = "SiteWorks_Compound",
  /** column name */
  SiteWorksCourt = "SiteWorks_Court",
  /** column name */
  SiteWorksGate = "SiteWorks_Gate",
  /** column name */
  SiteWorksOtherFacilities = "SiteWorks_Other_Facilities",
  /** column name */
  SiteWorksSwimmingPool = "SiteWorks_Swimming_Pool",
  /** column name */
  AccomodationBathroom = "accomodation_bathroom",
  /** column name */
  AccomodationBathroomShower = "accomodation_bathroom_shower",
  /** column name */
  AccomodationBedrooms = "accomodation_bedrooms",
  /** column name */
  AccomodationBuiltUpArea = "accomodation_built_up_area",
  /** column name */
  AccomodationComment = "accomodation_comment",
  /** column name */
  AccomodationDiningRooms = "accomodation_dining_rooms",
  /** column name */
  AccomodationEntertainmentRoom = "accomodation_entertainment_room",
  /** column name */
  AccomodationEnviromentalNoise = "accomodation_enviromental_noise",
  /** column name */
  AccomodationGarage = "accomodation_garage",
  /** column name */
  AccomodationKitchen = "accomodation_kitchen",
  /** column name */
  AccomodationLaundry = "accomodation_laundry",
  /** column name */
  AccomodationOthers = "accomodation_others",
  /** column name */
  AccomodationSecurity = "accomodation_security",
  /** column name */
  AccomodationShops = "accomodation_shops",
  /** column name */
  AccomodationShower = "accomodation_shower",
  /** column name */
  AccomodationSittingRoomLoungeNumber = "accomodation_sitting_room_lounge_number",
  /** column name */
  AccomodationStore = "accomodation_store",
  /** column name */
  AccomodationStudyRoom = "accomodation_study_room",
  /** column name */
  AccomodationVerandah = "accomodation_verandah",
  /** column name */
  AccomodationWc = "accomodation_wc",
  /** column name */
  AccomodationWcBathroom = "accomodation_wc_bathroom",
  /** column name */
  AccomodationWcBathroomShower = "accomodation_wc_bathroom_shower",
  /** column name */
  AccomodationWcShower = "accomodation_wc_shower",
  /** column name */
  AlternateEmail = "alternate_email",
  /** column name */
  AlternateFirstname = "alternate_firstname",
  /** column name */
  AlternateLandlineWithCode = "alternate_landline_with_code",
  /** column name */
  AlternateMiddlename = "alternate_middlename",
  /** column name */
  AlternateMobile_1 = "alternate_mobile_1",
  /** column name */
  AlternateMobile_2 = "alternate_mobile_2",
  /** column name */
  AlternateSurname = "alternate_surname",
  /** column name */
  AlternateTittle = "alternate_tittle",
  /** column name */
  AlternativeDrivingPermit = "alternative_driving_permit",
  /** column name */
  AlternativeFinancialCard = "alternative_financial_card",
  /** column name */
  AlternativeNationalId = "alternative_national_id",
  /** column name */
  AlternativeNssfCard = "alternative_nssf_card",
  /** column name */
  AlternativePassport = "alternative_passport",
  /** column name */
  AlternativeVillageId = "alternative_village_id",
  /** column name */
  BuildingBlockNumber = "building_block_number",
  /** column name */
  BuildingCondition = "building_condition",
  /** column name */
  BuildingFlatNumber = "building_flat_number",
  /** column name */
  BuildingFloorNumber = "building_floor_number",
  /** column name */
  BuildingName = "building_name",
  /** column name */
  BuildingNumber = "building_number",
  /** column name */
  BuildingOtherServices = "building_other_services",
  /** column name */
  BuildingParkingSlots = "building_parking_slots",
  /** column name */
  BuildingParkingSpaceAvailable = "building_parking_space_available",
  /** column name */
  BuildingParkingSpaceSufficient = "building_parking_space_sufficient",
  /** column name */
  BuildingPowerSupply = "building_power_supply",
  /** column name */
  BuildingRent = "building_rent",
  /** column name */
  BuildingSanitationType = "building_sanitation_type",
  /** column name */
  BuildingType = "building_type",
  /** column name */
  BuildingVerticalCirculation = "building_vertical_circulation",
  /** column name */
  BuildingWaterAccessibility = "building_water_accessibility",
  /** column name */
  Coin = "coin",
  /** column name */
  ConstructionCeiling = "construction_ceiling",
  /** column name */
  ConstructionDoorOthers = "construction_door_others",
  /** column name */
  ConstructionDoors = "construction_doors",
  /** column name */
  ConstructionFloorsFinish = "construction_floors_finish",
  /** column name */
  ConstructionFoorsConstruction = "construction_foors_construction",
  /** column name */
  ConstructionOtherDetails = "construction_other_details",
  /** column name */
  ConstructionRoofCovering = "construction_roof_covering",
  /** column name */
  ConstructionWallFinish = "construction_wall_finish",
  /** column name */
  ConstructionWalls = "construction_walls",
  /** column name */
  ConstructionWindowOthers = "construction_window_others",
  /** column name */
  ConstructionWindows = "construction_windows",
  /** column name */
  ContactAddress = "contact_address",
  /** column name */
  ContactCountry = "contact_country",
  /** column name */
  ContactCountyMunicipality = "contact_county_municipality",
  /** column name */
  ContactDistrict = "contact_district",
  /** column name */
  ContactEmail = "contact_email",
  /** column name */
  ContactLandline = "contact_landline",
  /** column name */
  ContactMobile_1 = "contact_mobile_1",
  /** column name */
  ContactMobile_2 = "contact_mobile_2",
  /** column name */
  ContactParishWard = "contact_parish_ward",
  /** column name */
  ContactSubcountryDivision = "contact_subcountry_division",
  /** column name */
  ContactVillageCellZone = "contact_village_cell_zone",
  /** column name */
  DataDate = "data_date",
  /** column name */
  File = "file",
  /** column name */
  Grade = "grade",
  /** column name */
  Id = "id",
  /** column name */
  IdentificationDrivingPermit = "identification_driving_permit",
  /** column name */
  IdentificationMaritalCertificate = "identification_marital_certificate",
  /** column name */
  IdentificationNationalId = "identification_national_id",
  /** column name */
  IdentificationNssfCard = "identification_nssf_card",
  /** column name */
  IdentificationPassport = "identification_passport",
  /** column name */
  IdentificationVillageId = "identification_village_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  NameContact = "name_contact",
  /** column name */
  OwnerCoin = "owner_coin",
  /** column name */
  OwnerFirstname = "owner_firstname",
  /** column name */
  OwnerLegalSurnameMaiden = "owner_legal_surname_maiden",
  /** column name */
  OwnerMiddlename = "owner_middlename",
  /** column name */
  OwnerOthernames = "owner_othernames",
  /** column name */
  OwnerTin = "owner_tin",
  /** column name */
  OwnerTitle = "owner_title",
  /** column name */
  ParticularsAccess = "particulars_access",
  /** column name */
  ParticularsBlockName = "particulars_block_name",
  /** column name */
  ParticularsBlockNumber = "particulars_block_number",
  /** column name */
  ParticularsFolioNumber = "particulars_folio_number",
  /** column name */
  ParticularsFrontages = "particulars_frontages",
  /** column name */
  ParticularsGpsEastingsUtm = "particulars_gps_eastings_utm",
  /** column name */
  ParticularsGpsNorthingsUtm = "particulars_gps_northings_utm",
  /** column name */
  ParticularsHouseNumber = "particulars_house_number",
  /** column name */
  ParticularsIsTitled = "particulars_is_titled",
  /** column name */
  ParticularsLandReference = "particulars_land_reference",
  /** column name */
  ParticularsNeighborhood = "particulars_neighborhood",
  /** column name */
  ParticularsPlotNumber = "particulars_plot_number",
  /** column name */
  ParticularsPlotShape = "particulars_plot_shape",
  /** column name */
  ParticularsPropertyName = "particulars_property_name",
  /** column name */
  ParticularsRoadStreet = "particulars_road_street",
  /** column name */
  ParticularsTopographyOthers = "particulars_topography_Others",
  /** column name */
  ParticularsTopograpy = "particulars_topograpy",
  /** column name */
  ParticularsTypeOfLandInterest = "particulars_type_of_land_interest",
  /** column name */
  ParticularsVolume = "particulars_volume",
  /** column name */
  PropertyOwnerOccupiedOrRented = "property_owner_occupied_or_rented",
  /** column name */
  PropertySubType = "property_sub_type",
  /** column name */
  PropertyType = "property_type",
  /** column name */
  Pvillage = "pvillage",
  /** column name */
  Reference = "reference",
  /** column name */
  SiteworksOthers = "siteworks_others",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Set_Input = {
  Cordinate_X?: Maybe<Scalars["String"]>;
  Cordinate_Y?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  GV?: Maybe<Scalars["String"]>;
  Other_Boundary_Fencespecify?: Maybe<Scalars["String"]>;
  Owner_Individual_Company?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  RV?: Maybe<Scalars["float8"]>;
  Reference_Number_New?: Maybe<Scalars["float8"]>;
  Roads_OtherSpecify?: Maybe<Scalars["String"]>;
  SiteWorks_Boundary_Fence?: Maybe<Scalars["String"]>;
  SiteWorks_Building_Number?: Maybe<Scalars["String"]>;
  SiteWorks_Compound?: Maybe<Scalars["String"]>;
  SiteWorks_Court?: Maybe<Scalars["String"]>;
  SiteWorks_Gate?: Maybe<Scalars["String"]>;
  SiteWorks_Other_Facilities?: Maybe<Scalars["String"]>;
  SiteWorks_Swimming_Pool?: Maybe<Scalars["String"]>;
  accomodation_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_bedrooms?: Maybe<Scalars["float8"]>;
  accomodation_built_up_area?: Maybe<Scalars["String"]>;
  accomodation_comment?: Maybe<Scalars["String"]>;
  accomodation_dining_rooms?: Maybe<Scalars["float8"]>;
  accomodation_entertainment_room?: Maybe<Scalars["float8"]>;
  accomodation_enviromental_noise?: Maybe<Scalars["String"]>;
  accomodation_garage?: Maybe<Scalars["float8"]>;
  accomodation_kitchen?: Maybe<Scalars["float8"]>;
  accomodation_laundry?: Maybe<Scalars["float8"]>;
  accomodation_others?: Maybe<Scalars["String"]>;
  accomodation_security?: Maybe<Scalars["String"]>;
  accomodation_shops?: Maybe<Scalars["float8"]>;
  accomodation_shower?: Maybe<Scalars["float8"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["float8"]>;
  accomodation_store?: Maybe<Scalars["float8"]>;
  accomodation_study_room?: Maybe<Scalars["float8"]>;
  accomodation_verandah?: Maybe<Scalars["float8"]>;
  accomodation_wc?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_wc_shower?: Maybe<Scalars["float8"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  alternate_firstname?: Maybe<Scalars["String"]>;
  alternate_landline_with_code?: Maybe<Scalars["String"]>;
  alternate_middlename?: Maybe<Scalars["String"]>;
  alternate_mobile_1?: Maybe<Scalars["String"]>;
  alternate_mobile_2?: Maybe<Scalars["float8"]>;
  alternate_surname?: Maybe<Scalars["String"]>;
  alternate_tittle?: Maybe<Scalars["String"]>;
  alternative_driving_permit?: Maybe<Scalars["String"]>;
  alternative_financial_card?: Maybe<Scalars["String"]>;
  alternative_national_id?: Maybe<Scalars["String"]>;
  alternative_nssf_card?: Maybe<Scalars["float8"]>;
  alternative_passport?: Maybe<Scalars["String"]>;
  alternative_village_id?: Maybe<Scalars["String"]>;
  building_block_number?: Maybe<Scalars["String"]>;
  building_condition?: Maybe<Scalars["String"]>;
  building_flat_number?: Maybe<Scalars["String"]>;
  building_floor_number?: Maybe<Scalars["float8"]>;
  building_name?: Maybe<Scalars["String"]>;
  building_number?: Maybe<Scalars["String"]>;
  building_other_services?: Maybe<Scalars["String"]>;
  building_parking_slots?: Maybe<Scalars["String"]>;
  building_parking_space_available?: Maybe<Scalars["String"]>;
  building_parking_space_sufficient?: Maybe<Scalars["String"]>;
  building_power_supply?: Maybe<Scalars["String"]>;
  building_rent?: Maybe<Scalars["String"]>;
  building_sanitation_type?: Maybe<Scalars["String"]>;
  building_type?: Maybe<Scalars["String"]>;
  building_vertical_circulation?: Maybe<Scalars["String"]>;
  building_water_accessibility?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["float8"]>;
  construction_ceiling?: Maybe<Scalars["String"]>;
  construction_door_others?: Maybe<Scalars["String"]>;
  construction_doors?: Maybe<Scalars["String"]>;
  construction_floors_finish?: Maybe<Scalars["String"]>;
  construction_foors_construction?: Maybe<Scalars["String"]>;
  construction_other_details?: Maybe<Scalars["String"]>;
  construction_roof_covering?: Maybe<Scalars["String"]>;
  construction_wall_finish?: Maybe<Scalars["String"]>;
  construction_walls?: Maybe<Scalars["String"]>;
  construction_window_others?: Maybe<Scalars["String"]>;
  construction_windows?: Maybe<Scalars["String"]>;
  contact_address?: Maybe<Scalars["String"]>;
  contact_country?: Maybe<Scalars["String"]>;
  contact_county_municipality?: Maybe<Scalars["String"]>;
  contact_district?: Maybe<Scalars["String"]>;
  contact_email?: Maybe<Scalars["String"]>;
  contact_landline?: Maybe<Scalars["float8"]>;
  contact_mobile_1?: Maybe<Scalars["float8"]>;
  contact_mobile_2?: Maybe<Scalars["float8"]>;
  contact_parish_ward?: Maybe<Scalars["String"]>;
  contact_subcountry_division?: Maybe<Scalars["String"]>;
  contact_village_cell_zone?: Maybe<Scalars["String"]>;
  data_date?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  grade?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_marital_certificate?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_card?: Maybe<Scalars["float8"]>;
  identification_passport?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name_contact?: Maybe<Scalars["String"]>;
  owner_coin?: Maybe<Scalars["String"]>;
  owner_firstname?: Maybe<Scalars["String"]>;
  owner_legal_surname_maiden?: Maybe<Scalars["String"]>;
  owner_middlename?: Maybe<Scalars["String"]>;
  owner_othernames?: Maybe<Scalars["String"]>;
  owner_tin?: Maybe<Scalars["String"]>;
  owner_title?: Maybe<Scalars["bigint"]>;
  particulars_access?: Maybe<Scalars["String"]>;
  particulars_block_name?: Maybe<Scalars["String"]>;
  particulars_block_number?: Maybe<Scalars["String"]>;
  particulars_folio_number?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["float8"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["float8"]>;
  particulars_house_number?: Maybe<Scalars["String"]>;
  particulars_is_titled?: Maybe<Scalars["String"]>;
  particulars_land_reference?: Maybe<Scalars["String"]>;
  particulars_neighborhood?: Maybe<Scalars["String"]>;
  particulars_plot_number?: Maybe<Scalars["String"]>;
  particulars_plot_shape?: Maybe<Scalars["String"]>;
  particulars_property_name?: Maybe<Scalars["String"]>;
  particulars_road_street?: Maybe<Scalars["String"]>;
  particulars_topography_Others?: Maybe<Scalars["String"]>;
  particulars_topograpy?: Maybe<Scalars["String"]>;
  particulars_type_of_land_interest?: Maybe<Scalars["String"]>;
  particulars_volume?: Maybe<Scalars["String"]>;
  property_owner_occupied_or_rented?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  pvillage?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["float8"]>;
  siteworks_others?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_RawProperties1_Stddev_Fields = {
  __typename?: "kcca_RawProperties1_stddev_fields";
  RV?: Maybe<Scalars["Float"]>;
  Reference_Number_New?: Maybe<Scalars["Float"]>;
  accomodation_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_bedrooms?: Maybe<Scalars["Float"]>;
  accomodation_dining_rooms?: Maybe<Scalars["Float"]>;
  accomodation_entertainment_room?: Maybe<Scalars["Float"]>;
  accomodation_garage?: Maybe<Scalars["Float"]>;
  accomodation_kitchen?: Maybe<Scalars["Float"]>;
  accomodation_laundry?: Maybe<Scalars["Float"]>;
  accomodation_shops?: Maybe<Scalars["Float"]>;
  accomodation_shower?: Maybe<Scalars["Float"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["Float"]>;
  accomodation_store?: Maybe<Scalars["Float"]>;
  accomodation_study_room?: Maybe<Scalars["Float"]>;
  accomodation_verandah?: Maybe<Scalars["Float"]>;
  accomodation_wc?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_wc_shower?: Maybe<Scalars["Float"]>;
  alternate_mobile_2?: Maybe<Scalars["Float"]>;
  alternative_nssf_card?: Maybe<Scalars["Float"]>;
  building_floor_number?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  contact_landline?: Maybe<Scalars["Float"]>;
  contact_mobile_1?: Maybe<Scalars["Float"]>;
  contact_mobile_2?: Maybe<Scalars["Float"]>;
  grade?: Maybe<Scalars["Float"]>;
  identification_nssf_card?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  owner_title?: Maybe<Scalars["Float"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["Float"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Stddev_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_RawProperties1_Stddev_Pop_Fields = {
  __typename?: "kcca_RawProperties1_stddev_pop_fields";
  RV?: Maybe<Scalars["Float"]>;
  Reference_Number_New?: Maybe<Scalars["Float"]>;
  accomodation_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_bedrooms?: Maybe<Scalars["Float"]>;
  accomodation_dining_rooms?: Maybe<Scalars["Float"]>;
  accomodation_entertainment_room?: Maybe<Scalars["Float"]>;
  accomodation_garage?: Maybe<Scalars["Float"]>;
  accomodation_kitchen?: Maybe<Scalars["Float"]>;
  accomodation_laundry?: Maybe<Scalars["Float"]>;
  accomodation_shops?: Maybe<Scalars["Float"]>;
  accomodation_shower?: Maybe<Scalars["Float"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["Float"]>;
  accomodation_store?: Maybe<Scalars["Float"]>;
  accomodation_study_room?: Maybe<Scalars["Float"]>;
  accomodation_verandah?: Maybe<Scalars["Float"]>;
  accomodation_wc?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_wc_shower?: Maybe<Scalars["Float"]>;
  alternate_mobile_2?: Maybe<Scalars["Float"]>;
  alternative_nssf_card?: Maybe<Scalars["Float"]>;
  building_floor_number?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  contact_landline?: Maybe<Scalars["Float"]>;
  contact_mobile_1?: Maybe<Scalars["Float"]>;
  contact_mobile_2?: Maybe<Scalars["Float"]>;
  grade?: Maybe<Scalars["Float"]>;
  identification_nssf_card?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  owner_title?: Maybe<Scalars["Float"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["Float"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Stddev_Pop_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_RawProperties1_Stddev_Samp_Fields = {
  __typename?: "kcca_RawProperties1_stddev_samp_fields";
  RV?: Maybe<Scalars["Float"]>;
  Reference_Number_New?: Maybe<Scalars["Float"]>;
  accomodation_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_bedrooms?: Maybe<Scalars["Float"]>;
  accomodation_dining_rooms?: Maybe<Scalars["Float"]>;
  accomodation_entertainment_room?: Maybe<Scalars["Float"]>;
  accomodation_garage?: Maybe<Scalars["Float"]>;
  accomodation_kitchen?: Maybe<Scalars["Float"]>;
  accomodation_laundry?: Maybe<Scalars["Float"]>;
  accomodation_shops?: Maybe<Scalars["Float"]>;
  accomodation_shower?: Maybe<Scalars["Float"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["Float"]>;
  accomodation_store?: Maybe<Scalars["Float"]>;
  accomodation_study_room?: Maybe<Scalars["Float"]>;
  accomodation_verandah?: Maybe<Scalars["Float"]>;
  accomodation_wc?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_wc_shower?: Maybe<Scalars["Float"]>;
  alternate_mobile_2?: Maybe<Scalars["Float"]>;
  alternative_nssf_card?: Maybe<Scalars["Float"]>;
  building_floor_number?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  contact_landline?: Maybe<Scalars["Float"]>;
  contact_mobile_1?: Maybe<Scalars["Float"]>;
  contact_mobile_2?: Maybe<Scalars["Float"]>;
  grade?: Maybe<Scalars["Float"]>;
  identification_nssf_card?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  owner_title?: Maybe<Scalars["Float"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["Float"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Stddev_Samp_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_RawProperties1_Sum_Fields = {
  __typename?: "kcca_RawProperties1_sum_fields";
  RV?: Maybe<Scalars["float8"]>;
  Reference_Number_New?: Maybe<Scalars["float8"]>;
  accomodation_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_bedrooms?: Maybe<Scalars["float8"]>;
  accomodation_dining_rooms?: Maybe<Scalars["float8"]>;
  accomodation_entertainment_room?: Maybe<Scalars["float8"]>;
  accomodation_garage?: Maybe<Scalars["float8"]>;
  accomodation_kitchen?: Maybe<Scalars["float8"]>;
  accomodation_laundry?: Maybe<Scalars["float8"]>;
  accomodation_shops?: Maybe<Scalars["float8"]>;
  accomodation_shower?: Maybe<Scalars["float8"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["float8"]>;
  accomodation_store?: Maybe<Scalars["float8"]>;
  accomodation_study_room?: Maybe<Scalars["float8"]>;
  accomodation_verandah?: Maybe<Scalars["float8"]>;
  accomodation_wc?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["float8"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["float8"]>;
  accomodation_wc_shower?: Maybe<Scalars["float8"]>;
  alternate_mobile_2?: Maybe<Scalars["float8"]>;
  alternative_nssf_card?: Maybe<Scalars["float8"]>;
  building_floor_number?: Maybe<Scalars["float8"]>;
  coin?: Maybe<Scalars["float8"]>;
  contact_landline?: Maybe<Scalars["float8"]>;
  contact_mobile_1?: Maybe<Scalars["float8"]>;
  contact_mobile_2?: Maybe<Scalars["float8"]>;
  grade?: Maybe<Scalars["float8"]>;
  identification_nssf_card?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  owner_title?: Maybe<Scalars["bigint"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["float8"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["float8"]>;
  reference?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Sum_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_RawProperties1_Var_Pop_Fields = {
  __typename?: "kcca_RawProperties1_var_pop_fields";
  RV?: Maybe<Scalars["Float"]>;
  Reference_Number_New?: Maybe<Scalars["Float"]>;
  accomodation_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_bedrooms?: Maybe<Scalars["Float"]>;
  accomodation_dining_rooms?: Maybe<Scalars["Float"]>;
  accomodation_entertainment_room?: Maybe<Scalars["Float"]>;
  accomodation_garage?: Maybe<Scalars["Float"]>;
  accomodation_kitchen?: Maybe<Scalars["Float"]>;
  accomodation_laundry?: Maybe<Scalars["Float"]>;
  accomodation_shops?: Maybe<Scalars["Float"]>;
  accomodation_shower?: Maybe<Scalars["Float"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["Float"]>;
  accomodation_store?: Maybe<Scalars["Float"]>;
  accomodation_study_room?: Maybe<Scalars["Float"]>;
  accomodation_verandah?: Maybe<Scalars["Float"]>;
  accomodation_wc?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_wc_shower?: Maybe<Scalars["Float"]>;
  alternate_mobile_2?: Maybe<Scalars["Float"]>;
  alternative_nssf_card?: Maybe<Scalars["Float"]>;
  building_floor_number?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  contact_landline?: Maybe<Scalars["Float"]>;
  contact_mobile_1?: Maybe<Scalars["Float"]>;
  contact_mobile_2?: Maybe<Scalars["Float"]>;
  grade?: Maybe<Scalars["Float"]>;
  identification_nssf_card?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  owner_title?: Maybe<Scalars["Float"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["Float"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Var_Pop_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_RawProperties1_Var_Samp_Fields = {
  __typename?: "kcca_RawProperties1_var_samp_fields";
  RV?: Maybe<Scalars["Float"]>;
  Reference_Number_New?: Maybe<Scalars["Float"]>;
  accomodation_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_bedrooms?: Maybe<Scalars["Float"]>;
  accomodation_dining_rooms?: Maybe<Scalars["Float"]>;
  accomodation_entertainment_room?: Maybe<Scalars["Float"]>;
  accomodation_garage?: Maybe<Scalars["Float"]>;
  accomodation_kitchen?: Maybe<Scalars["Float"]>;
  accomodation_laundry?: Maybe<Scalars["Float"]>;
  accomodation_shops?: Maybe<Scalars["Float"]>;
  accomodation_shower?: Maybe<Scalars["Float"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["Float"]>;
  accomodation_store?: Maybe<Scalars["Float"]>;
  accomodation_study_room?: Maybe<Scalars["Float"]>;
  accomodation_verandah?: Maybe<Scalars["Float"]>;
  accomodation_wc?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_wc_shower?: Maybe<Scalars["Float"]>;
  alternate_mobile_2?: Maybe<Scalars["Float"]>;
  alternative_nssf_card?: Maybe<Scalars["Float"]>;
  building_floor_number?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  contact_landline?: Maybe<Scalars["Float"]>;
  contact_mobile_1?: Maybe<Scalars["Float"]>;
  contact_mobile_2?: Maybe<Scalars["Float"]>;
  grade?: Maybe<Scalars["Float"]>;
  identification_nssf_card?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  owner_title?: Maybe<Scalars["Float"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["Float"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Var_Samp_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_RawProperties1_Variance_Fields = {
  __typename?: "kcca_RawProperties1_variance_fields";
  RV?: Maybe<Scalars["Float"]>;
  Reference_Number_New?: Maybe<Scalars["Float"]>;
  accomodation_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_bedrooms?: Maybe<Scalars["Float"]>;
  accomodation_dining_rooms?: Maybe<Scalars["Float"]>;
  accomodation_entertainment_room?: Maybe<Scalars["Float"]>;
  accomodation_garage?: Maybe<Scalars["Float"]>;
  accomodation_kitchen?: Maybe<Scalars["Float"]>;
  accomodation_laundry?: Maybe<Scalars["Float"]>;
  accomodation_shops?: Maybe<Scalars["Float"]>;
  accomodation_shower?: Maybe<Scalars["Float"]>;
  accomodation_sitting_room_lounge_number?: Maybe<Scalars["Float"]>;
  accomodation_store?: Maybe<Scalars["Float"]>;
  accomodation_study_room?: Maybe<Scalars["Float"]>;
  accomodation_verandah?: Maybe<Scalars["Float"]>;
  accomodation_wc?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom?: Maybe<Scalars["Float"]>;
  accomodation_wc_bathroom_shower?: Maybe<Scalars["Float"]>;
  accomodation_wc_shower?: Maybe<Scalars["Float"]>;
  alternate_mobile_2?: Maybe<Scalars["Float"]>;
  alternative_nssf_card?: Maybe<Scalars["Float"]>;
  building_floor_number?: Maybe<Scalars["Float"]>;
  coin?: Maybe<Scalars["Float"]>;
  contact_landline?: Maybe<Scalars["Float"]>;
  contact_mobile_1?: Maybe<Scalars["Float"]>;
  contact_mobile_2?: Maybe<Scalars["Float"]>;
  grade?: Maybe<Scalars["Float"]>;
  identification_nssf_card?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  owner_title?: Maybe<Scalars["Float"]>;
  particulars_gps_eastings_utm?: Maybe<Scalars["Float"]>;
  particulars_gps_northings_utm?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.RawProperties1" */
export type Kcca_RawProperties1_Variance_Order_By = {
  RV?: Maybe<Order_By>;
  Reference_Number_New?: Maybe<Order_By>;
  accomodation_bathroom?: Maybe<Order_By>;
  accomodation_bathroom_shower?: Maybe<Order_By>;
  accomodation_bedrooms?: Maybe<Order_By>;
  accomodation_dining_rooms?: Maybe<Order_By>;
  accomodation_entertainment_room?: Maybe<Order_By>;
  accomodation_garage?: Maybe<Order_By>;
  accomodation_kitchen?: Maybe<Order_By>;
  accomodation_laundry?: Maybe<Order_By>;
  accomodation_shops?: Maybe<Order_By>;
  accomodation_shower?: Maybe<Order_By>;
  accomodation_sitting_room_lounge_number?: Maybe<Order_By>;
  accomodation_store?: Maybe<Order_By>;
  accomodation_study_room?: Maybe<Order_By>;
  accomodation_verandah?: Maybe<Order_By>;
  accomodation_wc?: Maybe<Order_By>;
  accomodation_wc_bathroom?: Maybe<Order_By>;
  accomodation_wc_bathroom_shower?: Maybe<Order_By>;
  accomodation_wc_shower?: Maybe<Order_By>;
  alternate_mobile_2?: Maybe<Order_By>;
  alternative_nssf_card?: Maybe<Order_By>;
  building_floor_number?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  contact_landline?: Maybe<Order_By>;
  contact_mobile_1?: Maybe<Order_By>;
  contact_mobile_2?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  identification_nssf_card?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  owner_title?: Maybe<Order_By>;
  particulars_gps_eastings_utm?: Maybe<Order_By>;
  particulars_gps_northings_utm?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.RawProperties2" */
export type Kcca_RawProperties2 = {
  __typename?: "kcca_RawProperties2";
  AUTHORIZEDCONTACTPERSON?: Maybe<Scalars["String"]>;
  BoundaryFenceother?: Maybe<Scalars["String"]>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamp"]>;
  Creator?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  EditDate?: Maybe<Scalars["timestamp"]>;
  Editor?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["String"]>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Scalars["String"]>;
  SHAPE?: Maybe<Scalars["String"]>;
  SubCounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  access?: Maybe<Scalars["String"]>;
  agent?: Maybe<Scalars["String"]>;
  alternative?: Maybe<Scalars["String"]>;
  alternativeemailaddress?: Maybe<Scalars["String"]>;
  alternativefirstname?: Maybe<Scalars["String"]>;
  alternativelandlinenumber?: Maybe<Scalars["String"]>;
  alternativelandlinenumbercode?: Maybe<Scalars["String"]>;
  alternativelandlinenumberwithcountrycode?: Maybe<Scalars["String"]>;
  alternativeothername?: Maybe<Scalars["String"]>;
  alternativephonenumber1?: Maybe<Scalars["String"]>;
  alternativephonenumber1code?: Maybe<Scalars["String"]>;
  alternativephonenumber2?: Maybe<Scalars["String"]>;
  alternativephonenumber2code?: Maybe<Scalars["String"]>;
  alternativesurname?: Maybe<Scalars["String"]>;
  altphn1?: Maybe<Scalars["String"]>;
  altphn2?: Maybe<Scalars["String"]>;
  anyother?: Maybe<Scalars["String"]>;
  area_count?: Maybe<Scalars["String"]>;
  bldg2?: Maybe<Scalars["String"]>;
  blockname?: Maybe<Scalars["String"]>;
  blocknumber?: Maybe<Scalars["String"]>;
  blockother?: Maybe<Scalars["String"]>;
  boundary?: Maybe<Scalars["String"]>;
  buildingcondition?: Maybe<Scalars["String"]>;
  buildingname?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["bigint"]>;
  camv_serial?: Maybe<Scalars["String"]>;
  ceiling?: Maybe<Scalars["String"]>;
  ceilingothers?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  coin1?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  compound?: Maybe<Scalars["String"]>;
  compoundother?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  ctrycde?: Maybe<Scalars["String"]>;
  ctrycde1?: Maybe<Scalars["String"]>;
  ctrycde2?: Maybe<Scalars["String"]>;
  ctrycde4?: Maybe<Scalars["String"]>;
  datacollector?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  doors?: Maybe<Scalars["String"]>;
  doorsothers?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email1?: Maybe<Scalars["String"]>;
  email3?: Maybe<Scalars["String"]>;
  email5?: Maybe<Scalars["String"]>;
  entitytype?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  facilities?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  firstname3?: Maybe<Scalars["String"]>;
  flatblocknumber?: Maybe<Scalars["String"]>;
  flatnumber?: Maybe<Scalars["String"]>;
  floorconstruction?: Maybe<Scalars["String"]>;
  floorconstructionothers?: Maybe<Scalars["String"]>;
  floorfinish?: Maybe<Scalars["String"]>;
  floorfinishothers?: Maybe<Scalars["String"]>;
  folio?: Maybe<Scalars["String"]>;
  games?: Maybe<Scalars["String"]>;
  gamesother?: Maybe<Scalars["String"]>;
  gate?: Maybe<Scalars["String"]>;
  gateother?: Maybe<Scalars["String"]>;
  globalid?: Maybe<Scalars["String"]>;
  housenumber?: Maybe<Scalars["String"]>;
  hsenbr?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  id2?: Maybe<Scalars["String"]>;
  id3?: Maybe<Scalars["bigint"]>;
  land4?: Maybe<Scalars["String"]>;
  landline?: Maybe<Scalars["String"]>;
  landline1?: Maybe<Scalars["String"]>;
  landline1code?: Maybe<Scalars["String"]>;
  landline4?: Maybe<Scalars["String"]>;
  landlinecode?: Maybe<Scalars["String"]>;
  landlinecode4?: Maybe<Scalars["String"]>;
  landregister?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude_a?: Maybe<Scalars["float8"]>;
  latlong?: Maybe<Scalars["String"]>;
  legalname?: Maybe<Scalars["String"]>;
  levelofcompletion?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  lln?: Maybe<Scalars["String"]>;
  lln1?: Maybe<Scalars["String"]>;
  longitude_b?: Maybe<Scalars["float8"]>;
  lvls?: Maybe<Scalars["String"]>;
  mainbusinessname?: Maybe<Scalars["String"]>;
  mob?: Maybe<Scalars["String"]>;
  mob1?: Maybe<Scalars["String"]>;
  mob3?: Maybe<Scalars["String"]>;
  mob4?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  mobile1?: Maybe<Scalars["String"]>;
  mobile1code?: Maybe<Scalars["String"]>;
  mobile3?: Maybe<Scalars["String"]>;
  mobile3code?: Maybe<Scalars["String"]>;
  mobile4?: Maybe<Scalars["String"]>;
  mobile4code?: Maybe<Scalars["String"]>;
  mobile5?: Maybe<Scalars["String"]>;
  mobile5code?: Maybe<Scalars["String"]>;
  mobilecode?: Maybe<Scalars["String"]>;
  name2?: Maybe<Scalars["String"]>;
  nationalid?: Maybe<Scalars["String"]>;
  nationalid2?: Maybe<Scalars["String"]>;
  nbrofslots?: Maybe<Scalars["String"]>;
  neighbourhoodactivities?: Maybe<Scalars["String"]>;
  neighbourhoodstatus?: Maybe<Scalars["String"]>;
  nin?: Maybe<Scalars["String"]>;
  nin2?: Maybe<Scalars["String"]>;
  objectid?: Maybe<Scalars["float8"]>;
  other?: Maybe<Scalars["String"]>;
  otherfacilities?: Maybe<Scalars["String"]>;
  otherid?: Maybe<Scalars["String"]>;
  otherid1?: Maybe<Scalars["String"]>;
  otherid2?: Maybe<Scalars["String"]>;
  otherid3?: Maybe<Scalars["String"]>;
  otherid4?: Maybe<Scalars["String"]>;
  otherid4nbr?: Maybe<Scalars["String"]>;
  otherid6?: Maybe<Scalars["String"]>;
  otherid7?: Maybe<Scalars["String"]>;
  othernames?: Maybe<Scalars["String"]>;
  othernames3?: Maybe<Scalars["String"]>;
  otherservices?: Maybe<Scalars["String"]>;
  parish2?: Maybe<Scalars["String"]>;
  parking?: Maybe<Scalars["String"]>;
  parkingsufficient?: Maybe<Scalars["String"]>;
  pass?: Maybe<Scalars["float8"]>;
  plotnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  powersupply?: Maybe<Scalars["String"]>;
  propertyname?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["bigint"]>;
  relationshiptoowner?: Maybe<Scalars["String"]>;
  roadfrontageorgrid?: Maybe<Scalars["String"]>;
  roadname?: Maybe<Scalars["String"]>;
  roofcovering?: Maybe<Scalars["String"]>;
  roofcoveringothers?: Maybe<Scalars["String"]>;
  sanitation?: Maybe<Scalars["String"]>;
  security?: Maybe<Scalars["String"]>;
  shapeofplot?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  surname3?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  title1?: Maybe<Scalars["String"]>;
  topography?: Maybe<Scalars["String"]>;
  total_built_up_area?: Maybe<Scalars["float8"]>;
  total_gross_area?: Maybe<Scalars["float8"]>;
  typeofaccess?: Maybe<Scalars["String"]>;
  typeofbuilding?: Maybe<Scalars["String"]>;
  typeofcompany?: Maybe<Scalars["String"]>;
  typeoflandinterest?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["float8"]>;
  verticalcirculation?: Maybe<Scalars["String"]>;
  village2?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["String"]>;
  wallfinish?: Maybe<Scalars["String"]>;
  wallfinishothers?: Maybe<Scalars["String"]>;
  walls?: Maybe<Scalars["String"]>;
  wallsothers?: Maybe<Scalars["String"]>;
  wateraccessibility?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  windows?: Maybe<Scalars["String"]>;
  windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.RawProperties2" */
export type Kcca_RawProperties2_Aggregate = {
  __typename?: "kcca_RawProperties2_aggregate";
  aggregate?: Maybe<Kcca_RawProperties2_Aggregate_Fields>;
  nodes: Array<Kcca_RawProperties2>;
};

/** aggregate fields of "kcca.RawProperties2" */
export type Kcca_RawProperties2_Aggregate_Fields = {
  __typename?: "kcca_RawProperties2_aggregate_fields";
  avg?: Maybe<Kcca_RawProperties2_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_RawProperties2_Max_Fields>;
  min?: Maybe<Kcca_RawProperties2_Min_Fields>;
  stddev?: Maybe<Kcca_RawProperties2_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_RawProperties2_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_RawProperties2_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_RawProperties2_Sum_Fields>;
  var_pop?: Maybe<Kcca_RawProperties2_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_RawProperties2_Var_Samp_Fields>;
  variance?: Maybe<Kcca_RawProperties2_Variance_Fields>;
};

/** aggregate fields of "kcca.RawProperties2" */
export type Kcca_RawProperties2_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_RawProperties2_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Aggregate_Order_By = {
  avg?: Maybe<Kcca_RawProperties2_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_RawProperties2_Max_Order_By>;
  min?: Maybe<Kcca_RawProperties2_Min_Order_By>;
  stddev?: Maybe<Kcca_RawProperties2_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_RawProperties2_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_RawProperties2_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_RawProperties2_Sum_Order_By>;
  var_pop?: Maybe<Kcca_RawProperties2_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_RawProperties2_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_RawProperties2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Arr_Rel_Insert_Input = {
  data: Array<Kcca_RawProperties2_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_RawProperties2_Avg_Fields = {
  __typename?: "kcca_RawProperties2_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  camv_id?: Maybe<Scalars["Float"]>;
  id3?: Maybe<Scalars["Float"]>;
  latitude_a?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude_b?: Maybe<Scalars["Float"]>;
  objectid?: Maybe<Scalars["Float"]>;
  pass?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  total_built_up_area?: Maybe<Scalars["Float"]>;
  total_gross_area?: Maybe<Scalars["Float"]>;
  username?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.RawProperties2". All fields are combined with a logical 'AND'. */
export type Kcca_RawProperties2_Bool_Exp = {
  AUTHORIZEDCONTACTPERSON?: Maybe<String_Comparison_Exp>;
  BoundaryFenceother?: Maybe<String_Comparison_Exp>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  County1?: Maybe<String_Comparison_Exp>;
  CreationDate?: Maybe<Timestamp_Comparison_Exp>;
  Creator?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  District1?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  EditDate?: Maybe<Timestamp_Comparison_Exp>;
  Editor?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<String_Comparison_Exp>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Parish1?: Maybe<String_Comparison_Exp>;
  PropertySubType?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<String_Comparison_Exp>;
  SHAPE?: Maybe<String_Comparison_Exp>;
  SubCounty?: Maybe<String_Comparison_Exp>;
  Subcounty1?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  Village1?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Kcca_RawProperties2_Bool_Exp>>>;
  _not?: Maybe<Kcca_RawProperties2_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_RawProperties2_Bool_Exp>>>;
  access?: Maybe<String_Comparison_Exp>;
  agent?: Maybe<String_Comparison_Exp>;
  alternative?: Maybe<String_Comparison_Exp>;
  alternativeemailaddress?: Maybe<String_Comparison_Exp>;
  alternativefirstname?: Maybe<String_Comparison_Exp>;
  alternativelandlinenumber?: Maybe<String_Comparison_Exp>;
  alternativelandlinenumbercode?: Maybe<String_Comparison_Exp>;
  alternativelandlinenumberwithcountrycode?: Maybe<String_Comparison_Exp>;
  alternativeothername?: Maybe<String_Comparison_Exp>;
  alternativephonenumber1?: Maybe<String_Comparison_Exp>;
  alternativephonenumber1code?: Maybe<String_Comparison_Exp>;
  alternativephonenumber2?: Maybe<String_Comparison_Exp>;
  alternativephonenumber2code?: Maybe<String_Comparison_Exp>;
  alternativesurname?: Maybe<String_Comparison_Exp>;
  altphn1?: Maybe<String_Comparison_Exp>;
  altphn2?: Maybe<String_Comparison_Exp>;
  anyother?: Maybe<String_Comparison_Exp>;
  area_count?: Maybe<String_Comparison_Exp>;
  bldg2?: Maybe<String_Comparison_Exp>;
  blockname?: Maybe<String_Comparison_Exp>;
  blocknumber?: Maybe<String_Comparison_Exp>;
  blockother?: Maybe<String_Comparison_Exp>;
  boundary?: Maybe<String_Comparison_Exp>;
  buildingcondition?: Maybe<String_Comparison_Exp>;
  buildingname?: Maybe<String_Comparison_Exp>;
  camv_id?: Maybe<Bigint_Comparison_Exp>;
  camv_serial?: Maybe<String_Comparison_Exp>;
  ceiling?: Maybe<String_Comparison_Exp>;
  ceilingothers?: Maybe<String_Comparison_Exp>;
  coin?: Maybe<String_Comparison_Exp>;
  coin1?: Maybe<String_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  compound?: Maybe<String_Comparison_Exp>;
  compoundother?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  ctrycde?: Maybe<String_Comparison_Exp>;
  ctrycde1?: Maybe<String_Comparison_Exp>;
  ctrycde2?: Maybe<String_Comparison_Exp>;
  ctrycde4?: Maybe<String_Comparison_Exp>;
  datacollector?: Maybe<String_Comparison_Exp>;
  districtorcitywhereboxislocated?: Maybe<String_Comparison_Exp>;
  districtorcitywhereboxislocated1?: Maybe<String_Comparison_Exp>;
  doors?: Maybe<String_Comparison_Exp>;
  doorsothers?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email1?: Maybe<String_Comparison_Exp>;
  email3?: Maybe<String_Comparison_Exp>;
  email5?: Maybe<String_Comparison_Exp>;
  entitytype?: Maybe<String_Comparison_Exp>;
  environment?: Maybe<String_Comparison_Exp>;
  facilities?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  firstname3?: Maybe<String_Comparison_Exp>;
  flatblocknumber?: Maybe<String_Comparison_Exp>;
  flatnumber?: Maybe<String_Comparison_Exp>;
  floorconstruction?: Maybe<String_Comparison_Exp>;
  floorconstructionothers?: Maybe<String_Comparison_Exp>;
  floorfinish?: Maybe<String_Comparison_Exp>;
  floorfinishothers?: Maybe<String_Comparison_Exp>;
  folio?: Maybe<String_Comparison_Exp>;
  games?: Maybe<String_Comparison_Exp>;
  gamesother?: Maybe<String_Comparison_Exp>;
  gate?: Maybe<String_Comparison_Exp>;
  gateother?: Maybe<String_Comparison_Exp>;
  globalid?: Maybe<String_Comparison_Exp>;
  housenumber?: Maybe<String_Comparison_Exp>;
  hsenbr?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  id2?: Maybe<String_Comparison_Exp>;
  id3?: Maybe<Bigint_Comparison_Exp>;
  land4?: Maybe<String_Comparison_Exp>;
  landline?: Maybe<String_Comparison_Exp>;
  landline1?: Maybe<String_Comparison_Exp>;
  landline1code?: Maybe<String_Comparison_Exp>;
  landline4?: Maybe<String_Comparison_Exp>;
  landlinecode?: Maybe<String_Comparison_Exp>;
  landlinecode4?: Maybe<String_Comparison_Exp>;
  landregister?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  latitude_a?: Maybe<Float8_Comparison_Exp>;
  latlong?: Maybe<String_Comparison_Exp>;
  legalname?: Maybe<String_Comparison_Exp>;
  levelofcompletion?: Maybe<String_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  lln?: Maybe<String_Comparison_Exp>;
  lln1?: Maybe<String_Comparison_Exp>;
  longitude_b?: Maybe<Float8_Comparison_Exp>;
  lvls?: Maybe<String_Comparison_Exp>;
  mainbusinessname?: Maybe<String_Comparison_Exp>;
  mob?: Maybe<String_Comparison_Exp>;
  mob1?: Maybe<String_Comparison_Exp>;
  mob3?: Maybe<String_Comparison_Exp>;
  mob4?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  mobile1?: Maybe<String_Comparison_Exp>;
  mobile1code?: Maybe<String_Comparison_Exp>;
  mobile3?: Maybe<String_Comparison_Exp>;
  mobile3code?: Maybe<String_Comparison_Exp>;
  mobile4?: Maybe<String_Comparison_Exp>;
  mobile4code?: Maybe<String_Comparison_Exp>;
  mobile5?: Maybe<String_Comparison_Exp>;
  mobile5code?: Maybe<String_Comparison_Exp>;
  mobilecode?: Maybe<String_Comparison_Exp>;
  name2?: Maybe<String_Comparison_Exp>;
  nationalid?: Maybe<String_Comparison_Exp>;
  nationalid2?: Maybe<String_Comparison_Exp>;
  nbrofslots?: Maybe<String_Comparison_Exp>;
  neighbourhoodactivities?: Maybe<String_Comparison_Exp>;
  neighbourhoodstatus?: Maybe<String_Comparison_Exp>;
  nin?: Maybe<String_Comparison_Exp>;
  nin2?: Maybe<String_Comparison_Exp>;
  objectid?: Maybe<Float8_Comparison_Exp>;
  other?: Maybe<String_Comparison_Exp>;
  otherfacilities?: Maybe<String_Comparison_Exp>;
  otherid?: Maybe<String_Comparison_Exp>;
  otherid1?: Maybe<String_Comparison_Exp>;
  otherid2?: Maybe<String_Comparison_Exp>;
  otherid3?: Maybe<String_Comparison_Exp>;
  otherid4?: Maybe<String_Comparison_Exp>;
  otherid4nbr?: Maybe<String_Comparison_Exp>;
  otherid6?: Maybe<String_Comparison_Exp>;
  otherid7?: Maybe<String_Comparison_Exp>;
  othernames?: Maybe<String_Comparison_Exp>;
  othernames3?: Maybe<String_Comparison_Exp>;
  otherservices?: Maybe<String_Comparison_Exp>;
  parish2?: Maybe<String_Comparison_Exp>;
  parking?: Maybe<String_Comparison_Exp>;
  parkingsufficient?: Maybe<String_Comparison_Exp>;
  pass?: Maybe<Float8_Comparison_Exp>;
  plotnumber?: Maybe<String_Comparison_Exp>;
  postalofficeboxnumber?: Maybe<String_Comparison_Exp>;
  postalofficeboxnumber1?: Maybe<String_Comparison_Exp>;
  powersupply?: Maybe<String_Comparison_Exp>;
  propertyname?: Maybe<String_Comparison_Exp>;
  reference?: Maybe<Bigint_Comparison_Exp>;
  relationshiptoowner?: Maybe<String_Comparison_Exp>;
  roadfrontageorgrid?: Maybe<String_Comparison_Exp>;
  roadname?: Maybe<String_Comparison_Exp>;
  roofcovering?: Maybe<String_Comparison_Exp>;
  roofcoveringothers?: Maybe<String_Comparison_Exp>;
  sanitation?: Maybe<String_Comparison_Exp>;
  security?: Maybe<String_Comparison_Exp>;
  shapeofplot?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  surname3?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  title1?: Maybe<String_Comparison_Exp>;
  topography?: Maybe<String_Comparison_Exp>;
  total_built_up_area?: Maybe<Float8_Comparison_Exp>;
  total_gross_area?: Maybe<Float8_Comparison_Exp>;
  typeofaccess?: Maybe<String_Comparison_Exp>;
  typeofbuilding?: Maybe<String_Comparison_Exp>;
  typeofcompany?: Maybe<String_Comparison_Exp>;
  typeoflandinterest?: Maybe<String_Comparison_Exp>;
  username?: Maybe<Float8_Comparison_Exp>;
  verticalcirculation?: Maybe<String_Comparison_Exp>;
  village2?: Maybe<String_Comparison_Exp>;
  volume?: Maybe<String_Comparison_Exp>;
  wallfinish?: Maybe<String_Comparison_Exp>;
  wallfinishothers?: Maybe<String_Comparison_Exp>;
  walls?: Maybe<String_Comparison_Exp>;
  wallsothers?: Maybe<String_Comparison_Exp>;
  wateraccessibility?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
  windows?: Maybe<String_Comparison_Exp>;
  windowsothers?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  camv_id?: Maybe<Scalars["bigint"]>;
  id3?: Maybe<Scalars["bigint"]>;
  latitude_a?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude_b?: Maybe<Scalars["float8"]>;
  objectid?: Maybe<Scalars["float8"]>;
  pass?: Maybe<Scalars["float8"]>;
  reference?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  total_built_up_area?: Maybe<Scalars["float8"]>;
  total_gross_area?: Maybe<Scalars["float8"]>;
  username?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Insert_Input = {
  AUTHORIZEDCONTACTPERSON?: Maybe<Scalars["String"]>;
  BoundaryFenceother?: Maybe<Scalars["String"]>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamp"]>;
  Creator?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  EditDate?: Maybe<Scalars["timestamp"]>;
  Editor?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["String"]>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Scalars["String"]>;
  SHAPE?: Maybe<Scalars["String"]>;
  SubCounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  access?: Maybe<Scalars["String"]>;
  agent?: Maybe<Scalars["String"]>;
  alternative?: Maybe<Scalars["String"]>;
  alternativeemailaddress?: Maybe<Scalars["String"]>;
  alternativefirstname?: Maybe<Scalars["String"]>;
  alternativelandlinenumber?: Maybe<Scalars["String"]>;
  alternativelandlinenumbercode?: Maybe<Scalars["String"]>;
  alternativelandlinenumberwithcountrycode?: Maybe<Scalars["String"]>;
  alternativeothername?: Maybe<Scalars["String"]>;
  alternativephonenumber1?: Maybe<Scalars["String"]>;
  alternativephonenumber1code?: Maybe<Scalars["String"]>;
  alternativephonenumber2?: Maybe<Scalars["String"]>;
  alternativephonenumber2code?: Maybe<Scalars["String"]>;
  alternativesurname?: Maybe<Scalars["String"]>;
  altphn1?: Maybe<Scalars["String"]>;
  altphn2?: Maybe<Scalars["String"]>;
  anyother?: Maybe<Scalars["String"]>;
  area_count?: Maybe<Scalars["String"]>;
  bldg2?: Maybe<Scalars["String"]>;
  blockname?: Maybe<Scalars["String"]>;
  blocknumber?: Maybe<Scalars["String"]>;
  blockother?: Maybe<Scalars["String"]>;
  boundary?: Maybe<Scalars["String"]>;
  buildingcondition?: Maybe<Scalars["String"]>;
  buildingname?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["bigint"]>;
  camv_serial?: Maybe<Scalars["String"]>;
  ceiling?: Maybe<Scalars["String"]>;
  ceilingothers?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  coin1?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  compound?: Maybe<Scalars["String"]>;
  compoundother?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  ctrycde?: Maybe<Scalars["String"]>;
  ctrycde1?: Maybe<Scalars["String"]>;
  ctrycde2?: Maybe<Scalars["String"]>;
  ctrycde4?: Maybe<Scalars["String"]>;
  datacollector?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  doors?: Maybe<Scalars["String"]>;
  doorsothers?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email1?: Maybe<Scalars["String"]>;
  email3?: Maybe<Scalars["String"]>;
  email5?: Maybe<Scalars["String"]>;
  entitytype?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  facilities?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  firstname3?: Maybe<Scalars["String"]>;
  flatblocknumber?: Maybe<Scalars["String"]>;
  flatnumber?: Maybe<Scalars["String"]>;
  floorconstruction?: Maybe<Scalars["String"]>;
  floorconstructionothers?: Maybe<Scalars["String"]>;
  floorfinish?: Maybe<Scalars["String"]>;
  floorfinishothers?: Maybe<Scalars["String"]>;
  folio?: Maybe<Scalars["String"]>;
  games?: Maybe<Scalars["String"]>;
  gamesother?: Maybe<Scalars["String"]>;
  gate?: Maybe<Scalars["String"]>;
  gateother?: Maybe<Scalars["String"]>;
  globalid?: Maybe<Scalars["String"]>;
  housenumber?: Maybe<Scalars["String"]>;
  hsenbr?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  id2?: Maybe<Scalars["String"]>;
  id3?: Maybe<Scalars["bigint"]>;
  land4?: Maybe<Scalars["String"]>;
  landline?: Maybe<Scalars["String"]>;
  landline1?: Maybe<Scalars["String"]>;
  landline1code?: Maybe<Scalars["String"]>;
  landline4?: Maybe<Scalars["String"]>;
  landlinecode?: Maybe<Scalars["String"]>;
  landlinecode4?: Maybe<Scalars["String"]>;
  landregister?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude_a?: Maybe<Scalars["float8"]>;
  latlong?: Maybe<Scalars["String"]>;
  legalname?: Maybe<Scalars["String"]>;
  levelofcompletion?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  lln?: Maybe<Scalars["String"]>;
  lln1?: Maybe<Scalars["String"]>;
  longitude_b?: Maybe<Scalars["float8"]>;
  lvls?: Maybe<Scalars["String"]>;
  mainbusinessname?: Maybe<Scalars["String"]>;
  mob?: Maybe<Scalars["String"]>;
  mob1?: Maybe<Scalars["String"]>;
  mob3?: Maybe<Scalars["String"]>;
  mob4?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  mobile1?: Maybe<Scalars["String"]>;
  mobile1code?: Maybe<Scalars["String"]>;
  mobile3?: Maybe<Scalars["String"]>;
  mobile3code?: Maybe<Scalars["String"]>;
  mobile4?: Maybe<Scalars["String"]>;
  mobile4code?: Maybe<Scalars["String"]>;
  mobile5?: Maybe<Scalars["String"]>;
  mobile5code?: Maybe<Scalars["String"]>;
  mobilecode?: Maybe<Scalars["String"]>;
  name2?: Maybe<Scalars["String"]>;
  nationalid?: Maybe<Scalars["String"]>;
  nationalid2?: Maybe<Scalars["String"]>;
  nbrofslots?: Maybe<Scalars["String"]>;
  neighbourhoodactivities?: Maybe<Scalars["String"]>;
  neighbourhoodstatus?: Maybe<Scalars["String"]>;
  nin?: Maybe<Scalars["String"]>;
  nin2?: Maybe<Scalars["String"]>;
  objectid?: Maybe<Scalars["float8"]>;
  other?: Maybe<Scalars["String"]>;
  otherfacilities?: Maybe<Scalars["String"]>;
  otherid?: Maybe<Scalars["String"]>;
  otherid1?: Maybe<Scalars["String"]>;
  otherid2?: Maybe<Scalars["String"]>;
  otherid3?: Maybe<Scalars["String"]>;
  otherid4?: Maybe<Scalars["String"]>;
  otherid4nbr?: Maybe<Scalars["String"]>;
  otherid6?: Maybe<Scalars["String"]>;
  otherid7?: Maybe<Scalars["String"]>;
  othernames?: Maybe<Scalars["String"]>;
  othernames3?: Maybe<Scalars["String"]>;
  otherservices?: Maybe<Scalars["String"]>;
  parish2?: Maybe<Scalars["String"]>;
  parking?: Maybe<Scalars["String"]>;
  parkingsufficient?: Maybe<Scalars["String"]>;
  pass?: Maybe<Scalars["float8"]>;
  plotnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  powersupply?: Maybe<Scalars["String"]>;
  propertyname?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["bigint"]>;
  relationshiptoowner?: Maybe<Scalars["String"]>;
  roadfrontageorgrid?: Maybe<Scalars["String"]>;
  roadname?: Maybe<Scalars["String"]>;
  roofcovering?: Maybe<Scalars["String"]>;
  roofcoveringothers?: Maybe<Scalars["String"]>;
  sanitation?: Maybe<Scalars["String"]>;
  security?: Maybe<Scalars["String"]>;
  shapeofplot?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  surname3?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  title1?: Maybe<Scalars["String"]>;
  topography?: Maybe<Scalars["String"]>;
  total_built_up_area?: Maybe<Scalars["float8"]>;
  total_gross_area?: Maybe<Scalars["float8"]>;
  typeofaccess?: Maybe<Scalars["String"]>;
  typeofbuilding?: Maybe<Scalars["String"]>;
  typeofcompany?: Maybe<Scalars["String"]>;
  typeoflandinterest?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["float8"]>;
  verticalcirculation?: Maybe<Scalars["String"]>;
  village2?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["String"]>;
  wallfinish?: Maybe<Scalars["String"]>;
  wallfinishothers?: Maybe<Scalars["String"]>;
  walls?: Maybe<Scalars["String"]>;
  wallsothers?: Maybe<Scalars["String"]>;
  wateraccessibility?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  windows?: Maybe<Scalars["String"]>;
  windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_RawProperties2_Max_Fields = {
  __typename?: "kcca_RawProperties2_max_fields";
  AUTHORIZEDCONTACTPERSON?: Maybe<Scalars["String"]>;
  BoundaryFenceother?: Maybe<Scalars["String"]>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamp"]>;
  Creator?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  EditDate?: Maybe<Scalars["timestamp"]>;
  Editor?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["String"]>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Scalars["String"]>;
  SHAPE?: Maybe<Scalars["String"]>;
  SubCounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  access?: Maybe<Scalars["String"]>;
  agent?: Maybe<Scalars["String"]>;
  alternative?: Maybe<Scalars["String"]>;
  alternativeemailaddress?: Maybe<Scalars["String"]>;
  alternativefirstname?: Maybe<Scalars["String"]>;
  alternativelandlinenumber?: Maybe<Scalars["String"]>;
  alternativelandlinenumbercode?: Maybe<Scalars["String"]>;
  alternativelandlinenumberwithcountrycode?: Maybe<Scalars["String"]>;
  alternativeothername?: Maybe<Scalars["String"]>;
  alternativephonenumber1?: Maybe<Scalars["String"]>;
  alternativephonenumber1code?: Maybe<Scalars["String"]>;
  alternativephonenumber2?: Maybe<Scalars["String"]>;
  alternativephonenumber2code?: Maybe<Scalars["String"]>;
  alternativesurname?: Maybe<Scalars["String"]>;
  altphn1?: Maybe<Scalars["String"]>;
  altphn2?: Maybe<Scalars["String"]>;
  anyother?: Maybe<Scalars["String"]>;
  area_count?: Maybe<Scalars["String"]>;
  bldg2?: Maybe<Scalars["String"]>;
  blockname?: Maybe<Scalars["String"]>;
  blocknumber?: Maybe<Scalars["String"]>;
  blockother?: Maybe<Scalars["String"]>;
  boundary?: Maybe<Scalars["String"]>;
  buildingcondition?: Maybe<Scalars["String"]>;
  buildingname?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["bigint"]>;
  camv_serial?: Maybe<Scalars["String"]>;
  ceiling?: Maybe<Scalars["String"]>;
  ceilingothers?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  coin1?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  compound?: Maybe<Scalars["String"]>;
  compoundother?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  ctrycde?: Maybe<Scalars["String"]>;
  ctrycde1?: Maybe<Scalars["String"]>;
  ctrycde2?: Maybe<Scalars["String"]>;
  ctrycde4?: Maybe<Scalars["String"]>;
  datacollector?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  doors?: Maybe<Scalars["String"]>;
  doorsothers?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email1?: Maybe<Scalars["String"]>;
  email3?: Maybe<Scalars["String"]>;
  email5?: Maybe<Scalars["String"]>;
  entitytype?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  facilities?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  firstname3?: Maybe<Scalars["String"]>;
  flatblocknumber?: Maybe<Scalars["String"]>;
  flatnumber?: Maybe<Scalars["String"]>;
  floorconstruction?: Maybe<Scalars["String"]>;
  floorconstructionothers?: Maybe<Scalars["String"]>;
  floorfinish?: Maybe<Scalars["String"]>;
  floorfinishothers?: Maybe<Scalars["String"]>;
  folio?: Maybe<Scalars["String"]>;
  games?: Maybe<Scalars["String"]>;
  gamesother?: Maybe<Scalars["String"]>;
  gate?: Maybe<Scalars["String"]>;
  gateother?: Maybe<Scalars["String"]>;
  globalid?: Maybe<Scalars["String"]>;
  housenumber?: Maybe<Scalars["String"]>;
  hsenbr?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  id2?: Maybe<Scalars["String"]>;
  id3?: Maybe<Scalars["bigint"]>;
  land4?: Maybe<Scalars["String"]>;
  landline?: Maybe<Scalars["String"]>;
  landline1?: Maybe<Scalars["String"]>;
  landline1code?: Maybe<Scalars["String"]>;
  landline4?: Maybe<Scalars["String"]>;
  landlinecode?: Maybe<Scalars["String"]>;
  landlinecode4?: Maybe<Scalars["String"]>;
  landregister?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude_a?: Maybe<Scalars["float8"]>;
  latlong?: Maybe<Scalars["String"]>;
  legalname?: Maybe<Scalars["String"]>;
  levelofcompletion?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  lln?: Maybe<Scalars["String"]>;
  lln1?: Maybe<Scalars["String"]>;
  longitude_b?: Maybe<Scalars["float8"]>;
  lvls?: Maybe<Scalars["String"]>;
  mainbusinessname?: Maybe<Scalars["String"]>;
  mob?: Maybe<Scalars["String"]>;
  mob1?: Maybe<Scalars["String"]>;
  mob3?: Maybe<Scalars["String"]>;
  mob4?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  mobile1?: Maybe<Scalars["String"]>;
  mobile1code?: Maybe<Scalars["String"]>;
  mobile3?: Maybe<Scalars["String"]>;
  mobile3code?: Maybe<Scalars["String"]>;
  mobile4?: Maybe<Scalars["String"]>;
  mobile4code?: Maybe<Scalars["String"]>;
  mobile5?: Maybe<Scalars["String"]>;
  mobile5code?: Maybe<Scalars["String"]>;
  mobilecode?: Maybe<Scalars["String"]>;
  name2?: Maybe<Scalars["String"]>;
  nationalid?: Maybe<Scalars["String"]>;
  nationalid2?: Maybe<Scalars["String"]>;
  nbrofslots?: Maybe<Scalars["String"]>;
  neighbourhoodactivities?: Maybe<Scalars["String"]>;
  neighbourhoodstatus?: Maybe<Scalars["String"]>;
  nin?: Maybe<Scalars["String"]>;
  nin2?: Maybe<Scalars["String"]>;
  objectid?: Maybe<Scalars["float8"]>;
  other?: Maybe<Scalars["String"]>;
  otherfacilities?: Maybe<Scalars["String"]>;
  otherid?: Maybe<Scalars["String"]>;
  otherid1?: Maybe<Scalars["String"]>;
  otherid2?: Maybe<Scalars["String"]>;
  otherid3?: Maybe<Scalars["String"]>;
  otherid4?: Maybe<Scalars["String"]>;
  otherid4nbr?: Maybe<Scalars["String"]>;
  otherid6?: Maybe<Scalars["String"]>;
  otherid7?: Maybe<Scalars["String"]>;
  othernames?: Maybe<Scalars["String"]>;
  othernames3?: Maybe<Scalars["String"]>;
  otherservices?: Maybe<Scalars["String"]>;
  parish2?: Maybe<Scalars["String"]>;
  parking?: Maybe<Scalars["String"]>;
  parkingsufficient?: Maybe<Scalars["String"]>;
  pass?: Maybe<Scalars["float8"]>;
  plotnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  powersupply?: Maybe<Scalars["String"]>;
  propertyname?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["bigint"]>;
  relationshiptoowner?: Maybe<Scalars["String"]>;
  roadfrontageorgrid?: Maybe<Scalars["String"]>;
  roadname?: Maybe<Scalars["String"]>;
  roofcovering?: Maybe<Scalars["String"]>;
  roofcoveringothers?: Maybe<Scalars["String"]>;
  sanitation?: Maybe<Scalars["String"]>;
  security?: Maybe<Scalars["String"]>;
  shapeofplot?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  surname3?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  title1?: Maybe<Scalars["String"]>;
  topography?: Maybe<Scalars["String"]>;
  total_built_up_area?: Maybe<Scalars["float8"]>;
  total_gross_area?: Maybe<Scalars["float8"]>;
  typeofaccess?: Maybe<Scalars["String"]>;
  typeofbuilding?: Maybe<Scalars["String"]>;
  typeofcompany?: Maybe<Scalars["String"]>;
  typeoflandinterest?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["float8"]>;
  verticalcirculation?: Maybe<Scalars["String"]>;
  village2?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["String"]>;
  wallfinish?: Maybe<Scalars["String"]>;
  wallfinishothers?: Maybe<Scalars["String"]>;
  walls?: Maybe<Scalars["String"]>;
  wallsothers?: Maybe<Scalars["String"]>;
  wateraccessibility?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  windows?: Maybe<Scalars["String"]>;
  windowsothers?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Max_Order_By = {
  AUTHORIZEDCONTACTPERSON?: Maybe<Order_By>;
  BoundaryFenceother?: Maybe<Order_By>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  CreationDate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  EditDate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Order_By>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Order_By>;
  SHAPE?: Maybe<Order_By>;
  SubCounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  access?: Maybe<Order_By>;
  agent?: Maybe<Order_By>;
  alternative?: Maybe<Order_By>;
  alternativeemailaddress?: Maybe<Order_By>;
  alternativefirstname?: Maybe<Order_By>;
  alternativelandlinenumber?: Maybe<Order_By>;
  alternativelandlinenumbercode?: Maybe<Order_By>;
  alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  alternativeothername?: Maybe<Order_By>;
  alternativephonenumber1?: Maybe<Order_By>;
  alternativephonenumber1code?: Maybe<Order_By>;
  alternativephonenumber2?: Maybe<Order_By>;
  alternativephonenumber2code?: Maybe<Order_By>;
  alternativesurname?: Maybe<Order_By>;
  altphn1?: Maybe<Order_By>;
  altphn2?: Maybe<Order_By>;
  anyother?: Maybe<Order_By>;
  area_count?: Maybe<Order_By>;
  bldg2?: Maybe<Order_By>;
  blockname?: Maybe<Order_By>;
  blocknumber?: Maybe<Order_By>;
  blockother?: Maybe<Order_By>;
  boundary?: Maybe<Order_By>;
  buildingcondition?: Maybe<Order_By>;
  buildingname?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  camv_serial?: Maybe<Order_By>;
  ceiling?: Maybe<Order_By>;
  ceilingothers?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  coin1?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  compound?: Maybe<Order_By>;
  compoundother?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  ctrycde?: Maybe<Order_By>;
  ctrycde1?: Maybe<Order_By>;
  ctrycde2?: Maybe<Order_By>;
  ctrycde4?: Maybe<Order_By>;
  datacollector?: Maybe<Order_By>;
  districtorcitywhereboxislocated?: Maybe<Order_By>;
  districtorcitywhereboxislocated1?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  doorsothers?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email1?: Maybe<Order_By>;
  email3?: Maybe<Order_By>;
  email5?: Maybe<Order_By>;
  entitytype?: Maybe<Order_By>;
  environment?: Maybe<Order_By>;
  facilities?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  firstname3?: Maybe<Order_By>;
  flatblocknumber?: Maybe<Order_By>;
  flatnumber?: Maybe<Order_By>;
  floorconstruction?: Maybe<Order_By>;
  floorconstructionothers?: Maybe<Order_By>;
  floorfinish?: Maybe<Order_By>;
  floorfinishothers?: Maybe<Order_By>;
  folio?: Maybe<Order_By>;
  games?: Maybe<Order_By>;
  gamesother?: Maybe<Order_By>;
  gate?: Maybe<Order_By>;
  gateother?: Maybe<Order_By>;
  globalid?: Maybe<Order_By>;
  housenumber?: Maybe<Order_By>;
  hsenbr?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id2?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  land4?: Maybe<Order_By>;
  landline?: Maybe<Order_By>;
  landline1?: Maybe<Order_By>;
  landline1code?: Maybe<Order_By>;
  landline4?: Maybe<Order_By>;
  landlinecode?: Maybe<Order_By>;
  landlinecode4?: Maybe<Order_By>;
  landregister?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  latlong?: Maybe<Order_By>;
  legalname?: Maybe<Order_By>;
  levelofcompletion?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  lln?: Maybe<Order_By>;
  lln1?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  lvls?: Maybe<Order_By>;
  mainbusinessname?: Maybe<Order_By>;
  mob?: Maybe<Order_By>;
  mob1?: Maybe<Order_By>;
  mob3?: Maybe<Order_By>;
  mob4?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile1?: Maybe<Order_By>;
  mobile1code?: Maybe<Order_By>;
  mobile3?: Maybe<Order_By>;
  mobile3code?: Maybe<Order_By>;
  mobile4?: Maybe<Order_By>;
  mobile4code?: Maybe<Order_By>;
  mobile5?: Maybe<Order_By>;
  mobile5code?: Maybe<Order_By>;
  mobilecode?: Maybe<Order_By>;
  name2?: Maybe<Order_By>;
  nationalid?: Maybe<Order_By>;
  nationalid2?: Maybe<Order_By>;
  nbrofslots?: Maybe<Order_By>;
  neighbourhoodactivities?: Maybe<Order_By>;
  neighbourhoodstatus?: Maybe<Order_By>;
  nin?: Maybe<Order_By>;
  nin2?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  other?: Maybe<Order_By>;
  otherfacilities?: Maybe<Order_By>;
  otherid?: Maybe<Order_By>;
  otherid1?: Maybe<Order_By>;
  otherid2?: Maybe<Order_By>;
  otherid3?: Maybe<Order_By>;
  otherid4?: Maybe<Order_By>;
  otherid4nbr?: Maybe<Order_By>;
  otherid6?: Maybe<Order_By>;
  otherid7?: Maybe<Order_By>;
  othernames?: Maybe<Order_By>;
  othernames3?: Maybe<Order_By>;
  otherservices?: Maybe<Order_By>;
  parish2?: Maybe<Order_By>;
  parking?: Maybe<Order_By>;
  parkingsufficient?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  plotnumber?: Maybe<Order_By>;
  postalofficeboxnumber?: Maybe<Order_By>;
  postalofficeboxnumber1?: Maybe<Order_By>;
  powersupply?: Maybe<Order_By>;
  propertyname?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  relationshiptoowner?: Maybe<Order_By>;
  roadfrontageorgrid?: Maybe<Order_By>;
  roadname?: Maybe<Order_By>;
  roofcovering?: Maybe<Order_By>;
  roofcoveringothers?: Maybe<Order_By>;
  sanitation?: Maybe<Order_By>;
  security?: Maybe<Order_By>;
  shapeofplot?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  surname3?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  title1?: Maybe<Order_By>;
  topography?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  typeofaccess?: Maybe<Order_By>;
  typeofbuilding?: Maybe<Order_By>;
  typeofcompany?: Maybe<Order_By>;
  typeoflandinterest?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
  verticalcirculation?: Maybe<Order_By>;
  village2?: Maybe<Order_By>;
  volume?: Maybe<Order_By>;
  wallfinish?: Maybe<Order_By>;
  wallfinishothers?: Maybe<Order_By>;
  walls?: Maybe<Order_By>;
  wallsothers?: Maybe<Order_By>;
  wateraccessibility?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  windows?: Maybe<Order_By>;
  windowsothers?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_RawProperties2_Min_Fields = {
  __typename?: "kcca_RawProperties2_min_fields";
  AUTHORIZEDCONTACTPERSON?: Maybe<Scalars["String"]>;
  BoundaryFenceother?: Maybe<Scalars["String"]>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamp"]>;
  Creator?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  EditDate?: Maybe<Scalars["timestamp"]>;
  Editor?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["String"]>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Scalars["String"]>;
  SHAPE?: Maybe<Scalars["String"]>;
  SubCounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  access?: Maybe<Scalars["String"]>;
  agent?: Maybe<Scalars["String"]>;
  alternative?: Maybe<Scalars["String"]>;
  alternativeemailaddress?: Maybe<Scalars["String"]>;
  alternativefirstname?: Maybe<Scalars["String"]>;
  alternativelandlinenumber?: Maybe<Scalars["String"]>;
  alternativelandlinenumbercode?: Maybe<Scalars["String"]>;
  alternativelandlinenumberwithcountrycode?: Maybe<Scalars["String"]>;
  alternativeothername?: Maybe<Scalars["String"]>;
  alternativephonenumber1?: Maybe<Scalars["String"]>;
  alternativephonenumber1code?: Maybe<Scalars["String"]>;
  alternativephonenumber2?: Maybe<Scalars["String"]>;
  alternativephonenumber2code?: Maybe<Scalars["String"]>;
  alternativesurname?: Maybe<Scalars["String"]>;
  altphn1?: Maybe<Scalars["String"]>;
  altphn2?: Maybe<Scalars["String"]>;
  anyother?: Maybe<Scalars["String"]>;
  area_count?: Maybe<Scalars["String"]>;
  bldg2?: Maybe<Scalars["String"]>;
  blockname?: Maybe<Scalars["String"]>;
  blocknumber?: Maybe<Scalars["String"]>;
  blockother?: Maybe<Scalars["String"]>;
  boundary?: Maybe<Scalars["String"]>;
  buildingcondition?: Maybe<Scalars["String"]>;
  buildingname?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["bigint"]>;
  camv_serial?: Maybe<Scalars["String"]>;
  ceiling?: Maybe<Scalars["String"]>;
  ceilingothers?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  coin1?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  compound?: Maybe<Scalars["String"]>;
  compoundother?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  ctrycde?: Maybe<Scalars["String"]>;
  ctrycde1?: Maybe<Scalars["String"]>;
  ctrycde2?: Maybe<Scalars["String"]>;
  ctrycde4?: Maybe<Scalars["String"]>;
  datacollector?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  doors?: Maybe<Scalars["String"]>;
  doorsothers?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email1?: Maybe<Scalars["String"]>;
  email3?: Maybe<Scalars["String"]>;
  email5?: Maybe<Scalars["String"]>;
  entitytype?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  facilities?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  firstname3?: Maybe<Scalars["String"]>;
  flatblocknumber?: Maybe<Scalars["String"]>;
  flatnumber?: Maybe<Scalars["String"]>;
  floorconstruction?: Maybe<Scalars["String"]>;
  floorconstructionothers?: Maybe<Scalars["String"]>;
  floorfinish?: Maybe<Scalars["String"]>;
  floorfinishothers?: Maybe<Scalars["String"]>;
  folio?: Maybe<Scalars["String"]>;
  games?: Maybe<Scalars["String"]>;
  gamesother?: Maybe<Scalars["String"]>;
  gate?: Maybe<Scalars["String"]>;
  gateother?: Maybe<Scalars["String"]>;
  globalid?: Maybe<Scalars["String"]>;
  housenumber?: Maybe<Scalars["String"]>;
  hsenbr?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  id2?: Maybe<Scalars["String"]>;
  id3?: Maybe<Scalars["bigint"]>;
  land4?: Maybe<Scalars["String"]>;
  landline?: Maybe<Scalars["String"]>;
  landline1?: Maybe<Scalars["String"]>;
  landline1code?: Maybe<Scalars["String"]>;
  landline4?: Maybe<Scalars["String"]>;
  landlinecode?: Maybe<Scalars["String"]>;
  landlinecode4?: Maybe<Scalars["String"]>;
  landregister?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude_a?: Maybe<Scalars["float8"]>;
  latlong?: Maybe<Scalars["String"]>;
  legalname?: Maybe<Scalars["String"]>;
  levelofcompletion?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  lln?: Maybe<Scalars["String"]>;
  lln1?: Maybe<Scalars["String"]>;
  longitude_b?: Maybe<Scalars["float8"]>;
  lvls?: Maybe<Scalars["String"]>;
  mainbusinessname?: Maybe<Scalars["String"]>;
  mob?: Maybe<Scalars["String"]>;
  mob1?: Maybe<Scalars["String"]>;
  mob3?: Maybe<Scalars["String"]>;
  mob4?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  mobile1?: Maybe<Scalars["String"]>;
  mobile1code?: Maybe<Scalars["String"]>;
  mobile3?: Maybe<Scalars["String"]>;
  mobile3code?: Maybe<Scalars["String"]>;
  mobile4?: Maybe<Scalars["String"]>;
  mobile4code?: Maybe<Scalars["String"]>;
  mobile5?: Maybe<Scalars["String"]>;
  mobile5code?: Maybe<Scalars["String"]>;
  mobilecode?: Maybe<Scalars["String"]>;
  name2?: Maybe<Scalars["String"]>;
  nationalid?: Maybe<Scalars["String"]>;
  nationalid2?: Maybe<Scalars["String"]>;
  nbrofslots?: Maybe<Scalars["String"]>;
  neighbourhoodactivities?: Maybe<Scalars["String"]>;
  neighbourhoodstatus?: Maybe<Scalars["String"]>;
  nin?: Maybe<Scalars["String"]>;
  nin2?: Maybe<Scalars["String"]>;
  objectid?: Maybe<Scalars["float8"]>;
  other?: Maybe<Scalars["String"]>;
  otherfacilities?: Maybe<Scalars["String"]>;
  otherid?: Maybe<Scalars["String"]>;
  otherid1?: Maybe<Scalars["String"]>;
  otherid2?: Maybe<Scalars["String"]>;
  otherid3?: Maybe<Scalars["String"]>;
  otherid4?: Maybe<Scalars["String"]>;
  otherid4nbr?: Maybe<Scalars["String"]>;
  otherid6?: Maybe<Scalars["String"]>;
  otherid7?: Maybe<Scalars["String"]>;
  othernames?: Maybe<Scalars["String"]>;
  othernames3?: Maybe<Scalars["String"]>;
  otherservices?: Maybe<Scalars["String"]>;
  parish2?: Maybe<Scalars["String"]>;
  parking?: Maybe<Scalars["String"]>;
  parkingsufficient?: Maybe<Scalars["String"]>;
  pass?: Maybe<Scalars["float8"]>;
  plotnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  powersupply?: Maybe<Scalars["String"]>;
  propertyname?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["bigint"]>;
  relationshiptoowner?: Maybe<Scalars["String"]>;
  roadfrontageorgrid?: Maybe<Scalars["String"]>;
  roadname?: Maybe<Scalars["String"]>;
  roofcovering?: Maybe<Scalars["String"]>;
  roofcoveringothers?: Maybe<Scalars["String"]>;
  sanitation?: Maybe<Scalars["String"]>;
  security?: Maybe<Scalars["String"]>;
  shapeofplot?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  surname3?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  title1?: Maybe<Scalars["String"]>;
  topography?: Maybe<Scalars["String"]>;
  total_built_up_area?: Maybe<Scalars["float8"]>;
  total_gross_area?: Maybe<Scalars["float8"]>;
  typeofaccess?: Maybe<Scalars["String"]>;
  typeofbuilding?: Maybe<Scalars["String"]>;
  typeofcompany?: Maybe<Scalars["String"]>;
  typeoflandinterest?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["float8"]>;
  verticalcirculation?: Maybe<Scalars["String"]>;
  village2?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["String"]>;
  wallfinish?: Maybe<Scalars["String"]>;
  wallfinishothers?: Maybe<Scalars["String"]>;
  walls?: Maybe<Scalars["String"]>;
  wallsothers?: Maybe<Scalars["String"]>;
  wateraccessibility?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  windows?: Maybe<Scalars["String"]>;
  windowsothers?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Min_Order_By = {
  AUTHORIZEDCONTACTPERSON?: Maybe<Order_By>;
  BoundaryFenceother?: Maybe<Order_By>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  CreationDate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  EditDate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Order_By>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Order_By>;
  SHAPE?: Maybe<Order_By>;
  SubCounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  access?: Maybe<Order_By>;
  agent?: Maybe<Order_By>;
  alternative?: Maybe<Order_By>;
  alternativeemailaddress?: Maybe<Order_By>;
  alternativefirstname?: Maybe<Order_By>;
  alternativelandlinenumber?: Maybe<Order_By>;
  alternativelandlinenumbercode?: Maybe<Order_By>;
  alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  alternativeothername?: Maybe<Order_By>;
  alternativephonenumber1?: Maybe<Order_By>;
  alternativephonenumber1code?: Maybe<Order_By>;
  alternativephonenumber2?: Maybe<Order_By>;
  alternativephonenumber2code?: Maybe<Order_By>;
  alternativesurname?: Maybe<Order_By>;
  altphn1?: Maybe<Order_By>;
  altphn2?: Maybe<Order_By>;
  anyother?: Maybe<Order_By>;
  area_count?: Maybe<Order_By>;
  bldg2?: Maybe<Order_By>;
  blockname?: Maybe<Order_By>;
  blocknumber?: Maybe<Order_By>;
  blockother?: Maybe<Order_By>;
  boundary?: Maybe<Order_By>;
  buildingcondition?: Maybe<Order_By>;
  buildingname?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  camv_serial?: Maybe<Order_By>;
  ceiling?: Maybe<Order_By>;
  ceilingothers?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  coin1?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  compound?: Maybe<Order_By>;
  compoundother?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  ctrycde?: Maybe<Order_By>;
  ctrycde1?: Maybe<Order_By>;
  ctrycde2?: Maybe<Order_By>;
  ctrycde4?: Maybe<Order_By>;
  datacollector?: Maybe<Order_By>;
  districtorcitywhereboxislocated?: Maybe<Order_By>;
  districtorcitywhereboxislocated1?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  doorsothers?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email1?: Maybe<Order_By>;
  email3?: Maybe<Order_By>;
  email5?: Maybe<Order_By>;
  entitytype?: Maybe<Order_By>;
  environment?: Maybe<Order_By>;
  facilities?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  firstname3?: Maybe<Order_By>;
  flatblocknumber?: Maybe<Order_By>;
  flatnumber?: Maybe<Order_By>;
  floorconstruction?: Maybe<Order_By>;
  floorconstructionothers?: Maybe<Order_By>;
  floorfinish?: Maybe<Order_By>;
  floorfinishothers?: Maybe<Order_By>;
  folio?: Maybe<Order_By>;
  games?: Maybe<Order_By>;
  gamesother?: Maybe<Order_By>;
  gate?: Maybe<Order_By>;
  gateother?: Maybe<Order_By>;
  globalid?: Maybe<Order_By>;
  housenumber?: Maybe<Order_By>;
  hsenbr?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id2?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  land4?: Maybe<Order_By>;
  landline?: Maybe<Order_By>;
  landline1?: Maybe<Order_By>;
  landline1code?: Maybe<Order_By>;
  landline4?: Maybe<Order_By>;
  landlinecode?: Maybe<Order_By>;
  landlinecode4?: Maybe<Order_By>;
  landregister?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  latlong?: Maybe<Order_By>;
  legalname?: Maybe<Order_By>;
  levelofcompletion?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  lln?: Maybe<Order_By>;
  lln1?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  lvls?: Maybe<Order_By>;
  mainbusinessname?: Maybe<Order_By>;
  mob?: Maybe<Order_By>;
  mob1?: Maybe<Order_By>;
  mob3?: Maybe<Order_By>;
  mob4?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile1?: Maybe<Order_By>;
  mobile1code?: Maybe<Order_By>;
  mobile3?: Maybe<Order_By>;
  mobile3code?: Maybe<Order_By>;
  mobile4?: Maybe<Order_By>;
  mobile4code?: Maybe<Order_By>;
  mobile5?: Maybe<Order_By>;
  mobile5code?: Maybe<Order_By>;
  mobilecode?: Maybe<Order_By>;
  name2?: Maybe<Order_By>;
  nationalid?: Maybe<Order_By>;
  nationalid2?: Maybe<Order_By>;
  nbrofslots?: Maybe<Order_By>;
  neighbourhoodactivities?: Maybe<Order_By>;
  neighbourhoodstatus?: Maybe<Order_By>;
  nin?: Maybe<Order_By>;
  nin2?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  other?: Maybe<Order_By>;
  otherfacilities?: Maybe<Order_By>;
  otherid?: Maybe<Order_By>;
  otherid1?: Maybe<Order_By>;
  otherid2?: Maybe<Order_By>;
  otherid3?: Maybe<Order_By>;
  otherid4?: Maybe<Order_By>;
  otherid4nbr?: Maybe<Order_By>;
  otherid6?: Maybe<Order_By>;
  otherid7?: Maybe<Order_By>;
  othernames?: Maybe<Order_By>;
  othernames3?: Maybe<Order_By>;
  otherservices?: Maybe<Order_By>;
  parish2?: Maybe<Order_By>;
  parking?: Maybe<Order_By>;
  parkingsufficient?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  plotnumber?: Maybe<Order_By>;
  postalofficeboxnumber?: Maybe<Order_By>;
  postalofficeboxnumber1?: Maybe<Order_By>;
  powersupply?: Maybe<Order_By>;
  propertyname?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  relationshiptoowner?: Maybe<Order_By>;
  roadfrontageorgrid?: Maybe<Order_By>;
  roadname?: Maybe<Order_By>;
  roofcovering?: Maybe<Order_By>;
  roofcoveringothers?: Maybe<Order_By>;
  sanitation?: Maybe<Order_By>;
  security?: Maybe<Order_By>;
  shapeofplot?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  surname3?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  title1?: Maybe<Order_By>;
  topography?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  typeofaccess?: Maybe<Order_By>;
  typeofbuilding?: Maybe<Order_By>;
  typeofcompany?: Maybe<Order_By>;
  typeoflandinterest?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
  verticalcirculation?: Maybe<Order_By>;
  village2?: Maybe<Order_By>;
  volume?: Maybe<Order_By>;
  wallfinish?: Maybe<Order_By>;
  wallfinishothers?: Maybe<Order_By>;
  walls?: Maybe<Order_By>;
  wallsothers?: Maybe<Order_By>;
  wateraccessibility?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  windows?: Maybe<Order_By>;
  windowsothers?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Mutation_Response = {
  __typename?: "kcca_RawProperties2_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_RawProperties2>;
};

/** input type for inserting object relation for remote table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Obj_Rel_Insert_Input = {
  data: Kcca_RawProperties2_Insert_Input;
};

/** ordering options when selecting data from "kcca.RawProperties2" */
export type Kcca_RawProperties2_Order_By = {
  AUTHORIZEDCONTACTPERSON?: Maybe<Order_By>;
  BoundaryFenceother?: Maybe<Order_By>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  CreationDate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  EditDate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Order_By>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Order_By>;
  SHAPE?: Maybe<Order_By>;
  SubCounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  access?: Maybe<Order_By>;
  agent?: Maybe<Order_By>;
  alternative?: Maybe<Order_By>;
  alternativeemailaddress?: Maybe<Order_By>;
  alternativefirstname?: Maybe<Order_By>;
  alternativelandlinenumber?: Maybe<Order_By>;
  alternativelandlinenumbercode?: Maybe<Order_By>;
  alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  alternativeothername?: Maybe<Order_By>;
  alternativephonenumber1?: Maybe<Order_By>;
  alternativephonenumber1code?: Maybe<Order_By>;
  alternativephonenumber2?: Maybe<Order_By>;
  alternativephonenumber2code?: Maybe<Order_By>;
  alternativesurname?: Maybe<Order_By>;
  altphn1?: Maybe<Order_By>;
  altphn2?: Maybe<Order_By>;
  anyother?: Maybe<Order_By>;
  area_count?: Maybe<Order_By>;
  bldg2?: Maybe<Order_By>;
  blockname?: Maybe<Order_By>;
  blocknumber?: Maybe<Order_By>;
  blockother?: Maybe<Order_By>;
  boundary?: Maybe<Order_By>;
  buildingcondition?: Maybe<Order_By>;
  buildingname?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  camv_serial?: Maybe<Order_By>;
  ceiling?: Maybe<Order_By>;
  ceilingothers?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  coin1?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  compound?: Maybe<Order_By>;
  compoundother?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  ctrycde?: Maybe<Order_By>;
  ctrycde1?: Maybe<Order_By>;
  ctrycde2?: Maybe<Order_By>;
  ctrycde4?: Maybe<Order_By>;
  datacollector?: Maybe<Order_By>;
  districtorcitywhereboxislocated?: Maybe<Order_By>;
  districtorcitywhereboxislocated1?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  doorsothers?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email1?: Maybe<Order_By>;
  email3?: Maybe<Order_By>;
  email5?: Maybe<Order_By>;
  entitytype?: Maybe<Order_By>;
  environment?: Maybe<Order_By>;
  facilities?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  firstname3?: Maybe<Order_By>;
  flatblocknumber?: Maybe<Order_By>;
  flatnumber?: Maybe<Order_By>;
  floorconstruction?: Maybe<Order_By>;
  floorconstructionothers?: Maybe<Order_By>;
  floorfinish?: Maybe<Order_By>;
  floorfinishothers?: Maybe<Order_By>;
  folio?: Maybe<Order_By>;
  games?: Maybe<Order_By>;
  gamesother?: Maybe<Order_By>;
  gate?: Maybe<Order_By>;
  gateother?: Maybe<Order_By>;
  globalid?: Maybe<Order_By>;
  housenumber?: Maybe<Order_By>;
  hsenbr?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id2?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  land4?: Maybe<Order_By>;
  landline?: Maybe<Order_By>;
  landline1?: Maybe<Order_By>;
  landline1code?: Maybe<Order_By>;
  landline4?: Maybe<Order_By>;
  landlinecode?: Maybe<Order_By>;
  landlinecode4?: Maybe<Order_By>;
  landregister?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  latlong?: Maybe<Order_By>;
  legalname?: Maybe<Order_By>;
  levelofcompletion?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  lln?: Maybe<Order_By>;
  lln1?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  lvls?: Maybe<Order_By>;
  mainbusinessname?: Maybe<Order_By>;
  mob?: Maybe<Order_By>;
  mob1?: Maybe<Order_By>;
  mob3?: Maybe<Order_By>;
  mob4?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile1?: Maybe<Order_By>;
  mobile1code?: Maybe<Order_By>;
  mobile3?: Maybe<Order_By>;
  mobile3code?: Maybe<Order_By>;
  mobile4?: Maybe<Order_By>;
  mobile4code?: Maybe<Order_By>;
  mobile5?: Maybe<Order_By>;
  mobile5code?: Maybe<Order_By>;
  mobilecode?: Maybe<Order_By>;
  name2?: Maybe<Order_By>;
  nationalid?: Maybe<Order_By>;
  nationalid2?: Maybe<Order_By>;
  nbrofslots?: Maybe<Order_By>;
  neighbourhoodactivities?: Maybe<Order_By>;
  neighbourhoodstatus?: Maybe<Order_By>;
  nin?: Maybe<Order_By>;
  nin2?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  other?: Maybe<Order_By>;
  otherfacilities?: Maybe<Order_By>;
  otherid?: Maybe<Order_By>;
  otherid1?: Maybe<Order_By>;
  otherid2?: Maybe<Order_By>;
  otherid3?: Maybe<Order_By>;
  otherid4?: Maybe<Order_By>;
  otherid4nbr?: Maybe<Order_By>;
  otherid6?: Maybe<Order_By>;
  otherid7?: Maybe<Order_By>;
  othernames?: Maybe<Order_By>;
  othernames3?: Maybe<Order_By>;
  otherservices?: Maybe<Order_By>;
  parish2?: Maybe<Order_By>;
  parking?: Maybe<Order_By>;
  parkingsufficient?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  plotnumber?: Maybe<Order_By>;
  postalofficeboxnumber?: Maybe<Order_By>;
  postalofficeboxnumber1?: Maybe<Order_By>;
  powersupply?: Maybe<Order_By>;
  propertyname?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  relationshiptoowner?: Maybe<Order_By>;
  roadfrontageorgrid?: Maybe<Order_By>;
  roadname?: Maybe<Order_By>;
  roofcovering?: Maybe<Order_By>;
  roofcoveringothers?: Maybe<Order_By>;
  sanitation?: Maybe<Order_By>;
  security?: Maybe<Order_By>;
  shapeofplot?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  surname3?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  title1?: Maybe<Order_By>;
  topography?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  typeofaccess?: Maybe<Order_By>;
  typeofbuilding?: Maybe<Order_By>;
  typeofcompany?: Maybe<Order_By>;
  typeoflandinterest?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
  verticalcirculation?: Maybe<Order_By>;
  village2?: Maybe<Order_By>;
  volume?: Maybe<Order_By>;
  wallfinish?: Maybe<Order_By>;
  wallfinishothers?: Maybe<Order_By>;
  walls?: Maybe<Order_By>;
  wallsothers?: Maybe<Order_By>;
  wateraccessibility?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  windows?: Maybe<Order_By>;
  windowsothers?: Maybe<Order_By>;
};

/** select columns of table "kcca.RawProperties2" */
export enum Kcca_RawProperties2_Select_Column {
  /** column name */
  Authorizedcontactperson = "AUTHORIZEDCONTACTPERSON",
  /** column name */
  BoundaryFenceother = "BoundaryFenceother",
  /** column name */
  Contactdetailsofdirector = "CONTACTDETAILSOFDIRECTOR",
  /** column name */
  County = "County",
  /** column name */
  County1 = "County1",
  /** column name */
  CreationDate = "CreationDate",
  /** column name */
  Creator = "Creator",
  /** column name */
  District = "District",
  /** column name */
  District1 = "District1",
  /** column name */
  Division = "Division",
  /** column name */
  EditDate = "EditDate",
  /** column name */
  Editor = "Editor",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Partiiindividualownershipdetails = "PARTIIINDIVIDUALOWNERSHIPDETAILS",
  /** column name */
  Partinonindividualownershipdetails = "PARTINONINDIVIDUALOWNERSHIPDETAILS",
  /** column name */
  Parish = "Parish",
  /** column name */
  Parish1 = "Parish1",
  /** column name */
  PropertySubType = "PropertySubType",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  Sectionapropertyownersdetails = "SECTIONAPROPERTYOWNERSDETAILS",
  /** column name */
  Shape = "SHAPE",
  /** column name */
  SubCounty = "SubCounty",
  /** column name */
  Subcounty1 = "Subcounty1",
  /** column name */
  Village = "Village",
  /** column name */
  Village1 = "Village1",
  /** column name */
  Access = "access",
  /** column name */
  Agent = "agent",
  /** column name */
  Alternative = "alternative",
  /** column name */
  Alternativeemailaddress = "alternativeemailaddress",
  /** column name */
  Alternativefirstname = "alternativefirstname",
  /** column name */
  Alternativelandlinenumber = "alternativelandlinenumber",
  /** column name */
  Alternativelandlinenumbercode = "alternativelandlinenumbercode",
  /** column name */
  Alternativelandlinenumberwithcountrycode = "alternativelandlinenumberwithcountrycode",
  /** column name */
  Alternativeothername = "alternativeothername",
  /** column name */
  Alternativephonenumber1 = "alternativephonenumber1",
  /** column name */
  Alternativephonenumber1code = "alternativephonenumber1code",
  /** column name */
  Alternativephonenumber2 = "alternativephonenumber2",
  /** column name */
  Alternativephonenumber2code = "alternativephonenumber2code",
  /** column name */
  Alternativesurname = "alternativesurname",
  /** column name */
  Altphn1 = "altphn1",
  /** column name */
  Altphn2 = "altphn2",
  /** column name */
  Anyother = "anyother",
  /** column name */
  AreaCount = "area_count",
  /** column name */
  Bldg2 = "bldg2",
  /** column name */
  Blockname = "blockname",
  /** column name */
  Blocknumber = "blocknumber",
  /** column name */
  Blockother = "blockother",
  /** column name */
  Boundary = "boundary",
  /** column name */
  Buildingcondition = "buildingcondition",
  /** column name */
  Buildingname = "buildingname",
  /** column name */
  CamvId = "camv_id",
  /** column name */
  CamvSerial = "camv_serial",
  /** column name */
  Ceiling = "ceiling",
  /** column name */
  Ceilingothers = "ceilingothers",
  /** column name */
  Coin = "coin",
  /** column name */
  Coin1 = "coin1",
  /** column name */
  Comment = "comment",
  /** column name */
  Compound = "compound",
  /** column name */
  Compoundother = "compoundother",
  /** column name */
  Country = "country",
  /** column name */
  Ctrycde = "ctrycde",
  /** column name */
  Ctrycde1 = "ctrycde1",
  /** column name */
  Ctrycde2 = "ctrycde2",
  /** column name */
  Ctrycde4 = "ctrycde4",
  /** column name */
  Datacollector = "datacollector",
  /** column name */
  Districtorcitywhereboxislocated = "districtorcitywhereboxislocated",
  /** column name */
  Districtorcitywhereboxislocated1 = "districtorcitywhereboxislocated1",
  /** column name */
  Doors = "doors",
  /** column name */
  Doorsothers = "doorsothers",
  /** column name */
  Email = "email",
  /** column name */
  Email1 = "email1",
  /** column name */
  Email3 = "email3",
  /** column name */
  Email5 = "email5",
  /** column name */
  Entitytype = "entitytype",
  /** column name */
  Environment = "environment",
  /** column name */
  Facilities = "facilities",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  Firstname3 = "firstname3",
  /** column name */
  Flatblocknumber = "flatblocknumber",
  /** column name */
  Flatnumber = "flatnumber",
  /** column name */
  Floorconstruction = "floorconstruction",
  /** column name */
  Floorconstructionothers = "floorconstructionothers",
  /** column name */
  Floorfinish = "floorfinish",
  /** column name */
  Floorfinishothers = "floorfinishothers",
  /** column name */
  Folio = "folio",
  /** column name */
  Games = "games",
  /** column name */
  Gamesother = "gamesother",
  /** column name */
  Gate = "gate",
  /** column name */
  Gateother = "gateother",
  /** column name */
  Globalid = "globalid",
  /** column name */
  Housenumber = "housenumber",
  /** column name */
  Hsenbr = "hsenbr",
  /** column name */
  Id = "id",
  /** column name */
  Id2 = "id2",
  /** column name */
  Id3 = "id3",
  /** column name */
  Land4 = "land4",
  /** column name */
  Landline = "landline",
  /** column name */
  Landline1 = "landline1",
  /** column name */
  Landline1code = "landline1code",
  /** column name */
  Landline4 = "landline4",
  /** column name */
  Landlinecode = "landlinecode",
  /** column name */
  Landlinecode4 = "landlinecode4",
  /** column name */
  Landregister = "landregister",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LatitudeA = "latitude_a",
  /** column name */
  Latlong = "latlong",
  /** column name */
  Legalname = "legalname",
  /** column name */
  Levelofcompletion = "levelofcompletion",
  /** column name */
  Line = "line",
  /** column name */
  Lln = "lln",
  /** column name */
  Lln1 = "lln1",
  /** column name */
  LongitudeB = "longitude_b",
  /** column name */
  Lvls = "lvls",
  /** column name */
  Mainbusinessname = "mainbusinessname",
  /** column name */
  Mob = "mob",
  /** column name */
  Mob1 = "mob1",
  /** column name */
  Mob3 = "mob3",
  /** column name */
  Mob4 = "mob4",
  /** column name */
  Mobile = "mobile",
  /** column name */
  Mobile1 = "mobile1",
  /** column name */
  Mobile1code = "mobile1code",
  /** column name */
  Mobile3 = "mobile3",
  /** column name */
  Mobile3code = "mobile3code",
  /** column name */
  Mobile4 = "mobile4",
  /** column name */
  Mobile4code = "mobile4code",
  /** column name */
  Mobile5 = "mobile5",
  /** column name */
  Mobile5code = "mobile5code",
  /** column name */
  Mobilecode = "mobilecode",
  /** column name */
  Name2 = "name2",
  /** column name */
  Nationalid = "nationalid",
  /** column name */
  Nationalid2 = "nationalid2",
  /** column name */
  Nbrofslots = "nbrofslots",
  /** column name */
  Neighbourhoodactivities = "neighbourhoodactivities",
  /** column name */
  Neighbourhoodstatus = "neighbourhoodstatus",
  /** column name */
  Nin = "nin",
  /** column name */
  Nin2 = "nin2",
  /** column name */
  Objectid = "objectid",
  /** column name */
  Other = "other",
  /** column name */
  Otherfacilities = "otherfacilities",
  /** column name */
  Otherid = "otherid",
  /** column name */
  Otherid1 = "otherid1",
  /** column name */
  Otherid2 = "otherid2",
  /** column name */
  Otherid3 = "otherid3",
  /** column name */
  Otherid4 = "otherid4",
  /** column name */
  Otherid4nbr = "otherid4nbr",
  /** column name */
  Otherid6 = "otherid6",
  /** column name */
  Otherid7 = "otherid7",
  /** column name */
  Othernames = "othernames",
  /** column name */
  Othernames3 = "othernames3",
  /** column name */
  Otherservices = "otherservices",
  /** column name */
  Parish2 = "parish2",
  /** column name */
  Parking = "parking",
  /** column name */
  Parkingsufficient = "parkingsufficient",
  /** column name */
  Pass = "pass",
  /** column name */
  Plotnumber = "plotnumber",
  /** column name */
  Postalofficeboxnumber = "postalofficeboxnumber",
  /** column name */
  Postalofficeboxnumber1 = "postalofficeboxnumber1",
  /** column name */
  Powersupply = "powersupply",
  /** column name */
  Propertyname = "propertyname",
  /** column name */
  Reference = "reference",
  /** column name */
  Relationshiptoowner = "relationshiptoowner",
  /** column name */
  Roadfrontageorgrid = "roadfrontageorgrid",
  /** column name */
  Roadname = "roadname",
  /** column name */
  Roofcovering = "roofcovering",
  /** column name */
  Roofcoveringothers = "roofcoveringothers",
  /** column name */
  Sanitation = "sanitation",
  /** column name */
  Security = "security",
  /** column name */
  Shapeofplot = "shapeofplot",
  /** column name */
  StatusId = "status_id",
  /** column name */
  Surname = "surname",
  /** column name */
  Surname3 = "surname3",
  /** column name */
  Tin = "tin",
  /** column name */
  Title = "title",
  /** column name */
  Title1 = "title1",
  /** column name */
  Topography = "topography",
  /** column name */
  TotalBuiltUpArea = "total_built_up_area",
  /** column name */
  TotalGrossArea = "total_gross_area",
  /** column name */
  Typeofaccess = "typeofaccess",
  /** column name */
  Typeofbuilding = "typeofbuilding",
  /** column name */
  Typeofcompany = "typeofcompany",
  /** column name */
  Typeoflandinterest = "typeoflandinterest",
  /** column name */
  Username = "username",
  /** column name */
  Verticalcirculation = "verticalcirculation",
  /** column name */
  Village2 = "village2",
  /** column name */
  Volume = "volume",
  /** column name */
  Wallfinish = "wallfinish",
  /** column name */
  Wallfinishothers = "wallfinishothers",
  /** column name */
  Walls = "walls",
  /** column name */
  Wallsothers = "wallsothers",
  /** column name */
  Wateraccessibility = "wateraccessibility",
  /** column name */
  WhoUpdated = "who_updated",
  /** column name */
  Windows = "windows",
  /** column name */
  Windowsothers = "windowsothers",
}

/** input type for updating data in table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Set_Input = {
  AUTHORIZEDCONTACTPERSON?: Maybe<Scalars["String"]>;
  BoundaryFenceother?: Maybe<Scalars["String"]>;
  CONTACTDETAILSOFDIRECTOR?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamp"]>;
  Creator?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  EditDate?: Maybe<Scalars["timestamp"]>;
  Editor?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["String"]>;
  PARTIIINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  PARTINONINDIVIDUALOWNERSHIPDETAILS?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  SECTIONAPROPERTYOWNERSDETAILS?: Maybe<Scalars["String"]>;
  SHAPE?: Maybe<Scalars["String"]>;
  SubCounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  access?: Maybe<Scalars["String"]>;
  agent?: Maybe<Scalars["String"]>;
  alternative?: Maybe<Scalars["String"]>;
  alternativeemailaddress?: Maybe<Scalars["String"]>;
  alternativefirstname?: Maybe<Scalars["String"]>;
  alternativelandlinenumber?: Maybe<Scalars["String"]>;
  alternativelandlinenumbercode?: Maybe<Scalars["String"]>;
  alternativelandlinenumberwithcountrycode?: Maybe<Scalars["String"]>;
  alternativeothername?: Maybe<Scalars["String"]>;
  alternativephonenumber1?: Maybe<Scalars["String"]>;
  alternativephonenumber1code?: Maybe<Scalars["String"]>;
  alternativephonenumber2?: Maybe<Scalars["String"]>;
  alternativephonenumber2code?: Maybe<Scalars["String"]>;
  alternativesurname?: Maybe<Scalars["String"]>;
  altphn1?: Maybe<Scalars["String"]>;
  altphn2?: Maybe<Scalars["String"]>;
  anyother?: Maybe<Scalars["String"]>;
  area_count?: Maybe<Scalars["String"]>;
  bldg2?: Maybe<Scalars["String"]>;
  blockname?: Maybe<Scalars["String"]>;
  blocknumber?: Maybe<Scalars["String"]>;
  blockother?: Maybe<Scalars["String"]>;
  boundary?: Maybe<Scalars["String"]>;
  buildingcondition?: Maybe<Scalars["String"]>;
  buildingname?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["bigint"]>;
  camv_serial?: Maybe<Scalars["String"]>;
  ceiling?: Maybe<Scalars["String"]>;
  ceilingothers?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  coin1?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  compound?: Maybe<Scalars["String"]>;
  compoundother?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  ctrycde?: Maybe<Scalars["String"]>;
  ctrycde1?: Maybe<Scalars["String"]>;
  ctrycde2?: Maybe<Scalars["String"]>;
  ctrycde4?: Maybe<Scalars["String"]>;
  datacollector?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  doors?: Maybe<Scalars["String"]>;
  doorsothers?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email1?: Maybe<Scalars["String"]>;
  email3?: Maybe<Scalars["String"]>;
  email5?: Maybe<Scalars["String"]>;
  entitytype?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  facilities?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  firstname3?: Maybe<Scalars["String"]>;
  flatblocknumber?: Maybe<Scalars["String"]>;
  flatnumber?: Maybe<Scalars["String"]>;
  floorconstruction?: Maybe<Scalars["String"]>;
  floorconstructionothers?: Maybe<Scalars["String"]>;
  floorfinish?: Maybe<Scalars["String"]>;
  floorfinishothers?: Maybe<Scalars["String"]>;
  folio?: Maybe<Scalars["String"]>;
  games?: Maybe<Scalars["String"]>;
  gamesother?: Maybe<Scalars["String"]>;
  gate?: Maybe<Scalars["String"]>;
  gateother?: Maybe<Scalars["String"]>;
  globalid?: Maybe<Scalars["String"]>;
  housenumber?: Maybe<Scalars["String"]>;
  hsenbr?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  id2?: Maybe<Scalars["String"]>;
  id3?: Maybe<Scalars["bigint"]>;
  land4?: Maybe<Scalars["String"]>;
  landline?: Maybe<Scalars["String"]>;
  landline1?: Maybe<Scalars["String"]>;
  landline1code?: Maybe<Scalars["String"]>;
  landline4?: Maybe<Scalars["String"]>;
  landlinecode?: Maybe<Scalars["String"]>;
  landlinecode4?: Maybe<Scalars["String"]>;
  landregister?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude_a?: Maybe<Scalars["float8"]>;
  latlong?: Maybe<Scalars["String"]>;
  legalname?: Maybe<Scalars["String"]>;
  levelofcompletion?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  lln?: Maybe<Scalars["String"]>;
  lln1?: Maybe<Scalars["String"]>;
  longitude_b?: Maybe<Scalars["float8"]>;
  lvls?: Maybe<Scalars["String"]>;
  mainbusinessname?: Maybe<Scalars["String"]>;
  mob?: Maybe<Scalars["String"]>;
  mob1?: Maybe<Scalars["String"]>;
  mob3?: Maybe<Scalars["String"]>;
  mob4?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  mobile1?: Maybe<Scalars["String"]>;
  mobile1code?: Maybe<Scalars["String"]>;
  mobile3?: Maybe<Scalars["String"]>;
  mobile3code?: Maybe<Scalars["String"]>;
  mobile4?: Maybe<Scalars["String"]>;
  mobile4code?: Maybe<Scalars["String"]>;
  mobile5?: Maybe<Scalars["String"]>;
  mobile5code?: Maybe<Scalars["String"]>;
  mobilecode?: Maybe<Scalars["String"]>;
  name2?: Maybe<Scalars["String"]>;
  nationalid?: Maybe<Scalars["String"]>;
  nationalid2?: Maybe<Scalars["String"]>;
  nbrofslots?: Maybe<Scalars["String"]>;
  neighbourhoodactivities?: Maybe<Scalars["String"]>;
  neighbourhoodstatus?: Maybe<Scalars["String"]>;
  nin?: Maybe<Scalars["String"]>;
  nin2?: Maybe<Scalars["String"]>;
  objectid?: Maybe<Scalars["float8"]>;
  other?: Maybe<Scalars["String"]>;
  otherfacilities?: Maybe<Scalars["String"]>;
  otherid?: Maybe<Scalars["String"]>;
  otherid1?: Maybe<Scalars["String"]>;
  otherid2?: Maybe<Scalars["String"]>;
  otherid3?: Maybe<Scalars["String"]>;
  otherid4?: Maybe<Scalars["String"]>;
  otherid4nbr?: Maybe<Scalars["String"]>;
  otherid6?: Maybe<Scalars["String"]>;
  otherid7?: Maybe<Scalars["String"]>;
  othernames?: Maybe<Scalars["String"]>;
  othernames3?: Maybe<Scalars["String"]>;
  otherservices?: Maybe<Scalars["String"]>;
  parish2?: Maybe<Scalars["String"]>;
  parking?: Maybe<Scalars["String"]>;
  parkingsufficient?: Maybe<Scalars["String"]>;
  pass?: Maybe<Scalars["float8"]>;
  plotnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber?: Maybe<Scalars["String"]>;
  postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  powersupply?: Maybe<Scalars["String"]>;
  propertyname?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["bigint"]>;
  relationshiptoowner?: Maybe<Scalars["String"]>;
  roadfrontageorgrid?: Maybe<Scalars["String"]>;
  roadname?: Maybe<Scalars["String"]>;
  roofcovering?: Maybe<Scalars["String"]>;
  roofcoveringothers?: Maybe<Scalars["String"]>;
  sanitation?: Maybe<Scalars["String"]>;
  security?: Maybe<Scalars["String"]>;
  shapeofplot?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  surname?: Maybe<Scalars["String"]>;
  surname3?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  title1?: Maybe<Scalars["String"]>;
  topography?: Maybe<Scalars["String"]>;
  total_built_up_area?: Maybe<Scalars["float8"]>;
  total_gross_area?: Maybe<Scalars["float8"]>;
  typeofaccess?: Maybe<Scalars["String"]>;
  typeofbuilding?: Maybe<Scalars["String"]>;
  typeofcompany?: Maybe<Scalars["String"]>;
  typeoflandinterest?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["float8"]>;
  verticalcirculation?: Maybe<Scalars["String"]>;
  village2?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["String"]>;
  wallfinish?: Maybe<Scalars["String"]>;
  wallfinishothers?: Maybe<Scalars["String"]>;
  walls?: Maybe<Scalars["String"]>;
  wallsothers?: Maybe<Scalars["String"]>;
  wateraccessibility?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  windows?: Maybe<Scalars["String"]>;
  windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_RawProperties2_Stddev_Fields = {
  __typename?: "kcca_RawProperties2_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  camv_id?: Maybe<Scalars["Float"]>;
  id3?: Maybe<Scalars["Float"]>;
  latitude_a?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude_b?: Maybe<Scalars["Float"]>;
  objectid?: Maybe<Scalars["Float"]>;
  pass?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  total_built_up_area?: Maybe<Scalars["Float"]>;
  total_gross_area?: Maybe<Scalars["Float"]>;
  username?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_RawProperties2_Stddev_Pop_Fields = {
  __typename?: "kcca_RawProperties2_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  camv_id?: Maybe<Scalars["Float"]>;
  id3?: Maybe<Scalars["Float"]>;
  latitude_a?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude_b?: Maybe<Scalars["Float"]>;
  objectid?: Maybe<Scalars["Float"]>;
  pass?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  total_built_up_area?: Maybe<Scalars["Float"]>;
  total_gross_area?: Maybe<Scalars["Float"]>;
  username?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_RawProperties2_Stddev_Samp_Fields = {
  __typename?: "kcca_RawProperties2_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  camv_id?: Maybe<Scalars["Float"]>;
  id3?: Maybe<Scalars["Float"]>;
  latitude_a?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude_b?: Maybe<Scalars["Float"]>;
  objectid?: Maybe<Scalars["Float"]>;
  pass?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  total_built_up_area?: Maybe<Scalars["Float"]>;
  total_gross_area?: Maybe<Scalars["Float"]>;
  username?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_RawProperties2_Sum_Fields = {
  __typename?: "kcca_RawProperties2_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  camv_id?: Maybe<Scalars["bigint"]>;
  id3?: Maybe<Scalars["bigint"]>;
  latitude_a?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude_b?: Maybe<Scalars["float8"]>;
  objectid?: Maybe<Scalars["float8"]>;
  pass?: Maybe<Scalars["float8"]>;
  reference?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  total_built_up_area?: Maybe<Scalars["float8"]>;
  total_gross_area?: Maybe<Scalars["float8"]>;
  username?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_RawProperties2_Var_Pop_Fields = {
  __typename?: "kcca_RawProperties2_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  camv_id?: Maybe<Scalars["Float"]>;
  id3?: Maybe<Scalars["Float"]>;
  latitude_a?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude_b?: Maybe<Scalars["Float"]>;
  objectid?: Maybe<Scalars["Float"]>;
  pass?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  total_built_up_area?: Maybe<Scalars["Float"]>;
  total_gross_area?: Maybe<Scalars["Float"]>;
  username?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_RawProperties2_Var_Samp_Fields = {
  __typename?: "kcca_RawProperties2_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  camv_id?: Maybe<Scalars["Float"]>;
  id3?: Maybe<Scalars["Float"]>;
  latitude_a?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude_b?: Maybe<Scalars["Float"]>;
  objectid?: Maybe<Scalars["Float"]>;
  pass?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  total_built_up_area?: Maybe<Scalars["Float"]>;
  total_gross_area?: Maybe<Scalars["Float"]>;
  username?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_RawProperties2_Variance_Fields = {
  __typename?: "kcca_RawProperties2_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  camv_id?: Maybe<Scalars["Float"]>;
  id3?: Maybe<Scalars["Float"]>;
  latitude_a?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude_b?: Maybe<Scalars["Float"]>;
  objectid?: Maybe<Scalars["Float"]>;
  pass?: Maybe<Scalars["Float"]>;
  reference?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  total_built_up_area?: Maybe<Scalars["Float"]>;
  total_gross_area?: Maybe<Scalars["Float"]>;
  username?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.RawProperties2" */
export type Kcca_RawProperties2_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  id3?: Maybe<Order_By>;
  latitude_a?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude_b?: Maybe<Order_By>;
  objectid?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  total_built_up_area?: Maybe<Order_By>;
  total_gross_area?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.RevenueType" */
export type Kcca_RevenueType = {
  __typename?: "kcca_RevenueType";
  code?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  sub_revenue?: Maybe<Scalars["String"]>;
  system_code?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.RevenueType" */
export type Kcca_RevenueType_Aggregate = {
  __typename?: "kcca_RevenueType_aggregate";
  aggregate?: Maybe<Kcca_RevenueType_Aggregate_Fields>;
  nodes: Array<Kcca_RevenueType>;
};

/** aggregate fields of "kcca.RevenueType" */
export type Kcca_RevenueType_Aggregate_Fields = {
  __typename?: "kcca_RevenueType_aggregate_fields";
  avg?: Maybe<Kcca_RevenueType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_RevenueType_Max_Fields>;
  min?: Maybe<Kcca_RevenueType_Min_Fields>;
  stddev?: Maybe<Kcca_RevenueType_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_RevenueType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_RevenueType_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_RevenueType_Sum_Fields>;
  var_pop?: Maybe<Kcca_RevenueType_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_RevenueType_Var_Samp_Fields>;
  variance?: Maybe<Kcca_RevenueType_Variance_Fields>;
};

/** aggregate fields of "kcca.RevenueType" */
export type Kcca_RevenueType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_RevenueType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.RevenueType" */
export type Kcca_RevenueType_Aggregate_Order_By = {
  avg?: Maybe<Kcca_RevenueType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_RevenueType_Max_Order_By>;
  min?: Maybe<Kcca_RevenueType_Min_Order_By>;
  stddev?: Maybe<Kcca_RevenueType_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_RevenueType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_RevenueType_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_RevenueType_Sum_Order_By>;
  var_pop?: Maybe<Kcca_RevenueType_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_RevenueType_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_RevenueType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.RevenueType" */
export type Kcca_RevenueType_Arr_Rel_Insert_Input = {
  data: Array<Kcca_RevenueType_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_RevenueType_Avg_Fields = {
  __typename?: "kcca_RevenueType_avg_fields";
  code?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Avg_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.RevenueType". All fields are combined with a logical 'AND'. */
export type Kcca_RevenueType_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_RevenueType_Bool_Exp>>>;
  _not?: Maybe<Kcca_RevenueType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_RevenueType_Bool_Exp>>>;
  code?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Float8_Comparison_Exp>;
  sub_revenue?: Maybe<String_Comparison_Exp>;
  system_code?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.RevenueType" */
export type Kcca_RevenueType_Inc_Input = {
  code?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "kcca.RevenueType" */
export type Kcca_RevenueType_Insert_Input = {
  code?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  sub_revenue?: Maybe<Scalars["String"]>;
  system_code?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_RevenueType_Max_Fields = {
  __typename?: "kcca_RevenueType_max_fields";
  code?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  sub_revenue?: Maybe<Scalars["String"]>;
  system_code?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Max_Order_By = {
  code?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_revenue?: Maybe<Order_By>;
  system_code?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_RevenueType_Min_Fields = {
  __typename?: "kcca_RevenueType_min_fields";
  code?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  sub_revenue?: Maybe<Scalars["String"]>;
  system_code?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Min_Order_By = {
  code?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_revenue?: Maybe<Order_By>;
  system_code?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.RevenueType" */
export type Kcca_RevenueType_Mutation_Response = {
  __typename?: "kcca_RevenueType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_RevenueType>;
};

/** input type for inserting object relation for remote table "kcca.RevenueType" */
export type Kcca_RevenueType_Obj_Rel_Insert_Input = {
  data: Kcca_RevenueType_Insert_Input;
};

/** ordering options when selecting data from "kcca.RevenueType" */
export type Kcca_RevenueType_Order_By = {
  code?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_revenue?: Maybe<Order_By>;
  system_code?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.RevenueType" */
export enum Kcca_RevenueType_Select_Column {
  /** column name */
  Code = "code",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  SubRevenue = "sub_revenue",
  /** column name */
  SystemCode = "system_code",
  /** column name */
  Type = "type",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.RevenueType" */
export type Kcca_RevenueType_Set_Input = {
  code?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["float8"]>;
  sub_revenue?: Maybe<Scalars["String"]>;
  system_code?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_RevenueType_Stddev_Fields = {
  __typename?: "kcca_RevenueType_stddev_fields";
  code?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Stddev_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_RevenueType_Stddev_Pop_Fields = {
  __typename?: "kcca_RevenueType_stddev_pop_fields";
  code?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Stddev_Pop_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_RevenueType_Stddev_Samp_Fields = {
  __typename?: "kcca_RevenueType_stddev_samp_fields";
  code?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Stddev_Samp_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_RevenueType_Sum_Fields = {
  __typename?: "kcca_RevenueType_sum_fields";
  code?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Sum_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_RevenueType_Var_Pop_Fields = {
  __typename?: "kcca_RevenueType_var_pop_fields";
  code?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Var_Pop_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_RevenueType_Var_Samp_Fields = {
  __typename?: "kcca_RevenueType_var_samp_fields";
  code?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Var_Samp_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_RevenueType_Variance_Fields = {
  __typename?: "kcca_RevenueType_variance_fields";
  code?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.RevenueType" */
export type Kcca_RevenueType_Variance_Order_By = {
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.SubCounty" */
export type Kcca_SubCounty = {
  __typename?: "kcca_SubCounty";
  country_id?: Maybe<Scalars["bigint"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.SubCounty" */
export type Kcca_SubCounty_Aggregate = {
  __typename?: "kcca_SubCounty_aggregate";
  aggregate?: Maybe<Kcca_SubCounty_Aggregate_Fields>;
  nodes: Array<Kcca_SubCounty>;
};

/** aggregate fields of "kcca.SubCounty" */
export type Kcca_SubCounty_Aggregate_Fields = {
  __typename?: "kcca_SubCounty_aggregate_fields";
  avg?: Maybe<Kcca_SubCounty_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_SubCounty_Max_Fields>;
  min?: Maybe<Kcca_SubCounty_Min_Fields>;
  stddev?: Maybe<Kcca_SubCounty_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_SubCounty_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_SubCounty_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_SubCounty_Sum_Fields>;
  var_pop?: Maybe<Kcca_SubCounty_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_SubCounty_Var_Samp_Fields>;
  variance?: Maybe<Kcca_SubCounty_Variance_Fields>;
};

/** aggregate fields of "kcca.SubCounty" */
export type Kcca_SubCounty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_SubCounty_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.SubCounty" */
export type Kcca_SubCounty_Aggregate_Order_By = {
  avg?: Maybe<Kcca_SubCounty_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_SubCounty_Max_Order_By>;
  min?: Maybe<Kcca_SubCounty_Min_Order_By>;
  stddev?: Maybe<Kcca_SubCounty_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_SubCounty_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_SubCounty_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_SubCounty_Sum_Order_By>;
  var_pop?: Maybe<Kcca_SubCounty_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_SubCounty_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_SubCounty_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.SubCounty" */
export type Kcca_SubCounty_Arr_Rel_Insert_Input = {
  data: Array<Kcca_SubCounty_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_SubCounty_Avg_Fields = {
  __typename?: "kcca_SubCounty_avg_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Avg_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.SubCounty". All fields are combined with a logical 'AND'. */
export type Kcca_SubCounty_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_SubCounty_Bool_Exp>>>;
  _not?: Maybe<Kcca_SubCounty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_SubCounty_Bool_Exp>>>;
  country_id?: Maybe<Bigint_Comparison_Exp>;
  county_id?: Maybe<Bigint_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  district_id?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.SubCounty" */
export type Kcca_SubCounty_Inc_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.SubCounty" */
export type Kcca_SubCounty_Insert_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_SubCounty_Max_Fields = {
  __typename?: "kcca_SubCounty_max_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Max_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_SubCounty_Min_Fields = {
  __typename?: "kcca_SubCounty_min_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Min_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.SubCounty" */
export type Kcca_SubCounty_Mutation_Response = {
  __typename?: "kcca_SubCounty_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_SubCounty>;
};

/** input type for inserting object relation for remote table "kcca.SubCounty" */
export type Kcca_SubCounty_Obj_Rel_Insert_Input = {
  data: Kcca_SubCounty_Insert_Input;
};

/** ordering options when selecting data from "kcca.SubCounty" */
export type Kcca_SubCounty_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.SubCounty" */
export enum Kcca_SubCounty_Select_Column {
  /** column name */
  CountryId = "country_id",
  /** column name */
  CountyId = "county_id",
  /** column name */
  Description = "description",
  /** column name */
  DistrictId = "district_id",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  Name = "name",
  /** column name */
  StatusId = "status_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.SubCounty" */
export type Kcca_SubCounty_Set_Input = {
  country_id?: Maybe<Scalars["bigint"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_SubCounty_Stddev_Fields = {
  __typename?: "kcca_SubCounty_stddev_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Stddev_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_SubCounty_Stddev_Pop_Fields = {
  __typename?: "kcca_SubCounty_stddev_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Stddev_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_SubCounty_Stddev_Samp_Fields = {
  __typename?: "kcca_SubCounty_stddev_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Stddev_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_SubCounty_Sum_Fields = {
  __typename?: "kcca_SubCounty_sum_fields";
  country_id?: Maybe<Scalars["bigint"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  status_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Sum_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_SubCounty_Var_Pop_Fields = {
  __typename?: "kcca_SubCounty_var_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Var_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_SubCounty_Var_Samp_Fields = {
  __typename?: "kcca_SubCounty_var_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Var_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_SubCounty_Variance_Fields = {
  __typename?: "kcca_SubCounty_variance_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.SubCounty" */
export type Kcca_SubCounty_Variance_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.TradingLicense" */
export type Kcca_TradingLicense = {
  __typename?: "kcca_TradingLicense";
  Category?: Maybe<Scalars["String"]>;
  DOB?: Maybe<Scalars["date"]>;
  Nature_of_Business?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.TradingLicense" */
export type Kcca_TradingLicense_Aggregate = {
  __typename?: "kcca_TradingLicense_aggregate";
  aggregate?: Maybe<Kcca_TradingLicense_Aggregate_Fields>;
  nodes: Array<Kcca_TradingLicense>;
};

/** aggregate fields of "kcca.TradingLicense" */
export type Kcca_TradingLicense_Aggregate_Fields = {
  __typename?: "kcca_TradingLicense_aggregate_fields";
  avg?: Maybe<Kcca_TradingLicense_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_TradingLicense_Max_Fields>;
  min?: Maybe<Kcca_TradingLicense_Min_Fields>;
  stddev?: Maybe<Kcca_TradingLicense_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_TradingLicense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_TradingLicense_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_TradingLicense_Sum_Fields>;
  var_pop?: Maybe<Kcca_TradingLicense_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_TradingLicense_Var_Samp_Fields>;
  variance?: Maybe<Kcca_TradingLicense_Variance_Fields>;
};

/** aggregate fields of "kcca.TradingLicense" */
export type Kcca_TradingLicense_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_TradingLicense_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Aggregate_Order_By = {
  avg?: Maybe<Kcca_TradingLicense_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_TradingLicense_Max_Order_By>;
  min?: Maybe<Kcca_TradingLicense_Min_Order_By>;
  stddev?: Maybe<Kcca_TradingLicense_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_TradingLicense_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_TradingLicense_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_TradingLicense_Sum_Order_By>;
  var_pop?: Maybe<Kcca_TradingLicense_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_TradingLicense_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_TradingLicense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Arr_Rel_Insert_Input = {
  data: Array<Kcca_TradingLicense_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_TradingLicense_Avg_Fields = {
  __typename?: "kcca_TradingLicense_avg_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Avg_Order_By = {
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.TradingLicense". All fields are combined with a logical 'AND'. */
export type Kcca_TradingLicense_Bool_Exp = {
  Category?: Maybe<String_Comparison_Exp>;
  DOB?: Maybe<Date_Comparison_Exp>;
  Nature_of_Business?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Kcca_TradingLicense_Bool_Exp>>>;
  _not?: Maybe<Kcca_TradingLicense_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_TradingLicense_Bool_Exp>>>;
  business_name?: Maybe<String_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  division?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  entity_legal_name?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Inc_Input = {
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Insert_Input = {
  Category?: Maybe<Scalars["String"]>;
  DOB?: Maybe<Scalars["date"]>;
  Nature_of_Business?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_TradingLicense_Max_Fields = {
  __typename?: "kcca_TradingLicense_max_fields";
  Category?: Maybe<Scalars["String"]>;
  DOB?: Maybe<Scalars["date"]>;
  Nature_of_Business?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Max_Order_By = {
  Category?: Maybe<Order_By>;
  DOB?: Maybe<Order_By>;
  Nature_of_Business?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  division?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_TradingLicense_Min_Fields = {
  __typename?: "kcca_TradingLicense_min_fields";
  Category?: Maybe<Scalars["String"]>;
  DOB?: Maybe<Scalars["date"]>;
  Nature_of_Business?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Min_Order_By = {
  Category?: Maybe<Order_By>;
  DOB?: Maybe<Order_By>;
  Nature_of_Business?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  division?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Mutation_Response = {
  __typename?: "kcca_TradingLicense_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_TradingLicense>;
};

/** input type for inserting object relation for remote table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Obj_Rel_Insert_Input = {
  data: Kcca_TradingLicense_Insert_Input;
};

/** ordering options when selecting data from "kcca.TradingLicense" */
export type Kcca_TradingLicense_Order_By = {
  Category?: Maybe<Order_By>;
  DOB?: Maybe<Order_By>;
  Nature_of_Business?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  division?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.TradingLicense" */
export enum Kcca_TradingLicense_Select_Column {
  /** column name */
  Category = "Category",
  /** column name */
  Dob = "DOB",
  /** column name */
  NatureOfBusiness = "Nature_of_Business",
  /** column name */
  BusinessName = "business_name",
  /** column name */
  Code = "code",
  /** column name */
  Division = "division",
  /** column name */
  Email = "email",
  /** column name */
  EntityLegalName = "entity_legal_name",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Mobile = "mobile",
  /** column name */
  Surname = "surname",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Set_Input = {
  Category?: Maybe<Scalars["String"]>;
  DOB?: Maybe<Scalars["date"]>;
  Nature_of_Business?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  mobile?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_TradingLicense_Stddev_Fields = {
  __typename?: "kcca_TradingLicense_stddev_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Stddev_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_TradingLicense_Stddev_Pop_Fields = {
  __typename?: "kcca_TradingLicense_stddev_pop_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Stddev_Pop_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_TradingLicense_Stddev_Samp_Fields = {
  __typename?: "kcca_TradingLicense_stddev_samp_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Stddev_Samp_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_TradingLicense_Sum_Fields = {
  __typename?: "kcca_TradingLicense_sum_fields";
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Sum_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_TradingLicense_Var_Pop_Fields = {
  __typename?: "kcca_TradingLicense_var_pop_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Var_Pop_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_TradingLicense_Var_Samp_Fields = {
  __typename?: "kcca_TradingLicense_var_samp_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Var_Samp_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_TradingLicense_Variance_Fields = {
  __typename?: "kcca_TradingLicense_variance_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.TradingLicense" */
export type Kcca_TradingLicense_Variance_Order_By = {
  line?: Maybe<Order_By>;
};

/** columns and relationships of "kcca.Village" */
export type Kcca_Village = {
  __typename?: "kcca_Village";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "kcca.Village" */
export type Kcca_Village_Aggregate = {
  __typename?: "kcca_Village_aggregate";
  aggregate?: Maybe<Kcca_Village_Aggregate_Fields>;
  nodes: Array<Kcca_Village>;
};

/** aggregate fields of "kcca.Village" */
export type Kcca_Village_Aggregate_Fields = {
  __typename?: "kcca_Village_aggregate_fields";
  avg?: Maybe<Kcca_Village_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Kcca_Village_Max_Fields>;
  min?: Maybe<Kcca_Village_Min_Fields>;
  stddev?: Maybe<Kcca_Village_Stddev_Fields>;
  stddev_pop?: Maybe<Kcca_Village_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kcca_Village_Stddev_Samp_Fields>;
  sum?: Maybe<Kcca_Village_Sum_Fields>;
  var_pop?: Maybe<Kcca_Village_Var_Pop_Fields>;
  var_samp?: Maybe<Kcca_Village_Var_Samp_Fields>;
  variance?: Maybe<Kcca_Village_Variance_Fields>;
};

/** aggregate fields of "kcca.Village" */
export type Kcca_Village_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kcca_Village_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "kcca.Village" */
export type Kcca_Village_Aggregate_Order_By = {
  avg?: Maybe<Kcca_Village_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Kcca_Village_Max_Order_By>;
  min?: Maybe<Kcca_Village_Min_Order_By>;
  stddev?: Maybe<Kcca_Village_Stddev_Order_By>;
  stddev_pop?: Maybe<Kcca_Village_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Kcca_Village_Stddev_Samp_Order_By>;
  sum?: Maybe<Kcca_Village_Sum_Order_By>;
  var_pop?: Maybe<Kcca_Village_Var_Pop_Order_By>;
  var_samp?: Maybe<Kcca_Village_Var_Samp_Order_By>;
  variance?: Maybe<Kcca_Village_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kcca.Village" */
export type Kcca_Village_Arr_Rel_Insert_Input = {
  data: Array<Kcca_Village_Insert_Input>;
};

/** aggregate avg on columns */
export type Kcca_Village_Avg_Fields = {
  __typename?: "kcca_Village_avg_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "kcca.Village" */
export type Kcca_Village_Avg_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kcca.Village". All fields are combined with a logical 'AND'. */
export type Kcca_Village_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Kcca_Village_Bool_Exp>>>;
  _not?: Maybe<Kcca_Village_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Kcca_Village_Bool_Exp>>>;
  country_id?: Maybe<Float8_Comparison_Exp>;
  county_id?: Maybe<Bigint_Comparison_Exp>;
  created_by?: Maybe<Float8_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  district_id?: Maybe<Bigint_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Float8_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parish_id?: Maybe<Bigint_Comparison_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  sub_county_id?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "kcca.Village" */
export type Kcca_Village_Inc_Input = {
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  parish_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "kcca.Village" */
export type Kcca_Village_Insert_Input = {
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Kcca_Village_Max_Fields = {
  __typename?: "kcca_Village_max_fields";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "kcca.Village" */
export type Kcca_Village_Max_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Kcca_Village_Min_Fields = {
  __typename?: "kcca_Village_min_fields";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "kcca.Village" */
export type Kcca_Village_Min_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "kcca.Village" */
export type Kcca_Village_Mutation_Response = {
  __typename?: "kcca_Village_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Kcca_Village>;
};

/** input type for inserting object relation for remote table "kcca.Village" */
export type Kcca_Village_Obj_Rel_Insert_Input = {
  data: Kcca_Village_Insert_Input;
};

/** ordering options when selecting data from "kcca.Village" */
export type Kcca_Village_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "kcca.Village" */
export enum Kcca_Village_Select_Column {
  /** column name */
  CountryId = "country_id",
  /** column name */
  CountyId = "county_id",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  Description = "description",
  /** column name */
  DistrictId = "district_id",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  Name = "name",
  /** column name */
  ParishId = "parish_id",
  /** column name */
  StatusId = "status_id",
  /** column name */
  SubCountyId = "sub_county_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "kcca.Village" */
export type Kcca_Village_Set_Input = {
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  name?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Kcca_Village_Stddev_Fields = {
  __typename?: "kcca_Village_stddev_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "kcca.Village" */
export type Kcca_Village_Stddev_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kcca_Village_Stddev_Pop_Fields = {
  __typename?: "kcca_Village_stddev_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "kcca.Village" */
export type Kcca_Village_Stddev_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kcca_Village_Stddev_Samp_Fields = {
  __typename?: "kcca_Village_stddev_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "kcca.Village" */
export type Kcca_Village_Stddev_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Kcca_Village_Sum_Fields = {
  __typename?: "kcca_Village_sum_fields";
  country_id?: Maybe<Scalars["float8"]>;
  county_id?: Maybe<Scalars["bigint"]>;
  created_by?: Maybe<Scalars["float8"]>;
  district_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  modified_by?: Maybe<Scalars["float8"]>;
  parish_id?: Maybe<Scalars["bigint"]>;
  status_id?: Maybe<Scalars["bigint"]>;
  sub_county_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "kcca.Village" */
export type Kcca_Village_Sum_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Kcca_Village_Var_Pop_Fields = {
  __typename?: "kcca_Village_var_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "kcca.Village" */
export type Kcca_Village_Var_Pop_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kcca_Village_Var_Samp_Fields = {
  __typename?: "kcca_Village_var_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "kcca.Village" */
export type Kcca_Village_Var_Samp_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Kcca_Village_Variance_Fields = {
  __typename?: "kcca_Village_variance_fields";
  country_id?: Maybe<Scalars["Float"]>;
  county_id?: Maybe<Scalars["Float"]>;
  created_by?: Maybe<Scalars["Float"]>;
  district_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  modified_by?: Maybe<Scalars["Float"]>;
  parish_id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  sub_county_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "kcca.Village" */
export type Kcca_Village_Variance_Order_By = {
  country_id?: Maybe<Order_By>;
  county_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  sub_county_id?: Maybe<Order_By>;
};

/** columns and relationships of "mlhud.KCCA" */
export type Mlhud_Kcca = {
  __typename?: "mlhud_KCCA";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Title_Number?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "mlhud.KCCA" */
export type Mlhud_Kcca_Aggregate = {
  __typename?: "mlhud_KCCA_aggregate";
  aggregate?: Maybe<Mlhud_Kcca_Aggregate_Fields>;
  nodes: Array<Mlhud_Kcca>;
};

/** aggregate fields of "mlhud.KCCA" */
export type Mlhud_Kcca_Aggregate_Fields = {
  __typename?: "mlhud_KCCA_aggregate_fields";
  avg?: Maybe<Mlhud_Kcca_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Mlhud_Kcca_Max_Fields>;
  min?: Maybe<Mlhud_Kcca_Min_Fields>;
  stddev?: Maybe<Mlhud_Kcca_Stddev_Fields>;
  stddev_pop?: Maybe<Mlhud_Kcca_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mlhud_Kcca_Stddev_Samp_Fields>;
  sum?: Maybe<Mlhud_Kcca_Sum_Fields>;
  var_pop?: Maybe<Mlhud_Kcca_Var_Pop_Fields>;
  var_samp?: Maybe<Mlhud_Kcca_Var_Samp_Fields>;
  variance?: Maybe<Mlhud_Kcca_Variance_Fields>;
};

/** aggregate fields of "mlhud.KCCA" */
export type Mlhud_Kcca_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mlhud_Kcca_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "mlhud.KCCA" */
export type Mlhud_Kcca_Aggregate_Order_By = {
  avg?: Maybe<Mlhud_Kcca_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mlhud_Kcca_Max_Order_By>;
  min?: Maybe<Mlhud_Kcca_Min_Order_By>;
  stddev?: Maybe<Mlhud_Kcca_Stddev_Order_By>;
  stddev_pop?: Maybe<Mlhud_Kcca_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mlhud_Kcca_Stddev_Samp_Order_By>;
  sum?: Maybe<Mlhud_Kcca_Sum_Order_By>;
  var_pop?: Maybe<Mlhud_Kcca_Var_Pop_Order_By>;
  var_samp?: Maybe<Mlhud_Kcca_Var_Samp_Order_By>;
  variance?: Maybe<Mlhud_Kcca_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mlhud.KCCA" */
export type Mlhud_Kcca_Arr_Rel_Insert_Input = {
  data: Array<Mlhud_Kcca_Insert_Input>;
};

/** aggregate avg on columns */
export type Mlhud_Kcca_Avg_Fields = {
  __typename?: "mlhud_KCCA_avg_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  Title_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Avg_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mlhud.KCCA". All fields are combined with a logical 'AND'. */
export type Mlhud_Kcca_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Baunit_Type?: Maybe<String_Comparison_Exp>;
  Block_Number?: Maybe<String_Comparison_Exp>;
  Condo_Plan_No?: Maybe<String_Comparison_Exp>;
  Condo_Unit_Factor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  First_Name?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<Float8_Comparison_Exp>;
  Full_Name?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Laf_Number?: Maybe<String_Comparison_Exp>;
  Land_Type?: Maybe<String_Comparison_Exp>;
  Land_Use_Type?: Maybe<String_Comparison_Exp>;
  Last_Name?: Maybe<String_Comparison_Exp>;
  Legal_Area?: Maybe<Float8_Comparison_Exp>;
  Legal_Area_Unit_Type?: Maybe<String_Comparison_Exp>;
  Middle_Name?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  Plot_No?: Maybe<String_Comparison_Exp>;
  Registration_Datetime?: Maybe<Timestamp_Comparison_Exp>;
  Road_Name?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tenure_Type?: Maybe<String_Comparison_Exp>;
  Title_Location?: Maybe<String_Comparison_Exp>;
  Title_Number?: Maybe<Float8_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Mlhud_Kcca_Bool_Exp>>>;
  _not?: Maybe<Mlhud_Kcca_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Mlhud_Kcca_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "mlhud.KCCA" */
export type Mlhud_Kcca_Inc_Input = {
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Title_Number?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "mlhud.KCCA" */
export type Mlhud_Kcca_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Title_Number?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Mlhud_Kcca_Max_Fields = {
  __typename?: "mlhud_KCCA_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Title_Number?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mlhud_Kcca_Min_Fields = {
  __typename?: "mlhud_KCCA_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Title_Number?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "mlhud.KCCA" */
export type Mlhud_Kcca_Mutation_Response = {
  __typename?: "mlhud_KCCA_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Mlhud_Kcca>;
};

/** input type for inserting object relation for remote table "mlhud.KCCA" */
export type Mlhud_Kcca_Obj_Rel_Insert_Input = {
  data: Mlhud_Kcca_Insert_Input;
};

/** ordering options when selecting data from "mlhud.KCCA" */
export type Mlhud_Kcca_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "mlhud.KCCA" */
export enum Mlhud_Kcca_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "Baunit_Type",
  /** column name */
  BlockNumber = "Block_Number",
  /** column name */
  CondoPlanNo = "Condo_Plan_No",
  /** column name */
  CondoUnitFactor = "Condo_Unit_Factor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  FirstName = "First_Name",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "Full_Name",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "Laf_Number",
  /** column name */
  LandType = "Land_Type",
  /** column name */
  LandUseType = "Land_Use_Type",
  /** column name */
  LastName = "Last_Name",
  /** column name */
  LegalArea = "Legal_Area",
  /** column name */
  LegalAreaUnitType = "Legal_Area_Unit_Type",
  /** column name */
  MiddleName = "Middle_Name",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "Plot_No",
  /** column name */
  RegistrationDatetime = "Registration_Datetime",
  /** column name */
  RoadName = "Road_Name",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "Tenure_Type",
  /** column name */
  TitleLocation = "Title_Location",
  /** column name */
  TitleNumber = "Title_Number",
  /** column name */
  Volume = "Volume",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "mlhud.KCCA" */
export type Mlhud_Kcca_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Title_Number?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Mlhud_Kcca_Stddev_Fields = {
  __typename?: "mlhud_KCCA_stddev_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  Title_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Stddev_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mlhud_Kcca_Stddev_Pop_Fields = {
  __typename?: "mlhud_KCCA_stddev_pop_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  Title_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Stddev_Pop_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mlhud_Kcca_Stddev_Samp_Fields = {
  __typename?: "mlhud_KCCA_stddev_samp_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  Title_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Stddev_Samp_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mlhud_Kcca_Sum_Fields = {
  __typename?: "mlhud_KCCA_sum_fields";
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Title_Number?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Sum_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Mlhud_Kcca_Var_Pop_Fields = {
  __typename?: "mlhud_KCCA_var_pop_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  Title_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Var_Pop_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mlhud_Kcca_Var_Samp_Fields = {
  __typename?: "mlhud_KCCA_var_samp_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  Title_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Var_Samp_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mlhud_Kcca_Variance_Fields = {
  __typename?: "mlhud_KCCA_variance_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  Title_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "mlhud.KCCA" */
export type Mlhud_Kcca_Variance_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Title_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "mlhud.Mukono" */
export type Mlhud_Mukono = {
  __typename?: "mlhud_Mukono";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "mlhud.Mukono" */
export type Mlhud_Mukono_Aggregate = {
  __typename?: "mlhud_Mukono_aggregate";
  aggregate?: Maybe<Mlhud_Mukono_Aggregate_Fields>;
  nodes: Array<Mlhud_Mukono>;
};

/** aggregate fields of "mlhud.Mukono" */
export type Mlhud_Mukono_Aggregate_Fields = {
  __typename?: "mlhud_Mukono_aggregate_fields";
  avg?: Maybe<Mlhud_Mukono_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Mlhud_Mukono_Max_Fields>;
  min?: Maybe<Mlhud_Mukono_Min_Fields>;
  stddev?: Maybe<Mlhud_Mukono_Stddev_Fields>;
  stddev_pop?: Maybe<Mlhud_Mukono_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mlhud_Mukono_Stddev_Samp_Fields>;
  sum?: Maybe<Mlhud_Mukono_Sum_Fields>;
  var_pop?: Maybe<Mlhud_Mukono_Var_Pop_Fields>;
  var_samp?: Maybe<Mlhud_Mukono_Var_Samp_Fields>;
  variance?: Maybe<Mlhud_Mukono_Variance_Fields>;
};

/** aggregate fields of "mlhud.Mukono" */
export type Mlhud_Mukono_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mlhud_Mukono_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "mlhud.Mukono" */
export type Mlhud_Mukono_Aggregate_Order_By = {
  avg?: Maybe<Mlhud_Mukono_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mlhud_Mukono_Max_Order_By>;
  min?: Maybe<Mlhud_Mukono_Min_Order_By>;
  stddev?: Maybe<Mlhud_Mukono_Stddev_Order_By>;
  stddev_pop?: Maybe<Mlhud_Mukono_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mlhud_Mukono_Stddev_Samp_Order_By>;
  sum?: Maybe<Mlhud_Mukono_Sum_Order_By>;
  var_pop?: Maybe<Mlhud_Mukono_Var_Pop_Order_By>;
  var_samp?: Maybe<Mlhud_Mukono_Var_Samp_Order_By>;
  variance?: Maybe<Mlhud_Mukono_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mlhud.Mukono" */
export type Mlhud_Mukono_Arr_Rel_Insert_Input = {
  data: Array<Mlhud_Mukono_Insert_Input>;
};

/** aggregate avg on columns */
export type Mlhud_Mukono_Avg_Fields = {
  __typename?: "mlhud_Mukono_avg_fields";
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Avg_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mlhud.Mukono". All fields are combined with a logical 'AND'. */
export type Mlhud_Mukono_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Baunit_Type?: Maybe<String_Comparison_Exp>;
  Block_Number?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  First_Name?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<Float8_Comparison_Exp>;
  Full_Name?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Laf_Number?: Maybe<String_Comparison_Exp>;
  Land_Type?: Maybe<String_Comparison_Exp>;
  Land_Use_Type?: Maybe<String_Comparison_Exp>;
  Last_Name?: Maybe<String_Comparison_Exp>;
  Legal_Area?: Maybe<Float8_Comparison_Exp>;
  Legal_Area_Unit_Type?: Maybe<String_Comparison_Exp>;
  Middle_Name?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  Plot_No?: Maybe<String_Comparison_Exp>;
  Registration_Datetime?: Maybe<Timestamp_Comparison_Exp>;
  Road_Name?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tenure_Type?: Maybe<String_Comparison_Exp>;
  Title_Location?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Mlhud_Mukono_Bool_Exp>>>;
  _not?: Maybe<Mlhud_Mukono_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Mlhud_Mukono_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "mlhud.Mukono" */
export type Mlhud_Mukono_Inc_Input = {
  Folio?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "mlhud.Mukono" */
export type Mlhud_Mukono_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Mlhud_Mukono_Max_Fields = {
  __typename?: "mlhud_Mukono_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mlhud_Mukono_Min_Fields = {
  __typename?: "mlhud_Mukono_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "mlhud.Mukono" */
export type Mlhud_Mukono_Mutation_Response = {
  __typename?: "mlhud_Mukono_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Mlhud_Mukono>;
};

/** input type for inserting object relation for remote table "mlhud.Mukono" */
export type Mlhud_Mukono_Obj_Rel_Insert_Input = {
  data: Mlhud_Mukono_Insert_Input;
};

/** ordering options when selecting data from "mlhud.Mukono" */
export type Mlhud_Mukono_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "mlhud.Mukono" */
export enum Mlhud_Mukono_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "Baunit_Type",
  /** column name */
  BlockNumber = "Block_Number",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  FirstName = "First_Name",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "Full_Name",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "Laf_Number",
  /** column name */
  LandType = "Land_Type",
  /** column name */
  LandUseType = "Land_Use_Type",
  /** column name */
  LastName = "Last_Name",
  /** column name */
  LegalArea = "Legal_Area",
  /** column name */
  LegalAreaUnitType = "Legal_Area_Unit_Type",
  /** column name */
  MiddleName = "Middle_Name",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "Plot_No",
  /** column name */
  RegistrationDatetime = "Registration_Datetime",
  /** column name */
  RoadName = "Road_Name",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "Tenure_Type",
  /** column name */
  TitleLocation = "Title_Location",
  /** column name */
  Volume = "Volume",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "mlhud.Mukono" */
export type Mlhud_Mukono_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Mlhud_Mukono_Stddev_Fields = {
  __typename?: "mlhud_Mukono_stddev_fields";
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Stddev_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mlhud_Mukono_Stddev_Pop_Fields = {
  __typename?: "mlhud_Mukono_stddev_pop_fields";
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Stddev_Pop_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mlhud_Mukono_Stddev_Samp_Fields = {
  __typename?: "mlhud_Mukono_stddev_samp_fields";
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Stddev_Samp_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mlhud_Mukono_Sum_Fields = {
  __typename?: "mlhud_Mukono_sum_fields";
  Folio?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Sum_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Mlhud_Mukono_Var_Pop_Fields = {
  __typename?: "mlhud_Mukono_var_pop_fields";
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Var_Pop_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mlhud_Mukono_Var_Samp_Fields = {
  __typename?: "mlhud_Mukono_var_samp_fields";
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Var_Samp_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mlhud_Mukono_Variance_Fields = {
  __typename?: "mlhud_Mukono_variance_fields";
  Folio?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "mlhud.Mukono" */
export type Mlhud_Mukono_Variance_Order_By = {
  Folio?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "mlhud.Properties" */
export type Mlhud_Properties = {
  __typename?: "mlhud_Properties";
  block_numb?: Maybe<Scalars["String"]>;
  cad_county?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
  lot_number?: Maybe<Scalars["String"]>;
  road_name?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "mlhud.Properties" */
export type Mlhud_Properties_Aggregate = {
  __typename?: "mlhud_Properties_aggregate";
  aggregate?: Maybe<Mlhud_Properties_Aggregate_Fields>;
  nodes: Array<Mlhud_Properties>;
};

/** aggregate fields of "mlhud.Properties" */
export type Mlhud_Properties_Aggregate_Fields = {
  __typename?: "mlhud_Properties_aggregate_fields";
  avg?: Maybe<Mlhud_Properties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Mlhud_Properties_Max_Fields>;
  min?: Maybe<Mlhud_Properties_Min_Fields>;
  stddev?: Maybe<Mlhud_Properties_Stddev_Fields>;
  stddev_pop?: Maybe<Mlhud_Properties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mlhud_Properties_Stddev_Samp_Fields>;
  sum?: Maybe<Mlhud_Properties_Sum_Fields>;
  var_pop?: Maybe<Mlhud_Properties_Var_Pop_Fields>;
  var_samp?: Maybe<Mlhud_Properties_Var_Samp_Fields>;
  variance?: Maybe<Mlhud_Properties_Variance_Fields>;
};

/** aggregate fields of "mlhud.Properties" */
export type Mlhud_Properties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mlhud_Properties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "mlhud.Properties" */
export type Mlhud_Properties_Aggregate_Order_By = {
  avg?: Maybe<Mlhud_Properties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mlhud_Properties_Max_Order_By>;
  min?: Maybe<Mlhud_Properties_Min_Order_By>;
  stddev?: Maybe<Mlhud_Properties_Stddev_Order_By>;
  stddev_pop?: Maybe<Mlhud_Properties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mlhud_Properties_Stddev_Samp_Order_By>;
  sum?: Maybe<Mlhud_Properties_Sum_Order_By>;
  var_pop?: Maybe<Mlhud_Properties_Var_Pop_Order_By>;
  var_samp?: Maybe<Mlhud_Properties_Var_Samp_Order_By>;
  variance?: Maybe<Mlhud_Properties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mlhud.Properties" */
export type Mlhud_Properties_Arr_Rel_Insert_Input = {
  data: Array<Mlhud_Properties_Insert_Input>;
};

/** aggregate avg on columns */
export type Mlhud_Properties_Avg_Fields = {
  __typename?: "mlhud_Properties_avg_fields";
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Avg_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mlhud.Properties". All fields are combined with a logical 'AND'. */
export type Mlhud_Properties_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Mlhud_Properties_Bool_Exp>>>;
  _not?: Maybe<Mlhud_Properties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Mlhud_Properties_Bool_Exp>>>;
  block_numb?: Maybe<String_Comparison_Exp>;
  cad_county?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  latitude?: Maybe<Float8_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  longitude?: Maybe<Float8_Comparison_Exp>;
  lot_number?: Maybe<String_Comparison_Exp>;
  road_name?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "mlhud.Properties" */
export type Mlhud_Properties_Inc_Input = {
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "mlhud.Properties" */
export type Mlhud_Properties_Insert_Input = {
  block_numb?: Maybe<Scalars["String"]>;
  cad_county?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
  lot_number?: Maybe<Scalars["String"]>;
  road_name?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Mlhud_Properties_Max_Fields = {
  __typename?: "mlhud_Properties_max_fields";
  block_numb?: Maybe<Scalars["String"]>;
  cad_county?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
  lot_number?: Maybe<Scalars["String"]>;
  road_name?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Max_Order_By = {
  block_numb?: Maybe<Order_By>;
  cad_county?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  lot_number?: Maybe<Order_By>;
  road_name?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mlhud_Properties_Min_Fields = {
  __typename?: "mlhud_Properties_min_fields";
  block_numb?: Maybe<Scalars["String"]>;
  cad_county?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
  lot_number?: Maybe<Scalars["String"]>;
  road_name?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Min_Order_By = {
  block_numb?: Maybe<Order_By>;
  cad_county?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  lot_number?: Maybe<Order_By>;
  road_name?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "mlhud.Properties" */
export type Mlhud_Properties_Mutation_Response = {
  __typename?: "mlhud_Properties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Mlhud_Properties>;
};

/** input type for inserting object relation for remote table "mlhud.Properties" */
export type Mlhud_Properties_Obj_Rel_Insert_Input = {
  data: Mlhud_Properties_Insert_Input;
};

/** ordering options when selecting data from "mlhud.Properties" */
export type Mlhud_Properties_Order_By = {
  block_numb?: Maybe<Order_By>;
  cad_county?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  lot_number?: Maybe<Order_By>;
  road_name?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "mlhud.Properties" */
export enum Mlhud_Properties_Select_Column {
  /** column name */
  BlockNumb = "block_numb",
  /** column name */
  CadCounty = "cad_county",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Latitude = "latitude",
  /** column name */
  Line = "line",
  /** column name */
  Longitude = "longitude",
  /** column name */
  LotNumber = "lot_number",
  /** column name */
  RoadName = "road_name",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "mlhud.Properties" */
export type Mlhud_Properties_Set_Input = {
  block_numb?: Maybe<Scalars["String"]>;
  cad_county?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
  lot_number?: Maybe<Scalars["String"]>;
  road_name?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Mlhud_Properties_Stddev_Fields = {
  __typename?: "mlhud_Properties_stddev_fields";
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Stddev_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mlhud_Properties_Stddev_Pop_Fields = {
  __typename?: "mlhud_Properties_stddev_pop_fields";
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Stddev_Pop_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mlhud_Properties_Stddev_Samp_Fields = {
  __typename?: "mlhud_Properties_stddev_samp_fields";
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Stddev_Samp_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mlhud_Properties_Sum_Fields = {
  __typename?: "mlhud_Properties_sum_fields";
  latitude?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
  longitude?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Sum_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Mlhud_Properties_Var_Pop_Fields = {
  __typename?: "mlhud_Properties_var_pop_fields";
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Var_Pop_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mlhud_Properties_Var_Samp_Fields = {
  __typename?: "mlhud_Properties_var_samp_fields";
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Var_Samp_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mlhud_Properties_Variance_Fields = {
  __typename?: "mlhud_Properties_variance_fields";
  latitude?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "mlhud.Properties" */
export type Mlhud_Properties_Variance_Order_By = {
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** columns and relationships of "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro = {
  __typename?: "mlhud_WakisoBusiro";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Aggregate = {
  __typename?: "mlhud_WakisoBusiro_aggregate";
  aggregate?: Maybe<Mlhud_WakisoBusiro_Aggregate_Fields>;
  nodes: Array<Mlhud_WakisoBusiro>;
};

/** aggregate fields of "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Aggregate_Fields = {
  __typename?: "mlhud_WakisoBusiro_aggregate_fields";
  avg?: Maybe<Mlhud_WakisoBusiro_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Mlhud_WakisoBusiro_Max_Fields>;
  min?: Maybe<Mlhud_WakisoBusiro_Min_Fields>;
  stddev?: Maybe<Mlhud_WakisoBusiro_Stddev_Fields>;
  stddev_pop?: Maybe<Mlhud_WakisoBusiro_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mlhud_WakisoBusiro_Stddev_Samp_Fields>;
  sum?: Maybe<Mlhud_WakisoBusiro_Sum_Fields>;
  var_pop?: Maybe<Mlhud_WakisoBusiro_Var_Pop_Fields>;
  var_samp?: Maybe<Mlhud_WakisoBusiro_Var_Samp_Fields>;
  variance?: Maybe<Mlhud_WakisoBusiro_Variance_Fields>;
};

/** aggregate fields of "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mlhud_WakisoBusiro_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Aggregate_Order_By = {
  avg?: Maybe<Mlhud_WakisoBusiro_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mlhud_WakisoBusiro_Max_Order_By>;
  min?: Maybe<Mlhud_WakisoBusiro_Min_Order_By>;
  stddev?: Maybe<Mlhud_WakisoBusiro_Stddev_Order_By>;
  stddev_pop?: Maybe<Mlhud_WakisoBusiro_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mlhud_WakisoBusiro_Stddev_Samp_Order_By>;
  sum?: Maybe<Mlhud_WakisoBusiro_Sum_Order_By>;
  var_pop?: Maybe<Mlhud_WakisoBusiro_Var_Pop_Order_By>;
  var_samp?: Maybe<Mlhud_WakisoBusiro_Var_Samp_Order_By>;
  variance?: Maybe<Mlhud_WakisoBusiro_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Arr_Rel_Insert_Input = {
  data: Array<Mlhud_WakisoBusiro_Insert_Input>;
};

/** aggregate avg on columns */
export type Mlhud_WakisoBusiro_Avg_Fields = {
  __typename?: "mlhud_WakisoBusiro_avg_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Avg_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mlhud.WakisoBusiro". All fields are combined with a logical 'AND'. */
export type Mlhud_WakisoBusiro_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Baunit_Type?: Maybe<String_Comparison_Exp>;
  Block_Number?: Maybe<String_Comparison_Exp>;
  Condo_Plan_No?: Maybe<String_Comparison_Exp>;
  Condo_Unit_Factor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  First_Name?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  Full_Name?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Laf_Number?: Maybe<String_Comparison_Exp>;
  Land_Type?: Maybe<String_Comparison_Exp>;
  Land_Use_Type?: Maybe<String_Comparison_Exp>;
  Last_Name?: Maybe<String_Comparison_Exp>;
  Legal_Area?: Maybe<Float8_Comparison_Exp>;
  Legal_Area_Unit_Type?: Maybe<String_Comparison_Exp>;
  Middle_Name?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  Plot_No?: Maybe<String_Comparison_Exp>;
  Registration_Datetime?: Maybe<Timestamp_Comparison_Exp>;
  Road_Name?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tenure_Type?: Maybe<String_Comparison_Exp>;
  Title_Location?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Mlhud_WakisoBusiro_Bool_Exp>>>;
  _not?: Maybe<Mlhud_WakisoBusiro_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Mlhud_WakisoBusiro_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Inc_Input = {
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Mlhud_WakisoBusiro_Max_Fields = {
  __typename?: "mlhud_WakisoBusiro_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mlhud_WakisoBusiro_Min_Fields = {
  __typename?: "mlhud_WakisoBusiro_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Mutation_Response = {
  __typename?: "mlhud_WakisoBusiro_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Mlhud_WakisoBusiro>;
};

/** input type for inserting object relation for remote table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Obj_Rel_Insert_Input = {
  data: Mlhud_WakisoBusiro_Insert_Input;
};

/** ordering options when selecting data from "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "mlhud.WakisoBusiro" */
export enum Mlhud_WakisoBusiro_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "Baunit_Type",
  /** column name */
  BlockNumber = "Block_Number",
  /** column name */
  CondoPlanNo = "Condo_Plan_No",
  /** column name */
  CondoUnitFactor = "Condo_Unit_Factor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  FirstName = "First_Name",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "Full_Name",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "Laf_Number",
  /** column name */
  LandType = "Land_Type",
  /** column name */
  LandUseType = "Land_Use_Type",
  /** column name */
  LastName = "Last_Name",
  /** column name */
  LegalArea = "Legal_Area",
  /** column name */
  LegalAreaUnitType = "Legal_Area_Unit_Type",
  /** column name */
  MiddleName = "Middle_Name",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "Plot_No",
  /** column name */
  RegistrationDatetime = "Registration_Datetime",
  /** column name */
  RoadName = "Road_Name",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "Tenure_Type",
  /** column name */
  TitleLocation = "Title_Location",
  /** column name */
  Volume = "Volume",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Mlhud_WakisoBusiro_Stddev_Fields = {
  __typename?: "mlhud_WakisoBusiro_stddev_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Stddev_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mlhud_WakisoBusiro_Stddev_Pop_Fields = {
  __typename?: "mlhud_WakisoBusiro_stddev_pop_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Stddev_Pop_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mlhud_WakisoBusiro_Stddev_Samp_Fields = {
  __typename?: "mlhud_WakisoBusiro_stddev_samp_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Stddev_Samp_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mlhud_WakisoBusiro_Sum_Fields = {
  __typename?: "mlhud_WakisoBusiro_sum_fields";
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Sum_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Mlhud_WakisoBusiro_Var_Pop_Fields = {
  __typename?: "mlhud_WakisoBusiro_var_pop_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Var_Pop_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mlhud_WakisoBusiro_Var_Samp_Fields = {
  __typename?: "mlhud_WakisoBusiro_var_samp_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Var_Samp_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mlhud_WakisoBusiro_Variance_Fields = {
  __typename?: "mlhud_WakisoBusiro_variance_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "mlhud.WakisoBusiro" */
export type Mlhud_WakisoBusiro_Variance_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo = {
  __typename?: "mlhud_WakisoKyadondo";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Aggregate = {
  __typename?: "mlhud_WakisoKyadondo_aggregate";
  aggregate?: Maybe<Mlhud_WakisoKyadondo_Aggregate_Fields>;
  nodes: Array<Mlhud_WakisoKyadondo>;
};

/** aggregate fields of "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Aggregate_Fields = {
  __typename?: "mlhud_WakisoKyadondo_aggregate_fields";
  avg?: Maybe<Mlhud_WakisoKyadondo_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Mlhud_WakisoKyadondo_Max_Fields>;
  min?: Maybe<Mlhud_WakisoKyadondo_Min_Fields>;
  stddev?: Maybe<Mlhud_WakisoKyadondo_Stddev_Fields>;
  stddev_pop?: Maybe<Mlhud_WakisoKyadondo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mlhud_WakisoKyadondo_Stddev_Samp_Fields>;
  sum?: Maybe<Mlhud_WakisoKyadondo_Sum_Fields>;
  var_pop?: Maybe<Mlhud_WakisoKyadondo_Var_Pop_Fields>;
  var_samp?: Maybe<Mlhud_WakisoKyadondo_Var_Samp_Fields>;
  variance?: Maybe<Mlhud_WakisoKyadondo_Variance_Fields>;
};

/** aggregate fields of "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mlhud_WakisoKyadondo_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Aggregate_Order_By = {
  avg?: Maybe<Mlhud_WakisoKyadondo_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mlhud_WakisoKyadondo_Max_Order_By>;
  min?: Maybe<Mlhud_WakisoKyadondo_Min_Order_By>;
  stddev?: Maybe<Mlhud_WakisoKyadondo_Stddev_Order_By>;
  stddev_pop?: Maybe<Mlhud_WakisoKyadondo_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mlhud_WakisoKyadondo_Stddev_Samp_Order_By>;
  sum?: Maybe<Mlhud_WakisoKyadondo_Sum_Order_By>;
  var_pop?: Maybe<Mlhud_WakisoKyadondo_Var_Pop_Order_By>;
  var_samp?: Maybe<Mlhud_WakisoKyadondo_Var_Samp_Order_By>;
  variance?: Maybe<Mlhud_WakisoKyadondo_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Arr_Rel_Insert_Input = {
  data: Array<Mlhud_WakisoKyadondo_Insert_Input>;
};

/** aggregate avg on columns */
export type Mlhud_WakisoKyadondo_Avg_Fields = {
  __typename?: "mlhud_WakisoKyadondo_avg_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Avg_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mlhud.WakisoKyadondo". All fields are combined with a logical 'AND'. */
export type Mlhud_WakisoKyadondo_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Baunit_Type?: Maybe<String_Comparison_Exp>;
  Block_Number?: Maybe<String_Comparison_Exp>;
  Condo_Plan_No?: Maybe<String_Comparison_Exp>;
  Condo_Unit_Factor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  First_Name?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  Full_Name?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Laf_Number?: Maybe<String_Comparison_Exp>;
  Land_Type?: Maybe<String_Comparison_Exp>;
  Land_Use_Type?: Maybe<String_Comparison_Exp>;
  Last_Name?: Maybe<String_Comparison_Exp>;
  Legal_Area?: Maybe<Float8_Comparison_Exp>;
  Legal_Area_Unit_Type?: Maybe<String_Comparison_Exp>;
  Middle_Name?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  Plot_No?: Maybe<String_Comparison_Exp>;
  Registration_Datetime?: Maybe<Timestamp_Comparison_Exp>;
  Road_Name?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tenure_Type?: Maybe<String_Comparison_Exp>;
  Title_Location?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Mlhud_WakisoKyadondo_Bool_Exp>>>;
  _not?: Maybe<Mlhud_WakisoKyadondo_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Mlhud_WakisoKyadondo_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Inc_Input = {
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Mlhud_WakisoKyadondo_Max_Fields = {
  __typename?: "mlhud_WakisoKyadondo_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mlhud_WakisoKyadondo_Min_Fields = {
  __typename?: "mlhud_WakisoKyadondo_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Mutation_Response = {
  __typename?: "mlhud_WakisoKyadondo_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Mlhud_WakisoKyadondo>;
};

/** input type for inserting object relation for remote table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Obj_Rel_Insert_Input = {
  data: Mlhud_WakisoKyadondo_Insert_Input;
};

/** ordering options when selecting data from "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Order_By = {
  Address?: Maybe<Order_By>;
  Baunit_Type?: Maybe<Order_By>;
  Block_Number?: Maybe<Order_By>;
  Condo_Plan_No?: Maybe<Order_By>;
  Condo_Unit_Factor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Full_Name?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Laf_Number?: Maybe<Order_By>;
  Land_Type?: Maybe<Order_By>;
  Land_Use_Type?: Maybe<Order_By>;
  Last_Name?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  Legal_Area_Unit_Type?: Maybe<Order_By>;
  Middle_Name?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plot_No?: Maybe<Order_By>;
  Registration_Datetime?: Maybe<Order_By>;
  Road_Name?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenure_Type?: Maybe<Order_By>;
  Title_Location?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "mlhud.WakisoKyadondo" */
export enum Mlhud_WakisoKyadondo_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "Baunit_Type",
  /** column name */
  BlockNumber = "Block_Number",
  /** column name */
  CondoPlanNo = "Condo_Plan_No",
  /** column name */
  CondoUnitFactor = "Condo_Unit_Factor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  FirstName = "First_Name",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "Full_Name",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "Laf_Number",
  /** column name */
  LandType = "Land_Type",
  /** column name */
  LandUseType = "Land_Use_Type",
  /** column name */
  LastName = "Last_Name",
  /** column name */
  LegalArea = "Legal_Area",
  /** column name */
  LegalAreaUnitType = "Legal_Area_Unit_Type",
  /** column name */
  MiddleName = "Middle_Name",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "Plot_No",
  /** column name */
  RegistrationDatetime = "Registration_Datetime",
  /** column name */
  RoadName = "Road_Name",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "Tenure_Type",
  /** column name */
  TitleLocation = "Title_Location",
  /** column name */
  Volume = "Volume",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunit_Type?: Maybe<Scalars["String"]>;
  Block_Number?: Maybe<Scalars["String"]>;
  Condo_Plan_No?: Maybe<Scalars["String"]>;
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  First_Name?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Full_Name?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Laf_Number?: Maybe<Scalars["String"]>;
  Land_Type?: Maybe<Scalars["String"]>;
  Land_Use_Type?: Maybe<Scalars["String"]>;
  Last_Name?: Maybe<Scalars["String"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  Legal_Area_Unit_Type?: Maybe<Scalars["String"]>;
  Middle_Name?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plot_No?: Maybe<Scalars["String"]>;
  Registration_Datetime?: Maybe<Scalars["timestamp"]>;
  Road_Name?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenure_Type?: Maybe<Scalars["String"]>;
  Title_Location?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Mlhud_WakisoKyadondo_Stddev_Fields = {
  __typename?: "mlhud_WakisoKyadondo_stddev_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Stddev_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mlhud_WakisoKyadondo_Stddev_Pop_Fields = {
  __typename?: "mlhud_WakisoKyadondo_stddev_pop_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Stddev_Pop_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mlhud_WakisoKyadondo_Stddev_Samp_Fields = {
  __typename?: "mlhud_WakisoKyadondo_stddev_samp_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Stddev_Samp_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mlhud_WakisoKyadondo_Sum_Fields = {
  __typename?: "mlhud_WakisoKyadondo_sum_fields";
  Condo_Unit_Factor?: Maybe<Scalars["float8"]>;
  Legal_Area?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Sum_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Mlhud_WakisoKyadondo_Var_Pop_Fields = {
  __typename?: "mlhud_WakisoKyadondo_var_pop_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Var_Pop_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mlhud_WakisoKyadondo_Var_Samp_Fields = {
  __typename?: "mlhud_WakisoKyadondo_var_samp_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Var_Samp_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mlhud_WakisoKyadondo_Variance_Fields = {
  __typename?: "mlhud_WakisoKyadondo_variance_fields";
  Condo_Unit_Factor?: Maybe<Scalars["Float"]>;
  Legal_Area?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "mlhud.WakisoKyadondo" */
export type Mlhud_WakisoKyadondo_Variance_Order_By = {
  Condo_Unit_Factor?: Maybe<Order_By>;
  Legal_Area?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "kcca.AssociateType" */
  delete_kcca_AssociateType?: Maybe<Kcca_AssociateType_Mutation_Response>;
  /** delete data from the table: "kcca.County" */
  delete_kcca_County?: Maybe<Kcca_County_Mutation_Response>;
  /** delete data from the table: "kcca.Customer" */
  delete_kcca_Customer?: Maybe<Kcca_Customer_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerApplicantType" */
  delete_kcca_CustomerApplicantType?: Maybe<Kcca_CustomerApplicantType_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerBusinessAssociate" */
  delete_kcca_CustomerBusinessAssociate?: Maybe<Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerBusinessType" */
  delete_kcca_CustomerBusinessType?: Maybe<Kcca_CustomerBusinessType_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerCoinDeactivateReactive" */
  delete_kcca_CustomerCoinDeactivateReactive?: Maybe<Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerContactPerson" */
  delete_kcca_CustomerContactPerson?: Maybe<Kcca_CustomerContactPerson_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerDocumentItem" */
  delete_kcca_CustomerDocumentItem?: Maybe<Kcca_CustomerDocumentItem_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerGuardian" */
  delete_kcca_CustomerGuardian?: Maybe<Kcca_CustomerGuardian_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerReference" */
  delete_kcca_CustomerReference?: Maybe<Kcca_CustomerReference_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerRegistrationReason" */
  delete_kcca_CustomerRegistrationReason?: Maybe<Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerRevenueAgent" */
  delete_kcca_CustomerRevenueAgent?: Maybe<Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** delete data from the table: "kcca.CustomerType" */
  delete_kcca_CustomerType?: Maybe<Kcca_CustomerType_Mutation_Response>;
  /** delete data from the table: "kcca.District" */
  delete_kcca_District?: Maybe<Kcca_District_Mutation_Response>;
  /** delete data from the table: "kcca.Division" */
  delete_kcca_Division?: Maybe<Kcca_Division_Mutation_Response>;
  /** delete data from the table: "kcca.Parish" */
  delete_kcca_Parish?: Maybe<Kcca_Parish_Mutation_Response>;
  /** delete data from the table: "kcca.Property" */
  delete_kcca_Property?: Maybe<Kcca_Property_Mutation_Response>;
  /** delete data from the table: "kcca.PropertyFloors" */
  delete_kcca_PropertyFloors?: Maybe<Kcca_PropertyFloors_Mutation_Response>;
  /** delete data from the table: "kcca.PropertyNumberBedrooms" */
  delete_kcca_PropertyNumberBedrooms?: Maybe<Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** delete data from the table: "kcca.PropertyRentedStatus" */
  delete_kcca_PropertyRentedStatus?: Maybe<Kcca_PropertyRentedStatus_Mutation_Response>;
  /** delete data from the table: "kcca.PropertySecurity" */
  delete_kcca_PropertySecurity?: Maybe<Kcca_PropertySecurity_Mutation_Response>;
  /** delete data from the table: "kcca.PropertySubType" */
  delete_kcca_PropertySubType?: Maybe<Kcca_PropertySubType_Mutation_Response>;
  /** delete data from the table: "kcca.PropertyType" */
  delete_kcca_PropertyType?: Maybe<Kcca_PropertyType_Mutation_Response>;
  /** delete data from the table: "kcca.RawProperties1" */
  delete_kcca_RawProperties1?: Maybe<Kcca_RawProperties1_Mutation_Response>;
  /** delete data from the table: "kcca.RawProperties2" */
  delete_kcca_RawProperties2?: Maybe<Kcca_RawProperties2_Mutation_Response>;
  /** delete data from the table: "kcca.RevenueType" */
  delete_kcca_RevenueType?: Maybe<Kcca_RevenueType_Mutation_Response>;
  /** delete data from the table: "kcca.SubCounty" */
  delete_kcca_SubCounty?: Maybe<Kcca_SubCounty_Mutation_Response>;
  /** delete data from the table: "kcca.TradingLicense" */
  delete_kcca_TradingLicense?: Maybe<Kcca_TradingLicense_Mutation_Response>;
  /** delete data from the table: "kcca.Village" */
  delete_kcca_Village?: Maybe<Kcca_Village_Mutation_Response>;
  /** delete data from the table: "mlhud.KCCA" */
  delete_mlhud_KCCA?: Maybe<Mlhud_Kcca_Mutation_Response>;
  /** delete data from the table: "mlhud.Mukono" */
  delete_mlhud_Mukono?: Maybe<Mlhud_Mukono_Mutation_Response>;
  /** delete data from the table: "mlhud.Properties" */
  delete_mlhud_Properties?: Maybe<Mlhud_Properties_Mutation_Response>;
  /** delete data from the table: "mlhud.WakisoBusiro" */
  delete_mlhud_WakisoBusiro?: Maybe<Mlhud_WakisoBusiro_Mutation_Response>;
  /** delete data from the table: "mlhud.WakisoKyadondo" */
  delete_mlhud_WakisoKyadondo?: Maybe<Mlhud_WakisoKyadondo_Mutation_Response>;
  /** delete data from the table: "nwsc.Customers" */
  delete_nwsc_Customers?: Maybe<Nwsc_Customers_Mutation_Response>;
  /** delete data from the table: "nwsc.CustomersURATransactionsAndTrends" */
  delete_nwsc_CustomersURATransactionsAndTrends?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Mutation_Response>;
  /** delete data from the table: "nwsc.URATransactions" */
  delete_nwsc_URATransactions?: Maybe<Nwsc_UraTransactions_Mutation_Response>;
  /** delete data from the table: "ucc.UCC" */
  delete_ucc_UCC?: Maybe<Ucc_Ucc_Mutation_Response>;
  /** delete data from the table: "ura.EmploymentIncomeDetail" */
  delete_ura_EmploymentIncomeDetail?: Maybe<Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** delete data from the table: "ura.HoldingOfficers" */
  delete_ura_HoldingOfficers?: Maybe<Ura_HoldingOfficers_Mutation_Response>;
  /** delete data from the table: "ura.HoldingOwner" */
  delete_ura_HoldingOwner?: Maybe<Ura_HoldingOwner_Mutation_Response>;
  /** delete data from the table: "ura.IncomeTaxHolding" */
  delete_ura_IncomeTaxHolding?: Maybe<Ura_IncomeTaxHolding_Mutation_Response>;
  /** delete data from the table: "ura.IncomeTaxSummaryIndividual" */
  delete_ura_IncomeTaxSummaryIndividual?: Maybe<Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** delete data from the table: "ura.IncomeTaxSummaryNonIndividual" */
  delete_ura_IncomeTaxSummaryNonIndividual?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** delete data from the table: "ura.IndividualMortgage" */
  delete_ura_IndividualMortgage?: Maybe<Ura_IndividualMortgage_Mutation_Response>;
  /** delete data from the table: "ura.RMatchDetailed904" */
  delete_ura_RMatchDetailed904?: Maybe<Ura_RMatchDetailed904_Mutation_Response>;
  /** delete data from the table: "ura.RMatchDetailed916" */
  delete_ura_RMatchDetailed916?: Maybe<Ura_RMatchDetailed916_Mutation_Response>;
  /** delete data from the table: "ura.RMatchDetailed946" */
  delete_ura_RMatchDetailed946?: Maybe<Ura_RMatchDetailed946_Mutation_Response>;
  /** delete data from the table: "ura.RMatchSummary904" */
  delete_ura_RMatchSummary904?: Maybe<Ura_RMatchSummary904_Mutation_Response>;
  /** delete data from the table: "ura.RMatchSummary916" */
  delete_ura_RMatchSummary916?: Maybe<Ura_RMatchSummary916_Mutation_Response>;
  /** delete data from the table: "ura.RMatchSummary946" */
  delete_ura_RMatchSummary946?: Maybe<Ura_RMatchSummary946_Mutation_Response>;
  /** delete data from the table: "ura.RPT_IndividualTaxSummaryData" */
  delete_ura_RPT_IndividualTaxSummaryData?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** delete data from the table: "ura.RPT_KccaCustomerRegistrationData" */
  delete_ura_RPT_KccaCustomerRegistrationData?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** delete data from the table: "ura.RPT_KccaPropertyRegistrationData" */
  delete_ura_RPT_KccaPropertyRegistrationData?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** delete data from the table: "ura.RPT_NonIndividualTaxSummaryData" */
  delete_ura_RPT_NonIndividualTaxSummaryData?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** delete data from the table: "ura.RPT_RegIndividual" */
  delete_ura_RPT_RegIndividual?: Maybe<Ura_Rpt_RegIndividual_Mutation_Response>;
  /** delete data from the table: "ura.RegAssociates" */
  delete_ura_RegAssociates?: Maybe<Ura_RegAssociates_Mutation_Response>;
  /** delete data from the table: "ura.RegIndividual" */
  delete_ura_RegIndividual?: Maybe<Ura_RegIndividual_Mutation_Response>;
  /** delete data from the table: "ura.RegNonIndividual" */
  delete_ura_RegNonIndividual?: Maybe<Ura_RegNonIndividual_Mutation_Response>;
  /** delete data from the table: "ura.RentExpenseDetails" */
  delete_ura_RentExpenseDetails?: Maybe<Ura_RentExpenseDetails_Mutation_Response>;
  /** delete data from the table: "ura.RentalLandLordIncome" */
  delete_ura_RentalLandLordIncome?: Maybe<Ura_RentalLandLordIncome_Mutation_Response>;
  /** delete data from the table: "ura.RentalTenantPayments" */
  delete_ura_RentalTenantPayments?: Maybe<Ura_RentalTenantPayments_Mutation_Response>;
  /** delete data from the table: "ura.RipoIndRentalIncSummary" */
  delete_ura_RipoIndRentalIncSummary?: Maybe<Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** delete data from the table: "ura.Town" */
  delete_ura_Town?: Maybe<Ura_Town_Mutation_Response>;
  /** delete data from the table: "ursb.CompaniesAndBusinessNamesURSB" */
  delete_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
  /** insert data into the table: "kcca.AssociateType" */
  insert_kcca_AssociateType?: Maybe<Kcca_AssociateType_Mutation_Response>;
  /** insert a single row into the table: "kcca.AssociateType" */
  insert_kcca_AssociateType_one?: Maybe<Kcca_AssociateType>;
  /** insert data into the table: "kcca.County" */
  insert_kcca_County?: Maybe<Kcca_County_Mutation_Response>;
  /** insert a single row into the table: "kcca.County" */
  insert_kcca_County_one?: Maybe<Kcca_County>;
  /** insert data into the table: "kcca.Customer" */
  insert_kcca_Customer?: Maybe<Kcca_Customer_Mutation_Response>;
  /** insert data into the table: "kcca.CustomerApplicantType" */
  insert_kcca_CustomerApplicantType?: Maybe<Kcca_CustomerApplicantType_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerApplicantType" */
  insert_kcca_CustomerApplicantType_one?: Maybe<Kcca_CustomerApplicantType>;
  /** insert data into the table: "kcca.CustomerBusinessAssociate" */
  insert_kcca_CustomerBusinessAssociate?: Maybe<Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerBusinessAssociate" */
  insert_kcca_CustomerBusinessAssociate_one?: Maybe<Kcca_CustomerBusinessAssociate>;
  /** insert data into the table: "kcca.CustomerBusinessType" */
  insert_kcca_CustomerBusinessType?: Maybe<Kcca_CustomerBusinessType_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerBusinessType" */
  insert_kcca_CustomerBusinessType_one?: Maybe<Kcca_CustomerBusinessType>;
  /** insert data into the table: "kcca.CustomerCoinDeactivateReactive" */
  insert_kcca_CustomerCoinDeactivateReactive?: Maybe<Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerCoinDeactivateReactive" */
  insert_kcca_CustomerCoinDeactivateReactive_one?: Maybe<Kcca_CustomerCoinDeactivateReactive>;
  /** insert data into the table: "kcca.CustomerContactPerson" */
  insert_kcca_CustomerContactPerson?: Maybe<Kcca_CustomerContactPerson_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerContactPerson" */
  insert_kcca_CustomerContactPerson_one?: Maybe<Kcca_CustomerContactPerson>;
  /** insert data into the table: "kcca.CustomerDocumentItem" */
  insert_kcca_CustomerDocumentItem?: Maybe<Kcca_CustomerDocumentItem_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerDocumentItem" */
  insert_kcca_CustomerDocumentItem_one?: Maybe<Kcca_CustomerDocumentItem>;
  /** insert data into the table: "kcca.CustomerGuardian" */
  insert_kcca_CustomerGuardian?: Maybe<Kcca_CustomerGuardian_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerGuardian" */
  insert_kcca_CustomerGuardian_one?: Maybe<Kcca_CustomerGuardian>;
  /** insert data into the table: "kcca.CustomerReference" */
  insert_kcca_CustomerReference?: Maybe<Kcca_CustomerReference_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerReference" */
  insert_kcca_CustomerReference_one?: Maybe<Kcca_CustomerReference>;
  /** insert data into the table: "kcca.CustomerRegistrationReason" */
  insert_kcca_CustomerRegistrationReason?: Maybe<Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerRegistrationReason" */
  insert_kcca_CustomerRegistrationReason_one?: Maybe<Kcca_CustomerRegistrationReason>;
  /** insert data into the table: "kcca.CustomerRevenueAgent" */
  insert_kcca_CustomerRevenueAgent?: Maybe<Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerRevenueAgent" */
  insert_kcca_CustomerRevenueAgent_one?: Maybe<Kcca_CustomerRevenueAgent>;
  /** insert data into the table: "kcca.CustomerType" */
  insert_kcca_CustomerType?: Maybe<Kcca_CustomerType_Mutation_Response>;
  /** insert a single row into the table: "kcca.CustomerType" */
  insert_kcca_CustomerType_one?: Maybe<Kcca_CustomerType>;
  /** insert a single row into the table: "kcca.Customer" */
  insert_kcca_Customer_one?: Maybe<Kcca_Customer>;
  /** insert data into the table: "kcca.District" */
  insert_kcca_District?: Maybe<Kcca_District_Mutation_Response>;
  /** insert a single row into the table: "kcca.District" */
  insert_kcca_District_one?: Maybe<Kcca_District>;
  /** insert data into the table: "kcca.Division" */
  insert_kcca_Division?: Maybe<Kcca_Division_Mutation_Response>;
  /** insert a single row into the table: "kcca.Division" */
  insert_kcca_Division_one?: Maybe<Kcca_Division>;
  /** insert data into the table: "kcca.Parish" */
  insert_kcca_Parish?: Maybe<Kcca_Parish_Mutation_Response>;
  /** insert a single row into the table: "kcca.Parish" */
  insert_kcca_Parish_one?: Maybe<Kcca_Parish>;
  /** insert data into the table: "kcca.Property" */
  insert_kcca_Property?: Maybe<Kcca_Property_Mutation_Response>;
  /** insert data into the table: "kcca.PropertyFloors" */
  insert_kcca_PropertyFloors?: Maybe<Kcca_PropertyFloors_Mutation_Response>;
  /** insert a single row into the table: "kcca.PropertyFloors" */
  insert_kcca_PropertyFloors_one?: Maybe<Kcca_PropertyFloors>;
  /** insert data into the table: "kcca.PropertyNumberBedrooms" */
  insert_kcca_PropertyNumberBedrooms?: Maybe<Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** insert a single row into the table: "kcca.PropertyNumberBedrooms" */
  insert_kcca_PropertyNumberBedrooms_one?: Maybe<Kcca_PropertyNumberBedrooms>;
  /** insert data into the table: "kcca.PropertyRentedStatus" */
  insert_kcca_PropertyRentedStatus?: Maybe<Kcca_PropertyRentedStatus_Mutation_Response>;
  /** insert a single row into the table: "kcca.PropertyRentedStatus" */
  insert_kcca_PropertyRentedStatus_one?: Maybe<Kcca_PropertyRentedStatus>;
  /** insert data into the table: "kcca.PropertySecurity" */
  insert_kcca_PropertySecurity?: Maybe<Kcca_PropertySecurity_Mutation_Response>;
  /** insert a single row into the table: "kcca.PropertySecurity" */
  insert_kcca_PropertySecurity_one?: Maybe<Kcca_PropertySecurity>;
  /** insert data into the table: "kcca.PropertySubType" */
  insert_kcca_PropertySubType?: Maybe<Kcca_PropertySubType_Mutation_Response>;
  /** insert a single row into the table: "kcca.PropertySubType" */
  insert_kcca_PropertySubType_one?: Maybe<Kcca_PropertySubType>;
  /** insert data into the table: "kcca.PropertyType" */
  insert_kcca_PropertyType?: Maybe<Kcca_PropertyType_Mutation_Response>;
  /** insert a single row into the table: "kcca.PropertyType" */
  insert_kcca_PropertyType_one?: Maybe<Kcca_PropertyType>;
  /** insert a single row into the table: "kcca.Property" */
  insert_kcca_Property_one?: Maybe<Kcca_Property>;
  /** insert data into the table: "kcca.RawProperties1" */
  insert_kcca_RawProperties1?: Maybe<Kcca_RawProperties1_Mutation_Response>;
  /** insert a single row into the table: "kcca.RawProperties1" */
  insert_kcca_RawProperties1_one?: Maybe<Kcca_RawProperties1>;
  /** insert data into the table: "kcca.RawProperties2" */
  insert_kcca_RawProperties2?: Maybe<Kcca_RawProperties2_Mutation_Response>;
  /** insert a single row into the table: "kcca.RawProperties2" */
  insert_kcca_RawProperties2_one?: Maybe<Kcca_RawProperties2>;
  /** insert data into the table: "kcca.RevenueType" */
  insert_kcca_RevenueType?: Maybe<Kcca_RevenueType_Mutation_Response>;
  /** insert a single row into the table: "kcca.RevenueType" */
  insert_kcca_RevenueType_one?: Maybe<Kcca_RevenueType>;
  /** insert data into the table: "kcca.SubCounty" */
  insert_kcca_SubCounty?: Maybe<Kcca_SubCounty_Mutation_Response>;
  /** insert a single row into the table: "kcca.SubCounty" */
  insert_kcca_SubCounty_one?: Maybe<Kcca_SubCounty>;
  /** insert data into the table: "kcca.TradingLicense" */
  insert_kcca_TradingLicense?: Maybe<Kcca_TradingLicense_Mutation_Response>;
  /** insert a single row into the table: "kcca.TradingLicense" */
  insert_kcca_TradingLicense_one?: Maybe<Kcca_TradingLicense>;
  /** insert data into the table: "kcca.Village" */
  insert_kcca_Village?: Maybe<Kcca_Village_Mutation_Response>;
  /** insert a single row into the table: "kcca.Village" */
  insert_kcca_Village_one?: Maybe<Kcca_Village>;
  /** insert data into the table: "mlhud.KCCA" */
  insert_mlhud_KCCA?: Maybe<Mlhud_Kcca_Mutation_Response>;
  /** insert a single row into the table: "mlhud.KCCA" */
  insert_mlhud_KCCA_one?: Maybe<Mlhud_Kcca>;
  /** insert data into the table: "mlhud.Mukono" */
  insert_mlhud_Mukono?: Maybe<Mlhud_Mukono_Mutation_Response>;
  /** insert a single row into the table: "mlhud.Mukono" */
  insert_mlhud_Mukono_one?: Maybe<Mlhud_Mukono>;
  /** insert data into the table: "mlhud.Properties" */
  insert_mlhud_Properties?: Maybe<Mlhud_Properties_Mutation_Response>;
  /** insert a single row into the table: "mlhud.Properties" */
  insert_mlhud_Properties_one?: Maybe<Mlhud_Properties>;
  /** insert data into the table: "mlhud.WakisoBusiro" */
  insert_mlhud_WakisoBusiro?: Maybe<Mlhud_WakisoBusiro_Mutation_Response>;
  /** insert a single row into the table: "mlhud.WakisoBusiro" */
  insert_mlhud_WakisoBusiro_one?: Maybe<Mlhud_WakisoBusiro>;
  /** insert data into the table: "mlhud.WakisoKyadondo" */
  insert_mlhud_WakisoKyadondo?: Maybe<Mlhud_WakisoKyadondo_Mutation_Response>;
  /** insert a single row into the table: "mlhud.WakisoKyadondo" */
  insert_mlhud_WakisoKyadondo_one?: Maybe<Mlhud_WakisoKyadondo>;
  /** insert data into the table: "nwsc.Customers" */
  insert_nwsc_Customers?: Maybe<Nwsc_Customers_Mutation_Response>;
  /** insert data into the table: "nwsc.CustomersURATransactionsAndTrends" */
  insert_nwsc_CustomersURATransactionsAndTrends?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Mutation_Response>;
  /** insert a single row into the table: "nwsc.CustomersURATransactionsAndTrends" */
  insert_nwsc_CustomersURATransactionsAndTrends_one?: Maybe<Nwsc_CustomersUraTransactionsAndTrends>;
  /** insert a single row into the table: "nwsc.Customers" */
  insert_nwsc_Customers_one?: Maybe<Nwsc_Customers>;
  /** insert data into the table: "nwsc.URATransactions" */
  insert_nwsc_URATransactions?: Maybe<Nwsc_UraTransactions_Mutation_Response>;
  /** insert a single row into the table: "nwsc.URATransactions" */
  insert_nwsc_URATransactions_one?: Maybe<Nwsc_UraTransactions>;
  /** insert data into the table: "ucc.UCC" */
  insert_ucc_UCC?: Maybe<Ucc_Ucc_Mutation_Response>;
  /** insert a single row into the table: "ucc.UCC" */
  insert_ucc_UCC_one?: Maybe<Ucc_Ucc>;
  /** insert data into the table: "ura.EmploymentIncomeDetail" */
  insert_ura_EmploymentIncomeDetail?: Maybe<Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** insert a single row into the table: "ura.EmploymentIncomeDetail" */
  insert_ura_EmploymentIncomeDetail_one?: Maybe<Ura_EmploymentIncomeDetail>;
  /** insert data into the table: "ura.HoldingOfficers" */
  insert_ura_HoldingOfficers?: Maybe<Ura_HoldingOfficers_Mutation_Response>;
  /** insert a single row into the table: "ura.HoldingOfficers" */
  insert_ura_HoldingOfficers_one?: Maybe<Ura_HoldingOfficers>;
  /** insert data into the table: "ura.HoldingOwner" */
  insert_ura_HoldingOwner?: Maybe<Ura_HoldingOwner_Mutation_Response>;
  /** insert a single row into the table: "ura.HoldingOwner" */
  insert_ura_HoldingOwner_one?: Maybe<Ura_HoldingOwner>;
  /** insert data into the table: "ura.IncomeTaxHolding" */
  insert_ura_IncomeTaxHolding?: Maybe<Ura_IncomeTaxHolding_Mutation_Response>;
  /** insert a single row into the table: "ura.IncomeTaxHolding" */
  insert_ura_IncomeTaxHolding_one?: Maybe<Ura_IncomeTaxHolding>;
  /** insert data into the table: "ura.IncomeTaxSummaryIndividual" */
  insert_ura_IncomeTaxSummaryIndividual?: Maybe<Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** insert a single row into the table: "ura.IncomeTaxSummaryIndividual" */
  insert_ura_IncomeTaxSummaryIndividual_one?: Maybe<Ura_IncomeTaxSummaryIndividual>;
  /** insert data into the table: "ura.IncomeTaxSummaryNonIndividual" */
  insert_ura_IncomeTaxSummaryNonIndividual?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** insert a single row into the table: "ura.IncomeTaxSummaryNonIndividual" */
  insert_ura_IncomeTaxSummaryNonIndividual_one?: Maybe<Ura_IncomeTaxSummaryNonIndividual>;
  /** insert data into the table: "ura.IndividualMortgage" */
  insert_ura_IndividualMortgage?: Maybe<Ura_IndividualMortgage_Mutation_Response>;
  /** insert a single row into the table: "ura.IndividualMortgage" */
  insert_ura_IndividualMortgage_one?: Maybe<Ura_IndividualMortgage>;
  /** insert data into the table: "ura.RMatchDetailed904" */
  insert_ura_RMatchDetailed904?: Maybe<Ura_RMatchDetailed904_Mutation_Response>;
  /** insert a single row into the table: "ura.RMatchDetailed904" */
  insert_ura_RMatchDetailed904_one?: Maybe<Ura_RMatchDetailed904>;
  /** insert data into the table: "ura.RMatchDetailed916" */
  insert_ura_RMatchDetailed916?: Maybe<Ura_RMatchDetailed916_Mutation_Response>;
  /** insert a single row into the table: "ura.RMatchDetailed916" */
  insert_ura_RMatchDetailed916_one?: Maybe<Ura_RMatchDetailed916>;
  /** insert data into the table: "ura.RMatchDetailed946" */
  insert_ura_RMatchDetailed946?: Maybe<Ura_RMatchDetailed946_Mutation_Response>;
  /** insert a single row into the table: "ura.RMatchDetailed946" */
  insert_ura_RMatchDetailed946_one?: Maybe<Ura_RMatchDetailed946>;
  /** insert data into the table: "ura.RMatchSummary904" */
  insert_ura_RMatchSummary904?: Maybe<Ura_RMatchSummary904_Mutation_Response>;
  /** insert a single row into the table: "ura.RMatchSummary904" */
  insert_ura_RMatchSummary904_one?: Maybe<Ura_RMatchSummary904>;
  /** insert data into the table: "ura.RMatchSummary916" */
  insert_ura_RMatchSummary916?: Maybe<Ura_RMatchSummary916_Mutation_Response>;
  /** insert a single row into the table: "ura.RMatchSummary916" */
  insert_ura_RMatchSummary916_one?: Maybe<Ura_RMatchSummary916>;
  /** insert data into the table: "ura.RMatchSummary946" */
  insert_ura_RMatchSummary946?: Maybe<Ura_RMatchSummary946_Mutation_Response>;
  /** insert a single row into the table: "ura.RMatchSummary946" */
  insert_ura_RMatchSummary946_one?: Maybe<Ura_RMatchSummary946>;
  /** insert data into the table: "ura.RPT_IndividualTaxSummaryData" */
  insert_ura_RPT_IndividualTaxSummaryData?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** insert a single row into the table: "ura.RPT_IndividualTaxSummaryData" */
  insert_ura_RPT_IndividualTaxSummaryData_one?: Maybe<Ura_Rpt_IndividualTaxSummaryData>;
  /** insert data into the table: "ura.RPT_KccaCustomerRegistrationData" */
  insert_ura_RPT_KccaCustomerRegistrationData?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** insert a single row into the table: "ura.RPT_KccaCustomerRegistrationData" */
  insert_ura_RPT_KccaCustomerRegistrationData_one?: Maybe<Ura_Rpt_KccaCustomerRegistrationData>;
  /** insert data into the table: "ura.RPT_KccaPropertyRegistrationData" */
  insert_ura_RPT_KccaPropertyRegistrationData?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** insert a single row into the table: "ura.RPT_KccaPropertyRegistrationData" */
  insert_ura_RPT_KccaPropertyRegistrationData_one?: Maybe<Ura_Rpt_KccaPropertyRegistrationData>;
  /** insert data into the table: "ura.RPT_NonIndividualTaxSummaryData" */
  insert_ura_RPT_NonIndividualTaxSummaryData?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** insert a single row into the table: "ura.RPT_NonIndividualTaxSummaryData" */
  insert_ura_RPT_NonIndividualTaxSummaryData_one?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData>;
  /** insert data into the table: "ura.RPT_RegIndividual" */
  insert_ura_RPT_RegIndividual?: Maybe<Ura_Rpt_RegIndividual_Mutation_Response>;
  /** insert a single row into the table: "ura.RPT_RegIndividual" */
  insert_ura_RPT_RegIndividual_one?: Maybe<Ura_Rpt_RegIndividual>;
  /** insert data into the table: "ura.RegAssociates" */
  insert_ura_RegAssociates?: Maybe<Ura_RegAssociates_Mutation_Response>;
  /** insert a single row into the table: "ura.RegAssociates" */
  insert_ura_RegAssociates_one?: Maybe<Ura_RegAssociates>;
  /** insert data into the table: "ura.RegIndividual" */
  insert_ura_RegIndividual?: Maybe<Ura_RegIndividual_Mutation_Response>;
  /** insert a single row into the table: "ura.RegIndividual" */
  insert_ura_RegIndividual_one?: Maybe<Ura_RegIndividual>;
  /** insert data into the table: "ura.RegNonIndividual" */
  insert_ura_RegNonIndividual?: Maybe<Ura_RegNonIndividual_Mutation_Response>;
  /** insert a single row into the table: "ura.RegNonIndividual" */
  insert_ura_RegNonIndividual_one?: Maybe<Ura_RegNonIndividual>;
  /** insert data into the table: "ura.RentExpenseDetails" */
  insert_ura_RentExpenseDetails?: Maybe<Ura_RentExpenseDetails_Mutation_Response>;
  /** insert a single row into the table: "ura.RentExpenseDetails" */
  insert_ura_RentExpenseDetails_one?: Maybe<Ura_RentExpenseDetails>;
  /** insert data into the table: "ura.RentalLandLordIncome" */
  insert_ura_RentalLandLordIncome?: Maybe<Ura_RentalLandLordIncome_Mutation_Response>;
  /** insert a single row into the table: "ura.RentalLandLordIncome" */
  insert_ura_RentalLandLordIncome_one?: Maybe<Ura_RentalLandLordIncome>;
  /** insert data into the table: "ura.RentalTenantPayments" */
  insert_ura_RentalTenantPayments?: Maybe<Ura_RentalTenantPayments_Mutation_Response>;
  /** insert a single row into the table: "ura.RentalTenantPayments" */
  insert_ura_RentalTenantPayments_one?: Maybe<Ura_RentalTenantPayments>;
  /** insert data into the table: "ura.RipoIndRentalIncSummary" */
  insert_ura_RipoIndRentalIncSummary?: Maybe<Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** insert a single row into the table: "ura.RipoIndRentalIncSummary" */
  insert_ura_RipoIndRentalIncSummary_one?: Maybe<Ura_RipoIndRentalIncSummary>;
  /** insert data into the table: "ura.Town" */
  insert_ura_Town?: Maybe<Ura_Town_Mutation_Response>;
  /** insert a single row into the table: "ura.Town" */
  insert_ura_Town_one?: Maybe<Ura_Town>;
  /** insert data into the table: "ursb.CompaniesAndBusinessNamesURSB" */
  insert_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
  /** insert a single row into the table: "ursb.CompaniesAndBusinessNamesURSB" */
  insert_ursb_CompaniesAndBusinessNamesURSB_one?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb>;
  /** update data of the table: "kcca.AssociateType" */
  update_kcca_AssociateType?: Maybe<Kcca_AssociateType_Mutation_Response>;
  /** update data of the table: "kcca.County" */
  update_kcca_County?: Maybe<Kcca_County_Mutation_Response>;
  /** update data of the table: "kcca.Customer" */
  update_kcca_Customer?: Maybe<Kcca_Customer_Mutation_Response>;
  /** update data of the table: "kcca.CustomerApplicantType" */
  update_kcca_CustomerApplicantType?: Maybe<Kcca_CustomerApplicantType_Mutation_Response>;
  /** update data of the table: "kcca.CustomerBusinessAssociate" */
  update_kcca_CustomerBusinessAssociate?: Maybe<Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** update data of the table: "kcca.CustomerBusinessType" */
  update_kcca_CustomerBusinessType?: Maybe<Kcca_CustomerBusinessType_Mutation_Response>;
  /** update data of the table: "kcca.CustomerCoinDeactivateReactive" */
  update_kcca_CustomerCoinDeactivateReactive?: Maybe<Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** update data of the table: "kcca.CustomerContactPerson" */
  update_kcca_CustomerContactPerson?: Maybe<Kcca_CustomerContactPerson_Mutation_Response>;
  /** update data of the table: "kcca.CustomerDocumentItem" */
  update_kcca_CustomerDocumentItem?: Maybe<Kcca_CustomerDocumentItem_Mutation_Response>;
  /** update data of the table: "kcca.CustomerGuardian" */
  update_kcca_CustomerGuardian?: Maybe<Kcca_CustomerGuardian_Mutation_Response>;
  /** update data of the table: "kcca.CustomerReference" */
  update_kcca_CustomerReference?: Maybe<Kcca_CustomerReference_Mutation_Response>;
  /** update data of the table: "kcca.CustomerRegistrationReason" */
  update_kcca_CustomerRegistrationReason?: Maybe<Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** update data of the table: "kcca.CustomerRevenueAgent" */
  update_kcca_CustomerRevenueAgent?: Maybe<Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** update data of the table: "kcca.CustomerType" */
  update_kcca_CustomerType?: Maybe<Kcca_CustomerType_Mutation_Response>;
  /** update data of the table: "kcca.District" */
  update_kcca_District?: Maybe<Kcca_District_Mutation_Response>;
  /** update data of the table: "kcca.Division" */
  update_kcca_Division?: Maybe<Kcca_Division_Mutation_Response>;
  /** update data of the table: "kcca.Parish" */
  update_kcca_Parish?: Maybe<Kcca_Parish_Mutation_Response>;
  /** update data of the table: "kcca.Property" */
  update_kcca_Property?: Maybe<Kcca_Property_Mutation_Response>;
  /** update data of the table: "kcca.PropertyFloors" */
  update_kcca_PropertyFloors?: Maybe<Kcca_PropertyFloors_Mutation_Response>;
  /** update data of the table: "kcca.PropertyNumberBedrooms" */
  update_kcca_PropertyNumberBedrooms?: Maybe<Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** update data of the table: "kcca.PropertyRentedStatus" */
  update_kcca_PropertyRentedStatus?: Maybe<Kcca_PropertyRentedStatus_Mutation_Response>;
  /** update data of the table: "kcca.PropertySecurity" */
  update_kcca_PropertySecurity?: Maybe<Kcca_PropertySecurity_Mutation_Response>;
  /** update data of the table: "kcca.PropertySubType" */
  update_kcca_PropertySubType?: Maybe<Kcca_PropertySubType_Mutation_Response>;
  /** update data of the table: "kcca.PropertyType" */
  update_kcca_PropertyType?: Maybe<Kcca_PropertyType_Mutation_Response>;
  /** update data of the table: "kcca.RawProperties1" */
  update_kcca_RawProperties1?: Maybe<Kcca_RawProperties1_Mutation_Response>;
  /** update data of the table: "kcca.RawProperties2" */
  update_kcca_RawProperties2?: Maybe<Kcca_RawProperties2_Mutation_Response>;
  /** update data of the table: "kcca.RevenueType" */
  update_kcca_RevenueType?: Maybe<Kcca_RevenueType_Mutation_Response>;
  /** update data of the table: "kcca.SubCounty" */
  update_kcca_SubCounty?: Maybe<Kcca_SubCounty_Mutation_Response>;
  /** update data of the table: "kcca.TradingLicense" */
  update_kcca_TradingLicense?: Maybe<Kcca_TradingLicense_Mutation_Response>;
  /** update data of the table: "kcca.Village" */
  update_kcca_Village?: Maybe<Kcca_Village_Mutation_Response>;
  /** update data of the table: "mlhud.KCCA" */
  update_mlhud_KCCA?: Maybe<Mlhud_Kcca_Mutation_Response>;
  /** update data of the table: "mlhud.Mukono" */
  update_mlhud_Mukono?: Maybe<Mlhud_Mukono_Mutation_Response>;
  /** update data of the table: "mlhud.Properties" */
  update_mlhud_Properties?: Maybe<Mlhud_Properties_Mutation_Response>;
  /** update data of the table: "mlhud.WakisoBusiro" */
  update_mlhud_WakisoBusiro?: Maybe<Mlhud_WakisoBusiro_Mutation_Response>;
  /** update data of the table: "mlhud.WakisoKyadondo" */
  update_mlhud_WakisoKyadondo?: Maybe<Mlhud_WakisoKyadondo_Mutation_Response>;
  /** update data of the table: "nwsc.Customers" */
  update_nwsc_Customers?: Maybe<Nwsc_Customers_Mutation_Response>;
  /** update data of the table: "nwsc.CustomersURATransactionsAndTrends" */
  update_nwsc_CustomersURATransactionsAndTrends?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Mutation_Response>;
  /** update data of the table: "nwsc.URATransactions" */
  update_nwsc_URATransactions?: Maybe<Nwsc_UraTransactions_Mutation_Response>;
  /** update data of the table: "ucc.UCC" */
  update_ucc_UCC?: Maybe<Ucc_Ucc_Mutation_Response>;
  /** update data of the table: "ura.EmploymentIncomeDetail" */
  update_ura_EmploymentIncomeDetail?: Maybe<Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** update data of the table: "ura.HoldingOfficers" */
  update_ura_HoldingOfficers?: Maybe<Ura_HoldingOfficers_Mutation_Response>;
  /** update data of the table: "ura.HoldingOwner" */
  update_ura_HoldingOwner?: Maybe<Ura_HoldingOwner_Mutation_Response>;
  /** update data of the table: "ura.IncomeTaxHolding" */
  update_ura_IncomeTaxHolding?: Maybe<Ura_IncomeTaxHolding_Mutation_Response>;
  /** update data of the table: "ura.IncomeTaxSummaryIndividual" */
  update_ura_IncomeTaxSummaryIndividual?: Maybe<Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** update data of the table: "ura.IncomeTaxSummaryNonIndividual" */
  update_ura_IncomeTaxSummaryNonIndividual?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** update data of the table: "ura.IndividualMortgage" */
  update_ura_IndividualMortgage?: Maybe<Ura_IndividualMortgage_Mutation_Response>;
  /** update data of the table: "ura.RMatchDetailed904" */
  update_ura_RMatchDetailed904?: Maybe<Ura_RMatchDetailed904_Mutation_Response>;
  /** update data of the table: "ura.RMatchDetailed916" */
  update_ura_RMatchDetailed916?: Maybe<Ura_RMatchDetailed916_Mutation_Response>;
  /** update data of the table: "ura.RMatchDetailed946" */
  update_ura_RMatchDetailed946?: Maybe<Ura_RMatchDetailed946_Mutation_Response>;
  /** update data of the table: "ura.RMatchSummary904" */
  update_ura_RMatchSummary904?: Maybe<Ura_RMatchSummary904_Mutation_Response>;
  /** update data of the table: "ura.RMatchSummary916" */
  update_ura_RMatchSummary916?: Maybe<Ura_RMatchSummary916_Mutation_Response>;
  /** update data of the table: "ura.RMatchSummary946" */
  update_ura_RMatchSummary946?: Maybe<Ura_RMatchSummary946_Mutation_Response>;
  /** update data of the table: "ura.RPT_IndividualTaxSummaryData" */
  update_ura_RPT_IndividualTaxSummaryData?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** update data of the table: "ura.RPT_KccaCustomerRegistrationData" */
  update_ura_RPT_KccaCustomerRegistrationData?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** update data of the table: "ura.RPT_KccaPropertyRegistrationData" */
  update_ura_RPT_KccaPropertyRegistrationData?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** update data of the table: "ura.RPT_NonIndividualTaxSummaryData" */
  update_ura_RPT_NonIndividualTaxSummaryData?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** update data of the table: "ura.RPT_RegIndividual" */
  update_ura_RPT_RegIndividual?: Maybe<Ura_Rpt_RegIndividual_Mutation_Response>;
  /** update data of the table: "ura.RegAssociates" */
  update_ura_RegAssociates?: Maybe<Ura_RegAssociates_Mutation_Response>;
  /** update data of the table: "ura.RegIndividual" */
  update_ura_RegIndividual?: Maybe<Ura_RegIndividual_Mutation_Response>;
  /** update data of the table: "ura.RegNonIndividual" */
  update_ura_RegNonIndividual?: Maybe<Ura_RegNonIndividual_Mutation_Response>;
  /** update data of the table: "ura.RentExpenseDetails" */
  update_ura_RentExpenseDetails?: Maybe<Ura_RentExpenseDetails_Mutation_Response>;
  /** update data of the table: "ura.RentalLandLordIncome" */
  update_ura_RentalLandLordIncome?: Maybe<Ura_RentalLandLordIncome_Mutation_Response>;
  /** update data of the table: "ura.RentalTenantPayments" */
  update_ura_RentalTenantPayments?: Maybe<Ura_RentalTenantPayments_Mutation_Response>;
  /** update data of the table: "ura.RipoIndRentalIncSummary" */
  update_ura_RipoIndRentalIncSummary?: Maybe<Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** update data of the table: "ura.Town" */
  update_ura_Town?: Maybe<Ura_Town_Mutation_Response>;
  /** update data of the table: "ursb.CompaniesAndBusinessNamesURSB" */
  update_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_AssociateTypeArgs = {
  where: Kcca_AssociateType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CountyArgs = {
  where: Kcca_County_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerArgs = {
  where: Kcca_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerApplicantTypeArgs = {
  where: Kcca_CustomerApplicantType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerBusinessAssociateArgs = {
  where: Kcca_CustomerBusinessAssociate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerBusinessTypeArgs = {
  where: Kcca_CustomerBusinessType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerCoinDeactivateReactiveArgs = {
  where: Kcca_CustomerCoinDeactivateReactive_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerContactPersonArgs = {
  where: Kcca_CustomerContactPerson_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerDocumentItemArgs = {
  where: Kcca_CustomerDocumentItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerGuardianArgs = {
  where: Kcca_CustomerGuardian_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerReferenceArgs = {
  where: Kcca_CustomerReference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerRegistrationReasonArgs = {
  where: Kcca_CustomerRegistrationReason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerRevenueAgentArgs = {
  where: Kcca_CustomerRevenueAgent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_CustomerTypeArgs = {
  where: Kcca_CustomerType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_DistrictArgs = {
  where: Kcca_District_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_DivisionArgs = {
  where: Kcca_Division_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_ParishArgs = {
  where: Kcca_Parish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_PropertyArgs = {
  where: Kcca_Property_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_PropertyFloorsArgs = {
  where: Kcca_PropertyFloors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_PropertyNumberBedroomsArgs = {
  where: Kcca_PropertyNumberBedrooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_PropertyRentedStatusArgs = {
  where: Kcca_PropertyRentedStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_PropertySecurityArgs = {
  where: Kcca_PropertySecurity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_PropertySubTypeArgs = {
  where: Kcca_PropertySubType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_PropertyTypeArgs = {
  where: Kcca_PropertyType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_RawProperties1Args = {
  where: Kcca_RawProperties1_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_RawProperties2Args = {
  where: Kcca_RawProperties2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_RevenueTypeArgs = {
  where: Kcca_RevenueType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_SubCountyArgs = {
  where: Kcca_SubCounty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_TradingLicenseArgs = {
  where: Kcca_TradingLicense_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kcca_VillageArgs = {
  where: Kcca_Village_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Mlhud_KccaArgs = {
  where: Mlhud_Kcca_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Mlhud_MukonoArgs = {
  where: Mlhud_Mukono_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Mlhud_PropertiesArgs = {
  where: Mlhud_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Mlhud_WakisoBusiroArgs = {
  where: Mlhud_WakisoBusiro_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Mlhud_WakisoKyadondoArgs = {
  where: Mlhud_WakisoKyadondo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nwsc_CustomersArgs = {
  where: Nwsc_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nwsc_CustomersUraTransactionsAndTrendsArgs = {
  where: Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nwsc_UraTransactionsArgs = {
  where: Nwsc_UraTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ucc_UccArgs = {
  where: Ucc_Ucc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_EmploymentIncomeDetailArgs = {
  where: Ura_EmploymentIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_HoldingOfficersArgs = {
  where: Ura_HoldingOfficers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_HoldingOwnerArgs = {
  where: Ura_HoldingOwner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_IncomeTaxHoldingArgs = {
  where: Ura_IncomeTaxHolding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_IncomeTaxSummaryIndividualArgs = {
  where: Ura_IncomeTaxSummaryIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_IncomeTaxSummaryNonIndividualArgs = {
  where: Ura_IncomeTaxSummaryNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_IndividualMortgageArgs = {
  where: Ura_IndividualMortgage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RMatchDetailed904Args = {
  where: Ura_RMatchDetailed904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RMatchDetailed916Args = {
  where: Ura_RMatchDetailed916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RMatchDetailed946Args = {
  where: Ura_RMatchDetailed946_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RMatchSummary904Args = {
  where: Ura_RMatchSummary904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RMatchSummary916Args = {
  where: Ura_RMatchSummary916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RMatchSummary946Args = {
  where: Ura_RMatchSummary946_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_Rpt_IndividualTaxSummaryDataArgs = {
  where: Ura_Rpt_IndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_Rpt_KccaCustomerRegistrationDataArgs = {
  where: Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_Rpt_KccaPropertyRegistrationDataArgs = {
  where: Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_Rpt_NonIndividualTaxSummaryDataArgs = {
  where: Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_Rpt_RegIndividualArgs = {
  where: Ura_Rpt_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RegAssociatesArgs = {
  where: Ura_RegAssociates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RegIndividualArgs = {
  where: Ura_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RegNonIndividualArgs = {
  where: Ura_RegNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RentExpenseDetailsArgs = {
  where: Ura_RentExpenseDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RentalLandLordIncomeArgs = {
  where: Ura_RentalLandLordIncome_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RentalTenantPaymentsArgs = {
  where: Ura_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_RipoIndRentalIncSummaryArgs = {
  where: Ura_RipoIndRentalIncSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ura_TownArgs = {
  where: Ura_Town_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ursb_CompaniesAndBusinessNamesUrsbArgs = {
  where: Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_AssociateTypeArgs = {
  objects: Array<Kcca_AssociateType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_AssociateType_OneArgs = {
  object: Kcca_AssociateType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CountyArgs = {
  objects: Array<Kcca_County_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_County_OneArgs = {
  object: Kcca_County_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerArgs = {
  objects: Array<Kcca_Customer_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerApplicantTypeArgs = {
  objects: Array<Kcca_CustomerApplicantType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerApplicantType_OneArgs = {
  object: Kcca_CustomerApplicantType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerBusinessAssociateArgs = {
  objects: Array<Kcca_CustomerBusinessAssociate_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerBusinessAssociate_OneArgs = {
  object: Kcca_CustomerBusinessAssociate_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerBusinessTypeArgs = {
  objects: Array<Kcca_CustomerBusinessType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerBusinessType_OneArgs = {
  object: Kcca_CustomerBusinessType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerCoinDeactivateReactiveArgs = {
  objects: Array<Kcca_CustomerCoinDeactivateReactive_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerCoinDeactivateReactive_OneArgs = {
  object: Kcca_CustomerCoinDeactivateReactive_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerContactPersonArgs = {
  objects: Array<Kcca_CustomerContactPerson_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerContactPerson_OneArgs = {
  object: Kcca_CustomerContactPerson_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerDocumentItemArgs = {
  objects: Array<Kcca_CustomerDocumentItem_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerDocumentItem_OneArgs = {
  object: Kcca_CustomerDocumentItem_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerGuardianArgs = {
  objects: Array<Kcca_CustomerGuardian_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerGuardian_OneArgs = {
  object: Kcca_CustomerGuardian_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerReferenceArgs = {
  objects: Array<Kcca_CustomerReference_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerReference_OneArgs = {
  object: Kcca_CustomerReference_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerRegistrationReasonArgs = {
  objects: Array<Kcca_CustomerRegistrationReason_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerRegistrationReason_OneArgs = {
  object: Kcca_CustomerRegistrationReason_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerRevenueAgentArgs = {
  objects: Array<Kcca_CustomerRevenueAgent_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerRevenueAgent_OneArgs = {
  object: Kcca_CustomerRevenueAgent_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerTypeArgs = {
  objects: Array<Kcca_CustomerType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_CustomerType_OneArgs = {
  object: Kcca_CustomerType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_Customer_OneArgs = {
  object: Kcca_Customer_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_DistrictArgs = {
  objects: Array<Kcca_District_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_District_OneArgs = {
  object: Kcca_District_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_DivisionArgs = {
  objects: Array<Kcca_Division_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_Division_OneArgs = {
  object: Kcca_Division_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_ParishArgs = {
  objects: Array<Kcca_Parish_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_Parish_OneArgs = {
  object: Kcca_Parish_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyArgs = {
  objects: Array<Kcca_Property_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyFloorsArgs = {
  objects: Array<Kcca_PropertyFloors_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyFloors_OneArgs = {
  object: Kcca_PropertyFloors_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyNumberBedroomsArgs = {
  objects: Array<Kcca_PropertyNumberBedrooms_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyNumberBedrooms_OneArgs = {
  object: Kcca_PropertyNumberBedrooms_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyRentedStatusArgs = {
  objects: Array<Kcca_PropertyRentedStatus_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyRentedStatus_OneArgs = {
  object: Kcca_PropertyRentedStatus_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertySecurityArgs = {
  objects: Array<Kcca_PropertySecurity_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertySecurity_OneArgs = {
  object: Kcca_PropertySecurity_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertySubTypeArgs = {
  objects: Array<Kcca_PropertySubType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertySubType_OneArgs = {
  object: Kcca_PropertySubType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyTypeArgs = {
  objects: Array<Kcca_PropertyType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_PropertyType_OneArgs = {
  object: Kcca_PropertyType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_Property_OneArgs = {
  object: Kcca_Property_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_RawProperties1Args = {
  objects: Array<Kcca_RawProperties1_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_RawProperties1_OneArgs = {
  object: Kcca_RawProperties1_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_RawProperties2Args = {
  objects: Array<Kcca_RawProperties2_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_RawProperties2_OneArgs = {
  object: Kcca_RawProperties2_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_RevenueTypeArgs = {
  objects: Array<Kcca_RevenueType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_RevenueType_OneArgs = {
  object: Kcca_RevenueType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_SubCountyArgs = {
  objects: Array<Kcca_SubCounty_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_SubCounty_OneArgs = {
  object: Kcca_SubCounty_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_TradingLicenseArgs = {
  objects: Array<Kcca_TradingLicense_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_TradingLicense_OneArgs = {
  object: Kcca_TradingLicense_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_VillageArgs = {
  objects: Array<Kcca_Village_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Kcca_Village_OneArgs = {
  object: Kcca_Village_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_KccaArgs = {
  objects: Array<Mlhud_Kcca_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_Kcca_OneArgs = {
  object: Mlhud_Kcca_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_MukonoArgs = {
  objects: Array<Mlhud_Mukono_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_Mukono_OneArgs = {
  object: Mlhud_Mukono_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_PropertiesArgs = {
  objects: Array<Mlhud_Properties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_Properties_OneArgs = {
  object: Mlhud_Properties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_WakisoBusiroArgs = {
  objects: Array<Mlhud_WakisoBusiro_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_WakisoBusiro_OneArgs = {
  object: Mlhud_WakisoBusiro_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_WakisoKyadondoArgs = {
  objects: Array<Mlhud_WakisoKyadondo_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Mlhud_WakisoKyadondo_OneArgs = {
  object: Mlhud_WakisoKyadondo_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Nwsc_CustomersArgs = {
  objects: Array<Nwsc_Customers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Nwsc_CustomersUraTransactionsAndTrendsArgs = {
  objects: Array<Nwsc_CustomersUraTransactionsAndTrends_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Nwsc_CustomersUraTransactionsAndTrends_OneArgs =
  {
    object: Nwsc_CustomersUraTransactionsAndTrends_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Nwsc_Customers_OneArgs = {
  object: Nwsc_Customers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Nwsc_UraTransactionsArgs = {
  objects: Array<Nwsc_UraTransactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Nwsc_UraTransactions_OneArgs = {
  object: Nwsc_UraTransactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ucc_UccArgs = {
  objects: Array<Ucc_Ucc_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ucc_Ucc_OneArgs = {
  object: Ucc_Ucc_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_EmploymentIncomeDetailArgs = {
  objects: Array<Ura_EmploymentIncomeDetail_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_EmploymentIncomeDetail_OneArgs = {
  object: Ura_EmploymentIncomeDetail_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_HoldingOfficersArgs = {
  objects: Array<Ura_HoldingOfficers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_HoldingOfficers_OneArgs = {
  object: Ura_HoldingOfficers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_HoldingOwnerArgs = {
  objects: Array<Ura_HoldingOwner_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_HoldingOwner_OneArgs = {
  object: Ura_HoldingOwner_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IncomeTaxHoldingArgs = {
  objects: Array<Ura_IncomeTaxHolding_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IncomeTaxHolding_OneArgs = {
  object: Ura_IncomeTaxHolding_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IncomeTaxSummaryIndividualArgs = {
  objects: Array<Ura_IncomeTaxSummaryIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IncomeTaxSummaryIndividual_OneArgs = {
  object: Ura_IncomeTaxSummaryIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IncomeTaxSummaryNonIndividualArgs = {
  objects: Array<Ura_IncomeTaxSummaryNonIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IncomeTaxSummaryNonIndividual_OneArgs = {
  object: Ura_IncomeTaxSummaryNonIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IndividualMortgageArgs = {
  objects: Array<Ura_IndividualMortgage_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_IndividualMortgage_OneArgs = {
  object: Ura_IndividualMortgage_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchDetailed904Args = {
  objects: Array<Ura_RMatchDetailed904_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchDetailed904_OneArgs = {
  object: Ura_RMatchDetailed904_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchDetailed916Args = {
  objects: Array<Ura_RMatchDetailed916_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchDetailed916_OneArgs = {
  object: Ura_RMatchDetailed916_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchDetailed946Args = {
  objects: Array<Ura_RMatchDetailed946_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchDetailed946_OneArgs = {
  object: Ura_RMatchDetailed946_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchSummary904Args = {
  objects: Array<Ura_RMatchSummary904_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchSummary904_OneArgs = {
  object: Ura_RMatchSummary904_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchSummary916Args = {
  objects: Array<Ura_RMatchSummary916_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchSummary916_OneArgs = {
  object: Ura_RMatchSummary916_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchSummary946Args = {
  objects: Array<Ura_RMatchSummary946_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RMatchSummary946_OneArgs = {
  object: Ura_RMatchSummary946_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_IndividualTaxSummaryDataArgs = {
  objects: Array<Ura_Rpt_IndividualTaxSummaryData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_IndividualTaxSummaryData_OneArgs = {
  object: Ura_Rpt_IndividualTaxSummaryData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_KccaCustomerRegistrationDataArgs = {
  objects: Array<Ura_Rpt_KccaCustomerRegistrationData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_KccaCustomerRegistrationData_OneArgs = {
  object: Ura_Rpt_KccaCustomerRegistrationData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_KccaPropertyRegistrationDataArgs = {
  objects: Array<Ura_Rpt_KccaPropertyRegistrationData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_KccaPropertyRegistrationData_OneArgs = {
  object: Ura_Rpt_KccaPropertyRegistrationData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_NonIndividualTaxSummaryDataArgs = {
  objects: Array<Ura_Rpt_NonIndividualTaxSummaryData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_NonIndividualTaxSummaryData_OneArgs = {
  object: Ura_Rpt_NonIndividualTaxSummaryData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_RegIndividualArgs = {
  objects: Array<Ura_Rpt_RegIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Rpt_RegIndividual_OneArgs = {
  object: Ura_Rpt_RegIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RegAssociatesArgs = {
  objects: Array<Ura_RegAssociates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RegAssociates_OneArgs = {
  object: Ura_RegAssociates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RegIndividualArgs = {
  objects: Array<Ura_RegIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RegIndividual_OneArgs = {
  object: Ura_RegIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RegNonIndividualArgs = {
  objects: Array<Ura_RegNonIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RegNonIndividual_OneArgs = {
  object: Ura_RegNonIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RentExpenseDetailsArgs = {
  objects: Array<Ura_RentExpenseDetails_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RentExpenseDetails_OneArgs = {
  object: Ura_RentExpenseDetails_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RentalLandLordIncomeArgs = {
  objects: Array<Ura_RentalLandLordIncome_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RentalLandLordIncome_OneArgs = {
  object: Ura_RentalLandLordIncome_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RentalTenantPaymentsArgs = {
  objects: Array<Ura_RentalTenantPayments_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RentalTenantPayments_OneArgs = {
  object: Ura_RentalTenantPayments_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RipoIndRentalIncSummaryArgs = {
  objects: Array<Ura_RipoIndRentalIncSummary_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_RipoIndRentalIncSummary_OneArgs = {
  object: Ura_RipoIndRentalIncSummary_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ura_TownArgs = {
  objects: Array<Ura_Town_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ura_Town_OneArgs = {
  object: Ura_Town_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ursb_CompaniesAndBusinessNamesUrsbArgs = {
  objects: Array<Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ursb_CompaniesAndBusinessNamesUrsb_OneArgs = {
  object: Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_AssociateTypeArgs = {
  _inc?: Maybe<Kcca_AssociateType_Inc_Input>;
  _set?: Maybe<Kcca_AssociateType_Set_Input>;
  where: Kcca_AssociateType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CountyArgs = {
  _inc?: Maybe<Kcca_County_Inc_Input>;
  _set?: Maybe<Kcca_County_Set_Input>;
  where: Kcca_County_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerArgs = {
  _inc?: Maybe<Kcca_Customer_Inc_Input>;
  _set?: Maybe<Kcca_Customer_Set_Input>;
  where: Kcca_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerApplicantTypeArgs = {
  _inc?: Maybe<Kcca_CustomerApplicantType_Inc_Input>;
  _set?: Maybe<Kcca_CustomerApplicantType_Set_Input>;
  where: Kcca_CustomerApplicantType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerBusinessAssociateArgs = {
  _inc?: Maybe<Kcca_CustomerBusinessAssociate_Inc_Input>;
  _set?: Maybe<Kcca_CustomerBusinessAssociate_Set_Input>;
  where: Kcca_CustomerBusinessAssociate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerBusinessTypeArgs = {
  _inc?: Maybe<Kcca_CustomerBusinessType_Inc_Input>;
  _set?: Maybe<Kcca_CustomerBusinessType_Set_Input>;
  where: Kcca_CustomerBusinessType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerCoinDeactivateReactiveArgs = {
  _inc?: Maybe<Kcca_CustomerCoinDeactivateReactive_Inc_Input>;
  _set?: Maybe<Kcca_CustomerCoinDeactivateReactive_Set_Input>;
  where: Kcca_CustomerCoinDeactivateReactive_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerContactPersonArgs = {
  _inc?: Maybe<Kcca_CustomerContactPerson_Inc_Input>;
  _set?: Maybe<Kcca_CustomerContactPerson_Set_Input>;
  where: Kcca_CustomerContactPerson_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerDocumentItemArgs = {
  _inc?: Maybe<Kcca_CustomerDocumentItem_Inc_Input>;
  _set?: Maybe<Kcca_CustomerDocumentItem_Set_Input>;
  where: Kcca_CustomerDocumentItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerGuardianArgs = {
  _inc?: Maybe<Kcca_CustomerGuardian_Inc_Input>;
  _set?: Maybe<Kcca_CustomerGuardian_Set_Input>;
  where: Kcca_CustomerGuardian_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerReferenceArgs = {
  _inc?: Maybe<Kcca_CustomerReference_Inc_Input>;
  _set?: Maybe<Kcca_CustomerReference_Set_Input>;
  where: Kcca_CustomerReference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerRegistrationReasonArgs = {
  _inc?: Maybe<Kcca_CustomerRegistrationReason_Inc_Input>;
  _set?: Maybe<Kcca_CustomerRegistrationReason_Set_Input>;
  where: Kcca_CustomerRegistrationReason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerRevenueAgentArgs = {
  _inc?: Maybe<Kcca_CustomerRevenueAgent_Inc_Input>;
  _set?: Maybe<Kcca_CustomerRevenueAgent_Set_Input>;
  where: Kcca_CustomerRevenueAgent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_CustomerTypeArgs = {
  _inc?: Maybe<Kcca_CustomerType_Inc_Input>;
  _set?: Maybe<Kcca_CustomerType_Set_Input>;
  where: Kcca_CustomerType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_DistrictArgs = {
  _inc?: Maybe<Kcca_District_Inc_Input>;
  _set?: Maybe<Kcca_District_Set_Input>;
  where: Kcca_District_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_DivisionArgs = {
  _inc?: Maybe<Kcca_Division_Inc_Input>;
  _set?: Maybe<Kcca_Division_Set_Input>;
  where: Kcca_Division_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_ParishArgs = {
  _inc?: Maybe<Kcca_Parish_Inc_Input>;
  _set?: Maybe<Kcca_Parish_Set_Input>;
  where: Kcca_Parish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_PropertyArgs = {
  _inc?: Maybe<Kcca_Property_Inc_Input>;
  _set?: Maybe<Kcca_Property_Set_Input>;
  where: Kcca_Property_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_PropertyFloorsArgs = {
  _inc?: Maybe<Kcca_PropertyFloors_Inc_Input>;
  _set?: Maybe<Kcca_PropertyFloors_Set_Input>;
  where: Kcca_PropertyFloors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_PropertyNumberBedroomsArgs = {
  _inc?: Maybe<Kcca_PropertyNumberBedrooms_Inc_Input>;
  _set?: Maybe<Kcca_PropertyNumberBedrooms_Set_Input>;
  where: Kcca_PropertyNumberBedrooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_PropertyRentedStatusArgs = {
  _inc?: Maybe<Kcca_PropertyRentedStatus_Inc_Input>;
  _set?: Maybe<Kcca_PropertyRentedStatus_Set_Input>;
  where: Kcca_PropertyRentedStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_PropertySecurityArgs = {
  _inc?: Maybe<Kcca_PropertySecurity_Inc_Input>;
  _set?: Maybe<Kcca_PropertySecurity_Set_Input>;
  where: Kcca_PropertySecurity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_PropertySubTypeArgs = {
  _inc?: Maybe<Kcca_PropertySubType_Inc_Input>;
  _set?: Maybe<Kcca_PropertySubType_Set_Input>;
  where: Kcca_PropertySubType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_PropertyTypeArgs = {
  _inc?: Maybe<Kcca_PropertyType_Inc_Input>;
  _set?: Maybe<Kcca_PropertyType_Set_Input>;
  where: Kcca_PropertyType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_RawProperties1Args = {
  _inc?: Maybe<Kcca_RawProperties1_Inc_Input>;
  _set?: Maybe<Kcca_RawProperties1_Set_Input>;
  where: Kcca_RawProperties1_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_RawProperties2Args = {
  _inc?: Maybe<Kcca_RawProperties2_Inc_Input>;
  _set?: Maybe<Kcca_RawProperties2_Set_Input>;
  where: Kcca_RawProperties2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_RevenueTypeArgs = {
  _inc?: Maybe<Kcca_RevenueType_Inc_Input>;
  _set?: Maybe<Kcca_RevenueType_Set_Input>;
  where: Kcca_RevenueType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_SubCountyArgs = {
  _inc?: Maybe<Kcca_SubCounty_Inc_Input>;
  _set?: Maybe<Kcca_SubCounty_Set_Input>;
  where: Kcca_SubCounty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_TradingLicenseArgs = {
  _inc?: Maybe<Kcca_TradingLicense_Inc_Input>;
  _set?: Maybe<Kcca_TradingLicense_Set_Input>;
  where: Kcca_TradingLicense_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kcca_VillageArgs = {
  _inc?: Maybe<Kcca_Village_Inc_Input>;
  _set?: Maybe<Kcca_Village_Set_Input>;
  where: Kcca_Village_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Mlhud_KccaArgs = {
  _inc?: Maybe<Mlhud_Kcca_Inc_Input>;
  _set?: Maybe<Mlhud_Kcca_Set_Input>;
  where: Mlhud_Kcca_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Mlhud_MukonoArgs = {
  _inc?: Maybe<Mlhud_Mukono_Inc_Input>;
  _set?: Maybe<Mlhud_Mukono_Set_Input>;
  where: Mlhud_Mukono_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Mlhud_PropertiesArgs = {
  _inc?: Maybe<Mlhud_Properties_Inc_Input>;
  _set?: Maybe<Mlhud_Properties_Set_Input>;
  where: Mlhud_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Mlhud_WakisoBusiroArgs = {
  _inc?: Maybe<Mlhud_WakisoBusiro_Inc_Input>;
  _set?: Maybe<Mlhud_WakisoBusiro_Set_Input>;
  where: Mlhud_WakisoBusiro_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Mlhud_WakisoKyadondoArgs = {
  _inc?: Maybe<Mlhud_WakisoKyadondo_Inc_Input>;
  _set?: Maybe<Mlhud_WakisoKyadondo_Set_Input>;
  where: Mlhud_WakisoKyadondo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nwsc_CustomersArgs = {
  _inc?: Maybe<Nwsc_Customers_Inc_Input>;
  _set?: Maybe<Nwsc_Customers_Set_Input>;
  where: Nwsc_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nwsc_CustomersUraTransactionsAndTrendsArgs = {
  _inc?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Inc_Input>;
  _set?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Set_Input>;
  where: Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nwsc_UraTransactionsArgs = {
  _inc?: Maybe<Nwsc_UraTransactions_Inc_Input>;
  _set?: Maybe<Nwsc_UraTransactions_Set_Input>;
  where: Nwsc_UraTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ucc_UccArgs = {
  _inc?: Maybe<Ucc_Ucc_Inc_Input>;
  _set?: Maybe<Ucc_Ucc_Set_Input>;
  where: Ucc_Ucc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_EmploymentIncomeDetailArgs = {
  _inc?: Maybe<Ura_EmploymentIncomeDetail_Inc_Input>;
  _set?: Maybe<Ura_EmploymentIncomeDetail_Set_Input>;
  where: Ura_EmploymentIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_HoldingOfficersArgs = {
  _inc?: Maybe<Ura_HoldingOfficers_Inc_Input>;
  _set?: Maybe<Ura_HoldingOfficers_Set_Input>;
  where: Ura_HoldingOfficers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_HoldingOwnerArgs = {
  _inc?: Maybe<Ura_HoldingOwner_Inc_Input>;
  _set?: Maybe<Ura_HoldingOwner_Set_Input>;
  where: Ura_HoldingOwner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_IncomeTaxHoldingArgs = {
  _inc?: Maybe<Ura_IncomeTaxHolding_Inc_Input>;
  _set?: Maybe<Ura_IncomeTaxHolding_Set_Input>;
  where: Ura_IncomeTaxHolding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_IncomeTaxSummaryIndividualArgs = {
  _inc?: Maybe<Ura_IncomeTaxSummaryIndividual_Inc_Input>;
  _set?: Maybe<Ura_IncomeTaxSummaryIndividual_Set_Input>;
  where: Ura_IncomeTaxSummaryIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_IncomeTaxSummaryNonIndividualArgs = {
  _inc?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Inc_Input>;
  _set?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Set_Input>;
  where: Ura_IncomeTaxSummaryNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_IndividualMortgageArgs = {
  _inc?: Maybe<Ura_IndividualMortgage_Inc_Input>;
  _set?: Maybe<Ura_IndividualMortgage_Set_Input>;
  where: Ura_IndividualMortgage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RMatchDetailed904Args = {
  _inc?: Maybe<Ura_RMatchDetailed904_Inc_Input>;
  _set?: Maybe<Ura_RMatchDetailed904_Set_Input>;
  where: Ura_RMatchDetailed904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RMatchDetailed916Args = {
  _inc?: Maybe<Ura_RMatchDetailed916_Inc_Input>;
  _set?: Maybe<Ura_RMatchDetailed916_Set_Input>;
  where: Ura_RMatchDetailed916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RMatchDetailed946Args = {
  _inc?: Maybe<Ura_RMatchDetailed946_Inc_Input>;
  _set?: Maybe<Ura_RMatchDetailed946_Set_Input>;
  where: Ura_RMatchDetailed946_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RMatchSummary904Args = {
  _inc?: Maybe<Ura_RMatchSummary904_Inc_Input>;
  _set?: Maybe<Ura_RMatchSummary904_Set_Input>;
  where: Ura_RMatchSummary904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RMatchSummary916Args = {
  _inc?: Maybe<Ura_RMatchSummary916_Inc_Input>;
  _set?: Maybe<Ura_RMatchSummary916_Set_Input>;
  where: Ura_RMatchSummary916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RMatchSummary946Args = {
  _inc?: Maybe<Ura_RMatchSummary946_Inc_Input>;
  _set?: Maybe<Ura_RMatchSummary946_Set_Input>;
  where: Ura_RMatchSummary946_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_Rpt_IndividualTaxSummaryDataArgs = {
  _inc?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Inc_Input>;
  _set?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Set_Input>;
  where: Ura_Rpt_IndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_Rpt_KccaCustomerRegistrationDataArgs = {
  _set?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Set_Input>;
  where: Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_Rpt_KccaPropertyRegistrationDataArgs = {
  _set?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Set_Input>;
  where: Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_Rpt_NonIndividualTaxSummaryDataArgs = {
  _inc?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Inc_Input>;
  _set?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Set_Input>;
  where: Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_Rpt_RegIndividualArgs = {
  _inc?: Maybe<Ura_Rpt_RegIndividual_Inc_Input>;
  _set?: Maybe<Ura_Rpt_RegIndividual_Set_Input>;
  where: Ura_Rpt_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RegAssociatesArgs = {
  _inc?: Maybe<Ura_RegAssociates_Inc_Input>;
  _set?: Maybe<Ura_RegAssociates_Set_Input>;
  where: Ura_RegAssociates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RegIndividualArgs = {
  _inc?: Maybe<Ura_RegIndividual_Inc_Input>;
  _set?: Maybe<Ura_RegIndividual_Set_Input>;
  where: Ura_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RegNonIndividualArgs = {
  _inc?: Maybe<Ura_RegNonIndividual_Inc_Input>;
  _set?: Maybe<Ura_RegNonIndividual_Set_Input>;
  where: Ura_RegNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RentExpenseDetailsArgs = {
  _inc?: Maybe<Ura_RentExpenseDetails_Inc_Input>;
  _set?: Maybe<Ura_RentExpenseDetails_Set_Input>;
  where: Ura_RentExpenseDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RentalLandLordIncomeArgs = {
  _inc?: Maybe<Ura_RentalLandLordIncome_Inc_Input>;
  _set?: Maybe<Ura_RentalLandLordIncome_Set_Input>;
  where: Ura_RentalLandLordIncome_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RentalTenantPaymentsArgs = {
  _inc?: Maybe<Ura_RentalTenantPayments_Inc_Input>;
  _set?: Maybe<Ura_RentalTenantPayments_Set_Input>;
  where: Ura_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_RipoIndRentalIncSummaryArgs = {
  _inc?: Maybe<Ura_RipoIndRentalIncSummary_Inc_Input>;
  _set?: Maybe<Ura_RipoIndRentalIncSummary_Set_Input>;
  where: Ura_RipoIndRentalIncSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ura_TownArgs = {
  _inc?: Maybe<Ura_Town_Inc_Input>;
  _set?: Maybe<Ura_Town_Set_Input>;
  where: Ura_Town_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ursb_CompaniesAndBusinessNamesUrsbArgs = {
  _inc?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Inc_Input>;
  _set?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Set_Input>;
  where: Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp;
};

/** columns and relationships of "nwsc.Customers" */
export type Nwsc_Customers = {
  __typename?: "nwsc_Customers";
  Address_Cons?: Maybe<Scalars["String"]>;
  Address_Pay?: Maybe<Scalars["String"]>;
  Address_Trans?: Maybe<Scalars["String"]>;
  Area_Pay?: Maybe<Scalars["String"]>;
  Area_Trans?: Maybe<Scalars["String"]>;
  CustomerName_Cons?: Maybe<Scalars["String"]>;
  CustomerName_Pay?: Maybe<Scalars["String"]>;
  CustomerName_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo3_Pay?: Maybe<Scalars["String"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Latitude_Cons?: Maybe<Scalars["String"]>;
  Latitude_Pay?: Maybe<Scalars["String"]>;
  Longitude_Cons?: Maybe<Scalars["String"]>;
  Longitude_Pay?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends";
  Apr_17?: Maybe<Scalars["float8"]>;
  Apr_18?: Maybe<Scalars["float8"]>;
  Apr_19?: Maybe<Scalars["float8"]>;
  Apr_20?: Maybe<Scalars["String"]>;
  Aug_17?: Maybe<Scalars["float8"]>;
  Aug_18?: Maybe<Scalars["float8"]>;
  Aug_19?: Maybe<Scalars["float8"]>;
  Aug_20?: Maybe<Scalars["float8"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Dec_17?: Maybe<Scalars["float8"]>;
  Dec_18?: Maybe<Scalars["float8"]>;
  Dec_19?: Maybe<Scalars["String"]>;
  Feb_17?: Maybe<Scalars["float8"]>;
  Feb_18?: Maybe<Scalars["float8"]>;
  Feb_19?: Maybe<Scalars["float8"]>;
  Feb_20?: Maybe<Scalars["String"]>;
  Jan_17?: Maybe<Scalars["float8"]>;
  Jan_18?: Maybe<Scalars["float8"]>;
  Jan_19?: Maybe<Scalars["float8"]>;
  Jan_20?: Maybe<Scalars["String"]>;
  Jul_17?: Maybe<Scalars["float8"]>;
  Jul_18?: Maybe<Scalars["float8"]>;
  Jul_19?: Maybe<Scalars["float8"]>;
  Jul_20?: Maybe<Scalars["float8"]>;
  Jun_17?: Maybe<Scalars["float8"]>;
  Jun_18?: Maybe<Scalars["float8"]>;
  Jun_19?: Maybe<Scalars["float8"]>;
  Jun_20?: Maybe<Scalars["float8"]>;
  Mar_17?: Maybe<Scalars["float8"]>;
  Mar_18?: Maybe<Scalars["float8"]>;
  Mar_19?: Maybe<Scalars["float8"]>;
  Mar_20?: Maybe<Scalars["String"]>;
  May_17?: Maybe<Scalars["float8"]>;
  May_18?: Maybe<Scalars["float8"]>;
  May_19?: Maybe<Scalars["float8"]>;
  May_20?: Maybe<Scalars["String"]>;
  Nov_17?: Maybe<Scalars["float8"]>;
  Nov_18?: Maybe<Scalars["float8"]>;
  Nov_19?: Maybe<Scalars["float8"]>;
  Oct_17?: Maybe<Scalars["float8"]>;
  Oct_18?: Maybe<Scalars["float8"]>;
  Oct_19?: Maybe<Scalars["float8"]>;
  Oct_20?: Maybe<Scalars["float8"]>;
  Sep_17?: Maybe<Scalars["float8"]>;
  Sep_18?: Maybe<Scalars["float8"]>;
  Sep_19?: Maybe<Scalars["float8"]>;
  Sep_20?: Maybe<Scalars["float8"]>;
  Type?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Aggregate = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_aggregate";
  aggregate?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Aggregate_Fields>;
  nodes: Array<Nwsc_CustomersUraTransactionsAndTrends>;
};

/** aggregate fields of "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Aggregate_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_aggregate_fields";
  avg?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Max_Fields>;
  min?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Min_Fields>;
  stddev?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Stddev_Fields>;
  stddev_pop?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Stddev_Samp_Fields>;
  sum?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Sum_Fields>;
  var_pop?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Var_Pop_Fields>;
  var_samp?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Var_Samp_Fields>;
  variance?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Variance_Fields>;
};

/** aggregate fields of "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Nwsc_CustomersUraTransactionsAndTrends_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Aggregate_Order_By = {
  avg?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Max_Order_By>;
  min?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Min_Order_By>;
  stddev?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Stddev_Order_By>;
  stddev_pop?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Stddev_Samp_Order_By>;
  sum?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Sum_Order_By>;
  var_pop?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Var_Pop_Order_By>;
  var_samp?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Var_Samp_Order_By>;
  variance?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Arr_Rel_Insert_Input = {
  data: Array<Nwsc_CustomersUraTransactionsAndTrends_Insert_Input>;
};

/** aggregate avg on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Avg_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_avg_fields";
  Apr_17?: Maybe<Scalars["Float"]>;
  Apr_18?: Maybe<Scalars["Float"]>;
  Apr_19?: Maybe<Scalars["Float"]>;
  Aug_17?: Maybe<Scalars["Float"]>;
  Aug_18?: Maybe<Scalars["Float"]>;
  Aug_19?: Maybe<Scalars["Float"]>;
  Aug_20?: Maybe<Scalars["Float"]>;
  CustomerReference?: Maybe<Scalars["Float"]>;
  Dec_17?: Maybe<Scalars["Float"]>;
  Dec_18?: Maybe<Scalars["Float"]>;
  Feb_17?: Maybe<Scalars["Float"]>;
  Feb_18?: Maybe<Scalars["Float"]>;
  Feb_19?: Maybe<Scalars["Float"]>;
  Jan_17?: Maybe<Scalars["Float"]>;
  Jan_18?: Maybe<Scalars["Float"]>;
  Jan_19?: Maybe<Scalars["Float"]>;
  Jul_17?: Maybe<Scalars["Float"]>;
  Jul_18?: Maybe<Scalars["Float"]>;
  Jul_19?: Maybe<Scalars["Float"]>;
  Jul_20?: Maybe<Scalars["Float"]>;
  Jun_17?: Maybe<Scalars["Float"]>;
  Jun_18?: Maybe<Scalars["Float"]>;
  Jun_19?: Maybe<Scalars["Float"]>;
  Jun_20?: Maybe<Scalars["Float"]>;
  Mar_17?: Maybe<Scalars["Float"]>;
  Mar_18?: Maybe<Scalars["Float"]>;
  Mar_19?: Maybe<Scalars["Float"]>;
  May_17?: Maybe<Scalars["Float"]>;
  May_18?: Maybe<Scalars["Float"]>;
  May_19?: Maybe<Scalars["Float"]>;
  Nov_17?: Maybe<Scalars["Float"]>;
  Nov_18?: Maybe<Scalars["Float"]>;
  Nov_19?: Maybe<Scalars["Float"]>;
  Oct_17?: Maybe<Scalars["Float"]>;
  Oct_18?: Maybe<Scalars["Float"]>;
  Oct_19?: Maybe<Scalars["Float"]>;
  Oct_20?: Maybe<Scalars["Float"]>;
  Sep_17?: Maybe<Scalars["Float"]>;
  Sep_18?: Maybe<Scalars["Float"]>;
  Sep_19?: Maybe<Scalars["Float"]>;
  Sep_20?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Avg_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nwsc.CustomersURATransactionsAndTrends". All fields are combined with a logical 'AND'. */
export type Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp = {
  Apr_17?: Maybe<Float8_Comparison_Exp>;
  Apr_18?: Maybe<Float8_Comparison_Exp>;
  Apr_19?: Maybe<Float8_Comparison_Exp>;
  Apr_20?: Maybe<String_Comparison_Exp>;
  Aug_17?: Maybe<Float8_Comparison_Exp>;
  Aug_18?: Maybe<Float8_Comparison_Exp>;
  Aug_19?: Maybe<Float8_Comparison_Exp>;
  Aug_20?: Maybe<Float8_Comparison_Exp>;
  CustomerReference?: Maybe<Bigint_Comparison_Exp>;
  Dec_17?: Maybe<Float8_Comparison_Exp>;
  Dec_18?: Maybe<Float8_Comparison_Exp>;
  Dec_19?: Maybe<String_Comparison_Exp>;
  Feb_17?: Maybe<Float8_Comparison_Exp>;
  Feb_18?: Maybe<Float8_Comparison_Exp>;
  Feb_19?: Maybe<Float8_Comparison_Exp>;
  Feb_20?: Maybe<String_Comparison_Exp>;
  Jan_17?: Maybe<Float8_Comparison_Exp>;
  Jan_18?: Maybe<Float8_Comparison_Exp>;
  Jan_19?: Maybe<Float8_Comparison_Exp>;
  Jan_20?: Maybe<String_Comparison_Exp>;
  Jul_17?: Maybe<Float8_Comparison_Exp>;
  Jul_18?: Maybe<Float8_Comparison_Exp>;
  Jul_19?: Maybe<Float8_Comparison_Exp>;
  Jul_20?: Maybe<Float8_Comparison_Exp>;
  Jun_17?: Maybe<Float8_Comparison_Exp>;
  Jun_18?: Maybe<Float8_Comparison_Exp>;
  Jun_19?: Maybe<Float8_Comparison_Exp>;
  Jun_20?: Maybe<Float8_Comparison_Exp>;
  Mar_17?: Maybe<Float8_Comparison_Exp>;
  Mar_18?: Maybe<Float8_Comparison_Exp>;
  Mar_19?: Maybe<Float8_Comparison_Exp>;
  Mar_20?: Maybe<String_Comparison_Exp>;
  May_17?: Maybe<Float8_Comparison_Exp>;
  May_18?: Maybe<Float8_Comparison_Exp>;
  May_19?: Maybe<Float8_Comparison_Exp>;
  May_20?: Maybe<String_Comparison_Exp>;
  Nov_17?: Maybe<Float8_Comparison_Exp>;
  Nov_18?: Maybe<Float8_Comparison_Exp>;
  Nov_19?: Maybe<Float8_Comparison_Exp>;
  Oct_17?: Maybe<Float8_Comparison_Exp>;
  Oct_18?: Maybe<Float8_Comparison_Exp>;
  Oct_19?: Maybe<Float8_Comparison_Exp>;
  Oct_20?: Maybe<Float8_Comparison_Exp>;
  Sep_17?: Maybe<Float8_Comparison_Exp>;
  Sep_18?: Maybe<Float8_Comparison_Exp>;
  Sep_19?: Maybe<Float8_Comparison_Exp>;
  Sep_20?: Maybe<Float8_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp>>>;
  _not?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Inc_Input = {
  Apr_17?: Maybe<Scalars["float8"]>;
  Apr_18?: Maybe<Scalars["float8"]>;
  Apr_19?: Maybe<Scalars["float8"]>;
  Aug_17?: Maybe<Scalars["float8"]>;
  Aug_18?: Maybe<Scalars["float8"]>;
  Aug_19?: Maybe<Scalars["float8"]>;
  Aug_20?: Maybe<Scalars["float8"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Dec_17?: Maybe<Scalars["float8"]>;
  Dec_18?: Maybe<Scalars["float8"]>;
  Feb_17?: Maybe<Scalars["float8"]>;
  Feb_18?: Maybe<Scalars["float8"]>;
  Feb_19?: Maybe<Scalars["float8"]>;
  Jan_17?: Maybe<Scalars["float8"]>;
  Jan_18?: Maybe<Scalars["float8"]>;
  Jan_19?: Maybe<Scalars["float8"]>;
  Jul_17?: Maybe<Scalars["float8"]>;
  Jul_18?: Maybe<Scalars["float8"]>;
  Jul_19?: Maybe<Scalars["float8"]>;
  Jul_20?: Maybe<Scalars["float8"]>;
  Jun_17?: Maybe<Scalars["float8"]>;
  Jun_18?: Maybe<Scalars["float8"]>;
  Jun_19?: Maybe<Scalars["float8"]>;
  Jun_20?: Maybe<Scalars["float8"]>;
  Mar_17?: Maybe<Scalars["float8"]>;
  Mar_18?: Maybe<Scalars["float8"]>;
  Mar_19?: Maybe<Scalars["float8"]>;
  May_17?: Maybe<Scalars["float8"]>;
  May_18?: Maybe<Scalars["float8"]>;
  May_19?: Maybe<Scalars["float8"]>;
  Nov_17?: Maybe<Scalars["float8"]>;
  Nov_18?: Maybe<Scalars["float8"]>;
  Nov_19?: Maybe<Scalars["float8"]>;
  Oct_17?: Maybe<Scalars["float8"]>;
  Oct_18?: Maybe<Scalars["float8"]>;
  Oct_19?: Maybe<Scalars["float8"]>;
  Oct_20?: Maybe<Scalars["float8"]>;
  Sep_17?: Maybe<Scalars["float8"]>;
  Sep_18?: Maybe<Scalars["float8"]>;
  Sep_19?: Maybe<Scalars["float8"]>;
  Sep_20?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Insert_Input = {
  Apr_17?: Maybe<Scalars["float8"]>;
  Apr_18?: Maybe<Scalars["float8"]>;
  Apr_19?: Maybe<Scalars["float8"]>;
  Apr_20?: Maybe<Scalars["String"]>;
  Aug_17?: Maybe<Scalars["float8"]>;
  Aug_18?: Maybe<Scalars["float8"]>;
  Aug_19?: Maybe<Scalars["float8"]>;
  Aug_20?: Maybe<Scalars["float8"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Dec_17?: Maybe<Scalars["float8"]>;
  Dec_18?: Maybe<Scalars["float8"]>;
  Dec_19?: Maybe<Scalars["String"]>;
  Feb_17?: Maybe<Scalars["float8"]>;
  Feb_18?: Maybe<Scalars["float8"]>;
  Feb_19?: Maybe<Scalars["float8"]>;
  Feb_20?: Maybe<Scalars["String"]>;
  Jan_17?: Maybe<Scalars["float8"]>;
  Jan_18?: Maybe<Scalars["float8"]>;
  Jan_19?: Maybe<Scalars["float8"]>;
  Jan_20?: Maybe<Scalars["String"]>;
  Jul_17?: Maybe<Scalars["float8"]>;
  Jul_18?: Maybe<Scalars["float8"]>;
  Jul_19?: Maybe<Scalars["float8"]>;
  Jul_20?: Maybe<Scalars["float8"]>;
  Jun_17?: Maybe<Scalars["float8"]>;
  Jun_18?: Maybe<Scalars["float8"]>;
  Jun_19?: Maybe<Scalars["float8"]>;
  Jun_20?: Maybe<Scalars["float8"]>;
  Mar_17?: Maybe<Scalars["float8"]>;
  Mar_18?: Maybe<Scalars["float8"]>;
  Mar_19?: Maybe<Scalars["float8"]>;
  Mar_20?: Maybe<Scalars["String"]>;
  May_17?: Maybe<Scalars["float8"]>;
  May_18?: Maybe<Scalars["float8"]>;
  May_19?: Maybe<Scalars["float8"]>;
  May_20?: Maybe<Scalars["String"]>;
  Nov_17?: Maybe<Scalars["float8"]>;
  Nov_18?: Maybe<Scalars["float8"]>;
  Nov_19?: Maybe<Scalars["float8"]>;
  Oct_17?: Maybe<Scalars["float8"]>;
  Oct_18?: Maybe<Scalars["float8"]>;
  Oct_19?: Maybe<Scalars["float8"]>;
  Oct_20?: Maybe<Scalars["float8"]>;
  Sep_17?: Maybe<Scalars["float8"]>;
  Sep_18?: Maybe<Scalars["float8"]>;
  Sep_19?: Maybe<Scalars["float8"]>;
  Sep_20?: Maybe<Scalars["float8"]>;
  Type?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Max_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_max_fields";
  Apr_17?: Maybe<Scalars["float8"]>;
  Apr_18?: Maybe<Scalars["float8"]>;
  Apr_19?: Maybe<Scalars["float8"]>;
  Apr_20?: Maybe<Scalars["String"]>;
  Aug_17?: Maybe<Scalars["float8"]>;
  Aug_18?: Maybe<Scalars["float8"]>;
  Aug_19?: Maybe<Scalars["float8"]>;
  Aug_20?: Maybe<Scalars["float8"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Dec_17?: Maybe<Scalars["float8"]>;
  Dec_18?: Maybe<Scalars["float8"]>;
  Dec_19?: Maybe<Scalars["String"]>;
  Feb_17?: Maybe<Scalars["float8"]>;
  Feb_18?: Maybe<Scalars["float8"]>;
  Feb_19?: Maybe<Scalars["float8"]>;
  Feb_20?: Maybe<Scalars["String"]>;
  Jan_17?: Maybe<Scalars["float8"]>;
  Jan_18?: Maybe<Scalars["float8"]>;
  Jan_19?: Maybe<Scalars["float8"]>;
  Jan_20?: Maybe<Scalars["String"]>;
  Jul_17?: Maybe<Scalars["float8"]>;
  Jul_18?: Maybe<Scalars["float8"]>;
  Jul_19?: Maybe<Scalars["float8"]>;
  Jul_20?: Maybe<Scalars["float8"]>;
  Jun_17?: Maybe<Scalars["float8"]>;
  Jun_18?: Maybe<Scalars["float8"]>;
  Jun_19?: Maybe<Scalars["float8"]>;
  Jun_20?: Maybe<Scalars["float8"]>;
  Mar_17?: Maybe<Scalars["float8"]>;
  Mar_18?: Maybe<Scalars["float8"]>;
  Mar_19?: Maybe<Scalars["float8"]>;
  Mar_20?: Maybe<Scalars["String"]>;
  May_17?: Maybe<Scalars["float8"]>;
  May_18?: Maybe<Scalars["float8"]>;
  May_19?: Maybe<Scalars["float8"]>;
  May_20?: Maybe<Scalars["String"]>;
  Nov_17?: Maybe<Scalars["float8"]>;
  Nov_18?: Maybe<Scalars["float8"]>;
  Nov_19?: Maybe<Scalars["float8"]>;
  Oct_17?: Maybe<Scalars["float8"]>;
  Oct_18?: Maybe<Scalars["float8"]>;
  Oct_19?: Maybe<Scalars["float8"]>;
  Oct_20?: Maybe<Scalars["float8"]>;
  Sep_17?: Maybe<Scalars["float8"]>;
  Sep_18?: Maybe<Scalars["float8"]>;
  Sep_19?: Maybe<Scalars["float8"]>;
  Sep_20?: Maybe<Scalars["float8"]>;
  Type?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Max_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Apr_20?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Dec_19?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Feb_20?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jan_20?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  Mar_20?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  May_20?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Min_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_min_fields";
  Apr_17?: Maybe<Scalars["float8"]>;
  Apr_18?: Maybe<Scalars["float8"]>;
  Apr_19?: Maybe<Scalars["float8"]>;
  Apr_20?: Maybe<Scalars["String"]>;
  Aug_17?: Maybe<Scalars["float8"]>;
  Aug_18?: Maybe<Scalars["float8"]>;
  Aug_19?: Maybe<Scalars["float8"]>;
  Aug_20?: Maybe<Scalars["float8"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Dec_17?: Maybe<Scalars["float8"]>;
  Dec_18?: Maybe<Scalars["float8"]>;
  Dec_19?: Maybe<Scalars["String"]>;
  Feb_17?: Maybe<Scalars["float8"]>;
  Feb_18?: Maybe<Scalars["float8"]>;
  Feb_19?: Maybe<Scalars["float8"]>;
  Feb_20?: Maybe<Scalars["String"]>;
  Jan_17?: Maybe<Scalars["float8"]>;
  Jan_18?: Maybe<Scalars["float8"]>;
  Jan_19?: Maybe<Scalars["float8"]>;
  Jan_20?: Maybe<Scalars["String"]>;
  Jul_17?: Maybe<Scalars["float8"]>;
  Jul_18?: Maybe<Scalars["float8"]>;
  Jul_19?: Maybe<Scalars["float8"]>;
  Jul_20?: Maybe<Scalars["float8"]>;
  Jun_17?: Maybe<Scalars["float8"]>;
  Jun_18?: Maybe<Scalars["float8"]>;
  Jun_19?: Maybe<Scalars["float8"]>;
  Jun_20?: Maybe<Scalars["float8"]>;
  Mar_17?: Maybe<Scalars["float8"]>;
  Mar_18?: Maybe<Scalars["float8"]>;
  Mar_19?: Maybe<Scalars["float8"]>;
  Mar_20?: Maybe<Scalars["String"]>;
  May_17?: Maybe<Scalars["float8"]>;
  May_18?: Maybe<Scalars["float8"]>;
  May_19?: Maybe<Scalars["float8"]>;
  May_20?: Maybe<Scalars["String"]>;
  Nov_17?: Maybe<Scalars["float8"]>;
  Nov_18?: Maybe<Scalars["float8"]>;
  Nov_19?: Maybe<Scalars["float8"]>;
  Oct_17?: Maybe<Scalars["float8"]>;
  Oct_18?: Maybe<Scalars["float8"]>;
  Oct_19?: Maybe<Scalars["float8"]>;
  Oct_20?: Maybe<Scalars["float8"]>;
  Sep_17?: Maybe<Scalars["float8"]>;
  Sep_18?: Maybe<Scalars["float8"]>;
  Sep_19?: Maybe<Scalars["float8"]>;
  Sep_20?: Maybe<Scalars["float8"]>;
  Type?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Min_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Apr_20?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Dec_19?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Feb_20?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jan_20?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  Mar_20?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  May_20?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Mutation_Response = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Nwsc_CustomersUraTransactionsAndTrends>;
};

/** input type for inserting object relation for remote table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Obj_Rel_Insert_Input = {
  data: Nwsc_CustomersUraTransactionsAndTrends_Insert_Input;
};

/** ordering options when selecting data from "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Apr_20?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Dec_19?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Feb_20?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jan_20?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  Mar_20?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  May_20?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "nwsc.CustomersURATransactionsAndTrends" */
export enum Nwsc_CustomersUraTransactionsAndTrends_Select_Column {
  /** column name */
  Apr_17 = "Apr_17",
  /** column name */
  Apr_18 = "Apr_18",
  /** column name */
  Apr_19 = "Apr_19",
  /** column name */
  Apr_20 = "Apr_20",
  /** column name */
  Aug_17 = "Aug_17",
  /** column name */
  Aug_18 = "Aug_18",
  /** column name */
  Aug_19 = "Aug_19",
  /** column name */
  Aug_20 = "Aug_20",
  /** column name */
  CustomerReference = "CustomerReference",
  /** column name */
  Dec_17 = "Dec_17",
  /** column name */
  Dec_18 = "Dec_18",
  /** column name */
  Dec_19 = "Dec_19",
  /** column name */
  Feb_17 = "Feb_17",
  /** column name */
  Feb_18 = "Feb_18",
  /** column name */
  Feb_19 = "Feb_19",
  /** column name */
  Feb_20 = "Feb_20",
  /** column name */
  Jan_17 = "Jan_17",
  /** column name */
  Jan_18 = "Jan_18",
  /** column name */
  Jan_19 = "Jan_19",
  /** column name */
  Jan_20 = "Jan_20",
  /** column name */
  Jul_17 = "Jul_17",
  /** column name */
  Jul_18 = "Jul_18",
  /** column name */
  Jul_19 = "Jul_19",
  /** column name */
  Jul_20 = "Jul_20",
  /** column name */
  Jun_17 = "Jun_17",
  /** column name */
  Jun_18 = "Jun_18",
  /** column name */
  Jun_19 = "Jun_19",
  /** column name */
  Jun_20 = "Jun_20",
  /** column name */
  Mar_17 = "Mar_17",
  /** column name */
  Mar_18 = "Mar_18",
  /** column name */
  Mar_19 = "Mar_19",
  /** column name */
  Mar_20 = "Mar_20",
  /** column name */
  May_17 = "May_17",
  /** column name */
  May_18 = "May_18",
  /** column name */
  May_19 = "May_19",
  /** column name */
  May_20 = "May_20",
  /** column name */
  Nov_17 = "Nov_17",
  /** column name */
  Nov_18 = "Nov_18",
  /** column name */
  Nov_19 = "Nov_19",
  /** column name */
  Oct_17 = "Oct_17",
  /** column name */
  Oct_18 = "Oct_18",
  /** column name */
  Oct_19 = "Oct_19",
  /** column name */
  Oct_20 = "Oct_20",
  /** column name */
  Sep_17 = "Sep_17",
  /** column name */
  Sep_18 = "Sep_18",
  /** column name */
  Sep_19 = "Sep_19",
  /** column name */
  Sep_20 = "Sep_20",
  /** column name */
  Type = "Type",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Set_Input = {
  Apr_17?: Maybe<Scalars["float8"]>;
  Apr_18?: Maybe<Scalars["float8"]>;
  Apr_19?: Maybe<Scalars["float8"]>;
  Apr_20?: Maybe<Scalars["String"]>;
  Aug_17?: Maybe<Scalars["float8"]>;
  Aug_18?: Maybe<Scalars["float8"]>;
  Aug_19?: Maybe<Scalars["float8"]>;
  Aug_20?: Maybe<Scalars["float8"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Dec_17?: Maybe<Scalars["float8"]>;
  Dec_18?: Maybe<Scalars["float8"]>;
  Dec_19?: Maybe<Scalars["String"]>;
  Feb_17?: Maybe<Scalars["float8"]>;
  Feb_18?: Maybe<Scalars["float8"]>;
  Feb_19?: Maybe<Scalars["float8"]>;
  Feb_20?: Maybe<Scalars["String"]>;
  Jan_17?: Maybe<Scalars["float8"]>;
  Jan_18?: Maybe<Scalars["float8"]>;
  Jan_19?: Maybe<Scalars["float8"]>;
  Jan_20?: Maybe<Scalars["String"]>;
  Jul_17?: Maybe<Scalars["float8"]>;
  Jul_18?: Maybe<Scalars["float8"]>;
  Jul_19?: Maybe<Scalars["float8"]>;
  Jul_20?: Maybe<Scalars["float8"]>;
  Jun_17?: Maybe<Scalars["float8"]>;
  Jun_18?: Maybe<Scalars["float8"]>;
  Jun_19?: Maybe<Scalars["float8"]>;
  Jun_20?: Maybe<Scalars["float8"]>;
  Mar_17?: Maybe<Scalars["float8"]>;
  Mar_18?: Maybe<Scalars["float8"]>;
  Mar_19?: Maybe<Scalars["float8"]>;
  Mar_20?: Maybe<Scalars["String"]>;
  May_17?: Maybe<Scalars["float8"]>;
  May_18?: Maybe<Scalars["float8"]>;
  May_19?: Maybe<Scalars["float8"]>;
  May_20?: Maybe<Scalars["String"]>;
  Nov_17?: Maybe<Scalars["float8"]>;
  Nov_18?: Maybe<Scalars["float8"]>;
  Nov_19?: Maybe<Scalars["float8"]>;
  Oct_17?: Maybe<Scalars["float8"]>;
  Oct_18?: Maybe<Scalars["float8"]>;
  Oct_19?: Maybe<Scalars["float8"]>;
  Oct_20?: Maybe<Scalars["float8"]>;
  Sep_17?: Maybe<Scalars["float8"]>;
  Sep_18?: Maybe<Scalars["float8"]>;
  Sep_19?: Maybe<Scalars["float8"]>;
  Sep_20?: Maybe<Scalars["float8"]>;
  Type?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Stddev_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_stddev_fields";
  Apr_17?: Maybe<Scalars["Float"]>;
  Apr_18?: Maybe<Scalars["Float"]>;
  Apr_19?: Maybe<Scalars["Float"]>;
  Aug_17?: Maybe<Scalars["Float"]>;
  Aug_18?: Maybe<Scalars["Float"]>;
  Aug_19?: Maybe<Scalars["Float"]>;
  Aug_20?: Maybe<Scalars["Float"]>;
  CustomerReference?: Maybe<Scalars["Float"]>;
  Dec_17?: Maybe<Scalars["Float"]>;
  Dec_18?: Maybe<Scalars["Float"]>;
  Feb_17?: Maybe<Scalars["Float"]>;
  Feb_18?: Maybe<Scalars["Float"]>;
  Feb_19?: Maybe<Scalars["Float"]>;
  Jan_17?: Maybe<Scalars["Float"]>;
  Jan_18?: Maybe<Scalars["Float"]>;
  Jan_19?: Maybe<Scalars["Float"]>;
  Jul_17?: Maybe<Scalars["Float"]>;
  Jul_18?: Maybe<Scalars["Float"]>;
  Jul_19?: Maybe<Scalars["Float"]>;
  Jul_20?: Maybe<Scalars["Float"]>;
  Jun_17?: Maybe<Scalars["Float"]>;
  Jun_18?: Maybe<Scalars["Float"]>;
  Jun_19?: Maybe<Scalars["Float"]>;
  Jun_20?: Maybe<Scalars["Float"]>;
  Mar_17?: Maybe<Scalars["Float"]>;
  Mar_18?: Maybe<Scalars["Float"]>;
  Mar_19?: Maybe<Scalars["Float"]>;
  May_17?: Maybe<Scalars["Float"]>;
  May_18?: Maybe<Scalars["Float"]>;
  May_19?: Maybe<Scalars["Float"]>;
  Nov_17?: Maybe<Scalars["Float"]>;
  Nov_18?: Maybe<Scalars["Float"]>;
  Nov_19?: Maybe<Scalars["Float"]>;
  Oct_17?: Maybe<Scalars["Float"]>;
  Oct_18?: Maybe<Scalars["Float"]>;
  Oct_19?: Maybe<Scalars["Float"]>;
  Oct_20?: Maybe<Scalars["Float"]>;
  Sep_17?: Maybe<Scalars["Float"]>;
  Sep_18?: Maybe<Scalars["Float"]>;
  Sep_19?: Maybe<Scalars["Float"]>;
  Sep_20?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Stddev_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Stddev_Pop_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_stddev_pop_fields";
  Apr_17?: Maybe<Scalars["Float"]>;
  Apr_18?: Maybe<Scalars["Float"]>;
  Apr_19?: Maybe<Scalars["Float"]>;
  Aug_17?: Maybe<Scalars["Float"]>;
  Aug_18?: Maybe<Scalars["Float"]>;
  Aug_19?: Maybe<Scalars["Float"]>;
  Aug_20?: Maybe<Scalars["Float"]>;
  CustomerReference?: Maybe<Scalars["Float"]>;
  Dec_17?: Maybe<Scalars["Float"]>;
  Dec_18?: Maybe<Scalars["Float"]>;
  Feb_17?: Maybe<Scalars["Float"]>;
  Feb_18?: Maybe<Scalars["Float"]>;
  Feb_19?: Maybe<Scalars["Float"]>;
  Jan_17?: Maybe<Scalars["Float"]>;
  Jan_18?: Maybe<Scalars["Float"]>;
  Jan_19?: Maybe<Scalars["Float"]>;
  Jul_17?: Maybe<Scalars["Float"]>;
  Jul_18?: Maybe<Scalars["Float"]>;
  Jul_19?: Maybe<Scalars["Float"]>;
  Jul_20?: Maybe<Scalars["Float"]>;
  Jun_17?: Maybe<Scalars["Float"]>;
  Jun_18?: Maybe<Scalars["Float"]>;
  Jun_19?: Maybe<Scalars["Float"]>;
  Jun_20?: Maybe<Scalars["Float"]>;
  Mar_17?: Maybe<Scalars["Float"]>;
  Mar_18?: Maybe<Scalars["Float"]>;
  Mar_19?: Maybe<Scalars["Float"]>;
  May_17?: Maybe<Scalars["Float"]>;
  May_18?: Maybe<Scalars["Float"]>;
  May_19?: Maybe<Scalars["Float"]>;
  Nov_17?: Maybe<Scalars["Float"]>;
  Nov_18?: Maybe<Scalars["Float"]>;
  Nov_19?: Maybe<Scalars["Float"]>;
  Oct_17?: Maybe<Scalars["Float"]>;
  Oct_18?: Maybe<Scalars["Float"]>;
  Oct_19?: Maybe<Scalars["Float"]>;
  Oct_20?: Maybe<Scalars["Float"]>;
  Sep_17?: Maybe<Scalars["Float"]>;
  Sep_18?: Maybe<Scalars["Float"]>;
  Sep_19?: Maybe<Scalars["Float"]>;
  Sep_20?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Stddev_Pop_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Stddev_Samp_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_stddev_samp_fields";
  Apr_17?: Maybe<Scalars["Float"]>;
  Apr_18?: Maybe<Scalars["Float"]>;
  Apr_19?: Maybe<Scalars["Float"]>;
  Aug_17?: Maybe<Scalars["Float"]>;
  Aug_18?: Maybe<Scalars["Float"]>;
  Aug_19?: Maybe<Scalars["Float"]>;
  Aug_20?: Maybe<Scalars["Float"]>;
  CustomerReference?: Maybe<Scalars["Float"]>;
  Dec_17?: Maybe<Scalars["Float"]>;
  Dec_18?: Maybe<Scalars["Float"]>;
  Feb_17?: Maybe<Scalars["Float"]>;
  Feb_18?: Maybe<Scalars["Float"]>;
  Feb_19?: Maybe<Scalars["Float"]>;
  Jan_17?: Maybe<Scalars["Float"]>;
  Jan_18?: Maybe<Scalars["Float"]>;
  Jan_19?: Maybe<Scalars["Float"]>;
  Jul_17?: Maybe<Scalars["Float"]>;
  Jul_18?: Maybe<Scalars["Float"]>;
  Jul_19?: Maybe<Scalars["Float"]>;
  Jul_20?: Maybe<Scalars["Float"]>;
  Jun_17?: Maybe<Scalars["Float"]>;
  Jun_18?: Maybe<Scalars["Float"]>;
  Jun_19?: Maybe<Scalars["Float"]>;
  Jun_20?: Maybe<Scalars["Float"]>;
  Mar_17?: Maybe<Scalars["Float"]>;
  Mar_18?: Maybe<Scalars["Float"]>;
  Mar_19?: Maybe<Scalars["Float"]>;
  May_17?: Maybe<Scalars["Float"]>;
  May_18?: Maybe<Scalars["Float"]>;
  May_19?: Maybe<Scalars["Float"]>;
  Nov_17?: Maybe<Scalars["Float"]>;
  Nov_18?: Maybe<Scalars["Float"]>;
  Nov_19?: Maybe<Scalars["Float"]>;
  Oct_17?: Maybe<Scalars["Float"]>;
  Oct_18?: Maybe<Scalars["Float"]>;
  Oct_19?: Maybe<Scalars["Float"]>;
  Oct_20?: Maybe<Scalars["Float"]>;
  Sep_17?: Maybe<Scalars["Float"]>;
  Sep_18?: Maybe<Scalars["Float"]>;
  Sep_19?: Maybe<Scalars["Float"]>;
  Sep_20?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Stddev_Samp_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Sum_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_sum_fields";
  Apr_17?: Maybe<Scalars["float8"]>;
  Apr_18?: Maybe<Scalars["float8"]>;
  Apr_19?: Maybe<Scalars["float8"]>;
  Aug_17?: Maybe<Scalars["float8"]>;
  Aug_18?: Maybe<Scalars["float8"]>;
  Aug_19?: Maybe<Scalars["float8"]>;
  Aug_20?: Maybe<Scalars["float8"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Dec_17?: Maybe<Scalars["float8"]>;
  Dec_18?: Maybe<Scalars["float8"]>;
  Feb_17?: Maybe<Scalars["float8"]>;
  Feb_18?: Maybe<Scalars["float8"]>;
  Feb_19?: Maybe<Scalars["float8"]>;
  Jan_17?: Maybe<Scalars["float8"]>;
  Jan_18?: Maybe<Scalars["float8"]>;
  Jan_19?: Maybe<Scalars["float8"]>;
  Jul_17?: Maybe<Scalars["float8"]>;
  Jul_18?: Maybe<Scalars["float8"]>;
  Jul_19?: Maybe<Scalars["float8"]>;
  Jul_20?: Maybe<Scalars["float8"]>;
  Jun_17?: Maybe<Scalars["float8"]>;
  Jun_18?: Maybe<Scalars["float8"]>;
  Jun_19?: Maybe<Scalars["float8"]>;
  Jun_20?: Maybe<Scalars["float8"]>;
  Mar_17?: Maybe<Scalars["float8"]>;
  Mar_18?: Maybe<Scalars["float8"]>;
  Mar_19?: Maybe<Scalars["float8"]>;
  May_17?: Maybe<Scalars["float8"]>;
  May_18?: Maybe<Scalars["float8"]>;
  May_19?: Maybe<Scalars["float8"]>;
  Nov_17?: Maybe<Scalars["float8"]>;
  Nov_18?: Maybe<Scalars["float8"]>;
  Nov_19?: Maybe<Scalars["float8"]>;
  Oct_17?: Maybe<Scalars["float8"]>;
  Oct_18?: Maybe<Scalars["float8"]>;
  Oct_19?: Maybe<Scalars["float8"]>;
  Oct_20?: Maybe<Scalars["float8"]>;
  Sep_17?: Maybe<Scalars["float8"]>;
  Sep_18?: Maybe<Scalars["float8"]>;
  Sep_19?: Maybe<Scalars["float8"]>;
  Sep_20?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Sum_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Var_Pop_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_var_pop_fields";
  Apr_17?: Maybe<Scalars["Float"]>;
  Apr_18?: Maybe<Scalars["Float"]>;
  Apr_19?: Maybe<Scalars["Float"]>;
  Aug_17?: Maybe<Scalars["Float"]>;
  Aug_18?: Maybe<Scalars["Float"]>;
  Aug_19?: Maybe<Scalars["Float"]>;
  Aug_20?: Maybe<Scalars["Float"]>;
  CustomerReference?: Maybe<Scalars["Float"]>;
  Dec_17?: Maybe<Scalars["Float"]>;
  Dec_18?: Maybe<Scalars["Float"]>;
  Feb_17?: Maybe<Scalars["Float"]>;
  Feb_18?: Maybe<Scalars["Float"]>;
  Feb_19?: Maybe<Scalars["Float"]>;
  Jan_17?: Maybe<Scalars["Float"]>;
  Jan_18?: Maybe<Scalars["Float"]>;
  Jan_19?: Maybe<Scalars["Float"]>;
  Jul_17?: Maybe<Scalars["Float"]>;
  Jul_18?: Maybe<Scalars["Float"]>;
  Jul_19?: Maybe<Scalars["Float"]>;
  Jul_20?: Maybe<Scalars["Float"]>;
  Jun_17?: Maybe<Scalars["Float"]>;
  Jun_18?: Maybe<Scalars["Float"]>;
  Jun_19?: Maybe<Scalars["Float"]>;
  Jun_20?: Maybe<Scalars["Float"]>;
  Mar_17?: Maybe<Scalars["Float"]>;
  Mar_18?: Maybe<Scalars["Float"]>;
  Mar_19?: Maybe<Scalars["Float"]>;
  May_17?: Maybe<Scalars["Float"]>;
  May_18?: Maybe<Scalars["Float"]>;
  May_19?: Maybe<Scalars["Float"]>;
  Nov_17?: Maybe<Scalars["Float"]>;
  Nov_18?: Maybe<Scalars["Float"]>;
  Nov_19?: Maybe<Scalars["Float"]>;
  Oct_17?: Maybe<Scalars["Float"]>;
  Oct_18?: Maybe<Scalars["Float"]>;
  Oct_19?: Maybe<Scalars["Float"]>;
  Oct_20?: Maybe<Scalars["Float"]>;
  Sep_17?: Maybe<Scalars["Float"]>;
  Sep_18?: Maybe<Scalars["Float"]>;
  Sep_19?: Maybe<Scalars["Float"]>;
  Sep_20?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Var_Pop_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Var_Samp_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_var_samp_fields";
  Apr_17?: Maybe<Scalars["Float"]>;
  Apr_18?: Maybe<Scalars["Float"]>;
  Apr_19?: Maybe<Scalars["Float"]>;
  Aug_17?: Maybe<Scalars["Float"]>;
  Aug_18?: Maybe<Scalars["Float"]>;
  Aug_19?: Maybe<Scalars["Float"]>;
  Aug_20?: Maybe<Scalars["Float"]>;
  CustomerReference?: Maybe<Scalars["Float"]>;
  Dec_17?: Maybe<Scalars["Float"]>;
  Dec_18?: Maybe<Scalars["Float"]>;
  Feb_17?: Maybe<Scalars["Float"]>;
  Feb_18?: Maybe<Scalars["Float"]>;
  Feb_19?: Maybe<Scalars["Float"]>;
  Jan_17?: Maybe<Scalars["Float"]>;
  Jan_18?: Maybe<Scalars["Float"]>;
  Jan_19?: Maybe<Scalars["Float"]>;
  Jul_17?: Maybe<Scalars["Float"]>;
  Jul_18?: Maybe<Scalars["Float"]>;
  Jul_19?: Maybe<Scalars["Float"]>;
  Jul_20?: Maybe<Scalars["Float"]>;
  Jun_17?: Maybe<Scalars["Float"]>;
  Jun_18?: Maybe<Scalars["Float"]>;
  Jun_19?: Maybe<Scalars["Float"]>;
  Jun_20?: Maybe<Scalars["Float"]>;
  Mar_17?: Maybe<Scalars["Float"]>;
  Mar_18?: Maybe<Scalars["Float"]>;
  Mar_19?: Maybe<Scalars["Float"]>;
  May_17?: Maybe<Scalars["Float"]>;
  May_18?: Maybe<Scalars["Float"]>;
  May_19?: Maybe<Scalars["Float"]>;
  Nov_17?: Maybe<Scalars["Float"]>;
  Nov_18?: Maybe<Scalars["Float"]>;
  Nov_19?: Maybe<Scalars["Float"]>;
  Oct_17?: Maybe<Scalars["Float"]>;
  Oct_18?: Maybe<Scalars["Float"]>;
  Oct_19?: Maybe<Scalars["Float"]>;
  Oct_20?: Maybe<Scalars["Float"]>;
  Sep_17?: Maybe<Scalars["Float"]>;
  Sep_18?: Maybe<Scalars["Float"]>;
  Sep_19?: Maybe<Scalars["Float"]>;
  Sep_20?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Var_Samp_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Nwsc_CustomersUraTransactionsAndTrends_Variance_Fields = {
  __typename?: "nwsc_CustomersURATransactionsAndTrends_variance_fields";
  Apr_17?: Maybe<Scalars["Float"]>;
  Apr_18?: Maybe<Scalars["Float"]>;
  Apr_19?: Maybe<Scalars["Float"]>;
  Aug_17?: Maybe<Scalars["Float"]>;
  Aug_18?: Maybe<Scalars["Float"]>;
  Aug_19?: Maybe<Scalars["Float"]>;
  Aug_20?: Maybe<Scalars["Float"]>;
  CustomerReference?: Maybe<Scalars["Float"]>;
  Dec_17?: Maybe<Scalars["Float"]>;
  Dec_18?: Maybe<Scalars["Float"]>;
  Feb_17?: Maybe<Scalars["Float"]>;
  Feb_18?: Maybe<Scalars["Float"]>;
  Feb_19?: Maybe<Scalars["Float"]>;
  Jan_17?: Maybe<Scalars["Float"]>;
  Jan_18?: Maybe<Scalars["Float"]>;
  Jan_19?: Maybe<Scalars["Float"]>;
  Jul_17?: Maybe<Scalars["Float"]>;
  Jul_18?: Maybe<Scalars["Float"]>;
  Jul_19?: Maybe<Scalars["Float"]>;
  Jul_20?: Maybe<Scalars["Float"]>;
  Jun_17?: Maybe<Scalars["Float"]>;
  Jun_18?: Maybe<Scalars["Float"]>;
  Jun_19?: Maybe<Scalars["Float"]>;
  Jun_20?: Maybe<Scalars["Float"]>;
  Mar_17?: Maybe<Scalars["Float"]>;
  Mar_18?: Maybe<Scalars["Float"]>;
  Mar_19?: Maybe<Scalars["Float"]>;
  May_17?: Maybe<Scalars["Float"]>;
  May_18?: Maybe<Scalars["Float"]>;
  May_19?: Maybe<Scalars["Float"]>;
  Nov_17?: Maybe<Scalars["Float"]>;
  Nov_18?: Maybe<Scalars["Float"]>;
  Nov_19?: Maybe<Scalars["Float"]>;
  Oct_17?: Maybe<Scalars["Float"]>;
  Oct_18?: Maybe<Scalars["Float"]>;
  Oct_19?: Maybe<Scalars["Float"]>;
  Oct_20?: Maybe<Scalars["Float"]>;
  Sep_17?: Maybe<Scalars["Float"]>;
  Sep_18?: Maybe<Scalars["Float"]>;
  Sep_19?: Maybe<Scalars["Float"]>;
  Sep_20?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "nwsc.CustomersURATransactionsAndTrends" */
export type Nwsc_CustomersUraTransactionsAndTrends_Variance_Order_By = {
  Apr_17?: Maybe<Order_By>;
  Apr_18?: Maybe<Order_By>;
  Apr_19?: Maybe<Order_By>;
  Aug_17?: Maybe<Order_By>;
  Aug_18?: Maybe<Order_By>;
  Aug_19?: Maybe<Order_By>;
  Aug_20?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Dec_17?: Maybe<Order_By>;
  Dec_18?: Maybe<Order_By>;
  Feb_17?: Maybe<Order_By>;
  Feb_18?: Maybe<Order_By>;
  Feb_19?: Maybe<Order_By>;
  Jan_17?: Maybe<Order_By>;
  Jan_18?: Maybe<Order_By>;
  Jan_19?: Maybe<Order_By>;
  Jul_17?: Maybe<Order_By>;
  Jul_18?: Maybe<Order_By>;
  Jul_19?: Maybe<Order_By>;
  Jul_20?: Maybe<Order_By>;
  Jun_17?: Maybe<Order_By>;
  Jun_18?: Maybe<Order_By>;
  Jun_19?: Maybe<Order_By>;
  Jun_20?: Maybe<Order_By>;
  Mar_17?: Maybe<Order_By>;
  Mar_18?: Maybe<Order_By>;
  Mar_19?: Maybe<Order_By>;
  May_17?: Maybe<Order_By>;
  May_18?: Maybe<Order_By>;
  May_19?: Maybe<Order_By>;
  Nov_17?: Maybe<Order_By>;
  Nov_18?: Maybe<Order_By>;
  Nov_19?: Maybe<Order_By>;
  Oct_17?: Maybe<Order_By>;
  Oct_18?: Maybe<Order_By>;
  Oct_19?: Maybe<Order_By>;
  Oct_20?: Maybe<Order_By>;
  Sep_17?: Maybe<Order_By>;
  Sep_18?: Maybe<Order_By>;
  Sep_19?: Maybe<Order_By>;
  Sep_20?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregated selection of "nwsc.Customers" */
export type Nwsc_Customers_Aggregate = {
  __typename?: "nwsc_Customers_aggregate";
  aggregate?: Maybe<Nwsc_Customers_Aggregate_Fields>;
  nodes: Array<Nwsc_Customers>;
};

/** aggregate fields of "nwsc.Customers" */
export type Nwsc_Customers_Aggregate_Fields = {
  __typename?: "nwsc_Customers_aggregate_fields";
  avg?: Maybe<Nwsc_Customers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Nwsc_Customers_Max_Fields>;
  min?: Maybe<Nwsc_Customers_Min_Fields>;
  stddev?: Maybe<Nwsc_Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Nwsc_Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nwsc_Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Nwsc_Customers_Sum_Fields>;
  var_pop?: Maybe<Nwsc_Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Nwsc_Customers_Var_Samp_Fields>;
  variance?: Maybe<Nwsc_Customers_Variance_Fields>;
};

/** aggregate fields of "nwsc.Customers" */
export type Nwsc_Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Nwsc_Customers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "nwsc.Customers" */
export type Nwsc_Customers_Aggregate_Order_By = {
  avg?: Maybe<Nwsc_Customers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Nwsc_Customers_Max_Order_By>;
  min?: Maybe<Nwsc_Customers_Min_Order_By>;
  stddev?: Maybe<Nwsc_Customers_Stddev_Order_By>;
  stddev_pop?: Maybe<Nwsc_Customers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Nwsc_Customers_Stddev_Samp_Order_By>;
  sum?: Maybe<Nwsc_Customers_Sum_Order_By>;
  var_pop?: Maybe<Nwsc_Customers_Var_Pop_Order_By>;
  var_samp?: Maybe<Nwsc_Customers_Var_Samp_Order_By>;
  variance?: Maybe<Nwsc_Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nwsc.Customers" */
export type Nwsc_Customers_Arr_Rel_Insert_Input = {
  data: Array<Nwsc_Customers_Insert_Input>;
};

/** aggregate avg on columns */
export type Nwsc_Customers_Avg_Fields = {
  __typename?: "nwsc_Customers_avg_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Avg_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nwsc.Customers". All fields are combined with a logical 'AND'. */
export type Nwsc_Customers_Bool_Exp = {
  Address_Cons?: Maybe<String_Comparison_Exp>;
  Address_Pay?: Maybe<String_Comparison_Exp>;
  Address_Trans?: Maybe<String_Comparison_Exp>;
  Area_Pay?: Maybe<String_Comparison_Exp>;
  Area_Trans?: Maybe<String_Comparison_Exp>;
  CustomerName_Cons?: Maybe<String_Comparison_Exp>;
  CustomerName_Pay?: Maybe<String_Comparison_Exp>;
  CustomerName_Trans?: Maybe<String_Comparison_Exp>;
  CustomerPhoneNo1_Cons?: Maybe<String_Comparison_Exp>;
  CustomerPhoneNo1_Pay?: Maybe<String_Comparison_Exp>;
  CustomerPhoneNo1_Trans?: Maybe<String_Comparison_Exp>;
  CustomerPhoneNo2_Cons?: Maybe<String_Comparison_Exp>;
  CustomerPhoneNo2_Pay?: Maybe<String_Comparison_Exp>;
  CustomerPhoneNo2_Trans?: Maybe<String_Comparison_Exp>;
  CustomerPhoneNo3_Pay?: Maybe<String_Comparison_Exp>;
  CustomerReference?: Maybe<Bigint_Comparison_Exp>;
  Latitude_Cons?: Maybe<String_Comparison_Exp>;
  Latitude_Pay?: Maybe<String_Comparison_Exp>;
  Longitude_Cons?: Maybe<String_Comparison_Exp>;
  Longitude_Pay?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Nwsc_Customers_Bool_Exp>>>;
  _not?: Maybe<Nwsc_Customers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Nwsc_Customers_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "nwsc.Customers" */
export type Nwsc_Customers_Inc_Input = {
  CustomerReference?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "nwsc.Customers" */
export type Nwsc_Customers_Insert_Input = {
  Address_Cons?: Maybe<Scalars["String"]>;
  Address_Pay?: Maybe<Scalars["String"]>;
  Address_Trans?: Maybe<Scalars["String"]>;
  Area_Pay?: Maybe<Scalars["String"]>;
  Area_Trans?: Maybe<Scalars["String"]>;
  CustomerName_Cons?: Maybe<Scalars["String"]>;
  CustomerName_Pay?: Maybe<Scalars["String"]>;
  CustomerName_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo3_Pay?: Maybe<Scalars["String"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Latitude_Cons?: Maybe<Scalars["String"]>;
  Latitude_Pay?: Maybe<Scalars["String"]>;
  Longitude_Cons?: Maybe<Scalars["String"]>;
  Longitude_Pay?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Nwsc_Customers_Max_Fields = {
  __typename?: "nwsc_Customers_max_fields";
  Address_Cons?: Maybe<Scalars["String"]>;
  Address_Pay?: Maybe<Scalars["String"]>;
  Address_Trans?: Maybe<Scalars["String"]>;
  Area_Pay?: Maybe<Scalars["String"]>;
  Area_Trans?: Maybe<Scalars["String"]>;
  CustomerName_Cons?: Maybe<Scalars["String"]>;
  CustomerName_Pay?: Maybe<Scalars["String"]>;
  CustomerName_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo3_Pay?: Maybe<Scalars["String"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Latitude_Cons?: Maybe<Scalars["String"]>;
  Latitude_Pay?: Maybe<Scalars["String"]>;
  Longitude_Cons?: Maybe<Scalars["String"]>;
  Longitude_Pay?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Max_Order_By = {
  Address_Cons?: Maybe<Order_By>;
  Address_Pay?: Maybe<Order_By>;
  Address_Trans?: Maybe<Order_By>;
  Area_Pay?: Maybe<Order_By>;
  Area_Trans?: Maybe<Order_By>;
  CustomerName_Cons?: Maybe<Order_By>;
  CustomerName_Pay?: Maybe<Order_By>;
  CustomerName_Trans?: Maybe<Order_By>;
  CustomerPhoneNo1_Cons?: Maybe<Order_By>;
  CustomerPhoneNo1_Pay?: Maybe<Order_By>;
  CustomerPhoneNo1_Trans?: Maybe<Order_By>;
  CustomerPhoneNo2_Cons?: Maybe<Order_By>;
  CustomerPhoneNo2_Pay?: Maybe<Order_By>;
  CustomerPhoneNo2_Trans?: Maybe<Order_By>;
  CustomerPhoneNo3_Pay?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Latitude_Cons?: Maybe<Order_By>;
  Latitude_Pay?: Maybe<Order_By>;
  Longitude_Cons?: Maybe<Order_By>;
  Longitude_Pay?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Nwsc_Customers_Min_Fields = {
  __typename?: "nwsc_Customers_min_fields";
  Address_Cons?: Maybe<Scalars["String"]>;
  Address_Pay?: Maybe<Scalars["String"]>;
  Address_Trans?: Maybe<Scalars["String"]>;
  Area_Pay?: Maybe<Scalars["String"]>;
  Area_Trans?: Maybe<Scalars["String"]>;
  CustomerName_Cons?: Maybe<Scalars["String"]>;
  CustomerName_Pay?: Maybe<Scalars["String"]>;
  CustomerName_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo3_Pay?: Maybe<Scalars["String"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Latitude_Cons?: Maybe<Scalars["String"]>;
  Latitude_Pay?: Maybe<Scalars["String"]>;
  Longitude_Cons?: Maybe<Scalars["String"]>;
  Longitude_Pay?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Min_Order_By = {
  Address_Cons?: Maybe<Order_By>;
  Address_Pay?: Maybe<Order_By>;
  Address_Trans?: Maybe<Order_By>;
  Area_Pay?: Maybe<Order_By>;
  Area_Trans?: Maybe<Order_By>;
  CustomerName_Cons?: Maybe<Order_By>;
  CustomerName_Pay?: Maybe<Order_By>;
  CustomerName_Trans?: Maybe<Order_By>;
  CustomerPhoneNo1_Cons?: Maybe<Order_By>;
  CustomerPhoneNo1_Pay?: Maybe<Order_By>;
  CustomerPhoneNo1_Trans?: Maybe<Order_By>;
  CustomerPhoneNo2_Cons?: Maybe<Order_By>;
  CustomerPhoneNo2_Pay?: Maybe<Order_By>;
  CustomerPhoneNo2_Trans?: Maybe<Order_By>;
  CustomerPhoneNo3_Pay?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Latitude_Cons?: Maybe<Order_By>;
  Latitude_Pay?: Maybe<Order_By>;
  Longitude_Cons?: Maybe<Order_By>;
  Longitude_Pay?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "nwsc.Customers" */
export type Nwsc_Customers_Mutation_Response = {
  __typename?: "nwsc_Customers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Nwsc_Customers>;
};

/** input type for inserting object relation for remote table "nwsc.Customers" */
export type Nwsc_Customers_Obj_Rel_Insert_Input = {
  data: Nwsc_Customers_Insert_Input;
};

/** ordering options when selecting data from "nwsc.Customers" */
export type Nwsc_Customers_Order_By = {
  Address_Cons?: Maybe<Order_By>;
  Address_Pay?: Maybe<Order_By>;
  Address_Trans?: Maybe<Order_By>;
  Area_Pay?: Maybe<Order_By>;
  Area_Trans?: Maybe<Order_By>;
  CustomerName_Cons?: Maybe<Order_By>;
  CustomerName_Pay?: Maybe<Order_By>;
  CustomerName_Trans?: Maybe<Order_By>;
  CustomerPhoneNo1_Cons?: Maybe<Order_By>;
  CustomerPhoneNo1_Pay?: Maybe<Order_By>;
  CustomerPhoneNo1_Trans?: Maybe<Order_By>;
  CustomerPhoneNo2_Cons?: Maybe<Order_By>;
  CustomerPhoneNo2_Pay?: Maybe<Order_By>;
  CustomerPhoneNo2_Trans?: Maybe<Order_By>;
  CustomerPhoneNo3_Pay?: Maybe<Order_By>;
  CustomerReference?: Maybe<Order_By>;
  Latitude_Cons?: Maybe<Order_By>;
  Latitude_Pay?: Maybe<Order_By>;
  Longitude_Cons?: Maybe<Order_By>;
  Longitude_Pay?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "nwsc.Customers" */
export enum Nwsc_Customers_Select_Column {
  /** column name */
  AddressCons = "Address_Cons",
  /** column name */
  AddressPay = "Address_Pay",
  /** column name */
  AddressTrans = "Address_Trans",
  /** column name */
  AreaPay = "Area_Pay",
  /** column name */
  AreaTrans = "Area_Trans",
  /** column name */
  CustomerNameCons = "CustomerName_Cons",
  /** column name */
  CustomerNamePay = "CustomerName_Pay",
  /** column name */
  CustomerNameTrans = "CustomerName_Trans",
  /** column name */
  CustomerPhoneNo1Cons = "CustomerPhoneNo1_Cons",
  /** column name */
  CustomerPhoneNo1Pay = "CustomerPhoneNo1_Pay",
  /** column name */
  CustomerPhoneNo1Trans = "CustomerPhoneNo1_Trans",
  /** column name */
  CustomerPhoneNo2Cons = "CustomerPhoneNo2_Cons",
  /** column name */
  CustomerPhoneNo2Pay = "CustomerPhoneNo2_Pay",
  /** column name */
  CustomerPhoneNo2Trans = "CustomerPhoneNo2_Trans",
  /** column name */
  CustomerPhoneNo3Pay = "CustomerPhoneNo3_Pay",
  /** column name */
  CustomerReference = "CustomerReference",
  /** column name */
  LatitudeCons = "Latitude_Cons",
  /** column name */
  LatitudePay = "Latitude_Pay",
  /** column name */
  LongitudeCons = "Longitude_Cons",
  /** column name */
  LongitudePay = "Longitude_Pay",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "nwsc.Customers" */
export type Nwsc_Customers_Set_Input = {
  Address_Cons?: Maybe<Scalars["String"]>;
  Address_Pay?: Maybe<Scalars["String"]>;
  Address_Trans?: Maybe<Scalars["String"]>;
  Area_Pay?: Maybe<Scalars["String"]>;
  Area_Trans?: Maybe<Scalars["String"]>;
  CustomerName_Cons?: Maybe<Scalars["String"]>;
  CustomerName_Pay?: Maybe<Scalars["String"]>;
  CustomerName_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo1_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Cons?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Pay?: Maybe<Scalars["String"]>;
  CustomerPhoneNo2_Trans?: Maybe<Scalars["String"]>;
  CustomerPhoneNo3_Pay?: Maybe<Scalars["String"]>;
  CustomerReference?: Maybe<Scalars["bigint"]>;
  Latitude_Cons?: Maybe<Scalars["String"]>;
  Latitude_Pay?: Maybe<Scalars["String"]>;
  Longitude_Cons?: Maybe<Scalars["String"]>;
  Longitude_Pay?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Nwsc_Customers_Stddev_Fields = {
  __typename?: "nwsc_Customers_stddev_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Stddev_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nwsc_Customers_Stddev_Pop_Fields = {
  __typename?: "nwsc_Customers_stddev_pop_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Stddev_Pop_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nwsc_Customers_Stddev_Samp_Fields = {
  __typename?: "nwsc_Customers_stddev_samp_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Stddev_Samp_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Nwsc_Customers_Sum_Fields = {
  __typename?: "nwsc_Customers_sum_fields";
  CustomerReference?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Sum_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Nwsc_Customers_Var_Pop_Fields = {
  __typename?: "nwsc_Customers_var_pop_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Var_Pop_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nwsc_Customers_Var_Samp_Fields = {
  __typename?: "nwsc_Customers_var_samp_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Var_Samp_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Nwsc_Customers_Variance_Fields = {
  __typename?: "nwsc_Customers_variance_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "nwsc.Customers" */
export type Nwsc_Customers_Variance_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "nwsc.URATransactions" */
export type Nwsc_UraTransactions = {
  __typename?: "nwsc_URATransactions";
  CustomerReference?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  month?: Maybe<Scalars["bigint"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Aggregate = {
  __typename?: "nwsc_URATransactions_aggregate";
  aggregate?: Maybe<Nwsc_UraTransactions_Aggregate_Fields>;
  nodes: Array<Nwsc_UraTransactions>;
};

/** aggregate fields of "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Aggregate_Fields = {
  __typename?: "nwsc_URATransactions_aggregate_fields";
  avg?: Maybe<Nwsc_UraTransactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Nwsc_UraTransactions_Max_Fields>;
  min?: Maybe<Nwsc_UraTransactions_Min_Fields>;
  stddev?: Maybe<Nwsc_UraTransactions_Stddev_Fields>;
  stddev_pop?: Maybe<Nwsc_UraTransactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nwsc_UraTransactions_Stddev_Samp_Fields>;
  sum?: Maybe<Nwsc_UraTransactions_Sum_Fields>;
  var_pop?: Maybe<Nwsc_UraTransactions_Var_Pop_Fields>;
  var_samp?: Maybe<Nwsc_UraTransactions_Var_Samp_Fields>;
  variance?: Maybe<Nwsc_UraTransactions_Variance_Fields>;
};

/** aggregate fields of "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Nwsc_UraTransactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Aggregate_Order_By = {
  avg?: Maybe<Nwsc_UraTransactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Nwsc_UraTransactions_Max_Order_By>;
  min?: Maybe<Nwsc_UraTransactions_Min_Order_By>;
  stddev?: Maybe<Nwsc_UraTransactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Nwsc_UraTransactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Nwsc_UraTransactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Nwsc_UraTransactions_Sum_Order_By>;
  var_pop?: Maybe<Nwsc_UraTransactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Nwsc_UraTransactions_Var_Samp_Order_By>;
  variance?: Maybe<Nwsc_UraTransactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Arr_Rel_Insert_Input = {
  data: Array<Nwsc_UraTransactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Nwsc_UraTransactions_Avg_Fields = {
  __typename?: "nwsc_URATransactions_avg_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  month?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Avg_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nwsc.URATransactions". All fields are combined with a logical 'AND'. */
export type Nwsc_UraTransactions_Bool_Exp = {
  CustomerReference?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Nwsc_UraTransactions_Bool_Exp>>>;
  _not?: Maybe<Nwsc_UraTransactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Nwsc_UraTransactions_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  month?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing integer column in table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Inc_Input = {
  CustomerReference?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  month?: Maybe<Scalars["bigint"]>;
  year?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Insert_Input = {
  CustomerReference?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  month?: Maybe<Scalars["bigint"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Nwsc_UraTransactions_Max_Fields = {
  __typename?: "nwsc_URATransactions_max_fields";
  CustomerReference?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  month?: Maybe<Scalars["bigint"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Max_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Nwsc_UraTransactions_Min_Fields = {
  __typename?: "nwsc_URATransactions_min_fields";
  CustomerReference?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  month?: Maybe<Scalars["bigint"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Min_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Mutation_Response = {
  __typename?: "nwsc_URATransactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Nwsc_UraTransactions>;
};

/** input type for inserting object relation for remote table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Obj_Rel_Insert_Input = {
  data: Nwsc_UraTransactions_Insert_Input;
};

/** ordering options when selecting data from "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "nwsc.URATransactions" */
export enum Nwsc_UraTransactions_Select_Column {
  /** column name */
  CustomerReference = "CustomerReference",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Month = "month",
  /** column name */
  Type = "type",
  /** column name */
  Value = "value",
  /** column name */
  WhoUpdated = "who_updated",
  /** column name */
  Year = "year",
}

/** input type for updating data in table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Set_Input = {
  CustomerReference?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  month?: Maybe<Scalars["bigint"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Nwsc_UraTransactions_Stddev_Fields = {
  __typename?: "nwsc_URATransactions_stddev_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  month?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Stddev_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nwsc_UraTransactions_Stddev_Pop_Fields = {
  __typename?: "nwsc_URATransactions_stddev_pop_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  month?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Stddev_Pop_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nwsc_UraTransactions_Stddev_Samp_Fields = {
  __typename?: "nwsc_URATransactions_stddev_samp_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  month?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Stddev_Samp_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Nwsc_UraTransactions_Sum_Fields = {
  __typename?: "nwsc_URATransactions_sum_fields";
  CustomerReference?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  month?: Maybe<Scalars["bigint"]>;
  year?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Sum_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Nwsc_UraTransactions_Var_Pop_Fields = {
  __typename?: "nwsc_URATransactions_var_pop_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  month?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Var_Pop_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nwsc_UraTransactions_Var_Samp_Fields = {
  __typename?: "nwsc_URATransactions_var_samp_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  month?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Var_Samp_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Nwsc_UraTransactions_Variance_Fields = {
  __typename?: "nwsc_URATransactions_variance_fields";
  CustomerReference?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  month?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "nwsc.URATransactions" */
export type Nwsc_UraTransactions_Variance_Order_By = {
  CustomerReference?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = "asc",
  /** in the ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in the ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in the descending order, nulls first */
  Desc = "desc",
  /** in the descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in the descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** query root */
export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "kcca.AssociateType" */
  kcca_AssociateType: Array<Kcca_AssociateType>;
  /** fetch aggregated fields from the table: "kcca.AssociateType" */
  kcca_AssociateType_aggregate: Kcca_AssociateType_Aggregate;
  /** fetch data from the table: "kcca.County" */
  kcca_County: Array<Kcca_County>;
  /** fetch aggregated fields from the table: "kcca.County" */
  kcca_County_aggregate: Kcca_County_Aggregate;
  /** fetch data from the table: "kcca.Customer" */
  kcca_Customer: Array<Kcca_Customer>;
  /** fetch data from the table: "kcca.CustomerApplicantType" */
  kcca_CustomerApplicantType: Array<Kcca_CustomerApplicantType>;
  /** fetch aggregated fields from the table: "kcca.CustomerApplicantType" */
  kcca_CustomerApplicantType_aggregate: Kcca_CustomerApplicantType_Aggregate;
  /** fetch data from the table: "kcca.CustomerBusinessAssociate" */
  kcca_CustomerBusinessAssociate: Array<Kcca_CustomerBusinessAssociate>;
  /** fetch aggregated fields from the table: "kcca.CustomerBusinessAssociate" */
  kcca_CustomerBusinessAssociate_aggregate: Kcca_CustomerBusinessAssociate_Aggregate;
  /** fetch data from the table: "kcca.CustomerBusinessType" */
  kcca_CustomerBusinessType: Array<Kcca_CustomerBusinessType>;
  /** fetch aggregated fields from the table: "kcca.CustomerBusinessType" */
  kcca_CustomerBusinessType_aggregate: Kcca_CustomerBusinessType_Aggregate;
  /** fetch data from the table: "kcca.CustomerCoinDeactivateReactive" */
  kcca_CustomerCoinDeactivateReactive: Array<Kcca_CustomerCoinDeactivateReactive>;
  /** fetch aggregated fields from the table: "kcca.CustomerCoinDeactivateReactive" */
  kcca_CustomerCoinDeactivateReactive_aggregate: Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** fetch data from the table: "kcca.CustomerContactPerson" */
  kcca_CustomerContactPerson: Array<Kcca_CustomerContactPerson>;
  /** fetch aggregated fields from the table: "kcca.CustomerContactPerson" */
  kcca_CustomerContactPerson_aggregate: Kcca_CustomerContactPerson_Aggregate;
  /** fetch data from the table: "kcca.CustomerDocumentItem" */
  kcca_CustomerDocumentItem: Array<Kcca_CustomerDocumentItem>;
  /** fetch aggregated fields from the table: "kcca.CustomerDocumentItem" */
  kcca_CustomerDocumentItem_aggregate: Kcca_CustomerDocumentItem_Aggregate;
  /** fetch data from the table: "kcca.CustomerGuardian" */
  kcca_CustomerGuardian: Array<Kcca_CustomerGuardian>;
  /** fetch aggregated fields from the table: "kcca.CustomerGuardian" */
  kcca_CustomerGuardian_aggregate: Kcca_CustomerGuardian_Aggregate;
  /** fetch data from the table: "kcca.CustomerReference" */
  kcca_CustomerReference: Array<Kcca_CustomerReference>;
  /** fetch aggregated fields from the table: "kcca.CustomerReference" */
  kcca_CustomerReference_aggregate: Kcca_CustomerReference_Aggregate;
  /** fetch data from the table: "kcca.CustomerRegistrationReason" */
  kcca_CustomerRegistrationReason: Array<Kcca_CustomerRegistrationReason>;
  /** fetch aggregated fields from the table: "kcca.CustomerRegistrationReason" */
  kcca_CustomerRegistrationReason_aggregate: Kcca_CustomerRegistrationReason_Aggregate;
  /** fetch data from the table: "kcca.CustomerRevenueAgent" */
  kcca_CustomerRevenueAgent: Array<Kcca_CustomerRevenueAgent>;
  /** fetch aggregated fields from the table: "kcca.CustomerRevenueAgent" */
  kcca_CustomerRevenueAgent_aggregate: Kcca_CustomerRevenueAgent_Aggregate;
  /** fetch data from the table: "kcca.CustomerType" */
  kcca_CustomerType: Array<Kcca_CustomerType>;
  /** fetch aggregated fields from the table: "kcca.CustomerType" */
  kcca_CustomerType_aggregate: Kcca_CustomerType_Aggregate;
  /** fetch aggregated fields from the table: "kcca.Customer" */
  kcca_Customer_aggregate: Kcca_Customer_Aggregate;
  /** fetch data from the table: "kcca.District" */
  kcca_District: Array<Kcca_District>;
  /** fetch aggregated fields from the table: "kcca.District" */
  kcca_District_aggregate: Kcca_District_Aggregate;
  /** fetch data from the table: "kcca.Division" */
  kcca_Division: Array<Kcca_Division>;
  /** fetch aggregated fields from the table: "kcca.Division" */
  kcca_Division_aggregate: Kcca_Division_Aggregate;
  /** fetch data from the table: "kcca.Parish" */
  kcca_Parish: Array<Kcca_Parish>;
  /** fetch aggregated fields from the table: "kcca.Parish" */
  kcca_Parish_aggregate: Kcca_Parish_Aggregate;
  /** fetch data from the table: "kcca.Property" */
  kcca_Property: Array<Kcca_Property>;
  /** fetch data from the table: "kcca.PropertyFloors" */
  kcca_PropertyFloors: Array<Kcca_PropertyFloors>;
  /** fetch aggregated fields from the table: "kcca.PropertyFloors" */
  kcca_PropertyFloors_aggregate: Kcca_PropertyFloors_Aggregate;
  /** fetch data from the table: "kcca.PropertyNumberBedrooms" */
  kcca_PropertyNumberBedrooms: Array<Kcca_PropertyNumberBedrooms>;
  /** fetch aggregated fields from the table: "kcca.PropertyNumberBedrooms" */
  kcca_PropertyNumberBedrooms_aggregate: Kcca_PropertyNumberBedrooms_Aggregate;
  /** fetch data from the table: "kcca.PropertyRentedStatus" */
  kcca_PropertyRentedStatus: Array<Kcca_PropertyRentedStatus>;
  /** fetch aggregated fields from the table: "kcca.PropertyRentedStatus" */
  kcca_PropertyRentedStatus_aggregate: Kcca_PropertyRentedStatus_Aggregate;
  /** fetch data from the table: "kcca.PropertySecurity" */
  kcca_PropertySecurity: Array<Kcca_PropertySecurity>;
  /** fetch aggregated fields from the table: "kcca.PropertySecurity" */
  kcca_PropertySecurity_aggregate: Kcca_PropertySecurity_Aggregate;
  /** fetch data from the table: "kcca.PropertySubType" */
  kcca_PropertySubType: Array<Kcca_PropertySubType>;
  /** fetch aggregated fields from the table: "kcca.PropertySubType" */
  kcca_PropertySubType_aggregate: Kcca_PropertySubType_Aggregate;
  /** fetch data from the table: "kcca.PropertyType" */
  kcca_PropertyType: Array<Kcca_PropertyType>;
  /** fetch aggregated fields from the table: "kcca.PropertyType" */
  kcca_PropertyType_aggregate: Kcca_PropertyType_Aggregate;
  /** fetch aggregated fields from the table: "kcca.Property" */
  kcca_Property_aggregate: Kcca_Property_Aggregate;
  /** fetch data from the table: "kcca.RawProperties1" */
  kcca_RawProperties1: Array<Kcca_RawProperties1>;
  /** fetch aggregated fields from the table: "kcca.RawProperties1" */
  kcca_RawProperties1_aggregate: Kcca_RawProperties1_Aggregate;
  /** fetch data from the table: "kcca.RawProperties2" */
  kcca_RawProperties2: Array<Kcca_RawProperties2>;
  /** fetch aggregated fields from the table: "kcca.RawProperties2" */
  kcca_RawProperties2_aggregate: Kcca_RawProperties2_Aggregate;
  /** fetch data from the table: "kcca.RevenueType" */
  kcca_RevenueType: Array<Kcca_RevenueType>;
  /** fetch aggregated fields from the table: "kcca.RevenueType" */
  kcca_RevenueType_aggregate: Kcca_RevenueType_Aggregate;
  /** fetch data from the table: "kcca.SubCounty" */
  kcca_SubCounty: Array<Kcca_SubCounty>;
  /** fetch aggregated fields from the table: "kcca.SubCounty" */
  kcca_SubCounty_aggregate: Kcca_SubCounty_Aggregate;
  /** fetch data from the table: "kcca.TradingLicense" */
  kcca_TradingLicense: Array<Kcca_TradingLicense>;
  /** fetch aggregated fields from the table: "kcca.TradingLicense" */
  kcca_TradingLicense_aggregate: Kcca_TradingLicense_Aggregate;
  /** fetch data from the table: "kcca.Village" */
  kcca_Village: Array<Kcca_Village>;
  /** fetch aggregated fields from the table: "kcca.Village" */
  kcca_Village_aggregate: Kcca_Village_Aggregate;
  /** fetch data from the table: "mlhud.KCCA" */
  mlhud_KCCA: Array<Mlhud_Kcca>;
  /** fetch aggregated fields from the table: "mlhud.KCCA" */
  mlhud_KCCA_aggregate: Mlhud_Kcca_Aggregate;
  /** fetch data from the table: "mlhud.Mukono" */
  mlhud_Mukono: Array<Mlhud_Mukono>;
  /** fetch aggregated fields from the table: "mlhud.Mukono" */
  mlhud_Mukono_aggregate: Mlhud_Mukono_Aggregate;
  /** fetch data from the table: "mlhud.Properties" */
  mlhud_Properties: Array<Mlhud_Properties>;
  /** fetch aggregated fields from the table: "mlhud.Properties" */
  mlhud_Properties_aggregate: Mlhud_Properties_Aggregate;
  /** fetch data from the table: "mlhud.WakisoBusiro" */
  mlhud_WakisoBusiro: Array<Mlhud_WakisoBusiro>;
  /** fetch aggregated fields from the table: "mlhud.WakisoBusiro" */
  mlhud_WakisoBusiro_aggregate: Mlhud_WakisoBusiro_Aggregate;
  /** fetch data from the table: "mlhud.WakisoKyadondo" */
  mlhud_WakisoKyadondo: Array<Mlhud_WakisoKyadondo>;
  /** fetch aggregated fields from the table: "mlhud.WakisoKyadondo" */
  mlhud_WakisoKyadondo_aggregate: Mlhud_WakisoKyadondo_Aggregate;
  /** fetch data from the table: "nwsc.Customers" */
  nwsc_Customers: Array<Nwsc_Customers>;
  /** fetch data from the table: "nwsc.CustomersURATransactionsAndTrends" */
  nwsc_CustomersURATransactionsAndTrends: Array<Nwsc_CustomersUraTransactionsAndTrends>;
  /** fetch aggregated fields from the table: "nwsc.CustomersURATransactionsAndTrends" */
  nwsc_CustomersURATransactionsAndTrends_aggregate: Nwsc_CustomersUraTransactionsAndTrends_Aggregate;
  /** fetch aggregated fields from the table: "nwsc.Customers" */
  nwsc_Customers_aggregate: Nwsc_Customers_Aggregate;
  /** fetch data from the table: "nwsc.URATransactions" */
  nwsc_URATransactions: Array<Nwsc_UraTransactions>;
  /** fetch aggregated fields from the table: "nwsc.URATransactions" */
  nwsc_URATransactions_aggregate: Nwsc_UraTransactions_Aggregate;
  /** fetch data from the table: "ucc.UCC" */
  ucc_UCC: Array<Ucc_Ucc>;
  /** fetch aggregated fields from the table: "ucc.UCC" */
  ucc_UCC_aggregate: Ucc_Ucc_Aggregate;
  /** fetch data from the table: "ura.EmploymentIncomeDetail" */
  ura_EmploymentIncomeDetail: Array<Ura_EmploymentIncomeDetail>;
  /** fetch aggregated fields from the table: "ura.EmploymentIncomeDetail" */
  ura_EmploymentIncomeDetail_aggregate: Ura_EmploymentIncomeDetail_Aggregate;
  /** fetch data from the table: "ura.HoldingOfficers" */
  ura_HoldingOfficers: Array<Ura_HoldingOfficers>;
  /** fetch aggregated fields from the table: "ura.HoldingOfficers" */
  ura_HoldingOfficers_aggregate: Ura_HoldingOfficers_Aggregate;
  /** fetch data from the table: "ura.HoldingOwner" */
  ura_HoldingOwner: Array<Ura_HoldingOwner>;
  /** fetch aggregated fields from the table: "ura.HoldingOwner" */
  ura_HoldingOwner_aggregate: Ura_HoldingOwner_Aggregate;
  /** fetch data from the table: "ura.IncomeTaxHolding" */
  ura_IncomeTaxHolding: Array<Ura_IncomeTaxHolding>;
  /** fetch aggregated fields from the table: "ura.IncomeTaxHolding" */
  ura_IncomeTaxHolding_aggregate: Ura_IncomeTaxHolding_Aggregate;
  /** fetch data from the table: "ura.IncomeTaxSummaryIndividual" */
  ura_IncomeTaxSummaryIndividual: Array<Ura_IncomeTaxSummaryIndividual>;
  /** fetch aggregated fields from the table: "ura.IncomeTaxSummaryIndividual" */
  ura_IncomeTaxSummaryIndividual_aggregate: Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** fetch data from the table: "ura.IncomeTaxSummaryNonIndividual" */
  ura_IncomeTaxSummaryNonIndividual: Array<Ura_IncomeTaxSummaryNonIndividual>;
  /** fetch aggregated fields from the table: "ura.IncomeTaxSummaryNonIndividual" */
  ura_IncomeTaxSummaryNonIndividual_aggregate: Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** fetch data from the table: "ura.IndividualMortgage" */
  ura_IndividualMortgage: Array<Ura_IndividualMortgage>;
  /** fetch aggregated fields from the table: "ura.IndividualMortgage" */
  ura_IndividualMortgage_aggregate: Ura_IndividualMortgage_Aggregate;
  /** fetch data from the table: "ura.RMatchDetailed904" */
  ura_RMatchDetailed904: Array<Ura_RMatchDetailed904>;
  /** fetch aggregated fields from the table: "ura.RMatchDetailed904" */
  ura_RMatchDetailed904_aggregate: Ura_RMatchDetailed904_Aggregate;
  /** fetch data from the table: "ura.RMatchDetailed916" */
  ura_RMatchDetailed916: Array<Ura_RMatchDetailed916>;
  /** fetch aggregated fields from the table: "ura.RMatchDetailed916" */
  ura_RMatchDetailed916_aggregate: Ura_RMatchDetailed916_Aggregate;
  /** fetch data from the table: "ura.RMatchDetailed946" */
  ura_RMatchDetailed946: Array<Ura_RMatchDetailed946>;
  /** fetch aggregated fields from the table: "ura.RMatchDetailed946" */
  ura_RMatchDetailed946_aggregate: Ura_RMatchDetailed946_Aggregate;
  /** fetch data from the table: "ura.RMatchSummary904" */
  ura_RMatchSummary904: Array<Ura_RMatchSummary904>;
  /** fetch aggregated fields from the table: "ura.RMatchSummary904" */
  ura_RMatchSummary904_aggregate: Ura_RMatchSummary904_Aggregate;
  /** fetch data from the table: "ura.RMatchSummary916" */
  ura_RMatchSummary916: Array<Ura_RMatchSummary916>;
  /** fetch aggregated fields from the table: "ura.RMatchSummary916" */
  ura_RMatchSummary916_aggregate: Ura_RMatchSummary916_Aggregate;
  /** fetch data from the table: "ura.RMatchSummary946" */
  ura_RMatchSummary946: Array<Ura_RMatchSummary946>;
  /** fetch aggregated fields from the table: "ura.RMatchSummary946" */
  ura_RMatchSummary946_aggregate: Ura_RMatchSummary946_Aggregate;
  /** fetch data from the table: "ura.RPT_IndividualTaxSummaryData" */
  ura_RPT_IndividualTaxSummaryData: Array<Ura_Rpt_IndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "ura.RPT_IndividualTaxSummaryData" */
  ura_RPT_IndividualTaxSummaryData_aggregate: Ura_Rpt_IndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "ura.RPT_KccaCustomerRegistrationData" */
  ura_RPT_KccaCustomerRegistrationData: Array<Ura_Rpt_KccaCustomerRegistrationData>;
  /** fetch aggregated fields from the table: "ura.RPT_KccaCustomerRegistrationData" */
  ura_RPT_KccaCustomerRegistrationData_aggregate: Ura_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** fetch data from the table: "ura.RPT_KccaPropertyRegistrationData" */
  ura_RPT_KccaPropertyRegistrationData: Array<Ura_Rpt_KccaPropertyRegistrationData>;
  /** fetch aggregated fields from the table: "ura.RPT_KccaPropertyRegistrationData" */
  ura_RPT_KccaPropertyRegistrationData_aggregate: Ura_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** fetch data from the table: "ura.RPT_NonIndividualTaxSummaryData" */
  ura_RPT_NonIndividualTaxSummaryData: Array<Ura_Rpt_NonIndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "ura.RPT_NonIndividualTaxSummaryData" */
  ura_RPT_NonIndividualTaxSummaryData_aggregate: Ura_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "ura.RPT_RegIndividual" */
  ura_RPT_RegIndividual: Array<Ura_Rpt_RegIndividual>;
  /** fetch aggregated fields from the table: "ura.RPT_RegIndividual" */
  ura_RPT_RegIndividual_aggregate: Ura_Rpt_RegIndividual_Aggregate;
  /** fetch data from the table: "ura.RegAssociates" */
  ura_RegAssociates: Array<Ura_RegAssociates>;
  /** fetch aggregated fields from the table: "ura.RegAssociates" */
  ura_RegAssociates_aggregate: Ura_RegAssociates_Aggregate;
  /** fetch data from the table: "ura.RegIndividual" */
  ura_RegIndividual: Array<Ura_RegIndividual>;
  /** fetch aggregated fields from the table: "ura.RegIndividual" */
  ura_RegIndividual_aggregate: Ura_RegIndividual_Aggregate;
  /** fetch data from the table: "ura.RegNonIndividual" */
  ura_RegNonIndividual: Array<Ura_RegNonIndividual>;
  /** fetch aggregated fields from the table: "ura.RegNonIndividual" */
  ura_RegNonIndividual_aggregate: Ura_RegNonIndividual_Aggregate;
  /** fetch data from the table: "ura.RentExpenseDetails" */
  ura_RentExpenseDetails: Array<Ura_RentExpenseDetails>;
  /** fetch aggregated fields from the table: "ura.RentExpenseDetails" */
  ura_RentExpenseDetails_aggregate: Ura_RentExpenseDetails_Aggregate;
  /** fetch data from the table: "ura.RentalLandLordIncome" */
  ura_RentalLandLordIncome: Array<Ura_RentalLandLordIncome>;
  /** fetch aggregated fields from the table: "ura.RentalLandLordIncome" */
  ura_RentalLandLordIncome_aggregate: Ura_RentalLandLordIncome_Aggregate;
  /** fetch data from the table: "ura.RentalTenantPayments" */
  ura_RentalTenantPayments: Array<Ura_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "ura.RentalTenantPayments" */
  ura_RentalTenantPayments_aggregate: Ura_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "ura.RipoIndRentalIncSummary" */
  ura_RipoIndRentalIncSummary: Array<Ura_RipoIndRentalIncSummary>;
  /** fetch aggregated fields from the table: "ura.RipoIndRentalIncSummary" */
  ura_RipoIndRentalIncSummary_aggregate: Ura_RipoIndRentalIncSummary_Aggregate;
  /** fetch data from the table: "ura.Town" */
  ura_Town: Array<Ura_Town>;
  /** fetch aggregated fields from the table: "ura.Town" */
  ura_Town_aggregate: Ura_Town_Aggregate;
  /** fetch data from the table: "ursb.CompaniesAndBusinessNamesURSB" */
  ursb_CompaniesAndBusinessNamesURSB: Array<Ursb_CompaniesAndBusinessNamesUrsb>;
  /** fetch aggregated fields from the table: "ursb.CompaniesAndBusinessNamesURSB" */
  ursb_CompaniesAndBusinessNamesURSB_aggregate: Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
};

/** query root */
export type Query_RootKcca_AssociateTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_AssociateType_Order_By>>;
  where?: Maybe<Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_AssociateType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_AssociateType_Order_By>>;
  where?: Maybe<Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CountyArgs = {
  distinct_on?: Maybe<Array<Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_County_Order_By>>;
  where?: Maybe<Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_County_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_County_Order_By>>;
  where?: Maybe<Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerArgs = {
  distinct_on?: Maybe<Array<Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Customer_Order_By>>;
  where?: Maybe<Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerApplicantTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerApplicantType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerApplicantType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerApplicantType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerBusinessAssociateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessAssociate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerBusinessAssociate_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessAssociate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerBusinessTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerBusinessType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerCoinDeactivateReactiveArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Order_By>>;
  where?: Maybe<Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerCoinDeactivateReactive_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Order_By>>;
  where?: Maybe<Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerContactPersonArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerContactPerson_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerContactPerson_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerContactPerson_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerDocumentItemArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerDocumentItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerGuardianArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerGuardian_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerReferenceArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerReference_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerRegistrationReasonArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRegistrationReason_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerRegistrationReason_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRegistrationReason_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerRevenueAgentArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerRevenueAgent_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerType_Order_By>>;
  where?: Maybe<Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_CustomerType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerType_Order_By>>;
  where?: Maybe<Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Customer_Order_By>>;
  where?: Maybe<Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_DistrictArgs = {
  distinct_on?: Maybe<Array<Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_District_Order_By>>;
  where?: Maybe<Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_District_Order_By>>;
  where?: Maybe<Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_DivisionArgs = {
  distinct_on?: Maybe<Array<Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Division_Order_By>>;
  where?: Maybe<Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_Division_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Division_Order_By>>;
  where?: Maybe<Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_ParishArgs = {
  distinct_on?: Maybe<Array<Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Parish_Order_By>>;
  where?: Maybe<Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_Parish_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Parish_Order_By>>;
  where?: Maybe<Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyArgs = {
  distinct_on?: Maybe<Array<Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Property_Order_By>>;
  where?: Maybe<Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyFloorsArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyFloors_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyNumberBedroomsArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyNumberBedrooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyNumberBedrooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyNumberBedrooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyRentedStatusArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyRentedStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertySecurityArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertySecurity_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertySubTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertySubType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyType_Order_By>>;
  where?: Maybe<Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_PropertyType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyType_Order_By>>;
  where?: Maybe<Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_Property_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Property_Order_By>>;
  where?: Maybe<Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_RawProperties1Args = {
  distinct_on?: Maybe<Array<Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_RawProperties1_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_RawProperties2Args = {
  distinct_on?: Maybe<Array<Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_RawProperties2_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_RevenueTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RevenueType_Order_By>>;
  where?: Maybe<Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_RevenueType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RevenueType_Order_By>>;
  where?: Maybe<Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_SubCountyArgs = {
  distinct_on?: Maybe<Array<Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_SubCounty_Order_By>>;
  where?: Maybe<Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_SubCounty_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_SubCounty_Order_By>>;
  where?: Maybe<Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_TradingLicenseArgs = {
  distinct_on?: Maybe<Array<Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_TradingLicense_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_VillageArgs = {
  distinct_on?: Maybe<Array<Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Village_Order_By>>;
  where?: Maybe<Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootKcca_Village_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Village_Order_By>>;
  where?: Maybe<Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_KccaArgs = {
  distinct_on?: Maybe<Array<Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Kcca_Order_By>>;
  where?: Maybe<Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_Kcca_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Kcca_Order_By>>;
  where?: Maybe<Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_MukonoArgs = {
  distinct_on?: Maybe<Array<Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Mukono_Order_By>>;
  where?: Maybe<Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_Mukono_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Mukono_Order_By>>;
  where?: Maybe<Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_PropertiesArgs = {
  distinct_on?: Maybe<Array<Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Properties_Order_By>>;
  where?: Maybe<Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Properties_Order_By>>;
  where?: Maybe<Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_WakisoBusiroArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_WakisoBusiro_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_WakisoKyadondoArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootMlhud_WakisoKyadondo_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootNwsc_CustomersArgs = {
  distinct_on?: Maybe<Array<Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_Customers_Order_By>>;
  where?: Maybe<Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootNwsc_CustomersUraTransactionsAndTrendsArgs = {
  distinct_on?: Maybe<
    Array<Nwsc_CustomersUraTransactionsAndTrends_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_CustomersUraTransactionsAndTrends_Order_By>>;
  where?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp>;
};

/** query root */
export type Query_RootNwsc_CustomersUraTransactionsAndTrends_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Nwsc_CustomersUraTransactionsAndTrends_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_CustomersUraTransactionsAndTrends_Order_By>>;
  where?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp>;
};

/** query root */
export type Query_RootNwsc_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_Customers_Order_By>>;
  where?: Maybe<Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootNwsc_UraTransactionsArgs = {
  distinct_on?: Maybe<Array<Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootNwsc_UraTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootUcc_UccArgs = {
  distinct_on?: Maybe<Array<Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ucc_Ucc_Order_By>>;
  where?: Maybe<Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootUcc_Ucc_AggregateArgs = {
  distinct_on?: Maybe<Array<Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ucc_Ucc_Order_By>>;
  where?: Maybe<Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootUra_EmploymentIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Ura_EmploymentIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootUra_EmploymentIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_EmploymentIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootUra_HoldingOfficersArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootUra_HoldingOfficers_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootUra_HoldingOwnerArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootUra_HoldingOwner_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IncomeTaxHoldingArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IncomeTaxHolding_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IncomeTaxSummaryIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IncomeTaxSummaryIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IncomeTaxSummaryNonIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IncomeTaxSummaryNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IndividualMortgageArgs = {
  distinct_on?: Maybe<Array<Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootUra_IndividualMortgage_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchDetailed904Args = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed904_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchDetailed904_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed904_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchDetailed916Args = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed916_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchDetailed916_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed916_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchDetailed946Args = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed946_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed946_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchDetailed946_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed946_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed946_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchSummary904Args = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary904_Order_By>>;
  where?: Maybe<Ura_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchSummary904_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary904_Order_By>>;
  where?: Maybe<Ura_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchSummary916Args = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary916_Order_By>>;
  where?: Maybe<Ura_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchSummary916_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary916_Order_By>>;
  where?: Maybe<Ura_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchSummary946Args = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary946_Order_By>>;
  where?: Maybe<Ura_RMatchSummary946_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RMatchSummary946_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary946_Order_By>>;
  where?: Maybe<Ura_RMatchSummary946_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_IndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_IndividualTaxSummaryData_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_KccaCustomerRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Ura_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_KccaCustomerRegistrationData_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Ura_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_KccaPropertyRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Ura_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_KccaPropertyRegistrationData_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Ura_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_NonIndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_NonIndividualTaxSummaryData_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_RegIndividual_Order_By>>;
  where?: Maybe<Ura_Rpt_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Rpt_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_RegIndividual_Order_By>>;
  where?: Maybe<Ura_Rpt_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RegAssociatesArgs = {
  distinct_on?: Maybe<Array<Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegAssociates_Order_By>>;
  where?: Maybe<Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RegAssociates_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegAssociates_Order_By>>;
  where?: Maybe<Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegIndividual_Order_By>>;
  where?: Maybe<Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegIndividual_Order_By>>;
  where?: Maybe<Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RegNonIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RegNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RentExpenseDetailsArgs = {
  distinct_on?: Maybe<Array<Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RentExpenseDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RentalLandLordIncomeArgs = {
  distinct_on?: Maybe<Array<Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RentalLandLordIncome_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RipoIndRentalIncSummaryArgs = {
  distinct_on?: Maybe<Array<Ura_RipoIndRentalIncSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootUra_RipoIndRentalIncSummary_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RipoIndRentalIncSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootUra_TownArgs = {
  distinct_on?: Maybe<Array<Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Town_Order_By>>;
  where?: Maybe<Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootUra_Town_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Town_Order_By>>;
  where?: Maybe<Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootUrsb_CompaniesAndBusinessNamesUrsbArgs = {
  distinct_on?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Order_By>>;
  where?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
};

/** query root */
export type Query_RootUrsb_CompaniesAndBusinessNamesUrsb_AggregateArgs = {
  distinct_on?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Order_By>>;
  where?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "kcca.AssociateType" */
  kcca_AssociateType: Array<Kcca_AssociateType>;
  /** fetch aggregated fields from the table: "kcca.AssociateType" */
  kcca_AssociateType_aggregate: Kcca_AssociateType_Aggregate;
  /** fetch data from the table: "kcca.County" */
  kcca_County: Array<Kcca_County>;
  /** fetch aggregated fields from the table: "kcca.County" */
  kcca_County_aggregate: Kcca_County_Aggregate;
  /** fetch data from the table: "kcca.Customer" */
  kcca_Customer: Array<Kcca_Customer>;
  /** fetch data from the table: "kcca.CustomerApplicantType" */
  kcca_CustomerApplicantType: Array<Kcca_CustomerApplicantType>;
  /** fetch aggregated fields from the table: "kcca.CustomerApplicantType" */
  kcca_CustomerApplicantType_aggregate: Kcca_CustomerApplicantType_Aggregate;
  /** fetch data from the table: "kcca.CustomerBusinessAssociate" */
  kcca_CustomerBusinessAssociate: Array<Kcca_CustomerBusinessAssociate>;
  /** fetch aggregated fields from the table: "kcca.CustomerBusinessAssociate" */
  kcca_CustomerBusinessAssociate_aggregate: Kcca_CustomerBusinessAssociate_Aggregate;
  /** fetch data from the table: "kcca.CustomerBusinessType" */
  kcca_CustomerBusinessType: Array<Kcca_CustomerBusinessType>;
  /** fetch aggregated fields from the table: "kcca.CustomerBusinessType" */
  kcca_CustomerBusinessType_aggregate: Kcca_CustomerBusinessType_Aggregate;
  /** fetch data from the table: "kcca.CustomerCoinDeactivateReactive" */
  kcca_CustomerCoinDeactivateReactive: Array<Kcca_CustomerCoinDeactivateReactive>;
  /** fetch aggregated fields from the table: "kcca.CustomerCoinDeactivateReactive" */
  kcca_CustomerCoinDeactivateReactive_aggregate: Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** fetch data from the table: "kcca.CustomerContactPerson" */
  kcca_CustomerContactPerson: Array<Kcca_CustomerContactPerson>;
  /** fetch aggregated fields from the table: "kcca.CustomerContactPerson" */
  kcca_CustomerContactPerson_aggregate: Kcca_CustomerContactPerson_Aggregate;
  /** fetch data from the table: "kcca.CustomerDocumentItem" */
  kcca_CustomerDocumentItem: Array<Kcca_CustomerDocumentItem>;
  /** fetch aggregated fields from the table: "kcca.CustomerDocumentItem" */
  kcca_CustomerDocumentItem_aggregate: Kcca_CustomerDocumentItem_Aggregate;
  /** fetch data from the table: "kcca.CustomerGuardian" */
  kcca_CustomerGuardian: Array<Kcca_CustomerGuardian>;
  /** fetch aggregated fields from the table: "kcca.CustomerGuardian" */
  kcca_CustomerGuardian_aggregate: Kcca_CustomerGuardian_Aggregate;
  /** fetch data from the table: "kcca.CustomerReference" */
  kcca_CustomerReference: Array<Kcca_CustomerReference>;
  /** fetch aggregated fields from the table: "kcca.CustomerReference" */
  kcca_CustomerReference_aggregate: Kcca_CustomerReference_Aggregate;
  /** fetch data from the table: "kcca.CustomerRegistrationReason" */
  kcca_CustomerRegistrationReason: Array<Kcca_CustomerRegistrationReason>;
  /** fetch aggregated fields from the table: "kcca.CustomerRegistrationReason" */
  kcca_CustomerRegistrationReason_aggregate: Kcca_CustomerRegistrationReason_Aggregate;
  /** fetch data from the table: "kcca.CustomerRevenueAgent" */
  kcca_CustomerRevenueAgent: Array<Kcca_CustomerRevenueAgent>;
  /** fetch aggregated fields from the table: "kcca.CustomerRevenueAgent" */
  kcca_CustomerRevenueAgent_aggregate: Kcca_CustomerRevenueAgent_Aggregate;
  /** fetch data from the table: "kcca.CustomerType" */
  kcca_CustomerType: Array<Kcca_CustomerType>;
  /** fetch aggregated fields from the table: "kcca.CustomerType" */
  kcca_CustomerType_aggregate: Kcca_CustomerType_Aggregate;
  /** fetch aggregated fields from the table: "kcca.Customer" */
  kcca_Customer_aggregate: Kcca_Customer_Aggregate;
  /** fetch data from the table: "kcca.District" */
  kcca_District: Array<Kcca_District>;
  /** fetch aggregated fields from the table: "kcca.District" */
  kcca_District_aggregate: Kcca_District_Aggregate;
  /** fetch data from the table: "kcca.Division" */
  kcca_Division: Array<Kcca_Division>;
  /** fetch aggregated fields from the table: "kcca.Division" */
  kcca_Division_aggregate: Kcca_Division_Aggregate;
  /** fetch data from the table: "kcca.Parish" */
  kcca_Parish: Array<Kcca_Parish>;
  /** fetch aggregated fields from the table: "kcca.Parish" */
  kcca_Parish_aggregate: Kcca_Parish_Aggregate;
  /** fetch data from the table: "kcca.Property" */
  kcca_Property: Array<Kcca_Property>;
  /** fetch data from the table: "kcca.PropertyFloors" */
  kcca_PropertyFloors: Array<Kcca_PropertyFloors>;
  /** fetch aggregated fields from the table: "kcca.PropertyFloors" */
  kcca_PropertyFloors_aggregate: Kcca_PropertyFloors_Aggregate;
  /** fetch data from the table: "kcca.PropertyNumberBedrooms" */
  kcca_PropertyNumberBedrooms: Array<Kcca_PropertyNumberBedrooms>;
  /** fetch aggregated fields from the table: "kcca.PropertyNumberBedrooms" */
  kcca_PropertyNumberBedrooms_aggregate: Kcca_PropertyNumberBedrooms_Aggregate;
  /** fetch data from the table: "kcca.PropertyRentedStatus" */
  kcca_PropertyRentedStatus: Array<Kcca_PropertyRentedStatus>;
  /** fetch aggregated fields from the table: "kcca.PropertyRentedStatus" */
  kcca_PropertyRentedStatus_aggregate: Kcca_PropertyRentedStatus_Aggregate;
  /** fetch data from the table: "kcca.PropertySecurity" */
  kcca_PropertySecurity: Array<Kcca_PropertySecurity>;
  /** fetch aggregated fields from the table: "kcca.PropertySecurity" */
  kcca_PropertySecurity_aggregate: Kcca_PropertySecurity_Aggregate;
  /** fetch data from the table: "kcca.PropertySubType" */
  kcca_PropertySubType: Array<Kcca_PropertySubType>;
  /** fetch aggregated fields from the table: "kcca.PropertySubType" */
  kcca_PropertySubType_aggregate: Kcca_PropertySubType_Aggregate;
  /** fetch data from the table: "kcca.PropertyType" */
  kcca_PropertyType: Array<Kcca_PropertyType>;
  /** fetch aggregated fields from the table: "kcca.PropertyType" */
  kcca_PropertyType_aggregate: Kcca_PropertyType_Aggregate;
  /** fetch aggregated fields from the table: "kcca.Property" */
  kcca_Property_aggregate: Kcca_Property_Aggregate;
  /** fetch data from the table: "kcca.RawProperties1" */
  kcca_RawProperties1: Array<Kcca_RawProperties1>;
  /** fetch aggregated fields from the table: "kcca.RawProperties1" */
  kcca_RawProperties1_aggregate: Kcca_RawProperties1_Aggregate;
  /** fetch data from the table: "kcca.RawProperties2" */
  kcca_RawProperties2: Array<Kcca_RawProperties2>;
  /** fetch aggregated fields from the table: "kcca.RawProperties2" */
  kcca_RawProperties2_aggregate: Kcca_RawProperties2_Aggregate;
  /** fetch data from the table: "kcca.RevenueType" */
  kcca_RevenueType: Array<Kcca_RevenueType>;
  /** fetch aggregated fields from the table: "kcca.RevenueType" */
  kcca_RevenueType_aggregate: Kcca_RevenueType_Aggregate;
  /** fetch data from the table: "kcca.SubCounty" */
  kcca_SubCounty: Array<Kcca_SubCounty>;
  /** fetch aggregated fields from the table: "kcca.SubCounty" */
  kcca_SubCounty_aggregate: Kcca_SubCounty_Aggregate;
  /** fetch data from the table: "kcca.TradingLicense" */
  kcca_TradingLicense: Array<Kcca_TradingLicense>;
  /** fetch aggregated fields from the table: "kcca.TradingLicense" */
  kcca_TradingLicense_aggregate: Kcca_TradingLicense_Aggregate;
  /** fetch data from the table: "kcca.Village" */
  kcca_Village: Array<Kcca_Village>;
  /** fetch aggregated fields from the table: "kcca.Village" */
  kcca_Village_aggregate: Kcca_Village_Aggregate;
  /** fetch data from the table: "mlhud.KCCA" */
  mlhud_KCCA: Array<Mlhud_Kcca>;
  /** fetch aggregated fields from the table: "mlhud.KCCA" */
  mlhud_KCCA_aggregate: Mlhud_Kcca_Aggregate;
  /** fetch data from the table: "mlhud.Mukono" */
  mlhud_Mukono: Array<Mlhud_Mukono>;
  /** fetch aggregated fields from the table: "mlhud.Mukono" */
  mlhud_Mukono_aggregate: Mlhud_Mukono_Aggregate;
  /** fetch data from the table: "mlhud.Properties" */
  mlhud_Properties: Array<Mlhud_Properties>;
  /** fetch aggregated fields from the table: "mlhud.Properties" */
  mlhud_Properties_aggregate: Mlhud_Properties_Aggregate;
  /** fetch data from the table: "mlhud.WakisoBusiro" */
  mlhud_WakisoBusiro: Array<Mlhud_WakisoBusiro>;
  /** fetch aggregated fields from the table: "mlhud.WakisoBusiro" */
  mlhud_WakisoBusiro_aggregate: Mlhud_WakisoBusiro_Aggregate;
  /** fetch data from the table: "mlhud.WakisoKyadondo" */
  mlhud_WakisoKyadondo: Array<Mlhud_WakisoKyadondo>;
  /** fetch aggregated fields from the table: "mlhud.WakisoKyadondo" */
  mlhud_WakisoKyadondo_aggregate: Mlhud_WakisoKyadondo_Aggregate;
  /** fetch data from the table: "nwsc.Customers" */
  nwsc_Customers: Array<Nwsc_Customers>;
  /** fetch data from the table: "nwsc.CustomersURATransactionsAndTrends" */
  nwsc_CustomersURATransactionsAndTrends: Array<Nwsc_CustomersUraTransactionsAndTrends>;
  /** fetch aggregated fields from the table: "nwsc.CustomersURATransactionsAndTrends" */
  nwsc_CustomersURATransactionsAndTrends_aggregate: Nwsc_CustomersUraTransactionsAndTrends_Aggregate;
  /** fetch aggregated fields from the table: "nwsc.Customers" */
  nwsc_Customers_aggregate: Nwsc_Customers_Aggregate;
  /** fetch data from the table: "nwsc.URATransactions" */
  nwsc_URATransactions: Array<Nwsc_UraTransactions>;
  /** fetch aggregated fields from the table: "nwsc.URATransactions" */
  nwsc_URATransactions_aggregate: Nwsc_UraTransactions_Aggregate;
  /** fetch data from the table: "ucc.UCC" */
  ucc_UCC: Array<Ucc_Ucc>;
  /** fetch aggregated fields from the table: "ucc.UCC" */
  ucc_UCC_aggregate: Ucc_Ucc_Aggregate;
  /** fetch data from the table: "ura.EmploymentIncomeDetail" */
  ura_EmploymentIncomeDetail: Array<Ura_EmploymentIncomeDetail>;
  /** fetch aggregated fields from the table: "ura.EmploymentIncomeDetail" */
  ura_EmploymentIncomeDetail_aggregate: Ura_EmploymentIncomeDetail_Aggregate;
  /** fetch data from the table: "ura.HoldingOfficers" */
  ura_HoldingOfficers: Array<Ura_HoldingOfficers>;
  /** fetch aggregated fields from the table: "ura.HoldingOfficers" */
  ura_HoldingOfficers_aggregate: Ura_HoldingOfficers_Aggregate;
  /** fetch data from the table: "ura.HoldingOwner" */
  ura_HoldingOwner: Array<Ura_HoldingOwner>;
  /** fetch aggregated fields from the table: "ura.HoldingOwner" */
  ura_HoldingOwner_aggregate: Ura_HoldingOwner_Aggregate;
  /** fetch data from the table: "ura.IncomeTaxHolding" */
  ura_IncomeTaxHolding: Array<Ura_IncomeTaxHolding>;
  /** fetch aggregated fields from the table: "ura.IncomeTaxHolding" */
  ura_IncomeTaxHolding_aggregate: Ura_IncomeTaxHolding_Aggregate;
  /** fetch data from the table: "ura.IncomeTaxSummaryIndividual" */
  ura_IncomeTaxSummaryIndividual: Array<Ura_IncomeTaxSummaryIndividual>;
  /** fetch aggregated fields from the table: "ura.IncomeTaxSummaryIndividual" */
  ura_IncomeTaxSummaryIndividual_aggregate: Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** fetch data from the table: "ura.IncomeTaxSummaryNonIndividual" */
  ura_IncomeTaxSummaryNonIndividual: Array<Ura_IncomeTaxSummaryNonIndividual>;
  /** fetch aggregated fields from the table: "ura.IncomeTaxSummaryNonIndividual" */
  ura_IncomeTaxSummaryNonIndividual_aggregate: Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** fetch data from the table: "ura.IndividualMortgage" */
  ura_IndividualMortgage: Array<Ura_IndividualMortgage>;
  /** fetch aggregated fields from the table: "ura.IndividualMortgage" */
  ura_IndividualMortgage_aggregate: Ura_IndividualMortgage_Aggregate;
  /** fetch data from the table: "ura.RMatchDetailed904" */
  ura_RMatchDetailed904: Array<Ura_RMatchDetailed904>;
  /** fetch aggregated fields from the table: "ura.RMatchDetailed904" */
  ura_RMatchDetailed904_aggregate: Ura_RMatchDetailed904_Aggregate;
  /** fetch data from the table: "ura.RMatchDetailed916" */
  ura_RMatchDetailed916: Array<Ura_RMatchDetailed916>;
  /** fetch aggregated fields from the table: "ura.RMatchDetailed916" */
  ura_RMatchDetailed916_aggregate: Ura_RMatchDetailed916_Aggregate;
  /** fetch data from the table: "ura.RMatchDetailed946" */
  ura_RMatchDetailed946: Array<Ura_RMatchDetailed946>;
  /** fetch aggregated fields from the table: "ura.RMatchDetailed946" */
  ura_RMatchDetailed946_aggregate: Ura_RMatchDetailed946_Aggregate;
  /** fetch data from the table: "ura.RMatchSummary904" */
  ura_RMatchSummary904: Array<Ura_RMatchSummary904>;
  /** fetch aggregated fields from the table: "ura.RMatchSummary904" */
  ura_RMatchSummary904_aggregate: Ura_RMatchSummary904_Aggregate;
  /** fetch data from the table: "ura.RMatchSummary916" */
  ura_RMatchSummary916: Array<Ura_RMatchSummary916>;
  /** fetch aggregated fields from the table: "ura.RMatchSummary916" */
  ura_RMatchSummary916_aggregate: Ura_RMatchSummary916_Aggregate;
  /** fetch data from the table: "ura.RMatchSummary946" */
  ura_RMatchSummary946: Array<Ura_RMatchSummary946>;
  /** fetch aggregated fields from the table: "ura.RMatchSummary946" */
  ura_RMatchSummary946_aggregate: Ura_RMatchSummary946_Aggregate;
  /** fetch data from the table: "ura.RPT_IndividualTaxSummaryData" */
  ura_RPT_IndividualTaxSummaryData: Array<Ura_Rpt_IndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "ura.RPT_IndividualTaxSummaryData" */
  ura_RPT_IndividualTaxSummaryData_aggregate: Ura_Rpt_IndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "ura.RPT_KccaCustomerRegistrationData" */
  ura_RPT_KccaCustomerRegistrationData: Array<Ura_Rpt_KccaCustomerRegistrationData>;
  /** fetch aggregated fields from the table: "ura.RPT_KccaCustomerRegistrationData" */
  ura_RPT_KccaCustomerRegistrationData_aggregate: Ura_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** fetch data from the table: "ura.RPT_KccaPropertyRegistrationData" */
  ura_RPT_KccaPropertyRegistrationData: Array<Ura_Rpt_KccaPropertyRegistrationData>;
  /** fetch aggregated fields from the table: "ura.RPT_KccaPropertyRegistrationData" */
  ura_RPT_KccaPropertyRegistrationData_aggregate: Ura_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** fetch data from the table: "ura.RPT_NonIndividualTaxSummaryData" */
  ura_RPT_NonIndividualTaxSummaryData: Array<Ura_Rpt_NonIndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "ura.RPT_NonIndividualTaxSummaryData" */
  ura_RPT_NonIndividualTaxSummaryData_aggregate: Ura_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "ura.RPT_RegIndividual" */
  ura_RPT_RegIndividual: Array<Ura_Rpt_RegIndividual>;
  /** fetch aggregated fields from the table: "ura.RPT_RegIndividual" */
  ura_RPT_RegIndividual_aggregate: Ura_Rpt_RegIndividual_Aggregate;
  /** fetch data from the table: "ura.RegAssociates" */
  ura_RegAssociates: Array<Ura_RegAssociates>;
  /** fetch aggregated fields from the table: "ura.RegAssociates" */
  ura_RegAssociates_aggregate: Ura_RegAssociates_Aggregate;
  /** fetch data from the table: "ura.RegIndividual" */
  ura_RegIndividual: Array<Ura_RegIndividual>;
  /** fetch aggregated fields from the table: "ura.RegIndividual" */
  ura_RegIndividual_aggregate: Ura_RegIndividual_Aggregate;
  /** fetch data from the table: "ura.RegNonIndividual" */
  ura_RegNonIndividual: Array<Ura_RegNonIndividual>;
  /** fetch aggregated fields from the table: "ura.RegNonIndividual" */
  ura_RegNonIndividual_aggregate: Ura_RegNonIndividual_Aggregate;
  /** fetch data from the table: "ura.RentExpenseDetails" */
  ura_RentExpenseDetails: Array<Ura_RentExpenseDetails>;
  /** fetch aggregated fields from the table: "ura.RentExpenseDetails" */
  ura_RentExpenseDetails_aggregate: Ura_RentExpenseDetails_Aggregate;
  /** fetch data from the table: "ura.RentalLandLordIncome" */
  ura_RentalLandLordIncome: Array<Ura_RentalLandLordIncome>;
  /** fetch aggregated fields from the table: "ura.RentalLandLordIncome" */
  ura_RentalLandLordIncome_aggregate: Ura_RentalLandLordIncome_Aggregate;
  /** fetch data from the table: "ura.RentalTenantPayments" */
  ura_RentalTenantPayments: Array<Ura_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "ura.RentalTenantPayments" */
  ura_RentalTenantPayments_aggregate: Ura_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "ura.RipoIndRentalIncSummary" */
  ura_RipoIndRentalIncSummary: Array<Ura_RipoIndRentalIncSummary>;
  /** fetch aggregated fields from the table: "ura.RipoIndRentalIncSummary" */
  ura_RipoIndRentalIncSummary_aggregate: Ura_RipoIndRentalIncSummary_Aggregate;
  /** fetch data from the table: "ura.Town" */
  ura_Town: Array<Ura_Town>;
  /** fetch aggregated fields from the table: "ura.Town" */
  ura_Town_aggregate: Ura_Town_Aggregate;
  /** fetch data from the table: "ursb.CompaniesAndBusinessNamesURSB" */
  ursb_CompaniesAndBusinessNamesURSB: Array<Ursb_CompaniesAndBusinessNamesUrsb>;
  /** fetch aggregated fields from the table: "ursb.CompaniesAndBusinessNamesURSB" */
  ursb_CompaniesAndBusinessNamesURSB_aggregate: Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
};

/** subscription root */
export type Subscription_RootKcca_AssociateTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_AssociateType_Order_By>>;
  where?: Maybe<Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_AssociateType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_AssociateType_Order_By>>;
  where?: Maybe<Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CountyArgs = {
  distinct_on?: Maybe<Array<Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_County_Order_By>>;
  where?: Maybe<Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_County_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_County_Order_By>>;
  where?: Maybe<Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerArgs = {
  distinct_on?: Maybe<Array<Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Customer_Order_By>>;
  where?: Maybe<Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerApplicantTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerApplicantType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Kcca_CustomerApplicantType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerApplicantType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerApplicantType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Kcca_CustomerApplicantType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerBusinessAssociateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessAssociate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerBusinessAssociate_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessAssociate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerBusinessTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerBusinessType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Kcca_CustomerBusinessType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerCoinDeactivateReactiveArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Order_By>>;
  where?: Maybe<Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerCoinDeactivateReactive_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Kcca_CustomerCoinDeactivateReactive_Order_By>>;
    where?: Maybe<Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootKcca_CustomerContactPersonArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerContactPerson_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Kcca_CustomerContactPerson_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerContactPerson_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerContactPerson_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Kcca_CustomerContactPerson_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerDocumentItemArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerDocumentItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerGuardianArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerGuardian_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerReferenceArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerReference_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerRegistrationReasonArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRegistrationReason_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerRegistrationReason_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRegistrationReason_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerRevenueAgentArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerRevenueAgent_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerType_Order_By>>;
  where?: Maybe<Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_CustomerType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_CustomerType_Order_By>>;
  where?: Maybe<Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Customer_Order_By>>;
  where?: Maybe<Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_DistrictArgs = {
  distinct_on?: Maybe<Array<Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_District_Order_By>>;
  where?: Maybe<Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_District_Order_By>>;
  where?: Maybe<Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_DivisionArgs = {
  distinct_on?: Maybe<Array<Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Division_Order_By>>;
  where?: Maybe<Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_Division_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Division_Order_By>>;
  where?: Maybe<Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_ParishArgs = {
  distinct_on?: Maybe<Array<Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Parish_Order_By>>;
  where?: Maybe<Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_Parish_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Parish_Order_By>>;
  where?: Maybe<Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyArgs = {
  distinct_on?: Maybe<Array<Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Property_Order_By>>;
  where?: Maybe<Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyFloorsArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyFloors_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyNumberBedroomsArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyNumberBedrooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyNumberBedrooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyNumberBedrooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyRentedStatusArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyRentedStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertySecurityArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertySecurity_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertySubTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertySubType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyType_Order_By>>;
  where?: Maybe<Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_PropertyType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_PropertyType_Order_By>>;
  where?: Maybe<Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_Property_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Property_Order_By>>;
  where?: Maybe<Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_RawProperties1Args = {
  distinct_on?: Maybe<Array<Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_RawProperties1_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_RawProperties2Args = {
  distinct_on?: Maybe<Array<Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_RawProperties2_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_RevenueTypeArgs = {
  distinct_on?: Maybe<Array<Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RevenueType_Order_By>>;
  where?: Maybe<Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_RevenueType_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_RevenueType_Order_By>>;
  where?: Maybe<Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_SubCountyArgs = {
  distinct_on?: Maybe<Array<Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_SubCounty_Order_By>>;
  where?: Maybe<Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_SubCounty_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_SubCounty_Order_By>>;
  where?: Maybe<Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_TradingLicenseArgs = {
  distinct_on?: Maybe<Array<Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_TradingLicense_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_VillageArgs = {
  distinct_on?: Maybe<Array<Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Village_Order_By>>;
  where?: Maybe<Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootKcca_Village_AggregateArgs = {
  distinct_on?: Maybe<Array<Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Kcca_Village_Order_By>>;
  where?: Maybe<Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_KccaArgs = {
  distinct_on?: Maybe<Array<Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Kcca_Order_By>>;
  where?: Maybe<Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_Kcca_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Kcca_Order_By>>;
  where?: Maybe<Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_MukonoArgs = {
  distinct_on?: Maybe<Array<Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Mukono_Order_By>>;
  where?: Maybe<Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_Mukono_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Mukono_Order_By>>;
  where?: Maybe<Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_PropertiesArgs = {
  distinct_on?: Maybe<Array<Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Properties_Order_By>>;
  where?: Maybe<Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_Properties_Order_By>>;
  where?: Maybe<Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_WakisoBusiroArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_WakisoBusiro_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_WakisoKyadondoArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMlhud_WakisoKyadondo_AggregateArgs = {
  distinct_on?: Maybe<Array<Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootNwsc_CustomersArgs = {
  distinct_on?: Maybe<Array<Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_Customers_Order_By>>;
  where?: Maybe<Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootNwsc_CustomersUraTransactionsAndTrendsArgs = {
  distinct_on?: Maybe<
    Array<Nwsc_CustomersUraTransactionsAndTrends_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_CustomersUraTransactionsAndTrends_Order_By>>;
  where?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootNwsc_CustomersUraTransactionsAndTrends_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Nwsc_CustomersUraTransactionsAndTrends_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Nwsc_CustomersUraTransactionsAndTrends_Order_By>>;
    where?: Maybe<Nwsc_CustomersUraTransactionsAndTrends_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootNwsc_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_Customers_Order_By>>;
  where?: Maybe<Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootNwsc_UraTransactionsArgs = {
  distinct_on?: Maybe<Array<Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootNwsc_UraTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUcc_UccArgs = {
  distinct_on?: Maybe<Array<Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ucc_Ucc_Order_By>>;
  where?: Maybe<Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUcc_Ucc_AggregateArgs = {
  distinct_on?: Maybe<Array<Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ucc_Ucc_Order_By>>;
  where?: Maybe<Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_EmploymentIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Ura_EmploymentIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_EmploymentIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_EmploymentIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_HoldingOfficersArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_HoldingOfficers_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_HoldingOwnerArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_HoldingOwner_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IncomeTaxHoldingArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IncomeTaxHolding_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IncomeTaxSummaryIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IncomeTaxSummaryIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IncomeTaxSummaryNonIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IncomeTaxSummaryNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
  where?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IndividualMortgageArgs = {
  distinct_on?: Maybe<Array<Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_IndividualMortgage_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchDetailed904Args = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed904_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchDetailed904_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed904_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchDetailed916Args = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed916_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchDetailed916_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed916_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchDetailed946Args = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed946_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed946_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchDetailed946_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchDetailed946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchDetailed946_Order_By>>;
  where?: Maybe<Ura_RMatchDetailed946_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchSummary904Args = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary904_Order_By>>;
  where?: Maybe<Ura_RMatchSummary904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchSummary904_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary904_Order_By>>;
  where?: Maybe<Ura_RMatchSummary904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchSummary916Args = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary916_Order_By>>;
  where?: Maybe<Ura_RMatchSummary916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchSummary916_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary916_Order_By>>;
  where?: Maybe<Ura_RMatchSummary916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchSummary946Args = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary946_Order_By>>;
  where?: Maybe<Ura_RMatchSummary946_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RMatchSummary946_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RMatchSummary946_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RMatchSummary946_Order_By>>;
  where?: Maybe<Ura_RMatchSummary946_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Rpt_IndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Rpt_IndividualTaxSummaryData_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Rpt_KccaCustomerRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Ura_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Rpt_KccaCustomerRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Ura_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Ura_Rpt_KccaCustomerRegistrationData_Order_By>>;
    where?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootUra_Rpt_KccaPropertyRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Ura_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Rpt_KccaPropertyRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Ura_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Ura_Rpt_KccaPropertyRegistrationData_Order_By>>;
    where?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootUra_Rpt_NonIndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Rpt_NonIndividualTaxSummaryData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Ura_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Order_By>>;
    where?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootUra_Rpt_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_RegIndividual_Order_By>>;
  where?: Maybe<Ura_Rpt_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Rpt_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_Rpt_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Rpt_RegIndividual_Order_By>>;
  where?: Maybe<Ura_Rpt_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RegAssociatesArgs = {
  distinct_on?: Maybe<Array<Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegAssociates_Order_By>>;
  where?: Maybe<Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RegAssociates_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegAssociates_Order_By>>;
  where?: Maybe<Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegIndividual_Order_By>>;
  where?: Maybe<Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegIndividual_Order_By>>;
  where?: Maybe<Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RegNonIndividualArgs = {
  distinct_on?: Maybe<Array<Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RegNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RentExpenseDetailsArgs = {
  distinct_on?: Maybe<Array<Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RentExpenseDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RentalLandLordIncomeArgs = {
  distinct_on?: Maybe<Array<Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Ura_RentalLandLordIncome_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RentalLandLordIncome_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Ura_RentalLandLordIncome_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Ura_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Ura_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RipoIndRentalIncSummaryArgs = {
  distinct_on?: Maybe<Array<Ura_RipoIndRentalIncSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_RipoIndRentalIncSummary_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_RipoIndRentalIncSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_TownArgs = {
  distinct_on?: Maybe<Array<Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Town_Order_By>>;
  where?: Maybe<Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUra_Town_AggregateArgs = {
  distinct_on?: Maybe<Array<Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ura_Town_Order_By>>;
  where?: Maybe<Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUrsb_CompaniesAndBusinessNamesUrsbArgs = {
  distinct_on?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Order_By>>;
  where?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUrsb_CompaniesAndBusinessNamesUrsb_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Order_By>>;
    where?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** columns and relationships of "ucc.UCC" */
export type Ucc_Ucc = {
  __typename?: "ucc_UCC";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["float8"]>;
  Customer_ID?: Maybe<Scalars["float8"]>;
  First_Name?: Maybe<Scalars["String"]>;
  ID_Number?: Maybe<Scalars["String"]>;
  ID_Type?: Maybe<Scalars["String"]>;
  KCCA_Mobile?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  UCC_NIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ucc.UCC" */
export type Ucc_Ucc_Aggregate = {
  __typename?: "ucc_UCC_aggregate";
  aggregate?: Maybe<Ucc_Ucc_Aggregate_Fields>;
  nodes: Array<Ucc_Ucc>;
};

/** aggregate fields of "ucc.UCC" */
export type Ucc_Ucc_Aggregate_Fields = {
  __typename?: "ucc_UCC_aggregate_fields";
  avg?: Maybe<Ucc_Ucc_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ucc_Ucc_Max_Fields>;
  min?: Maybe<Ucc_Ucc_Min_Fields>;
  stddev?: Maybe<Ucc_Ucc_Stddev_Fields>;
  stddev_pop?: Maybe<Ucc_Ucc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ucc_Ucc_Stddev_Samp_Fields>;
  sum?: Maybe<Ucc_Ucc_Sum_Fields>;
  var_pop?: Maybe<Ucc_Ucc_Var_Pop_Fields>;
  var_samp?: Maybe<Ucc_Ucc_Var_Samp_Fields>;
  variance?: Maybe<Ucc_Ucc_Variance_Fields>;
};

/** aggregate fields of "ucc.UCC" */
export type Ucc_Ucc_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ucc_Ucc_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ucc.UCC" */
export type Ucc_Ucc_Aggregate_Order_By = {
  avg?: Maybe<Ucc_Ucc_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ucc_Ucc_Max_Order_By>;
  min?: Maybe<Ucc_Ucc_Min_Order_By>;
  stddev?: Maybe<Ucc_Ucc_Stddev_Order_By>;
  stddev_pop?: Maybe<Ucc_Ucc_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ucc_Ucc_Stddev_Samp_Order_By>;
  sum?: Maybe<Ucc_Ucc_Sum_Order_By>;
  var_pop?: Maybe<Ucc_Ucc_Var_Pop_Order_By>;
  var_samp?: Maybe<Ucc_Ucc_Var_Samp_Order_By>;
  variance?: Maybe<Ucc_Ucc_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ucc.UCC" */
export type Ucc_Ucc_Arr_Rel_Insert_Input = {
  data: Array<Ucc_Ucc_Insert_Input>;
};

/** aggregate avg on columns */
export type Ucc_Ucc_Avg_Fields = {
  __typename?: "ucc_UCC_avg_fields";
  Count?: Maybe<Scalars["Float"]>;
  Customer_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Avg_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ucc.UCC". All fields are combined with a logical 'AND'. */
export type Ucc_Ucc_Bool_Exp = {
  Carrier?: Maybe<String_Comparison_Exp>;
  Count?: Maybe<Float8_Comparison_Exp>;
  Customer_ID?: Maybe<Float8_Comparison_Exp>;
  First_Name?: Maybe<String_Comparison_Exp>;
  ID_Number?: Maybe<String_Comparison_Exp>;
  ID_Type?: Maybe<String_Comparison_Exp>;
  KCCA_Mobile?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  UCC_NIN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ucc_Ucc_Bool_Exp>>>;
  _not?: Maybe<Ucc_Ucc_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ucc_Ucc_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ucc.UCC" */
export type Ucc_Ucc_Inc_Input = {
  Count?: Maybe<Scalars["float8"]>;
  Customer_ID?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ucc.UCC" */
export type Ucc_Ucc_Insert_Input = {
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["float8"]>;
  Customer_ID?: Maybe<Scalars["float8"]>;
  First_Name?: Maybe<Scalars["String"]>;
  ID_Number?: Maybe<Scalars["String"]>;
  ID_Type?: Maybe<Scalars["String"]>;
  KCCA_Mobile?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  UCC_NIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ucc_Ucc_Max_Fields = {
  __typename?: "ucc_UCC_max_fields";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["float8"]>;
  Customer_ID?: Maybe<Scalars["float8"]>;
  First_Name?: Maybe<Scalars["String"]>;
  ID_Number?: Maybe<Scalars["String"]>;
  ID_Type?: Maybe<Scalars["String"]>;
  KCCA_Mobile?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  UCC_NIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Max_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  ID_Number?: Maybe<Order_By>;
  ID_Type?: Maybe<Order_By>;
  KCCA_Mobile?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  UCC_NIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ucc_Ucc_Min_Fields = {
  __typename?: "ucc_UCC_min_fields";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["float8"]>;
  Customer_ID?: Maybe<Scalars["float8"]>;
  First_Name?: Maybe<Scalars["String"]>;
  ID_Number?: Maybe<Scalars["String"]>;
  ID_Type?: Maybe<Scalars["String"]>;
  KCCA_Mobile?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  UCC_NIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Min_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  ID_Number?: Maybe<Order_By>;
  ID_Type?: Maybe<Order_By>;
  KCCA_Mobile?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  UCC_NIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ucc.UCC" */
export type Ucc_Ucc_Mutation_Response = {
  __typename?: "ucc_UCC_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ucc_Ucc>;
};

/** input type for inserting object relation for remote table "ucc.UCC" */
export type Ucc_Ucc_Obj_Rel_Insert_Input = {
  data: Ucc_Ucc_Insert_Input;
};

/** ordering options when selecting data from "ucc.UCC" */
export type Ucc_Ucc_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  First_Name?: Maybe<Order_By>;
  ID_Number?: Maybe<Order_By>;
  ID_Type?: Maybe<Order_By>;
  KCCA_Mobile?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  UCC_NIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ucc.UCC" */
export enum Ucc_Ucc_Select_Column {
  /** column name */
  Carrier = "Carrier",
  /** column name */
  Count = "Count",
  /** column name */
  CustomerId = "Customer_ID",
  /** column name */
  FirstName = "First_Name",
  /** column name */
  IdNumber = "ID_Number",
  /** column name */
  IdType = "ID_Type",
  /** column name */
  KccaMobile = "KCCA_Mobile",
  /** column name */
  Surname = "Surname",
  /** column name */
  UccNin = "UCC_NIN",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ucc.UCC" */
export type Ucc_Ucc_Set_Input = {
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["float8"]>;
  Customer_ID?: Maybe<Scalars["float8"]>;
  First_Name?: Maybe<Scalars["String"]>;
  ID_Number?: Maybe<Scalars["String"]>;
  ID_Type?: Maybe<Scalars["String"]>;
  KCCA_Mobile?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  UCC_NIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ucc_Ucc_Stddev_Fields = {
  __typename?: "ucc_UCC_stddev_fields";
  Count?: Maybe<Scalars["Float"]>;
  Customer_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Stddev_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ucc_Ucc_Stddev_Pop_Fields = {
  __typename?: "ucc_UCC_stddev_pop_fields";
  Count?: Maybe<Scalars["Float"]>;
  Customer_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Stddev_Pop_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ucc_Ucc_Stddev_Samp_Fields = {
  __typename?: "ucc_UCC_stddev_samp_fields";
  Count?: Maybe<Scalars["Float"]>;
  Customer_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Stddev_Samp_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ucc_Ucc_Sum_Fields = {
  __typename?: "ucc_UCC_sum_fields";
  Count?: Maybe<Scalars["float8"]>;
  Customer_ID?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Sum_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ucc_Ucc_Var_Pop_Fields = {
  __typename?: "ucc_UCC_var_pop_fields";
  Count?: Maybe<Scalars["Float"]>;
  Customer_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Var_Pop_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ucc_Ucc_Var_Samp_Fields = {
  __typename?: "ucc_UCC_var_samp_fields";
  Count?: Maybe<Scalars["Float"]>;
  Customer_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Var_Samp_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ucc_Ucc_Variance_Fields = {
  __typename?: "ucc_UCC_variance_fields";
  Count?: Maybe<Scalars["Float"]>;
  Customer_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ucc.UCC" */
export type Ucc_Ucc_Variance_Order_By = {
  Count?: Maybe<Order_By>;
  Customer_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail = {
  __typename?: "ura_EmploymentIncomeDetail";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["float8"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["float8"]>;
  BENEFIT_VALUE?: Maybe<Scalars["float8"]>;
  EMPLOYER_NAME?: Maybe<Scalars["String"]>;
  EMPLOYER_TIN?: Maybe<Scalars["String"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["float8"]>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Aggregate = {
  __typename?: "ura_EmploymentIncomeDetail_aggregate";
  aggregate?: Maybe<Ura_EmploymentIncomeDetail_Aggregate_Fields>;
  nodes: Array<Ura_EmploymentIncomeDetail>;
};

/** aggregate fields of "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Aggregate_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_aggregate_fields";
  avg?: Maybe<Ura_EmploymentIncomeDetail_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_EmploymentIncomeDetail_Max_Fields>;
  min?: Maybe<Ura_EmploymentIncomeDetail_Min_Fields>;
  stddev?: Maybe<Ura_EmploymentIncomeDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_EmploymentIncomeDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_EmploymentIncomeDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_EmploymentIncomeDetail_Sum_Fields>;
  var_pop?: Maybe<Ura_EmploymentIncomeDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_EmploymentIncomeDetail_Var_Samp_Fields>;
  variance?: Maybe<Ura_EmploymentIncomeDetail_Variance_Fields>;
};

/** aggregate fields of "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_EmploymentIncomeDetail_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Aggregate_Order_By = {
  avg?: Maybe<Ura_EmploymentIncomeDetail_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_EmploymentIncomeDetail_Max_Order_By>;
  min?: Maybe<Ura_EmploymentIncomeDetail_Min_Order_By>;
  stddev?: Maybe<Ura_EmploymentIncomeDetail_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_EmploymentIncomeDetail_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_EmploymentIncomeDetail_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_EmploymentIncomeDetail_Sum_Order_By>;
  var_pop?: Maybe<Ura_EmploymentIncomeDetail_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_EmploymentIncomeDetail_Var_Samp_Order_By>;
  variance?: Maybe<Ura_EmploymentIncomeDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Arr_Rel_Insert_Input = {
  data: Array<Ura_EmploymentIncomeDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_EmploymentIncomeDetail_Avg_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_avg_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["Float"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["Float"]>;
  BENEFIT_VALUE?: Maybe<Scalars["Float"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["Float"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Avg_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.EmploymentIncomeDetail". All fields are combined with a logical 'AND'. */
export type Ura_EmploymentIncomeDetail_Bool_Exp = {
  ALLW_EXMPT_INCTAX?: Maybe<Float8_Comparison_Exp>;
  ALLW_NOT_EXEMPT?: Maybe<Float8_Comparison_Exp>;
  BENEFIT_VALUE?: Maybe<Float8_Comparison_Exp>;
  EMPLOYER_NAME?: Maybe<String_Comparison_Exp>;
  EMPLOYER_TIN?: Maybe<String_Comparison_Exp>;
  PROFIT_LIEU_SALARY?: Maybe<Float8_Comparison_Exp>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<String_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  SALARY_EXCLD_ALLW?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_EmploymentIncomeDetail_Bool_Exp>>>;
  _not?: Maybe<Ura_EmploymentIncomeDetail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_EmploymentIncomeDetail_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Inc_Input = {
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["float8"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["float8"]>;
  BENEFIT_VALUE?: Maybe<Scalars["float8"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["float8"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Insert_Input = {
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["float8"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["float8"]>;
  BENEFIT_VALUE?: Maybe<Scalars["float8"]>;
  EMPLOYER_NAME?: Maybe<Scalars["String"]>;
  EMPLOYER_TIN?: Maybe<Scalars["String"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["float8"]>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_EmploymentIncomeDetail_Max_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_max_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["float8"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["float8"]>;
  BENEFIT_VALUE?: Maybe<Scalars["float8"]>;
  EMPLOYER_NAME?: Maybe<Scalars["String"]>;
  EMPLOYER_TIN?: Maybe<Scalars["String"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["float8"]>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Max_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  EMPLOYER_NAME?: Maybe<Order_By>;
  EMPLOYER_TIN?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_EmploymentIncomeDetail_Min_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_min_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["float8"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["float8"]>;
  BENEFIT_VALUE?: Maybe<Scalars["float8"]>;
  EMPLOYER_NAME?: Maybe<Scalars["String"]>;
  EMPLOYER_TIN?: Maybe<Scalars["String"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["float8"]>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Min_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  EMPLOYER_NAME?: Maybe<Order_By>;
  EMPLOYER_TIN?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Mutation_Response = {
  __typename?: "ura_EmploymentIncomeDetail_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_EmploymentIncomeDetail>;
};

/** input type for inserting object relation for remote table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Obj_Rel_Insert_Input = {
  data: Ura_EmploymentIncomeDetail_Insert_Input;
};

/** ordering options when selecting data from "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  EMPLOYER_NAME?: Maybe<Order_By>;
  EMPLOYER_TIN?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.EmploymentIncomeDetail" */
export enum Ura_EmploymentIncomeDetail_Select_Column {
  /** column name */
  AllwExmptInctax = "ALLW_EXMPT_INCTAX",
  /** column name */
  AllwNotExempt = "ALLW_NOT_EXEMPT",
  /** column name */
  BenefitValue = "BENEFIT_VALUE",
  /** column name */
  EmployerName = "EMPLOYER_NAME",
  /** column name */
  EmployerTin = "EMPLOYER_TIN",
  /** column name */
  ProfitLieuSalary = "PROFIT_LIEU_SALARY",
  /** column name */
  RtnEmploymentIncmId = "RTN_EMPLOYMENT_INCM_ID",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  SalaryExcldAllw = "SALARY_EXCLD_ALLW",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Set_Input = {
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["float8"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["float8"]>;
  BENEFIT_VALUE?: Maybe<Scalars["float8"]>;
  EMPLOYER_NAME?: Maybe<Scalars["String"]>;
  EMPLOYER_TIN?: Maybe<Scalars["String"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["float8"]>;
  RTN_EMPLOYMENT_INCM_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_EmploymentIncomeDetail_Stddev_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_stddev_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["Float"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["Float"]>;
  BENEFIT_VALUE?: Maybe<Scalars["Float"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["Float"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Stddev_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_EmploymentIncomeDetail_Stddev_Pop_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_stddev_pop_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["Float"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["Float"]>;
  BENEFIT_VALUE?: Maybe<Scalars["Float"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["Float"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Stddev_Pop_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_EmploymentIncomeDetail_Stddev_Samp_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_stddev_samp_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["Float"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["Float"]>;
  BENEFIT_VALUE?: Maybe<Scalars["Float"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["Float"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Stddev_Samp_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_EmploymentIncomeDetail_Sum_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_sum_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["float8"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["float8"]>;
  BENEFIT_VALUE?: Maybe<Scalars["float8"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["float8"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Sum_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_EmploymentIncomeDetail_Var_Pop_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_var_pop_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["Float"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["Float"]>;
  BENEFIT_VALUE?: Maybe<Scalars["Float"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["Float"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Var_Pop_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_EmploymentIncomeDetail_Var_Samp_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_var_samp_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["Float"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["Float"]>;
  BENEFIT_VALUE?: Maybe<Scalars["Float"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["Float"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Var_Samp_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_EmploymentIncomeDetail_Variance_Fields = {
  __typename?: "ura_EmploymentIncomeDetail_variance_fields";
  ALLW_EXMPT_INCTAX?: Maybe<Scalars["Float"]>;
  ALLW_NOT_EXEMPT?: Maybe<Scalars["Float"]>;
  BENEFIT_VALUE?: Maybe<Scalars["Float"]>;
  PROFIT_LIEU_SALARY?: Maybe<Scalars["Float"]>;
  SALARY_EXCLD_ALLW?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.EmploymentIncomeDetail" */
export type Ura_EmploymentIncomeDetail_Variance_Order_By = {
  ALLW_EXMPT_INCTAX?: Maybe<Order_By>;
  ALLW_NOT_EXEMPT?: Maybe<Order_By>;
  BENEFIT_VALUE?: Maybe<Order_By>;
  PROFIT_LIEU_SALARY?: Maybe<Order_By>;
  SALARY_EXCLD_ALLW?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.HoldingOfficers" */
export type Ura_HoldingOfficers = {
  __typename?: "ura_HoldingOfficers";
  ADDRESS?: Maybe<Scalars["String"]>;
  DESIGNATION?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["bigint"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Aggregate = {
  __typename?: "ura_HoldingOfficers_aggregate";
  aggregate?: Maybe<Ura_HoldingOfficers_Aggregate_Fields>;
  nodes: Array<Ura_HoldingOfficers>;
};

/** aggregate fields of "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Aggregate_Fields = {
  __typename?: "ura_HoldingOfficers_aggregate_fields";
  avg?: Maybe<Ura_HoldingOfficers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_HoldingOfficers_Max_Fields>;
  min?: Maybe<Ura_HoldingOfficers_Min_Fields>;
  stddev?: Maybe<Ura_HoldingOfficers_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_HoldingOfficers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_HoldingOfficers_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_HoldingOfficers_Sum_Fields>;
  var_pop?: Maybe<Ura_HoldingOfficers_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_HoldingOfficers_Var_Samp_Fields>;
  variance?: Maybe<Ura_HoldingOfficers_Variance_Fields>;
};

/** aggregate fields of "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_HoldingOfficers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Aggregate_Order_By = {
  avg?: Maybe<Ura_HoldingOfficers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_HoldingOfficers_Max_Order_By>;
  min?: Maybe<Ura_HoldingOfficers_Min_Order_By>;
  stddev?: Maybe<Ura_HoldingOfficers_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_HoldingOfficers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_HoldingOfficers_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_HoldingOfficers_Sum_Order_By>;
  var_pop?: Maybe<Ura_HoldingOfficers_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_HoldingOfficers_Var_Samp_Order_By>;
  variance?: Maybe<Ura_HoldingOfficers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Arr_Rel_Insert_Input = {
  data: Array<Ura_HoldingOfficers_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_HoldingOfficers_Avg_Fields = {
  __typename?: "ura_HoldingOfficers_avg_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Avg_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.HoldingOfficers". All fields are combined with a logical 'AND'. */
export type Ura_HoldingOfficers_Bool_Exp = {
  ADDRESS?: Maybe<String_Comparison_Exp>;
  DESIGNATION?: Maybe<String_Comparison_Exp>;
  NAME?: Maybe<String_Comparison_Exp>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Bigint_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  TIN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_HoldingOfficers_Bool_Exp>>>;
  _not?: Maybe<Ura_HoldingOfficers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_HoldingOfficers_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Inc_Input = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Insert_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  DESIGNATION?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["bigint"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_HoldingOfficers_Max_Fields = {
  __typename?: "ura_HoldingOfficers_max_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  DESIGNATION?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["bigint"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Max_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  DESIGNATION?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_HoldingOfficers_Min_Fields = {
  __typename?: "ura_HoldingOfficers_min_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  DESIGNATION?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["bigint"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Min_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  DESIGNATION?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Mutation_Response = {
  __typename?: "ura_HoldingOfficers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_HoldingOfficers>;
};

/** input type for inserting object relation for remote table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Obj_Rel_Insert_Input = {
  data: Ura_HoldingOfficers_Insert_Input;
};

/** ordering options when selecting data from "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  DESIGNATION?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.HoldingOfficers" */
export enum Ura_HoldingOfficers_Select_Column {
  /** column name */
  Address = "ADDRESS",
  /** column name */
  Designation = "DESIGNATION",
  /** column name */
  Name = "NAME",
  /** column name */
  RtnHoldingOfficersDtlId = "RTN_HOLDING_OFFICERS_DTL_ID",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  Tin = "TIN",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Set_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  DESIGNATION?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["bigint"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_HoldingOfficers_Stddev_Fields = {
  __typename?: "ura_HoldingOfficers_stddev_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Stddev_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_HoldingOfficers_Stddev_Pop_Fields = {
  __typename?: "ura_HoldingOfficers_stddev_pop_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Stddev_Pop_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_HoldingOfficers_Stddev_Samp_Fields = {
  __typename?: "ura_HoldingOfficers_stddev_samp_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Stddev_Samp_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_HoldingOfficers_Sum_Fields = {
  __typename?: "ura_HoldingOfficers_sum_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Sum_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_HoldingOfficers_Var_Pop_Fields = {
  __typename?: "ura_HoldingOfficers_var_pop_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Var_Pop_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_HoldingOfficers_Var_Samp_Fields = {
  __typename?: "ura_HoldingOfficers_var_samp_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Var_Samp_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_HoldingOfficers_Variance_Fields = {
  __typename?: "ura_HoldingOfficers_variance_fields";
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.HoldingOfficers" */
export type Ura_HoldingOfficers_Variance_Order_By = {
  RTN_HOLDING_OFFICERS_DTL_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.HoldingOwner" */
export type Ura_HoldingOwner = {
  __typename?: "ura_HoldingOwner";
  ADDRESS?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  PER_SHARE?: Maybe<Scalars["float8"]>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.HoldingOwner" */
export type Ura_HoldingOwner_Aggregate = {
  __typename?: "ura_HoldingOwner_aggregate";
  aggregate?: Maybe<Ura_HoldingOwner_Aggregate_Fields>;
  nodes: Array<Ura_HoldingOwner>;
};

/** aggregate fields of "ura.HoldingOwner" */
export type Ura_HoldingOwner_Aggregate_Fields = {
  __typename?: "ura_HoldingOwner_aggregate_fields";
  avg?: Maybe<Ura_HoldingOwner_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_HoldingOwner_Max_Fields>;
  min?: Maybe<Ura_HoldingOwner_Min_Fields>;
  stddev?: Maybe<Ura_HoldingOwner_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_HoldingOwner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_HoldingOwner_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_HoldingOwner_Sum_Fields>;
  var_pop?: Maybe<Ura_HoldingOwner_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_HoldingOwner_Var_Samp_Fields>;
  variance?: Maybe<Ura_HoldingOwner_Variance_Fields>;
};

/** aggregate fields of "ura.HoldingOwner" */
export type Ura_HoldingOwner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_HoldingOwner_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Aggregate_Order_By = {
  avg?: Maybe<Ura_HoldingOwner_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_HoldingOwner_Max_Order_By>;
  min?: Maybe<Ura_HoldingOwner_Min_Order_By>;
  stddev?: Maybe<Ura_HoldingOwner_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_HoldingOwner_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_HoldingOwner_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_HoldingOwner_Sum_Order_By>;
  var_pop?: Maybe<Ura_HoldingOwner_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_HoldingOwner_Var_Samp_Order_By>;
  variance?: Maybe<Ura_HoldingOwner_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Arr_Rel_Insert_Input = {
  data: Array<Ura_HoldingOwner_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_HoldingOwner_Avg_Fields = {
  __typename?: "ura_HoldingOwner_avg_fields";
  PER_SHARE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Avg_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.HoldingOwner". All fields are combined with a logical 'AND'. */
export type Ura_HoldingOwner_Bool_Exp = {
  ADDRESS?: Maybe<String_Comparison_Exp>;
  NAME?: Maybe<String_Comparison_Exp>;
  PER_SHARE?: Maybe<Float8_Comparison_Exp>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<String_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  TIN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_HoldingOwner_Bool_Exp>>>;
  _not?: Maybe<Ura_HoldingOwner_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_HoldingOwner_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Inc_Input = {
  PER_SHARE?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Insert_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  PER_SHARE?: Maybe<Scalars["float8"]>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_HoldingOwner_Max_Fields = {
  __typename?: "ura_HoldingOwner_max_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  PER_SHARE?: Maybe<Scalars["float8"]>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Max_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  PER_SHARE?: Maybe<Order_By>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_HoldingOwner_Min_Fields = {
  __typename?: "ura_HoldingOwner_min_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  PER_SHARE?: Maybe<Scalars["float8"]>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Min_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  PER_SHARE?: Maybe<Order_By>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Mutation_Response = {
  __typename?: "ura_HoldingOwner_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_HoldingOwner>;
};

/** input type for inserting object relation for remote table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Obj_Rel_Insert_Input = {
  data: Ura_HoldingOwner_Insert_Input;
};

/** ordering options when selecting data from "ura.HoldingOwner" */
export type Ura_HoldingOwner_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  PER_SHARE?: Maybe<Order_By>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.HoldingOwner" */
export enum Ura_HoldingOwner_Select_Column {
  /** column name */
  Address = "ADDRESS",
  /** column name */
  Name = "NAME",
  /** column name */
  PerShare = "PER_SHARE",
  /** column name */
  RtnHoldingOwnersDtlId = "RTN_HOLDING_OWNERS_DTL_ID",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  Tin = "TIN",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Set_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  PER_SHARE?: Maybe<Scalars["float8"]>;
  RTN_HOLDING_OWNERS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_HoldingOwner_Stddev_Fields = {
  __typename?: "ura_HoldingOwner_stddev_fields";
  PER_SHARE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Stddev_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_HoldingOwner_Stddev_Pop_Fields = {
  __typename?: "ura_HoldingOwner_stddev_pop_fields";
  PER_SHARE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Stddev_Pop_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_HoldingOwner_Stddev_Samp_Fields = {
  __typename?: "ura_HoldingOwner_stddev_samp_fields";
  PER_SHARE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Stddev_Samp_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_HoldingOwner_Sum_Fields = {
  __typename?: "ura_HoldingOwner_sum_fields";
  PER_SHARE?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Sum_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_HoldingOwner_Var_Pop_Fields = {
  __typename?: "ura_HoldingOwner_var_pop_fields";
  PER_SHARE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Var_Pop_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_HoldingOwner_Var_Samp_Fields = {
  __typename?: "ura_HoldingOwner_var_samp_fields";
  PER_SHARE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Var_Samp_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_HoldingOwner_Variance_Fields = {
  __typename?: "ura_HoldingOwner_variance_fields";
  PER_SHARE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.HoldingOwner" */
export type Ura_HoldingOwner_Variance_Order_By = {
  PER_SHARE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding = {
  __typename?: "ura_IncomeTaxHolding";
  HOLDING_CMPNY_ADDRESS?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_NAME?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_TIN?: Maybe<Scalars["String"]>;
  HOLDING_COMPANY_STATUS?: Maybe<Scalars["String"]>;
  HOLDING_STATUS?: Maybe<Scalars["String"]>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Aggregate = {
  __typename?: "ura_IncomeTaxHolding_aggregate";
  aggregate?: Maybe<Ura_IncomeTaxHolding_Aggregate_Fields>;
  nodes: Array<Ura_IncomeTaxHolding>;
};

/** aggregate fields of "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Aggregate_Fields = {
  __typename?: "ura_IncomeTaxHolding_aggregate_fields";
  avg?: Maybe<Ura_IncomeTaxHolding_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_IncomeTaxHolding_Max_Fields>;
  min?: Maybe<Ura_IncomeTaxHolding_Min_Fields>;
  stddev?: Maybe<Ura_IncomeTaxHolding_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_IncomeTaxHolding_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_IncomeTaxHolding_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_IncomeTaxHolding_Sum_Fields>;
  var_pop?: Maybe<Ura_IncomeTaxHolding_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_IncomeTaxHolding_Var_Samp_Fields>;
  variance?: Maybe<Ura_IncomeTaxHolding_Variance_Fields>;
};

/** aggregate fields of "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_IncomeTaxHolding_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Aggregate_Order_By = {
  avg?: Maybe<Ura_IncomeTaxHolding_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_IncomeTaxHolding_Max_Order_By>;
  min?: Maybe<Ura_IncomeTaxHolding_Min_Order_By>;
  stddev?: Maybe<Ura_IncomeTaxHolding_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_IncomeTaxHolding_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_IncomeTaxHolding_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_IncomeTaxHolding_Sum_Order_By>;
  var_pop?: Maybe<Ura_IncomeTaxHolding_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_IncomeTaxHolding_Var_Samp_Order_By>;
  variance?: Maybe<Ura_IncomeTaxHolding_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Arr_Rel_Insert_Input = {
  data: Array<Ura_IncomeTaxHolding_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_IncomeTaxHolding_Avg_Fields = {
  __typename?: "ura_IncomeTaxHolding_avg_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Avg_Order_By = {
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.IncomeTaxHolding". All fields are combined with a logical 'AND'. */
export type Ura_IncomeTaxHolding_Bool_Exp = {
  HOLDING_CMPNY_ADDRESS?: Maybe<String_Comparison_Exp>;
  HOLDING_CMPNY_NAME?: Maybe<String_Comparison_Exp>;
  HOLDING_CMPNY_TIN?: Maybe<String_Comparison_Exp>;
  HOLDING_COMPANY_STATUS?: Maybe<String_Comparison_Exp>;
  HOLDING_STATUS?: Maybe<String_Comparison_Exp>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<String_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_IncomeTaxHolding_Bool_Exp>>>;
  _not?: Maybe<Ura_IncomeTaxHolding_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_IncomeTaxHolding_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Inc_Input = {
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Insert_Input = {
  HOLDING_CMPNY_ADDRESS?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_NAME?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_TIN?: Maybe<Scalars["String"]>;
  HOLDING_COMPANY_STATUS?: Maybe<Scalars["String"]>;
  HOLDING_STATUS?: Maybe<Scalars["String"]>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_IncomeTaxHolding_Max_Fields = {
  __typename?: "ura_IncomeTaxHolding_max_fields";
  HOLDING_CMPNY_ADDRESS?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_NAME?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_TIN?: Maybe<Scalars["String"]>;
  HOLDING_COMPANY_STATUS?: Maybe<Scalars["String"]>;
  HOLDING_STATUS?: Maybe<Scalars["String"]>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Max_Order_By = {
  HOLDING_CMPNY_ADDRESS?: Maybe<Order_By>;
  HOLDING_CMPNY_NAME?: Maybe<Order_By>;
  HOLDING_CMPNY_TIN?: Maybe<Order_By>;
  HOLDING_COMPANY_STATUS?: Maybe<Order_By>;
  HOLDING_STATUS?: Maybe<Order_By>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_IncomeTaxHolding_Min_Fields = {
  __typename?: "ura_IncomeTaxHolding_min_fields";
  HOLDING_CMPNY_ADDRESS?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_NAME?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_TIN?: Maybe<Scalars["String"]>;
  HOLDING_COMPANY_STATUS?: Maybe<Scalars["String"]>;
  HOLDING_STATUS?: Maybe<Scalars["String"]>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Min_Order_By = {
  HOLDING_CMPNY_ADDRESS?: Maybe<Order_By>;
  HOLDING_CMPNY_NAME?: Maybe<Order_By>;
  HOLDING_CMPNY_TIN?: Maybe<Order_By>;
  HOLDING_COMPANY_STATUS?: Maybe<Order_By>;
  HOLDING_STATUS?: Maybe<Order_By>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Mutation_Response = {
  __typename?: "ura_IncomeTaxHolding_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_IncomeTaxHolding>;
};

/** input type for inserting object relation for remote table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Obj_Rel_Insert_Input = {
  data: Ura_IncomeTaxHolding_Insert_Input;
};

/** ordering options when selecting data from "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Order_By = {
  HOLDING_CMPNY_ADDRESS?: Maybe<Order_By>;
  HOLDING_CMPNY_NAME?: Maybe<Order_By>;
  HOLDING_CMPNY_TIN?: Maybe<Order_By>;
  HOLDING_COMPANY_STATUS?: Maybe<Order_By>;
  HOLDING_STATUS?: Maybe<Order_By>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.IncomeTaxHolding" */
export enum Ura_IncomeTaxHolding_Select_Column {
  /** column name */
  HoldingCmpnyAddress = "HOLDING_CMPNY_ADDRESS",
  /** column name */
  HoldingCmpnyName = "HOLDING_CMPNY_NAME",
  /** column name */
  HoldingCmpnyTin = "HOLDING_CMPNY_TIN",
  /** column name */
  HoldingCompanyStatus = "HOLDING_COMPANY_STATUS",
  /** column name */
  HoldingStatus = "HOLDING_STATUS",
  /** column name */
  RtnHoldingStatusDtlId = "RTN_HOLDING_STATUS_DTL_ID",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Set_Input = {
  HOLDING_CMPNY_ADDRESS?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_NAME?: Maybe<Scalars["String"]>;
  HOLDING_CMPNY_TIN?: Maybe<Scalars["String"]>;
  HOLDING_COMPANY_STATUS?: Maybe<Scalars["String"]>;
  HOLDING_STATUS?: Maybe<Scalars["String"]>;
  RTN_HOLDING_STATUS_DTL_ID?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_IncomeTaxHolding_Stddev_Fields = {
  __typename?: "ura_IncomeTaxHolding_stddev_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Stddev_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_IncomeTaxHolding_Stddev_Pop_Fields = {
  __typename?: "ura_IncomeTaxHolding_stddev_pop_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Stddev_Pop_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_IncomeTaxHolding_Stddev_Samp_Fields = {
  __typename?: "ura_IncomeTaxHolding_stddev_samp_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Stddev_Samp_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_IncomeTaxHolding_Sum_Fields = {
  __typename?: "ura_IncomeTaxHolding_sum_fields";
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Sum_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_IncomeTaxHolding_Var_Pop_Fields = {
  __typename?: "ura_IncomeTaxHolding_var_pop_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Var_Pop_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_IncomeTaxHolding_Var_Samp_Fields = {
  __typename?: "ura_IncomeTaxHolding_var_samp_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Var_Samp_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_IncomeTaxHolding_Variance_Fields = {
  __typename?: "ura_IncomeTaxHolding_variance_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.IncomeTaxHolding" */
export type Ura_IncomeTaxHolding_Variance_Order_By = {
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual = {
  __typename?: "ura_IncomeTaxSummaryIndividual";
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Aggregate = {
  __typename?: "ura_IncomeTaxSummaryIndividual_aggregate";
  aggregate?: Maybe<Ura_IncomeTaxSummaryIndividual_Aggregate_Fields>;
  nodes: Array<Ura_IncomeTaxSummaryIndividual>;
};

/** aggregate fields of "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Aggregate_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_aggregate_fields";
  avg?: Maybe<Ura_IncomeTaxSummaryIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_IncomeTaxSummaryIndividual_Max_Fields>;
  min?: Maybe<Ura_IncomeTaxSummaryIndividual_Min_Fields>;
  stddev?: Maybe<Ura_IncomeTaxSummaryIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_IncomeTaxSummaryIndividual_Sum_Fields>;
  var_pop?: Maybe<Ura_IncomeTaxSummaryIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_IncomeTaxSummaryIndividual_Var_Samp_Fields>;
  variance?: Maybe<Ura_IncomeTaxSummaryIndividual_Variance_Fields>;
};

/** aggregate fields of "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_IncomeTaxSummaryIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Aggregate_Order_By = {
  avg?: Maybe<Ura_IncomeTaxSummaryIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_IncomeTaxSummaryIndividual_Max_Order_By>;
  min?: Maybe<Ura_IncomeTaxSummaryIndividual_Min_Order_By>;
  stddev?: Maybe<Ura_IncomeTaxSummaryIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_IncomeTaxSummaryIndividual_Sum_Order_By>;
  var_pop?: Maybe<Ura_IncomeTaxSummaryIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_IncomeTaxSummaryIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Ura_IncomeTaxSummaryIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Arr_Rel_Insert_Input = {
  data: Array<Ura_IncomeTaxSummaryIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_IncomeTaxSummaryIndividual_Avg_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_avg_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Avg_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.IncomeTaxSummaryIndividual". All fields are combined with a logical 'AND'. */
export type Ura_IncomeTaxSummaryIndividual_Bool_Exp = {
  EMPLYMNT_INCOME?: Maybe<Float8_Comparison_Exp>;
  EMPLYMNT_RATE?: Maybe<Float8_Comparison_Exp>;
  EMPLYMNT_TAX?: Maybe<Float8_Comparison_Exp>;
  IS_AMENDMENT?: Maybe<String_Comparison_Exp>;
  IS_RESIDENT?: Maybe<String_Comparison_Exp>;
  PROPINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  PROPINC_RATE?: Maybe<Float8_Comparison_Exp>;
  PROPINC_TAX?: Maybe<Float8_Comparison_Exp>;
  RENTINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  RENTINC_RATE?: Maybe<Float8_Comparison_Exp>;
  RENTINC_TAX?: Maybe<Float8_Comparison_Exp>;
  RTN_DT?: Maybe<Date_Comparison_Exp>;
  RTN_FROM_DT?: Maybe<Date_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_PERIOD_YEAR?: Maybe<String_Comparison_Exp>;
  RTN_TO_DT?: Maybe<Date_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  Tot_INCOME?: Maybe<Float8_Comparison_Exp>;
  Tot_RATE?: Maybe<Float8_Comparison_Exp>;
  Tot_TAX?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_IncomeTaxSummaryIndividual_Bool_Exp>>>;
  _not?: Maybe<Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_IncomeTaxSummaryIndividual_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Inc_Input = {
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Insert_Input = {
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_IncomeTaxSummaryIndividual_Max_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_max_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Max_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_IncomeTaxSummaryIndividual_Min_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_min_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Min_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Mutation_Response = {
  __typename?: "ura_IncomeTaxSummaryIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_IncomeTaxSummaryIndividual>;
};

/** input type for inserting object relation for remote table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Obj_Rel_Insert_Input = {
  data: Ura_IncomeTaxSummaryIndividual_Insert_Input;
};

/** ordering options when selecting data from "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.IncomeTaxSummaryIndividual" */
export enum Ura_IncomeTaxSummaryIndividual_Select_Column {
  /** column name */
  EmplymntIncome = "EMPLYMNT_INCOME",
  /** column name */
  EmplymntRate = "EMPLYMNT_RATE",
  /** column name */
  EmplymntTax = "EMPLYMNT_TAX",
  /** column name */
  IsAmendment = "IS_AMENDMENT",
  /** column name */
  IsResident = "IS_RESIDENT",
  /** column name */
  PropincIncome = "PROPINC_INCOME",
  /** column name */
  PropincRate = "PROPINC_RATE",
  /** column name */
  PropincTax = "PROPINC_TAX",
  /** column name */
  RentincIncome = "RENTINC_INCOME",
  /** column name */
  RentincRate = "RENTINC_RATE",
  /** column name */
  RentincTax = "RENTINC_TAX",
  /** column name */
  RtnDt = "RTN_DT",
  /** column name */
  RtnFromDt = "RTN_FROM_DT",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnPeriodYear = "RTN_PERIOD_YEAR",
  /** column name */
  RtnToDt = "RTN_TO_DT",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  TotIncome = "Tot_INCOME",
  /** column name */
  TotRate = "Tot_RATE",
  /** column name */
  TotTax = "Tot_TAX",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Set_Input = {
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_IncomeTaxSummaryIndividual_Stddev_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_stddev_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Stddev_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_stddev_pop_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_stddev_samp_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_IncomeTaxSummaryIndividual_Sum_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_sum_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Sum_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_IncomeTaxSummaryIndividual_Var_Pop_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_var_pop_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Var_Pop_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_IncomeTaxSummaryIndividual_Var_Samp_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_var_samp_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Var_Samp_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_IncomeTaxSummaryIndividual_Variance_Fields = {
  __typename?: "ura_IncomeTaxSummaryIndividual_variance_fields";
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.IncomeTaxSummaryIndividual" */
export type Ura_IncomeTaxSummaryIndividual_Variance_Order_By = {
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Aggregate = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_aggregate";
  aggregate?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Aggregate_Fields>;
  nodes: Array<Ura_IncomeTaxSummaryNonIndividual>;
};

/** aggregate fields of "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Aggregate_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_aggregate_fields";
  avg?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Max_Fields>;
  min?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Min_Fields>;
  stddev?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Sum_Fields>;
  var_pop?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Variance_Fields>;
};

/** aggregate fields of "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_IncomeTaxSummaryNonIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Max_Order_By>;
  min?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Min_Order_By>;
  stddev?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Arr_Rel_Insert_Input = {
  data: Array<Ura_IncomeTaxSummaryNonIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Avg_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_avg_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Avg_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.IncomeTaxSummaryNonIndividual". All fields are combined with a logical 'AND'. */
export type Ura_IncomeTaxSummaryNonIndividual_Bool_Exp = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Float8_Comparison_Exp>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Float8_Comparison_Exp>;
  ADMINISTRATING_EXP?: Maybe<Float8_Comparison_Exp>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Float8_Comparison_Exp>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Float8_Comparison_Exp>;
  ASSESSED_RENTL_LOSS?: Maybe<Float8_Comparison_Exp>;
  BAD_DEBT_WRITE_OFF?: Maybe<Float8_Comparison_Exp>;
  BALANCE_CARRY_FORWARD?: Maybe<String_Comparison_Exp>;
  BALANCE_PREV_PERIOD?: Maybe<String_Comparison_Exp>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Float8_Comparison_Exp>;
  Business_INCOME?: Maybe<Float8_Comparison_Exp>;
  Business_RATE?: Maybe<Float8_Comparison_Exp>;
  Business_TAX?: Maybe<Float8_Comparison_Exp>;
  COST_OF_SALES?: Maybe<Float8_Comparison_Exp>;
  DEBENTURE?: Maybe<Float8_Comparison_Exp>;
  DEFER_TAX_LIABILITY?: Maybe<Float8_Comparison_Exp>;
  DEPRECIATION?: Maybe<Float8_Comparison_Exp>;
  EQUITY_SHARE?: Maybe<Float8_Comparison_Exp>;
  FACTORY_RENT_RATES?: Maybe<Float8_Comparison_Exp>;
  FINANCE_EXP?: Maybe<Float8_Comparison_Exp>;
  FIXED_DEPOSIT?: Maybe<Float8_Comparison_Exp>;
  GOVT_SECURITIES?: Maybe<Float8_Comparison_Exp>;
  GROSS_PROFIT?: Maybe<Float8_Comparison_Exp>;
  GROSS_RENTAL_INCOME?: Maybe<Float8_Comparison_Exp>;
  IS_AMENDMENT?: Maybe<String_Comparison_Exp>;
  IS_RESIDENT?: Maybe<String_Comparison_Exp>;
  LAND_BUILD?: Maybe<String_Comparison_Exp>;
  NON_ALLOWBL_EXP?: Maybe<Float8_Comparison_Exp>;
  OPERATING_EXP?: Maybe<Float8_Comparison_Exp>;
  OTHER_INVESTMENTS?: Maybe<Float8_Comparison_Exp>;
  OTHR_ALLOWBL_EXP?: Maybe<Float8_Comparison_Exp>;
  PAYABLE_AMT_INC?: Maybe<Float8_Comparison_Exp>;
  PAYABLE_AMT_RENT?: Maybe<Float8_Comparison_Exp>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Float8_Comparison_Exp>;
  PROFIT_AFTER_TAX?: Maybe<Float8_Comparison_Exp>;
  PROFIT_BEFORE_TAX?: Maybe<Float8_Comparison_Exp>;
  PROFIT_LOSS_RENTAL?: Maybe<Float8_Comparison_Exp>;
  PROPOSED_DIVIDEND?: Maybe<Float8_Comparison_Exp>;
  PROVISION_INCOME_TAX?: Maybe<Float8_Comparison_Exp>;
  REFUND_AMT_INC?: Maybe<Float8_Comparison_Exp>;
  REFUND_AMT_RENT?: Maybe<Float8_Comparison_Exp>;
  RENTINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  RENTINC_RATE?: Maybe<Float8_Comparison_Exp>;
  RENTINC_TAX?: Maybe<Float8_Comparison_Exp>;
  RENT_RATES?: Maybe<Float8_Comparison_Exp>;
  RTN_DT?: Maybe<Date_Comparison_Exp>;
  RTN_FROM_DT?: Maybe<Date_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_PERIOD_YEAR?: Maybe<String_Comparison_Exp>;
  SECURE_TOT_LOAN?: Maybe<String_Comparison_Exp>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Float8_Comparison_Exp>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Float8_Comparison_Exp>;
  SOURCE_FUND?: Maybe<String_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  TOTAL_PROP_FUND?: Maybe<String_Comparison_Exp>;
  TOTAL_RESERVE?: Maybe<String_Comparison_Exp>;
  TOTAL_SHARE_CAPITAL?: Maybe<Float8_Comparison_Exp>;
  TOT_ADMIN_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_BSNS_PROF_SALES?: Maybe<Float8_Comparison_Exp>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Float8_Comparison_Exp>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_FIXED_ASSET?: Maybe<String_Comparison_Exp>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Float8_Comparison_Exp>;
  TOT_OPERATION_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_OTHER_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_INCM_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_TAXES_PAID_INC?: Maybe<Float8_Comparison_Exp>;
  TOT_TAXES_PAID_RENT?: Maybe<Float8_Comparison_Exp>;
  TOT_TAX_LIABILITY?: Maybe<Float8_Comparison_Exp>;
  TOT_TAX_LIABILITY_INC?: Maybe<Float8_Comparison_Exp>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Float8_Comparison_Exp>;
  TOT_TRADE_INVESTMENT?: Maybe<String_Comparison_Exp>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Float8_Comparison_Exp>;
  Tot_INCOME?: Maybe<Float8_Comparison_Exp>;
  Tot_RATE?: Maybe<Float8_Comparison_Exp>;
  Tot_TAX?: Maybe<Float8_Comparison_Exp>;
  UNSECURE_TOT_LOAN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>>>;
  _not?: Maybe<Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Inc_Input = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Insert_Input = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Max_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_max_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Max_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BALANCE_CARRY_FORWARD?: Maybe<Order_By>;
  BALANCE_PREV_PERIOD?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  LAND_BUILD?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SECURE_TOT_LOAN?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  SOURCE_FUND?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOTAL_PROP_FUND?: Maybe<Order_By>;
  TOTAL_RESERVE?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_FIXED_ASSET?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_TRADE_INVESTMENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  UNSECURE_TOT_LOAN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Min_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_min_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Min_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BALANCE_CARRY_FORWARD?: Maybe<Order_By>;
  BALANCE_PREV_PERIOD?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  LAND_BUILD?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SECURE_TOT_LOAN?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  SOURCE_FUND?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOTAL_PROP_FUND?: Maybe<Order_By>;
  TOTAL_RESERVE?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_FIXED_ASSET?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_TRADE_INVESTMENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  UNSECURE_TOT_LOAN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Mutation_Response = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_IncomeTaxSummaryNonIndividual>;
};

/** input type for inserting object relation for remote table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Obj_Rel_Insert_Input = {
  data: Ura_IncomeTaxSummaryNonIndividual_Insert_Input;
};

/** ordering options when selecting data from "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BALANCE_CARRY_FORWARD?: Maybe<Order_By>;
  BALANCE_PREV_PERIOD?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  LAND_BUILD?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SECURE_TOT_LOAN?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  SOURCE_FUND?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOTAL_PROP_FUND?: Maybe<Order_By>;
  TOTAL_RESERVE?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_FIXED_ASSET?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_TRADE_INVESTMENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  UNSECURE_TOT_LOAN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.IncomeTaxSummaryNonIndividual" */
export enum Ura_IncomeTaxSummaryNonIndividual_Select_Column {
  /** column name */
  AdjProfitLossRental = "ADJ_PROFIT_LOSS_RENTAL",
  /** column name */
  AdjRentlIncmLoss = "ADJ_RENTL_INCM_LOSS",
  /** column name */
  AdministratingExp = "ADMINISTRATING_EXP",
  /** column name */
  AllowblApportionedCap = "ALLOWBL_APPORTIONED_CAP",
  /** column name */
  AssessedChrgblRentlIncm = "ASSESSED_CHRGBL_RENTL_INCM",
  /** column name */
  AssessedRentlLoss = "ASSESSED_RENTL_LOSS",
  /** column name */
  BadDebtWriteOff = "BAD_DEBT_WRITE_OFF",
  /** column name */
  BalanceCarryForward = "BALANCE_CARRY_FORWARD",
  /** column name */
  BalancePrevPeriod = "BALANCE_PREV_PERIOD",
  /** column name */
  BrghtFwdAssessedLoss = "BRGHT_FWD_ASSESSED_LOSS",
  /** column name */
  BusinessIncome = "Business_INCOME",
  /** column name */
  BusinessRate = "Business_RATE",
  /** column name */
  BusinessTax = "Business_TAX",
  /** column name */
  CostOfSales = "COST_OF_SALES",
  /** column name */
  Debenture = "DEBENTURE",
  /** column name */
  DeferTaxLiability = "DEFER_TAX_LIABILITY",
  /** column name */
  Depreciation = "DEPRECIATION",
  /** column name */
  EquityShare = "EQUITY_SHARE",
  /** column name */
  FactoryRentRates = "FACTORY_RENT_RATES",
  /** column name */
  FinanceExp = "FINANCE_EXP",
  /** column name */
  FixedDeposit = "FIXED_DEPOSIT",
  /** column name */
  GovtSecurities = "GOVT_SECURITIES",
  /** column name */
  GrossProfit = "GROSS_PROFIT",
  /** column name */
  GrossRentalIncome = "GROSS_RENTAL_INCOME",
  /** column name */
  IsAmendment = "IS_AMENDMENT",
  /** column name */
  IsResident = "IS_RESIDENT",
  /** column name */
  LandBuild = "LAND_BUILD",
  /** column name */
  NonAllowblExp = "NON_ALLOWBL_EXP",
  /** column name */
  OperatingExp = "OPERATING_EXP",
  /** column name */
  OtherInvestments = "OTHER_INVESTMENTS",
  /** column name */
  OthrAllowblExp = "OTHR_ALLOWBL_EXP",
  /** column name */
  PayableAmtInc = "PAYABLE_AMT_INC",
  /** column name */
  PayableAmtRent = "PAYABLE_AMT_RENT",
  /** column name */
  ProfitAfterDividend = "PROFIT_AFTER_DIVIDEND",
  /** column name */
  ProfitAfterTax = "PROFIT_AFTER_TAX",
  /** column name */
  ProfitBeforeTax = "PROFIT_BEFORE_TAX",
  /** column name */
  ProfitLossRental = "PROFIT_LOSS_RENTAL",
  /** column name */
  ProposedDividend = "PROPOSED_DIVIDEND",
  /** column name */
  ProvisionIncomeTax = "PROVISION_INCOME_TAX",
  /** column name */
  RefundAmtInc = "REFUND_AMT_INC",
  /** column name */
  RefundAmtRent = "REFUND_AMT_RENT",
  /** column name */
  RentincIncome = "RENTINC_INCOME",
  /** column name */
  RentincRate = "RENTINC_RATE",
  /** column name */
  RentincTax = "RENTINC_TAX",
  /** column name */
  RentRates = "RENT_RATES",
  /** column name */
  RtnDt = "RTN_DT",
  /** column name */
  RtnFromDt = "RTN_FROM_DT",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnPeriodYear = "RTN_PERIOD_YEAR",
  /** column name */
  SecureTotLoan = "SECURE_TOT_LOAN",
  /** column name */
  SelfAssessTaxPaidInc = "SELF_ASSESS_TAX_PAID_INC",
  /** column name */
  SelfAssessTaxPaidRent = "SELF_ASSESS_TAX_PAID_RENT",
  /** column name */
  SourceFund = "SOURCE_FUND",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  TotalPropFund = "TOTAL_PROP_FUND",
  /** column name */
  TotalReserve = "TOTAL_RESERVE",
  /** column name */
  TotalShareCapital = "TOTAL_SHARE_CAPITAL",
  /** column name */
  TotAdminExpense = "TOT_ADMIN_EXPENSE",
  /** column name */
  TotBsnsProfSales = "TOT_BSNS_PROF_SALES",
  /** column name */
  TotExpPurelyRental = "TOT_EXP_PURELY_RENTAL",
  /** column name */
  TotFinancialExpense = "TOT_FINANCIAL_EXPENSE",
  /** column name */
  TotFixedAsset = "TOT_FIXED_ASSET",
  /** column name */
  TotNonChargeableIncome = "TOT_NON_CHARGEABLE_INCOME",
  /** column name */
  TotOperationExpense = "TOT_OPERATION_EXPENSE",
  /** column name */
  TotOtherExpense = "TOT_OTHER_EXPENSE",
  /** column name */
  TotRentAdminiExpns = "TOT_RENT_ADMINI_EXPNS",
  /** column name */
  TotRentFinancExpns = "TOT_RENT_FINANC_EXPNS",
  /** column name */
  TotRentIncmExpns = "TOT_RENT_INCM_EXPNS",
  /** column name */
  TotRentOprtgExpns = "TOT_RENT_OPRTG_EXPNS",
  /** column name */
  TotTaxesPaidInc = "TOT_TAXES_PAID_INC",
  /** column name */
  TotTaxesPaidRent = "TOT_TAXES_PAID_RENT",
  /** column name */
  TotTaxLiability = "TOT_TAX_LIABILITY",
  /** column name */
  TotTaxLiabilityInc = "TOT_TAX_LIABILITY_INC",
  /** column name */
  TotTaxLiabilityRent = "TOT_TAX_LIABILITY_RENT",
  /** column name */
  TotTradeInvestment = "TOT_TRADE_INVESTMENT",
  /** column name */
  TotWthdTaxDeduct = "TOT_WTHD_TAX_DEDUCT",
  /** column name */
  TotIncome = "Tot_INCOME",
  /** column name */
  TotRate = "Tot_RATE",
  /** column name */
  TotTax = "Tot_TAX",
  /** column name */
  UnsecureTotLoan = "UNSECURE_TOT_LOAN",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Set_Input = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Stddev_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_stddev_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Stddev_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_stddev_pop_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_stddev_samp_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Sum_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_sum_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Sum_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_var_pop_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_var_samp_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_IncomeTaxSummaryNonIndividual_Variance_Fields = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual_variance_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.IncomeTaxSummaryNonIndividual" */
export type Ura_IncomeTaxSummaryNonIndividual_Variance_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.IndividualMortgage" */
export type Ura_IndividualMortgage = {
  __typename?: "ura_IndividualMortgage";
  DEED_NO?: Maybe<Scalars["String"]>;
  FINANCE_NAME?: Maybe<Scalars["String"]>;
  FINANCE_TIN?: Maybe<Scalars["bigint"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["float8"]>;
  PERIOD_FROM?: Maybe<Scalars["date"]>;
  PERIOD_TO?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Aggregate = {
  __typename?: "ura_IndividualMortgage_aggregate";
  aggregate?: Maybe<Ura_IndividualMortgage_Aggregate_Fields>;
  nodes: Array<Ura_IndividualMortgage>;
};

/** aggregate fields of "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Aggregate_Fields = {
  __typename?: "ura_IndividualMortgage_aggregate_fields";
  avg?: Maybe<Ura_IndividualMortgage_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_IndividualMortgage_Max_Fields>;
  min?: Maybe<Ura_IndividualMortgage_Min_Fields>;
  stddev?: Maybe<Ura_IndividualMortgage_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_IndividualMortgage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_IndividualMortgage_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_IndividualMortgage_Sum_Fields>;
  var_pop?: Maybe<Ura_IndividualMortgage_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_IndividualMortgage_Var_Samp_Fields>;
  variance?: Maybe<Ura_IndividualMortgage_Variance_Fields>;
};

/** aggregate fields of "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_IndividualMortgage_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Aggregate_Order_By = {
  avg?: Maybe<Ura_IndividualMortgage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_IndividualMortgage_Max_Order_By>;
  min?: Maybe<Ura_IndividualMortgage_Min_Order_By>;
  stddev?: Maybe<Ura_IndividualMortgage_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_IndividualMortgage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_IndividualMortgage_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_IndividualMortgage_Sum_Order_By>;
  var_pop?: Maybe<Ura_IndividualMortgage_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_IndividualMortgage_Var_Samp_Order_By>;
  variance?: Maybe<Ura_IndividualMortgage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Arr_Rel_Insert_Input = {
  data: Array<Ura_IndividualMortgage_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_IndividualMortgage_Avg_Fields = {
  __typename?: "ura_IndividualMortgage_avg_fields";
  FINANCE_TIN?: Maybe<Scalars["Float"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["Float"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Avg_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.IndividualMortgage". All fields are combined with a logical 'AND'. */
export type Ura_IndividualMortgage_Bool_Exp = {
  DEED_NO?: Maybe<String_Comparison_Exp>;
  FINANCE_NAME?: Maybe<String_Comparison_Exp>;
  FINANCE_TIN?: Maybe<Bigint_Comparison_Exp>;
  INTERSET_MORTGAGE?: Maybe<Float8_Comparison_Exp>;
  PERIOD_FROM?: Maybe<Date_Comparison_Exp>;
  PERIOD_TO?: Maybe<Date_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  TOTAL_MORTGAGE?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_IndividualMortgage_Bool_Exp>>>;
  _not?: Maybe<Ura_IndividualMortgage_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_IndividualMortgage_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Inc_Input = {
  FINANCE_TIN?: Maybe<Scalars["bigint"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["float8"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Insert_Input = {
  DEED_NO?: Maybe<Scalars["String"]>;
  FINANCE_NAME?: Maybe<Scalars["String"]>;
  FINANCE_TIN?: Maybe<Scalars["bigint"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["float8"]>;
  PERIOD_FROM?: Maybe<Scalars["date"]>;
  PERIOD_TO?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_IndividualMortgage_Max_Fields = {
  __typename?: "ura_IndividualMortgage_max_fields";
  DEED_NO?: Maybe<Scalars["String"]>;
  FINANCE_NAME?: Maybe<Scalars["String"]>;
  FINANCE_TIN?: Maybe<Scalars["bigint"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["float8"]>;
  PERIOD_FROM?: Maybe<Scalars["date"]>;
  PERIOD_TO?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Max_Order_By = {
  DEED_NO?: Maybe<Order_By>;
  FINANCE_NAME?: Maybe<Order_By>;
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  PERIOD_FROM?: Maybe<Order_By>;
  PERIOD_TO?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_IndividualMortgage_Min_Fields = {
  __typename?: "ura_IndividualMortgage_min_fields";
  DEED_NO?: Maybe<Scalars["String"]>;
  FINANCE_NAME?: Maybe<Scalars["String"]>;
  FINANCE_TIN?: Maybe<Scalars["bigint"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["float8"]>;
  PERIOD_FROM?: Maybe<Scalars["date"]>;
  PERIOD_TO?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Min_Order_By = {
  DEED_NO?: Maybe<Order_By>;
  FINANCE_NAME?: Maybe<Order_By>;
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  PERIOD_FROM?: Maybe<Order_By>;
  PERIOD_TO?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Mutation_Response = {
  __typename?: "ura_IndividualMortgage_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_IndividualMortgage>;
};

/** input type for inserting object relation for remote table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Obj_Rel_Insert_Input = {
  data: Ura_IndividualMortgage_Insert_Input;
};

/** ordering options when selecting data from "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Order_By = {
  DEED_NO?: Maybe<Order_By>;
  FINANCE_NAME?: Maybe<Order_By>;
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  PERIOD_FROM?: Maybe<Order_By>;
  PERIOD_TO?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.IndividualMortgage" */
export enum Ura_IndividualMortgage_Select_Column {
  /** column name */
  DeedNo = "DEED_NO",
  /** column name */
  FinanceName = "FINANCE_NAME",
  /** column name */
  FinanceTin = "FINANCE_TIN",
  /** column name */
  IntersetMortgage = "INTERSET_MORTGAGE",
  /** column name */
  PeriodFrom = "PERIOD_FROM",
  /** column name */
  PeriodTo = "PERIOD_TO",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  TotalMortgage = "TOTAL_MORTGAGE",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Set_Input = {
  DEED_NO?: Maybe<Scalars["String"]>;
  FINANCE_NAME?: Maybe<Scalars["String"]>;
  FINANCE_TIN?: Maybe<Scalars["bigint"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["float8"]>;
  PERIOD_FROM?: Maybe<Scalars["date"]>;
  PERIOD_TO?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_IndividualMortgage_Stddev_Fields = {
  __typename?: "ura_IndividualMortgage_stddev_fields";
  FINANCE_TIN?: Maybe<Scalars["Float"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["Float"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Stddev_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_IndividualMortgage_Stddev_Pop_Fields = {
  __typename?: "ura_IndividualMortgage_stddev_pop_fields";
  FINANCE_TIN?: Maybe<Scalars["Float"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["Float"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Stddev_Pop_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_IndividualMortgage_Stddev_Samp_Fields = {
  __typename?: "ura_IndividualMortgage_stddev_samp_fields";
  FINANCE_TIN?: Maybe<Scalars["Float"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["Float"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Stddev_Samp_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_IndividualMortgage_Sum_Fields = {
  __typename?: "ura_IndividualMortgage_sum_fields";
  FINANCE_TIN?: Maybe<Scalars["bigint"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["float8"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Sum_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_IndividualMortgage_Var_Pop_Fields = {
  __typename?: "ura_IndividualMortgage_var_pop_fields";
  FINANCE_TIN?: Maybe<Scalars["Float"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["Float"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Var_Pop_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_IndividualMortgage_Var_Samp_Fields = {
  __typename?: "ura_IndividualMortgage_var_samp_fields";
  FINANCE_TIN?: Maybe<Scalars["Float"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["Float"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Var_Samp_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_IndividualMortgage_Variance_Fields = {
  __typename?: "ura_IndividualMortgage_variance_fields";
  FINANCE_TIN?: Maybe<Scalars["Float"]>;
  INTERSET_MORTGAGE?: Maybe<Scalars["Float"]>;
  TOTAL_MORTGAGE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.IndividualMortgage" */
export type Ura_IndividualMortgage_Variance_Order_By = {
  FINANCE_TIN?: Maybe<Order_By>;
  INTERSET_MORTGAGE?: Maybe<Order_By>;
  TOTAL_MORTGAGE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904 = {
  __typename?: "ura_RMatchDetailed904";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_BIRTHDATE?: Maybe<Scalars["date"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_LANDLINE?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Aggregate = {
  __typename?: "ura_RMatchDetailed904_aggregate";
  aggregate?: Maybe<Ura_RMatchDetailed904_Aggregate_Fields>;
  nodes: Array<Ura_RMatchDetailed904>;
};

/** aggregate fields of "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Aggregate_Fields = {
  __typename?: "ura_RMatchDetailed904_aggregate_fields";
  avg?: Maybe<Ura_RMatchDetailed904_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RMatchDetailed904_Max_Fields>;
  min?: Maybe<Ura_RMatchDetailed904_Min_Fields>;
  stddev?: Maybe<Ura_RMatchDetailed904_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RMatchDetailed904_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RMatchDetailed904_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RMatchDetailed904_Sum_Fields>;
  var_pop?: Maybe<Ura_RMatchDetailed904_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RMatchDetailed904_Var_Samp_Fields>;
  variance?: Maybe<Ura_RMatchDetailed904_Variance_Fields>;
};

/** aggregate fields of "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RMatchDetailed904_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Aggregate_Order_By = {
  avg?: Maybe<Ura_RMatchDetailed904_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RMatchDetailed904_Max_Order_By>;
  min?: Maybe<Ura_RMatchDetailed904_Min_Order_By>;
  stddev?: Maybe<Ura_RMatchDetailed904_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RMatchDetailed904_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RMatchDetailed904_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RMatchDetailed904_Sum_Order_By>;
  var_pop?: Maybe<Ura_RMatchDetailed904_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RMatchDetailed904_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RMatchDetailed904_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Arr_Rel_Insert_Input = {
  data: Array<Ura_RMatchDetailed904_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RMatchDetailed904_Avg_Fields = {
  __typename?: "ura_RMatchDetailed904_avg_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Avg_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RMatchDetailed904". All fields are combined with a logical 'AND'. */
export type Ura_RMatchDetailed904_Bool_Exp = {
  ADDRESS?: Maybe<String_Comparison_Exp>;
  BLOCK_NO?: Maybe<String_Comparison_Exp>;
  COUNT_URA?: Maybe<Bigint_Comparison_Exp>;
  CUSTOMER_ID?: Maybe<Bigint_Comparison_Exp>;
  DIVISION_ID?: Maybe<String_Comparison_Exp>;
  FLAT_NO?: Maybe<String_Comparison_Exp>;
  GROSS_VALUE?: Maybe<String_Comparison_Exp>;
  HOUSE_NO?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_EMP?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  KCCA_EMAIL?: Maybe<String_Comparison_Exp>;
  KCCA_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_LANDLINE?: Maybe<String_Comparison_Exp>;
  KCCA_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_MOBILE1?: Maybe<String_Comparison_Exp>;
  KCCA_MOBILE2?: Maybe<String_Comparison_Exp>;
  KCCA_NIN?: Maybe<String_Comparison_Exp>;
  KCCA_SURNAME?: Maybe<String_Comparison_Exp>;
  KCCA_TIN?: Maybe<String_Comparison_Exp>;
  LATITUDE?: Maybe<Float8_Comparison_Exp>;
  LONGITUDE?: Maybe<Float8_Comparison_Exp>;
  MATCH_DETAILS?: Maybe<String_Comparison_Exp>;
  MATCH_KCCA_URA?: Maybe<String_Comparison_Exp>;
  MATCH_SCORE?: Maybe<Bigint_Comparison_Exp>;
  NUM_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  PARISH_ID?: Maybe<String_Comparison_Exp>;
  PLOT_NO?: Maybe<String_Comparison_Exp>;
  PROPERTY_ID?: Maybe<String_Comparison_Exp>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<String_Comparison_Exp>;
  PROPERTY_TYPE_ID?: Maybe<String_Comparison_Exp>;
  RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  SCORE_DETAILS?: Maybe<String_Comparison_Exp>;
  STREET_ID?: Maybe<String_Comparison_Exp>;
  SUB_PROPERTY_TYPE_ID?: Maybe<String_Comparison_Exp>;
  URA_BIRTHDATE?: Maybe<Date_Comparison_Exp>;
  URA_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  URA_FIRSTNAME?: Maybe<String_Comparison_Exp>;
  URA_LANDLINE?: Maybe<String_Comparison_Exp>;
  URA_MIDDLENAME?: Maybe<String_Comparison_Exp>;
  URA_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  URA_NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  URA_SURNAME?: Maybe<String_Comparison_Exp>;
  VILLAGE_ID?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RMatchDetailed904_Bool_Exp>>>;
  _not?: Maybe<Ura_RMatchDetailed904_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RMatchDetailed904_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Inc_Input = {
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Insert_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_BIRTHDATE?: Maybe<Scalars["date"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_LANDLINE?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RMatchDetailed904_Max_Fields = {
  __typename?: "ura_RMatchDetailed904_max_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_BIRTHDATE?: Maybe<Scalars["date"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_LANDLINE?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Max_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_EMAIL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_LANDLINE?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_MOBILE1?: Maybe<Order_By>;
  KCCA_MOBILE2?: Maybe<Order_By>;
  KCCA_NIN?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_DETAILS?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  SCORE_DETAILS?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_BIRTHDATE?: Maybe<Order_By>;
  URA_EMAIL_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_LANDLINE?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_MOBILE_NUMBER?: Maybe<Order_By>;
  URA_NATIONAL_ID?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RMatchDetailed904_Min_Fields = {
  __typename?: "ura_RMatchDetailed904_min_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_BIRTHDATE?: Maybe<Scalars["date"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_LANDLINE?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Min_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_EMAIL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_LANDLINE?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_MOBILE1?: Maybe<Order_By>;
  KCCA_MOBILE2?: Maybe<Order_By>;
  KCCA_NIN?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_DETAILS?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  SCORE_DETAILS?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_BIRTHDATE?: Maybe<Order_By>;
  URA_EMAIL_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_LANDLINE?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_MOBILE_NUMBER?: Maybe<Order_By>;
  URA_NATIONAL_ID?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Mutation_Response = {
  __typename?: "ura_RMatchDetailed904_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RMatchDetailed904>;
};

/** input type for inserting object relation for remote table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Obj_Rel_Insert_Input = {
  data: Ura_RMatchDetailed904_Insert_Input;
};

/** ordering options when selecting data from "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_EMAIL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_LANDLINE?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_MOBILE1?: Maybe<Order_By>;
  KCCA_MOBILE2?: Maybe<Order_By>;
  KCCA_NIN?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_DETAILS?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  SCORE_DETAILS?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_BIRTHDATE?: Maybe<Order_By>;
  URA_EMAIL_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_LANDLINE?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_MOBILE_NUMBER?: Maybe<Order_By>;
  URA_NATIONAL_ID?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RMatchDetailed904" */
export enum Ura_RMatchDetailed904_Select_Column {
  /** column name */
  Address = "ADDRESS",
  /** column name */
  BlockNo = "BLOCK_NO",
  /** column name */
  CountUra = "COUNT_URA",
  /** column name */
  CustomerId = "CUSTOMER_ID",
  /** column name */
  DivisionId = "DIVISION_ID",
  /** column name */
  FlatNo = "FLAT_NO",
  /** column name */
  GrossValue = "GROSS_VALUE",
  /** column name */
  HouseNo = "HOUSE_NO",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcEmp = "IS_INCOME_SRC_EMP",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  KccaEmail = "KCCA_EMAIL",
  /** column name */
  KccaFirstName = "KCCA_FIRST_NAME",
  /** column name */
  KccaLandline = "KCCA_LANDLINE",
  /** column name */
  KccaMiddleName = "KCCA_MIDDLE_NAME",
  /** column name */
  KccaMobile1 = "KCCA_MOBILE1",
  /** column name */
  KccaMobile2 = "KCCA_MOBILE2",
  /** column name */
  KccaNin = "KCCA_NIN",
  /** column name */
  KccaSurname = "KCCA_SURNAME",
  /** column name */
  KccaTin = "KCCA_TIN",
  /** column name */
  Latitude = "LATITUDE",
  /** column name */
  Longitude = "LONGITUDE",
  /** column name */
  MatchDetails = "MATCH_DETAILS",
  /** column name */
  MatchKccaUra = "MATCH_KCCA_URA",
  /** column name */
  MatchScore = "MATCH_SCORE",
  /** column name */
  NumProperties = "NUM_PROPERTIES",
  /** column name */
  ParishId = "PARISH_ID",
  /** column name */
  PlotNo = "PLOT_NO",
  /** column name */
  PropertyId = "PROPERTY_ID",
  /** column name */
  PropertyRentedStatusId = "PROPERTY_RENTED_STATUS_ID",
  /** column name */
  PropertyTypeId = "PROPERTY_TYPE_ID",
  /** column name */
  RateableValue = "RATEABLE_VALUE",
  /** column name */
  ScoreDetails = "SCORE_DETAILS",
  /** column name */
  StreetId = "STREET_ID",
  /** column name */
  SubPropertyTypeId = "SUB_PROPERTY_TYPE_ID",
  /** column name */
  UraBirthdate = "URA_BIRTHDATE",
  /** column name */
  UraEmailId = "URA_EMAIL_ID",
  /** column name */
  UraFirstname = "URA_FIRSTNAME",
  /** column name */
  UraLandline = "URA_LANDLINE",
  /** column name */
  UraMiddlename = "URA_MIDDLENAME",
  /** column name */
  UraMobileNumber = "URA_MOBILE_NUMBER",
  /** column name */
  UraNationalId = "URA_NATIONAL_ID",
  /** column name */
  UraSurname = "URA_SURNAME",
  /** column name */
  VillageId = "VILLAGE_ID",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Set_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_BIRTHDATE?: Maybe<Scalars["date"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_LANDLINE?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RMatchDetailed904_Stddev_Fields = {
  __typename?: "ura_RMatchDetailed904_stddev_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Stddev_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RMatchDetailed904_Stddev_Pop_Fields = {
  __typename?: "ura_RMatchDetailed904_stddev_pop_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Stddev_Pop_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RMatchDetailed904_Stddev_Samp_Fields = {
  __typename?: "ura_RMatchDetailed904_stddev_samp_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Stddev_Samp_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RMatchDetailed904_Sum_Fields = {
  __typename?: "ura_RMatchDetailed904_sum_fields";
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Sum_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RMatchDetailed904_Var_Pop_Fields = {
  __typename?: "ura_RMatchDetailed904_var_pop_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Var_Pop_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RMatchDetailed904_Var_Samp_Fields = {
  __typename?: "ura_RMatchDetailed904_var_samp_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Var_Samp_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RMatchDetailed904_Variance_Fields = {
  __typename?: "ura_RMatchDetailed904_variance_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RMatchDetailed904" */
export type Ura_RMatchDetailed904_Variance_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916 = {
  __typename?: "ura_RMatchDetailed916";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Aggregate = {
  __typename?: "ura_RMatchDetailed916_aggregate";
  aggregate?: Maybe<Ura_RMatchDetailed916_Aggregate_Fields>;
  nodes: Array<Ura_RMatchDetailed916>;
};

/** aggregate fields of "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Aggregate_Fields = {
  __typename?: "ura_RMatchDetailed916_aggregate_fields";
  avg?: Maybe<Ura_RMatchDetailed916_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RMatchDetailed916_Max_Fields>;
  min?: Maybe<Ura_RMatchDetailed916_Min_Fields>;
  stddev?: Maybe<Ura_RMatchDetailed916_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RMatchDetailed916_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RMatchDetailed916_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RMatchDetailed916_Sum_Fields>;
  var_pop?: Maybe<Ura_RMatchDetailed916_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RMatchDetailed916_Var_Samp_Fields>;
  variance?: Maybe<Ura_RMatchDetailed916_Variance_Fields>;
};

/** aggregate fields of "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RMatchDetailed916_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Aggregate_Order_By = {
  avg?: Maybe<Ura_RMatchDetailed916_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RMatchDetailed916_Max_Order_By>;
  min?: Maybe<Ura_RMatchDetailed916_Min_Order_By>;
  stddev?: Maybe<Ura_RMatchDetailed916_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RMatchDetailed916_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RMatchDetailed916_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RMatchDetailed916_Sum_Order_By>;
  var_pop?: Maybe<Ura_RMatchDetailed916_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RMatchDetailed916_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RMatchDetailed916_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Arr_Rel_Insert_Input = {
  data: Array<Ura_RMatchDetailed916_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RMatchDetailed916_Avg_Fields = {
  __typename?: "ura_RMatchDetailed916_avg_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Avg_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RMatchDetailed916". All fields are combined with a logical 'AND'. */
export type Ura_RMatchDetailed916_Bool_Exp = {
  ADDRESS?: Maybe<String_Comparison_Exp>;
  BLOCK_NO?: Maybe<String_Comparison_Exp>;
  COUNT_URA?: Maybe<Bigint_Comparison_Exp>;
  CUSTOMER_ID?: Maybe<Bigint_Comparison_Exp>;
  DIVISION_ID?: Maybe<String_Comparison_Exp>;
  FLAT_NO?: Maybe<String_Comparison_Exp>;
  GROSS_VALUE?: Maybe<String_Comparison_Exp>;
  HOUSE_NO?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_EMP?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  KCCA_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_SURNAME?: Maybe<String_Comparison_Exp>;
  LATITUDE?: Maybe<Float8_Comparison_Exp>;
  LONGITUDE?: Maybe<Float8_Comparison_Exp>;
  MATCH_KCCA_URA?: Maybe<String_Comparison_Exp>;
  MATCH_SCORE?: Maybe<Bigint_Comparison_Exp>;
  NUM_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  PARISH_ID?: Maybe<String_Comparison_Exp>;
  PLOT_NO?: Maybe<String_Comparison_Exp>;
  PROPERTY_ID?: Maybe<String_Comparison_Exp>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<String_Comparison_Exp>;
  PROPERTY_TYPE_ID?: Maybe<String_Comparison_Exp>;
  RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  SCORE_MATCH_ATRIBUTES?: Maybe<String_Comparison_Exp>;
  STREET_ID?: Maybe<String_Comparison_Exp>;
  SUB_PROPERTY_TYPE_ID?: Maybe<String_Comparison_Exp>;
  URA_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  URA_FIRSTNAME?: Maybe<String_Comparison_Exp>;
  URA_MATCHING_TINS?: Maybe<Bigint_Comparison_Exp>;
  URA_MIDDLENAME?: Maybe<String_Comparison_Exp>;
  URA_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  URA_NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  URA_SURNAME?: Maybe<String_Comparison_Exp>;
  VILLAGE_ID?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RMatchDetailed916_Bool_Exp>>>;
  _not?: Maybe<Ura_RMatchDetailed916_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RMatchDetailed916_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Inc_Input = {
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Insert_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RMatchDetailed916_Max_Fields = {
  __typename?: "ura_RMatchDetailed916_max_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Max_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_EMAIL_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_MOBILE_NUMBER?: Maybe<Order_By>;
  URA_NATIONAL_ID?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RMatchDetailed916_Min_Fields = {
  __typename?: "ura_RMatchDetailed916_min_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Min_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_EMAIL_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_MOBILE_NUMBER?: Maybe<Order_By>;
  URA_NATIONAL_ID?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Mutation_Response = {
  __typename?: "ura_RMatchDetailed916_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RMatchDetailed916>;
};

/** input type for inserting object relation for remote table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Obj_Rel_Insert_Input = {
  data: Ura_RMatchDetailed916_Insert_Input;
};

/** ordering options when selecting data from "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_EMAIL_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_MOBILE_NUMBER?: Maybe<Order_By>;
  URA_NATIONAL_ID?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RMatchDetailed916" */
export enum Ura_RMatchDetailed916_Select_Column {
  /** column name */
  Address = "ADDRESS",
  /** column name */
  BlockNo = "BLOCK_NO",
  /** column name */
  CountUra = "COUNT_URA",
  /** column name */
  CustomerId = "CUSTOMER_ID",
  /** column name */
  DivisionId = "DIVISION_ID",
  /** column name */
  FlatNo = "FLAT_NO",
  /** column name */
  GrossValue = "GROSS_VALUE",
  /** column name */
  HouseNo = "HOUSE_NO",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcEmp = "IS_INCOME_SRC_EMP",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  KccaFirstName = "KCCA_FIRST_NAME",
  /** column name */
  KccaMiddleName = "KCCA_MIDDLE_NAME",
  /** column name */
  KccaSurname = "KCCA_SURNAME",
  /** column name */
  Latitude = "LATITUDE",
  /** column name */
  Longitude = "LONGITUDE",
  /** column name */
  MatchKccaUra = "MATCH_KCCA_URA",
  /** column name */
  MatchScore = "MATCH_SCORE",
  /** column name */
  NumProperties = "NUM_PROPERTIES",
  /** column name */
  ParishId = "PARISH_ID",
  /** column name */
  PlotNo = "PLOT_NO",
  /** column name */
  PropertyId = "PROPERTY_ID",
  /** column name */
  PropertyRentedStatusId = "PROPERTY_RENTED_STATUS_ID",
  /** column name */
  PropertyTypeId = "PROPERTY_TYPE_ID",
  /** column name */
  RateableValue = "RATEABLE_VALUE",
  /** column name */
  ScoreMatchAtributes = "SCORE_MATCH_ATRIBUTES",
  /** column name */
  StreetId = "STREET_ID",
  /** column name */
  SubPropertyTypeId = "SUB_PROPERTY_TYPE_ID",
  /** column name */
  UraEmailId = "URA_EMAIL_ID",
  /** column name */
  UraFirstname = "URA_FIRSTNAME",
  /** column name */
  UraMatchingTins = "URA_MATCHING_TINS",
  /** column name */
  UraMiddlename = "URA_MIDDLENAME",
  /** column name */
  UraMobileNumber = "URA_MOBILE_NUMBER",
  /** column name */
  UraNationalId = "URA_NATIONAL_ID",
  /** column name */
  UraSurname = "URA_SURNAME",
  /** column name */
  VillageId = "VILLAGE_ID",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Set_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_EMAIL_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  URA_NATIONAL_ID?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RMatchDetailed916_Stddev_Fields = {
  __typename?: "ura_RMatchDetailed916_stddev_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Stddev_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RMatchDetailed916_Stddev_Pop_Fields = {
  __typename?: "ura_RMatchDetailed916_stddev_pop_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Stddev_Pop_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RMatchDetailed916_Stddev_Samp_Fields = {
  __typename?: "ura_RMatchDetailed916_stddev_samp_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Stddev_Samp_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RMatchDetailed916_Sum_Fields = {
  __typename?: "ura_RMatchDetailed916_sum_fields";
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Sum_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RMatchDetailed916_Var_Pop_Fields = {
  __typename?: "ura_RMatchDetailed916_var_pop_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Var_Pop_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RMatchDetailed916_Var_Samp_Fields = {
  __typename?: "ura_RMatchDetailed916_var_samp_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Var_Samp_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RMatchDetailed916_Variance_Fields = {
  __typename?: "ura_RMatchDetailed916_variance_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RMatchDetailed916" */
export type Ura_RMatchDetailed916_Variance_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946 = {
  __typename?: "ura_RMatchDetailed946";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN_NO?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Aggregate = {
  __typename?: "ura_RMatchDetailed946_aggregate";
  aggregate?: Maybe<Ura_RMatchDetailed946_Aggregate_Fields>;
  nodes: Array<Ura_RMatchDetailed946>;
};

/** aggregate fields of "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Aggregate_Fields = {
  __typename?: "ura_RMatchDetailed946_aggregate_fields";
  avg?: Maybe<Ura_RMatchDetailed946_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RMatchDetailed946_Max_Fields>;
  min?: Maybe<Ura_RMatchDetailed946_Min_Fields>;
  stddev?: Maybe<Ura_RMatchDetailed946_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RMatchDetailed946_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RMatchDetailed946_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RMatchDetailed946_Sum_Fields>;
  var_pop?: Maybe<Ura_RMatchDetailed946_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RMatchDetailed946_Var_Samp_Fields>;
  variance?: Maybe<Ura_RMatchDetailed946_Variance_Fields>;
};

/** aggregate fields of "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RMatchDetailed946_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Aggregate_Order_By = {
  avg?: Maybe<Ura_RMatchDetailed946_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RMatchDetailed946_Max_Order_By>;
  min?: Maybe<Ura_RMatchDetailed946_Min_Order_By>;
  stddev?: Maybe<Ura_RMatchDetailed946_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RMatchDetailed946_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RMatchDetailed946_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RMatchDetailed946_Sum_Order_By>;
  var_pop?: Maybe<Ura_RMatchDetailed946_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RMatchDetailed946_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RMatchDetailed946_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Arr_Rel_Insert_Input = {
  data: Array<Ura_RMatchDetailed946_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RMatchDetailed946_Avg_Fields = {
  __typename?: "ura_RMatchDetailed946_avg_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Avg_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RMatchDetailed946". All fields are combined with a logical 'AND'. */
export type Ura_RMatchDetailed946_Bool_Exp = {
  ADDRESS?: Maybe<String_Comparison_Exp>;
  BLOCK_NO?: Maybe<String_Comparison_Exp>;
  CUSTOMER_ID?: Maybe<Bigint_Comparison_Exp>;
  DIVISION_ID?: Maybe<String_Comparison_Exp>;
  FLAT_NO?: Maybe<String_Comparison_Exp>;
  GROSS_VALUE?: Maybe<String_Comparison_Exp>;
  HOUSE_NO?: Maybe<String_Comparison_Exp>;
  KCCA_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_SURNAME?: Maybe<String_Comparison_Exp>;
  KCCA_TIN_NO?: Maybe<String_Comparison_Exp>;
  LATITUDE?: Maybe<Float8_Comparison_Exp>;
  LONGITUDE?: Maybe<Float8_Comparison_Exp>;
  MATCH_KCCA_URA?: Maybe<String_Comparison_Exp>;
  MATCH_SCORE?: Maybe<Bigint_Comparison_Exp>;
  NUM_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  PARISH_ID?: Maybe<String_Comparison_Exp>;
  PLOT_NO?: Maybe<String_Comparison_Exp>;
  PROPERTY_ID?: Maybe<String_Comparison_Exp>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<String_Comparison_Exp>;
  PROPERTY_TYPE_ID?: Maybe<String_Comparison_Exp>;
  RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Float8_Comparison_Exp>;
  SCORE_MATCH_ATRIBUTES?: Maybe<String_Comparison_Exp>;
  STREET_ID?: Maybe<String_Comparison_Exp>;
  SUB_PROPERTY_TYPE_ID?: Maybe<String_Comparison_Exp>;
  URA_FIRSTNAME?: Maybe<String_Comparison_Exp>;
  URA_MATCHING_TINS?: Maybe<String_Comparison_Exp>;
  URA_MIDDLENAME?: Maybe<String_Comparison_Exp>;
  URA_SURNAME?: Maybe<String_Comparison_Exp>;
  URA_TIN?: Maybe<String_Comparison_Exp>;
  VILLAGE_ID?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RMatchDetailed946_Bool_Exp>>>;
  _not?: Maybe<Ura_RMatchDetailed946_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RMatchDetailed946_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Inc_Input = {
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Insert_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN_NO?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RMatchDetailed946_Max_Fields = {
  __typename?: "ura_RMatchDetailed946_max_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN_NO?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Max_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN_NO?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RMatchDetailed946_Min_Fields = {
  __typename?: "ura_RMatchDetailed946_min_fields";
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN_NO?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Min_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN_NO?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Mutation_Response = {
  __typename?: "ura_RMatchDetailed946_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RMatchDetailed946>;
};

/** input type for inserting object relation for remote table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Obj_Rel_Insert_Input = {
  data: Ura_RMatchDetailed946_Insert_Input;
};

/** ordering options when selecting data from "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Order_By = {
  ADDRESS?: Maybe<Order_By>;
  BLOCK_NO?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIVISION_ID?: Maybe<Order_By>;
  FLAT_NO?: Maybe<Order_By>;
  GROSS_VALUE?: Maybe<Order_By>;
  HOUSE_NO?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN_NO?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PARISH_ID?: Maybe<Order_By>;
  PLOT_NO?: Maybe<Order_By>;
  PROPERTY_ID?: Maybe<Order_By>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Order_By>;
  PROPERTY_TYPE_ID?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  STREET_ID?: Maybe<Order_By>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  VILLAGE_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RMatchDetailed946" */
export enum Ura_RMatchDetailed946_Select_Column {
  /** column name */
  Address = "ADDRESS",
  /** column name */
  BlockNo = "BLOCK_NO",
  /** column name */
  CustomerId = "CUSTOMER_ID",
  /** column name */
  DivisionId = "DIVISION_ID",
  /** column name */
  FlatNo = "FLAT_NO",
  /** column name */
  GrossValue = "GROSS_VALUE",
  /** column name */
  HouseNo = "HOUSE_NO",
  /** column name */
  KccaFirstName = "KCCA_FIRST_NAME",
  /** column name */
  KccaMiddleName = "KCCA_MIDDLE_NAME",
  /** column name */
  KccaSurname = "KCCA_SURNAME",
  /** column name */
  KccaTinNo = "KCCA_TIN_NO",
  /** column name */
  Latitude = "LATITUDE",
  /** column name */
  Longitude = "LONGITUDE",
  /** column name */
  MatchKccaUra = "MATCH_KCCA_URA",
  /** column name */
  MatchScore = "MATCH_SCORE",
  /** column name */
  NumProperties = "NUM_PROPERTIES",
  /** column name */
  ParishId = "PARISH_ID",
  /** column name */
  PlotNo = "PLOT_NO",
  /** column name */
  PropertyId = "PROPERTY_ID",
  /** column name */
  PropertyRentedStatusId = "PROPERTY_RENTED_STATUS_ID",
  /** column name */
  PropertyTypeId = "PROPERTY_TYPE_ID",
  /** column name */
  RateableValue = "RATEABLE_VALUE",
  /** column name */
  RateableValueAdjusted = "RATEABLE_VALUE_ADJUSTED",
  /** column name */
  ScoreMatchAtributes = "SCORE_MATCH_ATRIBUTES",
  /** column name */
  StreetId = "STREET_ID",
  /** column name */
  SubPropertyTypeId = "SUB_PROPERTY_TYPE_ID",
  /** column name */
  UraFirstname = "URA_FIRSTNAME",
  /** column name */
  UraMatchingTins = "URA_MATCHING_TINS",
  /** column name */
  UraMiddlename = "URA_MIDDLENAME",
  /** column name */
  UraSurname = "URA_SURNAME",
  /** column name */
  UraTin = "URA_TIN",
  /** column name */
  VillageId = "VILLAGE_ID",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Set_Input = {
  ADDRESS?: Maybe<Scalars["String"]>;
  BLOCK_NO?: Maybe<Scalars["String"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIVISION_ID?: Maybe<Scalars["String"]>;
  FLAT_NO?: Maybe<Scalars["String"]>;
  GROSS_VALUE?: Maybe<Scalars["String"]>;
  HOUSE_NO?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN_NO?: Maybe<Scalars["String"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PARISH_ID?: Maybe<Scalars["String"]>;
  PLOT_NO?: Maybe<Scalars["String"]>;
  PROPERTY_ID?: Maybe<Scalars["String"]>;
  PROPERTY_RENTED_STATUS_ID?: Maybe<Scalars["String"]>;
  PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["float8"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  STREET_ID?: Maybe<Scalars["String"]>;
  SUB_PROPERTY_TYPE_ID?: Maybe<Scalars["String"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  VILLAGE_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RMatchDetailed946_Stddev_Fields = {
  __typename?: "ura_RMatchDetailed946_stddev_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Stddev_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RMatchDetailed946_Stddev_Pop_Fields = {
  __typename?: "ura_RMatchDetailed946_stddev_pop_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Stddev_Pop_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RMatchDetailed946_Stddev_Samp_Fields = {
  __typename?: "ura_RMatchDetailed946_stddev_samp_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Stddev_Samp_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RMatchDetailed946_Sum_Fields = {
  __typename?: "ura_RMatchDetailed946_sum_fields";
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LATITUDE?: Maybe<Scalars["float8"]>;
  LONGITUDE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Sum_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RMatchDetailed946_Var_Pop_Fields = {
  __typename?: "ura_RMatchDetailed946_var_pop_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Var_Pop_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RMatchDetailed946_Var_Samp_Fields = {
  __typename?: "ura_RMatchDetailed946_var_samp_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Var_Samp_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RMatchDetailed946_Variance_Fields = {
  __typename?: "ura_RMatchDetailed946_variance_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LATITUDE?: Maybe<Scalars["Float"]>;
  LONGITUDE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RMatchDetailed946" */
export type Ura_RMatchDetailed946_Variance_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  LATITUDE?: Maybe<Order_By>;
  LONGITUDE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  RATEABLE_VALUE?: Maybe<Order_By>;
  RATEABLE_VALUE_ADJUSTED?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RMatchSummary904" */
export type Ura_RMatchSummary904 = {
  __typename?: "ura_RMatchSummary904";
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_DOB?: Maybe<Scalars["date"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_DOB?: Maybe<Scalars["date"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Aggregate = {
  __typename?: "ura_RMatchSummary904_aggregate";
  aggregate?: Maybe<Ura_RMatchSummary904_Aggregate_Fields>;
  nodes: Array<Ura_RMatchSummary904>;
};

/** aggregate fields of "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Aggregate_Fields = {
  __typename?: "ura_RMatchSummary904_aggregate_fields";
  avg?: Maybe<Ura_RMatchSummary904_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RMatchSummary904_Max_Fields>;
  min?: Maybe<Ura_RMatchSummary904_Min_Fields>;
  stddev?: Maybe<Ura_RMatchSummary904_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RMatchSummary904_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RMatchSummary904_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RMatchSummary904_Sum_Fields>;
  var_pop?: Maybe<Ura_RMatchSummary904_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RMatchSummary904_Var_Samp_Fields>;
  variance?: Maybe<Ura_RMatchSummary904_Variance_Fields>;
};

/** aggregate fields of "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RMatchSummary904_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Aggregate_Order_By = {
  avg?: Maybe<Ura_RMatchSummary904_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RMatchSummary904_Max_Order_By>;
  min?: Maybe<Ura_RMatchSummary904_Min_Order_By>;
  stddev?: Maybe<Ura_RMatchSummary904_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RMatchSummary904_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RMatchSummary904_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RMatchSummary904_Sum_Order_By>;
  var_pop?: Maybe<Ura_RMatchSummary904_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RMatchSummary904_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RMatchSummary904_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Arr_Rel_Insert_Input = {
  data: Array<Ura_RMatchSummary904_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RMatchSummary904_Avg_Fields = {
  __typename?: "ura_RMatchSummary904_avg_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Avg_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RMatchSummary904". All fields are combined with a logical 'AND'. */
export type Ura_RMatchSummary904_Bool_Exp = {
  COUNT_URA?: Maybe<Bigint_Comparison_Exp>;
  CUSTOMER_ID?: Maybe<Bigint_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_EMP?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  KCCA_DOB?: Maybe<Date_Comparison_Exp>;
  KCCA_EMAIL?: Maybe<String_Comparison_Exp>;
  KCCA_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_LANDLINE?: Maybe<String_Comparison_Exp>;
  KCCA_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_MOBILE1?: Maybe<String_Comparison_Exp>;
  KCCA_MOBILE2?: Maybe<String_Comparison_Exp>;
  KCCA_NIN?: Maybe<String_Comparison_Exp>;
  KCCA_SURNAME?: Maybe<String_Comparison_Exp>;
  KCCA_TIN?: Maybe<String_Comparison_Exp>;
  LANDLINE_NUMBER?: Maybe<Float8_Comparison_Exp>;
  MATCH_DETAILS?: Maybe<String_Comparison_Exp>;
  MATCH_KCCA_URA?: Maybe<String_Comparison_Exp>;
  MATCH_SCORE?: Maybe<Bigint_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<Float8_Comparison_Exp>;
  NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  NUM_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  SCORE_DETAILS?: Maybe<String_Comparison_Exp>;
  SUM_ADJUSTED_INCOME?: Maybe<Float8_Comparison_Exp>;
  SUM_GROSS_VALUE?: Maybe<Float8_Comparison_Exp>;
  SUM_RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  URA_DOB?: Maybe<Date_Comparison_Exp>;
  URA_FIRSTNAME?: Maybe<String_Comparison_Exp>;
  URA_MIDDLENAME?: Maybe<String_Comparison_Exp>;
  URA_SURNAME?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RMatchSummary904_Bool_Exp>>>;
  _not?: Maybe<Ura_RMatchSummary904_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RMatchSummary904_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Inc_Input = {
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Insert_Input = {
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_DOB?: Maybe<Scalars["date"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_DOB?: Maybe<Scalars["date"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RMatchSummary904_Max_Fields = {
  __typename?: "ura_RMatchSummary904_max_fields";
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_DOB?: Maybe<Scalars["date"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_DOB?: Maybe<Scalars["date"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Max_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_DOB?: Maybe<Order_By>;
  KCCA_EMAIL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_LANDLINE?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_MOBILE1?: Maybe<Order_By>;
  KCCA_MOBILE2?: Maybe<Order_By>;
  KCCA_NIN?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_DETAILS?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SCORE_DETAILS?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_DOB?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RMatchSummary904_Min_Fields = {
  __typename?: "ura_RMatchSummary904_min_fields";
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_DOB?: Maybe<Scalars["date"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_DOB?: Maybe<Scalars["date"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Min_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_DOB?: Maybe<Order_By>;
  KCCA_EMAIL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_LANDLINE?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_MOBILE1?: Maybe<Order_By>;
  KCCA_MOBILE2?: Maybe<Order_By>;
  KCCA_NIN?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_DETAILS?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SCORE_DETAILS?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_DOB?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Mutation_Response = {
  __typename?: "ura_RMatchSummary904_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RMatchSummary904>;
};

/** input type for inserting object relation for remote table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Obj_Rel_Insert_Input = {
  data: Ura_RMatchSummary904_Insert_Input;
};

/** ordering options when selecting data from "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_DOB?: Maybe<Order_By>;
  KCCA_EMAIL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_LANDLINE?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_MOBILE1?: Maybe<Order_By>;
  KCCA_MOBILE2?: Maybe<Order_By>;
  KCCA_NIN?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_DETAILS?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SCORE_DETAILS?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_DOB?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RMatchSummary904" */
export enum Ura_RMatchSummary904_Select_Column {
  /** column name */
  CountUra = "COUNT_URA",
  /** column name */
  CustomerId = "CUSTOMER_ID",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcEmp = "IS_INCOME_SRC_EMP",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  KccaDob = "KCCA_DOB",
  /** column name */
  KccaEmail = "KCCA_EMAIL",
  /** column name */
  KccaFirstName = "KCCA_FIRST_NAME",
  /** column name */
  KccaLandline = "KCCA_LANDLINE",
  /** column name */
  KccaMiddleName = "KCCA_MIDDLE_NAME",
  /** column name */
  KccaMobile1 = "KCCA_MOBILE1",
  /** column name */
  KccaMobile2 = "KCCA_MOBILE2",
  /** column name */
  KccaNin = "KCCA_NIN",
  /** column name */
  KccaSurname = "KCCA_SURNAME",
  /** column name */
  KccaTin = "KCCA_TIN",
  /** column name */
  LandlineNumber = "LANDLINE_NUMBER",
  /** column name */
  MatchDetails = "MATCH_DETAILS",
  /** column name */
  MatchKccaUra = "MATCH_KCCA_URA",
  /** column name */
  MatchScore = "MATCH_SCORE",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  NationalId = "NATIONAL_ID",
  /** column name */
  NumProperties = "NUM_PROPERTIES",
  /** column name */
  ScoreDetails = "SCORE_DETAILS",
  /** column name */
  SumAdjustedIncome = "SUM_ADJUSTED_INCOME",
  /** column name */
  SumGrossValue = "SUM_GROSS_VALUE",
  /** column name */
  SumRateableValue = "SUM_RATEABLE_VALUE",
  /** column name */
  TotalRentedProperties = "TOTAL_RENTED_PROPERTIES",
  /** column name */
  UraDob = "URA_DOB",
  /** column name */
  UraFirstname = "URA_FIRSTNAME",
  /** column name */
  UraMiddlename = "URA_MIDDLENAME",
  /** column name */
  UraSurname = "URA_SURNAME",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Set_Input = {
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_DOB?: Maybe<Scalars["date"]>;
  KCCA_EMAIL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_LANDLINE?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_MOBILE1?: Maybe<Scalars["String"]>;
  KCCA_MOBILE2?: Maybe<Scalars["String"]>;
  KCCA_NIN?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MATCH_DETAILS?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_DETAILS?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_DOB?: Maybe<Scalars["date"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RMatchSummary904_Stddev_Fields = {
  __typename?: "ura_RMatchSummary904_stddev_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Stddev_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RMatchSummary904_Stddev_Pop_Fields = {
  __typename?: "ura_RMatchSummary904_stddev_pop_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Stddev_Pop_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RMatchSummary904_Stddev_Samp_Fields = {
  __typename?: "ura_RMatchSummary904_stddev_samp_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Stddev_Samp_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RMatchSummary904_Sum_Fields = {
  __typename?: "ura_RMatchSummary904_sum_fields";
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Sum_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RMatchSummary904_Var_Pop_Fields = {
  __typename?: "ura_RMatchSummary904_var_pop_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Var_Pop_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RMatchSummary904_Var_Samp_Fields = {
  __typename?: "ura_RMatchSummary904_var_samp_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Var_Samp_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RMatchSummary904_Variance_Fields = {
  __typename?: "ura_RMatchSummary904_variance_fields";
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RMatchSummary904" */
export type Ura_RMatchSummary904_Variance_Order_By = {
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RMatchSummary916" */
export type Ura_RMatchSummary916 = {
  __typename?: "ura_RMatchSummary916";
  AVG_RATE?: Maybe<Scalars["float8"]>;
  COMPLIANCE_CATEGORY?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIFFERENCE?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  urid?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Aggregate = {
  __typename?: "ura_RMatchSummary916_aggregate";
  aggregate?: Maybe<Ura_RMatchSummary916_Aggregate_Fields>;
  nodes: Array<Ura_RMatchSummary916>;
};

/** aggregate fields of "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Aggregate_Fields = {
  __typename?: "ura_RMatchSummary916_aggregate_fields";
  avg?: Maybe<Ura_RMatchSummary916_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RMatchSummary916_Max_Fields>;
  min?: Maybe<Ura_RMatchSummary916_Min_Fields>;
  stddev?: Maybe<Ura_RMatchSummary916_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RMatchSummary916_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RMatchSummary916_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RMatchSummary916_Sum_Fields>;
  var_pop?: Maybe<Ura_RMatchSummary916_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RMatchSummary916_Var_Samp_Fields>;
  variance?: Maybe<Ura_RMatchSummary916_Variance_Fields>;
};

/** aggregate fields of "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RMatchSummary916_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Aggregate_Order_By = {
  avg?: Maybe<Ura_RMatchSummary916_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RMatchSummary916_Max_Order_By>;
  min?: Maybe<Ura_RMatchSummary916_Min_Order_By>;
  stddev?: Maybe<Ura_RMatchSummary916_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RMatchSummary916_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RMatchSummary916_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RMatchSummary916_Sum_Order_By>;
  var_pop?: Maybe<Ura_RMatchSummary916_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RMatchSummary916_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RMatchSummary916_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Arr_Rel_Insert_Input = {
  data: Array<Ura_RMatchSummary916_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RMatchSummary916_Avg_Fields = {
  __typename?: "ura_RMatchSummary916_avg_fields";
  AVG_RATE?: Maybe<Scalars["Float"]>;
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  DIFFERENCE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Avg_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RMatchSummary916". All fields are combined with a logical 'AND'. */
export type Ura_RMatchSummary916_Bool_Exp = {
  AVG_RATE?: Maybe<Float8_Comparison_Exp>;
  COMPLIANCE_CATEGORY?: Maybe<String_Comparison_Exp>;
  COUNT_URA?: Maybe<Bigint_Comparison_Exp>;
  CUSTOMER_ID?: Maybe<Bigint_Comparison_Exp>;
  DIFFERENCE?: Maybe<Float8_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  IS_AMENDMENT?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_EMP?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  KCCA_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_SURNAME?: Maybe<String_Comparison_Exp>;
  MATCH_KCCA_URA?: Maybe<String_Comparison_Exp>;
  MATCH_SCORE?: Maybe<Bigint_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<Float8_Comparison_Exp>;
  NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Bigint_Comparison_Exp>;
  NUM_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  PERCENTAGE_DIFFERENCE?: Maybe<Float8_Comparison_Exp>;
  PROPINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  RENTINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_PERIOD_YEAR?: Maybe<String_Comparison_Exp>;
  SCORE_MATCH_ATRIBUTES?: Maybe<String_Comparison_Exp>;
  SUM_ADJUSTED_INCOME?: Maybe<Float8_Comparison_Exp>;
  SUM_GROSS_VALUE?: Maybe<Float8_Comparison_Exp>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  SUM_RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  URA_FIRSTNAME?: Maybe<String_Comparison_Exp>;
  URA_MATCHING_TINS?: Maybe<Bigint_Comparison_Exp>;
  URA_MIDDLENAME?: Maybe<String_Comparison_Exp>;
  URA_SURNAME?: Maybe<String_Comparison_Exp>;
  URA_TIN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RMatchSummary916_Bool_Exp>>>;
  _not?: Maybe<Ura_RMatchSummary916_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RMatchSummary916_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  urid?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Inc_Input = {
  AVG_RATE?: Maybe<Scalars["float8"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIFFERENCE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Insert_Input = {
  AVG_RATE?: Maybe<Scalars["float8"]>;
  COMPLIANCE_CATEGORY?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIFFERENCE?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  urid?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RMatchSummary916_Max_Fields = {
  __typename?: "ura_RMatchSummary916_max_fields";
  AVG_RATE?: Maybe<Scalars["float8"]>;
  COMPLIANCE_CATEGORY?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIFFERENCE?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  urid?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Max_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COMPLIANCE_CATEGORY?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RMatchSummary916_Min_Fields = {
  __typename?: "ura_RMatchSummary916_min_fields";
  AVG_RATE?: Maybe<Scalars["float8"]>;
  COMPLIANCE_CATEGORY?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIFFERENCE?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  urid?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Min_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COMPLIANCE_CATEGORY?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Mutation_Response = {
  __typename?: "ura_RMatchSummary916_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RMatchSummary916>;
};

/** input type for inserting object relation for remote table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Obj_Rel_Insert_Input = {
  data: Ura_RMatchSummary916_Insert_Input;
};

/** ordering options when selecting data from "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COMPLIANCE_CATEGORY?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RMatchSummary916" */
export enum Ura_RMatchSummary916_Select_Column {
  /** column name */
  AvgRate = "AVG_RATE",
  /** column name */
  ComplianceCategory = "COMPLIANCE_CATEGORY",
  /** column name */
  CountUra = "COUNT_URA",
  /** column name */
  CustomerId = "CUSTOMER_ID",
  /** column name */
  Difference = "DIFFERENCE",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  IsAmendment = "IS_AMENDMENT",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcEmp = "IS_INCOME_SRC_EMP",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  KccaFirstName = "KCCA_FIRST_NAME",
  /** column name */
  KccaMiddleName = "KCCA_MIDDLE_NAME",
  /** column name */
  KccaSurname = "KCCA_SURNAME",
  /** column name */
  MatchKccaUra = "MATCH_KCCA_URA",
  /** column name */
  MatchScore = "MATCH_SCORE",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  NationalId = "NATIONAL_ID",
  /** column name */
  NumOwnedNonRateable = "NUM_OWNED_NON_RATEABLE",
  /** column name */
  NumProperties = "NUM_PROPERTIES",
  /** column name */
  PercentageDifference = "PERCENTAGE_DIFFERENCE",
  /** column name */
  PropincIncome = "PROPINC_INCOME",
  /** column name */
  RentincIncome = "RENTINC_INCOME",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnPeriodYear = "RTN_PERIOD_YEAR",
  /** column name */
  ScoreMatchAtributes = "SCORE_MATCH_ATRIBUTES",
  /** column name */
  SumAdjustedIncome = "SUM_ADJUSTED_INCOME",
  /** column name */
  SumGrossValue = "SUM_GROSS_VALUE",
  /** column name */
  SumNonRateableValue = "SUM_NON_RATEABLE_VALUE",
  /** column name */
  SumRateableValue = "SUM_RATEABLE_VALUE",
  /** column name */
  TotalRentedProperties = "TOTAL_RENTED_PROPERTIES",
  /** column name */
  UraFirstname = "URA_FIRSTNAME",
  /** column name */
  UraMatchingTins = "URA_MATCHING_TINS",
  /** column name */
  UraMiddlename = "URA_MIDDLENAME",
  /** column name */
  UraSurname = "URA_SURNAME",
  /** column name */
  UraTin = "URA_TIN",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  Urid = "urid",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Set_Input = {
  AVG_RATE?: Maybe<Scalars["float8"]>;
  COMPLIANCE_CATEGORY?: Maybe<Scalars["String"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIFFERENCE?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  urid?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RMatchSummary916_Stddev_Fields = {
  __typename?: "ura_RMatchSummary916_stddev_fields";
  AVG_RATE?: Maybe<Scalars["Float"]>;
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  DIFFERENCE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Stddev_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RMatchSummary916_Stddev_Pop_Fields = {
  __typename?: "ura_RMatchSummary916_stddev_pop_fields";
  AVG_RATE?: Maybe<Scalars["Float"]>;
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  DIFFERENCE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Stddev_Pop_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RMatchSummary916_Stddev_Samp_Fields = {
  __typename?: "ura_RMatchSummary916_stddev_samp_fields";
  AVG_RATE?: Maybe<Scalars["Float"]>;
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  DIFFERENCE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Stddev_Samp_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RMatchSummary916_Sum_Fields = {
  __typename?: "ura_RMatchSummary916_sum_fields";
  AVG_RATE?: Maybe<Scalars["float8"]>;
  COUNT_URA?: Maybe<Scalars["bigint"]>;
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  DIFFERENCE?: Maybe<Scalars["float8"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Sum_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RMatchSummary916_Var_Pop_Fields = {
  __typename?: "ura_RMatchSummary916_var_pop_fields";
  AVG_RATE?: Maybe<Scalars["Float"]>;
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  DIFFERENCE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Var_Pop_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RMatchSummary916_Var_Samp_Fields = {
  __typename?: "ura_RMatchSummary916_var_samp_fields";
  AVG_RATE?: Maybe<Scalars["Float"]>;
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  DIFFERENCE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Var_Samp_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RMatchSummary916_Variance_Fields = {
  __typename?: "ura_RMatchSummary916_variance_fields";
  AVG_RATE?: Maybe<Scalars["Float"]>;
  COUNT_URA?: Maybe<Scalars["Float"]>;
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  DIFFERENCE?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  PERCENTAGE_DIFFERENCE?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RMatchSummary916" */
export type Ura_RMatchSummary916_Variance_Order_By = {
  AVG_RATE?: Maybe<Order_By>;
  COUNT_URA?: Maybe<Order_By>;
  CUSTOMER_ID?: Maybe<Order_By>;
  DIFFERENCE?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_NON_RATEABLE?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  PERCENTAGE_DIFFERENCE?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RMatchSummary946" */
export type Ura_RMatchSummary946 = {
  __typename?: "ura_RMatchSummary946";
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Aggregate = {
  __typename?: "ura_RMatchSummary946_aggregate";
  aggregate?: Maybe<Ura_RMatchSummary946_Aggregate_Fields>;
  nodes: Array<Ura_RMatchSummary946>;
};

/** aggregate fields of "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Aggregate_Fields = {
  __typename?: "ura_RMatchSummary946_aggregate_fields";
  avg?: Maybe<Ura_RMatchSummary946_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RMatchSummary946_Max_Fields>;
  min?: Maybe<Ura_RMatchSummary946_Min_Fields>;
  stddev?: Maybe<Ura_RMatchSummary946_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RMatchSummary946_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RMatchSummary946_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RMatchSummary946_Sum_Fields>;
  var_pop?: Maybe<Ura_RMatchSummary946_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RMatchSummary946_Var_Samp_Fields>;
  variance?: Maybe<Ura_RMatchSummary946_Variance_Fields>;
};

/** aggregate fields of "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RMatchSummary946_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Aggregate_Order_By = {
  avg?: Maybe<Ura_RMatchSummary946_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RMatchSummary946_Max_Order_By>;
  min?: Maybe<Ura_RMatchSummary946_Min_Order_By>;
  stddev?: Maybe<Ura_RMatchSummary946_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RMatchSummary946_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RMatchSummary946_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RMatchSummary946_Sum_Order_By>;
  var_pop?: Maybe<Ura_RMatchSummary946_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RMatchSummary946_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RMatchSummary946_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Arr_Rel_Insert_Input = {
  data: Array<Ura_RMatchSummary946_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RMatchSummary946_Avg_Fields = {
  __typename?: "ura_RMatchSummary946_avg_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Avg_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RMatchSummary946". All fields are combined with a logical 'AND'. */
export type Ura_RMatchSummary946_Bool_Exp = {
  CUSTOMER_ID?: Maybe<Bigint_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  KCCA_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  KCCA_SURNAME?: Maybe<String_Comparison_Exp>;
  KCCA_TIN?: Maybe<String_Comparison_Exp>;
  MATCH_KCCA_URA?: Maybe<String_Comparison_Exp>;
  MATCH_SCORE?: Maybe<Bigint_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<Float8_Comparison_Exp>;
  NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  NUM_OWNED_OCCUPIED?: Maybe<Bigint_Comparison_Exp>;
  NUM_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  SCORE_MATCH_ATRIBUTES?: Maybe<String_Comparison_Exp>;
  SUM_ADJUSTED_INCOME?: Maybe<Float8_Comparison_Exp>;
  SUM_GROSS_VALUE?: Maybe<Float8_Comparison_Exp>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  SUM_RATEABLE_VALUE?: Maybe<Float8_Comparison_Exp>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Bigint_Comparison_Exp>;
  URA_FIRSTNAME?: Maybe<String_Comparison_Exp>;
  URA_MATCHING_TINS?: Maybe<String_Comparison_Exp>;
  URA_MIDDLENAME?: Maybe<String_Comparison_Exp>;
  URA_SURNAME?: Maybe<String_Comparison_Exp>;
  URA_TIN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RMatchSummary946_Bool_Exp>>>;
  _not?: Maybe<Ura_RMatchSummary946_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RMatchSummary946_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Inc_Input = {
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Insert_Input = {
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RMatchSummary946_Max_Fields = {
  __typename?: "ura_RMatchSummary946_max_fields";
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Max_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RMatchSummary946_Min_Fields = {
  __typename?: "ura_RMatchSummary946_min_fields";
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Min_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Mutation_Response = {
  __typename?: "ura_RMatchSummary946_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RMatchSummary946>;
};

/** input type for inserting object relation for remote table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Obj_Rel_Insert_Input = {
  data: Ura_RMatchSummary946_Insert_Input;
};

/** ordering options when selecting data from "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  KCCA_FIRST_NAME?: Maybe<Order_By>;
  KCCA_MIDDLE_NAME?: Maybe<Order_By>;
  KCCA_SURNAME?: Maybe<Order_By>;
  KCCA_TIN?: Maybe<Order_By>;
  MATCH_KCCA_URA?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  URA_FIRSTNAME?: Maybe<Order_By>;
  URA_MATCHING_TINS?: Maybe<Order_By>;
  URA_MIDDLENAME?: Maybe<Order_By>;
  URA_SURNAME?: Maybe<Order_By>;
  URA_TIN?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RMatchSummary946" */
export enum Ura_RMatchSummary946_Select_Column {
  /** column name */
  CustomerId = "CUSTOMER_ID",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  KccaFirstName = "KCCA_FIRST_NAME",
  /** column name */
  KccaMiddleName = "KCCA_MIDDLE_NAME",
  /** column name */
  KccaSurname = "KCCA_SURNAME",
  /** column name */
  KccaTin = "KCCA_TIN",
  /** column name */
  MatchKccaUra = "MATCH_KCCA_URA",
  /** column name */
  MatchScore = "MATCH_SCORE",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  NationalId = "NATIONAL_ID",
  /** column name */
  NumOwnedOccupied = "NUM_OWNED_OCCUPIED",
  /** column name */
  NumProperties = "NUM_PROPERTIES",
  /** column name */
  ScoreMatchAtributes = "SCORE_MATCH_ATRIBUTES",
  /** column name */
  SumAdjustedIncome = "SUM_ADJUSTED_INCOME",
  /** column name */
  SumGrossValue = "SUM_GROSS_VALUE",
  /** column name */
  SumNonRateableValue = "SUM_NON_RATEABLE_VALUE",
  /** column name */
  SumRateableValue = "SUM_RATEABLE_VALUE",
  /** column name */
  TotalRentedProperties = "TOTAL_RENTED_PROPERTIES",
  /** column name */
  UraFirstname = "URA_FIRSTNAME",
  /** column name */
  UraMatchingTins = "URA_MATCHING_TINS",
  /** column name */
  UraMiddlename = "URA_MIDDLENAME",
  /** column name */
  UraSurname = "URA_SURNAME",
  /** column name */
  UraTin = "URA_TIN",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Set_Input = {
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  KCCA_FIRST_NAME?: Maybe<Scalars["String"]>;
  KCCA_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  KCCA_SURNAME?: Maybe<Scalars["String"]>;
  KCCA_TIN?: Maybe<Scalars["String"]>;
  MATCH_KCCA_URA?: Maybe<Scalars["String"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SCORE_MATCH_ATRIBUTES?: Maybe<Scalars["String"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  URA_FIRSTNAME?: Maybe<Scalars["String"]>;
  URA_MATCHING_TINS?: Maybe<Scalars["String"]>;
  URA_MIDDLENAME?: Maybe<Scalars["String"]>;
  URA_SURNAME?: Maybe<Scalars["String"]>;
  URA_TIN?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RMatchSummary946_Stddev_Fields = {
  __typename?: "ura_RMatchSummary946_stddev_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Stddev_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RMatchSummary946_Stddev_Pop_Fields = {
  __typename?: "ura_RMatchSummary946_stddev_pop_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Stddev_Pop_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RMatchSummary946_Stddev_Samp_Fields = {
  __typename?: "ura_RMatchSummary946_stddev_samp_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Stddev_Samp_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RMatchSummary946_Sum_Fields = {
  __typename?: "ura_RMatchSummary946_sum_fields";
  CUSTOMER_ID?: Maybe<Scalars["bigint"]>;
  MATCH_SCORE?: Maybe<Scalars["bigint"]>;
  MOBILE_NUMBER?: Maybe<Scalars["float8"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["bigint"]>;
  NUM_PROPERTIES?: Maybe<Scalars["bigint"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["float8"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["float8"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["float8"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Sum_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RMatchSummary946_Var_Pop_Fields = {
  __typename?: "ura_RMatchSummary946_var_pop_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Var_Pop_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RMatchSummary946_Var_Samp_Fields = {
  __typename?: "ura_RMatchSummary946_var_samp_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Var_Samp_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RMatchSummary946_Variance_Fields = {
  __typename?: "ura_RMatchSummary946_variance_fields";
  CUSTOMER_ID?: Maybe<Scalars["Float"]>;
  MATCH_SCORE?: Maybe<Scalars["Float"]>;
  MOBILE_NUMBER?: Maybe<Scalars["Float"]>;
  NUM_OWNED_OCCUPIED?: Maybe<Scalars["Float"]>;
  NUM_PROPERTIES?: Maybe<Scalars["Float"]>;
  SUM_ADJUSTED_INCOME?: Maybe<Scalars["Float"]>;
  SUM_GROSS_VALUE?: Maybe<Scalars["Float"]>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  SUM_RATEABLE_VALUE?: Maybe<Scalars["Float"]>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RMatchSummary946" */
export type Ura_RMatchSummary946_Variance_Order_By = {
  CUSTOMER_ID?: Maybe<Order_By>;
  MATCH_SCORE?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NUM_OWNED_OCCUPIED?: Maybe<Order_By>;
  NUM_PROPERTIES?: Maybe<Order_By>;
  SUM_ADJUSTED_INCOME?: Maybe<Order_By>;
  SUM_GROSS_VALUE?: Maybe<Order_By>;
  SUM_NON_RATEABLE_VALUE?: Maybe<Order_By>;
  SUM_RATEABLE_VALUE?: Maybe<Order_By>;
  TOTAL_RENTED_PROPERTIES?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData = {
  __typename?: "ura_RPT_IndividualTaxSummaryData";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Aggregate = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_aggregate";
  aggregate?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Aggregate_Fields>;
  nodes: Array<Ura_Rpt_IndividualTaxSummaryData>;
};

/** aggregate fields of "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Aggregate_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_aggregate_fields";
  avg?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Max_Fields>;
  min?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Min_Fields>;
  stddev?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Sum_Fields>;
  var_pop?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Var_Samp_Fields>;
  variance?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Variance_Fields>;
};

/** aggregate fields of "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_Rpt_IndividualTaxSummaryData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Aggregate_Order_By = {
  avg?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Max_Order_By>;
  min?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Min_Order_By>;
  stddev?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Sum_Order_By>;
  var_pop?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Var_Samp_Order_By>;
  variance?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Arr_Rel_Insert_Input = {
  data: Array<Ura_Rpt_IndividualTaxSummaryData_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Avg_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_avg_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Avg_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RPT_IndividualTaxSummaryData". All fields are combined with a logical 'AND'. */
export type Ura_Rpt_IndividualTaxSummaryData_Bool_Exp = {
  APPLIED_FOR_TIN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_SURNAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_TIN?: Maybe<String_Comparison_Exp>;
  BIRTH_CITY?: Maybe<Float8_Comparison_Exp>;
  BIRTH_COUNTRY?: Maybe<String_Comparison_Exp>;
  BIRTH_DT?: Maybe<String_Comparison_Exp>;
  BIRTH_STATE?: Maybe<String_Comparison_Exp>;
  BSNS_NAME?: Maybe<String_Comparison_Exp>;
  CHRGD_RENTAL_INCM?: Maybe<Float8_Comparison_Exp>;
  CITIZEN_COUNTRY?: Maybe<String_Comparison_Exp>;
  DEDUCT_INCM_TAX?: Maybe<Float8_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  EMPLYMNT_INCOME?: Maybe<Float8_Comparison_Exp>;
  EMPLYMNT_RATE?: Maybe<Float8_Comparison_Exp>;
  EMPLYMNT_TAX?: Maybe<Float8_Comparison_Exp>;
  EXIST_TIN_NO?: Maybe<String_Comparison_Exp>;
  FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  FIRST_NAME?: Maybe<String_Comparison_Exp>;
  GENDER?: Maybe<String_Comparison_Exp>;
  GROSS_RENTAL_INCM?: Maybe<Float8_Comparison_Exp>;
  HOME_ADDR_BUILDINGNAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_PARISH_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_PLOTNO?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_STRRET_ADDR?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_TOWN_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<String_Comparison_Exp>;
  INCOME_TAX_P?: Maybe<Float8_Comparison_Exp>;
  IS_AMENDMENT?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_EMP?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  IS_RESIDENT?: Maybe<String_Comparison_Exp>;
  LANDLINE_NUMBER?: Maybe<Float8_Comparison_Exp>;
  MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  MOTGAGE_INT_DECD?: Maybe<Float8_Comparison_Exp>;
  NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  NET_RENT_INCM?: Maybe<Float8_Comparison_Exp>;
  NON_RENTAL_INCOME?: Maybe<Float8_Comparison_Exp>;
  NSSF_ID?: Maybe<String_Comparison_Exp>;
  OTHER_NAME?: Maybe<String_Comparison_Exp>;
  PARTNER_RENT_INCOME?: Maybe<Float8_Comparison_Exp>;
  POSTADDR_DISTICT_NAME?: Maybe<String_Comparison_Exp>;
  POSTADDR_ID?: Maybe<String_Comparison_Exp>;
  PROPINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  PROPINC_RATE?: Maybe<Float8_Comparison_Exp>;
  PROPINC_TAX?: Maybe<Float8_Comparison_Exp>;
  REF_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  REF_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  REF_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  REF_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  REF_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  REF_SURNAME?: Maybe<String_Comparison_Exp>;
  REF_TIN?: Maybe<String_Comparison_Exp>;
  RENTAL_TAX_P?: Maybe<Float8_Comparison_Exp>;
  RENTINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  RENTINC_P?: Maybe<Float8_Comparison_Exp>;
  RENTINC_RATE?: Maybe<Float8_Comparison_Exp>;
  RENTINC_TAX?: Maybe<Float8_Comparison_Exp>;
  RTN_DT?: Maybe<String_Comparison_Exp>;
  RTN_FROM_DT?: Maybe<String_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_PERIOD_YEAR?: Maybe<String_Comparison_Exp>;
  RTN_TO_DT?: Maybe<String_Comparison_Exp>;
  SUR_NAME?: Maybe<String_Comparison_Exp>;
  TAX_ASSESSED?: Maybe<Float8_Comparison_Exp>;
  TAX_PAYER_ID?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_NAME?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_TYPE?: Maybe<String_Comparison_Exp>;
  THRESHOLD_AMNT?: Maybe<Float8_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  TOT_RENTAL_INCM?: Maybe<Float8_Comparison_Exp>;
  Tot_INCOME?: Maybe<Float8_Comparison_Exp>;
  Tot_RATE?: Maybe<Float8_Comparison_Exp>;
  Tot_TAX?: Maybe<Float8_Comparison_Exp>;
  Visible_Level?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_Rpt_IndividualTaxSummaryData_Bool_Exp>>>;
  _not?: Maybe<Ura_Rpt_IndividualTaxSummaryData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_Rpt_IndividualTaxSummaryData_Bool_Exp>>>;
  cat_legend?: Maybe<String_Comparison_Exp>;
  cat_legend_ri?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  file_x?: Maybe<String_Comparison_Exp>;
  file_y?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<String_Comparison_Exp>;
  last_updated_x?: Maybe<String_Comparison_Exp>;
  last_updated_y?: Maybe<String_Comparison_Exp>;
  legend?: Maybe<String_Comparison_Exp>;
  legend_ri?: Maybe<String_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  line_x?: Maybe<Bigint_Comparison_Exp>;
  line_y?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
  who_updated_x?: Maybe<String_Comparison_Exp>;
  who_updated_y?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Inc_Input = {
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Insert_Input = {
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Max_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_max_fields";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Max_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  cat_legend?: Maybe<Order_By>;
  cat_legend_ri?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  file_x?: Maybe<Order_By>;
  file_y?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  last_updated_x?: Maybe<Order_By>;
  last_updated_y?: Maybe<Order_By>;
  legend?: Maybe<Order_By>;
  legend_ri?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  who_updated_x?: Maybe<Order_By>;
  who_updated_y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Min_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_min_fields";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Min_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  cat_legend?: Maybe<Order_By>;
  cat_legend_ri?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  file_x?: Maybe<Order_By>;
  file_y?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  last_updated_x?: Maybe<Order_By>;
  last_updated_y?: Maybe<Order_By>;
  legend?: Maybe<Order_By>;
  legend_ri?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  who_updated_x?: Maybe<Order_By>;
  who_updated_y?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Mutation_Response = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_Rpt_IndividualTaxSummaryData>;
};

/** input type for inserting object relation for remote table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Obj_Rel_Insert_Input = {
  data: Ura_Rpt_IndividualTaxSummaryData_Insert_Input;
};

/** ordering options when selecting data from "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  cat_legend?: Maybe<Order_By>;
  cat_legend_ri?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  file_x?: Maybe<Order_By>;
  file_y?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  last_updated_x?: Maybe<Order_By>;
  last_updated_y?: Maybe<Order_By>;
  legend?: Maybe<Order_By>;
  legend_ri?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
  who_updated_x?: Maybe<Order_By>;
  who_updated_y?: Maybe<Order_By>;
};

/** select columns of table "ura.RPT_IndividualTaxSummaryData" */
export enum Ura_Rpt_IndividualTaxSummaryData_Select_Column {
  /** column name */
  AppliedForTin = "APPLIED_FOR_TIN",
  /** column name */
  AuthctcdEmailId = "AUTHCTCD_EMAIL_ID",
  /** column name */
  AuthctcdFamilyName = "AUTHCTCD_FAMILY_NAME",
  /** column name */
  AuthctcdFirstName = "AUTHCTCD_FIRST_NAME",
  /** column name */
  AuthctcdLandlineNumber = "AUTHCTCD_LANDLINE_NUMBER",
  /** column name */
  AuthctcdMobileNumber = "AUTHCTCD_MOBILE_NUMBER",
  /** column name */
  AuthctcdOtherName = "AUTHCTCD_OTHER_NAME",
  /** column name */
  AuthctcdPrsnDesgn = "AUTHCTCD_PRSN_DESGN",
  /** column name */
  AuthctcdSurname = "AUTHCTCD_SURNAME",
  /** column name */
  AuthctcdTin = "AUTHCTCD_TIN",
  /** column name */
  BirthCity = "BIRTH_CITY",
  /** column name */
  BirthCountry = "BIRTH_COUNTRY",
  /** column name */
  BirthDt = "BIRTH_DT",
  /** column name */
  BirthState = "BIRTH_STATE",
  /** column name */
  BsnsName = "BSNS_NAME",
  /** column name */
  ChrgdRentalIncm = "CHRGD_RENTAL_INCM",
  /** column name */
  CitizenCountry = "CITIZEN_COUNTRY",
  /** column name */
  DeductIncmTax = "DEDUCT_INCM_TAX",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  EmplymntIncome = "EMPLYMNT_INCOME",
  /** column name */
  EmplymntRate = "EMPLYMNT_RATE",
  /** column name */
  EmplymntTax = "EMPLYMNT_TAX",
  /** column name */
  ExistTinNo = "EXIST_TIN_NO",
  /** column name */
  FamilyName = "FAMILY_NAME",
  /** column name */
  FirstName = "FIRST_NAME",
  /** column name */
  Gender = "GENDER",
  /** column name */
  GrossRentalIncm = "GROSS_RENTAL_INCM",
  /** column name */
  HomeAddrBuildingname = "HOME_ADDR_BUILDINGNAME",
  /** column name */
  HomeAddrDistrictName = "HOME_ADDR_DISTRICT_NAME",
  /** column name */
  HomeAddrLocalCouncil = "HOME_ADDR_LOCAL_COUNCIL",
  /** column name */
  HomeAddrParishName = "HOME_ADDR_PARISH_NAME",
  /** column name */
  HomeAddrPlotno = "HOME_ADDR_PLOTNO",
  /** column name */
  HomeAddrStrretAddr = "HOME_ADDR_STRRET_ADDR",
  /** column name */
  HomeAddrSubCountyName = "HOME_ADDR_SUB_COUNTY_NAME",
  /** column name */
  HomeAddrTownName = "HOME_ADDR_TOWN_NAME",
  /** column name */
  HomeAddrTradeCentre = "HOME_ADDR_TRADE_CENTRE",
  /** column name */
  HomeAddrVillageName = "HOME_ADDR_VILLAGE_NAME",
  /** column name */
  IncomeTaxP = "INCOME_TAX_P",
  /** column name */
  IsAmendment = "IS_AMENDMENT",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcEmp = "IS_INCOME_SRC_EMP",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  IsResident = "IS_RESIDENT",
  /** column name */
  LandlineNumber = "LANDLINE_NUMBER",
  /** column name */
  MiddleName = "MIDDLE_NAME",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  MotgageIntDecd = "MOTGAGE_INT_DECD",
  /** column name */
  NationalId = "NATIONAL_ID",
  /** column name */
  NetRentIncm = "NET_RENT_INCM",
  /** column name */
  NonRentalIncome = "NON_RENTAL_INCOME",
  /** column name */
  NssfId = "NSSF_ID",
  /** column name */
  OtherName = "OTHER_NAME",
  /** column name */
  PartnerRentIncome = "PARTNER_RENT_INCOME",
  /** column name */
  PostaddrDistictName = "POSTADDR_DISTICT_NAME",
  /** column name */
  PostaddrId = "POSTADDR_ID",
  /** column name */
  PropincIncome = "PROPINC_INCOME",
  /** column name */
  PropincRate = "PROPINC_RATE",
  /** column name */
  PropincTax = "PROPINC_TAX",
  /** column name */
  RefEmailId = "REF_EMAIL_ID",
  /** column name */
  RefFamilyName = "REF_FAMILY_NAME",
  /** column name */
  RefFirstName = "REF_FIRST_NAME",
  /** column name */
  RefLandlineNumber = "REF_LANDLINE_NUMBER",
  /** column name */
  RefMobileNumber = "REF_MOBILE_NUMBER",
  /** column name */
  RefOtherName = "REF_OTHER_NAME",
  /** column name */
  RefPrsnDesgn = "REF_PRSN_DESGN",
  /** column name */
  RefSurname = "REF_SURNAME",
  /** column name */
  RefTin = "REF_TIN",
  /** column name */
  RentalTaxP = "RENTAL_TAX_P",
  /** column name */
  RentincIncome = "RENTINC_INCOME",
  /** column name */
  RentincP = "RENTINC_P",
  /** column name */
  RentincRate = "RENTINC_RATE",
  /** column name */
  RentincTax = "RENTINC_TAX",
  /** column name */
  RtnDt = "RTN_DT",
  /** column name */
  RtnFromDt = "RTN_FROM_DT",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnPeriodYear = "RTN_PERIOD_YEAR",
  /** column name */
  RtnToDt = "RTN_TO_DT",
  /** column name */
  SurName = "SUR_NAME",
  /** column name */
  TaxAssessed = "TAX_ASSESSED",
  /** column name */
  TaxPayerId = "TAX_PAYER_ID",
  /** column name */
  TaxPayerName = "TAX_PAYER_NAME",
  /** column name */
  TaxPayerType = "TAX_PAYER_TYPE",
  /** column name */
  ThresholdAmnt = "THRESHOLD_AMNT",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  TotRentalIncm = "TOT_RENTAL_INCM",
  /** column name */
  TotIncome = "Tot_INCOME",
  /** column name */
  TotRate = "Tot_RATE",
  /** column name */
  TotTax = "Tot_TAX",
  /** column name */
  VisibleLevel = "Visible_Level",
  /** column name */
  CatLegend = "cat_legend",
  /** column name */
  CatLegendRi = "cat_legend_ri",
  /** column name */
  File = "file",
  /** column name */
  FileX = "file_x",
  /** column name */
  FileY = "file_y",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LastUpdatedX = "last_updated_x",
  /** column name */
  LastUpdatedY = "last_updated_y",
  /** column name */
  Legend = "legend",
  /** column name */
  LegendRi = "legend_ri",
  /** column name */
  Line = "line",
  /** column name */
  LineX = "line_x",
  /** column name */
  LineY = "line_y",
  /** column name */
  WhoUpdated = "who_updated",
  /** column name */
  WhoUpdatedX = "who_updated_x",
  /** column name */
  WhoUpdatedY = "who_updated_y",
}

/** input type for updating data in table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Set_Input = {
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Stddev_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_stddev_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Stddev_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Stddev_Pop_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_stddev_pop_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Stddev_Pop_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Stddev_Samp_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_stddev_samp_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Stddev_Samp_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Sum_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_sum_fields";
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["float8"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["float8"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_INCOME?: Maybe<Scalars["float8"]>;
  PROPINC_RATE?: Maybe<Scalars["float8"]>;
  PROPINC_TAX?: Maybe<Scalars["float8"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Sum_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Var_Pop_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_var_pop_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Var_Pop_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Var_Samp_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_var_samp_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Var_Samp_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_Rpt_IndividualTaxSummaryData_Variance_Fields = {
  __typename?: "ura_RPT_IndividualTaxSummaryData_variance_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  EMPLYMNT_INCOME?: Maybe<Scalars["Float"]>;
  EMPLYMNT_RATE?: Maybe<Scalars["Float"]>;
  EMPLYMNT_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  NON_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_INCOME?: Maybe<Scalars["Float"]>;
  PROPINC_RATE?: Maybe<Scalars["Float"]>;
  PROPINC_TAX?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RPT_IndividualTaxSummaryData" */
export type Ura_Rpt_IndividualTaxSummaryData_Variance_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  EMPLYMNT_INCOME?: Maybe<Order_By>;
  EMPLYMNT_RATE?: Maybe<Order_By>;
  EMPLYMNT_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  NON_RENTAL_INCOME?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  PROPINC_INCOME?: Maybe<Order_By>;
  PROPINC_RATE?: Maybe<Order_By>;
  PROPINC_TAX?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData = {
  __typename?: "ura_RPT_KccaCustomerRegistrationData";
  Visible_Level?: Maybe<Scalars["String"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["String"]>;
  birthdate?: Maybe<Scalars["String"]>;
  box_district_id?: Maybe<Scalars["String"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["String"]>;
  customer_business_type?: Maybe<Scalars["String"]>;
  customer_business_type_id?: Maybe<Scalars["String"]>;
  customer_type?: Maybe<Scalars["String"]>;
  customer_type_id?: Maybe<Scalars["String"]>;
  date_of_incorporation?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["String"]>;
  have_alternate_primary_contact?: Maybe<Scalars["String"]>;
  have_reference?: Maybe<Scalars["String"]>;
  have_revenue_agent?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["String"]>;
  is_minor?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_district_id?: Maybe<Scalars["String"]>;
  previous_business_address_parish_id?: Maybe<Scalars["String"]>;
  previous_business_address_plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["String"]>;
  previous_business_name?: Maybe<Scalars["String"]>;
  residential_address_county_id?: Maybe<Scalars["String"]>;
  residential_address_district_id?: Maybe<Scalars["String"]>;
  residential_address_parish_id?: Maybe<Scalars["String"]>;
  residential_address_sub_county_id?: Maybe<Scalars["String"]>;
  residential_address_village_id?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["String"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Aggregate = {
  __typename?: "ura_RPT_KccaCustomerRegistrationData_aggregate";
  aggregate?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Aggregate_Fields>;
  nodes: Array<Ura_Rpt_KccaCustomerRegistrationData>;
};

/** aggregate fields of "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Aggregate_Fields = {
  __typename?: "ura_RPT_KccaCustomerRegistrationData_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Max_Fields>;
  min?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Min_Fields>;
};

/** aggregate fields of "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_Rpt_KccaCustomerRegistrationData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Max_Order_By>;
  min?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Arr_Rel_Insert_Input = {
  data: Array<Ura_Rpt_KccaCustomerRegistrationData_Insert_Input>;
};

/** Boolean expression to filter rows from the table "ura.RPT_KccaCustomerRegistrationData". All fields are combined with a logical 'AND'. */
export type Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp = {
  Visible_Level?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp>>>;
  _not?: Maybe<Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_Rpt_KccaCustomerRegistrationData_Bool_Exp>>>;
  acquired_name?: Maybe<String_Comparison_Exp>;
  balance?: Maybe<String_Comparison_Exp>;
  birthdate?: Maybe<String_Comparison_Exp>;
  box_district_id?: Maybe<String_Comparison_Exp>;
  box_number?: Maybe<String_Comparison_Exp>;
  building_name?: Maybe<String_Comparison_Exp>;
  business_name?: Maybe<String_Comparison_Exp>;
  citizenship_country_id?: Maybe<String_Comparison_Exp>;
  coin?: Maybe<String_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  created_date?: Maybe<String_Comparison_Exp>;
  customer_applicant_type?: Maybe<String_Comparison_Exp>;
  customer_applicant_type_id?: Maybe<String_Comparison_Exp>;
  customer_business_type?: Maybe<String_Comparison_Exp>;
  customer_business_type_id?: Maybe<String_Comparison_Exp>;
  customer_type?: Maybe<String_Comparison_Exp>;
  customer_type_id?: Maybe<String_Comparison_Exp>;
  date_of_incorporation?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  entity_legal_name?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  former_business_name?: Maybe<String_Comparison_Exp>;
  gender_id?: Maybe<String_Comparison_Exp>;
  have_alternate_primary_contact?: Maybe<String_Comparison_Exp>;
  have_reference?: Maybe<String_Comparison_Exp>;
  have_revenue_agent?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  identification_diplomatic_id?: Maybe<String_Comparison_Exp>;
  identification_driving_permit?: Maybe<String_Comparison_Exp>;
  identification_employee_id?: Maybe<String_Comparison_Exp>;
  identification_financial_card?: Maybe<String_Comparison_Exp>;
  identification_national_id?: Maybe<String_Comparison_Exp>;
  identification_nssf_number?: Maybe<String_Comparison_Exp>;
  identification_passport_no?: Maybe<String_Comparison_Exp>;
  identification_refugee_id?: Maybe<String_Comparison_Exp>;
  identification_village_id?: Maybe<String_Comparison_Exp>;
  identification_voter_id?: Maybe<String_Comparison_Exp>;
  identification_work_id?: Maybe<String_Comparison_Exp>;
  identification_work_permit?: Maybe<String_Comparison_Exp>;
  is_forced_action?: Maybe<String_Comparison_Exp>;
  is_minor?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<String_Comparison_Exp>;
  line?: Maybe<String_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<String_Comparison_Exp>;
  modified_date?: Maybe<String_Comparison_Exp>;
  mother_maiden_name?: Maybe<String_Comparison_Exp>;
  plot_number?: Maybe<String_Comparison_Exp>;
  previous_business_address_building_name?: Maybe<String_Comparison_Exp>;
  previous_business_address_county_id?: Maybe<String_Comparison_Exp>;
  previous_business_address_district_id?: Maybe<String_Comparison_Exp>;
  previous_business_address_parish_id?: Maybe<String_Comparison_Exp>;
  previous_business_address_plot_number?: Maybe<String_Comparison_Exp>;
  previous_business_address_street_name?: Maybe<String_Comparison_Exp>;
  previous_business_address_sub_county_id?: Maybe<String_Comparison_Exp>;
  previous_business_address_trading_center?: Maybe<String_Comparison_Exp>;
  previous_business_address_village_id?: Maybe<String_Comparison_Exp>;
  previous_business_name?: Maybe<String_Comparison_Exp>;
  residential_address_county_id?: Maybe<String_Comparison_Exp>;
  residential_address_district_id?: Maybe<String_Comparison_Exp>;
  residential_address_parish_id?: Maybe<String_Comparison_Exp>;
  residential_address_sub_county_id?: Maybe<String_Comparison_Exp>;
  residential_address_village_id?: Maybe<String_Comparison_Exp>;
  second_mobile?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<String_Comparison_Exp>;
  street_name?: Maybe<String_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  title_id?: Maybe<String_Comparison_Exp>;
  trading_center?: Maybe<String_Comparison_Exp>;
  transactional_status?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Insert_Input = {
  Visible_Level?: Maybe<Scalars["String"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["String"]>;
  birthdate?: Maybe<Scalars["String"]>;
  box_district_id?: Maybe<Scalars["String"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["String"]>;
  customer_business_type?: Maybe<Scalars["String"]>;
  customer_business_type_id?: Maybe<Scalars["String"]>;
  customer_type?: Maybe<Scalars["String"]>;
  customer_type_id?: Maybe<Scalars["String"]>;
  date_of_incorporation?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["String"]>;
  have_alternate_primary_contact?: Maybe<Scalars["String"]>;
  have_reference?: Maybe<Scalars["String"]>;
  have_revenue_agent?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["String"]>;
  is_minor?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_district_id?: Maybe<Scalars["String"]>;
  previous_business_address_parish_id?: Maybe<Scalars["String"]>;
  previous_business_address_plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["String"]>;
  previous_business_name?: Maybe<Scalars["String"]>;
  residential_address_county_id?: Maybe<Scalars["String"]>;
  residential_address_district_id?: Maybe<Scalars["String"]>;
  residential_address_parish_id?: Maybe<Scalars["String"]>;
  residential_address_sub_county_id?: Maybe<Scalars["String"]>;
  residential_address_village_id?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["String"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_Rpt_KccaCustomerRegistrationData_Max_Fields = {
  __typename?: "ura_RPT_KccaCustomerRegistrationData_max_fields";
  Visible_Level?: Maybe<Scalars["String"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["String"]>;
  birthdate?: Maybe<Scalars["String"]>;
  box_district_id?: Maybe<Scalars["String"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["String"]>;
  customer_business_type?: Maybe<Scalars["String"]>;
  customer_business_type_id?: Maybe<Scalars["String"]>;
  customer_type?: Maybe<Scalars["String"]>;
  customer_type_id?: Maybe<Scalars["String"]>;
  date_of_incorporation?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["String"]>;
  have_alternate_primary_contact?: Maybe<Scalars["String"]>;
  have_reference?: Maybe<Scalars["String"]>;
  have_revenue_agent?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["String"]>;
  is_minor?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_district_id?: Maybe<Scalars["String"]>;
  previous_business_address_parish_id?: Maybe<Scalars["String"]>;
  previous_business_address_plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["String"]>;
  previous_business_name?: Maybe<Scalars["String"]>;
  residential_address_county_id?: Maybe<Scalars["String"]>;
  residential_address_district_id?: Maybe<Scalars["String"]>;
  residential_address_parish_id?: Maybe<Scalars["String"]>;
  residential_address_sub_county_id?: Maybe<Scalars["String"]>;
  residential_address_village_id?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["String"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Max_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  acquired_name?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  box_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_applicant_type?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  date_of_incorporation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  former_business_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_diplomatic_id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_employee_id?: Maybe<Order_By>;
  identification_financial_card?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_number?: Maybe<Order_By>;
  identification_passport_no?: Maybe<Order_By>;
  identification_refugee_id?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  identification_voter_id?: Maybe<Order_By>;
  identification_work_id?: Maybe<Order_By>;
  identification_work_permit?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  plot_number?: Maybe<Order_By>;
  previous_business_address_building_name?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_street_name?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_trading_center?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_name?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  trading_center?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_Rpt_KccaCustomerRegistrationData_Min_Fields = {
  __typename?: "ura_RPT_KccaCustomerRegistrationData_min_fields";
  Visible_Level?: Maybe<Scalars["String"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["String"]>;
  birthdate?: Maybe<Scalars["String"]>;
  box_district_id?: Maybe<Scalars["String"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["String"]>;
  customer_business_type?: Maybe<Scalars["String"]>;
  customer_business_type_id?: Maybe<Scalars["String"]>;
  customer_type?: Maybe<Scalars["String"]>;
  customer_type_id?: Maybe<Scalars["String"]>;
  date_of_incorporation?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["String"]>;
  have_alternate_primary_contact?: Maybe<Scalars["String"]>;
  have_reference?: Maybe<Scalars["String"]>;
  have_revenue_agent?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["String"]>;
  is_minor?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_district_id?: Maybe<Scalars["String"]>;
  previous_business_address_parish_id?: Maybe<Scalars["String"]>;
  previous_business_address_plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["String"]>;
  previous_business_name?: Maybe<Scalars["String"]>;
  residential_address_county_id?: Maybe<Scalars["String"]>;
  residential_address_district_id?: Maybe<Scalars["String"]>;
  residential_address_parish_id?: Maybe<Scalars["String"]>;
  residential_address_sub_county_id?: Maybe<Scalars["String"]>;
  residential_address_village_id?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["String"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Min_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  acquired_name?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  box_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_applicant_type?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  date_of_incorporation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  former_business_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_diplomatic_id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_employee_id?: Maybe<Order_By>;
  identification_financial_card?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_number?: Maybe<Order_By>;
  identification_passport_no?: Maybe<Order_By>;
  identification_refugee_id?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  identification_voter_id?: Maybe<Order_By>;
  identification_work_id?: Maybe<Order_By>;
  identification_work_permit?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  plot_number?: Maybe<Order_By>;
  previous_business_address_building_name?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_street_name?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_trading_center?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_name?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  trading_center?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Mutation_Response = {
  __typename?: "ura_RPT_KccaCustomerRegistrationData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_Rpt_KccaCustomerRegistrationData>;
};

/** input type for inserting object relation for remote table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Obj_Rel_Insert_Input = {
  data: Ura_Rpt_KccaCustomerRegistrationData_Insert_Input;
};

/** ordering options when selecting data from "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Order_By = {
  Visible_Level?: Maybe<Order_By>;
  acquired_name?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  birthdate?: Maybe<Order_By>;
  box_district_id?: Maybe<Order_By>;
  box_number?: Maybe<Order_By>;
  building_name?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  citizenship_country_id?: Maybe<Order_By>;
  coin?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  customer_applicant_type?: Maybe<Order_By>;
  customer_applicant_type_id?: Maybe<Order_By>;
  customer_business_type?: Maybe<Order_By>;
  customer_business_type_id?: Maybe<Order_By>;
  customer_type?: Maybe<Order_By>;
  customer_type_id?: Maybe<Order_By>;
  date_of_incorporation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  former_business_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  have_alternate_primary_contact?: Maybe<Order_By>;
  have_reference?: Maybe<Order_By>;
  have_revenue_agent?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_diplomatic_id?: Maybe<Order_By>;
  identification_driving_permit?: Maybe<Order_By>;
  identification_employee_id?: Maybe<Order_By>;
  identification_financial_card?: Maybe<Order_By>;
  identification_national_id?: Maybe<Order_By>;
  identification_nssf_number?: Maybe<Order_By>;
  identification_passport_no?: Maybe<Order_By>;
  identification_refugee_id?: Maybe<Order_By>;
  identification_village_id?: Maybe<Order_By>;
  identification_voter_id?: Maybe<Order_By>;
  identification_work_id?: Maybe<Order_By>;
  identification_work_permit?: Maybe<Order_By>;
  is_forced_action?: Maybe<Order_By>;
  is_minor?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  mother_maiden_name?: Maybe<Order_By>;
  plot_number?: Maybe<Order_By>;
  previous_business_address_building_name?: Maybe<Order_By>;
  previous_business_address_county_id?: Maybe<Order_By>;
  previous_business_address_district_id?: Maybe<Order_By>;
  previous_business_address_parish_id?: Maybe<Order_By>;
  previous_business_address_plot_number?: Maybe<Order_By>;
  previous_business_address_street_name?: Maybe<Order_By>;
  previous_business_address_sub_county_id?: Maybe<Order_By>;
  previous_business_address_trading_center?: Maybe<Order_By>;
  previous_business_address_village_id?: Maybe<Order_By>;
  previous_business_name?: Maybe<Order_By>;
  residential_address_county_id?: Maybe<Order_By>;
  residential_address_district_id?: Maybe<Order_By>;
  residential_address_parish_id?: Maybe<Order_By>;
  residential_address_sub_county_id?: Maybe<Order_By>;
  residential_address_village_id?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_name?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  trading_center?: Maybe<Order_By>;
  transactional_status?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RPT_KccaCustomerRegistrationData" */
export enum Ura_Rpt_KccaCustomerRegistrationData_Select_Column {
  /** column name */
  VisibleLevel = "Visible_Level",
  /** column name */
  AcquiredName = "acquired_name",
  /** column name */
  Balance = "balance",
  /** column name */
  Birthdate = "birthdate",
  /** column name */
  BoxDistrictId = "box_district_id",
  /** column name */
  BoxNumber = "box_number",
  /** column name */
  BuildingName = "building_name",
  /** column name */
  BusinessName = "business_name",
  /** column name */
  CitizenshipCountryId = "citizenship_country_id",
  /** column name */
  Coin = "coin",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CustomerApplicantType = "customer_applicant_type",
  /** column name */
  CustomerApplicantTypeId = "customer_applicant_type_id",
  /** column name */
  CustomerBusinessType = "customer_business_type",
  /** column name */
  CustomerBusinessTypeId = "customer_business_type_id",
  /** column name */
  CustomerType = "customer_type",
  /** column name */
  CustomerTypeId = "customer_type_id",
  /** column name */
  DateOfIncorporation = "date_of_incorporation",
  /** column name */
  Email = "email",
  /** column name */
  EntityLegalName = "entity_legal_name",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  FormerBusinessName = "former_business_name",
  /** column name */
  GenderId = "gender_id",
  /** column name */
  HaveAlternatePrimaryContact = "have_alternate_primary_contact",
  /** column name */
  HaveReference = "have_reference",
  /** column name */
  HaveRevenueAgent = "have_revenue_agent",
  /** column name */
  Id = "id",
  /** column name */
  IdentificationDiplomaticId = "identification_diplomatic_id",
  /** column name */
  IdentificationDrivingPermit = "identification_driving_permit",
  /** column name */
  IdentificationEmployeeId = "identification_employee_id",
  /** column name */
  IdentificationFinancialCard = "identification_financial_card",
  /** column name */
  IdentificationNationalId = "identification_national_id",
  /** column name */
  IdentificationNssfNumber = "identification_nssf_number",
  /** column name */
  IdentificationPassportNo = "identification_passport_no",
  /** column name */
  IdentificationRefugeeId = "identification_refugee_id",
  /** column name */
  IdentificationVillageId = "identification_village_id",
  /** column name */
  IdentificationVoterId = "identification_voter_id",
  /** column name */
  IdentificationWorkId = "identification_work_id",
  /** column name */
  IdentificationWorkPermit = "identification_work_permit",
  /** column name */
  IsForcedAction = "is_forced_action",
  /** column name */
  IsMinor = "is_minor",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  Mobile = "mobile",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  MotherMaidenName = "mother_maiden_name",
  /** column name */
  PlotNumber = "plot_number",
  /** column name */
  PreviousBusinessAddressBuildingName = "previous_business_address_building_name",
  /** column name */
  PreviousBusinessAddressCountyId = "previous_business_address_county_id",
  /** column name */
  PreviousBusinessAddressDistrictId = "previous_business_address_district_id",
  /** column name */
  PreviousBusinessAddressParishId = "previous_business_address_parish_id",
  /** column name */
  PreviousBusinessAddressPlotNumber = "previous_business_address_plot_number",
  /** column name */
  PreviousBusinessAddressStreetName = "previous_business_address_street_name",
  /** column name */
  PreviousBusinessAddressSubCountyId = "previous_business_address_sub_county_id",
  /** column name */
  PreviousBusinessAddressTradingCenter = "previous_business_address_trading_center",
  /** column name */
  PreviousBusinessAddressVillageId = "previous_business_address_village_id",
  /** column name */
  PreviousBusinessName = "previous_business_name",
  /** column name */
  ResidentialAddressCountyId = "residential_address_county_id",
  /** column name */
  ResidentialAddressDistrictId = "residential_address_district_id",
  /** column name */
  ResidentialAddressParishId = "residential_address_parish_id",
  /** column name */
  ResidentialAddressSubCountyId = "residential_address_sub_county_id",
  /** column name */
  ResidentialAddressVillageId = "residential_address_village_id",
  /** column name */
  SecondMobile = "second_mobile",
  /** column name */
  StatusId = "status_id",
  /** column name */
  StreetName = "street_name",
  /** column name */
  Surname = "surname",
  /** column name */
  Telephone = "telephone",
  /** column name */
  Tin = "tin",
  /** column name */
  TitleId = "title_id",
  /** column name */
  TradingCenter = "trading_center",
  /** column name */
  TransactionalStatus = "transactional_status",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RPT_KccaCustomerRegistrationData" */
export type Ura_Rpt_KccaCustomerRegistrationData_Set_Input = {
  Visible_Level?: Maybe<Scalars["String"]>;
  acquired_name?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["String"]>;
  birthdate?: Maybe<Scalars["String"]>;
  box_district_id?: Maybe<Scalars["String"]>;
  box_number?: Maybe<Scalars["String"]>;
  building_name?: Maybe<Scalars["String"]>;
  business_name?: Maybe<Scalars["String"]>;
  citizenship_country_id?: Maybe<Scalars["String"]>;
  coin?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  customer_applicant_type?: Maybe<Scalars["String"]>;
  customer_applicant_type_id?: Maybe<Scalars["String"]>;
  customer_business_type?: Maybe<Scalars["String"]>;
  customer_business_type_id?: Maybe<Scalars["String"]>;
  customer_type?: Maybe<Scalars["String"]>;
  customer_type_id?: Maybe<Scalars["String"]>;
  date_of_incorporation?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  former_business_name?: Maybe<Scalars["String"]>;
  gender_id?: Maybe<Scalars["String"]>;
  have_alternate_primary_contact?: Maybe<Scalars["String"]>;
  have_reference?: Maybe<Scalars["String"]>;
  have_revenue_agent?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  identification_diplomatic_id?: Maybe<Scalars["String"]>;
  identification_driving_permit?: Maybe<Scalars["String"]>;
  identification_employee_id?: Maybe<Scalars["String"]>;
  identification_financial_card?: Maybe<Scalars["String"]>;
  identification_national_id?: Maybe<Scalars["String"]>;
  identification_nssf_number?: Maybe<Scalars["String"]>;
  identification_passport_no?: Maybe<Scalars["String"]>;
  identification_refugee_id?: Maybe<Scalars["String"]>;
  identification_village_id?: Maybe<Scalars["String"]>;
  identification_voter_id?: Maybe<Scalars["String"]>;
  identification_work_id?: Maybe<Scalars["String"]>;
  identification_work_permit?: Maybe<Scalars["String"]>;
  is_forced_action?: Maybe<Scalars["String"]>;
  is_minor?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  mother_maiden_name?: Maybe<Scalars["String"]>;
  plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_building_name?: Maybe<Scalars["String"]>;
  previous_business_address_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_district_id?: Maybe<Scalars["String"]>;
  previous_business_address_parish_id?: Maybe<Scalars["String"]>;
  previous_business_address_plot_number?: Maybe<Scalars["String"]>;
  previous_business_address_street_name?: Maybe<Scalars["String"]>;
  previous_business_address_sub_county_id?: Maybe<Scalars["String"]>;
  previous_business_address_trading_center?: Maybe<Scalars["String"]>;
  previous_business_address_village_id?: Maybe<Scalars["String"]>;
  previous_business_name?: Maybe<Scalars["String"]>;
  residential_address_county_id?: Maybe<Scalars["String"]>;
  residential_address_district_id?: Maybe<Scalars["String"]>;
  residential_address_parish_id?: Maybe<Scalars["String"]>;
  residential_address_sub_county_id?: Maybe<Scalars["String"]>;
  residential_address_village_id?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_name?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  title_id?: Maybe<Scalars["String"]>;
  trading_center?: Maybe<Scalars["String"]>;
  transactional_status?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData = {
  __typename?: "ura_RPT_KccaPropertyRegistrationData";
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  current_rateable_value?: Maybe<Scalars["String"]>;
  customer_id?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  division_id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parish?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  property_type_id?: Maybe<Scalars["String"]>;
  rateable_value?: Maybe<Scalars["String"]>;
  rating_zone_id?: Maybe<Scalars["String"]>;
  remisson?: Maybe<Scalars["String"]>;
  road_others?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["String"]>;
  supplementary?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  village?: Maybe<Scalars["String"]>;
  village_id?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Aggregate = {
  __typename?: "ura_RPT_KccaPropertyRegistrationData_aggregate";
  aggregate?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Aggregate_Fields>;
  nodes: Array<Ura_Rpt_KccaPropertyRegistrationData>;
};

/** aggregate fields of "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Aggregate_Fields = {
  __typename?: "ura_RPT_KccaPropertyRegistrationData_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Max_Fields>;
  min?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Min_Fields>;
};

/** aggregate fields of "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_Rpt_KccaPropertyRegistrationData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Max_Order_By>;
  min?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Arr_Rel_Insert_Input = {
  data: Array<Ura_Rpt_KccaPropertyRegistrationData_Insert_Input>;
};

/** Boolean expression to filter rows from the table "ura.RPT_KccaPropertyRegistrationData". All fields are combined with a logical 'AND'. */
export type Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp>>>;
  _not?: Maybe<Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_Rpt_KccaPropertyRegistrationData_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  block_no?: Maybe<String_Comparison_Exp>;
  camv_id?: Maybe<String_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  created_date?: Maybe<String_Comparison_Exp>;
  current_rateable_value?: Maybe<String_Comparison_Exp>;
  customer_id?: Maybe<String_Comparison_Exp>;
  division?: Maybe<String_Comparison_Exp>;
  division_id?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  entity_legal_name?: Maybe<String_Comparison_Exp>;
  file?: Maybe<String_Comparison_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  flat_no?: Maybe<String_Comparison_Exp>;
  gprs_cordinate_x?: Maybe<String_Comparison_Exp>;
  gprs_cordinate_y?: Maybe<String_Comparison_Exp>;
  gross_value?: Maybe<String_Comparison_Exp>;
  house_no?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  img_path?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<String_Comparison_Exp>;
  latitude?: Maybe<String_Comparison_Exp>;
  line?: Maybe<String_Comparison_Exp>;
  location_address?: Maybe<String_Comparison_Exp>;
  longitude?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<String_Comparison_Exp>;
  modified_date?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parish?: Maybe<String_Comparison_Exp>;
  parish_id?: Maybe<String_Comparison_Exp>;
  particulars_frontages?: Maybe<String_Comparison_Exp>;
  plot_no?: Maybe<String_Comparison_Exp>;
  property_no?: Maybe<String_Comparison_Exp>;
  property_rented_status?: Maybe<String_Comparison_Exp>;
  property_rented_status_id?: Maybe<String_Comparison_Exp>;
  property_sub_type?: Maybe<String_Comparison_Exp>;
  property_type?: Maybe<String_Comparison_Exp>;
  property_type_id?: Maybe<String_Comparison_Exp>;
  rateable_value?: Maybe<String_Comparison_Exp>;
  rating_zone_id?: Maybe<String_Comparison_Exp>;
  remisson?: Maybe<String_Comparison_Exp>;
  road_others?: Maybe<String_Comparison_Exp>;
  second_mobile?: Maybe<String_Comparison_Exp>;
  serial_no?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<String_Comparison_Exp>;
  street_id?: Maybe<String_Comparison_Exp>;
  sub_property_type_id?: Maybe<String_Comparison_Exp>;
  supplementary?: Maybe<String_Comparison_Exp>;
  surname?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  village?: Maybe<String_Comparison_Exp>;
  village_id?: Maybe<String_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Insert_Input = {
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  current_rateable_value?: Maybe<Scalars["String"]>;
  customer_id?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  division_id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parish?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  property_type_id?: Maybe<Scalars["String"]>;
  rateable_value?: Maybe<Scalars["String"]>;
  rating_zone_id?: Maybe<Scalars["String"]>;
  remisson?: Maybe<Scalars["String"]>;
  road_others?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["String"]>;
  supplementary?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  village?: Maybe<Scalars["String"]>;
  village_id?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_Rpt_KccaPropertyRegistrationData_Max_Fields = {
  __typename?: "ura_RPT_KccaPropertyRegistrationData_max_fields";
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  current_rateable_value?: Maybe<Scalars["String"]>;
  customer_id?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  division_id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parish?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  property_type_id?: Maybe<Scalars["String"]>;
  rateable_value?: Maybe<Scalars["String"]>;
  rating_zone_id?: Maybe<Scalars["String"]>;
  remisson?: Maybe<Scalars["String"]>;
  road_others?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["String"]>;
  supplementary?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  village?: Maybe<Scalars["String"]>;
  village_id?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Max_Order_By = {
  address?: Maybe<Order_By>;
  block_no?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  flat_no?: Maybe<Order_By>;
  gprs_cordinate_x?: Maybe<Order_By>;
  gprs_cordinate_y?: Maybe<Order_By>;
  gross_value?: Maybe<Order_By>;
  house_no?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img_path?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parish?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  plot_no?: Maybe<Order_By>;
  property_no?: Maybe<Order_By>;
  property_rented_status?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_sub_type?: Maybe<Order_By>;
  property_type?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  road_others?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  serial_no?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  village?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_Rpt_KccaPropertyRegistrationData_Min_Fields = {
  __typename?: "ura_RPT_KccaPropertyRegistrationData_min_fields";
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  current_rateable_value?: Maybe<Scalars["String"]>;
  customer_id?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  division_id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parish?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  property_type_id?: Maybe<Scalars["String"]>;
  rateable_value?: Maybe<Scalars["String"]>;
  rating_zone_id?: Maybe<Scalars["String"]>;
  remisson?: Maybe<Scalars["String"]>;
  road_others?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["String"]>;
  supplementary?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  village?: Maybe<Scalars["String"]>;
  village_id?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Min_Order_By = {
  address?: Maybe<Order_By>;
  block_no?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  flat_no?: Maybe<Order_By>;
  gprs_cordinate_x?: Maybe<Order_By>;
  gprs_cordinate_y?: Maybe<Order_By>;
  gross_value?: Maybe<Order_By>;
  house_no?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img_path?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parish?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  plot_no?: Maybe<Order_By>;
  property_no?: Maybe<Order_By>;
  property_rented_status?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_sub_type?: Maybe<Order_By>;
  property_type?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  road_others?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  serial_no?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  village?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Mutation_Response = {
  __typename?: "ura_RPT_KccaPropertyRegistrationData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_Rpt_KccaPropertyRegistrationData>;
};

/** input type for inserting object relation for remote table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Obj_Rel_Insert_Input = {
  data: Ura_Rpt_KccaPropertyRegistrationData_Insert_Input;
};

/** ordering options when selecting data from "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Order_By = {
  address?: Maybe<Order_By>;
  block_no?: Maybe<Order_By>;
  camv_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  current_rateable_value?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  division?: Maybe<Order_By>;
  division_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_legal_name?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  flat_no?: Maybe<Order_By>;
  gprs_cordinate_x?: Maybe<Order_By>;
  gprs_cordinate_y?: Maybe<Order_By>;
  gross_value?: Maybe<Order_By>;
  house_no?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img_path?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parish?: Maybe<Order_By>;
  parish_id?: Maybe<Order_By>;
  particulars_frontages?: Maybe<Order_By>;
  plot_no?: Maybe<Order_By>;
  property_no?: Maybe<Order_By>;
  property_rented_status?: Maybe<Order_By>;
  property_rented_status_id?: Maybe<Order_By>;
  property_sub_type?: Maybe<Order_By>;
  property_type?: Maybe<Order_By>;
  property_type_id?: Maybe<Order_By>;
  rateable_value?: Maybe<Order_By>;
  rating_zone_id?: Maybe<Order_By>;
  remisson?: Maybe<Order_By>;
  road_others?: Maybe<Order_By>;
  second_mobile?: Maybe<Order_By>;
  serial_no?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  street_id?: Maybe<Order_By>;
  sub_property_type_id?: Maybe<Order_By>;
  supplementary?: Maybe<Order_By>;
  surname?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  village?: Maybe<Order_By>;
  village_id?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RPT_KccaPropertyRegistrationData" */
export enum Ura_Rpt_KccaPropertyRegistrationData_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  BlockNo = "block_no",
  /** column name */
  CamvId = "camv_id",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  CurrentRateableValue = "current_rateable_value",
  /** column name */
  CustomerId = "customer_id",
  /** column name */
  Division = "division",
  /** column name */
  DivisionId = "division_id",
  /** column name */
  Email = "email",
  /** column name */
  EntityLegalName = "entity_legal_name",
  /** column name */
  File = "file",
  /** column name */
  Firstname = "firstname",
  /** column name */
  FlatNo = "flat_no",
  /** column name */
  GprsCordinateX = "gprs_cordinate_x",
  /** column name */
  GprsCordinateY = "gprs_cordinate_y",
  /** column name */
  GrossValue = "gross_value",
  /** column name */
  HouseNo = "house_no",
  /** column name */
  Id = "id",
  /** column name */
  ImgPath = "img_path",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Latitude = "latitude",
  /** column name */
  Line = "line",
  /** column name */
  LocationAddress = "location_address",
  /** column name */
  Longitude = "longitude",
  /** column name */
  Mobile = "mobile",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  ModifiedDate = "modified_date",
  /** column name */
  Name = "name",
  /** column name */
  Parish = "parish",
  /** column name */
  ParishId = "parish_id",
  /** column name */
  ParticularsFrontages = "particulars_frontages",
  /** column name */
  PlotNo = "plot_no",
  /** column name */
  PropertyNo = "property_no",
  /** column name */
  PropertyRentedStatus = "property_rented_status",
  /** column name */
  PropertyRentedStatusId = "property_rented_status_id",
  /** column name */
  PropertySubType = "property_sub_type",
  /** column name */
  PropertyType = "property_type",
  /** column name */
  PropertyTypeId = "property_type_id",
  /** column name */
  RateableValue = "rateable_value",
  /** column name */
  RatingZoneId = "rating_zone_id",
  /** column name */
  Remisson = "remisson",
  /** column name */
  RoadOthers = "road_others",
  /** column name */
  SecondMobile = "second_mobile",
  /** column name */
  SerialNo = "serial_no",
  /** column name */
  StatusId = "status_id",
  /** column name */
  StreetId = "street_id",
  /** column name */
  SubPropertyTypeId = "sub_property_type_id",
  /** column name */
  Supplementary = "supplementary",
  /** column name */
  Surname = "surname",
  /** column name */
  Tin = "tin",
  /** column name */
  Village = "village",
  /** column name */
  VillageId = "village_id",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RPT_KccaPropertyRegistrationData" */
export type Ura_Rpt_KccaPropertyRegistrationData_Set_Input = {
  address?: Maybe<Scalars["String"]>;
  block_no?: Maybe<Scalars["String"]>;
  camv_id?: Maybe<Scalars["String"]>;
  created_by?: Maybe<Scalars["String"]>;
  created_date?: Maybe<Scalars["String"]>;
  current_rateable_value?: Maybe<Scalars["String"]>;
  customer_id?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  division_id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  entity_legal_name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  flat_no?: Maybe<Scalars["String"]>;
  gprs_cordinate_x?: Maybe<Scalars["String"]>;
  gprs_cordinate_y?: Maybe<Scalars["String"]>;
  gross_value?: Maybe<Scalars["String"]>;
  house_no?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  img_path?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  modified_by?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parish?: Maybe<Scalars["String"]>;
  parish_id?: Maybe<Scalars["String"]>;
  particulars_frontages?: Maybe<Scalars["String"]>;
  plot_no?: Maybe<Scalars["String"]>;
  property_no?: Maybe<Scalars["String"]>;
  property_rented_status?: Maybe<Scalars["String"]>;
  property_rented_status_id?: Maybe<Scalars["String"]>;
  property_sub_type?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  property_type_id?: Maybe<Scalars["String"]>;
  rateable_value?: Maybe<Scalars["String"]>;
  rating_zone_id?: Maybe<Scalars["String"]>;
  remisson?: Maybe<Scalars["String"]>;
  road_others?: Maybe<Scalars["String"]>;
  second_mobile?: Maybe<Scalars["String"]>;
  serial_no?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["String"]>;
  street_id?: Maybe<Scalars["String"]>;
  sub_property_type_id?: Maybe<Scalars["String"]>;
  supplementary?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  tin?: Maybe<Scalars["String"]>;
  village?: Maybe<Scalars["String"]>;
  village_id?: Maybe<Scalars["String"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData";
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["String"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["float8"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Aggregate = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_aggregate";
  aggregate?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Aggregate_Fields>;
  nodes: Array<Ura_Rpt_NonIndividualTaxSummaryData>;
};

/** aggregate fields of "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Aggregate_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_aggregate_fields";
  avg?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Max_Fields>;
  min?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Min_Fields>;
  stddev?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Sum_Fields>;
  var_pop?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Var_Samp_Fields>;
  variance?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Variance_Fields>;
};

/** aggregate fields of "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_Rpt_NonIndividualTaxSummaryData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Aggregate_Order_By = {
  avg?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Max_Order_By>;
  min?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Min_Order_By>;
  stddev?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Sum_Order_By>;
  var_pop?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Var_Samp_Order_By>;
  variance?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Arr_Rel_Insert_Input = {
  data: Array<Ura_Rpt_NonIndividualTaxSummaryData_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Avg_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_avg_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Avg_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RPT_NonIndividualTaxSummaryData". All fields are combined with a logical 'AND'. */
export type Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp = {
  ACTVTY_DESC?: Maybe<String_Comparison_Exp>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Float8_Comparison_Exp>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Float8_Comparison_Exp>;
  ADMINISTRATING_EXP?: Maybe<Float8_Comparison_Exp>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Float8_Comparison_Exp>;
  APPLIED_FOR_TIN?: Maybe<String_Comparison_Exp>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Float8_Comparison_Exp>;
  ASSESSED_RENTL_LOSS?: Maybe<Float8_Comparison_Exp>;
  AUTHCTCD_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_SURNAME?: Maybe<String_Comparison_Exp>;
  BAD_DEBT_WRITE_OFF?: Maybe<Float8_Comparison_Exp>;
  BALANCE_CARRY_FORWARD?: Maybe<String_Comparison_Exp>;
  BALANCE_PREV_PERIOD?: Maybe<String_Comparison_Exp>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Float8_Comparison_Exp>;
  BSNSADDR_BUILDNAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_DISTRICT_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<String_Comparison_Exp>;
  BSNSADDR_PARISH_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_PLOT_NO?: Maybe<String_Comparison_Exp>;
  BSNSADDR_STREET_ADDR?: Maybe<String_Comparison_Exp>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_TOWN_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_TRADE_CENTRE?: Maybe<String_Comparison_Exp>;
  BSNSADDR_VILLAGE_NAME?: Maybe<String_Comparison_Exp>;
  BSNS_CERT_NUMBER?: Maybe<String_Comparison_Exp>;
  BSNS_NAME?: Maybe<String_Comparison_Exp>;
  BUSINESS_START_DT?: Maybe<String_Comparison_Exp>;
  Business_INCOME?: Maybe<Float8_Comparison_Exp>;
  Business_RATE?: Maybe<Float8_Comparison_Exp>;
  Business_TAX?: Maybe<Float8_Comparison_Exp>;
  COST_OF_SALES?: Maybe<Float8_Comparison_Exp>;
  DEBENTURE?: Maybe<Float8_Comparison_Exp>;
  DEFER_TAX_LIABILITY?: Maybe<Float8_Comparison_Exp>;
  DEPRECIATION?: Maybe<Float8_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  EQUITY_SHARE?: Maybe<Float8_Comparison_Exp>;
  EXIST_TIN_NO?: Maybe<String_Comparison_Exp>;
  FACTORY_RENT_RATES?: Maybe<Float8_Comparison_Exp>;
  FINANCE_EXP?: Maybe<Float8_Comparison_Exp>;
  FIXED_DEPOSIT?: Maybe<Float8_Comparison_Exp>;
  GOVT_SECURITIES?: Maybe<Float8_Comparison_Exp>;
  GROSS_PROFIT?: Maybe<Float8_Comparison_Exp>;
  GROSS_RENTAL_INCOME?: Maybe<Float8_Comparison_Exp>;
  HOLDING_COMP_NAME?: Maybe<String_Comparison_Exp>;
  HOLDING_COMP_TIN?: Maybe<String_Comparison_Exp>;
  INCOME_TAX_P?: Maybe<Float8_Comparison_Exp>;
  IS_AMENDMENT?: Maybe<String_Comparison_Exp>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  IS_RESIDENT?: Maybe<String_Comparison_Exp>;
  IS_SUBSIDARY_COMP?: Maybe<String_Comparison_Exp>;
  LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  LAND_BUILD?: Maybe<String_Comparison_Exp>;
  MAIN_ACTIVITY?: Maybe<String_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  NON_ALLOWBL_EXP?: Maybe<Float8_Comparison_Exp>;
  OPERATING_EXP?: Maybe<Float8_Comparison_Exp>;
  ORG_SUB_TYPE?: Maybe<String_Comparison_Exp>;
  ORG_SUB_TYPE_DESC?: Maybe<Float8_Comparison_Exp>;
  ORG_TYPE?: Maybe<String_Comparison_Exp>;
  OTHER_INVESTMENTS?: Maybe<Float8_Comparison_Exp>;
  OTHR_ALLOWBL_EXP?: Maybe<Float8_Comparison_Exp>;
  PAYABLE_AMT_INC?: Maybe<Float8_Comparison_Exp>;
  PAYABLE_AMT_RENT?: Maybe<Float8_Comparison_Exp>;
  POSTADDR_DISTICT_NAME?: Maybe<String_Comparison_Exp>;
  POSTADDR_ID?: Maybe<String_Comparison_Exp>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Float8_Comparison_Exp>;
  PROFIT_AFTER_TAX?: Maybe<Float8_Comparison_Exp>;
  PROFIT_BEFORE_TAX?: Maybe<Float8_Comparison_Exp>;
  PROFIT_LOSS_RENTAL?: Maybe<Float8_Comparison_Exp>;
  PROPOSED_DIVIDEND?: Maybe<Float8_Comparison_Exp>;
  PROVISION_INCOME_TAX?: Maybe<Float8_Comparison_Exp>;
  REFUND_AMT_INC?: Maybe<Float8_Comparison_Exp>;
  REFUND_AMT_RENT?: Maybe<Float8_Comparison_Exp>;
  REF_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  REF_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  REF_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  REF_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  REF_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  REF_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  REF_SURNAME?: Maybe<String_Comparison_Exp>;
  REF_TIN?: Maybe<String_Comparison_Exp>;
  RENTAL_TAX_P?: Maybe<Float8_Comparison_Exp>;
  RENTINC_INCOME?: Maybe<Float8_Comparison_Exp>;
  RENTINC_P?: Maybe<Float8_Comparison_Exp>;
  RENTINC_RATE?: Maybe<Float8_Comparison_Exp>;
  RENTINC_TAX?: Maybe<Float8_Comparison_Exp>;
  RENT_RATES?: Maybe<Float8_Comparison_Exp>;
  RTN_DT?: Maybe<String_Comparison_Exp>;
  RTN_FROM_DT?: Maybe<String_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_PERIOD_YEAR?: Maybe<String_Comparison_Exp>;
  SECURE_TOT_LOAN?: Maybe<String_Comparison_Exp>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Float8_Comparison_Exp>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Float8_Comparison_Exp>;
  SOURCE_FUND?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_NAME?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_TYPE?: Maybe<String_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  TOTAL_PROP_FUND?: Maybe<String_Comparison_Exp>;
  TOTAL_RESERVE?: Maybe<String_Comparison_Exp>;
  TOTAL_SHARE_CAPITAL?: Maybe<Float8_Comparison_Exp>;
  TOT_ADMIN_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_BSNS_PROF_SALES?: Maybe<Float8_Comparison_Exp>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Float8_Comparison_Exp>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_FIXED_ASSET?: Maybe<String_Comparison_Exp>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Float8_Comparison_Exp>;
  TOT_OPERATION_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_OTHER_EXPENSE?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_INCM_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Float8_Comparison_Exp>;
  TOT_TAXES_PAID_INC?: Maybe<Float8_Comparison_Exp>;
  TOT_TAXES_PAID_RENT?: Maybe<Float8_Comparison_Exp>;
  TOT_TAX_LIABILITY?: Maybe<Float8_Comparison_Exp>;
  TOT_TAX_LIABILITY_INC?: Maybe<Float8_Comparison_Exp>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Float8_Comparison_Exp>;
  TOT_TRADE_INVESTMENT?: Maybe<String_Comparison_Exp>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Float8_Comparison_Exp>;
  TRADING_NAME?: Maybe<String_Comparison_Exp>;
  Tot_INCOME?: Maybe<Float8_Comparison_Exp>;
  Tot_RATE?: Maybe<Float8_Comparison_Exp>;
  Tot_TAX?: Maybe<Float8_Comparison_Exp>;
  UNSECURE_TOT_LOAN?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp>>>;
  _not?: Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_Rpt_NonIndividualTaxSummaryData_Bool_Exp>>>;
  cat_legend?: Maybe<String_Comparison_Exp>;
  cat_legend_ri?: Maybe<String_Comparison_Exp>;
  file_x?: Maybe<String_Comparison_Exp>;
  file_y?: Maybe<String_Comparison_Exp>;
  last_updated_x?: Maybe<String_Comparison_Exp>;
  last_updated_y?: Maybe<String_Comparison_Exp>;
  legend?: Maybe<String_Comparison_Exp>;
  legend_ri?: Maybe<String_Comparison_Exp>;
  line_x?: Maybe<Bigint_Comparison_Exp>;
  line_y?: Maybe<Bigint_Comparison_Exp>;
  who_updated_x?: Maybe<String_Comparison_Exp>;
  who_updated_y?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Inc_Input = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Insert_Input = {
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["String"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["float8"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Max_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_max_fields";
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["String"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["float8"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Max_Order_By = {
  ACTVTY_DESC?: Maybe<Order_By>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BALANCE_CARRY_FORWARD?: Maybe<Order_By>;
  BALANCE_PREV_PERIOD?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  BSNSADDR_BUILDNAME?: Maybe<Order_By>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Order_By>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  BSNSADDR_PARISH_NAME?: Maybe<Order_By>;
  BSNSADDR_PLOT_NO?: Maybe<Order_By>;
  BSNSADDR_STREET_ADDR?: Maybe<Order_By>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  BSNSADDR_TOWN_NAME?: Maybe<Order_By>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Order_By>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Order_By>;
  BSNS_CERT_NUMBER?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  BUSINESS_START_DT?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  HOLDING_COMP_NAME?: Maybe<Order_By>;
  HOLDING_COMP_TIN?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  IS_SUBSIDARY_COMP?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  LAND_BUILD?: Maybe<Order_By>;
  MAIN_ACTIVITY?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  ORG_TYPE?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MIDDLE_NAME?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SECURE_TOT_LOAN?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  SOURCE_FUND?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOTAL_PROP_FUND?: Maybe<Order_By>;
  TOTAL_RESERVE?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_FIXED_ASSET?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_TRADE_INVESTMENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  TRADING_NAME?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  UNSECURE_TOT_LOAN?: Maybe<Order_By>;
  cat_legend?: Maybe<Order_By>;
  cat_legend_ri?: Maybe<Order_By>;
  file_x?: Maybe<Order_By>;
  file_y?: Maybe<Order_By>;
  last_updated_x?: Maybe<Order_By>;
  last_updated_y?: Maybe<Order_By>;
  legend?: Maybe<Order_By>;
  legend_ri?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
  who_updated_x?: Maybe<Order_By>;
  who_updated_y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Min_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_min_fields";
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["String"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["float8"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Min_Order_By = {
  ACTVTY_DESC?: Maybe<Order_By>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BALANCE_CARRY_FORWARD?: Maybe<Order_By>;
  BALANCE_PREV_PERIOD?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  BSNSADDR_BUILDNAME?: Maybe<Order_By>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Order_By>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  BSNSADDR_PARISH_NAME?: Maybe<Order_By>;
  BSNSADDR_PLOT_NO?: Maybe<Order_By>;
  BSNSADDR_STREET_ADDR?: Maybe<Order_By>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  BSNSADDR_TOWN_NAME?: Maybe<Order_By>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Order_By>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Order_By>;
  BSNS_CERT_NUMBER?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  BUSINESS_START_DT?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  HOLDING_COMP_NAME?: Maybe<Order_By>;
  HOLDING_COMP_TIN?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  IS_SUBSIDARY_COMP?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  LAND_BUILD?: Maybe<Order_By>;
  MAIN_ACTIVITY?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  ORG_TYPE?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MIDDLE_NAME?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SECURE_TOT_LOAN?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  SOURCE_FUND?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOTAL_PROP_FUND?: Maybe<Order_By>;
  TOTAL_RESERVE?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_FIXED_ASSET?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_TRADE_INVESTMENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  TRADING_NAME?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  UNSECURE_TOT_LOAN?: Maybe<Order_By>;
  cat_legend?: Maybe<Order_By>;
  cat_legend_ri?: Maybe<Order_By>;
  file_x?: Maybe<Order_By>;
  file_y?: Maybe<Order_By>;
  last_updated_x?: Maybe<Order_By>;
  last_updated_y?: Maybe<Order_By>;
  legend?: Maybe<Order_By>;
  legend_ri?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
  who_updated_x?: Maybe<Order_By>;
  who_updated_y?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Mutation_Response = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_Rpt_NonIndividualTaxSummaryData>;
};

/** input type for inserting object relation for remote table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Obj_Rel_Insert_Input = {
  data: Ura_Rpt_NonIndividualTaxSummaryData_Insert_Input;
};

/** ordering options when selecting data from "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Order_By = {
  ACTVTY_DESC?: Maybe<Order_By>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BALANCE_CARRY_FORWARD?: Maybe<Order_By>;
  BALANCE_PREV_PERIOD?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  BSNSADDR_BUILDNAME?: Maybe<Order_By>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Order_By>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  BSNSADDR_PARISH_NAME?: Maybe<Order_By>;
  BSNSADDR_PLOT_NO?: Maybe<Order_By>;
  BSNSADDR_STREET_ADDR?: Maybe<Order_By>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  BSNSADDR_TOWN_NAME?: Maybe<Order_By>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Order_By>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Order_By>;
  BSNS_CERT_NUMBER?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  BUSINESS_START_DT?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  HOLDING_COMP_NAME?: Maybe<Order_By>;
  HOLDING_COMP_TIN?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  IS_AMENDMENT?: Maybe<Order_By>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_RESIDENT?: Maybe<Order_By>;
  IS_SUBSIDARY_COMP?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  LAND_BUILD?: Maybe<Order_By>;
  MAIN_ACTIVITY?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  ORG_TYPE?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MIDDLE_NAME?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_PERIOD_YEAR?: Maybe<Order_By>;
  SECURE_TOT_LOAN?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  SOURCE_FUND?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TOTAL_PROP_FUND?: Maybe<Order_By>;
  TOTAL_RESERVE?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_FIXED_ASSET?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_TRADE_INVESTMENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  TRADING_NAME?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  UNSECURE_TOT_LOAN?: Maybe<Order_By>;
  cat_legend?: Maybe<Order_By>;
  cat_legend_ri?: Maybe<Order_By>;
  file_x?: Maybe<Order_By>;
  file_y?: Maybe<Order_By>;
  last_updated_x?: Maybe<Order_By>;
  last_updated_y?: Maybe<Order_By>;
  legend?: Maybe<Order_By>;
  legend_ri?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
  who_updated_x?: Maybe<Order_By>;
  who_updated_y?: Maybe<Order_By>;
};

/** select columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export enum Ura_Rpt_NonIndividualTaxSummaryData_Select_Column {
  /** column name */
  ActvtyDesc = "ACTVTY_DESC",
  /** column name */
  AdjProfitLossRental = "ADJ_PROFIT_LOSS_RENTAL",
  /** column name */
  AdjRentlIncmLoss = "ADJ_RENTL_INCM_LOSS",
  /** column name */
  AdministratingExp = "ADMINISTRATING_EXP",
  /** column name */
  AllowblApportionedCap = "ALLOWBL_APPORTIONED_CAP",
  /** column name */
  AppliedForTin = "APPLIED_FOR_TIN",
  /** column name */
  AssessedChrgblRentlIncm = "ASSESSED_CHRGBL_RENTL_INCM",
  /** column name */
  AssessedRentlLoss = "ASSESSED_RENTL_LOSS",
  /** column name */
  AuthctcdEmailId = "AUTHCTCD_EMAIL_ID",
  /** column name */
  AuthctcdFamilyName = "AUTHCTCD_FAMILY_NAME",
  /** column name */
  AuthctcdFirstName = "AUTHCTCD_FIRST_NAME",
  /** column name */
  AuthctcdLandlineNumber = "AUTHCTCD_LANDLINE_NUMBER",
  /** column name */
  AuthctcdMobileNumber = "AUTHCTCD_MOBILE_NUMBER",
  /** column name */
  AuthctcdOtherName = "AUTHCTCD_OTHER_NAME",
  /** column name */
  AuthctcdPrsnDesgn = "AUTHCTCD_PRSN_DESGN",
  /** column name */
  AuthctcdSurname = "AUTHCTCD_SURNAME",
  /** column name */
  BadDebtWriteOff = "BAD_DEBT_WRITE_OFF",
  /** column name */
  BalanceCarryForward = "BALANCE_CARRY_FORWARD",
  /** column name */
  BalancePrevPeriod = "BALANCE_PREV_PERIOD",
  /** column name */
  BrghtFwdAssessedLoss = "BRGHT_FWD_ASSESSED_LOSS",
  /** column name */
  BsnsaddrBuildname = "BSNSADDR_BUILDNAME",
  /** column name */
  BsnsaddrDistrictName = "BSNSADDR_DISTRICT_NAME",
  /** column name */
  BsnsaddrLocalCouncil = "BSNSADDR_LOCAL_COUNCIL",
  /** column name */
  BsnsaddrParishName = "BSNSADDR_PARISH_NAME",
  /** column name */
  BsnsaddrPlotNo = "BSNSADDR_PLOT_NO",
  /** column name */
  BsnsaddrStreetAddr = "BSNSADDR_STREET_ADDR",
  /** column name */
  BsnsaddrSubCountyName = "BSNSADDR_SUB_COUNTY_NAME",
  /** column name */
  BsnsaddrTownName = "BSNSADDR_TOWN_NAME",
  /** column name */
  BsnsaddrTradeCentre = "BSNSADDR_TRADE_CENTRE",
  /** column name */
  BsnsaddrVillageName = "BSNSADDR_VILLAGE_NAME",
  /** column name */
  BsnsCertNumber = "BSNS_CERT_NUMBER",
  /** column name */
  BsnsName = "BSNS_NAME",
  /** column name */
  BusinessStartDt = "BUSINESS_START_DT",
  /** column name */
  BusinessIncome = "Business_INCOME",
  /** column name */
  BusinessRate = "Business_RATE",
  /** column name */
  BusinessTax = "Business_TAX",
  /** column name */
  CostOfSales = "COST_OF_SALES",
  /** column name */
  Debenture = "DEBENTURE",
  /** column name */
  DeferTaxLiability = "DEFER_TAX_LIABILITY",
  /** column name */
  Depreciation = "DEPRECIATION",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  EquityShare = "EQUITY_SHARE",
  /** column name */
  ExistTinNo = "EXIST_TIN_NO",
  /** column name */
  FactoryRentRates = "FACTORY_RENT_RATES",
  /** column name */
  FinanceExp = "FINANCE_EXP",
  /** column name */
  FixedDeposit = "FIXED_DEPOSIT",
  /** column name */
  GovtSecurities = "GOVT_SECURITIES",
  /** column name */
  GrossProfit = "GROSS_PROFIT",
  /** column name */
  GrossRentalIncome = "GROSS_RENTAL_INCOME",
  /** column name */
  HoldingCompName = "HOLDING_COMP_NAME",
  /** column name */
  HoldingCompTin = "HOLDING_COMP_TIN",
  /** column name */
  IncomeTaxP = "INCOME_TAX_P",
  /** column name */
  IsAmendment = "IS_AMENDMENT",
  /** column name */
  IsHldCmpInUganda = "IS_HLD_CMP_IN_UGANDA",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  IsResident = "IS_RESIDENT",
  /** column name */
  IsSubsidaryComp = "IS_SUBSIDARY_COMP",
  /** column name */
  LandlineNumber = "LANDLINE_NUMBER",
  /** column name */
  LandBuild = "LAND_BUILD",
  /** column name */
  MainActivity = "MAIN_ACTIVITY",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  NonAllowblExp = "NON_ALLOWBL_EXP",
  /** column name */
  OperatingExp = "OPERATING_EXP",
  /** column name */
  OrgSubType = "ORG_SUB_TYPE",
  /** column name */
  OrgSubTypeDesc = "ORG_SUB_TYPE_DESC",
  /** column name */
  OrgType = "ORG_TYPE",
  /** column name */
  OtherInvestments = "OTHER_INVESTMENTS",
  /** column name */
  OthrAllowblExp = "OTHR_ALLOWBL_EXP",
  /** column name */
  PayableAmtInc = "PAYABLE_AMT_INC",
  /** column name */
  PayableAmtRent = "PAYABLE_AMT_RENT",
  /** column name */
  PostaddrDistictName = "POSTADDR_DISTICT_NAME",
  /** column name */
  PostaddrId = "POSTADDR_ID",
  /** column name */
  ProfitAfterDividend = "PROFIT_AFTER_DIVIDEND",
  /** column name */
  ProfitAfterTax = "PROFIT_AFTER_TAX",
  /** column name */
  ProfitBeforeTax = "PROFIT_BEFORE_TAX",
  /** column name */
  ProfitLossRental = "PROFIT_LOSS_RENTAL",
  /** column name */
  ProposedDividend = "PROPOSED_DIVIDEND",
  /** column name */
  ProvisionIncomeTax = "PROVISION_INCOME_TAX",
  /** column name */
  RefundAmtInc = "REFUND_AMT_INC",
  /** column name */
  RefundAmtRent = "REFUND_AMT_RENT",
  /** column name */
  RefEmailId = "REF_EMAIL_ID",
  /** column name */
  RefFamilyName = "REF_FAMILY_NAME",
  /** column name */
  RefFirstName = "REF_FIRST_NAME",
  /** column name */
  RefLandlineNumber = "REF_LANDLINE_NUMBER",
  /** column name */
  RefMiddleName = "REF_MIDDLE_NAME",
  /** column name */
  RefMobileNumber = "REF_MOBILE_NUMBER",
  /** column name */
  RefOtherName = "REF_OTHER_NAME",
  /** column name */
  RefPrsnDesgn = "REF_PRSN_DESGN",
  /** column name */
  RefSurname = "REF_SURNAME",
  /** column name */
  RefTin = "REF_TIN",
  /** column name */
  RentalTaxP = "RENTAL_TAX_P",
  /** column name */
  RentincIncome = "RENTINC_INCOME",
  /** column name */
  RentincP = "RENTINC_P",
  /** column name */
  RentincRate = "RENTINC_RATE",
  /** column name */
  RentincTax = "RENTINC_TAX",
  /** column name */
  RentRates = "RENT_RATES",
  /** column name */
  RtnDt = "RTN_DT",
  /** column name */
  RtnFromDt = "RTN_FROM_DT",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnPeriodYear = "RTN_PERIOD_YEAR",
  /** column name */
  SecureTotLoan = "SECURE_TOT_LOAN",
  /** column name */
  SelfAssessTaxPaidInc = "SELF_ASSESS_TAX_PAID_INC",
  /** column name */
  SelfAssessTaxPaidRent = "SELF_ASSESS_TAX_PAID_RENT",
  /** column name */
  SourceFund = "SOURCE_FUND",
  /** column name */
  TaxPayerName = "TAX_PAYER_NAME",
  /** column name */
  TaxPayerType = "TAX_PAYER_TYPE",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  TotalPropFund = "TOTAL_PROP_FUND",
  /** column name */
  TotalReserve = "TOTAL_RESERVE",
  /** column name */
  TotalShareCapital = "TOTAL_SHARE_CAPITAL",
  /** column name */
  TotAdminExpense = "TOT_ADMIN_EXPENSE",
  /** column name */
  TotBsnsProfSales = "TOT_BSNS_PROF_SALES",
  /** column name */
  TotExpPurelyRental = "TOT_EXP_PURELY_RENTAL",
  /** column name */
  TotFinancialExpense = "TOT_FINANCIAL_EXPENSE",
  /** column name */
  TotFixedAsset = "TOT_FIXED_ASSET",
  /** column name */
  TotNonChargeableIncome = "TOT_NON_CHARGEABLE_INCOME",
  /** column name */
  TotOperationExpense = "TOT_OPERATION_EXPENSE",
  /** column name */
  TotOtherExpense = "TOT_OTHER_EXPENSE",
  /** column name */
  TotRentAdminiExpns = "TOT_RENT_ADMINI_EXPNS",
  /** column name */
  TotRentFinancExpns = "TOT_RENT_FINANC_EXPNS",
  /** column name */
  TotRentIncmExpns = "TOT_RENT_INCM_EXPNS",
  /** column name */
  TotRentOprtgExpns = "TOT_RENT_OPRTG_EXPNS",
  /** column name */
  TotTaxesPaidInc = "TOT_TAXES_PAID_INC",
  /** column name */
  TotTaxesPaidRent = "TOT_TAXES_PAID_RENT",
  /** column name */
  TotTaxLiability = "TOT_TAX_LIABILITY",
  /** column name */
  TotTaxLiabilityInc = "TOT_TAX_LIABILITY_INC",
  /** column name */
  TotTaxLiabilityRent = "TOT_TAX_LIABILITY_RENT",
  /** column name */
  TotTradeInvestment = "TOT_TRADE_INVESTMENT",
  /** column name */
  TotWthdTaxDeduct = "TOT_WTHD_TAX_DEDUCT",
  /** column name */
  TradingName = "TRADING_NAME",
  /** column name */
  TotIncome = "Tot_INCOME",
  /** column name */
  TotRate = "Tot_RATE",
  /** column name */
  TotTax = "Tot_TAX",
  /** column name */
  UnsecureTotLoan = "UNSECURE_TOT_LOAN",
  /** column name */
  CatLegend = "cat_legend",
  /** column name */
  CatLegendRi = "cat_legend_ri",
  /** column name */
  FileX = "file_x",
  /** column name */
  FileY = "file_y",
  /** column name */
  LastUpdatedX = "last_updated_x",
  /** column name */
  LastUpdatedY = "last_updated_y",
  /** column name */
  Legend = "legend",
  /** column name */
  LegendRi = "legend_ri",
  /** column name */
  LineX = "line_x",
  /** column name */
  LineY = "line_y",
  /** column name */
  WhoUpdatedX = "who_updated_x",
  /** column name */
  WhoUpdatedY = "who_updated_y",
}

/** input type for updating data in table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Set_Input = {
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BALANCE_CARRY_FORWARD?: Maybe<Scalars["String"]>;
  BALANCE_PREV_PERIOD?: Maybe<Scalars["String"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["String"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  IS_AMENDMENT?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_RESIDENT?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  LAND_BUILD?: Maybe<Scalars["String"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["float8"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["String"]>;
  RTN_FROM_DT?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_PERIOD_YEAR?: Maybe<Scalars["String"]>;
  SECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  SOURCE_FUND?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TOTAL_PROP_FUND?: Maybe<Scalars["String"]>;
  TOTAL_RESERVE?: Maybe<Scalars["String"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_FIXED_ASSET?: Maybe<Scalars["String"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_TRADE_INVESTMENT?: Maybe<Scalars["String"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  UNSECURE_TOT_LOAN?: Maybe<Scalars["String"]>;
  cat_legend?: Maybe<Scalars["String"]>;
  cat_legend_ri?: Maybe<Scalars["String"]>;
  file_x?: Maybe<Scalars["String"]>;
  file_y?: Maybe<Scalars["String"]>;
  last_updated_x?: Maybe<Scalars["String"]>;
  last_updated_y?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  legend_ri?: Maybe<Scalars["String"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
  who_updated_x?: Maybe<Scalars["String"]>;
  who_updated_y?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_stddev_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_stddev_pop_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_stddev_samp_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Sum_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_sum_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["float8"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["float8"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["float8"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["float8"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["float8"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["float8"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["float8"]>;
  Business_INCOME?: Maybe<Scalars["float8"]>;
  Business_RATE?: Maybe<Scalars["float8"]>;
  Business_TAX?: Maybe<Scalars["float8"]>;
  COST_OF_SALES?: Maybe<Scalars["float8"]>;
  DEBENTURE?: Maybe<Scalars["float8"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  DEPRECIATION?: Maybe<Scalars["float8"]>;
  EQUITY_SHARE?: Maybe<Scalars["float8"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["float8"]>;
  FINANCE_EXP?: Maybe<Scalars["float8"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["float8"]>;
  GOVT_SECURITIES?: Maybe<Scalars["float8"]>;
  GROSS_PROFIT?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  INCOME_TAX_P?: Maybe<Scalars["float8"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  OPERATING_EXP?: Maybe<Scalars["float8"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["float8"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["float8"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["float8"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["float8"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["float8"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["float8"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["float8"]>;
  REFUND_AMT_INC?: Maybe<Scalars["float8"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["float8"]>;
  RENTAL_TAX_P?: Maybe<Scalars["float8"]>;
  RENTINC_INCOME?: Maybe<Scalars["float8"]>;
  RENTINC_P?: Maybe<Scalars["float8"]>;
  RENTINC_RATE?: Maybe<Scalars["float8"]>;
  RENTINC_TAX?: Maybe<Scalars["float8"]>;
  RENT_RATES?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["float8"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["float8"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["float8"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["float8"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["float8"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["float8"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["float8"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["float8"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["float8"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["float8"]>;
  Tot_INCOME?: Maybe<Scalars["float8"]>;
  Tot_RATE?: Maybe<Scalars["float8"]>;
  Tot_TAX?: Maybe<Scalars["float8"]>;
  line_x?: Maybe<Scalars["bigint"]>;
  line_y?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Sum_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Var_Pop_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_var_pop_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Var_Pop_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Var_Samp_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_var_samp_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Var_Samp_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_Rpt_NonIndividualTaxSummaryData_Variance_Fields = {
  __typename?: "ura_RPT_NonIndividualTaxSummaryData_variance_fields";
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Scalars["Float"]>;
  ADMINISTRATING_EXP?: Maybe<Scalars["Float"]>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Scalars["Float"]>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Scalars["Float"]>;
  ASSESSED_RENTL_LOSS?: Maybe<Scalars["Float"]>;
  BAD_DEBT_WRITE_OFF?: Maybe<Scalars["Float"]>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Scalars["Float"]>;
  Business_INCOME?: Maybe<Scalars["Float"]>;
  Business_RATE?: Maybe<Scalars["Float"]>;
  Business_TAX?: Maybe<Scalars["Float"]>;
  COST_OF_SALES?: Maybe<Scalars["Float"]>;
  DEBENTURE?: Maybe<Scalars["Float"]>;
  DEFER_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  DEPRECIATION?: Maybe<Scalars["Float"]>;
  EQUITY_SHARE?: Maybe<Scalars["Float"]>;
  FACTORY_RENT_RATES?: Maybe<Scalars["Float"]>;
  FINANCE_EXP?: Maybe<Scalars["Float"]>;
  FIXED_DEPOSIT?: Maybe<Scalars["Float"]>;
  GOVT_SECURITIES?: Maybe<Scalars["Float"]>;
  GROSS_PROFIT?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  INCOME_TAX_P?: Maybe<Scalars["Float"]>;
  NON_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  OPERATING_EXP?: Maybe<Scalars["Float"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["Float"]>;
  OTHER_INVESTMENTS?: Maybe<Scalars["Float"]>;
  OTHR_ALLOWBL_EXP?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_INC?: Maybe<Scalars["Float"]>;
  PAYABLE_AMT_RENT?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROFIT_AFTER_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_BEFORE_TAX?: Maybe<Scalars["Float"]>;
  PROFIT_LOSS_RENTAL?: Maybe<Scalars["Float"]>;
  PROPOSED_DIVIDEND?: Maybe<Scalars["Float"]>;
  PROVISION_INCOME_TAX?: Maybe<Scalars["Float"]>;
  REFUND_AMT_INC?: Maybe<Scalars["Float"]>;
  REFUND_AMT_RENT?: Maybe<Scalars["Float"]>;
  RENTAL_TAX_P?: Maybe<Scalars["Float"]>;
  RENTINC_INCOME?: Maybe<Scalars["Float"]>;
  RENTINC_P?: Maybe<Scalars["Float"]>;
  RENTINC_RATE?: Maybe<Scalars["Float"]>;
  RENTINC_TAX?: Maybe<Scalars["Float"]>;
  RENT_RATES?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Scalars["Float"]>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOTAL_SHARE_CAPITAL?: Maybe<Scalars["Float"]>;
  TOT_ADMIN_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_BSNS_PROF_SALES?: Maybe<Scalars["Float"]>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Scalars["Float"]>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Scalars["Float"]>;
  TOT_OPERATION_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_OTHER_EXPENSE?: Maybe<Scalars["Float"]>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_INCM_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_INC?: Maybe<Scalars["Float"]>;
  TOT_TAXES_PAID_RENT?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_INC?: Maybe<Scalars["Float"]>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Scalars["Float"]>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Scalars["Float"]>;
  Tot_INCOME?: Maybe<Scalars["Float"]>;
  Tot_RATE?: Maybe<Scalars["Float"]>;
  Tot_TAX?: Maybe<Scalars["Float"]>;
  line_x?: Maybe<Scalars["Float"]>;
  line_y?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RPT_NonIndividualTaxSummaryData" */
export type Ura_Rpt_NonIndividualTaxSummaryData_Variance_Order_By = {
  ADJ_PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  ADJ_RENTL_INCM_LOSS?: Maybe<Order_By>;
  ADMINISTRATING_EXP?: Maybe<Order_By>;
  ALLOWBL_APPORTIONED_CAP?: Maybe<Order_By>;
  ASSESSED_CHRGBL_RENTL_INCM?: Maybe<Order_By>;
  ASSESSED_RENTL_LOSS?: Maybe<Order_By>;
  BAD_DEBT_WRITE_OFF?: Maybe<Order_By>;
  BRGHT_FWD_ASSESSED_LOSS?: Maybe<Order_By>;
  Business_INCOME?: Maybe<Order_By>;
  Business_RATE?: Maybe<Order_By>;
  Business_TAX?: Maybe<Order_By>;
  COST_OF_SALES?: Maybe<Order_By>;
  DEBENTURE?: Maybe<Order_By>;
  DEFER_TAX_LIABILITY?: Maybe<Order_By>;
  DEPRECIATION?: Maybe<Order_By>;
  EQUITY_SHARE?: Maybe<Order_By>;
  FACTORY_RENT_RATES?: Maybe<Order_By>;
  FINANCE_EXP?: Maybe<Order_By>;
  FIXED_DEPOSIT?: Maybe<Order_By>;
  GOVT_SECURITIES?: Maybe<Order_By>;
  GROSS_PROFIT?: Maybe<Order_By>;
  GROSS_RENTAL_INCOME?: Maybe<Order_By>;
  INCOME_TAX_P?: Maybe<Order_By>;
  NON_ALLOWBL_EXP?: Maybe<Order_By>;
  OPERATING_EXP?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  OTHER_INVESTMENTS?: Maybe<Order_By>;
  OTHR_ALLOWBL_EXP?: Maybe<Order_By>;
  PAYABLE_AMT_INC?: Maybe<Order_By>;
  PAYABLE_AMT_RENT?: Maybe<Order_By>;
  PROFIT_AFTER_DIVIDEND?: Maybe<Order_By>;
  PROFIT_AFTER_TAX?: Maybe<Order_By>;
  PROFIT_BEFORE_TAX?: Maybe<Order_By>;
  PROFIT_LOSS_RENTAL?: Maybe<Order_By>;
  PROPOSED_DIVIDEND?: Maybe<Order_By>;
  PROVISION_INCOME_TAX?: Maybe<Order_By>;
  REFUND_AMT_INC?: Maybe<Order_By>;
  REFUND_AMT_RENT?: Maybe<Order_By>;
  RENTAL_TAX_P?: Maybe<Order_By>;
  RENTINC_INCOME?: Maybe<Order_By>;
  RENTINC_P?: Maybe<Order_By>;
  RENTINC_RATE?: Maybe<Order_By>;
  RENTINC_TAX?: Maybe<Order_By>;
  RENT_RATES?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_INC?: Maybe<Order_By>;
  SELF_ASSESS_TAX_PAID_RENT?: Maybe<Order_By>;
  TOTAL_SHARE_CAPITAL?: Maybe<Order_By>;
  TOT_ADMIN_EXPENSE?: Maybe<Order_By>;
  TOT_BSNS_PROF_SALES?: Maybe<Order_By>;
  TOT_EXP_PURELY_RENTAL?: Maybe<Order_By>;
  TOT_FINANCIAL_EXPENSE?: Maybe<Order_By>;
  TOT_NON_CHARGEABLE_INCOME?: Maybe<Order_By>;
  TOT_OPERATION_EXPENSE?: Maybe<Order_By>;
  TOT_OTHER_EXPENSE?: Maybe<Order_By>;
  TOT_RENT_ADMINI_EXPNS?: Maybe<Order_By>;
  TOT_RENT_FINANC_EXPNS?: Maybe<Order_By>;
  TOT_RENT_INCM_EXPNS?: Maybe<Order_By>;
  TOT_RENT_OPRTG_EXPNS?: Maybe<Order_By>;
  TOT_TAXES_PAID_INC?: Maybe<Order_By>;
  TOT_TAXES_PAID_RENT?: Maybe<Order_By>;
  TOT_TAX_LIABILITY?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_INC?: Maybe<Order_By>;
  TOT_TAX_LIABILITY_RENT?: Maybe<Order_By>;
  TOT_WTHD_TAX_DEDUCT?: Maybe<Order_By>;
  Tot_INCOME?: Maybe<Order_By>;
  Tot_RATE?: Maybe<Order_By>;
  Tot_TAX?: Maybe<Order_By>;
  line_x?: Maybe<Order_By>;
  line_y?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual = {
  __typename?: "ura_RPT_RegIndividual";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  FULL_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Aggregate = {
  __typename?: "ura_RPT_RegIndividual_aggregate";
  aggregate?: Maybe<Ura_Rpt_RegIndividual_Aggregate_Fields>;
  nodes: Array<Ura_Rpt_RegIndividual>;
};

/** aggregate fields of "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Aggregate_Fields = {
  __typename?: "ura_RPT_RegIndividual_aggregate_fields";
  avg?: Maybe<Ura_Rpt_RegIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_Rpt_RegIndividual_Max_Fields>;
  min?: Maybe<Ura_Rpt_RegIndividual_Min_Fields>;
  stddev?: Maybe<Ura_Rpt_RegIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_Rpt_RegIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_Rpt_RegIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_Rpt_RegIndividual_Sum_Fields>;
  var_pop?: Maybe<Ura_Rpt_RegIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_Rpt_RegIndividual_Var_Samp_Fields>;
  variance?: Maybe<Ura_Rpt_RegIndividual_Variance_Fields>;
};

/** aggregate fields of "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_Rpt_RegIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Aggregate_Order_By = {
  avg?: Maybe<Ura_Rpt_RegIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_Rpt_RegIndividual_Max_Order_By>;
  min?: Maybe<Ura_Rpt_RegIndividual_Min_Order_By>;
  stddev?: Maybe<Ura_Rpt_RegIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_Rpt_RegIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_Rpt_RegIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_Rpt_RegIndividual_Sum_Order_By>;
  var_pop?: Maybe<Ura_Rpt_RegIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_Rpt_RegIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Ura_Rpt_RegIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Arr_Rel_Insert_Input = {
  data: Array<Ura_Rpt_RegIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_Rpt_RegIndividual_Avg_Fields = {
  __typename?: "ura_RPT_RegIndividual_avg_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Avg_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RPT_RegIndividual". All fields are combined with a logical 'AND'. */
export type Ura_Rpt_RegIndividual_Bool_Exp = {
  APPLIED_FOR_TIN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_SURNAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_TIN?: Maybe<String_Comparison_Exp>;
  BIRTH_CITY?: Maybe<Float8_Comparison_Exp>;
  BIRTH_COUNTRY?: Maybe<String_Comparison_Exp>;
  BIRTH_DT?: Maybe<String_Comparison_Exp>;
  BIRTH_STATE?: Maybe<String_Comparison_Exp>;
  BSNS_NAME?: Maybe<String_Comparison_Exp>;
  CITIZEN_COUNTRY?: Maybe<String_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  EXIST_TIN_NO?: Maybe<String_Comparison_Exp>;
  FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  FIRST_NAME?: Maybe<String_Comparison_Exp>;
  FULL_NAME?: Maybe<String_Comparison_Exp>;
  GENDER?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_BUILDINGNAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_PARISH_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_PLOTNO?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_STRRET_ADDR?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_TOWN_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_EMP?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  NSSF_ID?: Maybe<String_Comparison_Exp>;
  OTHER_NAME?: Maybe<String_Comparison_Exp>;
  POSTADDR_DISTICT_NAME?: Maybe<String_Comparison_Exp>;
  POSTADDR_ID?: Maybe<String_Comparison_Exp>;
  REF_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  REF_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  REF_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  REF_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  REF_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  REF_SURNAME?: Maybe<String_Comparison_Exp>;
  REF_TIN?: Maybe<String_Comparison_Exp>;
  SUR_NAME?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_ID?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_NAME?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_TYPE?: Maybe<String_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  Visible_Level?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_Rpt_RegIndividual_Bool_Exp>>>;
  _not?: Maybe<Ura_Rpt_RegIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_Rpt_RegIndividual_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<String_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Inc_Input = {
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Insert_Input = {
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  FULL_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_Rpt_RegIndividual_Max_Fields = {
  __typename?: "ura_RPT_RegIndividual_max_fields";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  FULL_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Max_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  FULL_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_Rpt_RegIndividual_Min_Fields = {
  __typename?: "ura_RPT_RegIndividual_min_fields";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  FULL_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Min_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  FULL_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Mutation_Response = {
  __typename?: "ura_RPT_RegIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_Rpt_RegIndividual>;
};

/** input type for inserting object relation for remote table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Obj_Rel_Insert_Input = {
  data: Ura_Rpt_RegIndividual_Insert_Input;
};

/** ordering options when selecting data from "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  FULL_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RPT_RegIndividual" */
export enum Ura_Rpt_RegIndividual_Select_Column {
  /** column name */
  AppliedForTin = "APPLIED_FOR_TIN",
  /** column name */
  AuthctcdEmailId = "AUTHCTCD_EMAIL_ID",
  /** column name */
  AuthctcdFamilyName = "AUTHCTCD_FAMILY_NAME",
  /** column name */
  AuthctcdFirstName = "AUTHCTCD_FIRST_NAME",
  /** column name */
  AuthctcdLandlineNumber = "AUTHCTCD_LANDLINE_NUMBER",
  /** column name */
  AuthctcdMobileNumber = "AUTHCTCD_MOBILE_NUMBER",
  /** column name */
  AuthctcdOtherName = "AUTHCTCD_OTHER_NAME",
  /** column name */
  AuthctcdPrsnDesgn = "AUTHCTCD_PRSN_DESGN",
  /** column name */
  AuthctcdSurname = "AUTHCTCD_SURNAME",
  /** column name */
  AuthctcdTin = "AUTHCTCD_TIN",
  /** column name */
  BirthCity = "BIRTH_CITY",
  /** column name */
  BirthCountry = "BIRTH_COUNTRY",
  /** column name */
  BirthDt = "BIRTH_DT",
  /** column name */
  BirthState = "BIRTH_STATE",
  /** column name */
  BsnsName = "BSNS_NAME",
  /** column name */
  CitizenCountry = "CITIZEN_COUNTRY",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  ExistTinNo = "EXIST_TIN_NO",
  /** column name */
  FamilyName = "FAMILY_NAME",
  /** column name */
  FirstName = "FIRST_NAME",
  /** column name */
  FullName = "FULL_NAME",
  /** column name */
  Gender = "GENDER",
  /** column name */
  HomeAddrBuildingname = "HOME_ADDR_BUILDINGNAME",
  /** column name */
  HomeAddrDistrictName = "HOME_ADDR_DISTRICT_NAME",
  /** column name */
  HomeAddrLocalCouncil = "HOME_ADDR_LOCAL_COUNCIL",
  /** column name */
  HomeAddrParishName = "HOME_ADDR_PARISH_NAME",
  /** column name */
  HomeAddrPlotno = "HOME_ADDR_PLOTNO",
  /** column name */
  HomeAddrStrretAddr = "HOME_ADDR_STRRET_ADDR",
  /** column name */
  HomeAddrSubCountyName = "HOME_ADDR_SUB_COUNTY_NAME",
  /** column name */
  HomeAddrTownName = "HOME_ADDR_TOWN_NAME",
  /** column name */
  HomeAddrTradeCentre = "HOME_ADDR_TRADE_CENTRE",
  /** column name */
  HomeAddrVillageName = "HOME_ADDR_VILLAGE_NAME",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcEmp = "IS_INCOME_SRC_EMP",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  LandlineNumber = "LANDLINE_NUMBER",
  /** column name */
  MiddleName = "MIDDLE_NAME",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  NationalId = "NATIONAL_ID",
  /** column name */
  NssfId = "NSSF_ID",
  /** column name */
  OtherName = "OTHER_NAME",
  /** column name */
  PostaddrDistictName = "POSTADDR_DISTICT_NAME",
  /** column name */
  PostaddrId = "POSTADDR_ID",
  /** column name */
  RefEmailId = "REF_EMAIL_ID",
  /** column name */
  RefFamilyName = "REF_FAMILY_NAME",
  /** column name */
  RefFirstName = "REF_FIRST_NAME",
  /** column name */
  RefLandlineNumber = "REF_LANDLINE_NUMBER",
  /** column name */
  RefMobileNumber = "REF_MOBILE_NUMBER",
  /** column name */
  RefOtherName = "REF_OTHER_NAME",
  /** column name */
  RefPrsnDesgn = "REF_PRSN_DESGN",
  /** column name */
  RefSurname = "REF_SURNAME",
  /** column name */
  RefTin = "REF_TIN",
  /** column name */
  SurName = "SUR_NAME",
  /** column name */
  TaxPayerId = "TAX_PAYER_ID",
  /** column name */
  TaxPayerName = "TAX_PAYER_NAME",
  /** column name */
  TaxPayerType = "TAX_PAYER_TYPE",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  VisibleLevel = "Visible_Level",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Set_Input = {
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["String"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  FULL_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_Rpt_RegIndividual_Stddev_Fields = {
  __typename?: "ura_RPT_RegIndividual_stddev_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Stddev_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_Rpt_RegIndividual_Stddev_Pop_Fields = {
  __typename?: "ura_RPT_RegIndividual_stddev_pop_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Stddev_Pop_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_Rpt_RegIndividual_Stddev_Samp_Fields = {
  __typename?: "ura_RPT_RegIndividual_stddev_samp_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Stddev_Samp_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_Rpt_RegIndividual_Sum_Fields = {
  __typename?: "ura_RPT_RegIndividual_sum_fields";
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Sum_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_Rpt_RegIndividual_Var_Pop_Fields = {
  __typename?: "ura_RPT_RegIndividual_var_pop_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Var_Pop_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_Rpt_RegIndividual_Var_Samp_Fields = {
  __typename?: "ura_RPT_RegIndividual_var_samp_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Var_Samp_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_Rpt_RegIndividual_Variance_Fields = {
  __typename?: "ura_RPT_RegIndividual_variance_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RPT_RegIndividual" */
export type Ura_Rpt_RegIndividual_Variance_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RegAssociates" */
export type Ura_RegAssociates = {
  __typename?: "ura_RegAssociates";
  ASSOCIATE_TIN?: Maybe<Scalars["String"]>;
  ENTITY_DESIGNATION?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Scalars["String"]>;
  SURNAME?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RegAssociates" */
export type Ura_RegAssociates_Aggregate = {
  __typename?: "ura_RegAssociates_aggregate";
  aggregate?: Maybe<Ura_RegAssociates_Aggregate_Fields>;
  nodes: Array<Ura_RegAssociates>;
};

/** aggregate fields of "ura.RegAssociates" */
export type Ura_RegAssociates_Aggregate_Fields = {
  __typename?: "ura_RegAssociates_aggregate_fields";
  avg?: Maybe<Ura_RegAssociates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RegAssociates_Max_Fields>;
  min?: Maybe<Ura_RegAssociates_Min_Fields>;
  stddev?: Maybe<Ura_RegAssociates_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RegAssociates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RegAssociates_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RegAssociates_Sum_Fields>;
  var_pop?: Maybe<Ura_RegAssociates_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RegAssociates_Var_Samp_Fields>;
  variance?: Maybe<Ura_RegAssociates_Variance_Fields>;
};

/** aggregate fields of "ura.RegAssociates" */
export type Ura_RegAssociates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RegAssociates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RegAssociates" */
export type Ura_RegAssociates_Aggregate_Order_By = {
  avg?: Maybe<Ura_RegAssociates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RegAssociates_Max_Order_By>;
  min?: Maybe<Ura_RegAssociates_Min_Order_By>;
  stddev?: Maybe<Ura_RegAssociates_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RegAssociates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RegAssociates_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RegAssociates_Sum_Order_By>;
  var_pop?: Maybe<Ura_RegAssociates_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RegAssociates_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RegAssociates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RegAssociates" */
export type Ura_RegAssociates_Arr_Rel_Insert_Input = {
  data: Array<Ura_RegAssociates_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RegAssociates_Avg_Fields = {
  __typename?: "ura_RegAssociates_avg_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Avg_Order_By = {
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RegAssociates". All fields are combined with a logical 'AND'. */
export type Ura_RegAssociates_Bool_Exp = {
  ASSOCIATE_TIN?: Maybe<String_Comparison_Exp>;
  ENTITY_DESIGNATION?: Maybe<String_Comparison_Exp>;
  FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  FIRST_NAME?: Maybe<String_Comparison_Exp>;
  OTHER_NAME?: Maybe<String_Comparison_Exp>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<String_Comparison_Exp>;
  SURNAME?: Maybe<String_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RegAssociates_Bool_Exp>>>;
  _not?: Maybe<Ura_RegAssociates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RegAssociates_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RegAssociates" */
export type Ura_RegAssociates_Inc_Input = {
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RegAssociates" */
export type Ura_RegAssociates_Insert_Input = {
  ASSOCIATE_TIN?: Maybe<Scalars["String"]>;
  ENTITY_DESIGNATION?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Scalars["String"]>;
  SURNAME?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RegAssociates_Max_Fields = {
  __typename?: "ura_RegAssociates_max_fields";
  ASSOCIATE_TIN?: Maybe<Scalars["String"]>;
  ENTITY_DESIGNATION?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Scalars["String"]>;
  SURNAME?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Max_Order_By = {
  ASSOCIATE_TIN?: Maybe<Order_By>;
  ENTITY_DESIGNATION?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Order_By>;
  SURNAME?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RegAssociates_Min_Fields = {
  __typename?: "ura_RegAssociates_min_fields";
  ASSOCIATE_TIN?: Maybe<Scalars["String"]>;
  ENTITY_DESIGNATION?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Scalars["String"]>;
  SURNAME?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Min_Order_By = {
  ASSOCIATE_TIN?: Maybe<Order_By>;
  ENTITY_DESIGNATION?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Order_By>;
  SURNAME?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RegAssociates" */
export type Ura_RegAssociates_Mutation_Response = {
  __typename?: "ura_RegAssociates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RegAssociates>;
};

/** input type for inserting object relation for remote table "ura.RegAssociates" */
export type Ura_RegAssociates_Obj_Rel_Insert_Input = {
  data: Ura_RegAssociates_Insert_Input;
};

/** ordering options when selecting data from "ura.RegAssociates" */
export type Ura_RegAssociates_Order_By = {
  ASSOCIATE_TIN?: Maybe<Order_By>;
  ENTITY_DESIGNATION?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Order_By>;
  SURNAME?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RegAssociates" */
export enum Ura_RegAssociates_Select_Column {
  /** column name */
  AssociateTin = "ASSOCIATE_TIN",
  /** column name */
  EntityDesignation = "ENTITY_DESIGNATION",
  /** column name */
  FamilyName = "FAMILY_NAME",
  /** column name */
  FirstName = "FIRST_NAME",
  /** column name */
  OtherName = "OTHER_NAME",
  /** column name */
  RegAssociatedEntityDtlId = "REG_ASSOCIATED_ENTITY_DTL_ID",
  /** column name */
  Surname = "SURNAME",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RegAssociates" */
export type Ura_RegAssociates_Set_Input = {
  ASSOCIATE_TIN?: Maybe<Scalars["String"]>;
  ENTITY_DESIGNATION?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  REG_ASSOCIATED_ENTITY_DTL_ID?: Maybe<Scalars["String"]>;
  SURNAME?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RegAssociates_Stddev_Fields = {
  __typename?: "ura_RegAssociates_stddev_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Stddev_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RegAssociates_Stddev_Pop_Fields = {
  __typename?: "ura_RegAssociates_stddev_pop_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Stddev_Pop_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RegAssociates_Stddev_Samp_Fields = {
  __typename?: "ura_RegAssociates_stddev_samp_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Stddev_Samp_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RegAssociates_Sum_Fields = {
  __typename?: "ura_RegAssociates_sum_fields";
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Sum_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RegAssociates_Var_Pop_Fields = {
  __typename?: "ura_RegAssociates_var_pop_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Var_Pop_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RegAssociates_Var_Samp_Fields = {
  __typename?: "ura_RegAssociates_var_samp_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Var_Samp_Order_By = {
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RegAssociates_Variance_Fields = {
  __typename?: "ura_RegAssociates_variance_fields";
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RegAssociates" */
export type Ura_RegAssociates_Variance_Order_By = {
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RegIndividual" */
export type Ura_RegIndividual = {
  __typename?: "ura_RegIndividual";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["date"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RegIndividual" */
export type Ura_RegIndividual_Aggregate = {
  __typename?: "ura_RegIndividual_aggregate";
  aggregate?: Maybe<Ura_RegIndividual_Aggregate_Fields>;
  nodes: Array<Ura_RegIndividual>;
};

/** aggregate fields of "ura.RegIndividual" */
export type Ura_RegIndividual_Aggregate_Fields = {
  __typename?: "ura_RegIndividual_aggregate_fields";
  avg?: Maybe<Ura_RegIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RegIndividual_Max_Fields>;
  min?: Maybe<Ura_RegIndividual_Min_Fields>;
  stddev?: Maybe<Ura_RegIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RegIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RegIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RegIndividual_Sum_Fields>;
  var_pop?: Maybe<Ura_RegIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RegIndividual_Var_Samp_Fields>;
  variance?: Maybe<Ura_RegIndividual_Variance_Fields>;
};

/** aggregate fields of "ura.RegIndividual" */
export type Ura_RegIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RegIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RegIndividual" */
export type Ura_RegIndividual_Aggregate_Order_By = {
  avg?: Maybe<Ura_RegIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RegIndividual_Max_Order_By>;
  min?: Maybe<Ura_RegIndividual_Min_Order_By>;
  stddev?: Maybe<Ura_RegIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RegIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RegIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RegIndividual_Sum_Order_By>;
  var_pop?: Maybe<Ura_RegIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RegIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RegIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RegIndividual" */
export type Ura_RegIndividual_Arr_Rel_Insert_Input = {
  data: Array<Ura_RegIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RegIndividual_Avg_Fields = {
  __typename?: "ura_RegIndividual_avg_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Avg_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RegIndividual". All fields are combined with a logical 'AND'. */
export type Ura_RegIndividual_Bool_Exp = {
  APPLIED_FOR_TIN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_SURNAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_TIN?: Maybe<String_Comparison_Exp>;
  BIRTH_CITY?: Maybe<Float8_Comparison_Exp>;
  BIRTH_COUNTRY?: Maybe<String_Comparison_Exp>;
  BIRTH_DT?: Maybe<Date_Comparison_Exp>;
  BIRTH_STATE?: Maybe<String_Comparison_Exp>;
  BSNS_NAME?: Maybe<String_Comparison_Exp>;
  CITIZEN_COUNTRY?: Maybe<String_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  EXIST_TIN_NO?: Maybe<String_Comparison_Exp>;
  FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  FIRST_NAME?: Maybe<String_Comparison_Exp>;
  GENDER?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_BUILDINGNAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_PARISH_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_PLOTNO?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_STRRET_ADDR?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_TOWN_NAME?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<String_Comparison_Exp>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_EMP?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  NATIONAL_ID?: Maybe<String_Comparison_Exp>;
  NSSF_ID?: Maybe<String_Comparison_Exp>;
  OTHER_NAME?: Maybe<String_Comparison_Exp>;
  POSTADDR_DISTICT_NAME?: Maybe<String_Comparison_Exp>;
  POSTADDR_ID?: Maybe<String_Comparison_Exp>;
  REF_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  REF_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  REF_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  REF_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  REF_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  REF_SURNAME?: Maybe<String_Comparison_Exp>;
  REF_TIN?: Maybe<String_Comparison_Exp>;
  SUR_NAME?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_ID?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_NAME?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_TYPE?: Maybe<String_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  Visible_Level?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RegIndividual_Bool_Exp>>>;
  _not?: Maybe<Ura_RegIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RegIndividual_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RegIndividual" */
export type Ura_RegIndividual_Inc_Input = {
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RegIndividual" */
export type Ura_RegIndividual_Insert_Input = {
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["date"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RegIndividual_Max_Fields = {
  __typename?: "ura_RegIndividual_max_fields";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["date"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Max_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RegIndividual_Min_Fields = {
  __typename?: "ura_RegIndividual_min_fields";
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["date"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Min_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RegIndividual" */
export type Ura_RegIndividual_Mutation_Response = {
  __typename?: "ura_RegIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RegIndividual>;
};

/** input type for inserting object relation for remote table "ura.RegIndividual" */
export type Ura_RegIndividual_Obj_Rel_Insert_Input = {
  data: Ura_RegIndividual_Insert_Input;
};

/** ordering options when selecting data from "ura.RegIndividual" */
export type Ura_RegIndividual_Order_By = {
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  AUTHCTCD_TIN?: Maybe<Order_By>;
  BIRTH_CITY?: Maybe<Order_By>;
  BIRTH_COUNTRY?: Maybe<Order_By>;
  BIRTH_DT?: Maybe<Order_By>;
  BIRTH_STATE?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  CITIZEN_COUNTRY?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  FAMILY_NAME?: Maybe<Order_By>;
  FIRST_NAME?: Maybe<Order_By>;
  GENDER?: Maybe<Order_By>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Order_By>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Order_By>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  HOME_ADDR_PARISH_NAME?: Maybe<Order_By>;
  HOME_ADDR_PLOTNO?: Maybe<Order_By>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Order_By>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  HOME_ADDR_TOWN_NAME?: Maybe<Order_By>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Order_By>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_EMP?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MIDDLE_NAME?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  NATIONAL_ID?: Maybe<Order_By>;
  NSSF_ID?: Maybe<Order_By>;
  OTHER_NAME?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  SUR_NAME?: Maybe<Order_By>;
  TAX_PAYER_ID?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RegIndividual" */
export enum Ura_RegIndividual_Select_Column {
  /** column name */
  AppliedForTin = "APPLIED_FOR_TIN",
  /** column name */
  AuthctcdEmailId = "AUTHCTCD_EMAIL_ID",
  /** column name */
  AuthctcdFamilyName = "AUTHCTCD_FAMILY_NAME",
  /** column name */
  AuthctcdFirstName = "AUTHCTCD_FIRST_NAME",
  /** column name */
  AuthctcdLandlineNumber = "AUTHCTCD_LANDLINE_NUMBER",
  /** column name */
  AuthctcdMobileNumber = "AUTHCTCD_MOBILE_NUMBER",
  /** column name */
  AuthctcdOtherName = "AUTHCTCD_OTHER_NAME",
  /** column name */
  AuthctcdPrsnDesgn = "AUTHCTCD_PRSN_DESGN",
  /** column name */
  AuthctcdSurname = "AUTHCTCD_SURNAME",
  /** column name */
  AuthctcdTin = "AUTHCTCD_TIN",
  /** column name */
  BirthCity = "BIRTH_CITY",
  /** column name */
  BirthCountry = "BIRTH_COUNTRY",
  /** column name */
  BirthDt = "BIRTH_DT",
  /** column name */
  BirthState = "BIRTH_STATE",
  /** column name */
  BsnsName = "BSNS_NAME",
  /** column name */
  CitizenCountry = "CITIZEN_COUNTRY",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  ExistTinNo = "EXIST_TIN_NO",
  /** column name */
  FamilyName = "FAMILY_NAME",
  /** column name */
  FirstName = "FIRST_NAME",
  /** column name */
  Gender = "GENDER",
  /** column name */
  HomeAddrBuildingname = "HOME_ADDR_BUILDINGNAME",
  /** column name */
  HomeAddrDistrictName = "HOME_ADDR_DISTRICT_NAME",
  /** column name */
  HomeAddrLocalCouncil = "HOME_ADDR_LOCAL_COUNCIL",
  /** column name */
  HomeAddrParishName = "HOME_ADDR_PARISH_NAME",
  /** column name */
  HomeAddrPlotno = "HOME_ADDR_PLOTNO",
  /** column name */
  HomeAddrStrretAddr = "HOME_ADDR_STRRET_ADDR",
  /** column name */
  HomeAddrSubCountyName = "HOME_ADDR_SUB_COUNTY_NAME",
  /** column name */
  HomeAddrTownName = "HOME_ADDR_TOWN_NAME",
  /** column name */
  HomeAddrTradeCentre = "HOME_ADDR_TRADE_CENTRE",
  /** column name */
  HomeAddrVillageName = "HOME_ADDR_VILLAGE_NAME",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcEmp = "IS_INCOME_SRC_EMP",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  LandlineNumber = "LANDLINE_NUMBER",
  /** column name */
  MiddleName = "MIDDLE_NAME",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  NationalId = "NATIONAL_ID",
  /** column name */
  NssfId = "NSSF_ID",
  /** column name */
  OtherName = "OTHER_NAME",
  /** column name */
  PostaddrDistictName = "POSTADDR_DISTICT_NAME",
  /** column name */
  PostaddrId = "POSTADDR_ID",
  /** column name */
  RefEmailId = "REF_EMAIL_ID",
  /** column name */
  RefFamilyName = "REF_FAMILY_NAME",
  /** column name */
  RefFirstName = "REF_FIRST_NAME",
  /** column name */
  RefLandlineNumber = "REF_LANDLINE_NUMBER",
  /** column name */
  RefMobileNumber = "REF_MOBILE_NUMBER",
  /** column name */
  RefOtherName = "REF_OTHER_NAME",
  /** column name */
  RefPrsnDesgn = "REF_PRSN_DESGN",
  /** column name */
  RefSurname = "REF_SURNAME",
  /** column name */
  RefTin = "REF_TIN",
  /** column name */
  SurName = "SUR_NAME",
  /** column name */
  TaxPayerId = "TAX_PAYER_ID",
  /** column name */
  TaxPayerName = "TAX_PAYER_NAME",
  /** column name */
  TaxPayerType = "TAX_PAYER_TYPE",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  VisibleLevel = "Visible_Level",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RegIndividual" */
export type Ura_RegIndividual_Set_Input = {
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_TIN?: Maybe<Scalars["String"]>;
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  BIRTH_COUNTRY?: Maybe<Scalars["String"]>;
  BIRTH_DT?: Maybe<Scalars["date"]>;
  BIRTH_STATE?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  CITIZEN_COUNTRY?: Maybe<Scalars["String"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  FAMILY_NAME?: Maybe<Scalars["String"]>;
  FIRST_NAME?: Maybe<Scalars["String"]>;
  GENDER?: Maybe<Scalars["String"]>;
  HOME_ADDR_BUILDINGNAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  HOME_ADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_PLOTNO?: Maybe<Scalars["String"]>;
  HOME_ADDR_STRRET_ADDR?: Maybe<Scalars["String"]>;
  HOME_ADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  HOME_ADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  HOME_ADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_EMP?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  MIDDLE_NAME?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  NATIONAL_ID?: Maybe<Scalars["String"]>;
  NSSF_ID?: Maybe<Scalars["String"]>;
  OTHER_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  SUR_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_ID?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RegIndividual_Stddev_Fields = {
  __typename?: "ura_RegIndividual_stddev_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Stddev_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RegIndividual_Stddev_Pop_Fields = {
  __typename?: "ura_RegIndividual_stddev_pop_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Stddev_Pop_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RegIndividual_Stddev_Samp_Fields = {
  __typename?: "ura_RegIndividual_stddev_samp_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Stddev_Samp_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RegIndividual_Sum_Fields = {
  __typename?: "ura_RegIndividual_sum_fields";
  BIRTH_CITY?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Sum_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RegIndividual_Var_Pop_Fields = {
  __typename?: "ura_RegIndividual_var_pop_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Var_Pop_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RegIndividual_Var_Samp_Fields = {
  __typename?: "ura_RegIndividual_var_samp_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Var_Samp_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RegIndividual_Variance_Fields = {
  __typename?: "ura_RegIndividual_variance_fields";
  BIRTH_CITY?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RegIndividual" */
export type Ura_RegIndividual_Variance_Order_By = {
  BIRTH_CITY?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RegNonIndividual" */
export type Ura_RegNonIndividual = {
  __typename?: "ura_RegNonIndividual";
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["date"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["String"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Aggregate = {
  __typename?: "ura_RegNonIndividual_aggregate";
  aggregate?: Maybe<Ura_RegNonIndividual_Aggregate_Fields>;
  nodes: Array<Ura_RegNonIndividual>;
};

/** aggregate fields of "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Aggregate_Fields = {
  __typename?: "ura_RegNonIndividual_aggregate_fields";
  avg?: Maybe<Ura_RegNonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RegNonIndividual_Max_Fields>;
  min?: Maybe<Ura_RegNonIndividual_Min_Fields>;
  stddev?: Maybe<Ura_RegNonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RegNonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RegNonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RegNonIndividual_Sum_Fields>;
  var_pop?: Maybe<Ura_RegNonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RegNonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Ura_RegNonIndividual_Variance_Fields>;
};

/** aggregate fields of "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RegNonIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Ura_RegNonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RegNonIndividual_Max_Order_By>;
  min?: Maybe<Ura_RegNonIndividual_Min_Order_By>;
  stddev?: Maybe<Ura_RegNonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RegNonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RegNonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RegNonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Ura_RegNonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RegNonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RegNonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Arr_Rel_Insert_Input = {
  data: Array<Ura_RegNonIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RegNonIndividual_Avg_Fields = {
  __typename?: "ura_RegNonIndividual_avg_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Avg_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RegNonIndividual". All fields are combined with a logical 'AND'. */
export type Ura_RegNonIndividual_Bool_Exp = {
  ACTVTY_DESC?: Maybe<String_Comparison_Exp>;
  APPLIED_FOR_TIN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  AUTHCTCD_SURNAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_BUILDNAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_DISTRICT_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<String_Comparison_Exp>;
  BSNSADDR_PARISH_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_PLOT_NO?: Maybe<String_Comparison_Exp>;
  BSNSADDR_STREET_ADDR?: Maybe<String_Comparison_Exp>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_TOWN_NAME?: Maybe<String_Comparison_Exp>;
  BSNSADDR_TRADE_CENTRE?: Maybe<String_Comparison_Exp>;
  BSNSADDR_VILLAGE_NAME?: Maybe<String_Comparison_Exp>;
  BSNS_CERT_NUMBER?: Maybe<String_Comparison_Exp>;
  BSNS_NAME?: Maybe<String_Comparison_Exp>;
  BUSINESS_START_DT?: Maybe<Date_Comparison_Exp>;
  EMAIL_ID?: Maybe<String_Comparison_Exp>;
  EXIST_TIN_NO?: Maybe<String_Comparison_Exp>;
  HOLDING_COMP_NAME?: Maybe<String_Comparison_Exp>;
  HOLDING_COMP_TIN?: Maybe<String_Comparison_Exp>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_BSNS?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_PRPTY?: Maybe<String_Comparison_Exp>;
  IS_INCOME_SRC_RENTAL?: Maybe<String_Comparison_Exp>;
  IS_SUBSIDARY_COMP?: Maybe<String_Comparison_Exp>;
  LANDLINE_NUMBER?: Maybe<Float8_Comparison_Exp>;
  MAIN_ACTIVITY?: Maybe<String_Comparison_Exp>;
  MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  ORG_SUB_TYPE?: Maybe<String_Comparison_Exp>;
  ORG_SUB_TYPE_DESC?: Maybe<String_Comparison_Exp>;
  ORG_TYPE?: Maybe<String_Comparison_Exp>;
  POSTADDR_DISTICT_NAME?: Maybe<String_Comparison_Exp>;
  POSTADDR_ID?: Maybe<String_Comparison_Exp>;
  REF_EMAIL_ID?: Maybe<String_Comparison_Exp>;
  REF_FAMILY_NAME?: Maybe<String_Comparison_Exp>;
  REF_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  REF_LANDLINE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_MIDDLE_NAME?: Maybe<String_Comparison_Exp>;
  REF_MOBILE_NUMBER?: Maybe<String_Comparison_Exp>;
  REF_OTHER_NAME?: Maybe<String_Comparison_Exp>;
  REF_PRSN_DESGN?: Maybe<String_Comparison_Exp>;
  REF_SURNAME?: Maybe<String_Comparison_Exp>;
  REF_TIN?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_NAME?: Maybe<String_Comparison_Exp>;
  TAX_PAYER_TYPE?: Maybe<String_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  TRADING_NAME?: Maybe<String_Comparison_Exp>;
  Visible_Level?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RegNonIndividual_Bool_Exp>>>;
  _not?: Maybe<Ura_RegNonIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RegNonIndividual_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Inc_Input = {
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Insert_Input = {
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["date"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["String"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RegNonIndividual_Max_Fields = {
  __typename?: "ura_RegNonIndividual_max_fields";
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["date"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["String"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Max_Order_By = {
  ACTVTY_DESC?: Maybe<Order_By>;
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  BSNSADDR_BUILDNAME?: Maybe<Order_By>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Order_By>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  BSNSADDR_PARISH_NAME?: Maybe<Order_By>;
  BSNSADDR_PLOT_NO?: Maybe<Order_By>;
  BSNSADDR_STREET_ADDR?: Maybe<Order_By>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  BSNSADDR_TOWN_NAME?: Maybe<Order_By>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Order_By>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Order_By>;
  BSNS_CERT_NUMBER?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  BUSINESS_START_DT?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  HOLDING_COMP_NAME?: Maybe<Order_By>;
  HOLDING_COMP_TIN?: Maybe<Order_By>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_SUBSIDARY_COMP?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MAIN_ACTIVITY?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  ORG_SUB_TYPE?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  ORG_TYPE?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MIDDLE_NAME?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TRADING_NAME?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RegNonIndividual_Min_Fields = {
  __typename?: "ura_RegNonIndividual_min_fields";
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["date"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["String"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Min_Order_By = {
  ACTVTY_DESC?: Maybe<Order_By>;
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  BSNSADDR_BUILDNAME?: Maybe<Order_By>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Order_By>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  BSNSADDR_PARISH_NAME?: Maybe<Order_By>;
  BSNSADDR_PLOT_NO?: Maybe<Order_By>;
  BSNSADDR_STREET_ADDR?: Maybe<Order_By>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  BSNSADDR_TOWN_NAME?: Maybe<Order_By>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Order_By>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Order_By>;
  BSNS_CERT_NUMBER?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  BUSINESS_START_DT?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  HOLDING_COMP_NAME?: Maybe<Order_By>;
  HOLDING_COMP_TIN?: Maybe<Order_By>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_SUBSIDARY_COMP?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MAIN_ACTIVITY?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  ORG_SUB_TYPE?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  ORG_TYPE?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MIDDLE_NAME?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TRADING_NAME?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Mutation_Response = {
  __typename?: "ura_RegNonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RegNonIndividual>;
};

/** input type for inserting object relation for remote table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Obj_Rel_Insert_Input = {
  data: Ura_RegNonIndividual_Insert_Input;
};

/** ordering options when selecting data from "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Order_By = {
  ACTVTY_DESC?: Maybe<Order_By>;
  APPLIED_FOR_TIN?: Maybe<Order_By>;
  AUTHCTCD_EMAIL_ID?: Maybe<Order_By>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Order_By>;
  AUTHCTCD_FIRST_NAME?: Maybe<Order_By>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Order_By>;
  AUTHCTCD_OTHER_NAME?: Maybe<Order_By>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Order_By>;
  AUTHCTCD_SURNAME?: Maybe<Order_By>;
  BSNSADDR_BUILDNAME?: Maybe<Order_By>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Order_By>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Order_By>;
  BSNSADDR_PARISH_NAME?: Maybe<Order_By>;
  BSNSADDR_PLOT_NO?: Maybe<Order_By>;
  BSNSADDR_STREET_ADDR?: Maybe<Order_By>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Order_By>;
  BSNSADDR_TOWN_NAME?: Maybe<Order_By>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Order_By>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Order_By>;
  BSNS_CERT_NUMBER?: Maybe<Order_By>;
  BSNS_NAME?: Maybe<Order_By>;
  BUSINESS_START_DT?: Maybe<Order_By>;
  EMAIL_ID?: Maybe<Order_By>;
  EXIST_TIN_NO?: Maybe<Order_By>;
  HOLDING_COMP_NAME?: Maybe<Order_By>;
  HOLDING_COMP_TIN?: Maybe<Order_By>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Order_By>;
  IS_INCOME_SRC_BSNS?: Maybe<Order_By>;
  IS_INCOME_SRC_PRPTY?: Maybe<Order_By>;
  IS_INCOME_SRC_RENTAL?: Maybe<Order_By>;
  IS_SUBSIDARY_COMP?: Maybe<Order_By>;
  LANDLINE_NUMBER?: Maybe<Order_By>;
  MAIN_ACTIVITY?: Maybe<Order_By>;
  MOBILE_NUMBER?: Maybe<Order_By>;
  ORG_SUB_TYPE?: Maybe<Order_By>;
  ORG_SUB_TYPE_DESC?: Maybe<Order_By>;
  ORG_TYPE?: Maybe<Order_By>;
  POSTADDR_DISTICT_NAME?: Maybe<Order_By>;
  POSTADDR_ID?: Maybe<Order_By>;
  REF_EMAIL_ID?: Maybe<Order_By>;
  REF_FAMILY_NAME?: Maybe<Order_By>;
  REF_FIRST_NAME?: Maybe<Order_By>;
  REF_LANDLINE_NUMBER?: Maybe<Order_By>;
  REF_MIDDLE_NAME?: Maybe<Order_By>;
  REF_MOBILE_NUMBER?: Maybe<Order_By>;
  REF_OTHER_NAME?: Maybe<Order_By>;
  REF_PRSN_DESGN?: Maybe<Order_By>;
  REF_SURNAME?: Maybe<Order_By>;
  REF_TIN?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TAX_PAYER_TYPE?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  TRADING_NAME?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RegNonIndividual" */
export enum Ura_RegNonIndividual_Select_Column {
  /** column name */
  ActvtyDesc = "ACTVTY_DESC",
  /** column name */
  AppliedForTin = "APPLIED_FOR_TIN",
  /** column name */
  AuthctcdEmailId = "AUTHCTCD_EMAIL_ID",
  /** column name */
  AuthctcdFamilyName = "AUTHCTCD_FAMILY_NAME",
  /** column name */
  AuthctcdFirstName = "AUTHCTCD_FIRST_NAME",
  /** column name */
  AuthctcdLandlineNumber = "AUTHCTCD_LANDLINE_NUMBER",
  /** column name */
  AuthctcdMobileNumber = "AUTHCTCD_MOBILE_NUMBER",
  /** column name */
  AuthctcdOtherName = "AUTHCTCD_OTHER_NAME",
  /** column name */
  AuthctcdPrsnDesgn = "AUTHCTCD_PRSN_DESGN",
  /** column name */
  AuthctcdSurname = "AUTHCTCD_SURNAME",
  /** column name */
  BsnsaddrBuildname = "BSNSADDR_BUILDNAME",
  /** column name */
  BsnsaddrDistrictName = "BSNSADDR_DISTRICT_NAME",
  /** column name */
  BsnsaddrLocalCouncil = "BSNSADDR_LOCAL_COUNCIL",
  /** column name */
  BsnsaddrParishName = "BSNSADDR_PARISH_NAME",
  /** column name */
  BsnsaddrPlotNo = "BSNSADDR_PLOT_NO",
  /** column name */
  BsnsaddrStreetAddr = "BSNSADDR_STREET_ADDR",
  /** column name */
  BsnsaddrSubCountyName = "BSNSADDR_SUB_COUNTY_NAME",
  /** column name */
  BsnsaddrTownName = "BSNSADDR_TOWN_NAME",
  /** column name */
  BsnsaddrTradeCentre = "BSNSADDR_TRADE_CENTRE",
  /** column name */
  BsnsaddrVillageName = "BSNSADDR_VILLAGE_NAME",
  /** column name */
  BsnsCertNumber = "BSNS_CERT_NUMBER",
  /** column name */
  BsnsName = "BSNS_NAME",
  /** column name */
  BusinessStartDt = "BUSINESS_START_DT",
  /** column name */
  EmailId = "EMAIL_ID",
  /** column name */
  ExistTinNo = "EXIST_TIN_NO",
  /** column name */
  HoldingCompName = "HOLDING_COMP_NAME",
  /** column name */
  HoldingCompTin = "HOLDING_COMP_TIN",
  /** column name */
  IsHldCmpInUganda = "IS_HLD_CMP_IN_UGANDA",
  /** column name */
  IsIncomeSrcBsns = "IS_INCOME_SRC_BSNS",
  /** column name */
  IsIncomeSrcPrpty = "IS_INCOME_SRC_PRPTY",
  /** column name */
  IsIncomeSrcRental = "IS_INCOME_SRC_RENTAL",
  /** column name */
  IsSubsidaryComp = "IS_SUBSIDARY_COMP",
  /** column name */
  LandlineNumber = "LANDLINE_NUMBER",
  /** column name */
  MainActivity = "MAIN_ACTIVITY",
  /** column name */
  MobileNumber = "MOBILE_NUMBER",
  /** column name */
  OrgSubType = "ORG_SUB_TYPE",
  /** column name */
  OrgSubTypeDesc = "ORG_SUB_TYPE_DESC",
  /** column name */
  OrgType = "ORG_TYPE",
  /** column name */
  PostaddrDistictName = "POSTADDR_DISTICT_NAME",
  /** column name */
  PostaddrId = "POSTADDR_ID",
  /** column name */
  RefEmailId = "REF_EMAIL_ID",
  /** column name */
  RefFamilyName = "REF_FAMILY_NAME",
  /** column name */
  RefFirstName = "REF_FIRST_NAME",
  /** column name */
  RefLandlineNumber = "REF_LANDLINE_NUMBER",
  /** column name */
  RefMiddleName = "REF_MIDDLE_NAME",
  /** column name */
  RefMobileNumber = "REF_MOBILE_NUMBER",
  /** column name */
  RefOtherName = "REF_OTHER_NAME",
  /** column name */
  RefPrsnDesgn = "REF_PRSN_DESGN",
  /** column name */
  RefSurname = "REF_SURNAME",
  /** column name */
  RefTin = "REF_TIN",
  /** column name */
  TaxPayerName = "TAX_PAYER_NAME",
  /** column name */
  TaxPayerType = "TAX_PAYER_TYPE",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  TradingName = "TRADING_NAME",
  /** column name */
  VisibleLevel = "Visible_Level",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Set_Input = {
  ACTVTY_DESC?: Maybe<Scalars["String"]>;
  APPLIED_FOR_TIN?: Maybe<Scalars["String"]>;
  AUTHCTCD_EMAIL_ID?: Maybe<Scalars["String"]>;
  AUTHCTCD_FAMILY_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_FIRST_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  AUTHCTCD_OTHER_NAME?: Maybe<Scalars["String"]>;
  AUTHCTCD_PRSN_DESGN?: Maybe<Scalars["String"]>;
  AUTHCTCD_SURNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_BUILDNAME?: Maybe<Scalars["String"]>;
  BSNSADDR_DISTRICT_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_LOCAL_COUNCIL?: Maybe<Scalars["String"]>;
  BSNSADDR_PARISH_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_PLOT_NO?: Maybe<Scalars["String"]>;
  BSNSADDR_STREET_ADDR?: Maybe<Scalars["String"]>;
  BSNSADDR_SUB_COUNTY_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TOWN_NAME?: Maybe<Scalars["String"]>;
  BSNSADDR_TRADE_CENTRE?: Maybe<Scalars["String"]>;
  BSNSADDR_VILLAGE_NAME?: Maybe<Scalars["String"]>;
  BSNS_CERT_NUMBER?: Maybe<Scalars["String"]>;
  BSNS_NAME?: Maybe<Scalars["String"]>;
  BUSINESS_START_DT?: Maybe<Scalars["date"]>;
  EMAIL_ID?: Maybe<Scalars["String"]>;
  EXIST_TIN_NO?: Maybe<Scalars["String"]>;
  HOLDING_COMP_NAME?: Maybe<Scalars["String"]>;
  HOLDING_COMP_TIN?: Maybe<Scalars["String"]>;
  IS_HLD_CMP_IN_UGANDA?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_BSNS?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_PRPTY?: Maybe<Scalars["String"]>;
  IS_INCOME_SRC_RENTAL?: Maybe<Scalars["String"]>;
  IS_SUBSIDARY_COMP?: Maybe<Scalars["String"]>;
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  MAIN_ACTIVITY?: Maybe<Scalars["String"]>;
  MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE?: Maybe<Scalars["String"]>;
  ORG_SUB_TYPE_DESC?: Maybe<Scalars["String"]>;
  ORG_TYPE?: Maybe<Scalars["String"]>;
  POSTADDR_DISTICT_NAME?: Maybe<Scalars["String"]>;
  POSTADDR_ID?: Maybe<Scalars["String"]>;
  REF_EMAIL_ID?: Maybe<Scalars["String"]>;
  REF_FAMILY_NAME?: Maybe<Scalars["String"]>;
  REF_FIRST_NAME?: Maybe<Scalars["String"]>;
  REF_LANDLINE_NUMBER?: Maybe<Scalars["String"]>;
  REF_MIDDLE_NAME?: Maybe<Scalars["String"]>;
  REF_MOBILE_NUMBER?: Maybe<Scalars["String"]>;
  REF_OTHER_NAME?: Maybe<Scalars["String"]>;
  REF_PRSN_DESGN?: Maybe<Scalars["String"]>;
  REF_SURNAME?: Maybe<Scalars["String"]>;
  REF_TIN?: Maybe<Scalars["String"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TAX_PAYER_TYPE?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  TRADING_NAME?: Maybe<Scalars["String"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RegNonIndividual_Stddev_Fields = {
  __typename?: "ura_RegNonIndividual_stddev_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Stddev_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RegNonIndividual_Stddev_Pop_Fields = {
  __typename?: "ura_RegNonIndividual_stddev_pop_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Stddev_Pop_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RegNonIndividual_Stddev_Samp_Fields = {
  __typename?: "ura_RegNonIndividual_stddev_samp_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Stddev_Samp_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RegNonIndividual_Sum_Fields = {
  __typename?: "ura_RegNonIndividual_sum_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["float8"]>;
  Visible_Level?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Sum_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RegNonIndividual_Var_Pop_Fields = {
  __typename?: "ura_RegNonIndividual_var_pop_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Var_Pop_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RegNonIndividual_Var_Samp_Fields = {
  __typename?: "ura_RegNonIndividual_var_samp_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Var_Samp_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RegNonIndividual_Variance_Fields = {
  __typename?: "ura_RegNonIndividual_variance_fields";
  LANDLINE_NUMBER?: Maybe<Scalars["Float"]>;
  Visible_Level?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RegNonIndividual" */
export type Ura_RegNonIndividual_Variance_Order_By = {
  LANDLINE_NUMBER?: Maybe<Order_By>;
  Visible_Level?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails = {
  __typename?: "ura_RentExpenseDetails";
  AMOUNT?: Maybe<Scalars["float8"]>;
  EXPENSETYPE?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Aggregate = {
  __typename?: "ura_RentExpenseDetails_aggregate";
  aggregate?: Maybe<Ura_RentExpenseDetails_Aggregate_Fields>;
  nodes: Array<Ura_RentExpenseDetails>;
};

/** aggregate fields of "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Aggregate_Fields = {
  __typename?: "ura_RentExpenseDetails_aggregate_fields";
  avg?: Maybe<Ura_RentExpenseDetails_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RentExpenseDetails_Max_Fields>;
  min?: Maybe<Ura_RentExpenseDetails_Min_Fields>;
  stddev?: Maybe<Ura_RentExpenseDetails_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RentExpenseDetails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RentExpenseDetails_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RentExpenseDetails_Sum_Fields>;
  var_pop?: Maybe<Ura_RentExpenseDetails_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RentExpenseDetails_Var_Samp_Fields>;
  variance?: Maybe<Ura_RentExpenseDetails_Variance_Fields>;
};

/** aggregate fields of "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RentExpenseDetails_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Aggregate_Order_By = {
  avg?: Maybe<Ura_RentExpenseDetails_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RentExpenseDetails_Max_Order_By>;
  min?: Maybe<Ura_RentExpenseDetails_Min_Order_By>;
  stddev?: Maybe<Ura_RentExpenseDetails_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RentExpenseDetails_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RentExpenseDetails_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RentExpenseDetails_Sum_Order_By>;
  var_pop?: Maybe<Ura_RentExpenseDetails_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RentExpenseDetails_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RentExpenseDetails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Arr_Rel_Insert_Input = {
  data: Array<Ura_RentExpenseDetails_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RentExpenseDetails_Avg_Fields = {
  __typename?: "ura_RentExpenseDetails_avg_fields";
  AMOUNT?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Avg_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RentExpenseDetails". All fields are combined with a logical 'AND'. */
export type Ura_RentExpenseDetails_Bool_Exp = {
  AMOUNT?: Maybe<Float8_Comparison_Exp>;
  EXPENSETYPE?: Maybe<String_Comparison_Exp>;
  NAME?: Maybe<String_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RentExpenseDetails_Bool_Exp>>>;
  _not?: Maybe<Ura_RentExpenseDetails_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RentExpenseDetails_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Inc_Input = {
  AMOUNT?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Insert_Input = {
  AMOUNT?: Maybe<Scalars["float8"]>;
  EXPENSETYPE?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RentExpenseDetails_Max_Fields = {
  __typename?: "ura_RentExpenseDetails_max_fields";
  AMOUNT?: Maybe<Scalars["float8"]>;
  EXPENSETYPE?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Max_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  EXPENSETYPE?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RentExpenseDetails_Min_Fields = {
  __typename?: "ura_RentExpenseDetails_min_fields";
  AMOUNT?: Maybe<Scalars["float8"]>;
  EXPENSETYPE?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Min_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  EXPENSETYPE?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Mutation_Response = {
  __typename?: "ura_RentExpenseDetails_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RentExpenseDetails>;
};

/** input type for inserting object relation for remote table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Obj_Rel_Insert_Input = {
  data: Ura_RentExpenseDetails_Insert_Input;
};

/** ordering options when selecting data from "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  EXPENSETYPE?: Maybe<Order_By>;
  NAME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RentExpenseDetails" */
export enum Ura_RentExpenseDetails_Select_Column {
  /** column name */
  Amount = "AMOUNT",
  /** column name */
  Expensetype = "EXPENSETYPE",
  /** column name */
  Name = "NAME",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnOtherExpnsDtlId = "RTN_OTHER_EXPNS_DTL_ID",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Set_Input = {
  AMOUNT?: Maybe<Scalars["float8"]>;
  EXPENSETYPE?: Maybe<Scalars["String"]>;
  NAME?: Maybe<Scalars["String"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_OTHER_EXPNS_DTL_ID?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RentExpenseDetails_Stddev_Fields = {
  __typename?: "ura_RentExpenseDetails_stddev_fields";
  AMOUNT?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Stddev_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RentExpenseDetails_Stddev_Pop_Fields = {
  __typename?: "ura_RentExpenseDetails_stddev_pop_fields";
  AMOUNT?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Stddev_Pop_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RentExpenseDetails_Stddev_Samp_Fields = {
  __typename?: "ura_RentExpenseDetails_stddev_samp_fields";
  AMOUNT?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Stddev_Samp_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RentExpenseDetails_Sum_Fields = {
  __typename?: "ura_RentExpenseDetails_sum_fields";
  AMOUNT?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Sum_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RentExpenseDetails_Var_Pop_Fields = {
  __typename?: "ura_RentExpenseDetails_var_pop_fields";
  AMOUNT?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Var_Pop_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RentExpenseDetails_Var_Samp_Fields = {
  __typename?: "ura_RentExpenseDetails_var_samp_fields";
  AMOUNT?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Var_Samp_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RentExpenseDetails_Variance_Fields = {
  __typename?: "ura_RentExpenseDetails_variance_fields";
  AMOUNT?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RentExpenseDetails" */
export type Ura_RentExpenseDetails_Variance_Order_By = {
  AMOUNT?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome = {
  __typename?: "ura_RentalLandLordIncome";
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENTAL_INCOME_ID?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  TENANT_NAME?: Maybe<Scalars["String"]>;
  TENANT_TIN?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Aggregate = {
  __typename?: "ura_RentalLandLordIncome_aggregate";
  aggregate?: Maybe<Ura_RentalLandLordIncome_Aggregate_Fields>;
  nodes: Array<Ura_RentalLandLordIncome>;
};

/** aggregate fields of "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Aggregate_Fields = {
  __typename?: "ura_RentalLandLordIncome_aggregate_fields";
  avg?: Maybe<Ura_RentalLandLordIncome_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RentalLandLordIncome_Max_Fields>;
  min?: Maybe<Ura_RentalLandLordIncome_Min_Fields>;
  stddev?: Maybe<Ura_RentalLandLordIncome_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RentalLandLordIncome_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RentalLandLordIncome_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RentalLandLordIncome_Sum_Fields>;
  var_pop?: Maybe<Ura_RentalLandLordIncome_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RentalLandLordIncome_Var_Samp_Fields>;
  variance?: Maybe<Ura_RentalLandLordIncome_Variance_Fields>;
};

/** aggregate fields of "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RentalLandLordIncome_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Aggregate_Order_By = {
  avg?: Maybe<Ura_RentalLandLordIncome_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RentalLandLordIncome_Max_Order_By>;
  min?: Maybe<Ura_RentalLandLordIncome_Min_Order_By>;
  stddev?: Maybe<Ura_RentalLandLordIncome_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RentalLandLordIncome_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RentalLandLordIncome_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RentalLandLordIncome_Sum_Order_By>;
  var_pop?: Maybe<Ura_RentalLandLordIncome_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RentalLandLordIncome_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RentalLandLordIncome_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Arr_Rel_Insert_Input = {
  data: Array<Ura_RentalLandLordIncome_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RentalLandLordIncome_Avg_Fields = {
  __typename?: "ura_RentalLandLordIncome_avg_fields";
  RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Avg_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RentalLandLordIncome". All fields are combined with a logical 'AND'. */
export type Ura_RentalLandLordIncome_Bool_Exp = {
  PREMISE_LOCATION?: Maybe<String_Comparison_Exp>;
  RENTAL_INCOME?: Maybe<Float8_Comparison_Exp>;
  RTN_DT?: Maybe<Date_Comparison_Exp>;
  RTN_FROM_DT?: Maybe<Date_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_RENTAL_INCOME_ID?: Maybe<String_Comparison_Exp>;
  RTN_TO_DT?: Maybe<Date_Comparison_Exp>;
  TAX_PAYER_NAME?: Maybe<String_Comparison_Exp>;
  TENANCY_PERIOD_FROM?: Maybe<Date_Comparison_Exp>;
  TENANCY_PERIOD_TO?: Maybe<Date_Comparison_Exp>;
  TENANT_NAME?: Maybe<String_Comparison_Exp>;
  TENANT_TIN?: Maybe<String_Comparison_Exp>;
  TIN_NO?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RentalLandLordIncome_Bool_Exp>>>;
  _not?: Maybe<Ura_RentalLandLordIncome_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RentalLandLordIncome_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Inc_Input = {
  RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Insert_Input = {
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENTAL_INCOME_ID?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  TENANT_NAME?: Maybe<Scalars["String"]>;
  TENANT_TIN?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RentalLandLordIncome_Max_Fields = {
  __typename?: "ura_RentalLandLordIncome_max_fields";
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENTAL_INCOME_ID?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  TENANT_NAME?: Maybe<Scalars["String"]>;
  TENANT_TIN?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Max_Order_By = {
  PREMISE_LOCATION?: Maybe<Order_By>;
  RENTAL_INCOME?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_RENTAL_INCOME_ID?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TENANCY_PERIOD_FROM?: Maybe<Order_By>;
  TENANCY_PERIOD_TO?: Maybe<Order_By>;
  TENANT_NAME?: Maybe<Order_By>;
  TENANT_TIN?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RentalLandLordIncome_Min_Fields = {
  __typename?: "ura_RentalLandLordIncome_min_fields";
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENTAL_INCOME_ID?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  TENANT_NAME?: Maybe<Scalars["String"]>;
  TENANT_TIN?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Min_Order_By = {
  PREMISE_LOCATION?: Maybe<Order_By>;
  RENTAL_INCOME?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_RENTAL_INCOME_ID?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TENANCY_PERIOD_FROM?: Maybe<Order_By>;
  TENANCY_PERIOD_TO?: Maybe<Order_By>;
  TENANT_NAME?: Maybe<Order_By>;
  TENANT_TIN?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Mutation_Response = {
  __typename?: "ura_RentalLandLordIncome_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RentalLandLordIncome>;
};

/** input type for inserting object relation for remote table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Obj_Rel_Insert_Input = {
  data: Ura_RentalLandLordIncome_Insert_Input;
};

/** ordering options when selecting data from "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Order_By = {
  PREMISE_LOCATION?: Maybe<Order_By>;
  RENTAL_INCOME?: Maybe<Order_By>;
  RTN_DT?: Maybe<Order_By>;
  RTN_FROM_DT?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_RENTAL_INCOME_ID?: Maybe<Order_By>;
  RTN_TO_DT?: Maybe<Order_By>;
  TAX_PAYER_NAME?: Maybe<Order_By>;
  TENANCY_PERIOD_FROM?: Maybe<Order_By>;
  TENANCY_PERIOD_TO?: Maybe<Order_By>;
  TENANT_NAME?: Maybe<Order_By>;
  TENANT_TIN?: Maybe<Order_By>;
  TIN_NO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RentalLandLordIncome" */
export enum Ura_RentalLandLordIncome_Select_Column {
  /** column name */
  PremiseLocation = "PREMISE_LOCATION",
  /** column name */
  RentalIncome = "RENTAL_INCOME",
  /** column name */
  RtnDt = "RTN_DT",
  /** column name */
  RtnFromDt = "RTN_FROM_DT",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnRentalIncomeId = "RTN_RENTAL_INCOME_ID",
  /** column name */
  RtnToDt = "RTN_TO_DT",
  /** column name */
  TaxPayerName = "TAX_PAYER_NAME",
  /** column name */
  TenancyPeriodFrom = "TENANCY_PERIOD_FROM",
  /** column name */
  TenancyPeriodTo = "TENANCY_PERIOD_TO",
  /** column name */
  TenantName = "TENANT_NAME",
  /** column name */
  TenantTin = "TENANT_TIN",
  /** column name */
  TinNo = "TIN_NO",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Set_Input = {
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  RTN_DT?: Maybe<Scalars["date"]>;
  RTN_FROM_DT?: Maybe<Scalars["date"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENTAL_INCOME_ID?: Maybe<Scalars["String"]>;
  RTN_TO_DT?: Maybe<Scalars["date"]>;
  TAX_PAYER_NAME?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  TENANT_NAME?: Maybe<Scalars["String"]>;
  TENANT_TIN?: Maybe<Scalars["String"]>;
  TIN_NO?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RentalLandLordIncome_Stddev_Fields = {
  __typename?: "ura_RentalLandLordIncome_stddev_fields";
  RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Stddev_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RentalLandLordIncome_Stddev_Pop_Fields = {
  __typename?: "ura_RentalLandLordIncome_stddev_pop_fields";
  RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Stddev_Pop_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RentalLandLordIncome_Stddev_Samp_Fields = {
  __typename?: "ura_RentalLandLordIncome_stddev_samp_fields";
  RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Stddev_Samp_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RentalLandLordIncome_Sum_Fields = {
  __typename?: "ura_RentalLandLordIncome_sum_fields";
  RENTAL_INCOME?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Sum_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RentalLandLordIncome_Var_Pop_Fields = {
  __typename?: "ura_RentalLandLordIncome_var_pop_fields";
  RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Var_Pop_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RentalLandLordIncome_Var_Samp_Fields = {
  __typename?: "ura_RentalLandLordIncome_var_samp_fields";
  RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Var_Samp_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RentalLandLordIncome_Variance_Fields = {
  __typename?: "ura_RentalLandLordIncome_variance_fields";
  RENTAL_INCOME?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RentalLandLordIncome" */
export type Ura_RentalLandLordIncome_Variance_Order_By = {
  RENTAL_INCOME?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments = {
  __typename?: "ura_RentalTenantPayments";
  LANDLORD_NAME?: Maybe<Scalars["String"]>;
  LANDLORD_TIN?: Maybe<Scalars["String"]>;
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENT_PAID?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENT_PAID_ID?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Aggregate = {
  __typename?: "ura_RentalTenantPayments_aggregate";
  aggregate?: Maybe<Ura_RentalTenantPayments_Aggregate_Fields>;
  nodes: Array<Ura_RentalTenantPayments>;
};

/** aggregate fields of "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Aggregate_Fields = {
  __typename?: "ura_RentalTenantPayments_aggregate_fields";
  avg?: Maybe<Ura_RentalTenantPayments_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RentalTenantPayments_Max_Fields>;
  min?: Maybe<Ura_RentalTenantPayments_Min_Fields>;
  stddev?: Maybe<Ura_RentalTenantPayments_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RentalTenantPayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RentalTenantPayments_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RentalTenantPayments_Sum_Fields>;
  var_pop?: Maybe<Ura_RentalTenantPayments_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RentalTenantPayments_Var_Samp_Fields>;
  variance?: Maybe<Ura_RentalTenantPayments_Variance_Fields>;
};

/** aggregate fields of "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RentalTenantPayments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Aggregate_Order_By = {
  avg?: Maybe<Ura_RentalTenantPayments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RentalTenantPayments_Max_Order_By>;
  min?: Maybe<Ura_RentalTenantPayments_Min_Order_By>;
  stddev?: Maybe<Ura_RentalTenantPayments_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RentalTenantPayments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RentalTenantPayments_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RentalTenantPayments_Sum_Order_By>;
  var_pop?: Maybe<Ura_RentalTenantPayments_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RentalTenantPayments_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RentalTenantPayments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Arr_Rel_Insert_Input = {
  data: Array<Ura_RentalTenantPayments_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RentalTenantPayments_Avg_Fields = {
  __typename?: "ura_RentalTenantPayments_avg_fields";
  RENT_PAID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Avg_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RentalTenantPayments". All fields are combined with a logical 'AND'. */
export type Ura_RentalTenantPayments_Bool_Exp = {
  LANDLORD_NAME?: Maybe<String_Comparison_Exp>;
  LANDLORD_TIN?: Maybe<String_Comparison_Exp>;
  PREMISE_LOCATION?: Maybe<String_Comparison_Exp>;
  RENT_PAID?: Maybe<Float8_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  RTN_RENT_PAID_ID?: Maybe<String_Comparison_Exp>;
  TENANCY_PERIOD_FROM?: Maybe<Date_Comparison_Exp>;
  TENANCY_PERIOD_TO?: Maybe<Date_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RentalTenantPayments_Bool_Exp>>>;
  _not?: Maybe<Ura_RentalTenantPayments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RentalTenantPayments_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Inc_Input = {
  RENT_PAID?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Insert_Input = {
  LANDLORD_NAME?: Maybe<Scalars["String"]>;
  LANDLORD_TIN?: Maybe<Scalars["String"]>;
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENT_PAID?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENT_PAID_ID?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RentalTenantPayments_Max_Fields = {
  __typename?: "ura_RentalTenantPayments_max_fields";
  LANDLORD_NAME?: Maybe<Scalars["String"]>;
  LANDLORD_TIN?: Maybe<Scalars["String"]>;
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENT_PAID?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENT_PAID_ID?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Max_Order_By = {
  LANDLORD_NAME?: Maybe<Order_By>;
  LANDLORD_TIN?: Maybe<Order_By>;
  PREMISE_LOCATION?: Maybe<Order_By>;
  RENT_PAID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_RENT_PAID_ID?: Maybe<Order_By>;
  TENANCY_PERIOD_FROM?: Maybe<Order_By>;
  TENANCY_PERIOD_TO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RentalTenantPayments_Min_Fields = {
  __typename?: "ura_RentalTenantPayments_min_fields";
  LANDLORD_NAME?: Maybe<Scalars["String"]>;
  LANDLORD_TIN?: Maybe<Scalars["String"]>;
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENT_PAID?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENT_PAID_ID?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Min_Order_By = {
  LANDLORD_NAME?: Maybe<Order_By>;
  LANDLORD_TIN?: Maybe<Order_By>;
  PREMISE_LOCATION?: Maybe<Order_By>;
  RENT_PAID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_RENT_PAID_ID?: Maybe<Order_By>;
  TENANCY_PERIOD_FROM?: Maybe<Order_By>;
  TENANCY_PERIOD_TO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Mutation_Response = {
  __typename?: "ura_RentalTenantPayments_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RentalTenantPayments>;
};

/** input type for inserting object relation for remote table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Obj_Rel_Insert_Input = {
  data: Ura_RentalTenantPayments_Insert_Input;
};

/** ordering options when selecting data from "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Order_By = {
  LANDLORD_NAME?: Maybe<Order_By>;
  LANDLORD_TIN?: Maybe<Order_By>;
  PREMISE_LOCATION?: Maybe<Order_By>;
  RENT_PAID?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  RTN_RENT_PAID_ID?: Maybe<Order_By>;
  TENANCY_PERIOD_FROM?: Maybe<Order_By>;
  TENANCY_PERIOD_TO?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RentalTenantPayments" */
export enum Ura_RentalTenantPayments_Select_Column {
  /** column name */
  LandlordName = "LANDLORD_NAME",
  /** column name */
  LandlordTin = "LANDLORD_TIN",
  /** column name */
  PremiseLocation = "PREMISE_LOCATION",
  /** column name */
  RentPaid = "RENT_PAID",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  RtnRentPaidId = "RTN_RENT_PAID_ID",
  /** column name */
  TenancyPeriodFrom = "TENANCY_PERIOD_FROM",
  /** column name */
  TenancyPeriodTo = "TENANCY_PERIOD_TO",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Set_Input = {
  LANDLORD_NAME?: Maybe<Scalars["String"]>;
  LANDLORD_TIN?: Maybe<Scalars["String"]>;
  PREMISE_LOCATION?: Maybe<Scalars["String"]>;
  RENT_PAID?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  RTN_RENT_PAID_ID?: Maybe<Scalars["String"]>;
  TENANCY_PERIOD_FROM?: Maybe<Scalars["date"]>;
  TENANCY_PERIOD_TO?: Maybe<Scalars["date"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RentalTenantPayments_Stddev_Fields = {
  __typename?: "ura_RentalTenantPayments_stddev_fields";
  RENT_PAID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Stddev_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RentalTenantPayments_Stddev_Pop_Fields = {
  __typename?: "ura_RentalTenantPayments_stddev_pop_fields";
  RENT_PAID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Stddev_Pop_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RentalTenantPayments_Stddev_Samp_Fields = {
  __typename?: "ura_RentalTenantPayments_stddev_samp_fields";
  RENT_PAID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Stddev_Samp_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RentalTenantPayments_Sum_Fields = {
  __typename?: "ura_RentalTenantPayments_sum_fields";
  RENT_PAID?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Sum_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RentalTenantPayments_Var_Pop_Fields = {
  __typename?: "ura_RentalTenantPayments_var_pop_fields";
  RENT_PAID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Var_Pop_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RentalTenantPayments_Var_Samp_Fields = {
  __typename?: "ura_RentalTenantPayments_var_samp_fields";
  RENT_PAID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Var_Samp_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RentalTenantPayments_Variance_Fields = {
  __typename?: "ura_RentalTenantPayments_variance_fields";
  RENT_PAID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RentalTenantPayments" */
export type Ura_RentalTenantPayments_Variance_Order_By = {
  RENT_PAID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary = {
  __typename?: "ura_RipoIndRentalIncSummary";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Aggregate = {
  __typename?: "ura_RipoIndRentalIncSummary_aggregate";
  aggregate?: Maybe<Ura_RipoIndRentalIncSummary_Aggregate_Fields>;
  nodes: Array<Ura_RipoIndRentalIncSummary>;
};

/** aggregate fields of "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Aggregate_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_aggregate_fields";
  avg?: Maybe<Ura_RipoIndRentalIncSummary_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_RipoIndRentalIncSummary_Max_Fields>;
  min?: Maybe<Ura_RipoIndRentalIncSummary_Min_Fields>;
  stddev?: Maybe<Ura_RipoIndRentalIncSummary_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_RipoIndRentalIncSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_RipoIndRentalIncSummary_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_RipoIndRentalIncSummary_Sum_Fields>;
  var_pop?: Maybe<Ura_RipoIndRentalIncSummary_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_RipoIndRentalIncSummary_Var_Samp_Fields>;
  variance?: Maybe<Ura_RipoIndRentalIncSummary_Variance_Fields>;
};

/** aggregate fields of "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_RipoIndRentalIncSummary_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Aggregate_Order_By = {
  avg?: Maybe<Ura_RipoIndRentalIncSummary_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_RipoIndRentalIncSummary_Max_Order_By>;
  min?: Maybe<Ura_RipoIndRentalIncSummary_Min_Order_By>;
  stddev?: Maybe<Ura_RipoIndRentalIncSummary_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_RipoIndRentalIncSummary_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_RipoIndRentalIncSummary_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_RipoIndRentalIncSummary_Sum_Order_By>;
  var_pop?: Maybe<Ura_RipoIndRentalIncSummary_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_RipoIndRentalIncSummary_Var_Samp_Order_By>;
  variance?: Maybe<Ura_RipoIndRentalIncSummary_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Arr_Rel_Insert_Input = {
  data: Array<Ura_RipoIndRentalIncSummary_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_RipoIndRentalIncSummary_Avg_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_avg_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Avg_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.RipoIndRentalIncSummary". All fields are combined with a logical 'AND'. */
export type Ura_RipoIndRentalIncSummary_Bool_Exp = {
  CHRGD_RENTAL_INCM?: Maybe<Float8_Comparison_Exp>;
  DEDUCT_INCM_TAX?: Maybe<Float8_Comparison_Exp>;
  GROSS_RENTAL_INCM?: Maybe<Float8_Comparison_Exp>;
  MOTGAGE_INT_DECD?: Maybe<Float8_Comparison_Exp>;
  NET_RENT_INCM?: Maybe<Float8_Comparison_Exp>;
  PARTNER_RENT_INCOME?: Maybe<Float8_Comparison_Exp>;
  RTN_NO?: Maybe<String_Comparison_Exp>;
  TAX_ASSESSED?: Maybe<Float8_Comparison_Exp>;
  THRESHOLD_AMNT?: Maybe<Float8_Comparison_Exp>;
  TOT_RENTAL_INCM?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_RipoIndRentalIncSummary_Bool_Exp>>>;
  _not?: Maybe<Ura_RipoIndRentalIncSummary_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_RipoIndRentalIncSummary_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Inc_Input = {
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Insert_Input = {
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_RipoIndRentalIncSummary_Max_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_max_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Max_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_RipoIndRentalIncSummary_Min_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_min_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Min_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Mutation_Response = {
  __typename?: "ura_RipoIndRentalIncSummary_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_RipoIndRentalIncSummary>;
};

/** input type for inserting object relation for remote table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Obj_Rel_Insert_Input = {
  data: Ura_RipoIndRentalIncSummary_Insert_Input;
};

/** ordering options when selecting data from "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  RTN_NO?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.RipoIndRentalIncSummary" */
export enum Ura_RipoIndRentalIncSummary_Select_Column {
  /** column name */
  ChrgdRentalIncm = "CHRGD_RENTAL_INCM",
  /** column name */
  DeductIncmTax = "DEDUCT_INCM_TAX",
  /** column name */
  GrossRentalIncm = "GROSS_RENTAL_INCM",
  /** column name */
  MotgageIntDecd = "MOTGAGE_INT_DECD",
  /** column name */
  NetRentIncm = "NET_RENT_INCM",
  /** column name */
  PartnerRentIncome = "PARTNER_RENT_INCOME",
  /** column name */
  RtnNo = "RTN_NO",
  /** column name */
  TaxAssessed = "TAX_ASSESSED",
  /** column name */
  ThresholdAmnt = "THRESHOLD_AMNT",
  /** column name */
  TotRentalIncm = "TOT_RENTAL_INCM",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Set_Input = {
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  RTN_NO?: Maybe<Scalars["String"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_RipoIndRentalIncSummary_Stddev_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_stddev_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Stddev_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_RipoIndRentalIncSummary_Stddev_Pop_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_stddev_pop_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Stddev_Pop_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_RipoIndRentalIncSummary_Stddev_Samp_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_stddev_samp_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Stddev_Samp_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_RipoIndRentalIncSummary_Sum_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_sum_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["float8"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["float8"]>;
  NET_RENT_INCM?: Maybe<Scalars["float8"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["float8"]>;
  TAX_ASSESSED?: Maybe<Scalars["float8"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["float8"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["float8"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Sum_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_RipoIndRentalIncSummary_Var_Pop_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_var_pop_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Var_Pop_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_RipoIndRentalIncSummary_Var_Samp_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_var_samp_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Var_Samp_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_RipoIndRentalIncSummary_Variance_Fields = {
  __typename?: "ura_RipoIndRentalIncSummary_variance_fields";
  CHRGD_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  DEDUCT_INCM_TAX?: Maybe<Scalars["Float"]>;
  GROSS_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  MOTGAGE_INT_DECD?: Maybe<Scalars["Float"]>;
  NET_RENT_INCM?: Maybe<Scalars["Float"]>;
  PARTNER_RENT_INCOME?: Maybe<Scalars["Float"]>;
  TAX_ASSESSED?: Maybe<Scalars["Float"]>;
  THRESHOLD_AMNT?: Maybe<Scalars["Float"]>;
  TOT_RENTAL_INCM?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.RipoIndRentalIncSummary" */
export type Ura_RipoIndRentalIncSummary_Variance_Order_By = {
  CHRGD_RENTAL_INCM?: Maybe<Order_By>;
  DEDUCT_INCM_TAX?: Maybe<Order_By>;
  GROSS_RENTAL_INCM?: Maybe<Order_By>;
  MOTGAGE_INT_DECD?: Maybe<Order_By>;
  NET_RENT_INCM?: Maybe<Order_By>;
  PARTNER_RENT_INCOME?: Maybe<Order_By>;
  TAX_ASSESSED?: Maybe<Order_By>;
  THRESHOLD_AMNT?: Maybe<Order_By>;
  TOT_RENTAL_INCM?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ura.Town" */
export type Ura_Town = {
  __typename?: "ura_Town";
  DISTRICT_ID?: Maybe<Scalars["bigint"]>;
  TOWN_CD?: Maybe<Scalars["String"]>;
  TOWN_ID?: Maybe<Scalars["bigint"]>;
  TOWN_NAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ura.Town" */
export type Ura_Town_Aggregate = {
  __typename?: "ura_Town_aggregate";
  aggregate?: Maybe<Ura_Town_Aggregate_Fields>;
  nodes: Array<Ura_Town>;
};

/** aggregate fields of "ura.Town" */
export type Ura_Town_Aggregate_Fields = {
  __typename?: "ura_Town_aggregate_fields";
  avg?: Maybe<Ura_Town_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ura_Town_Max_Fields>;
  min?: Maybe<Ura_Town_Min_Fields>;
  stddev?: Maybe<Ura_Town_Stddev_Fields>;
  stddev_pop?: Maybe<Ura_Town_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ura_Town_Stddev_Samp_Fields>;
  sum?: Maybe<Ura_Town_Sum_Fields>;
  var_pop?: Maybe<Ura_Town_Var_Pop_Fields>;
  var_samp?: Maybe<Ura_Town_Var_Samp_Fields>;
  variance?: Maybe<Ura_Town_Variance_Fields>;
};

/** aggregate fields of "ura.Town" */
export type Ura_Town_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ura_Town_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ura.Town" */
export type Ura_Town_Aggregate_Order_By = {
  avg?: Maybe<Ura_Town_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ura_Town_Max_Order_By>;
  min?: Maybe<Ura_Town_Min_Order_By>;
  stddev?: Maybe<Ura_Town_Stddev_Order_By>;
  stddev_pop?: Maybe<Ura_Town_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ura_Town_Stddev_Samp_Order_By>;
  sum?: Maybe<Ura_Town_Sum_Order_By>;
  var_pop?: Maybe<Ura_Town_Var_Pop_Order_By>;
  var_samp?: Maybe<Ura_Town_Var_Samp_Order_By>;
  variance?: Maybe<Ura_Town_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ura.Town" */
export type Ura_Town_Arr_Rel_Insert_Input = {
  data: Array<Ura_Town_Insert_Input>;
};

/** aggregate avg on columns */
export type Ura_Town_Avg_Fields = {
  __typename?: "ura_Town_avg_fields";
  DISTRICT_ID?: Maybe<Scalars["Float"]>;
  TOWN_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ura.Town" */
export type Ura_Town_Avg_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ura.Town". All fields are combined with a logical 'AND'. */
export type Ura_Town_Bool_Exp = {
  DISTRICT_ID?: Maybe<Bigint_Comparison_Exp>;
  TOWN_CD?: Maybe<String_Comparison_Exp>;
  TOWN_ID?: Maybe<Bigint_Comparison_Exp>;
  TOWN_NAME?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ura_Town_Bool_Exp>>>;
  _not?: Maybe<Ura_Town_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ura_Town_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ura.Town" */
export type Ura_Town_Inc_Input = {
  DISTRICT_ID?: Maybe<Scalars["bigint"]>;
  TOWN_ID?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ura.Town" */
export type Ura_Town_Insert_Input = {
  DISTRICT_ID?: Maybe<Scalars["bigint"]>;
  TOWN_CD?: Maybe<Scalars["String"]>;
  TOWN_ID?: Maybe<Scalars["bigint"]>;
  TOWN_NAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ura_Town_Max_Fields = {
  __typename?: "ura_Town_max_fields";
  DISTRICT_ID?: Maybe<Scalars["bigint"]>;
  TOWN_CD?: Maybe<Scalars["String"]>;
  TOWN_ID?: Maybe<Scalars["bigint"]>;
  TOWN_NAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ura.Town" */
export type Ura_Town_Max_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_CD?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  TOWN_NAME?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ura_Town_Min_Fields = {
  __typename?: "ura_Town_min_fields";
  DISTRICT_ID?: Maybe<Scalars["bigint"]>;
  TOWN_CD?: Maybe<Scalars["String"]>;
  TOWN_ID?: Maybe<Scalars["bigint"]>;
  TOWN_NAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ura.Town" */
export type Ura_Town_Min_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_CD?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  TOWN_NAME?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ura.Town" */
export type Ura_Town_Mutation_Response = {
  __typename?: "ura_Town_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ura_Town>;
};

/** input type for inserting object relation for remote table "ura.Town" */
export type Ura_Town_Obj_Rel_Insert_Input = {
  data: Ura_Town_Insert_Input;
};

/** ordering options when selecting data from "ura.Town" */
export type Ura_Town_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_CD?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  TOWN_NAME?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ura.Town" */
export enum Ura_Town_Select_Column {
  /** column name */
  DistrictId = "DISTRICT_ID",
  /** column name */
  TownCd = "TOWN_CD",
  /** column name */
  TownId = "TOWN_ID",
  /** column name */
  TownName = "TOWN_NAME",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ura.Town" */
export type Ura_Town_Set_Input = {
  DISTRICT_ID?: Maybe<Scalars["bigint"]>;
  TOWN_CD?: Maybe<Scalars["String"]>;
  TOWN_ID?: Maybe<Scalars["bigint"]>;
  TOWN_NAME?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ura_Town_Stddev_Fields = {
  __typename?: "ura_Town_stddev_fields";
  DISTRICT_ID?: Maybe<Scalars["Float"]>;
  TOWN_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ura.Town" */
export type Ura_Town_Stddev_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ura_Town_Stddev_Pop_Fields = {
  __typename?: "ura_Town_stddev_pop_fields";
  DISTRICT_ID?: Maybe<Scalars["Float"]>;
  TOWN_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ura.Town" */
export type Ura_Town_Stddev_Pop_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ura_Town_Stddev_Samp_Fields = {
  __typename?: "ura_Town_stddev_samp_fields";
  DISTRICT_ID?: Maybe<Scalars["Float"]>;
  TOWN_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ura.Town" */
export type Ura_Town_Stddev_Samp_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ura_Town_Sum_Fields = {
  __typename?: "ura_Town_sum_fields";
  DISTRICT_ID?: Maybe<Scalars["bigint"]>;
  TOWN_ID?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ura.Town" */
export type Ura_Town_Sum_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ura_Town_Var_Pop_Fields = {
  __typename?: "ura_Town_var_pop_fields";
  DISTRICT_ID?: Maybe<Scalars["Float"]>;
  TOWN_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ura.Town" */
export type Ura_Town_Var_Pop_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ura_Town_Var_Samp_Fields = {
  __typename?: "ura_Town_var_samp_fields";
  DISTRICT_ID?: Maybe<Scalars["Float"]>;
  TOWN_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ura.Town" */
export type Ura_Town_Var_Samp_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ura_Town_Variance_Fields = {
  __typename?: "ura_Town_variance_fields";
  DISTRICT_ID?: Maybe<Scalars["Float"]>;
  TOWN_ID?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ura.Town" */
export type Ura_Town_Variance_Order_By = {
  DISTRICT_ID?: Maybe<Order_By>;
  TOWN_ID?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** columns and relationships of "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB";
  Business_Name?: Maybe<Scalars["String"]>;
  Company_Name?: Maybe<Scalars["String"]>;
  Company_Type?: Maybe<Scalars["String"]>;
  Data_Source?: Maybe<Scalars["String"]>;
  Director_FirstName?: Maybe<Scalars["String"]>;
  Director_Surname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Partners_FirstName?: Maybe<Scalars["String"]>;
  Partners_Surname?: Maybe<Scalars["String"]>;
  Registration_Date?: Maybe<Scalars["timestamp"]>;
  Registration_Number?: Maybe<Scalars["bigint"]>;
  Shareholder_FirstName?: Maybe<Scalars["String"]>;
  Shareholders_Surname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SubType?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Aggregate = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_aggregate";
  aggregate?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Fields>;
  nodes: Array<Ursb_CompaniesAndBusinessNamesUrsb>;
};

/** aggregate fields of "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_aggregate_fields";
  avg?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Max_Fields>;
  min?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Min_Fields>;
  stddev?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Fields>;
  stddev_pop?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Fields>;
  sum?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Sum_Fields>;
  var_pop?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Fields>;
  var_samp?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Fields>;
  variance?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Variance_Fields>;
};

/** aggregate fields of "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Order_By = {
  avg?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Max_Order_By>;
  min?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Min_Order_By>;
  stddev?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Order_By>;
  stddev_pop?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Order_By>;
  sum?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Sum_Order_By>;
  var_pop?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Order_By>;
  var_samp?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Order_By>;
  variance?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Arr_Rel_Insert_Input = {
  data: Array<Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input>;
};

/** aggregate avg on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Avg_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_avg_fields";
  Registration_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Avg_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ursb.CompaniesAndBusinessNamesURSB". All fields are combined with a logical 'AND'. */
export type Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp = {
  Business_Name?: Maybe<String_Comparison_Exp>;
  Company_Name?: Maybe<String_Comparison_Exp>;
  Company_Type?: Maybe<String_Comparison_Exp>;
  Data_Source?: Maybe<String_Comparison_Exp>;
  Director_FirstName?: Maybe<String_Comparison_Exp>;
  Director_Surname?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Partners_FirstName?: Maybe<String_Comparison_Exp>;
  Partners_Surname?: Maybe<String_Comparison_Exp>;
  Registration_Date?: Maybe<Timestamp_Comparison_Exp>;
  Registration_Number?: Maybe<Bigint_Comparison_Exp>;
  Shareholder_FirstName?: Maybe<String_Comparison_Exp>;
  Shareholders_Surname?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  SubType?: Maybe<String_Comparison_Exp>;
  Town?: Maybe<String_Comparison_Exp>;
  Trading?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>>>;
  _not?: Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>>>;
  file?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamp_Comparison_Exp>;
  line?: Maybe<Bigint_Comparison_Exp>;
  who_updated?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Inc_Input = {
  Registration_Number?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input = {
  Business_Name?: Maybe<Scalars["String"]>;
  Company_Name?: Maybe<Scalars["String"]>;
  Company_Type?: Maybe<Scalars["String"]>;
  Data_Source?: Maybe<Scalars["String"]>;
  Director_FirstName?: Maybe<Scalars["String"]>;
  Director_Surname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Partners_FirstName?: Maybe<Scalars["String"]>;
  Partners_Surname?: Maybe<Scalars["String"]>;
  Registration_Date?: Maybe<Scalars["timestamp"]>;
  Registration_Number?: Maybe<Scalars["bigint"]>;
  Shareholder_FirstName?: Maybe<Scalars["String"]>;
  Shareholders_Surname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SubType?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Max_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_max_fields";
  Business_Name?: Maybe<Scalars["String"]>;
  Company_Name?: Maybe<Scalars["String"]>;
  Company_Type?: Maybe<Scalars["String"]>;
  Data_Source?: Maybe<Scalars["String"]>;
  Director_FirstName?: Maybe<Scalars["String"]>;
  Director_Surname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Partners_FirstName?: Maybe<Scalars["String"]>;
  Partners_Surname?: Maybe<Scalars["String"]>;
  Registration_Date?: Maybe<Scalars["timestamp"]>;
  Registration_Number?: Maybe<Scalars["bigint"]>;
  Shareholder_FirstName?: Maybe<Scalars["String"]>;
  Shareholders_Surname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SubType?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Max_Order_By = {
  Business_Name?: Maybe<Order_By>;
  Company_Name?: Maybe<Order_By>;
  Company_Type?: Maybe<Order_By>;
  Data_Source?: Maybe<Order_By>;
  Director_FirstName?: Maybe<Order_By>;
  Director_Surname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Partners_FirstName?: Maybe<Order_By>;
  Partners_Surname?: Maybe<Order_By>;
  Registration_Date?: Maybe<Order_By>;
  Registration_Number?: Maybe<Order_By>;
  Shareholder_FirstName?: Maybe<Order_By>;
  Shareholders_Surname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SubType?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Min_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_min_fields";
  Business_Name?: Maybe<Scalars["String"]>;
  Company_Name?: Maybe<Scalars["String"]>;
  Company_Type?: Maybe<Scalars["String"]>;
  Data_Source?: Maybe<Scalars["String"]>;
  Director_FirstName?: Maybe<Scalars["String"]>;
  Director_Surname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Partners_FirstName?: Maybe<Scalars["String"]>;
  Partners_Surname?: Maybe<Scalars["String"]>;
  Registration_Date?: Maybe<Scalars["timestamp"]>;
  Registration_Number?: Maybe<Scalars["bigint"]>;
  Shareholder_FirstName?: Maybe<Scalars["String"]>;
  Shareholders_Surname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SubType?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Min_Order_By = {
  Business_Name?: Maybe<Order_By>;
  Company_Name?: Maybe<Order_By>;
  Company_Type?: Maybe<Order_By>;
  Data_Source?: Maybe<Order_By>;
  Director_FirstName?: Maybe<Order_By>;
  Director_Surname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Partners_FirstName?: Maybe<Order_By>;
  Partners_Surname?: Maybe<Order_By>;
  Registration_Date?: Maybe<Order_By>;
  Registration_Number?: Maybe<Order_By>;
  Shareholder_FirstName?: Maybe<Order_By>;
  Shareholders_Surname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SubType?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Ursb_CompaniesAndBusinessNamesUrsb>;
};

/** input type for inserting object relation for remote table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Obj_Rel_Insert_Input = {
  data: Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input;
};

/** ordering options when selecting data from "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Order_By = {
  Business_Name?: Maybe<Order_By>;
  Company_Name?: Maybe<Order_By>;
  Company_Type?: Maybe<Order_By>;
  Data_Source?: Maybe<Order_By>;
  Director_FirstName?: Maybe<Order_By>;
  Director_Surname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Partners_FirstName?: Maybe<Order_By>;
  Partners_Surname?: Maybe<Order_By>;
  Registration_Date?: Maybe<Order_By>;
  Registration_Number?: Maybe<Order_By>;
  Shareholder_FirstName?: Maybe<Order_By>;
  Shareholders_Surname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SubType?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  file?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
  who_updated?: Maybe<Order_By>;
};

/** select columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export enum Ursb_CompaniesAndBusinessNamesUrsb_Select_Column {
  /** column name */
  BusinessName = "Business_Name",
  /** column name */
  CompanyName = "Company_Name",
  /** column name */
  CompanyType = "Company_Type",
  /** column name */
  DataSource = "Data_Source",
  /** column name */
  DirectorFirstName = "Director_FirstName",
  /** column name */
  DirectorSurname = "Director_Surname",
  /** column name */
  District = "District",
  /** column name */
  Parish = "Parish",
  /** column name */
  PartnersFirstName = "Partners_FirstName",
  /** column name */
  PartnersSurname = "Partners_Surname",
  /** column name */
  RegistrationDate = "Registration_Date",
  /** column name */
  RegistrationNumber = "Registration_Number",
  /** column name */
  ShareholderFirstName = "Shareholder_FirstName",
  /** column name */
  ShareholdersSurname = "Shareholders_Surname",
  /** column name */
  Street = "Street",
  /** column name */
  SubType = "SubType",
  /** column name */
  Town = "Town",
  /** column name */
  Trading = "Trading",
  /** column name */
  Village = "Village",
  /** column name */
  File = "file",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Line = "line",
  /** column name */
  WhoUpdated = "who_updated",
}

/** input type for updating data in table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Set_Input = {
  Business_Name?: Maybe<Scalars["String"]>;
  Company_Name?: Maybe<Scalars["String"]>;
  Company_Type?: Maybe<Scalars["String"]>;
  Data_Source?: Maybe<Scalars["String"]>;
  Director_FirstName?: Maybe<Scalars["String"]>;
  Director_Surname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Partners_FirstName?: Maybe<Scalars["String"]>;
  Partners_Surname?: Maybe<Scalars["String"]>;
  Registration_Date?: Maybe<Scalars["timestamp"]>;
  Registration_Number?: Maybe<Scalars["bigint"]>;
  Shareholder_FirstName?: Maybe<Scalars["String"]>;
  Shareholders_Surname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SubType?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamp"]>;
  line?: Maybe<Scalars["bigint"]>;
  who_updated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_stddev_fields";
  Registration_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_stddev_pop_fields";
  Registration_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_stddev_samp_fields";
  Registration_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Sum_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_sum_fields";
  Registration_Number?: Maybe<Scalars["bigint"]>;
  line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Sum_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_var_pop_fields";
  Registration_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_var_samp_fields";
  Registration_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ursb_CompaniesAndBusinessNamesUrsb_Variance_Fields = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB_variance_fields";
  Registration_Number?: Maybe<Scalars["Float"]>;
  line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ursb.CompaniesAndBusinessNamesURSB" */
export type Ursb_CompaniesAndBusinessNamesUrsb_Variance_Order_By = {
  Registration_Number?: Maybe<Order_By>;
  line?: Maybe<Order_By>;
};

export type RangeSliderTestQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RangeSliderTestQueryQuery = { __typename?: "query_root" } & {
  ura_IncomeTaxSummaryIndividual_aggregate: {
    __typename?: "ura_IncomeTaxSummaryIndividual_aggregate";
  } & {
    aggregate?: Maybe<
      { __typename?: "ura_IncomeTaxSummaryIndividual_aggregate_fields" } & {
        min?: Maybe<
          { __typename?: "ura_IncomeTaxSummaryIndividual_min_fields" } & Pick<
            Ura_IncomeTaxSummaryIndividual_Min_Fields,
            "Tot_INCOME"
          >
        >;
        max?: Maybe<
          { __typename?: "ura_IncomeTaxSummaryIndividual_max_fields" } & Pick<
            Ura_IncomeTaxSummaryIndividual_Max_Fields,
            "Tot_INCOME"
          >
        >;
      }
    >;
  };
};

export type RegIndividualDataReportFragment = {
  __typename?: "ura_RegIndividual";
} & Pick<
  Ura_RegIndividual,
  | "TIN_NO"
  | "TAX_PAYER_TYPE"
  | "TAX_PAYER_NAME"
  | "TAX_PAYER_ID"
  | "SUR_NAME"
  | "REF_TIN"
  | "REF_SURNAME"
  | "REF_PRSN_DESGN"
  | "REF_OTHER_NAME"
  | "REF_MOBILE_NUMBER"
  | "REF_LANDLINE_NUMBER"
  | "REF_FIRST_NAME"
  | "REF_FAMILY_NAME"
  | "REF_EMAIL_ID"
  | "POSTADDR_ID"
  | "POSTADDR_DISTICT_NAME"
  | "OTHER_NAME"
  | "NSSF_ID"
  | "NATIONAL_ID"
  | "MOBILE_NUMBER"
  | "MIDDLE_NAME"
  | "LANDLINE_NUMBER"
  | "IS_INCOME_SRC_RENTAL"
  | "IS_INCOME_SRC_PRPTY"
  | "IS_INCOME_SRC_EMP"
  | "IS_INCOME_SRC_BSNS"
  | "HOME_ADDR_VILLAGE_NAME"
  | "HOME_ADDR_TRADE_CENTRE"
  | "HOME_ADDR_TOWN_NAME"
  | "HOME_ADDR_SUB_COUNTY_NAME"
  | "HOME_ADDR_STRRET_ADDR"
  | "HOME_ADDR_PLOTNO"
  | "HOME_ADDR_PARISH_NAME"
  | "HOME_ADDR_LOCAL_COUNCIL"
  | "HOME_ADDR_DISTRICT_NAME"
  | "HOME_ADDR_BUILDINGNAME"
  | "GENDER"
  | "FIRST_NAME"
  | "FAMILY_NAME"
  | "EXIST_TIN_NO"
  | "EMAIL_ID"
  | "CITIZEN_COUNTRY"
  | "BSNS_NAME"
  | "BIRTH_STATE"
  | "BIRTH_DT"
  | "BIRTH_COUNTRY"
  | "BIRTH_CITY"
  | "AUTHCTCD_TIN"
  | "AUTHCTCD_SURNAME"
  | "AUTHCTCD_PRSN_DESGN"
  | "AUTHCTCD_OTHER_NAME"
  | "AUTHCTCD_MOBILE_NUMBER"
  | "AUTHCTCD_LANDLINE_NUMBER"
  | "AUTHCTCD_FIRST_NAME"
  | "AUTHCTCD_FAMILY_NAME"
  | "AUTHCTCD_EMAIL_ID"
  | "APPLIED_FOR_TIN"
>;

export type RegIndividualDataReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RegIndividualDataReportQuery = { __typename?: "query_root" } & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & RegIndividualDataReportFragment
  >;
};

export type RegIndividualDataFragment = {
  __typename?: "ura_RPT_RegIndividual";
} & Pick<
  Ura_Rpt_RegIndividual,
  | "FULL_NAME"
  | "TIN_NO"
  | "FIRST_NAME"
  | "SUR_NAME"
  | "MIDDLE_NAME"
  | "FAMILY_NAME"
  | "BIRTH_DT"
  | "BIRTH_CITY"
  | "MOBILE_NUMBER"
  | "EMAIL_ID"
  | "HOME_ADDR_PLOTNO"
  | "HOME_ADDR_STRRET_ADDR"
  | "HOME_ADDR_DISTRICT_NAME"
  | "HOME_ADDR_SUB_COUNTY_NAME"
  | "HOME_ADDR_PARISH_NAME"
  | "HOME_ADDR_LOCAL_COUNCIL"
  | "IS_INCOME_SRC_RENTAL"
  | "IS_INCOME_SRC_PRPTY"
  | "IS_INCOME_SRC_BSNS"
  | "IS_INCOME_SRC_EMP"
>;

export type RegIndividualDataQueryVariables = Exact<{ [key: string]: never }>;

export type RegIndividualDataQuery = { __typename?: "query_root" } & {
  ura_RPT_RegIndividual: Array<
    { __typename?: "ura_RPT_RegIndividual" } & RegIndividualDataFragment
  >;
};

export type GetPaginatedRegIndividualsQueryVariables = Exact<{
  where: Ura_Rpt_RegIndividual_Bool_Exp;
  orderBy?: Maybe<
    Array<Ura_Rpt_RegIndividual_Order_By> | Ura_Rpt_RegIndividual_Order_By
  >;
  limit: Scalars["Int"];
  offset?: Maybe<Scalars["Int"]>;
}>;

export type GetPaginatedRegIndividualsQuery = { __typename?: "query_root" } & {
  ura_RPT_RegIndividual: Array<
    { __typename?: "ura_RPT_RegIndividual" } & RegIndividualDataFragment
  >;
  ura_RPT_RegIndividual_aggregate: {
    __typename?: "ura_RPT_RegIndividual_aggregate";
  } & {
    aggregate?: Maybe<
      { __typename?: "ura_RPT_RegIndividual_aggregate_fields" } & Pick<
        Ura_Rpt_RegIndividual_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type RegNonIndividualDataFragment = {
  __typename?: "ura_RegNonIndividual";
} & Pick<Ura_RegNonIndividual, "BSNS_NAME" | "TIN_NO">;

export type RegNonIndividualDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RegNonIndividualDataQuery = { __typename?: "query_root" } & {
  ura_RegNonIndividual: Array<
    { __typename?: "ura_RegNonIndividual" } & RegNonIndividualDataFragment
  >;
};

export type RegIndividualSuggestionFragment = {
  __typename?: "ura_RegIndividual";
} & Pick<Ura_RegIndividual, "TIN_NO" | "TAX_PAYER_NAME" | "NATIONAL_ID">;

export type RegIndividualSuggestionsQueryVariables = Exact<{
  searchString: Scalars["String"];
}>;

export type RegIndividualSuggestionsQuery = { __typename?: "query_root" } & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & RegIndividualSuggestionFragment
  >;
};

export type KccaPropertiesFragment = { __typename?: "kcca_Property" } & Pick<
  Kcca_Property,
  | "address"
  | "block_no"
  | "current_rateable_value"
  | "created_date"
  | "id"
  | "plot_no"
  | "flat_no"
  | "house_no"
  | "street_id"
  | "village_id"
  | "parish_id"
  | "division_id"
  | "property_no"
  | "location_address"
  | "property_rented_status_id"
  | "rating_zone_id"
  | "gross_value"
  | "rateable_value"
>;

export type KccaPropertiesQueryVariables = Exact<{
  propertyId: Scalars["bigint"];
}>;

export type KccaPropertiesQuery = { __typename?: "query_root" } & {
  kcca_Property: Array<
    { __typename?: "kcca_Property" } & KccaPropertiesFragment
  >;
};

export type IndividualPropertiesFragment = {
  __typename?: "kcca_Property";
} & Pick<
  Kcca_Property,
  | "plot_no"
  | "property_no"
  | "house_no"
  | "current_rateable_value"
  | "block_no"
  | "address"
  | "rateable_value"
  | "village_id"
  | "street_id"
  | "status_id"
  | "rating_zone_id"
  | "property_rented_status_id"
  | "flat_no"
  | "road_others"
  | "gross_value"
  | "id"
  | "customer_id"
  | "location_address"
  | "last_updated"
  | "property_type_id"
  | "parish_id"
  | "division_id"
>;

export type IndividualPropertiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IndividualPropertiesQuery = { __typename?: "query_root" } & {
  kcca_Property: Array<
    { __typename?: "kcca_Property" } & IndividualPropertiesFragment
  >;
};

export type KccaPropertyDetailsFragment = {
  __typename?: "kcca_RawProperties1";
} & Pick<
  Kcca_RawProperties1,
  | "building_name"
  | "Cordinate_X"
  | "Cordinate_Y"
  | "property_sub_type"
  | "property_type"
  | "RV"
  | "particulars_block_name"
  | "particulars_block_number"
  | "particulars_folio_number"
  | "particulars_house_number"
  | "particulars_is_titled"
  | "particulars_plot_number"
  | "particulars_plot_shape"
  | "particulars_property_name"
  | "particulars_road_street"
  | "particulars_type_of_land_interest"
  | "accomodation_bathroom"
  | "accomodation_bathroom_shower"
  | "accomodation_bedrooms"
  | "accomodation_built_up_area"
  | "accomodation_comment"
  | "accomodation_dining_rooms"
  | "accomodation_entertainment_room"
  | "accomodation_enviromental_noise"
  | "accomodation_garage"
  | "accomodation_kitchen"
  | "accomodation_laundry"
  | "accomodation_others"
  | "accomodation_security"
  | "accomodation_shops"
  | "accomodation_shower"
  | "accomodation_sitting_room_lounge_number"
  | "accomodation_store"
  | "accomodation_study_room"
  | "accomodation_verandah"
  | "accomodation_wc"
  | "accomodation_wc_bathroom"
  | "accomodation_wc_bathroom_shower"
  | "accomodation_wc_shower"
  | "particulars_access"
  | "particulars_frontages"
  | "particulars_gps_eastings_utm"
  | "particulars_gps_northings_utm"
  | "particulars_land_reference"
  | "particulars_neighborhood"
  | "particulars_topography_Others"
  | "particulars_topograpy"
  | "particulars_volume"
  | "property_owner_occupied_or_rented"
  | "pvillage"
  | "id"
>;

export type KccaProperties1QueryVariables = Exact<{ [key: string]: never }>;

export type KccaProperties1Query = { __typename?: "query_root" } & {
  kcca_RawProperties1: Array<
    { __typename?: "kcca_RawProperties1" } & KccaPropertyDetailsFragment
  >;
};

export type KccaPropertyDetailsQueryVariables = Exact<{
  propertyId: Scalars["String"];
}>;

export type KccaPropertyDetailsQuery = { __typename?: "query_root" } & {
  kcca_RawProperties1: Array<
    { __typename?: "kcca_RawProperties1" } & KccaPropertyDetailsFragment
  >;
};

export type UraNonIndividualFragment = {
  __typename?: "ura_RegNonIndividual";
} & Pick<
  Ura_RegNonIndividual,
  | "BSNS_CERT_NUMBER"
  | "BSNSADDR_PLOT_NO"
  | "BSNSADDR_STREET_ADDR"
  | "BSNSADDR_BUILDNAME"
  | "BSNSADDR_DISTRICT_NAME"
  | "BSNSADDR_TOWN_NAME"
  | "BSNSADDR_SUB_COUNTY_NAME"
  | "BSNSADDR_PARISH_NAME"
  | "BSNSADDR_VILLAGE_NAME"
  | "TRADING_NAME"
  | "EMAIL_ID"
  | "LANDLINE_NUMBER"
  | "MOBILE_NUMBER"
  | "IS_INCOME_SRC_RENTAL"
  | "IS_INCOME_SRC_BSNS"
  | "IS_INCOME_SRC_PRPTY"
  | "TIN_NO"
  | "TAX_PAYER_NAME"
>;

export type UraNonIndividualQueryVariables = Exact<{
  tin: Scalars["String"];
}>;

export type UraNonIndividualQuery = { __typename?: "query_root" } & {
  ura_RegNonIndividual: Array<
    { __typename?: "ura_RegNonIndividual" } & UraNonIndividualFragment
  >;
};

export type UraIndividualFragment = { __typename?: "ura_RegIndividual" } & Pick<
  Ura_RegIndividual,
  | "TIN_NO"
  | "TAX_PAYER_TYPE"
  | "TAX_PAYER_NAME"
  | "TAX_PAYER_ID"
  | "SUR_NAME"
  | "REF_TIN"
  | "REF_SURNAME"
  | "REF_PRSN_DESGN"
  | "REF_OTHER_NAME"
  | "REF_MOBILE_NUMBER"
  | "REF_LANDLINE_NUMBER"
  | "REF_FIRST_NAME"
  | "REF_FAMILY_NAME"
  | "REF_EMAIL_ID"
  | "POSTADDR_ID"
  | "POSTADDR_DISTICT_NAME"
  | "OTHER_NAME"
  | "NSSF_ID"
  | "NATIONAL_ID"
  | "MOBILE_NUMBER"
  | "MIDDLE_NAME"
  | "LANDLINE_NUMBER"
  | "IS_INCOME_SRC_RENTAL"
  | "IS_INCOME_SRC_PRPTY"
  | "IS_INCOME_SRC_EMP"
  | "IS_INCOME_SRC_BSNS"
  | "HOME_ADDR_VILLAGE_NAME"
  | "HOME_ADDR_TRADE_CENTRE"
  | "HOME_ADDR_TOWN_NAME"
  | "HOME_ADDR_SUB_COUNTY_NAME"
  | "HOME_ADDR_STRRET_ADDR"
  | "HOME_ADDR_PLOTNO"
  | "HOME_ADDR_PARISH_NAME"
  | "HOME_ADDR_LOCAL_COUNCIL"
  | "HOME_ADDR_DISTRICT_NAME"
  | "HOME_ADDR_BUILDINGNAME"
  | "GENDER"
  | "FIRST_NAME"
  | "FAMILY_NAME"
  | "EXIST_TIN_NO"
  | "EMAIL_ID"
  | "CITIZEN_COUNTRY"
  | "BSNS_NAME"
  | "BIRTH_STATE"
  | "BIRTH_DT"
  | "BIRTH_COUNTRY"
  | "BIRTH_CITY"
  | "AUTHCTCD_TIN"
  | "AUTHCTCD_SURNAME"
  | "AUTHCTCD_PRSN_DESGN"
  | "AUTHCTCD_OTHER_NAME"
  | "AUTHCTCD_MOBILE_NUMBER"
  | "AUTHCTCD_LANDLINE_NUMBER"
  | "AUTHCTCD_FIRST_NAME"
  | "AUTHCTCD_FAMILY_NAME"
  | "AUTHCTCD_EMAIL_ID"
  | "APPLIED_FOR_TIN"
>;

export type UraIndividualsQueryVariables = Exact<{ [key: string]: never }>;

export type UraIndividualsQuery = { __typename?: "query_root" } & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & UraIndividualFragment
  >;
};

export type UraIndividualUniversalFilterQueryVariables = Exact<{
  condition: Ura_RegIndividual_Bool_Exp;
}>;

export type UraIndividualUniversalFilterQuery = {
  __typename?: "query_root";
} & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & UraIndividualFragment
  >;
};

export type UraIndividualFilterQueryVariables = Exact<{
  condition: Ura_RegIndividual_Bool_Exp;
  columnName: Ura_RegIndividual_Select_Column;
}>;

export type UraIndividualFilterQuery = { __typename?: "query_root" } & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & UraIndividualFragment
  >;
};

export type GetPaginatedRegIndividualQueryVariables = Exact<{
  where: Ura_RegIndividual_Bool_Exp;
  orderBy?: Maybe<
    Array<Ura_RegIndividual_Order_By> | Ura_RegIndividual_Order_By
  >;
  limit: Scalars["Int"];
  offset?: Maybe<Scalars["Int"]>;
}>;

export type GetPaginatedRegIndividualQuery = { __typename?: "query_root" } & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & UraIndividualFragment
  >;
  ura_RegIndividual_aggregate: {
    __typename?: "ura_RegIndividual_aggregate";
  } & {
    aggregate?: Maybe<
      { __typename?: "ura_RegIndividual_aggregate_fields" } & Pick<
        Ura_RegIndividual_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type UraIndividualTaxFragment = {
  __typename?: "ura_RPT_IndividualTaxSummaryData";
} & Pick<
  Ura_Rpt_IndividualTaxSummaryData,
  | "TIN_NO"
  | "IS_AMENDMENT"
  | "IS_RESIDENT"
  | "RTN_NO"
  | "RTN_PERIOD_YEAR"
  | "RTN_FROM_DT"
  | "RTN_TO_DT"
  | "RTN_DT"
  | "EMPLYMNT_INCOME"
  | "EMPLYMNT_TAX"
  | "EMPLYMNT_RATE"
  | "RENTINC_INCOME"
  | "RENTINC_TAX"
  | "RENTINC_RATE"
  | "PROPINC_INCOME"
  | "PROPINC_TAX"
  | "PROPINC_RATE"
  | "Tot_INCOME"
  | "Tot_TAX"
  | "Tot_RATE"
  | "FIRST_NAME"
  | "SUR_NAME"
  | "IS_INCOME_SRC_RENTAL"
  | "TOT_RENTAL_INCM"
  | "PARTNER_RENT_INCOME"
  | "GROSS_RENTAL_INCM"
  | "DEDUCT_INCM_TAX"
  | "MOTGAGE_INT_DECD"
  | "NET_RENT_INCM"
  | "THRESHOLD_AMNT"
  | "CHRGD_RENTAL_INCM"
  | "TAX_ASSESSED"
  | "NON_RENTAL_INCOME"
>;

export type UraIndividualTaxUniversalFilterQueryVariables = Exact<{
  condition: Ura_Rpt_IndividualTaxSummaryData_Bool_Exp;
}>;

export type UraIndividualTaxUniversalFilterQuery = {
  __typename?: "query_root";
} & {
  ura_RPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "ura_RPT_IndividualTaxSummaryData";
    } & UraIndividualTaxFragment
  >;
};

export type UraIndividualTaxFilterQueryVariables = Exact<{
  condition: Ura_Rpt_IndividualTaxSummaryData_Bool_Exp;
  columnName: Ura_Rpt_IndividualTaxSummaryData_Select_Column;
}>;

export type UraIndividualTaxFilterQuery = { __typename?: "query_root" } & {
  ura_RPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "ura_RPT_IndividualTaxSummaryData";
    } & UraIndividualTaxFragment
  >;
};

export type GetPaginatedRegIndividualsTaxQueryVariables = Exact<{
  where: Ura_Rpt_IndividualTaxSummaryData_Bool_Exp;
  orderBy?: Maybe<
    | Array<Ura_Rpt_IndividualTaxSummaryData_Order_By>
    | Ura_Rpt_IndividualTaxSummaryData_Order_By
  >;
  limit: Scalars["Int"];
  offset?: Maybe<Scalars["Int"]>;
}>;

export type GetPaginatedRegIndividualsTaxQuery = {
  __typename?: "query_root";
} & {
  ura_RPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "ura_RPT_IndividualTaxSummaryData";
    } & UraIndividualTaxFragment
  >;
  ura_RPT_IndividualTaxSummaryData_aggregate: {
    __typename?: "ura_RPT_IndividualTaxSummaryData_aggregate";
  } & {
    aggregate?: Maybe<
      {
        __typename?: "ura_RPT_IndividualTaxSummaryData_aggregate_fields";
      } & Pick<Ura_Rpt_IndividualTaxSummaryData_Aggregate_Fields, "count">
    >;
  };
};

export type UraIndividualQueryVariables = Exact<{
  tin?: Maybe<Scalars["String"]>;
}>;

export type UraIndividualQuery = { __typename?: "query_root" } & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & UraIndividualFragment
  >;
};

export type IndividualBusinessesFragment = {
  __typename?: "ursb_CompaniesAndBusinessNamesURSB";
} & Pick<
  Ursb_CompaniesAndBusinessNamesUrsb,
  | "Business_Name"
  | "Company_Name"
  | "Company_Type"
  | "District"
  | "Director_Surname"
  | "Director_FirstName"
  | "Registration_Number"
  | "Shareholder_FirstName"
  | "Shareholders_Surname"
  | "Data_Source"
  | "Parish"
  | "Partners_FirstName"
  | "Partners_Surname"
  | "Registration_Date"
  | "Street"
  | "SubType"
  | "Town"
  | "Trading"
  | "Village"
>;

export type IndividualBusinessesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IndividualBusinessesQuery = { __typename?: "query_root" } & {
  ursb_CompaniesAndBusinessNamesURSB: Array<
    {
      __typename?: "ursb_CompaniesAndBusinessNamesURSB";
    } & IndividualBusinessesFragment
  >;
};

export type KccaCustomerNonIndividualFragment = {
  __typename?: "kcca_Customer";
} & Pick<
  Kcca_Customer,
  | "coin"
  | "tin"
  | "entity_legal_name"
  | "business_name"
  | "building_name"
  | "date_of_incorporation"
  | "trading_center"
  | "customer_type_id"
  | "customer_applicant_type_id"
  | "customer_business_type_id"
  | "telephone"
  | "mobile"
  | "second_mobile"
  | "email"
>;

export type KccaCustomerNonIndividualQueryVariables = Exact<{
  id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerNonIndividualQuery = { __typename?: "query_root" } & {
  kcca_Customer: Array<
    { __typename?: "kcca_Customer" } & KccaCustomerNonIndividualFragment
  >;
};

export type KccaCustomerTypeQueryVariables = Exact<{
  customer_type_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerTypeQuery = { __typename?: "query_root" } & {
  kcca_CustomerType: Array<
    { __typename?: "kcca_CustomerType" } & Pick<Kcca_CustomerType, "name">
  >;
};

export type KccaCustomerFragment = { __typename?: "kcca_Customer" } & Pick<
  Kcca_Customer,
  | "firstname"
  | "middle_name"
  | "surname"
  | "email"
  | "mother_maiden_name"
  | "coin"
  | "tin"
  | "identification_national_id"
  | "identification_nssf_number"
  | "identification_passport_no"
  | "plot_number"
  | "street_name"
  | "residential_address_village_id"
  | "residential_address_parish_id"
  | "residential_address_sub_county_id"
  | "residential_address_county_id"
  | "residential_address_district_id"
  | "mobile"
  | "second_mobile"
  | "telephone"
>;

export type KccaCustomerQueryVariables = Exact<{
  id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerQuery = { __typename?: "query_root" } & {
  kcca_Customer: Array<{ __typename?: "kcca_Customer" } & KccaCustomerFragment>;
};

export type KccaCustomerVillageQueryVariables = Exact<{
  village_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerVillageQuery = { __typename?: "query_root" } & {
  kcca_Village: Array<
    { __typename?: "kcca_Village" } & Pick<Kcca_Village, "name">
  >;
};

export type KccaCustomerParishQueryVariables = Exact<{
  parish_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerParishQuery = { __typename?: "query_root" } & {
  kcca_Parish: Array<
    { __typename?: "kcca_Parish" } & Pick<Kcca_Parish, "name">
  >;
};

export type KccaCustomerSubcountyQueryVariables = Exact<{
  subcounty_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerSubcountyQuery = { __typename?: "query_root" } & {
  kcca_SubCounty: Array<
    { __typename?: "kcca_SubCounty" } & Pick<Kcca_SubCounty, "name">
  >;
};

export type KccaApplicantTypeQueryVariables = Exact<{
  applicant_type_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaApplicantTypeQuery = { __typename?: "query_root" } & {
  kcca_CustomerApplicantType: Array<
    { __typename?: "kcca_CustomerApplicantType" } & Pick<
      Kcca_CustomerApplicantType,
      "name"
    >
  >;
};

export type KccaCustomerCountyQueryVariables = Exact<{
  county_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerCountyQuery = { __typename?: "query_root" } & {
  kcca_County: Array<
    { __typename?: "kcca_County" } & Pick<Kcca_County, "name">
  >;
};

export type KccaBusinessTypeQueryVariables = Exact<{
  business_type_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaBusinessTypeQuery = { __typename?: "query_root" } & {
  kcca_CustomerBusinessType: Array<
    { __typename?: "kcca_CustomerBusinessType" } & Pick<
      Kcca_CustomerBusinessType,
      "name"
    >
  >;
};

export type KccaCustomerDistrictQueryVariables = Exact<{
  district_id?: Maybe<Scalars["bigint"]>;
}>;

export type KccaCustomerDistrictQuery = { __typename?: "query_root" } & {
  kcca_District: Array<
    { __typename?: "kcca_District" } & Pick<Kcca_District, "name">
  >;
};

export type RegIndividualAffiliatesFragment = {
  __typename?: "ura_RegIndividual";
} & Pick<
  Ura_RegIndividual,
  | "REF_FAMILY_NAME"
  | "REF_FIRST_NAME"
  | "REF_SURNAME"
  | "REF_TIN"
  | "REF_MOBILE_NUMBER"
  | "REF_EMAIL_ID"
>;

export type RegIndividualAffiliatesQueryVariables = Exact<{
  tin: Scalars["String"];
}>;

export type RegIndividualAffiliatesQuery = { __typename?: "query_root" } & {
  ura_RegIndividual: Array<
    { __typename?: "ura_RegIndividual" } & RegIndividualAffiliatesFragment
  >;
};

export type UraAuthIndividualFragment = {
  __typename?: "ura_RegNonIndividual";
} & Pick<
  Ura_RegNonIndividual,
  | "AUTHCTCD_SURNAME"
  | "TIN_NO"
  | "AUTHCTCD_FIRST_NAME"
  | "AUTHCTCD_OTHER_NAME"
  | "AUTHCTCD_PRSN_DESGN"
  | "AUTHCTCD_MOBILE_NUMBER"
  | "AUTHCTCD_EMAIL_ID"
  | "AUTHCTCD_FAMILY_NAME"
>;

export type UraRegAssociateFragment = {
  __typename?: "ura_RegAssociates";
} & Pick<
  Ura_RegAssociates,
  "FIRST_NAME" | "TIN_NO" | "FAMILY_NAME" | "SURNAME" | "ENTITY_DESIGNATION"
>;

export type NonIndividualAffilatesAndIndividualsQueryVariables = Exact<{
  tin: Scalars["String"];
}>;

export type NonIndividualAffilatesAndIndividualsQuery = {
  __typename?: "query_root";
} & {
  ura_RegNonIndividual: Array<
    { __typename?: "ura_RegNonIndividual" } & UraAuthIndividualFragment
  >;
  ura_RegAssociates: Array<
    { __typename?: "ura_RegAssociates" } & UraRegAssociateFragment
  >;
};

export type NonIndividualIncomeTaxSummariesFragment = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual";
} & Pick<
  Ura_IncomeTaxSummaryNonIndividual,
  | "RTN_PERIOD_YEAR"
  | "RTN_NO"
  | "IS_AMENDMENT"
  | "Tot_INCOME"
  | "Tot_TAX"
  | "Tot_RATE"
  | "RENTINC_INCOME"
  | "RENTINC_TAX"
  | "RENTINC_RATE"
  | "RTN_DT"
>;

export type NonIndividualIncomeTaxSummaryFragment = {
  __typename?: "ura_IncomeTaxSummaryNonIndividual";
} & Pick<
  Ura_IncomeTaxSummaryNonIndividual,
  | "GROSS_RENTAL_INCOME"
  | "TOT_EXP_PURELY_RENTAL"
  | "OPERATING_EXP"
  | "ADMINISTRATING_EXP"
  | "FINANCE_EXP"
  | "PROFIT_LOSS_RENTAL"
  | "NON_ALLOWBL_EXP"
  | "ADJ_PROFIT_LOSS_RENTAL"
  | "ALLOWBL_APPORTIONED_CAP"
  | "OTHR_ALLOWBL_EXP"
  | "ADJ_RENTL_INCM_LOSS"
  | "BRGHT_FWD_ASSESSED_LOSS"
  | "ASSESSED_CHRGBL_RENTL_INCM"
  | "ASSESSED_RENTL_LOSS"
> &
  NonIndividualIncomeTaxSummariesFragment;

export type NonIndividualIncomeTaxSummariesQueryVariables = Exact<{
  tin?: Maybe<Scalars["String"]>;
}>;

export type NonIndividualIncomeTaxSummariesQuery = {
  __typename?: "query_root";
} & {
  ura_IncomeTaxSummaryNonIndividual: Array<
    {
      __typename?: "ura_IncomeTaxSummaryNonIndividual";
    } & NonIndividualIncomeTaxSummariesFragment
  >;
};

export type NonIndividualIncomeTaxSummaryQueryVariables = Exact<{
  rtnNO?: Maybe<Scalars["String"]>;
}>;

export type NonIndividualIncomeTaxSummaryQuery = {
  __typename?: "query_root";
} & {
  ura_IncomeTaxSummaryNonIndividual: Array<
    {
      __typename?: "ura_IncomeTaxSummaryNonIndividual";
    } & NonIndividualIncomeTaxSummaryFragment
  >;
};

export type NonIndividualRentExpensesFragment = {
  __typename?: "ura_RentExpenseDetails";
} & Pick<Ura_RentExpenseDetails, "NAME" | "EXPENSETYPE" | "AMOUNT">;

export type NonIndividualRentExpensesQueryVariables = Exact<{
  rtnNO?: Maybe<Scalars["String"]>;
}>;

export type NonIndividualRentExpensesQuery = { __typename?: "query_root" } & {
  ura_RentExpenseDetails: Array<
    {
      __typename?: "ura_RentExpenseDetails";
    } & NonIndividualRentExpensesFragment
  >;
};

export type NwscTransactionsFragment = {
  __typename?: "nwsc_URATransactions";
} & Pick<
  Nwsc_UraTransactions,
  "year" | "value" | "type" | "month" | "CustomerReference"
>;

export type PropertyUtilitiesFragment = {
  __typename?: "nwsc_Customers";
} & Pick<
  Nwsc_Customers,
  | "CustomerName_Pay"
  | "CustomerReference"
  | "CustomerPhoneNo1_Pay"
  | "CustomerPhoneNo2_Pay"
  | "CustomerPhoneNo3_Pay"
  | "Address_Pay"
  | "Area_Pay"
  | "Latitude_Pay"
  | "Longitude_Pay"
>;

export type MaxMinUraTransactionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MaxMinUraTransactionsQuery = { __typename?: "query_root" } & {
  nwsc_URATransactions_aggregate: {
    __typename?: "nwsc_URATransactions_aggregate";
  } & {
    aggregate?: Maybe<
      { __typename?: "nwsc_URATransactions_aggregate_fields" } & {
        max?: Maybe<
          { __typename?: "nwsc_URATransactions_max_fields" } & Pick<
            Nwsc_UraTransactions_Max_Fields,
            "year"
          >
        >;
        min?: Maybe<
          { __typename?: "nwsc_URATransactions_min_fields" } & Pick<
            Nwsc_UraTransactions_Min_Fields,
            "year"
          >
        >;
      }
    >;
  };
};

export type PropertyUtilitiesQueryVariables = Exact<{
  reference: Scalars["bigint"];
  maxYear: Scalars["bigint"];
}>;

export type PropertyUtilitiesQuery = { __typename?: "query_root" } & {
  nwsc_Customers: Array<
    { __typename?: "nwsc_Customers" } & PropertyUtilitiesFragment
  >;
  nwsc_URATransactions: Array<
    { __typename?: "nwsc_URATransactions" } & NwscTransactionsFragment
  >;
};

export type NwscTransactionsQueryVariables = Exact<{
  reference?: Maybe<Scalars["bigint"]>;
  maxYear?: Maybe<Scalars["bigint"]>;
  minYear?: Maybe<Scalars["bigint"]>;
  maxMonth?: Maybe<Scalars["bigint"]>;
  minMonth?: Maybe<Scalars["bigint"]>;
}>;

export type NwscTransactionsQuery = { __typename?: "query_root" } & {
  nwsc_URATransactions: Array<
    { __typename?: "nwsc_URATransactions" } & NwscTransactionsFragment
  >;
};

export type UraIndividualsWithNoTinFragment = {
  __typename?: "ura_RMatchSummary904";
} & Pick<
  Ura_RMatchSummary904,
  | "CUSTOMER_ID"
  | "KCCA_FIRST_NAME"
  | "KCCA_MIDDLE_NAME"
  | "KCCA_SURNAME"
  | "KCCA_TIN"
  | "KCCA_MOBILE1"
  | "KCCA_MOBILE2"
  | "KCCA_LANDLINE"
  | "KCCA_DOB"
  | "KCCA_EMAIL"
  | "KCCA_NIN"
  | "NUM_PROPERTIES"
  | "TOTAL_RENTED_PROPERTIES"
  | "SUM_GROSS_VALUE"
  | "SUM_RATEABLE_VALUE"
>;

export type UraIndividualsWithNoTinQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UraIndividualsWithNoTinQuery = { __typename?: "query_root" } & {
  ura_RMatchSummary904: Array<
    { __typename?: "ura_RMatchSummary904" } & UraIndividualsWithNoTinFragment
  >;
};

export type UraIndividualWithNoTinFilterQueryVariables = Exact<{
  condition: Ura_RMatchSummary904_Bool_Exp;
}>;

export type UraIndividualWithNoTinFilterQuery = {
  __typename?: "query_root";
} & {
  ura_RMatchSummary904: Array<
    { __typename?: "ura_RMatchSummary904" } & UraIndividualsWithNoTinFragment
  >;
};

export type UraIndividualWithNoTinSuggestionsQueryVariables = Exact<{
  condition: Ura_RMatchSummary904_Bool_Exp;
  columnNames?: Maybe<
    | Array<Ura_RMatchSummary904_Select_Column>
    | Ura_RMatchSummary904_Select_Column
  >;
}>;

export type UraIndividualWithNoTinSuggestionsQuery = {
  __typename?: "query_root";
} & {
  ura_RMatchSummary904: Array<
    { __typename?: "ura_RMatchSummary904" } & UraIndividualsWithNoTinFragment
  >;
};

export type UraRptIndividualTaxSummariesFragment = {
  __typename?: "ura_RPT_IndividualTaxSummaryData";
} & Pick<
  Ura_Rpt_IndividualTaxSummaryData,
  | "RTN_PERIOD_YEAR"
  | "RTN_NO"
  | "IS_AMENDMENT"
  | "Tot_INCOME"
  | "Tot_TAX"
  | "INCOME_TAX_P"
  | "RENTINC_INCOME"
  | "RENTINC_TAX"
  | "RENTAL_TAX_P"
  | "RENTINC_P"
>;

export type UraRptIndividualTaxSummariesQueryVariables = Exact<{
  tin?: Maybe<Scalars["String"]>;
}>;

export type UraRptIndividualTaxSummariesQuery = {
  __typename?: "query_root";
} & {
  ura_RPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "ura_RPT_IndividualTaxSummaryData";
    } & UraRptIndividualTaxSummariesFragment
  >;
};

export type UraRptIndividualTaxSummaryFragment = {
  __typename?: "ura_RPT_IndividualTaxSummaryData";
} & Pick<
  Ura_Rpt_IndividualTaxSummaryData,
  | "GROSS_RENTAL_INCM"
  | "DEDUCT_INCM_TAX"
  | "MOTGAGE_INT_DECD"
  | "CHRGD_RENTAL_INCM"
  | "THRESHOLD_AMNT"
  | "NET_RENT_INCM"
  | "TAX_ASSESSED"
  | "EMPLYMNT_INCOME"
  | "PROPINC_INCOME"
  | "NON_RENTAL_INCOME"
  | "TOT_RENTAL_INCM"
  | "PARTNER_RENT_INCOME"
  | "IS_RESIDENT"
  | "RTN_DT"
> &
  UraRptIndividualTaxSummariesFragment;

export type UraRptIndividualTaxSummaryQueryVariables = Exact<{
  rtnNO?: Maybe<Scalars["String"]>;
}>;

export type UraRptIndividualTaxSummaryQuery = { __typename?: "query_root" } & {
  ura_RPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "ura_RPT_IndividualTaxSummaryData";
    } & UraRptIndividualTaxSummaryFragment
  >;
};

export type IndividualMortgageFragment = {
  __typename?: "ura_IndividualMortgage";
} & Pick<
  Ura_IndividualMortgage,
  | "DEED_NO"
  | "FINANCE_NAME"
  | "FINANCE_TIN"
  | "INTERSET_MORTGAGE"
  | "PERIOD_FROM"
  | "PERIOD_TO"
  | "TOTAL_MORTGAGE"
>;

export type IndividualMortgageQueryVariables = Exact<{
  rtnNO?: Maybe<Scalars["String"]>;
}>;

export type IndividualMortgageQuery = { __typename?: "query_root" } & {
  ura_IndividualMortgage: Array<
    { __typename?: "ura_IndividualMortgage" } & IndividualMortgageFragment
  >;
};

export type IndividualTenantFragment = {
  __typename?: "ura_RentalLandLordIncome";
} & Pick<
  Ura_RentalLandLordIncome,
  | "TENANT_NAME"
  | "TENANT_TIN"
  | "PREMISE_LOCATION"
  | "TENANCY_PERIOD_FROM"
  | "TENANCY_PERIOD_TO"
  | "RENTAL_INCOME"
>;

export type IndividualTenantsQueryVariables = Exact<{
  rtnNO?: Maybe<Scalars["String"]>;
}>;

export type IndividualTenantsQuery = { __typename?: "query_root" } & {
  ura_RentalLandLordIncome: Array<
    { __typename?: "ura_RentalLandLordIncome" } & IndividualTenantFragment
  >;
};

export type IndividualReportsFragment = {
  __typename?: "ura_RMatchSummary916";
} & Pick<
  Ura_RMatchSummary916,
  | "URA_FIRSTNAME"
  | "URA_MIDDLENAME"
  | "URA_SURNAME"
  | "MATCH_SCORE"
  | "URA_TIN"
  | "IS_INCOME_SRC_RENTAL"
  | "MOBILE_NUMBER"
  | "EMAIL_ID"
  | "NATIONAL_ID"
  | "NUM_PROPERTIES"
  | "TOTAL_RENTED_PROPERTIES"
  | "SUM_ADJUSTED_INCOME"
  | "RTN_NO"
  | "IS_AMENDMENT"
  | "RTN_PERIOD_YEAR"
  | "PROPINC_INCOME"
  | "RENTINC_INCOME"
  | "DIFFERENCE"
  | "AVG_RATE"
>;

export type IndividualReportsQueryVariables = Exact<{
  condition: Ura_RMatchSummary916_Bool_Exp;
}>;

export type IndividualReportsQuery = { __typename?: "query_root" } & {
  ura_RMatchSummary916: Array<
    { __typename?: "ura_RMatchSummary916" } & IndividualReportsFragment
  >;
};

export type NonIndividualHoldingOfficersFragment = {
  __typename?: "ura_HoldingOfficers";
} & Pick<Ura_HoldingOfficers, "TIN" | "NAME" | "DESIGNATION" | "ADDRESS">;

export type NonIndividualHoldingOfficersQueryVariables = Exact<{
  tin?: Maybe<Scalars["String"]>;
}>;

export type NonIndividualHoldingOfficersQuery = {
  __typename?: "query_root";
} & {
  ura_HoldingOfficers: Array<
    {
      __typename?: "ura_HoldingOfficers";
    } & NonIndividualHoldingOfficersFragment
  >;
};

export type NonIndividualHoldingOwnersFragment = {
  __typename?: "ura_HoldingOwner";
} & Pick<Ura_HoldingOwner, "TIN" | "NAME" | "ADDRESS" | "PER_SHARE">;

export type NonIndividualHoldingOwnersQueryVariables = Exact<{
  tin?: Maybe<Scalars["String"]>;
}>;

export type NonIndividualHoldingOwnersQuery = { __typename?: "query_root" } & {
  ura_HoldingOwner: Array<
    { __typename?: "ura_HoldingOwner" } & NonIndividualHoldingOwnersFragment
  >;
};

export const RegIndividualDataReportFragmentDoc = gql`
  fragment RegIndividualDataReport on ura_RegIndividual {
    TIN_NO
    TAX_PAYER_TYPE
    TAX_PAYER_NAME
    TAX_PAYER_ID
    SUR_NAME
    REF_TIN
    REF_SURNAME
    REF_PRSN_DESGN
    REF_OTHER_NAME
    REF_MOBILE_NUMBER
    REF_LANDLINE_NUMBER
    REF_FIRST_NAME
    REF_FAMILY_NAME
    REF_EMAIL_ID
    POSTADDR_ID
    POSTADDR_DISTICT_NAME
    OTHER_NAME
    NSSF_ID
    NATIONAL_ID
    MOBILE_NUMBER
    MIDDLE_NAME
    LANDLINE_NUMBER
    IS_INCOME_SRC_RENTAL
    IS_INCOME_SRC_PRPTY
    IS_INCOME_SRC_EMP
    IS_INCOME_SRC_BSNS
    HOME_ADDR_VILLAGE_NAME
    HOME_ADDR_TRADE_CENTRE
    HOME_ADDR_TOWN_NAME
    HOME_ADDR_SUB_COUNTY_NAME
    HOME_ADDR_STRRET_ADDR
    HOME_ADDR_PLOTNO
    HOME_ADDR_PARISH_NAME
    HOME_ADDR_LOCAL_COUNCIL
    HOME_ADDR_DISTRICT_NAME
    HOME_ADDR_BUILDINGNAME
    GENDER
    FIRST_NAME
    FAMILY_NAME
    EXIST_TIN_NO
    EMAIL_ID
    CITIZEN_COUNTRY
    BSNS_NAME
    BIRTH_STATE
    BIRTH_DT
    BIRTH_COUNTRY
    BIRTH_CITY
    AUTHCTCD_TIN
    AUTHCTCD_SURNAME
    AUTHCTCD_PRSN_DESGN
    AUTHCTCD_OTHER_NAME
    AUTHCTCD_MOBILE_NUMBER
    AUTHCTCD_LANDLINE_NUMBER
    AUTHCTCD_FIRST_NAME
    AUTHCTCD_FAMILY_NAME
    AUTHCTCD_EMAIL_ID
    APPLIED_FOR_TIN
  }
`;
export const RegIndividualDataFragmentDoc = gql`
  fragment RegIndividualData on ura_RPT_RegIndividual {
    FULL_NAME
    TIN_NO
    FIRST_NAME
    SUR_NAME
    MIDDLE_NAME
    FAMILY_NAME
    BIRTH_DT
    BIRTH_CITY
    MOBILE_NUMBER
    EMAIL_ID
    HOME_ADDR_PLOTNO
    HOME_ADDR_STRRET_ADDR
    HOME_ADDR_DISTRICT_NAME
    HOME_ADDR_SUB_COUNTY_NAME
    HOME_ADDR_PARISH_NAME
    HOME_ADDR_LOCAL_COUNCIL
    IS_INCOME_SRC_RENTAL
    IS_INCOME_SRC_PRPTY
    IS_INCOME_SRC_BSNS
    IS_INCOME_SRC_EMP
  }
`;
export const RegNonIndividualDataFragmentDoc = gql`
  fragment RegNonIndividualData on ura_RegNonIndividual {
    BSNS_NAME
    TIN_NO
  }
`;
export const RegIndividualSuggestionFragmentDoc = gql`
  fragment RegIndividualSuggestion on ura_RegIndividual {
    TIN_NO
    TAX_PAYER_NAME
    NATIONAL_ID
  }
`;
export const KccaPropertiesFragmentDoc = gql`
  fragment KccaProperties on kcca_Property {
    address
    block_no
    current_rateable_value
    created_date
    id
    plot_no
    flat_no
    house_no
    street_id
    village_id
    parish_id
    division_id
    property_no
    location_address
    property_rented_status_id
    rating_zone_id
    gross_value
    rateable_value
  }
`;
export const IndividualPropertiesFragmentDoc = gql`
  fragment IndividualProperties on kcca_Property {
    plot_no
    property_no
    house_no
    current_rateable_value
    block_no
    address
    rateable_value
    village_id
    street_id
    status_id
    rating_zone_id
    property_rented_status_id
    flat_no
    road_others
    gross_value
    id
    customer_id
    location_address
    last_updated
    property_type_id
    parish_id
    division_id
  }
`;
export const KccaPropertyDetailsFragmentDoc = gql`
  fragment KccaPropertyDetails on kcca_RawProperties1 {
    building_name
    Cordinate_X
    Cordinate_Y
    property_sub_type
    property_type
    RV
    particulars_block_name
    particulars_block_number
    particulars_folio_number
    particulars_house_number
    particulars_is_titled
    particulars_plot_number
    particulars_plot_shape
    particulars_property_name
    particulars_road_street
    particulars_type_of_land_interest
    accomodation_bathroom
    accomodation_bathroom_shower
    accomodation_bedrooms
    accomodation_built_up_area
    accomodation_comment
    accomodation_dining_rooms
    accomodation_entertainment_room
    accomodation_enviromental_noise
    accomodation_garage
    accomodation_kitchen
    accomodation_laundry
    accomodation_others
    accomodation_security
    accomodation_shops
    accomodation_shower
    accomodation_sitting_room_lounge_number
    accomodation_store
    accomodation_study_room
    accomodation_verandah
    accomodation_wc
    accomodation_wc_bathroom
    accomodation_wc_bathroom_shower
    accomodation_wc_shower
    particulars_access
    particulars_frontages
    particulars_gps_eastings_utm
    particulars_gps_northings_utm
    particulars_land_reference
    particulars_neighborhood
    particulars_topography_Others
    particulars_topograpy
    particulars_volume
    property_owner_occupied_or_rented
    pvillage
    id
  }
`;
export const UraNonIndividualFragmentDoc = gql`
  fragment UraNonIndividual on ura_RegNonIndividual {
    BSNS_CERT_NUMBER
    BSNSADDR_PLOT_NO
    BSNSADDR_STREET_ADDR
    BSNSADDR_BUILDNAME
    BSNSADDR_DISTRICT_NAME
    BSNSADDR_TOWN_NAME
    BSNSADDR_SUB_COUNTY_NAME
    BSNSADDR_PARISH_NAME
    BSNSADDR_VILLAGE_NAME
    TRADING_NAME
    EMAIL_ID
    LANDLINE_NUMBER
    MOBILE_NUMBER
    IS_INCOME_SRC_RENTAL
    IS_INCOME_SRC_BSNS
    IS_INCOME_SRC_PRPTY
    TIN_NO
    TAX_PAYER_NAME
  }
`;
export const UraIndividualFragmentDoc = gql`
  fragment UraIndividual on ura_RegIndividual {
    TIN_NO
    TAX_PAYER_TYPE
    TAX_PAYER_NAME
    TAX_PAYER_ID
    SUR_NAME
    REF_TIN
    REF_SURNAME
    REF_PRSN_DESGN
    REF_OTHER_NAME
    REF_MOBILE_NUMBER
    REF_LANDLINE_NUMBER
    REF_FIRST_NAME
    REF_FAMILY_NAME
    REF_EMAIL_ID
    POSTADDR_ID
    POSTADDR_DISTICT_NAME
    OTHER_NAME
    NSSF_ID
    NATIONAL_ID
    MOBILE_NUMBER
    MIDDLE_NAME
    LANDLINE_NUMBER
    IS_INCOME_SRC_RENTAL
    IS_INCOME_SRC_PRPTY
    IS_INCOME_SRC_EMP
    IS_INCOME_SRC_BSNS
    HOME_ADDR_VILLAGE_NAME
    HOME_ADDR_TRADE_CENTRE
    HOME_ADDR_TOWN_NAME
    HOME_ADDR_SUB_COUNTY_NAME
    HOME_ADDR_STRRET_ADDR
    HOME_ADDR_PLOTNO
    HOME_ADDR_PARISH_NAME
    HOME_ADDR_LOCAL_COUNCIL
    HOME_ADDR_DISTRICT_NAME
    HOME_ADDR_BUILDINGNAME
    GENDER
    FIRST_NAME
    FAMILY_NAME
    EXIST_TIN_NO
    EMAIL_ID
    CITIZEN_COUNTRY
    BSNS_NAME
    BIRTH_STATE
    BIRTH_DT
    BIRTH_COUNTRY
    BIRTH_CITY
    AUTHCTCD_TIN
    AUTHCTCD_SURNAME
    AUTHCTCD_PRSN_DESGN
    AUTHCTCD_OTHER_NAME
    AUTHCTCD_MOBILE_NUMBER
    AUTHCTCD_LANDLINE_NUMBER
    AUTHCTCD_FIRST_NAME
    AUTHCTCD_FAMILY_NAME
    AUTHCTCD_EMAIL_ID
    APPLIED_FOR_TIN
  }
`;
export const UraIndividualTaxFragmentDoc = gql`
  fragment UraIndividualTax on ura_RPT_IndividualTaxSummaryData {
    TIN_NO
    IS_AMENDMENT
    IS_RESIDENT
    RTN_NO
    RTN_PERIOD_YEAR
    RTN_FROM_DT
    RTN_TO_DT
    RTN_DT
    EMPLYMNT_INCOME
    EMPLYMNT_TAX
    EMPLYMNT_RATE
    RENTINC_INCOME
    RENTINC_TAX
    RENTINC_RATE
    PROPINC_INCOME
    PROPINC_TAX
    PROPINC_RATE
    Tot_INCOME
    Tot_TAX
    Tot_RATE
    FIRST_NAME
    SUR_NAME
    IS_INCOME_SRC_RENTAL
    TOT_RENTAL_INCM
    PARTNER_RENT_INCOME
    GROSS_RENTAL_INCM
    DEDUCT_INCM_TAX
    MOTGAGE_INT_DECD
    NET_RENT_INCM
    THRESHOLD_AMNT
    CHRGD_RENTAL_INCM
    TAX_ASSESSED
    NON_RENTAL_INCOME
  }
`;
export const IndividualBusinessesFragmentDoc = gql`
  fragment IndividualBusinesses on ursb_CompaniesAndBusinessNamesURSB {
    Business_Name
    Company_Name
    Company_Type
    District
    Director_Surname
    Director_FirstName
    Registration_Number
    Shareholder_FirstName
    Shareholders_Surname
    Data_Source
    Parish
    Partners_FirstName
    Partners_Surname
    Registration_Date
    Street
    SubType
    Town
    Trading
    Village
  }
`;
export const KccaCustomerNonIndividualFragmentDoc = gql`
  fragment kccaCustomerNonIndividual on kcca_Customer {
    coin
    tin
    entity_legal_name
    business_name
    building_name
    date_of_incorporation
    trading_center
    customer_type_id
    customer_applicant_type_id
    customer_business_type_id
    telephone
    mobile
    second_mobile
    email
  }
`;
export const KccaCustomerFragmentDoc = gql`
  fragment kccaCustomer on kcca_Customer {
    firstname
    middle_name
    surname
    email
    mother_maiden_name
    coin
    tin
    identification_national_id
    identification_nssf_number
    identification_passport_no
    plot_number
    street_name
    residential_address_village_id
    residential_address_parish_id
    residential_address_sub_county_id
    residential_address_county_id
    residential_address_district_id
    mobile
    second_mobile
    telephone
  }
`;
export const RegIndividualAffiliatesFragmentDoc = gql`
  fragment RegIndividualAffiliates on ura_RegIndividual {
    REF_FAMILY_NAME
    REF_FIRST_NAME
    REF_SURNAME
    REF_TIN
    REF_MOBILE_NUMBER
    REF_EMAIL_ID
  }
`;
export const UraAuthIndividualFragmentDoc = gql`
  fragment UraAuthIndividual on ura_RegNonIndividual {
    AUTHCTCD_SURNAME
    TIN_NO
    AUTHCTCD_FIRST_NAME
    AUTHCTCD_OTHER_NAME
    AUTHCTCD_PRSN_DESGN
    AUTHCTCD_MOBILE_NUMBER
    AUTHCTCD_EMAIL_ID
    AUTHCTCD_FAMILY_NAME
  }
`;
export const UraRegAssociateFragmentDoc = gql`
  fragment UraRegAssociate on ura_RegAssociates {
    FIRST_NAME
    TIN_NO
    FAMILY_NAME
    SURNAME
    ENTITY_DESIGNATION
  }
`;
export const NonIndividualIncomeTaxSummariesFragmentDoc = gql`
  fragment NonIndividualIncomeTaxSummaries on ura_IncomeTaxSummaryNonIndividual {
    RTN_PERIOD_YEAR
    RTN_NO
    IS_AMENDMENT
    Tot_INCOME
    Tot_TAX
    Tot_RATE
    RENTINC_INCOME
    RENTINC_TAX
    RENTINC_RATE
    RTN_DT
  }
`;
export const NonIndividualIncomeTaxSummaryFragmentDoc = gql`
  fragment NonIndividualIncomeTaxSummary on ura_IncomeTaxSummaryNonIndividual {
    ...NonIndividualIncomeTaxSummaries
    GROSS_RENTAL_INCOME
    TOT_EXP_PURELY_RENTAL
    OPERATING_EXP
    ADMINISTRATING_EXP
    FINANCE_EXP
    PROFIT_LOSS_RENTAL
    NON_ALLOWBL_EXP
    ADJ_PROFIT_LOSS_RENTAL
    ALLOWBL_APPORTIONED_CAP
    OTHR_ALLOWBL_EXP
    ADJ_RENTL_INCM_LOSS
    BRGHT_FWD_ASSESSED_LOSS
    ASSESSED_CHRGBL_RENTL_INCM
    ASSESSED_RENTL_LOSS
  }
  ${NonIndividualIncomeTaxSummariesFragmentDoc}
`;
export const NonIndividualRentExpensesFragmentDoc = gql`
  fragment NonIndividualRentExpenses on ura_RentExpenseDetails {
    NAME
    EXPENSETYPE
    AMOUNT
  }
`;
export const NwscTransactionsFragmentDoc = gql`
  fragment NwscTransactions on nwsc_URATransactions {
    year
    value
    type
    month
    CustomerReference
  }
`;
export const PropertyUtilitiesFragmentDoc = gql`
  fragment PropertyUtilities on nwsc_Customers {
    CustomerName_Pay
    CustomerReference
    CustomerPhoneNo1_Pay
    CustomerPhoneNo2_Pay
    CustomerPhoneNo3_Pay
    Address_Pay
    Area_Pay
    Latitude_Pay
    Longitude_Pay
  }
`;
export const UraIndividualsWithNoTinFragmentDoc = gql`
  fragment UraIndividualsWithNoTin on ura_RMatchSummary904 {
    CUSTOMER_ID
    KCCA_FIRST_NAME
    KCCA_MIDDLE_NAME
    KCCA_SURNAME
    KCCA_TIN
    KCCA_MOBILE1
    KCCA_MOBILE2
    KCCA_LANDLINE
    KCCA_DOB
    KCCA_EMAIL
    KCCA_NIN
    NUM_PROPERTIES
    TOTAL_RENTED_PROPERTIES
    SUM_GROSS_VALUE
    SUM_RATEABLE_VALUE
  }
`;
export const UraRptIndividualTaxSummariesFragmentDoc = gql`
  fragment UraRPTIndividualTaxSummaries on ura_RPT_IndividualTaxSummaryData {
    RTN_PERIOD_YEAR
    RTN_NO
    IS_AMENDMENT
    Tot_INCOME
    Tot_TAX
    INCOME_TAX_P
    RENTINC_INCOME
    RENTINC_TAX
    RENTAL_TAX_P
    RENTINC_P
  }
`;
export const UraRptIndividualTaxSummaryFragmentDoc = gql`
  fragment UraRPTIndividualTaxSummary on ura_RPT_IndividualTaxSummaryData {
    ...UraRPTIndividualTaxSummaries
    GROSS_RENTAL_INCM
    DEDUCT_INCM_TAX
    MOTGAGE_INT_DECD
    CHRGD_RENTAL_INCM
    THRESHOLD_AMNT
    NET_RENT_INCM
    TAX_ASSESSED
    EMPLYMNT_INCOME
    PROPINC_INCOME
    NON_RENTAL_INCOME
    TOT_RENTAL_INCM
    PARTNER_RENT_INCOME
    IS_RESIDENT
    RTN_DT
  }
  ${UraRptIndividualTaxSummariesFragmentDoc}
`;
export const IndividualMortgageFragmentDoc = gql`
  fragment IndividualMortgage on ura_IndividualMortgage {
    DEED_NO
    FINANCE_NAME
    FINANCE_TIN
    INTERSET_MORTGAGE
    PERIOD_FROM
    PERIOD_TO
    TOTAL_MORTGAGE
  }
`;
export const IndividualTenantFragmentDoc = gql`
  fragment IndividualTenant on ura_RentalLandLordIncome {
    TENANT_NAME
    TENANT_TIN
    PREMISE_LOCATION
    TENANCY_PERIOD_FROM
    TENANCY_PERIOD_TO
    RENTAL_INCOME
  }
`;
export const IndividualReportsFragmentDoc = gql`
  fragment IndividualReports on ura_RMatchSummary916 {
    URA_FIRSTNAME
    URA_MIDDLENAME
    URA_SURNAME
    MATCH_SCORE
    URA_TIN
    IS_INCOME_SRC_RENTAL
    MOBILE_NUMBER
    EMAIL_ID
    NATIONAL_ID
    NUM_PROPERTIES
    TOTAL_RENTED_PROPERTIES
    SUM_ADJUSTED_INCOME
    RTN_NO
    IS_AMENDMENT
    RTN_PERIOD_YEAR
    PROPINC_INCOME
    RENTINC_INCOME
    DIFFERENCE
    AVG_RATE
  }
`;
export const NonIndividualHoldingOfficersFragmentDoc = gql`
  fragment NonIndividualHoldingOfficers on ura_HoldingOfficers {
    TIN
    NAME
    DESIGNATION
    ADDRESS
  }
`;
export const NonIndividualHoldingOwnersFragmentDoc = gql`
  fragment NonIndividualHoldingOwners on ura_HoldingOwner {
    TIN
    NAME
    ADDRESS
    PER_SHARE
  }
`;
export const RangeSliderTestQueryDocument = gql`
  query RangeSliderTestQuery {
    ura_IncomeTaxSummaryIndividual_aggregate {
      aggregate {
        min {
          Tot_INCOME
        }
        max {
          Tot_INCOME
        }
      }
    }
  }
`;

/**
 * __useRangeSliderTestQueryQuery__
 *
 * To run a query within a React component, call `useRangeSliderTestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRangeSliderTestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRangeSliderTestQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useRangeSliderTestQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >
) {
  return Apollo.useQuery<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >(RangeSliderTestQueryDocument, baseOptions);
}
export function useRangeSliderTestQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >(RangeSliderTestQueryDocument, baseOptions);
}
export type RangeSliderTestQueryQueryHookResult = ReturnType<
  typeof useRangeSliderTestQueryQuery
>;
export type RangeSliderTestQueryLazyQueryHookResult = ReturnType<
  typeof useRangeSliderTestQueryLazyQuery
>;
export type RangeSliderTestQueryQueryResult = Apollo.QueryResult<
  RangeSliderTestQueryQuery,
  RangeSliderTestQueryQueryVariables
>;
export const RegIndividualDataReportDocument = gql`
  query RegIndividualDataReport {
    ura_RegIndividual(limit: 100) {
      ...RegIndividualDataReport
    }
  }
  ${RegIndividualDataReportFragmentDoc}
`;

/**
 * __useRegIndividualDataReportQuery__
 *
 * To run a query within a React component, call `useRegIndividualDataReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegIndividualDataReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegIndividualDataReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegIndividualDataReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegIndividualDataReportQuery,
    RegIndividualDataReportQueryVariables
  >
) {
  return Apollo.useQuery<
    RegIndividualDataReportQuery,
    RegIndividualDataReportQueryVariables
  >(RegIndividualDataReportDocument, baseOptions);
}
export function useRegIndividualDataReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegIndividualDataReportQuery,
    RegIndividualDataReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegIndividualDataReportQuery,
    RegIndividualDataReportQueryVariables
  >(RegIndividualDataReportDocument, baseOptions);
}
export type RegIndividualDataReportQueryHookResult = ReturnType<
  typeof useRegIndividualDataReportQuery
>;
export type RegIndividualDataReportLazyQueryHookResult = ReturnType<
  typeof useRegIndividualDataReportLazyQuery
>;
export type RegIndividualDataReportQueryResult = Apollo.QueryResult<
  RegIndividualDataReportQuery,
  RegIndividualDataReportQueryVariables
>;
export const RegIndividualDataDocument = gql`
  query RegIndividualData {
    ura_RPT_RegIndividual(limit: 100) {
      ...RegIndividualData
    }
  }
  ${RegIndividualDataFragmentDoc}
`;

/**
 * __useRegIndividualDataQuery__
 *
 * To run a query within a React component, call `useRegIndividualDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegIndividualDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegIndividualDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegIndividualDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >
) {
  return Apollo.useQuery<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >(RegIndividualDataDocument, baseOptions);
}
export function useRegIndividualDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >(RegIndividualDataDocument, baseOptions);
}
export type RegIndividualDataQueryHookResult = ReturnType<
  typeof useRegIndividualDataQuery
>;
export type RegIndividualDataLazyQueryHookResult = ReturnType<
  typeof useRegIndividualDataLazyQuery
>;
export type RegIndividualDataQueryResult = Apollo.QueryResult<
  RegIndividualDataQuery,
  RegIndividualDataQueryVariables
>;
export const GetPaginatedRegIndividualsDocument = gql`
  query GetPaginatedRegIndividuals(
    $where: ura_RPT_RegIndividual_bool_exp!
    $orderBy: [ura_RPT_RegIndividual_order_by!]
    $limit: Int!
    $offset: Int
  ) {
    ura_RPT_RegIndividual(
      limit: $limit
      order_by: $orderBy
      where: $where
      offset: $offset
    ) {
      ...RegIndividualData
    }
    ura_RPT_RegIndividual_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${RegIndividualDataFragmentDoc}
`;

/**
 * __useGetPaginatedRegIndividualsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegIndividualsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPaginatedRegIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >(GetPaginatedRegIndividualsDocument, baseOptions);
}
export function useGetPaginatedRegIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >(GetPaginatedRegIndividualsDocument, baseOptions);
}
export type GetPaginatedRegIndividualsQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsQuery
>;
export type GetPaginatedRegIndividualsLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsLazyQuery
>;
export type GetPaginatedRegIndividualsQueryResult = Apollo.QueryResult<
  GetPaginatedRegIndividualsQuery,
  GetPaginatedRegIndividualsQueryVariables
>;
export const RegNonIndividualDataDocument = gql`
  query RegNonIndividualData {
    ura_RegNonIndividual(limit: 100) {
      ...RegNonIndividualData
    }
  }
  ${RegNonIndividualDataFragmentDoc}
`;

/**
 * __useRegNonIndividualDataQuery__
 *
 * To run a query within a React component, call `useRegNonIndividualDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegNonIndividualDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegNonIndividualDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegNonIndividualDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegNonIndividualDataQuery,
    RegNonIndividualDataQueryVariables
  >
) {
  return Apollo.useQuery<
    RegNonIndividualDataQuery,
    RegNonIndividualDataQueryVariables
  >(RegNonIndividualDataDocument, baseOptions);
}
export function useRegNonIndividualDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegNonIndividualDataQuery,
    RegNonIndividualDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegNonIndividualDataQuery,
    RegNonIndividualDataQueryVariables
  >(RegNonIndividualDataDocument, baseOptions);
}
export type RegNonIndividualDataQueryHookResult = ReturnType<
  typeof useRegNonIndividualDataQuery
>;
export type RegNonIndividualDataLazyQueryHookResult = ReturnType<
  typeof useRegNonIndividualDataLazyQuery
>;
export type RegNonIndividualDataQueryResult = Apollo.QueryResult<
  RegNonIndividualDataQuery,
  RegNonIndividualDataQueryVariables
>;
export const RegIndividualSuggestionsDocument = gql`
  query RegIndividualSuggestions($searchString: String!) {
    ura_RegIndividual(
      limit: 10
      where: {
        _or: [
          { NATIONAL_ID: { _ilike: $searchString } }
          { TAX_PAYER_NAME: { _ilike: $searchString } }
        ]
      }
      order_by: { TAX_PAYER_NAME: asc }
    ) {
      ...RegIndividualSuggestion
    }
  }
  ${RegIndividualSuggestionFragmentDoc}
`;

/**
 * __useRegIndividualSuggestionsQuery__
 *
 * To run a query within a React component, call `useRegIndividualSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegIndividualSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegIndividualSuggestionsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useRegIndividualSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >
) {
  return Apollo.useQuery<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >(RegIndividualSuggestionsDocument, baseOptions);
}
export function useRegIndividualSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >(RegIndividualSuggestionsDocument, baseOptions);
}
export type RegIndividualSuggestionsQueryHookResult = ReturnType<
  typeof useRegIndividualSuggestionsQuery
>;
export type RegIndividualSuggestionsLazyQueryHookResult = ReturnType<
  typeof useRegIndividualSuggestionsLazyQuery
>;
export type RegIndividualSuggestionsQueryResult = Apollo.QueryResult<
  RegIndividualSuggestionsQuery,
  RegIndividualSuggestionsQueryVariables
>;
export const KccaPropertiesDocument = gql`
  query KccaProperties($propertyId: bigint!) {
    kcca_Property(limit: 1, where: { id: { _eq: $propertyId } }) {
      ...KccaProperties
    }
  }
  ${KccaPropertiesFragmentDoc}
`;

/**
 * __useKccaPropertiesQuery__
 *
 * To run a query within a React component, call `useKccaPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaPropertiesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useKccaPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaPropertiesQuery,
    KccaPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<KccaPropertiesQuery, KccaPropertiesQueryVariables>(
    KccaPropertiesDocument,
    baseOptions
  );
}
export function useKccaPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaPropertiesQuery,
    KccaPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<KccaPropertiesQuery, KccaPropertiesQueryVariables>(
    KccaPropertiesDocument,
    baseOptions
  );
}
export type KccaPropertiesQueryHookResult = ReturnType<
  typeof useKccaPropertiesQuery
>;
export type KccaPropertiesLazyQueryHookResult = ReturnType<
  typeof useKccaPropertiesLazyQuery
>;
export type KccaPropertiesQueryResult = Apollo.QueryResult<
  KccaPropertiesQuery,
  KccaPropertiesQueryVariables
>;
export const IndividualPropertiesDocument = gql`
  query IndividualProperties {
    kcca_Property(limit: 10) {
      ...IndividualProperties
    }
  }
  ${IndividualPropertiesFragmentDoc}
`;

/**
 * __useIndividualPropertiesQuery__
 *
 * To run a query within a React component, call `useIndividualPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndividualPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >(IndividualPropertiesDocument, baseOptions);
}
export function useIndividualPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >(IndividualPropertiesDocument, baseOptions);
}
export type IndividualPropertiesQueryHookResult = ReturnType<
  typeof useIndividualPropertiesQuery
>;
export type IndividualPropertiesLazyQueryHookResult = ReturnType<
  typeof useIndividualPropertiesLazyQuery
>;
export type IndividualPropertiesQueryResult = Apollo.QueryResult<
  IndividualPropertiesQuery,
  IndividualPropertiesQueryVariables
>;
export const KccaProperties1Document = gql`
  query KccaProperties1 {
    kcca_RawProperties1(limit: 100) {
      ...KccaPropertyDetails
    }
  }
  ${KccaPropertyDetailsFragmentDoc}
`;

/**
 * __useKccaProperties1Query__
 *
 * To run a query within a React component, call `useKccaProperties1Query` and pass it any options that fit your needs.
 * When your component renders, `useKccaProperties1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaProperties1Query({
 *   variables: {
 *   },
 * });
 */
export function useKccaProperties1Query(
  baseOptions?: Apollo.QueryHookOptions<
    KccaProperties1Query,
    KccaProperties1QueryVariables
  >
) {
  return Apollo.useQuery<KccaProperties1Query, KccaProperties1QueryVariables>(
    KccaProperties1Document,
    baseOptions
  );
}
export function useKccaProperties1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaProperties1Query,
    KccaProperties1QueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaProperties1Query,
    KccaProperties1QueryVariables
  >(KccaProperties1Document, baseOptions);
}
export type KccaProperties1QueryHookResult = ReturnType<
  typeof useKccaProperties1Query
>;
export type KccaProperties1LazyQueryHookResult = ReturnType<
  typeof useKccaProperties1LazyQuery
>;
export type KccaProperties1QueryResult = Apollo.QueryResult<
  KccaProperties1Query,
  KccaProperties1QueryVariables
>;
export const KccaPropertyDetailsDocument = gql`
  query KccaPropertyDetails($propertyId: String!) {
    kcca_RawProperties1(limit: 1, where: { id: { _eq: $propertyId } }) {
      ...KccaPropertyDetails
    }
  }
  ${KccaPropertyDetailsFragmentDoc}
`;

/**
 * __useKccaPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useKccaPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaPropertyDetailsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useKccaPropertyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >(KccaPropertyDetailsDocument, baseOptions);
}
export function useKccaPropertyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >(KccaPropertyDetailsDocument, baseOptions);
}
export type KccaPropertyDetailsQueryHookResult = ReturnType<
  typeof useKccaPropertyDetailsQuery
>;
export type KccaPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useKccaPropertyDetailsLazyQuery
>;
export type KccaPropertyDetailsQueryResult = Apollo.QueryResult<
  KccaPropertyDetailsQuery,
  KccaPropertyDetailsQueryVariables
>;
export const UraNonIndividualDocument = gql`
  query UraNonIndividual($tin: String!) {
    ura_RegNonIndividual(where: { TIN_NO: { _eq: $tin } }) {
      ...UraNonIndividual
    }
  }
  ${UraNonIndividualFragmentDoc}
`;

/**
 * __useUraNonIndividualQuery__
 *
 * To run a query within a React component, call `useUraNonIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraNonIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraNonIndividualQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useUraNonIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraNonIndividualQuery,
    UraNonIndividualQueryVariables
  >
) {
  return Apollo.useQuery<UraNonIndividualQuery, UraNonIndividualQueryVariables>(
    UraNonIndividualDocument,
    baseOptions
  );
}
export function useUraNonIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraNonIndividualQuery,
    UraNonIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraNonIndividualQuery,
    UraNonIndividualQueryVariables
  >(UraNonIndividualDocument, baseOptions);
}
export type UraNonIndividualQueryHookResult = ReturnType<
  typeof useUraNonIndividualQuery
>;
export type UraNonIndividualLazyQueryHookResult = ReturnType<
  typeof useUraNonIndividualLazyQuery
>;
export type UraNonIndividualQueryResult = Apollo.QueryResult<
  UraNonIndividualQuery,
  UraNonIndividualQueryVariables
>;
export const UraIndividualsDocument = gql`
  query UraIndividuals {
    ura_RegIndividual(limit: 100) {
      ...UraIndividual
    }
  }
  ${UraIndividualFragmentDoc}
`;

/**
 * __useUraIndividualsQuery__
 *
 * To run a query within a React component, call `useUraIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUraIndividualsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UraIndividualsQuery,
    UraIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<UraIndividualsQuery, UraIndividualsQueryVariables>(
    UraIndividualsDocument,
    baseOptions
  );
}
export function useUraIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualsQuery,
    UraIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<UraIndividualsQuery, UraIndividualsQueryVariables>(
    UraIndividualsDocument,
    baseOptions
  );
}
export type UraIndividualsQueryHookResult = ReturnType<
  typeof useUraIndividualsQuery
>;
export type UraIndividualsLazyQueryHookResult = ReturnType<
  typeof useUraIndividualsLazyQuery
>;
export type UraIndividualsQueryResult = Apollo.QueryResult<
  UraIndividualsQuery,
  UraIndividualsQueryVariables
>;
export const UraIndividualUniversalFilterDocument = gql`
  query UraIndividualUniversalFilter($condition: ura_RegIndividual_bool_exp!) {
    ura_RegIndividual(limit: 100, where: $condition) {
      ...UraIndividual
    }
  }
  ${UraIndividualFragmentDoc}
`;

/**
 * __useUraIndividualUniversalFilterQuery__
 *
 * To run a query within a React component, call `useUraIndividualUniversalFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualUniversalFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualUniversalFilterQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUraIndividualUniversalFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualUniversalFilterQuery,
    UraIndividualUniversalFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualUniversalFilterQuery,
    UraIndividualUniversalFilterQueryVariables
  >(UraIndividualUniversalFilterDocument, baseOptions);
}
export function useUraIndividualUniversalFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualUniversalFilterQuery,
    UraIndividualUniversalFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualUniversalFilterQuery,
    UraIndividualUniversalFilterQueryVariables
  >(UraIndividualUniversalFilterDocument, baseOptions);
}
export type UraIndividualUniversalFilterQueryHookResult = ReturnType<
  typeof useUraIndividualUniversalFilterQuery
>;
export type UraIndividualUniversalFilterLazyQueryHookResult = ReturnType<
  typeof useUraIndividualUniversalFilterLazyQuery
>;
export type UraIndividualUniversalFilterQueryResult = Apollo.QueryResult<
  UraIndividualUniversalFilterQuery,
  UraIndividualUniversalFilterQueryVariables
>;
export const UraIndividualFilterDocument = gql`
  query UraIndividualFilter(
    $condition: ura_RegIndividual_bool_exp!
    $columnName: ura_RegIndividual_select_column!
  ) {
    ura_RegIndividual(
      limit: 10
      distinct_on: [$columnName]
      where: $condition
    ) {
      ...UraIndividual
    }
  }
  ${UraIndividualFragmentDoc}
`;

/**
 * __useUraIndividualFilterQuery__
 *
 * To run a query within a React component, call `useUraIndividualFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualFilterQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      columnName: // value for 'columnName'
 *   },
 * });
 */
export function useUraIndividualFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >(UraIndividualFilterDocument, baseOptions);
}
export function useUraIndividualFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >(UraIndividualFilterDocument, baseOptions);
}
export type UraIndividualFilterQueryHookResult = ReturnType<
  typeof useUraIndividualFilterQuery
>;
export type UraIndividualFilterLazyQueryHookResult = ReturnType<
  typeof useUraIndividualFilterLazyQuery
>;
export type UraIndividualFilterQueryResult = Apollo.QueryResult<
  UraIndividualFilterQuery,
  UraIndividualFilterQueryVariables
>;
export const GetPaginatedRegIndividualDocument = gql`
  query GetPaginatedRegIndividual(
    $where: ura_RegIndividual_bool_exp!
    $orderBy: [ura_RegIndividual_order_by!]
    $limit: Int!
    $offset: Int
  ) {
    ura_RegIndividual(
      limit: $limit
      order_by: $orderBy
      where: $where
      offset: $offset
    ) {
      ...UraIndividual
    }
    ura_RegIndividual_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${UraIndividualFragmentDoc}
`;

/**
 * __useGetPaginatedRegIndividualQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegIndividualQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPaginatedRegIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >(GetPaginatedRegIndividualDocument, baseOptions);
}
export function useGetPaginatedRegIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >(GetPaginatedRegIndividualDocument, baseOptions);
}
export type GetPaginatedRegIndividualQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualQuery
>;
export type GetPaginatedRegIndividualLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualLazyQuery
>;
export type GetPaginatedRegIndividualQueryResult = Apollo.QueryResult<
  GetPaginatedRegIndividualQuery,
  GetPaginatedRegIndividualQueryVariables
>;
export const UraIndividualTaxUniversalFilterDocument = gql`
  query UraIndividualTaxUniversalFilter(
    $condition: ura_RPT_IndividualTaxSummaryData_bool_exp!
  ) {
    ura_RPT_IndividualTaxSummaryData(limit: 100, where: $condition) {
      ...UraIndividualTax
    }
  }
  ${UraIndividualTaxFragmentDoc}
`;

/**
 * __useUraIndividualTaxUniversalFilterQuery__
 *
 * To run a query within a React component, call `useUraIndividualTaxUniversalFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualTaxUniversalFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualTaxUniversalFilterQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUraIndividualTaxUniversalFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualTaxUniversalFilterQuery,
    UraIndividualTaxUniversalFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualTaxUniversalFilterQuery,
    UraIndividualTaxUniversalFilterQueryVariables
  >(UraIndividualTaxUniversalFilterDocument, baseOptions);
}
export function useUraIndividualTaxUniversalFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualTaxUniversalFilterQuery,
    UraIndividualTaxUniversalFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualTaxUniversalFilterQuery,
    UraIndividualTaxUniversalFilterQueryVariables
  >(UraIndividualTaxUniversalFilterDocument, baseOptions);
}
export type UraIndividualTaxUniversalFilterQueryHookResult = ReturnType<
  typeof useUraIndividualTaxUniversalFilterQuery
>;
export type UraIndividualTaxUniversalFilterLazyQueryHookResult = ReturnType<
  typeof useUraIndividualTaxUniversalFilterLazyQuery
>;
export type UraIndividualTaxUniversalFilterQueryResult = Apollo.QueryResult<
  UraIndividualTaxUniversalFilterQuery,
  UraIndividualTaxUniversalFilterQueryVariables
>;
export const UraIndividualTaxFilterDocument = gql`
  query UraIndividualTaxFilter(
    $condition: ura_RPT_IndividualTaxSummaryData_bool_exp!
    $columnName: ura_RPT_IndividualTaxSummaryData_select_column!
  ) {
    ura_RPT_IndividualTaxSummaryData(
      limit: 10
      distinct_on: [$columnName]
      where: $condition
    ) {
      ...UraIndividualTax
    }
  }
  ${UraIndividualTaxFragmentDoc}
`;

/**
 * __useUraIndividualTaxFilterQuery__
 *
 * To run a query within a React component, call `useUraIndividualTaxFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualTaxFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualTaxFilterQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      columnName: // value for 'columnName'
 *   },
 * });
 */
export function useUraIndividualTaxFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >(UraIndividualTaxFilterDocument, baseOptions);
}
export function useUraIndividualTaxFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >(UraIndividualTaxFilterDocument, baseOptions);
}
export type UraIndividualTaxFilterQueryHookResult = ReturnType<
  typeof useUraIndividualTaxFilterQuery
>;
export type UraIndividualTaxFilterLazyQueryHookResult = ReturnType<
  typeof useUraIndividualTaxFilterLazyQuery
>;
export type UraIndividualTaxFilterQueryResult = Apollo.QueryResult<
  UraIndividualTaxFilterQuery,
  UraIndividualTaxFilterQueryVariables
>;
export const GetPaginatedRegIndividualsTaxDocument = gql`
  query GetPaginatedRegIndividualsTax(
    $where: ura_RPT_IndividualTaxSummaryData_bool_exp!
    $orderBy: [ura_RPT_IndividualTaxSummaryData_order_by!]
    $limit: Int!
    $offset: Int
  ) {
    ura_RPT_IndividualTaxSummaryData(
      limit: $limit
      order_by: $orderBy
      where: $where
      offset: $offset
    ) {
      ...UraIndividualTax
    }
    ura_RPT_IndividualTaxSummaryData_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${UraIndividualTaxFragmentDoc}
`;

/**
 * __useGetPaginatedRegIndividualsTaxQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegIndividualsTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegIndividualsTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegIndividualsTaxQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPaginatedRegIndividualsTaxQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >(GetPaginatedRegIndividualsTaxDocument, baseOptions);
}
export function useGetPaginatedRegIndividualsTaxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >(GetPaginatedRegIndividualsTaxDocument, baseOptions);
}
export type GetPaginatedRegIndividualsTaxQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsTaxQuery
>;
export type GetPaginatedRegIndividualsTaxLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsTaxLazyQuery
>;
export type GetPaginatedRegIndividualsTaxQueryResult = Apollo.QueryResult<
  GetPaginatedRegIndividualsTaxQuery,
  GetPaginatedRegIndividualsTaxQueryVariables
>;
export const UraIndividualDocument = gql`
  query UraIndividual($tin: String) {
    ura_RegIndividual(limit: 1, where: { TIN_NO: { _eq: $tin } }) {
      ...UraIndividual
    }
  }
  ${UraIndividualFragmentDoc}
`;

/**
 * __useUraIndividualQuery__
 *
 * To run a query within a React component, call `useUraIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useUraIndividualQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UraIndividualQuery,
    UraIndividualQueryVariables
  >
) {
  return Apollo.useQuery<UraIndividualQuery, UraIndividualQueryVariables>(
    UraIndividualDocument,
    baseOptions
  );
}
export function useUraIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualQuery,
    UraIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<UraIndividualQuery, UraIndividualQueryVariables>(
    UraIndividualDocument,
    baseOptions
  );
}
export type UraIndividualQueryHookResult = ReturnType<
  typeof useUraIndividualQuery
>;
export type UraIndividualLazyQueryHookResult = ReturnType<
  typeof useUraIndividualLazyQuery
>;
export type UraIndividualQueryResult = Apollo.QueryResult<
  UraIndividualQuery,
  UraIndividualQueryVariables
>;
export const IndividualBusinessesDocument = gql`
  query IndividualBusinesses {
    ursb_CompaniesAndBusinessNamesURSB(limit: 10) {
      ...IndividualBusinesses
    }
  }
  ${IndividualBusinessesFragmentDoc}
`;

/**
 * __useIndividualBusinessesQuery__
 *
 * To run a query within a React component, call `useIndividualBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualBusinessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndividualBusinessesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndividualBusinessesQuery,
    IndividualBusinessesQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualBusinessesQuery,
    IndividualBusinessesQueryVariables
  >(IndividualBusinessesDocument, baseOptions);
}
export function useIndividualBusinessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualBusinessesQuery,
    IndividualBusinessesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualBusinessesQuery,
    IndividualBusinessesQueryVariables
  >(IndividualBusinessesDocument, baseOptions);
}
export type IndividualBusinessesQueryHookResult = ReturnType<
  typeof useIndividualBusinessesQuery
>;
export type IndividualBusinessesLazyQueryHookResult = ReturnType<
  typeof useIndividualBusinessesLazyQuery
>;
export type IndividualBusinessesQueryResult = Apollo.QueryResult<
  IndividualBusinessesQuery,
  IndividualBusinessesQueryVariables
>;
export const KccaCustomerNonIndividualDocument = gql`
  query kccaCustomerNonIndividual($id: bigint) {
    kcca_Customer(limit: 1, where: { id: { _eq: $id } }) {
      ...kccaCustomerNonIndividual
    }
  }
  ${KccaCustomerNonIndividualFragmentDoc}
`;

/**
 * __useKccaCustomerNonIndividualQuery__
 *
 * To run a query within a React component, call `useKccaCustomerNonIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerNonIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerNonIndividualQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKccaCustomerNonIndividualQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >(KccaCustomerNonIndividualDocument, baseOptions);
}
export function useKccaCustomerNonIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >(KccaCustomerNonIndividualDocument, baseOptions);
}
export type KccaCustomerNonIndividualQueryHookResult = ReturnType<
  typeof useKccaCustomerNonIndividualQuery
>;
export type KccaCustomerNonIndividualLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerNonIndividualLazyQuery
>;
export type KccaCustomerNonIndividualQueryResult = Apollo.QueryResult<
  KccaCustomerNonIndividualQuery,
  KccaCustomerNonIndividualQueryVariables
>;
export const KccaCustomerTypeDocument = gql`
  query kccaCustomerType($customer_type_id: bigint) {
    kcca_CustomerType(limit: 1, where: { id: { _eq: $customer_type_id } }) {
      name
    }
  }
`;

/**
 * __useKccaCustomerTypeQuery__
 *
 * To run a query within a React component, call `useKccaCustomerTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerTypeQuery({
 *   variables: {
 *      customer_type_id: // value for 'customer_type_id'
 *   },
 * });
 */
export function useKccaCustomerTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerTypeQuery,
    KccaCustomerTypeQueryVariables
  >
) {
  return Apollo.useQuery<KccaCustomerTypeQuery, KccaCustomerTypeQueryVariables>(
    KccaCustomerTypeDocument,
    baseOptions
  );
}
export function useKccaCustomerTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerTypeQuery,
    KccaCustomerTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerTypeQuery,
    KccaCustomerTypeQueryVariables
  >(KccaCustomerTypeDocument, baseOptions);
}
export type KccaCustomerTypeQueryHookResult = ReturnType<
  typeof useKccaCustomerTypeQuery
>;
export type KccaCustomerTypeLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerTypeLazyQuery
>;
export type KccaCustomerTypeQueryResult = Apollo.QueryResult<
  KccaCustomerTypeQuery,
  KccaCustomerTypeQueryVariables
>;
export const KccaCustomerDocument = gql`
  query kccaCustomer($id: bigint) {
    kcca_Customer(limit: 1, where: { id: { _eq: $id } }) {
      ...kccaCustomer
    }
  }
  ${KccaCustomerFragmentDoc}
`;

/**
 * __useKccaCustomerQuery__
 *
 * To run a query within a React component, call `useKccaCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKccaCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerQuery,
    KccaCustomerQueryVariables
  >
) {
  return Apollo.useQuery<KccaCustomerQuery, KccaCustomerQueryVariables>(
    KccaCustomerDocument,
    baseOptions
  );
}
export function useKccaCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerQuery,
    KccaCustomerQueryVariables
  >
) {
  return Apollo.useLazyQuery<KccaCustomerQuery, KccaCustomerQueryVariables>(
    KccaCustomerDocument,
    baseOptions
  );
}
export type KccaCustomerQueryHookResult = ReturnType<
  typeof useKccaCustomerQuery
>;
export type KccaCustomerLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerLazyQuery
>;
export type KccaCustomerQueryResult = Apollo.QueryResult<
  KccaCustomerQuery,
  KccaCustomerQueryVariables
>;
export const KccaCustomerVillageDocument = gql`
  query kccaCustomerVillage($village_id: bigint) {
    kcca_Village(limit: 1, where: { id: { _eq: $village_id } }) {
      name
    }
  }
`;

/**
 * __useKccaCustomerVillageQuery__
 *
 * To run a query within a React component, call `useKccaCustomerVillageQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerVillageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerVillageQuery({
 *   variables: {
 *      village_id: // value for 'village_id'
 *   },
 * });
 */
export function useKccaCustomerVillageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >(KccaCustomerVillageDocument, baseOptions);
}
export function useKccaCustomerVillageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >(KccaCustomerVillageDocument, baseOptions);
}
export type KccaCustomerVillageQueryHookResult = ReturnType<
  typeof useKccaCustomerVillageQuery
>;
export type KccaCustomerVillageLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerVillageLazyQuery
>;
export type KccaCustomerVillageQueryResult = Apollo.QueryResult<
  KccaCustomerVillageQuery,
  KccaCustomerVillageQueryVariables
>;
export const KccaCustomerParishDocument = gql`
  query kccaCustomerParish($parish_id: bigint) {
    kcca_Parish(limit: 1, where: { id: { _eq: $parish_id } }) {
      name
    }
  }
`;

/**
 * __useKccaCustomerParishQuery__
 *
 * To run a query within a React component, call `useKccaCustomerParishQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerParishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerParishQuery({
 *   variables: {
 *      parish_id: // value for 'parish_id'
 *   },
 * });
 */
export function useKccaCustomerParishQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >(KccaCustomerParishDocument, baseOptions);
}
export function useKccaCustomerParishLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >(KccaCustomerParishDocument, baseOptions);
}
export type KccaCustomerParishQueryHookResult = ReturnType<
  typeof useKccaCustomerParishQuery
>;
export type KccaCustomerParishLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerParishLazyQuery
>;
export type KccaCustomerParishQueryResult = Apollo.QueryResult<
  KccaCustomerParishQuery,
  KccaCustomerParishQueryVariables
>;
export const KccaCustomerSubcountyDocument = gql`
  query kccaCustomerSubcounty($subcounty_id: bigint) {
    kcca_SubCounty(limit: 1, where: { id: { _eq: $subcounty_id } }) {
      name
    }
  }
`;

/**
 * __useKccaCustomerSubcountyQuery__
 *
 * To run a query within a React component, call `useKccaCustomerSubcountyQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerSubcountyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerSubcountyQuery({
 *   variables: {
 *      subcounty_id: // value for 'subcounty_id'
 *   },
 * });
 */
export function useKccaCustomerSubcountyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >(KccaCustomerSubcountyDocument, baseOptions);
}
export function useKccaCustomerSubcountyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >(KccaCustomerSubcountyDocument, baseOptions);
}
export type KccaCustomerSubcountyQueryHookResult = ReturnType<
  typeof useKccaCustomerSubcountyQuery
>;
export type KccaCustomerSubcountyLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerSubcountyLazyQuery
>;
export type KccaCustomerSubcountyQueryResult = Apollo.QueryResult<
  KccaCustomerSubcountyQuery,
  KccaCustomerSubcountyQueryVariables
>;
export const KccaApplicantTypeDocument = gql`
  query kccaApplicantType($applicant_type_id: bigint) {
    kcca_CustomerApplicantType(
      limit: 1
      where: { id: { _eq: $applicant_type_id } }
    ) {
      name
    }
  }
`;

/**
 * __useKccaApplicantTypeQuery__
 *
 * To run a query within a React component, call `useKccaApplicantTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaApplicantTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaApplicantTypeQuery({
 *   variables: {
 *      applicant_type_id: // value for 'applicant_type_id'
 *   },
 * });
 */
export function useKccaApplicantTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >(KccaApplicantTypeDocument, baseOptions);
}
export function useKccaApplicantTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >(KccaApplicantTypeDocument, baseOptions);
}
export type KccaApplicantTypeQueryHookResult = ReturnType<
  typeof useKccaApplicantTypeQuery
>;
export type KccaApplicantTypeLazyQueryHookResult = ReturnType<
  typeof useKccaApplicantTypeLazyQuery
>;
export type KccaApplicantTypeQueryResult = Apollo.QueryResult<
  KccaApplicantTypeQuery,
  KccaApplicantTypeQueryVariables
>;
export const KccaCustomerCountyDocument = gql`
  query kccaCustomerCounty($county_id: bigint) {
    kcca_County(limit: 1, where: { id: { _eq: $county_id } }) {
      name
    }
  }
`;

/**
 * __useKccaCustomerCountyQuery__
 *
 * To run a query within a React component, call `useKccaCustomerCountyQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerCountyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerCountyQuery({
 *   variables: {
 *      county_id: // value for 'county_id'
 *   },
 * });
 */
export function useKccaCustomerCountyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >(KccaCustomerCountyDocument, baseOptions);
}
export function useKccaCustomerCountyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >(KccaCustomerCountyDocument, baseOptions);
}
export type KccaCustomerCountyQueryHookResult = ReturnType<
  typeof useKccaCustomerCountyQuery
>;
export type KccaCustomerCountyLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerCountyLazyQuery
>;
export type KccaCustomerCountyQueryResult = Apollo.QueryResult<
  KccaCustomerCountyQuery,
  KccaCustomerCountyQueryVariables
>;
export const KccaBusinessTypeDocument = gql`
  query kccaBusinessType($business_type_id: bigint) {
    kcca_CustomerBusinessType(
      limit: 1
      where: { id: { _eq: $business_type_id } }
    ) {
      name
    }
  }
`;

/**
 * __useKccaBusinessTypeQuery__
 *
 * To run a query within a React component, call `useKccaBusinessTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaBusinessTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaBusinessTypeQuery({
 *   variables: {
 *      business_type_id: // value for 'business_type_id'
 *   },
 * });
 */
export function useKccaBusinessTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaBusinessTypeQuery,
    KccaBusinessTypeQueryVariables
  >
) {
  return Apollo.useQuery<KccaBusinessTypeQuery, KccaBusinessTypeQueryVariables>(
    KccaBusinessTypeDocument,
    baseOptions
  );
}
export function useKccaBusinessTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaBusinessTypeQuery,
    KccaBusinessTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaBusinessTypeQuery,
    KccaBusinessTypeQueryVariables
  >(KccaBusinessTypeDocument, baseOptions);
}
export type KccaBusinessTypeQueryHookResult = ReturnType<
  typeof useKccaBusinessTypeQuery
>;
export type KccaBusinessTypeLazyQueryHookResult = ReturnType<
  typeof useKccaBusinessTypeLazyQuery
>;
export type KccaBusinessTypeQueryResult = Apollo.QueryResult<
  KccaBusinessTypeQuery,
  KccaBusinessTypeQueryVariables
>;
export const KccaCustomerDistrictDocument = gql`
  query kccaCustomerDistrict($district_id: bigint) {
    kcca_District(limit: 1, where: { id: { _eq: $district_id } }) {
      name
    }
  }
`;

/**
 * __useKccaCustomerDistrictQuery__
 *
 * To run a query within a React component, call `useKccaCustomerDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerDistrictQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useKccaCustomerDistrictQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >(KccaCustomerDistrictDocument, baseOptions);
}
export function useKccaCustomerDistrictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >(KccaCustomerDistrictDocument, baseOptions);
}
export type KccaCustomerDistrictQueryHookResult = ReturnType<
  typeof useKccaCustomerDistrictQuery
>;
export type KccaCustomerDistrictLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerDistrictLazyQuery
>;
export type KccaCustomerDistrictQueryResult = Apollo.QueryResult<
  KccaCustomerDistrictQuery,
  KccaCustomerDistrictQueryVariables
>;
export const RegIndividualAffiliatesDocument = gql`
  query RegIndividualAffiliates($tin: String!) {
    ura_RegIndividual(where: { TIN_NO: { _eq: $tin } }) {
      ...RegIndividualAffiliates
    }
  }
  ${RegIndividualAffiliatesFragmentDoc}
`;

/**
 * __useRegIndividualAffiliatesQuery__
 *
 * To run a query within a React component, call `useRegIndividualAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegIndividualAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegIndividualAffiliatesQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useRegIndividualAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >(RegIndividualAffiliatesDocument, baseOptions);
}
export function useRegIndividualAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >(RegIndividualAffiliatesDocument, baseOptions);
}
export type RegIndividualAffiliatesQueryHookResult = ReturnType<
  typeof useRegIndividualAffiliatesQuery
>;
export type RegIndividualAffiliatesLazyQueryHookResult = ReturnType<
  typeof useRegIndividualAffiliatesLazyQuery
>;
export type RegIndividualAffiliatesQueryResult = Apollo.QueryResult<
  RegIndividualAffiliatesQuery,
  RegIndividualAffiliatesQueryVariables
>;
export const NonIndividualAffilatesAndIndividualsDocument = gql`
  query NonIndividualAffilatesAndIndividuals($tin: String!) {
    ura_RegNonIndividual(where: { TIN_NO: { _eq: $tin } }) {
      ...UraAuthIndividual
    }
    ura_RegAssociates(where: { TIN_NO: { _eq: $tin } }) {
      ...UraRegAssociate
    }
  }
  ${UraAuthIndividualFragmentDoc}
  ${UraRegAssociateFragmentDoc}
`;

/**
 * __useNonIndividualAffilatesAndIndividualsQuery__
 *
 * To run a query within a React component, call `useNonIndividualAffilatesAndIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualAffilatesAndIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualAffilatesAndIndividualsQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useNonIndividualAffilatesAndIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >(NonIndividualAffilatesAndIndividualsDocument, baseOptions);
}
export function useNonIndividualAffilatesAndIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >(NonIndividualAffilatesAndIndividualsDocument, baseOptions);
}
export type NonIndividualAffilatesAndIndividualsQueryHookResult = ReturnType<
  typeof useNonIndividualAffilatesAndIndividualsQuery
>;
export type NonIndividualAffilatesAndIndividualsLazyQueryHookResult =
  ReturnType<typeof useNonIndividualAffilatesAndIndividualsLazyQuery>;
export type NonIndividualAffilatesAndIndividualsQueryResult =
  Apollo.QueryResult<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >;
export const NonIndividualIncomeTaxSummariesDocument = gql`
  query NonIndividualIncomeTaxSummaries($tin: String) {
    ura_IncomeTaxSummaryNonIndividual(where: { TIN_NO: { _eq: $tin } }) {
      ...NonIndividualIncomeTaxSummaries
    }
  }
  ${NonIndividualIncomeTaxSummariesFragmentDoc}
`;

/**
 * __useNonIndividualIncomeTaxSummariesQuery__
 *
 * To run a query within a React component, call `useNonIndividualIncomeTaxSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualIncomeTaxSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualIncomeTaxSummariesQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useNonIndividualIncomeTaxSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >(NonIndividualIncomeTaxSummariesDocument, baseOptions);
}
export function useNonIndividualIncomeTaxSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >(NonIndividualIncomeTaxSummariesDocument, baseOptions);
}
export type NonIndividualIncomeTaxSummariesQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummariesQuery
>;
export type NonIndividualIncomeTaxSummariesLazyQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummariesLazyQuery
>;
export type NonIndividualIncomeTaxSummariesQueryResult = Apollo.QueryResult<
  NonIndividualIncomeTaxSummariesQuery,
  NonIndividualIncomeTaxSummariesQueryVariables
>;
export const NonIndividualIncomeTaxSummaryDocument = gql`
  query NonIndividualIncomeTaxSummary($rtnNO: String) {
    ura_IncomeTaxSummaryNonIndividual(where: { RTN_NO: { _eq: $rtnNO } }) {
      ...NonIndividualIncomeTaxSummary
    }
  }
  ${NonIndividualIncomeTaxSummaryFragmentDoc}
`;

/**
 * __useNonIndividualIncomeTaxSummaryQuery__
 *
 * To run a query within a React component, call `useNonIndividualIncomeTaxSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualIncomeTaxSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualIncomeTaxSummaryQuery({
 *   variables: {
 *      rtnNO: // value for 'rtnNO'
 *   },
 * });
 */
export function useNonIndividualIncomeTaxSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >(NonIndividualIncomeTaxSummaryDocument, baseOptions);
}
export function useNonIndividualIncomeTaxSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >(NonIndividualIncomeTaxSummaryDocument, baseOptions);
}
export type NonIndividualIncomeTaxSummaryQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummaryQuery
>;
export type NonIndividualIncomeTaxSummaryLazyQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummaryLazyQuery
>;
export type NonIndividualIncomeTaxSummaryQueryResult = Apollo.QueryResult<
  NonIndividualIncomeTaxSummaryQuery,
  NonIndividualIncomeTaxSummaryQueryVariables
>;
export const NonIndividualRentExpensesDocument = gql`
  query NonIndividualRentExpenses($rtnNO: String) {
    ura_RentExpenseDetails(where: { RTN_NO: { _eq: $rtnNO } }) {
      ...NonIndividualRentExpenses
    }
  }
  ${NonIndividualRentExpensesFragmentDoc}
`;

/**
 * __useNonIndividualRentExpensesQuery__
 *
 * To run a query within a React component, call `useNonIndividualRentExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualRentExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualRentExpensesQuery({
 *   variables: {
 *      rtnNO: // value for 'rtnNO'
 *   },
 * });
 */
export function useNonIndividualRentExpensesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >(NonIndividualRentExpensesDocument, baseOptions);
}
export function useNonIndividualRentExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >(NonIndividualRentExpensesDocument, baseOptions);
}
export type NonIndividualRentExpensesQueryHookResult = ReturnType<
  typeof useNonIndividualRentExpensesQuery
>;
export type NonIndividualRentExpensesLazyQueryHookResult = ReturnType<
  typeof useNonIndividualRentExpensesLazyQuery
>;
export type NonIndividualRentExpensesQueryResult = Apollo.QueryResult<
  NonIndividualRentExpensesQuery,
  NonIndividualRentExpensesQueryVariables
>;
export const MaxMinUraTransactionsDocument = gql`
  query MaxMinURATransactions {
    nwsc_URATransactions_aggregate {
      aggregate {
        max {
          year
        }
        min {
          year
        }
      }
    }
  }
`;

/**
 * __useMaxMinUraTransactionsQuery__
 *
 * To run a query within a React component, call `useMaxMinUraTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaxMinUraTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaxMinUraTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaxMinUraTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MaxMinUraTransactionsQuery,
    MaxMinUraTransactionsQueryVariables
  >
) {
  return Apollo.useQuery<
    MaxMinUraTransactionsQuery,
    MaxMinUraTransactionsQueryVariables
  >(MaxMinUraTransactionsDocument, baseOptions);
}
export function useMaxMinUraTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MaxMinUraTransactionsQuery,
    MaxMinUraTransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MaxMinUraTransactionsQuery,
    MaxMinUraTransactionsQueryVariables
  >(MaxMinUraTransactionsDocument, baseOptions);
}
export type MaxMinUraTransactionsQueryHookResult = ReturnType<
  typeof useMaxMinUraTransactionsQuery
>;
export type MaxMinUraTransactionsLazyQueryHookResult = ReturnType<
  typeof useMaxMinUraTransactionsLazyQuery
>;
export type MaxMinUraTransactionsQueryResult = Apollo.QueryResult<
  MaxMinUraTransactionsQuery,
  MaxMinUraTransactionsQueryVariables
>;
export const PropertyUtilitiesDocument = gql`
  query PropertyUtilities($reference: bigint!, $maxYear: bigint!) {
    nwsc_Customers(
      limit: 1
      where: { CustomerReference: { _eq: $reference } }
    ) {
      ...PropertyUtilities
    }
    nwsc_URATransactions(
      where: {
        year: { _gte: $maxYear }
        CustomerReference: { _eq: $reference }
      }
      order_by: { year: desc, month: desc }
    ) {
      ...NwscTransactions
    }
  }
  ${PropertyUtilitiesFragmentDoc}
  ${NwscTransactionsFragmentDoc}
`;

/**
 * __usePropertyUtilitiesQuery__
 *
 * To run a query within a React component, call `usePropertyUtilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyUtilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyUtilitiesQuery({
 *   variables: {
 *      reference: // value for 'reference'
 *      maxYear: // value for 'maxYear'
 *   },
 * });
 */
export function usePropertyUtilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >
) {
  return Apollo.useQuery<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >(PropertyUtilitiesDocument, baseOptions);
}
export function usePropertyUtilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >(PropertyUtilitiesDocument, baseOptions);
}
export type PropertyUtilitiesQueryHookResult = ReturnType<
  typeof usePropertyUtilitiesQuery
>;
export type PropertyUtilitiesLazyQueryHookResult = ReturnType<
  typeof usePropertyUtilitiesLazyQuery
>;
export type PropertyUtilitiesQueryResult = Apollo.QueryResult<
  PropertyUtilitiesQuery,
  PropertyUtilitiesQueryVariables
>;
export const NwscTransactionsDocument = gql`
  query NwscTransactions(
    $reference: bigint
    $maxYear: bigint
    $minYear: bigint
    $maxMonth: bigint
    $minMonth: bigint
  ) {
    nwsc_URATransactions(
      where: {
        year: { _lte: $maxYear, _gte: $minYear }
        month: { _lte: $maxMonth, _gte: $minMonth }
        CustomerReference: { _eq: $reference }
      }
      order_by: { year: desc, month: desc }
    ) {
      ...NwscTransactions
    }
  }
  ${NwscTransactionsFragmentDoc}
`;

/**
 * __useNwscTransactionsQuery__
 *
 * To run a query within a React component, call `useNwscTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNwscTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNwscTransactionsQuery({
 *   variables: {
 *      reference: // value for 'reference'
 *      maxYear: // value for 'maxYear'
 *      minYear: // value for 'minYear'
 *      maxMonth: // value for 'maxMonth'
 *      minMonth: // value for 'minMonth'
 *   },
 * });
 */
export function useNwscTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NwscTransactionsQuery,
    NwscTransactionsQueryVariables
  >
) {
  return Apollo.useQuery<NwscTransactionsQuery, NwscTransactionsQueryVariables>(
    NwscTransactionsDocument,
    baseOptions
  );
}
export function useNwscTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NwscTransactionsQuery,
    NwscTransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NwscTransactionsQuery,
    NwscTransactionsQueryVariables
  >(NwscTransactionsDocument, baseOptions);
}
export type NwscTransactionsQueryHookResult = ReturnType<
  typeof useNwscTransactionsQuery
>;
export type NwscTransactionsLazyQueryHookResult = ReturnType<
  typeof useNwscTransactionsLazyQuery
>;
export type NwscTransactionsQueryResult = Apollo.QueryResult<
  NwscTransactionsQuery,
  NwscTransactionsQueryVariables
>;
export const UraIndividualsWithNoTinDocument = gql`
  query UraIndividualsWithNoTin {
    ura_RMatchSummary904(limit: 100) {
      ...UraIndividualsWithNoTin
    }
  }
  ${UraIndividualsWithNoTinFragmentDoc}
`;

/**
 * __useUraIndividualsWithNoTinQuery__
 *
 * To run a query within a React component, call `useUraIndividualsWithNoTinQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualsWithNoTinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualsWithNoTinQuery({
 *   variables: {
 *   },
 * });
 */
export function useUraIndividualsWithNoTinQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UraIndividualsWithNoTinQuery,
    UraIndividualsWithNoTinQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualsWithNoTinQuery,
    UraIndividualsWithNoTinQueryVariables
  >(UraIndividualsWithNoTinDocument, baseOptions);
}
export function useUraIndividualsWithNoTinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualsWithNoTinQuery,
    UraIndividualsWithNoTinQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualsWithNoTinQuery,
    UraIndividualsWithNoTinQueryVariables
  >(UraIndividualsWithNoTinDocument, baseOptions);
}
export type UraIndividualsWithNoTinQueryHookResult = ReturnType<
  typeof useUraIndividualsWithNoTinQuery
>;
export type UraIndividualsWithNoTinLazyQueryHookResult = ReturnType<
  typeof useUraIndividualsWithNoTinLazyQuery
>;
export type UraIndividualsWithNoTinQueryResult = Apollo.QueryResult<
  UraIndividualsWithNoTinQuery,
  UraIndividualsWithNoTinQueryVariables
>;
export const UraIndividualWithNoTinFilterDocument = gql`
  query UraIndividualWithNoTinFilter(
    $condition: ura_RMatchSummary904_bool_exp!
  ) {
    ura_RMatchSummary904(limit: 100, where: $condition) {
      ...UraIndividualsWithNoTin
    }
  }
  ${UraIndividualsWithNoTinFragmentDoc}
`;

/**
 * __useUraIndividualWithNoTinFilterQuery__
 *
 * To run a query within a React component, call `useUraIndividualWithNoTinFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualWithNoTinFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualWithNoTinFilterQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUraIndividualWithNoTinFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualWithNoTinFilterQuery,
    UraIndividualWithNoTinFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualWithNoTinFilterQuery,
    UraIndividualWithNoTinFilterQueryVariables
  >(UraIndividualWithNoTinFilterDocument, baseOptions);
}
export function useUraIndividualWithNoTinFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualWithNoTinFilterQuery,
    UraIndividualWithNoTinFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualWithNoTinFilterQuery,
    UraIndividualWithNoTinFilterQueryVariables
  >(UraIndividualWithNoTinFilterDocument, baseOptions);
}
export type UraIndividualWithNoTinFilterQueryHookResult = ReturnType<
  typeof useUraIndividualWithNoTinFilterQuery
>;
export type UraIndividualWithNoTinFilterLazyQueryHookResult = ReturnType<
  typeof useUraIndividualWithNoTinFilterLazyQuery
>;
export type UraIndividualWithNoTinFilterQueryResult = Apollo.QueryResult<
  UraIndividualWithNoTinFilterQuery,
  UraIndividualWithNoTinFilterQueryVariables
>;
export const UraIndividualWithNoTinSuggestionsDocument = gql`
  query UraIndividualWithNoTinSuggestions(
    $condition: ura_RMatchSummary904_bool_exp!
    $columnNames: [ura_RMatchSummary904_select_column!]
  ) {
    ura_RMatchSummary904(
      limit: 10
      where: $condition
      distinct_on: $columnNames
    ) {
      ...UraIndividualsWithNoTin
    }
  }
  ${UraIndividualsWithNoTinFragmentDoc}
`;

/**
 * __useUraIndividualWithNoTinSuggestionsQuery__
 *
 * To run a query within a React component, call `useUraIndividualWithNoTinSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualWithNoTinSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualWithNoTinSuggestionsQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      columnNames: // value for 'columnNames'
 *   },
 * });
 */
export function useUraIndividualWithNoTinSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualWithNoTinSuggestionsQuery,
    UraIndividualWithNoTinSuggestionsQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualWithNoTinSuggestionsQuery,
    UraIndividualWithNoTinSuggestionsQueryVariables
  >(UraIndividualWithNoTinSuggestionsDocument, baseOptions);
}
export function useUraIndividualWithNoTinSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualWithNoTinSuggestionsQuery,
    UraIndividualWithNoTinSuggestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualWithNoTinSuggestionsQuery,
    UraIndividualWithNoTinSuggestionsQueryVariables
  >(UraIndividualWithNoTinSuggestionsDocument, baseOptions);
}
export type UraIndividualWithNoTinSuggestionsQueryHookResult = ReturnType<
  typeof useUraIndividualWithNoTinSuggestionsQuery
>;
export type UraIndividualWithNoTinSuggestionsLazyQueryHookResult = ReturnType<
  typeof useUraIndividualWithNoTinSuggestionsLazyQuery
>;
export type UraIndividualWithNoTinSuggestionsQueryResult = Apollo.QueryResult<
  UraIndividualWithNoTinSuggestionsQuery,
  UraIndividualWithNoTinSuggestionsQueryVariables
>;
export const UraRptIndividualTaxSummariesDocument = gql`
  query UraRPTIndividualTaxSummaries($tin: String) {
    ura_RPT_IndividualTaxSummaryData(where: { TIN_NO: { _eq: $tin } }) {
      ...UraRPTIndividualTaxSummaries
    }
  }
  ${UraRptIndividualTaxSummariesFragmentDoc}
`;

/**
 * __useUraRptIndividualTaxSummariesQuery__
 *
 * To run a query within a React component, call `useUraRptIndividualTaxSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraRptIndividualTaxSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraRptIndividualTaxSummariesQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useUraRptIndividualTaxSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >
) {
  return Apollo.useQuery<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >(UraRptIndividualTaxSummariesDocument, baseOptions);
}
export function useUraRptIndividualTaxSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >(UraRptIndividualTaxSummariesDocument, baseOptions);
}
export type UraRptIndividualTaxSummariesQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummariesQuery
>;
export type UraRptIndividualTaxSummariesLazyQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummariesLazyQuery
>;
export type UraRptIndividualTaxSummariesQueryResult = Apollo.QueryResult<
  UraRptIndividualTaxSummariesQuery,
  UraRptIndividualTaxSummariesQueryVariables
>;
export const UraRptIndividualTaxSummaryDocument = gql`
  query UraRPTIndividualTaxSummary($rtnNO: String) {
    ura_RPT_IndividualTaxSummaryData(where: { RTN_NO: { _eq: $rtnNO } }) {
      ...UraRPTIndividualTaxSummary
    }
  }
  ${UraRptIndividualTaxSummaryFragmentDoc}
`;

/**
 * __useUraRptIndividualTaxSummaryQuery__
 *
 * To run a query within a React component, call `useUraRptIndividualTaxSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraRptIndividualTaxSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraRptIndividualTaxSummaryQuery({
 *   variables: {
 *      rtnNO: // value for 'rtnNO'
 *   },
 * });
 */
export function useUraRptIndividualTaxSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >
) {
  return Apollo.useQuery<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >(UraRptIndividualTaxSummaryDocument, baseOptions);
}
export function useUraRptIndividualTaxSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >(UraRptIndividualTaxSummaryDocument, baseOptions);
}
export type UraRptIndividualTaxSummaryQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummaryQuery
>;
export type UraRptIndividualTaxSummaryLazyQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummaryLazyQuery
>;
export type UraRptIndividualTaxSummaryQueryResult = Apollo.QueryResult<
  UraRptIndividualTaxSummaryQuery,
  UraRptIndividualTaxSummaryQueryVariables
>;
export const IndividualMortgageDocument = gql`
  query IndividualMortgage($rtnNO: String) {
    ura_IndividualMortgage(where: { RTN_NO: { _eq: $rtnNO } }) {
      ...IndividualMortgage
    }
  }
  ${IndividualMortgageFragmentDoc}
`;

/**
 * __useIndividualMortgageQuery__
 *
 * To run a query within a React component, call `useIndividualMortgageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualMortgageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualMortgageQuery({
 *   variables: {
 *      rtnNO: // value for 'rtnNO'
 *   },
 * });
 */
export function useIndividualMortgageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >(IndividualMortgageDocument, baseOptions);
}
export function useIndividualMortgageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >(IndividualMortgageDocument, baseOptions);
}
export type IndividualMortgageQueryHookResult = ReturnType<
  typeof useIndividualMortgageQuery
>;
export type IndividualMortgageLazyQueryHookResult = ReturnType<
  typeof useIndividualMortgageLazyQuery
>;
export type IndividualMortgageQueryResult = Apollo.QueryResult<
  IndividualMortgageQuery,
  IndividualMortgageQueryVariables
>;
export const IndividualTenantsDocument = gql`
  query IndividualTenants($rtnNO: String) {
    ura_RentalLandLordIncome(where: { RTN_NO: { _eq: $rtnNO } }) {
      ...IndividualTenant
    }
  }
  ${IndividualTenantFragmentDoc}
`;

/**
 * __useIndividualTenantsQuery__
 *
 * To run a query within a React component, call `useIndividualTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualTenantsQuery({
 *   variables: {
 *      rtnNO: // value for 'rtnNO'
 *   },
 * });
 */
export function useIndividualTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >(IndividualTenantsDocument, baseOptions);
}
export function useIndividualTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >(IndividualTenantsDocument, baseOptions);
}
export type IndividualTenantsQueryHookResult = ReturnType<
  typeof useIndividualTenantsQuery
>;
export type IndividualTenantsLazyQueryHookResult = ReturnType<
  typeof useIndividualTenantsLazyQuery
>;
export type IndividualTenantsQueryResult = Apollo.QueryResult<
  IndividualTenantsQuery,
  IndividualTenantsQueryVariables
>;
export const IndividualReportsDocument = gql`
  query IndividualReports($condition: ura_RMatchSummary916_bool_exp!) {
    ura_RMatchSummary916(limit: 100, where: $condition) {
      ...IndividualReports
    }
  }
  ${IndividualReportsFragmentDoc}
`;

/**
 * __useIndividualReportsQuery__
 *
 * To run a query within a React component, call `useIndividualReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualReportsQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useIndividualReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualReportsQuery,
    IndividualReportsQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualReportsQuery,
    IndividualReportsQueryVariables
  >(IndividualReportsDocument, baseOptions);
}
export function useIndividualReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualReportsQuery,
    IndividualReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualReportsQuery,
    IndividualReportsQueryVariables
  >(IndividualReportsDocument, baseOptions);
}
export type IndividualReportsQueryHookResult = ReturnType<
  typeof useIndividualReportsQuery
>;
export type IndividualReportsLazyQueryHookResult = ReturnType<
  typeof useIndividualReportsLazyQuery
>;
export type IndividualReportsQueryResult = Apollo.QueryResult<
  IndividualReportsQuery,
  IndividualReportsQueryVariables
>;
export const NonIndividualHoldingOfficersDocument = gql`
  query NonIndividualHoldingOfficers($tin: String) {
    ura_HoldingOfficers(where: { TIN: { _eq: $tin } }) {
      ...NonIndividualHoldingOfficers
    }
  }
  ${NonIndividualHoldingOfficersFragmentDoc}
`;

/**
 * __useNonIndividualHoldingOfficersQuery__
 *
 * To run a query within a React component, call `useNonIndividualHoldingOfficersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualHoldingOfficersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualHoldingOfficersQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useNonIndividualHoldingOfficersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NonIndividualHoldingOfficersQuery,
    NonIndividualHoldingOfficersQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualHoldingOfficersQuery,
    NonIndividualHoldingOfficersQueryVariables
  >(NonIndividualHoldingOfficersDocument, baseOptions);
}
export function useNonIndividualHoldingOfficersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualHoldingOfficersQuery,
    NonIndividualHoldingOfficersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualHoldingOfficersQuery,
    NonIndividualHoldingOfficersQueryVariables
  >(NonIndividualHoldingOfficersDocument, baseOptions);
}
export type NonIndividualHoldingOfficersQueryHookResult = ReturnType<
  typeof useNonIndividualHoldingOfficersQuery
>;
export type NonIndividualHoldingOfficersLazyQueryHookResult = ReturnType<
  typeof useNonIndividualHoldingOfficersLazyQuery
>;
export type NonIndividualHoldingOfficersQueryResult = Apollo.QueryResult<
  NonIndividualHoldingOfficersQuery,
  NonIndividualHoldingOfficersQueryVariables
>;
export const NonIndividualHoldingOwnersDocument = gql`
  query NonIndividualHoldingOwners($tin: String) {
    ura_HoldingOwner(where: { TIN: { _eq: $tin } }) {
      ...NonIndividualHoldingOwners
    }
  }
  ${NonIndividualHoldingOwnersFragmentDoc}
`;

/**
 * __useNonIndividualHoldingOwnersQuery__
 *
 * To run a query within a React component, call `useNonIndividualHoldingOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualHoldingOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualHoldingOwnersQuery({
 *   variables: {
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useNonIndividualHoldingOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NonIndividualHoldingOwnersQuery,
    NonIndividualHoldingOwnersQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualHoldingOwnersQuery,
    NonIndividualHoldingOwnersQueryVariables
  >(NonIndividualHoldingOwnersDocument, baseOptions);
}
export function useNonIndividualHoldingOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualHoldingOwnersQuery,
    NonIndividualHoldingOwnersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualHoldingOwnersQuery,
    NonIndividualHoldingOwnersQueryVariables
  >(NonIndividualHoldingOwnersDocument, baseOptions);
}
export type NonIndividualHoldingOwnersQueryHookResult = ReturnType<
  typeof useNonIndividualHoldingOwnersQuery
>;
export type NonIndividualHoldingOwnersLazyQueryHookResult = ReturnType<
  typeof useNonIndividualHoldingOwnersLazyQuery
>;
export type NonIndividualHoldingOwnersQueryResult = Apollo.QueryResult<
  NonIndividualHoldingOwnersQuery,
  NonIndividualHoldingOwnersQueryVariables
>;
