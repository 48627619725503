import { SearchRequestConfig } from "./corporatus_rest_graphql.types";

export const getSearchRequestConfig = (
  stream?: boolean,
  plan?: string
): SearchRequestConfig => {
  if (!stream) {
    stream = false;
  }
  return {
    stream: stream,
    temperature: 0.1,
    top_p: 0.57,
    top_k: 40,
    num_beams: 1,
    model_max_length: 1024,
    tokenizer_max_length: 1024,
    plan: plan ?? "free",
  };
};
