/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { LOOKER } = ActionTypes;

const LOOKS_AND_DASHBOARDS_INITIAL_STATE = {
  lookAndDashboardById: {
    20: {
      lookByIdData: [],
      selectedLook: {},
      dashboardByIdData: [],
      selectedDashboard: {},
    },
    21: {
      lookByIdData: [],
      selectedLook: {},
      dashboardByIdData: [],
      selectedDashboard: {},
    },
    22: {
      lookByIdData: [],
      selectedLook: {},
      dashboardByIdData: [],
      selectedDashboard: {},
    },
    23: {
      lookByIdData: [],
      selectedLook: {},
      dashboardByIdData: [],
      selectedDashboard: {},
    }
  },
  looksData: [],
  dashboardsData: [],
  isReceivingData: false,
  error: null
};

const getLooksAndDashboardsReducerFn = (state = LOOKS_AND_DASHBOARDS_INITIAL_STATE, action) => {
  switch (action.type) {
    /* FETCH LOOKS DATA */
    case LOOKER.FETCH_LOOKS_DATA.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case LOOKER.FETCH_LOOKS_DATA.SUCCESS: {
      const { data } = action.payload;
      // console.warn('looks data: ', data);
      return {
        ...state,
        looksData: data && JSON.parse(data.LookerGetLooks),
        isReceivingData: false
      };
    }
    case LOOKER.FETCH_LOOKS_DATA.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    /* FETCH LOOK BY ID DATA */
    case LOOKER.FETCH_LOOK_BY_ID.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case LOOKER.FETCH_LOOK_BY_ID.SUCCESS: {
      const { data, selectedLook, slidyName } = action.payload;
      // console.warn('data.LookerGetLooks: ', data.data.LookerGetLooks);
      return {
        ...state,
        lookAndDashboardById: {
          ...state.lookAndDashboardById,
          [slidyName]: {
            lookByIdData: data && JSON.parse(data.data.LookerGetLooks),
            selectedLook,
          }
        },
        isReceivingData: false
      };
    }
    case LOOKER.FETCH_LOOK_BY_ID.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    /* FETCH DASHBOARDS DATA */
    case LOOKER.FETCH_DASHBOARDS_DATA.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case LOOKER.FETCH_DASHBOARDS_DATA.SUCCESS: {
      const { data } = action.payload;
      // console.warn('dashboard data: ', data);
      return {
        ...state,
        dashboardsData: data && JSON.parse(data.LookerGetDashboards),
        isReceivingData: false
      };
    }
    case LOOKER.FETCH_DASHBOARDS_DATA.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    /* FETCH DASHBOARD BY ID DATA */
    case LOOKER.FETCH_DASHBOARD_BY_ID.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case LOOKER.FETCH_DASHBOARD_BY_ID.SUCCESS: {
      const { data, selectedDashboard, slidyName } = action.payload;
      // console.warn('selectedDashboard: ', selectedDashboard);
      return {
        ...state,
        lookAndDashboardById: {
          ...state.lookAndDashboardById,
          [slidyName]: {
            dashboardByIdData: data && JSON.parse(data.data.LookerGetDashboards),
            selectedDashboard,
          }
        },
        isReceivingData: false
      };
    }
    case LOOKER.FETCH_DASHBOARD_BY_ID.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    default: return state;
  }
};

export default getLooksAndDashboardsReducerFn;
