import React, { ElementType } from "react";

import ButtonPrimary from "components/button-primary/button-primary.component";
import { Box, CardMedia, Typography } from "@material-ui/core";

import SC from "./generic-info-card.styles";

export interface GenericInfoCardInterface {
  url: string;
  title: string;
  subtitle?: string;
  description: string;
  buttonLbl?: string;
}

const GenericInfoCard: React.FC<{
  data: GenericInfoCardInterface;
  buttonHandler?: (param1) => void;
}> = ({ data, buttonHandler = null }) => {
  return (
    <SC.CardContainer>
      <SC.Title>{data.title}</SC.Title>
      {data.subtitle ? <Typography>{data.subtitle}</Typography> : null}
      <CardMedia
        hidden={!data.url}
        component="img"
        src={data.url}
        title={data.title}
      />
      <SC.Description>
        <Box
          component="p"
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        />
      </SC.Description>
      {buttonHandler ? (
        <SC.FooterContainer display="flex">
          <ButtonPrimary
            hidden={!data.buttonLbl}
            size="small"
            color="primary"
            onClick={buttonHandler}
          >
            {data.buttonLbl}
          </ButtonPrimary>
        </SC.FooterContainer>
      ) : null}
    </SC.CardContainer>
  );
};

export default GenericInfoCard;
