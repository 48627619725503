/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  CardMedia,
  CardMediaProps,
  Typography,
  Theme,
  withStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import corporatusLogo from "assets/images/corporatus.svg";

export const Container = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "auto",
    display: "flex",
    background: theme.authGeneralStyles.container.backgroundColor,
    [theme.breakpoints.down(550)]: {
      height: "100vh",
    },
  },
}))(Box);

export const Content = withStyles((theme: Theme) => ({
  root: {
    width: "592px",
    margin: "auto",
    background: "transparent",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "30px 0px",
    "& .content": {
      background: "#fff",
      padding: "21px 138px 53px",
      borderRadius: "0px 0px 10px 10px",
      [theme.breakpoints.down(540)]: {
        flex: "1",
      },
    },
    [theme.breakpoints.down(540)]: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "unset",
    },
  },
}))(Box);

export const LogoWrapper = withStyles((theme: Theme) => ({
  root: {
    padding: "20px",
    background: theme.authGeneralStyles.logoContainer.backgroundColor,
    borderRadius: "10px 10px 0px 0px",
  },
}))(Box);

export const LogoBase = withStyles((theme: Theme) => ({
  root: {
    height: "131px",
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      height: "100px",
    },
  },
}))(CardMedia);

export const Logo: React.FC<CardMediaProps> = (props) => {
  const { image = corporatusLogo, title = "Corporatus", ...moreProps } = props;
  return (
    <LogoWrapper>
      <Link to="/">
        <LogoBase image={image} title={title} {...moreProps} />
      </Link>
    </LogoWrapper>
  );
};

export const Title = withStyles((theme: Theme) => ({
  root: {
    fontSize: "26px",
    lineHeight: "32px",
    fontWeight: "bold",
    paddingTop: "20px",
    paddingBottom: "10px",
    backgroundColor: theme.authGeneralStyles.formContainer.backgroundColor,
    textAlign: "center",
    [theme.breakpoints.down(540)]: {
      flex: "1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxHeight: "200px",
    },
  },
}))(Typography);

export default {
  Container,
  Content,
  Logo,
  Title,
};
