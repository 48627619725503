/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Theme,
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  Divider,
  Collapse,
  TableContainer,
  TableCell,
  CircularProgress,
  Popover,
  Input,
  Select,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles, createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import {
  useUpdateTargetsGroupMutation,
  useDeleteTargetsGroupMutation,
  useUpdateTargetMutation,
  useDeleteTargetMutation,
  useGetGroupTargetsByIdQuery,
} from "graphql/types-and-hooks";
import { useGetMediasRecommendationsLazyQuery } from "graphql/recommendations/types-and-hooks";
import {
  BehaviorResultsFragment,
  GetResultsByBehaviorIdQuery,
  GetResultsByBehaviorIdQueryVariables,
  useGetBehaviorResultsQuery,
  useGetMediasResultsTotalQuery,
  useGetResultsByBehaviorIdQuery,
} from "graphql/behaviors/types-and-hooks";
import {
  MediaFragment,
  useGetVideosFromMasterByIdQuery,
  GetVideosFromMasterByIdQuery,
  useGetVideosIdsByMediaIdLazyQuery,
} from "graphql/explore/types-and-hooks";

import { CLIENT_NAME } from "graphql/client";
import { Reference, StoreObject } from "@apollo/client";

import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { useAppErrorHandler } from "errors/app.errors";

import Paginator from "components/paginator/paginator.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

import SCBehaviorsResults from "pages/behaviors-results/behaviors-results.styles";

import YoutubeIcon from "assets/icons/YoutubeLogo.png";
import ApplePCIcon from "assets/icons/ApplePCLogo.png";
import PodcastIcon from "assets/icons/DefaultLogo.png";
import VimeoIcon from "assets/icons/VimeoLogo.png";
import TiktokIcon from "assets/icons/TicktockLogo.png";
import SpotifyIcon from "assets/icons/SpotifyLogo.png";
import CustomCheckBox from "components/custom-checkbox/custom-checkbox.component";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import { getYoutubeVideoId } from "utils/string";
import {
  createEmptyMedia,
  GroupTargetTableItemInterface,
} from "graphql/audionaut.utils";
import DeleteIconButton from "components/delete-icon-button/delete-icon-button.component";
import CopyIconButton from "components/copy-icon-button/copy-icon-button.component";
import ButtonPrimary from "components/button-primary/button-primary.component";
import ButtonSecondary from "components/button-secondary/button-secondary.component";
import { isMobileResolution } from "commons/utils/device-info.util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      title: {
        "& h1": {
          textAlign: "left",
        },
        pagination: {
          flex: "1",
        },
      },
    },
    updateBtn: {
      margin: "auto",
      marginRight: "10px !important",
      width: theme.buttonSize.small.width,
      [theme.breakpoints.down(540)]: {
        width: "30px",
        minWidth: "unset",
        borderRadius: "50%",
        "& .MuiButton-startIcon": {
          margin: "unset",
        },
      },
    },
    deleteBtn: {
      margin: "auto",
      marginLeft: "10px !important",
      width: theme.buttonSize.small.width,
    },
    tagsPopover: {
      width: "300px",
      "& .MuiPaper-root": {
        width: "100%",
        padding: "10px",
      },
    },
    tagsBtn: {
      background: "transparent",
      color: theme.palette.primary.main,
      "&:hover": {
        background: "transparent",
      },
    },
    adviseMessage: {
      flex: "1",
      "& .MuiPaper-root": {
        height: "100%",
        paddingBottom: "30px",
        paddingLeft: "30px",
        paddingRight: "30px",
        boxShadow: "none",
        background: "transparent",
        "& div:first-child": {
          height: "100%",
          margin: "unset",
        },
      },
    },
    brandIcon: {
      width: "35px",
      marginRight: "10px",
    },
    ellipsisText: {
      maxWidth: "200px",
      width: "calc(100vw / 9)",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  })
);

const images: any = {
  YoutubeIcon,
  ApplePCIcon,
  PodcastIcon,
  VimeoIcon,
  TiktokIcon,
  SpotifyIcon,
};

function getImageByKey(key: string) {
  return images[key];
}

const TGContainer = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    [theme.breakpoints.down(540)]: {
      height: "unset",
    },
  },
}))(Box);

const TGContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: "30px",
    paddingBottom: "30px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
}))(Box);

const TGHeader = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    background: "#FAFAFA",
    minHeight: "159px",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "32px",
    paddingRight: "32px",
    "& h1": {
      flex: "1",
    },
    "& button": {
      margin: "0px",
      marginRight: "16px",
    },
    "& button:last-child": {
      margin: "0px !important",
    },
    [theme.breakpoints.down(540)]: {
      minHeight: "unset",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
}))(Box);

const TGHeaderTitle = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: "1",
    [theme.breakpoints.down(540)]: {
      flex: "unset",
      "& h1": {
        margin: "unset",
        fontSize: theme.fontGeneralStyles.large.fontSize,
        lineHeight: theme.fontGeneralStyles.large.lineHeight,
        fontWeight: 600,
      },
    },
  },
}))(Box);

const TGBody = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "calc(100% - 160px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    paddingLeft: "32px",
    paddingRight: "32px",
    "& ul": {
      borderRadius: "10px",
      padding: "0px",
      "& li": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },
    [theme.breakpoints.down(540)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
}))(Box);

const TGTableHead = withStyles((theme: Theme) => ({
  root: {
    height: "31px",
    padding: "10px 16px",
    "& th": {
      maxHeight: "41px",
      borderBottom: "1px solid #EAEAEA",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: "#FFF",
      "& .MuiCheckbox-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
      "& p": {
        fontWeight: "700 !important",
        fontSize: theme.fontGeneralStyles.small.fontSize,
        lineHeight: theme.fontGeneralStyles.small.lineHeight,
      },
      "&.extraSpace": {
        borderBottom: "#FFF",
        paddingLeft: "0px",
      },
    },
  },
}))(TableHead);

const TGTableBody = withStyles((theme: Theme) => ({
  root: {
    "& td": {
      border: "none",
      fontWeight: "500 !important",
      fontSize: theme.fontGeneralStyles.default.fontSize,
      lineHeight: theme.fontGeneralStyles.default.lineHeight,
    },
    "& tr:nth-child(even)": {
      background: "#9280BB0A",
    },
  },
}))(TableBody);

const TGTableCell = withStyles(() => ({
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    height: "55px",
    "& .MuiCheckbox-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .MuiButton-root": {
      maxWidth: "100px",
      padding: "0px",
    },
    "& .MuiIconButton-root": {
      padding: "0px",
      "&:first-child": {
        marginRight: "10px",
      },
    },
    "& .TGTableCell": {
      padding: "0px",
    },
    "&:first-child": {
      paddingLeft: "16px",
    },
    "&:last-child": {
      width: "1%",
      paddingRight: "16px",
      whiteSpace: "nowrap",
    },
  },
}))(TableCell);

const TableCellSticky = withStyles((theme: Theme) => ({
  root: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&.noPaddingLeft": {
      paddingLeft: "0px",
    },
    [theme.breakpoints.down(540)]: {
      position: "sticky",
      left: "0px",
      zIndex: "11",
      background: "#FFF",
      "&.lastCell": {
        maxWidth: "120px",
        left: "40px",
        borderRight: "1px solid #E1E1E1",
        boxShadow: "7px 0 5px -4px rgba(0,0,0,0.1)",
      },
      "&.firstCell": {
        left: "0px !important",
        maxWidth: "40px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      "&.headTitle": {
        "& p": {
          fontSize: theme.fontGeneralStyles.default.fontSize,
          lineHeight: theme.fontGeneralStyles.default.lineHeight,
        },
      },
      "&.cellText": {
        "& p": {
          fontSize: theme.fontGeneralStyles.xSmall.fontSize,
          lineHeight: theme.fontGeneralStyles.xSmall.lineHeight,
        },
      },
    },
  },
}))(TableCell);

const headersColap: any[] = [
  {
    text: "Media Title",
    key: "mediaTitle",
    icon: false,
  },
  {
    text: "Author",
    key: "author",
    icon: false,
  },
  {
    text: "Source",
    key: "source",
    icon: false,
  },
  {
    text: "Source ID",
    key: "sourceId",
    icon: true,
  },
  {
    text: "Tags",
    key: "tags",
    icon: false,
  },
];

const EditTargetDialogContent = withStyles(() => ({
  root: {
    paddingBottom: "40px",
  },
}))(DialogContent);

const GroupTextField = withStyles(() => ({
  root: {
    height: "45px",
    "& div": {
      height: "100%",
      "& fieldset": {
        height: "100%",
      },
    },
  },
}))(TextField);

const GroupDialogContent = withStyles(() => ({
  root: {
    paddingTop: "16px",
    paddingBottom: "60px",
  },
}))(DialogContent);

const GroupDialogActions = withStyles(() => ({
  root: {
    justifyContent: "space-between",
  },
}))(DialogActions);

const GetBehaviorData = (sourceId: string) => {
  return useGetBehaviorResultsQuery({
    variables: {
      id: sourceId,
    },
    context: {
      clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
    },
  });
};

const GetMediaData = (sourceId: string) => {
  return useGetVideosFromMasterByIdQuery({
    variables: {
      id: sourceId,
    },
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
  });
};

interface RowItemProps {
  id: string;
  name: string;
  source: string;
  parentId: string;
  indexRow: string;
  updateTargets?: any;
  showToast?: any;
  selected: string[];
  selectItem: any;
  isBehavior?: boolean;
  // updateContent: any;
  isRecommendation?: boolean;
  recommendationData?: any;
  handlerOnClickCopy: () => void;
}
const TGRowItem: React.FC<RowItemProps> = ({
  id: sourceId,
  name,
  source,
  parentId,
  indexRow,
  updateTargets,
  showToast,
  selected,
  selectItem,
  isBehavior = false,
  isRecommendation = false,
  recommendationData = {},
  handlerOnClickCopy,
  // updateContent,
}) => {
  const classes = useStyles();
  const classesBR = SCBehaviorsResults.behaviorsResultsStyles();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [newTargetName, setNewTargetName] = React.useState<any>({});
  const [expanded, setExpanded] = React.useState(false);
  const [behaviorMedias, setBehaviorMedias] = React.useState<
    BehaviorResultsFragment[]
  >([]);
  const [mediaInfo, setMediaInfo] =
    React.useState<MediaFragment & { sourceId: string }>(createEmptyMedia);
  const [anchorTagsEl, setAnchorTagsEl] =
    React.useState<HTMLButtonElement | null>(null);

  const [behaviorMediasPerPage, setBehaviorMediasPerPage] = React.useState(5);
  const [behaviorMediasPage, setBehaviorMediasPage] = React.useState(0);

  const [checkboxStatus, setCheckboxStatus] = React.useState(false);
  const [deletedItem, setDeletedItem] = React.useState(false);

  const openTags = Boolean(anchorTagsEl);
  const popoverId = openTags
    ? `tags-popover-${isBehavior ? sourceId : mediaInfo.id}`
    : undefined;

  const [resultsPaginationOpts, setResultsPaginationOpts] =
    React.useState<GetResultsByBehaviorIdQueryVariables>({
      id: sourceId,
      index: 0,
      total: 5,
    });

  const [deleteTarget, { loading: deleteTargetLoading }] =
    useDeleteTargetMutation({
      update(cache, { data }) {
        const targetToDeleteId = data?.DeleteTarget;
        cache.modify({
          fields: {
            GetTarget(existingTarget, { readField }) {
              return existingTarget.filter(
                (target: Reference | StoreObject | undefined) =>
                  targetToDeleteId !== readField("id", target)
              );
            },
          },
        });
      },
    });
  const [updateTarget, { loading: updateTargetLoading }] =
    useUpdateTargetMutation();

  const {
    data: currentMediaData,
    loading: currentMediaDataLoading,
    error: currentMediaDataError,
  } = useGetVideosFromMasterByIdQuery({
    variables: {
      id: sourceId,
    },
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
    skip: isBehavior,
  });

  const {
    data: resultsTotalData,
    loading: resultsTotalLoading,
    error: resultsTotalerror,
  } = useGetMediasResultsTotalQuery({
    variables: {
      id: sourceId,
    },
    context: {
      clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
    },
    skip: !isBehavior,
  });

  const {
    data: behaviorResultsData,
    loading: behaviorResultsLoading,
    error: behaviorResultsError,
  } = useGetResultsByBehaviorIdQuery({
    variables: resultsPaginationOpts,
    context: {
      clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
    },
    skip: !isBehavior,
  });

  const loading = updateTargetLoading;
  useAppErrorHandler(behaviorResultsError || currentMediaDataError);

  const resultsTotal = React.useMemo(() => {
    return resultsTotalData &&
      resultsTotalData.getBehaviors &&
      resultsTotalData.getBehaviors.behaviors &&
      resultsTotalData.getBehaviors.behaviors.length > 0 &&
      resultsTotalData.getBehaviors.behaviors[0] &&
      resultsTotalData.getBehaviors.behaviors[0].mediaResults
      ? resultsTotalData.getBehaviors.behaviors[0].mediaResults
      : 0;
  }, [resultsTotalData]);

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const handleClickToggleUpdate: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenUpdate(!openUpdate);
    }, [openUpdate, setOpenUpdate]);

  const handleDeleteTarget = async (index: string) => {
    const targetDeleted = await deleteTarget({
      variables: {
        targetId: index,
      },
    });

    showToast({
      message: "The target has been deleted successfully",
      severity: "warning",
    });

    setDeletedItem(false);
    updateTargets();
    // updateContent();
    handleClickToggleDelete();
  };
  const handleSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();

      const targetUpdated = await updateTarget({
        variables: {
          targetId: indexRow,
          targetName: newTargetName.target.value,
        },
      });

      showToast({
        message: "The target has been updated successfully",
        severity: "success",
      });

      updateTargets();
      // updateContent();
      handleClickToggleUpdate(event);
    },
    [
      indexRow,
      newTargetName,
      updateTargets,
      // updateContent,
      handleClickToggleUpdate,
      updateTarget,
      showToast,
    ]
  );

  const handleExpandClick = () => {
    if (expanded) {
      setResultsPaginationOpts((oldVars) => ({ ...oldVars, index: 0 }));
    }
    setExpanded(!expanded);
  };

  const handleOpenTags = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorTagsEl(event.currentTarget);
  };

  const handleCloseTags = () => {
    setAnchorTagsEl(null);
  };

  const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = event.target.value;
    setCheckboxStatus(event.target.checked);
    selectItem(event.target.checked, itemId);
  };

  const handleUpdateItems = (newPage: number, newRowsPerPage: number) => {
    setResultsPaginationOpts({
      id: sourceId,
      total: newRowsPerPage,
      index: newRowsPerPage * newPage,
    });
  };

  React.useEffect(() => {
    const isSelected = selected.indexOf(sourceId as string) !== -1;

    setCheckboxStatus(isSelected);
  }, [selected, sourceId]);

  React.useEffect(() => {
    if (isBehavior && !behaviorResultsLoading && !!behaviorResultsData) {
      let results: BehaviorResultsFragment[] = [];

      const { getResults } = behaviorResultsData as GetResultsByBehaviorIdQuery;

      if (getResults && getResults.length > 0) {
        // const { behaviors } = getBehaviors;

        // const {
        //   results: { edges },
        // } = behaviors[0] as BehaviorDataInterface;

        results = getResults.map((media: any) => {
          let sourceMediaId = "-";
          const { mediaUrl } = media;
          if (mediaUrl) {
            sourceMediaId = getYoutubeVideoId(mediaUrl);
          }

          const mediaUpdated = {
            ...media,
            sourceId: sourceMediaId,
          };

          return mediaUpdated;
        });
      }
      setBehaviorMedias(results);
    } else if (!isBehavior && !currentMediaDataLoading && !!currentMediaData) {
      let sourceMediaId = "-";
      const mediaData = currentMediaData as GetVideosFromMasterByIdQuery;

      if (
        mediaData! &&
        mediaData!.getMediasFromMaster![0] &&
        mediaData!.getMediasFromMaster![0].url
      ) {
        const { getMediasFromMaster } = mediaData;
        if (getMediasFromMaster && getMediasFromMaster.length > 0) {
          sourceMediaId = getYoutubeVideoId(getMediasFromMaster[0]!.url!);
        }
      }

      const newCurrentMediaData: GroupTargetTableItemInterface = {
        ...mediaData!.getMediasFromMaster![0],
        sourceId: sourceMediaId,
      };

      if (!newCurrentMediaData.id) {
        newCurrentMediaData.id = sourceId;
      }
      if (!newCurrentMediaData.source) {
        const mediaSource = source;
        newCurrentMediaData.source = mediaSource;
      }

      setMediaInfo(newCurrentMediaData);
    }
  }, [
    isBehavior,
    source,
    sourceId,
    behaviorResultsLoading,
    currentMediaDataLoading,
    behaviorResultsData,
    currentMediaData,
  ]);

  return (
    <>
      <TableRow id={`row-${indexRow}`}>
        <ProgressIndicator open={loading} />
        <TableCellSticky padding="checkbox" className="firstCell">
          <CustomCheckBox
            checked={checkboxStatus}
            onChange={handleSelectItem}
            value={sourceId}
            inputProps={{ "aria-labelledby": sourceId }}
          />
        </TableCellSticky>
        <TableCellSticky className="lastCell cellText">{name}</TableCellSticky>
        {!isBehavior && !isRecommendation ? (
          <>
            <TGTableCell>
              <Box component="div" className={classes.ellipsisText}>
                {mediaInfo.videoName}
              </Box>
            </TGTableCell>
            <TGTableCell className={classes.ellipsisText}>
              {mediaInfo.author}
            </TGTableCell>
            <TGTableCell>{mediaInfo.source}</TGTableCell>
            <TGTableCell>{mediaInfo.sourceId}</TGTableCell>
            <TGTableCell>
              {mediaInfo && mediaInfo.tags && mediaInfo.tags.length > 0 ? (
                <>
                  <Button
                    type="button"
                    className={classes.tagsBtn}
                    onClick={handleOpenTags}
                  >
                    {mediaInfo.tags.length > 0
                      ? `${mediaInfo.tags.join(", ").substring(0, 10)} ...`
                      : "-"}
                  </Button>
                  <Popover
                    id={sourceId}
                    open={openTags}
                    anchorEl={anchorTagsEl}
                    onClose={handleCloseTags}
                    className={classes.tagsPopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Typography>{mediaInfo.tags.join(", ")}</Typography>
                  </Popover>
                </>
              ) : (
                "-"
              )}
            </TGTableCell>
          </>
        ) : null}
        {isBehavior ? <TGTableCell>{sourceId}</TGTableCell> : null}
        {isRecommendation ? (
          <>
            <TGTableCell>
              <img
                className={classes.brandIcon}
                src={getImageByKey(
                  `${
                    recommendationData.source.charAt(0).toUpperCase() +
                    recommendationData.source.slice(1).toLowerCase()
                  }Icon`
                )}
                alt={`${recommendationData.source}-Icon`}
                style={{ width: "35px" }}
              />
              {recommendationData.source}
            </TGTableCell>
            <TGTableCell>{recommendationData.platformID}</TGTableCell>
          </>
        ) : null}
        <TGTableCell hidden={isRecommendation} width={300} align="center">
          <IconButton onClick={handleClickToggleUpdate}>
            <EditIcon style={{ color: "#ABABAB" }} />
          </IconButton>
          <Dialog
            id={`dialog-${indexRow}`}
            open={openUpdate}
            onClose={handleClickToggleUpdate}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Edit target</DialogTitle>
            <Box component="form" onSubmit={handleSubmit}>
              <EditTargetDialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="nameTarget"
                  label="Name"
                  type="text"
                  variant="outlined"
                  placeholder={name}
                  fullWidth
                  onChange={setNewTargetName}
                />
                {/* <TextField
                  margin="dense"
                  id="mediaId"
                  label="Media ID"
                  type="text"
                  variant="outlined"
                  value={id}
                  fullWidth
                  disabled
                /> */}
              </EditTargetDialogContent>
              <GroupDialogActions>
                <ButtonSecondary onClick={handleClickToggleUpdate}>
                  Cancel
                </ButtonSecondary>
                <ButtonPrimary type="submit">Update</ButtonPrimary>
              </GroupDialogActions>
            </Box>
          </Dialog>

          <DeleteIconButton
            itemId={indexRow}
            itemName={name ?? ""}
            type=""
            deleteItem={handleDeleteTarget}
            data={indexRow}
            itemDeleted={deletedItem}
          />
          {/* <IconButton onClick={handleClickToggleDelete}>
            <DeleteIcon />
          </IconButton>          
          <Dialog
            id={`dialog-${indexRow}`}
            open={openDelete}
            onClose={handleClickToggleDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete target</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want delete &quot;{name}&quot; target?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonSecondary onClick={handleClickToggleDelete}>
                Cancel
              </ButtonSecondary>
              <ButtonPrimary
                onClick={(event) => handleDeleteTarget(indexRow)}
                autoFocus
              >
                Delete
              </ButtonPrimary>
            </DialogActions>
          </Dialog> */}
        </TGTableCell>
        {isBehavior ? (
          <TGTableCell width="1rem" style={{ paddingLeft: "0px" }}>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              className="TGTableCell"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TGTableCell>
        ) : null}
      </TableRow>
      {isBehavior ? (
        <TableRow style={{ background: "#fff" }}>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headersColap.length + 2}
          >
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Paginator
                totalItems={resultsTotal}
                getNewItems={handleUpdateItems}
              />
              <SCBehaviorsResults.BRTable
                title=""
                headers={headersColap}
                items={behaviorMedias}
                ItemComponent={SCBehaviorsResults.BehaviorItemCollapsable}
                ItemComponentProps={{
                  hideCollapse: true,
                  classes: {
                    tagsBtn: classes.tagsBtn,
                    tagsPopover: classes.tagsPopover,
                  },
                }}
                thClass={classesBR.tableHeader}
                tbClass={classesBR.tableBody}
                tableClass={classesBR.tableContainer}
                extraSpaces={2}
                lastExtraSpaces={false}
                sortEnable
                handleOnClickCopy={handlerOnClickCopy}
              />
              {/* <div>{JSON.stringify(content)}</div> */}
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

const useStylesListItem = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      width: "100%",
      boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      "& .MuiCardContent-root": {
        padding: "0px",
      },
    },
    itemTitle: {
      height: "24px",
      flex: "1",
      fontSize: "22px",
      lineHeight: "28px",
      fontWeight: 800,
      [theme.breakpoints.down(540)]: {
        fontSize: theme.fontGeneralStyles.medium.fontSize,
        lineHeight: theme.fontGeneralStyles.medium.lineHeight,
      },
    },
    tableTitle: {
      flex: "1",
      color: "#000 !important",
      fontSize: theme.fontGeneralStyles.medium.fontSize,
      lineHeight: theme.fontGeneralStyles.medium.lineHeight,
      fontStyle: "normal !important",
      fontWeight: "bold",
      fontFamily: "Gothic A1 !important",
      [theme.breakpoints.down(540)]: {
        fontSize: theme.fontGeneralStyles.small.fontSize,
        lineHeight: theme.fontGeneralStyles.small.lineHeight,
      },
    },
    itemHeader: {
      minHeight: "46px",
      padding: "17px 16px 12px 16px",
      "& button": {
        padding: "0px",
      },
      [theme.breakpoints.down(540)]: {
        padding: "10px",
      },
    },
    headerDivider: {
      maxWidth: "calc(100% - 32px)",
      margin: "auto",
      [theme.breakpoints.down(540)]: {
        maxWidth: "calc(100% - 20px)",
      },
    },
    updateBtn: {
      margin: "auto",
      marginRight: "10px !important",
    },
    deleteBtn: {
      margin: "auto",
      marginLeft: "10px !important",
    },
    hide: {
      display: "none",
    },
    recommendationBtn: {
      margin: "auto",
      marginRight: "10px !important",
      height: theme.buttonSize.large.height,
      width: theme.buttonSize.large.width,
      fontWeight: theme.buttonSize.large.fontWeight,
      [theme.breakpoints.down(540)]: {
        minWidth: "unset",
        height: "30px",
        width: "30px",
        margin: "unset !important",
        borderRadius: "50%",
        "& .MuiButton-startIcon ": {
          margin: "unset",
        },
      },
    },
    recommendationTitle: {
      textTransform: "capitalize",
      maxWidth: "300px",
      overflow: "hidden",
      wordBreak: "break-word",
    },
    sectionTableTitle: {
      height: "86px",
      display: "flex",
      alignItems: "center",
      padding: "37px 16px 11px 16px",
      [theme.breakpoints.down(540)]: {
        flexWrap: "wrap",
        height: "unset",
        padding: "17px 10px 10px",
        justifyContent: "space-between",
      },
    },
    linkStyle: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #ABABAB",
      borderRadius: "0px",
      maxWidth: "50px",
      "& .MuiInputBase-root": {
        marginLeft: "5px",
      },
      "& .MuiInputBase-root:before": {
        border: "none !important",
      },
      "& .MuiInputBase-root:after": {
        border: "none",
      },
    },
    gray: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#ABABAB",
    },
    elemStyle: {
      marginRight: "30px",
      [theme.breakpoints.down(540)]: {
        marginRight: "5px",
      },
    },
  })
);

const NoResults = withStyles(() => ({
  root: {
    boxShadow: "unset",
  },
}))(GenericEmptyMessages);

const GroupPaginator = withStyles((theme: Theme) => ({
  root: {
    minHeight: "unset !important",
    width: "unset !important",
    margin: "auto",
    marginRight: "0px",
    padding: "unset !important",
  },
}))(Paginator);

interface ListItemProps {
  group: any;
  indexGroup: string;
  updateTargets: any;
  showToast: any;
}
const TGListItem: React.FC<ListItemProps> = ({
  group,
  indexGroup,
  updateTargets,
  showToast,
}) => {
  const classes = useStylesListItem();

  // useStates definitions
  const [expanded, setExpanded] = React.useState(false);
  const [expandedRecommendations, setExpandedRecommendations] =
    React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [newTargetName, setNewTargetName] = React.useState<any>({});
  const [mediaRecommendationsResult, setMediaRecommendationsResult] =
    React.useState<any>([]);
  const [recommendationsMediasIds, setRecommendationsMediasIds] =
    React.useState<string[]>([]);

  const [totalRecommendations, setTotalRecommendations] =
    React.useState<number>(50);

  // Checkbox select item variables
  const [selectedAllMedias, setSelectedAllMedias] = React.useState(false);
  const [selectedMedias, setSelectedMedias] = React.useState<string[]>([]);
  const [selectedAllBehaviors, setSelectedAllBehaviors] = React.useState(false);
  const [behaviorsSelected, setBehaviorsSelected] = React.useState<string[]>(
    []
  );
  const [selectedAllRecommendations, setSelectedAllRecommendation] =
    React.useState(false);
  const [recommendationsSelected, setRecommendationsSelected] = React.useState<
    string[]
  >([]);

  // pagination component variables
  const [mediasPerPage, setMediasPerPage] = React.useState(5);
  const [mediasPage, setMediasPage] = React.useState(0);
  const [behaviorsPerPage, setBehaviorsPerPage] = React.useState(5);
  const [behaviorsPage, setBehaviorsPage] = React.useState(0);
  const [recommendationsPerPage, setRecommendationsPerPage] = React.useState(5);
  const [recommendationsPage, setRecommendationsPage] = React.useState(0);
  const [deletedItem, setDeletedItem] = React.useState(false);

  // Handles definitions
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleRecommendationsExpand = (event: any) => {
    if (
      event.target &&
      event.target.tagName !== "INPUT" &&
      event.target.tagName !== "BUTTON" &&
      event.target.parentElement.tagName !== "BUTTON"
    ) {
      setExpandedRecommendations(!expandedRecommendations);
    }
  };

  const [getVideosIds, { data: videosIds, error: videosIdsError }] =
    useGetVideosIdsByMediaIdLazyQuery({
      context: {
        clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
      },
      fetchPolicy: "no-cache",
    });

  const {
    data: targets,
    loading: itemsLoading,
    error: itemsError,
    refetch: refreshTargets,
  } = useGetGroupTargetsByIdQuery({
    variables: {
      groupId: indexGroup,
    },
    fetchPolicy: "no-cache",
  });

  const items = React.useMemo(
    () => targets?.GetTargetsByGroup ?? [],
    [targets]
  );
  const mediaItems: string[] = React.useMemo(() => {
    let currentTargets: any = [];
    if (targets?.GetTargetsByGroup && targets!.GetTargetsByGroup.length > 0) {
      currentTargets = targets!.GetTargetsByGroup.filter((target: any) => {
        return target.sourceType === "media";
        // if (target.sourceType === "media") return target;
      });
    }
    return currentTargets;
  }, [targets]);

  const currentMediasIds: string[] = React.useMemo(() => {
    return mediaItems.map((target: any) => {
      return target.sourceId;
    });
  }, [mediaItems]);

  const behaviorItems = React.useMemo(() => {
    let currentTargets: any = [];
    if (targets?.GetTargetsByGroup && targets!.GetTargetsByGroup.length > 0) {
      currentTargets = targets!.GetTargetsByGroup.filter((target: any) => {
        return target.sourceType === "behavior";
      });
    }
    return currentTargets;
  }, [targets]);

  const behaviorMediasIds = React.useMemo(() => {
    let mediasIdsTemp: any = [];
    behaviorItems.forEach((target: any) => {
      mediasIdsTemp = mediasIdsTemp.concat(JSON.parse(target.mediasIds));
    });
    return mediasIdsTemp;
  }, [behaviorItems]);

  const [
    getRecommendationsResults,
    {
      data: mediaRecommendationsData,
      loading: mediaRecommendationsDataLoading,
      error: mediaRecommendationsDataError,
    },
  ] = useGetMediasRecommendationsLazyQuery({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTRECOMMENDATIONS,
    },
    fetchPolicy: "no-cache",
  });

  const itemloading = itemsLoading;
  const errorHandler = useAppErrorHandler(itemsError);

  const [deleteTargetsGroup, { loading: deleteTargetsGroupLoading }] =
    useDeleteTargetsGroupMutation({
      update(cache, { data }) {
        const groupToDeleteId = data?.DeleteTargetsGroup;
        cache.modify({
          fields: {
            GetTargetsGroup(existingTargetsGroup, { readField }) {
              return existingTargetsGroup.filter(
                (groupData: Reference | StoreObject | undefined) =>
                  groupToDeleteId !== readField("id", groupData)
              );
            },
          },
        });
      },
    });
  const [updateTargetsGroup, { loading: updateTargetsGroupLoading }] =
    useUpdateTargetsGroupMutation();

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  const handleClickToggleUpdate: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenUpdate(!openUpdate);
    }, [openUpdate, setOpenUpdate]);

  const handleDeleteTargetGroup = async (index: string) => {
    await deleteTargetsGroup({
      variables: {
        groupId: index,
      },
    });

    showToast({
      message: "The group has been deleted successfully",
      severity: "warning",
    });

    setDeletedItem(true);
    updateTargets();
    handleClickToggleDelete();
  };

  const handleChangeTotalRecommendations = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let total = 0;
    if (event.target.value) {
      total = Number(event.target.value);
    }
    setTotalRecommendations(total);
  };

  const handleSelectAllMedias = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = mediaItems.map((n: any) => n.sourceId);
      setSelectedMedias(newSelecteds);
      setSelectedAllMedias(true);
      return;
    }
    setSelectedMedias([]);
    setSelectedAllMedias(false);
  };

  const handleSelectMedia = (checkstatus: boolean, itemId: string) => {
    const currentSelects = selectedMedias;

    if (checkstatus) {
      currentSelects.push(itemId);
    } else {
      const currentIndex = currentSelects.findIndex((id) => id === itemId);
      currentSelects.splice(currentIndex, 1);
    }
    setSelectedMedias(currentSelects);
    setSelectedAllMedias(
      mediaItems.length > 0 && currentSelects.length === mediaItems.length
    );
  };

  // const handleClickItemSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelecteds = mediaItems.map((n: any) => n.sourceId);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleSelectAllBehaviors = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = behaviorItems.map((n: any) => n.sourceId);
      setBehaviorsSelected(newSelecteds);
      setSelectedAllBehaviors(true);
      return;
    }
    setBehaviorsSelected([]);
    setSelectedAllBehaviors(false);
  };

  const handleSelectBehavior = (checkstatus: boolean, itemId: string) => {
    const currentSelects = behaviorsSelected;

    if (checkstatus) {
      currentSelects.push(itemId);
    } else {
      const currentIndex = currentSelects.findIndex((id) => id === itemId);
      currentSelects.splice(currentIndex, 1);
    }
    setBehaviorsSelected(currentSelects);
    setSelectedAllBehaviors(
      behaviorItems.length > 0 && currentSelects.length === behaviorItems.length
    );
  };

  const handleSelectAllRecommendationsClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = mediaRecommendationsResult.map(
        (n: any) => n.audionautID
      );
      setRecommendationsSelected(newSelecteds);
      setSelectedAllRecommendation(true);
      return;
    }
    setRecommendationsSelected([]);
    setSelectedAllRecommendation(false);
  };

  const handleSelectRecommendation = (checkstatus: boolean, itemId: string) => {
    const currentSelects = recommendationsSelected;

    if (checkstatus) {
      currentSelects.push(itemId);
    } else {
      const currentIndex = currentSelects.findIndex(
        (audionautID: string) => audionautID === itemId
      );
      currentSelects.splice(currentIndex, 1);
    }
    setRecommendationsSelected(currentSelects);
    setSelectedAllRecommendation(
      mediaRecommendationsResult.length > 0 &&
        currentSelects.length === mediaRecommendationsResult.length
    );
  };

  const handleSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      const groupUpdated = await updateTargetsGroup({
        variables: {
          groupId: indexGroup,
          groupName: newTargetName.target.value,
        },
      });

      showToast({
        message: "The group has been updated successfully",
        severity: "success",
      });

      updateTargets();
      handleClickToggleUpdate(event);
    },
    [
      indexGroup,
      newTargetName,
      updateTargets,
      handleClickToggleUpdate,
      updateTargetsGroup,
      showToast,
    ]
  );

  const handleClickGetRecommendations: React.FormEventHandler =
    React.useCallback(async () => {
      try {
        getRecommendationsResults({
          variables: {
            total: totalRecommendations,
            mediasIds: [...currentMediasIds, ...behaviorMediasIds],
          },
        });
      } catch (error: any) {
        // console.debug("=====> Recommendations error: ", error);
        // errorHandler(
        //   new UIError(
        //     UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
        //     "An error has ocurred while adding new target"
        //   )
        // );
      }
    }, [
      getRecommendationsResults,
      currentMediasIds,
      behaviorMediasIds,
      totalRecommendations,
    ]);

  const handleClickGetVideosIds = (type: number) => {
    try {
      let mediasIds: string | string[] | null = null;
      if (type === 3) {
        mediasIds = recommendationsMediasIds.join("\n");
        navigator.clipboard.writeText(mediasIds);

        showToast({
          message: "Videos Ids copied in the clipboard successfull",
          severity: "success",
          delay: 3000,
        });
      } else {
        switch (type) {
          case 1:
            mediasIds = currentMediasIds;
            break;
          case 2:
            mediasIds = behaviorMediasIds;
            break;
          default:
            mediasIds = [...currentMediasIds, ...behaviorMediasIds];
            break;
        }
        getVideosIds({
          variables: {
            ids: mediasIds,
          },
        });
        showToast({
          message: "copying video ids ...",
          severity: "success",
          delay: 5000,
        });
      }
    } catch (error: any) {
      // console.debug("=====> Recommendations error: ", error);
      // errorHandler(
      //   new UIError(
      //     UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
      //     "An error has ocurred while adding new target"
      //   )
      // );
      showToast({
        message: "An error ocurred to the try copy the videos ids",
        severity: "error",
      });
    }
  };

  React.useEffect(() => {
    if (
      mediaRecommendationsData?.getRecommendations &&
      mediaRecommendationsData.getRecommendations.recommendations
    ) {
      const {
        getRecommendations: { recommendations },
      } = mediaRecommendationsData;

      const mediasIds = recommendations.map(
        (recommendation) => recommendation?.platformID
      );

      setMediaRecommendationsResult(recommendations);
      setRecommendationsMediasIds(mediasIds as string[]);
      setExpandedRecommendations(true);
    }
  }, [mediaRecommendationsData]);

  React.useEffect(() => {
    if (videosIds?.getMediaId) {
      const ids: string[] = [];
      videosIds.getMediaId.forEach((metadata) => {
        const { mediaId } = metadata as any;
        const existId = ids.find((currentId) => currentId === mediaId);
        if (mediaId && !existId) {
          ids.push(mediaId);
        }
        return mediaId ?? "";
      });
      const idsStr = ids.join("\n");
      navigator.clipboard.writeText(idsStr);

      showToast({
        message: "Videos Ids copied in the clipboard successfull",
        severity: "success",
        delay: 3000,
      });
    }
  }, [videosIds, showToast]);

  return (
    <Card key={`group-${indexGroup}`} className={classes.itemContainer}>
      <ProgressIndicator open={itemloading} />
      <CardActions className={classes.itemHeader} onClick={handleExpandClick}>
        <Typography className={classes.itemTitle}>{group.name}</Typography>
        <AppPermissionValidator
          appPermission={APP_PERMISSION.TARGET_ADDEDIT_TARGET_GROUPS}
        >
          <CopyIconButton
            handleOnClick={() => {
              handleClickGetVideosIds(0);
            }}
            disabled={
              currentMediasIds.length === 0 && behaviorMediasIds.length === 0
            }
          />
        </AppPermissionValidator>
        <AppPermissionValidator
          appPermission={APP_PERMISSION.TARGET_ADDEDIT_TARGET_GROUPS}
        >
          <IconButton
            hidden={!group.editable}
            onClick={handleClickToggleUpdate}
          >
            <EditIcon style={{ color: "#ABABAB" }} />
          </IconButton>
        </AppPermissionValidator>
        <Dialog
          id={`dialog-${indexGroup}`}
          open={openUpdate}
          onClose={handleClickToggleUpdate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit group name</DialogTitle>
          <Box component="form" onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                Please typing a new group name:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="nameTarget"
                label="Name"
                type="text"
                variant="outlined"
                placeholder={group.name}
                fullWidth
                onChange={setNewTargetName}
              />
              {/* <TextField
                margin="dense"
                id="mediaId"
                label="Media ID"
                type="text"
                variant="outlined"
                value={indexGroup}
                fullWidth
                disabled
              /> */}
            </DialogContent>
            <GroupDialogActions>
              <ButtonSecondary onClick={handleClickToggleUpdate}>
                Cancel
              </ButtonSecondary>
              <ButtonPrimary type="submit">Update</ButtonPrimary>
            </GroupDialogActions>
          </Box>
        </Dialog>

        <AppPermissionValidator
          appPermission={APP_PERMISSION.TARGET_ADDEDIT_TARGET_GROUPS}
        >
          <Box hidden={!group.editable}>
            <DeleteIconButton
              itemId={indexGroup}
              itemName={group.name ?? ""}
              type=""
              deleteItem={handleDeleteTargetGroup}
              data={indexGroup}
              itemDeleted={deletedItem}
            />
          </Box>
        </AppPermissionValidator>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </CardActions>
      <Divider
        className={clsx(classes.headerDivider, {
          [classes.hide]: !expanded,
        })}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {mediaItems.length > 0 ? (
            <>
              <Box className={classes.sectionTableTitle}>
                <Typography className={classes.tableTitle}>Medias</Typography>
                <GroupPaginator
                  totalItems={mediaItems.length}
                  updateItems={setMediasPerPage}
                  updatePage={setMediasPage}
                />
              </Box>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <TGTableHead>
                    <TableRow>
                      <TableCellSticky padding="checkbox" className="firstCell">
                        <CustomCheckBox
                          checked={selectedAllMedias}
                          onChange={handleSelectAllMedias}
                          inputProps={{ "aria-label": "select all medias" }}
                        />
                      </TableCellSticky>
                      <TableCellSticky
                        colSpan={1}
                        className="lastCell headTitle"
                      >
                        <Typography>Name</Typography>
                      </TableCellSticky>
                      <TGTableCell colSpan={1}>
                        <Typography>Media Title</Typography>
                      </TGTableCell>
                      <TGTableCell colSpan={1}>
                        <Typography>Author</Typography>
                      </TGTableCell>
                      <TGTableCell colSpan={1}>
                        <Typography>Source</Typography>
                      </TGTableCell>
                      <TGTableCell colSpan={1}>
                        <Typography>
                          Source ID{" "}
                          <CopyIconButton
                            handleOnClick={() => {
                              handleClickGetVideosIds(1);
                            }}
                          />
                        </Typography>
                      </TGTableCell>
                      <TGTableCell colSpan={1}>
                        <Typography>Tags</Typography>
                      </TGTableCell>
                      <TGTableCell width={300} align="center">
                        <Typography>Actions</Typography>
                      </TGTableCell>
                    </TableRow>
                  </TGTableHead>
                  {mediaItems && mediaItems.length > 0 ? (
                    <TGTableBody>
                      {(mediasPerPage > 0
                        ? mediaItems.slice(
                            mediasPage * mediasPerPage,
                            mediasPage * mediasPerPage + mediasPerPage
                          )
                        : mediaItems
                      ).map((target: any) => {
                        return (
                          <TGRowItem
                            id={target.sourceId}
                            name={target.name}
                            source={target.source}
                            parentId={indexGroup}
                            indexRow={target.id}
                            updateTargets={refreshTargets}
                            showToast={showToast}
                            selected={selectedMedias}
                            selectItem={handleSelectMedia}
                            handlerOnClickCopy={() => {}}
                          />
                        );
                      })}
                    </TGTableBody>
                  ) : (
                    "Not have content"
                  )}
                </Table>
              </TableContainer>
            </>
          ) : null}
          {behaviorItems.length > 0 ? (
            <>
              <Box className={classes.sectionTableTitle}>
                <Typography className={classes.itemTitle}>Behaviors</Typography>
                <Paginator
                  totalItems={behaviorItems.length}
                  updateItems={setBehaviorsPerPage}
                  updatePage={setBehaviorsPage}
                />
              </Box>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TGTableHead>
                    <TableRow>
                      <TGTableCell padding="checkbox">
                        <CustomCheckBox
                          checked={selectedAllBehaviors}
                          onChange={handleSelectAllBehaviors}
                          inputProps={{ "aria-label": "select all behaviors" }}
                        />
                      </TGTableCell>
                      <TGTableCell colSpan={1}>
                        <Typography>Name</Typography>
                      </TGTableCell>
                      <TGTableCell colSpan={1}>
                        <Typography>Behavior ID</Typography>
                      </TGTableCell>
                      <TGTableCell width={300} align="center">
                        <Typography>Actions</Typography>
                      </TGTableCell>
                      <TGTableCell width="1rem" />
                    </TableRow>
                  </TGTableHead>
                  {behaviorItems && behaviorItems.length > 0 ? (
                    <TGTableBody>
                      {(behaviorsPerPage > 0
                        ? behaviorItems.slice(
                            behaviorsPage * behaviorsPerPage,
                            behaviorsPage * behaviorsPerPage + behaviorsPerPage
                          )
                        : behaviorItems
                      ).map((target: any) => {
                        return (
                          <TGRowItem
                            id={target.sourceId}
                            name={target.name}
                            source={target.source}
                            parentId={indexGroup}
                            indexRow={target.id}
                            updateTargets={refreshTargets}
                            showToast={showToast}
                            selected={behaviorsSelected}
                            selectItem={handleSelectBehavior}
                            isBehavior
                            handlerOnClickCopy={() => {
                              handleClickGetVideosIds(2);
                            }}
                          />
                        );
                      })}
                    </TGTableBody>
                  ) : (
                    "Not have content"
                  )}
                </Table>
              </TableContainer>
            </>
          ) : null}
          <NoResults
            show={mediaItems.length === 0 && behaviorItems.length === 0}
            title="No targets"
            description="There isn't any medias or behaviors targets selected for this group."
          />
        </CardContent>
        <>
          <Divider />
          <CardActions
            className={classes.itemHeader}
            onClick={(event: any) => {
              if (mediaItems.length > 0 && behaviorItems.length > 0)
                handleRecommendationsExpand(event);
            }}
          >
            <Typography
              className={classes.itemTitle}
              style={{
                paddingLeft: "14px",
              }}
            >
              Recommendations
            </Typography>
            <Box
              component="div"
              className={`${classes.linkStyle} ${classes.gray} ${classes.elemStyle}`}
            >
              <TextField
                disabled={mediaItems.length === 0 && behaviorItems.length === 0}
                label="Total"
                placeholder="Total"
                onChange={handleChangeTotalRecommendations}
                value={totalRecommendations}
              />
            </Box>
            <ButtonPrimary
              disabled={mediaItems.length === 0 && behaviorItems.length === 0}
              type="button"
              onClick={handleClickGetRecommendations}
              className={classes.recommendationBtn}
              startIcon={<VerticalAlignBottomIcon />}
            >
              {isMobileResolution() ? "" : "Get Recommendations"}
            </ButtonPrimary>
            <IconButton
              onClick={(event: any) => {
                if (mediaItems.length > 0 || behaviorItems.length > 0)
                  handleRecommendationsExpand(event);
              }}
              aria-expanded={expandedRecommendations}
              aria-label="show more recommendations"
              disabled={mediaItems.length === 0 && behaviorItems.length === 0}
            >
              {expandedRecommendations ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </CardActions>
          <Box
            hidden={!mediaRecommendationsDataLoading}
            style={{
              backgroundColor: "rgba(0,0,0,.1)",
              display: "flex",
              width: "100%",
            }}
          >
            <CircularProgress style={{ margin: "auto" }} />
          </Box>
          <Collapse
            in={expandedRecommendations}
            timeout="auto"
            unmountOnExit
            style={{
              paddingLeft: "30px",
              paddingRight: "16px",
              paddingBottom: "16px",
            }}
          >
            {mediaRecommendationsResult &&
            mediaRecommendationsResult.length > 0 ? (
              <>
                <Paginator
                  totalItems={mediaRecommendationsResult.length}
                  updateItems={setRecommendationsPerPage}
                  updatePage={setRecommendationsPage}
                />
                <TableContainer
                  style={{
                    maxHeight: "300px",
                  }}
                >
                  <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                  >
                    <TGTableHead>
                      <TableRow>
                        <TGTableCell padding="checkbox">
                          <CustomCheckBox
                            checked={selectedAllRecommendations}
                            onChange={handleSelectAllRecommendationsClick}
                            inputProps={{
                              "aria-label": "select all recommendations",
                            }}
                          />
                        </TGTableCell>
                        <TGTableCell colSpan={1}>
                          <Typography>Video Title</Typography>
                        </TGTableCell>
                        <TGTableCell colSpan={1}>
                          <Typography>Platform Name</Typography>
                        </TGTableCell>
                        <TGTableCell colSpan={1}>
                          <Typography>
                            Platform ID
                            <CopyIconButton
                              handleOnClick={() => {
                                handleClickGetVideosIds(3);
                              }}
                            />
                          </Typography>
                        </TGTableCell>
                      </TableRow>
                    </TGTableHead>
                    {mediaRecommendationsResult &&
                    mediaRecommendationsResult.length > 0 ? (
                      <TGTableBody>
                        {
                          (recommendationsPerPage > 0
                            ? mediaRecommendationsResult.slice(
                                recommendationsPage * recommendationsPerPage,
                                recommendationsPage * recommendationsPerPage +
                                  recommendationsPerPage
                              )
                            : mediaRecommendationsResult
                          ).map((recommendation: any, key: any) => {
                            return (
                              <TGRowItem
                                id={recommendation.audionautID}
                                name={recommendation.title}
                                source={recommendation.source}
                                parentId={indexGroup}
                                indexRow={recommendation.audionautID}
                                selected={recommendationsSelected}
                                selectItem={handleSelectRecommendation}
                                isRecommendation
                                recommendationData={recommendation}
                                handlerOnClickCopy={() => {}}
                              />
                            );
                          })

                          // mediaRecommendationsResult.map(
                          //   (recommendation: any, key: any) => {
                          //     const isItemSelected = isRecommendationSelected(
                          //       recommendation.audionautID
                          //     );
                          //     return (
                          //       <TableRow id={`row-${key}`} style={{ height: 55 }}>
                          //         <TGTableCell padding="checkbox">
                          //           <Checkbox
                          //             checked={isItemSelected}
                          //             inputProps={{ "aria-labelledby": key }}
                          //           />
                          //         </TGTableCell>
                          //         <TGTableCell
                          //           className={classes.recommendationTitle}
                          //         >
                          //           {recommendation.title}
                          //         </TGTableCell>
                          //         <TGTableCell>
                          //           <img
                          //             className={classes.brandIcon}
                          //             src={getImageByKey(
                          //               `${
                          //                 recommendation.source
                          //                   .charAt(0)
                          //                   .toUpperCase() +
                          //                 recommendation.source
                          //                   .slice(1)
                          //                   .toLowerCase()
                          //               }Icon`
                          //             )}
                          //             alt={`${recommendation.source}-Icon`}
                          //             style={{ width: "35px" }}
                          //           />
                          //           {recommendation.source}
                          //         </TGTableCell>
                          //         <TGTableCell>
                          //           {recommendation.audionautID}
                          //         </TGTableCell>
                          //         <TGTableCell>
                          //           {recommendation.platformID}
                          //         </TGTableCell>
                          //       </TableRow>
                          //     );
                          //   }
                          // )
                        }
                      </TGTableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              </>
            ) : (
              <NoResults
                show={
                  !(
                    mediaRecommendationsDataLoading &&
                    (!mediaRecommendationsResult ||
                      (mediaRecommendationsResult &&
                        mediaRecommendationsResult.length === 0))
                  )
                }
                title="No recommendations"
                description={
                  "The current media ids don't have any recommendations result, try run " +
                  '"' +
                  "Get Recommendation" +
                  '"' +
                  " button."
                }
              />
              // <Box
              //   hidden={
              //     mediaRecommendationsDataLoading &&
              //     (!mediaRecommendationsResult ||
              //       (mediaRecommendationsResult &&
              //         mediaRecommendationsResult.length === 0))
              //   }
              // >
              //   <Typography
              //     style={{
              //       margin: "auto",
              //       textAlign: "center",
              //       paddingTop: "10px",
              //       paddingBottom: "10px",
              //     }}
              //   >
              //     There is not recommendations to show!
              //   </Typography>
              // </Box>
            )}
          </Collapse>
        </>
      </Collapse>
    </Card>
  );
};

export default {
  TGContainer,
  TGContent,
  TGHeader,
  TGBody,
  TGTableHead,
  TGTableBody,
  TGRowItem,
  TGListItem,
  GroupTextField,
  GroupDialogContent,
  GroupDialogActions,
  TGHeaderTitle,
  GroupPaginator,
  useStyles,
};
