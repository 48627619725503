import {
  withStyles,
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  Link,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textClipped: { ...theme.commons.textClipped },
  })
);

const CardTitle = withStyles((theme: Theme) => ({
  root: {
    textAlign: "left",
    marginBottom: "24px",
    "& h6": {
      color: theme.theming.gray1.color,
      fontWeight: 500,
    },
    "& .authors": {
      color: theme.generalStylesContainer.chat.remote.color2,
      marginBottom: "5px",
    },
    "& .padding-left": {
      paddingLeft: "15px",
    },
  },
}))(Box);

const MediaTitle = withStyles((theme: Theme) => ({
  root: {
    color: "#1D1D1D",
    fontSize: theme.fontGeneralStyles.medium.fontSize,
    marginBottom: "14px",
  },
}))(Typography);

const MediaTitleLink = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "inline-block",
    color: theme.generalStylesContainer.chat.remote.color,
    fontSize: theme.fontGeneralStyles.medium.fontSize,
    lineHeight: theme.fontGeneralStyles.medium.lineHeight,
    marginBottom: "10px",
    "&:hover": {
      color: theme.generalStylesContainer.color,
    },
    [theme.breakpoints.down(540)]: {
      maxWidth: "calc(100vw - 155px)",
    },
  },
}))(Link);

const MediaContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginBottom: "8px",
    "& img": {
      "&.catalog-image": {
        borderRadius: "40px",
      },
    },
    "& image": {
      height: "170px",
      width: "294px",
      borderRadius: "7px",
      "&.catalog-image": {
        borderRadius: "40px",
      },
    },
    "& video": {
      maxWidth: "294px",
    },
    "& iframe": {
      maxWidth: "294px",
      borderRadius: "7px",
      border: "unset",
    },
    "&.widget-media-content": {
      flexDirection: "column",
      rowGap: "10px",
      "& iframe": {
        maxWidth: "unset",
        width: "100%",
      },
      "& .widget-media-desc": {
        padding: "unset",
      },
    },
    "& .big-video": {
      minHeight: "400px",
      height: "100%",
    },
    [theme.breakpoints.down(991)]: {
      flexDirection: "column",
      rowGap: "10px",
      "& iframe": {
        maxWidth: "unset",
        width: "100%",
      },
    },
  },
}))(Box);

const MediaDescription = withStyles((theme: Theme) => ({
  root: {
    flex: "1",
    textAlign: "left",
    paddingLeft: "16px",
    "& p": {
      fontWeight: 600,
      color: "#373737",
      marginBottom: "22px",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-all",
      hyphens: "auto",
    },
    [theme.breakpoints.down(540)]: {
      padding: "unset",
    },
  },
}))(Box);

const MediaExtras = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    columnGap: "5px",
    "& h6": {
      fontWeight: 500,
      color: "#959595",
    },
    "& h6:first-child": {
      paddingRight: "5px",
      borderRight: "1px solid",
      borderColor: "#959595",
    },
  },
}))(Box);

export default {
  useStyles,
  CardTitle,
  MediaTitle,
  MediaTitleLink,
  MediaContent,
  MediaDescription,
  MediaExtras,
};
