/* eslint-disable import/no-extraneous-dependencies */
import _ from "lodash";

const NetworkErrorCodes = {
  SERVER_NOT_REACHABLE: "server-not-reachable",
  HTTP_UNAUTHORIZED: "http-unauthorized",
} as const;
type NetworkErrorType = keyof typeof NetworkErrorCodes;
type NetworkErrorCode = typeof NetworkErrorCodes[NetworkErrorType];
const isNetworkErrorCode = (
  maybeNetworkErrorCode: unknown
): maybeNetworkErrorCode is NetworkErrorCode => {
  return (
    _.isString(maybeNetworkErrorCode) &&
    Object.values<string>(NetworkErrorCodes).includes(maybeNetworkErrorCode)
  );
};
class NetworkError extends Error {
  public readonly code: NetworkErrorCode;

  public constructor(
    code: NetworkErrorCode,
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...params: any[]
  ) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError);
    }
    this.name = "NetworkError";
    this.code = code;
    this.message = message;
  }
}
export { NetworkErrorCodes, isNetworkErrorCode, NetworkError };
export type { NetworkErrorType, NetworkErrorCode };
