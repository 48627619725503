import React from "react";
import { Box, Typography } from "@material-ui/core";

import { PaymentMethod } from "graphql/types-and-hooks";

import MasterCardBrand from "assets/icons/mastercard.png";
import VisaBrand from "assets/icons/visa.png";

import SC from "./payment_method_card.styles";

type ButtonType = "button" | "reset" | "submit" | undefined;

interface PaymentMethodCardProps {
  show: boolean;
  paymentMethod: PaymentMethod | undefined;
  onSubmit: any;
  onCancel: any;
  isWizard: boolean;
  customClasses: string;
}
const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  show = false,
  paymentMethod,
  onSubmit,
  onCancel,
  isWizard = false,
  customClasses,
}) => {
  const { cardLast4, cardExpMonth, cardExpYear } = paymentMethod!;

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      onSubmit(paymentMethod);
      // if (paymentMethodInfo !== paymentMethodData) onSubmit(paymentMethodInfo);
    },
    [onSubmit, paymentMethod]
  );

  const handlerCancelOnClick = React.useCallback(() => {
    // setIsEditable(false);
    // setUserData(initialUserData);
    if (onCancel) onCancel();
  }, [onCancel]);

  const buttons = [
    {
      title: "Next",
      type: "button",
      handler: handlerOnSubmit,
      isShown: true,
      btnClasses: "nextBtn",
    },
    {
      title: "back",
      type: "button",
      handler: handlerCancelOnClick,
      isShown: true,
      btnClasses: "backBtn",
    },
  ];

  return (
    <SC.CardBox hidden={!show} className={customClasses}>
      <Box className="cardPreview">
        <Box className="cardTitle">
          {paymentMethod!.cardBrand === "visa" ||
          paymentMethod!.cardBrand === "Visa" ? (
            <img
              src={VisaBrand}
              alt="brand-icon"
              style={{ width: "auto", height: "21px" }}
            />
          ) : null}
          {paymentMethod!.cardBrand === "mastercard" ? (
            <img
              src={MasterCardBrand}
              alt="brand-icon"
              style={{ width: "auto", height: "21px" }}
            />
          ) : null}
          <Typography>Credit</Typography>
        </Box>
        <Typography className="cardNumber">
          **** **** **** {cardLast4}
        </Typography>
        <Box className="cardFooter">
          <Box className="cardHolder">
            <Typography className="title">Card holder</Typography>
            <Typography className="value">Holder Name</Typography>
          </Box>
          <Box className="expires">
            <Typography className="title">Expires</Typography>
            <Typography className="value">
              {cardExpMonth}/{cardExpYear}
            </Typography>
          </Box>
        </Box>
      </Box>
      <SC.FormActions className={isWizard ? "customAB" : ""}>
        {buttons.map(
          ({ isShown, type, handler, title, btnClasses }) =>
            isShown && (
              <SC.Button
                key={title}
                type={type as ButtonType}
                onClick={handler}
                className={btnClasses}
              >
                {title}
              </SC.Button>
            )
        )}
      </SC.FormActions>
    </SC.CardBox>
  );
};
export default PaymentMethodCard;
