/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from "react";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Theme,
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  Divider,
  Collapse,
  TableContainer,
  CardHeader,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AddIcon from "@material-ui/icons/Add";
import waveBorder from "assets/images/wave-border.png";

import {
  withStyles,
  useTheme,
  createStyles,
  makeStyles,
} from "@material-ui/styles";
import { classNames } from "react-select/src/utils";
import clsx from "clsx";

import {
  useUpdateTargetsGroupMutation,
  useDeleteTargetsGroupMutation,
  useUpdateTargetMutation,
  useDeleteTargetMutation,
  AudionautPlan,
} from "graphql/types-and-hooks";
import { Reference, StoreObject } from "@apollo/client";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      title: {
        "& h1": {
          textAlign: "left",
        },
        pagination: {
          flex: "1",
        },
      },
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    sectionTitle: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "bold",
      textAlign: "left",
      marginBottom: "20px",
    },
  })
);

const TGContainer = withStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    overflow: "hidden",
  },
}))(Box);

const TGContent = withStyles((theme: Theme) => ({
  root: {
    // display: "flex",
    // flexDirection: "column",
    height: "calc(100vh - 60px)",
    width: "100%",
    padding: "30px 32px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px",
    },
  },
}))(Box);

const TGHeader = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    minHeight: "130px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(540)]: {
      minHeight: "60px",
    },
  },
}))(Box);

const TGBody = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px 10px 0px 0px",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    padding: "16px",
    [theme.breakpoints.down(540)]: {
      padding: "unset",
      border: "unset",
      boxShadow: "unset",
      background: "transparent",
      "& .alice-carousel__wrapper": {
        height: "calc(100vh - 300px)",
      },
    },
  },
}))(Box);

const TGTableHead = withStyles(() => ({
  root: {
    height: "46px",
    paddingTop: "16px",
    paddingLeft: "14px",
    paddingRight: "14px",
    "& th": {
      borderBottom: "1px solid #EAEAEA",
      "& p": {
        fontFamily: "Gothic A1 !important",
        fontStyle: "normal !important",
        fontWeight: "800 !important",
        fontSize: "18px !important",
        lineHeight: "22px !important",
        color: "#000 !important",
      },
    },
  },
}))(TableHead);

const TGTableBody = withStyles(() => ({
  root: {
    "& td": {
      border: "none",
      fontFamily: "Gothic A1 !important",
      fontStyle: "normal !important",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      lineHeight: "15px !important",
    },
    "& tr:nth-child(even)": {
      background: "#9280BB0A",
    },
  },
}))(TableBody);

interface RowItemProps {
  id: string;
  name: string;
  parentId: string;
  indexRow: string;
  updateTargets: any;
  showToast: any;
}
const TGRowItem: React.FC<RowItemProps> = ({
  id,
  name,
  parentId,
  indexRow,
  updateTargets,
  showToast,
}) => {
  const classes = useStyles();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [newTargetName, setNewTargetName] = React.useState<any>({});

  const [deleteTarget, { loading: deleteTargetLoading }] =
    useDeleteTargetMutation({
      update(cache, { data }) {
        const targetToDeleteId = data?.DeleteTarget;
        cache.modify({
          fields: {
            GetTarget(existingTarget, { readField }) {
              return existingTarget.filter(
                (target: Reference | StoreObject | undefined) =>
                  targetToDeleteId !== readField("id", target)
              );
            },
          },
        });
      },
    });
  const [updateTarget, { loading: updateTargetLoading }] =
    useUpdateTargetMutation();

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const handleClickToggleUpdate: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenUpdate(!openUpdate);
    }, [openUpdate, setOpenUpdate]);

  const handleDeleteTarget = async (index: string) => {
    const targetDeleted = await deleteTarget({
      variables: {
        targetId: index,
      },
    });

    showToast({
      message: "The target has been deleted successfully",
      severity: "warning",
    });

    updateTargets();
    handleClickToggleDelete();
  };
  const handleSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();

      const targetUpdated = await updateTarget({
        variables: {
          targetId: indexRow,
          targetName: newTargetName.target.value,
        },
      });

      showToast({
        message: "The target has been updated successfully",
        severity: "success",
      });

      updateTargets();
      handleClickToggleUpdate(event);
    },
    [
      indexRow,
      newTargetName,
      updateTargets,
      handleClickToggleUpdate,
      updateTarget,
      showToast,
    ]
  );

  return (
    <TableRow id={`row-${indexRow}`} style={{ height: 55 }}>
      <TableCell>{name}</TableCell>
      <TableCell>{id}</TableCell>
      <TableCell width={300} align="center">
        <Button className={classes.updateBtn} onClick={handleClickToggleUpdate}>
          Update
        </Button>
        <Dialog
          id={`dialog-${indexRow}`}
          open={openUpdate}
          onClose={handleClickToggleUpdate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <Box component="form" onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                Please typing a new target name:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="nameTarget"
                label="Name"
                type="text"
                placeholder={name}
                fullWidth
                onChange={setNewTargetName}
              />
              <TextField
                margin="dense"
                id="mediaId"
                label="Media ID"
                type="text"
                value={id}
                fullWidth
                disabled
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickToggleUpdate} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button className={classes.deleteBtn} onClick={handleClickToggleDelete}>
          Delete
        </Button>
        <Dialog
          id={`dialog-${indexRow}`}
          open={openDelete}
          onClose={handleClickToggleDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete target</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want delete the target {name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickToggleDelete} color="primary">
              Cancel
            </Button>
            <Button
              onClick={(event) => handleDeleteTarget(indexRow)}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

const useStylesPlanItem = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      minWidth: "350px",
      height: "328px",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      [theme.breakpoints.down(540)]: {
        minWidth: "unset",
        height: "100%",
      },
    },
    itemTitle: {
      flex: "1",
      fontSize: "18px !important",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: theme.button.primary.backgroundColor,
      color: theme.button.primary.color,
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: theme.button.primary.hoverBackground,
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: theme.button.secondary.backgroundColor,
      color: theme.button.secondary.color,
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: theme.button.secondary.hoverBackground,
      },
    },
    hide: {
      display: "none",
    },
    headerPlan: {
      display: "flex",
      [theme.breakpoints.down(540)]: {
        alignItems: "center",
        gap: "10px",
        marginBottom: "20px",
      },
    },
    editPlan: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: theme.button.secondary.backgroundColor,
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: theme.button.secondary.color,
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
      "&:hover": {
        background: theme.button.secondary.hoverBackground,
        color: theme.button.secondary.hoverColor,
      },
    },
    titlePlan: {
      display: "flex",
      height: "30px",
      width: "208px",
      margin: "auto",
      background: `${theme.palette.primary.main}A3`,
      borderRadius: "10px",
      marginTop: "7px",
      marginBottom: "26px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        margin: "auto",
      },
      [theme.breakpoints.down(540)]: {
        flex: "1",
        margin: "unset",
      },
    },
    planContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "12px 12px 10px 12px !important",
      [theme.breakpoints.down(540)]: {
        padding: "10px",
      },
    },
    planImage: {
      display: "flex",
      height: "62px",
      width: "62px",
      background: `${theme.palette.primary.main}A3`,
      borderRadius: "38px",
      margin: "auto",
      marginBottom: "20px",
      "& svg": {
        margin: "auto",
      },
    },
    planAmount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      fontSize: "35px",
      lineHeight: "44px",
      fontWeight: "bold",
      marginBottom: "17px",
      "& .amount": {
        fontSize: "35px",
        lineHeight: "44px",
        fontWeight: "bold",
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "25px",
        lineHeight: "31px",
      },
      [theme.breakpoints.down(540)]: {
        "& .amount": {
          fontSize: "30px",
          lineHeight: "40px",
        },
        "& .currency": {
          fontSize: "20px",
          lineHeight: "28px",
        },
      },
    },
    planDescription: {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      fontWeight: "normal",
      textAlign: "center",
      marginBottom: "23px",
      paddingLeft: "47px",
      paddingRight: "47px",
      [theme.breakpoints.down(540)]: {
        flex: "1",
        fontSize: theme.fontGeneralStyles.default.fontSize,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
        padding: "0px 20px",
      },
    },
    deletePlan: {
      height: "38px",
      width: "159px",
      border: `2px solid ${theme.button.secondary.border}`,
      boxSizing: "border-box",
      borderRadius: "116px",
      background: theme.button.secondary.backgroundColor,
      color: theme.button.secondary.color,
      "&:hover": {
        border: `2px solid ${theme.button.secondary.hoverBorder}`,
        background: theme.button.secondary.hoverBackground,
        color: theme.button.secondary.hoverColor,
      },
      [theme.breakpoints.down(540)]: {
        height: "30px",
        width: "100%",
      },
    },
    planFooter: {
      display: "flex",
      justifyContent: "end",
    },
    addPlanContainer: {
      padding: "17px 14px 16px 14px",
      border: "none !important",
    },
    addPlanItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      border: `2px dashed ${theme.generalStylesContainer.layers.content.borderColor}`,
      borderRadius: "10px",
      cursor: "pointer",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "normal",
      },
    },
  })
);
interface PlanItemProps {
  planData?: AudionautPlan;
  toggleActiveItem?: any | undefined;
  editItem: any;
}
const PlanItem: React.FC<PlanItemProps> = ({
  planData,
  editItem,
  toggleActiveItem,
}) => {
  const classes = useStylesPlanItem();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [newTargetName, setNewTargetName] = React.useState<any>({});

  // const {
  //   data: targets,
  //   loading: itemsLoading,
  //   error: itemsError,
  // } = useGetGroupTargetsByIdQuery({
  //   variables: {
  //     groupId: indexGroup,
  //   },
  // });
  // const items = React.useMemo(
  //   () => targets?.GetTargetsByGroup ?? [],
  //   [targets]
  // );

  const [deleteTargetsGroup, { loading: deleteTargetsGroupLoading }] =
    useDeleteTargetsGroupMutation({
      update(cache, { data }) {
        const groupToDeleteId = data?.DeleteTargetsGroup;
        cache.modify({
          fields: {
            GetTargetsGroup(existingTargetsGroup, { readField }) {
              return existingTargetsGroup.filter(
                (groupData: Reference | StoreObject | undefined) =>
                  groupToDeleteId !== readField("id", groupData)
              );
            },
          },
        });
      },
    });
  const [updateTargetsGroup, { loading: updateTargetsGroupLoading }] =
    useUpdateTargetsGroupMutation();

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  const handleClickToggleUpdate: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenUpdate(!openUpdate);
    }, [openUpdate, setOpenUpdate]);

  const handleDeleteTarget = async (index: string) => {
    // setOpen(false);

    const groupDeleted = await deleteTargetsGroup({
      variables: {
        groupId: index,
      },
    });

    // showToast({
    //   message: "The group has been deleted successfully",
    //   severity: "warning",
    // });

    // updateTargets();
    handleClickToggleDelete();
  };

  // const handleSubmit: React.FormEventHandler = React.useCallback(
  //   async (event: any) => {
  //     event.preventDefault();
  //     const groupUpdated = await updateTargetsGroup({
  //       variables: {
  //         groupId: indexGroup,
  //         groupName: newTargetName.target.value,
  //       },
  //     });

  //     showToast({
  //       message: "The group has been updated successfully",
  //       severity: "success",
  //     });

  //     updateTargets();
  //     handleClickToggleUpdate(event);
  //   },
  //   [
  //     indexGroup,
  //     newTargetName,
  //     updateTargets,
  //     handleClickToggleUpdate,
  //     updateTargetsGroup,
  //     showToast,
  //   ]
  // );

  return (
    <Card
      className={clsx(classes.itemContainer, {
        [classes.addPlanContainer]: !planData,
      })}
    >
      {planData ? (
        <CardContent className={classes.planContainer}>
          <Box className={classes.headerPlan}>
            <Box className={classes.titlePlan}>
              <Typography color="primary">{planData.name}</Typography>
            </Box>
            <Button
              className={classes.editPlan}
              variant="outlined"
              startIcon={<EditOutlinedIcon />}
              onClick={editItem}
            />
          </Box>
          <Box className={classes.planImage}>
            <StorefrontIcon color="primary" />
          </Box>
          <Box className={classes.planAmount}>
            <Typography className="amount" color="primary">
              {planData.priceInCent ? "$" : ""}
              {planData.priceInCent
                ? Number(planData.priceInCent) / 100
                : "Free"}
            </Typography>
            {planData.priceInCent ? (
              <Typography className="currency" color="secondary">
                USD/MO
              </Typography>
            ) : null}
          </Box>
          <Typography className={classes.planDescription} color="secondary">
            {planData.description}
          </Typography>
          <Box className={classes.planFooter}>
            <Button
              className={classes.deletePlan}
              variant="outlined"
              onClick={() => {
                toggleActiveItem(planData);
              }}
            >
              {planData.active ? "Deactive Plan" : "Active Plan"}
            </Button>
          </Box>
        </CardContent>
      ) : (
        <CardContent className={classes.addPlanItem} onClick={editItem}>
          <AddIcon color="primary" />
          <Typography color="primary">Add New Plan</Typography>
        </CardContent>
      )}

      {/* <CardActions className={classes.itemHeader} onClick={handleExpandClick}>
        <Typography className={classes.itemTitle}>{group.name}</Typography>
        <Button
          className={classes.updateBtn}
          hidden={!group.editable}
          onClick={handleClickToggleUpdate}
        >
          Update
        </Button>
        <Dialog
          id={`dialog-${indexGroup}`}
          open={openUpdate}
          onClose={handleClickToggleUpdate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit target name</DialogTitle>
          <Box component="form" onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                Please typing a new target name:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="nameTarget"
                label="Name"
                type="text"
                placeholder={group.name}
                fullWidth
                onChange={setNewTargetName}
              />
              <TextField
                margin="dense"
                id="mediaId"
                label="Media ID"
                type="text"
                value={indexGroup}
                fullWidth
                disabled
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickToggleUpdate} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button
          className={classes.deleteBtn}
          hidden={!group.editable}
          onClick={handleClickToggleDelete}
        >
          Delete
        </Button>
        <Dialog
          id={`dialog-${indexGroup}`}
          open={openDelete}
          onClose={handleClickToggleDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete target</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want delete the target {group.name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickToggleDelete} color="primary">
              Cancel
            </Button>
            <Button
              onClick={(event) => handleDeleteTarget(indexGroup)}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogAc50%tions>
        </Dialog>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </CardActions>
      <Divider
        className={clsx(classes.headerDivider, {
          [classes.hide]: !expanded,
        })}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TGTableHead>
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography>Name</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography>Media ID</Typography>
                  </TableCell>
                  <TableCell width={300} align="center">
                    <Typography>Actions</Typography>
                  </TableCell>
                </TableRow>
              </TGTableHead>
              {items && items.length > 0 ? (
                <TGTableBody>
                  {items.map((target: any, key: any) => (
                    <TGRowItem
                      id={target.mediaId}
                      name={target.name}
                      parentId={indexGroup}
                      indexRow={target.id}
                      updateTargets={updateTargets}
                      showToast={showToast}
                    />
                  ))}
                </TGTableBody>
              ) : (
                "Not have content"
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse> */}
    </Card>
  );
};

const useStylesCouponItem = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      height: "332px",
      width: "332px",
      margin: "auto",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
      borderRadius: "50%",
      padding: "5px",
    },
    itemTitle: {
      flex: "1",
      color: "#000 !important",
      fontSize: "18px !important",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: theme.button.primary.backgroundColor,
      color: theme.button.primary.color,
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: theme.button.primary.hoverBackground,
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: theme.button.secondary.backgroundColor,
      color: theme.button.secondary.color,
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: theme.button.secondary.hoverBackground,
      },
    },
    hide: {
      display: "none",
    },
    headerCoupon: {
      display: "flex",
      justifyContent: "end",
      marginBottom: "20px",
    },
    editCoupon: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: "#FEFEFE",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "#973B7B",
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
    },
    titleCoupon: {
      display: "flex",
      height: "30px",
      width: "208px",
      margin: "auto",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "10px",
      marginTop: "7px",
      marginBottom: "26px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#973B7B",
        margin: "auto",
      },
    },
    couponContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      backgroundImage: `url(${waveBorder})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      padding: "15%",
    },
    couponImage: {
      display: "flex",
      height: "62px",
      width: "62px",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "38px",
      color: "#973B7B",
      margin: "auto",
      marginBottom: "20px",
      "& svg": {
        margin: "auto",
      },
    },
    couponAmount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: "17px",
      "& .amount": {
        fontSize: "90px",
        lineHeight: "1",
        fontWeight: "bold",
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "30px",
        lineHeight: "1",
      },
    },
    couponDescription: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "normal",
      color: "#454545",
      textAlign: "center",
      marginBottom: "23px",
    },
    deleteCoupon: {
      height: "38px",
      width: "159px",
      border: "2px solid #973B7B",
      boxSizing: "border-box",
      borderRadius: "116px",
      color: "#973B7B",
    },
    couponFooter: {
      display: "flex",
      justifyContent: "end",
    },
    addCouponContainer: {
      background: "#F6F6F6",
      padding: "17px 14px 16px 14px",
      border: "none !important",
    },
    addCouponItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      border: "2px dashed #929292",
      borderRadius: "50%",
      color: "#767676",
      cursor: "pointer",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "normal",
        color: "#767676",
      },
    },
  })
);
interface CouponItemProps {
  couponData: any | undefined;
  editItem: any;
}
const CouponItem: React.FC<CouponItemProps> = ({ couponData, editItem }) => {
  const classes = useStylesCouponItem();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [newTargetName, setNewTargetName] = React.useState<any>({});
  const [deleteTargetsGroup, { loading: deleteTargetsGroupLoading }] =
    useDeleteTargetsGroupMutation({
      update(cache, { data }) {
        const groupToDeleteId = data?.DeleteTargetsGroup;
        cache.modify({
          fields: {
            GetTargetsGroup(existingTargetsGroup, { readField }) {
              return existingTargetsGroup.filter(
                (groupData: Reference | StoreObject | undefined) =>
                  groupToDeleteId !== readField("id", groupData)
              );
            },
          },
        });
      },
    });
  const [updateTargetsGroup, { loading: updateTargetsGroupLoading }] =
    useUpdateTargetsGroupMutation();

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  const handleClickToggleUpdate: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenUpdate(!openUpdate);
    }, [openUpdate, setOpenUpdate]);

  const handleDeleteTarget = async (index: string) => {
    // setOpen(false);

    const groupDeleted = await deleteTargetsGroup({
      variables: {
        groupId: index,
      },
    });

    // showToast({
    //   message: "The group has been deleted successfully",
    //   severity: "warning",
    // });

    // updateTargets();
    handleClickToggleDelete();
  };

  return (
    <Card
      className={clsx(classes.itemContainer, {
        [classes.addCouponContainer]: !couponData,
      })}
    >
      {couponData ? (
        <CardContent className={classes.couponContainer}>
          <Box className={classes.headerCoupon}>
            <Button
              className={classes.editCoupon}
              variant="outlined"
              startIcon={<EditOutlinedIcon />}
              onClick={editItem}
            />
          </Box>
          <Box className={classes.couponAmount}>
            <Typography className="amount">30%</Typography>
            <Typography className="currency">OFF</Typography>
          </Box>
          <Typography className={classes.couponDescription}>
            + 500 free minutes
          </Typography>
          {/* <Box className={classes.couponFooter}>
            <Button className={classes.deleteCoupon} variant="outlined">
              Remove Coupon
            </Button>
          </Box> */}
        </CardContent>
      ) : (
        <CardContent className={classes.addCouponItem} onClick={editItem}>
          <AddIcon />
          <Typography>Add New Coupon</Typography>
        </CardContent>
      )}
    </Card>
  );
};

const ButtonSecondary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.secondary.backgroundColor,
    // fontSize: theme.button.secondary.fontSize,
    // lineHeight: theme.button.secondary.lineHeight,
    color: theme.button.secondary.color,
    border: theme.button.secondary.border,
    textTransform: theme.button.secondary.textTransform,
    "&:hover": {
      color: theme.button.secondary.hoverColor,
      backgroundColor: theme.button.secondary.hoverBackground,
      border: theme.button.secondary.hoverBorder,
    },
    "&:disabled": {
      color: theme.button.secondary.disabledColor,
      backgroundColor: theme.button.secondary.disabledBackground,
      border: theme.button.secondary.disabledBorder,
    },
  },
}))(Button);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
}))(Button);

export default {
  useStyles,
  TGContainer,
  TGContent,
  TGHeader,
  TGBody,
  TGTableHead,
  TGTableBody,
  TGRowItem,
  PlanItem,
  CouponItem,
  ButtonPrimary,
  ButtonSecondary,
};
