import React, { ReactComponentElement, ReactElement } from "react";

import { Box, BoxProps, Typography } from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import AliceCarousel, { EventObject } from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import IconButtonGeneric from "components/icon-button/icon-button.component";
import GenerateItemSearch from "components/search-result-item/search-result-item.component";

import SC from "./carousel-generic.styles";

interface CarouselGenericInterface extends BoxProps {
  items: any[];
  type: "catalog" | "widget" | "plans" | "search" | "summary" | "captions";
  infiniteScroll?: boolean;
  hideDots?: boolean;
  customPaddingLeft?: number;
  customPaddingRight?: number;
  startIndex?: number;
  onCurrentSlide?: (param1: any) => void;
}

const CarouselGeneric: React.FC<CarouselGenericInterface> = ({
  items,
  infiniteScroll = false,
  hideDots = true,
  type,
  customPaddingLeft,
  customPaddingRight,
  startIndex = 0,
  onCurrentSlide,
  ...props
}) => {
  const carouselRef = React.useRef<AliceCarousel>(null);
  const [itemSelected, setItemSelected] = React.useState<number>(0);
  const [isPrevDisabled, setIsPrevDisabled] = React.useState<boolean>(true);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(false);

  const onSlideChanged = React.useCallback(
    (e: EventObject) => {
      setTimeout(() => {
        // console.debug(
        //   "=====> onSlideChange: ",
        //   e,
        //   carouselRef.current?.state.activeIndex
        // );
        setIsPrevDisabled(carouselRef.current?.state.activeIndex === 0);
        setIsNextDisabled(
          carouselRef.current?.state.activeIndex === items.length - 1
        );

        if (onCurrentSlide) {
          onCurrentSlide(carouselRef.current?.state.activeIndex ?? 0);
        }
      }, 0);
    },
    [items, setIsPrevDisabled, setIsNextDisabled, onCurrentSlide]
  );

  const responsiveConf = React.useMemo(() => {
    let config;
    switch (type) {
      case "plans":
        config = {
          0: {
            items: 1,
          },
          511: {
            items: 2,
          },
          717: {
            items: 3,
          },
          922: {
            items: 4,
          },
          1128: {
            items: 5,
          },
          1333: {
            items: 6,
          },
          1550: {
            items: 7,
          },
        };
        break;
      case "search":
        config = {
          0: {
            items: 2,
          },
          600: {
            items: 3,
          },
        };
        break;
      default:
        config = {
          0: {
            items: 1,
          },
        };
        break;
    }

    return config;
  }, [type]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SC.CarouselContainer {...props}>
      <SC.NavigationButton
        hidden={items.length < 2}
        name=""
        icon={<ArrowBackIosIcon />}
        type="button"
        className={`prev-button${isPrevDisabled ? " disabled" : ""} ${type}`}
        onClick={() => {
          carouselRef.current?.slidePrev();
        }}
      />
      <AliceCarousel
        infinite={infiniteScroll}
        disableDotsControls={hideDots}
        disableButtonsControls
        responsive={responsiveConf}
        ref={carouselRef}
        onSlideChange={onSlideChanged}
        paddingLeft={customPaddingLeft}
        paddingRight={customPaddingRight}
        activeIndex={startIndex > 0 ? startIndex : 0}
        onInitialized={() => {
          if (onCurrentSlide) {
            onCurrentSlide(startIndex);
          }
        }}
      >
        {items.map((item: any, index: number) => (
          <>
            {type === "catalog" ? (
              <SC.CarouselItem
                className={itemSelected === index ? "active" : ""}
                onClick={() => {
                  setItemSelected(index);
                }}
              >
                <SC.TopicTitle>{item.title}</SC.TopicTitle>
                <SC.TotalVideos>{item.total}K Videos</SC.TotalVideos>
              </SC.CarouselItem>
            ) : null}
            {type === "widget" ? (
              <GenerateItemSearch item={item} isPro type={type as "widget"} />
            ) : null}
            {type === "plans" ||
            type === "search" ||
            type === "summary" ||
            type === "captions" ? (
              <Box height="100%" paddingLeft="5px" paddingRight="5px">
                {item}
              </Box>
            ) : null}
            {/* {type === "search" ? { item } : null} */}
          </>
        ))}
      </AliceCarousel>
      <SC.NavigationButton
        hidden={items.length < 2}
        name=""
        icon={<ArrowForwardIosIcon />}
        type="button"
        className={`next-button${isNextDisabled ? " disabled" : ""} ${type}`}
        onClick={() => {
          carouselRef.current?.slideNext();
        }}
      />
    </SC.CarouselContainer>
  );
};

export default CarouselGeneric;
