/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
// GraphQL
import { useGetCurrentUserProfileQuery } from "graphql/types-and-hooks";

export default function hasProductOwnerRole(): boolean | undefined {
  const {
    data: userProfileData,
    loading: userProfileLoading,
    error: userProfileError,
  } = useGetCurrentUserProfileQuery({});
  if (userProfileLoading) return undefined;
  if (userProfileError) return undefined;

  const isProductOwner =
    userProfileData?.GetCurrentUserProfile?.groups?.some(
      (element, index, array) => {
        return element?.name?.toLowerCase() === "product_owner";
      }
    ) ?? false;

  return isProductOwner;
}
