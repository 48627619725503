// Dependencies
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

// Components
import App from "components/app/app.component";
import ThemeProvider from "components/theme-provider/theme-provider.component";

// Utils
import client from "graphql/client";
import reportWebVitals from "reportWebVitals";
import { store } from "./store";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <IntlProvider locale="en-UG" defaultLocale="en-US">
        <ThemeProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </ThemeProvider>
      </IntlProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
