import { Box, Chip, withStyles, Theme } from "@material-ui/core";

const ThemeSwitchContainer = withStyles((theme: Theme) => ({
  root: {
    background: theme.generalStylesContainer.drawer.backgroundColor,
    padding: "0px 24px 12px",
    "&.minimized": {
      padding: "0px 0px 12px",
      display: "flex",
      justifyContent: "center",
      "& .switch-content": {
        height: "48px",
        width: "48px",
      },
      "& .MuiChip-root": {
        height: "100%",
      },
      "& .option": {
        "& svg": {
          margin: "unset",
        },
        "& span": {
          display: "none",
        },
      },
    },
    [theme.breakpoints.down(540)]: {
      position: "absolute",
      right: "60px",
      zIndex: "10",
      padding: "10px !important",
      background: theme.generalStylesContainer.header.backgroundColor,
      "&.minimized": {
        "& .switch-content": {
          height: "38px !important",
          width: "38px !important",
        },
      },
    },
  },
}))(Box);

const ThemeSwitch = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "fit-contain",
    borderRadius: "8px",
    backgroundColor:
      theme.generalStylesContainer.drawer.themeSwitch.borderColor,
    padding: "3px",
  },
}))(Box);

const ThemeSwithchOption = withStyles((theme: Theme) => ({
  root: {
    flex: "1",
    borderRadius: "6px",
    backgroundColor:
      theme.generalStylesContainer.drawer.themeSwitch.borderColor,
    color: theme.generalStylesContainer.drawer.themeSwitch.backgroundColor,
    "& svg": {
      color: theme.generalStylesContainer.drawer.themeSwitch.backgroundColor,
    },
    "&.active": {
      backgroundColor:
        theme.generalStylesContainer.drawer.themeSwitch.backgroundColor,
      color: "currentColor",
      "& svg": {
        color: "currentColor",
      },
    },
    "&.dark": {
      "& svg": {
        transform: "rotate(150deg)",
      },
    },
  },
}))(Chip);

export default {
  ThemeSwitchContainer,
  ThemeSwitch,
  ThemeSwithchOption,
};
