import { Box, FormControlLabel, TextField, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ButtonPrimary from "components/button-primary/button-primary.component";

const Title = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      fontWeight: 800,
      flex: "1",
    },
  },
}))(Box);

const RadioButton = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.fontGeneralStyles.default.fontSize,
    lineHeight: theme.fontGeneralStyles.default.lineHeight,
  },
}))(FormControlLabel);

const RadioIcon = withStyles((theme) => ({
  root: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    border: "1.5px solid #979697",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
  },
}))(Box);

const CheckedRadioIcon = withStyles((theme) => ({
  root: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    border: "1.5px solid #9683BE",
    backgroundColor: "#FFF",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      backgroundImage: "radial-gradient(#9683BE,#9683BE 28%,transparent 32%)",
      content: "''",
      marginTop: "-6px",
      marginLeft: "-6px",
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
  },
}))(Box);

const CustomField = withStyles((theme) => ({
  root: {
    marginTop: "28px",
    marginBottom: "28px",
    height: "56px",
    paddingRight: "0px",
    "& .MuiInputBase-root:hover fieldset": {
      borderColor: "#9683BE",
    },
  },
  input: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
    lineHeight: "19px",
  },
  notchedOutline: {
    border: "1px solid rgba(0, 0, 0, 0.36)",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
}))(TextField);

const SubmitBtn = withStyles(() => ({
  root: {
    float: "right",
  },
}))(ButtonPrimary);

export default {
  RadioButton,
  RadioIcon,
  CheckedRadioIcon,
  CustomField,
  Title,
  SubmitBtn,
};
