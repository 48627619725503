import React from "react";

import { CardMedia, Typography } from "@material-ui/core";
import ButtonPrimary from "components/button-primary/button-primary.component";
import ButtonSecondary from "components/button-secondary/button-secondary.component";

import { TutoElementInterface } from "./public-search.utils";

import SC from "./public-search-tuto.styles";

const TutorialContent: React.FC<TutoElementInterface> = ({
  content,
  successHandler,
  cancelHandler,
}) => {
  const classes = SC.useStyles();
  const { title, description, url, successText, cancelText } = content;

  return (
    <SC.CardContainer>
      <CardMedia
        hidden={!url}
        component="img"
        src={url}
        title={title}
        className={classes.tutoImage}
      />
      <SC.TooltipDescription>
        <Typography className="title">{title}</Typography>
        <Typography className="description">{description}</Typography>
      </SC.TooltipDescription>
      <SC.TooltipButtons>
        <ButtonSecondary
          hidden={!cancelText}
          size="small"
          color="primary"
          onClick={cancelHandler}
        >
          {cancelText}
        </ButtonSecondary>
        <ButtonPrimary
          hidden={!successText}
          size="small"
          color="primary"
          onClick={successHandler}
        >
          {successText}
        </ButtonPrimary>
      </SC.TooltipButtons>
    </SC.CardContainer>
  );
};

export default TutorialContent;
