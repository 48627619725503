import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: 'Query';
  getAnswers?: Maybe<GetAnswerResponse>;
};


export type QueryGetAnswersArgs = {
  query: Scalars['String'];
  sessionId: Scalars['String'];
};

export type GetAnswerResponse = Answer | AnwerNotFound | AnswerResponseError;

export type Answer = {
  __typename?: 'Answer';
  query?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  mediasCount?: Maybe<Scalars['Int']>;
  medias?: Maybe<Array<Maybe<ModelAnswerResult>>>;
  created?: Maybe<Scalars['String']>;
};

export type ModelAnswerResult = {
  __typename?: 'ModelAnswerResult';
  modelName?: Maybe<Scalars['String']>;
  modelAnswerText?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  webUrl?: Maybe<Scalars['String']>;
  audioUrl?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  views?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  audioLength?: Maybe<Scalars['Int']>;
  transcription?: Maybe<Scalars['String']>;
  sentences?: Maybe<Array<Maybe<Sentence>>>;
  entities?: Maybe<Array<Maybe<Scalars['String']>>>;
  positivePercentageScore?: Maybe<Scalars['Float']>;
  negativePercentageScore?: Maybe<Scalars['Float']>;
  joy?: Maybe<Scalars['Float']>;
  trust?: Maybe<Scalars['Float']>;
  surprise?: Maybe<Scalars['Float']>;
  anticipation?: Maybe<Scalars['Float']>;
  fear?: Maybe<Scalars['Float']>;
  sadness?: Maybe<Scalars['Float']>;
  disgust?: Maybe<Scalars['Float']>;
  anger?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['String']>;
};

export type Sentence = {
  __typename?: 'Sentence';
  text?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
};

export type AnwerNotFound = {
  __typename?: 'AnwerNotFound';
  message?: Maybe<Scalars['String']>;
};

export type AnswerResponseError = {
  __typename?: 'AnswerResponseError';
  message?: Maybe<Scalars['String']>;
};

export type SentenceFragment = (
  { __typename?: 'Sentence' }
  & Pick<Sentence, 'text' | 'start' | 'end'>
);

export type OsMediaFragment = (
  { __typename?: 'ModelAnswerResult' }
  & Pick<ModelAnswerResult, 'modelName' | 'modelAnswerText' | 'startTime' | 'endTime' | 'documentId' | 'uuid' | 'title' | 'author' | 'webUrl' | 'audioUrl' | 'thumbnail' | 'source' | 'description' | 'tags' | 'views' | 'likes' | 'transcription' | 'entities' | 'positivePercentageScore' | 'negativePercentageScore' | 'joy' | 'trust' | 'fear' | 'surprise' | 'sadness' | 'disgust' | 'anger' | 'anticipation' | 'created'>
  & { sentences?: Maybe<Array<Maybe<(
    { __typename?: 'Sentence' }
    & SentenceFragment
  )>>> }
);

export type OsAnswersFragment = (
  { __typename?: 'Answer' }
  & Pick<Answer, 'mediasCount' | 'text'>
  & { medias?: Maybe<Array<Maybe<(
    { __typename?: 'ModelAnswerResult' }
    & OsMediaFragment
  )>>> }
);

export type OpenSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
}>;


export type OpenSearchQuery = (
  { __typename?: 'Query' }
  & { getAnswers?: Maybe<(
    { __typename: 'Answer' }
    & OsAnswersFragment
  ) | (
    { __typename: 'AnwerNotFound' }
    & Pick<AnwerNotFound, 'message'>
  ) | (
    { __typename: 'AnswerResponseError' }
    & Pick<AnswerResponseError, 'message'>
  )> }
);

export const SentenceFragmentDoc = gql`
    fragment Sentence on Sentence {
  text
  start
  end
}
    `;
export const OsMediaFragmentDoc = gql`
    fragment OSMedia on ModelAnswerResult {
  modelName
  modelAnswerText
  startTime
  endTime
  documentId
  uuid
  title
  author
  webUrl
  audioUrl
  thumbnail
  source
  description
  tags
  views
  likes
  transcription
  sentences {
    ...Sentence
  }
  entities
  positivePercentageScore
  negativePercentageScore
  joy
  trust
  fear
  surprise
  sadness
  disgust
  anger
  anticipation
  created
}
    ${SentenceFragmentDoc}`;
export const OsAnswersFragmentDoc = gql`
    fragment OSAnswers on Answer {
  mediasCount
  text
  medias {
    ...OSMedia
  }
}
    ${OsMediaFragmentDoc}`;
export const OpenSearchDocument = gql`
    query OpenSearch($search: String = "", $sessionId: String = "") {
  getAnswers(query: $search, sessionId: $sessionId) {
    __typename
    ... on Answer {
      ...OSAnswers
    }
    ... on AnwerNotFound {
      message
    }
    ... on AnswerResponseError {
      message
    }
  }
}
    ${OsAnswersFragmentDoc}`;

/**
 * __useOpenSearchQuery__
 *
 * To run a query within a React component, call `useOpenSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useOpenSearchQuery(baseOptions?: Apollo.QueryHookOptions<OpenSearchQuery, OpenSearchQueryVariables>) {
        return Apollo.useQuery<OpenSearchQuery, OpenSearchQueryVariables>(OpenSearchDocument, baseOptions);
      }
export function useOpenSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenSearchQuery, OpenSearchQueryVariables>) {
          return Apollo.useLazyQuery<OpenSearchQuery, OpenSearchQueryVariables>(OpenSearchDocument, baseOptions);
        }
export type OpenSearchQueryHookResult = ReturnType<typeof useOpenSearchQuery>;
export type OpenSearchLazyQueryHookResult = ReturnType<typeof useOpenSearchLazyQuery>;
export type OpenSearchQueryResult = Apollo.QueryResult<OpenSearchQuery, OpenSearchQueryVariables>;