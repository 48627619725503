/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  Button as ButtonBase,
  Checkbox as CheckboxBase,
  CheckboxProps,
  FormControlLabel as FormControlLabelBase,
  IconButton as IconButtonBase,
  InputAdornment,
  TextField as TextFieldBase,
  Typography,
  withStyles,
  BoxProps,
  Theme,
} from "@material-ui/core";
import {
  Visibility,
  VisibilityOff,
  PersonOutlineOutlined as PersonOutlineOutlinedBase,
} from "@material-ui/icons";
import { Link as LinkBase } from "react-router-dom";
import ButtonPrimary from "components/button-primary/button-primary.component";

export const FormBase = withStyles((theme: Theme) => ({
  root: {
    background: theme.authGeneralStyles.formContainer.backgroundColor,
    borderRadius: "0px 0px 10px 10px",
    paddingLeft: "138px",
    paddingRight: "138px",
    paddingTop: "21px",
    paddingBottom: "53px",
    [theme.breakpoints.down(540)]: {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      padding: "20px",
    },
  },
}))(Box);

export const Form: React.FC<BoxProps> = (props) => {
  const { component = "form", ...moreProps } = props;
  return <FormBase component={component} {...moreProps} />;
};

const CustomField = withStyles((theme) => ({
  root: {
    marginBottom: "44px",
    height: "56px",
    paddingRight: "0px",
  },
  input: {
    fontSize: "16px",
    lineHeight: "19px",
  },
  notchedOutline: {
    borderRadius: "4px",
    boxSizing: "border-box",
  },
}))(TextFieldBase);

export const EmailField = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "34px",
    },
  },
}))(CustomField);

export const PasswordField = withStyles(() => ({
  root: {},
}))(CustomField);

export const PasswordFieldContainer = withStyles({
  root: {
    marginBottom: "30px",
  },
})(Box);

export const Checkbox = withStyles({
  root: {},
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props: CheckboxProps) => <CheckboxBase color="default" {...props} />);

export const ForgotPasswordContainer = withStyles({
  root: {
    marginTop: "10px",
    textAlign: "end",
    color: "#B8CAC6",
    fontSize: "12px",
    lineHeight: "14px",
    "& a": {
      color: "#B8CAC6",
    },
  },
})(Typography);

export const Button = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "48px",
    borderRadius: "116px",
    // border: "2px solid #B8CAC6",
    // background: theme.palette.primary.main,
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "bold",
    textTransform: "lowercase",
    // color: "#FFFFFF",
    marginBottom: "24px",
    // "&:hover": {
    //   background: "#B8CAC6",
    // },
    "& p": {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
  disabled: {
    // background: "#B8CAC6",
    // color: "#fff !important",
    opacity: 0.5,
  },
  [theme.breakpoints.down(540)]: {
    maxWidth: "unset",
  },
}))(ButtonPrimary);

export const NoRegisteredUserInfo = withStyles((theme: Theme) => ({
  root: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.authGeneralStyles.fontStyles.color.secondary,
    textAlign: "center",
    "& a": {
      textDecoration: "underline",
      color: "#B8CAC6",
    },
  },
}))(Typography);

export const EmailInputAdornment = withStyles({
  root: {
    color: "#B8CAC6",
  },
})(InputAdornment);

export const PersonOutlineOutlinedIcon = PersonOutlineOutlinedBase;

export const PasswordInputAdornment = withStyles({
  root: {
    marginLeft: "0px",
  },
})(InputAdornment);

export const IconButton = withStyles({
  root: {
    // margin: "0px",
    color: "#D3CFDB",
  },
})(IconButtonBase);

export const VisibilityIcon = Visibility;

export const VisibilityOffIcon = VisibilityOff;

export const FormControlLabel = withStyles((theme) => ({
  root: {
    marginBottom: "70px",
    float: "left",
    [theme.breakpoints.down("md")]: {
      marginBottom: "60px",
    },
  },
}))(FormControlLabelBase);

export const Link = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
}))(LinkBase);

const DividerMobile = withStyles((theme: Theme) => ({
  root: {
    display: "none",
    [theme.breakpoints.down(540)]: {
      display: "block",
      flex: "1",
    },
  },
}))(Box);

export default {
  Form,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  EmailField,
  PasswordField,
  PasswordFieldContainer,
  EmailInputAdornment,
  PasswordInputAdornment,
  NoRegisteredUserInfo,
  ForgotPasswordContainer,
  Link,
  DividerMobile,
  VisibilityIcon,
  VisibilityOffIcon,
  PersonOutlineOutlinedIcon,
};
