/* eslint-disable */
import { ActionTypes } from '../actions/types';

const slidiesInitialState = {
  0: 0,
  1: 0,
  2: 0,
  3: 0
};

const slidyReducerFn = (state = slidiesInitialState, action) => {
  switch (action.type) {
    case ActionTypes.SLIDY.INDEX:
      return {
        ...state,
        [`${action.payload.slidy}`]: action.payload.index
      };
    default:
      return state;
  }
};

export default slidyReducerFn;
