/* eslint-disable import/prefer-default-export */
import numbro from "numbro";

export const formatCurrency = (value: number, currency: string): string => {
  return numbro(value).formatCurrency({
    postfix: ` ${currency.toUpperCase()}`,
    thousandSeparated: true,
    mantissa: 2, // number of decimals displayed
  });
};

export const secToStrTime = (seconds: number) => {
  return numbro(seconds).format({
    output: "time",
  });
};

export const averageNumber = (normalNum: number) => {
  return numbro(normalNum).format({
    spaceSeparated: true,
    average: true,
    optionalMantissa: true,
    mantissa: 1,
  });
};
