/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { GDRIVE_DOCUMENTS } = ActionTypes;

const INITIAL_STATE = {
  documentsDict: {},
  loading: false,
  error: null,
};

const gdriveDocumentsReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GDRIVE_DOCUMENTS.OPERATION.START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GDRIVE_DOCUMENTS.OPERATION.END:
      return {
        ...state,
        loading: false,
      };
    case GDRIVE_DOCUMENTS.OPERATION.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GDRIVE_DOCUMENTS.OPERATION.CLEAN_ERROR:
      return {
        ...state,
        error: null,
      };
    case GDRIVE_DOCUMENTS.UPDATE:
      return {
        ...state,
        documentsDict: {
          ...state.documentsDict,
          [action.payload.id]: { ...action.payload }
        },
      };
    case GDRIVE_DOCUMENTS.DICT:
      return {
        ...state,
        documentsDict: { ...action.payload },
      };
    case GDRIVE_DOCUMENTS.OPERATION.CLEAN_DATA:
      return {};
    default:
      return {
        ...state,
      };
  }
};

export default gdriveDocumentsReducerFn;
