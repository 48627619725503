/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  makeStyles,
  withStyles,
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Box as BoxMui,
  GridProps,
  BoxProps,
} from "@material-ui/core/";

interface CustomGridProps extends GridProps {
  MarginTop?: number;
}

const useGridStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    textAlign: "center",
    backgroundColor: "#fff",
    marginTop: (props: CustomGridProps) =>
      props.MarginTop ? props.MarginTop : theme.spacing(2),
  },
}));

const Grid = (props: CustomGridProps): JSX.Element => {
  const { MarginTop, ...muiProps } = props;
  const classes = useGridStyles(props);

  return <GridMui className={classes.root} {...muiProps} />;
};

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const useBoxStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props: CustomBoxProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(4),
  },
}));

const Box = (props: CustomBoxProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useBoxStyles(props);

  return <BoxMui className={classes.root} {...muiProps} />;
};

const InputLabel = withStyles({
  root: {
    textAlign: "left",
    transform: "translate(12px, 10px) scale(0.75)",
  },
})(InputLabelMui);

export default {
  Grid,
  Box,
  InputLabel,
};
