/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';
// Constants
import { COLUMN_ID } from '../../commons/constants';

const { ORG_FILTER } = ActionTypes;
const orgFilterInitialState = [
  {
    id: COLUMN_ID.BRAND,
    value: []
  },
  {
    id: COLUMN_ID.COUNTRY,
    value: []
  },
  {
    id: COLUMN_ID.DISTRICT,
    value: []
  },
  {
    id: COLUMN_ID.DIVISION,
    value: []
  },
  {
    id: COLUMN_ID.STORE_NAME,
    value: []
  },
  {
    id: COLUMN_ID.REGION,
    value: []
  },
  {
    id: COLUMN_ID.TERRITORY,
    value: []
  }
];

const orgFilterReducerFn = (state = orgFilterInitialState, action) => {
  switch (action.type) {
    case ORG_FILTER.UPDATE: {
      const orgFilter = state;
      const newFilterOption = action.payload;
      const newOrgFilter = orgFilter.filter(f => f.id !== newFilterOption.id);
      newOrgFilter.push(newFilterOption);
      return newOrgFilter;
    }
    case ORG_FILTER.SET: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
};

export default orgFilterReducerFn;
