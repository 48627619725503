// Dependencies
import TreeView from "@material-ui/lab/TreeView";
import { withStyles, Box as BoxMui } from "@material-ui/core";

const TreeViewMui = withStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
})(TreeView);

const Box = withStyles({
  root: {
    height: 300,
    overflowY: "auto",
  },
})(BoxMui);

export default {
  TreeViewMui,
  Box,
};
