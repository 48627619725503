/* eslint-disable */
// Dependencies
import { REHYDRATE } from 'redux-persist';

// Actions
import { ActionTypes } from '../actions/types';
// import { logOut } from '../actions/auth.action';

// Utils
import GatewayServer from '../../commons/utils/gatewayserver.util';
// import HttpError, {
//   HTTP_STATUS_CODES
// } from '../../commons/utils/http-error.util';


import { APP_BUILD_DATE } from '../../commons/constants';

const { AUTH } = ActionTypes;


const authMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  switch (action.type) {
    case REHYDRATE: {
      const { payload } = action;
      if (APP_BUILD_DATE !== localStorage.buildDate) {
        localStorage.buildDate = APP_BUILD_DATE;
        action.payload = {};
      }

      GatewayServer.setToken(payload
        ? (payload && payload.auth && payload.auth.tokenInfo && payload.auth.tokenInfo.access_token)
        : null
      );
      break;
    }

    case AUTH.AUTH.SUCCESS: {
      GatewayServer.setToken(action
        && action.payload
        && action.payload.tokenInfo
        && action.payload.tokenInfo.access_token);
      break;
    }

    case AUTH.LOGOUT: {
      GatewayServer.setToken(null);
      break;
    }

    // case OTHER.API.ENDPOINT.FAILURE: {
    //   const { payload: error } = action;

    //   if (error instanceof HttpError) {
    //     if (error.status === HTTP_STATUS_CODES.FORBIDDEN) {
    //       dispatch(logOut());
    //     }
    //   }
    //   break;
    // }

    default: break;
  }

  return next(action);
};

export default authMiddleware;
