const BASE_SCREEN_WIDTH = 1680;
export const BASE_FONT_SIZE = 14;

export const adaptativeSmallScreen = (measureInRems: number): string => {
  // 1680 is a base screen, 14 is an equivalent to 14px(1rem) in that size
  // newPx get the new size equivalent through the base measure (1680px -> 14px)
  const newPx = (window.innerWidth * BASE_FONT_SIZE) / BASE_SCREEN_WIDTH;
  // Get the "rem" measurement equivalent in "vw" for   any screen size
  // By definition the width of any screens equals to "100"vw
  // You can see more information in the next link: https://stackoverflow.com/a/28295133
  return `${(measureInRems * (100 / window.innerWidth) * newPx).toFixed(3)}vw`;
};
