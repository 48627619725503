// Dependencies
import {
  withStyles,
  Dialog as MuiDialog,
  CircularProgress,
} from "@material-ui/core";

const Dialog = withStyles((theme) => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: theme.progressIndicator.backgroundColor,
    },
  },
  paper: {
    background: "transparent !important",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}))(MuiDialog);

const ProgressIndicator = withStyles((theme) => ({
  root: {
    margin: `10px auto`,
  },
}))(CircularProgress);

export default {
  Dialog,
  ProgressIndicator,
};
