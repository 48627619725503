/* eslint-disable no-useless-computed-key */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React, { useMemo, useRef } from "react";
import {
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Snackbar,
} from "@material-ui/core";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import TableCellGenericItem from "components/tableCellGenericItem/tableCellGenericItem.component";
import { Alert, AlertProps } from "components/alert/alert.component";

import clsx from "clsx";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { useGenerateStripeClientSecretQuery } from "graphql/types-and-hooks";

import { useAppErrorHandler, UIError, UIErrorCodes } from "errors/app.errors";

import { PaymentMethodModel } from "pages/payment-management/models/payment-method";

// Assets
// eslint-disable-next-line import/no-named-as-default
import PMForm from "components/payment-management-form/payment-management-fields.styles";
import SC from "components/payment-management/payment-management-form.styles";
import SCOptions from "components/payment-options/payment-options.styles";

import { isMobileResolution } from "commons/utils/device-info.util";

import StripeInput from "../stripe-input/stripe-input.component";

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;

const STRIPE_ERRORS: { [key: string]: string } = {
  generic_decline: "Your card has been declined",
  insufficient_funds: "Your card doesn't have enough funds",
  lost_card: "Your card has been declined",
  stolen_card: "Your card has been declined",
  expired_card: "Your card is expired",
  incorrect_cvc: "The card cvc is invalid",
  processing_error:
    "An error occurred during card processing. Try again in a few minutes.",
};

export interface PaymentMethodFormProps {
  paymentMethodData: any;
  onSubmit: (
    paymentMethod:
      | PaymentMethodModel
      | {
          isDefault: boolean;
          stripePaymentMethodId: string;
          holderName: string;
        }
  ) => void;
  onCancel?: () => void;
  buttonsType?: number;
  customClasses?: string;
  show?: boolean;
  isWizard?: boolean;
  isPaymentSingle?: boolean;
  amountOwed?: number;
}

export const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  paymentMethodData,
  onSubmit,
  onCancel,
  buttonsType = 1,
  customClasses = "",
  show = true,
  isWizard = false,
  isPaymentSingle = false,
  amountOwed = 0,
}) => {
  const classes = PMForm.formStyles();

  const stripe = useStripe();
  const elements = useElements();

  const ownerNameField = React.useRef(null);

  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [stripeError, setStripeError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { data: clientSecret, refetch: refetchClientSecret } =
    useGenerateStripeClientSecretQuery({
      fetchPolicy: "no-cache",
    });
  // const[generateClientSecret, {data: clientSecret}] = useGenerateStripeClientSecretLazyQuery({
  //   fetchPolicy: "no-cache",
  // });

  const [numberValidate, setNumberValidate] = React.useState({
    touched: false,
    dirty: false,
    isValid: false,
    isEmpty: false,
    errorMsg: "",
  });
  const [expDateValidate, setExpDateValidate] = React.useState({
    touched: false,
    dirty: false,
    isValid: false,
    isEmpty: false,
    errorMsg: "",
  });
  const [CVCValidate, setCVCValidate] = React.useState({
    touched: false,
    dirty: false,
    isValid: false,
    isEmpty: false,
    errorMsg: "",
  });

  const [holderName, setHolderName] = React.useState("");

  const [paymentMethodInfo, setPaymentMethodInfo] =
    React.useState<PaymentMethodModel>(paymentMethodData);

  const errorHandler = useAppErrorHandler();

  const handleDefaultChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { name } = event.target;

    setPaymentMethodInfo((oldPaymentMethodInfo) => ({
      ...oldPaymentMethodInfo,
      [name]: checked,
    }));
  };

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // console.debug("=====> stripe is not loaded: ", stripe, elements);
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setSnackBarMessage({
          message:
            "The payment service is not works properly, please restart the payment process.",
          severity: "error",
        });

        return;
      }

      try {
        // TODO: Call generateStripeClientSecret query here and use the string value in the next call
        if (clientSecret?.generateStripeClientSecret) {
          setLoading(true);
          const payload = await stripe.confirmCardSetup(
            clientSecret?.generateStripeClientSecret,
            {
              payment_method: {
                card: elements.getElement(CardNumberElement)!,
                // TODO: Set organisation's defatult billing address info here.
                billing_details: {
                  name: holderName, // default billing address name
                },
              },
            }
          );
          setLoading(false);

          if (payload.error) {
            // Display result.error.message in your UI.
            const {
              error: { code, decline_code: declineCode },
            } = payload;

            const errorMessage =
              STRIPE_ERRORS[code! === "card_declined" ? declineCode! : code!] ??
              "Stripe error of payment card ";
            setStripeError(errorMessage);
            refetchClientSecret();
          } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            const currentHolderName = holderName;
            const responseSuccess: PaymentMethodModel = {
              stripePaymentMethodId: payload.setupIntent
                .payment_method as string,
              isDefault: paymentMethodInfo.isDefault,
              holderName: currentHolderName,
            };

            refetchClientSecret();
            onSubmit(responseSuccess);
          }
        } else {
          setSnackBarMessage({
            message:
              "The payment service is not works properly, please restart the payment process.",
            severity: "error",
          });
        }
      } catch (error) {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while stripe tryed confirm card setup"
          )
        );
      }
    },
    [
      onSubmit,
      paymentMethodInfo,
      stripe,
      elements,
      clientSecret,
      holderName,
      setLoading,
      refetchClientSecret,
      errorHandler,
    ]
  );

  const handlerCancelOnClick = React.useCallback(() => {
    // setIsEditable(false);
    // setUserData(initialUserData);
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  React.useEffect(() => {
    if (elements) elements.getElement(CardNumberElement)?.focus();
  }, [elements]);

  const buttons = [
    {
      title: "Cancel",
      handler: handlerCancelOnClick,
      isShown: true,
      btnClasses: "secondaryBtn",
    },
    {
      title: "Save",
      type: "submit",
      isShown: true,
      btnClasses: "",
    },
  ];

  const buttons2 = [
    {
      title: "Next",
      variant: "contained",
      type: "submit",
      isShown: true,
      btnClasses: "nextBtn",
    },
    {
      title: "back",
      variant: "outlined",
      handler: handlerCancelOnClick,
      isShown: true,
      btnClasses: "backBtn",
    },
  ];

  const buttons3 = [
    {
      title: "back",
      handler: handlerCancelOnClick,
      isShown: true,
      btnClasses: "backBtn",
    },
    {
      title: "Make a payment",
      type: "submit",
      isShown: true,
      btnClasses: "nextBtn",
    },
  ];

  return (
    <SC.PaymentMethodForm
      hidden={!show}
      component="form"
      onSubmit={handlerOnSubmit}
      className={customClasses}
    >
      <ProgressIndicator open={loading} />
      <Box hidden={isMobileResolution()}>
        <Typography
          className={`${classes.formTitle} customFormTitle`}
          style={{ marginBottom: "10px" }}
          color="primary"
        >
          Add Card
        </Typography>
      </Box>
      <PMForm.Box
        className={`${classes.fieldsContainer} customFieldsContainer`}
        style={{ maxHeight: "unset !important" }}
      >
        <PMForm.Grid
          container
          spacing={2}
          disabledMarginTop
          className={classes.formContainer}
          xl={12}
          lg={12}
          md={12}
          xs={12}
        >
          <PMForm.Grid
            item
            xl={isWizard || isPaymentSingle ? 6 : 4}
            lg={isWizard || isPaymentSingle ? 6 : 4}
            md={6}
            xs={12}
            disabledMarginTop
          >
            <PMForm.Box disabledMarginTop>
              <TextField
                name="number"
                label="Card Number"
                placeholder="Card Number"
                variant="outlined"
                color="primary"
                fullWidth
                helperText={numberValidate.errorMsg}
                onChange={(event: any) => {
                  const currentValidate = JSON.parse(
                    JSON.stringify(numberValidate)
                  );
                  currentValidate.dirty = true;
                  currentValidate.isValid = event.complete;
                  currentValidate.isEmpty = event.empty;

                  if (numberValidate.touched && numberValidate.dirty) {
                    if (event.empty) {
                      currentValidate.errorMsg = "Card number is required";
                    } else if (!event.complete) {
                      currentValidate.errorMsg = "Card number is invalid";
                    } else {
                      currentValidate.errorMsg = "";
                    }
                  }

                  if (currentValidate.isValid) {
                    elements!.getElement(CardExpiryElement)?.focus();
                  }
                  setStripeError("");
                  setNumberValidate(currentValidate);
                }}
                onBlur={(event) => {
                  const currentValidate = numberValidate;
                  currentValidate.touched = true;
                  setNumberValidate(currentValidate);
                }}
                className={`${clsx({
                  [classes.invalid]:
                    numberValidate.dirty &&
                    numberValidate.touched &&
                    (!numberValidate.isValid || numberValidate.isEmpty),
                })}`}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: StripeInput as any,
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
              />
            </PMForm.Box>
          </PMForm.Grid>
          <PMForm.Grid
            item
            xl={isWizard || isPaymentSingle ? 3 : 2}
            lg={isWizard || isPaymentSingle ? 3 : 2}
            md={6}
            xs={6}
            disabledMarginTop
          >
            <PMForm.Box disabledMarginTop>
              <TextField
                name="expires"
                label="Expires Date"
                placeholder="mm/yy"
                variant="outlined"
                fullWidth
                helperText={expDateValidate.errorMsg}
                onChange={(event: any) => {
                  const currentValidate = JSON.parse(
                    JSON.stringify(expDateValidate)
                  );
                  currentValidate.dirty = true;
                  currentValidate.isValid = event.complete;
                  currentValidate.isEmpty = event.empty;

                  if (expDateValidate.touched && expDateValidate.dirty) {
                    if (event.empty) {
                      currentValidate.errorMsg = "Expires date is required";
                    } else if (!event.complete) {
                      currentValidate.errorMsg = "Expires date is invalid";
                    } else {
                      currentValidate.errorMsg = "";
                    }
                  }

                  if (currentValidate.isValid) {
                    elements!.getElement(CardCvcElement)?.focus();
                  }
                  setStripeError("");
                  setExpDateValidate(currentValidate);
                }}
                onBlur={(event) => {
                  const currentValidate = expDateValidate;
                  currentValidate.touched = true;
                  setExpDateValidate(currentValidate);
                }}
                className={`${clsx({
                  [classes.invalid]:
                    expDateValidate.dirty &&
                    expDateValidate.touched &&
                    (!expDateValidate.isValid || expDateValidate.isEmpty),
                })}`}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: StripeInput as any,
                  inputProps: {
                    component: CardExpiryElement,
                  },
                }}
              />
            </PMForm.Box>
          </PMForm.Grid>
          <PMForm.Grid
            item
            xl={isWizard || isPaymentSingle ? 3 : 2}
            lg={isWizard || isPaymentSingle ? 3 : 2}
            md={6}
            xs={6}
            disabledMarginTop
          >
            <PMForm.Box disabledMarginTop>
              <TextField
                name="cvc"
                label="CVV"
                placeholder="CVV"
                variant="outlined"
                type="password"
                fullWidth
                helperText={CVCValidate.errorMsg}
                onChange={(event: any) => {
                  const currentValidate = JSON.parse(
                    JSON.stringify(CVCValidate)
                  );
                  currentValidate.dirty = true;
                  currentValidate.isValid = event.complete;
                  currentValidate.isEmpty = event.empty;

                  if (CVCValidate.touched && CVCValidate.dirty) {
                    if (event.empty) {
                      currentValidate.errorMsg = "CVC is required";
                    } else if (!event.complete) {
                      currentValidate.errorMsg = "CVC is invalid";
                    } else {
                      currentValidate.errorMsg = "";
                    }
                  }

                  if (currentValidate.isValid && ownerNameField) {
                    (ownerNameField!.current! as any).focus();
                  }
                  setStripeError("");
                  setCVCValidate(currentValidate);
                }}
                onBlur={(event) => {
                  const currentValidate = CVCValidate;
                  currentValidate.touched = true;
                  setCVCValidate(currentValidate);
                }}
                className={`${clsx({
                  [classes.invalid]:
                    CVCValidate.dirty &&
                    CVCValidate.touched &&
                    (!CVCValidate.isValid || CVCValidate.isEmpty),
                })}`}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: StripeInput as any,
                  inputProps: {
                    component: CardCvcElement,
                  },
                }}
              />
            </PMForm.Box>
          </PMForm.Grid>
          <PMForm.Grid
            item
            xl={isWizard || isPaymentSingle ? 12 : 4}
            lg={isWizard || isPaymentSingle ? 12 : 4}
            md={6}
            xs={12}
            disabledMarginTop
          >
            <PMForm.Box disabledMarginTop>
              <TextField
                name="cardHolder"
                label="Card Holder"
                variant="outlined"
                placeholder="Card Holder"
                fullWidth
                onChange={(event) => {
                  setStripeError("");
                  setHolderName(event.currentTarget.value);
                }}
                inputRef={ownerNameField}
                color="primary"
              />
            </PMForm.Box>
          </PMForm.Grid>
        </PMForm.Grid>
        <Box hidden={!stripeError}>
          <Typography color="error" style={{ textAlign: "left" }}>
            {stripeError}
          </Typography>
        </Box>
        <PMForm.Box className={classes.checkContainer} disabledMarginTop>
          <FormControlLabel
            control={
              <Checkbox
                name="isDefault"
                color="primary"
                onChange={handleDefaultChange}
                checked={paymentMethodInfo.isDefault}
                disabled={isWizard}
              />
            }
            label={
              isPaymentSingle
                ? "Used this payment method for future recurring payment"
                : "Set as default"
            }
          />
        </PMForm.Box>
        <SCOptions.ResumeAmount
          hidden={!isPaymentSingle}
          className={classes.resumeAmount}
        >
          <Typography>Amount due:</Typography>
          <TableCellGenericItem
            value={amountOwed}
            type="currency"
            valueKey=""
          />
          {/* <Typography>$3,000.00 USD</Typography> */}
        </SCOptions.ResumeAmount>
      </PMForm.Box>
      <SC.FormActions
        hidden={isPaymentSingle}
        className={isWizard ? "customAB" : ""}
      >
        {!isWizard
          ? buttons.map(
              ({ isShown, type, handler, title, btnClasses }) =>
                isShown && (
                  <SC.Button
                    key={title}
                    type={type as ButtonType}
                    onClick={handler}
                    className={`${classes.type1Btns} ${btnClasses}`}
                  >
                    {title}
                  </SC.Button>
                )
            )
          : buttons2.map(
              ({ isShown, variant, type, handler, title, btnClasses }) =>
                isShown && (
                  <SC.Button
                    key={title}
                    variant={variant as ButtonVariant}
                    type={type as ButtonType}
                    onClick={handler}
                    className={btnClasses}
                  >
                    {title}
                  </SC.Button>
                )
            )}
      </SC.FormActions>
      <SC.FormActions
        hidden={!isPaymentSingle}
        className={classes.paymentSingleBtns}
      >
        {buttons3.map(
          ({ isShown, type, handler, title, btnClasses }) =>
            isShown && (
              <SC.Button
                key={title}
                type={type as ButtonType}
                onClick={handler}
                className={btnClasses}
              >
                {title}
              </SC.Button>
            )
        )}
      </SC.FormActions>
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
    </SC.PaymentMethodForm>
  );
};

export default PaymentMethodForm;
