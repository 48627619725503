import React from "react";

import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Brightness3Icon from "@material-ui/icons/Brightness3";

import { useThemeStore } from "commons/utils/user-data";

import SC from "./theme-switch.styles";

interface ThemeSwitchComponentInterface {
  minimized: boolean;
}

const ThemeSwitchComponent: React.FC<ThemeSwitchComponentInterface> = ({
  minimized,
}) => {
  const setGlobalTheme = useThemeStore((state: any) => state.setTheme);
  const globalTheme = useThemeStore((state: any) => state.theme);

  const localTheme = React.useMemo(() => globalTheme, [globalTheme]);

  const [ligth, setLigth] = React.useState<boolean>(globalTheme === "ligth");

  return (
    <SC.ThemeSwitchContainer className={`${minimized ? "minimized" : ""}`}>
      <SC.ThemeSwitch className="switch-content">
        <SC.ThemeSwithchOption
          hidden={minimized && ligth}
          label="Ligth"
          className={`option ${ligth ? "active" : ""}`}
          icon={<WbSunnyIcon />}
          onClick={() => {
            setLigth(true);
            setGlobalTheme("ligth");
          }}
        />
        <SC.ThemeSwithchOption
          hidden={minimized && !ligth}
          label="Dark"
          className={`option dark${!ligth ? " active" : ""}`}
          icon={<Brightness3Icon />}
          onClick={() => {
            setLigth(false);
            setGlobalTheme("dark");
          }}
        />
      </SC.ThemeSwitch>
    </SC.ThemeSwitchContainer>
  );
};

export default ThemeSwitchComponent;
