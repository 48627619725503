/* eslint-disable */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
// Dependencies
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactElement,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes, { string } from "prop-types";

import { useQuery, useLazyQuery, gql } from "@apollo/client";
import { CLIENT_NAME } from "graphql/client";

import _ from "lodash";
import moment from "moment";

import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";

// Components
import FilterList, {
  FilterValue,
} from "components/filter-list/filter-list.component";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";

// Assets
import { GroupData, UserData } from "graphql/types-and-hooks";
import SC from "./user-filtering-left-pane.styles";

import { isEmpty, groupByKey, objectToString } from "../../commons/utils";

import {
  updateFilters,
  updateFilterEventsBy,
} from "../../store/actions/filter.action";
import { NoEncryptionTwoTone } from "@material-ui/icons";
import { getFormattedDate } from "commons/utils/date-format";

/* Styles to new filter elements */
const DELAY_TIME = 1000;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datesFilter: {
      padding: "8px 16px",
      textAlign: "left",
    },
    dateRangeContent: {
      display: "flex",
      flexDirection: "column",
      padding: "0 16px",
    },
    dateInput: {
      margin: "8px 0px",
    },
    periodSelector: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    filterContainer: {
      background: "#FFFFFF",
      listStyle: "none",
      maxWidth: "300px",
      // boxShadow: "0px 4px 15px 0px #0000001A",
      // overflow: "auto",
    },
    hide: {
      display: "none",
    },
    show: {},
  })
);

const ActionTypes = {
  FILTER: {
    UPDATE: "FILTER_UPDATE",
    GROUP: {
      UPDATE: "FILTER_GROUP_UPDATE",
    },
    GRAPH: {
      UPDATE: "FILTER_GRAPH_UPDATE",
    },
    USERS: {
      BY: "FILTER_USERS_BY",
    },
    EVENTS: {
      BY: "FILTER_EVENTS_BY",
    },
    GROUPS: {
      BY: "FILTER_GROUPS_BY",
    },
    IDENTITY_PROVIDERS: {
      BY: "FILTER_IDENTITY_PROVIDERS_BY",
    },
    LABELING_JOBS: {
      BY: "LABELING_JOBS_BY",
    },
    SEARCH_TEXT: {
      SET: "SEARCH_TEXT_SET",
    },
    TRAINING_JOBS: {
      BY: "TRAINING_JOBS_BY",
    },
  },
  MODEBAR: {
    SET: "MODEBAR_SET",
    SELECT: "MODEBAR_SELECT",
  },
  SELECTED_OPTIONS: {
    SET_INITIAL_DATA: "SET_INITIAL_DATA",
    CHANGE_OPTIONS: "CHANGE_OPTIONS",
  },
  FRAME: {
    SELECT: "SELECT_FRAME",
  },
  AUTH: {
    AUTH: {
      SUCCESS: "AUTH_AUTH_SUCCESS",
      FAILURE: "AUTH_AUTH_FAILURE",
    },
    LOGOUT: "AUTH_LOGOUT",
  },
  ROUTE: {
    TAB: "TAB_UPDATE",
  },
  HELP_API_DOC: {
    START: "HELP_API_DOC_START",
    SUCCESS: "HELP_API_DOC_SUCCESS",
    FAILURE: "HELP_API_DOC_FAILURE",
  },
  FETCH_API_SCHEMA: {
    START: "FETCH_API_SCHEMA_START",
    SUCCESS: "FETCH_API_SCHEMA_SUCCESS",
    FAILURE: "FETCH_API_SCHEMA_FAILURE",
  },
  FETCH_GRAPHQL_DATA: {
    START: "FETCH_GRAPHQL_DATA_START",
    SUCCESS: "FETCH_GRAPHQL_DATA_SUCCESS",
    FAILURE: "FETCH_GRAPHQL_DATA_FAILURE",
    CLEAN: "FETCH_GRAPHQL_DATA_CLEAN",
  },
  SLIDY: {
    INDEX: "SELECT_INDEX",
  },
  ORGANIZATIONS: {
    FETCH: {
      START: "ORGANIZATIONS_FETCH_START",
      SUCCESS: "ORGANIZATIONS_FETCH_SUCCESS",
      FAILURE: "ORGANIZATIONS_FETCH_FAILURE",
    },
  },
  ROLES: {
    SET: {
      SELECTED: "ROLES_SET_SELECTED",
      UPDATED: "ROLES_SET_UPDATED",
      DELETED: "ROLES_SET_DELETED",
      PERMISSIONS: {
        UPDATED: "ROLES_SET_PERMISSIONS_UPDATED",
      },
    },
    CREATE: {
      START: "ROLES_CREATE_START",
      SUCCESS: "ROLES_CREATE_SUCCESS",
      FAILURE: "ROLES_CREATE_FAILURE",
      CLEAR: "ROLES_CREATE_CLEAR",
    },
    FETCH: {
      START: "ROLES_FETCH_START",
      SUCCESS: "ROLES_FETCH_SUCCESS",
      FAILURE: "ROLES_FETCH_FAILURE",
    },
    UPDATE: {
      START: "ROLES_UPDATE_START",
      SUCCESS: "ROLES_UPDATE_SUCCESS",
      FAILURE: "ROLES_UPDATE_FAILURE",
      CLEAR: "ROLES_UPDATE_CLEAR",
    },
    DELETE: {
      START: "ROLES_DELETE_START",
      SUCCESS: "ROLES_DELETE_SUCCESS",
      FAILURE: "ROLES_DELETE_FAILURE",
      CLEAR: "ROLES_DELETE_CLEAR",
    },
    FILTER: {
      STATE: "ROLES_FILTER_STATE",
    },
    PERMISSIONS: {
      FETCH: {
        START: "ROLES_PERMISSIONS_FETCH_START",
        SUCCESS: "ROLES_PERMISSIONS_FETCH_SUCCESS",
        FAILURE: "ROLES_PERMISSIONS_FETCH_FAILURE",
      },
    },
  },
  USER: {
    CREATE: {
      START: "USER_CREATE_START",
      SUCCESS: "USER_CREATE_SUCCESS",
      FAILURE: "USER_CREATE_FAILURE",
      CLEAR: "USER_CREATE_CLEAR",
    },
    READ: {
      START: "USER_READ_START",
      SUCCESS: "USER_READ_SUCCESS",
      FAILURE: "USER_READ_FAILURE",
    },
    UPDATE: {
      START: "USER_UPDATE_START",
      SUCCESS: "USER_UPDATE_SUCCESS",
      FAILURE: "USER_UPDATE_FAILURE",
      CLEAR: "USER_UPDATE_CLEAR",
      STATUS: "USER_UPDATE_STATUS",
    },
    DELETE: {
      START: "USER_DELETE_START",
      SUCCESS: "USER_DELETE_SUCCESS",
      FAILURE: "USER_DELETE_FAILURE",
      CLEAR: "USER_DELETE_CLEAR",
    },
    SET: {
      DATA: "USER_SET_DATA",
    },
    EDIT: "UPDATE_USER_FIELDS",
    // Link/Unlink user to groups
    GROUPS: {
      ADD: {
        START: "USER_GROUPS_ADD_START",
        SUCCESS: "USER_GROUPS_ADD_SUCCESS",
        FAILURE: "USER_GROUPS_ADD_FAILURE",
        CLEAR: "USER_GROUPS_ADD_CLEAR",
      },
      DELETE: {
        START: "USER_GROUPS_DELETE_START",
        SUCCESS: "USER_GROUPS_DELETE_SUCCESS",
        FAILURE: "USER_GROUPS_DELETE_FAILURE",
        CLEAR: "USER_GROUPS_DELETE_CLEAR",
      },
    },
  },
  GROUPS: {
    // Set Groups Data
    SET: {
      DATA: "GROUPS_SET_DATA",
    },
    // CRUD
    CREATE: {
      START: "GROUPS_CREATE_START",
      SUCCESS: "GROUPS_CREATE_SUCCESS",
      FAILURE: "GROUPS_CREATE_FAILURE",
      CLEAR: "GROUPS_CREATE_CLEAR",
    },
    FETCH: {
      START: "GROUPS_FETCH_START",
      SUCCESS: "GROUPS_FETCH_SUCCESS",
      FAILURE: "GROUPS_FETCH_FAILURE",
    },
    UPDATE: {
      START: "GROUPS_UPDATE_START",
      SUCCESS: "GROUPS_UPDATE_SUCCESS",
      FAILURE: "GROUPS_UPDATE_FAILURE",
      CLEAR: "GROUPS_UPDATE_CLEAR",
    },
    DELETE: {
      START: "GROUPS_DELETE_START",
      SUCCESS: "GROUPS_DELETE_SUCCESS",
      FAILURE: "GROUPS_DELETE_FAILURE",
      CLEAR: "GROUPS_DELETE_CLEAR",
    },
    // Link/Unlink group to/from roles
    ROLES: {
      ADD: {
        START: "GROUPS_ROLES_ADD_START",
        SUCCESS: "GROUPS_ROLES_ADD_SUCCESS",
        FAILURE: "GROUPS_ROLES_ADD_FAILURE",
        CLEAR: "GROUPS_ROLES_ADD_CLEAR",
      },
      DELETE: {
        START: "GROUPS_ROLES_DELETE_START",
        SUCCESS: "GROUPS_ROLES_DELETE_SUCCESS",
        FAILURE: "GROUPS_ROLES_DELETE_FAILURE",
        CLEAR: "GROUPS_ROLES_DELETE_CLEAR",
      },
    },
  },
  IDENTITY_PROVIDERS: {
    // Set Identity Providers Data
    SET: {
      DATA: "IDENTITY_PROVIDERS_SET_DATA",
    },
    // CRUD
    CREATE: {
      START: "IDENTITY_PROVIDERS_CREATE_START",
      SUCCESS: "IDENTITY_PROVIDERS_CREATE_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_CREATE_FAILURE",
      CLEAR: "IDENTITY_PROVIDERS_CREATE_CLEAR",
    },
    FETCH: {
      START: "IDENTITY_PROVIDERS_FETCH_START",
      SUCCESS: "IDENTITY_PROVIDERS_FETCH_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_FETCH_FAILURE",
    },
    UPDATE: {
      START: "IDENTITY_PROVIDERS_UPDATE_START",
      SUCCESS: "IDENTITY_PROVIDERS_UPDATE_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_UPDATE_FAILURE",
      CLEAR: "IDENTITY_PROVIDERS_UPDATE_CLEAR",
    },
    DELETE: {
      START: "IDENTITY_PROVIDERS_DELETE_START",
      SUCCESS: "IDENTITY_PROVIDERS_DELETE_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_DELETE_FAILURE",
      CLEAR: "IDENTITY_PROVIDERS_DELETE_CLEAR",
    },
  },
  PROTOCOLS: {
    FETCH: {
      START: "PROTOCOLS_FETCH_START",
      SUCCESS: "PROTOCOLS_FETCH_SUCCESS",
      FAILURE: "PROTOCOLS_FETCH_FAILURE",
    },
  },
  ORG_FILTER: {
    UPDATE: "ORG_FILTER_UPDATE",
    SET: "ORG_FILTER_SET",
  },
  TB_STORES: {
    // Set TB_STORES Data
    SET: {
      DATA: "TB_STORES_SET_DATA",
    },
    // CRUD
    FETCH: {
      START: "TB_STORES_FETCH_START",
      SUCCESS: "TB_STORES_FETCH_SUCCESS",
      FAILURE: "TB_STORES_FETCH_FAILURE",
    },
    CREATE: {
      START: "TB_STORES_CREATE_START",
      SUCCESS: "TB_STORES_CREATE_SUCCESS",
      FAILURE: "TB_STORES_CREATE_FAILURE",
      CLEAR: "TB_STORES_CREATE_CLEAR",
    },
    UPDATE: {
      START: "TB_STORES_UPDATE_START",
      SUCCESS: "TB_STORES_UPDATE_SUCCESS",
      FAILURE: "TB_STORES_UPDATE_FAILURE",
      CLEAR: "TB_STORES_UPDATE_CLEAR",
    },
    DELETE: {
      START: "TB_STORES_DELETE_START",
      SUCCESS: "TB_STORES_DELETE_SUCCESS",
      FAILURE: "TB_STORES_DELETE_FAILURE",
      CLEAR: "TB_STORES_DELETE_CLEAR",
    },
  },
  LOOKER: {
    // GET LOOKS INFO
    FETCH_LOOKS_DATA: {
      START: "LOOKS_FETCH_START",
      SUCCESS: "LOOKS_FETCH_SUCCESS",
      FAILURE: "LOOKS_FETCH_FAILURE",
    },
    // GET DASBOARDS INFO
    FETCH_DASHBOARDS_DATA: {
      START: "DASHBOARDS_FETCH_START",
      SUCCESS: "DASHBOARDS_FETCH_SUCCESS",
      FAILURE: "DASHBOARDS_FETCH_FAILURE",
    },
    // CREATE SSO URL
    CREATE_SSO_URL: {
      START: "SSO_URL_START",
      SUCCESS: "SSO_URL_SUCCESS",
      FAILURE: "SSO_URL_FAILURE",
    },
    // GET SPECIFIC LOOK INFO
    FETCH_LOOK_BY_ID: {
      START: "LOOK_BY_ID_FETCH_START",
      SUCCESS: "LOOK_BY_ID_FETCH_SUCCESS",
      FAILURE: "LOOK_BY_ID_FETCH_FAILURE",
    },
    // GET SPECIFIC DASHBOARD INFO
    FETCH_DASHBOARD_BY_ID: {
      START: "DASHBOARD_BY_ID_FETCH_START",
      SUCCESS: "DASHBOARD_BY_ID_FETCH_SUCCESS",
      FAILURE: "DASHBOARD_BY_ID_FETCH_FAILURE",
    },
  },
  LABELJOB: {
    ADDED: "LABELJOB.ADDED",
    UPDATE: {
      START: "LABELJOB_UPDATE_START",
      SUCCESS: "LABELJOB_UPDATE_SUCCESS",
      ERROR: "LABELJOB_UPDATE_ERROR",
    },
  },
  JOBS: {
    CREATE: {
      START: "JOBS_CREATE_START",
      SUCCESS: "JOBS_CREATE_SUCCESS",
      FAILURE: "JOBS_CREATE_FAILURE",
      CLEAR: "JOBS_CREATE_CLEAR",
    },
    READ: {
      UPDATE: "JOBS_READ_UPDATE",
      START: "JOBS_READ_START",
      SUCCESS: "JOBS_READ_SUCCESS",
      FAILURE: "JOBS_READ_FAILURE",
    },
    UPDATE: {
      START: "JOBS_UPDATE_START",
      SUCCESS: "JOBS_UPDATE_SUCCESS",
      FAILURE: "JOBS_UPDATE_FAILURE",
      CLEAR: "JOBS_UPDATE_CLEAR",
      LOCAL: {
        USER: "JOBS_UPDATE_LOCAL_USER",
      },
    },
    DELETE: {
      START: "JOBS_DELETE_START",
      SUCCESS: "JOBS_DELETE_SUCCESS",
      FAILURE: "JOBS_DELETE_FAILURE",
      CLEAR: "JOBS_DELETE_CLEAR",
    },
    SET: {
      DATA: "JOBS_SET_DATA",
    },
    EDIT: "UPDATE_JOBS_FIELDS",
    START: {
      SUCCESS: "JOB_START_SUCCESS",
    },
    QUIT: {
      SUCCESS: "JOB_QUIT_SUCCESS",
    },
  },
  JOB_FILTER: {
    UPDATE: "JOB_FILTER_UPDATE",
    SET: "JOB_FILTER_SET",
    RESET: "JOB_FILTER_RESET",
  },
  TRAINING_JOB_CREATION_FILTER: {
    UPDATE: "TRAINING_JOB_CREATION_FILTER_UPDATE",
    SET: "TRAINING_JOB_CREATION_FILTER_SET",
    RESET: "JTRAINING_JOB_CREATION_FILTER_RESET",
  },
  TRAINED_MODELS: {
    READ: {
      START: "TRAINED_MODELS_READ_START",
      SUCCESS: "TRAINED_MODELS_READ_SUCCESS",
      FAILURE: "TRAINED_MODELS_READ_FAILURE",
    },
    FILTER: {
      UPDATE: "TRAINED_MODELS_FILTER_UPDATE",
      SET: "TRAINED_MODELS_FILTER_SET",
      RESET: "TRAINED_MODELS_FILTER_RESET",
    },
    FILTERED: {
      SET: "TRAINED_MODELS_FILTERED_SET",
    },
  },
  JOBS_FOR_TRAINING: {
    READ: {
      START: "JOBS_FOR_TRAINING_READ_START",
      SUCCESS: "JOBS_FOR_TRAINING_READ_SUCCESS",
      FAILURE: "JOBS_FOR_TRAINING_READ_FAILURE",
    },
    READ_IMAGES: {
      START: "JOBS_FOR_TRAINING_READ_IMAGES_START",
      SUCCESS: "JOBS_FOR_TRAINING_READ_IMAGES_SUCCESS",
      FAILURE: "JOBS_FOR_TRAINING_READ_IMAGES_FAILURE",
    },
    EXCLUDED_IMAGES: {
      SET: "JOBS_FOR_TRAINING_EXCLUDED_IMAGES_SET",
    },
    TF_PARAMS: {
      SET: "JOBS_FOR_TRAINING_TF_PARAMS_SET",
    },
    PIPELINE_CONFIG: {
      SET: "JOBS_FOR_TRAINING_PIPELINE_CONFIG_SET",
    },
    MODEL_OPTIONS: {
      SET: "JOBS_FOR_TRAINING_MODEL_OPTIONS_SET",
    },
    CLASS_MAP: {
      SET: "JOBS_FOR_TRAINING_CLASS_MAP_SET",
    },
    LABEL_COUNTS: {
      READ: {
        START: "JOBS_FOR_TRAINING_LABEL_COUNTS_READ_START",
        SUCCESS: "JOBS_FOR_TRAINING_LABEL_COUNTS_READ_SUCCESS",
        FAILURE: "JOBS_FOR_TRAINING_LABEL_COUNTS_READ_FAILURE",
      },
    },
    NAME_INFO: {
      SET: "JOBS_FOR_TRAINING_NAME_INFO_SET",
    },
    MACHINE_TYPE: {
      SET: "JOBS_FOR_TRAINING_MACHINE_TYPE_SET",
    },
  },
  JOBS_FOR_TRAINING_FILTERED: {
    SET: "JOBS_FOR_TRAINING_FILTERED_SET",
    UPDATE: "JOBS_FOR_TRAINING_FILTERED_UPDATE",
  },
  LABEL_CLASSES: {
    READ: {
      START: "LABEL_CLASSES_READ_START",
      SUCCESS: "LABEL_CLASSES_READ_SUCCESS",
      FAILURE: "LABEL_CLASSES_READ_FAILURE",
    },
  },
  TRAINING_JOBS: {
    CREATION: {
      IMG_SLIDER: {
        NEXT: "TRAINING_JOBS_CREATION_IMG_SLIDER_NEXT",
        BEFORE: "TRAINING_JOBS_CREATION_IMG_SLIDER_BEFORE",
        LOAD_MORE: {
          START: "TRAINING_JOBS_CREATION_IMG_SLIDER_LOAD_MORE_START",
          ON_SUCCESS: "TRAINING_JOBS_CREATION_IMG_SLIDER_LOAD_MORE_SUCCESS",
          ON_ERROR: "TRAINING_JOBS_CREATION_IMG_SLIDER_LOAD_MORE_ERROR",
        },
        LABEL_EDITOR: {
          SET_IMAGE: "TRAINING_JOBS_CREATION_IMG_SLIDER_LABEL_EDITOR_SET_IMAGE",
          CLOSE: "TRAINING_JOBS_CREATION_IMG_SLIDER_LABEL_EDITOR_CLOSE",
          UPDATE_IMAGE_REGIONS:
            "TRAINING_JOBS_CREATION_IMG_SLIDER_LABEL_EDITOR_UPDATE_IMAGE_REGIONS",
        },
        SHOW_ONLY_OVERLAPPED: {
          SET: "TRAINING_JOBS_CREATION_IMG_SLIDER_SHOW_ONLY_OVERLAPPED_SET",
        },
      },
    },
    CREATE: {
      START: "TRAINING_JOBS_CREATE_START",
      SUCCESS: "TRAINING_JOBS_CREATE_SUCCESS",
      FAILURE: "TRAINING_JOBS_CREATE_FAILURE",
      CLEAR: "TRAINING_JOBS_CREATE_CLEAR",
    },
    UPDATE: "TRAINING_JOBS_UPDATE",
    ADD_NEW: "TRAINING_JOBS_ADD_NEW",
    MESSAGES: {
      CREATE_TRAINING_SUCCESS:
        "Training Created Successfully, review the status on tensor board",
      CREATE_TRAINING_ERROR:
        "Error on creating Training Job, try later or contact the admin",
      UPDATE_LABELS_SUCCESS: "Labels updated succesfully",
      IMGSLIDER: { LOAD_MORE_ERROR: "Error on load more images, try later" },
      READ_IMAGES_ERROR: "Error loading images data, try later",
      READ_IMAGES_SUCCESS: "Images Data loaded successfully",
      READ_IMAGES_START: "Reading Image Data from database",
      LABEL_COUNTS: {
        READ_START: "Reading Label Counts",
        READ_ERROR: "Error on Read Label Counts",
      },
    },
    READ: {
      START: "TRAINING_JOBS_READ_START",
      SUCCESS: "TRAINING_JOBS_READ_SUCCESS",
      FAILURE: "TRAINING_JOBS_READ_FAILURE",
    },
  },
  MACHINE_TYPES: {
    READ: {
      START: " MACHINE_TYPES_READ_START",
      SUCCESS: " MACHINE_TYPES_READ_SUCCESS",
      FAILURE: " MACHINE_TYPES_READ_FAILURE",
    },
    FILTER: {
      UPDATE: " MACHINE_TYPES_FILTER_UPDATE",
      SET: " MACHINE_TYPES_FILTER_SET",
      RESET: " MACHINE_TYPES_FILTER_RESET",
    },
    FILTERED: {
      SET: " MACHINE_TYPES_FILTERED_SET",
    },
  },
  NAVIGATION: {
    BLOCK: {
      SET: "NAVIGATION_BLOCKED_SET",
    },
  },
  GDRIVE_DOCUMENTS: {
    UPDATE: "GDRIVE_DOCUMENTS_UPDATE",
    DICT: "GDRIVE_DOCUMENTS_DICT",
    OPERATION: {
      START: "GDRIVE_DOCUMENTS_OPERATION_START",
      END: "GDRIVE_DOCUMENTS_OPERATION_END",
      FAILURE: "GDRIVE_DOCUMENTS_OPERATION_FAILURE",
      CLEAN_DATA: "GDRIVE_DOCUMENTS_OPERATION_CLEAN_DATA",
      CLEAN_ERROR: "GDRIVE_DOCUMENTS_OPERATION_CLEAN_ERROR",
    },
  },
  GDRIVE_VERSIONS: {
    ADD: "GDRIVE_VERSIONS_ADD",
    UPDATE: "GDRIVE_VERSIONS_UPDATE",
    LIST: "GDRIVE_VERSIONS_LIST",
    OPERATION: {
      START: "GDRIVE_VERSIONS_OPERATION_START",
      END: "GDRIVE_VERSIONS_OPERATION_END",
      FAILURE: "GDRIVE_VERSIONS_OPERATION_FAILURE",
      CLEAN_DATA: "GDRIVE_VERSIONS_OPERATION_CLEAN_DATA",
      CLEAN_ERROR: "GDRIVE_VERSIONS_OPERATION_CLEAN_ERROR",
    },
  },
};

// Filter Query
/**
 * * Create GQL query without parameters
 */
export const strQueryNoParamsFilterCount =
  "EventFilterCount { name description count }";

/**
 * * Create GQL query using the given parameters
 * @param {string} strParams
 */
export const strQueryParamsFilterCount = (strParams: any) =>
  `EventFilterCount(filter: ${strParams}) { name description count }`;

/**
 * * Return the correct query given the argument
 * @param {[object Object]} params
 */
export const getFilterCountQuery = (params: any) => {
  // Converting the params property to string
  const strParams = objectToString(params);
  const strQuery =
    strParams === "[]"
      ? strQueryNoParamsFilterCount
      : strQueryParamsFilterCount(strParams);
  return strQuery;
};
/* End */

const filterUsers = (
  users: UserData[],
  searchTerm: string,
  filteredGroupsIds: string[]
) => {
  return users?.filter(
    (user) =>
      `${user.firstName?.toLocaleLowerCase()} ${user.lastName?.toLocaleLowerCase()} ${user.email?.toLocaleLowerCase()}`.includes(
        searchTerm.toLocaleLowerCase()
      ) &&
      // user.groups?.some(
      //   (group) => group?.id && filteredGroupsIds.includes(group.id)
      // )
      (filteredGroupsIds.length > 0
        ? user.groups?.some(
            (group) => group?.id && filteredGroupsIds.includes(group.id)
          )
        : true)
  );
};

export interface UserFilteringLeftPaneProps {
  users: UserData[];
  groups?: GroupData[];
  onFilter: (filteredUsers: UserData[]) => void;
  onlyCheckbox: boolean;
}

/* export const getFormattedDate = (date?: any) => {
  const dateFormat = "YYYY-MM-DDTHH:mm";
  let formattedDate;
  if (date) {
    formattedDate = moment(date).format(dateFormat);
  }
  return formattedDate;
}; */

export const updateGroupFilters = (filterGroupParams: any) => ({
  type: ActionTypes.FILTER.GROUP.UPDATE,
  payload: filterGroupParams,
});

const useStyles2 = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemIcon: {
    minWidth: 0,
  },
  labelBold: {
    fontWeight: "bold",
  },
  rootText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "5px",
    color: "#3F525C",
  },
  rootIcon: {
    color: "yellow",
  },
  primaryText: {
    display: "inline",
    whiteSpace: "initial",
  },
  secondaryText: {
    float: "right",
    padding: "0 8px",
  },
  itemSecondaryAction: {
    right: 8,
  },
  activeButton: {
    color: "gray",
  },
  inactiveButton: {
    color: "lightgray",
    "&:hover": {
      color: "darkgray",
    },
  },
  checkboxStyle: {
    border: "1px solid red",
  },
}));

export const GkFilterItem = ({
  key,
  classes,
  item,
  parent,
  toggleCheckBox,
  hasGroups,
  filterName,
  setRootIconColor,
}: {
  key: any;
  classes: any;
  item: any;
  parent: any;
  toggleCheckBox: any;
  hasGroups: any;
  filterName: any;
  setRootIconColor: any;
}) => {
  const localClasses = useStyles2();
  const [open, setOpen] = React.useState(false);
  const groups = useSelector((state: any) => state.filter.group);
  const [clicked, setClicked] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasGroups) setClicked(groups.includes(item.name));
  }, [groups, item, hasGroups]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleGroupClick = () => dispatch(updateGroupFilters(item.name));

  const groupButton = hasGroups && (
    <IconButton
      onClick={handleGroupClick}
      classes={{
        root: clicked ? localClasses.activeButton : localClasses.inactiveButton,
      }}
      style={{ padding: 0 }}
      disabled={!(item.checked || item.indeterminate)}
    >
      <GroupWorkIcon />
    </IconButton>
  );
  let iconToShow: ReactElement | null = null;
  if (item.items && item.items.length > 0) {
    iconToShow = open ? (
      <ListItemSecondaryAction
        classes={{ root: localClasses.itemSecondaryAction }}
      >
        {item.level === 1 && groupButton}
        <IconButton onClick={handleOpen} style={{ padding: 0 }}>
          <ExpandLess />
        </IconButton>
      </ListItemSecondaryAction>
    ) : (
      <ListItemSecondaryAction
        classes={{ root: localClasses.itemSecondaryAction }}
      >
        {item.level === 1 && groupButton}
        <IconButton onClick={handleOpen} style={{ padding: 0 }}>
          <ExpandMore />
        </IconButton>
      </ListItemSecondaryAction>
    );
  }

  return (
    <>
      <ListItem
        key={item.id}
        divider
        button
        disableGutters
        onClick={handleOpen}
        style={{ paddingLeft: 16 * (item.level || 0) }}
      >
        <ListItemText
          primary={item.title}
          classes={{
            root: localClasses.rootText,
            primary: localClasses.primaryText,
            secondary: localClasses.secondaryText,
          }}
        />
        <ListItemIcon
          className={localClasses.listItemIcon}
          onClick={() => toggleCheckBox(item, filterName)}
        >
          <Checkbox
            checked={!!item.checked}
            value={item.name}
            indeterminate={!!item.indeterminate}
            style={{ color: "#B8CAC6", border: "unset" }}
            classes={{
              root: localClasses.checkboxStyle,
            }}
          />
        </ListItemIcon>
      </ListItem>
      {item.items && item.items.length > 0 ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List dense disablePadding>
            {item.items.map((inner: any) => (
              <GkFilterItem
                key={inner.id}
                classes={classes}
                item={inner}
                parent={item}
                toggleCheckBox={toggleCheckBox}
                hasGroups={hasGroups}
                filterName={filterName}
                setRootIconColor={setRootIconColor}
              />
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

export const UserFilteringLeftPane: React.FC<UserFilteringLeftPaneProps> = ({
  users,
  groups,
  onFilter,
  onlyCheckbox,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredGroups, setFilteredGroups] = React.useState<string[]>([]);
  const [filteredUsers, setFilteredUsers] = React.useState(users);
  const [filteredTwidgets, setFilteredTwidgets] = useState<any[]>([]);
  const [twidgetsData, setTwidgetsData] = useState([]);
  const [strGql, setStrGql] = useState<string>(
    "EventFilterCount { name description count }"
  );
  const dispatch = useDispatch();

  const setLabelTextColor = "#3F525C";
  const setRootIconColor = "#647CB3";

  const FILTERS_OPTS = gql`
    query getFilterOpts($mode: Int!) {
      FilterMode(mode: $mode) {
        id
        name
        parentId
        twidget
        title: label
        level
        type
      }
    }
  `;

  const FILTER_EXTTRA_OPTS = gql`
    query getFilterExtraOpts{
      ${strGql}
    }
  `;

  const [getFilterOpts, { data: filterOpts }] = useLazyQuery(FILTERS_OPTS, {
    variables: { mode: 802 },
  });
  const [getFilterExtraOpts, { data: filterExtraOpts }] =
    useLazyQuery(FILTER_EXTTRA_OPTS);

  useEffect(() => {
    const setChildrenChecked = (elem: any, elements: any) => {
      elem.checked = true;
      const children = elements.filter(
        (item: any) => item.parentId === elem.id
      );
      children.forEach((item: any) => setChildrenChecked(item, elements));
    };
    // Fetching twidgets data...
    getFilterOpts();

    if (filterOpts && filterOpts.FilterMode) {
      const fixedResults = filterOpts.FilterMode.map((item: any) => ({
        ...item,
        checked: false,
        indeterminate: false,
        count: 0,
      }));
      // Pre-Select "Alerts" twidget and children
      const twidgets = fixedResults.filter((elem: any) => elem.level === 0);
      const alertsTwidget = twidgets.find(
        (elem: any) => elem.name === "Alerts"
      );
      if (!isEmpty(alertsTwidget))
        setChildrenChecked(alertsTwidget, fixedResults);
      // End Pre-Select
      // Set actual "count" values
      const alertTypes = fixedResults.filter((elem: any) => elem.level === 1);
      const staticFilter = [
        {
          column: "alert_type",
          values: groupByKey(alertTypes, "name", "twidget"),
        },
      ];
      const strQuery = getFilterCountQuery(staticFilter);
      setStrGql(strQuery);
      getFilterExtraOpts();
      if (filterExtraOpts && filterExtraOpts.EventFilterCount) {
        const queryResult = filterExtraOpts;
        const getSum = (numbers: any) =>
          numbers.reduce((a: any, b: any) => a + b, 0);
        const setTotal = (elem: any, elements: any) => {
          const children = elements.filter(
            (item: any) => item.parentId === elem.id
          );
          elem.count += getSum(
            children.map((child: any) => {
              setTotal(child, elements);
              return child.count;
            })
          );
        };
        const resultsArray = queryResult.EventFilterCount;
        const alertDescriptions = fixedResults.filter(
          (elem: any) => elem.level === 2
        );
        resultsArray.forEach((elem: any) => {
          const parent = alertTypes.find(
            (item: any) => item.name === elem.name
          );
          const children = alertDescriptions.filter(
            (item: any) => item.parentId === parent.id
          );
          const child = children.find(
            (item: any) => item.title === elem.description
          );
          if (child) child.count = elem.count;
        });
        // Get total sum counts from children
        twidgets.forEach((elem: any) => setTotal(elem, fixedResults));
        // End set actual "count"
        setTwidgetsData(fixedResults);
        setFilteredTwidgets(fixedResults);
      }
    }
  }, [getFilterOpts, filterOpts, getFilterExtraOpts, filterExtraOpts]);

  useEffect(() => {
    const performQuery = (
      eventTypes: any,
      descriptionTypes: any,
      newGraphFilterData: any
    ) => {
      const newFilterData: { [string: string]: any }[] = [];
      if (!isEmpty(eventTypes))
        newFilterData.push({ column: "alert_type", values: eventTypes });
      if (!isEmpty(descriptionTypes))
        newFilterData.push({
          column: "alert_description",
          values: descriptionTypes,
        });
      dispatch(
        updateFilters({
          newFilterData,
          newGraphFilterData,
        })
      );
    };

    const id = setTimeout(() => {
      const checkedTwidgets = twidgetsData.filter(
        (twidget: any) => twidget.checked
      );
      const typeData = checkedTwidgets.filter(
        (twidget: any) => twidget.level === 1
      );
      const eventTypes = groupByKey(typeData, "name", "twidget");
      const typeIDs = typeData.map((item: any) => item.id);
      const descriptionData = checkedTwidgets.filter(
        (twidget: any) => twidget.level === 2
      );
      const descriptionTypes = groupByKey(
        typeIDs.length === 1
          ? descriptionData
          : descriptionData.filter(
              (item: any) => !typeIDs.includes(item.parentId)
            ),
        "title",
        "twidget"
      );
      // Get graph filter data
      const items = twidgetsData.filter(
        (item: any) => item.checked || item.indeterminate
      );
      const alertTypes = items.filter((item: any) => item.level === 1);
      const alertDescriptions: any[] = items.filter(
        (item: any) => item.level === 2
      );
      const newGraphFilterData: any[] = [];
      alertTypes.forEach((item?: any) => {
        const element = {
          key: "alert_type",
          value: item.name,
          group: groups ? groups.includes(item.name) : false,
          subGroups: alertDescriptions
            .filter((elem: any) => elem.parentId === item.id)
            .map((elem: any) => elem.title),
        };
        newGraphFilterData.push(element);
      });
      performQuery(eventTypes, descriptionTypes, newGraphFilterData);
    }, DELAY_TIME);
    return () => clearTimeout(id);
  }, [twidgetsData, dispatch, groups]);

  const createTwidgetsFromData = useCallback((data) => {
    const twidgets = data.filter((item: any) => item.level === 0);

    const createInnerItems = (el: any) => ({
      ...el,
      items: data
        .filter((item: any) => item.parentId === el.id)
        .map((inner: any) => createInnerItems(inner)),
    });

    return twidgets.map((item: any) => createInnerItems(item));
  }, []);

  const updateGroupState = useCallback(
    (typeData: any) => {
      const selected = typeData.map((item: any) => item.name);
      if (groups) {
        groups.forEach((itemName: any) => {
          if (!selected.includes(itemName)) {
            dispatch(updateGroupFilters(itemName));
          }
        });
      }
    },
    [dispatch, groups]
  );

  const toggleCheckBox = useCallback(
    (changedCheckBox: any) => {
      const checked = !changedCheckBox.checked;
      const container = twidgetsData.find(
        (cb: any) => cb.id === changedCheckBox.id
      );

      function checkChilds(el: any) {
        el.indeterminate = false;
        el.checked = checked;
        const childs = twidgetsData.filter((cb: any) => cb.parentId === el.id);
        childs.forEach((item: any) => checkChilds(item));
      }

      function checkParents(el: any) {
        el.indeterminate = true;
        el.checked = false;
        const parent = twidgetsData.find((cb: any) => cb.id === el.parentId);
        if (parent) {
          checkParents(parent);
        }
      }

      // check from siblings to outside
      function checkSiblings(el: any) {
        const parent: any = twidgetsData.find(
          (cb: any) => cb.id === el.parentId
        );
        if (parent != null) {
          // check siblings
          const siblings = twidgetsData.filter(
            (item: any) => item.parentId === el.parentId
          );
          if (siblings.every((item: any) => item.checked === true)) {
            parent.checked = true;
            parent.indeterminate = false;
            checkSiblings(parent);
          } else if (siblings.every((item: any) => item.checked === false)) {
            parent.checked = false;
            parent.indeterminate = false;
            checkSiblings(parent);
          } else {
            // we need to set all parents
            checkParents(parent);
          }
        }
      }

      checkChilds(container);
      checkSiblings(container);
      // Updating the state
      const newtwidgetsData = [...twidgetsData];
      setTwidgetsData(newtwidgetsData);
      // Update group state
      const filteredItems = newtwidgetsData
        .filter((item: any) => item.checked || item.indeterminate)
        .filter((item: any) => item.level === 1);
      updateGroupState(filteredItems);
    },
    [twidgetsData, updateGroupState]
  );

  // Search method
  useEffect(() => {
    let data: any[] = twidgetsData.filter((twidget: any) =>
      `${twidget.twidget} ${twidget.title}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    function findParents(el: any) {
      if (el !== null && data.findIndex((item: any) => item.id === el) === -1) {
        const newItem: any = twidgetsData.find((item: any) => item.id === el);
        if (newItem) {
          newItem.collapsed = false;
          data.push(newItem);
          if (newItem.parentId !== null) {
            findParents(newItem.parentId);
          }
        }
      }
    }

    if (searchTerm.length > 0) {
      const parents: any = [...new Set(data.map((item: any) => item.parentId))];
      parents.forEach((el: any) => findParents(el));
    }

    data = createTwidgetsFromData(data);

    setFilteredTwidgets(data);
  }, [createTwidgetsFromData, twidgetsData, searchTerm]);

  React.useEffect(() => {
    if (users.length > 0) {
      const newFilteredUsers = filterUsers(users, searchTerm, filteredGroups);

      setFilteredUsers(newFilteredUsers);
      onFilter(newFilteredUsers);
    }
  }, [users, searchTerm, filteredGroups, onFilter]);

  /* Functions of filter  new elements */
  const updateFilterEventsBy = (newFilterParams: any) => ({
    type: ActionTypes.FILTER.EVENTS.BY,
    payload: newFilterParams,
  });

  // Date range handler
  const filterEventsBy = useSelector(
    (state: any) => state.filter.filterEventsBy
  );
  const { startDate, endDate } = filterEventsBy;
  const [dateRange, setDateRange] = useState({ startDate, endDate });
  const [startHelperText, setStartHelperText] = useState("");
  const [endHelperText, setEndHelperText] = useState("");

  // Report period
  const [period, setPeriod] = useState("");

  useEffect(() => {
    const exists = (property: any) =>
      property !== null &&
      property !== undefined &&
      property.trim().length !== 0;
    const isValid = (date: any) => date.isValid();
    const isBefore = (first: any, second: any) => first.isSameOrBefore(second);
    const isAfter = (first: any, second: any) => first.isSameOrAfter(second);
    const validateRange = (start: any, end: any) => {
      let isValidRange = true;
      const startM = moment(start);
      const endM = moment(end);
      // const now = getFormattedDate();
      const now = new Date();
      // Start date is valid
      if (!isValid(startM)) {
        isValidRange = false;
        setStartHelperText("The start date is invalid");
      }
      // End date is valid
      if (!isValid(endM)) {
        isValidRange = false;
        setEndHelperText("The end date is invalid");
      }
      // Start date is before now
      if (!isBefore(startM, now)) {
        isValidRange = false;
        setStartHelperText("The start date should be same or before today");
      }
      // End date is before now
      if (!isBefore(endM, now)) {
        isValidRange = false;
        setEndHelperText("The end date should be same or before today");
      }
      // Start date is before End date
      if (!isBefore(startM, endM)) {
        isValidRange = false;
        setStartHelperText("The start date should be same or before end date");
      }
      // End date is same or after Start date
      if (!isAfter(endM, startM)) {
        isValidRange = false;
        setEndHelperText("The end date should be same or after start date");
      }
      if (isValidRange) {
        setStartHelperText("");
        setEndHelperText("");
      }
      return isValidRange;
    };
    const { startDate: start, endDate: end } = dateRange;
    if (exists(start) && exists(end) && validateRange(start, end)) {
      dispatch(updateFilterEventsBy(dateRange));
    }
  }, [dateRange, dispatch]);
  const handleDateChange = (event: any) => {
    const { id, value } = event.target;
    const newDateRange = {
      ...dateRange,
      [id]: value,
    };
    setDateRange(newDateRange);
  };

  const now = moment();
  const dateStart = moment(now.startOf("day"));
  const dateEnd = moment(now.endOf("day"));
  const dateFormat = "YYYY-MM-DDTHH:mm";

  const allowedPeriods: any = useMemo(() => {
    return {
      Today: [
        moment(dateStart).format(dateFormat),
        moment().format(dateFormat),
      ],
      Yesterday: [
        moment(dateStart).subtract(1, "days").format(dateFormat),
        moment(dateEnd).subtract(1, "days").format(dateFormat),
      ],
      "Last 3 days": [
        moment(dateStart).subtract(3, "days").format(dateFormat),
        moment(dateEnd).subtract(1, "days").format(dateFormat),
      ],
      "Last week": [
        moment(dateStart).subtract(7, "days").format(dateFormat),
        moment(dateEnd).subtract(1, "days").format(dateFormat),
      ],
      "Last month": [
        moment(dateStart).subtract(1, "months").format(dateFormat),
        moment(dateEnd).subtract(1, "days").format(dateFormat),
      ],
      "Last year": [
        moment(dateStart).subtract(1, "years").format(dateFormat),
        moment(dateEnd).subtract(1, "days").format(dateFormat),
      ],
      Custom: [startDate, endDate],
    };
  }, [dateEnd, dateStart, endDate, startDate]);

  const handleSelectPeriod = (event: any) => {
    const { value } = event.target;
    setPeriod(value);
  };

  const [startValue, setStartValue] = useState(startDate);
  const [endValue, setEndValue] = useState(endDate);
  useEffect(() => {
    setStartValue(period !== "" ? allowedPeriods[period][0] : startDate);
    setEndValue(period !== "" ? allowedPeriods[period][1] : endDate);
  }, [period, allowedPeriods, startDate, endDate]);

  const commonProps = {
    className: classes.dateInput,
    type: "datetime-local",
    fullWidth: true,
  };

  const startInputProps = {
    key: "startDate",
    id: "startDate",
    label: "From:",
    error: startHelperText !== "",
    helperText: startHelperText,
  };

  const endInputProps = {
    key: "endDate",
    id: "endDate",
    label: "To:",
    error: endHelperText !== "",
    helperText: endHelperText,
  };

  const getControlledDateTimeInput = (props: any, val: any) => (
    <TextField
      {...commonProps}
      {...props}
      defaultValue={getFormattedDate(val)}
      onChange={handleDateChange}
    />
  );

  const getUncontrolledDateTimeInput = (props: any, val: any) => (
    <TextField
      {...commonProps}
      {...props}
      value={getFormattedDate(val)}
      disabled
    />
  );

  const startInputControlled = getControlledDateTimeInput(
    startInputProps,
    startValue
  );

  const startInputUncontrolled = getUncontrolledDateTimeInput(
    startInputProps,
    startValue
  );

  const endInputControlled = getControlledDateTimeInput(
    endInputProps,
    endValue
  );

  const endInputUncontrolled = getUncontrolledDateTimeInput(
    endInputProps,
    endValue
  );
  /* End */

  const handlerOnInputSearchBoxChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: string) => {
      setSearchTerm(value);
    },
    []
  );

  const handlerOnSearchBoxChange = React.useCallback(
    (
      event: React.ChangeEvent<unknown>,
      value: string | UserData | (string | UserData)[] | null
    ) => {
      if (_.isString(value)) {
        setSearchTerm(value);
      }
    },
    []
  );

  const handleGroupSelection = React.useCallback((value: FilterValue) => {
    const newFilteredGroups = Object.keys(value).filter((k) => value[k]);
    setFilteredGroups(newFilteredGroups);
  }, []);

  const handlerFilterOptions = (): UserData[] => filteredUsers;

  let content = (
    <ListItem className={classes.filterContainer} key="notFound">
      <Typography color="textPrimary">No items were found</Typography>
    </ListItem>
  );

  if (filteredTwidgets.length > 0 && onlyCheckbox) {
    content = (
      <div className={classes.filterContainer}>
        <p className={classes.datesFilter}>Filter</p>
        <Divider />
        {filteredTwidgets.map((item: any) => (
          <GkFilterItem
            key={item.id}
            classes={classes}
            item={item}
            parent={null}
            toggleCheckBox={toggleCheckBox}
            hasGroups
            filterName={item.name}
            setRootIconColor={setRootIconColor}
          />
        ))}
      </div>
    );
  } else if (!onlyCheckbox) {
    content = (
      <Box className={classes.filterContainer}>
        <SC.SearchBoxFixed
          options={users}
          filterOptions={handlerFilterOptions}
          getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
          getOptionSelected={(user, currentUser) => user.id === currentUser.id}
          inputValue={searchTerm}
          onInputChange={handlerOnInputSearchBoxChange}
          onChange={handlerOnSearchBoxChange}
          textFieldProps={{
            label: "search",
          }}
        />
        <Divider />
        <p className={classes.datesFilter}>Date Range</p>
        <div className={classes.dateRangeContent}>
          <div className={classes.periodSelector}>
            <InputLabel id="reportPeriodLabel">Period:</InputLabel>
            <Select
              labelId="reportPeriodLabel"
              id="reportPeriod"
              value={period}
              onChange={handleSelectPeriod}
              style={{ width: "100%", paddingLeft: "10px" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.entries(allowedPeriods).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </div>
          {period === "Custom" && [startInputControlled, endInputControlled]}
          {period !== "Custom" && [
            startInputUncontrolled,
            endInputUncontrolled,
          ]}
        </div>
        <div>
          <p className={classes.datesFilter}>Events</p>
          {filteredTwidgets.map((item: any) => (
            <GkFilterItem
              key={item.id}
              classes={classes}
              item={item}
              parent={null}
              toggleCheckBox={toggleCheckBox}
              hasGroups
              filterName={item.name}
              setRootIconColor={setRootIconColor}
            />
          ))}
        </div>
        <Divider />
        <FilterList
          items={groups}
          label="Group"
          getItemKey={(item) => item.id!}
          getItemLabel={(item) => item.name!}
          onChange={handleGroupSelection}
        />
      </Box>
    );
  }

  return content;
};

export default UserFilteringLeftPane;
