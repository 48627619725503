/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  withStyles,
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Checkbox,
  OutlinedInput,
  Box as BoxMui,
  GridProps,
  BoxProps,
  Theme,
  makeStyles,
  Button,
} from "@material-ui/core/";
import { createStyles } from "@material-ui/styles";

const generalStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      width: "100%",
      height: "100%",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
      padding: "16px 16px 20px 16px",
      borderRadius: "10px",
      border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
      boxSizing: "border-box",
    },
    itemTitle: {
      flex: "1",
      color: "#000 !important",
      fontSize: "18px !important",
      fontStyle: "normal !important",
      fontWeight: "bold",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    hide: {
      display: "none",
    },
    headerPayment: {
      display: "flex",
      justifyContent: "end",
      marginBottom: "20px",
    },
    editPayment: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: "#FEFEFE",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "#973B7B",
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
    },
    titlePayment: {
      display: "flex",
      height: "30px",
      width: "208px",
      margin: "auto",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "10px",
      marginTop: "7px",
      marginBottom: "26px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#973B7B",
        margin: "auto",
      },
    },
    paymentContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      padding: "0px !important",
    },
    paymentContent: {
      display: "flex",
      height: "100%",
      width: "100%",
    },
    paymentImage: {
      display: "flex",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "10px",
      color: "#973B7B",
      margin: "auto",
      marginBottom: "20px",
      "& svg": {
        margin: "auto",
      },
    },
    paymentAmount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: "17px",
      "& .amount": {
        fontSize: "90px",
        lineHeight: "1",
        fontWeight: "bold",
        color: "#000000",
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "30px",
        lineHeight: "1",
        color: "#CCCCCC",
      },
    },
    paymentDescription: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "normal",
      color: "#454545",
      textAlign: "center",
      marginBottom: "23px",
    },
    deletePayment: {
      height: "38px",
      width: "159px",
      border: "2px solid #973B7B",
      boxSizing: "border-box",
      borderRadius: "116px",
      color: "#973B7B",
    },
    paymentFooter: {
      display: "flex",
      justifyContent: "end",
    },
    cardPreview: {
      display: "flex",
      flexDirection: "column",
      height: "90px",
      width: "150px",
      padding: "11px 16px",
      background: "linear-gradient(109.58deg, #B8CAC6 0%, #65DFBD 100%)",
      borderRadius: "5px",
      marginRight: "12px",
      marginTop: "6px",
    },
    cardTitle: {
      display: "flex",
      justifyContent: "space-between",
      "& p": {
        fontFamily: "'Sarabun'",
        fontSize: "10px",
        lineHeight: "21px",
        fontWeight: "normal",
        letterSpacing: "0.1em",
        color: "#FFF",
        textAlign: "right",
      },
    },
    cardNumber: {
      display: "flex",
      flex: "1",
      alignItems: "end",
      fontFamily: "'Sarabun'",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "21px",
      justifyContent: "left",
      letterSpacing: "3px",
      color: "#FFFFFF",
    },
    cardDetail: {
      flex: 1,
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: "normal",
      textAlign: "left",
      "& .title": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "bold",
        marginBottom: "30px",
      },
      "& p": {
        paddingLeft: "13px",
      },
    },
    defaultLbl: {
      display: "flex",
      height: "27px",
      width: "104px",
      background: `${theme.generalStylesContainer.layers.secondary.backgroundColor}A3`,
      borderRadius: "116px",
      margin: "auto",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        margin: "auto",
      },
    },
    menuBtn: {
      height: "26px",
      width: "26px",
      minWidth: "unset",
    },
    checkContainer: {
      display: "flex",
      textAlign: "left",
      alignItems: "center",
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "normal",
      },
      "& span": {
        padding: "0px !important",
      },
    },
  })
);

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const useBoxStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props: CustomBoxProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(4),
    maxHeight: "56px",
  },
}));

const Box = (props: CustomBoxProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useBoxStyles(props);

  return <BoxMui className={classes.root} {...muiProps} />;
};

const ButtonSecondary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.secondary.backgroundColor,
    // fontSize: theme.button.secondary.fontSize,
    // lineHeight: theme.button.secondary.lineHeight,
    color: theme.button.secondary.color,
    border: theme.button.secondary.border,
    textTransform: theme.button.secondary.textTransform,
    "&:hover": {
      color: theme.button.secondary.hoverColor,
      backgroundColor: theme.button.secondary.hoverBackground,
      border: theme.button.secondary.hoverBorder,
    },
    "&:disabled": {
      color: theme.button.secondary.disabledColor,
      backgroundColor: theme.button.secondary.disabledBackground,
      border: theme.button.secondary.disabledBorder,
    },
  },
}))(Button);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
}))(Button);

export default {
  Box,
  Checkbox,
  ButtonPrimary,
  ButtonSecondary,
  generalStyles,
};
