/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { JOBS_FOR_TRAINING, TRAINING_JOBS, TRAINED_MODELS } = ActionTypes;
const imagePhase = 24;
const INITIAL_STATE = {
  trainingJobs: {
    isReadingData: false,
    onReadSuccess: null,
    onReadError: null
  },
  // read classes
  isReadData: false,
  onReadError: null,
  onReadSuccess: [],
  isReadClassesData: false,
  onReadClassesError: null,
  onReadClassesSuccess: [],
  isReadImagesData: false,
  onReadImagesDataError: null,
  onReadImagesDataSuccess: [],
  isReadTrainedModels: false,
  onReadTrainedModelsError: null,
  onReadTrainedModelsSuccess: [],
  excludedImagesFromTraining: [],
  tfParams: {
    network_type: 'mobilenet_v2_ssd',
    train_whole_model: true,
    num_training_steps: 1000,
    num_eval_steps: 120,
    batch_size: 64,
    train_percentage: 80,
    ssd_feature_extract: {
      depth_multiplier: 1
    }
  },
  pipelineConfig: null,
  modelOptions: {},
  classMap: {
    selectedLabels: [],
    selectedLabelClasses: []
  },
  nameInfo: { name: '', comments: '' },
  trainingCreationImageindex: 0,
  creation: {
    loadMore: {
      isLoadingMore: false,
      onLoadMoreError: null
    }
  },
  filteredModels: [],
  imageToEdit: {
    image: null,
    img: null,
    open: false,
    index: null
  },
  showOnlyOverlapped: false,
  onActionSuccess: null,
  onActionError: null,
  isPerformingAction: false,
  selectedMachine: { value: 'n1-standard-16', label: 'n1-standard-16' },
};


const trainingJobsReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Read Jobs from db
    case JOBS_FOR_TRAINING.READ.START:
      return {
        ...state,
        isReadData: true,
        onUpdateError: null
      };
    case JOBS_FOR_TRAINING.READ.SUCCESS: {
      return {
        ...state,
        onReadSuccess: action.payload,
        isReadData: false,
      };
    }
    case JOBS_FOR_TRAINING.READ.FAILURE:
      return {
        ...state,
        isReadData: false,
        onReadError: action.payload,
      };

    // Read ImagesData from db
    case JOBS_FOR_TRAINING.READ_IMAGES.START:
      return {
        ...state,
        isReadImagesData: true,
        onReadImagesDataError: null,
        isPerformingAction: false,
        onActionSuccess: { message: TRAINING_JOBS.MESSAGES.READ_IMAGES_START },
        onActionError: null
      };
    case JOBS_FOR_TRAINING.READ_IMAGES.SUCCESS: {
      return {
        ...state,
        onReadImagesDataSuccess: action.payload,
        isReadImagesData: false,
        trainingCreationImageindex: 0,
        isPerformingAction: false,
        onActionSuccess: { message: TRAINING_JOBS.MESSAGES.READ_IMAGES_SUCCESS },
        onActionError: null
      };
    }
    case JOBS_FOR_TRAINING.READ_IMAGES.FAILURE:
      return {
        ...state,
        isReadImagesData: false,
        onReadImagesDataError: action.payload,
        trainingCreationImageindex: 0,
        isPerformingAction: false,
        onActionSuccess: null,
        onActionError: { message: TRAINING_JOBS.MESSAGES.READ_IMAGES_ERROR }
      };
    // Read TRAINED MODELS from db
    case TRAINED_MODELS.READ.START:
      return {
        ...state,
        isReadTrainedModels: !action.payload,
        onReadTrainedModelsError: null
      };
    case TRAINED_MODELS.READ.SUCCESS: {
      return {
        ...state,
        onReadTrainedModelsSuccess: action.payload,
        isReadTrainedModels: false,
        filteredModels: action.payload
      };
    }
    case TRAINED_MODELS.READ.FAILURE:
      return {
        ...state,
        isReadTrainedModels: false,
        onReadTrainedModelsError: action.payload,
      };
    case TRAINED_MODELS.FILTERED.SET:
      return {
        ...state,
        filteredModels: action.payload,
      };
    // Read labelCounts from db
    case JOBS_FOR_TRAINING.LABEL_COUNTS.READ.START:
      return {
        ...state,
        isReadLabelCounts: true,
        onReadLabelCountsError: null,
        isPerformingAction: false,
        onActionSuccess: { message: TRAINING_JOBS.MESSAGES.LABEL_COUNTS.READ_START },
        onActionError: null
      };
    case JOBS_FOR_TRAINING.LABEL_COUNTS.READ.SUCCESS: {
      return {
        ...state,
        onReadLabelCountsSuccess: action.payload,
        isReadLabelCounts: false,
      };
    }
    case JOBS_FOR_TRAINING.LABEL_COUNTS.READ.FAILURE:
      return {
        ...state,
        isReadLabelCounts: false,
        onReadLabelCountsError: action.payload,
        isPerformingAction: false,
        onActionSuccess: null,
        onActionError: { message: TRAINING_JOBS.MESSAGES.LABEL_COUNTS.READ_ERROR }
      };
    // excluded images
    case JOBS_FOR_TRAINING.EXCLUDED_IMAGES.SET: {
      const aux = state.excludedImagesFromTraining;

      action.payload.forEach(id => {
        const position = aux.indexOf(id);
        if (position >= 0) {
          aux.splice(position, 1);
        } else {
          aux.push(id);
        }
      });

      return ({ ...state, excludedImagesFromTraining: [...aux] });
    }
    // tfPArtams
    case JOBS_FOR_TRAINING.TF_PARAMS.SET: {
      return ({ ...state, tfParams: action.payload });
    }
    case JOBS_FOR_TRAINING.PIPELINE_CONFIG.SET: {
      return ({ ...state, pipelineConfig: action.payload });
    }
    // machineType
    case JOBS_FOR_TRAINING.MACHINE_TYPE.SET: {
      return ({ ...state, selectedMachine: action.payload });
    }
    // model Options
    case JOBS_FOR_TRAINING.MODEL_OPTIONS.SET: {
      return ({ ...state, modelOptions: action.payload });
    }
    // class map
    case JOBS_FOR_TRAINING.CLASS_MAP.SET: {
      return ({ ...state, classMap: action.payload });
    }
    // Name Info
    case JOBS_FOR_TRAINING.NAME_INFO.SET: {
      return ({ ...state, nameInfo: action.payload });
    }
    // imageSlider
    case TRAINING_JOBS.CREATION.IMG_SLIDER.NEXT: {
      return ({
        ...state,
        trainingCreationImageindex:
          (state.trainingCreationImageindex + imagePhase) >= state.onReadImagesDataSuccess.length
            ? state.trainingCreationImageindex : state.trainingCreationImageindex + imagePhase
      });
    }

    case TRAINING_JOBS.CREATION.IMG_SLIDER.BEFORE: {
      return ({ ...state, trainingCreationImageindex: Math.max(state.trainingCreationImageindex - imagePhase, 0) });
    }
    case TRAINING_JOBS.CREATION.IMG_SLIDER.LOAD_MORE.START: {
      return ({
        ...state,
        creation: {
          ...state.creation,
          loadMore:
          {
            ...state.creation.loadMore,
            isLoadingMore: true,
            onReadError: null
          }
        }
      });
    }
    case TRAINING_JOBS.CREATION.IMG_SLIDER.LOAD_MORE.ON_SUCCESS: {
      const imageAux = state.onReadImagesDataSuccess;
      action.payload.positions.forEach((position, index) => {
        imageAux[state.trainingCreationImageindex + position].url = action.payload.urls[index];
      });
      return ({
        ...state,
        onReadImagesDataSuccess: [...imageAux]

      });
    }
    case TRAINING_JOBS.CREATION.IMG_SLIDER.LOAD_MORE.ON_ERROR: {
      return ({
        ...state,
        creation: {
          ...state.creation,
          loadMore:
            { ...state.creation.loadMore, isLoadingMore: true, onReadError: action.payload }
        },
        isPerformingAction: false,
        onActionSuccess: null,
        onActionError: { message: TRAINING_JOBS.MESSAGES.IMGSLIDER.LOAD_MORE_ERROR }
      });
    }
    case TRAINING_JOBS.CREATION.IMG_SLIDER.LABEL_EDITOR.SET_IMAGE: {
      return {
        ...state,

        imageToEdit: {
          ...action.payload,
          open: true
        }

      };
    }
    case TRAINING_JOBS.CREATION.IMG_SLIDER.LABEL_EDITOR.CLOSE: {
      return {
        ...state,

        imageToEdit: {
          image: null,
          img: null,
          open: false
        }

      };
    }
    case TRAINING_JOBS.CREATION.IMG_SLIDER.LABEL_EDITOR.UPDATE_IMAGE_REGIONS: {
      if (action.payload) {
        const images = [...state.onReadImagesDataSuccess];
        images[state.imageToEdit.index].labelsInImages = action.payload.regions;
        return {
          ...state,
          onReadImagesDataSuccess: images,
          imageToEdit: {
            ...state.imageToEdit,
            image: {
              ...state.imageToEdit.image,
              labelsInImages: action.payload.regions,
              noLabels: action.payload.noLabels
            }
          },
          isPerformingAction: false,
          onActionSuccess: { message: TRAINING_JOBS.MESSAGES.UPDATE_LABELS_SUCCESS },
          onActionError: null
        };
      }

      return state;
    }
    case TRAINING_JOBS.CREATION.IMG_SLIDER.SHOW_ONLY_OVERLAPPED.SET: {
      return {
        ...state,
        showOnlyOverlapped: action.payload
      };
    }
    case TRAINING_JOBS.CREATE.START: {
      return {
        ...state,
        isPerformingAction: true,
        onActionSuccess: null,
        onActionError: null

      };
    }
    case TRAINING_JOBS.CREATE.SUCCESS: {
      return {
        ...state,
        isPerformingAction: false,
        onActionSuccess: {
          message: TRAINING_JOBS.MESSAGES.CREATE_TRAINING_SUCCESS,
          onCloseAction:
            action.payload.action
        },
        onActionError: null

      };
    }
    case TRAINING_JOBS.CREATE.FAILURE: {
      return {
        ...state,
        isPerformingAction: false,
        onActionSuccess: null,
        onActionError: { message: TRAINING_JOBS.MESSAGES.CREATE_TRAINING_ERROR }

      };
    }
    case TRAINING_JOBS.CREATE.CLEAR: {
      return {
        ...INITIAL_STATE,
        filteredModels: state.filteredModels,
        onReadSuccess: state.onReadSuccess,
        onReadClassesSuccess: state.onReadClassesSuccess,
        onReadTrainedModelsSuccess: state.onReadTrainedModelsSuccess,
        trainingJobs: state.trainingJobs
      };
    }
    case TRAINING_JOBS.READ.START: {
      return {
        ...state,
        trainingJobs: {
          ...state.trainingJobs,
          isReadingData: true
        }
      };
    }
    case TRAINING_JOBS.READ.SUCCESS: {
      return {
        ...state,
        trainingJobs: {
          ...state.trainingJobs,
          isReadingData: false,
          onReadSuccess: action.payload
        }
      };
    }
    case TRAINING_JOBS.READ.FAILURE: {
      return {
        ...state,
        trainingJobs: {
          ...state.trainingJobs,
          isReadingData: false,
          onReadSuccess: state.trainingJobs?.onReadSuccess,
          onReadError: action.payload
        },
        isPerformingAction: false,
        onActionSuccess: null,
        onActionError: { message: TRAINING_JOBS.MESSAGES.READ_TRAINING_ERROR }

      };
    }
    case TRAINING_JOBS.UPDATE: {
      const { trainingJobId, updatedTrainingJob } = action.payload;
      let newTrainingJobs = state.trainingJobs.onReadSuccess;
      if (newTrainingJobs) {
        newTrainingJobs = newTrainingJobs.map((job) => {
          if (job.resultedModelID === trainingJobId) {
            return updatedTrainingJob;
          }
          return job;
        });
      }
      return {
        ...state,
        trainingJobs: {
          ...state.trainingJobs,
          isReadingData: false,
          onReadSuccess: newTrainingJobs
        }
      };
    }
    case TRAINING_JOBS.ADD_NEW: {
      const { newTrainingJob } = action.payload;
      return {
        ...state,
        trainingJobs: {
          ...state.trainingJobs,
          isReadingData: false,
          onReadSuccess: [newTrainingJob, ...state.trainingJobs.onReadSuccess]
        }
      };
    }
    default: return state;
  }
};




export default trainingJobsReducerFn;

export const addLabelsTojobs = (labelClasses, jobs, fieldName, fieldTocheck = 'labeledClasses') => jobs.map((job) => {
  const aux = [];
  job[fieldTocheck]?.forEach((enumValue) => {
    aux.push(labelClasses[enumValue]);
  });
  job[fieldName] = aux;
  return job;
});
