import { gql } from "@apollo/client";
import {
  ContactData,
  UserData,
  GroupData,
  RoleData,
  MutationAddNoteArgs as AddNoteData,
} from "./types-and-hooks";

export const createEmptyNoteData = (): AddNoteData => {
  const noteData: AddNoteData = {
    comment: "",
    rID: "",
    attachments: [],
  };

  return noteData;
};

export const createEmptyContactData = (): ContactData => {
  const contactData: ContactData = {
    __typename: "ContactData",
    address: "",
    phone: "",
  };
  return contactData;
};

export const createEmptyUserData = (): UserData => {
  const userData: UserData = {
    __typename: "UserData",
    id: undefined,
    email: "",
    firstName: "",
    lastName: "",
    active: true,
    profilePicture: "",
    updated: "",
    creationDate: null,
    departmentName: "",
  };

  return userData;
};

export const createEmptyOrganizationData = () => {
  const userData: UserData = {
    __typename: "UserData",
    id: undefined,
    email: "",
    firstName: "",
    active: true,
    updated: "",
    creationDate: null,
  };

  return userData;
};

export const createEmptyGroupData = (): GroupData => {
  const groupData: GroupData = {
    __typename: "GroupData",
    id: undefined,
    name: "",
    description: "",
    creationDate: null,
    roles: [],
  };

  return groupData;
};

export const createEmptyRoleData = (): RoleData => {
  const roleData: RoleData = {
    __typename: "RoleData",
    id: undefined,
    name: "",
    description: "",
    visibleLevel: 0,
    permissions: [],
    creationDate: null,
  };

  return roleData;
};

export const NEW_NOTE_FRAGMENT_GQL = gql`
  fragment NewNote on AddNoteData {
    id
    comment
    rID
    creationDate
    attachments {
      name
      path
    }
  }
`;

export const NEW_ROLE_FRAGMENT_GQL = gql`
  fragment NewRole on RoleData {
    id
    name
    description
    visibleLevel
    permissions {
      id
      name
      description
      type
      order
      enabled
      children {
        id
        name
        description
        type
        order
        enabled
      }
    }
    creationDate
  }
`;

export const NEW_GROUP_FRAGMENT_GQL = gql`
  fragment NewGroup on GroupData {
    id
    name
    description
    creationDate
    roles {
      id
      name
      description
      permissions
      dacls
      views
      creationDate
    }
  }
`;

export const NEW_USER_FRAGMENT_GQL = gql`
  fragment NewUser on UserData {
    id
    email
    firstName
    lastName
    active
    profilePicture
    work {
      address
      phone
    }
    updated
    creationDate
    organisation {
      id
      name
    }
    department {
      id
      name
    }
    groups {
      id
      name
      description
      creationDate
      roles {
        id
        name
        description
        permissions
        dacls
        views
        creationDate
      }
    }
  }
`;
