/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from "react";
import _ from "lodash";

// Assets
import { GroupData } from "graphql/types-and-hooks";

import SC from "./group-filtering-left-pane.styles";

const filterGroups = (groups: GroupData[], searchTerm: string) => {
  return groups?.filter((group) =>
    group.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
  );
};

export interface GroupFilteringLeftPaneProps {
  groups: GroupData[];
  onFilter: (filteredGroups: GroupData[]) => void;
}

export const GroupFilteringLeftPane: React.FC<GroupFilteringLeftPaneProps> = ({
  groups,
  onFilter,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredGroups, setFilteredGroups] = React.useState(groups);

  React.useEffect(() => {
    if (groups.length > 0) {
      const newFilteredGroups = filterGroups(groups, searchTerm);

      setFilteredGroups(newFilteredGroups);
      onFilter(newFilteredGroups);
    }
  }, [searchTerm, groups, onFilter]);

  const handlerOnInputSearchBoxChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: string) => {
      setSearchTerm(value);
    },
    []
  );

  const handlerOnSearchBoxChange = React.useCallback(
    (
      event: React.ChangeEvent<unknown>,
      value: string | GroupData | (string | GroupData)[] | null
    ) => {
      if (_.isString(value)) {
        setSearchTerm(value);
      }
    },
    []
  );

  const handlerFilterOptions = (): GroupData[] => filteredGroups;

  return (
    <SC.LeftPaneContainer>
      <SC.SearchBoxFixed
        options={groups}
        filterOptions={handlerFilterOptions}
        getOptionLabel={(group) => group.name ?? ""}
        getOptionSelected={(group, currentGroup) =>
          group.id === currentGroup.id
        }
        inputValue={searchTerm}
        onInputChange={handlerOnInputSearchBoxChange}
        onChange={handlerOnSearchBoxChange}
        textFieldProps={{
          label: "search",
        }}
      />
    </SC.LeftPaneContainer>
  );
};

export default GroupFilteringLeftPane;
