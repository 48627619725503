// Dependencies
import React from "react";
import { IconButtonProps as IconButtonPropsBase } from "@material-ui/core";

// Styles
import SC from "./icon-button.styles";

export interface IconButtonProps extends IconButtonPropsBase {
  icon: JSX.Element;
  name: string;
  type: "button" | "reset" | "submit" | undefined;
  onClick?: () => void;
  disabled?: boolean;
}

export const IconButtonGeneric: React.FC<IconButtonProps> = ({
  name,
  icon,
  type,
  onClick,
  disabled,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SC.IconButton type={type} onClick={onClick} disabled={disabled} {...props}>
    {icon}
    {name ? <SC.Label> {name}</SC.Label> : null}
  </SC.IconButton>
);

export default IconButtonGeneric;
