/* eslint-disable react/jsx-boolean-value */
import React from "react";
import {
  Box,
  Button,
  Snackbar,
  Tooltip,
  Typography,
  CircularProgress,
  SvgIcon,
  CardMedia,
} from "@material-ui/core";
import Alert, { AlertProps } from "components/alert/alert.component";

import CustomTextField from "components/custom-input-field/custom-input-field.component";
import ButtonPrimary from "components/button-primary/button-primary.component";

import StorefrontIcon from "@material-ui/icons/Storefront";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import {
  AudionautCoupon,
  AudionautPlanFragmentFragment,
  useGetStripeCouponLazyQuery,
} from "graphql/types-and-hooks";

import { isServerError } from "errors/server.errors";
import { UIError, UIErrorCodes } from "errors/ui.errors";
import { useAppErrorHandler } from "errors/app.errors";

import { isMobileResolution } from "commons/utils/device-info.util";

import SpaceshipIcon from "assets/icons/spaceship-big-icon.png";

import GenericInfoCard from "components/generic-info-card/generic-info-card.component";

import { TrialInfoData } from "../../../public-search/public-search.utils";

import SC from "./payment-confirm.styles";

const numberFormat = (value: any) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

interface PaymentConfirmProps {
  currentPlan: AudionautPlanFragmentFragment;
  billingAddress: any;
  paymentMethod: any;
  onConfirmHandler: any;
  onCancel: any;
  onSetCurrentStep: any;
  show: boolean;
  isPaymentSingle: boolean;
  coupon?: AudionautCoupon;
}

const PaymentConfirm: React.FC<PaymentConfirmProps> = ({
  currentPlan,
  billingAddress,
  paymentMethod,
  onConfirmHandler,
  onCancel,
  onSetCurrentStep,
  show = false,
  isPaymentSingle = false,
  coupon = undefined,
}) => {
  const classes = SC.useStyles();
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [promoCode, setPromoCode] = React.useState("");
  const [newAmount, setNewAmount] = React.useState<null | number>(null);
  const [errorCoupon, setErrorCoupon] = React.useState("");

  const [
    getCoupon,
    { data: couponData, loading: couponLoading, error: couponError },
  ] = useGetStripeCouponLazyQuery({
    fetchPolicy: "no-cache",
  });

  // const errorHandler = useAppErrorHandler(couponError);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handleCuoponChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (errorCoupon) {
      setErrorCoupon("");
      setSnackBarMessage(undefined);
    }
    setPromoCode(value);
  };

  const applyCouponHandler = React.useCallback(async () => {
    try {
      await getCoupon({
        variables: {
          coupon: promoCode,
        },
      });
    } catch (error: any) {
      setSnackBarMessage({
        message: `An error has ocurred while validating the coupon ${promoCode}`,
        severity: "warning",
      });
    }
  }, [getCoupon, promoCode]);

  React.useEffect(() => {
    if (couponData && couponData.getCouponByCode) {
      const {
        getCouponByCode: {
          id,
          stripeCouponId,
          percentage,
          forAudionautPlan,
          validTill,
          maxRedemptions,
        },
      } = couponData;
      const { id: planId, name, priceInCent } = currentPlan;
      const planIdsInCoupon = forAudionautPlan
        ? forAudionautPlan.id === planId
        : false;

      let isCouponExpired = false;
      if (validTill) {
        const currentDate = new Date();
        const couponExpirationDate = new Date(validTill);
        isCouponExpired =
          couponExpirationDate.getTime() < currentDate.getTime();
      }

      const amountWithDiscount =
        (priceInCent ?? 0) - (priceInCent ?? 0) * ((percentage ?? 100) / 100);

      if (
        !isCouponExpired &&
        (!maxRedemptions || maxRedemptions > 0) &&
        planIdsInCoupon
      ) {
        setNewAmount(Math.floor(amountWithDiscount));
        setSnackBarMessage({
          message: "The coupon discount was applied successfully!",
          severity: "success",
        });
      } else {
        setSnackBarMessage({
          message: "The coupon is invalid!",
          severity: "warning",
        });
        setErrorCoupon("Invalid coupon");
      }
    }
  }, [couponData, currentPlan, setNewAmount, setPromoCode, setErrorCoupon]);

  React.useEffect(() => {
    if (couponError) {
      setErrorCoupon("Invalid coupon");
      setSnackBarMessage({
        message: `The coupon is invalid!`,
        severity: "warning",
      });
    }
  }, [couponError, setErrorCoupon]);

  React.useEffect(() => {
    if (coupon) {
      const { stripeCouponId } = coupon;
      setPromoCode(stripeCouponId as string);
      applyCouponHandler();
    }
  }, [coupon, applyCouponHandler]);

  return (
    <Box hidden={!show} className={classes.confirmContainer}>
      {isPaymentSingle ? (
        <>
          <SC.ChatProContainer hidden>
            <SC.PlanName>
              <Typography>{currentPlan.name}</Typography>
            </SC.PlanName>
            <SC.IconPlanContainer>
              <SC.ImagePNG title="spaceship-icon" image={SpaceshipIcon} />
            </SC.IconPlanContainer>
            <SC.PlanAmount className="bigger">
              {numberFormat((newAmount ?? currentPlan.priceInCent ?? 0) / 100)}{" "}
              <SC.AmountCurrency component="span" className="bigger">
                USD/MO
              </SC.AmountCurrency>
            </SC.PlanAmount>
            <SC.ConfirmTexts className="main medium center-text">
              Experience the next level of video search
            </SC.ConfirmTexts>
            <SC.ConfirmBullet>
              <CheckCircleOutlineIcon />
              <SC.ConfirmTexts>
                Get more, better, and faster answers from the world of audio.
              </SC.ConfirmTexts>
            </SC.ConfirmBullet>
          </SC.ChatProContainer>
          <GenericInfoCard data={TrialInfoData[2]} />
        </>
      ) : (
        <>
          <Typography className={classes.sectionTitle} color="primary">
            Plan
          </Typography>
          <Box component="div" className={classes.planItem}>
            <Box className="group-1500">
              <Box className="flex-col">
                <Box className="group-828 flex-row">
                  <Box className="group-854 flex-col">
                    <Box className="rectangle-441">
                      <Typography
                        className="txt-950 flex-hcenter"
                        color="primary"
                      >
                        {currentPlan.name}
                      </Typography>
                    </Box>
                    <SC.AmountContainer>
                      <Typography className="txt-558" color="primary">
                        {numberFormat(
                          (newAmount ?? currentPlan.priceInCent ?? 0) / 100
                        )}{" "}
                        <Typography
                          component="span"
                          className="txt-5583"
                          color="secondary"
                        >
                          USD/MO
                        </Typography>
                      </Typography>
                      {newAmount != null ? (
                        <Typography
                          component="span"
                          className="txt-558 strikethrough"
                        >
                          {numberFormat((currentPlan.priceInCent ?? 0) / 100)}
                        </Typography>
                      ) : null}
                    </SC.AmountContainer>
                  </Box>
                  <Box hidden={isMobileResolution()} className="group-876">
                    <StorefrontIcon color="primary" />
                  </Box>
                </Box>
                <Typography className="txt-574" color="secondary">
                  {currentPlan.description}.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography className={classes.sectionTitle} color="primary">
            Billing address
          </Typography>
          <Tooltip title="Edit Address" aria-label="edit-address">
            <Box
              className={classes.sectionCard}
              onClick={() => {
                onSetCurrentStep(1);
              }}
            >
              <Typography className="cardTitle" color="primary">
                {billingAddress.firstName} {billingAddress.lastName}
              </Typography>
              <Typography className="cardText" color="secondary">
                {billingAddress.address} {billingAddress.city},{" "}
                {billingAddress.state}, {billingAddress.country},{" "}
                {billingAddress.zipCode}
              </Typography>
            </Box>
          </Tooltip>
          <Typography className={classes.sectionTitle} color="primary">
            Payment information
          </Typography>
          <Tooltip title="Edit Payment" aria-label="edit-payment">
            <Box
              className={classes.sectionCard}
              onClick={() => {
                onSetCurrentStep(2);
              }}
            >
              <Typography className="cardTitle" color="primary">
                {paymentMethod.holderName}
              </Typography>
              <Typography className="cardText" color="secondary">
                Mastercard: <b>**** **** **** ****</b>
              </Typography>
            </Box>
          </Tooltip>
          <Typography className={classes.sectionTitle} color="primary">
            Add a promo code
          </Typography>
          <SC.PromoCouponContainer>
            <CustomTextField
              name="cuopon"
              label="Promo Code"
              variant="outlined"
              placeholder="AUDIONAUTCUOPON"
              fullWidth
              required
              onChange={handleCuoponChange}
              helperText={errorCoupon}
              className={errorCoupon ? "errorCoupon" : ""}
            />
            <ButtonPrimary
              disabled={!promoCode || couponLoading}
              onClick={applyCouponHandler}
            >
              <CircularProgress
                hidden={!couponLoading}
                style={{ position: "absolute", height: "30px", width: "30px" }}
              />
              Apply
            </ButtonPrimary>
          </SC.PromoCouponContainer>
        </>
      )}
      <Box className={classes.footerEle}>
        <Button
          className={`${classes.confirmBtn} ${
            isPaymentSingle ? "chatPro" : ""
          }`}
          onClick={() => {
            onConfirmHandler(promoCode);
          }}
        >
          {isPaymentSingle ? "Upgrade Now" : "Confirm"}
        </Button>
        <Button
          hidden={isPaymentSingle}
          className={classes.backBtn}
          onClick={onCancel}
        >
          back
        </Button>
        {!isPaymentSingle ? (
          <Typography color="primary">
            You can cancel anytime via your Account Summary
          </Typography>
        ) : null}
      </Box>
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
    </Box>
  );
};

export default PaymentConfirm;
