/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
/* eslint-disable spaced-comment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from "react";
import merge from "lodash/merge";
import {
  Query,
  Builder,
  Utils as QbUtils,
  Types,
  Settings,
  Operators,
  DateTimeFieldSettings,
} from "react-awesome-query-builder";
// types
import {
  JsonGroup,
  Config,
  ImmutableTree,
  BuilderProps,
} from "react-awesome-query-builder";

// For AntDesign widgets only:
// import AntdConfig from "react-awesome-query-builder/lib/config/antd";
import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import "antd/dist/antd.css"; // or import "react-awesome-query-builder/css/antd.less";
// For Material-UI widgets only:
//import MaterialConfig from "react-awesome-query-builder/lib/config/material";

import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles
import moment from "moment";
import BC from "./behaviors.styles";

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or BasicConfig

const types: Types = {
  ...InitialConfig.types,
  // examples of  overriding
  select: merge(InitialConfig.types.select, {
    widgets: {
      select: {
        widgetProps: {
          hideOperator: false,
        },
      },
    },
  }),
  text: merge(InitialConfig.types.text, {
    widgets: {
      text: {
        widgetProps: {
          hideOperator: false,
        },
      },
    },
  }),
};

const settings: Settings = {
  ...InitialConfig.settings,
  showNot: false,
};

const customOperators: Operators = {
  ...InitialConfig.operators,
  between: {
    ...InitialConfig.operators.between,
    valueLabels: ["Value from", "Value to"],
    textSeparators: ["from", "to"],
  },
};

// You need to provide your own config. See below 'Config format'
const config: Config = {
  ...InitialConfig,
  fields: {
    source: {
      label: "Source",
      type: "multiselect",
      fieldSettings: {
        listValues: {
          YOUTUBE: "Youtube",
          PODCAST: "Apple Podcast",
          TIKTOK: "TikTok",
        },
        allowCustomValues: true,
      },
    },
    type: {
      label: "Type",
      type: "select",
      fieldSettings: {
        listValues: {
          MEDIA: "Media",
          PLAYLIST: "Playlist",
          CHANNEL: "Channel",
        },
      },
    },
    id: {
      label: "Media ID",
      type: "text",
      excludeOperators: ["starts_with", "ends_with", "proximity"],
      mainWidgetProps: {
        valueLabel: "Media ID",
        valuePlaceholder: "Enter unique ID",
      },
    },
    name: {
      label: "Media Name",
      type: "text",
      excludeOperators: ["starts_with", "ends_with", "proximity"],
      mainWidgetProps: {
        valueLabel: "Media Name",
        valuePlaceholder: "Enter the name",
      },
    },
    datetime: {
      label: "Publication date",
      type: "date",
      valueSources: ["value"],
    },
    entities: {
      label: "Entity",
      type: "text",
      excludeOperators: ["starts_with", "ends_with", "proximity"],

      mainWidgetProps: {
        valueLabel: "Entity",
        valuePlaceholder: "e.g., Blockchain",
      },
    },
    sentiments: {
      label: "Sentiments",
      tooltip: "Group of fields",
      type: "!struct",
      subfields: {
        joy: {
          label: "Joy",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
        trust: {
          label: "Trust",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
        fear: {
          label: "Fear",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
        surprise: {
          label: "Surprise",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
        sadness: {
          label: "Sadness",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
        disgust: {
          label: "Disgust",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
        anger: {
          label: "Anger",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
        anticipation: {
          label: "Anticipation",
          type: "number",
          preferWidgets: ["slider", "rangeslider"],
          fieldSettings: {
            min: 0,
            max: 100,
            step: 1,
            marks: {
              0: <strong>0%</strong>,
              100: <strong>100%</strong>,
            },
          },
          //overrides
          widgets: {
            slider: {
              widgetProps: {
                valuePlaceholder: "score",
              },
            },
          },
        },
      },
    },
  },
  types,
  settings,
  operators: customOperators,
};

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };

interface QueryBuilderComponentInterface {
  jsonLogicExist: any;
  updatejsonTree: any;
}

export const QueryBuilderComponent: React.FC<QueryBuilderComponentInterface> =
  ({ jsonLogicExist, updatejsonTree }) => {
    const [state, setState] = useState({
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      config: config,
    });

    const onChange = (immutableTree: ImmutableTree, config: Config) => {
      // Tip: for better performance you can apply `throttle` - see `examples/demo`
      setState({ tree: immutableTree, config: config });

      const jsonTree = QbUtils.getTree(immutableTree);
      const { logic, data, errors } = QbUtils.jsonLogicFormat(
        immutableTree,
        config
      );
      // `jsonTree` can be saved to backend, and later loaded to `queryValue`
      updatejsonTree(logic);
    };

    const setJsonLogicExist = useEffect(() => {
      if (jsonLogicExist) {
        setState({
          tree: QbUtils.checkTree(
            QbUtils.loadFromJsonLogic(jsonLogicExist, config),
            config
          ),
          config: config,
        });
      }
    }, [jsonLogicExist]);

    const renderBuilder = (props: BuilderProps) => (
      <div style={{ width: "100%" }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    );

    return (
      <div>
        <Query
          {...config}
          value={state.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
        {/* <div className="query-builder-result">
                <div>
                    Query string:{" "}
                    <pre>
                        {JSON.stringify(QbUtils.queryString(state.tree, state.config))}
                    </pre>
                </div>
                <div>
                    MongoDb query:{" "}
                    <pre>
                        {JSON.stringify(QbUtils.mongodbFormat(state.tree, state.config))}
                    </pre>
                </div>
                <div>
                    SQL where:{" "}
                    <pre>
                        {JSON.stringify(QbUtils.sqlFormat(state.tree, state.config))}
                    </pre>
                </div>
                <div>
                    JsonLogic:{" "}
                    <pre>
                        {JSON.stringify(QbUtils.jsonLogicFormat(state.tree, state.config))}
                    </pre>
                </div>
            </div> */}
      </div>
    );
  };
