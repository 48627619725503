// Dependencies
import { withStyles, Box } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AutofillField from "components/autofill-field/autofill-field.component";

const drawerWidth = 240;

const SearchBoxFixed = withStyles({
  root: {
    width: "100%",
  },
})(AutofillField) as typeof AutofillField;

const LeftPaneContainer = withStyles({
  root: {
    display: "flex",
  },
})(Box);
const HidePaneIconContainer = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
})(Box);

const DrawerBody = withStyles({
  root: { width: drawerWidth, flexShrink: 0 },
  paper: { width: drawerWidth },
})(Drawer);

const HidePaneIcon = withStyles({
  root: {
    cursor: "pointer",
  },
})(ChevronLeftIcon);

export default {
  SearchBoxFixed,
  LeftPaneContainer,
  DrawerBody,
  HidePaneIconContainer,
  HidePaneIcon,
};
