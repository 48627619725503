import { Checkbox, withStyles, Theme } from "@material-ui/core";

const CustomCheckBox = withStyles((theme: Theme) => ({
  root: {
    "&.Mui-checked": {
      "& svg": {
        color: "#B8CAC6",
      },
    },
    [theme.breakpoints.down(540)]: {
      padding: "3px",
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
  },
}))(Checkbox);

export default CustomCheckBox;
