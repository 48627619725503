import React from "react";
import { TextField, withStyles, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

import YoutubeIcon from "../../assets/icons/YoutubeLogo.png";
import PodcastIcon from "../../assets/icons/ApplePCLogo.png";
import UnknownIcon from "../../assets/icons/DefaultLogo.png";
import VimeoIcon from "../../assets/icons/VimeoLogo.png";
import TiktokIcon from "../../assets/icons/TicktockLogo.png";
import SpotifyIcon from "../../assets/icons/SpotifyLogo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
      [theme.breakpoints.down(540)]: {
        padding: "unset",
        margin: "unset",
      },
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    itemContainer: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      [theme.breakpoints.down(540)]: {
        width: "calc(100vw - 40px)",
      },
    },
    itemActions: {
      display: "flex",
      height: "53px",
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "16px",
      paddingRight: "16px",
      [theme.breakpoints.down(540)]: {
        height: "unset",
        padding: "12px",
      },
    },
    brandIcon: {
      width: "35px",
      marginRight: "10px",
    },
    brandLbl: {
      fontSize: "22px",
      lineHeight: "28px",
      fontWeight: 800,
      flex: "1",
      marginBottom: "0px",
    },
    linkStyle: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #ABABAB",
      borderRadius: "0px",
      "& .MuiInputBase-root": {
        marginLeft: "5px",
      },
      "& .MuiInputBase-root:before": {
        border: "none !important",
      },
      "& .MuiInputBase-root:after": {
        border: "none",
      },
    },
    gray: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#ABABAB",
    },
    elemStyle: {
      marginRight: "30px",
      [theme.breakpoints.down(540)]: {
        margin: "unset",
      },
    },
    purple: {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      color: "#B8CAC6",
      [theme.breakpoints.down(540)]: {
        fontSize: theme.fontGeneralStyles.default.fontSize,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
      },
    },
    hide: {
      display: "none",
    },
    subTableRoot: {
      padding: "0px",
    },
    modalCustom: {
      "& .MuiDialog-paper": {
        width: "100%",
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        color: "#000",
        "& form": {
          padding: "20px",
          paddingTop: "14px",
        },
        "& .title": {
          margin: "0px",
          marginBottom: "20px",
          padding: "0px",
          paddingBottom: "16px",
        },
        "& .MuiDialogContent-root": {
          padding: "0px",
          marginBottom: "65px",
          "& .MuiTextField-root": {
            margin: "0px",
            marginTop: "8px",
            "& filedSet": {
              borderColor: "#C6CACC",
            },
          },
        },
        "& .cancelBtn": {
          border: "2px solid #B8CAC6",
          height: "38px",
          width: "144px !important",
          borderRadius: "116px",
          color: "#B8CAC6",
          "&:hover": {
            color: "#fff",
            background: "#8773B1",
          },
          "& span": {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 600,
            textTransform: "capitalize",
          },
        },
        "& .createBtn": {
          background: "#B8CAC6",
          height: "38px",
          width: "144px !important",
          borderRadius: "116px",
          color: "#FFF",
          "&:hover": {
            background: "#8773B1",
          },
          "& span": {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 600,
            textTransform: "capitalize",
          },
        },
      },
    },
  })
);

const images: any = {
  YoutubeIcon,
  PodcastIcon,
  UnknownIcon,
  VimeoIcon,
  TiktokIcon,
  SpotifyIcon,
};
function getImageByKey(key: string) {
  return images[key];
}

const SourceUrlField = withStyles(() => ({
  root: {
    height: "45px",
    "& div": {
      height: "100%",
    },
  },
}))(TextField);

const AudioInfo = withStyles(() => ({
  root: {
    flex: "1",
    textAlign: "end",
  },
}))(Typography);

const NoResults = withStyles(() => ({
  root: {
    boxShadow: "unset !important",
  },
}))(GenericEmptyMessages);

export default {
  useStyles,
  getImageByKey,
  SourceUrlField,
  NoResults,
  AudioInfo,
};
