/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import {
  Container as ContainerBase,
  ContainerProps,
  withStyles,
  TableContainer as TableContainerBase,
  Table as TableBase,
  TableHead as TableHeadBase,
  TableFooter as TableFooterBase,
  TableRow as TableRowBase,
  TableCell as TableCellBase,
  TableBody as TableBodyBase,
  TableSortLabel as TableSortLabelBase,
  IconButton,
  Grid as GridBase,
  Box as BoxBase,
  BoxProps,
  Checkbox,
  TableCellProps,
} from "@material-ui/core";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

interface CustomBoxProps extends BoxProps {
  pane?: boolean;
}

const TableFooter = withStyles((theme) => ({
  root: {
    "& tr": {
      borderRadius: "10px",
      "& td": {
        border: "none",
      },
    },
  },
}))(TableFooterBase);

const useRightPaneBoxStyles = makeStyles(() => ({
  root: {
    width: (props: CustomBoxProps) =>
      props.pane ? "calc(100% - 23rem)" : "100%",
  },
}));

interface CustomContainerProps extends ContainerProps {
  maxHeight?: number;
}

const useContainerStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowX: "auto",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
    width: "100% !important",
    // padding: "30px",
    padding: "unset",
    [theme.breakpoints.down(540)]: {
      width: "calc(100% - 40px) !important",
      padding: "unset",
      paddingTop: "20px",
      boxShadow: "0px 24px 25px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      borderRadius: "10px",
    },
  },
}));

const Container = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);
    return <ContainerBase className={classes.root} {...muiProps} ref={ref} />;
  }
);

const RightPaneBox = (props: CustomBoxProps): JSX.Element => {
  const { pane = false, ...muiProps } = props;
  const classes = useRightPaneBoxStyles(props);

  return <BoxBase className={classes.root} {...muiProps} />;
};

const useLeftPaneBoxStyles = makeStyles(() => ({
  root: {
    width: "23rem",
    minWidth: "23rem",
    overflowY: "auto",
    display: (props: CustomBoxProps) => (props.pane ? "inherit" : "none"),
    filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1))",
  },
}));

const LeftPaneBox = (props: CustomBoxProps): JSX.Element => {
  const { pane = false, ...muiProps } = props;
  const classes = useLeftPaneBoxStyles(props);

  return <BoxBase className={classes.root} {...muiProps} />;
};

const TopPaneBox = withStyles((theme) => ({
  root: {
    // padding: "0 30px",
  },
}))(BoxBase) as typeof BoxBase;

const TableContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.down(540)]: {
      height: "100%",
    },
  },
}))(TableContainerBase);

const Table = withStyles((theme) => ({
  root: {
    background: theme.generalStylesContainer.layers.content.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
    tableLayout: "auto",
    // padding: "20px 16px",
    // boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    marginRight: "22px",
    "&:last-child": {
      marginRight: "unset !important",
    },
    "& p": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
}))(TableBase);

const Grid = GridBase;

const Box = BoxBase;

const Resizer = withStyles(() => ({
  root: {
    minWidth: ".5rem !important",
    width: ".5rem !important",
    cursor: "ew-resize !important",
    overflowX: "auto",
    backgroundColor: "transparent",
    padding: "0",
    flex: "0 0 auto !important",
  },
}))(BoxBase);

const useTableStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: 0,
    height: "100%",
  },
  tableContainer: {
    overflowX: "auto",
  },
}));

const TableHead = withStyles((theme) => ({
  root: {
    top: 0,
    position: "sticky",
    "& tr": {
      borderRadius: "10px",
    },
  },
}))(TableHeadBase);

const TableBody = TableBodyBase;

const TableRow = withStyles((theme) => ({
  root: {
    // background: "#FFF",
    // "&:nth-of-type(even)": {
    //   backgroundColor: theme.table.tableRow.evenRowBackgroundColor,
    // },
  },
}))(TableRowBase);

const TableCell = withStyles((theme: Theme) => ({
  root: {
    overflow: "hidden",
    cursor: "default !important",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "0.4rem",
    background: "transparent",
    "& form": {
      borderRadius: "0px",
    },
    "&:first-child": {
      paddingLeft: "30px",
    },
    "&:last-child": {
      paddingRight: "30px",
    },
  },
  body: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    border: "none",
    color: theme.palette.secondary.main,
    "& a": {
      textDecoration: theme.table.link.textDecoration,
      color: theme.table.link.color,
    },
    [theme.breakpoints.down(540)]: {
      minWidth: "40px",
      padding: "10px 16px",
      "& svg": {
        width: "20px",
        height: "20px",
      },
      "&.collapsable-icon": {
        padding: "unset",
        maxWidth: "40px !important",
        "& button": {
          padding: "10px",
        },
      },
    },
  },
  head: {
    fontWeight: theme.table.head.fontWeight,
    fontSize: theme.fontGeneralStyles.default.fontSize,
    lineHeight: theme.fontGeneralStyles.default.lineHeight,
    position: "relative",
    [theme.breakpoints.down(540)]: {
      minWidth: "40px",
      padding: "10px 16px",
      fontSize: theme.fontGeneralStyles.default.fontSize,
      lineHeight: theme.fontGeneralStyles.default.lineHeight,
    },
  },
  footer: {
    fontWeight: theme.table.head.fontWeight,
    fontSize: theme.table.head.fontSize,
  },
}))(TableCellBase);

const TableCellSticky = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      position: "sticky !important",
      left: "0px",
      zIndex: "11",
      background: "#FFF",
      "&.lastCell": {
        maxWidth: "120px",
        left: "40px",
        borderRight: "1px solid #E1E1E1",
        boxShadow: "7px 0 5px -4px rgba(0,0,0,0.1)",
      },
      "&.firstCell": {
        left: "0px !important",
        maxWidth: "40px",
        minWidth: "unset !important",
        width: "40px !important",
      },
      // "&.headTitle": {
      //   "& p": {
      //     fontSize: theme.fontGeneralStyles.default.fontSize,
      //     lineHeight: theme.fontGeneralStyles.default.lineHeight,
      //   },
      // },
      // "&.cellText": {
      //   "& p": {
      //     fontSize: theme.fontGeneralStyles.xSmall.fontSize,
      //     lineHeight: theme.fontGeneralStyles.xSmall.lineHeight,
      //   },
      // },
    },
  },
}))(TableCell);

interface TableCellHeaderProps extends BoxProps {
  align?: TableCellProps["align"];
}

const alignToJustifyContentMap: Record<
  NonNullable<TableCellProps["align"]>,
  React.CSSProperties["justifyContent"]
> = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  justify: "normal",
  inherit: "inherit",
};

const useTableCellHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: ({ align }: TableCellHeaderProps) =>
      align ? alignToJustifyContentMap[align] : "normal",
    "& span": {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      whiteSpace: "normal",
      width: "auto !important",
      minWidth: "auto !important",
      color: theme.palette.primary.main,
    },
  },
}));

const TableCellHeader = (props: TableCellHeaderProps): JSX.Element => {
  const { align, ...muiProps } = props;
  const classes = useTableCellHeaderStyles(props);

  return <BoxBase className={classes.root} {...muiProps} />;
};

const TableSortLabel = withStyles((theme) => ({
  root: {
    color: `${theme.table.icon.default.color} !important`,
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      fontSize: theme.spacing(3),
      padding: "0.1rem",
    },
  },
  icon: {
    fontSize: theme.spacing(3),
    cursor: "pointer",
    color: `${theme.palette.primary.main} !important`,
  },
}))(TableSortLabelBase);

const ExpanderIconButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.8),
  },
}))(IconButton);

const VisibilityIcon = withStyles(() => ({
  root: {
    paddingTop: 0,
  },
}))(Checkbox);

const NoResults = withStyles(() => ({
  root: {
    boxShadow: "unset",
  },
}))(GenericEmptyMessages);

export default {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TableCellSticky,
  TableBody,
  TableCellHeader,
  TableSortLabel,
  ExpanderIconButton,
  Grid,
  Box,
  LeftPaneBox,
  TopPaneBox,
  RightPaneBox,
  useTableStyles,
  Resizer,
  VisibilityIcon,
  NoResults,
};
