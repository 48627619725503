/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { TB_STORES } = ActionTypes;

const INITIAL_STATE = {
  tbStores: [],
  isReceivingTbStores: false,
  error: null
};

const queryTbStoresReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TB_STORES.FETCH.START:
      return {
        ...state,
        isReceivingTbStores: true,
        error: null
      };
    case TB_STORES.FETCH.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        tbStores: data && data.GetTbStores,
        isReceivingTbStores: false
      };
    }
    case TB_STORES.FETCH.FAILURE:
      return {
        ...state,
        isReceivingTbStores: false,
        error: action.payload
      };
    // Set TB Stores Data
    case TB_STORES.SET.DATA:
      return {
        ...state,
        tbStores: action.payload
      };
    default: return state;
  }
};

export default queryTbStoresReducerFn;
