import { Box, CardMedia, Chip, Theme, makeStyles } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import Tab from "@material-ui/core/Tab";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { createStyles, withStyles } from "@material-ui/styles";

import HelpIcon from "@material-ui/icons/Help";

import GeneralSearch from "components/general-search/general-search.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import IconButtonGeneric from "components/icon-button/icon-button.component";
import ButtonPrimary from "components/button-primary/button-primary.component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tutoActived: {
      zIndex: 3,
    },
    searchButton: {
      position: "absolute",
      borderRadius: "50% !important",
      right: "-52px",
      boxShadow: "none",
    },
    optionActive: {
      background: "#B8CAC6 !important",
      cursor: "unset !important",
      padding: "0px 20px !important",
      "& span": {
        color: "#FFF !important",
      },
      [theme.breakpoints.down(540)]: {
        padding: "0px 10px !important",
      },
    },
    noResults: {
      justifyContent: "end",
      "& .switch": {
        top: "20px",
      },
    },
    customDialog: {
      "& .MuiPaper-root": {
        background: "transparent",
        boxShadow: "unset",
      },
    },
  })
);

const MainContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100% !important",
  },
}))(Box);

const OpenSearchContainer = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    [theme.breakpoints.down(540)]: {
      height: "100% !important",
    },
  },
}))(Box);

const Container = withStyles(() => ({
  root: {
    // maxHeight: "calc(100% - 80px)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "1",
    "& Form": {
      // position: "absolute",
      width: "100%",
      maxWidth: "1201px",
      bottom: "0px",
      "& div": {
        width: "100%",
        // background: "#E0DFE3",
        // boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
        border: "none",
        padding: "22px",
        borderRadius: "7px",
      },
    },
  },
}))(Box);

const OpenSearchDescription = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "1",
    paddingTop: "30px",
    "& .first-description": {
      marginBottom: "40px",
      [theme.breakpoints.down(540)]: {
        marginTop: "50px",
        "& .mainChatImage": {
          width: "70%",
        },
      },
      "& .mainChatImage": {
        width: "60%",
        margin: "auto",
      },
    },
    "& .contentTitle": {
      justifyContent: "center",
      [theme.breakpoints.down(540)]: {
        flexDirection: "column",
        marginBottom: "10px",
      },
    },
    "& .title": {
      marginBottom: "33px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "40px",
        marginBottom: "20px",
      },
      [theme.breakpoints.down(540)]: {
        fontSize: "35px",
        marginBottom: "10px",
      },
    },
    "& .brand": {
      color: theme.generalStylesContainer.chat.brand,
    },
    "& .subTitle": {
      color: theme.generalStylesContainer.chat.color,
      fontSize: "40px",
      fontWeight: "800",
      [theme.breakpoints.down("lg")]: {
        fontSize: "30px",
      },
      [theme.breakpoints.down(540)]: {
        fontSize: "23px",
      },
    },
    "& .description-1": {
      fontWeight: 500,
      [theme.breakpoints.down("lg")]: {
        fontSize: "20px",
      },
    },
    "& .description-2": {
      marginBottom: "66px",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "35px",
        fontSize: "20px",
      },
    },
    "& .description-3": {
      fontWeight: 500,
      padding: "59px 158px",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
        padding: "30px 138px",
      },
      [theme.breakpoints.down(540)]: {
        padding: "30px 20px",
      },
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px 20px 10px",
      overflowY: "auto",
    },
  },
}))(Box);

const OpenSearchImage = withStyles((theme: Theme) => ({
  root: {
    width: "276px",
    height: "276px",
    margin: "auto",
    marginBottom: "75px",
    [theme.breakpoints.down("xl")]: {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "40px",
      width: "150px",
      height: "150px",
    },
    [theme.breakpoints.down(540)]: {
      marginBottom: "40px",
      width: "100px",
      height: "100px",
    },
  },
}))(CardMedia);

const ResultContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: "1",
    maxWidth: "1352px",
    width: "100%",
    overflowY: "auto",
    [theme.breakpoints.down(540)]: {
      padding: "10px",
      paddingBottom: "0px",
    },
  },
}))(Box);

const NoResults = withStyles(() => ({
  root: {
    background: "transparent !important",
    boxShadow: "unset",
    margin: "auto",
  },
}))(GenericEmptyMessages);

const InputSearch = withStyles((theme: Theme) => ({
  root: {
    height: "auto",
    flex: "unset",
    paddingBottom: "30px",
    paddingRight: "72px",
    "& div": {
      // background: "#FFF !important",
      padding: "6px 16px 6px 12px !important",
      // boxShadow: "0px 2px 10px #B8CAC6 !important",
      borderRadius: "20px !important",
    },
    [theme.breakpoints.down(540)]: {
      padding: "5px 60px 20px 20px",
    },
  },
}))(GeneralSearch);

const ResultsContent = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "21px",
    overflowY: "auto",
    padding: "20px 40px 20px 40px",
    "& .full-width": {
      width: "100%",
    },
    [theme.breakpoints.down(540)]: {
      padding: "unset",
      paddingBottom: "10px",
    },
  },
}))(Box);

const StartTutoIcon = withStyles((theme: Theme) => ({
  root: {
    color: theme.button.primary.backgroundColor,
  },
}))(HelpIcon);

const ScrollTopRefElem = withStyles(() => ({
  root: {
    marginTop: "-20px",
  },
}))(Box);

const ScrollToTopElem = withStyles(() => ({
  root: {
    position: "absolute",
    zIndex: 2,
    right: "20px",
    bottom: "100px",
  },
}))(Box);

const IconButton = withStyles((theme: Theme) => ({
  root: {
    margin: "unset !important",
    padding: "0px 12px 0px 0px",
  },
}))(IconButtonGeneric);

const InsightContainer = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "371px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "24px 12px",
    borderLeft: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    backgroundColor:
      theme.generalStylesContainer.layers.content.backgroundColor,
    color: theme.generalStylesContainer.color,
    gap: "32px",
  },
}))(Box);

const InsightTabsContainer = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
  },
}))(TabContext);

const InsightTabs = withStyles((theme: Theme) => ({
  root: {
    widht: "100%",
    height: "34px",
    minHeight: "34px",
    borderRadius: "9px",
    border: `1px solid ${theme.palette.primary.main}`,
    "& .Mui-selected": {
      background: theme.button.primary.backgroundColor,
      color: `${theme.button.primary.color} !important`,
    },
  },
}))(TabList);

const InsightTab = withStyles((theme: Theme) => ({
  root: {
    minHeight: "34px",
    height: "34px",
  },
}))(Tab);

const InsightTabContent = withStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 130px)",
    flex: "1",
    padding: "unset",
    overflowY: "auto",
  },
}))(TabPanel);

const OpenInsightsButton = withStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    right: "50px",
    top: "50px",
    [theme.breakpoints.down(540)]: {
      top: "70px",
      right: "20px",
    },
  },
}))(ButtonPrimary);

const CloseInsightsButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.generalStylesContainer.color,
  },
}))(IconButtonGeneric);

const AdSenseContainer = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    background: theme.generalStylesContainer.layers.content.backgroundColor,
    padding: "20px 0px",
  },
}))(Box);

export default {
  useStyles,
  MainContainer,
  OpenSearchContainer,
  Container,
  OpenSearchDescription,
  OpenSearchImage,
  ResultContainer,
  NoResults,
  InputSearch,
  ResultsContent,
  StartTutoIcon,
  ScrollToTopElem,
  ScrollTopRefElem,
  IconButton,
  InsightContainer,
  InsightTabsContainer,
  InsightTabs,
  InsightTab,
  InsightTabContent,
  CloseInsightsButton,
  OpenInsightsButton,
  AdSenseContainer,
};
