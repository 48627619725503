import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Popover,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";

import { getFormattedDate } from "commons/utils/date-format";
import { averageNumber } from "commons/utils/number-format";

import CustomCheckBox from "components/custom-checkbox/custom-checkbox.component";
import DeleteIconButton from "components/delete-icon-button/delete-icon-button.component";

import { isMobileResolution } from "commons/utils/device-info.util";

import ASSubitemStyles from "./audio-source-subitem.styles";

const secToTime: any = (duration: any) => {
  let seconds: any = Math.floor(duration % 60);
  let minutes: any = Math.floor((duration / 60) % 60);
  let hours: any = Math.floor((duration / (60 * 60)) % 24);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

interface AudioSourceSubitemProps {
  data: any;
  selected: string[];
  selectItem: any;
  deleteItem: any;
  id: string;
  even: boolean;
  type: string;
  avatar: boolean;
  itemDeleted: boolean;
}

const AudioSourceSubitem: React.FC<AudioSourceSubitemProps> = ({
  data: itemData,
  selected,
  selectItem,
  deleteItem,
  id,
  even,
  type,
  avatar = false,
  itemDeleted = false,
}) => {
  const classes = ASSubitemStyles.useStyles();
  let currentStatus = itemData.status ?? "NO DATA";

  const [checkboxStatus, setCheckboxStatus] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleting, setDeleting] = React.useState(itemDeleted);

  if (currentStatus === "DOWNLOADED" || currentStatus === "TRANSCRIBED") {
    currentStatus = "PROCESSING";
  } else if (
    currentStatus === "NO FILES" ||
    currentStatus === "NO TRANSCRIPTION" ||
    currentStatus === "NO DATA" ||
    currentStatus === "ERROR-DOWNLOADING"
  ) {
    currentStatus = "ERROR";
  }

  const [schedule, setSchedule] = useState(() => {
    if (itemData && itemData.scheduled && itemData.scheduled > 0) {
      return new Date(itemData.scheduled);
    }
    return -1;
  });

  const [selectedDate, setSelectedData] = useState(() => {
    if (itemData && itemData.scheduled && itemData.scheduled > 0) {
      return new Date(itemData.scheduled);
    }
    return new Date();
  });

  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  // const [openCalendar, setOpenCalendar] = React.useState<boolean>(false);
  const [autoOkEnabled, setAutoOk] = React.useState<boolean>(true);

  const open = Boolean(anchorEl);
  const idPopOver = open ? "calendar-popover" : undefined;

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    // setTimeout(() => {
    //   setOpenCalendar(true);
    // }, 0);
  };

  const handleClose = () => {
    // setOpenCalendar(false);
    setAnchorEl(null);
  };

  const handleDateChange = (response: any) => {
    const newDate = new Date(response);
    setSchedule(newDate.getTime());
    setSelectedData(newDate);
  };

  const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = event.target.value;
    setCheckboxStatus(event.target.checked);
    selectItem(event.target.checked, itemId);
  };

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  React.useEffect(() => {
    const isSelected = selected.indexOf(itemData.id as string) !== -1;

    setCheckboxStatus(isSelected);
  }, [selected, itemData]);

  React.useEffect(() => {
    setDeleting(itemDeleted);
  }, [itemDeleted]);

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={id}
      className={clsx({
        [classes.rowEven]: even,
      })}
    >
      <ASSubitemStyles.TableCellSticky
        className={`checkbox ${classes.cellBodyStyle} ${even ? "rowEven" : ""}`}
        padding="checkbox"
      >
        <CustomCheckBox
          checked={checkboxStatus}
          onChange={handleSelectItem}
          value={itemData.id}
          inputProps={{ "aria-labelledby": id }}
        />
      </ASSubitemStyles.TableCellSticky>
      <ASSubitemStyles.TableCellSticky
        hidden={!avatar}
        id={id}
        className={`stickyCell ${classes.cellBodyStyle} ${classes.nameCell} ${
          classes.nameAvatar
        } ${even ? "rowEven" : ""}`}
        colSpan={1}
      >
        <ASSubitemStyles.AvatarContainer>
          {itemData && itemData.image && itemData.image.length === 0 ? (
            <Avatar className={`${classes.noimg} ${classes.cellAvatar}`}>
              {itemData.videoName
                ? itemData.videoName.split("")[0]
                : itemData.name.split("")[0]}
            </Avatar>
          ) : (
            <Avatar className={classes.cellAvatar} src={itemData.image} />
          )}
          {!isMobileResolution() ? (
            <Box component="div" className={classes.ellipsisText}>
              {itemData.videoName ? itemData.videoName : itemData.name}
            </Box>
          ) : (
            <Link
              href={itemData.url}
              target="_blank"
              className={classes.linkInCell}
            >
              <Box component="div" className={classes.ellipsisText}>
                {itemData.videoName ? itemData.videoName : itemData.name}
              </Box>
            </Link>
          )}
        </ASSubitemStyles.AvatarContainer>
      </ASSubitemStyles.TableCellSticky>
      <ASSubitemStyles.TableCellSticky
        hidden={avatar}
        id={id}
        className={`stickyCell ${classes.cellBodyStyle} ${classes.nameCell} ${
          even ? "rowEven" : ""
        }`}
      >
        <Box component="div" className={classes.ellipsisText}>
          {itemData.videoName ? itemData.videoName : itemData.name}
        </Box>
      </ASSubitemStyles.TableCellSticky>
      <TableCell
        hidden={itemData.category === "HASHTAG" || isMobileResolution()}
        align="left"
        className={`${classes.cellBodyStyle} centerText`}
      >
        <Link
          href={itemData.url}
          target="_blank"
          className={classes.linkInCell}
        >
          <Box component="div" className={classes.ellipsisText}>
            {itemData.url}
          </Box>
        </Link>
      </TableCell>
      <TableCell
        hidden={itemData.category !== "HASHTAG"}
        className={`${classes.cellBodyStyle} centerText`}
      >
        {getFormattedDate(itemData.created)}
      </TableCell>
      <TableCell className={`${classes.cellBodyStyle} centerText`}>
        {itemData.totalMedias || itemData.mediasCount
          ? `${itemData.totalMedias ?? itemData.mediasCount} Videos`
          : secToTime(itemData.duration)}
      </TableCell>
      <TableCell
        hidden={itemData.category === "HASHTAG"}
        className={`${classes.cellBodyStyle} centerText`}
      >
        {itemData.subscribers
          ? `${averageNumber(Number(itemData.subscribers))} Subscribers`
          : getFormattedDate(itemData.dateCreation ?? itemData.uploaded)}
      </TableCell>
      <TableCell
        hidden={itemData.category === "HASHTAG"}
        className={`${classes.cellBodyStyle} centerText`}
      >
        {type === "PLAYLIST" || type === "MEDIA"
          ? `${averageNumber(Number(itemData.views)) ?? 0} Views`
          : getFormattedDate(itemData.updated)}
      </TableCell>
      <TableCell
        className={`${classes.cellBodyStyle} ${classes.cellActions} centerText`}
      >
        <DeleteIconButton
          itemId={itemData.id}
          itemName={itemData.videoName ?? itemData.name}
          type={type}
          deleteItem={deleteItem}
          itemDeleted={itemDeleted}
        />
        <Popover
          id={idPopOver}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.popOverDatePicker}
        >
          {/* <ThemeProvider theme={SC.calendarOverridesTheme}> */}
          <DateTimePicker
            format="MM/dd/yyyy"
            variant="static"
            label="Schedule"
            value={selectedDate}
            onChange={handleDateChange as any}
            autoOk
            hideTabs={false}
          />
          <Box className={classes.actionsCalendar}>
            <IconButton component="button" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* <Box className={classes.actionsCalendar}>
            <Box className="containerBtns">
              <Button>AM</Button>
              <Button>PM</Button>
            </Box>zz
            <IconButton component="button" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box> */}
          {/* </ThemeProvider> */}
        </Popover>
      </TableCell>
      <TableCell
        align="center"
        className={`${classes.cellBodyStyle} centerText`}
      >
        <Button className={`${classes.status} ${currentStatus}`}>
          {itemData.status
            ? itemData.status.charAt(0) + itemData.status.slice(1).toLowerCase()
            : "NO DATA"}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AudioSourceSubitem;
