import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  getBehaviors?: Maybe<GetBehaviors>;
  getResults?: Maybe<Array<Maybe<Result>>>;
  getNewResults?: Maybe<Array<Maybe<NewResult>>>;
};


export type QueryGetBehaviorsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  behaviorId?: Maybe<Scalars['String']>;
};


export type QueryGetResultsArgs = {
  behaviorId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetNewResultsArgs = {
  behaviorId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GetBehaviors = {
  __typename?: 'GetBehaviors';
  behaviorsCount?: Maybe<Scalars['Int']>;
  behaviors?: Maybe<Array<Maybe<Behavior>>>;
};

export type Behavior = Node & {
  __typename?: 'Behavior';
  /** The ID of the object. */
  id: Scalars['ID'];
  behaviorId?: Maybe<Scalars['String']>;
  behaviorName?: Maybe<Scalars['String']>;
  mediaResults?: Maybe<Scalars['Int']>;
  newMediaResults?: Maybe<Scalars['Int']>;
  positiveSentimentRate?: Maybe<Scalars['Float']>;
  negativeSentimentRate?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Int']>;
  json?: Maybe<Scalars['String']>;
  tree?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  results?: Maybe<ResultConnection>;
  newResults?: Maybe<NewResultConnection>;
};


export type BehaviorResultsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type BehaviorNewResultsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};


export type ResultConnection = {
  __typename?: 'ResultConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ResultEdge>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `Result` and its cursor. */
export type ResultEdge = {
  __typename?: 'ResultEdge';
  /** The item at the end of the edge */
  node?: Maybe<Result>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Result = Node & {
  __typename?: 'Result';
  /** The ID of the object. */
  id: Scalars['ID'];
  mediaTitle?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  positiveSentimentRate?: Maybe<Scalars['Float']>;
  negativeSentimentRate?: Maybe<Scalars['Float']>;
  sentences?: Maybe<Scalars['Int']>;
  entities?: Maybe<Scalars['Int']>;
  joyScore?: Maybe<Scalars['Float']>;
  trustScore?: Maybe<Scalars['Float']>;
  surpriseScore?: Maybe<Scalars['Float']>;
  anticipationScore?: Maybe<Scalars['Float']>;
  sadnessScore?: Maybe<Scalars['Float']>;
  fearScore?: Maybe<Scalars['Float']>;
  angerScore?: Maybe<Scalars['Float']>;
  disgustScore?: Maybe<Scalars['Float']>;
  behaviorId?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
};

export type NewResultConnection = {
  __typename?: 'NewResultConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NewResultEdge>>;
};

/** A Relay edge containing a `NewResult` and its cursor. */
export type NewResultEdge = {
  __typename?: 'NewResultEdge';
  /** The item at the end of the edge */
  node?: Maybe<NewResult>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type NewResult = Node & {
  __typename?: 'NewResult';
  /** The ID of the object. */
  id: Scalars['ID'];
  mediaTitle?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  sentimentScore?: Maybe<Scalars['Int']>;
  sentences?: Maybe<Scalars['Int']>;
  entities?: Maybe<Scalars['Int']>;
  joyScore?: Maybe<Scalars['Float']>;
  trustScore?: Maybe<Scalars['Float']>;
  surpriseScore?: Maybe<Scalars['Float']>;
  anticipationScore?: Maybe<Scalars['Float']>;
  sadnessScore?: Maybe<Scalars['Float']>;
  fearScore?: Maybe<Scalars['Float']>;
  angerScore?: Maybe<Scalars['Float']>;
  disgustScore?: Maybe<Scalars['Float']>;
  behaviorId?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBehavior?: Maybe<AddBehavior>;
  editBehavior?: Maybe<EditBehavior>;
  deleteBehavior?: Maybe<DeleteBehavior>;
};


export type MutationAddBehaviorArgs = {
  behaviorName: Scalars['String'];
  jsonLogic: Scalars['String'];
  tree: Scalars['String'];
};


export type MutationEditBehaviorArgs = {
  behaviorId: Scalars['String'];
  behaviorName?: Maybe<Scalars['String']>;
  jsonLogic: Scalars['String'];
  tree: Scalars['String'];
};


export type MutationDeleteBehaviorArgs = {
  behaviorId: Scalars['String'];
};

export type AddBehavior = {
  __typename?: 'AddBehavior';
  response?: Maybe<AddBehaviorResponse>;
};

export type AddBehaviorResponse = Behavior | BehaviorQueryMissing | BehaviorExists;

export type BehaviorQueryMissing = {
  __typename?: 'BehaviorQueryMissing';
  message?: Maybe<Scalars['String']>;
};

export type BehaviorExists = {
  __typename?: 'BehaviorExists';
  message?: Maybe<Scalars['String']>;
};

export type EditBehavior = {
  __typename?: 'EditBehavior';
  response?: Maybe<UpdateBehaviorResponse>;
};

export type UpdateBehaviorResponse = Behavior | BehaviorNotFound | BehaviorExists;

export type BehaviorNotFound = {
  __typename?: 'BehaviorNotFound';
  message?: Maybe<Scalars['String']>;
};

export type DeleteBehavior = {
  __typename?: 'DeleteBehavior';
  response?: Maybe<DeleteBehaviorResponse>;
};

export type DeleteBehaviorResponse = BehaviorDeleted | BehaviorNotFound | BehaviorResponseError;

export type BehaviorDeleted = {
  __typename?: 'BehaviorDeleted';
  message?: Maybe<Scalars['String']>;
};

export type BehaviorResponseError = {
  __typename?: 'BehaviorResponseError';
  message?: Maybe<Scalars['String']>;
};

export type BehaviorNewMediaResultsFragment = (
  { __typename?: 'NewResult' }
  & Pick<NewResult, 'id' | 'mediaTitle' | 'mediaId' | 'mediaUrl' | 'creationDate' | 'source' | 'duration' | 'sentimentScore' | 'sentences' | 'entities' | 'joyScore' | 'trustScore' | 'surpriseScore' | 'anticipationScore' | 'sadnessScore' | 'fearScore' | 'angerScore' | 'disgustScore' | 'behaviorId' | 'created'>
);

export type BehaviorMediaResultsFragment = (
  { __typename?: 'Result' }
  & Pick<Result, 'id' | 'mediaTitle' | 'mediaId' | 'mediaUrl' | 'creationDate' | 'source' | 'duration' | 'positiveSentimentRate' | 'negativeSentimentRate' | 'sentences' | 'entities' | 'joyScore' | 'trustScore' | 'surpriseScore' | 'anticipationScore' | 'sadnessScore' | 'fearScore' | 'angerScore' | 'disgustScore' | 'behaviorId' | 'created'>
);

export type BehaviorResultsFragment = (
  { __typename?: 'Behavior' }
  & Pick<Behavior, 'id' | 'behaviorId' | 'behaviorName' | 'mediaResults' | 'newMediaResults' | 'positiveSentimentRate' | 'negativeSentimentRate' | 'duration' | 'json' | 'tree' | 'userId' | 'created' | 'updated'>
);

export type GetBehaviorsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBehaviorsCountQuery = (
  { __typename?: 'Query' }
  & { getBehaviors?: Maybe<(
    { __typename?: 'GetBehaviors' }
    & Pick<GetBehaviors, 'behaviorsCount'>
  )> }
);

export type GetMediasResultsTotalQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetMediasResultsTotalQuery = (
  { __typename?: 'Query' }
  & { getBehaviors?: Maybe<(
    { __typename?: 'GetBehaviors' }
    & { behaviors?: Maybe<Array<Maybe<(
      { __typename?: 'Behavior' }
      & Pick<Behavior, 'mediaResults' | 'newMediaResults'>
    )>>> }
  )> }
);

export type GetBehaviorResultsQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
}>;


export type GetBehaviorResultsQuery = (
  { __typename?: 'Query' }
  & { getBehaviors?: Maybe<(
    { __typename?: 'GetBehaviors' }
    & { behaviors?: Maybe<Array<Maybe<(
      { __typename?: 'Behavior' }
      & BehaviorResultsFragment
    )>>> }
  )> }
);

export type GetResultsByBehaviorIdQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
}>;


export type GetResultsByBehaviorIdQuery = (
  { __typename?: 'Query' }
  & { getResults?: Maybe<Array<Maybe<(
    { __typename?: 'Result' }
    & BehaviorMediaResultsFragment
  )>>> }
);

export type GetNewResultsByBehaviorIdQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
}>;


export type GetNewResultsByBehaviorIdQuery = (
  { __typename?: 'Query' }
  & { getNewResults?: Maybe<Array<Maybe<(
    { __typename?: 'NewResult' }
    & BehaviorNewMediaResultsFragment
  )>>> }
);

export type AddBehaviorQueryMutationVariables = Exact<{
  behaviorName: Scalars['String'];
  behaviorQuery: Scalars['String'];
}>;


export type AddBehaviorQueryMutation = (
  { __typename?: 'Mutation' }
  & { addBehavior?: Maybe<(
    { __typename?: 'AddBehavior' }
    & { response?: Maybe<(
      { __typename: 'Behavior' }
      & BehaviorResultsFragment
    ) | (
      { __typename: 'BehaviorQueryMissing' }
      & Pick<BehaviorQueryMissing, 'message'>
    ) | (
      { __typename: 'BehaviorExists' }
      & Pick<BehaviorExists, 'message'>
    )> }
  )> }
);

export type UpdateBehaviorQueryMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  query: Scalars['String'];
}>;


export type UpdateBehaviorQueryMutation = (
  { __typename?: 'Mutation' }
  & { editBehavior?: Maybe<(
    { __typename?: 'EditBehavior' }
    & { response?: Maybe<(
      { __typename: 'Behavior' }
      & BehaviorResultsFragment
    ) | (
      { __typename: 'BehaviorNotFound' }
      & Pick<BehaviorNotFound, 'message'>
    ) | (
      { __typename: 'BehaviorExists' }
      & Pick<BehaviorExists, 'message'>
    )> }
  )> }
);

export type DeleteBehaviorQueryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteBehaviorQueryMutation = (
  { __typename?: 'Mutation' }
  & { deleteBehavior?: Maybe<(
    { __typename?: 'DeleteBehavior' }
    & { response?: Maybe<(
      { __typename: 'BehaviorDeleted' }
      & Pick<BehaviorDeleted, 'message'>
    ) | (
      { __typename: 'BehaviorNotFound' }
      & Pick<BehaviorNotFound, 'message'>
    ) | { __typename: 'BehaviorResponseError' }> }
  )> }
);

export const BehaviorNewMediaResultsFragmentDoc = gql`
    fragment BehaviorNewMediaResults on NewResult {
  id
  mediaTitle
  mediaId
  mediaUrl
  creationDate
  source
  duration
  sentimentScore
  sentences
  entities
  joyScore
  trustScore
  surpriseScore
  anticipationScore
  sadnessScore
  fearScore
  angerScore
  disgustScore
  behaviorId
  created
}
    `;
export const BehaviorMediaResultsFragmentDoc = gql`
    fragment BehaviorMediaResults on Result {
  id
  mediaTitle
  mediaId
  mediaUrl
  creationDate
  source
  duration
  positiveSentimentRate
  negativeSentimentRate
  sentences
  entities
  joyScore
  trustScore
  surpriseScore
  anticipationScore
  sadnessScore
  fearScore
  angerScore
  disgustScore
  behaviorId
  created
}
    `;
export const BehaviorResultsFragmentDoc = gql`
    fragment BehaviorResults on Behavior {
  id
  behaviorId
  behaviorName
  mediaResults
  newMediaResults
  positiveSentimentRate
  negativeSentimentRate
  duration
  json
  tree
  userId
  created
  updated
}
    `;
export const GetBehaviorsCountDocument = gql`
    query getBehaviorsCount {
  getBehaviors {
    behaviorsCount
  }
}
    `;

/**
 * __useGetBehaviorsCountQuery__
 *
 * To run a query within a React component, call `useGetBehaviorsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBehaviorsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBehaviorsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBehaviorsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetBehaviorsCountQuery, GetBehaviorsCountQueryVariables>) {
        return Apollo.useQuery<GetBehaviorsCountQuery, GetBehaviorsCountQueryVariables>(GetBehaviorsCountDocument, baseOptions);
      }
export function useGetBehaviorsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBehaviorsCountQuery, GetBehaviorsCountQueryVariables>) {
          return Apollo.useLazyQuery<GetBehaviorsCountQuery, GetBehaviorsCountQueryVariables>(GetBehaviorsCountDocument, baseOptions);
        }
export type GetBehaviorsCountQueryHookResult = ReturnType<typeof useGetBehaviorsCountQuery>;
export type GetBehaviorsCountLazyQueryHookResult = ReturnType<typeof useGetBehaviorsCountLazyQuery>;
export type GetBehaviorsCountQueryResult = Apollo.QueryResult<GetBehaviorsCountQuery, GetBehaviorsCountQueryVariables>;
export const GetMediasResultsTotalDocument = gql`
    query getMediasResultsTotal($id: String = "") {
  getBehaviors(behaviorId: $id) {
    behaviors {
      mediaResults
      newMediaResults
    }
  }
}
    `;

/**
 * __useGetMediasResultsTotalQuery__
 *
 * To run a query within a React component, call `useGetMediasResultsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediasResultsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediasResultsTotalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediasResultsTotalQuery(baseOptions?: Apollo.QueryHookOptions<GetMediasResultsTotalQuery, GetMediasResultsTotalQueryVariables>) {
        return Apollo.useQuery<GetMediasResultsTotalQuery, GetMediasResultsTotalQueryVariables>(GetMediasResultsTotalDocument, baseOptions);
      }
export function useGetMediasResultsTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediasResultsTotalQuery, GetMediasResultsTotalQueryVariables>) {
          return Apollo.useLazyQuery<GetMediasResultsTotalQuery, GetMediasResultsTotalQueryVariables>(GetMediasResultsTotalDocument, baseOptions);
        }
export type GetMediasResultsTotalQueryHookResult = ReturnType<typeof useGetMediasResultsTotalQuery>;
export type GetMediasResultsTotalLazyQueryHookResult = ReturnType<typeof useGetMediasResultsTotalLazyQuery>;
export type GetMediasResultsTotalQueryResult = Apollo.QueryResult<GetMediasResultsTotalQuery, GetMediasResultsTotalQueryVariables>;
export const GetBehaviorResultsDocument = gql`
    query getBehaviorResults($id: String = "", $total: Int = 10, $index: Int = 0) {
  getBehaviors(behaviorId: $id, first: $total, offset: $index) {
    behaviors {
      ...BehaviorResults
    }
  }
}
    ${BehaviorResultsFragmentDoc}`;

/**
 * __useGetBehaviorResultsQuery__
 *
 * To run a query within a React component, call `useGetBehaviorResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBehaviorResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBehaviorResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetBehaviorResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetBehaviorResultsQuery, GetBehaviorResultsQueryVariables>) {
        return Apollo.useQuery<GetBehaviorResultsQuery, GetBehaviorResultsQueryVariables>(GetBehaviorResultsDocument, baseOptions);
      }
export function useGetBehaviorResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBehaviorResultsQuery, GetBehaviorResultsQueryVariables>) {
          return Apollo.useLazyQuery<GetBehaviorResultsQuery, GetBehaviorResultsQueryVariables>(GetBehaviorResultsDocument, baseOptions);
        }
export type GetBehaviorResultsQueryHookResult = ReturnType<typeof useGetBehaviorResultsQuery>;
export type GetBehaviorResultsLazyQueryHookResult = ReturnType<typeof useGetBehaviorResultsLazyQuery>;
export type GetBehaviorResultsQueryResult = Apollo.QueryResult<GetBehaviorResultsQuery, GetBehaviorResultsQueryVariables>;
export const GetResultsByBehaviorIdDocument = gql`
    query getResultsByBehaviorId($id: String = "", $total: Int = 10, $index: Int = 0) {
  getResults(behaviorId: $id, first: $total, offset: $index) {
    ...BehaviorMediaResults
  }
}
    ${BehaviorMediaResultsFragmentDoc}`;

/**
 * __useGetResultsByBehaviorIdQuery__
 *
 * To run a query within a React component, call `useGetResultsByBehaviorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultsByBehaviorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultsByBehaviorIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetResultsByBehaviorIdQuery(baseOptions?: Apollo.QueryHookOptions<GetResultsByBehaviorIdQuery, GetResultsByBehaviorIdQueryVariables>) {
        return Apollo.useQuery<GetResultsByBehaviorIdQuery, GetResultsByBehaviorIdQueryVariables>(GetResultsByBehaviorIdDocument, baseOptions);
      }
export function useGetResultsByBehaviorIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResultsByBehaviorIdQuery, GetResultsByBehaviorIdQueryVariables>) {
          return Apollo.useLazyQuery<GetResultsByBehaviorIdQuery, GetResultsByBehaviorIdQueryVariables>(GetResultsByBehaviorIdDocument, baseOptions);
        }
export type GetResultsByBehaviorIdQueryHookResult = ReturnType<typeof useGetResultsByBehaviorIdQuery>;
export type GetResultsByBehaviorIdLazyQueryHookResult = ReturnType<typeof useGetResultsByBehaviorIdLazyQuery>;
export type GetResultsByBehaviorIdQueryResult = Apollo.QueryResult<GetResultsByBehaviorIdQuery, GetResultsByBehaviorIdQueryVariables>;
export const GetNewResultsByBehaviorIdDocument = gql`
    query getNewResultsByBehaviorId($id: String = "", $total: Int = 10, $index: Int = 0) {
  getNewResults(behaviorId: $id, first: $total, offset: $index) {
    ...BehaviorNewMediaResults
  }
}
    ${BehaviorNewMediaResultsFragmentDoc}`;

/**
 * __useGetNewResultsByBehaviorIdQuery__
 *
 * To run a query within a React component, call `useGetNewResultsByBehaviorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewResultsByBehaviorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewResultsByBehaviorIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetNewResultsByBehaviorIdQuery(baseOptions?: Apollo.QueryHookOptions<GetNewResultsByBehaviorIdQuery, GetNewResultsByBehaviorIdQueryVariables>) {
        return Apollo.useQuery<GetNewResultsByBehaviorIdQuery, GetNewResultsByBehaviorIdQueryVariables>(GetNewResultsByBehaviorIdDocument, baseOptions);
      }
export function useGetNewResultsByBehaviorIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewResultsByBehaviorIdQuery, GetNewResultsByBehaviorIdQueryVariables>) {
          return Apollo.useLazyQuery<GetNewResultsByBehaviorIdQuery, GetNewResultsByBehaviorIdQueryVariables>(GetNewResultsByBehaviorIdDocument, baseOptions);
        }
export type GetNewResultsByBehaviorIdQueryHookResult = ReturnType<typeof useGetNewResultsByBehaviorIdQuery>;
export type GetNewResultsByBehaviorIdLazyQueryHookResult = ReturnType<typeof useGetNewResultsByBehaviorIdLazyQuery>;
export type GetNewResultsByBehaviorIdQueryResult = Apollo.QueryResult<GetNewResultsByBehaviorIdQuery, GetNewResultsByBehaviorIdQueryVariables>;
export const AddBehaviorQueryDocument = gql`
    mutation addBehaviorQuery($behaviorName: String!, $behaviorQuery: String!) {
  addBehavior(
    behaviorName: $behaviorName
    jsonLogic: $behaviorQuery
    tree: $behaviorQuery
  ) {
    response {
      __typename
      ... on Behavior {
        ...BehaviorResults
      }
      ... on BehaviorQueryMissing {
        message
      }
      ... on BehaviorExists {
        message
      }
    }
  }
}
    ${BehaviorResultsFragmentDoc}`;
export type AddBehaviorQueryMutationFn = Apollo.MutationFunction<AddBehaviorQueryMutation, AddBehaviorQueryMutationVariables>;

/**
 * __useAddBehaviorQueryMutation__
 *
 * To run a mutation, you first call `useAddBehaviorQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBehaviorQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBehaviorQueryMutation, { data, loading, error }] = useAddBehaviorQueryMutation({
 *   variables: {
 *      behaviorName: // value for 'behaviorName'
 *      behaviorQuery: // value for 'behaviorQuery'
 *   },
 * });
 */
export function useAddBehaviorQueryMutation(baseOptions?: Apollo.MutationHookOptions<AddBehaviorQueryMutation, AddBehaviorQueryMutationVariables>) {
        return Apollo.useMutation<AddBehaviorQueryMutation, AddBehaviorQueryMutationVariables>(AddBehaviorQueryDocument, baseOptions);
      }
export type AddBehaviorQueryMutationHookResult = ReturnType<typeof useAddBehaviorQueryMutation>;
export type AddBehaviorQueryMutationResult = Apollo.MutationResult<AddBehaviorQueryMutation>;
export type AddBehaviorQueryMutationOptions = Apollo.BaseMutationOptions<AddBehaviorQueryMutation, AddBehaviorQueryMutationVariables>;
export const UpdateBehaviorQueryDocument = gql`
    mutation updateBehaviorQuery($id: String!, $name: String!, $query: String!) {
  editBehavior(
    behaviorId: $id
    behaviorName: $name
    jsonLogic: $query
    tree: $query
  ) {
    response {
      __typename
      ... on Behavior {
        ...BehaviorResults
      }
      ... on BehaviorNotFound {
        message
      }
      ... on BehaviorExists {
        message
      }
    }
  }
}
    ${BehaviorResultsFragmentDoc}`;
export type UpdateBehaviorQueryMutationFn = Apollo.MutationFunction<UpdateBehaviorQueryMutation, UpdateBehaviorQueryMutationVariables>;

/**
 * __useUpdateBehaviorQueryMutation__
 *
 * To run a mutation, you first call `useUpdateBehaviorQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBehaviorQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBehaviorQueryMutation, { data, loading, error }] = useUpdateBehaviorQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useUpdateBehaviorQueryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBehaviorQueryMutation, UpdateBehaviorQueryMutationVariables>) {
        return Apollo.useMutation<UpdateBehaviorQueryMutation, UpdateBehaviorQueryMutationVariables>(UpdateBehaviorQueryDocument, baseOptions);
      }
export type UpdateBehaviorQueryMutationHookResult = ReturnType<typeof useUpdateBehaviorQueryMutation>;
export type UpdateBehaviorQueryMutationResult = Apollo.MutationResult<UpdateBehaviorQueryMutation>;
export type UpdateBehaviorQueryMutationOptions = Apollo.BaseMutationOptions<UpdateBehaviorQueryMutation, UpdateBehaviorQueryMutationVariables>;
export const DeleteBehaviorQueryDocument = gql`
    mutation deleteBehaviorQuery($id: String!) {
  deleteBehavior(behaviorId: $id) {
    response {
      __typename
      ... on BehaviorDeleted {
        message
      }
      ... on BehaviorNotFound {
        message
      }
    }
  }
}
    `;
export type DeleteBehaviorQueryMutationFn = Apollo.MutationFunction<DeleteBehaviorQueryMutation, DeleteBehaviorQueryMutationVariables>;

/**
 * __useDeleteBehaviorQueryMutation__
 *
 * To run a mutation, you first call `useDeleteBehaviorQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBehaviorQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBehaviorQueryMutation, { data, loading, error }] = useDeleteBehaviorQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBehaviorQueryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBehaviorQueryMutation, DeleteBehaviorQueryMutationVariables>) {
        return Apollo.useMutation<DeleteBehaviorQueryMutation, DeleteBehaviorQueryMutationVariables>(DeleteBehaviorQueryDocument, baseOptions);
      }
export type DeleteBehaviorQueryMutationHookResult = ReturnType<typeof useDeleteBehaviorQueryMutation>;
export type DeleteBehaviorQueryMutationResult = Apollo.MutationResult<DeleteBehaviorQueryMutation>;
export type DeleteBehaviorQueryMutationOptions = Apollo.BaseMutationOptions<DeleteBehaviorQueryMutation, DeleteBehaviorQueryMutationVariables>;