// Dependencies
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  KeyboardDateTimePicker,
  KeyboardDatePickerProps as Props,
} from "@material-ui/pickers";

// Assets
import SC from "components/text-field/text-field.styles";

export type DateTimeFieldProps = Props;

export const DateTimeField: React.FC<DateTimeFieldProps> = (props) => {
  const theme = useTheme();

  return (
    <KeyboardDateTimePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      variant="inline"
      inputVariant="filled"
      format={theme.formats.datetime}
      TextFieldComponent={SC.TextField}
    />
  );
};

export default DateTimeField;
