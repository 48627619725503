/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";

// Components
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// GraphQL
import {
  AudionautPlan,
  DepartmentData,
  GroupData,
  OrganisationData,
  UserData,
} from "graphql/types-and-hooks";

import { PlanModel } from "components/plans-coupons-form-fields/data/models/plans-model";

import PlansCouponsFormFields from "../plans-coupons-form-fields/plans-coupons-form-fields.component";
// Assets
import SC from "./plans-coupons-form.styles";

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;

export interface PlansCouponsFormProps {
  initialItemData: PlanModel | AudionautPlan;
  initialEditable: boolean;
  saveActive?: boolean;
  isProfile?: boolean;
  onSubmit: (itemData: PlanModel | AudionautPlan) => void;
  onCancel: () => void;
  isPlan: boolean;
}

export const PlansCouponsForm: React.FC<PlansCouponsFormProps> = ({
  initialItemData,
  initialEditable,
  onSubmit,
  onCancel,
  isPlan,
}) => {
  const [itemData, setItemData] =
    React.useState<PlanModel | AudionautPlan>(initialItemData);
  const [isEditable, setIsEditable] = React.useState(initialEditable);

  React.useEffect(() => {
    setItemData(initialItemData);
  }, [initialItemData]);

  const handlerEditOnClick = React.useCallback(() => {
    setIsEditable(true);
  }, []);

  const handlerCancelOnClick = React.useCallback(() => {
    // setIsEditable(false);
    // setItemData(initialItemData);
    onCancel();
  }, [onCancel]);

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();

      setIsEditable(initialEditable);
      if (initialItemData !== itemData) onSubmit(itemData);
    },
    [initialEditable, initialItemData, onSubmit, itemData]
  );

  const handleOnChange = React.useCallback((newUserData: any) => {
    setItemData(newUserData);
  }, []);

  const buttons = [
    {
      title: isPlan ? "Cancel" : "Remove Coupon",
      variant: "outlined",
      handler: handlerCancelOnClick,
    },
    {
      title: "Save",
      variant: "contained",
      type: "submit",
    },
  ];

  // const appPermissionValidator = useAppPermissionValidator();

  // if (appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)) {
  //   buttons.push({
  //     title: "Edit",
  //     variant: "outlined",
  //     handler: handlerEditOnClick,
  //     isShown: !isEditable,
  //   });
  // }

  return (
    <SC.PlansCouponsForm component="form" onSubmit={handlerOnSubmit}>
      <PlansCouponsFormFields
        itemData={itemData}
        isEditable={isEditable}
        onChange={handleOnChange}
        isPlan={isPlan}
      />
      <SC.FormActions>
        {buttons.map(({ variant, type, handler, title }) => (
          <SC.Button
            key={title}
            color="primary"
            variant={variant as ButtonVariant}
            type={type as ButtonType}
            onClick={handler}
          >
            {title}
          </SC.Button>
        ))}
      </SC.FormActions>
    </SC.PlansCouponsForm>
  );
};

export default PlansCouponsForm;
