/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
// Dependencies
import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";

// GraphQL
import {
  useGetCurrentUserProfileQuery,
  useUpdateUserDataMutation,
  useUpdateUserDeparmentMutation,
  useUpdateUserOrganisationMutation,
  UserData,
} from "graphql/types-and-hooks";

// Components
import { Alert, AlertProps } from "components/alert/alert.component";
import UserForm from "components/user-form/user-form.container";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Assets
import SC from "./my-profile.styles";

const MyProfilePage: React.FC = () => {
  const classes = SC.useStyles();
  const {
    data,
    loading: getUserProfileLoading,
    error,
  } = useGetCurrentUserProfileQuery();
  const initialUserData = data?.GetCurrentUserProfile;

  const errorHandler = useAppErrorHandler(error);

  const [updateUserData, { loading: updateUserDataLoading }] =
    useUpdateUserDataMutation();
  const [updateUserDepartment, { loading: updateUserDepartmentLoading }] =
    useUpdateUserDeparmentMutation();
  const [updateUserOrganisation, { loading: updateUserOrganisationLoading }] =
    useUpdateUserOrganisationMutation();

  const loading =
    getUserProfileLoading ||
    updateUserDataLoading ||
    updateUserDepartmentLoading ||
    updateUserOrganisationLoading;

  const [updateStatus, setUpdateStatus] = React.useState<AlertProps>();

  const handlerUserFormOnSubmit = React.useCallback(
    async (userData: any) => {
      try {
        const initialOrganisationId = initialUserData?.organisation?.id;
        const initialDepartmentId = initialUserData?.department?.id;

        if (initialOrganisationId !== userData?.organisation?.id) {
          await updateUserOrganisation({
            variables: {
              userID: userData?.id!,
              organisationID: userData?.organisation?.id,
            },
          });
        }

        if (initialDepartmentId !== userData?.department?.id) {
          await updateUserDepartment({
            variables: {
              userID: userData?.id!,
              departmentID: userData?.department?.id,
            },
          });
        }

        const newUserData: any = {
          id: userData?.id!,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          profilePicture: userData?.profilePicture,
          mobilePhone: userData?.mobilePhone,
          workAddress: userData?.work?.address,
          workPhone: userData?.workPhone,
          workName: userData?.workPhone,
          departmentName: userData?.departmentName,
          FindOutAudionaut: userData?.FindOutAudionaut,
        };

        if (userData.newPassword) {
          newUserData.password = userData?.newPassword;
        }

        await updateUserData({
          variables: newUserData,
        });

        setUpdateStatus({
          message: "Your profile has been updated successfully",
          severity: "success",
        });
      } catch (err: any) {
        errorHandler(err);
      }
    },
    [
      initialUserData,
      updateUserOrganisation,
      updateUserDepartment,
      updateUserData,
      errorHandler,
    ]
  );

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setUpdateStatus(undefined);
    },
    []
  );

  return (
    <SC.MyProfilePage component="main">
      <SC.MyProfileContent>
        <SC.Snackbar
          open={!!updateStatus}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={updateStatus?.severity}
            message={updateStatus?.message}
          />
        </SC.Snackbar>

        <ProgressIndicator open={loading} />
        <Box component="div" className={classes.titleContainer}>
          <Typography variant="h1" color="primary">
            Account
          </Typography>
        </Box>
        {/* <Divider /> */}
        <UserForm
          initialUserData={initialUserData}
          isProfile
          initialEditable={false}
          onSubmit={handlerUserFormOnSubmit}
          onCancel={() => {}}
        />
      </SC.MyProfileContent>
    </SC.MyProfilePage>
  );
};
export default MyProfilePage;
