/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  from,
  ServerParseError,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { RestLink } from "apollo-link-rest";

// Config
import GlobalConfig from "global.config";

// Apollo
import {
  appErrorDataVar,
  extractAppErrorData,
  isServerErrorCode,
  networkErrorToAppErrorData,
  serverErrorToAppErrorData,
} from "errors/app.errors";
import { NetworkError, NetworkErrorCodes } from "errors/network.errors";
import cache from "./cache";

// Assets
import { version } from "../../package.json";
import { authenticationDataVar, TOKEN_KEY } from "./authentication.utils";

export type GraphQLServer =
  | "RBAC"
  | "HASURA"
  | "GKFILTER"
  | "AUDIONAUTSEARCH"
  | "AUDIONAUTSQANDA"
  | "AUDIONAUTBEHAVIORSAPI"
  | "AUDIONAUTRECOMMENDATIONS"
  | "AUDIONAUTEXPLOREAPI"
  | "AUDIONAUTSQANDAPRO"
  | "CORPORATUSAPIREST";

export const CLIENT_NAME: Record<GraphQLServer, string> = {
  RBAC: "RBAC client",
  HASURA: "HASURA client",
  GKFILTER: "GK client",
  AUDIONAUTSEARCH: "Search client",
  AUDIONAUTSQANDA: "Questions and answers client",
  AUDIONAUTBEHAVIORSAPI: "Behaviors client",
  AUDIONAUTRECOMMENDATIONS: "Recommendations client",
  AUDIONAUTEXPLOREAPI: "Explore client",
  AUDIONAUTSQANDAPRO: "Questions and answers client pro",
  CORPORATUSAPIREST: "Corporatus url api rest",
};

const customFetch = (
  uri: RequestInfo,
  options: RequestInit | undefined
): Promise<Response> => {
  return fetch(uri, options).then((response) => {
    if (response.status >= 500) {
      // or handle 400 errors
      // return Promise.reject(response.status);
      return Promise.reject({
        response,
        status: response.status,
        bodyText: "The last action have had an error, please try it again.",
      });
    } else if (response.status >= 400) {
      // or handle 400 errors
      // return Promise.reject(response.status);
      return Promise.reject({
        response,
        statusCode: response.status,
        bodyText: response.statusText,
      });
    }
    return response;
  });
};

// Only for Development stage: You should disable CORS policy in web browsers,
// if you have CORS issues.
const httpLink = createUploadLink({
  uri: GlobalConfig.get("GraphQLServerURL"),
  credentials: "include",
  fetch: customFetch,
});

const authLink = setContext((_, previousContext) => {
  // TODO: get the authentication token from local storage if it exists

  const { headers, noAuthNeeded } = previousContext;
  const { token: tokenAuth } = authenticationDataVar();

  let token = `Bearer ${tokenAuth}`;

  if (noAuthNeeded || !token) return previousContext;

  // if (!token) {
  //   token = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsidXNlciJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJ1c2VyIiwieC1oYXN1cmEtb3JnLWlkIjoiMDQ2OWNmMTItYjYxMi00NzdkLWE5ZDEtZDgzOTlmNzg0N2I2IiwieC1oYXN1cmEtdXNlci1pZCI6InNhaGVlZEBkYXR5cmEuY29tIn0sImV4cCI6MTY1NzkyODkxNSwianRpIjoic2FoZWVkQGRhdHlyYS5jb20iLCJpYXQiOjE2NTc5MDAxMTUsIm5iZiI6MTY1NzkwMDExNSwic3ViIjoic2FoZWVkQGRhdHlyYS5jb20ifQ.ZP_CWlHs2G68qSQBWn4oMOqXFUYubQYMNA1Na5wxek8GNBKhQ_lWyq7TIC_UNn0rAo_y7-m6DlXCCDi9v4apyNMX8TqPH-b3_A5U6kWTeyiFb6jbMFcnkPgliWLmB64OyYUSeQ2a_qoUW9LHU-Ph0EfllWR77xQk73xQ2yuokjaqEHwxhePvaoXgbkKSHmne__mMvAfpP9EIhze7pbnil-w6lIFgIEZpj3x4m9RxOQYufnBNEZxp3Ns0keM8nJ8mx1sahhVALoj0d7JQKDtSQssINOeGXj9GrkLfqWUK4aCCH4D_I5EunwwmNWTXZun4L-7K9yMLjO_koBBVFKdE0Q`;
  // }

  // eslint-disable-next-line prefer-template

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // Token of user owner@audionaut.ai
      Authorization: token,
    },
  };
});

const searchServer = createHttpLink({
  uri: GlobalConfig.get("GraphQLSearchServerURL"),
  credentials: "include",
  fetch: customFetch,
});

const qAndAServer = createHttpLink({
  uri: GlobalConfig.get("GraphQLQAndAServerURL"),
  credentials: "include",
  fetch: customFetch,
});

const qAndAProServer = createHttpLink({
  uri: GlobalConfig.get("GraphQLQAndAProServerURL"),
  credentials: "include",
  fetch: customFetch,
});

const behaviorServer = createHttpLink({
  uri: GlobalConfig.get("GraphQLBehaviorsAPIURL"),
  credentials: "include",
  fetch: customFetch,
});

const recommendationsServer = createHttpLink({
  uri: GlobalConfig.get("GraphQLRecommendationsURL"),
  credentials: "include",
  fetch: customFetch,
});

const exploreServer = createHttpLink({
  uri: GlobalConfig.get("GraphQLExploreAPIURL"),
  credentials: "include",
  fetch: customFetch,
});

const corporatusServer = new RestLink({
  endpoints: {
    corporatus: GlobalConfig.get("APIRESTCorporatusServerURL") as string,
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // if (operation.operationName === "GetUserProfile") {
  // }
  if (graphQLErrors) {
    const serverErrors = graphQLErrors.filter((gqlError) => {
      const { extensions } = gqlError;
      if (extensions) {
        const { code } = extensions;

        if (isServerErrorCode(code)) {
          return true;
        }
      }

      return false;
    });

    if (serverErrors.length > 0) {
      const appErrorData = serverErrorToAppErrorData(graphQLErrors);
      appErrorDataVar(appErrorData);
    }
  }

  if (networkError) {
    const serverParseError = networkError as ServerParseError;

    let networkErr = new NetworkError(
      NetworkErrorCodes.SERVER_NOT_REACHABLE,
      serverParseError.bodyText
    );

    if (serverParseError.statusCode === 401) {
      networkErr = new NetworkError(
        NetworkErrorCodes.HTTP_UNAUTHORIZED,
        serverParseError.bodyText
      );
    }

    const appErrorData = networkErrorToAppErrorData(networkErr);
    appErrorDataVar(appErrorData);
  }

  // console.log(">>> GQL Errors: ", graphQLErrors);
  // console.log(">> Network error: ", networkError);
  // console.log("> Operation: ", operation);
});

const client = new ApolloClient({
  name: "rtcs-webui",
  version,
  // link: ApolloLink.split(
  //   (operation) =>
  //     operation.getContext().clientName === CLIENT_NAME.AUDIONAUTSEARCH,
  //   searchAuthLink.concat(searchServer),
  //   authLink.concat(httpLink)
  // ),
  link: from([
    errorLink,
    ApolloLink.split(
      (operation) =>
        operation.getContext().clientName === CLIENT_NAME.AUDIONAUTSEARCH,
      authLink.concat(searchServer),
      ApolloLink.split(
        (operation) =>
          operation.getContext().clientName ===
          CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
        authLink.concat(behaviorServer),
        ApolloLink.split(
          (operation) =>
            operation.getContext().clientName ===
            CLIENT_NAME.AUDIONAUTRECOMMENDATIONS,
          authLink.concat(recommendationsServer),
          ApolloLink.split(
            (operation) =>
              operation.getContext().clientName === CLIENT_NAME.AUDIONAUTSQANDA,
            authLink.concat(qAndAServer),
            ApolloLink.split(
              (operation) =>
                operation.getContext().clientName ===
                CLIENT_NAME.AUDIONAUTEXPLOREAPI,
              authLink.concat(exploreServer),
              ApolloLink.split(
                (operation) =>
                  operation.getContext().clientName ===
                  CLIENT_NAME.AUDIONAUTSQANDAPRO,
                authLink.concat(qAndAProServer),
                ApolloLink.split(
                  (operation) =>
                    operation.getContext().clientName ===
                    CLIENT_NAME.CORPORATUSAPIREST,
                  authLink.concat(corporatusServer),
                  authLink.concat(httpLink)
                )
              )
            )
          )
        )
      )
    ),
  ]),
  cache,
});

export default client;
