/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, useState } from "react";
import clsx from "clsx";
import {
  withStyles,
  makeStyles,
  TablePagination,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  DialogProps,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  MuiThemeProvider,
  createTheme,
  createStyles,
  Tooltip,
  Chip,
  CardMedia,
  Theme,
  Divider,
  Typography,
} from "@material-ui/core";

import playImage from "assets/images/play_audio.png";

import {
  useGetTargetBySourceIdLazyQuery,
  useGetTargetBySourceIdQuery,
} from "graphql/types-and-hooks";
import AudionautCharts from "components/charts/charts.component";
import { getFormattedDate } from "commons/utils/date-format";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";

const drawerWidth = "300px";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#FAFAFA",
    },
    modalStyle: {
      // height: "calc(100% - 108px) !important",
      height: "100%",
      position: "absolute",
      top: "0px",
      // top: "108px",
      zIndex: 1000,
      [theme.breakpoints.down(540)]: {
        height: "calc(100% - 60px) !important",
        width: "100% !important",
        top: "60px",
        background: "#FFF",
      },
    },
    fullWidth: {
      width: "calc(100% - 73px)",
    },
    partialWidth: {
      width: "calc(100% - 264px)",
    },
    drawer: {
      width: drawerWidth,
      padding: "0px 15px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    },
    drawerOpen: {
      top: "auto !important",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: "relative",
      borderRight: "unset",
      "& div": {
        boxShadow: "unset",
      },
    },
    drawerClose: {
      position: "relative",
      borderRight: "unset",
      "& div": {
        boxShadow: "unset",
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      top: "auto !important",
      overflowX: "hidden",
      width: "0px",
      [theme.breakpoints.up("sm")]: {
        width: "0px",
      },
      padding: "0px",
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "right",
      "& hr": {
        height: "25px",
        margin: "auto",
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
    iconFilter: {
      height: "50px",
      width: "50px",
      [theme.breakpoints.down(540)]: {
        margin: "auto",
      },
    },
    iconFilterActive: {
      color: "#8773B1",
    },
    iconFilterInactive: {
      color: "#A2A2A2",
    },
    hide: {
      display: "none !important",
    },
    show: {
      display: "block",
    },
    itemDetailContainer: {
      padding: "30px",
    },
    itemDetailHeader: {
      height: "fit-content !important",
      background: "transparent !important",
      boxShadow: "none !important",
      padding: "unset !important",
      marginBottom: "30px",
      "& .header": {
        height: "100%",
        display: "flex !important",
        "& .titles": {
          flex: "1",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Gothic A1",
          "& .title": {
            fontSize: "30px",
            lineHeight: "38px",
            fontWeight: 800,
            marginBottom: "10px",
          },
          "& .date": {
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 400,
            marginBottom: "16px",
          },
          "& .breadcrumb": {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 400,
            "& .MuiBreadcrumbs-separator": {
              color: "#a2a2a2",
            },
            "& a": {
              color: "#adadad",
            },
            "& p": {
              fontSize: "18px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#B8CAC6",
            },
          },
        },
        "& .play-image": {
          width: "65px",
          height: "fit-content",
          display: "grid",
          flexDirection: "column",
          "& span": {
            fontSize: "18px",
            color: "#D83651",
            paddingTop: "18px",
          },
        },
      },
    },
    pointer: {
      cursor: "pointer",
    },
    chipRoot: {
      height: "fit-content",
      lineHeight: "1.2",
      "& span": {
        color: "#1C1C1C",
        padding: "0px 5px",
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    itemDetailContent: {
      height: "fit-content",
      "& .content": {
        height: "auto",
        "& p": {
          lineHeight: "2",
          maxHeight: "calc(100% - 1em)",
        },
      },
    },
    containerCardImg: {
      maxHeight: "calc(100% - 50px)",
      margin: "auto",
      marginRight: "auto !important",
    },
    cardImg: {
      height: "-webkit-fill-available",
      margin: "auto",
      objectFit: "contain",
    },
    tableContainer: {
      backgroundColor: "transparent",
      boxShadow: "none",
      width: "calc(100% + 20px)",
      marginLeft: "-10px",
      marginRight: "-10px",
      "& th": {
        paddingLeft: "10px",
        paddingRight: "10px",
        border: "none",
        "& .itemSerch": {
          marginLeft: "0px",
          marginRight: "0px",
        },
      },
      [theme.breakpoints.down(540)]: {
        width: "calc(100% + 40px)",
        marginLeft: "-20px",
        marginRight: "-20px",
        "& th": {
          paddingLeft: "20px",
          paddingRight: "20px",
          "& .itemSerch": {
            width: "calc(100vw - 40px)",
            padding: "10px 20px",
          },
        },
      },
    },
    pagination: {
      title: {
        "& h1": {
          textAlign: "left",
        },
        pagination: {
          flex: "1",
        },
      },
    },
    loader: {
      zIndex: 1001,
      position: "relative",
      display: "flex",
      width: "100% !important",
      height: "200px",
      background: "#0000004D",
      top: "16px",
      borderRadius: "10px",
      marginBottom: "16px",
    },
    circularProgress: {
      margin: "auto",
      color: "#FFFFFF",
    },
    extractedContent: {
      display: "flex",
      flex: "1",
    },
    targetBtn: {
      background: "#B8CAC6",
      height: "30px",
      borderRadius: "15px",
      color: "#FFF",
      "&:hover": {
        background: "#8773B1",
      },
    },
  })
);

const useContainerStyles = makeStyles((theme) => ({
  title: {
    "& h1": {
      textAlign: "left",
    },
    pagination: {
      flex: "1",
    },
  },
}));

interface Props {
  component: string;
  count: number;
  page: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  rowsPerPage: number;
  onChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

// const GSTablePagination = withStyles(() => ({
//   container: {
//     textAlign: "left",
//   },
// }))(TablePagination) as typeof TablePagination;

const GSTablePagination = (props: Props) => {
  const { ...muiProps } = props;
  const classes = useContainerStyles(props);
  return (
    <TablePagination
      className={classes.title}
      style={{ flex: "1" }}
      {...muiProps}
    />
  );
};

const GSContainer = withStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "hidden",
  },
}))(Box);

const GSContainerResults = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    padding: "30px 28px",
    overflowY: "auto",
    "& h1": {
      textAlign: "left",
      fontFamily: "Gothic A1",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "30px",
      lineHeight: "38px",
      color: "#000000",
    },
    [theme.breakpoints.down(540)]: {
      "& h1": {
        fontSize: theme.fontGeneralStyles.large.fontSize,
        lineHeight: theme.fontGeneralStyles.large.lineHeight,
        fontWeight: 600,
      },
      padding: "20px",
    },
  },
}))(Box);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
    [theme.breakpoints.down(540)]: {
      height: "30px",
      width: "120px",
    },
  },
}))(Button);

const ButtonSecondary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.secondary.backgroundColor,
    // fontSize: theme.button.secondary.fontSize,
    // lineHeight: theme.button.secondary.lineHeight,
    color: theme.button.secondary.color,
    border: theme.button.secondary.border,
    textTransform: theme.button.secondary.textTransform,
    "&:hover": {
      color: theme.button.secondary.hoverColor,
      backgroundColor: theme.button.secondary.hoverBackground,
      border: theme.button.secondary.hoverBorder,
    },
    "&:disabled": {
      color: theme.button.secondary.disabledColor,
      backgroundColor: theme.button.secondary.disabledBackground,
      border: theme.button.secondary.disabledBorder,
    },
  },
}))(Button);

const GSSummary = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
    "& div:first-child": {
      marginLeft: "0px",
    },
    "& div:last-child": {
      marginRight: "0px",
    },
    [theme.breakpoints.down(540)]: {
      gap: "10px",
    },
  },
}))(Box);

// maxWidth: "calc(100%/6)",
const GSSummaryItem = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    padding: "12px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginLeft: "10px",
    marginRight: "10px",
    "& span.title": {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "20px",
      textAlign: "left",
    },
    "& .content": {
      flex: "1",
      display: "flex",
      flexDirection: "column",
    },
    "& .main": {
      flex: "1",
      display: "flex",
      "& div": {
        margin: "auto",
        "& span": {
          fontSize: "45px",
          fontWeight: "800",
        },
      },
    },
    "& .footer": {
      color: "#696969",
      fontSize: theme.fontGeneralStyles.small.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      marginTop: "0px",
    },
    "& .cardHeader": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "8px",
      },
    },
    [theme.breakpoints.down(540)]: {
      height: "150px",
      margin: "unset",
      "& span.title": {
        fontSize: theme.fontGeneralStyles.default.fontSize,
        fontWeight: 400,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
      },
      "& .main": {
        "& div": {
          "& span": {
            fontSize: "35px !important",
          },
        },
      },
      "& .footer": {
        fontSize: theme.fontGeneralStyles.default.fontSize,
        fontWeight: 600,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
      },
    },
  },
}))(Box);

const GSResultItem = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    padding: "15px 45px",
    // height: "200px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginLeft: "10px",
    marginRight: "10px",
    "& .header": {
      display: "contents",
      "& .title": {
        fontSize: theme.fontGeneralStyles.medium.fontSize,
        fontWeight: "800",
        lineHeight: theme.fontGeneralStyles.medium.lineHeight,
        textAlign: "left",
        marginBottom: "8px",
        [theme.breakpoints.down(540)]: {
          fontSize: theme.fontGeneralStyles.small.fontSize,
          lineHeight: theme.fontGeneralStyles.medium.lineHeight,
        },
      },
      "& .date": {
        fontSize: "11px",
        lineHeight: "13px",
        textAlign: "left",
        fontWeight: "400",
        color: "#ABABAB",
      },
    },
    "& .content": {
      // height: "70px",
      flex: "1",
      display: "flex",
      marginBottom: "15px",
      paddingTop: "15px",
      "& p": {
        flex: "1",
        // maxHeight: "70px",
        height: "100%",
        textAlign: "left",
        fontSize: theme.fontGeneralStyles.small.fontSize,
        fontWeight: "noormal",
        color: "#1C1C1C",
        paddingRight: "50px",
        overflow: "hidden",
        "& p": {
          height: "fit-content",
          marginBottom: "5px",
        },
        "& em": {
          fontStyle: "normal",
        },
        "& .green": {
          color: "#60C3A6",
        },
        "& .purple": {
          color: "#945AA9",
        },
        "& .color-1": {
          background: "#D4DCF3",
        },
        "& .color-2": {
          background: "#F4E2FB",
        },
        "& .color-3": {
          background: "#DFCEEE6",
        },
        "& .color-4": {
          background: "#FCF6C6",
        },
        "& .color-5": {
          background: "#EADEEE",
        },
        "& .color-6": {
          background: "#CFEDE4",
        },
        "& .color-7": {
          background: "#C6DBEE",
        },
        "& .MuiChip-root": {
          height: "auto",
          "& span": {
            padding: "0px 5px",
            fontSize: "16px",
            fontWeight: "noormal",
            color: "#1C1C1C",
          },
        },
      },
      "& .red": {
        background: "red",
      },
      "& .play-image": {
        minWidth: "50px",
        width: "50px",
        marginTop: "1em",
        "& span": {
          color: "#D83651",
          fontSize: "12px",
          fontWeight: "normal",
        },
      },
      [theme.breakpoints.down(540)]: {
        "& p": {
          paddingRight: "unset",
          fontSize: theme.fontGeneralStyles.default.fontSize,
        },
      },
    },
    "& .footer": {
      display: "flex",
      marginTop: "15px",
      "& .MuiChip-root": {
        color: "#575757",
        marginRight: "30px",
        height: "20px",
        minWidth: "125px",
        fontSize: "11px",
        fontWeight: "500",
        [theme.breakpoints.down(540)]: {
          minWidth: "100px",
        },
      },
      "& .sentiment-color": {
        backgroundColor: "#df71713d",
      },
      "& .entity-color": {
        backgroundColor: "#aa8bec3d",
      },
      "& .word-color": {
        backgroundColor: "#71c5df3d",
      },
    },
  },
}))(Box);

const GSAddNewTargetStyles = makeStyles((theme) => ({
  addTargetContainer: {
    "& .MuiPaper-root": {
      borderRadius: "11px",
    },
  },
  addTargetFrom: {
    "& .MuiFormControl-root": {
      height: "48px",
      marginBottom: "26px",
      "& .MuiFormLabel-root": {
        color: "#B8CAC6",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 400,
      },
      "& input": {
        height: "100%",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: 400,
      },
      "& fieldset": {
        borderColor: "#B8CAC6",
      },
      "& .MuiInputBase-root": {
        height: "100%",
        "& .MuiSelect-root:focus": {
          backgroundColor: "#fff",
        },
      },
    },
  },
  addTargetBtn: {
    background: "#B8CAC6",
    height: "38px",
    width: "144px !important",
    borderRadius: "116px",
    color: "#FFF",
    "&:hover": {
      background: "#8773B1",
    },
    "& span": {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 600,
      textTransform: "capitalize",
    },
  },
  buttons: {
    padding: "20px",
  },
}));

interface NewTargetProps {
  sourceId: number;
  openAddTarget: boolean;
  setOpenAddTarget: any;
  handleSubmit: any;
  groups: any;
}
const GSAddNewTarget: React.FC<NewTargetProps> = ({
  sourceId: id,
  openAddTarget,
  setOpenAddTarget,
  handleSubmit,
  groups,
}) => {
  const classes = GSAddNewTargetStyles();

  const [newTargetName, setNewTargetName] = React.useState<any>({});
  const [group, setGroup] = React.useState(0);

  const handleAddGroupSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();

      const newTarget = {
        sourceId: id,
        targetName: newTargetName.target.value,
        groupId: group,
        source: "-",
        type: "media",
        mediasIds: JSON.stringify([]),
      };

      const response = await handleSubmit({
        variables: newTarget,
      });

      setOpenAddTarget(response);
    },
    [group, id, newTargetName, setOpenAddTarget, handleSubmit]
  );

  const handleSelectGroup = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGroup(event.target.value as number);
  };
  const numberId = Math.floor(Math.random() * (10000 - 100) + 100);

  return (
    <Dialog
      id={`addTargetDialog-${numberId}`}
      open={openAddTarget}
      onClose={setOpenAddTarget}
      aria-labelledby="form-dialog-title"
      className={classes.addTargetContainer}
    >
      <DialogTitle id="form-dialog-title">Add new target</DialogTitle>
      <Box component="form" onSubmit={handleAddGroupSubmit}>
        <DialogContent className={classes.addTargetFrom}>
          <DialogContentText>
            Please select a group and add a target name
          </DialogContentText>
          {/* <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="mediaIdField"
            label="Media ID"
            type="text"
            value={id}
            fullWidth
            disabled
          /> */}
          <FormControl variant="outlined" fullWidth>
            <InputLabel id={`select-lbl-${numberId}`}>Group</InputLabel>
            <Select
              labelId={`select-${numberId}`}
              id={`select-${numberId}`}
              fullWidth
              label="Group"
              value={group}
              onChange={handleSelectGroup}
            >
              {groups
                ? groups.map((groupData: any) => (
                    <MenuItem value={groupData.id}>{groupData.name}</MenuItem>
                  ))
                : "no groups exists"}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="nameTarget"
            label="Target Name"
            type="text"
            placeholder="Target name"
            fullWidth
            onChange={setNewTargetName}
            required
          />
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <ButtonSecondary type="button" onClick={setOpenAddTarget}>
            Cancel
          </ButtonSecondary>
          <ButtonPrimary type="submit" className={classes.addTargetBtn}>
            Add
          </ButtonPrimary>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const tooltipStyles = (createTheme as any)({
  overrides: {
    MuiTooltip: {
      arrow: {
        "&:before": {
          backgroundColor: "#484848",
        },
      },
      tooltip: {
        fontSize: "10px",
        fontWeight: "500",
        backgroundColor: "#484848",
      },
    },
  },
});
const useItemSearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipRoot: {
      height: "fit-content",
      lineHeight: "1.2",
      "& span": {
        color: "#1C1C1C",
        padding: "0px 5px",
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    extractedContent: {
      display: "flex",
      flex: "1",
      alignItems: "center",
      flexWrap: "wrap",
      rowGap: "5px",
    },
    hide: {
      display: "none !important",
    },
    chartPopover: {
      "& div:last-child": {
        "& br": {
          display: "Hidden",
        },
      },
    },
  })
);

interface ItemSearchProps {
  item: any;
  selectMediaId: any;
  addTargetDialog: any;
  showDetail: any;
  isQAndA: boolean;
  isItemDetail: boolean;
}
const GenerateItemSearch: React.FC<ItemSearchProps> = ({
  item,
  selectMediaId,
  addTargetDialog,
  showDetail,
  isQAndA,
  isItemDetail,
}) => {
  const classes = useItemSearchStyles();
  const {
    id: itemId,
    mediaId,
    documentId,
    mediaName,
    entities,
    sentence,
    sentiments,
    answer,
    context,
    score,
    title,
    author,
    source,
    targetStatus,
    created,
    meta,
  } = item;

  const [targetExists, setTargetExists] = React.useState(true);

  // This code fagment is a temporal solution until we have able the mediaId property in the Q&A service response
  let mediaIdSource = mediaId;
  if (meta) {
    const { source: sourceTemp } = JSON.parse(meta);
    mediaIdSource = sourceTemp;
  }
  // --------------------------------

  const {
    data,
    loading: targetLoading,
    error,
  } = useGetTargetBySourceIdQuery({
    variables: {
      sourceId: mediaId ?? mediaIdSource,
    },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (targetStatus !== undefined) {
      setTargetExists(targetStatus);
    } else {
      setTargetExists(!!data);
    }
  }, [data, targetStatus]);

  return (
    <GSResultItem className="itemSerch">
      <Box
        className={`header ${isItemDetail ? classes.hide : ""}`}
        onClick={() => {
          showDetail(item);
        }}
      >
        <span className="title">{!isQAndA ? mediaName : title}</span>
        <span className="date">
          {created !== null ? getFormattedDate(created) : "-"}
        </span>
      </Box>
      <Box
        className="content"
        onClick={() => {
          showDetail(item);
        }}
      >
        <p>
          {isQAndA && !isItemDetail ? (
            <Typography style={{ height: "fit-content" }}>
              <b>{answer}</b>
            </Typography>
          ) : null}
          {!isQAndA
            ? sentence
            : `${
                context && context.length > 299 && !isItemDetail
                  ? context.substr(0, 300)
                  : context
              } ...`}
          {/* Consectetur&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Pronoun" placement="top" arrow>
              <Chip className={`color-1 ${classes.chipRoot}`} label="I" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Verb" placement="top" arrow>
              <Chip className={`color-2 ${classes.chipRoot}`} label="like" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;adipiscing&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Determinant" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="the" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;duis tristique sollicitudin&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Product" placement="top" arrow>
              <Chip
                className={`color-5 ${classes.chipRoot}`}
                label="McLaren 720s"
              />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;nibh sit amet commodo nulla facilisi nullam&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Pronoun" placement="top" arrow>
              <Chip className={`color-1 ${classes.chipRoot}`} label="I" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;vehicula ipsum a arcu cursus&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Color" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="for" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;vitae congue&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Verb" placement="top" arrow>
              <Chip className={`color-2 ${classes.chipRoot}`} label="like" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;adipiscing&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Determinant" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="the" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;elit duis tristique&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Verb" placement="top" arrow>
              <Chip className={`color-7 ${classes.chipRoot}`} label="color" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Color" placement="top" arrow>
              <Chip className={`color-6 ${classes.chipRoot}`} label="green" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;nibh sit&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Pronoun" placement="top" arrow>
              <Chip className={`color-1 ${classes.chipRoot}`} label="I" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;amet&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Determinant" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="the" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;commodo nulla facilisi nullam for&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Product" placement="top" arrow>
              <Chip
                className={`color-5 ${classes.chipRoot}`}
                label="McLaren 720s"
              />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;ipsum a arcu cursus vitae congue like adipiscing elit duis
          tristique sollicitud&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Color" placement="top" arrow>
              <Chip className={`color-6 ${classes.chipRoot}`} label="green" />
            </Tooltip>
          </MuiThemeProvider> */}
        </p>
        <div
          className={`play-image ${
            isQAndA && !isItemDetail ? "chart-wrapper" : classes.hide
          }`}
        >
          {!isQAndA ? (
            <>
              <CardMedia component="img" image={playImage} />
              <span>3:05.02</span>
            </>
          ) : (
            <AudionautCharts
              type={5}
              customStyle={{
                height: "100%",
                width: "auto",
                minWidth: "50px",
                minHeight: "50px",
              }}
              data={{
                score: Number(Math.floor(score * 100)),
                isPositive: true,
              }}
            />
          )}
        </div>
      </Box>
      <Divider />
      <Box className="footer">
        <Box
          className={classes.extractedContent}
          onClick={() => {
            showDetail(item);
          }}
        >
          {isQAndA ? (
            <>
              <Chip className="sentiment-color" label={`Author: ${author}`} />
              <Chip className="entity-color" label={source} />{" "}
            </>
          ) : (
            <>
              <Chip
                className={clsx("sentiment-color", {
                  [classes.hide]: !sentiments,
                })}
                label={`Sentiment Score: ${
                  sentiments ? sentiments.split(",").length : 0
                }`}
              />
              <Chip
                className={clsx("entity-color", {
                  [classes.hide]: !entities,
                })}
                label={`${entities ? entities.split(",").length : 0} Entities`}
              />
              <Chip
                className={clsx("word-color", {
                  [classes.hide]: !sentence,
                })}
                label={`${
                  sentence
                    ? sentence.trimStart().trimEnd().split(" ").length
                    : 0
                } Words`}
              />
            </>
          )}
        </Box>
        <ButtonPrimary
          disabled={targetExists}
          onClick={(event: any) => {
            selectMediaId(mediaId ?? mediaIdSource);
            addTargetDialog(null);
          }}
        >
          {targetExists ? "Targeted" : "Target"}
        </ButtonPrimary>
      </Box>
    </GSResultItem>
  );
};

const NoResults = withStyles(() => ({
  root: {
    marginLeft: "0px",
    marginRight: "0px",
    height: "calc(100% - 360px)",
  },
}))(GenericEmptyMessages);

const TopicsCarousel = withStyles(() => ({
  root: {
    position: "sticky",
    "& .alice-carousel__stage-item": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
}))(CarouselGeneric);

const ScrollTopRefElem = withStyles(() => ({
  root: {},
}))(Box);

const ScrollToTopElem = withStyles(() => ({
  root: {
    position: "absolute",
    right: "20px",
    bottom: "84px",
  },
}))(Box);

export default {
  useStyles,
  tooltipStyles,
  GSTablePagination,
  GSContainer,
  GSContainerResults,
  GSSummary,
  GSSummaryItem,
  GSResultItem,
  GSAddNewTarget,
  GenerateItemSearch,
  TopicsCarousel,
  NoResults,
  ScrollToTopElem,
  ScrollTopRefElem,
};
