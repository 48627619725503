import React from "react";
import { StyledComponentProps } from "@material-ui/core";
import { NearMe as SearchIcon } from "@material-ui/icons";

import SC from "./search-button.styles";

interface SearchButtonInterface {
  type: "button" | "reset" | "submit" | undefined;
  customClasses?: string;
}

const SearchButton: React.FC<SearchButtonInterface> = ({
  type,
  customClasses = "",
}) => {
  return (
    <SC.ButtonSearch type={type} className={customClasses}>
      <SearchIcon />
    </SC.ButtonSearch>
  );
};

export default SearchButton;
