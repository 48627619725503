// Dependencies
import { withStyles, Box, Theme } from "@material-ui/core";

const App = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    textAlign: "center",
    [theme.breakpoints.down(540)]: {
      minHeight: "unset !important",
      height: "100%",
    },
  },
}))(Box);

export default {
  App,
};
