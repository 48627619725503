/* eslint-disable prettier/prettier */
import React from "react";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { useSendPasswordResetRequestLazyQuery } from "graphql/types-and-hooks";

import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import SC from "./forgot-password.styles";

export type UserAccount = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [userCredentials, setUserCredentials] = React.useState<UserAccount>({
    email: "",
  });

  const [sendPasswordResetRequestQuery, { loading, error, data }] =
    useSendPasswordResetRequestLazyQuery({
      fetchPolicy: "no-cache",
      context: {
        noAuthNeeded: true,
      },
    });

  const handleUserCredentials = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setUserCredentials((oldUserCredentials) => ({
      ...oldUserCredentials,
      [name]: value,
    }));
  };

  const errorHandler = useAppErrorHandler();

  const onSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      sendPasswordResetRequestQuery({
        variables: userCredentials,
      });
    },
    [sendPasswordResetRequestQuery, userCredentials]
  );

  React.useEffect(() => {
    if (error) {
      if (isServerError(error)) {
        errorHandler(error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while requesting password recovery."
          )
        );
      }
    }

    if (data?.SendPasswordResetRequest && !emailSent) {
      setEmailSent(true);
    }
  }, [data, setEmailSent, emailSent, errorHandler, error]);

  return (
    <SC.Container>
      <ProgressIndicator open={loading} />
      <SC.Content>
        <SC.Logo />
        <SC.Title>
          {emailSent ? "Check Your Email" : "Forgot Password?"}
        </SC.Title>
        {emailSent ? (
          <SC.CheckEmail
            email={userCredentials.email}
            resendEmailHandler={onSubmit}
          />
        ) : (
          <SC.Form
            handleSubmit={onSubmit}
            credentials={userCredentials}
            handleOnChange={handleUserCredentials}
          />
        )}
      </SC.Content>
    </SC.Container>
  );
};

export default ForgotPassword;
