import React from "react";
import { withStyles, IconButton } from "@material-ui/core";

const DeleteButton = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.theming.gray.color,
    padding: "9px",
    [theme.breakpoints.down(540)]: {
      padding: "3px",
      "& svg": {
        width: "20px",
        height: "20px",
      },
    },
  },
}))(IconButton);

export default {
  DeleteButton,
};
