import { MediaFragment } from "./explore/types-and-hooks";
import { gql } from "@apollo/client";
import { PlanModel } from "components/plans-coupons-form-fields/data/models/plans-model";
import { PaymentMethodModel } from "pages/payment-management/models/payment-method";
import {
  TargetsGroupData,
  TargetData,
  BillingAddress,
  PaymentMethod,
} from "./types-and-hooks";
import { TopicItemModel, HistoryItemModel } from "graphql/corporatus/corporatus_rest_graphql.types";

export const createEmptyPaymentMethod = (): PaymentMethodModel => {
  const billingAddress: PaymentMethodModel = {
    stripePaymentMethodId: "",
    isDefault: true,
    holderName: "",
  };

  return billingAddress;
};

export const createEmptyBillingAddress = (): BillingAddress => {
  const billingAddress: BillingAddress = {
    id: "",
    firstName: "",
    lastName: "",
    address: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    countryCode: "",
    zipCode: "",
    isDefault: true,
  };

  return billingAddress;
};

export const createEmptyTargetsGroup = (): TargetsGroupData => {
  const targetsGroup: TargetsGroupData = {
    id: "",
    name: "",
    updateDate: "",
    creationDate: "",
  };

  return targetsGroup;
};

export const createEmptyTarget = (): TargetData => {
  const target: TargetData = {
    id: "",
    sourceId: "",
    name: "",
    groupId: "",
    source: "",
    sourceType: "",
    updateDate: "",
    creationDate: "",
  };

  return target;
};

export const createEmptyPlan = (): PlanModel => {
  const plan: PlanModel = {
    name: "",
    description: "",
    active: false,
    priceInCent: 0,
    audionautUnitInSec: 0,
    pricePerUnitInCent: 0,
    trialPeriodNumOfDays: 0,
  };

  return plan;
};

export const createEmptyMedia = (): MediaFragment & { sourceId: string } => {
  const media: MediaFragment & { sourceId: string } = {
    id: "",
    videoName: "",
    url: "",
    source: "",
    category: "",
    duration: "",
    views: 0,
    dateCreation: "",
    tags: [],
    author: "",
    sourceId: "",
  };

  return media;
};

export const NEW_TARGETS_GROUP_FRAGMENT_GQL = gql`
  fragment newTargetsGroup on TargetsGroupData {
    id
    name
    updateDate
    creationDate
  }
`;

export const NEW_TARGET_FRAGMENT_GQL = gql`
  fragment newTarget on TargetData {
    id
    mediaId
    name
    groupId
    source
    updateDate
    creationDate
  }
`;

export const NEW_SOURCE_URL_FRAGMENT_GQL = gql`
  fragment VideoFragment on Video {
    id
    recordId
    videoName
    url
    source
    category
    duration
    views
    status
    dateCreation
  }
`;

export interface BehaviorToAddTGInterface {
  sourceId: string;
  mediasIds: string[];
}

export interface GroupTargetTableItemInterface extends MediaFragment {
  sourceId: string;
}

export const createEmptyTopicItem = (): TopicItemModel => {
    const topicItem: TopicItemModel = {
      topic: "",
      caltech_scraper: false,
      escholarship_scraper: false,
      liberty_scraper: false,
      uvic_scraper: false,
    };

    return topicItem;
  };

  export const createEmptyHistoryItem = (): HistoryItemModel => {
    const historyItem: HistoryItemModel = {
      rid: "",
      email: "",
      session_id: "",
      query: "",
      response: "",
      resource: [],
    };

    return historyItem;
  };