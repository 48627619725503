/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from "react";
import _ from "lodash";

// Assets
import { RoleData } from "graphql/types-and-hooks";

import SC from "./role-filtering-left-pane.styles";

const filterRoles = (roles: RoleData[], searchTerm: string) => {
  return roles?.filter((role) =>
    role.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
  );
};

export interface RoleFilteringLeftPaneProps {
  roles: RoleData[];
  onFilter: (filteredRoles: RoleData[]) => void;
}

export const RoleFilteringLeftPane: React.FC<RoleFilteringLeftPaneProps> = ({
  roles,
  onFilter,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredRoles, setFilteredRoles] = React.useState(roles);

  React.useEffect(() => {
    if (roles.length > 0) {
      const newFilteredRoles = filterRoles(roles, searchTerm);

      setFilteredRoles(newFilteredRoles);
      onFilter(newFilteredRoles);
    }
  }, [searchTerm, roles, onFilter]);

  const handlerOnInputSearchBoxChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: string) => {
      setSearchTerm(value);
    },
    []
  );

  const handlerOnSearchBoxChange = React.useCallback(
    (
      event: React.ChangeEvent<unknown>,
      value: string | RoleData | (string | RoleData)[] | null
    ) => {
      if (_.isString(value)) {
        setSearchTerm(value);
      }
    },
    []
  );

  const handlerFilterOptions = (): RoleData[] => filteredRoles;

  return (
    <SC.LeftPaneContainer>
      <SC.SearchBoxFixed
        options={roles}
        filterOptions={handlerFilterOptions}
        getOptionLabel={(role) => role.name ?? ""}
        getOptionSelected={(role, currentRole) => role.id === currentRole.id}
        inputValue={searchTerm}
        onInputChange={handlerOnInputSearchBoxChange}
        onChange={handlerOnSearchBoxChange}
        textFieldProps={{
          label: "search",
        }}
      />
    </SC.LeftPaneContainer>
  );
};

export default RoleFilteringLeftPane;
