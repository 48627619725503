import {
  Theme,
  createStyles,
  makeStyles,
  Box,
  Card,
  CardMedia,
  CardActions,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tutoImage: {
      width: "100%",
      margin: "auto",
      background: "transparent !important",
      // [theme.breakpoints.down("lg")]: {
      //   width: "45% !important",
      //   margin: "auto",
      // },
      [theme.breakpoints.down(540)]: {
        width: "100% !important",
      },
    },
  })
);

const CardContainer = withStyles((theme: Theme) => ({
  root: {
    width: "100% !important",
    background: "transparent !important",
    padding: "unset !important",
    border: "unset !important",
    boxShadow: "unset !important",
    color: theme.generalStylesContainer.referrals.color.main,
  },
}))(Card);

const TooltipDescription = withStyles((theme: Theme) => ({
  root: {
    background: "transparent !important",
    border: "unset !important",
    boxShadow: "unset !important",
    fontSize: theme.fontGeneralStyles.medium.fontSize,
    lineHeight: theme.fontGeneralStyles.medium.lineHeight,
    color: theme.generalStylesContainer.referrals.color.main,
    padding: "32px 20px",
    "& .title": {
      fontWeight: theme.typography.h1.fontWeight,
      color: theme.generalStylesContainer.referrals.color.main,
      marginBottom: "20px",
    },
    "& .description": {
      color: theme.generalStylesContainer.referrals.color.main,
      fontWeight: 500,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      padding: "12px 0px",
      "& .title": {
        marginBottom: "10px",
      },
    },
  },
}))(Box);

const TooltipButtons = withStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "end",
    background: "transparent !important",
    boxShadow: "unset !important",
    padding: "unset !important",
  },
}))(CardActions);

export default {
  useStyles,
  CardContainer,
  TooltipDescription,
  TooltipButtons,
};
