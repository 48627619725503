/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";
import { Box, Divider, Typography, useTheme } from "@material-ui/core";

// Components
import Pane from "components/pane/pane.component";
// import TextField from "components/text-field/text-field.component";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "components/autocomplete/autocomplete.component";
import List from "components/list/list.component";
import UserProfilePicture from "components/user-profile-picture/user-profile-picture.component";
import DateTimeField from "components/datetime-field/datetime-field.component";

// Types
import {
  UserData,
  DepartmentData,
  OrganisationData,
  GroupData,
  RoleData,
} from "graphql/types-and-hooks";

// Utils
import { resizeImage } from "utils/image";

// Assets
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SC from "./user-form-fields.styles";

interface Props {
  userData: UserData;
  isEditable: boolean;
  isProfile: boolean;
  organisations: OrganisationData[];
  departments: DepartmentData[];
  groups: GroupData[];
  onChange: (userData: UserData) => void;
}

const UserFormFields: React.FC<Props> = ({
  userData,
  isEditable,
  isProfile,
  organisations = [],
  departments = [],
  groups = [],
  onChange,
}) => {
  const theme = useTheme();
  const classes = SC.generalStyles();
  const userRoles: RoleData[] = [];
  const handleActiveStatus = () => {
    const newUserData = { ...userData };
    newUserData.active = !newUserData.active;
    onChange(newUserData);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newUserData = { ...userData };

    switch (name) {
      case "workAddress":
        newUserData.work = { ...newUserData.work, address: value };
        break;
      // case "workPhone":
      //   newUserData.work = { ...newUserData.work, phone: value };
      //   break;
      // // case "workName":
      //   newUserData.organisation = { ...newUserData.organisation, name: value };
      //   break;
      // case "departmentName":
      //   newUserData.department = { ...newUserData.department, name: value };
      //   break;
      default:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newUserData[name] = value;
        break;
    }

    onChange(newUserData);
  };

  const handleAutocompleteDepartmentChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: DepartmentData | null
  ) => {
    const newUserData = { ...userData, department: value };
    onChange(newUserData);
  };

  const handleAutocompleteOrganisationChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: OrganisationData | null
  ) => {
    const newUserData = { ...userData, organisation: value };
    onChange(newUserData);
  };

  const handleAutocompleteGroupChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: GroupData[] | null
  ) => {
    const newUserData = { ...userData, groups: value };
    onChange(newUserData);
  };

  const handleCreationDate = (date: MaterialUiPickersDate) => {
    const newUserData = { ...userData, creationDate: date?.toISOString() };
    onChange(newUserData);
  };

  const handleExpirationDate = (date: MaterialUiPickersDate) => {
    const newUserData = { ...userData, expirationDate: date?.toISOString() };
    onChange(newUserData);
  };

  // const [selectedDate, handleExpirationDate] = React.useState<Date | null>(
  //   new Date()
  // );
  const handleInputProfilePicture: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      if (event.target.files && window.FileReader) {
        const file = event.target.files[0];

        if (file && file.type.match("image.*")) {
          const { width = 200, height = 200 } = theme.userProfilePicture;

          resizeImage(
            file,
            width as number,
            height as number,
            (result: string | Blob | File | ProgressEvent<FileReader>) => {
              const newUserData = {
                ...userData,
                [event.target.name]: result.toString(),
              };
              onChange(newUserData);
            }
          );
        }
      }
    };

  userData.groups?.forEach((group) => {
    group?.roles?.forEach((role) => {
      if (role != null && !userRoles.includes(role)) userRoles.push(role);
    });
  });
  const today = new Date().toISOString();

  return (
    <>
      <Box component="div" className={classes.profileHeader}>
        <UserProfilePicture
          name="profilePicture"
          urlPicture={userData.profilePicture!}
          isEditable={isEditable}
          onChange={handleInputProfilePicture}
        />
        <Box component="div">
          <Typography className="nameLbl">
            {userData.firstName} {userData.lastName}
          </Typography>
          <Typography className="companyLbl">
            {userData.departmentName ? userData.departmentName : "-"}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <SC.Grid
        container
        spacing={2}
        disabledMarginTop
        className={classes.formContainer}
        xl={12}
        lg={12}
        md={12}
        xs={12}
      >
        <SC.Grid item xl={6} lg={6} md={12} xs={12} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <TextField
              name="firstName"
              label="First Name"
              placeholder="John"
              variant="outlined"
              fullWidth
              required
              value={userData.firstName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
          {/* <SC.Box>
            <TextField
              name="workName"
              label="Company Name"
              placeholder="Company"
              variant="outlined"
              fullWidth
              required
              // value={userData.workName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
            <Autocomplete<OrganisationData, false, false, false>
              disabled={!isEditable}
              options={organisations}
              getOptionLabel={(userOrganisation) => userOrganisation.name ?? ""}
              getOptionSelected={(option, value) => option.id === value.id}
              textFieldProps={{ label: "Organisation" }}
              value={userData.organisation}
              onChange={handleAutocompleteOrganisationChange}
            />
          </SC.Box>
          <SC.Box>
            <TextField
              name="workPhone"
              label="Company Phone"
              variant="outlined"
              placeholder="415 555-8725"
              fullWidth
              required
              // value={userData.workPhone}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
          <SC.Box>
            <Autocomplete<GroupData, true, false, false>
              multiple
              disabled={!isEditable}
              options={groups}
              getOptionLabel={(userGroup) => userGroup.name ?? ""}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleAutocompleteGroupChange}
              textFieldProps={{
                variant: "outlined",
                label: "Groups",
                required: (userData.groups ?? []).length === 0,
                placeholder: "Select a group",
              }}
              value={userData.groups as GroupData[]}
            />
          </SC.Box> */}
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} xs={12} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <TextField
              name="lastName"
              label="Last Name"
              variant="outlined"
              placeholder="Doe"
              fullWidth
              required
              value={userData.lastName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} xs={12} disabledMarginTop>
          <SC.Box>
            <TextField
              name="email"
              label="Email Address"
              variant="outlined"
              placeholder="@corporatus.com"
              fullWidth
              type="email"
              required
              value={userData.email}
              onChange={handleTextFieldChange}
              disabled={isProfile || !isEditable}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} xs={12} disabledMarginTop>
          <SC.Box>
            <TextField
              name="departmentName"
              label="Department"
              variant="outlined"
              placeholder="Department"
              fullWidth
              value={userData.departmentName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
            {/* <Autocomplete<DepartmentData, false, false, false>
              disabled={!isEditable}
              options={departments}
              getOptionLabel={(userDepartment) => userDepartment.name ?? ""}
              getOptionSelected={(option, value) => option.id === value.id}
              textFieldProps={{ label: "Department" }}
              value={userData.department}
              onChange={handleAutocompleteDepartmentChange}
            /> */}
          </SC.Box>
        </SC.Grid>
      </SC.Grid>
      <Divider />
      <Box className={classes.subtitle}>Security</Box>
      <SC.Grid
        container
        spacing={2}
        disabledMarginTop
        xl={12}
        lg={12}
        md={12}
        xs={12}
      >
        <SC.Grid item xl={6} lg={6} md={12} xs={12} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <TextField
              name="newPassword"
              label="password"
              variant="outlined"
              placeholder="Password"
              fullWidth
              type="password"
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} xs={12} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <TextField
              name="password"
              label="Current Password"
              variant="outlined"
              placeholder="Password"
              fullWidth
              type="password"
              onChange={handleTextFieldChange}
              disabled={!isEditable}
              className={!isProfile ? classes.hide : ""}
            />
          </SC.Box>
        </SC.Grid>
      </SC.Grid>

      {/* Second Row */}
      {/* {!isProfile && (
        <SC.Grid container spacing={2}>
          <SC.Grid item xs={4} />
          <SC.Grid item xs={2}>
            <SC.InputLabelCenter>Active</SC.InputLabelCenter>
            <SC.Checkbox
              name="active"
              size="small"
              checked={!!userData.active}
              onChange={handleActiveStatus}
              disabled={isProfile || !isEditable}
            />
          </SC.Grid>
          <SC.Grid item xs={3}>
            <DateTimeField
              value={userData.creationDate}
              label="Creation Date"
              disabled
              onChange={handleCreationDate}
            />
          </SC.Grid>
          <SC.Grid item xs={3}>
            <DateTimeField
              value={userData.expirationDate}
              label="Expiration Date"
              disabled={isProfile || !isEditable}
              required
              onChange={handleExpirationDate}
              minDate={today}
            />
          </SC.Grid>
        </SC.Grid>
      )} */}

      {/* Third Row */}
      {/* <SC.Grid container spacing={2}>
        <SC.Grid item xs={4}>
          <Autocomplete<GroupData, true, false, false>
            multiple
            disabled={isProfile || !isEditable}
            options={groups}
            getOptionLabel={(userGroup) => userGroup.name ?? ""}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={handleAutocompleteGroupChange}
            textFieldProps={{
              label: "Groups",
              required: (userData.groups ?? []).length === 0,
            }}
            value={userData.groups as GroupData[]}
          />
        </SC.Grid>
        <SC.Grid item xs={4}>
          <SC.InputLabel>Inherited Roles</SC.InputLabel>
          <List<RoleData>
            // isEditable={!isEditable}
            items={userRoles}
            getItemKey={(userRole) => userRole.id ?? ""}
            getItemLabel={(userRole) => userRole.name ?? ""}
            dense
          />
        </SC.Grid>
        <SC.Grid item xs={4}> */}
      {/* <TextField
            name="homeAddress"
            label="Home Address"
            placeholder="Carlsbad, CA"
            fullWidth
            disabled={!isEditable}
          /> */}
      {/* </SC.Grid>
      </SC.Grid> */}

      {/* <SC.Grid container spacing={2}>
        <SC.Grid item xs={4}>
          <TextField
            name="workAddress"
            label="Work Address"
            placeholder="Carlsbad, CA"
            fullWidth
            required
            value={userData.work?.address}
            onChange={handleTextFieldChange}
            disabled={!isEditable}
          />
        </SC.Grid>
        <SC.Grid item xs={4}>
          <TextField
            name="workPhone"
            label="Work Phone"
            placeholder="415 555-8725"
            fullWidth
            required
            value={userData.work?.phone}
            onChange={handleTextFieldChange}
            disabled={!isEditable}
          />
        </SC.Grid>
        <SC.Grid item xs={4}>
          <TextField
            name="mobilePhone"
            label="Mobile Phone"
            placeholder="281 843-6334"
            fullWidth
            required
            value={userData.mobilePhone}
            onChange={handleTextFieldChange}
            disabled={!isEditable}
          />
        </SC.Grid>
      </SC.Grid> */}
      {/* Fourth Row */}
    </>
  );
};

export default UserFormFields;
