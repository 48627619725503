// Dependencies
import { Box, Snackbar } from "@material-ui/core";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  createTheme,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      height: "150px",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "30px",
      "& h1": {
        margin: "0px",
      },
      [theme.breakpoints.down(540)]: {
        height: "60px",
        padding: "unset",
      },
    },
  })
);

const MyProfileContent = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    padding: "0px 30px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      height: "100%",
      padding: "20px",
    },
  },
}))(Box);

const MyProfilePage = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
  },
}))(Box);

export default {
  MyProfilePage,
  MyProfileContent,
  Snackbar,
  useStyles,
};
