/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { JOBS, LABELJOB } = ActionTypes;
const actionTypes = {
  reset: 'isReseting',
  update: 'isupdating'
};
const INITIAL_STATE = {
  // read jobs
  isReadData: false,
  onReadError: null,
  onReadSuccess: [],
  // Delete jobs
  isDeletingData: false,
  onDeleteError: null,
  onDeleteSuccess: null,
  isUpdatingJob: false,
  onUpdateSuccess: null,
  onUpdateError: null
};

const jobsReducerFn = (state = INITIAL_STATE, action) => {
  let updated = null;
  switch (action.type) {
    // Read Jobs from db
    case JOBS.READ.START:
      return {
        ...state,
        isReadData: true,
        onUpdateError: null
      };
    case JOBS.READ.SUCCESS: {
      return {
        ...state,
        onReadSuccess: action.payload,
        isReadData: false,
      };
    }
    case JOBS.READ.FAILURE:
      return {
        ...state,
        isReadData: false,
        onReadError: action.payload,
      };
    case LABELJOB.ADDED:
      // eslint-disable-next-line
      const { newJob } = action.payload;
      return {
        ...state,
        onReadSuccess: [newJob, ...state.onReadSuccess]
      };
    case JOBS.READ.UPDATE:
      // eslint-disable-next-line
      const { job: jobUpdated } = action.payload;
      // eslint-disable-next-line
      const updatedJobs = state.onReadSuccess.map(job => {
        return job.ID === action.payload.jobID ? jobUpdated : job;
      });
      return {
        ...state,
        onReadSuccess: updatedJobs
      };
    case JOBS.UPDATE.CLEAR:
      return {
        ...state,
        onReadError: null,
        onReadSuccess: null
      };
    // update job
    case LABELJOB.UPDATE.START: {
      const newData = [...state.onReadSuccess];
      const originalRow = newData.find(r => r.ID === action.payload.jobID);
      originalRow[actionTypes[action.payload.method]] = true;
      return {
        ...state,
        isUpdatingJob: true,
        onUpdateSuccess: null,
        onUpdateError: null,
        onReadSuccess: [...newData]
      }; }
    case LABELJOB.UPDATE.SUCCESS: {
      const newData = [...state.onReadSuccess];
      const originalRow = newData.find(r => r.ID === action.payload.jobID);
      if (action?.payload?.method === 'reset') {
        originalRow.statuEnum = originalRow.statuEnum === 6 ? 6 : 5;
        originalRow.labeledCount = 0;
      } else {
        originalRow.isEditable = false;
        originalRow.comments = action?.payload?.comments ?? originalRow.comments;
        originalRow.classificationEnum = action?.payload?.classificationEnum ?? originalRow.classificationEnum;
        originalRow.labels = action.payoad?.labels ?? originalRow.labels;
      }
      originalRow[actionTypes[action.payload.method]] = false;
      return {
        ...state,
        isUpdatingJob: false,
        onUpdateSuccess: action.payload,
        onUpdateError: null,
        onReadSuccess: [...newData]
      }; }
    case LABELJOB.UPDATE.ERROR: {
      const newData = [...state.onReadSuccess];
      const originalRow = newData.find(r => r.ID === action.payload.jobID);
      originalRow[actionTypes[action.payload.method]] = false;
      return {
        ...state,
        isUpdatingJob: false,
        onUpdateSuccess: null,
        onUpdateError: action.payload,
        onReadSuccess: [...newData]
      }; }
    // Delete JOBS
    case JOBS.DELETE.START:
      return {
        ...state,
        isDeletingData: true,
        onDeleteError: null
      };
    case JOBS.DELETE.SUCCESS: {
      return {
        ...state,
        onDeleteSuccess: action.payload,
        isDeletingData: false
      };
    }
    case JOBS.DELETE.FAILURE:
      return {
        ...state,
        isDeletingData: false,
        onDeleteError: action.payload
      };
    case JOBS.DELETE.CLEAR:
      return {
        ...state,
        onDeleteError: null,
        onDeleteSuccess: null
      };
    case JOBS.START.SUCCESS:
      return {
        ...state,
        start: true,
        quit: false
      };
    case JOBS.QUIT.SUCCESS:
      return {
        ...state,
        start: false,
        quit: true
      };
    case JOBS.UPDATE.LOCAL.USER:
      updated = state.onReadSuccess;
      updated = updated.map(job => {
        const index = action.payload.indexOf(updatedJob => job.ID === updatedJob.ID);
        if (index !== -1) {
          return ({ ...job, user: updatedJobs[index].user });
        }
        return (job);
      });
      return {
        ...state,
        onReadSuccess: updated
      };
    default: return state;
  }
};


export default jobsReducerFn;
