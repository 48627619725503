import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";

import { CLIENT_NAME } from "graphql/client";
import {
  useGetSourcesQuery,
  SourceFragment,
} from "graphql/explore/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

import CachedIcon from "@material-ui/icons/Cached";

import AudioSourceItem from "components/audio-source-item/audio-source-item.component";
import { isMobileResolution } from "commons/utils/device-info.util";

import SC from "./audio-sources.styles";

const AudioSourcesPage: React.FC = () => {
  const classes = SC.useStyles();

  const [refreshPlatforms, setRefreshPlatforms] = React.useState(false);
  const [existsVideos, setExistsVideos] = React.useState(true);
  const [videosLoading, setVideosLoading] = React.useState(false);

  const {
    data: platformsDataReceived,
    loading: platformsLoading,
    error: platformError,
    refetch: platformsRefetch,
  } = useGetSourcesQuery({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
  });

  // const errorHandler = useAppErrorHandler(platformError);
  const loading = platformsLoading;

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [rows, setRows] = useState<any>([]);

  const platformsData: SourceFragment[] = [
    {
      sourceName: "YOUTUBE",
      proccesedAudio: "00:00:00",
    },
    {
      sourceName: "TIKTOK",
      proccesedAudio: "00:00:00",
    },
    {
      sourceName: "PODCAST",
      proccesedAudio: "00:00:00",
    },
  ];

  if (platformsDataReceived?.getSourcesInfo) {
    if (
      platformsDataReceived?.getSourcesInfo &&
      platformsDataReceived?.getSourcesInfo.length > 0
    ) {
      platformsDataReceived?.getSourcesInfo.forEach((platform: any) => {
        const currentIndex = platformsData.findIndex(
          (x) => x.sourceName === platform.sourceName
        );
        platformsData[currentIndex] = platform;
      });
    }
    // setRows(platformsData);
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <SC.ASContainer>
      {/* <ProgressIndicator open={loading}/> */}
      {loading ? (
        <Box
          style={{
            backgroundColor: "rgba(0,0,0,.1)",
            display: "flex",
            width: "100%",
          }}
        >
          <CircularProgress style={{ margin: "auto" }} />
        </Box>
      ) : (
        <SC.ASContent>
          <Box component="div" className={classes.titleContainer}>
            <h1>Audio Sources</h1>
            <SC.ButtonPrimary
              disabled={videosLoading}
              startIcon={<CachedIcon />}
              onClick={() => {
                setRefreshPlatforms(true);
                setTimeout(() => {
                  setRefreshPlatforms(false);
                }, 1000);
              }}
            >
              {!isMobileResolution() ? "Refresh" : ""}
            </SC.ButtonPrimary>
          </Box>
          {/* <Paginator
            totalItems={rows.length}
            updateItems={setRowsPerPage}
            updatePage={setPage}
          /> */}

          <TableContainer component={Paper} className={classes.tableContainer}>
            <SC.Table aria-label="custom pagination table">
              <TableBody>
                {(rowsPerPage > 0
                  ? platformsData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : platformsData
                ).map((row: SourceFragment) => (
                  <TableRow key={`collapsable-platform-${row.sourceName}`}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.rowStyle}
                    >
                      <AudioSourceItem
                        itemData={row as { id: string } & SourceFragment}
                        refetch={platformsRefetch}
                        reloadVideos={refreshPlatforms}
                        existsVideos={setExistsVideos}
                        setVideosLoading={setVideosLoading}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </SC.Table>
          </TableContainer>

          <SC.NoResults
            show={!existsVideos && !loading}
            title="Ingest audio from your preferred audio platform"
            description="Press the + icon to add audio URLs and start transcribe it!"
          />
        </SC.ASContent>
      )}
    </SC.ASContainer>
  );
};

export default AudioSourcesPage;
