// Dependencies
import {
  withStyles,
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingLogo: {
      paddingLeft: "12px",
      paddingRight: "13px",
    },
  })
);

const BannerGraphic = withStyles((theme) => ({
  root: {
    // maxWidth: "210px",
    maxHeight: "60px",
    height: "100%",
    width: "100%",
    boxShadow: "none",
    backgroundColor: theme.generalStylesContainer.logo.backgroundColor,
    cursor: "pointer",
    margin: "auto",
    "& button": {
      display: "flex",
      height: "100%",
      pointerEvents: "none",
      flexDirection: "column",
      padding: "8px 16px",
      // [theme.breakpoints.down("xl")]: {
      //   paddingTop: theme.bannerGraphicSmall.paddingTop,
      //   paddingRight: theme.bannerGraphicSmall.paddingRight,
      //   paddingBottom: theme.bannerGraphicSmall.paddingBottom,
      //   paddingLeft: theme.bannerGraphicSmall.paddingLeft,
      // },
      // [theme.breakpoints.up("xl")]: {
      //   paddingTop: theme.bannerGraphic.paddingTop,
      //   paddingRight: theme.bannerGraphic.paddingRight,
      //   paddingBottom: theme.bannerGraphic.paddingBottom,
      //   paddingLeft: theme.bannerGraphic.paddingLeft,
      // },
      [theme.breakpoints.down(540)]: {
        width: "100px",
        alignItems: "start",
      },
    },
    [theme.breakpoints.down(540)]: {
      backgroundColor: theme.generalStylesContainer.logo.backgroundColor,
      maxWidth: "unset",
      margin: "unset",
      borderRadius: "unset",
    },
  },
}))(Card);

const Logo = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    // maxWidth: "210px",
    backgroundSize: "contain !important",
    // paddingTop: "36px",
    // paddingBottom: "36px",
    [theme.breakpoints.down(540)]: {
      backgroundPosition: "left",
    },
  },
}))(CardMedia);

const TextLogoContent = withStyles({
  root: {
    padding: "0",
  },
})(CardContent);

const Text = withStyles((theme) => ({
  root: {
    marginBottom: "0",
    fontWeight: theme.bannerGraphicText.fontWeight,
    color: theme.bannerGraphicText.color,
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.bannerGraphicTextSmall.fontSize,
      lineHeight: theme.bannerGraphicTextSmall.lineHeight,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.bannerGraphicText.fontSize,
      lineHeight: theme.bannerGraphicText.lineHeight,
    },
  },
}))(Typography);

export default {
  BannerGraphic,
  Logo,
  TextLogoContent,
  Text,
  useStyles,
};
