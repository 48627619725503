import React from "react";

import { Box } from "@material-ui/core";

import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import ButtonSecondary from "components/button-secondary/button-secondary.component";

interface AddTargetBtnProps {
  behaviorData: any;
  togleModal: any;
  targetData: any;
  setTargetData: any;
  customClasses?: string;
}
const AddTargetBtn: React.FC<AddTargetBtnProps> = ({
  behaviorData,
  togleModal,
  targetData,
  setTargetData,
  customClasses,
}) => {
  const {
    actions: { target },
  } = behaviorData;

  return (
    <Box display="flex" className={customClasses}>
      <AppPermissionValidator
        appPermission={APP_PERMISSION.TARGET_TARGET_MEDIA}
      >
        <ButtonSecondary
          disabled={target}
          onClick={(event: any) => {
            setTargetData(
              behaviorData.behaviorId
                ? {
                    sourceId: behaviorData.behaviorId,
                    mediasIds: targetData,
                  }
                : behaviorData
            );
            togleModal(event);
          }}
        >
          {target ? "Targeted" : "Target"}
        </ButtonSecondary>
      </AppPermissionValidator>
    </Box>
  );
};

export default AddTargetBtn;
