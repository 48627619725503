/* eslint-disable */
// Dependencies
import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
// Reducers
import filterReducerFn from './reducers/filter.reducer';
import modebarReducerFn from './reducers/modebar.reducer';
import frameReducerFn from './reducers/frame.reducer';
import authReducerFn from './reducers/auth.reducer';
import routeReducerFn from './reducers/route.reducer';
import helpReducerFn from './reducers/help.reducer';
import fetchApiSchemaReducerFn from './reducers/fetchApiSchema.reducer';
import fetchGraphQLDataReducerFn from './reducers/fetchGraphQLData.reducer';
import slidyReducerFn from './reducers/slidy.reducer';
import organizationsReducerFn from './reducers/organizations.reducer';
import rolesReducerFn from './reducers/roles.reducer';
import createUserReducerFn from './reducers/createUser.reducer';
import groupsReducerFn from './reducers/groups.reducer';
import getUsersReducerFn from './reducers/getUsers.reducer';
import usersReducerFn from './reducers/users.reducer';
import orgFilterReducerFn from './reducers/orgFilter.reducer';
import queryTbStoresReducerFn from './reducers/getTbStores.reducer';
import createTbStoreReducerFn from './reducers/createTbStore.reducer';
import mutationsTbStoresReducerFn from './reducers/tbStores.reducer';
import createSsoUrlReducerFn from './reducers/createSsoUrl.reducer';
import getLooksAndDashboardsReducerFn from './reducers/getLooksAndDashboards.reducer';
import identityProvidersReducerFn from './reducers/identityProviders.reducer';
import protocolsReducerFn from './reducers/protocols.reducer';
import jobsReducerFn from './reducers/labelingJobs.reducer';
import jobFilterReducerFn from './reducers/jobFilter.reducer';
import labelClassesReducerFn from './reducers/labelClasses.reducer';
import trainingJobsReducerFn from './reducers/trainingJobs.reducer';
import trainingJobCreationFilterReducerFn from './reducers/trainingJobCreationFilter.reducer';
import jobsForTrainingCreationFilteredReducerFn from './reducers/jobsForTraininCreationFiltered.reducer';
import trainedModelFilterReducerFn from './reducers/trainedModelsFilter.reducer';
import machineTypesReducerFn from './reducers/machineTypes.reducer';
import gdriveDocumentsReducerFn from './reducers/gdriveDocuments.reducer';
import gdriveVersionsReducerFn from './reducers/gdriveVersions.reducer';
// blacklist to exclude filters
const filterPersistConfig = {
  key: 'filter',
  storage,
  blacklist: ['filterTrainingJobsBy'],
  whitelist: ['filter', 'filterBy', 'filterEventsBy', 'graphFilter', 'group', 'searchText'],
};
const rootReducer = combineReducers({
  filter: persistReducer(filterPersistConfig, filterReducerFn),
  modebar: modebarReducerFn,
  frame: frameReducerFn,
  auth: authReducerFn,
  route: routeReducerFn,
  help: helpReducerFn,
  jsonSchema: fetchApiSchemaReducerFn,
  graphQLData: fetchGraphQLDataReducerFn,
  slidy: slidyReducerFn,
  organizations: organizationsReducerFn,
  roles: rolesReducerFn,
  createUser: createUserReducerFn,
  groups: groupsReducerFn,
  getUsers: getUsersReducerFn,
  users: usersReducerFn,
  orgFilter: orgFilterReducerFn,
  queryTbStores: queryTbStoresReducerFn,
  createTbStore: createTbStoreReducerFn,
  mutationsTbStores: mutationsTbStoresReducerFn,
  ssoUrl: createSsoUrlReducerFn,
  lookerLooksAndDashboards: getLooksAndDashboardsReducerFn,
  identityProviders: identityProvidersReducerFn,
  protocols: protocolsReducerFn,
  jobs: jobsReducerFn,
  jobFilter: jobFilterReducerFn,
  labelClasses: labelClassesReducerFn,
  trainingJobs: trainingJobsReducerFn,
  trainingJobCreationFilter: trainingJobCreationFilterReducerFn,
  jobsForTrainingCreationFiltered: jobsForTrainingCreationFilteredReducerFn,
  trainedModelsFilter: trainedModelFilterReducerFn,
  machineTypes: machineTypesReducerFn,
  gdriveDocuments: gdriveDocumentsReducerFn,
  gdriveVersions: gdriveVersionsReducerFn,
});

export default rootReducer;
