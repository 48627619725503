/* eslint-disable react/jsx-props-no-spreading */
import React, { RefObject } from "react";

import {
  Backdrop,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Typography,
  Zoom,
} from "@material-ui/core";
import { TransitionProps as ModalTransitionProps } from "@material-ui/core/transitions";
import ButtonPrimary from "components/button-primary/button-primary.component";
import ButtonSecondary from "components/button-secondary/button-secondary.component";

import { InfoTooltipInterface } from "./info-tooltip.types";

import SC from "./info-tooltip.styles";

const Transition = React.forwardRef(function Transition(
  props: ModalTransitionProps,
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const InfoTooltip: React.FC<InfoTooltipInterface> = ({
  open,
  eleRef = null,
  content,
  showArrow = false,
  isModal = false,
  customClasses = "",
}) => {
  const classes = SC.useStyles();
  const arrowRef = React.useRef<HTMLElement>(null);

  return isModal ? (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={customClasses}
    >
      <DialogContent className={classes.tooltipContainer}>
        {content}
      </DialogContent>
    </Dialog>
  ) : (
    <SC.BackdropTooltip open={open} className={customClasses}>
      <SC.TooltipContainer
        open={open}
        anchorEl={eleRef ? eleRef.current : null}
        transition
        disablePortal
        placement="bottom"
        modifiers={[
          {
            name: "flip",
            enabled: true,
          },
          {
            name: "offset",
            enabled: true,
            options: {
              offset: [0, 30],
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              boundariesElement: "scrollParent",
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef.current,
            },
          },
        ]}
        className={classes.tooltipContainer}
      >
        {({ TransitionProps, placement }) => (
          <SC.TransitionAnimation {...TransitionProps}>
            <SC.TooltipContent>
              {content}
              <SC.TooltipArrow
                hidden={!showArrow}
                component="span"
                className={classes.arrow}
                ref={arrowRef}
              />
            </SC.TooltipContent>
          </SC.TransitionAnimation>
        )}
      </SC.TooltipContainer>
    </SC.BackdropTooltip>
  );
};

export default InfoTooltip;
