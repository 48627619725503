/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { HELP_API_DOC } = ActionTypes;

const INITIAL_STATE = {
  documentData: {},
  selectedOption: null,
  isReceivingHelpData: false,
  error: null,
};


const helpReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HELP_API_DOC.START:
      return {
        ...state,
        isReceivingHelpData: true,
        error: null
      };

    case HELP_API_DOC.SUCCESS: {
      return {
        ...state,
        documentData: action.payload,
        selectedOption: action.selectedOption,
        isReceivingHelpData: false,
      };
    }

    case HELP_API_DOC.FAILURE:
      return {
        ...state,
        isReceivingHelpData: false,
        error: action.payload,
      };

    default: return state;
  }
};

export default helpReducerFn;
