import {
  withStyles,
  Box as BoxBase,
  Container as ContainerBase,
  Typography,
} from "@material-ui/core";

const Container = withStyles((theme) => ({
  root: {
    justifyContent: "flex-start",
    backgroundColor: theme.accordionBody.backgroundColor,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: "100%",
    height: "100%",
    overflow: "inherit",
    padding: "11px 13px",
  },
}))(ContainerBase);

const Box = withStyles(() => ({
  root: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
}))(BoxBase);

const Label = withStyles((theme) => ({
  root: {
    padding: 5,
    alignSelf: "flex-start",
    textAlign: "left",
    fontWeight: 500,
    fontSize: theme.leftPane.fontSize,
  },
}))(Typography);

export default { Container, Box, Label };
