/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  createStyles,
  withStyles,
  makeStyles,
  TablePagination,
  Box,
  Button,
  Theme,
  createTheme,
  Grid,
} from "@material-ui/core";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";

const useContainerStyles = makeStyles((theme) => ({
  title: {
    "& h1": {
      textAlign: "left",
    },
    pagination: {
      flex: "1",
    },
  },
}));

const drawerWidth = "300px";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    headerSS: {
      width: "100%",
      background: "#FAFAFA",
      marginBottom: "10px",
      paddingLeft: "32px",
      paddingRight: "32px",
      "&.drawerOpened": {
        width: "calc(100% - 62px - 264px)",
      },
      "&.drawerClosed": {
        width: "calc(100% - 62px - 73px)",
      },
      [theme.breakpoints.down(540)]: {
        padding: "unset",
      },
    },
    drawer: {
      width: drawerWidth,
      padding: "0px 15px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    },
    drawerOpen: {
      top: "auto !important",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: "relative",
      borderRight: "unset",
      "& div": {
        boxShadow: "unset",
      },
    },
    drawerClose: {
      position: "relative",
      borderRight: "unset",
      "& div": {
        boxShadow: "unset",
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      top: "auto !important",
      overflowX: "hidden",
      width: "0px",
      [theme.breakpoints.up("sm")]: {
        width: "0px",
      },
      padding: "0px",
    },
    datesContainer: {
      justifyContent: "space-around",
    },
    iconFilter: {
      height: "50px",
      width: "50px",
    },
    iconFilterActive: {
      color: "#8773B1",
    },
    iconFilterInactive: {
      color: "#A2A2A2",
    },
    hide: {
      display: "none",
    },
    show: {
      display: "block",
    },
    itemDetailContainer: {
      padding: "30px",
    },
    itemDetailHeader: {
      height: "120px",
      background: "transparent",
      boxShadow: "none",
      padding: "0px",
      marginBottom: "15px",
      "& .header": {
        height: "100%",
        display: "flex",
        marginBottom: "1em",
        "& .titles": {
          flex: "1",
          display: "flex",
          flexDirection: "column",
          "& .title": {
            flex: "1",
            fontSize: "26px",
          },
          "& .date": {
            fontSize: "18px",
          },
        },
        "& .play-image": {
          width: "65px",
          height: "100%",
          display: "grid",
          flexDirection: "column",
          "& span": {
            fontSize: "18px",
            color: "#D83651",
            paddingTop: "18px",
          },
        },
      },
    },
    pointer: {
      cursor: "pointer",
    },
    chipRoot: {
      height: "fit-content",
      lineHeight: "1.2",
      "& span": {
        color: "#1C1C1C",
        padding: "0px 5px",
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    itemDetailContent: {
      height: "fit-content",
      "& .content": {
        height: "auto",
        "& p": {
          lineHeight: "2",
          maxHeight: "calc(100% - 1em)",
        },
      },
    },
    containerCardImg: {
      margin: "auto",
      marginRight: "auto !important",
      marginBottom: "50px",
      "& img": {
        maxHeight: "300px",
      },
    },
    cardImg: {
      height: "100%",
      margin: "auto",
      objectFit: "contain",
    },
    SSContent: {
      width: "100%",
      height: "calc(100% - 140px)",
      minHeight: "350px",
      paddingLeft: "32px",
      paddingRight: "32px",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      padding: "30px 0px",
      [theme.breakpoints.down(540)]: {
        padding: "unset",
      },
    },
    flexRow: {
      flex: "1",
    },
    datePickerContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    dateField: {
      marginRight: "30px",
      width: 230,
      borderRadius: "4px",
      boxSizing: "border-box",
      marginBottom: "20px",
      color: "#6D6D6D",
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "8px",
      },
      "& div:before": {
        border: "none",
        borderBottom: "none !important",
      },
      "& div:after": {
        borderBottom: "none !important",
      },
      "& div input": {
        height: "45px",
        color: "#6D6D6D",
        padding: "0px",
        paddingLeft: "8px",
      },
      "& div button": {
        padding: "0px",
      },
      "& .MuiInputBase-root:hover": {
        "& fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
      [theme.breakpoints.down(540)]: {
        flex: "1",
        margin: "unset",
        marginTop: "10px",
      },
    },
    headerCard: {
      display: "flex",
      alignItems: "center",
      marginBottom: "66px",
      "& .title": {
        marginLeft: "10px",
        flex: "1",
      },
      "& img": {
        width: "36px",
        height: "36px",
      },
    },
    moreBtn: {
      height: "42px",
      width: "185px",
      border: "2px solid #929292",
      boxSizing: "border-box",
      borderRadius: "116px",
      fontSize: "18px",
      lineHeight: "22px",
      color: "#767676",
    },
    chartContainer: {
      paddingBottom: "25px !important",
    },
    adviseMessage: {
      flex: "1",
      "& .MuiPaper-root": {
        height: "100%",
        background: "transparent",
        boxShadow: "unset",
        paddingLeft: "32px",
        paddingRight: "32px",
      },
      [theme.breakpoints.down(540)]: {
        padding: "unset",
      },
    },
  })
);

const modalUseStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      margin: "10vh",
    },
    paper: {
      position: "absolute",
      height: "80vh",
      width: "80vh",
      padding: "20px",
      background: "#FFFFFF",
      boxSizing: "border-box",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)",
      borderRadius: "6px",
      "& h2": {
        marginTop: "0px",
      },
      "& .itemSelectedRoot": {
        display: "flex",
        position: "absolute",
        bottom: "40px",
      },
      "& .circle": {
        height: "14px",
        width: "14px",
        borderRadius: "7px",
        background: "#973B7B",
      },
      "& .itemSelected": {
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "19px",
        color: "#696969",
        paddingLeft: "30px",
      },
    },
  })
);

const tooltipStyles = (createTheme as any)({
  overrides: {
    MuiTooltip: {
      arrow: {
        "&:before": {
          backgroundColor: "#484848",
        },
      },
      tooltip: {
        fontSize: "10px",
        fontWeight: "500",
        backgroundColor: "#484848",
      },
    },
  },
});

interface Props {
  component: string;
  count: number;
  page: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  rowsPerPage: number;
  onChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

// const GSTablePagination = withStyles(() => ({
//   container: {
//     textAlign: "left",
//   },
// }))(TablePagination) as typeof TablePagination;

const GSTablePagination = (props: Props) => {
  const { ...muiProps } = props;
  const classes = useContainerStyles(props);
  return (
    <TablePagination
      className={classes.title}
      style={{ flex: "1" }}
      {...muiProps}
    />
  );
};

const GSContainer = withStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "hidden",
  },
}))(Box);

const GSContainerResults = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "30px 32px",
    paddingLeft: "0px",
    paddingRight: "0px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
      marginBottom: "11px",
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px",
    },
  },
}))(Box);

const GSSummary = withStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    // marginBottom: "20px",
    "& div:first-child": {
      marginLeft: "0px",
    },
    "& div:last-child": {
      marginRight: "0px",
      marginBottom: "0px",
    },
  },
}))(Box);

const GSSummaryItem = withStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    maxHeight: "350px",
    padding: "22px",
    paddingTop: "19px !important",
    // paddingBottom: "45px !important",
    flexDirection: "column",
    flex: "1",
    marginLeft: "10px",
    marginRight: "10px",
    "& span.title": {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "20px",
      textAlign: "left",
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
    },
    "& .main": {
      flex: "1",
      display: "flex",
      marginBottom: "16px",
      "& div": {
        margin: "auto",
        "& span": {
          fontSize: "80px",
          lineHeight: "100px",
          fontWeight: "800",
        },
      },
    },
    "& .footer": {
      color: "#696969",
      fontSize: "24px",
      lineHeight: "30px",
      marginBottom: "0px",
      fontWeight: "800",
      marginTop: "0px",
    },
  },
}))(Box);

const GSResultItem = withStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    padding: "15px 45px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginLeft: "10px",
    marginRight: "10px",
    "& .header": {
      display: "contents",
      "& .title": {
        fontSize: "18px",
        fontWeight: "800",
        lineHeight: "22px",
        textAlign: "left",
      },
      "& .date": {
        fontSize: "11px",
        textAlign: "left",
        fontWeight: "400",
        color: "#ABABAB",
      },
    },
    "& .content": {
      height: "70px",
      flex: "1",
      display: "flex",
      marginBottom: "15px",
      "& p": {
        maxHeight: "70px",
        height: "100%",
        textAlign: "left",
        fontSize: "16px",
        fontWeight: "noormal",
        color: "#1C1C1C",
        paddingRight: "50px",
        overflow: "hidden",
        "& em": {
          fontStyle: "normal",
        },
        "& .green": {
          color: "#60C3A6",
        },
        "& .purple": {
          color: "#945AA9",
        },
        "& .color-1": {
          background: "#D4DCF3",
        },
        "& .color-2": {
          background: "#F4E2FB",
        },
        "& .color-3": {
          background: "#DFCEEE6",
        },
        "& .color-4": {
          background: "#FCF6C6",
        },
        "& .color-5": {
          background: "#EADEEE",
        },
        "& .color-6": {
          background: "#CFEDE4",
        },
        "& .color-7": {
          background: "#C6DBEE",
        },
        "& .MuiChip-root": {
          height: "auto",
          "& span": {
            padding: "0px 5px",
            fontSize: "16px",
            fontWeight: "noormal",
            color: "#1C1C1C",
          },
        },
      },
      "& .red": {
        background: "red",
      },
      "& .play-image": {
        minWidth: "50px",
        width: "50px",
        marginTop: "1em",
        "& span": {
          color: "#D83651",
          fontSize: "12px",
          fontWeight: "normal",
        },
      },
    },
    "& .footer": {
      display: "flex",
      marginTop: "15px",
      "& .MuiChip-root": {
        color: "#575757",
        marginRight: "30px",
        height: "20px",
        width: "125px",
        fontSize: "11px",
        fontWeight: "500",
      },
      "& .sentiment-color": {
        backgroundColor: "#df71713d",
      },
      "& .entity-color": {
        backgroundColor: "#aa8bec3d",
      },
      "& .word-color": {
        backgroundColor: "#71c5df3d",
      },
    },
  },
}))(Box);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    margin: "auto",
    marginLeft: "0px !important",
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
    [theme.breakpoints.down(540)]: {
      width: "100%",
    },
  },
}))(Button);

const NoResults = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    marginLeft: "0px",
    marginRight: "0px",
  },
}))(GenericEmptyMessages);

const DatepickerContainer = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      columnGap: "10px",
      rowGap: "20px",
    },
  },
}))(Grid);

const SummaryCarouselGeneric = withStyles(() => ({
  root: {
    position: "sticky",
    "& .alice-carousel__stage-item": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
}))(CarouselGeneric);

export default {
  GSTablePagination,
  GSContainer,
  GSContainerResults,
  GSSummary,
  GSSummaryItem,
  GSResultItem,
  ButtonPrimary,
  useStyles,
  modalUseStyle,
  tooltipStyles,
  NoResults,
  DatepickerContainer,
  SummaryCarouselGeneric,
};
