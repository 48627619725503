/* eslint-disable */
import { ActionTypes } from '../actions/types';
import { FILTER_INITIAL_DATE_RANGE } from '../../commons/constants/index';

const appInitialState = {
  filter: [],
  group: [],
  graphFilter: [],
  filterEventsBy: {
    startDate: '2017-10-01',
    endDate: '2017-12-31'
  },
  searchText: null,
  filterTrainingJobsBy: [
    {
      id: 'updateDatetimeUTC',
      value: [FILTER_INITIAL_DATE_RANGE.startDate, FILTER_INITIAL_DATE_RANGE.endDate]
    }
  ],
  filterBy: []
};

const filterReducerFn = (state = appInitialState, action) => {
  switch (action.type) {
    case ActionTypes.FILTER.UPDATE:
      return {
        ...state,
        filter: action.payload.newFilterData,
        graphFilter: action.payload.newGraphFilterData
      };
    case ActionTypes.FILTER.GROUP.UPDATE: {
      const item = action.payload;
      const groupValues = state.group;
      let newGroupValues = groupValues.slice();
      const newGraphFilter = state.graphFilter.slice();
      const graphFilterElem = newGraphFilter.find(elem => elem.value === item);
      // Remove from state
      if (groupValues.includes(item)) {
        newGroupValues = newGroupValues.filter(elem => elem !== item);
        if (graphFilterElem) {
          graphFilterElem.group = false;
        }
      } else {
        // Add to state
        newGroupValues.push(item);
        if (graphFilterElem) {
          graphFilterElem.group = true;
        }
      }
      return {
        ...state,
        group: newGroupValues,
        graphFilter: newGraphFilter
      };
    }
    case ActionTypes.FILTER.USERS.BY: {
      return {
        ...state,
        filterBy: action.payload
      };
    }
    case ActionTypes.FILTER.EVENTS.BY: {
      return {
        ...state,
        filterEventsBy: action.payload
      };
    }
    case ActionTypes.FILTER.GROUPS.BY: {
      return {
        ...state,
        filterGroupsBy: action.payload
      };
    }
    case ActionTypes.FILTER.IDENTITY_PROVIDERS.BY: {
      return {
        ...state,
        filterIdentityProvidersBy: action.payload
      };
    }
    case ActionTypes.FILTER.LABELING_JOBS.BY: {
      return {
        ...state,
        filterLabelingJobsBy: action.payload
      };
    }
    case ActionTypes.FILTER.SEARCH_TEXT.SET: {
      return {
        ...state,
        searchText: action.payload
      };
    }
    case ActionTypes.FILTER.TRAINING_JOBS.BY: {
      return {
        ...state,
        filterTrainingJobsBy: state.filterTrainingJobsBy.find(filt => filt.id === action.payload.id)
          ? state.filterTrainingJobsBy.map(filt => (filt.id === action.payload.id
            ? action.payload
            : filt))
          : [...state.filterTrainingJobsBy, ...[action.payload]]
      };
    }
    default:
      return state;
  }
};

export default filterReducerFn;
