/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { FETCH_GRAPHQL_DATA } = ActionTypes;

const INITIAL_STATE = {
  graphQLData: {},
  isReceivingGraphQLData: false,
  error: null,
};


const fetchGraphQLDataReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_GRAPHQL_DATA.START:
      return {
        ...state,
        isReceivingGraphQLData: true,
        error: null
      };

    case FETCH_GRAPHQL_DATA.SUCCESS: {
      return {
        ...state,
        isReceivingGraphQLData: false,
      };
    }

    case FETCH_GRAPHQL_DATA.FAILURE:
      return {
        ...state,
        isReceivingGraphQLData: false,
        error: action.payload,
      };
    case FETCH_GRAPHQL_DATA.CLEAN:
      return {
        ...state,
        isReceivingGraphQLData: false,
        error: null
      };

    default: return state;
  }
};

export default fetchGraphQLDataReducerFn;
