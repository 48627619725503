import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: "Query";
  getAnswersPro?: Maybe<GetAnswerResponse>;
};

export type QueryGetAnswersProArgs = {
  query: Scalars["String"];
  sessionId: Scalars["String"];
};

export type GetAnswerResponse = AnswerPro | AnwerNotFound | AnswerResponseError;

export type AnswerPro = {
  __typename?: "AnswerPro";
  query?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  mediasCount?: Maybe<Scalars["Int"]>;
  medias?: Maybe<Array<Maybe<ModelAnswerResult>>>;
  created?: Maybe<Scalars["String"]>;
};

export type ModelAnswerResult = {
  __typename?: "ModelAnswerResult";
  modelName?: Maybe<Scalars["String"]>;
  modelAnswer?: Maybe<Scalars["String"]>;
  confidenceInPercentage?: Maybe<Scalars["Float"]>;
  speechSegment?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["Float"]>;
  endTime?: Maybe<Scalars["Float"]>;
  documentId?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  webUrl?: Maybe<Scalars["String"]>;
  audioUrl?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  views?: Maybe<Scalars["Int"]>;
  likes?: Maybe<Scalars["Int"]>;
  audioLength?: Maybe<Scalars["Int"]>;
  transcription?: Maybe<Scalars["String"]>;
  sentences?: Maybe<Array<Maybe<Sentence>>>;
  entities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  positivePercentageScore?: Maybe<Scalars["Float"]>;
  negativePercentageScore?: Maybe<Scalars["Float"]>;
  joy?: Maybe<Scalars["Float"]>;
  trust?: Maybe<Scalars["Float"]>;
  surprise?: Maybe<Scalars["Float"]>;
  anticipation?: Maybe<Scalars["Float"]>;
  fear?: Maybe<Scalars["Float"]>;
  sadness?: Maybe<Scalars["Float"]>;
  disgust?: Maybe<Scalars["Float"]>;
  anger?: Maybe<Scalars["Float"]>;
  created?: Maybe<Scalars["String"]>;
};

export type Sentence = {
  __typename?: "Sentence";
  text?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["Float"]>;
};

export type AnwerNotFound = {
  __typename?: "AnwerNotFound";
  message?: Maybe<Scalars["String"]>;
};

export type AnswerResponseError = {
  __typename?: "AnswerResponseError";
  message?: Maybe<Scalars["String"]>;
};

export type SentenceFragment = { __typename?: "Sentence" } & Pick<
  Sentence,
  "text" | "start" | "end"
>;

export type OsMediaProFragment = { __typename?: "ModelAnswerResult" } & Pick<
  ModelAnswerResult,
  | "modelName"
  | "modelAnswer"
  | "confidenceInPercentage"
  | "speechSegment"
  | "startTime"
  | "endTime"
  | "documentId"
  | "uuid"
  | "title"
  | "author"
  | "webUrl"
  | "audioUrl"
  | "thumbnail"
  | "source"
  | "description"
  | "tags"
  | "views"
  | "likes"
  | "audioLength"
  | "transcription"
  | "entities"
  | "positivePercentageScore"
  | "negativePercentageScore"
  | "joy"
  | "trust"
  | "surprise"
  | "anticipation"
  | "fear"
  | "sadness"
  | "disgust"
  | "anger"
  | "created"
> & {
    sentences?: Maybe<
      Array<Maybe<{ __typename?: "Sentence" } & SentenceFragment>>
    >;
  };

export type OsAnswersProFragment = { __typename?: "AnswerPro" } & Pick<
  AnswerPro,
  "query" | "mediasCount" | "text"
> & {
    medias?: Maybe<
      Array<Maybe<{ __typename?: "ModelAnswerResult" } & OsMediaProFragment>>
    >;
  };

export type OpenSearchProQueryVariables = Exact<{
  search?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["String"]>;
}>;

export type OpenSearchProQuery = { __typename?: "Query" } & {
  getAnswersPro?: Maybe<
    | ({ __typename: "AnswerPro" } & OsAnswersProFragment)
    | ({ __typename: "AnwerNotFound" } & Pick<AnwerNotFound, "message">)
    | ({ __typename: "AnswerResponseError" } & Pick<
        AnswerResponseError,
        "message"
      >)
  >;
};

export const SentenceFragmentDoc = gql`
  fragment Sentence on Sentence {
    text
    start
    end
  }
`;
export const OsMediaProFragmentDoc = gql`
  fragment OSMediaPro on ModelAnswerResult {
    modelName
    modelAnswer
    confidenceInPercentage
    speechSegment
    startTime
    endTime
    documentId
    uuid
    title
    author
    webUrl
    audioUrl
    thumbnail
    source
    description
    tags
    views
    likes
    audioLength
    transcription
    sentences {
      ...Sentence
    }
    entities
    positivePercentageScore
    negativePercentageScore
    joy
    trust
    surprise
    anticipation
    fear
    sadness
    disgust
    anger
    created
  }
  ${SentenceFragmentDoc}
`;
export const OsAnswersProFragmentDoc = gql`
  fragment OSAnswersPro on AnswerPro {
    query
    mediasCount
    text
    medias {
      ...OSMediaPro
    }
  }
  ${OsMediaProFragmentDoc}
`;
export const OpenSearchProDocument = gql`
  query OpenSearchPro($search: String = "", $sessionId: String = "") {
    getAnswersPro(query: $search, sessionId: $sessionId) {
      __typename
      ... on AnswerPro {
        ...OSAnswersPro
      }
      ... on AnwerNotFound {
        message
      }
      ... on AnswerResponseError {
        message
      }
    }
  }
  ${OsAnswersProFragmentDoc}
`;

/**
 * __useOpenSearchProQuery__
 *
 * To run a query within a React component, call `useOpenSearchProQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenSearchProQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenSearchProQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useOpenSearchProQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OpenSearchProQuery,
    OpenSearchProQueryVariables
  >
) {
  return Apollo.useQuery<OpenSearchProQuery, OpenSearchProQueryVariables>(
    OpenSearchProDocument,
    baseOptions
  );
}
export function useOpenSearchProLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OpenSearchProQuery,
    OpenSearchProQueryVariables
  >
) {
  return Apollo.useLazyQuery<OpenSearchProQuery, OpenSearchProQueryVariables>(
    OpenSearchProDocument,
    baseOptions
  );
}
export type OpenSearchProQueryHookResult = ReturnType<
  typeof useOpenSearchProQuery
>;
export type OpenSearchProLazyQueryHookResult = ReturnType<
  typeof useOpenSearchProLazyQuery
>;
export type OpenSearchProQueryResult = Apollo.QueryResult<
  OpenSearchProQuery,
  OpenSearchProQueryVariables
>;
