/* eslint-disable react/jsx-boolean-value */
import React from "react";
import TagManager from "react-gtm-module";

import { Typography, Box, Snackbar, Grid } from "@material-ui/core";
import { Alert, AlertProps } from "components/alert/alert.component";
// import { Redirect } from "react-router-dom";

import PlansCouponsFormContainer from "components/plans-coupons-form/plans-coupons-form.container";

import {
  useGetAudionautPlansQuery,
  useCreatePlanSubscriptionMutation,
  useGetCurrentUserOrganisationQuery,
  useCancelCurrentSubscriptionMutation,
  AudionautPlanFragmentFragment,
  CreatePlanSubscriptionMutationVariables,
  useCreatePlanSubscriptionWithCouponMutation,
  useGetCurrentSubscriptionInfoQuery,
  useGetReferralLinkByCodeLazyQuery,
  useSensReferalRewardEmailLazyQuery,
  ReferredLinkFragment,
  useSetReferralLinkMutation,
} from "graphql/types-and-hooks";
import {
  createEmptyBillingAddress,
  createEmptyPaymentMethod,
} from "graphql/audionaut.utils";

// Utils
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import { useUserDataStore } from "commons/utils/user-data";

import { isMobileResolution } from "commons/utils/device-info.util";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";
import BilingModal from "./components/billing-modal/billing-modal.component";

import SC from "./user-plans.styles";

const UserPlansPage: React.FC = () => {
  const classes = SC.useStyles();

  const userData = useUserDataStore((state: any) => state.userData);
  const userDataLocal = React.useMemo(() => userData, [userData]);

  const planPreselect = React.useMemo(() => {
    return (
      !!localStorage.getItem("selectedPlan") &&
      localStorage.getItem("selectedPlan") !== "chatFree"
    );
  }, []);

  // const [redirect, setRedirect] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [openBillingModal, setBillingModal] = React.useState(!!planPreselect);
  const [currentAddress, setCurrentAddress] = React.useState(() =>
    createEmptyBillingAddress()
  );
  const [updateAddressData, setUpdateAddressData] =
    React.useState<boolean>(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = React.useState(() => {
    const { stripePaymentMethodId } = createEmptyPaymentMethod();
    return {
      isDefault: true,
      stripePaymentMethodId,
      holderName: "",
    };
  });
  const [updatePaymentMethodData, setUpdatePaymentMethodData] =
    React.useState<boolean>(false);
  const [planToBuy, setPlanToBuy] =
    React.useState<AudionautPlanFragmentFragment>({});

  const [referralLink, setReferralLink] =
    React.useState<ReferredLinkFragment | null>(null);
  const referralCode = React.useMemo(() => {
    return localStorage.getItem("referralCode");
  }, []);

  const [
    createPlanSubscription,
    {
      data: subscriptionData,
      loading: subscriptionLoading,
      error: subscriptionError,
    },
  ] = useCreatePlanSubscriptionMutation();

  const [
    createPlanSubscriptionWithCoupon,
    {
      data: subsCouponData,
      loading: subsCouponLoading,
      error: subsCouponError,
    },
  ] = useCreatePlanSubscriptionWithCouponMutation();

  const {
    data: currentUserOrganisation,
    loading: getCurrentUserOrganisationLoading,
    error: getCurrentUserOrganisationError,
    refetch: currentUserOrganisationRefetch,
  } = useGetCurrentUserOrganisationQuery({
    fetchPolicy: "no-cache",
  });

  const {
    data: plans,
    loading: getAudionautPlansLoading,
    error: getAudionautPlansError,
    refetch: plansRefecth,
  } = useGetAudionautPlansQuery({
    fetchPolicy: "no-cache",
  });

  const [
    cancelSubscription,
    { loading: cancelSubscriptionLoading, error: cancelSubscriptionError },
  ] = useCancelCurrentSubscriptionMutation();

  const {
    data: currentSubscriptionInfoData,
    loading: currentSubscriptionInfoLoading,
    error: currentSubscriptionInfoError,
  } = useGetCurrentSubscriptionInfoQuery();

  const [
    getReferralLink,
    {
      data: getReferralLinkData,
      loading: getReferralLinkLoading,
      error: getReferralLinkError,
    },
  ] = useGetReferralLinkByCodeLazyQuery();

  // const [useReferralLinkMutation, { loading: useReferralLinkLoading }] =
  //   useUseReferralLinkMutation();
  const [sendReferralRewardEmail] = useSensReferalRewardEmailLazyQuery();

  const [setReferralLinkMut] = useSetReferralLinkMutation();

  const getAudionautPlans = React.useMemo(() => {
    const planstemp = plans?.getAudionautPlans ?? [];

    const activePlans = planstemp
      .filter((plan) => !!plan?.active)
      .map((item) => ({ ...item, isCustom: false }));

    if (planstemp.length > 0) {
      const customPlan = {
        ...planstemp[0],
        active: true,
        isCustom: true,
        name: "Customized Version",
        id: "custom",
        description:
          "Please note: The price range for the customized versions depends on the size and specificity required for the model’s customization, reflecting the complexity and depth of domain-specific integration into the Corporatus model. For precise pricing and tailored solutions, contact us.",
      };
      if (customPlan) {
        activePlans.push(customPlan);
      }
    }

    return activePlans ?? [];
  }, [plans]);
  const userOrganization = React.useMemo(
    () => currentUserOrganisation?.getCurrentUserOrganisation ?? null,
    [currentUserOrganisation]
  );

  const userHasActiveSubscription = React.useMemo(
    () =>
      currentSubscriptionInfoData &&
      currentSubscriptionInfoData.getCustomerSubscriptionInfo &&
      currentSubscriptionInfoData.getCustomerSubscriptionInfo
        .hasActiveMeteredSubscription,
    [currentSubscriptionInfoData]
  );

  const loading =
    subscriptionLoading ||
    subsCouponLoading ||
    getCurrentUserOrganisationLoading ||
    getAudionautPlansLoading ||
    cancelSubscriptionLoading;
  const errorHandler = useAppErrorHandler(
    subscriptionError ||
      subsCouponError ||
      getCurrentUserOrganisationError ||
      getAudionautPlansError ||
      cancelSubscriptionError
  );

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const ManageReferralLink = React.useCallback(() => {
    const { referredBy, id } = referralLink as ReferredLinkFragment;
    setReferralLinkMut({
      variables: {
        userId: userDataLocal.id,
        referralLinkId: id ?? "",
      },
    });

    sendReferralRewardEmail({
      variables: {
        email: referredBy?.email ?? "",
        name: referredBy?.firstName ?? "",
      },
    });

    localStorage.removeItem("referralCode");
  }, [
    referralLink,
    sendReferralRewardEmail,
    setReferralLinkMut,
    userDataLocal,
  ]);

  const createSubscription = React.useCallback(
    async (id?: string, couponCode?: string) => {
      try {
        if (couponCode) {
          createPlanSubscriptionWithCoupon({
            variables: {
              planId: id ?? "",
              coupon: couponCode,
            },
          });
        } else {
          await createPlanSubscription({
            variables: {
              planId: id ?? "",
            },
          });
        }

        // After user is subscribed successfully, we do the referral stuffs to update info referral link
        if (referralLink && referralLink.id) {
          ManageReferralLink();
        }

        TagManager.dataLayer({
          dataLayer: {
            event: "planSubscribed",
          },
        });

        setSnackBarMessage({
          message:
            "The plan subscription has been updated successfully, Redirection to home...",
          severity: "success",
        });

        plansRefecth();
        // setTimeout(() => {
        //   // setRedirect(true);
        //   window.location.href = "/";
        // }, 3000);
      } catch (error: any) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PlanSubscriptionFailed",
          },
        });

        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the plan subscription"
            )
          );
        }
      }
    },
    [
      createPlanSubscription,
      createPlanSubscriptionWithCoupon,
      errorHandler,
      plansRefecth,
      ManageReferralLink,
      referralLink,
    ]
  );

  const handlerCancelSubscription = React.useCallback(async () => {
    try {
      const result = await cancelSubscription();

      setSnackBarMessage({
        message: "The current subscription has been canceled successfully",
        severity: "success",
      });

      plansRefecth();
      setTimeout(() => {
        currentUserOrganisationRefetch();
      }, 2000);
    } catch (error: any) {
      if (isServerError(error)) {
        errorHandler(error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while canceling the current subscription"
          )
        );
      }
    }
  }, [
    cancelSubscription,
    errorHandler,
    plansRefecth,
    currentUserOrganisationRefetch,
  ]);

  const handleOpenBillingModal = () => {
    setBillingModal(true);
  };
  const handleCloseBillingModal = () => {
    setBillingModal(false);
  };

  React.useEffect(() => {
    if (
      (subscriptionData &&
        subscriptionData?.createSubscription &&
        subscriptionData?.createSubscription.id) ||
      (subsCouponData &&
        subsCouponData?.createSubscription &&
        subsCouponData?.createSubscription.id)
    ) {
      // We send the purchase gtm event
      TagManager.dataLayer({
        dataLayer: {
          transaction_id: subscriptionData
            ? subscriptionData?.createSubscription.id
            : subsCouponData?.createSubscription.id,
        },
      });

      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
        },
      });

      setTimeout(() => {
        // setRedirect(true);
        window.location.href = "/";
      }, 3000);
    }
  }, [subscriptionData, subsCouponData]);

  React.useEffect(() => {
    if (referralCode) {
      getReferralLink({
        variables: {
          code: referralCode,
        },
      });
    }
  }, [referralCode, getReferralLink]);

  React.useEffect(() => {
    if (getReferralLinkData && getReferralLinkData.GetReferredLinkByCode) {
      setReferralLink(getReferralLinkData.GetReferredLinkByCode);
    }
  }, [getReferralLinkData]);

  return (
    <SC.UPContainer>
      <ProgressIndicator
        open={loading}
        backdropColor={planPreselect ? "#000" : ""}
      />
      <SC.UPContent>
        <SC.UPHeader component="div">
          <Box component="div" display="flex" flex="1">
            <Typography variant="h1" color="primary">
              Existing Plans
            </Typography>
          </Box>
        </SC.UPHeader>
        <SC.UPBody component="div" key="groups-table">
          <Box className={classes.sectionContainer}>
            {isMobileResolution() ? (
              <CarouselGeneric
                type="plans"
                items={getAudionautPlans?.map((planData: any) => (
                  <SC.PlanItem
                    planData={planData}
                    currentUserOrganisation={
                      currentUserOrganisation?.getCurrentUserOrganisation
                    }
                    planBought={userHasActiveSubscription ?? false}
                    eventsNotification={setSnackBarMessage}
                    cancelSubscriptionHandler={handlerCancelSubscription}
                    showAddressPaymenthModal={handleOpenBillingModal}
                    setCurrentAddress={setCurrentAddress}
                    setCurrentPaymentMethod={setCurrentPaymentMethod}
                    setUpdateAddressStatus={setUpdateAddressData}
                    setUpdatePaymentMethodStatus={setUpdatePaymentMethodData}
                    setPlanToBuy={setPlanToBuy}
                    cardType={2}
                  />
                ))}
                hideDots={false}
                customPaddingLeft={20}
                customPaddingRight={20}
              />
            ) : (
              <>
                <Grid
                  container
                  spacing={3}
                  lg={12}
                  md={12}
                  xs={12}
                  className={classes.cardsContainer}
                >
                  {getAudionautPlans?.map((planData: any) => (
                    <Grid
                      key={`user-plan-${planData.id}`}
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      xs={12}
                      style={{
                        position: "relative",
                        display: planData.active ? "relative" : "none",
                      }}
                    >
                      <SC.PlanItem
                        planData={planData}
                        currentUserOrganisation={
                          currentUserOrganisation?.getCurrentUserOrganisation
                        }
                        planBought={userHasActiveSubscription ?? false}
                        eventsNotification={setSnackBarMessage}
                        cancelSubscriptionHandler={handlerCancelSubscription}
                        showAddressPaymenthModal={handleOpenBillingModal}
                        setCurrentAddress={setCurrentAddress}
                        setCurrentPaymentMethod={setCurrentPaymentMethod}
                        setUpdateAddressStatus={setUpdateAddressData}
                        setUpdatePaymentMethodStatus={
                          setUpdatePaymentMethodData
                        }
                        setPlanToBuy={setPlanToBuy}
                        cardType={2}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Box>
        </SC.UPBody>
      </SC.UPContent>
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
      <BilingModal
        openModal={openBillingModal}
        planData={planToBuy}
        createSubscription={createSubscription}
        handleCloseModal={handleCloseBillingModal}
        currentAddressData={currentAddress}
        paymentMethodData={currentPaymentMethod}
        updateAddressData={updateAddressData}
        updatePaymentMethodData={updatePaymentMethodData}
        modalType={planPreselect ? 1 : 0}
      />
      {/* {redirect ? <Redirect to="/" /> : null} */}
    </SC.UPContainer>
  );
};

export default UserPlansPage;
