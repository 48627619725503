/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';
// Constants
import {
  TRAINING_MODELS_COLUMN_ID,
  FILTER_INITIAL_DATE_RANGE
} from '../../commons/constants';

const { TRAINED_MODELS, TRAINING_JOBS } = ActionTypes;
const modelFilterInitialState = [
  {
    id: TRAINING_MODELS_COLUMN_ID.NAME,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.STATUS,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.STORE,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.CREATETIME,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.VERSION,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.DEVICE,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.ENDTIME,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.PARENT_MODEL,
    value: []
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.DATE_RANGE_START,
    value: [FILTER_INITIAL_DATE_RANGE.startDate]
  },
  {
    id: TRAINING_MODELS_COLUMN_ID.DATE_RANGE_END,
    value: [FILTER_INITIAL_DATE_RANGE.endDate]
  }
];

const trainedModelFilterReducerFn = (state = modelFilterInitialState, action) => {
  switch (action.type) {
    case TRAINED_MODELS.FILTER.UPDATE: {
      const modelFilter = state;
      const newFilterOption = action.payload;
      const newModelFilter = modelFilter.filter(f => f.id !== newFilterOption.id);
      newModelFilter.push(newFilterOption);
      return newModelFilter;
    }
    case TRAINED_MODELS.FILTER.SET: {
      return [...action.payload];
    }
    case TRAINED_MODELS.FILTER.RESET: {
      return [
        ...modelFilterInitialState
      ];
    }
    case TRAINING_JOBS.CREATE.CLEAR: {
      return modelFilterInitialState;
    }
    default:
      return state;
  }
};

export default trainedModelFilterReducerFn;
