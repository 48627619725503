import { Typography, Box, Theme } from "@material-ui/core";
import {
  withStyles,
  useTheme,
  createStyles,
  makeStyles,
} from "@material-ui/styles";

const MessageContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    borderRadius: "10px",
    margin: "10px",
    [theme.breakpoints.down(540)]: {
      border: "unste",
      boxShadow: "unset",
      background: "transparent",
    },
  },
}))(Box);

const MessageTitle = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    fontSize: "40px !important",
    fontWeight: "800 !important" as any,
    lineHeight: "60px !important",
    textAlign: "center",
    marginBottom: "40px !important",
    "& p": {
      fontSize: "40px !important",
      fontWeight: "800 !important" as any,
      lineHeight: "60px !important",
      textAlign: "center",
      marginLeft: "10px",
    },
    [theme.breakpoints.down(540)]: {
      display: "unset",
      fontSize: "30px !important",
      lineHeight: "40px !important",
      marginBottom: "20px !important",
      "& p": {
        fontSize: "30px !important",
        lineHeight: "40px !important",
      },
    },
  },
}))(Typography);

const MessageDescription = withStyles((theme) => ({
  root: {
    fontSize: "20px !important",
    fontWeight: "500 !important" as any,
    lineHeight: "30px !important",
    textAlign: "center",
    color: theme.generalStylesContainer.referrals.color.main,
    [theme.breakpoints.down(540)]: {
      fontSize: "18px !important",
      lineHeight: "24px !important",
    },
  },
}))(Box);

export default { MessageContainer, MessageTitle, MessageDescription };
