import React from "react";

import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
} from "@material-ui/core";

import ButtonPrimary from "components/button-primary/button-primary.component";

import CloseIcon from "@material-ui/icons/Close";

import SC from "./hear-about-audionaut.styles";

interface HearAboutAudionautInterface {
  successHandler: (arg1: string) => void;
  cancelHandler: () => void;
}

const HearAboutAudionaut: React.FC<HearAboutAudionautInterface> = ({
  successHandler,
  cancelHandler,
}) => {
  const [paymentOption, setPaymentOption] = React.useState("searchEngine");
  const [comment, setComment] = React.useState("Search Engine");
  const [showOtherInput, setShowOtherInput] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentOption((event.target as HTMLInputElement).value);

    switch ((event.target as HTMLInputElement).value) {
      case "socialMedia":
        setComment("Social media");
        setShowOtherInput(true);
        break;
      case "ad":
        setComment("ad");
        setShowOtherInput(true);
        break;
      case "other":
        setComment("");
        setShowOtherInput(false);
        break;
      default:
        setComment("Search Engine");
        setShowOtherInput(true);
        break;
    }
  };

  return (
    <Box>
      <SC.Title display="flex">
        <Typography>How did you discover Audionaut Chat?</Typography>
        <IconButton component="button" onClick={cancelHandler}>
          <CloseIcon />
        </IconButton>
      </SC.Title>

      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="payment-options"
          name="payment"
          value={paymentOption}
          onChange={handleChange}
        >
          <SC.RadioButton
            value="searchEngine"
            control={
              <Radio
                icon={<SC.RadioIcon component="span" />}
                checkedIcon={<SC.CheckedRadioIcon component="span" />}
              />
            }
            label="Search engine"
          />
          <SC.RadioButton
            value="socialMedia"
            control={
              <Radio
                icon={<SC.RadioIcon component="span" />}
                checkedIcon={<SC.CheckedRadioIcon component="span" />}
              />
            }
            label="Social media"
          />
          <SC.RadioButton
            value="ad"
            control={
              <Radio
                icon={<SC.RadioIcon component="span" />}
                checkedIcon={<SC.CheckedRadioIcon component="span" />}
              />
            }
            label="Ad"
          />
          <SC.RadioButton
            value="other"
            control={
              <Radio
                icon={<SC.RadioIcon component="span" />}
                checkedIcon={<SC.CheckedRadioIcon component="span" />}
              />
            }
            label="Other"
          />
        </RadioGroup>
      </FormControl>
      <SC.CustomField
        style={{
          visibility: showOtherInput ? "hidden" : "visible",
          height: showOtherInput ? "0px" : "auto",
          margin: showOtherInput ? "28px 0px 0px" : "28px 0px",
        }}
        multiline
        autoFocus
        name="comment"
        label="Please specify"
        placeholder=""
        type="text"
        variant="outlined"
        fullWidth
        value={comment}
        onChange={(event) => {
          setComment(event.currentTarget.value);
        }}
      />
      <SC.SubmitBtn
        onClick={() => {
          successHandler(comment);
        }}
      >
        Submit
      </SC.SubmitBtn>
    </Box>
  );
};

export default HearAboutAudionaut;
