/* eslint-disable */
// Utils
import Api from './api.util';


const API = {
  AUTH: 'auth',
};

export const USER_ROLE = {
  USER: 'User',
  ADMIN: 'Admin'
};

const { REACT_APP_GATEKEEPER_G2IOT: GATEKEEPER_G2IOT } = process.env;
class GatewayServer {
  static api = null;

  static token = null;

  static getApi = () => {
    if (GatewayServer.api === null) {
      GatewayServer.api = new Api(GATEKEEPER_G2IOT);
    }

    return GatewayServer.api;
  };

  static setToken = (token) => {
    GatewayServer.token = token;
  }

  static auth = (query) => {
    const options = {
      method: 'POST',
      body: query
    };

    return GatewayServer.getApi().apiFetch(
      API.AUTH,
      options
    );
  };
}

export default GatewayServer;
