/* eslint-disable import/no-extraneous-dependencies */
import { isApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import _ from "lodash";
// Errors
import {
  isNetworkErrorCode,
  NetworkError,
  NetworkErrorCode,
  NetworkErrorCodes,
  NetworkErrorType,
} from "./network.errors";

const ServerErrorCodes = {
  // General Errors
  GENERAL_ERROR: "general-error", // generic error, this error is assign to server error without code defined
  INSUFFICIENT_PRIVILEGES: "insufficient-privileges",
  INCORRECT_ENTITY_NAME: "incorrect-entity-name",
  NOT_VALID_EMAIL_ADDRESS: "not-valid-email-address",
  NO_ENTITY_FOUND: "no-entity-found",
  NOT_VALID_PASSWORD: "not-valid-password",
  SERVER_UNREACHABLE: "server-not-reachable",

  // User Errors
  EMAIL_ADDRESS_ALREADY_IN_USE: "email-address-already-in-use",
  CANNOT_DELETE_LAST_SYS_ADMIN: "cannot-delete-last-sys-admin",
  CANNOT_DELETE_ANOTHER_SYS_ADMIN: "cannot-delete-another-sys-admin",
  CANNOT_CHANGE_EMAIL: "cannot-change-email",
  DEACTIVATED_USER: "deactivated-user",
  USER_NOT_EXISTS: "User doesn't exists",

  // GENERIC ERRORS
  RECORD_ALREADY_EXISTS: "record-already-exists",
  RECORD_NOT_EXIST: "record-not-exist",

  // Group Errors
  CANNOT_ASSIGN_USERS_THIS_GROUP: "cannot-assign-users-this-group",
  GROUP_ALREADY_EXISTS: "group-already-exists",
  THIS_GROUP_CANNOT_BE_UPDATED: "this-group-cannot-be-updated",
  REMOVE_GROUP_FROM_USERS: "remove-group-from-users",

  // Role Errors
  ROLE_ALREADY_EXISTS: "role-already-exists",
  REMOVE_ROLE_FROM_USERS: "remove-role-from-users",
  THIS_ROLE_CANNOT_BE_DELETED: "this-role-cannot-be-deleted",

  // Organization Errors
  ORGANIZATION_ALREADY_EXISTS: "organization-already-exists",

  // Department Errors
  DEPARTMENT_ALREADY_EXISTS: "department-already-exists",

  // Note Attachment Errors
  FILE_EXCEEDED_UPLOAD_SIZE: "file-exceeded-upload-size",
  FILES_EXCEEDED_UPLOAD_SIZE: "files-exceeded-upload-size",
  FILES_EXCEEDED_MAX_ATTACHMENT_COUNT: "files-exceeded-max-attachment-count",
  FILE_CONTENT_TYPE_NOT_SUPPORTED: "file-content-type-not-supported",

  ERROR_FETCHING_INFORMATION: "error-fetching-information",

  ERROR_WHILE: "error-while",

  // Authentication Errors
  INVALID_LINK: "invalid-link",
  LINK_TIMEOUT: "link-timeout",
  INVALID_EMAIL_OR_PASSWORD: "invalid-email-or-password",
  LOGIN_ATTEMPTS_EXCEEDED: "login-attempts-exceeded",
  INVALID_JWT: "invalid-jwt",
  INVALID_LICENSE: "invalid-license",

  // Permission Errors
  INVALID_PERMISSION: "invalid-permission",

  // Network Error Codes

  // UI Error Codes

  // Scripts & Pipelines Error Codes
  // Script - OrientDB to PostgreSQL
  SOURCE_FILE_DOES_NOT_EXIST: "source-file-does-not-exist",
  DB_CONNECTION_CANNOT_BE_ESTABLISHED: "db-connection-cannot-be-established",
  CANNOT_EXPORT_DATA_TO_DATABASE: "cannot-export-data-to-database",
  CANNOT_CREATE_DATA_PERMISSIONS: "cannot-create-data-permissions",
  CANNOT_CREATE_LINKS_BETWEEN_ROLES_AND_PERMS:
    "cannot-create-links-between-roles-and-perms",
  CANNOT_CREATE_ACCESS_CONTROL_FUNCTIONS:
    "cannot-create-access-control-functions",
  CANNOT_UPDATE_HASURA_CONFIG: "cannot-update-hasura-config",
  VERSIONING_SCHEMA_NOT_FOUND: "versioning-schema-not-found",
  INTERNAL_SCRIPT_ERROR: "internal-script-error",

  // Dagster Pipelines
  PIPELINE_RUN_FAILURE: "pipeline-run-failure",
  PIPELINE_RUN_CANCELED: "pipeline-run-canceled",
} as const;
type ServerErrorType = keyof typeof ServerErrorCodes & NetworkErrorType;
type ServerErrorCode = typeof ServerErrorCodes[ServerErrorType] &
  NetworkErrorCode;
const isServerErrorCode = (
  maybeServerErrorCode: unknown
): maybeServerErrorCode is ServerErrorCode => {
  return (
    _.isString(maybeServerErrorCode) &&
    (Object.values<string>(ServerErrorCodes).includes(maybeServerErrorCode) ||
      isNetworkErrorCode(maybeServerErrorCode))
  );
};
const isRtcsServerError = (gqlError: GraphQLError): boolean => {
  const { extensions } = gqlError;
  return !!extensions && isServerErrorCode(extensions.code);
};
const isHasuraServerError = (gqlError: GraphQLError): boolean => {
  const { extensions } = gqlError;
  return !!extensions && !!extensions.path;
};
const isServerError = (error: Error): boolean => {
  if (isApolloError(error)) {
    const { networkError, graphQLErrors } = error;
    if (networkError !== null) return true;
    const serverError = graphQLErrors.find(
      (gqlError) => isRtcsServerError(gqlError) || isHasuraServerError(gqlError)
    );
    return !!serverError;
  }
  return false;
};
const extractServerError = (
  error: Error
): NetworkError | GraphQLError[] | undefined => {
  if (isApolloError(error)) {
    const { message, networkError, graphQLErrors } = error;
    if (networkError !== null) {
      return new NetworkError(NetworkErrorCodes.SERVER_NOT_REACHABLE, message);
    }
    const serverErrors = graphQLErrors.filter(
      (gqlError) => isRtcsServerError(gqlError) || isHasuraServerError(gqlError)
    );
    return serverErrors.length > 0 ? serverErrors : undefined;
  }
  return undefined;
};
export {
  ServerErrorCodes,
  isServerErrorCode,
  isServerError,
  isRtcsServerError,
  isHasuraServerError,
  extractServerError,
  NetworkError,
  NetworkErrorCodes,
};
export type { ServerErrorType, ServerErrorCode };
