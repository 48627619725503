import React from "react";
import { Box } from "@material-ui/core";

import SC from "./signup-verify-email.styles";

const VerifyEmail: React.FC = () => {
  return (
    <Box className="content">
      <SC.Text>
        Account activation link has been sent to the email address you provided
      </SC.Text>
      <SC.Footer>
        Didn&apos;t receive the email? Please reach out your administrator to
        ensure you are receiving mail.
      </SC.Footer>
    </Box>
  );
};

export default VerifyEmail;
