/* eslint-disable prettier/prettier */
import React from "react";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";

// Assets
import SC from "./signup-form.styles";

interface ResetPasswordFormProps {
  handleSubmit: any;
  userInfo: any;
  organizationInfo: any;
  handleUserInfoChanged: any;
  handleOrganizationInfoChanged: any;
}

const SignupForm: React.FC<ResetPasswordFormProps> = (props) => {
  const {
    handleSubmit,
    userInfo,
    organizationInfo,
    handleUserInfoChanged,
    handleOrganizationInfoChanged,
  } = props;
  const history = useHistory();

  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [showPassword1, setShowPassword1] = React.useState<boolean>(false);
  const [showPassword2, setShowPassword2] = React.useState<boolean>(false);

  const [formStates, setFormState] = React.useState<any>({
    firstname: {
      touched: false,
      dirty: false,
      isValid: false,
      errorMsg: "",
    },
    email: {
      touched: false,
      dirty: false,
      isValid: false,
      errorMsg: "",
    },
    phone: {
      touched: false,
      dirty: false,
      isValid: false,
      errorMsg: "",
    },
    password: {
      touched: false,
      dirty: false,
      isValid: false,
      errorMsg: "",
    },
    confirmPassword: {
      touched: false,
      dirty: false,
      isValid: false,
      errorMsg: "",
    },
  });

  const validateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    let target: { name; value };

    if (event && !event.target) {
      target = {
        name: "phone",
        value: event,
      };
    } else {
      target = event.target;
    }

    const { name, value } = target;

    const { [name]: currentField } = formStates;

    currentField.errorMsg = "";

    switch (name) {
      case "firstname":
        currentField.isValid = !!value;
        currentField.errorMsg = !value
          ? "Organization name field is required"
          : "";
        currentField.dirty = true;
        break;
      case "email":
        currentField.dirty = true;
        if (value) {
          const emailRegExp =
            /([a-zA-Z0-9_-]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5})/;
          currentField.isValid = emailRegExp.test(value);
          currentField.errorMsg = !emailRegExp.test(value)
            ? "Email format invalid"
            : "";

          if (currentField.isValid) {
            TagManager.dataLayer({
              dataLayer: {
                email: value,
              },
            });
          }
        } else {
          currentField.isValid = false;
          currentField.errorMsg = "Email field is required";
        }
        break;
      case "phone":
        currentField.dirty = true;
        if (value) {
          const phoneRegExp =
            /^[+]?[-\s.]?[0-9]{1,4}?[-\s.]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{2,3}[-\s.]?[0-9]{2,6}[-\s.]?[0-9]{2,6}$/;
          currentField.isValid = phoneRegExp.test(value);
          currentField.errorMsg = !phoneRegExp.test(value)
            ? "Phone number format invalid"
            : "";
        } else {
          currentField.isValid = false;
          currentField.errorMsg = "Phone number field is required";
        }
        break;
      case "password":
        currentField.dirty = true;
        if (value) {
          const digitReg = /(?=.*\d)/;
          const charsReg = /((?=.*\W){1})/;
          // const charsReg = /((?=.*[@#$%^&+=]){1})/;

          currentField.isValid =
            value.length > 7 && digitReg.test(value) && charsReg.test(value);

          currentField.errorMsg = currentField.errorMsg.concat(
            !(value.length > 7) ? "Must be at least 8 characters long and " : ""
          );
          currentField.errorMsg = currentField.errorMsg.concat(
            !digitReg.test(value) ? "must contain at least one number and " : ""
          );
          currentField.errorMsg = currentField.errorMsg.concat(
            !charsReg.test(value) ? "at least one special character" : ""
          );

          if (formStates.confirmPassword.touched) {
            formStates.confirmPassword.isValid =
              value === userInfo.confirmPassword;
            formStates.confirmPassword.errorMsg =
              value !== userInfo.confirmPassword ? "Passwords Must Match" : "";
          }
        } else {
          currentField.isValid = false;
          currentField.errorMsg = "Password field is required";
        }
        break;
      case "confirmPassword":
        currentField.dirty = true;
        currentField.isValid = value === userInfo.password;
        currentField.errorMsg =
          value !== userInfo.password ? "Passwords Must Match" : "";
        break;
      default:
        break;
    }

    setFormState((oldFormState: any) => ({
      ...oldFormState,
      [name]: currentField,
    }));
  };

  const handleClickShowPassword = (index: number) => {
    if (index === 1) {
      setShowPassword1(!showPassword1);
    } else {
      setShowPassword2(!showPassword2);
    }
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <SC.Form>
      {currentStep === 1 ? (
        <SC.Grid xs={12} container>
          <SC.GridFieldContainer
            item
            xs={12}
            className={
              formStates.firstname.touched &&
              formStates.firstname.dirty &&
              !formStates.firstname.isValid
                ? "field-error"
                : ""
            }
          >
            <SC.TextFieldCustom
              autoFocus
              type="text"
              name="firstname"
              label="Name"
              placeholder="Enter Name"
              variant="outlined"
              fullWidth
              required
              value={userInfo.firstname}
              onChange={(event: any) => {
                handleUserInfoChanged(event);
                validateField(event);
              }}
              onFocus={() => {
                formStates.firstname.touched = true;
              }}
              helperText={
                formStates.firstname.touched &&
                formStates.firstname.dirty &&
                !formStates.firstname.isValid
                  ? formStates.firstname.errorMsg
                  : ""
              }
            />
          </SC.GridFieldContainer>
          <SC.GridFieldContainer
            item
            xs={12}
            className={
              formStates.email.touched &&
              formStates.email.dirty &&
              !formStates.email.isValid
                ? "field-error"
                : ""
            }
          >
            <SC.TextFieldCustom
              name="email"
              label="Email Address"
              variant="outlined"
              placeholder="@corporatus.com"
              fullWidth
              type="email"
              required
              value={userInfo.email}
              onChange={(event: any) => {
                handleUserInfoChanged(event);
                validateField(event);
              }}
              onFocus={() => {
                formStates.email.touched = true;
              }}
              helperText={
                formStates.email.touched &&
                formStates.email.dirty &&
                !formStates.email.isValid
                  ? formStates.email.errorMsg
                  : ""
              }
            />
          </SC.GridFieldContainer>

          <SC.GridFieldContainer
            item
            xs={12}
            className={
              formStates.phone.touched &&
              formStates.phone.dirty &&
              !formStates.phone.isValid
                ? "field-error"
                : ""
            }
          >
            <SC.MuiPhoneNumberField
              name="phone"
              variant="outlined"
              label="Phone Number"
              inputMode="tel"
              fullWidth
              defaultCountry="us"
              autoFormat
              required
              onChange={(event: any) => {
                handleUserInfoChanged(event);
                validateField(event);
              }}
              onFocus={() => {
                formStates.phone.touched = true;
              }}
              helperText={
                formStates.phone.touched &&
                formStates.phone.dirty &&
                !formStates.phone.isValid
                  ? formStates.phone.errorMsg
                  : ""
              }
              className="phone-number-input"
            />
          </SC.GridFieldContainer>

          <SC.GridFieldContainer
            item
            xs={12}
            className={
              formStates.password.touched &&
              formStates.password.dirty &&
              !formStates.password.isValid
                ? "field-error"
                : ""
            }
          >
            <SC.PasswordField
              id="password"
              name="password"
              label="Password"
              type={showPassword1 ? "text" : "password"}
              variant="outlined"
              fullWidth
              value={userInfo.password}
              InputProps={{
                endAdornment: (
                  <SC.PasswordInputAdornment position="start">
                    <SC.IconButton
                      onClick={() => {
                        handleClickShowPassword(1);
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword1 ? (
                        <SC.VisibilityOffIcon />
                      ) : (
                        <SC.VisibilityIcon />
                      )}
                    </SC.IconButton>
                  </SC.PasswordInputAdornment>
                ),
              }}
              onChange={(event: any) => {
                handleUserInfoChanged(event);
                validateField(event);
              }}
              onFocus={() => {
                formStates.password.touched = true;
              }}
              helperText={
                formStates.password.touched &&
                formStates.password.dirty &&
                !formStates.password.isValid
                  ? formStates.password.errorMsg
                  : ""
              }
              required
            />
            {/* <Typography
              className={localClasses.passwordRules}
              style={{ marginBottom: "0px" }}
            >
              Must be at least 8 characters long and must contain at least one
              number and at least one special character
            </Typography> */}
          </SC.GridFieldContainer>
          <SC.GridFieldContainer
            item
            xs={12}
            className={
              formStates.confirmPassword.touched &&
              formStates.confirmPassword.dirty &&
              !formStates.confirmPassword.isValid
                ? "field-error"
                : ""
            }
          >
            <SC.PasswordField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type={showPassword2 ? "text" : "password"}
              variant="outlined"
              fullWidth
              value={userInfo.confirmPassword}
              InputProps={{
                endAdornment: (
                  <SC.PasswordInputAdornment position="start">
                    <SC.IconButton
                      onClick={() => {
                        handleClickShowPassword(2);
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword2 ? (
                        <SC.VisibilityOffIcon />
                      ) : (
                        <SC.VisibilityIcon />
                      )}
                    </SC.IconButton>
                  </SC.PasswordInputAdornment>
                ),
              }}
              onChange={(event: any) => {
                handleUserInfoChanged(event);
                validateField(event);
              }}
              onFocus={() => {
                formStates.confirmPassword.touched = true;
              }}
              helperText={
                formStates.confirmPassword.touched &&
                formStates.confirmPassword.dirty &&
                !formStates.confirmPassword.isValid
                  ? formStates.confirmPassword.errorMsg
                  : ""
              }
              required
            />
          </SC.GridFieldContainer>
        </SC.Grid>
      ) : (
        <SC.Grid container>
          <SC.GridFieldContainer item xs={12}>
            <SC.TextFieldCustom
              type="text"
              name="name"
              label="Organization Name"
              placeholder="John"
              variant="outlined"
              fullWidth
              required
              value={organizationInfo.name}
              onChange={handleOrganizationInfoChanged}
            />
          </SC.GridFieldContainer>
          <SC.GridFieldContainer item xs={12}>
            <SC.TextFieldCustom
              type="text"
              name="type"
              label="Organization Type"
              placeholder="John"
              variant="outlined"
              fullWidth
              required
              value={organizationInfo.type}
              onChange={handleOrganizationInfoChanged}
            />
          </SC.GridFieldContainer>
          <SC.GridFieldContainer item xs={12}>
            <SC.TextFieldCustom
              type="text"
              name="phone"
              label="Organization Phone"
              variant="outlined"
              placeholder="+10000000000"
              fullWidth
              required
              value={organizationInfo.phone}
              onChange={handleOrganizationInfoChanged}
            />
          </SC.GridFieldContainer>
        </SC.Grid>
      )}
      <SC.Button
        id="audionaut-signup-button"
        type="button"
        color="primary"
        onClick={() => {
          handleSubmit();
        }}
        disabled={
          !formStates.firstname.isValid ||
          !formStates.email.isValid ||
          !formStates.password.isValid ||
          !formStates.confirmPassword.isValid ||
          !formStates.phone.isValid
        }
      >
        Sign up
      </SC.Button>
      <SC.Footer>
        {currentStep === 1 ? "Are you already registered?" : ""}
        <SC.ButtonLink
          type="button"
          variant="outlined"
          onClick={() => {
            if (currentStep === 1) {
              history.push("/login");
            } else if (currentStep === 2) {
              setCurrentStep(1);
            }
          }}
        >
          {currentStep === 1 ? "Sign in" : "Back"}
        </SC.ButtonLink>
      </SC.Footer>
    </SC.Form>
  );
};

export default SignupForm;
