// Dependencies
import React from "react";
import { Box, Typography } from "@material-ui/core";
import _ from "lodash";

// Types
import TablePaginationActions from "components/table-pagination-actions/table-pagination-actions.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import { isMobileResolution } from "commons/utils/device-info.util";

// Assets
import SC from "./table-toolbar.styles";

interface Props {
  hiddenTitle?: boolean;
  title?: string | JSX.Element;
  toolbarButtonText?: string;
  rowsCount: number;
  page: number;
  rowsPerPage: number;
  isPaginationHidden?: boolean;
  rowsPerPageOptions: number[];
  onPageChange?: (page: number) => void;
  onSaveButton?: () => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  actionsOnLeft?: TableToolbarAction[];
  actionsOnRight?: TableToolbarAction[];
  onAction?: (action: TableToolbarAction) => void;
}

const TableToolbar: React.FC<Props> = ({
  hiddenTitle,
  title = "",
  toolbarButtonText,
  rowsCount,
  page,
  rowsPerPage,
  isPaginationHidden,
  rowsPerPageOptions,
  onPageChange,
  onSaveButton,
  onChangeRowsPerPage,
  actionsOnLeft,
  actionsOnRight,
  onAction,
}) => {
  const handleronPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void = (event, newPage) => {
    onPageChange?.(newPage);
  };

  const handlerOnChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void = (event) => {
    onChangeRowsPerPage?.(parseInt(event?.target.value, 10));
  };

  const titleComponent = React.useMemo(
    () =>
      _.isString(title) ? (
        <SC.Title variant="subtitle1">{title}</SC.Title>
      ) : (
        title
      ),
    [title]
  );

  return (
    <SC.Toolbar disableGutters>
      <SC.TitleActionsContainer hidden={hiddenTitle}>
        <Typography variant="h1" color="primary">
          {title}
        </Typography>

        {actionsOnLeft && (
          <SC.TableToolbarActionsMod
            actions={actionsOnLeft}
            onAction={onAction}
          />
        )}

        {toolbarButtonText && (
          <SC.ToolbarButton
            onClick={onSaveButton}
            size="small"
            variant="outlined"
            color="primary"
          >
            {toolbarButtonText}
          </SC.ToolbarButton>
        )}
      </SC.TitleActionsContainer>
      <SC.ActionsContainer>
        <Box flex="1">
          <SC.BreadcrumbMod hidden isSimple />
        </Box>

        {!isPaginationHidden && (
          <SC.TablePagination
            labelRowsPerPage={isMobileResolution() ? "" : "Rows per page"}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rowsCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleronPageChange}
            onRowsPerPageChange={handlerOnChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        )}

        {actionsOnRight && (
          <SC.TableToolbarActionsMod
            actions={actionsOnRight}
            onAction={onAction}
            className="filter"
          />
        )}
      </SC.ActionsContainer>
    </SC.Toolbar>
  );
};

export default TableToolbar;
