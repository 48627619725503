/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { ROLES } = ActionTypes;

const INITIAL_STATE = {
  // Create role
  isCreatingData: false,
  onCreateError: null,
  onCreateSuccess: null,
  // Get all roles
  roles: [],
  isReceivingData: false,
  error: null,
  // Update role
  isUpdatingData: false,
  onUpdateError: null,
  onUpdateSuccess: null,
  // Delete role
  isDeletingData: false,
  onDeleteError: null,
  onDeleteSuccess: null,
  // Roles Filter State
  rolesFilterEnabled: true,
  // Get role permissions
  rolePermissions: {},
  isReceivingRolePermissions: false,
  rolePermissionsError: null
};

const rolesReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Set UI Role States
    case ROLES.SET.SELECTED:
      return {
        ...state,
        selected: action.payload
      };
    case ROLES.SET.UPDATED: {
      const updated = action.payload;
      const stateRoles = state.roles;
      let newRoles = stateRoles && stateRoles.slice();
      if (newRoles) {
        // Remove old element
        newRoles = newRoles.filter(r => r.id !== updated.id);
        // Add updated element
        newRoles.push(updated);
      }
      return {
        ...state,
        roles: newRoles
      };
    }
    case ROLES.SET.DELETED: {
      const deleted = action.payload;
      const stateRoles = state.roles;
      let newRoles = stateRoles && stateRoles.slice();
      if (newRoles) {
        // Remove deleted element
        newRoles = newRoles.filter(r => r.id !== deleted.id);
      }
      return {
        ...state,
        roles: newRoles
      };
    }
    case ROLES.SET.PERMISSIONS.UPDATED: {
      return {
        ...state,
        rolePermissions: action.payload,
        isReceivingRolePermissions: false
      };
    }
    // Create Role
    case ROLES.CREATE.START:
      return {
        ...state,
        isCreatingData: true,
        onCreateError: null
      };
    case ROLES.CREATE.SUCCESS:
      return {
        ...state,
        onCreateSuccess: action.payload,
        isCreatingData: false
      };
    case ROLES.CREATE.FAILURE:
      return {
        ...state,
        isCreatingData: false,
        onCreateError: action.payload
      };
    case ROLES.CREATE.CLEAR:
      return {
        ...state,
        onCreateError: null,
        onCreateSuccess: null
      };
    // Get All Roles
    case ROLES.FETCH.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case ROLES.FETCH.SUCCESS:
      return {
        ...state,
        roles: action.payload,
        isReceivingData: false
      };
    case ROLES.FETCH.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    // Update Role
    case ROLES.UPDATE.START:
      return {
        ...state,
        isUpdatingData: true,
        onUpdateError: null
      };
    case ROLES.UPDATE.SUCCESS:
      return {
        ...state,
        onUpdateSuccess: action.payload,
        isUpdatingData: false
      };
    case ROLES.UPDATE.FAILURE:
      return {
        ...state,
        isUpdatingData: false,
        onUpdateError: action.payload
      };
    case ROLES.UPDATE.CLEAR:
      return {
        ...state,
        onUpdateError: null,
        onUpdateSuccess: null
      };
    // Delete Role
    case ROLES.DELETE.START:
      return {
        ...state,
        isDeletingData: true,
        onDeleteError: null
      };
    case ROLES.DELETE.SUCCESS:
      return {
        ...state,
        onDeleteSuccess: action.payload,
        isDeletingData: false
      };
    case ROLES.DELETE.FAILURE:
      return {
        ...state,
        isDeletingData: false,
        onDeleteError: action.payload
      };
    case ROLES.DELETE.CLEAR:
      return {
        ...state,
        onDeleteError: null,
        onDeleteSuccess: null
      };
    case ROLES.FILTER.STATE:
      return {
        ...state,
        rolesFilterEnabled: action.payload
      };
    // Get Role Permissions
    case ROLES.PERMISSIONS.FETCH.START:
      return {
        ...state,
        isReceivingRolePermissions: true,
        rolePermissionsError: null
      };
    case ROLES.PERMISSIONS.FETCH.SUCCESS:
      return {
        ...state,
        rolePermissions: JSON.parse(action.payload),
        isReceivingRolePermissions: false
      };
    case ROLES.PERMISSIONS.FETCH.FAILURE:
      return {
        ...state,
        isReceivingRolePermissions: false,
        rolePermissionsError: action.payload
      };
    default: return state;
  }
};

export default rolesReducerFn;
