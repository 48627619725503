/* eslint-disable import/prefer-default-export */
import { create } from "zustand";

export const useUserDataStore = create((set) => ({
  userData: {
    profilePicture: "",
  },
  setUserData: (newData) => set((state) => ({ userData: newData })),
}));

export const useThemeStore = create((set) => ({
  theme: !window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "ligth"
    : "dark",
  setTheme: (newTheme) => set((state) => ({ theme: newTheme })),
}));
