/* eslint-disable prettier/prettier */
import React from "react";

// Assets
import SC from "./forgot-password-form.styles";

interface ForgotPasswordFormProps {
  handleSubmit: any;
  credentials: any;
  handleOnChange: any;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const { handleSubmit, credentials, handleOnChange } = props;

  return (
    <SC.Form onSubmit={handleSubmit}>
      <SC.Text>
        Enter the email associated with your account and we’ll send you a reset
        password link
      </SC.Text>
      <SC.EmailField
        autoFocus
        name="email"
        label="Email Address"
        placeholder="Enter your Email Address"
        type="text"
        variant="outlined"
        fullWidth
        value={credentials.email}
        InputProps={{
          endAdornment: (
            <SC.EmailInputAdornment position="start" className="emailIcon">
              <SC.PersonOutlineOutlinedIcon />
            </SC.EmailInputAdornment>
          ),
        }}
        onChange={handleOnChange}
      />
      <br />
      <SC.DividerMobile component="div" />
      <SC.Button type="submit" color="primary">
        Send Email
      </SC.Button>
      <br />
      <SC.NoRegisteredUserInfo>
        Back to&nbsp;
        <SC.Link to="/login">Sign in</SC.Link>
      </SC.NoRegisteredUserInfo>
    </SC.Form>
  );
};

export default ForgotPasswordForm;
