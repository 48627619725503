import {
  withStyles,
  Typography,
  Button as ButtonBase,
} from "@material-ui/core";

import {
  Container,
  Content,
  Logo,
  Title as OriginTitle,
} from "pages/signin/signin.styles";
import Form from "components/signin-form/signin-form.component";

export const Title = withStyles(() => ({
  root: {
    fontSize: "30px",
    lineHeight: "37px",
    paddingBottom: "8px",
  },
}))(OriginTitle);

export const Text = withStyles(() => ({
  root: {
    color: "#757575",
    fontSize: "16px",
    lineHeight: "22px",
    paddingBottom: "51px",
    fontWeight: 600,
    "& b": {
      color: "#000000",
    },
  },
}))(Typography);

export const Footer = withStyles({
  root: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#757575",
  },
})(Typography);

export const Button = withStyles({
  root: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 600,
    textTransform: "inherit",
    color: "#B8CAC6",
    border: "none",
    padding: "0px",
    paddingLeft: "5px",
    "&:hover": {
      background: "unset",
    },
  },
})(ButtonBase);

export default {
  Container,
  Content,
  Logo,
  Title,
  Form,
  Text,
  Footer,
  Button,
};
