/* eslint-disable prettier/prettier */
import React from "react";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { useResetPasswordMutation } from "graphql/types-and-hooks";
import { useParams } from "react-router-dom";

import { isServerError } from "errors/server.errors";
import { UIError, UIErrorCodes } from "errors/ui.errors";
import { useAppErrorHandler } from "errors/app.errors";

// Assets
import SC from "./reset-password.styles";

export type UserPassword = {
  newPassword: string;
  confirmPassword: string;
};

export const ResetPasswordScreen: React.FC = () => {
  const currentParams: { code: string } = useParams();
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [userPasswords, setUserPasswords] = React.useState<UserPassword>({
    newPassword: "",
    confirmPassword: "",
  });

  const [sendNewPassword, { loading, error: newPasswordError, data }] =
    useResetPasswordMutation({
      fetchPolicy: "no-cache",
      context: {
        noAuthNeeded: true,
      },
    });

  const errorHandler = useAppErrorHandler(newPasswordError);

  const handleUserPasswords = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserPasswords((oldUserCredentials) => ({
      ...oldUserCredentials,
      [name]: value,
    }));
  };

  const onSubmit: React.FormEventHandler = React.useCallback(
    async (event) => {
      event.preventDefault();
      const { code: currentCode } = currentParams;

      try {
        sendNewPassword({
          variables: {
            password: userPasswords.newPassword,
            code: currentCode,
          },
        });
        if (!emailSent) setEmailSent(true);
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updating your password!"
            )
          );
        }
      }
    },
    [sendNewPassword, userPasswords, emailSent, currentParams, errorHandler]
  );

  return (
    <SC.Container>
      <ProgressIndicator open={loading} />
      <SC.Content>
        <SC.Logo />
        <SC.Title>{emailSent ? "Password Reset" : "Set New Password"}</SC.Title>
        {emailSent ? (
          <SC.ResetPasswordResult />
        ) : (
          <SC.Form
            handleSubmit={onSubmit}
            credentials={userPasswords}
            handleOnChange={handleUserPasswords}
          />
        )}
      </SC.Content>
    </SC.Container>
  );
};

export default ResetPasswordScreen;
