/* eslint-disable */
// Redux Action Types
import { ActionTypes } from '../actions/types';

// Constants
import { DEFAULT_TAB_PAGE } from '../../commons/constants';

const { PARENT_NAME, CHILD_NAME, DEFAULT_ROUTE } = DEFAULT_TAB_PAGE;

const appInitialState = {
  route: {
    parent: PARENT_NAME,
    child: CHILD_NAME,
    newRoute: DEFAULT_ROUTE
  }
};

const routeReducerFn = (state = appInitialState, action) => {
  switch (action.type) {
    case ActionTypes.ROUTE.TAB:
      return {
        ...state,
        route: action.payload,
      };
    default:
      return state;
  }
};

export default routeReducerFn;
