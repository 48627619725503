/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";
import {
  Box,
  Divider,
  IconButton,
  SvgIcon,
  Typography,
  useTheme,
} from "@material-ui/core";

// Components
import Pane from "components/pane/pane.component";
// import TextField from "components/text-field/text-field.component";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "components/autocomplete/autocomplete.component";
import List from "components/list/list.component";
import UserProfilePicture from "components/user-profile-picture/user-profile-picture.component";
import DateTimeField from "components/datetime-field/datetime-field.component";
import { KeyboardDateTimePicker, DateTimePicker } from "@material-ui/pickers";

import {
  Visibility,
  VisibilityOff,
  CheckCircleOutline,
} from "@material-ui/icons";

// Types
import {
  UserData,
  DepartmentData,
  OrganisationData,
  GroupData,
  RoleData,
} from "graphql/types-and-hooks";

// Utils
import { resizeImage } from "utils/image";

// Assets
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SC from "./organization-form-fields.styles";

interface Props {
  userData: UserData;
  isEditable: boolean;
  isProfile: boolean;
  organisations?: OrganisationData[];
  departments?: DepartmentData[];
  groups?: GroupData[];
  onChange: (userData: UserData) => void;
}

const OrganizationFormFields: React.FC<Props> = ({
  userData,
  isEditable,
  isProfile,
  organisations = [],
  departments = [],
  groups = [],
  onChange,
}) => {
  const theme = useTheme();
  const classes = SC.generalStyles();
  const userRoles: RoleData[] = [];

  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [expDate, setExpDate] = React.useState<Date | null>(new Date());

  const handleClickShowPassword = (mainPasswordField: any) => {
    if (mainPasswordField) setShowPassword1(!showPassword1);
    else setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleExpDateChange = (date: Date | null) => {
    setExpDate(date);
  };

  const handleActiveStatus = () => {
    const newUserData = { ...userData };
    newUserData.active = !newUserData.active;
    onChange(newUserData);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newUserData = { ...userData };

    switch (name) {
      case "workAddress":
        newUserData.work = { ...newUserData.work, address: value };
        break;
      // case "workPhone":
      //   newUserData.work = { ...newUserData.work, phone: value };
      //   break;
      // // case "workName":
      //   newUserData.organisation = { ...newUserData.organisation, name: value };
      //   break;
      // case "departmentName":
      //   newUserData.department = { ...newUserData.department, name: value };
      //   break;
      default:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newUserData[name] = value;
        break;
    }

    onChange(newUserData);
  };

  const handleAutocompleteDepartmentChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: DepartmentData | null
  ) => {
    const newUserData = { ...userData, department: value };
    onChange(newUserData);
  };

  const handleAutocompleteOrganisationChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: OrganisationData | null
  ) => {
    const newUserData = { ...userData, organisation: value };
    onChange(newUserData);
  };

  const handleAutocompleteGroupChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: GroupData[] | null
  ) => {
    const newUserData = { ...userData, groups: value };
    onChange(newUserData);
  };

  const handleCreationDate = (date: MaterialUiPickersDate) => {
    const newUserData = { ...userData, creationDate: date?.toISOString() };
    onChange(newUserData);
  };

  const handleExpirationDate = (date: MaterialUiPickersDate) => {
    const newUserData = { ...userData, expirationDate: date?.toISOString() };
    onChange(newUserData);
  };

  // const [selectedDate, handleExpirationDate] = React.useState<Date | null>(
  //   new Date()
  // );
  const handleInputProfilePicture: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      if (event.target.files && window.FileReader) {
        const file = event.target.files[0];

        if (file && file.type.match("image.*")) {
          const { width = 200, height = 200 } = theme.userProfilePicture;

          resizeImage(
            file,
            width as number,
            height as number,
            (result: string | Blob | File | ProgressEvent<FileReader>) => {
              const newUserData = {
                ...userData,
                [event.target.name]: result.toString(),
              };
              onChange(newUserData);
            }
          );
        }
      }
    };

  userData.groups?.forEach((group) => {
    group?.roles?.forEach((role) => {
      if (role != null && !userRoles.includes(role)) userRoles.push(role);
    });
  });
  const today = new Date().toISOString();

  return (
    <>
      <Box component="div" className={classes.profileHeader}>
        <UserProfilePicture
          name="profilePicture"
          urlPicture={userData.profilePicture!}
          isEditable={isEditable}
          onChange={handleInputProfilePicture}
        />
        <Box component="div">
          <Typography className="nameLbl">{userData.firstName}</Typography>
          <Typography className="companyLbl">
            {userData.organisation ? userData.organisation.name : "-"}
          </Typography>
          <Box className={classes.status}>
            <Typography>Active</Typography>
            {/* <Box className="separator" />
            <Typography>03/09/2021</Typography> */}
          </Box>
        </Box>
      </Box>
      <Divider />
      <SC.Grid
        container
        spacing={2}
        disabledMarginTop
        className={classes.formContainer}
        xl={12}
        lg={12}
        md={12}
        sm={12}
      >
        <SC.Grid item xl={6} lg={6} md={12} sm={12} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <TextField
              name="firstName"
              label="Organization Name"
              placeholder="Organization"
              variant="outlined"
              fullWidth
              required
              value={userData.firstName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
          {/* <SC.Box>
            <TextField
              name="organizationPhone"
              label="Organization Phone"
              variant="outlined"
              placeholder="415 555-8725"
              fullWidth
              required
              // value={userData.workPhone}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
          <SC.Box>
            <KeyboardDateTimePicker
              inputVariant="outlined"
              variant="inline"
              format="dd/MM/yyyy hh:mm a"
              margin="normal"
              id="startDate"
              label="Expiration Date"
              fullWidth
              value={expDate}
              onChange={handleExpDateChange}
              InputProps={{ readOnly: true }}
              className={classes.dateField}
              disabled={!isEditable}
            />
          </SC.Box> */}
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} sm={12} disabledMarginTop>
          {/* <SC.Box disabledMarginTop>
            <TextField
              name="organizationType"
              label="Organization Type"
              placeholder="Org. type"
              variant="outlined"
              fullWidth
              required
              // value={userData.workName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box> */}
          <SC.Box disabledMarginTop>
            <TextField
              name="email"
              label="Email Address"
              variant="outlined"
              placeholder="john@doe.com"
              fullWidth
              type="email"
              required
              value={userData.email}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
        </SC.Grid>
      </SC.Grid>
      <Divider />
      <Box className={classes.subtitle}>Security</Box>
      <SC.Grid
        container
        spacing={2}
        disabledMarginTop
        xl={12}
        lg={12}
        md={12}
        sm={12}
      >
        <SC.Grid item xl={6} lg={6} md={12} sm={12} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <TextField
              name="password"
              label="New Password"
              variant="outlined"
              placeholder="Password"
              fullWidth
              type={showPassword1 ? "text" : "password"}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleClickShowPassword(true);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className="passwordVisibility"
                    hidden={!isEditable}
                  >
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <Typography className={classes.footText}>
              Must be at least 8 characters long and must contain at least one
              number and at least one special character
            </Typography>
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} sm={12} disabledMarginTop>
          <SC.Box className={!isProfile ? classes.hide : ""} disabledMarginTop>
            <TextField
              name="lastPassword"
              label="Confirm New Password"
              variant="outlined"
              placeholder="Password"
              fullWidth
              type={showPassword2 ? "text" : "password"}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleClickShowPassword(false);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className="passwordVisibility"
                    hidden={!isEditable}
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <Typography className={classes.footText}>
              Passwords must match
            </Typography>
          </SC.Box>
        </SC.Grid>
        <SC.Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          disabledMarginTop
          className="emailVerification"
        >
          <SC.Box className={classes.emailNotVerified}>
            <SvgIcon component={CheckCircleOutline} />
            <Typography>Email not verified yet</Typography>
          </SC.Box>
        </SC.Grid>
      </SC.Grid>
    </>
  );
};

export default OrganizationFormFields;
