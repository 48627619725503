import React from "react";

import { Fab, Zoom, useScrollTrigger } from "@material-ui/core";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import SC from "./scrollToTop-button.styles";

const ScrollToTopButton: React.FC<{
  containerId: string;
  querySelector: string;
  refContainer: any;
}> = ({ containerId, querySelector, refContainer }) => {
  const [scrollTarget, setScrollTarget] =
    React.useState<Node | undefined>(undefined);

  const trigger = useScrollTrigger({
    target: scrollTarget,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector(`#${querySelector}`);

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  React.useEffect(() => {
    const currentNode = document.getElementById(containerId) as Node;

    if (currentNode) {
      setScrollTarget(currentNode);
    }
  }, [refContainer, setScrollTarget, containerId]);

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation">
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
};

export default ScrollToTopButton;
