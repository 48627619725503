import React, { useState } from "react";

// Components
import AutofillField from "components/autofill-field/autofill-field.component";

// Styles
import SC from "./left-pane.styles";

export interface MockData {
  id: string;
  value: string;
}

export interface LeftPaneProps {
  searchOptionsData?: MockData[] | [];
}

const mockData: MockData[] = [
  { id: "1", value: "Auditor" },
  { id: "2", value: "Auditor Manager" },
  { id: "3", value: "Data Scientist" },
  { id: "4", value: "App Admin" },
  { id: "5", value: "Super Admin" },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const LeftPaneContainer = ({
  searchOptionsData = [],
}: LeftPaneProps) => {
  const [inputvalue, setinputvalue] = useState("");

  const onSearchFunction = () => {};

  return (
    <SC.Container>
      <SC.Box>
        <SC.Label> Search </SC.Label>
        <AutofillField
          textFieldProps={{
            label: "Search",
            helperText: "Enter Name, TIN",
          }}
          options={searchOptionsData || mockData}
          inputValue={inputvalue}
          onInputChange={(event, newInputValue) => setinputvalue(newInputValue)}
          serverSideSearchProps={{
            minLengthSearch: 3,
            msDelaySearch: 3000,
            onTriggerSearch: onSearchFunction,
          }}
          getOptionLabel={(option: MockData) => option.value}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </SC.Box>
    </SC.Container>
  );
};

export default LeftPaneContainer;
