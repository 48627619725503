import { Container, Content, Logo, Title } from "pages/signin/signin.styles";
import Form from "./reset-password-form.component";
import ResetPasswordResult from "./reset-password-result.component";

export default {
  Container,
  Content,
  Logo,
  Title,
  Form,
  ResetPasswordResult,
};
