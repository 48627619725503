import React from "react";
import { withStyles, IconButton } from "@material-ui/core";

const CopyButton = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.theming.gray.color,
    marginLeft: "10px",
    marginRight: "0px",
    "&:hover": {
      color: theme.theming.hoverIconButton.color,
    },
    [theme.breakpoints.down(540)]: {
      "& svg": {
        height: "20px",
        width: "20px",
      },
    },
  },
}))(IconButton);

export default {
  CopyButton,
};
