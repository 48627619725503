import { IconButton, withStyles, Theme } from "@material-ui/core";

const ButtonSearch = withStyles((theme: Theme) => ({
  root: {
    padding: "7px",
    color: theme.button.primary.color,
    backgroundColor: theme.button.primary.backgroundColor,
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "& span": {
      "& svg": {
        [theme.breakpoints.down("xl")]: {
          fontSize: "28px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "28px",
        },
        [theme.breakpoints.down(540)]: {
          fontSize: theme.fontGeneralStyles.large.fontSize,
        },
      },
    },
  },
}))(IconButton);

export default {
  ButtonSearch,
};
