/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { LOOKER } = ActionTypes;

const LOOKER_INITIAL_STATE = {
  url: {
    20: '',
    21: '',
    22: '',
    23: '',
  },
  isReceivingData: false,
  error: null
};

const createSsoUrlReducerFn = (state = LOOKER_INITIAL_STATE, action) => {
  switch (action.type) {
    case LOOKER.CREATE_SSO_URL.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case LOOKER.CREATE_SSO_URL.SUCCESS: {
      const { data, slidyName } = action.payload;
      // console.warn('looker data: ', data);
      return {
        ...state,
        url: {
          ...state.url,
          [slidyName]: data.data.Looker
        },
        isReceivingData: false
      };
    }
    case LOOKER.CREATE_SSO_URL.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    default: return state;
  }
};

export default createSsoUrlReducerFn;
