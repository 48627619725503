import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useMutation } from "@apollo/client";

import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import SearchIcon from "@material-ui/icons/Search";

import { CLIENT_NAME } from "graphql/client";
import { deleteHistoricBySessionId } from "graphql/corporatus/corporatus_rest_graphql";

import ChatTopicItem, {
  TopicItemModel,
} from "components/chat-topic-item/chat-topic-item.component";
import { ChatHistoricItem } from "graphql/corporatus/corporatus_rest_graphql.types";

import SC from "./chat-topics-list.styles";

interface ChatTopicsListInterface {
  onOpen: (param1: ChatHistoricItem | null) => void;
  items: ChatHistoricItem[];
  loading: boolean;
}

const ChatTopicsList: React.FC<ChatTopicsListInterface> = ({
  onOpen,
  items,
  loading,
}) => {
  const [currentItems, setCurrentItems] = React.useState<ChatHistoricItem[]>(
    []
  );
  const [currentId, setCurrentId] = React.useState<string>("0");
  const [topicsList, setTopicsList] = React.useState<ChatHistoricItem[]>([]);

  const [
    deleteChatMessagesRequest,
    { loading: updateChatMessageLoading, error: updateChatMessageError },
  ] = useMutation(deleteHistoricBySessionId, {
    fetchPolicy: "no-cache",
    context: {
      clientName: CLIENT_NAME.CORPORATUSAPIREST,
      noAuthNeeded: true,
      headers: {
        Authorization: "Bearer O3IGNECRD5KNJCN38L9JGIVBVU8G6W0L1Y0BOCTS",
        "Content-Type": "application/json",
      },
    },
  });

  const onOpenHandler = React.useCallback(
    (item: ChatHistoricItem) => {
      setCurrentId(item.session_id);
      onOpen(item);
    },
    [onOpen]
  );

  const onDeleteHandler = React.useCallback(
    async (item: ChatHistoricItem) => {
      // console.debug(
      //   "=====> Delete messages with session id: ",
      //   item.session_id
      // );
      await deleteChatMessagesRequest({
        variables: { sessionId: item.session_id },
      });

      setCurrentItems((oldCurrentItems) => {
        return [
          ...oldCurrentItems.filter(
            (currentItem) => currentItem.session_id !== item.session_id
          ),
        ];
      });

      onOpen(null);
      setCurrentId("");
    },
    [deleteChatMessagesRequest, onOpen]
  );

  const filterTopics = React.useCallback(
    (term: string) => {
      // console.debug("=====> search topic...", term);
      let itemsFiltered = currentItems.filter(
        (item, index) =>
          currentItems.findIndex(
            (_item) => _item.session_id === item.session_id
          ) === index
      );

      if (term) {
        itemsFiltered = currentItems.filter((item) => {
          return (
            item.query.toLowerCase().includes(term) ||
            item.response.toLowerCase().includes(term)
          );
        });
      }
      setTopicsList(itemsFiltered);
    },
    [currentItems]
  );

  React.useEffect(() => {
    if (currentItems && currentItems.length > 0) {
      const itemsFiltered = currentItems.filter(
        (item, index) =>
          currentItems.findIndex(
            (_item) => _item.session_id === item.session_id
          ) === index
      );
      setTopicsList(itemsFiltered);
    }
  }, [currentItems]);

  React.useEffect(() => {
    setCurrentItems(items ?? []);
  }, [items]);

  return (
    <SC.TopicsListContainer>
      <SC.NewChatButton
        type="button"
        startIcon={<ChatBubbleIcon />}
        onClick={() => {
          onOpen(null);
          setCurrentId("");
        }}
      >
        CHAT
      </SC.NewChatButton>
      <SC.SearchInput
        hideSearchBar={false}
        startIcon={<SearchIcon color="primary" />}
        doSearch={filterTopics}
        clearInputE={false}
        placeholder="Search ..."
        isMultilines={false}
      />
      <Box
        hidden={!loading}
        style={{
          backgroundColor: "rgba(0,0,0,.1)",
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress style={{ margin: "auto" }} />
      </Box>
      <SC.TopicsListContent>
        <SC.NoResults
          show={topicsList.length === 0 && !loading}
          title="Empty History"
          description="Currently you don´t have any chat registered, please start to do queries to the Corporatus Chat."
        />
        {topicsList.map((item) => (
          <ChatTopicItem
            isActive={item.session_id === currentId}
            item={item}
            onOpen={onOpenHandler}
            handlerDeleteItem={onDeleteHandler}
          />
        ))}
      </SC.TopicsListContent>
    </SC.TopicsListContainer>
  );
};

export default ChatTopicsList;
