import { Box, Theme, Typography, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const Container = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}))(Box);

const CustomDivider = withStyles(() => ({
  root: {
    flex: "1",
    backgroundColor: "#BCB0B0",
  },
}))(Divider);

const DateLabel = withStyles((theme: Theme) => ({
  root: {
    width: "178px",
    fontSize: theme.fontGeneralStyles.medium.fontSize,
    lineHeight: theme.fontGeneralStyles.medium.lineHeight,
    color: theme.theming.gray2.color,
  },
}))(Typography);

export default { Container, CustomDivider, DateLabel };
