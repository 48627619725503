/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { TB_STORES } = ActionTypes;

const TB_STORE_INITIAL_STATE = {
  tbStoreDataFromCreating: null,
  isAddingTbStore: false,
  errorCreatingTbStore: null,
};


const createTbStoreReducerFn = (state = TB_STORE_INITIAL_STATE, action) => {
  switch (action.type) {
    case TB_STORES.CREATE.START:
      return {
        ...state,
        isAddingTbStore: true,
        errorCreatingTbStore: null
      };

    case TB_STORES.CREATE.SUCCESS: {
      const { AddTbStore } = action.payload;
      return {
        ...state,
        tbStoreDataFromCreating: AddTbStore,
        isAddingTbStore: false,
      };
    }

    case TB_STORES.CREATE.FAILURE:
      return {
        ...state,
        isAddingTbStore: false,
        errorCreatingTbStore: action.payload,
      };

    case TB_STORES.CREATE.CLEAR:
      return {
        ...state,
        tbStoreDataFromCreating: null,
        errorCreatingTbStore: null
      };
    default: return state;
  }
};


export default createTbStoreReducerFn;
