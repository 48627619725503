import { withStyles, Theme, Button } from "@material-ui/core";

const ButtonSecondary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.secondary.backgroundColor,
    // fontSize: theme.button.secondary.fontSize,
    // lineHeight: theme.button.secondary.lineHeight,
    color: theme.button.secondary.color,
    border: theme.button.secondary.border,
    textTransform: theme.button.secondary.textTransform,
    "&:hover": {
      color: theme.button.secondary.hoverColor,
      backgroundColor: theme.button.secondary.hoverBackground,
      border: theme.button.secondary.hoverBorder,
    },
    "&:disabled": {
      color: theme.button.secondary.disabledColor,
      backgroundColor: theme.button.secondary.disabledBackground,
      border: theme.button.secondary.disabledBorder,
      opacity: "0.5",
    },
    [theme.breakpoints.down(540)]: {
      height: "30px",
      width: "120px",
    },
  },
}))(Button);

export default ButtonSecondary;
