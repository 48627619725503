import { gql } from "@apollo/client";
import { Behavior } from "./types-and-hooks";

export const NEW_BEHAVIOR_QUERY_FRAGMENT_GQL = gql`
  fragment newBehaviorQuery on Behavior {
    id
    behaviorName
    mediaResults
    newMediaResults
    positiveSentimentRate
    negativeSentimentRate
    duration
    json
    tree
    userId
    created
    updated
    results {
      edges {
        node {
          id
          mediaTitle
          mediaId
          mediaUrl
          creationDate
          source
          duration
          positiveSentimentRate
          negativeSentimentRate
          sentences
          entities
          joyScore
          trustScore
          surpriseScore
          anticipationScore
          sadnessScore
          fearScore
          angerScore
          disgustScore
          behaviorId
          created
        }
      }
    }
    newResults {
      edges {
        node {
          id
          mediaTitle
          mediaId
          mediaUrl
          creationDate
          source
          duration
          sentimentScore
          sentences
          entities
          joyScore
          trustScore
          surpriseScore
          anticipationScore
          sadnessScore
          fearScore
          angerScore
          disgustScore
          behaviorId
          created
        }
      }
    }
  }
`;

export const BEHAVIOR_QUERY_DELETED_FRAGMENT_GQL = gql`
  fragment behaviorQueryDeleted on DeleteBehavior {
    success
    errors
  }
`;
