// export type ConfigVariable = "GraphQLServerURL" | "HasuraGraphQLServerURL";
export type ConfigVariable =
  | "GraphQLServerURL"
  | "GraphQLSearchServerURL"
  | "GraphQLQAndAServerURL"
  | "GraphQLBehaviorsAPIURL"
  | "GraphQLRecommendationsURL"
  | "GraphQLExploreAPIURL"
  | "GraphQLQAndAProServerURL"
  | "APIRESTCorporatusServerURL";

const {
  REACT_APP_GRAPHQL_SERVER_URL,
  REACT_APP_SEARCH_GRAPHQL_SERVER_URL,
  REACT_APP_QANDA_GRAPHQL_SERVER_URL,
  REACT_APP_GRAPHQL_BEHAVIOR_API_URL,
  REACT_APP_GRAPHQL_RECOMMENDATIONS_URL,
  REACT_APP_GRAPHQL_EXPLORE_API,
  REACT_APP_QANDAPRO_GRAPHQL_SERVER_URL,
  REACT_APP_API_REST_CORPORATUS_SERVER_URL,
} = process.env;

class GlobalConfig {
  private static variables: {
    [x in ConfigVariable]?: string;
  } = {};

  static get(variable: ConfigVariable): string | undefined {
    const { variables } = GlobalConfig;

    if (!variables[variable]) {
      switch (variable) {
        case "GraphQLServerURL":
          variables[variable] = REACT_APP_GRAPHQL_SERVER_URL;
          break;
        case "GraphQLSearchServerURL":
          variables[variable] = REACT_APP_SEARCH_GRAPHQL_SERVER_URL;
          break;
        case "GraphQLQAndAServerURL":
          variables[variable] = REACT_APP_QANDA_GRAPHQL_SERVER_URL;
          break;
        case "GraphQLBehaviorsAPIURL":
          variables[variable] = REACT_APP_GRAPHQL_BEHAVIOR_API_URL;
          break;

        case "GraphQLRecommendationsURL":
          variables[variable] = REACT_APP_GRAPHQL_RECOMMENDATIONS_URL;
          break;

        case "GraphQLExploreAPIURL":
          variables[variable] = REACT_APP_GRAPHQL_EXPLORE_API;
          break;

        case "GraphQLQAndAProServerURL":
          variables[variable] = REACT_APP_QANDAPRO_GRAPHQL_SERVER_URL;
          break;

        case "APIRESTCorporatusServerURL":
          variables[variable] = REACT_APP_API_REST_CORPORATUS_SERVER_URL;
          break;

        default:
          throw new Error("Environment variable not implemented");
      }
    }

    return variables[variable];
  }
}

export default GlobalConfig;
