import React from "react";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import ButtonSecondary from "components/button-secondary/button-secondary.component";
import ButtonPrimary from "components/button-primary/button-primary.component";

import SC from "./delete-icon-button.styles";

// Start EChart Component

interface DeleteButtonProps {
  itemId: string;
  itemName: string;
  type: string;
  deleteItem: (arg0: any, arg1?: string) => void;
  data?: any;
  itemDeleted?: boolean;
}

const DeleteIconButton: React.FC<DeleteButtonProps> = ({
  itemId,
  itemName,
  type,
  deleteItem,
  data = null,
  itemDeleted = null,
}) => {
  //   const classes = SC.useChartStyles();
  const [deleting, setDeleting] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  React.useEffect(() => {
    if (itemDeleted !== null) setDeleting(!itemDeleted);
  }, [itemDeleted]);

  return (
    <>
      <SC.DeleteButton onClick={handleClickToggleDelete}>
        <CircularProgress hidden={deleting} style={{ position: "absolute" }} />
        <DeleteIcon />
      </SC.DeleteButton>
      <Dialog
        id={`dialog-${itemId}`}
        open={openDelete}
        onClose={handleClickToggleDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete target</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want delete &quot;{itemName}
            &quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary onClick={handleClickToggleDelete}>
            Cancel
          </ButtonSecondary>
          <ButtonPrimary
            onClick={(event) => {
              if (itemDeleted !== null) setDeleting(itemDeleted);
              if (type) {
                deleteItem(itemId, type);
              } else {
                deleteItem(data);
              }
              handleClickToggleDelete();
            }}
            autoFocus
          >
            Delete
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteIconButton;
