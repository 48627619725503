/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependecies
import React from "react";
import {
  makeStyles,
  withStyles,
  Box,
  InputBase,
  InputBaseProps,
  IconButton,
  IconButtonProps,
  Button,
  Theme,
} from "@material-ui/core";

// Functions
import { adaptativeSmallScreen } from "components/theme-provider/theme.utils";

interface CustomIconButtonProps extends IconButtonProps {
  isActive?: boolean;
  clearActive?: boolean;
}

interface CustomInputBaseProps extends InputBaseProps {
  isActive: boolean;
}

const GeneralSearch = withStyles((theme) => ({
  root: {
    height: "100%",
    padding: "10px 35px",
    flex: "1",
    position: "relative",
    display: "flex",
    alignItems: "end",
    justifyContent: "start",
    [theme.breakpoints.down("xl")]: {
      width: theme.generalSearchSmall.width,
    },
    [theme.breakpoints.up("xl")]: {
      width: theme.generalSearch.width,
    },
    // "&:hover": {
    //   color: "#807795 !important",
    // },
    // "&:active": {
    //   color: "#7E6FA2 !important",
    // },
  },
}))(Box);

const clearStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 12px",
    height: "30px",
    borderRadius: "0px",
    display: (props: CustomIconButtonProps) =>
      props.clearActive ? "block" : "none",
    zIndex: 1,
    color: theme.generalSearchActive.color,
    "&:hover": {
      backgroundColor: "transparent",
      color: "#807795 !important",
    },
    "& svg": {
      [theme.breakpoints.down("xl")]: {
        fontSize: adaptativeSmallScreen(1.5),
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "1.5rem",
      },
    },
  },
}));

const Input = withStyles((theme: Theme) => ({
  root: {
    width: "50%",
    fontSize: theme.fontGeneralStyles.medium.fontSize,
    fontWeight: 500,
    lineHeight: "32px",
    zIndex: (props: CustomInputBaseProps) => (props.isActive ? 1 : 3),
    [theme.breakpoints.down("xl")]: {
      minHeight: "32px",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "32px",
    },
    position: "relative",
    border: "none",
    borderBottom: "solid 1.5px #727273",
    // background: "transparent",
    // color: "#6B6B6B",
    // "&:hover": {
    //   borderColor: "#6B6B6B",
    //   // color: "#6B6B6B",
    //   "& input::placeholder": {
    //     color: "#6B6B6B !important",
    //   },
    //   "& .MuiButtonBase-root:first-child": {
    //     color: "#6B6B6B",
    //   },
    // },
    // "&.active": {
    //   borderColor: "#6B6B6B",
    //   "& input": {
    //     color: "#6B6B6B",
    //   },
    //   "& .MuiButtonBase-root:first-child": {
    //     color: "#6B6B6B",
    //   },
    // },
    // "& input::placeholder": {
    //   color: "#B0B0B0",
    // },
    [theme.breakpoints.down(540)]: {
      width: "100%",
      fontSize: theme.fontGeneralStyles.medium.fontSize,
    },
  },
}))(InputBase);

const ButtonClear = (props: CustomIconButtonProps): JSX.Element => {
  const { clearActive, ...muiProps } = props;
  const classes = clearStyles(props);
  return <IconButton className={classes.root} {...muiProps} />;
};

export default {
  GeneralSearch,
  Input,
  ButtonClear,
};
