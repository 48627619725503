/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  withStyles,
} from "@material-ui/core";

import { BillingAddress } from "graphql/types-and-hooks";

import MoreVertIcon from "@material-ui/icons/MoreVert";

// Assets
import SC from "components/payment-billing-address-item/payment-address-item.styles";

export interface BillingAddressItemProps {
  billingAddress: BillingAddress;
  editItemHandler: any;
  setAsDefault: any;
  deleteItem: any;
}

const BillingAddressItem: React.FC<BillingAddressItemProps> = ({
  billingAddress,
  editItemHandler,
  setAsDefault,
  deleteItem,
}) => {
  const classes = SC.generalStyles();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleOpenMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  return (
    <Card className={classes.itemContainer}>
      <CardContent className={classes.paymentContainer}>
        <Box className={classes.paymentContent}>
          <Box className={classes.cardDetail} onClick={editItemHandler}>
            <Typography className="title" color="primary">
              {billingAddress?.firstName} {billingAddress?.lastName}
            </Typography>
            <Typography color="primary">
              {billingAddress?.address} {billingAddress?.city},{" "}
              {billingAddress?.state}.
            </Typography>
            <Typography style={{ marginBottom: "25px" }} color="primary">
              {billingAddress?.country}, {billingAddress?.countryCode}
            </Typography>
          </Box>
          <div>
            <Button
              ref={anchorRef}
              aria-controls={openMenu ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              className={classes.menuBtn}
              onClick={handleOpenMenu}
            >
              <MoreVertIcon color="primary" />
            </Button>
            <Popper
              open={openMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                      <MenuList
                        autoFocusItem={openMenu}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        color="primary"
                      >
                        <MenuItem
                          onClick={() => {
                            /* eslint-disable no-param-reassign */
                            const billingAddressCopy: BillingAddress = {
                              ...billingAddress,
                              isDefault: true,
                            };

                            setAsDefault(billingAddressCopy);
                            setOpenMenu(false);
                          }}
                        >
                          Set as Default
                        </MenuItem>
                        <MenuItem onClick={() => setOpenDelete(true)}>
                          Delete Address
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Box>
        {billingAddress?.isDefault ?? false ? (
          <Box className={classes.defaultLbl}>
            <Typography color="primary">Default</Typography>
          </Box>
        ) : null}
      </CardContent>

      <Dialog
        id="delete-dialog"
        open={openDelete}
        onClose={() => {
          setOpenDelete(!openDelete);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="primary">
          Delete Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Are you sure you want to delete this card? Action taken cannot be
            reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SC.ButtonSecondary
            onClick={() => {
              setOpenDelete(!openDelete);
            }}
            color="primary"
          >
            Cancel
          </SC.ButtonSecondary>
          <SC.ButtonPrimary
            onClick={() => {
              deleteItem(billingAddress.id);
              setOpenDelete(!openDelete);
            }}
            color="primary"
            autoFocus
          >
            Delete
          </SC.ButtonPrimary>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default BillingAddressItem;
