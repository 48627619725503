/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  Box,
  Button,
  Grow,
  GrowProps,
  Paper,
  MenuItem,
  MenuItemProps,
  Avatar,
  Theme,
} from "@material-ui/core";
import { AccountCircle as AccountCircleIcon } from "@material-ui/icons";
import { Link, LinkProps } from "react-router-dom";

// Functions
import { adaptativeSmallScreen } from "components/theme-provider/theme.utils";

interface CustomGrowProps extends GrowProps {
  isActive: boolean;
}

const OptionStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "40px",
    fontSize: "14px !important",
    paddingLeft: "10px !important",
    color: `${theme.userMenu.color} !important`,
    "& path": {
      fill: `${theme.userMenu.color} !important`,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: adaptativeSmallScreen(1),
      paddingTop: adaptativeSmallScreen(0.57),
      // paddingLeft: adaptativeSmallScreen(1.14),
      paddingBottom: adaptativeSmallScreen(0.57),
      color: `${theme.userMenu.color}`,
      lineHeight: "22px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "0.57rem",
      // paddingLeft: "1.14rem",
      paddingBottom: "0.57rem",
      color: `${theme.userMenu.color}`,
      lineHeight: "22px",
    },
    "&:hover": {
      background: "#6EA1A340",
      borderRadius: "10px",
    },
    "& svg": {
      marginRight: "14px",
    },
  },
}));

const UserMenu = withStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    // [theme.breakpoints.down("xl")]: {
    //   paddingRight: theme.userMenuSmall.paddingRight,
    // },
    // [theme.breakpoints.up("xl")]: {
    //   paddingRight: theme.userMenu.paddingRight,
    // },
    // paddingRight: "52px",
    // paddingLeft: "15px",
    // paddingLeft: adaptativeSmallScreen(2.18),
    padding: "0px 24px 12px",
    "& div": {
      zIndex: 2,
    },
    "&.single-menu": {
      padding: "0px 12px 12px",
    },
    "&.mobile-user-menu": {
      position: "absolute",
      zIndex: 999,
      right: "0px",
      width: "unset",
      top: "0px",
      padding: "10px !important",
      "& button": {
        height: "38px",
        width: "38px",
        minwidth: "unset",
        "& svg": {
          minWidth: "30px",
          minHeight: "30px",
          width: "100%",
          height: "100%",
        },
      },
    },
    [theme.breakpoints.down(540)]: {
      padding: "unset !important",
    },
  },
}))(Box);

const ButtonIcon = withStyles((theme: Theme) => ({
  root: {
    minWidth: "40px",
    width: "100%",
    // height: "40px",
    display: "flex",
    justifyContent: "start",
    padding: "4px",
    background: "#2A3534",
    borderRadius: "8px",
    color: "#FFF",
    cursor: "default",
    "&:hover": {
      backgroundColor: "#2A3534",
    },
    "&.single-icon": {
      background: "transparent",
      backgroundColor: "transparent",
      padding: "unset",
    },
  },
}))(Button);

const UserIcon = withStyles((theme: Theme) => ({
  root: {
    color: theme.generalStylesContainer.color,
    minHeight: "50px",
    minWidth: "50px",
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.userMenuSmall.fontSize,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.userMenu.fontSize,
    },
  },
}))(AccountCircleIcon);

const UserAvatar = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.userMenuSmall.fontSize,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.userMenu.fontSize,
    },
  },
}))(Avatar);

const TransitionAnimation = (props: CustomGrowProps): JSX.Element => {
  const { isActive, ...muiProps } = props;
  return (
    <Grow
      style={{
        transformOrigin: isActive ? "center top" : "center bottom",
      }}
      {...muiProps}
    />
  );
};

const MenuChildContainer = withStyles((theme: Theme) => ({
  root: {
    width: "300px",
    color: theme.generalStylesContainer.color,
    backgroundColor:
      theme.generalStylesContainer.layers.secondary.backgroundColor,
    padding: "8px",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    "& a:hover": {
      color: theme.generalStylesContainer.hoverColor,
    },
  },
}))(Paper);

const LinkOption: React.ComponentType<MenuItemProps & LinkProps> =
  MenuItem as React.ComponentType<MenuItemProps & LinkProps>;

type LinkOptionProps = MenuItemProps & LinkProps;

const Option: React.FC<LinkOptionProps> = (props) => {
  const { to, ...muiProps } = props;
  const classes = OptionStyles();
  return (
    <LinkOption
      className={classes.root}
      component={Link}
      to={to}
      {...muiProps}
    />
  );
};

const UserNameElem = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.fontGeneralStyles.small.fontSize,
    lineHeight: theme.fontGeneralStyles.small.lineHeight,
    color: "#FFF",
    margin: "auto",
    marginLeft: "14px",
    textTransform: "capitalize",
  },
}))(Box);

const UserEmail = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.fontGeneralStyles.xSmall.fontSize,
    lineHeight: theme.fontGeneralStyles.xSmall.lineHeight,
    color: "#FFF",
    margin: "auto",
    marginLeft: "14px",
    textTransform: "capitalize",
  },
}))(Box);

const UsernameItem = withStyles((theme: Theme) => ({
  root: {
    // display: "none",
    display: "flex",
    padding: "unset",
    justifyContent: "start",
    "& svg": {
      color: `${theme.userMenu.color} !important`,
    },
    "& p": {
      color: `${theme.userMenu.color} !important`,
      margin: "unset",
      fontWeight: 800,
    },
    "& .email": {
      color: "#C8C6C6 !important",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down(540)]: {
      display: "flex",
      cursor: "unset",
      paddingTop: adaptativeSmallScreen(0.57),
      paddingLeft: adaptativeSmallScreen(1.14),
      paddingBottom: adaptativeSmallScreen(0.57),
    },
  },
}))(MenuItem);

const MenuIcon = withStyles((theme: Theme) => ({
  root: {
    minWidth: "unset",
    width: "fit-content",
    position: "absolute",
    right: "0px",
    top: "0px",
    "& .MuiButton-startIcon": {
      margin: "unset",
    },
  },
}))(ButtonIcon);

export default {
  UserMenu,
  ButtonIcon,
  UserIcon,
  TransitionAnimation,
  MenuChildContainer,
  Option,
  UserAvatar,
  UserNameElem,
  UsernameItem,
  UserEmail,
  MenuIcon,
};
