// Dependencies
import React from "react";
import { BoxProps, Button, SvgIcon } from "@material-ui/core";
import { Add, Fullscreen, ViewColumn, SaveAlt } from "@material-ui/icons";

// Components
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import { isMobileResolution } from "commons/utils/device-info.util";

// Assets
// FilterAlt icon will be available in next version of @material-ui/core
import { ReactComponent as FilterAlt } from "assets/icons/filter_alt-black-24dp.svg";
import SC from "./table-toolbar-actions.styles";

export type TableToolbarAction =
  | "add"
  | "fullscreen-expand"
  | "hide/show-columns"
  | "filter-results"
  | "export-to-excel-sheet/csv";

const ActionIcon: Record<TableToolbarAction, JSX.Element> = {
  add: <Add />,
  "fullscreen-expand": <Fullscreen />,
  "hide/show-columns": <ViewColumn />,
  "filter-results": <SvgIcon component={FilterAlt} />,
  "export-to-excel-sheet/csv": <SaveAlt />,
};

const ActionTitle: Record<TableToolbarAction, string> = {
  add: "Add",
  "fullscreen-expand": "Fullscreen Expand",
  "hide/show-columns": "Hide/Show Columns",
  "filter-results": "Filter Results",
  "export-to-excel-sheet/csv": "Export to Excel/Sheets/CSV",
};

interface Props extends BoxProps {
  actions?: TableToolbarAction[];
  onAction?: (action: TableToolbarAction) => void;
}

const TableToolbarActions: React.FC<Props> = ({
  actions,
  onAction,
  ...props
}) => {
  const classes = SC.useStyles();
  const appPermissionValidator = useAppPermissionValidator();

  const filteredActions = React.useMemo<TableToolbarAction[] | undefined>(
    () =>
      appPermissionValidator?.(APP_PERMISSION.ADMIN_EXPORT)
        ? actions
        : actions?.filter((action) => action !== "export-to-excel-sheet/csv"),
    [actions, appPermissionValidator]
  );

  if (!filteredActions) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SC.ToolbarActions {...props} display="flex">
      {filteredActions.map((action) => (
        <SC.Tooltip key={action} title={ActionTitle[action]}>
          {action === "add" ? (
            <Button
              className={classes.addBtn}
              onClick={() => onAction?.(action)}
              startIcon={<Add />}
            >
              {!isMobileResolution() ? Add : ""}
            </Button>
          ) : (
            <SC.IconButton
              aria-label={ActionTitle[action]}
              onClick={() => onAction?.(action)}
            >
              {ActionIcon[action]}
            </SC.IconButton>
          )}
        </SC.Tooltip>
      ))}
    </SC.ToolbarActions>
  );
};

TableToolbarActions.defaultProps = {
  actions: [],
};

export default TableToolbarActions;
