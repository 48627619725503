import {
  withStyles,
  Typography,
  Button as ButtonBase,
} from "@material-ui/core";

import { Form } from "components/signin-form/signin-form.styles";

export const Text = withStyles(() => ({
  root: {
    color: "#757575",
    fontSize: "16px",
    lineHeight: "22px",
    paddingBottom: "68px",
    fontWeight: 400,
    "& b": {
      color: "#000000",
    },
  },
}))(Typography);

export const Footer = withStyles({
  root: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 600,
    color: "#757575",
  },
})(Typography);

export const Button = withStyles({
  root: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 600,
    textTransform: "inherit",
    color: "#B8CAC6",
    border: "none",
    padding: "0px",
    paddingLeft: "5px",
    "&:hover": {
      background: "unset",
    },
  },
})(ButtonBase);

export default {
  Form,
  Text,
  Footer,
  Button,
};
