/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  createTheme,
} from "@material-ui/core/styles";
import { Box, Button, Table } from "@material-ui/core";

import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

import YoutubeIcon from "assets/icons/YoutubeLogo.png";
import ApplePCIcon from "assets/icons/ApplePCLogo.png";
import DefaultIcon from "assets/icons/DefaultLogo.png";
import VimeoIcon from "assets/icons/VimeoLogo.png";
import TiktokIcon from "assets/icons/TicktockLogo.png";
import SpotifyIcon from "assets/icons/SpotifyLogo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      backgroundColor: "transparent",
      boxShadow: "none",
      width: "calc(100% + 20px)",
      marginLeft: "-10px",
      marginRight: "-10px",
      "& th": {
        paddingLeft: "10px",
        paddingRight: "10px",
        border: "none",
        "& .itemSerch": {
          marginLeft: "0px",
          marginRight: "0px",
        },
      },
      [theme.breakpoints.down(540)]: {
        width: "calc(100% + 40px)",
        marginLeft: "-20px",
        marginRight: "-20px",
        padding: "0px 20px",
        "& th": {
          padding: "7px 0px",
        },
      },
    },
    itemContainer: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    brandIcon: {
      width: "35px",
      marginRight: "10px",
    },
    brandLbl: {
      fontSize: "22px",
      lineHeight: "27px",
      flex: "1",
    },
    itemActions: {
      display: "flex",
      height: "55px",
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    linkStyle: {
      borderBottom: "1px solid #ABABAB",
      borderRadius: "0px",
    },
    gray: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#ABABAB",
    },
    purple: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#B8CAC6",
    },
    elemStyle: {
      marginRight: "30px",
    },
    hide: {
      display: "none",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "15px",
    },
    rowStyle: {
      paddingTop: "7px",
      paddingBottom: "7px",
    },
    "& th:first-child": {
      paddingTop: "14px",
    },
    "& th:last-child": {
      paddingBottom: "14px",
    },
    title: {
      flex: "1 1 100%",
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    subTableRoot: {
      padding: "0px",
    },
    subTableTitle: {
      minHeight: "35px",
      padding: "0px",
    },
    headerLabel: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "bold",
    },
    subTableHeader: {
      borderBottom: "1px solid #EAEAEA",
    },
    paddingLeft0: {
      "& td": {
        paddingLeft: "0px",
      },
      "& th": {
        paddingLeft: "0px",
      },
      "& span": {
        paddingLeft: "0px",
      },
    },
    cellBodyStyle: {
      fontSize: "12px",
      lineHeight: "15px",
      color: "#444444",
      borderBottom: "0px",
    },
    rowEven: {
      background: "rgba(146, 128, 187, 0.04)",
    },
    addSourcesBtn: {
      height: "48px",
      width: "147px",
      background: "#B8CAC6",
      border: "2px solid #B8CAC6",
      boxSizing: "border-box",
      borderRadius: "116px",
      color: "#FFFFFF",
      fontSize: "18px",
      lineHeight: "22px",
      textTransform: "capitalize",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& h1": {
        flex: "1",
      },
      [theme.breakpoints.down(540)]: {
        "& h1": {
          fontSize: theme.fontGeneralStyles.large.fontSize,
          lineHeight: theme.fontGeneralStyles.large.lineHeight,
          fontWeight: 600,
        },
      },
    },
    downloadBtn: {
      fontSize: "12px",
      lineHeight: "15px",
      background: "rgba(90, 227, 194, 0.1)",
      color: "#5AE391",
      width: "auto",
      height: "23px",
      borderRadius: "10px",
    },
    linkInCell: {
      color: "#A23A74",
      fontSize: "12px",
      lineHeight: "15px",
      textDecorationLine: "underline",
    },
    noimg: {
      color: "#FFFFFF",
      backgroundColor: "#B8CAC6",
    },
    nameCell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "16px",
      paddingBottom: "16px",
      verticalAlign: "unset !important",
    },
    cellAvatar: {
      height: "30px",
      width: "30px",
    },
    popOverDatePicker: {
      "& .MuiPaper-root": {
        width: "372px",
        height: "417px",
        boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
        borderRadius: "10px",
        padding: "21px 31px",
        "& .MuiTextField-root": {
          width: "100%",
          top: "-10px",
          "& .MuiFormLabel-root": {
            position: "absolute",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#B8CAC6",
            background: "#FFFFFF",
            top: "7px",
            left: "5px",
            zIndex: "1",
            padding: "1px 5px",
          },
          "& .MuiInput-root": {
            border: "2px solid #7E6FA2",
            boxSizing: "border-box",
            borderRadius: "4px",
            padding: "10px",
            fontSize: "18px",
            lineHeight: "22px",
            color: "#B8CAC6",
            "& input": {
              padding: "0px",
            },
          },
          "& .MuiInput-root:after": {
            borderBottom: "none",
          },
          "& .MuiInput-root:before": {
            borderBottom: "none",
          },
        },
      },
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    actionsCalendar: {
      position: "absolute",
      height: "25px",
      top: "37px",
      right: "0px",
      display: "flex",
      width: "50%",
      alignItems: "center",
      justifyContent: "end",
      paddingRight: "41px",
      "& .containerBtns": {
        display: "flex",
        width: "66px",
        height: "25px",
        border: "1px solid #7E6FA2",
        boxSizing: "border-box",
        borderRadius: "4px",
      },
      "& button": {
        minWidth: "unset",
        maxWidth: "32px",
        color: "#B8CAC6",
        flex: "1",
        borderRadius: "0px",
      },
      "& button:active": {
        background: "#7E6FA2",
        color: "#FFFFFF",
      },
      "& svg": {
        marginLeft: "16px",
        color: "#7E6FA2",
      },
    },
  })
);

const images: any = {
  YoutubeIcon,
  ApplePCIcon,
  DefaultIcon,
  VimeoIcon,
  TiktokIcon,
  SpotifyIcon,
};

function getImageByKey(key: string) {
  return images[key];
}

const ASContainer = withStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
  },
}))(Box);

const ASContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "30px 35px",
    overflow: "auto",
    "& h1": {
      fontFamily: "Gothic A1",
      color: "#000",
      textAlign: "left",
      fontSize: theme.fontGeneralStyles.large.fontSize,
      lineHeight: theme.fontGeneralStyles.large.lineHeight,
      fontWeight: 800,
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px",
      gap: "20px",
    },
  },
}))(Box);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
    [theme.breakpoints.down(540)]: {
      height: "unset",
      width: "unset",
      minWidth: "unset",
      borderRadius: "50%",
      padding: "6px",
      "& .MuiButton-startIcon": {
        margin: "unset",
      },
    },
  },
}))(Button);

const NoResults = withStyles(() => ({
  root: {
    flex: "1",
    marginLeft: "0px",
    marginRight: "0px",
  },
}))(GenericEmptyMessages);

const MainTable = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      width: "calc(100vw - 40px)",
    },
  },
}))(Table);

export default {
  useStyles,
  ASContainer,
  ASContent,
  getImageByKey,
  ButtonPrimary,
  NoResults,
  Table,
};
