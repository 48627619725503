/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Redirect } from "react-router-dom";
import {
  Box,
  TableHead,
  Button,
  Theme,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardActions,
  Link,
} from "@material-ui/core";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AddIcon from "@material-ui/icons/Add";
import waveBorder from "assets/images/wave-border.png";

import { withStyles, createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import {
  AudionautPlan,
  BillingAddress,
  OrganisationData,
} from "graphql/types-and-hooks";
import { isMobileResolution } from "commons/utils/device-info.util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      title: {
        "& h1": {
          textAlign: "left",
        },
        pagination: {
          flex: "1",
        },
      },
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    sectionContainer: {
      height: "100%",
      marginBottom: "56px",
      [theme.breakpoints.down(540)]: {
        maxHeight: "550px",
        marginBottom: "unset",
        "& .alice-carousel": {
          marginTop: "0px",
          height: "calc(100% - 50px)",
          "& > div": {
            height: "100%",
            "& .alice-carousel__wrapper": {
              height: "100%",
            },
          },
        },
      },
    },
    sectionTitle: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "bold",
      textAlign: "left",
      marginBottom: "20px",
    },
    cardsContainer: {
      height: "100%",
      paddingLeft: "64px",
      paddingRight: "64px",
      margin: "unset",
    },
  })
);

const UPContainer = withStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100vh - 108px)",
    // display: "flex",
    overflow: "auto",
  },
}))(Box);

const UPContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "30px 32px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      height: "100%",
      padding: "20px",
      paddingBottom: "0px",
    },
  },
}))(Box);

const UPHeader = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    background: theme.generalStylesContainer.layers.main.backgroundColor,
    minHeight: "130px",
    display: "flex",
    flexDirection: "column",
    "& h1": {
      fontWeight: 800,
    },
    [theme.breakpoints.down(540)]: {
      minHeight: "60px",
    },
  },
}))(Box);

const UPBody = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    // height: "calc(100% - 160px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px 10px 0px 0px",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    padding: "16px",
    [theme.breakpoints.down(540)]: {
      background: "transparent",
      border: "unset",
      boxShadow: "unset",
      padding: "unset",
    },
  },
}))(Box);

const UPTableHead = withStyles(() => ({
  root: {
    height: "46px",
    paddingTop: "16px",
    paddingLeft: "14px",
    paddingRight: "14px",
    "& th": {
      borderBottom: "1px solid #EAEAEA",
      "& p": {
        fontFamily: "Gothic A1 !important",
        fontStyle: "normal !important",
        fontWeight: "800 !important",
        fontSize: "18px !important",
        lineHeight: "22px !important",
        color: "#000 !important",
      },
    },
  },
}))(TableHead);

const numberFormat = (value: any) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

const useStylesPlanItem = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      position: "relative",
      [theme.breakpoints.down(540)]: {
        minWidth: "unset !important",
      },
    },
    cardType2: {
      borderRadius: "0px !important",
      "& .MuiCardContent-root": {
        padding: "0px !important",
      },
      "& .cardHeader": {
        minHeight: "100px",
        backgroundColor: theme.plans.card.header.backgroundColor,
        "& .title": {
          margin: "auto !important",
        },
      },
      "& .planAmount": {
        "& .amount": {
          color: `${theme.plans.card.highlight} !important`,
        },
      },
      "& .section": {
        borderBottom: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
        borderTop: "unset",
        padding: "23px 20px",
        margin: "0px",
      },
      "& .corporatus-bullet": {
        color: theme.plans.card.color,
      },
      [theme.breakpoints.down(540)]: {
        "& .corporatus-bullets": {
          overflowY: "scroll",
        },
        "& .section": {
          padding: "13px 10px",
        },
        "& .planAmount": {
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
        },
        "& .bottom-card": {
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    itemTitle: {
      flex: "1",
      color: "#000 !important",
      fontSize: "18px !important",
      fontStyle: "normal !important",
      fontWeight: "bold",
      // fontFamily: "Gothic A1 !important",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    hide: {
      display: "none",
    },
    headerPlan: {
      display: "flex",
    },
    editPlan: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: "#FEFEFE",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "#973B7B",
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
    },
    titlePlan: {
      display: "flex",
      height: "30px",
      minWidth: "208px",
      margin: "auto",
      borderRadius: "10px",
      marginTop: "7px",
      marginBottom: "44px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "0.15em",
        fontWeight: 800,
        textTransform: "uppercase",
        textAlign: "center",
        margin: "auto",
        padding: "7px 14px",
      },
      [theme.breakpoints.down(540)]: {
        minWidth: "unset !important",
        marginBottom: "20px",
      },
    },
    planContainer: {
      flex: "1",
      position: "relative",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "24px 24px 20px 24px !important",
      // background: theme.generalStylesContainer.layers.primary.backgroundColor,
      [theme.breakpoints.down(540)]: {
        padding: "14px !important",
      },
    },
    planImage: {
      display: "flex",
      height: "62px",
      width: "62px",
      borderRadius: "38px",
      margin: "auto",
      marginTop: "0px",
      marginBottom: "20px",
      "& svg": {
        margin: "auto",
        fontSize: "42px",
      },
      [theme.breakpoints.down(540)]: {
        height: "42px",
        width: "42px",
        "& svg": {
          fontSize: "32px",
        },
      },
    },
    planAmount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      fontSize: "35px",
      lineHeight: "44px",
      fontWeight: "bold",
      marginBottom: "17px",
      "& .amount": {
        fontSize: "35px",
        lineHeight: "44px",
        fontWeight: "bold",
        // color: "#000000",
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "25px",
        lineHeight: "31px",
        // color: "#CCCCCC",
      },
      [theme.breakpoints.down(540)]: {
        flexWrap: "wrap",
        "& .amount": {
          fontSize: "25px",
          lineHeight: "34px",
        },
        "& .currency": {
          fontZise: "20px",
          lineHeight: "26px",
        },
      },
    },
    planDescription: {
      fontWeight: "normal",
      // color: "#454545",
      textAlign: "center",
      marginBottom: "34px",
      paddingLeft: "47px",
      paddingRight: "47px",
      "& p": {
        fontSize: theme.fontGeneralStyles.medium.fontSize,
        lineHeight: theme.fontGeneralStyles.medium.lineHeight,
      },
      [theme.breakpoints.down(540)]: {
        // padding: "unset !important",
        "& p": {
          fontSize: theme.fontGeneralStyles.default.fontSize,
          lineHeight: theme.fontGeneralStyles.default.lineHeight,
        },
      },
    },
    deletePlan: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto !important",
      height: "38px",
      minWidth: "159px",
      boxSizing: "border-box",
      borderRadius: "116px",
      paddingLeft: "16px",
      paddingRight: "16px",
      textTransform: "uppercase",
      backgroundColor: theme.button.primary.backgroundColor,
      color: theme.button.primary.color,
      "&:hover": {
        backgroundColor: theme.button.primary.hoverBackground,
        color: theme.button.primary.hoverColor,
      },
      "& a": {
        color: theme.button.primary.color,
      },
      [theme.breakpoints.down(540)]: {
        width: "100%",
        padding: "13px 10px",
        border: "none",
        borderRadius: "0px",
      },
    },
    planFooter: {
      paddingBottom: "20px",
    },
    addPlanContainer: {
      padding: "17px 14px 16px 14px",
      border: "none !important",
    },
    addPlanItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      border: "2px dashed #929292",
      borderRadius: "10px",
      color: "#767676",
      cursor: "pointer",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "normal",
        color: "#767676",
      },
    },
    advantages: {
      display: "flex",
      justifyContent: "center",
      flex: "1",
      paddingLeft: "47px",
      paddingRight: "47px",
      "& svg": {
        color: "#B8CAC6",
        marginRight: "10px",
      },
      "& p": {
        fontSize: "15px",
        lineHeight: "24px",
        textAlign: "left",
      },
    },
    bestDeal: {
      position: "absolute",
      height: "38px",
      width: "calc(100% - 24px)",
      marginTop: "-29px",
      zIndex: 1,
      borderRadius: "10px 10px 0px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& p": {
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: "bolder",
        textTransform: "uppercase",
        color: "#FFF",
      },
    },
    discount: {
      height: "130px",
      width: "130px",
      right: "-35px",
      top: "-57px",
      position: "absolute",
      zIndex: 2,
    },
    offerSticker: {
      width: "100% !important",
      height: "100% !important",
    },
    couponDetail: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0px",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
      "& p": {
        fontFamily: "'Montserrat'",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFF",
      },
      "& .percent": {
        fontSize: "35px",
        lineHeight: "43px",
        fontWeight: "bold",
      },
      "& .off": {
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "bold",
      },
      "& .offText": {
        fontSize: "9px",
        lineHeight: "11px",
        fontWeight: "normal",
      },
    },
    uoTo: {
      position: "absolute",
      top: "12px",
      left: "6px",
      width: "45px",
      height: "16px",
      background: "#DAD4E8",
      boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      fontFamily: "'Montserrat'",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: "bold",
      color: "#000",
    },
  })
);

const getBtnLabel = (planBought: boolean, trialDays: number): string => {
  let btnLabel = "Buy Now";

  if (planBought) {
    btnLabel = "Cancel Subscription";
  } else if (!planBought && trialDays > 0) {
    btnLabel = `Try ${trialDays} days for free`;
  }

  return btnLabel;
};

const PlansButtonsIds = {
  "Chat Free": "audionaut-chat-free",
  "Chat Plus": "audionaut-chat-plus",
  "Marketer's Plan": "audionaut-marketers",
  "Influencer Plan": "audionaut-influencer",
};

const selectedPlansValues = {
  chatFree: "Chat Free",
  targeting: "Targeting Plan",
  influencer: "Influencer Plan",
  chatPro: "Chat Pro",
};

interface PlanItemProps {
  planData: (AudionautPlan & { isCustom: boolean }) | undefined;
  currentUserOrganisation: OrganisationData | undefined;
  planBought: boolean;
  eventsNotification: any;
  cancelSubscriptionHandler: any;
  showAddressPaymenthModal: any;
  setCurrentAddress: any;
  setCurrentPaymentMethod: any;
  setUpdateAddressStatus: any;
  setUpdatePaymentMethodStatus: any;
  setPlanToBuy: any;
  cardType: number;
}

const PlanItem: React.FC<PlanItemProps> = ({
  planData,
  currentUserOrganisation,
  planBought = false,
  eventsNotification,
  cancelSubscriptionHandler,
  showAddressPaymenthModal,
  setCurrentAddress,
  setCurrentPaymentMethod,
  setUpdateAddressStatus,
  setUpdatePaymentMethodStatus,
  setPlanToBuy,
  cardType,
}) => {
  const classes = useStylesPlanItem();

  const [openDelete, setOpenDelete] = React.useState(false);
  const [redirectToAddresses, setRedirectToAddresses] =
    React.useState<boolean>(false);
  const [redirectToPM, setRedirectToPM] = React.useState<boolean>(false);
  const [shortDesc, setShortDesc] = React.useState<boolean>(true);

  const buyButtonRef = React.useRef(null);

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  const onSubscribe: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      let defaultAddress: any = null;
      let defaultPaymentMethods: any = null;
      if (currentUserOrganisation) {
        if (
          currentUserOrganisation.billingAddresses &&
          currentUserOrganisation.billingAddresses.length > 0
        ) {
          defaultAddress = currentUserOrganisation.billingAddresses!.find(
            (address) => {
              let currentAddress: any = null;
              if (address?.isDefault) {
                currentAddress = address;
              }
              return currentAddress;
            }
          );

          const [addressData] = currentUserOrganisation.billingAddresses;
          const addressDataTemp = JSON.parse(JSON.stringify(addressData));
          if (!defaultAddress) {
            addressDataTemp!.isDefault = true;
          }

          setUpdateAddressStatus(true);
          setCurrentAddress(defaultAddress ?? addressData);
        }
        if (
          currentUserOrganisation.paymentMethods &&
          currentUserOrganisation.paymentMethods.length > 0
        ) {
          defaultPaymentMethods = currentUserOrganisation.paymentMethods.find(
            (paymentMethod) => {
              let currentPaymentMethod: any = null;
              if (paymentMethod?.isDefault) {
                currentPaymentMethod = paymentMethod;
              }
              return currentPaymentMethod;
            }
          );

          const [paymentData] = currentUserOrganisation.paymentMethods;
          const paymentMethodDataTemp = JSON.parse(JSON.stringify(paymentData));
          if (!defaultPaymentMethods) {
            paymentMethodDataTemp!.isDefault = true;
          }

          setUpdatePaymentMethodStatus(true);
          setCurrentPaymentMethod(defaultPaymentMethods ?? paymentData);
        }
      }

      setPlanToBuy(planData);
      showAddressPaymenthModal(true);
    },
    [
      currentUserOrganisation,
      planData,
      showAddressPaymenthModal,
      setCurrentAddress,
      setCurrentPaymentMethod,
      setPlanToBuy,
      setUpdateAddressStatus,
      setUpdatePaymentMethodStatus,
    ]
  );

  React.useEffect(() => {
    const selectedPlan = localStorage.getItem("selectedPlan");
    if (
      buyButtonRef &&
      buyButtonRef.current &&
      selectedPlan &&
      selectedPlansValues[selectedPlan] === planData?.name
    ) {
      const { current } = buyButtonRef as { current: any };
      current.click();
      localStorage.removeItem("selectedPlan");
    }
  }, [planData, buyButtonRef]);

  // Not delete this code, is for data not added yet
  // let extraLabelColor = "";
  // if (planData.extraLabel) {
  //   extraLabelColor = planData.color
  //     .match(/.{1,2}/g)
  //     .map((value: string) => {
  //       return (
  //         parseInt(value, 16) - Math.floor(parseInt(value, 16) * 0.1)
  //       ).toString(16);
  //     })
  //     .join("");
  // }

  return (
    <>
      {/* {planData.coupons.length > 0 ? (
        <Box className={classes.discount}>
          <OfferSticker
            className={classes.offerSticker}
            style={{ fill: `#973B7B` }}
          />
          <Typography className={classes.uoTo}>up to</Typography>
          <Box className={classes.couponDetail}>
            <Typography className="percent">
              {planData.coupons[0].percent}%
            </Typography>
            <Typography className="off">off</Typography>
            <Typography className="offText">
              valid till{" "}
              {moment(new Date(Number(planData.coupons[0].expired))).format(
                "MMMM DD"
              )}
            </Typography>
          </Box>
        </Box>
      ) : null} */}
      {/* {planData.extraLabel ? (
        <Box
          className={classes.bestDeal}
          style={{ background: `#${extraLabelColor}` }}
        >
          <Typography>{planData.extraLabel}</Typography>
        </Box>
      ) : null} */}
      <Card
        hidden={!planData?.active}
        className={`${classes.itemContainer} ${
          !planData ? classes.addPlanContainer : null
        } ${cardType === 2 ? classes.cardType2 : null}`}
      >
        {planData ? (
          <>
            <CardContent className={classes.planContainer}>
              <Box className={`cardHeader ${classes.headerPlan}`}>
                <Box className={`title ${classes.titlePlan}`}>
                  <Typography color="primary">{planData.name}</Typography>
                </Box>
              </Box>
              <Box hidden={cardType === 2} className={classes.planImage}>
                {/* <img src={planData.icon} alt="custom-icon" /> */}
                <StorefrontIcon color="secondary" />
              </Box>
              <Box className={`section planAmount ${classes.planAmount}`}>
                {planData.isCustom ? (
                  <Typography className="amount" color="primary">
                    $5,000 - 250,000
                  </Typography>
                ) : (
                  <>
                    <Typography className="amount" color="primary">
                      {numberFormat((planData.priceInCent ?? 0) / 100)}
                    </Typography>
                    <Typography className="currency" color="secondary">
                      USD/MO
                    </Typography>
                  </>
                )}
              </Box>
              <Box hidden={cardType !== 2} className="corporatus-bullets">
                <Typography component="p" className="section corporatus-bullet">
                  {planData.name === "Base license"
                    ? "Standard access to the Corporatus model"
                    : "Tailored model with domain-specific knowledge"}
                </Typography>
                <Typography component="p" className="section corporatus-bullet">
                  {planData.name === "Base license"
                    ? "Effortless navigation of scientific literature"
                    : "Inclusion of proprietary databases into the model weights"}
                </Typography>
                <Typography component="p" className="section corporatus-bullet">
                  {planData.name === "Base license"
                    ? "Basic experiment design assistance"
                    : "Comprehensive and detailed AI customization"}
                </Typography>
                <Typography component="p" className="section corporatus-bullet">
                  {planData.name === "Base license"
                    ? "Limited AI customization options"
                    : "Advanced experiment design and brainstorming capabilities"}
                </Typography>
                <Typography
                  hidden={planData.name === "Base license"}
                  component="p"
                  className="section corporatus-bullet"
                >
                  {planData.name === "Base license"
                    ? ""
                    : "Enhanced AI performance with specific and detailed domain knowledge"}
                </Typography>
              </Box>
              {!isMobileResolution() ? (
                <Typography
                  className={`section ${classes.planDescription}`}
                  color="primary"
                >
                  <Typography component="p" color="secondary">
                    {planData.description}
                  </Typography>
                </Typography>
              ) : null}
              {/* {planData.bullets.map((bullet: any) => (
                <Box className={classes.advantages}>
                  <CheckCircleOutline
                    style={{
                      color: `#973B7B`,
                    }}
                  />
                  <Typography>{bullet.text}</Typography>
                </Box>
              ))} */}
              <Box className="bottom-card" hidden={!isMobileResolution()}>
                <Typography
                  className={`section ${classes.planDescription}`}
                  component="p"
                  color="secondary"
                  onClick={() => {
                    setShortDesc(!shortDesc);
                  }}
                >
                  {shortDesc &&
                  planData.description &&
                  planData.description.length > 50
                    ? `${planData.description.slice(0, 50)} ...`
                    : planData.description}
                </Typography>
                <Button
                  hidden={planData.isCustom}
                  id={
                    planData && planData.name && !planBought
                      ? PlansButtonsIds[planData.name]
                      : "cancel-plan"
                  }
                  ref={buyButtonRef}
                  className={classes.deletePlan}
                  onClick={planBought ? handleClickToggleDelete : onSubscribe}
                  variant="outlined"
                  color="primary"
                >
                  {getBtnLabel(planBought, planData!.trialPeriodNumOfDays!)}
                </Button>
                <Box hidden={!planData.isCustom} className={classes.deletePlan}>
                  <Link href="mailto:n@datyra.com" color="primary">
                    Contact us
                  </Link>
                </Box>
              </Box>
            </CardContent>
            <CardActions
              hidden={isMobileResolution()}
              className={`section ${classes.planFooter}`}
            >
              <Button
                hidden={planData.isCustom}
                id={
                  planData && planData.name && !planBought
                    ? PlansButtonsIds[planData.name]
                    : "cancel-plan"
                }
                ref={buyButtonRef}
                className={classes.deletePlan}
                onClick={planBought ? handleClickToggleDelete : onSubscribe}
                variant="outlined"
                color="primary"
              >
                {getBtnLabel(planBought, planData!.trialPeriodNumOfDays!)}
              </Button>
              {planData.isCustom ? (
                <Link
                  hidden={!planData.isCustom}
                  href="mailto:n@datyra.com"
                  className={classes.deletePlan}
                  color="primary"
                >
                  Contact us
                </Link>
              ) : null}
            </CardActions>
            <Dialog
              id="delete-dialog"
              open={openDelete}
              onClose={() => {
                setOpenDelete(!openDelete);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Cancel Subscription
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to cance subscription {planData.name}?,
                  Action taken cannot be reversed.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenDelete(!openDelete);
                  }}
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    cancelSubscriptionHandler();
                    setOpenDelete(!openDelete);
                  }}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <CardContent className={classes.addPlanItem}>
            <AddIcon />
            <Typography>Add New Plan</Typography>
          </CardContent>
        )}
      </Card>
      {redirectToAddresses ? (
        <Redirect to="subscription/billing-address?redirectTo=/user-plans" />
      ) : null}
      {redirectToPM ? (
        <Redirect to="/subscription/payment-methods?redirectTo=/user-plans" />
      ) : null}
    </>
  );
};

const useStylesCouponItem = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      height: "332px",
      width: "332px",
      margin: "auto",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
      borderRadius: "50%",
      padding: "5px",
    },
    itemTitle: {
      flex: "1",
      color: "#000 !important",
      fontSize: "18px !important",
      fontStyle: "normal !important",
      fontWeight: "bold",
      fontFamily: "Gothic A1 !important",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    hide: {
      display: "none",
    },
    headerCoupon: {
      display: "flex",
      justifyContent: "end",
      marginBottom: "20px",
    },
    editCoupon: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: "#FEFEFE",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "#973B7B",
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
    },
    titleCoupon: {
      display: "flex",
      height: "30px",
      width: "208px",
      margin: "auto",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "10px",
      marginTop: "7px",
      marginBottom: "26px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#973B7B",
        margin: "auto",
      },
    },
    couponContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      backgroundImage: `url(${waveBorder})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      padding: "15%",
    },
    couponImage: {
      display: "flex",
      height: "62px",
      width: "62px",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "38px",
      color: "#973B7B",
      margin: "auto",
      marginBottom: "20px",
      "& svg": {
        margin: "auto",
      },
    },
    couponAmount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: "17px",
      "& .amount": {
        fontSize: "90px",
        lineHeight: "1",
        fontWeight: "bold",
        color: "#000000",
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "30px",
        lineHeight: "1",
        color: "#CCCCCC",
      },
    },
    couponDescription: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "normal",
      color: "#454545",
      textAlign: "center",
      marginBottom: "23px",
    },
    deleteCoupon: {
      height: "38px",
      width: "159px",
      border: "2px solid #973B7B",
      boxSizing: "border-box",
      borderRadius: "116px",
      color: "#973B7B",
    },
    couponFooter: {
      display: "flex",
      justifyContent: "end",
    },
    addCouponContainer: {
      background: "#F6F6F6",
      padding: "17px 14px 16px 14px",
      border: "none !important",
    },
    addCouponItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      border: "2px dashed #929292",
      borderRadius: "50%",
      color: "#767676",
      cursor: "pointer",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "normal",
        color: "#767676",
      },
    },
  })
);
interface CouponItemProps {
  couponData: any | undefined;
  editItem: any;
}

const CouponItem: React.FC<CouponItemProps> = ({ couponData, editItem }) => {
  const classes = useStylesCouponItem();

  return (
    <Card
      className={clsx(classes.itemContainer, {
        [classes.addCouponContainer]: !couponData,
      })}
    >
      {couponData ? (
        <CardContent className={classes.couponContainer}>
          <Box className={classes.headerCoupon}>
            <Button
              className={classes.editCoupon}
              variant="outlined"
              startIcon={<EditOutlinedIcon />}
              onClick={editItem}
            />
          </Box>
          <Box className={classes.couponAmount}>
            <Typography className="amount">30%</Typography>
            <Typography className="currency">OFF</Typography>
          </Box>
          <Typography className={classes.couponDescription}>
            + 500 free minutes
          </Typography>
          {/* <Box className={classes.couponFooter}>
            <Button className={classes.deleteCoupon} variant="outlined">
              Remove Coupon
            </Button>
          </Box> */}
        </CardContent>
      ) : (
        <CardContent className={classes.addCouponItem} onClick={editItem}>
          <AddIcon />
          <Typography>Add New Coupon</Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default {
  useStyles,
  UPContainer,
  UPContent,
  UPHeader,
  UPBody,
  UPTableHead,
  PlanItem,
  CouponItem,
};
