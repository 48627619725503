import React from "react";

import { Box } from "@material-ui/core";

import { init, getInstanceByDom } from "echarts";

import SC from "./charts.styles";

// Start EChart Component

interface AudionautChartsProps {
  type: number;
  data?: any;
  title?: string;
  customStyle?: any;
  customClass?: string;
}
const AudionautCharts: React.FC<AudionautChartsProps> = ({
  type,
  data,
  title,
  customStyle,
  customClass = "",
}) => {
  const classes = SC.useChartStyles();

  const chartRef = React.useRef<HTMLDivElement>(null);

  if (!customStyle) {
    // eslint-disable-next-line no-param-reassign
    customStyle = {
      height: "250px",
      width: "100%",
    };
  }

  React.useEffect(() => {
    const currentOptions = SC.chartOptions[type];

    if (type === 1) {
      currentOptions.yAxis.data = data.labels;
      currentOptions.series[0].data = data.values;
    } else if (type === 3) {
      currentOptions.series[0].data[0].value = data.score;
      currentOptions.series[0].data[0].name = data.isPositive
        ? "Positive"
        : "Negative";
      currentOptions.series[0].data[0].detail.color = data.isPositive
        ? "#5AE391"
        : "#FE6377";
      currentOptions.series[0].data[0].title.color = data.isPositive
        ? "#5AE391"
        : "#FE6377";
      currentOptions.series[0].progress.itemStyle.color.colorStops[0].color =
        data.isPositive ? "#5AE391" : "#FE6377";
      currentOptions.series[0].progress.itemStyle.color.colorStops[1].color =
        data.isPositive ? "#5AE391" : "#FE6377";
    } else if (type === 5) {
      currentOptions.series[0].data[0].name = "Confidence";
      currentOptions.series[0].data[0].value = data.score;
    }

    if (chartRef.current) {
      init(chartRef.current);
      const chart = getInstanceByDom(chartRef.current);
      chart!.setOption(currentOptions, {});
    }
  }, [type, data]);

  return (
    <Box
      component="div"
      ref={chartRef}
      style={customStyle}
      className={classes.customClass}
    />
  );
};
// End EChart Component

export default AudionautCharts;
