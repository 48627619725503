// Dependencies
import {
  withStyles,
  Avatar,
  Box,
  Fab as FabMui,
  Tooltip,
  InputBase,
  InputLabel,
  Button,
  Theme,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

const ProfileContainer = withStyles((theme: Theme) => ({
  root: {
    position: "relative",
    width: "100px",
    height: "100px",
    marginRight: "16px",
    [theme.breakpoints.down(540)]: {
      width: "50px !important",
      height: "50px !important",
      marginTop: "auto",
      marginBottom: "auto",
      "& svg": {
        width: "100% !important",
        height: "100% !important",
      },
    },
  },
}))(Box);

const Fab = withStyles({
  root: {
    height: "24px",
    width: "24px",
    minHeight: "24px",
    position: "absolute",
    zIndex: 1,
    bottom: "-5px",
    right: "-5px",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    "& svg": {
      height: "12px",
    },
  },
})(FabMui) as typeof FabMui;

const InputBasePicture = withStyles({
  root: {
    display: "none",
  },
})(InputBase);

const LabelPicture = InputLabel;

const UserAvatar = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "100px",
  },
}))(Avatar);

const DefaultAvatarIcon = withStyles((theme) => ({
  root: {
    width: "100px",
    height: "100px",
  },
}))(AccountCircleIcon);

export default {
  ProfileContainer,
  Tooltip,
  Fab,
  CreateOutlinedIcon,
  InputBasePicture,
  LabelPicture,
  Button,
  UserAvatar,
  DefaultAvatarIcon,
};
