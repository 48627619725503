import { gql } from "@apollo/client";

export const corporatusQuery = gql`
  mutation CorporatusSearchRequest($searchBody: SearchQueryModel!) {
    searchQuery(input: $searchBody)
      @rest(
        type: "SearchQuery"
        endpoint: "corporatus"
        path: "/corporatus"
        method: "POST"
        bodyKey: "input"
      ) {
      event_id
      data
      sources
      is_last_event
    }
  }
`;

export const getPapersList = gql`
  query CorporatusPapersList($query: string!, $total: number) {
    papersList(query: $query, total: $total)
      @rest(
        type: "PapersList"
        endpoint: "corporatus"
        path: "/model/files?query=:query&k=:total"
        method: "GET"
      ) {
      title
      authors
      full_keywords
      page
      bucket_path
      pdf_url
    }
  }
`;

export const getPaperImages = gql`
  mutation CorporatusExtractPDFImages($filePath: string!) {
    paperImages(fileType: $filePath, input: {})
      @rest(
        type: "PaperImages"
        endpoint: "corporatus"
        path: "/captions/process_pdf_minio?minio_object_name=:fileType"
        method: "POST"
        bodyKey: "input"
      ) {
      pages
    }
  }
`;

export const getPaperImagesByPages = gql`
  mutation CorporatusExtractPDFImages($filePath: string!, $pages: string!) {
    paperImages(fileType: $filePath, pages: $pages, input: {})
      @rest(
        type: "PaperImages"
        endpoint: "corporatus"
        path: "/captions/process_pdf_minio_v2?minio_object_name=:fileType&pages_doc=:pages"
        method: "POST"
        bodyKey: "input"
      ) {
      message {
        pages
      }
    }
  }
`;

export const downloadImage = gql`
  mutation CorporatusDownloadImage($imagePath: string!) {
    imageBlob(imagePath: $imagePath, input: {})
      @rest(
        type: "ImageBlob"
        endpoint: "corporatus"
        path: "/captions/get_image?minio_object_name=:imagePath"
        method: "GET"
        bodyKey: "input"
      ) {
      image
    }
  }
`;

export const getImageCaption = gql`
  mutation CorporatusGetImageCaption($imagePath: string!) {
    imageCaption(imagePath: $imagePath, input: {})
      @rest(
        type: "ImageCaption"
        endpoint: "corporatus"
        path: "/openclip/generate_caption_minio/?url_minio=:imagePath"
        method: "POST"
        bodyKey: "input"
      ) {
      caption
    }
  }
`;

export const saveChatMessage = gql`
  mutation SaveChatMessage($message: ChatHistoricItem!) {
    chatMessage(input: $message)
      @rest(
        type: "ChatMessage"
        endpoint: "corporatus"
        path: "/model/chat_history"
        method: "POST"
        bodyKey: "input"
      ) {
      rid
    }
  }
`;

export const getChatHistoric = gql`
  query CorporatusChatHistoric($imagePath: string!) {
    chatHistoric(userEmail: $userEmail)
      @rest(
        type: "ChatHistoric"
        endpoint: "corporatus"
        path: "/model/chat_history?email=:userEmail"
        method: "GET"
      ) {
      rid: string
      email: String
      session_id: String
      created_at: String
      query: String
      response: String
      resource {
        source: String
        page: String
        abstract: String
        ai_keywords: String
        paper_doi: String
        pdf_url: String
        publisher: String
        webpage_url: String
        title: String
        bucket_path: String
        created: String
        full_citation: String
        year: String
        keywords: String
        authors: String
        full_keywords: String
      }
    }
  }
`;

export const updateItemHistoric = gql`
  mutation CorporatusUpdateHistoric($message: ChatHistoricItem!) {
    updateItem(input: $message)
      @rest(
        type: "UpdateItem"
        endpoint: "corporatus"
        path: "/model/chat_history"
        method: "PUT"
        bodyKey: "input"
      )
  }
`;

export const deleteHistoricBySessionId = gql`
  mutation CorporatusDeleteMessages($sessionId: string!) {
    deleteMessages(sessionId: $sessionId)
      @rest(
        type: "DeleteMessages"
        endpoint: "corporatus"
        path: "/model/chat_history?session_id=:sessionId"
        method: "DELETE"
      )
  }
`;
