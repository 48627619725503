import { withStyles, Typography, Theme, Box } from "@material-ui/core";

import {
  Form,
  EmailField,
  EmailInputAdornment,
  PersonOutlineOutlinedIcon,
  Button as ButtonBase,
  NoRegisteredUserInfo,
  Link,
} from "components/signin-form/signin-form.styles";

export const Text = withStyles(() => ({
  root: {
    // color: "#757575",
    fontSize: "16px",
    // lineHeight: "22px",
    // paddingTop: "29px",
    paddingTop: "10px",
    // paddingBottom: "68px",
    paddingBottom: "40px",
    fontWeight: 500,
  },
}))(Typography);

export const Button = withStyles({
  root: {
    textTransform: "none",
  },
})(ButtonBase);

const DividerMobile = withStyles((theme: Theme) => ({
  root: {
    display: "none",
    [theme.breakpoints.down(540)]: {
      display: "block",
      flex: "1",
    },
  },
}))(Box);

export default {
  Form,
  Text,
  EmailField,
  EmailInputAdornment,
  PersonOutlineOutlinedIcon,
  Button,
  NoRegisteredUserInfo,
  Link,
  DividerMobile,
};
