import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
  withStyles,
} from "@material-ui/core";
import ButtonPrimary from "components/button-primary/button-primary.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

const FiguresContainer = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    transition: "visibility 0s linear 0.33s",
    "&.show-figures": {
      display: "block",
      width: "30%",
      borderLeft: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
      paddingLeft: "20px",
      marginLeft: "20px",
      overflowY: "auto",
    },
  },
}))(Box);

const FiguresContent = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    [theme.breakpoints.down(540)]: {
      height: "calc(100% - 60px)",
      top: "60px",
    },
  },
}))(Box);

const ModalTitle = withStyles((theme: Theme) => ({
  root: {
    "& h2": {
      display: "flex",
      "& p": {
        display: "flex",
        alignItems: "center",
        flex: "1",
      },
    },
  },
}))(DialogTitle);

const ModalAction = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(DialogActions);

const DiscussButton = withStyles((theme: Theme) => ({
  root: {
    width: "auto",
    paddingLeft: "30px",
    paddingRight: "30px",
    "& svg": {
      marginRight: "8px",
    },
  },
}))(ButtonPrimary);

const FiguresDialog = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
}))(Dialog);

const NoResults = withStyles(() => ({
  root: {
    boxShadow: "unset",
  },
}))(GenericEmptyMessages);

export default {
  FiguresContainer,
  FiguresContent,
  ModalTitle,
  ModalAction,
  DiscussButton,
  FiguresDialog,
  NoResults,
};
