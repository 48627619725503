// Dependencies
import { withStyles, Button as ButtonBase } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";

const Button = withStyles((theme) => ({
  root: {
    height: "1.8rem",
    color: theme.table.toolbarActions.iconColor,
    borderColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      borderColor: theme.table.toolbarActions.iconColor,
    },
  },
  contained: {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(ButtonBase);

const ButtonContainer = withStyles((theme) => ({
  root: {
    justifyContent: "flex-end",
    marginRight: theme.spacing(2),
  },
}))(MuiDialogActions);

export default { Button, ButtonContainer };
