// Dependencies
import React from "react";
import { TextFieldProps as Props } from "@material-ui/core/TextField";

// Assets
import SC from "./text-field.styles";

export type TextFieldProps = Props;

export const TextField: React.FC<TextFieldProps> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SC.TextField {...props} />
);

export default TextField;
