import { Container, Content, Logo, Title } from "pages/signin/signin.styles";
import Form from "./forgot-password-form";
import CheckEmail from "./check-email.component";

export default {
  Container,
  Content,
  Logo,
  Title,
  Form,
  CheckEmail,
};
