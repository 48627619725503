/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  Button,
  Theme,
  Card,
  CardContent,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { AlertProps } from "components/alert/alert.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import { createStyles, makeStyles } from "@material-ui/styles";

import { PaymentMethod } from "graphql/types-and-hooks";

import MasterCardBrand from "assets/icons/mastercard.png";
import VisaBrand from "assets/icons/visa.png";
// Utils
import { useAppErrorHandler } from "errors/app.errors";

import SC from "components/payment-billing-address-item/payment-address-item.styles";

const useStylesPaymentMethodItem = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      height: "100%",
      margin: "auto",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
      padding: "11px 11px 6px 11px",
      borderRadius: "10px",
      border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
      boxSizing: "border-box",
    },
    itemTitle: {
      flex: "1",
      color: "#000 !important",
      fontSize: "18px !important",
      fontStyle: "normal !important",
      fontWeight: "bold",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    hide: {
      display: "none",
    },
    headerPayment: {
      display: "flex",
      justifyContent: "end",
      marginBottom: "20px",
    },
    editPayment: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: "#FEFEFE",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "#973B7B",
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
    },
    titlePayment: {
      display: "flex",
      height: "30px",
      width: "208px",
      margin: "auto",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "10px",
      marginTop: "7px",
      marginBottom: "26px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#973B7B",
        margin: "auto",
      },
    },
    paymentContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    paymentContent: {
      display: "flex",
      height: "100%",
      width: "100%",
      marginBottom: "10px",
    },
    paymentImage: {
      display: "flex",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "10px",
      color: "#973B7B",
      margin: "auto",
      marginBottom: "20px",
      "& svg": {
        margin: "auto",
      },
    },
    paymentAmount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: "17px",
      "& .amount": {
        fontSize: "90px",
        lineHeight: "1",
        fontWeight: "bold",
        color: "#000000",
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "30px",
        lineHeight: "1",
        color: "#CCCCCC",
      },
    },
    paymentDescription: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "normal",
      color: "#454545",
      textAlign: "center",
      marginBottom: "23px",
    },
    deletePayment: {
      height: "38px",
      width: "159px",
      border: "2px solid #973B7B",
      boxSizing: "border-box",
      borderRadius: "116px",
      color: "#973B7B",
    },
    paymentFooter: {
      display: "flex",
      justifyContent: "end",
    },
    cardPreview: {
      display: "flex",
      flexDirection: "column",
      height: "90px",
      width: "150px",
      padding: "11px 16px",
      background: "linear-gradient(109.58deg, #B8CAC6 0%, #65DFBD 100%)",
      borderRadius: "5px",
      marginRight: "12px",
      marginTop: "6px",
    },
    cardTitle: {
      display: "flex",
      justifyContent: "space-between",
      "& p": {
        fontFamily: "'Sarabun'",
        fontSize: "10px",
        lineHeight: "21px",
        fontWeight: "normal",
        letterSpacing: "0.1em",
        color: "#FFF",
        textAlign: "right",
      },
    },
    cardNumber: {
      display: "flex",
      flex: "1",
      alignItems: "end",
      fontFamily: "'Sarabun'",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "21px",
      justifyContent: "left",
      letterSpacing: "3px",
      color: "#FFFFFF",
    },
    cardDetail: {
      flex: 1,
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: "normal",
      textAlign: "left",
      "& .title": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "bold",
        marginBottom: "12px",
      },
    },
    defaultLbl: {
      display: "flex",
      height: "27px",
      width: "104px",
      background: `${theme.generalStylesContainer.layers.secondary.backgroundColor}A3`,
      borderRadius: "116px",
      margin: "auto",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        margin: "auto",
      },
    },
    menuBtn: {
      height: "26px",
      width: "26px",
      minWidth: "unset",
    },
  })
);

interface PaymentMethodItemProps {
  paymentMethod: PaymentMethod | undefined;
  setAsDefault: any;
  deletItem: any;
}
const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({
  paymentMethod,
  setAsDefault,
  deletItem,
}) => {
  const classes = useStylesPaymentMethodItem();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [openMenu, setOpenMenu] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleOpenMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  return (
    <Card className={classes.itemContainer}>
      <CardContent className={classes.paymentContainer}>
        <Box className={classes.paymentContent}>
          <Box className={classes.cardPreview}>
            <Box className={classes.cardTitle}>
              {paymentMethod!.cardBrand === "visa" ||
              paymentMethod!.cardBrand === "Visa" ? (
                <img
                  src={VisaBrand}
                  alt="brand-icon"
                  style={{ width: "auto", height: "17px" }}
                />
              ) : null}
              {paymentMethod!.cardBrand === "mastercard" ? (
                <img
                  src={MasterCardBrand}
                  alt="brand-icon"
                  style={{ width: "auto", height: "17px" }}
                />
              ) : null}
              <Typography color="primary">Credit</Typography>
            </Box>
            <Typography className={classes.cardNumber}>
              {paymentMethod?.cardLast4}
            </Typography>
          </Box>
          <Box className={classes.cardDetail}>
            <Typography className="title" color="primary">
              Debit Card
            </Typography>
            <Typography color="secondary">
              {paymentMethod?.cardBrand}:{" "}
              <b>**** **** **** {paymentMethod?.cardLast4}</b>
            </Typography>
            <Typography color="secondary">
              Expires Date:{" "}
              <b>
                {paymentMethod?.cardExpMonth}/
                {paymentMethod?.cardExpYear?.toString().substring(2, 4)}
              </b>
            </Typography>
          </Box>
          <div>
            <Button
              ref={anchorRef}
              aria-controls={openMenu ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              className={classes.menuBtn}
              onClick={handleOpenMenu}
            >
              <MoreVertIcon color="primary" />
            </Button>
            <Popper
              open={openMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                      <MenuList
                        autoFocusItem={openMenu}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={() => {
                            setAsDefault(paymentMethod);
                            setOpenMenu(false);
                          }}
                        >
                          Set as Default
                        </MenuItem>
                        <MenuItem onClick={() => setOpenDelete(true)}>
                          Delete Card
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Box>
        {paymentMethod?.isDefault ?? false ? (
          <Box className={classes.defaultLbl}>
            <Typography>Default</Typography>
          </Box>
        ) : null}
      </CardContent>

      <Dialog
        id="delete-dialog"
        open={openDelete}
        onClose={() => {
          setOpenDelete(!openDelete);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="primary">
          Delete Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Are you sure you want to delete this card? Action taken cannot be
            reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SC.ButtonSecondary
            onClick={() => {
              setOpenDelete(!openDelete);
            }}
            color="primary"
          >
            Cancel
          </SC.ButtonSecondary>
          <SC.ButtonPrimary
            onClick={() => {
              deletItem(paymentMethod);
              setOpenDelete(!openDelete);
              setOpenMenu(false);
            }}
            color="primary"
            autoFocus
          >
            Delete
          </SC.ButtonPrimary>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
export default PaymentMethodItem;
