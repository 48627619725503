/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { FETCH_API_SCHEMA } = ActionTypes;

const INITIAL_STATE = {
  jsonSchema: {},
  selectedOption: null,
  isReceivingJsonSchema: false,
  error: null,
};


const fetchApiSchemaReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_API_SCHEMA.START:
      return {
        ...state,
        isReceivingJsonSchema: true,
        error: null
      };

    case FETCH_API_SCHEMA.SUCCESS: {
      return {
        ...state,
        jsonSchema: action.payload,
        selectedOption: action.selectedOption,
        isReceivingJsonSchema: false,
      };
    }

    case FETCH_API_SCHEMA.FAILURE:
      return {
        ...state,
        isReceivingJsonSchema: false,
        error: action.payload,
      };

    default: return state;
  }
};

export default fetchApiSchemaReducerFn;
