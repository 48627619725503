import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: "Query";
  getMediaId?: Maybe<Array<Maybe<GetMediasIdsResponse>>>;
  getSourcesInfo?: Maybe<Array<Maybe<Source>>>;
  getVideos?: Maybe<Array<Maybe<Video>>>;
  getMediasFromMaster?: Maybe<Array<Maybe<Video>>>;
  getPlaylists?: Maybe<Array<Maybe<Playlist>>>;
  getMediasOfPlaylist?: Maybe<Array<Maybe<Video>>>;
  getChannels?: Maybe<Array<Maybe<Channel>>>;
  getPlaylistsOfChannel?: Maybe<Array<Maybe<Playlist>>>;
  getMediasOfChannel?: Maybe<Array<Maybe<Video>>>;
  getHashtags?: Maybe<Array<Maybe<Hashtag>>>;
  getMediasOfHashtag?: Maybe<Array<Maybe<HashtagVideo>>>;
  getLatestMedias?: Maybe<Array<Maybe<Video>>>;
  getAddedChannels?: Maybe<Array<Maybe<Channel>>>;
};

export type QueryGetMediaIdArgs = {
  ids: Array<Maybe<Scalars["String"]>>;
};

export type QueryGetSourcesInfoArgs = {
  sources?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryGetVideosArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  sources?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id?: Maybe<Scalars["String"]>;
};

export type QueryGetMediasFromMasterArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  sources?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id?: Maybe<Scalars["String"]>;
};

export type QueryGetPlaylistsArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  source?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type QueryGetMediasOfPlaylistArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  playlistId: Scalars["String"];
  mediaId?: Maybe<Scalars["String"]>;
};

export type QueryGetChannelsArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  source?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type QueryGetPlaylistsOfChannelArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  channelId: Scalars["String"];
  playlistId?: Maybe<Scalars["String"]>;
};

export type QueryGetMediasOfChannelArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  channelId: Scalars["String"];
  mediaId?: Maybe<Scalars["String"]>;
};

export type QueryGetHashtagsArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  source?: Maybe<Scalars["String"]>;
};

export type QueryGetMediasOfHashtagArgs = {
  hashtagId?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type QueryGetLatestMediasArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
};

export type QueryGetAddedChannelsArgs = {
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
};

export type GetMediasIdsResponse = Media | MediaIdNotExistError;

export type Media = {
  __typename?: "Media";
  /** database uuid */
  uuid?: Maybe<Scalars["String"]>;
  /** Identidier in the plaform */
  mediaId?: Maybe<Scalars["String"]>;
  /** URL */
  webpageUrl?: Maybe<Scalars["String"]>;
};

export type MediaIdNotExistError = {
  __typename?: "MediaIdNotExistError";
  /** Message error */
  message?: Maybe<Scalars["String"]>;
};

/** A platform in Audionaut */
export type Source = {
  __typename?: "Source";
  /** The name of the platform. */
  sourceName?: Maybe<Scalars["String"]>;
  /** Total time of procecesed audio. */
  proccesedAudio?: Maybe<Scalars["String"]>;
  /** Number of channels processed */
  channelsCount?: Maybe<Scalars["Int"]>;
  /** Number of playlists processed */
  playlistsCount?: Maybe<Scalars["Int"]>;
  /** Number of hashtags processed */
  hashtagsCount?: Maybe<Scalars["Int"]>;
  /** Number of videos processed */
  mediasCount?: Maybe<Scalars["Int"]>;
};

/** A video in Youtube, Podcast or TikTok platform */
export type Video = {
  __typename?: "Video";
  /** OrientDB unique identifier. */
  id?: Maybe<Scalars["String"]>;
  /** Unique identifier of the video in his platform */
  mediaId?: Maybe<Scalars["String"]>;
  /** The name of the media */
  videoName?: Maybe<Scalars["String"]>;
  /** Author of the media */
  author?: Maybe<Scalars["String"]>;
  /** Web page url */
  url?: Maybe<Scalars["String"]>;
  /** The platform name for the url */
  source?: Maybe<Scalars["String"]>;
  /** CHANNEL, PLAYLIST or MEDIA */
  category?: Maybe<Scalars["String"]>;
  /** Duration of media in seconds */
  duration?: Maybe<Scalars["String"]>;
  /** Number of views */
  views?: Maybe<Scalars["Int"]>;
  /** hashtags */
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The current proccesing status. */
  status?: Maybe<Scalars["String"]>;
  /** Creation datetime */
  dateCreation?: Maybe<Scalars["String"]>;
};

/** A playlist in Youtube, Podcast or TikTok platform */
export type Playlist = {
  __typename?: "Playlist";
  /** OrientDB unique identifier. */
  id?: Maybe<Scalars["String"]>;
  /** Unique identifier of the playlist in his platform */
  playlistId?: Maybe<Scalars["String"]>;
  /** The name of this playlist. */
  name?: Maybe<Scalars["String"]>;
  /** Web page url for this playlist. */
  url?: Maybe<Scalars["String"]>;
  /** The platform name for the url */
  source?: Maybe<Scalars["String"]>;
  /** CHANNEL, PLAYLIST or MEDIA */
  category?: Maybe<Scalars["String"]>;
  /** List of urls */
  mediasUrls?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Number of videos on playlist. */
  mediasCount?: Maybe<Scalars["Int"]>;
  /** Number of views for this playlist */
  views?: Maybe<Scalars["Int"]>;
  /** Current status of analysis  */
  status?: Maybe<Scalars["String"]>;
  /** Upload datetime. */
  uploaded?: Maybe<Scalars["String"]>;
  /** Creation datetime. */
  created?: Maybe<Scalars["String"]>;
  /** Last modified datetime. */
  updated?: Maybe<Scalars["String"]>;
};

/** A channel in Youtube, Podcast or TikTok platform */
export type Channel = {
  __typename?: "Channel";
  /** OrientDB unique identifier. */
  id?: Maybe<Scalars["String"]>;
  /** Unique identifier of the channel in his platform */
  channelId?: Maybe<Scalars["String"]>;
  /** Name of this */
  name?: Maybe<Scalars["String"]>;
  /** Owner of the channel */
  author?: Maybe<Scalars["String"]>;
  /** Page URL for this channel */
  url?: Maybe<Scalars["String"]>;
  /** The platform name for the url */
  source?: Maybe<Scalars["String"]>;
  /** CHANNEL, PLAYLIST or MEDIA */
  category?: Maybe<Scalars["String"]>;
  /** Number of videos for this channel */
  totalMedias?: Maybe<Scalars["Int"]>;
  /** Number of subscribers for this channel */
  subscribers?: Maybe<Scalars["Int"]>;
  /** Current status of analysis  */
  status?: Maybe<Scalars["String"]>;
  /** Creation datetime. */
  created?: Maybe<Scalars["String"]>;
  /** Last modified datetime. */
  updated?: Maybe<Scalars["String"]>;
};

/** A hashtag of TikTok platform */
export type Hashtag = {
  __typename?: "Hashtag";
  /** OrientDB unique identifier. */
  id?: Maybe<Scalars["String"]>;
  /** The name of the hashtag. */
  name?: Maybe<Scalars["String"]>;
  /** The id of hashtag */
  hashtagId?: Maybe<Scalars["String"]>;
  /** Source of hashtag */
  source?: Maybe<Scalars["String"]>;
  /** category of hashtag */
  category?: Maybe<Scalars["String"]>;
  /** Number of videos related with a hashtag. */
  mediasCount?: Maybe<Scalars["Int"]>;
  /** Current status of analysis  */
  status?: Maybe<Scalars["String"]>;
  /** Creation datetime. */
  created?: Maybe<Scalars["String"]>;
  /** Last modified datetime. */
  updated?: Maybe<Scalars["String"]>;
};

export type HashtagVideo = {
  __typename?: "HashtagVideo";
  /** The name of the media */
  hashtagName?: Maybe<Scalars["String"]>;
  /** OrientDB unique identifier. */
  mediaId?: Maybe<Scalars["String"]>;
  /** Author of the media */
  author?: Maybe<Scalars["String"]>;
  /** Web page url */
  url?: Maybe<Scalars["String"]>;
  /** The platform name for the url */
  source?: Maybe<Scalars["String"]>;
  /** CHANNEL, PLAYLIST or MEDIA */
  category?: Maybe<Scalars["String"]>;
  /** Duration of media in seconds */
  duration?: Maybe<Scalars["String"]>;
  /** Number of views */
  views?: Maybe<Scalars["Int"]>;
  /** hashtags */
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The current proccesing status. */
  status?: Maybe<Scalars["String"]>;
  /** Creation datetime */
  creation?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  addNewUrlToProcess?: Maybe<AddNewUrlToProcess>;
  deleteUrlPermanently?: Maybe<DeleteUrlPermanently>;
  scheduleNewAnalysis?: Maybe<ScheduleNewAnalysis>;
};

export type MutationAddNewUrlToProcessArgs = {
  urls: Array<Maybe<Scalars["String"]>>;
};

export type MutationDeleteUrlPermanentlyArgs = {
  recordId: Scalars["String"];
};

export type MutationScheduleNewAnalysisArgs = {
  dateTime: Scalars["String"];
  recordId: Scalars["String"];
};

export type AddNewUrlToProcess = {
  __typename?: "AddNewUrlToProcess";
  data?: Maybe<Array<Maybe<NewUrlToProcess>>>;
};

/** TaskScheduler class in OrientDB database */
export type NewUrlToProcess = {
  __typename?: "NewUrlToProcess";
  /** Flag to indicate if the URL is added to Audionaut */
  error?: Maybe<Scalars["Boolean"]>;
  /** Flag to indicate if the URL already exist  with DONE status */
  urlAlreadyExists?: Maybe<Scalars["Boolean"]>;
  /** TaskScheduler unique identifier */
  recordId?: Maybe<Scalars["String"]>;
  /** The platform name for the url */
  source?: Maybe<Scalars["String"]>;
  /** CHANNEL, PLAYLIST, MEDIA or HASHTAG */
  category?: Maybe<Scalars["String"]>;
  /** The time and date of the calendar for a new analysis */
  schedule?: Maybe<Scalars["String"]>;
  /** Data obtained for the URL */
  metadata?: Maybe<Scalars["String"]>;
  /** Creation date of this task */
  created?: Maybe<Scalars["String"]>;
};

export type DeleteUrlPermanently = {
  __typename?: "DeleteUrlPermanently";
  /** Flag indicating if the url was successfully removed */
  success?: Maybe<Scalars["Boolean"]>;
  /** Help message to understand the answer */
  message?: Maybe<Scalars["String"]>;
};

export type ScheduleNewAnalysis = {
  __typename?: "ScheduleNewAnalysis";
  data?: Maybe<NewUrlToProcess>;
};

export type SourceFragment = { __typename?: "Source" } & Pick<
  Source,
  | "sourceName"
  | "proccesedAudio"
  | "channelsCount"
  | "playlistsCount"
  | "mediasCount"
  | "hashtagsCount"
>;

export type MediaFragment = { __typename?: "Video" } & Pick<
  Video,
  | "id"
  | "mediaId"
  | "videoName"
  | "author"
  | "url"
  | "source"
  | "category"
  | "duration"
  | "views"
  | "tags"
  | "status"
  | "dateCreation"
>;

export type PlaylistFragment = { __typename?: "Playlist" } & Pick<
  Playlist,
  | "id"
  | "playlistId"
  | "name"
  | "url"
  | "source"
  | "category"
  | "mediasUrls"
  | "mediasCount"
  | "views"
  | "status"
  | "uploaded"
  | "created"
  | "updated"
>;

export type ChannelFragment = { __typename?: "Channel" } & Pick<
  Channel,
  | "id"
  | "channelId"
  | "name"
  | "author"
  | "url"
  | "source"
  | "category"
  | "totalMedias"
  | "subscribers"
  | "status"
  | "created"
  | "updated"
>;

export type HashtagFragment = { __typename?: "Hashtag" } & Pick<
  Hashtag,
  | "id"
  | "name"
  | "hashtagId"
  | "source"
  | "category"
  | "mediasCount"
  | "status"
  | "created"
  | "updated"
>;

export type HashtagMediaFragment = { __typename?: "HashtagVideo" } & Pick<
  HashtagVideo,
  | "hashtagName"
  | "mediaId"
  | "author"
  | "url"
  | "source"
  | "category"
  | "duration"
  | "views"
  | "tags"
  | "status"
  | "creation"
>;

export type NewUrlToProcessFragment = { __typename?: "NewUrlToProcess" } & Pick<
  NewUrlToProcess,
  | "error"
  | "urlAlreadyExists"
  | "recordId"
  | "source"
  | "category"
  | "schedule"
  | "metadata"
  | "created"
>;

export type UrlToProcessFragment = { __typename?: "AddNewUrlToProcess" } & {
  data?: Maybe<
    Array<Maybe<{ __typename?: "NewUrlToProcess" } & NewUrlToProcessFragment>>
  >;
};

export type ScheduleFragment = { __typename?: "ScheduleNewAnalysis" } & {
  data?: Maybe<{ __typename?: "NewUrlToProcess" } & NewUrlToProcessFragment>;
};

export type GetSourcesQueryVariables = Exact<{
  sources?: Maybe<Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>>;
}>;

export type GetSourcesQuery = { __typename?: "Query" } & {
  getSourcesInfo?: Maybe<
    Array<Maybe<{ __typename?: "Source" } & SourceFragment>>
  >;
};

export type GetVideosQueryVariables = Exact<{
  source?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetVideosQuery = { __typename?: "Query" } & {
  getVideos?: Maybe<Array<Maybe<{ __typename?: "Video" } & MediaFragment>>>;
};

export type GetVideosFromMasterQueryVariables = Exact<{
  source?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetVideosFromMasterQuery = { __typename?: "Query" } & {
  getMediasFromMaster?: Maybe<
    Array<Maybe<{ __typename?: "Video" } & MediaFragment>>
  >;
};

export type GetVideosFromMasterByIdQueryVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetVideosFromMasterByIdQuery = { __typename?: "Query" } & {
  getMediasFromMaster?: Maybe<
    Array<Maybe<{ __typename?: "Video" } & MediaFragment>>
  >;
};

export type GetPlaylistsQueryVariables = Exact<{
  source?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetPlaylistsQuery = { __typename?: "Query" } & {
  getPlaylists?: Maybe<
    Array<Maybe<{ __typename?: "Playlist" } & PlaylistFragment>>
  >;
};

export type GetPlaylistVideosQueryVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
  mediaId?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetPlaylistVideosQuery = { __typename?: "Query" } & {
  getMediasOfPlaylist?: Maybe<
    Array<Maybe<{ __typename?: "Video" } & MediaFragment>>
  >;
};

export type GetChannelsQueryVariables = Exact<{
  source?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetChannelsQuery = { __typename?: "Query" } & {
  getChannels?: Maybe<
    Array<Maybe<{ __typename?: "Channel" } & ChannelFragment>>
  >;
};

export type GetChannelPlaylistsQueryVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
  playListId?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetChannelPlaylistsQuery = { __typename?: "Query" } & {
  getPlaylistsOfChannel?: Maybe<
    Array<Maybe<{ __typename?: "Playlist" } & PlaylistFragment>>
  >;
};

export type GetChannelVideosQueryVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
  mediaId?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetChannelVideosQuery = { __typename?: "Query" } & {
  getMediasOfChannel?: Maybe<
    Array<Maybe<{ __typename?: "Video" } & MediaFragment>>
  >;
};

export type GetHashTagsInfoQueryVariables = Exact<{
  source?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetHashTagsInfoQuery = { __typename?: "Query" } & {
  getHashtags?: Maybe<
    Array<Maybe<{ __typename?: "Hashtag" } & HashtagFragment>>
  >;
};

export type GetHashtagMediaByIdQueryVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
  index?: Maybe<Scalars["Int"]>;
}>;

export type GetHashtagMediaByIdQuery = { __typename?: "Query" } & {
  getMediasOfHashtag?: Maybe<
    Array<Maybe<{ __typename?: "HashtagVideo" } & HashtagMediaFragment>>
  >;
};

export type GetVideosIdsByMediaIdQueryVariables = Exact<{
  ids: Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>;
}>;

export type GetVideosIdsByMediaIdQuery = { __typename?: "Query" } & {
  getMediaId?: Maybe<
    Array<
      Maybe<
        | ({ __typename: "Media" } & Pick<
            Media,
            "uuid" | "mediaId" | "webpageUrl"
          >)
        | ({ __typename: "MediaIdNotExistError" } & Pick<
            MediaIdNotExistError,
            "message"
          >)
      >
    >
  >;
};

export type AddMediaSourceMutationVariables = Exact<{
  urls: Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>;
}>;

export type AddMediaSourceMutation = { __typename?: "Mutation" } & {
  addNewUrlToProcess?: Maybe<
    { __typename?: "AddNewUrlToProcess" } & UrlToProcessFragment
  >;
};

export type RemoveUrlScheduleMutationVariables = Exact<{
  recordId: Scalars["String"];
}>;

export type RemoveUrlScheduleMutation = { __typename?: "Mutation" } & {
  deleteUrlPermanently?: Maybe<
    { __typename?: "DeleteUrlPermanently" } & Pick<
      DeleteUrlPermanently,
      "success" | "message"
    >
  >;
};

export const SourceFragmentDoc = gql`
  fragment Source on Source {
    sourceName
    proccesedAudio
    channelsCount
    playlistsCount
    mediasCount
    hashtagsCount
  }
`;
export const MediaFragmentDoc = gql`
  fragment Media on Video {
    id
    mediaId
    videoName
    author
    url
    source
    category
    duration
    views
    tags
    status
    dateCreation
  }
`;
export const PlaylistFragmentDoc = gql`
  fragment Playlist on Playlist {
    id
    playlistId
    name
    url
    source
    category
    mediasUrls
    mediasCount
    views
    status
    uploaded
    created
    updated
  }
`;
export const ChannelFragmentDoc = gql`
  fragment Channel on Channel {
    id
    channelId
    name
    author
    url
    source
    category
    totalMedias
    subscribers
    status
    created
    updated
  }
`;
export const HashtagFragmentDoc = gql`
  fragment Hashtag on Hashtag {
    id
    name
    hashtagId
    source
    category
    mediasCount
    status
    created
    updated
  }
`;
export const HashtagMediaFragmentDoc = gql`
  fragment HashtagMedia on HashtagVideo {
    hashtagName
    mediaId
    author
    url
    source
    category
    duration
    views
    tags
    status
    creation
  }
`;
export const NewUrlToProcessFragmentDoc = gql`
  fragment NewURLToProcess on NewUrlToProcess {
    error
    urlAlreadyExists
    recordId
    source
    category
    schedule
    metadata
    created
  }
`;
export const UrlToProcessFragmentDoc = gql`
  fragment UrlToProcess on AddNewUrlToProcess {
    data {
      ...NewURLToProcess
    }
  }
  ${NewUrlToProcessFragmentDoc}
`;
export const ScheduleFragmentDoc = gql`
  fragment Schedule on ScheduleNewAnalysis {
    data {
      ...NewURLToProcess
    }
  }
  ${NewUrlToProcessFragmentDoc}
`;
export const GetSourcesDocument = gql`
  query getSources($sources: [String] = []) {
    getSourcesInfo(sources: $sources) {
      ...Source
    }
  }
  ${SourceFragmentDoc}
`;

/**
 * __useGetSourcesQuery__
 *
 * To run a query within a React component, call `useGetSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourcesQuery({
 *   variables: {
 *      sources: // value for 'sources'
 *   },
 * });
 */
export function useGetSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSourcesQuery,
    GetSourcesQueryVariables
  >
) {
  return Apollo.useQuery<GetSourcesQuery, GetSourcesQueryVariables>(
    GetSourcesDocument,
    baseOptions
  );
}
export function useGetSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSourcesQuery,
    GetSourcesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetSourcesQuery, GetSourcesQueryVariables>(
    GetSourcesDocument,
    baseOptions
  );
}
export type GetSourcesQueryHookResult = ReturnType<typeof useGetSourcesQuery>;
export type GetSourcesLazyQueryHookResult = ReturnType<
  typeof useGetSourcesLazyQuery
>;
export type GetSourcesQueryResult = Apollo.QueryResult<
  GetSourcesQuery,
  GetSourcesQueryVariables
>;
export const GetVideosDocument = gql`
  query getVideos(
    $source: String = ""
    $id: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getVideos(sources: [$source], id: $id, first: $total, offset: $index) {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *      source: // value for 'source'
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables>
) {
  return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(
    GetVideosDocument,
    baseOptions
  );
}
export function useGetVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideosQuery,
    GetVideosQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(
    GetVideosDocument,
    baseOptions
  );
}
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<
  typeof useGetVideosLazyQuery
>;
export type GetVideosQueryResult = Apollo.QueryResult<
  GetVideosQuery,
  GetVideosQueryVariables
>;
export const GetVideosFromMasterDocument = gql`
  query getVideosFromMaster(
    $source: String = ""
    $id: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getMediasFromMaster(
      sources: [$source]
      id: $id
      first: $total
      offset: $index
    ) {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useGetVideosFromMasterQuery__
 *
 * To run a query within a React component, call `useGetVideosFromMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosFromMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosFromMasterQuery({
 *   variables: {
 *      source: // value for 'source'
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetVideosFromMasterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVideosFromMasterQuery,
    GetVideosFromMasterQueryVariables
  >
) {
  return Apollo.useQuery<
    GetVideosFromMasterQuery,
    GetVideosFromMasterQueryVariables
  >(GetVideosFromMasterDocument, baseOptions);
}
export function useGetVideosFromMasterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideosFromMasterQuery,
    GetVideosFromMasterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetVideosFromMasterQuery,
    GetVideosFromMasterQueryVariables
  >(GetVideosFromMasterDocument, baseOptions);
}
export type GetVideosFromMasterQueryHookResult = ReturnType<
  typeof useGetVideosFromMasterQuery
>;
export type GetVideosFromMasterLazyQueryHookResult = ReturnType<
  typeof useGetVideosFromMasterLazyQuery
>;
export type GetVideosFromMasterQueryResult = Apollo.QueryResult<
  GetVideosFromMasterQuery,
  GetVideosFromMasterQueryVariables
>;
export const GetVideosFromMasterByIdDocument = gql`
  query getVideosFromMasterById(
    $id: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getMediasFromMaster(id: $id, first: $total, offset: $index) {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useGetVideosFromMasterByIdQuery__
 *
 * To run a query within a React component, call `useGetVideosFromMasterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosFromMasterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosFromMasterByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetVideosFromMasterByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVideosFromMasterByIdQuery,
    GetVideosFromMasterByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetVideosFromMasterByIdQuery,
    GetVideosFromMasterByIdQueryVariables
  >(GetVideosFromMasterByIdDocument, baseOptions);
}
export function useGetVideosFromMasterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideosFromMasterByIdQuery,
    GetVideosFromMasterByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetVideosFromMasterByIdQuery,
    GetVideosFromMasterByIdQueryVariables
  >(GetVideosFromMasterByIdDocument, baseOptions);
}
export type GetVideosFromMasterByIdQueryHookResult = ReturnType<
  typeof useGetVideosFromMasterByIdQuery
>;
export type GetVideosFromMasterByIdLazyQueryHookResult = ReturnType<
  typeof useGetVideosFromMasterByIdLazyQuery
>;
export type GetVideosFromMasterByIdQueryResult = Apollo.QueryResult<
  GetVideosFromMasterByIdQuery,
  GetVideosFromMasterByIdQueryVariables
>;
export const GetPlaylistsDocument = gql`
  query getPlaylists(
    $source: String = ""
    $id: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getPlaylists(source: $source, id: $id, first: $total, offset: $index) {
      ...Playlist
    }
  }
  ${PlaylistFragmentDoc}
`;

/**
 * __useGetPlaylistsQuery__
 *
 * To run a query within a React component, call `useGetPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistsQuery({
 *   variables: {
 *      source: // value for 'source'
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetPlaylistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlaylistsQuery,
    GetPlaylistsQueryVariables
  >
) {
  return Apollo.useQuery<GetPlaylistsQuery, GetPlaylistsQueryVariables>(
    GetPlaylistsDocument,
    baseOptions
  );
}
export function useGetPlaylistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlaylistsQuery,
    GetPlaylistsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPlaylistsQuery, GetPlaylistsQueryVariables>(
    GetPlaylistsDocument,
    baseOptions
  );
}
export type GetPlaylistsQueryHookResult = ReturnType<
  typeof useGetPlaylistsQuery
>;
export type GetPlaylistsLazyQueryHookResult = ReturnType<
  typeof useGetPlaylistsLazyQuery
>;
export type GetPlaylistsQueryResult = Apollo.QueryResult<
  GetPlaylistsQuery,
  GetPlaylistsQueryVariables
>;
export const GetPlaylistVideosDocument = gql`
  query getPlaylistVideos(
    $id: String = ""
    $mediaId: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getMediasOfPlaylist(
      playlistId: $id
      mediaId: $mediaId
      first: $total
      offset: $index
    ) {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useGetPlaylistVideosQuery__
 *
 * To run a query within a React component, call `useGetPlaylistVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistVideosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      mediaId: // value for 'mediaId'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetPlaylistVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlaylistVideosQuery,
    GetPlaylistVideosQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPlaylistVideosQuery,
    GetPlaylistVideosQueryVariables
  >(GetPlaylistVideosDocument, baseOptions);
}
export function useGetPlaylistVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlaylistVideosQuery,
    GetPlaylistVideosQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPlaylistVideosQuery,
    GetPlaylistVideosQueryVariables
  >(GetPlaylistVideosDocument, baseOptions);
}
export type GetPlaylistVideosQueryHookResult = ReturnType<
  typeof useGetPlaylistVideosQuery
>;
export type GetPlaylistVideosLazyQueryHookResult = ReturnType<
  typeof useGetPlaylistVideosLazyQuery
>;
export type GetPlaylistVideosQueryResult = Apollo.QueryResult<
  GetPlaylistVideosQuery,
  GetPlaylistVideosQueryVariables
>;
export const GetChannelsDocument = gql`
  query getChannels(
    $source: String = ""
    $id: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getChannels(source: $source, id: $id, first: $total, offset: $index) {
      ...Channel
    }
  }
  ${ChannelFragmentDoc}
`;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *      source: // value for 'source'
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChannelsQuery,
    GetChannelsQueryVariables
  >
) {
  return Apollo.useQuery<GetChannelsQuery, GetChannelsQueryVariables>(
    GetChannelsDocument,
    baseOptions
  );
}
export function useGetChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelsQuery,
    GetChannelsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetChannelsQuery, GetChannelsQueryVariables>(
    GetChannelsDocument,
    baseOptions
  );
}
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<
  typeof useGetChannelsLazyQuery
>;
export type GetChannelsQueryResult = Apollo.QueryResult<
  GetChannelsQuery,
  GetChannelsQueryVariables
>;
export const GetChannelPlaylistsDocument = gql`
  query getChannelPlaylists(
    $id: String = ""
    $playListId: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getPlaylistsOfChannel(
      channelId: $id
      playlistId: $playListId
      first: $total
      offset: $index
    ) {
      ...Playlist
    }
  }
  ${PlaylistFragmentDoc}
`;

/**
 * __useGetChannelPlaylistsQuery__
 *
 * To run a query within a React component, call `useGetChannelPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelPlaylistsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      playListId: // value for 'playListId'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetChannelPlaylistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChannelPlaylistsQuery,
    GetChannelPlaylistsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetChannelPlaylistsQuery,
    GetChannelPlaylistsQueryVariables
  >(GetChannelPlaylistsDocument, baseOptions);
}
export function useGetChannelPlaylistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelPlaylistsQuery,
    GetChannelPlaylistsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetChannelPlaylistsQuery,
    GetChannelPlaylistsQueryVariables
  >(GetChannelPlaylistsDocument, baseOptions);
}
export type GetChannelPlaylistsQueryHookResult = ReturnType<
  typeof useGetChannelPlaylistsQuery
>;
export type GetChannelPlaylistsLazyQueryHookResult = ReturnType<
  typeof useGetChannelPlaylistsLazyQuery
>;
export type GetChannelPlaylistsQueryResult = Apollo.QueryResult<
  GetChannelPlaylistsQuery,
  GetChannelPlaylistsQueryVariables
>;
export const GetChannelVideosDocument = gql`
  query getChannelVideos(
    $id: String = ""
    $mediaId: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getMediasOfChannel(
      channelId: $id
      mediaId: $mediaId
      first: $total
      offset: $index
    ) {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useGetChannelVideosQuery__
 *
 * To run a query within a React component, call `useGetChannelVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelVideosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      mediaId: // value for 'mediaId'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetChannelVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChannelVideosQuery,
    GetChannelVideosQueryVariables
  >
) {
  return Apollo.useQuery<GetChannelVideosQuery, GetChannelVideosQueryVariables>(
    GetChannelVideosDocument,
    baseOptions
  );
}
export function useGetChannelVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelVideosQuery,
    GetChannelVideosQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetChannelVideosQuery,
    GetChannelVideosQueryVariables
  >(GetChannelVideosDocument, baseOptions);
}
export type GetChannelVideosQueryHookResult = ReturnType<
  typeof useGetChannelVideosQuery
>;
export type GetChannelVideosLazyQueryHookResult = ReturnType<
  typeof useGetChannelVideosLazyQuery
>;
export type GetChannelVideosQueryResult = Apollo.QueryResult<
  GetChannelVideosQuery,
  GetChannelVideosQueryVariables
>;
export const GetHashTagsInfoDocument = gql`
  query getHashTagsInfo(
    $source: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getHashtags(source: $source, first: $total, offset: $index) {
      ...Hashtag
    }
  }
  ${HashtagFragmentDoc}
`;

/**
 * __useGetHashTagsInfoQuery__
 *
 * To run a query within a React component, call `useGetHashTagsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHashTagsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHashTagsInfoQuery({
 *   variables: {
 *      source: // value for 'source'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetHashTagsInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHashTagsInfoQuery,
    GetHashTagsInfoQueryVariables
  >
) {
  return Apollo.useQuery<GetHashTagsInfoQuery, GetHashTagsInfoQueryVariables>(
    GetHashTagsInfoDocument,
    baseOptions
  );
}
export function useGetHashTagsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHashTagsInfoQuery,
    GetHashTagsInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetHashTagsInfoQuery,
    GetHashTagsInfoQueryVariables
  >(GetHashTagsInfoDocument, baseOptions);
}
export type GetHashTagsInfoQueryHookResult = ReturnType<
  typeof useGetHashTagsInfoQuery
>;
export type GetHashTagsInfoLazyQueryHookResult = ReturnType<
  typeof useGetHashTagsInfoLazyQuery
>;
export type GetHashTagsInfoQueryResult = Apollo.QueryResult<
  GetHashTagsInfoQuery,
  GetHashTagsInfoQueryVariables
>;
export const GetHashtagMediaByIdDocument = gql`
  query getHashtagMediaById(
    $id: String = ""
    $total: Int = 10
    $index: Int = 0
  ) {
    getMediasOfHashtag(hashtagId: $id, first: $total, offset: $index) {
      ...HashtagMedia
    }
  }
  ${HashtagMediaFragmentDoc}
`;

/**
 * __useGetHashtagMediaByIdQuery__
 *
 * To run a query within a React component, call `useGetHashtagMediaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHashtagMediaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHashtagMediaByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetHashtagMediaByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHashtagMediaByIdQuery,
    GetHashtagMediaByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetHashtagMediaByIdQuery,
    GetHashtagMediaByIdQueryVariables
  >(GetHashtagMediaByIdDocument, baseOptions);
}
export function useGetHashtagMediaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHashtagMediaByIdQuery,
    GetHashtagMediaByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetHashtagMediaByIdQuery,
    GetHashtagMediaByIdQueryVariables
  >(GetHashtagMediaByIdDocument, baseOptions);
}
export type GetHashtagMediaByIdQueryHookResult = ReturnType<
  typeof useGetHashtagMediaByIdQuery
>;
export type GetHashtagMediaByIdLazyQueryHookResult = ReturnType<
  typeof useGetHashtagMediaByIdLazyQuery
>;
export type GetHashtagMediaByIdQueryResult = Apollo.QueryResult<
  GetHashtagMediaByIdQuery,
  GetHashtagMediaByIdQueryVariables
>;
export const GetVideosIdsByMediaIdDocument = gql`
  query getVideosIdsByMediaId($ids: [String]!) {
    getMediaId(ids: $ids) {
      __typename
      ... on Media {
        uuid
        mediaId
        webpageUrl
      }
      ... on MediaIdNotExistError {
        message
      }
    }
  }
`;

/**
 * __useGetVideosIdsByMediaIdQuery__
 *
 * To run a query within a React component, call `useGetVideosIdsByMediaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosIdsByMediaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosIdsByMediaIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetVideosIdsByMediaIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVideosIdsByMediaIdQuery,
    GetVideosIdsByMediaIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetVideosIdsByMediaIdQuery,
    GetVideosIdsByMediaIdQueryVariables
  >(GetVideosIdsByMediaIdDocument, baseOptions);
}
export function useGetVideosIdsByMediaIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideosIdsByMediaIdQuery,
    GetVideosIdsByMediaIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetVideosIdsByMediaIdQuery,
    GetVideosIdsByMediaIdQueryVariables
  >(GetVideosIdsByMediaIdDocument, baseOptions);
}
export type GetVideosIdsByMediaIdQueryHookResult = ReturnType<
  typeof useGetVideosIdsByMediaIdQuery
>;
export type GetVideosIdsByMediaIdLazyQueryHookResult = ReturnType<
  typeof useGetVideosIdsByMediaIdLazyQuery
>;
export type GetVideosIdsByMediaIdQueryResult = Apollo.QueryResult<
  GetVideosIdsByMediaIdQuery,
  GetVideosIdsByMediaIdQueryVariables
>;
export const AddMediaSourceDocument = gql`
  mutation addMediaSource($urls: [String]!) {
    addNewUrlToProcess(urls: $urls) {
      ...UrlToProcess
    }
  }
  ${UrlToProcessFragmentDoc}
`;
export type AddMediaSourceMutationFn = Apollo.MutationFunction<
  AddMediaSourceMutation,
  AddMediaSourceMutationVariables
>;

/**
 * __useAddMediaSourceMutation__
 *
 * To run a mutation, you first call `useAddMediaSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaSourceMutation, { data, loading, error }] = useAddMediaSourceMutation({
 *   variables: {
 *      urls: // value for 'urls'
 *   },
 * });
 */
export function useAddMediaSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMediaSourceMutation,
    AddMediaSourceMutationVariables
  >
) {
  return Apollo.useMutation<
    AddMediaSourceMutation,
    AddMediaSourceMutationVariables
  >(AddMediaSourceDocument, baseOptions);
}
export type AddMediaSourceMutationHookResult = ReturnType<
  typeof useAddMediaSourceMutation
>;
export type AddMediaSourceMutationResult =
  Apollo.MutationResult<AddMediaSourceMutation>;
export type AddMediaSourceMutationOptions = Apollo.BaseMutationOptions<
  AddMediaSourceMutation,
  AddMediaSourceMutationVariables
>;
export const RemoveUrlScheduleDocument = gql`
  mutation removeURLSchedule($recordId: String!) {
    deleteUrlPermanently(recordId: $recordId) {
      success
      message
    }
  }
`;
export type RemoveUrlScheduleMutationFn = Apollo.MutationFunction<
  RemoveUrlScheduleMutation,
  RemoveUrlScheduleMutationVariables
>;

/**
 * __useRemoveUrlScheduleMutation__
 *
 * To run a mutation, you first call `useRemoveUrlScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUrlScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUrlScheduleMutation, { data, loading, error }] = useRemoveUrlScheduleMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useRemoveUrlScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUrlScheduleMutation,
    RemoveUrlScheduleMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveUrlScheduleMutation,
    RemoveUrlScheduleMutationVariables
  >(RemoveUrlScheduleDocument, baseOptions);
}
export type RemoveUrlScheduleMutationHookResult = ReturnType<
  typeof useRemoveUrlScheduleMutation
>;
export type RemoveUrlScheduleMutationResult =
  Apollo.MutationResult<RemoveUrlScheduleMutation>;
export type RemoveUrlScheduleMutationOptions = Apollo.BaseMutationOptions<
  RemoveUrlScheduleMutation,
  RemoveUrlScheduleMutationVariables
>;
