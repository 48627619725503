import React from "react";

import SC from "./toggle-drawer-icon.styles";

interface ToggleDrawerIconInterface {
  opened: boolean;
  openHandler: () => void;
  closeHandler: () => void;
  isMobile: boolean;
}

const ToggleDrawerIcon: React.FC<ToggleDrawerIconInterface> = ({
  opened,
  openHandler,
  closeHandler,
  isMobile = false,
}) => {
  return (
    <>
      {isMobile ? (
        <SC.MobileToggleIcon
          color="inherit"
          aria-label="open drawer"
          onClick={!opened ? openHandler : closeHandler}
          edge="start"
        >
          {opened ? <SC.IconClosed /> : <SC.IconOpened className="open-icon" />}
        </SC.MobileToggleIcon>
      ) : (
        <SC.ToggleIcon
          color="inherit"
          aria-label="open drawer"
          onClick={!opened ? openHandler : closeHandler}
          edge="start"
        >
          {opened ? <SC.IconClosed /> : <SC.IconOpened />}
        </SC.ToggleIcon>
      )}
    </>
  );
};

export default ToggleDrawerIcon;
