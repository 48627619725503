import React from "react";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import ButtonSecondary from "components/button-secondary/button-secondary.component";
import ButtonPrimary from "components/button-primary/button-primary.component";

import SC from "./copy-icon-button.styles";

// Start EChart Component

interface CopyButtonProps {
  handleOnClick: (
    arg0: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  disabled?: boolean;
}

const CopyIconButton: React.FC<CopyButtonProps> = ({
  handleOnClick,
  disabled = false,
}) => {
  //   const classes = SC.useChartStyles();
  const [deleting, setDeleting] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    if (handleOnClick) handleOnClick(e);
  };

  return (
    <>
      <SC.CopyButton disabled={disabled} onClick={handleClick}>
        {/* <CircularProgress hidden={deleting} style={{ position: "absolute" }} /> */}
        <FileCopyIcon />
      </SC.CopyButton>
      {/* <Dialog
        id={`dialog-${itemId}`}
        open={openDelete}
        onClose={handleClickToggleDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete target</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want delete &quot;{itemName}
            &quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary onClick={handleClickToggleDelete}>
            Cancel
          </ButtonSecondary>
          <ButtonPrimary
            onClick={(event) => {
              if (itemDeleted !== null) setDeleting(itemDeleted);
              if (type) {
                deleteItem(itemId, type);
              } else {
                deleteItem(data);
              }
              handleClickToggleDelete();
            }}
            autoFocus
          >
            Delete
          </ButtonPrimary>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default CopyIconButton;
