import { Box, Card, Theme, Typography, withStyles } from "@material-ui/core";

const CardContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
    background: "transparent !important",
    padding: "unset !important",
    border: "unset !important",
    boxShadow: "unset !important",
    fontSize: theme.fontGeneralStyles.small.fontSize,
    lineHeight: theme.fontGeneralStyles.small.lineHeight,
    color: theme.generalStylesContainer.referrals.color.main,
    fontWeight: 500,
    rowGap: "34px",
    "& .title": {
      fontSize: theme.fontGeneralStyles.medium1.fontSize,
      lineHeight: theme.fontGeneralStyles.medium1.lineHeight,
      color: theme.generalStylesContainer.referrals.color.main,
      fontWeight: 800,
      marginBottom: "8px",
    },
    "& img": {
      maxHeight: "35%",
      maxWidth: "50%",
      margin: "auto",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.fontGeneralStyles.default.fontSize,
      lineHeight: theme.fontGeneralStyles.default.lineHeight,
      rowGap: "24px",
      "& .title": {
        fontSize: theme.fontGeneralStyles.small.fontSize,
        lineHeight: theme.fontGeneralStyles.small.lineHeight,
      },
      "& img": {
        maxWidth: "40%",
      },
    },
  },
}))(Card);

const Title = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.fontGeneralStyles.medium1.fontSize,
    lineHeight: theme.fontGeneralStyles.medium1.lineHeight,
    color: theme.generalStylesContainer.referrals.color.main,
    fontWeight: 800,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
    },
  },
}))(Typography);

const Description = withStyles((theme: Theme) => ({
  root: {
    color: theme.generalStylesContainer.referrals.color.main,
    "& ol": {
      marginTop: "1rem",
    },
  },
}))(Box);

const FooterContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.generalStylesContainer.referrals.color.main,
    "& button": {
      width: "unset",
      padding: "11px 26px",
    },
  },
}))(Box);

export default {
  CardContainer,
  Description,
  Title,
  FooterContainer,
};
