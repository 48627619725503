/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  TabProps,
  Drawer,
  SwipeableDrawer,
  List,
  ListItem,
  Theme,
} from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";
import ToggleDrawerIcon from "components/toggle-drawer-icon/toggle-drawer-icon.component";

const drawerWidth = 264;

const TabStyles = makeStyles((theme) => ({
  tab: {
    alignSelf: "flex-end",
    padding: "0",
    margin: "0",
    minHeight: "0",
    textTransform: "none",
    opacity: "1",
    fontWeight: theme.navigation.fontWeight,
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.navigationSmall.paddingTop,
      paddingRight: theme.navigationSmall.paddingRight,
      paddingBottom: theme.navigationSmall.paddingBottom,
      paddingLeft: theme.navigationSmall.paddingLeft,
      borderTopRightRadius: theme.navigationSmall.borderTopRightRadius,
      borderTopLeftRadius: theme.navigationSmall.borderTopLeftRadius,
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.navigation.paddingTop,
      paddingRight: theme.navigation.paddingRight,
      paddingBottom: theme.navigation.paddingBottom,
      paddingLeft: theme.navigation.paddingLeft,
      borderTopRightRadius: theme.navigation.borderTopRightRadius,
      borderTopLeftRadius: theme.navigation.borderTopLeftRadius,
    },
    minWidth: "auto",
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      "& > .MuiSvgIcon-root": {
        marginBottom: "0px",
        [theme.breakpoints.down("xl")]: {
          fontSize: theme.navigationIconsSmall.fontSize,
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: theme.navigationIcons.fontSize,
        },
      },
      "& span": {
        minWidth: "fit-content",
        [theme.breakpoints.down("xl")]: {
          fontSize: theme.navigationSmall.fontSize,
          lineHeight: theme.navigationSmall.lineHeight,
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: theme.navigation.fontSize,
          lineHeight: theme.navigation.lineHeight,
        },
      },
    },
    "&:hover": {
      background: theme.navigation.background,
    },
  },
  active: {
    background: theme.navigationTabActive.background,
    color: theme.navigationTabActive.color,
    opacity: "1",
    "&:hover": {
      background: theme.navigationTabActive.background,
    },
  },
}));

const Navigation = withStyles({
  root: {
    alignSelf: "flex-end",
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "fit-content",
    "& div": {
      minHeight: "auto",
    },
  },
})(AppBar);

const TabsOptions = withStyles({
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);

const LinkTab: React.ComponentType<TabProps & LinkProps> =
  Tab as React.ComponentType<TabProps & LinkProps>;

type LinkTabProps = TabProps &
  LinkProps & {
    isActive: boolean;
  };

const Option: React.FC<LinkTabProps> = (props) => {
  const { isActive, to, ...muiProps } = props;
  const classes = TabStyles();
  return (
    <LinkTab
      className={isActive ? `${classes.tab} ${classes.active}` : classes.tab}
      component={Link}
      to={to}
      {...muiProps}
    />
  );
};

const LateralMenu = withStyles(() => ({
  root: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    "& ul": {
      overflowY: "auto !important",
      overflowX: "hidden",
      flex: "1",
      "& .MuiListItem-root": {
        height: "80px",
      },
      "& svg": {
        fill: "#CCCCCC",
      },
      "& span": {
        color: "#CCCCCC",
      },
    },
  },
}))(Drawer);

const MobileLateralMenu = withStyles((theme: Theme) => ({
  root: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    "& ul": {
      overflowY: "auto !important",
      overflowX: "hidden",
      flex: "1",
      "& .MuiListItem-root": {
        height: "80px",
      },
      "& svg": {
        fill: theme.generalStylesContainer.drawer.color,
      },
      "& span": {
        color: theme.generalStylesContainer.drawer.color,
      },
    },
    "& .MuiDrawer-paper": {
      width: "inherit",
    },
  },
}))(SwipeableDrawer);

const MobileToggleDrawerIcon = withStyles(() => ({
  root: {
    position: "absolute",
    width: "50px",
    height: "60px",
    top: "0px",
    background: "transparent",
  },
}))(ToggleDrawerIcon);

const NavigationList = withStyles((theme: Theme) => ({
  root: {
    background: theme.generalStylesContainer.drawer.backgroundColor,
    "& .MuiListItem-root:hover": {
      "& .MuiTypography-root": {
        color: theme.generalStylesContainer.color,
      },
      "& .MuiListItemIcon-root": {
        "& svg": {
          fill: theme.generalStylesContainer.color,
        },
        "& svg path": {
          fill: theme.generalStylesContainer.color,
        },
      },
    },
    "& .subItem": {
      paddingLeft: "82px !important",
      height: "40px !important",
    },
    "& .active": {
      borderLeft: `solid 4px ${theme.generalStylesContainer.drawer.active.borderColor}`,
      backgroundColor:
        theme.generalStylesContainer.drawer.active.backgroundColor,
      paddingLeft: "12px",
      "& svg": {
        fill: `${theme.generalStylesContainer.drawer.active.color} !important`,
        "& path": {
          fill: `${theme.generalStylesContainer.drawer.active.color} !important`,
        },
      },
      "& span": {
        color: `${theme.generalStylesContainer.drawer.active.color} !important`,
      },
    },
  },
}))(List);

export default {
  Navigation,
  TabsOptions,
  TabStyles,
  Option,
  LateralMenu,
  MobileLateralMenu,
  MobileToggleDrawerIcon,
  NavigationList,
};
