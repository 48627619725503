import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import TagManager from "react-gtm-module";

import Table, { TableColumn } from "components/table/table.component";
import { Alert, AlertProps } from "components/alert/alert.component";
import ButtonSecondary from "components/button-secondary/button-secondary.component";
import ButtonPrimary from "components/button-primary/button-primary.component";
import BilingModal from "pages/user-plans/components/billing-modal/billing-modal.component";

import SearchIcon from "@material-ui/icons/Search";

import {
  ReferredLink,
  useGetAllReferralLinksQuery,
  useSendReferralInviteLazyQuery,
  useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
  AudionautSubscriptionFragmentFragment,
  AudionautPlanFragmentFragment,
  useCreateRewardCouponMutation,
  useUpdateSubscriptionWithCouponMutation,
  AudionautCouponFragmentFragment,
  useGetReferralLevelQuery,
  useUpdateReferredStatusMutation,
  useUpdateReferralLevelMutation,
  useGetReferredLinksByUserIdQuery,
  useGetAudionautPlansQuery,
  useCreatePlanSubscriptionMutation,
  useCreatePlanSubscriptionWithCouponMutation,
  useGetCurrentUserOrganisationQuery,
  OrganisationData,
  BillingAddress,
  PaymentMethod,
  GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
  AudionautCoupon,
} from "graphql/types-and-hooks";
import { isServerError } from "errors/server.errors";
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";

import { useUserDataStore } from "commons/utils/user-data";
import { getFormattedDate } from "commons/utils/date-format";
import {
  createEmptyBillingAddress,
  createEmptyPaymentMethod,
} from "graphql/audionaut.utils";

import SC, { ResumeInfoInterface } from "./referral-system.styles";

const { REACT_APP_TRIAL_PLAN_ID } = process.env;

const referralsList = [
  {
    name: "James Mwangi",
    email: "jamesm@gmail.com",
    date: "17/04/2024",
    status: "COMPLETE",
    discount: "15",
  },
  {
    name: "James Mwangi",
    email: "jamesm@gmail.com",
    date: "17/04/2024",
    status: "IN PROGRESS",
    discount: "0",
  },
  {
    name: "James Mwangi",
    email: "jamesm@gmail.com",
    date: "17/04/2024",
    status: "IN PROGRESS",
    discount: "0",
  },
];

const ReferralSystemPage: React.FC = () => {
  const userData = useUserDataStore((state: any) => state.userData);
  const userDataLocal = React.useMemo(() => userData, [userData]);
  const [snackBarMessage, setSnackBarMessage] =
    React.useState<AlertProps & { delay?: number }>();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalDescription, setModalDescription] = React.useState("");
  const [inviteEmail, setInviteEmail] = React.useState<string | null>(null);
  const [loadingInvite, setLoadingInvite] = React.useState(false);
  const [loadingRedeem, setLoadingRedeem] = React.useState(false);

  const [currentPlan, setCurrentPlan] =
    React.useState<AudionautSubscriptionFragmentFragment | null>(null);
  const [currentUserSubscription, setCurrentUserSubscription] =
    React.useState<GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery | null>(
      null
    );
  const [referralList, setReferralList] = React.useState<ReferredLink[]>([]);
  const [referralListBkp, setReferralListBkp] = React.useState<ReferredLink[]>(
    []
  );
  const [resumeInfo, setResumeInfo] = React.useState<ResumeInfoInterface>({
    completeRewards: 0,
    pendingRewards: 0,
    signedInRewards: 0,
    signedUpRewards: 0,
    percentageApplied: 0,
    percentageAvailable: 0,
  });

  const [openBillingModal, setOpenBillingModal] = React.useState(false);
  const [currentAddress, setCurrentAddress] = React.useState(() =>
    createEmptyBillingAddress()
  );
  const [currentPaymentMethod, setCurrentPaymentMethod] =
    React.useState<PaymentMethod>(createEmptyPaymentMethod());
  const [currentCouponCreated, setCurrentCouponCreated] =
    React.useState<AudionautCoupon | null>(null);

  const columns: TableColumn<ReferredLink>[] = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "referred",
        align: "left",
        width: 200,
        Cell: ({ row, value }) => (
          <Box
            display="flex"
            gridGap="3px"
            title={value && value.firstName ? value.firstName : ""}
          >
            {value ? value.firstName : "-"}
          </Box>
        ),
      },
      {
        Header: "Email",
        accessor: "emailSentTo",
        align: "left",
        width: 200,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        align: "left",
        width: 200,
        Cell: ({ row, value }) => (
          <Box
            display="flex"
            gridGap="3px"
            title={getFormattedDate(
              (value ?? "").replaceAll("\\", ""),
              "DD/MM/YYYY"
            )}
          >
            {getFormattedDate((value ?? "").replaceAll("\\", ""), "DD/MM/YYYY")}
          </Box>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box display="flex" gridGap="3px" title={value ?? "pending"}>
            {value
              ? value.split(",").map((keyword) => {
                  // const randomNum = Math.floor(Math.random() * 4);
                  // const currentColor = SC.ColorsChips[randomNum];
                  return keyword ? (
                    <SC.CustomChip
                      label={keyword.toUpperCase()}
                      className={value === "Complete" ? "complete" : "pending"}
                    />
                  ) : null;
                })
              : ""}
          </Box>
        ),
      },
      {
        Header: "Discount",
        accessor: "referredBy",
        align: "left",
        width: 310,
        Cell: ({ row, value }) => (
          <Box
            display="flex"
            gridGap="3px"
            title={
              value && value.referralLevel ? value.referralLevel.toString() : ""
            }
          >
            {value ? value.referralLevel : "-"}%
          </Box>
        ),
      },
    ],
    []
  );

  const {
    data: currentSubscriptionData,
    loading: currentSubscriptionLoading,
    error: currentSubscriptionError,
    refetch: currentSubscriptionRefetch,
  } = useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery();

  const {
    data: referralLinksData,
    loading: referralLinksLoading,
    error: referralLinksError,
    refetch: referralLinksRefetch,
  } = useGetReferredLinksByUserIdQuery({
    fetchPolicy: "no-cache",
    variables: {
      userId: userDataLocal.id,
    },
  });

  const [
    sendReferralInvite,
    {
      data: sendReferralInviteData,
      loading: sendReferralInviteLoading,
      error: sendReferralInviteError,
    },
  ] = useSendReferralInviteLazyQuery();

  const [
    createRedeemCoupon,
    {
      data: createdCouponData,
      loading: createdCouponLoading,
      error: createdCouponError,
    },
  ] = useCreateRewardCouponMutation();

  const [
    updatePlanWithCoupon,
    {
      data: updatePlanWithCouponData,
      loading: updatePlanWithCouponLoading,
      error: updatePlanWithCouponError,
    },
  ] = useUpdateSubscriptionWithCouponMutation();

  const {
    data: plans,
    loading: getAudionautPlansLoading,
    error: getAudionautPlansError,
  } = useGetAudionautPlansQuery({
    fetchPolicy: "no-cache",
  });

  const [
    createPlanSubscription,
    {
      data: subscriptionData,
      loading: subscriptionLoading,
      error: subscriptionError,
    },
  ] = useCreatePlanSubscriptionMutation();

  const [
    createPlanSubscriptionWithCoupon,
    {
      data: subsCouponData,
      loading: subsCouponLoading,
      error: subsCouponError,
    },
  ] = useCreatePlanSubscriptionWithCouponMutation();

  const {
    data: currentUserOrganisation,
    loading: getCurrentUserOrganisationLoading,
    error: getCurrentUserOrganisationError,
  } = useGetCurrentUserOrganisationQuery({
    fetchPolicy: "no-cache",
  });

  const errorHandler = useAppErrorHandler(
    currentSubscriptionError ||
      referralLinksError ||
      createdCouponError ||
      updatePlanWithCouponError ||
      getAudionautPlansError ||
      subscriptionError ||
      subsCouponError ||
      getCurrentUserOrganisationError
  );

  const chatProPlanData = React.useMemo(() => {
    return plans && plans.getAudionautPlans
      ? plans.getAudionautPlans.find(
          (plan) => plan?.id === REACT_APP_TRIAL_PLAN_ID
        )
      : null;
  }, [plans]);

  const userOrganization: OrganisationData | null = React.useMemo(
    () => currentUserOrganisation?.getCurrentUserOrganisation ?? null,
    [currentUserOrganisation]
  );

  const { data: getReferralLevelData } = useGetReferralLevelQuery({
    variables: {
      userId: userDataLocal.id,
    },
  });

  const [updateReferralLevel] = useUpdateReferralLevelMutation();
  const [updateReferredStatus] = useUpdateReferredStatusMutation();

  const handleBillingModal = React.useCallback(() => {
    setOpenBillingModal(!openBillingModal);
  }, [setOpenBillingModal, openBillingModal]);

  const sendReferralHandler = React.useCallback(
    async (email: string) => {
      // console.debug("====> Sending email invitation: ", email);

      setInviteEmail(email);
      setLoadingInvite(true);
      try {
        await sendReferralInvite({
          variables: {
            email,
            userId: userDataLocal.id,
            name: email,
          },
        });
      } catch (error) {
        setSnackBarMessage({
          message: "Error while try send invitation email!",
          severity: "error",
        });
      }

      setLoadingInvite(false);
    },
    [sendReferralInvite, userDataLocal, setInviteEmail]
  );

  const updateReferralInfo = React.useCallback(() => {
    const referralLinksAvailables = referralList.filter(
      (link) => link.status === "Signed In"
    );

    referralLinksAvailables.forEach(async (link) => {
      await updateReferredStatus({
        variables: {
          referralLinkId: link.id ?? "",
          status: "Complete",
        },
      });
    });

    updateReferralLevel({
      variables: {
        userId: userDataLocal.id,
        percentage: resumeInfo.percentageAvailable,
      },
    });

    referralLinksRefetch();
  }, [
    referralLinksRefetch,
    referralList,
    resumeInfo,
    updateReferralLevel,
    updateReferredStatus,
    userDataLocal,
  ]);

  const manageCouponCreated = React.useCallback(
    async (coupon: AudionautCoupon) => {
      if (coupon.stripeCouponId) {
        // console.debug("=====> new Coupon: ", currentPlan);

        if (
          currentPlan &&
          currentPlan.stripeSubscriptionId &&
          coupon.stripeCouponId
        ) {
          // console.debug("=====> updating subscription...", currentPlan);
          await updatePlanWithCoupon({
            variables: {
              planId: currentPlan.stripeSubscriptionId,
              couponId: coupon.stripeCouponId ?? "",
            },
          });
          updateReferralInfo();

          setSnackBarMessage({
            message: `The Coupon has been applied successfully to the current subscription!`,
            severity: "success",
          });
        } else {
          // console.debug("=====> opening payment modal...");
          handleBillingModal();
        }
        setLoadingRedeem(false);
      }
    },
    [currentPlan, handleBillingModal, updatePlanWithCoupon, updateReferralInfo]
  );

  const redeemHandler = React.useCallback(async () => {
    try {
      setInviteEmail(null);
      setLoadingRedeem(true);
      if (!currentCouponCreated) {
        const referralLinksAvailables = referralList.filter(
          (link) => link.status === "Signed In"
        );
        // console.debug("=====> Redeeming coupon: ", latestReferralLink, currentPlan);
        const currentDate = new Date();
        const expirationDate = new Date();

        expirationDate.setMonth(currentDate.getMonth() + 1);

        // console.debug("====> expiration date: ", expirationDate.toISOString());

        const redeemCoupon = {
          couponId: `RedeemCoupon-${currentDate.getTime()}`,
          referralLinkId: referralLinksAvailables[0].id ?? "",
          name: `Redeemed Coupon ${resumeInfo.percentageAvailable}%`,
          description: "Redeemed coupon earned by referred your friends",
          percentage: resumeInfo.percentageAvailable,
          validTill: expirationDate.toISOString(),
          audionautPlanId:
            currentPlan && currentPlan.audionautPlan
              ? (currentPlan.audionautPlan.id as string)
              : (chatProPlanData?.id as string),
          maxRedemptions: 1,
        };

        // console.debug("=====> redeem coupon data: ", redeemCoupon);

        await createRedeemCoupon({
          variables: redeemCoupon,
        });

        setSnackBarMessage({
          message: `The Coupon has been created successfully, now can you use it!`,
          severity: "success",
        });
      } else {
        manageCouponCreated(currentCouponCreated);
      }
    } catch (error) {
      setLoadingRedeem(false);
      setSnackBarMessage({
        message: `Error while try apply a discount to the current subscription!`,
        severity: "error",
      });
    }
  }, [
    referralList,
    currentPlan,
    createRedeemCoupon,
    resumeInfo,
    setInviteEmail,
    chatProPlanData,
    currentCouponCreated,
    manageCouponCreated,
  ]);

  const searchReferrals = React.useCallback(
    (query) => {
      if (query !== "") {
        // console.debug("======> searchPapers: ", query);

        setReferralList(
          referralListBkp.filter((item) => item.emailSentTo?.includes(query))
        );
        // getPapersListQuery({
        //   variables: {
        //     query,
        //     total: 10,
        //   },
        // });
      } else {
        setReferralList(referralListBkp);
      }
    },
    [referralListBkp]
  );

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const createSubscription = React.useCallback(
    async (id?: string, couponCode?: string) => {
      try {
        if (couponCode) {
          await createPlanSubscriptionWithCoupon({
            variables: {
              planId: id ?? "",
              coupon: couponCode,
            },
          });
        } else {
          await createPlanSubscription({
            variables: {
              planId: id ?? "",
            },
          });
        }

        updateReferralInfo();

        TagManager.dataLayer({
          dataLayer: {
            event: "planSubscribed",
          },
        });

        setSnackBarMessage({
          message: "The plan subscription has been buyed successfully!",
          severity: "success",
        });

        setOpenBillingModal(false);
        currentSubscriptionRefetch();
      } catch (error: any) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PlanSubscriptionFailed",
          },
        });

        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the plan subscription"
            )
          );
        }
      }
    },
    [
      createPlanSubscription,
      createPlanSubscriptionWithCoupon,
      errorHandler,
      updateReferralInfo,
      currentSubscriptionRefetch,
    ]
  );

  React.useEffect(() => {
    if (referralLinksData && referralLinksData.GetMyReferrals) {
      const { GetMyReferrals } = referralLinksData;
      setReferralList((GetMyReferrals as ReferredLink[]) ?? []);
      setReferralListBkp((GetMyReferrals as ReferredLink[]) ?? []);

      setResumeInfo((oldREsumeInfo) => {
        const newResumeInfo = { ...oldREsumeInfo };

        newResumeInfo.pendingRewards = GetMyReferrals.filter(
          (link) => (link?.status ?? "").toLowerCase() === "pending"
        ).length;
        newResumeInfo.signedInRewards = GetMyReferrals.filter(
          (link) => (link?.status ?? "").toLowerCase() === "signed in"
        ).length;
        newResumeInfo.signedUpRewards = GetMyReferrals.filter(
          (link) => (link?.status ?? "").toLowerCase() === "signed up"
        ).length;
        newResumeInfo.completeRewards = GetMyReferrals.filter(
          (link) => (link?.status ?? "").toLowerCase() === "complete"
        ).length;

        if (newResumeInfo.completeRewards > 0) {
          if (newResumeInfo.completeRewards > 2) {
            newResumeInfo.percentageApplied = 40;
          } else {
            newResumeInfo.percentageApplied =
              newResumeInfo.completeRewards === 2 ? 30 : 20;
          }
        }

        if (
          newResumeInfo.completeRewards < 3 &&
          newResumeInfo.percentageApplied !== 40
        ) {
          if (
            newResumeInfo.signedInRewards + newResumeInfo.completeRewards >
            2
          ) {
            newResumeInfo.percentageAvailable = 40;
          } else {
            newResumeInfo.percentageAvailable =
              newResumeInfo.signedInRewards + newResumeInfo.completeRewards ===
              2
                ? 30
                : 20;

            if (
              newResumeInfo.signedInRewards + newResumeInfo.completeRewards ===
              0
            ) {
              newResumeInfo.percentageAvailable = 0;
            }
          }
        }

        if (
          newResumeInfo.percentageApplied === newResumeInfo.percentageAvailable
        ) {
          newResumeInfo.percentageAvailable = 0;
        }

        // console.debug("======> new resume info: ", newResumeInfo);

        return newResumeInfo;
      });
    } else {
      setReferralList([]);
      setReferralListBkp([]);
    }
  }, [referralLinksData]);

  React.useEffect(() => {
    // console.debug("======> currentSubscriptionData: ", currentSubscriptionData);
    if (
      currentSubscriptionData &&
      currentSubscriptionData?.getCurrentUserOrganisationCurrentPeriodSubscription
    ) {
      setCurrentPlan(
        currentSubscriptionData.getCurrentUserOrganisationCurrentPeriodSubscription
      );
    }
  }, [currentSubscriptionData]);

  React.useEffect(() => {
    // console.debug("======> createdCouponData: ", createdCouponData);
    if (
      createdCouponData &&
      createdCouponData.createReferredCoupon &&
      createdCouponData.createReferredCoupon.id &&
      !currentCouponCreated
    ) {
      // console.debug("=====> applying coupon: ", createdCouponData);

      setCurrentCouponCreated(createdCouponData.createReferredCoupon);
      manageCouponCreated(createdCouponData.createReferredCoupon);
    }
  }, [createdCouponData, currentCouponCreated, manageCouponCreated]);

  React.useEffect(() => {
    if (userOrganization && userOrganization.id) {
      const { billingAddresses, paymentMethods } = userOrganization;
      if (billingAddresses && billingAddresses.length > 0) {
        const defaultBillingAddress: BillingAddress =
          (billingAddresses.find(
            (address) => !!address && address.isDefault
          ) as BillingAddress) ?? billingAddresses[0];
        setCurrentAddress(defaultBillingAddress);
      }

      if (paymentMethods && paymentMethods.length > 0) {
        const defaultPaymentMethod: PaymentMethod =
          (paymentMethods.find(
            (payment) => payment?.isDefault
          ) as PaymentMethod) ?? paymentMethods[0];
        setCurrentPaymentMethod(defaultPaymentMethod);
      }
    }
  }, [userOrganization]);

  React.useEffect(() => {
    if (
      sendReferralInviteData &&
      sendReferralInviteData.SendEmailReferralInvite
    ) {
      // console.debug("=====> send email: ", sendReferralInviteData);
      setTimeout(() => {
        referralLinksRefetch();
      }, 1000);

      setSnackBarMessage({
        message: `The invitation email was sent successfully!`,
        severity: "success",
      });
    }
  }, [sendReferralInviteData, referralLinksRefetch]);

  // React.useEffect(() => {
  //   if (sendReferralInviteError) {
  //     console.debug("=====> Error send email: ", sendReferralInviteError);
  //     setSnackBarMessage({
  //       message: `The email invited already has been invited before!`,
  //       severity: "info",
  //     });
  //   }
  // }, [sendReferralInviteError]);

  return (
    <SC.PageContainer>
      <SC.PageContent>
        <SC.PageHeader>
          <SC.PageTitle>
            <Typography variant="h1" color="primary">
              Referrals
            </Typography>
          </SC.PageTitle>
        </SC.PageHeader>
        <Grid
          container
          spacing={3}
          lg={12}
          md={12}
          xs={12}
          style={{ margin: "0px" }}
        >
          <Grid
            key="rewards"
            item
            lg={8}
            md={4}
            xs={12}
            style={{ paddingLeft: "0px" }}
          >
            <SC.CardElement>
              <SC.RewardsElement
                onClickSend={(email: string) => {
                  setInviteEmail(email);
                  setOpenDelete(true);
                  setModalTitle("Email Invite");
                  setModalDescription(
                    `Are you sure you send an invite to ${email}?`
                  );
                }}
                resumeInfo={resumeInfo}
                loading={loadingInvite}
              />
            </SC.CardElement>
          </Grid>
          <Grid
            key="Earned"
            item
            lg={4}
            md={4}
            xs={12}
            style={{ paddingLeft: "0px" }}
          >
            <SC.CardElement>
              <SC.MainContainerWithBorder>
                <SC.CardTitle align="center">Rewards Earned</SC.CardTitle>
                <SC.EarnedElement
                  redeemClick={() => {
                    setInviteEmail(null);
                    setOpenDelete(true);
                    setModalTitle("Apply discount");
                    setModalDescription(
                      `Are you sure apply a discount of ${resumeInfo.percentageAvailable}%?`
                    );
                  }}
                  resumeInfo={resumeInfo}
                  loading={loadingRedeem}
                />
              </SC.MainContainerWithBorder>
            </SC.CardElement>
          </Grid>
          <Grid
            key="references"
            item
            lg={12}
            md={12}
            xs={12}
            style={{ paddingLeft: "0px" }}
          >
            <SC.CardElement>
              <SC.MainContainerWithBorder>
                <SC.CardTitle align="left">List of references</SC.CardTitle>
                <SC.PapersTable
                  hiddenTitle
                  hiddenFooter
                  setCustomRowClass="status"
                  loading={referralLinksLoading}
                  title="List of references"
                  data={referralList}
                  columns={
                    columns as unknown as TableColumn<Record<string, unknown>>[]
                  }
                  onAction={(action) => {
                    // console.debug("=====> on action: ", action);
                  }}
                  topPanel={
                    <SC.SearchInputContainer>
                      <SC.SearchInput
                        hideSearchBar={false}
                        placeholder="Search papers"
                        doSearch={searchReferrals}
                        startIcon={<SearchIcon color="primary" />}
                        isMultilines={false}
                        clearInputE={false}
                      />
                    </SC.SearchInputContainer>
                  }
                />
              </SC.MainContainerWithBorder>
            </SC.CardElement>
          </Grid>
        </Grid>
      </SC.PageContent>
      <SC.RewardInfo />
      <BilingModal
        openModal={openBillingModal}
        planData={chatProPlanData as AudionautPlanFragmentFragment | null}
        createSubscription={createSubscription}
        handleCloseModal={handleBillingModal}
        currentAddressData={currentAddress}
        paymentMethodData={currentPaymentMethod}
        couponPreloaded={
          createdCouponData ? createdCouponData.createReferredCoupon : undefined
        }
      />
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={snackBarMessage?.delay ?? 3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
      <Dialog
        id="delete-dialog"
        open={openDelete}
        onClose={() => {
          setOpenDelete(!openDelete);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="primary">
          {modalTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            {modalDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary
            onClick={() => {
              setOpenDelete(!openDelete);
              setInviteEmail(null);
            }}
            color="primary"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary
            onClick={() => {
              if (inviteEmail !== null) {
                sendReferralHandler(inviteEmail);
                setInviteEmail(null);
              } else {
                redeemHandler();
              }
              setOpenDelete(!openDelete);
            }}
            color="primary"
            autoFocus
          >
            Accept
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </SC.PageContainer>
  );
};

export default ReferralSystemPage;
