import {
  withStyles,
  Typography,
  FormHelperText,
  TextField,
  Theme,
  Box,
} from "@material-ui/core";

import {
  Form as FormBase,
  PasswordInputAdornment,
  IconButton,
  VisibilityIcon,
  VisibilityOffIcon,
  Button as ButtonBase,
  NoRegisteredUserInfo,
  Link,
} from "components/signin-form/signin-form.styles";

export const Form = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: "117px",
    paddingRight: "117px",
    [theme.breakpoints.down(540)]: {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      padding: "20px",
    },
  },
}))(FormBase);

export const Text = withStyles(() => ({
  root: {
    // color: "#757575",
    fontSize: "16px",
    // lineHeight: "22px",
    // paddingTop: "29px",
    paddingTop: "0px",
    // paddingBottom: "68px",
    paddingBottom: "40px",
    fontWeight: 500,
  },
}))(Typography);

export const PasswordField = withStyles({
  root: {
    marginBottom: "33px",
  },
})(TextField);

export const ConfirmPasswordField = withStyles({
  root: {
    marginBottom: "65px",
  },
})(TextField);

export const Button = withStyles({
  root: {
    textTransform: "none",
    marginBottom: "0px",
  },
})(ButtonBase);

// passwordRules: {
//   fontSize: "14px",
//   lineHeight: "16px",
//   fontWeight: 400,
//   color: "rgba(0, 0, 0, 0.54)",
//   marginBottom: "48px",
// },

const DividerMobile = withStyles((theme: Theme) => ({
  root: {
    display: "none",
    [theme.breakpoints.down(540)]: {
      display: "block",
      flex: "1",
    },
  },
}))(Box);

export default {
  Form,
  Text,
  PasswordField,
  ConfirmPasswordField,
  PasswordInputAdornment,
  IconButton,
  VisibilityIcon,
  VisibilityOffIcon,
  FormHelperText,
  Button,
  NoRegisteredUserInfo,
  Link,
  DividerMobile,
};
