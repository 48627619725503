import React from "react";

// Styles
import SC from "./left-pane.styles";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LeftPane: React.FC = ({ children }) => (
  <SC.Container>
    <SC.Box>{children}</SC.Box>
  </SC.Container>
);

export default LeftPane;
