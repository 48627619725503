// Dependencies
import React from "react";

// Asses
import SC from "./progress-indicator.styles";

export interface ProgressIndicatorProps {
  open: boolean;
  backdropColor?: string;
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  open,
  backdropColor = "",
}) => {
  return (
    <SC.Dialog
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: backdropColor,
        },
      }}
    >
      <SC.ProgressIndicator />
    </SC.Dialog>
  );
};

export default ProgressIndicator;
