/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { USER } = ActionTypes;

const INITIAL_STATE = {
  userData: null,
  isAddingUser: false,
  error: null,
};


const createUserReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER.CREATE.START:
      return {
        ...state,
        isAddingUser: true,
        error: null
      };

    case USER.CREATE.SUCCESS: {
      const { AddUser } = action.payload;
      return {
        ...state,
        userData: AddUser[0],
        isAddingUser: false,
      };
    }

    case USER.CREATE.FAILURE:
      return {
        ...state,
        isAddingUser: false,
        error: action.payload,
      };

    case USER.CREATE.CLEAR:
      return {
        ...state,
        userData: null,
        error: null
      };
    default: return state;
  }
};


export default createUserReducerFn;
