import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Paper,
  Slide,
  Snackbar,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import {
  useGetBehaviorResultsQuery,
  useAddBehaviorQueryMutation,
  useUpdateBehaviorQueryMutation,
  BehaviorResultsFragment,
} from "graphql/behaviors/types-and-hooks";
import { CLIENT_NAME } from "graphql/client";
import { NEW_BEHAVIOR_QUERY_FRAGMENT_GQL } from "graphql/behaviors/behaviors.utils";

import Alert, { AlertProps } from "components/alert/alert.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import { useLocation } from "react-router-dom";
import { useAppErrorHandler } from "errors/app.errors";

import Fade from "@material-ui/core/Fade";
import { QueryBuilderComponent } from "./query_builder.component";
import BC from "./behaviors.styles";

export const BehaviorsPage = (): JSX.Element => {
  const classes = BC.useStyles();

  const [jsonTree, setjsonTree] = useState("");
  const [currentJsonLogic, setCurrentJsonLogic] = useState("");

  const [newBehaviorName, setNewBehaviorName] = useState("");
  const [inputType, setInputType] = useState<"filled" | "standard">("filled");

  const queryParms = useLocation().search;
  const behaviorId = new URLSearchParams(queryParms).get("id");

  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();

  const behaviorExist = currentJsonLogic && behaviorId;

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const {
    data: behaviorDataResponse,
    loading: behaviorDataLoading,
    error: errorBehaviorDataError,
    refetch: refetchBrahviorResults,
  } = useGetBehaviorResultsQuery({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
    },
    fetchPolicy: "no-cache",
    variables: {
      id: !behaviorId ? "" : behaviorId,
      total: 1,
    },
    skip: !behaviorId,
  });

  const errorHandler = useAppErrorHandler();

  const [addBehaviorQuery, { loading: addBehaviorQueryLoading }] =
    useAddBehaviorQueryMutation({
      context: {
        clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
      },
      update(cache, { data }) {
        const newBehaviorQuery = data?.addBehavior;
        if (newBehaviorQuery) {
          cache.modify({
            fields: {
              GetBehaviorQuery(existingBehavior = []) {
                const newBehaviorRef = cache.writeFragment({
                  id: cache.identify(newBehaviorQuery),
                  data: newBehaviorQuery,
                  fragment: NEW_BEHAVIOR_QUERY_FRAGMENT_GQL,
                });
                return [...existingBehavior, newBehaviorRef];
              },
            },
          });
        }
      },
    });

  const [updateBehaviorQuery, { loading: updateBehaviorQueryLoading }] =
    useUpdateBehaviorQueryMutation({
      context: {
        clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
      },
      update(cache, { data }) {
        const behaviorQuery = data?.editBehavior?.response;
        if (behaviorQuery) {
          cache.modify({
            fields: {
              GetBehaviorQuery(existingBehavior = []) {
                const updateBehaviorRef = cache.writeFragment({
                  id: cache.identify(behaviorQuery),
                  data: behaviorQuery,
                  fragment: NEW_BEHAVIOR_QUERY_FRAGMENT_GQL,
                });
                return [...existingBehavior, updateBehaviorRef];
              },
            },
          });
        }
      },
    });

  const loading = behaviorDataLoading;

  const handleClickAddBehaviorQuery: React.FormEventHandler =
    React.useCallback(async () => {
      if (!newBehaviorName || !jsonTree) {
        setSnackBarMessage({
          message: `Please set a behavior name and defina a query.`,
          severity: "error",
        });
        return;
      }

      if (behaviorExist) {
        const updateBehavior = {
          id: behaviorId!,
          name: newBehaviorName,
          query: JSON.stringify(jsonTree),
        };
        await updateBehaviorQuery({
          variables: updateBehavior,
        });
      } else {
        const newBehavior = {
          behaviorName: newBehaviorName,
          behaviorQuery: JSON.stringify(jsonTree),
        };

        await addBehaviorQuery({
          variables: newBehavior,
        });
      }

      // setNewBehaviorName("");
      // setjsonTree("");
      setSnackBarMessage({
        message: `The behavior query ${newBehaviorName} has been ${
          behaviorExist ? "updated" : "created"
        } successfully.`,
        severity: "success",
      });
    }, [
      addBehaviorQuery,
      updateBehaviorQuery,
      jsonTree,
      newBehaviorName,
      behaviorExist,
      behaviorId,
    ]);

  useEffect(() => {
    if (behaviorDataResponse && behaviorDataResponse.getBehaviors) {
      const {
        getBehaviors: { behaviors },
      } = behaviorDataResponse;
      if (behaviors && behaviors.length > 0) {
        const { behaviorName, tree } = behaviors[0] as BehaviorResultsFragment;
        setNewBehaviorName(behaviorName!);
        setCurrentJsonLogic(JSON.parse(tree!));
        setInputType("standard");
      }
    }
  }, [behaviorDataResponse]);

  return (
    <BC.ASContainer>
      <ProgressIndicator
        open={addBehaviorQueryLoading || updateBehaviorQueryLoading}
      />
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
      <BC.ASContent>
        <Box component="div" className={classes.titleContainer}>
          <h1>Behaviors</h1>
          <BC.ButtonPrimary
            disabled={!newBehaviorName || !jsonTree}
            onClick={handleClickAddBehaviorQuery}
          >
            {behaviorExist ? "Update Behavior" : "Save Behavior"}
          </BC.ButtonPrimary>
        </Box>
        <BC.BehaviorBg>
          {/* <Box className="header">
            <Box className="leading">
              <SvgIcon component={MenuThreeDotIcon} />
              <span className="text">I want to see </span>
              <SourceListMenu />
              <SvgIcon component={CloseCircleIcon} />
            </Box>
          </Box> */}
          <Box className="content">
            {loading ? (
              <CircularProgress className={classes.loader} />
            ) : (
              <>
                <BC.BehaviorNameContainer component="div">
                  <TextField
                    id="behaviorName"
                    placeholder="Set a behavior name"
                    variant={inputType}
                    className={classes.behaviorName}
                    value={newBehaviorName}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: inputType !== "filled",
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          hidden={inputType === "filled"}
                        >
                          <EditIcon />
                        </InputAdornment>
                      ),
                    }}
                    onClick={(event) => {
                      if (inputType !== "filled") {
                        setInputType("filled");
                        event.currentTarget.focus();
                      }
                    }}
                    onChange={(event) => {
                      setNewBehaviorName(event.currentTarget.value);
                    }}
                  />
                </BC.BehaviorNameContainer>
                <QueryBuilderComponent
                  updatejsonTree={setjsonTree}
                  jsonLogicExist={currentJsonLogic}
                />
              </>
            )}
          </Box>
        </BC.BehaviorBg>
      </BC.ASContent>
      <Box
        hidden={!!newBehaviorName || !!jsonTree}
        className={classes.adviseMessage}
      >
        <Slide direction="up" in={!newBehaviorName && !jsonTree}>
          <Paper elevation={4}>
            <BC.NoResults
              show
              title="Build behaviors that detect every time your product is mentioned"
              description="Start building behaviors by adding rules and groups."
            />
          </Paper>
        </Slide>
      </Box>
    </BC.ASContainer>
  );
};

export default BehaviorsPage;
