import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Tooltip,
  DialogTitle,
  Snackbar,
  LinearProgress,
} from "@material-ui/core";
import { Alert, AlertProps } from "components/alert/alert.component";
import clsx from "clsx";

import { AudionautCapitalizedWord } from "utils/internationalization";

import { ApolloQueryResult } from "@apollo/client";
import { CLIENT_NAME } from "graphql/client";
import {
  Exact,
  GetSourcesQuery,
  Maybe,
  SourceFragment,
  useAddMediaSourceMutation,
  useRemoveUrlScheduleMutation,
} from "graphql/explore/types-and-hooks";
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PlatformTable from "components/audio-source-table/audio-source-table.component";
import { isMobileResolution } from "commons/utils/device-info.util";

import {
  VideoModel,
  ChannelModel,
  PlaylistModel,
  HashtagModel,
} from "commons/models/audio-source.model";

import ASItemStyles from "./audio-source-item.styles";

interface DataSubTable {
  videoName: string;
  url: string;
  videos: string;
  views: string;
  subscribers: string;
  update: string;
  creation: string;
  actions: string;
  image: string;
  status: string;
  scheduled: number;
  duration: string;
}

function createDataSubTable(
  videoName: string,
  url: string,
  videos: string,
  views: string,
  subscribers: string,
  update: string,
  creation: string,
  actions: string,
  image: string,
  status: string,
  scheduled: number,
  duration: string
): DataSubTable {
  return {
    videoName,
    url,
    videos,
    views,
    subscribers,
    update,
    creation,
    actions,
    image,
    status,
    scheduled,
    duration,
  };
}

interface AudioSourceItemProps {
  itemData: { id: string } & SourceFragment;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            sources?: Maybe<Maybe<string> | Maybe<string>[]> | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetSourcesQuery>>;
  reloadVideos: boolean;
  existsVideos: (arg0: boolean) => void;
  setVideosLoading: (arg0: boolean) => void;
}

const AudioSourceItem: React.FC<AudioSourceItemProps> = ({
  itemData,
  refetch,
  reloadVideos,
  existsVideos,
  setVideosLoading,
}) => {
  const classes = ASItemStyles.useStyles();

  const [loadingChannels, setLoadingChannels] = React.useState(false);
  const [loadingPlaylists, setLoadingPlaylists] = React.useState(false);
  const [loadingVideos, setLoadingVideos] = React.useState(false);
  const [loadingHashtags, setLoadingHashtags] = React.useState(false);

  const [channelsExists, setChannelsExists] = React.useState(false);
  const [playlistsExists, setPlaylistsExists] = React.useState(false);
  const [videosExists, setVideosExists] = React.useState(false);
  const [HashtagsExists, setHashtagsExists] = React.useState(false);

  const {
    id,
    proccesedAudio,
    sourceName,
    channelsCount,
    playlistsCount,
    mediasCount,
    hashtagsCount,
  } = itemData;

  const [expanded, setExpanded] = React.useState(false);

  const [openAddSourceForm, setOpenAddSourceForm] = React.useState(false);
  const [newSourceName, setNewSourceName] = React.useState<any>({});

  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [totalTimeProcessed, setTotalAudioProcessed] = React.useState("00:00");

  const [addingNewChannelItem, setAddingNewChannelItem] = React.useState(false);
  const [channelsAdded, setChannelsAdded] =
    React.useState<ChannelModel[] | undefined>();
  const [addingNewPlaylistItem, setAddingNewPlaylistItem] =
    React.useState(false);
  const [playlistsAdded, setPlaylistsAdded] =
    React.useState<PlaylistModel[] | undefined>();
  const [addingNewVideoItem, setAddingNewVideoItem] = React.useState(false);
  const [videosAdded, setVideosAdded] =
    React.useState<VideoModel[] | undefined>();
  const [addingNewHashtagItem, setAddingNewHashtagItem] = React.useState(false);
  const [hashtagsAdded, setHashtagsAdded] =
    React.useState<HashtagModel[] | undefined>();
  const [itemIdDeleted, setItemIdDeleted] =
    React.useState<string | undefined>();

  const [
    addNewSourceURL,
    { loading: addSourceLoading, error: addSourceError },
  ] = useAddMediaSourceMutation({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
  });

  const [deleteUrl, { error: deletedError }] = useRemoveUrlScheduleMutation({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
  });

  const errorHandler = useAppErrorHandler(addSourceError || deletedError);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handleSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      const urlsToAdd = newSourceName.target.value.split("\n");

      setOpenAddSourceForm(false);
      setNewSourceName("");

      const newChannels: ChannelModel[] = [];
      const newPlaylists: PlaylistModel[] = [];
      const newMedias: VideoModel[] = [];
      const newHashtags: HashtagModel[] = [];
      let userNotifyMsg = "";

      try {
        setAddingNewChannelItem(
          !!urlsToAdd.find((url: string) => url.includes("channel"))
        );
        setAddingNewPlaylistItem(
          !!urlsToAdd.find(
            (url: string) => url.includes("playlist") || url.includes("list")
          )
        );
        setAddingNewVideoItem(
          !!urlsToAdd.find(
            (url: string) => url.includes("watch") && !url.includes("list")
          )
        );
        setAddingNewHashtagItem(
          !!urlsToAdd.find((url: string) => url.substring(0, 1) === "#")
        );
        const result = await addNewSourceURL({
          variables: {
            urls: urlsToAdd,
          },
        });

        // console.debug("=====> Add new item: ", urlsToAdd, result);

        const response =
          result &&
          result.data &&
          result.data?.addNewUrlToProcess &&
          result.data?.addNewUrlToProcess?.data
            ? result.data?.addNewUrlToProcess?.data
            : [];

        const wrongURLS = [];

        response?.forEach((media) => {
          if (media?.error) {
            errorHandler(
              new UIError(
                UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
                "An error has ocurred while adding the new source URL"
              )
            );
          } else if (media?.urlAlreadyExists) {
            userNotifyMsg = "The audio already processed successfully before";
          } else {
            const metadata = JSON.parse(media!.metadata!);
            const { category, url } = metadata;

            // console.debug("=====> Metadata: ", metadata);

            if (category === "HASHTAG") {
              const newItem: HashtagModel = metadata;
              newHashtags.push(newItem);
            } else if (!!url && url.includes("channel")) {
              const newItem: ChannelModel = metadata;
              newChannels.push(newItem);
            } else if (
              (!!url && url.includes("playlist")) ||
              url.includes("list")
            ) {
              const newItem: PlaylistModel = metadata;
              newPlaylists.push(newItem);
            } else if (
              !!url &&
              url.includes("watch") &&
              !url.includes("list")
            ) {
              const newItem: VideoModel = metadata;
              newMedias.push(newItem);
            }

            // Add new items data in each type respectively (channel, playlist or video)
            if (newChannels.length > 0) setChannelsAdded(newChannels);
            if (newPlaylists.length > 0) setPlaylistsAdded(newPlaylists);
            if (newMedias.length > 0) setVideosAdded(newMedias);
            if (newHashtags.length > 0) setHashtagsAdded(newHashtags);

            userNotifyMsg = "The audio source has been added successfully";
          }
        });

        if (response!.length > wrongURLS.length && !!userNotifyMsg) {
          setSnackBarMessage({
            message: userNotifyMsg,
            severity: "success",
          });
        }

        setChannelsAdded(undefined);
        setPlaylistsAdded(undefined);
        setVideosAdded(undefined);
        setHashtagsAdded(undefined);

        setAddingNewChannelItem(false);
        setAddingNewPlaylistItem(false);
        setAddingNewVideoItem(false);
        setAddingNewHashtagItem(false);
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while adding the new source URL"
            )
          );
        }
      }
    },
    [newSourceName, errorHandler, addNewSourceURL]
  );

  const handleDeleteItem = async (itemId: string, category: string) => {
    try {
      await deleteUrl({
        variables: {
          recordId: itemId,
        },
      });

      setItemIdDeleted(itemId);

      setSnackBarMessage({
        message: "The audio source has been deleted successfully",
        severity: "success",
      });
    } catch (error: any) {
      if (isServerError(error)) {
        errorHandler(error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while adding the new source URL"
          )
        );
      }
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseAddSourceFrom = () => {
    setOpenAddSourceForm(false);
  };

  const handleAddSourceForm = () => {
    setOpenAddSourceForm(true);
    setNewSourceName("");
  };

  React.useEffect(() => {
    setVideosLoading(
      loadingChannels || loadingPlaylists || loadingVideos || loadingHashtags
    );
  }, [
    setVideosLoading,
    loadingChannels,
    loadingPlaylists,
    loadingVideos,
    loadingHashtags,
  ]);

  return (
    <Card className={classes.itemContainer} key={id}>
      {/* <ProgressIndicator open={loading} /> */}
      <CardActions className={classes.itemActions} disableSpacing>
        <img
          className={classes.brandIcon}
          src={ASItemStyles.getImageByKey(
            `${AudionautCapitalizedWord(sourceName ?? "Default")}Icon`
          )}
          alt={`${sourceName}-Icon`}
          style={{ width: "35px" }}
        />
        {/* <SvgIcon className={classes.brandIcon} component={youtube} /> */}
        <h3 hidden={isMobileResolution()} className={classes.brandLbl}>
          {AudionautCapitalizedWord(sourceName ?? "Default")}
        </h3>
        {/* <Box
          component="div"
          className={`${classes.linkStyle} ${classes.gray} ${classes.elemStyle}`}
        >
          <SearchIcon />
          <Input placeholder="Explore URL" onKeyDown={handleclickKey} />
        </Box> */}
        <ASItemStyles.AudioInfo
          className={`${classes.purple} ${classes.elemStyle}`}
        >
          Processed Audio: {proccesedAudio}
        </ASItemStyles.AudioInfo>
        {/* <Tooltip title="Schedule" arrow>
          <IconButton className={`${classes.gray} ${classes.elemStyle}`}>
            <CalendarTodayOutlinedIcon />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Add URL" arrow>
          <IconButton
            className={`${classes.purple} ${classes.elemStyle}`}
            onClick={handleAddSourceForm}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={openAddSourceForm}
          onClose={handleCloseAddSourceFrom}
          aria-labelledby="form-dialog-title"
          className={classes.modalCustom}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <DialogTitle className="title">
              {`Add a ${AudionautCapitalizedWord(
                sourceName ?? "Default"
              )} webpage address`}
            </DialogTitle>
            <DialogContent>
              <ASItemStyles.SourceUrlField
                autoFocus
                variant="outlined"
                name="sourceUrl"
                margin="dense"
                label="Source URL"
                placeholder={`https://www.${
                  sourceName ? sourceName.toLowerCase() : "example"
                }.com/example`}
                type="text"
                fullWidth
                onChange={setNewSourceName as any}
                multiline
              />
            </DialogContent>
            <DialogActions className="buttons">
              <Button onClick={handleCloseAddSourceFrom} className="cancelBtn">
                Cancel
              </Button>
              <Button type="submit" className="createBtn">
                Add
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        {/* <Tooltip title="Actions" arrow>
          <IconButton className={`${classes.purple} ${classes.elemStyle}`}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip> */}
        <Tooltip title={expanded ? "Collapse" : "Expand"} arrow>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <Divider
        className={clsx({
          [classes.hide]: !expanded,
        })}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.subTableRoot}>
          <PlatformTable
            title="Channels"
            addingNewItem={addingNewChannelItem}
            source={sourceName ?? "Unknown"}
            deleteItem={handleDeleteItem}
            reloadVideos={reloadVideos}
            category="CHANNEL"
            itemIdDeleted={itemIdDeleted}
            itemAdded={channelsAdded}
            setExistsVideos={setChannelsExists}
            loadingCategory={setLoadingChannels}
            totalItems={channelsCount ?? 0}
          />
          <PlatformTable
            title="Playlists"
            addingNewItem={addingNewPlaylistItem}
            source={sourceName ?? "Unknown"}
            deleteItem={handleDeleteItem}
            reloadVideos={reloadVideos}
            category="PLAYLIST"
            itemIdDeleted={itemIdDeleted}
            itemAdded={playlistsAdded}
            setExistsVideos={setPlaylistsExists}
            loadingCategory={setLoadingPlaylists}
            totalItems={playlistsCount ?? 0}
          />
          <PlatformTable
            title="Videos"
            addingNewItem={addingNewVideoItem}
            source={sourceName ?? "Unknown"}
            deleteItem={handleDeleteItem}
            reloadVideos={reloadVideos}
            category="MEDIA"
            itemIdDeleted={itemIdDeleted}
            itemAdded={videosAdded}
            setExistsVideos={setVideosExists}
            loadingCategory={setLoadingVideos}
            totalItems={mediasCount ?? 0}
          />
          <PlatformTable
            title="Hashtags"
            addingNewItem={addingNewHashtagItem}
            source={sourceName ?? "Unknown"}
            deleteItem={handleDeleteItem}
            reloadVideos={reloadVideos}
            category="HASHTAG"
            itemIdDeleted={itemIdDeleted}
            itemAdded={hashtagsAdded}
            setExistsVideos={setHashtagsExists}
            loadingCategory={setLoadingHashtags}
            totalItems={hashtagsCount ?? 0}
          />
          {
            <>
              <LinearProgress
                style={{ width: "100%" }}
                className={clsx({
                  [classes.hide]:
                    !loadingChannels ||
                    !loadingPlaylists ||
                    !loadingVideos ||
                    !loadingHashtags,
                })}
              />
              {/* <Box
                className={clsx(classes.divider, {
                  [classes.hide]:
                    videosLoading ||
                    (!videosLoading && videosList && videosList.length > 0),
                })}
              >
                <Typography style={{ textAlign: "center" }}>
                  Doesn&apos;t exist videos information yet
                </Typography>
                <Divider />
              </Box> */}
              <ASItemStyles.NoResults
                show={
                  !(loadingChannels || (!loadingChannels && channelsExists)) &&
                  !(
                    loadingPlaylists ||
                    (!loadingPlaylists && playlistsExists)
                  ) &&
                  !(loadingVideos || (!loadingVideos && videosExists)) &&
                  !(loadingHashtags || (!loadingHashtags && HashtagsExists))
                }
                title="Add a new audio source"
                description={`Use the + icon to add a ${AudionautCapitalizedWord(
                  sourceName ?? "Unknown"
                )} channel, playlist or video address`}
              />
            </>
          }
        </CardContent>
      </Collapse>
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message ?? ""}
        />
      </Snackbar>
    </Card>
  );
};

export default AudioSourceItem;
