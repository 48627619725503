import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

/** Represents the data of the status of the ACFs generation process. */
export type AcfsGenerationStatus = {
  __typename?: "AcfsGenerationStatus";
  /** Dagster pipeline run status. */
  status: DagsterPipelineRunStatus;
  /** Text that gives more information about the status. */
  message: Scalars["String"];
};

/** Represents the data of a audio source */
export type AudioSourceData = {
  __typename?: "AudioSourceData";
  /** Audio source id */
  id?: Maybe<Scalars["ID"]>;
  /** Audio source name */
  name?: Maybe<Scalars["String"]>;
  /** Audio source platform logo */
  logo?: Maybe<Scalars["String"]>;
};

export type AudionautCoupon = {
  __typename?: "AudionautCoupon";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["Int"]>;
  stripeCouponId?: Maybe<Scalars["String"]>;
  validTill?: Maybe<Scalars["String"]>;
  forAudionautPlanId?: Maybe<Scalars["String"]>;
  forAudionautPlan?: Maybe<AudionautPlan>;
  updatedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  maxRedemptions?: Maybe<Scalars["Int"]>;
};

export type AudionautPlan = {
  __typename?: "AudionautPlan";
  /** ID of plan. */
  id?: Maybe<Scalars["ID"]>;
  /** Plan's name. */
  name?: Maybe<Scalars["String"]>;
  /** Plan's description. */
  description?: Maybe<Scalars["String"]>;
  /** Subscription period price in cents. */
  priceInCent?: Maybe<Scalars["Int"]>;
  /** Total audio time of the subscription period in seconds. */
  audionautUnitInSec?: Maybe<Scalars["Int"]>;
  /** Price per second for extra audio time. */
  pricePerUnitInCent?: Maybe<Scalars["Int"]>;
  /** Number of days that trial periods last. A value of 0 disables trials on subscriptions. */
  trialPeriodNumOfDays?: Maybe<Scalars["Int"]>;
  /** Stripe product Id associated with the plan. */
  stripeProductId?: Maybe<Scalars["String"]>;
  /** Stripe metered price ID associated with the plan. */
  stripePriceId?: Maybe<Scalars["String"]>;
  /** Stripe flat price ID associated with the plan. */
  stripeFlatPriceId?: Maybe<Scalars["String"]>;
  /** Indicates if plan is active. */
  active?: Maybe<Scalars["Boolean"]>;
  /** Contains the list of active coupons related with the plan in JSON format. */
  couponId?: Maybe<Scalars["String"]>;
  /** List of active coupons objects related with the plan. */
  activeCoupons?: Maybe<Array<Maybe<AudionautCoupon>>>;
  /** Timestamp that indicates the latest datetime when the plan was modified. */
  updatedAt?: Maybe<Scalars["String"]>;
  /** Timestamp that indicates when the plan was created. */
  createdAt?: Maybe<Scalars["String"]>;
};

export type AudionautSubscription = {
  __typename?: "AudionautSubscription";
  /** ID of Audionaut subscription. Null if it is an incomplete subscription. */
  id?: Maybe<Scalars["ID"]>;
  startsAt?: Maybe<Scalars["String"]>;
  endsAt?: Maybe<Scalars["String"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]>;
  stripeMeteredSubscriptionItemId?: Maybe<Scalars["String"]>;
  audionautPlan?: Maybe<AudionautPlan>;
  organisation?: Maybe<OrganisationData>;
  audionautPlanId?: Maybe<Scalars["String"]>;
  organisationId?: Maybe<Scalars["String"]>;
  status?: Maybe<SubscriptionStatus>;
  updatedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
};

export type AuthenticationData = {
  __typename?: "AuthenticationData";
  accessToken?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
};

/** Represents the total averange data */
export type AverangeData = {
  __typename?: "AverangeData";
  total?: Maybe<Scalars["String"]>;
  sentences?: Maybe<Scalars["String"]>;
  sentiments?: Maybe<SentimentsPercent>;
};

export type BillingAddress = {
  __typename?: "BillingAddress";
  id?: Maybe<Scalars["ID"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
  organisation?: Maybe<OrganisationData>;
  addedBy?: Maybe<UserData>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
};

/** Represents the structure of the contact data. */
export type ContactData = {
  __typename?: "ContactData";
  /** Address of the contact data. */
  address?: Maybe<Scalars["String"]>;
  /** Phone of the contact data. */
  phone?: Maybe<Scalars["String"]>;
};

export type CustomerSubscriptionInfo = {
  __typename?: "CustomerSubscriptionInfo";
  audionautUnitsLeft?: Maybe<Scalars["Int"]>;
  hasActiveMeteredSubscription?: Maybe<Scalars["Boolean"]>;
  hasDefaultPaymentMethod?: Maybe<Scalars["Boolean"]>;
};

export enum DagsterPipelineRunStatus {
  Queued = "QUEUED",
  NotStarted = "NOT_STARTED",
  Managed = "MANAGED",
  Starting = "STARTING",
  Started = "STARTED",
  Success = "SUCCESS",
  Failure = "FAILURE",
  Canceling = "CANCELING",
  Canceled = "CANCELED",
}

/** Represents the data of a department. */
export type DepartmentData = {
  __typename?: "DepartmentData";
  /** Department ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the department. */
  name?: Maybe<Scalars["String"]>;
};

export enum Entity {
  User = "USER",
  Group = "GROUP",
  Role = "ROLE",
  Department = "DEPARTMENT",
  Organisation = "ORGANISATION",
}

/** Represents the data of an EventFilterCountData item. */
export type EventFilterCountData = {
  __typename?: "EventFilterCountData";
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
};

/** Represents the data of an filter item. */
export type EventFilterMetadata = {
  __typename?: "EventFilterMetadata";
  id?: Maybe<Scalars["ID"]>;
  parentId?: Maybe<Scalars["ID"]>;
  twidget?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  orderNum?: Maybe<Scalars["Int"]>;
  updated?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  checksum?: Maybe<Scalars["Int"]>;
};

/** Represents the file content got from the rTCS storage platform. */
export type FileContent = {
  __typename?: "FileContent";
  filename: Scalars["String"];
  content: Scalars["String"];
};

/** Represents the file location within the rTCS storage platform. */
export type FileLocation = {
  folder: Scalars["String"];
  filename: Scalars["String"];
};

/** Represents the data of a Filter. */
export type Filter = {
  column?: Maybe<Scalars["String"]>;
  values?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum Format {
  Email = "EMAIL",
  StandardName = "STANDARD_NAME",
  Password = "PASSWORD",
}

/** Represents the data of a group. */
export type GroupData = {
  __typename?: "GroupData";
  /** Group ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the group. It is unique and could be used as another identifier. */
  name?: Maybe<Scalars["String"]>;
  /** Description of the group. */
  description?: Maybe<Scalars["String"]>;
  /** UTC datetime when the group data was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /** Roles assigned to the group. */
  roles?: Maybe<Array<Maybe<RoleData>>>;
};

export type GroupInput = {
  /** Group ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the group. It is unique and could be used as another identifier. */
  name?: Maybe<Scalars["String"]>;
  /** Description of the group. */
  description?: Maybe<Scalars["String"]>;
  /** UTC datetime when the group data was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /** Roles assigned to the group. */
  roles?: Maybe<Array<Maybe<RoleInput>>>;
};

/** Represents the relationship between a group and the roles assigned to this group. */
export type GroupRoleData = {
  __typename?: "GroupRoleData";
  /** Group who owns this relationship. */
  group?: Maybe<GroupData>;
  /** Roles assigned to the group of this relationship. */
  roles?: Maybe<Array<Maybe<RoleData>>>;
};

export type Invoice = {
  __typename?: "Invoice";
  id?: Maybe<Scalars["ID"]>;
  stripeInvoiceId?: Maybe<Scalars["String"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]>;
  amountDue?: Maybe<Scalars["Int"]>;
  amountPaid?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  tax?: Maybe<Scalars["Int"]>;
  isAttempted?: Maybe<Scalars["Boolean"]>;
  currency?: Maybe<Scalars["String"]>;
  status?: Maybe<InvoiceStatus>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  invoiceLinesId?: Maybe<Scalars["String"]>;
  lines?: Maybe<Array<Maybe<InvoiceLine>>>;
  paymentIntent?: Maybe<PaymentIntent>;
};

export type InvoiceData = {
  __typename?: "InvoiceData";
  amountOwed?: Maybe<Scalars["Int"]>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
};

export type InvoiceLine = {
  __typename?: "InvoiceLine";
  id?: Maybe<Scalars["ID"]>;
  stripeInvoiceLineId?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Int"]>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

export enum InvoiceStatus {
  Draft = "draft",
  Open = "open",
  Paid = "paid",
  Uncollectible = "uncollectible",
  Void = "void",
}

/** Represents the table name of an MDA. */
export type MdaTable = {
  mda: Scalars["String"];
  name: Scalars["String"];
};

export type MediaItem = {
  __typename?: "MediaItem";
  id: Scalars["ID"];
  recordId?: Maybe<Scalars["String"]>;
  videoName?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  mediaType?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["String"]>;
  views?: Maybe<Scalars["Int"]>;
  schedule?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  dateCreation?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["String"]>;
  classType?: Maybe<Scalars["String"]>;
  orgId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Create a new role. */
  AddRole: Array<Maybe<RoleData>>;
  /** Create a new user. */
  AddUser: Array<Maybe<UserData>>;
  /** Create a new group. */
  AddGroup: Array<Maybe<GroupData>>;
  /** Create a organisation account. */
  createOrganizationAccount: UserData;
  /** Create a new organisation. */
  AddOrganisation: OrganisationData;
  /** Create a new department. */
  AddDepartment: Array<Maybe<DepartmentData>>;
  /** Create the relationship between a group and a role. */
  AddGroupRole: Array<Maybe<GroupData>>;
  /** Create the relationship between an user and a group. */
  AddUserGroup: Array<Maybe<UserData>>;
  /**
   * Updates the relationship between an user and an organisation.
   * If no organisationID is passed or its value is null, the user
   * will not be linked to any organisation.
   */
  UpdateUserOrganisation: Array<Maybe<UserData>>;
  /**
   * Updates or creates the relationship between an user and a department.
   * If no departmentID is passed or its value is null, the user will not
   * be linked to any department.
   */
  UpdateUserDepartment: Array<Maybe<UserData>>;
  /** Update the data of a role. */
  UpdateRole: Array<Maybe<RoleData>>;
  /** Update the information of the user. */
  UpdateUser: Array<Maybe<UserData>>;
  /** Update the current user's profile. */
  UpdateCurrentUserProfile: UserProfile;
  /**
   * Updates the relationship between the current user and an organisation.
   * If no organisationID is passed or its value is null, the user
   * will not be linked to any organisation.
   */
  UpdateCurrentUserProfileOrganisation: UserProfile;
  /**
   * Updates or creates the relationship between the current user and a department.
   * If no departmentID is passed or its value is null, the user will not
   * be linked to any department.
   */
  UpdateCurrentUserProfileDepartment: UserProfile;
  /** Update the data of the group. */
  UpdateGroup: Array<Maybe<GroupData>>;
  /** Update the data of the organisation. */
  UpdateOrganisation: OrganisationData;
  /** Update the data of the department. */
  UpdateDepartment: Array<Maybe<DepartmentData>>;
  /** Delete the role data related with the ID provided. */
  DeleteRole: Scalars["ID"];
  /** Delete the user data related with the ID provided. */
  DeleteUser: Scalars["ID"];
  /** Delete the group data related with the ID provided. */
  DeleteGroup: Scalars["ID"];
  /** Delete the organisation data related with the ID provided. */
  DeleteOrganisation: Scalars["ID"];
  /** Delete the department data related with the ID provided. */
  DeleteDepartment: Scalars["ID"];
  /** Delete the relationships between a group and its roles assigned. */
  DeleteGroupRole: Array<GroupData>;
  /** Delete the relationships between an user and the groups belongs to. */
  DeleteUserGroup: Array<UserData>;
  /**
   * Apply the current permissions configuration to the corresponding parties that
   * compose the system.
   */
  ApplyPermissions: Scalars["String"];
  /** Create a new targets group */
  CreateTargetsGroup?: Maybe<TargetsGroupData>;
  /** Modify a targets group by id */
  UpdateTargetsGroup?: Maybe<TargetsGroupData>;
  /** Delete a targets group by id */
  DeleteTargetsGroup?: Maybe<TargetsGroupData>;
  /** Create a new target */
  CreateTarget?: Maybe<TargetData>;
  /** Modify a target by id */
  UpdateTarget?: Maybe<TargetData>;
  /** Delete a target by id */
  DeleteTarget?: Maybe<TargetData>;
  /** Sends an email to the user whom the email address given belongs to. */
  SendEmailVerification: UserData;
  /** Validates if the Email Verification code provided is valid. */
  ValidateEmailVerificationCode: Scalars["Boolean"];
  /** Updates the user password with the new one if the code is valid. */
  ResetPassword: Scalars["Boolean"];
  /** Create a new note. */
  AddNote: Array<Maybe<NoteData>>;
  /** Create a new ReferredLink */
  CreateReferredLink?: Maybe<ReferredLink>;
  /** Create a ReferredCoupon */
  createReferredCoupon: AudionautCoupon;
  /** Update the status of the ReferredLink */
  UpdateReferredStatus?: Maybe<ReferredLink>;
  UpdateSubscriptionWithACoupon: OrganisationData;
  /** Delete a RefferedLink */
  DeleteReferredLink?: Maybe<Scalars["ID"]>;
  /** Change the status of the referredLink to Used */
  UseReferredLink?: Maybe<Scalars["ID"]>;
  /** Change the referral level */
  UpdateReferralLevel: UserData;
  /** Create a new discount. */
  createAudionautCoupon: AudionautCoupon;
  /** Edit a discount. */
  editAudionautCoupon: AudionautCoupon;
  /** Delete a discount. */
  deleteAudionautCoupon: Scalars["ID"];
  /** Reduce the maximum number of available redemptions */
  useAudionautCoupon?: Maybe<AudionautCoupon>;
  /** Create a new monthly subscription plan. */
  createAudionautPlan: AudionautPlan;
  /** Edit a plan. Modifications to number parameters will be applied only to new subscriptions. */
  editAudionautPlan: AudionautPlan;
  /** Create a new billing address. */
  createBillingAddress: BillingAddress;
  /** Edit a billing address. */
  editBillingAddress: BillingAddress;
  /** Delete a billing address. */
  deleteBillingAddress: Scalars["ID"];
  /** Create a new subscription. */
  createSubscription: AudionautSubscription;
  /** Record subscription usage. */
  recordSubscriptionUsage: AudionautSubscription;
  recordSubscriptionUsageWithOrganizationId?: Maybe<AudionautSubscription>;
  payOutstandingInvoices?: Maybe<Scalars["Boolean"]>;
  cancelCurrentActiveSubscription?: Maybe<AudionautSubscription>;
  updateSubscriptionAutopay: OrganisationData;
  /** Create a new payment method. */
  createPaymentMethod: PaymentMethod;
  /** Edit a payment method. */
  editPaymentMethod: PaymentMethod;
  /** Delete a payment method. */
  deletePaymentMethod: Scalars["ID"];
  addMedia: MediaItem;
};

export type MutationAddRoleArgs = {
  name: Scalars["String"];
  description: Scalars["String"];
  visibleLevel: Scalars["Int"];
  permissionInputs?: Maybe<Array<Maybe<PermissionInput>>>;
};

export type MutationAddUserArgs = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  active: Scalars["Boolean"];
  profilePicture: Scalars["String"];
  departmentName: Scalars["String"];
  password: Scalars["String"];
  organisationID: Scalars["ID"];
  phone: Scalars["String"];
};

export type MutationAddGroupArgs = {
  name: Scalars["String"];
  description: Scalars["String"];
};

export type MutationCreateOrganizationAccountArgs = {
  email: Scalars["String"];
  name: Scalars["String"];
  phone: Scalars["String"];
  password: Scalars["String"];
  active?: Maybe<Scalars["Boolean"]>;
};

export type MutationAddOrganisationArgs = {
  name: Scalars["String"];
  email: Scalars["String"];
  audionautUnitInSec: Scalars["Int"];
  stripeCustomerId: Scalars["String"];
  esIndexName: Scalars["String"];
  esIndexAliasName: Scalars["String"];
  active: Scalars["Boolean"];
};

export type MutationAddDepartmentArgs = {
  name: Scalars["String"];
};

export type MutationAddGroupRoleArgs = {
  groupID: Scalars["ID"];
  roleID: Array<Maybe<Scalars["ID"]>>;
};

export type MutationAddUserGroupArgs = {
  userID: Scalars["ID"];
  groupID: Array<Maybe<Scalars["ID"]>>;
};

export type MutationUpdateUserOrganisationArgs = {
  userID: Scalars["ID"];
  organisationID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateUserDepartmentArgs = {
  userID: Scalars["ID"];
  departmentID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateRoleArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  visibleLevel?: Maybe<Scalars["Int"]>;
  permissionInputs?: Maybe<Array<Maybe<PermissionInput>>>;
};

export type MutationUpdateUserArgs = {
  id: Scalars["ID"];
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  departmentName?: Maybe<Scalars["String"]>;
  FindOutAudionaut?: Maybe<Scalars["String"]>;
};

export type MutationUpdateCurrentUserProfileArgs = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  FindOutAudionaut?: Maybe<Scalars["String"]>;
};

export type MutationUpdateCurrentUserProfileOrganisationArgs = {
  organisationID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateCurrentUserProfileDepartmentArgs = {
  departmentID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateGroupArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type MutationUpdateOrganisationArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
  email: Scalars["String"];
  audionautUnitInSec: Scalars["Int"];
  subscriptionAutopay: Scalars["Boolean"];
  stripeCustomerId: Scalars["String"];
  esIndexName: Scalars["String"];
  esIndexAliasName: Scalars["String"];
  active: Scalars["Boolean"];
};

export type MutationUpdateDepartmentArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type MutationDeleteRoleArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGroupArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteOrganisationArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteDepartmentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGroupRoleArgs = {
  groupID: Scalars["ID"];
  roleID: Array<Scalars["ID"]>;
};

export type MutationDeleteUserGroupArgs = {
  userID: Scalars["ID"];
  groupID: Array<Scalars["ID"]>;
};

export type MutationCreateTargetsGroupArgs = {
  name: Scalars["String"];
  editable: Scalars["Boolean"];
};

export type MutationUpdateTargetsGroupArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationDeleteTargetsGroupArgs = {
  id: Scalars["ID"];
};

export type MutationCreateTargetArgs = {
  sourceId: Scalars["String"];
  name: Scalars["String"];
  groupId: Scalars["ID"];
  source: Scalars["String"];
  sourceType: Scalars["String"];
  mediasIds?: Maybe<Scalars["String"]>;
};

export type MutationUpdateTargetArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationDeleteTargetArgs = {
  id: Scalars["ID"];
};

export type MutationSendEmailVerificationArgs = {
  userId?: Maybe<Scalars["ID"]>;
  userEmail?: Maybe<Scalars["String"]>;
};

export type MutationValidateEmailVerificationCodeArgs = {
  code: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  code: Scalars["String"];
  password: Scalars["String"];
};

export type MutationAddNoteArgs = {
  comment: Scalars["String"];
  rID: Scalars["String"];
  attachments?: Maybe<Array<NoteAttachmentFile>>;
};

export type MutationCreateReferredLinkArgs = {
  userId: Scalars["ID"];
};

export type MutationCreateReferredCouponArgs = {
  couponId: Scalars["String"];
  referralLinkId: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  percentage: Scalars["Int"];
  validTill: Scalars["String"];
  audionautPlanId: Scalars["String"];
  maxRedemptions?: Maybe<Scalars["Int"]>;
};

export type MutationUpdateReferredStatusArgs = {
  referralLinkId: Scalars["ID"];
  linkStatus: Scalars["String"];
};

export type MutationUpdateSubscriptionWithACouponArgs = {
  AudionautPlanID: Scalars["String"];
  couponID: Scalars["String"];
};

export type MutationDeleteReferredLinkArgs = {
  referralLinkId: Scalars["ID"];
};

export type MutationUseReferredLinkArgs = {
  userId: Scalars["ID"];
  referralLinkId: Scalars["ID"];
};

export type MutationUpdateReferralLevelArgs = {
  userId: Scalars["ID"];
  level: Scalars["Int"];
};

export type MutationCreateAudionautCouponArgs = {
  couponId: Scalars["String"];
  name: Scalars["String"];
  description: Scalars["String"];
  percentage: Scalars["Int"];
  validTill: Scalars["String"];
  audionautPlanId: Scalars["String"];
  maxRedemptions?: Maybe<Scalars["Int"]>;
};

export type MutationEditAudionautCouponArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationDeleteAudionautCouponArgs = {
  id: Scalars["ID"];
};

export type MutationUseAudionautCouponArgs = {
  couponId: Scalars["String"];
};

export type MutationCreateAudionautPlanArgs = {
  name: Scalars["String"];
  description: Scalars["String"];
  priceInCent: Scalars["Int"];
  pricePerUnitInCent: Scalars["Int"];
  audionautUnitInSec: Scalars["Int"];
  trialPeriodNumOfDays: Scalars["Int"];
  active: Scalars["Boolean"];
};

export type MutationEditAudionautPlanArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  priceInCent: Scalars["Int"];
  pricePerUnitInCent: Scalars["Int"];
  audionautUnitInSec: Scalars["Int"];
  trialPeriodNumOfDays: Scalars["Int"];
  active: Scalars["Boolean"];
};

export type MutationCreateBillingAddressArgs = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  address: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  state: Scalars["String"];
  country: Scalars["String"];
  countryCode: Scalars["String"];
  zipCode: Scalars["String"];
  isDefault: Scalars["Boolean"];
};

export type MutationEditBillingAddressArgs = {
  id: Scalars["ID"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  address: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  state: Scalars["String"];
  country: Scalars["String"];
  countryCode: Scalars["String"];
  zipCode: Scalars["String"];
  isDefault: Scalars["Boolean"];
};

export type MutationDeleteBillingAddressArgs = {
  id: Scalars["ID"];
};

export type MutationCreateSubscriptionArgs = {
  audionautPlanId: Scalars["String"];
  couponCode?: Maybe<Scalars["String"]>;
};

export type MutationRecordSubscriptionUsageArgs = {
  newUsageInSec: Scalars["Int"];
};

export type MutationRecordSubscriptionUsageWithOrganizationIdArgs = {
  newUsageInSec: Scalars["Int"];
  organisationId: Scalars["ID"];
};

export type MutationPayOutstandingInvoicesArgs = {
  stripePaymentMethodId?: Maybe<Scalars["String"]>;
  paymentMethodCardOwnerName?: Maybe<Scalars["String"]>;
  savePaymentMethodForFuturePayment?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateSubscriptionAutopayArgs = {
  chargeAutomatically: Scalars["Boolean"];
};

export type MutationCreatePaymentMethodArgs = {
  cardOwnerName: Scalars["String"];
  stripePaymentMethodId: Scalars["String"];
  isDefault: Scalars["Boolean"];
};

export type MutationEditPaymentMethodArgs = {
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars["ID"];
};

export type MutationAddMediaArgs = {
  url: Scalars["String"];
};

/** Represents the data of a note attachment. */
export type NoteAttachment = {
  __typename?: "NoteAttachment";
  /** Note Attachment Name. */
  name?: Maybe<Scalars["String"]>;
  /** Folder where note's attachment is stored. */
  folder?: Maybe<Scalars["String"]>;
  /** Note Attachment filename. */
  filename?: Maybe<Scalars["String"]>;
};

/** Represents the data of a note attachment. */
export type NoteAttachmentFile = {
  /** Note Attachment Path. */
  id: Scalars["Int"];
  file: Scalars["Upload"];
};

/** Represents the data of a note. */
export type NoteData = {
  __typename?: "NoteData";
  /** Note ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Note Comment. */
  comment?: Maybe<Scalars["String"]>;
  /** Ripplenami ID. */
  rID?: Maybe<Scalars["String"]>;
  /** UTC datetime when the note information was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /** Note Attachments ID. */
  attachments?: Maybe<Array<Maybe<NoteAttachment>>>;
  /** Note Attachments Author. */
  user?: Maybe<UserData>;
};

/** Represents the data of an organisation. */
export type OrganisationData = {
  __typename?: "OrganisationData";
  /** Organisation ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Organisation Alternantive ID. */
  altId?: Maybe<Scalars["ID"]>;
  /** Name of the organisation. */
  name?: Maybe<Scalars["String"]>;
  /** Email of the organisation. */
  email?: Maybe<Scalars["String"]>;
  /** Audionaut Unit of the organisation. */
  audionautUnitInSec?: Maybe<Scalars["Int"]>;
  /** Subscription Automatic Payment is enabled. */
  subscriptionAutopay?: Maybe<Scalars["Boolean"]>;
  /** Stripe Customer Id of the organisation. */
  stripeCustomerId?: Maybe<Scalars["String"]>;
  /** Elastic Search Index Name of the organisation.  */
  esIndexName?: Maybe<Scalars["String"]>;
  /** Elastic Search Index Alias Name of the organisation. */
  esIndexAliasName?: Maybe<Scalars["String"]>;
  /** Status of the organisation. */
  active?: Maybe<Scalars["Boolean"]>;
  /** Organisation's billing addresses id. */
  billingAddressesId?: Maybe<Scalars["String"]>;
  /** Organisation's billing addresses. */
  billingAddresses?: Maybe<Array<Maybe<BillingAddress>>>;
  /** Organisation's payment methods id. */
  paymentMethodsId?: Maybe<Scalars["String"]>;
  /** Organisation's payment methods. */
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Organisation's current period subscription. */
  currentPeriodSubscription?: Maybe<AudionautSubscription>;
};

export type PaymentIntent = {
  __typename?: "PaymentIntent";
  id?: Maybe<Scalars["ID"]>;
  stripePaymentIntentId?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Int"]>;
  currency?: Maybe<Scalars["String"]>;
  captureMethod?: Maybe<Scalars["String"]>;
  confirmationMethod?: Maybe<Scalars["String"]>;
  status?: Maybe<PaymentIntentStatus>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  paymentMethodsId?: Maybe<Scalars["String"]>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export enum PaymentIntentStatus {
  RequiresPaymentMethod = "requires_payment_method",
  RequiresConfirmation = "requires_confirmation",
  RequiresAction = "requires_action",
  Processing = "processing",
  RequiresCapture = "requires_capture",
  Canceled = "canceled",
  Succeeded = "succeeded",
}

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  id?: Maybe<Scalars["ID"]>;
  stripePaymentMethodId?: Maybe<Scalars["String"]>;
  cardOwnerName?: Maybe<Scalars["String"]>;
  cardLast4?: Maybe<Scalars["String"]>;
  cardBrand?: Maybe<Scalars["String"]>;
  cardExpMonth?: Maybe<Scalars["Int"]>;
  cardExpYear?: Maybe<Scalars["Int"]>;
  cardFingerprint?: Maybe<Scalars["String"]>;
  organisation?: Maybe<OrganisationData>;
  addedBy?: Maybe<UserData>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
};

export type PermissionData = {
  __typename?: "PermissionData";
  /** Permissions ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the permission. */
  name?: Maybe<Scalars["String"]>;
  /** Descrition of the permission. */
  description?: Maybe<Scalars["String"]>;
  /** Type of the permission. It can be either Application or Data permission. */
  type?: Maybe<PermissionType>;
  /** Sequence number used by the UI to display the permissions in a consecutive order. */
  order?: Maybe<Scalars["Int"]>;
  /** Enable/disable the enforcement of this permission. */
  enabled?: Maybe<Scalars["Boolean"]>;
  /** Permissions associated with this permission. */
  children?: Maybe<Array<PermissionData>>;
};

export type PermissionInput = {
  /** Permission ID */
  id?: Maybe<Scalars["ID"]>;
  /** Enable/disable the enforcement of this permission. */
  enabled?: Maybe<Scalars["Boolean"]>;
};

export enum PermissionType {
  Application = "APPLICATION",
  Data = "DATA",
}

export type Query = {
  __typename?: "Query";
  /** Retrieves a list of all roles or a specific role for the given ID. */
  GetRole: Array<Maybe<RoleData>>;
  /** Retrieves a list of all users or a specific user for the given ID, email or by organisation id. */
  GetUser: Array<UserData>;
  /** Retrieves a list of all organisations or a specific one for the given ID. */
  GetOrganisation: OrganisationData;
  /** Retrieves a list of all departments or a specific one for the given ID. */
  GetDepartment: Array<Maybe<DepartmentData>>;
  /** Retrieves a list of all groups or a specific group for the given ID. */
  GetGroup: Array<Maybe<GroupData>>;
  /** Retrieves a list of all available permissions. */
  GetPermissions: Array<Maybe<PermissionData>>;
  /** Retrieves the user profile of the logged in user. */
  GetCurrentUserProfile: UserProfile;
  /** Retrieves the organisation information of the logged in user. */
  getCurrentUserOrganisation: OrganisationData;
  /**
   * Contains the final permissions computed by the processing of all inherited permissions of the roles
   * and groups related with the logged in user.
   */
  GetCurrentUserPermissions: Array<Maybe<PermissionData>>;
  /** Retrieves the status of the ACFs generation process. */
  GetAcfsGenerationStatus: AcfsGenerationStatus;
  /** Retrieves a type of options ables to filter data */
  FilterMode?: Maybe<Array<Maybe<EventFilterMetadata>>>;
  /** Retrieves the options ables to filter data */
  EventFilterCount?: Maybe<Array<EventFilterCountData>>;
  /** Retrieves a specific targets group or all targets groups */
  GetTargetsGroups?: Maybe<Array<Maybe<TargetsGroupData>>>;
  /** Retrieves specific target by id or all targets */
  GetTargets?: Maybe<Array<Maybe<TargetData>>>;
  /** Retrieves all targets of the specifict group */
  GetTargetsByGroup?: Maybe<Array<Maybe<TargetData>>>;
  /** Retrieves target data by sourceId */
  GetTargetBySourceId?: Maybe<TargetData>;
  /** Retrieves specific audio source or all audiosources ables */
  GetAudioSources?: Maybe<Array<Maybe<AudioSourceData>>>;
  /** Get summary statistics data by a date range, by default get data with the range yesterday - today dates */
  getSummaryStatistics?: Maybe<SummaryStatistics>;
  /** Refresh user's token */
  refreshAuthenticationToken: AuthenticationData;
  /** Creates a JWT token that allows the user to log in. */
  LogIn: Scalars["String"];
  /** Send a password reset request to the email address. */
  SendPasswordResetRequest: Scalars["Boolean"];
  /** Serializes and retrieves data for a given table name. */
  ExportData: Scalars["String"];
  /** Retrieves a list of notes. */
  GetNote: Array<NoteData>;
  /** Serializes and retrieves files for the given file locations. */
  GetFile: Array<FileContent>;
  GetReferredLink?: Maybe<ReferredLink>;
  GetAllReferredLinks?: Maybe<Array<Maybe<ReferredLink>>>;
  GetReferredLinkByCode?: Maybe<ReferredLink>;
  GetMyReferrals?: Maybe<Array<Maybe<ReferredLink>>>;
  GetReferredLinkByReferredId?: Maybe<ReferredLink>;
  SendEmailReferralGift: Scalars["Boolean"];
  SendEmailReferralInvite: Scalars["Boolean"];
  GetReferralLevel: UserData;
  getAudionautCoupons?: Maybe<Array<Maybe<AudionautCoupon>>>;
  getAudionautCoupon?: Maybe<AudionautCoupon>;
  getCouponByCode?: Maybe<AudionautCoupon>;
  getAudionautPlans?: Maybe<Array<Maybe<AudionautPlan>>>;
  getAudionautPlan?: Maybe<AudionautPlan>;
  getBillingAddress?: Maybe<BillingAddress>;
  getOrganisationBillingAddress?: Maybe<Array<Maybe<BillingAddress>>>;
  getCustomerSubscriptionInfo?: Maybe<CustomerSubscriptionInfo>;
  getAccountInvoices?: Maybe<InvoiceData>;
  getAccountUsageReport?: Maybe<SubscriptionUsage>;
  getSubscriptionById?: Maybe<AudionautSubscription>;
  getMeteredSubscriptionById?: Maybe<AudionautSubscription>;
  getCurrentUserOrganisationCurrentPeriodSubscription?: Maybe<AudionautSubscription>;
  getOrganisationSubscriptionHistory?: Maybe<
    Array<Maybe<AudionautSubscription>>
  >;
  getCurrentUserOrganisationActiveMeteredSubscription?: Maybe<AudionautSubscription>;
  getCurrentUserOrganisationSubscriptionHistory?: Maybe<
    Array<Maybe<AudionautSubscription>>
  >;
  getAudionautPlanSubscriptionHistory?: Maybe<
    Array<Maybe<AudionautSubscription>>
  >;
  getPaymentMethod?: Maybe<PaymentMethod>;
  generateStripeClientSecret?: Maybe<Scalars["String"]>;
  getOrganisationPaymentMethod?: Maybe<Array<Maybe<PaymentMethod>>>;
  getMedias?: Maybe<Array<Maybe<MediaItem>>>;
};

export type QueryGetRoleArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetUserArgs = {
  id?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
  organisationID?: Maybe<Scalars["ID"]>;
};

export type QueryGetOrganisationArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetDepartmentArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetGroupArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetPermissionsArgs = {
  type?: Maybe<PermissionType>;
};

export type QueryGetCurrentUserPermissionsArgs = {
  type?: Maybe<PermissionType>;
};

export type QueryFilterModeArgs = {
  mode?: Maybe<Scalars["Int"]>;
};

export type QueryEventFilterCountArgs = {
  filter?: Maybe<Array<Maybe<Filter>>>;
};

export type QueryGetTargetsGroupsArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetTargetsArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetTargetsByGroupArgs = {
  id: Scalars["ID"];
};

export type QueryGetTargetBySourceIdArgs = {
  sourceId: Scalars["String"];
};

export type QueryGetAudioSourcesArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetSummaryStatisticsArgs = {
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
};

export type QueryRefreshAuthenticationTokenArgs = {
  refreshToken: Scalars["String"];
};

export type QueryLogInArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type QuerySendPasswordResetRequestArgs = {
  email: Scalars["String"];
};

export type QueryExportDataArgs = {
  table: MdaTable;
};

export type QueryGetNoteArgs = {
  id?: Maybe<Scalars["ID"]>;
  rID?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["String"]>;
};

export type QueryGetFileArgs = {
  fileLocations: Array<FileLocation>;
};

export type QueryGetReferredLinkArgs = {
  id: Scalars["ID"];
};

export type QueryGetReferredLinkByCodeArgs = {
  code: Scalars["String"];
};

export type QueryGetMyReferralsArgs = {
  userId: Scalars["ID"];
};

export type QueryGetReferredLinkByReferredIdArgs = {
  userId: Scalars["ID"];
};

export type QuerySendEmailReferralGiftArgs = {
  referredByEmail: Scalars["String"];
  referredName: Scalars["String"];
};

export type QuerySendEmailReferralInviteArgs = {
  userId: Scalars["ID"];
  email: Scalars["String"];
  name: Scalars["String"];
};

export type QueryGetReferralLevelArgs = {
  userId: Scalars["ID"];
};

export type QueryGetAudionautCouponArgs = {
  id: Scalars["ID"];
};

export type QueryGetCouponByCodeArgs = {
  couponId: Scalars["String"];
};

export type QueryGetAudionautPlanArgs = {
  id: Scalars["ID"];
};

export type QueryGetBillingAddressArgs = {
  id: Scalars["ID"];
};

export type QueryGetAccountUsageReportArgs = {
  startsAt?: Maybe<Scalars["String"]>;
  endsAt?: Maybe<Scalars["String"]>;
};

export type QueryGetSubscriptionByIdArgs = {
  id: Scalars["ID"];
};

export type QueryGetMeteredSubscriptionByIdArgs = {
  id: Scalars["ID"];
};

export type QueryGetCurrentUserOrganisationCurrentPeriodSubscriptionArgs = {
  orgId?: Maybe<Scalars["ID"]>;
};

export type QueryGetOrganisationSubscriptionHistoryArgs = {
  organisationId?: Maybe<Scalars["ID"]>;
  organisationAltId?: Maybe<Scalars["String"]>;
};

export type QueryGetAudionautPlanSubscriptionHistoryArgs = {
  audionautPlanId: Scalars["ID"];
};

export type QueryGetPaymentMethodArgs = {
  id: Scalars["ID"];
};

export type ReferredLink = {
  __typename?: "ReferredLink";
  id?: Maybe<Scalars["ID"]>;
  referredID?: Maybe<Scalars["String"]>;
  referred?: Maybe<UserData>;
  referredByID?: Maybe<Scalars["String"]>;
  referredBy?: Maybe<UserData>;
  ReferredByCouponID?: Maybe<Scalars["String"]>;
  ReferredByCoupon?: Maybe<AudionautCoupon>;
  createdAt?: Maybe<Scalars["String"]>;
  referralLink?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  emailSentTo?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export enum Role {
  ProductOwner = "PRODUCT_OWNER",
  EndUser = "END_USER",
}

/** Represents the data of a role. */
export type RoleData = {
  __typename?: "RoleData";
  /** Role ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the role. It is unique and could be used as another identifier. */
  name?: Maybe<Scalars["String"]>;
  /** Description of the role.  */
  description?: Maybe<Scalars["String"]>;
  /**
   * Visible Level for this role that will provides tha ability to see specific
   * designated records (Row Level Access).
   */
  visibleLevel?: Maybe<Scalars["Int"]>;
  /** UTC datetime when the role data was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /**
   * Permissions is a JSON string that specifies read and write access to the GraphQL entities
   * through their queries and mutations respectively.
   */
  permissions: Array<PermissionData>;
};

export type RoleInput = {
  /** Role ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the role. It is unique and could be used as another identifier. */
  name?: Maybe<Scalars["String"]>;
  /** Description of the role.  */
  description?: Maybe<Scalars["String"]>;
  /**
   * Visible Level for this role that will provides tha ability to see specific
   * designated records (Row Level Access).
   */
  visibleLevel?: Maybe<Scalars["Int"]>;
  /** UTC datetime when the role data was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /**
   * Permissions is a JSON string that specifies read and write access to the GraphQL entities
   * through their queries and mutations respectively.
   */
  permissions?: Maybe<Array<Maybe<PermissionInput>>>;
};

/** Represents the percents of a set of sentiments */
export type SentimentsPercent = {
  __typename?: "SentimentsPercent";
  joy?: Maybe<Scalars["String"]>;
  trust?: Maybe<Scalars["String"]>;
  surprise?: Maybe<Scalars["String"]>;
  anticipation?: Maybe<Scalars["String"]>;
  sadness?: Maybe<Scalars["String"]>;
  fear?: Maybe<Scalars["String"]>;
  anger?: Maybe<Scalars["String"]>;
  disgust?: Maybe<Scalars["String"]>;
};

export enum SubscriptionStatus {
  Active = "active",
  PastDue = "past_due",
  Unpaid = "unpaid",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  Trialing = "trialing",
}

export type SubscriptionUsage = {
  __typename?: "SubscriptionUsage";
  totalDurationOfAudioIngestedInSec?: Maybe<Scalars["Int"]>;
  totalEntitiesInTheAudioIngested?: Maybe<Scalars["Int"]>;
  totalWordsInTheAudioIngested?: Maybe<Scalars["Int"]>;
};

/** Represents the summary statictics data */
export type SummaryStatistics = {
  __typename?: "SummaryStatistics";
  audio?: Maybe<Scalars["String"]>;
  sentences?: Maybe<Scalars["String"]>;
  entities?: Maybe<Scalars["String"]>;
  Sentiments?: Maybe<AverangeData>;
  ROI?: Maybe<AverangeData>;
};

/** Represents the data of a target */
export type TargetData = {
  __typename?: "TargetData";
  /** Target id */
  id?: Maybe<Scalars["ID"]>;
  /** Target creation date */
  creationDate?: Maybe<Scalars["String"]>;
  /** Target lastest update date */
  updateDate?: Maybe<Scalars["String"]>;
  /** Target source id */
  sourceId?: Maybe<Scalars["String"]>;
  /** Target name */
  name?: Maybe<Scalars["String"]>;
  /** Target group id */
  groupId?: Maybe<Scalars["ID"]>;
  /** Media id source */
  source?: Maybe<Scalars["String"]>;
  /** Target data type */
  sourceType?: Maybe<Scalars["String"]>;
  /** Medias ids related to the behavior target */
  mediasIds?: Maybe<Scalars["String"]>;
};

/** Represents the data of a targets group. */
export type TargetsGroupData = {
  __typename?: "TargetsGroupData";
  /** Group ID */
  id?: Maybe<Scalars["ID"]>;
  /** Group creation date */
  creationDate?: Maybe<Scalars["String"]>;
  /** Group lastest update date */
  updateDate?: Maybe<Scalars["String"]>;
  /** Group name */
  name?: Maybe<Scalars["String"]>;
  /** Editable flag */
  editable?: Maybe<Scalars["Boolean"]>;
};

/** Represents the information of an user. */
export type UserData = {
  __typename?: "UserData";
  /** User ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Email of the user. It is unique and could be used as another identifier. */
  email?: Maybe<Scalars["String"]>;
  /** Firstname of the user. */
  firstName?: Maybe<Scalars["String"]>;
  /** Lastname of the user. */
  lastName?: Maybe<Scalars["String"]>;
  /** RefreshToken of the user. */
  refreshToken?: Maybe<Scalars["String"]>;
  /** Activate/deactivate the user. */
  active?: Maybe<Scalars["Boolean"]>;
  /** Profile picture of the user encoded in a base64 string. */
  profilePicture?: Maybe<Scalars["String"]>;
  /** Work contact data of the user. */
  work?: Maybe<ContactData>;
  /** UTC datetime when the password user was modified. */
  passwordModificationDate?: Maybe<Scalars["String"]>;
  /** Flag that indicates if whether or not the user has verified its email address. */
  emailVerified?: Maybe<Scalars["Boolean"]>;
  /** Login attempt counter. */
  loginAttemptCounter?: Maybe<Scalars["Int"]>;
  /** UTC datetime when the user information was last updated. */
  updated?: Maybe<Scalars["String"]>;
  /** UTC datetime when the user information was created. */
  creationDate?: Maybe<Scalars["String"]>;
  /** Groups the user belongs to. */
  groups?: Maybe<Array<Maybe<GroupData>>>;
  /** Organisation in which the user works. */
  organisation?: Maybe<OrganisationData>;
  /** Department the user belongs to. */
  department?: Maybe<DepartmentData>;
  /** Department name of user in job. */
  departmentName?: Maybe<Scalars["String"]>;
  /** Current user password */
  password?: Maybe<Scalars["String"]>;
  /** flag that indicates if  the user is new or not. */
  firstLogin?: Maybe<Scalars["Boolean"]>;
  /** Phone of the contact data. */
  phone?: Maybe<Scalars["String"]>;
  /** Referral level data */
  referralLevel?: Maybe<Scalars["Int"]>;
  /** FindOutAudionaut field to save a user comment of how find out audionaut. */
  FindOutAudionaut?: Maybe<Scalars["String"]>;
};

/** Represents the information of an user. */
export type UserProfile = {
  __typename?: "UserProfile";
  /** User ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Email of the user. It is unique and could be used as another identifier. */
  email?: Maybe<Scalars["String"]>;
  /** Firstname of the user. */
  firstName?: Maybe<Scalars["String"]>;
  /** Lastname of the user. */
  lastName?: Maybe<Scalars["String"]>;
  /** Profile picture of the user encoded in a base64 string. */
  profilePicture?: Maybe<Scalars["String"]>;
  /** Groups the user belongs to. */
  groups?: Maybe<Array<Maybe<GroupData>>>;
  /** Work contact data of the user. */
  work?: Maybe<ContactData>;
  /** Organisation in which the user works. */
  organisation?: Maybe<OrganisationData>;
  /** Department the user belongs to. */
  department?: Maybe<DepartmentData>;
  /** department name the user belongs to. */
  departmentName?: Maybe<Scalars["String"]>;
  /** flag that indicates if  the user is new or not. */
  firstLogin?: Maybe<Scalars["Boolean"]>;
  /** Phone of the contact data. */
  phone?: Maybe<Scalars["String"]>;
  /** Referral level data */
  referralLevel?: Maybe<Scalars["Int"]>;
  /** FindOutAudionaut field to save a user comment of how find out audionaut. */
  FindOutAudionaut?: Maybe<Scalars["String"]>;
};

export type TargetsGroupFragmentFragment = {
  __typename?: "TargetsGroupData";
} & Pick<
  TargetsGroupData,
  "id" | "creationDate" | "updateDate" | "name" | "editable"
>;

export type TargetFragmentFragment = { __typename?: "TargetData" } & Pick<
  TargetData,
  | "id"
  | "creationDate"
  | "updateDate"
  | "sourceId"
  | "name"
  | "groupId"
  | "source"
  | "sourceType"
  | "mediasIds"
>;

export type AudioSourceFragmentFragment = {
  __typename?: "AudioSourceData";
} & Pick<AudioSourceData, "id" | "name" | "logo">;

export type SubscriptionUsageFragmentFragment = {
  __typename?: "SubscriptionUsage";
} & Pick<
  SubscriptionUsage,
  | "totalDurationOfAudioIngestedInSec"
  | "totalEntitiesInTheAudioIngested"
  | "totalWordsInTheAudioIngested"
>;

export type SummaryStatisticsFragmentFragment = {
  __typename?: "SummaryStatistics";
} & Pick<SummaryStatistics, "audio" | "sentences" | "entities"> & {
    Sentiments?: Maybe<
      { __typename?: "AverangeData" } & Pick<
        AverangeData,
        "total" | "sentences"
      > & {
          sentiments?: Maybe<
            { __typename?: "SentimentsPercent" } & Pick<
              SentimentsPercent,
              | "joy"
              | "trust"
              | "surprise"
              | "anticipation"
              | "sadness"
              | "fear"
              | "anger"
              | "disgust"
            >
          >;
        }
    >;
    ROI?: Maybe<
      { __typename?: "AverangeData" } & Pick<
        AverangeData,
        "total" | "sentences"
      > & {
          sentiments?: Maybe<
            { __typename?: "SentimentsPercent" } & Pick<
              SentimentsPercent,
              | "joy"
              | "trust"
              | "surprise"
              | "anticipation"
              | "sadness"
              | "fear"
              | "anger"
              | "disgust"
            >
          >;
        }
    >;
  };

export type GetAudioSourcesQueryVariables = Exact<{
  audioSourceId: Scalars["ID"];
}>;

export type GetAudioSourcesQuery = { __typename?: "Query" } & {
  GetAudioSources?: Maybe<
    Array<
      Maybe<{ __typename?: "AudioSourceData" } & AudioSourceFragmentFragment>
    >
  >;
};

export type GetAllTargetsGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTargetsGroupsQuery = { __typename?: "Query" } & {
  GetTargetsGroups?: Maybe<
    Array<
      Maybe<{ __typename?: "TargetsGroupData" } & TargetsGroupFragmentFragment>
    >
  >;
};

export type GetTargetsQueryVariables = Exact<{
  targetId: Scalars["ID"];
}>;

export type GetTargetsQuery = { __typename?: "Query" } & {
  GetTargets?: Maybe<
    Array<Maybe<{ __typename?: "TargetData" } & TargetFragmentFragment>>
  >;
};

export type GetGroupTargetsByIdQueryVariables = Exact<{
  groupId: Scalars["ID"];
}>;

export type GetGroupTargetsByIdQuery = { __typename?: "Query" } & {
  GetTargetsByGroup?: Maybe<
    Array<Maybe<{ __typename?: "TargetData" } & TargetFragmentFragment>>
  >;
};

export type GetTargetBySourceIdQueryVariables = Exact<{
  sourceId: Scalars["String"];
}>;

export type GetTargetBySourceIdQuery = { __typename?: "Query" } & {
  GetTargetBySourceId?: Maybe<
    { __typename?: "TargetData" } & TargetFragmentFragment
  >;
};

export type GetAccountUsageReportQueryVariables = Exact<{
  startsAt?: Maybe<Scalars["String"]>;
  endsAt?: Maybe<Scalars["String"]>;
}>;

export type GetAccountUsageReportQuery = { __typename?: "Query" } & {
  getAccountUsageReport?: Maybe<
    { __typename?: "SubscriptionUsage" } & SubscriptionUsageFragmentFragment
  >;
};

export type GetSummaryStatisticsQueryVariables = Exact<{
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
}>;

export type GetSummaryStatisticsQuery = { __typename?: "Query" } & {
  getSummaryStatistics?: Maybe<
    { __typename?: "SummaryStatistics" } & SummaryStatisticsFragmentFragment
  >;
};

export type CreateTargetsGroupMutationVariables = Exact<{
  nameGroup: Scalars["String"];
  editable: Scalars["Boolean"];
}>;

export type CreateTargetsGroupMutation = { __typename?: "Mutation" } & {
  CreateTargetsGroup?: Maybe<
    { __typename?: "TargetsGroupData" } & TargetsGroupFragmentFragment
  >;
};

export type UpdateTargetsGroupMutationVariables = Exact<{
  groupId: Scalars["ID"];
  groupName: Scalars["String"];
}>;

export type UpdateTargetsGroupMutation = { __typename?: "Mutation" } & {
  UpdateTargetsGroup?: Maybe<
    { __typename?: "TargetsGroupData" } & TargetsGroupFragmentFragment
  >;
};

export type DeleteTargetsGroupMutationVariables = Exact<{
  groupId: Scalars["ID"];
}>;

export type DeleteTargetsGroupMutation = { __typename?: "Mutation" } & {
  DeleteTargetsGroup?: Maybe<
    { __typename?: "TargetsGroupData" } & TargetsGroupFragmentFragment
  >;
};

export type CreateTargetMutationVariables = Exact<{
  sourceId: Scalars["String"];
  targetName: Scalars["String"];
  groupId: Scalars["ID"];
  source: Scalars["String"];
  type: Scalars["String"];
  mediasIds: Scalars["String"];
}>;

export type CreateTargetMutation = { __typename?: "Mutation" } & {
  CreateTarget?: Maybe<{ __typename?: "TargetData" } & TargetFragmentFragment>;
};

export type UpdateTargetMutationVariables = Exact<{
  targetId: Scalars["ID"];
  targetName: Scalars["String"];
}>;

export type UpdateTargetMutation = { __typename?: "Mutation" } & {
  UpdateTarget?: Maybe<{ __typename?: "TargetData" } & TargetFragmentFragment>;
};

export type DeleteTargetMutationVariables = Exact<{
  targetId: Scalars["ID"];
}>;

export type DeleteTargetMutation = { __typename?: "Mutation" } & {
  DeleteTarget?: Maybe<{ __typename?: "TargetData" } & TargetFragmentFragment>;
};

export type AudionautPlanFragmentFragment = {
  __typename?: "AudionautPlan";
} & Pick<
  AudionautPlan,
  | "id"
  | "name"
  | "description"
  | "priceInCent"
  | "audionautUnitInSec"
  | "pricePerUnitInCent"
  | "trialPeriodNumOfDays"
  | "stripeProductId"
  | "stripePriceId"
  | "stripeFlatPriceId"
  | "active"
  | "couponId"
  | "createdAt"
  | "updatedAt"
> & {
    activeCoupons?: Maybe<
      Array<
        Maybe<
          { __typename?: "AudionautCoupon" } & AudionautCouponFragmentFragment
        >
      >
    >;
  };

export type AudionautPlanSimplifyFragmentFragment = {
  __typename?: "AudionautPlan";
} & Pick<AudionautPlan, "id" | "name">;

export type AudionautCouponFragmentFragment = {
  __typename?: "AudionautCoupon";
} & Pick<
  AudionautCoupon,
  | "id"
  | "name"
  | "description"
  | "percentage"
  | "stripeCouponId"
  | "validTill"
  | "forAudionautPlanId"
  | "maxRedemptions"
  | "createdAt"
  | "updatedAt"
> & {
    forAudionautPlan?: Maybe<
      { __typename?: "AudionautPlan" } & AudionautPlanSimplifyFragmentFragment
    >;
  };

export type GetAudionautPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetAudionautPlansQuery = { __typename?: "Query" } & {
  getAudionautPlans?: Maybe<
    Array<
      Maybe<{ __typename?: "AudionautPlan" } & AudionautPlanFragmentFragment>
    >
  >;
};

export type GetStripeCouponQueryVariables = Exact<{
  coupon: Scalars["String"];
}>;

export type GetStripeCouponQuery = { __typename?: "Query" } & {
  getCouponByCode?: Maybe<
    { __typename?: "AudionautCoupon" } & AudionautCouponFragmentFragment
  >;
};

export type CreateAudionautPlanMutationVariables = Exact<{
  name: Scalars["String"];
  description: Scalars["String"];
  priceInCent: Scalars["Int"];
  pricePerUnitInCent: Scalars["Int"];
  audionautUnitInSec: Scalars["Int"];
  trialPeriodNumOfDays: Scalars["Int"];
  active: Scalars["Boolean"];
}>;

export type CreateAudionautPlanMutation = { __typename?: "Mutation" } & {
  createAudionautPlan: {
    __typename?: "AudionautPlan";
  } & AudionautPlanFragmentFragment;
};

export type EditAudionautPlanByIdMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  priceInCent: Scalars["Int"];
  pricePerUnitInCent: Scalars["Int"];
  audionautUnitInSec: Scalars["Int"];
  trialPeriodNumOfDays: Scalars["Int"];
  active: Scalars["Boolean"];
}>;

export type EditAudionautPlanByIdMutation = { __typename?: "Mutation" } & {
  editAudionautPlan: {
    __typename?: "AudionautPlan";
  } & AudionautPlanFragmentFragment;
};

export type AudionautSubscriptionFragmentFragment = {
  __typename?: "AudionautSubscription";
} & Pick<
  AudionautSubscription,
  | "id"
  | "startsAt"
  | "endsAt"
  | "stripeSubscriptionId"
  | "stripeMeteredSubscriptionItemId"
  | "audionautPlanId"
  | "organisationId"
  | "status"
  | "createdAt"
  | "updatedAt"
> & {
    audionautPlan?: Maybe<
      { __typename?: "AudionautPlan" } & AudionautPlanFragmentFragment
    >;
  };

export type PaymentIntentFragmentFragment = {
  __typename?: "PaymentIntent";
} & Pick<
  PaymentIntent,
  | "id"
  | "stripePaymentIntentId"
  | "amount"
  | "currency"
  | "captureMethod"
  | "confirmationMethod"
  | "status"
  | "createdAt"
  | "updatedAt"
> & {
    paymentMethods?: Maybe<
      Array<
        Maybe<
          { __typename?: "PaymentMethod" } & Pick<
            PaymentMethod,
            | "id"
            | "stripePaymentMethodId"
            | "cardLast4"
            | "cardBrand"
            | "cardExpMonth"
            | "cardExpYear"
            | "cardFingerprint"
            | "isDefault"
            | "cardOwnerName"
            | "updatedAt"
            | "createdAt"
          >
        >
      >
    >;
  };

export type InvoicesFragmentFragment = { __typename?: "Invoice" } & Pick<
  Invoice,
  | "id"
  | "stripeInvoiceId"
  | "stripeSubscriptionId"
  | "amountDue"
  | "amountPaid"
  | "total"
  | "tax"
  | "isAttempted"
  | "currency"
  | "status"
  | "createdAt"
  | "updatedAt"
> & {
    lines?: Maybe<
      Array<
        Maybe<
          { __typename?: "InvoiceLine" } & Pick<
            InvoiceLine,
            | "id"
            | "stripeInvoiceLineId"
            | "amount"
            | "currency"
            | "description"
            | "quantity"
          >
        >
      >
    >;
    paymentIntent?: Maybe<
      { __typename?: "PaymentIntent" } & PaymentIntentFragmentFragment
    >;
  };

export type GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryVariables =
  Exact<{ [key: string]: never }>;

export type GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery = {
  __typename?: "Query";
} & {
  getCurrentUserOrganisationCurrentPeriodSubscription?: Maybe<
    {
      __typename?: "AudionautSubscription";
    } & AudionautSubscriptionFragmentFragment
  >;
};

export type GetSubscriptionHistoryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSubscriptionHistoryQuery = { __typename?: "Query" } & {
  getCurrentUserOrganisationSubscriptionHistory?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "AudionautSubscription";
        } & AudionautSubscriptionFragmentFragment
      >
    >
  >;
};

export type CancelCurrentSubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelCurrentSubscriptionMutation = { __typename?: "Mutation" } & {
  cancelCurrentActiveSubscription?: Maybe<
    {
      __typename?: "AudionautSubscription";
    } & AudionautSubscriptionFragmentFragment
  >;
};

export type GetAllInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllInvoicesQuery = { __typename?: "Query" } & {
  getAccountInvoices?: Maybe<
    { __typename?: "InvoiceData" } & Pick<InvoiceData, "amountOwed"> & {
        invoices?: Maybe<
          Array<Maybe<{ __typename?: "Invoice" } & InvoicesFragmentFragment>>
        >;
      }
  >;
};

export type GetCurrentSubscriptionInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentSubscriptionInfoQuery = { __typename?: "Query" } & {
  getCustomerSubscriptionInfo?: Maybe<
    { __typename?: "CustomerSubscriptionInfo" } & Pick<
      CustomerSubscriptionInfo,
      | "audionautUnitsLeft"
      | "hasActiveMeteredSubscription"
      | "hasDefaultPaymentMethod"
    >
  >;
};

export type PayOutstandingInvoiceMutationVariables = Exact<{
  methodId?: Maybe<Scalars["String"]>;
  ownerName?: Maybe<Scalars["String"]>;
  default?: Maybe<Scalars["Boolean"]>;
}>;

export type PayOutstandingInvoiceMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "payOutstandingInvoices"
>;

export type CreatePlanSubscriptionWithCouponMutationVariables = Exact<{
  planId: Scalars["String"];
  coupon?: Maybe<Scalars["String"]>;
}>;

export type CreatePlanSubscriptionWithCouponMutation = {
  __typename?: "Mutation";
} & {
  createSubscription: {
    __typename?: "AudionautSubscription";
  } & AudionautSubscriptionFragmentFragment;
};

export type CreatePlanSubscriptionMutationVariables = Exact<{
  planId: Scalars["String"];
}>;

export type CreatePlanSubscriptionMutation = { __typename?: "Mutation" } & {
  createSubscription: {
    __typename?: "AudionautSubscription";
  } & AudionautSubscriptionFragmentFragment;
};

export type ChangeAutoPayStatusMutationVariables = Exact<{
  status: Scalars["Boolean"];
}>;

export type ChangeAutoPayStatusMutation = { __typename?: "Mutation" } & {
  updateSubscriptionAutopay: {
    __typename?: "OrganisationData";
  } & OrganisationDataFragmentFragment;
};

export type ValidateEmailVerificationCodeMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type ValidateEmailVerificationCodeMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "ValidateEmailVerificationCode">;

export type SendEmailVerificationMutationVariables = Exact<{
  userId?: Maybe<Scalars["ID"]>;
  userEmail?: Maybe<Scalars["String"]>;
}>;

export type SendEmailVerificationMutation = { __typename?: "Mutation" } & {
  SendEmailVerification: { __typename?: "UserData" } & UserFragmentFragment;
};

export type LogInQueryVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LogInQuery = { __typename?: "Query" } & Pick<Query, "LogIn">;

export type SendPasswordResetRequestQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendPasswordResetRequestQuery = { __typename?: "Query" } & Pick<
  Query,
  "SendPasswordResetRequest"
>;

export type ResetPasswordMutationVariables = Exact<{
  code: Scalars["String"];
  password: Scalars["String"];
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "ResetPassword"
>;

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars["String"];
  email: Scalars["String"];
  phone: Scalars["String"];
  password: Scalars["String"];
}>;

export type CreateOrganizationMutation = { __typename?: "Mutation" } & {
  createOrganizationAccount: { __typename?: "UserData" } & UserFragmentFragment;
};

export type BillingAddressFragmentFragment = {
  __typename?: "BillingAddress";
} & Pick<
  BillingAddress,
  | "id"
  | "firstName"
  | "lastName"
  | "address"
  | "addressLine2"
  | "zipCode"
  | "city"
  | "state"
  | "country"
  | "countryCode"
  | "isDefault"
  | "updatedAt"
  | "createdAt"
>;

export type CreateBillingAddressMutationVariables = Exact<{
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  isDefault: Scalars["Boolean"];
  address: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  state: Scalars["String"];
  country: Scalars["String"];
  countryCode: Scalars["String"];
  zipCode: Scalars["String"];
}>;

export type CreateBillingAddressMutation = { __typename?: "Mutation" } & {
  createBillingAddress: {
    __typename?: "BillingAddress";
  } & BillingAddressFragmentFragment;
};

export type EditBillingAddressMutationVariables = Exact<{
  id: Scalars["ID"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  isDefault: Scalars["Boolean"];
  address: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  state: Scalars["String"];
  country: Scalars["String"];
  countryCode: Scalars["String"];
  zipCode: Scalars["String"];
}>;

export type EditBillingAddressMutation = { __typename?: "Mutation" } & {
  editBillingAddress: {
    __typename?: "BillingAddress";
  } & BillingAddressFragmentFragment;
};

export type DeleteBillingAddressMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteBillingAddressMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteBillingAddress"
>;

export type ReferredLinkFragment = { __typename?: "ReferredLink" } & Pick<
  ReferredLink,
  | "id"
  | "referredID"
  | "referredByID"
  | "ReferredByCouponID"
  | "createdAt"
  | "referralLink"
  | "code"
  | "emailSentTo"
  | "status"
> & {
    referred?: Maybe<{ __typename?: "UserData" } & UserFragmentFragment>;
    referredBy?: Maybe<{ __typename?: "UserData" } & UserFragmentFragment>;
    ReferredByCoupon?: Maybe<
      { __typename?: "AudionautCoupon" } & AudionautCouponFragmentFragment
    >;
  };

export type GetAllReferralLinksQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllReferralLinksQuery = { __typename?: "Query" } & {
  GetAllReferredLinks?: Maybe<
    Array<Maybe<{ __typename?: "ReferredLink" } & ReferredLinkFragment>>
  >;
};

export type GetReferralLinkByCodeQueryVariables = Exact<{
  code: Scalars["String"];
}>;

export type GetReferralLinkByCodeQuery = { __typename?: "Query" } & {
  GetReferredLinkByCode?: Maybe<
    { __typename?: "ReferredLink" } & ReferredLinkFragment
  >;
};

export type GetReferredLinkByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetReferredLinkByIdQuery = { __typename?: "Query" } & {
  GetReferredLink?: Maybe<
    { __typename?: "ReferredLink" } & ReferredLinkFragment
  >;
};

export type GetReferredLinksByUserIdQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetReferredLinksByUserIdQuery = { __typename?: "Query" } & {
  GetMyReferrals?: Maybe<
    Array<Maybe<{ __typename?: "ReferredLink" } & ReferredLinkFragment>>
  >;
};

export type SendReferralInviteQueryVariables = Exact<{
  userId: Scalars["ID"];
  email: Scalars["String"];
  name: Scalars["String"];
}>;

export type SendReferralInviteQuery = { __typename?: "Query" } & Pick<
  Query,
  "SendEmailReferralInvite"
>;

export type SensReferalRewardEmailQueryVariables = Exact<{
  email: Scalars["String"];
  name: Scalars["String"];
}>;

export type SensReferalRewardEmailQuery = { __typename?: "Query" } & Pick<
  Query,
  "SendEmailReferralGift"
>;

export type GetReferralLevelQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetReferralLevelQuery = { __typename?: "Query" } & {
  GetReferralLevel: { __typename?: "UserData" } & UserFragmentFragment;
};

export type SetReferralLinkMutationVariables = Exact<{
  userId: Scalars["ID"];
  referralLinkId: Scalars["ID"];
}>;

export type SetReferralLinkMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "UseReferredLink"
>;

export type CreateRewardCouponMutationVariables = Exact<{
  couponId: Scalars["String"];
  referralLinkId: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  percentage: Scalars["Int"];
  validTill: Scalars["String"];
  audionautPlanId: Scalars["String"];
  maxRedemptions?: Maybe<Scalars["Int"]>;
}>;

export type CreateRewardCouponMutation = { __typename?: "Mutation" } & {
  createReferredCoupon: {
    __typename?: "AudionautCoupon";
  } & AudionautCouponFragmentFragment;
};

export type UpdateSubscriptionWithCouponMutationVariables = Exact<{
  planId: Scalars["String"];
  couponId: Scalars["String"];
}>;

export type UpdateSubscriptionWithCouponMutation = {
  __typename?: "Mutation";
} & {
  UpdateSubscriptionWithACoupon: {
    __typename?: "OrganisationData";
  } & OrganisationDataFragmentFragment;
};

export type DeleteReferralLinkMutationVariables = Exact<{
  referralLinkId: Scalars["ID"];
}>;

export type DeleteReferralLinkMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "DeleteReferredLink"
>;

export type UpdateReferralLevelMutationVariables = Exact<{
  userId: Scalars["ID"];
  percentage: Scalars["Int"];
}>;

export type UpdateReferralLevelMutation = { __typename?: "Mutation" } & {
  UpdateReferralLevel: { __typename?: "UserData" } & UserFragmentFragment;
};

export type UpdateReferredStatusMutationVariables = Exact<{
  referralLinkId: Scalars["ID"];
  status: Scalars["String"];
}>;

export type UpdateReferredStatusMutation = { __typename?: "Mutation" } & {
  UpdateReferredStatus?: Maybe<
    { __typename?: "ReferredLink" } & ReferredLinkFragment
  >;
};

export type NoteFragmentFragment = { __typename?: "NoteData" } & Pick<
  NoteData,
  "id" | "comment" | "rID" | "creationDate"
> & {
    attachments?: Maybe<
      Array<
        Maybe<
          { __typename?: "NoteAttachment" } & Pick<
            NoteAttachment,
            "name" | "folder" | "filename"
          >
        >
      >
    >;
    user?: Maybe<{ __typename?: "UserData" } & UserFragmentFragment>;
  };

export type GetNoteQueryVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  rID?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["String"]>;
}>;

export type GetNoteQuery = { __typename?: "Query" } & {
  GetNote: Array<{ __typename?: "NoteData" } & NoteFragmentFragment>;
};

export type AddNoteMutationVariables = Exact<{
  comment: Scalars["String"];
  rID: Scalars["String"];
  attachments?: Maybe<Array<NoteAttachmentFile> | NoteAttachmentFile>;
}>;

export type AddNoteMutation = { __typename?: "Mutation" } & {
  AddNote: Array<Maybe<{ __typename?: "NoteData" } & NoteFragmentFragment>>;
};

export type GetFileQueryVariables = Exact<{
  folder: Scalars["String"];
  filename: Scalars["String"];
}>;

export type GetFileQuery = { __typename?: "Query" } & {
  GetFile: Array<
    { __typename?: "FileContent" } & Pick<FileContent, "filename" | "content">
  >;
};

export type OrganisationDataFragmentFragment = {
  __typename?: "OrganisationData";
} & Pick<
  OrganisationData,
  | "id"
  | "name"
  | "email"
  | "audionautUnitInSec"
  | "stripeCustomerId"
  | "esIndexName"
  | "esIndexAliasName"
  | "active"
  | "billingAddressesId"
  | "paymentMethodsId"
  | "subscriptionAutopay"
> & {
    currentPeriodSubscription?: Maybe<
      {
        __typename?: "AudionautSubscription";
      } & AudionautSubscriptionFragmentFragment
    >;
    billingAddresses?: Maybe<
      Array<
        Maybe<
          { __typename?: "BillingAddress" } & BillingAddressFragmentFragment
        >
      >
    >;
    paymentMethods?: Maybe<
      Array<
        Maybe<{ __typename?: "PaymentMethod" } & PaymentMethodFragmentFragment>
      >
    >;
  };

export type GetCurrentUserOrganisationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserOrganisationQuery = { __typename?: "Query" } & {
  getCurrentUserOrganisation: {
    __typename?: "OrganisationData";
  } & OrganisationDataFragmentFragment;
};

export type PaymentMethodFragmentFragment = {
  __typename?: "PaymentMethod";
} & Pick<
  PaymentMethod,
  | "id"
  | "stripePaymentMethodId"
  | "cardLast4"
  | "cardBrand"
  | "cardExpMonth"
  | "cardExpYear"
  | "cardFingerprint"
  | "isDefault"
  | "createdAt"
  | "updatedAt"
> & {
    organisation?: Maybe<
      { __typename?: "OrganisationData" } & Pick<
        OrganisationData,
        "id" | "name" | "email"
      >
    >;
  };

export type GenerateStripeClientSecretQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GenerateStripeClientSecretQuery = { __typename?: "Query" } & Pick<
  Query,
  "generateStripeClientSecret"
>;

export type CreatePaymentMethodMutationVariables = Exact<{
  stripePaymentMethodId: Scalars["String"];
  isDefault: Scalars["Boolean"];
  holderName: Scalars["String"];
}>;

export type CreatePaymentMethodMutation = { __typename?: "Mutation" } & {
  createPaymentMethod: {
    __typename?: "PaymentMethod";
  } & PaymentMethodFragmentFragment;
};

export type EditPaymentMethodMutationVariables = Exact<{
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
}>;

export type EditPaymentMethodMutation = { __typename?: "Mutation" } & {
  editPaymentMethod: {
    __typename?: "PaymentMethod";
  } & PaymentMethodFragmentFragment;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeletePaymentMethodMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deletePaymentMethod"
>;

export type PermissionFragmentFragment = {
  __typename?: "PermissionData";
} & Pick<
  PermissionData,
  "id" | "name" | "description" | "type" | "order" | "enabled"
> & {
    children?: Maybe<
      Array<
        { __typename?: "PermissionData" } & Pick<
          PermissionData,
          "id" | "name" | "description" | "type" | "order" | "enabled"
        > & {
            children?: Maybe<
              Array<
                { __typename?: "PermissionData" } & Pick<
                  PermissionData,
                  "id" | "name" | "description" | "type" | "order" | "enabled"
                >
              >
            >;
          }
      >
    >;
  };

export type RoleFragmentFragment = { __typename?: "RoleData" } & Pick<
  RoleData,
  "id" | "name" | "description" | "visibleLevel" | "creationDate"
> & {
    permissions: Array<
      { __typename?: "PermissionData" } & PermissionFragmentFragment
    >;
  };

export type GroupFragmentFragment = { __typename?: "GroupData" } & Pick<
  GroupData,
  "id" | "name" | "description" | "creationDate"
> & {
    roles?: Maybe<
      Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>
    >;
  };

export type UserFragmentFragment = { __typename?: "UserData" } & Pick<
  UserData,
  | "id"
  | "email"
  | "firstName"
  | "lastName"
  | "active"
  | "profilePicture"
  | "updated"
  | "creationDate"
  | "passwordModificationDate"
  | "emailVerified"
  | "departmentName"
  | "password"
  | "phone"
  | "FindOutAudionaut"
  | "referralLevel"
> & {
    work?: Maybe<
      { __typename?: "ContactData" } & Pick<ContactData, "address" | "phone">
    >;
    organisation?: Maybe<
      { __typename?: "OrganisationData" } & Pick<
        OrganisationData,
        "id" | "name"
      >
    >;
    department?: Maybe<
      { __typename?: "DepartmentData" } & Pick<DepartmentData, "id" | "name">
    >;
    groups?: Maybe<
      Array<Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>>
    >;
  };

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermissionsQuery = { __typename?: "Query" } & {
  GetPermissions: Array<
    Maybe<{ __typename?: "PermissionData" } & PermissionFragmentFragment>
  >;
};

export type GetUserProfileQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type GetUserProfileQuery = { __typename?: "Query" } & {
  GetUser: Array<{ __typename?: "UserData" } & UserFragmentFragment>;
};

export type GetCurrentUserProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserProfileQuery = { __typename?: "Query" } & {
  GetCurrentUserProfile: { __typename?: "UserProfile" } & Pick<
    UserProfile,
    | "id"
    | "email"
    | "firstName"
    | "lastName"
    | "profilePicture"
    | "phone"
    | "departmentName"
    | "FindOutAudionaut"
  > & {
      groups?: Maybe<
        Array<Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>>
      >;
      work?: Maybe<
        { __typename?: "ContactData" } & Pick<ContactData, "address" | "phone">
      >;
      organisation?: Maybe<
        { __typename?: "OrganisationData" } & Pick<
          OrganisationData,
          "id" | "name"
        >
      >;
      department?: Maybe<
        { __typename?: "DepartmentData" } & Pick<DepartmentData, "id" | "name">
      >;
    };
};

export type GetDepartmentQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepartmentQuery = { __typename?: "Query" } & {
  GetDepartment: Array<
    Maybe<
      { __typename?: "DepartmentData" } & Pick<DepartmentData, "id" | "name">
    >
  >;
};

export type GetOrganisationQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganisationQuery = { __typename?: "Query" } & {
  GetOrganisation: { __typename?: "OrganisationData" } & Pick<
    OrganisationData,
    "id" | "name"
  >;
};

export type UpdateUserDataMutationVariables = Exact<{
  id: Scalars["ID"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  departmentName?: Maybe<Scalars["String"]>;
  FindOutAudionaut?: Maybe<Scalars["String"]>;
}>;

export type UpdateUserDataMutation = { __typename?: "Mutation" } & {
  UpdateUser: Array<Maybe<{ __typename?: "UserData" } & UserFragmentFragment>>;
};

export type UpdateUserOrganisationMutationVariables = Exact<{
  userID: Scalars["ID"];
  organisationID?: Maybe<Scalars["ID"]>;
}>;

export type UpdateUserOrganisationMutation = { __typename?: "Mutation" } & {
  UpdateUserOrganisation: Array<
    Maybe<{ __typename?: "UserData" } & UserFragmentFragment>
  >;
};

export type UpdateUserDeparmentMutationVariables = Exact<{
  userID: Scalars["ID"];
  departmentID?: Maybe<Scalars["ID"]>;
}>;

export type UpdateUserDeparmentMutation = { __typename?: "Mutation" } & {
  UpdateUserDepartment: Array<
    Maybe<{ __typename?: "UserData" } & Pick<UserData, "id">>
  >;
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  active: Scalars["Boolean"];
  profilePicture: Scalars["String"];
  departmentName: Scalars["String"];
  password: Scalars["String"];
  organisationID: Scalars["ID"];
  phone: Scalars["String"];
}>;

export type CreateUserMutation = { __typename?: "Mutation" } & {
  AddUser: Array<Maybe<{ __typename?: "UserData" } & UserFragmentFragment>>;
};

export type DeleteUserMutationVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type DeleteUserMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "DeleteUser"
>;

export type AddUserGroupMutationVariables = Exact<{
  userID: Scalars["ID"];
  groupID: Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>;
}>;

export type AddUserGroupMutation = { __typename?: "Mutation" } & {
  AddUserGroup: Array<
    Maybe<{ __typename?: "UserData" } & UserFragmentFragment>
  >;
};

export type UpdateUserGroupMutationVariables = Exact<{
  userID: Scalars["ID"];
  groupID: Array<Scalars["ID"]> | Scalars["ID"];
  groupIDNew: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UpdateUserGroupMutation = { __typename?: "Mutation" } & {
  DeleteUserGroup: Array<{ __typename?: "UserData" } & UserFragmentFragment>;
  AddUserGroup: Array<
    Maybe<{ __typename?: "UserData" } & UserFragmentFragment>
  >;
};

export type DeleteUserGroupMutationVariables = Exact<{
  userID: Scalars["ID"];
  groupID: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteUserGroupMutation = { __typename?: "Mutation" } & {
  DeleteUserGroup: Array<{ __typename?: "UserData" } & UserFragmentFragment>;
};

export type GetGroupQueryVariables = Exact<{ [key: string]: never }>;

export type GetGroupQuery = { __typename?: "Query" } & {
  GetGroup: Array<Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>>;
};

export type GetAllUsersQueryVariables = Exact<{
  organisationID: Scalars["ID"];
}>;

export type GetAllUsersQuery = { __typename?: "Query" } & {
  GetUser: Array<{ __typename?: "UserData" } & UserFragmentFragment>;
};

export type AddRoleMutationVariables = Exact<{
  name: Scalars["String"];
  description: Scalars["String"];
  visibleLevel: Scalars["Int"];
  permissionInputs?: Maybe<
    Array<Maybe<PermissionInput>> | Maybe<PermissionInput>
  >;
}>;

export type AddRoleMutation = { __typename?: "Mutation" } & {
  AddRole: Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>;
};

export type GetRoleQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoleQuery = { __typename?: "Query" } & {
  GetRole: Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>;
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  visibleLevel: Scalars["Int"];
  permissionInputs?: Maybe<
    Array<Maybe<PermissionInput>> | Maybe<PermissionInput>
  >;
}>;

export type UpdateRoleMutation = { __typename?: "Mutation" } & {
  UpdateRole: Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>;
};

export type UpdatePermissionMutationVariables = Exact<{
  roleId: Scalars["ID"];
  permissionInputs?: Maybe<
    Array<Maybe<PermissionInput>> | Maybe<PermissionInput>
  >;
}>;

export type UpdatePermissionMutation = { __typename?: "Mutation" } & {
  UpdateRole: Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>;
};

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteRoleMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "DeleteRole"
>;

export type AddGroupMutationVariables = Exact<{
  name: Scalars["String"];
  description: Scalars["String"];
}>;

export type AddGroupMutation = { __typename?: "Mutation" } & {
  AddGroup: Array<Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>>;
};

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
}>;

export type UpdateGroupMutation = { __typename?: "Mutation" } & {
  UpdateGroup: Array<
    Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>
  >;
};

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteGroupMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "DeleteGroup"
>;

export type AddGroupRoleMutationVariables = Exact<{
  groupID: Scalars["ID"];
  roleID: Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>;
}>;

export type AddGroupRoleMutation = { __typename?: "Mutation" } & {
  AddGroupRole: Array<
    Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>
  >;
};

export type DeleteGroupRoleMutationVariables = Exact<{
  groupID: Scalars["ID"];
  roleID: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteGroupRoleMutation = { __typename?: "Mutation" } & {
  DeleteGroupRole: Array<{ __typename?: "GroupData" } & GroupFragmentFragment>;
};

export type GetCurrentUserPermissionsQueryVariables = Exact<{
  type?: Maybe<PermissionType>;
}>;

export type GetCurrentUserPermissionsQuery = { __typename?: "Query" } & {
  GetCurrentUserPermissions: Array<
    Maybe<{ __typename?: "PermissionData" } & PermissionFragmentFragment>
  >;
};

export type ApplyDataPermissionsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ApplyDataPermissionsMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "ApplyPermissions"
>;

export const TargetsGroupFragmentFragmentDoc = gql`
  fragment TargetsGroupFragment on TargetsGroupData {
    id
    creationDate
    updateDate
    name
    editable
  }
`;
export const TargetFragmentFragmentDoc = gql`
  fragment TargetFragment on TargetData {
    id
    creationDate
    updateDate
    sourceId
    name
    groupId
    source
    sourceType
    mediasIds
  }
`;
export const AudioSourceFragmentFragmentDoc = gql`
  fragment AudioSourceFragment on AudioSourceData {
    id
    name
    logo
  }
`;
export const SubscriptionUsageFragmentFragmentDoc = gql`
  fragment SubscriptionUsageFragment on SubscriptionUsage {
    totalDurationOfAudioIngestedInSec
    totalEntitiesInTheAudioIngested
    totalWordsInTheAudioIngested
  }
`;
export const SummaryStatisticsFragmentFragmentDoc = gql`
  fragment SummaryStatisticsFragment on SummaryStatistics {
    audio
    sentences
    entities
    Sentiments {
      total
      sentences
      sentiments {
        joy
        trust
        surprise
        anticipation
        sadness
        fear
        anger
        disgust
      }
    }
    ROI {
      total
      sentences
      sentiments {
        joy
        trust
        surprise
        anticipation
        sadness
        fear
        anger
        disgust
      }
    }
  }
`;
export const PaymentIntentFragmentFragmentDoc = gql`
  fragment PaymentIntentFragment on PaymentIntent {
    id
    stripePaymentIntentId
    amount
    currency
    captureMethod
    confirmationMethod
    status
    createdAt
    updatedAt
    paymentMethods {
      id
      stripePaymentMethodId
      cardLast4
      cardBrand
      cardExpMonth
      cardExpYear
      cardFingerprint
      isDefault
      cardOwnerName
      updatedAt
      createdAt
    }
  }
`;
export const InvoicesFragmentFragmentDoc = gql`
  fragment InvoicesFragment on Invoice {
    id
    stripeInvoiceId
    stripeSubscriptionId
    amountDue
    amountPaid
    total
    tax
    isAttempted
    currency
    status
    createdAt
    updatedAt
    lines {
      id
      stripeInvoiceLineId
      amount
      currency
      description
      quantity
    }
    paymentIntent {
      ...PaymentIntentFragment
    }
  }
  ${PaymentIntentFragmentFragmentDoc}
`;
export const PermissionFragmentFragmentDoc = gql`
  fragment PermissionFragment on PermissionData {
    id
    name
    description
    type
    order
    enabled
    children {
      id
      name
      description
      type
      order
      enabled
      children {
        id
        name
        description
        type
        order
        enabled
      }
    }
  }
`;
export const RoleFragmentFragmentDoc = gql`
  fragment RoleFragment on RoleData {
    id
    name
    description
    visibleLevel
    permissions {
      ...PermissionFragment
    }
    creationDate
  }
  ${PermissionFragmentFragmentDoc}
`;
export const GroupFragmentFragmentDoc = gql`
  fragment GroupFragment on GroupData {
    id
    name
    description
    creationDate
    roles {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on UserData {
    id
    email
    firstName
    lastName
    active
    profilePicture
    work {
      address
      phone
    }
    updated
    creationDate
    passwordModificationDate
    emailVerified
    organisation {
      id
      name
    }
    department {
      id
      name
    }
    groups {
      ...GroupFragment
    }
    departmentName
    password
    phone
    FindOutAudionaut
    referralLevel
  }
  ${GroupFragmentFragmentDoc}
`;
export const AudionautPlanSimplifyFragmentFragmentDoc = gql`
  fragment AudionautPlanSimplifyFragment on AudionautPlan {
    id
    name
  }
`;
export const AudionautCouponFragmentFragmentDoc = gql`
  fragment AudionautCouponFragment on AudionautCoupon {
    id
    name
    description
    percentage
    stripeCouponId
    validTill
    forAudionautPlanId
    forAudionautPlan {
      ...AudionautPlanSimplifyFragment
    }
    maxRedemptions
    createdAt
    updatedAt
  }
  ${AudionautPlanSimplifyFragmentFragmentDoc}
`;
export const ReferredLinkFragmentDoc = gql`
  fragment ReferredLink on ReferredLink {
    id
    referredID
    referred {
      ...UserFragment
    }
    referredByID
    referredBy {
      ...UserFragment
    }
    ReferredByCouponID
    ReferredByCoupon {
      ...AudionautCouponFragment
    }
    createdAt
    referralLink
    code
    emailSentTo
    status
  }
  ${UserFragmentFragmentDoc}
  ${AudionautCouponFragmentFragmentDoc}
`;
export const NoteFragmentFragmentDoc = gql`
  fragment NoteFragment on NoteData {
    id
    comment
    rID
    creationDate
    attachments {
      name
      folder
      filename
    }
    user {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export const AudionautPlanFragmentFragmentDoc = gql`
  fragment AudionautPlanFragment on AudionautPlan {
    id
    name
    description
    priceInCent
    audionautUnitInSec
    pricePerUnitInCent
    trialPeriodNumOfDays
    stripeProductId
    stripePriceId
    stripeFlatPriceId
    active
    couponId
    activeCoupons {
      ...AudionautCouponFragment
    }
    createdAt
    updatedAt
  }
  ${AudionautCouponFragmentFragmentDoc}
`;
export const AudionautSubscriptionFragmentFragmentDoc = gql`
  fragment AudionautSubscriptionFragment on AudionautSubscription {
    id
    startsAt
    endsAt
    stripeSubscriptionId
    stripeMeteredSubscriptionItemId
    audionautPlan {
      ...AudionautPlanFragment
    }
    audionautPlanId
    organisationId
    status
    createdAt
    updatedAt
  }
  ${AudionautPlanFragmentFragmentDoc}
`;
export const BillingAddressFragmentFragmentDoc = gql`
  fragment BillingAddressFragment on BillingAddress {
    id
    firstName
    lastName
    address
    addressLine2
    zipCode
    city
    state
    country
    countryCode
    isDefault
    updatedAt
    createdAt
  }
`;
export const PaymentMethodFragmentFragmentDoc = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    id
    stripePaymentMethodId
    cardLast4
    cardBrand
    cardExpMonth
    cardExpYear
    cardFingerprint
    isDefault
    createdAt
    updatedAt
    organisation {
      id
      name
      email
    }
  }
`;
export const OrganisationDataFragmentFragmentDoc = gql`
  fragment OrganisationDataFragment on OrganisationData {
    id
    name
    email
    audionautUnitInSec
    stripeCustomerId
    esIndexName
    esIndexAliasName
    active
    billingAddressesId
    paymentMethodsId
    subscriptionAutopay
    currentPeriodSubscription {
      ...AudionautSubscriptionFragment
    }
    billingAddresses {
      ...BillingAddressFragment
    }
    paymentMethods {
      ...PaymentMethodFragment
    }
  }
  ${AudionautSubscriptionFragmentFragmentDoc}
  ${BillingAddressFragmentFragmentDoc}
  ${PaymentMethodFragmentFragmentDoc}
`;
export const GetAudioSourcesDocument = gql`
  query getAudioSources($audioSourceId: ID!) {
    GetAudioSources(id: $audioSourceId) {
      ...AudioSourceFragment
    }
  }
  ${AudioSourceFragmentFragmentDoc}
`;

/**
 * __useGetAudioSourcesQuery__
 *
 * To run a query within a React component, call `useGetAudioSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudioSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudioSourcesQuery({
 *   variables: {
 *      audioSourceId: // value for 'audioSourceId'
 *   },
 * });
 */
export function useGetAudioSourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAudioSourcesQuery,
    GetAudioSourcesQueryVariables
  >
) {
  return Apollo.useQuery<GetAudioSourcesQuery, GetAudioSourcesQueryVariables>(
    GetAudioSourcesDocument,
    baseOptions
  );
}
export function useGetAudioSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAudioSourcesQuery,
    GetAudioSourcesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAudioSourcesQuery,
    GetAudioSourcesQueryVariables
  >(GetAudioSourcesDocument, baseOptions);
}
export type GetAudioSourcesQueryHookResult = ReturnType<
  typeof useGetAudioSourcesQuery
>;
export type GetAudioSourcesLazyQueryHookResult = ReturnType<
  typeof useGetAudioSourcesLazyQuery
>;
export type GetAudioSourcesQueryResult = Apollo.QueryResult<
  GetAudioSourcesQuery,
  GetAudioSourcesQueryVariables
>;
export const GetAllTargetsGroupsDocument = gql`
  query getAllTargetsGroups {
    GetTargetsGroups {
      ...TargetsGroupFragment
    }
  }
  ${TargetsGroupFragmentFragmentDoc}
`;

/**
 * __useGetAllTargetsGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllTargetsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTargetsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTargetsGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTargetsGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTargetsGroupsQuery,
    GetAllTargetsGroupsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAllTargetsGroupsQuery,
    GetAllTargetsGroupsQueryVariables
  >(GetAllTargetsGroupsDocument, baseOptions);
}
export function useGetAllTargetsGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTargetsGroupsQuery,
    GetAllTargetsGroupsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllTargetsGroupsQuery,
    GetAllTargetsGroupsQueryVariables
  >(GetAllTargetsGroupsDocument, baseOptions);
}
export type GetAllTargetsGroupsQueryHookResult = ReturnType<
  typeof useGetAllTargetsGroupsQuery
>;
export type GetAllTargetsGroupsLazyQueryHookResult = ReturnType<
  typeof useGetAllTargetsGroupsLazyQuery
>;
export type GetAllTargetsGroupsQueryResult = Apollo.QueryResult<
  GetAllTargetsGroupsQuery,
  GetAllTargetsGroupsQueryVariables
>;
export const GetTargetsDocument = gql`
  query getTargets($targetId: ID!) {
    GetTargets(id: $targetId) {
      ...TargetFragment
    }
  }
  ${TargetFragmentFragmentDoc}
`;

/**
 * __useGetTargetsQuery__
 *
 * To run a query within a React component, call `useGetTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetsQuery({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useGetTargetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTargetsQuery,
    GetTargetsQueryVariables
  >
) {
  return Apollo.useQuery<GetTargetsQuery, GetTargetsQueryVariables>(
    GetTargetsDocument,
    baseOptions
  );
}
export function useGetTargetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTargetsQuery,
    GetTargetsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTargetsQuery, GetTargetsQueryVariables>(
    GetTargetsDocument,
    baseOptions
  );
}
export type GetTargetsQueryHookResult = ReturnType<typeof useGetTargetsQuery>;
export type GetTargetsLazyQueryHookResult = ReturnType<
  typeof useGetTargetsLazyQuery
>;
export type GetTargetsQueryResult = Apollo.QueryResult<
  GetTargetsQuery,
  GetTargetsQueryVariables
>;
export const GetGroupTargetsByIdDocument = gql`
  query getGroupTargetsById($groupId: ID!) {
    GetTargetsByGroup(id: $groupId) {
      ...TargetFragment
    }
  }
  ${TargetFragmentFragmentDoc}
`;

/**
 * __useGetGroupTargetsByIdQuery__
 *
 * To run a query within a React component, call `useGetGroupTargetsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupTargetsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupTargetsByIdQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupTargetsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupTargetsByIdQuery,
    GetGroupTargetsByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetGroupTargetsByIdQuery,
    GetGroupTargetsByIdQueryVariables
  >(GetGroupTargetsByIdDocument, baseOptions);
}
export function useGetGroupTargetsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupTargetsByIdQuery,
    GetGroupTargetsByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetGroupTargetsByIdQuery,
    GetGroupTargetsByIdQueryVariables
  >(GetGroupTargetsByIdDocument, baseOptions);
}
export type GetGroupTargetsByIdQueryHookResult = ReturnType<
  typeof useGetGroupTargetsByIdQuery
>;
export type GetGroupTargetsByIdLazyQueryHookResult = ReturnType<
  typeof useGetGroupTargetsByIdLazyQuery
>;
export type GetGroupTargetsByIdQueryResult = Apollo.QueryResult<
  GetGroupTargetsByIdQuery,
  GetGroupTargetsByIdQueryVariables
>;
export const GetTargetBySourceIdDocument = gql`
  query getTargetBySourceId($sourceId: String!) {
    GetTargetBySourceId(sourceId: $sourceId) {
      ...TargetFragment
    }
  }
  ${TargetFragmentFragmentDoc}
`;

/**
 * __useGetTargetBySourceIdQuery__
 *
 * To run a query within a React component, call `useGetTargetBySourceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetBySourceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetBySourceIdQuery({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *   },
 * });
 */
export function useGetTargetBySourceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTargetBySourceIdQuery,
    GetTargetBySourceIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTargetBySourceIdQuery,
    GetTargetBySourceIdQueryVariables
  >(GetTargetBySourceIdDocument, baseOptions);
}
export function useGetTargetBySourceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTargetBySourceIdQuery,
    GetTargetBySourceIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTargetBySourceIdQuery,
    GetTargetBySourceIdQueryVariables
  >(GetTargetBySourceIdDocument, baseOptions);
}
export type GetTargetBySourceIdQueryHookResult = ReturnType<
  typeof useGetTargetBySourceIdQuery
>;
export type GetTargetBySourceIdLazyQueryHookResult = ReturnType<
  typeof useGetTargetBySourceIdLazyQuery
>;
export type GetTargetBySourceIdQueryResult = Apollo.QueryResult<
  GetTargetBySourceIdQuery,
  GetTargetBySourceIdQueryVariables
>;
export const GetAccountUsageReportDocument = gql`
  query getAccountUsageReport($startsAt: String, $endsAt: String) {
    getAccountUsageReport(startsAt: $startsAt, endsAt: $endsAt) {
      ...SubscriptionUsageFragment
    }
  }
  ${SubscriptionUsageFragmentFragmentDoc}
`;

/**
 * __useGetAccountUsageReportQuery__
 *
 * To run a query within a React component, call `useGetAccountUsageReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountUsageReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountUsageReportQuery({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useGetAccountUsageReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountUsageReportQuery,
    GetAccountUsageReportQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAccountUsageReportQuery,
    GetAccountUsageReportQueryVariables
  >(GetAccountUsageReportDocument, baseOptions);
}
export function useGetAccountUsageReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountUsageReportQuery,
    GetAccountUsageReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAccountUsageReportQuery,
    GetAccountUsageReportQueryVariables
  >(GetAccountUsageReportDocument, baseOptions);
}
export type GetAccountUsageReportQueryHookResult = ReturnType<
  typeof useGetAccountUsageReportQuery
>;
export type GetAccountUsageReportLazyQueryHookResult = ReturnType<
  typeof useGetAccountUsageReportLazyQuery
>;
export type GetAccountUsageReportQueryResult = Apollo.QueryResult<
  GetAccountUsageReportQuery,
  GetAccountUsageReportQueryVariables
>;
export const GetSummaryStatisticsDocument = gql`
  query getSummaryStatistics($startDate: String, $endDate: String) {
    getSummaryStatistics(startDate: $startDate, endDate: $endDate) {
      ...SummaryStatisticsFragment
    }
  }
  ${SummaryStatisticsFragmentFragmentDoc}
`;

/**
 * __useGetSummaryStatisticsQuery__
 *
 * To run a query within a React component, call `useGetSummaryStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSummaryStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSummaryStatisticsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetSummaryStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSummaryStatisticsQuery,
    GetSummaryStatisticsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetSummaryStatisticsQuery,
    GetSummaryStatisticsQueryVariables
  >(GetSummaryStatisticsDocument, baseOptions);
}
export function useGetSummaryStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSummaryStatisticsQuery,
    GetSummaryStatisticsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetSummaryStatisticsQuery,
    GetSummaryStatisticsQueryVariables
  >(GetSummaryStatisticsDocument, baseOptions);
}
export type GetSummaryStatisticsQueryHookResult = ReturnType<
  typeof useGetSummaryStatisticsQuery
>;
export type GetSummaryStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetSummaryStatisticsLazyQuery
>;
export type GetSummaryStatisticsQueryResult = Apollo.QueryResult<
  GetSummaryStatisticsQuery,
  GetSummaryStatisticsQueryVariables
>;
export const CreateTargetsGroupDocument = gql`
  mutation createTargetsGroup($nameGroup: String!, $editable: Boolean!) {
    CreateTargetsGroup(name: $nameGroup, editable: $editable) {
      ...TargetsGroupFragment
    }
  }
  ${TargetsGroupFragmentFragmentDoc}
`;
export type CreateTargetsGroupMutationFn = Apollo.MutationFunction<
  CreateTargetsGroupMutation,
  CreateTargetsGroupMutationVariables
>;

/**
 * __useCreateTargetsGroupMutation__
 *
 * To run a mutation, you first call `useCreateTargetsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTargetsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTargetsGroupMutation, { data, loading, error }] = useCreateTargetsGroupMutation({
 *   variables: {
 *      nameGroup: // value for 'nameGroup'
 *      editable: // value for 'editable'
 *   },
 * });
 */
export function useCreateTargetsGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTargetsGroupMutation,
    CreateTargetsGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateTargetsGroupMutation,
    CreateTargetsGroupMutationVariables
  >(CreateTargetsGroupDocument, baseOptions);
}
export type CreateTargetsGroupMutationHookResult = ReturnType<
  typeof useCreateTargetsGroupMutation
>;
export type CreateTargetsGroupMutationResult =
  Apollo.MutationResult<CreateTargetsGroupMutation>;
export type CreateTargetsGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateTargetsGroupMutation,
  CreateTargetsGroupMutationVariables
>;
export const UpdateTargetsGroupDocument = gql`
  mutation updateTargetsGroup($groupId: ID!, $groupName: String!) {
    UpdateTargetsGroup(id: $groupId, name: $groupName) {
      ...TargetsGroupFragment
    }
  }
  ${TargetsGroupFragmentFragmentDoc}
`;
export type UpdateTargetsGroupMutationFn = Apollo.MutationFunction<
  UpdateTargetsGroupMutation,
  UpdateTargetsGroupMutationVariables
>;

/**
 * __useUpdateTargetsGroupMutation__
 *
 * To run a mutation, you first call `useUpdateTargetsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetsGroupMutation, { data, loading, error }] = useUpdateTargetsGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useUpdateTargetsGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTargetsGroupMutation,
    UpdateTargetsGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTargetsGroupMutation,
    UpdateTargetsGroupMutationVariables
  >(UpdateTargetsGroupDocument, baseOptions);
}
export type UpdateTargetsGroupMutationHookResult = ReturnType<
  typeof useUpdateTargetsGroupMutation
>;
export type UpdateTargetsGroupMutationResult =
  Apollo.MutationResult<UpdateTargetsGroupMutation>;
export type UpdateTargetsGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateTargetsGroupMutation,
  UpdateTargetsGroupMutationVariables
>;
export const DeleteTargetsGroupDocument = gql`
  mutation deleteTargetsGroup($groupId: ID!) {
    DeleteTargetsGroup(id: $groupId) {
      ...TargetsGroupFragment
    }
  }
  ${TargetsGroupFragmentFragmentDoc}
`;
export type DeleteTargetsGroupMutationFn = Apollo.MutationFunction<
  DeleteTargetsGroupMutation,
  DeleteTargetsGroupMutationVariables
>;

/**
 * __useDeleteTargetsGroupMutation__
 *
 * To run a mutation, you first call `useDeleteTargetsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTargetsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTargetsGroupMutation, { data, loading, error }] = useDeleteTargetsGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteTargetsGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTargetsGroupMutation,
    DeleteTargetsGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteTargetsGroupMutation,
    DeleteTargetsGroupMutationVariables
  >(DeleteTargetsGroupDocument, baseOptions);
}
export type DeleteTargetsGroupMutationHookResult = ReturnType<
  typeof useDeleteTargetsGroupMutation
>;
export type DeleteTargetsGroupMutationResult =
  Apollo.MutationResult<DeleteTargetsGroupMutation>;
export type DeleteTargetsGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteTargetsGroupMutation,
  DeleteTargetsGroupMutationVariables
>;
export const CreateTargetDocument = gql`
  mutation createTarget(
    $sourceId: String!
    $targetName: String!
    $groupId: ID!
    $source: String!
    $type: String!
    $mediasIds: String!
  ) {
    CreateTarget(
      sourceId: $sourceId
      name: $targetName
      groupId: $groupId
      source: $source
      sourceType: $type
      mediasIds: $mediasIds
    ) {
      ...TargetFragment
    }
  }
  ${TargetFragmentFragmentDoc}
`;
export type CreateTargetMutationFn = Apollo.MutationFunction<
  CreateTargetMutation,
  CreateTargetMutationVariables
>;

/**
 * __useCreateTargetMutation__
 *
 * To run a mutation, you first call `useCreateTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTargetMutation, { data, loading, error }] = useCreateTargetMutation({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      targetName: // value for 'targetName'
 *      groupId: // value for 'groupId'
 *      source: // value for 'source'
 *      type: // value for 'type'
 *      mediasIds: // value for 'mediasIds'
 *   },
 * });
 */
export function useCreateTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTargetMutation,
    CreateTargetMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateTargetMutation,
    CreateTargetMutationVariables
  >(CreateTargetDocument, baseOptions);
}
export type CreateTargetMutationHookResult = ReturnType<
  typeof useCreateTargetMutation
>;
export type CreateTargetMutationResult =
  Apollo.MutationResult<CreateTargetMutation>;
export type CreateTargetMutationOptions = Apollo.BaseMutationOptions<
  CreateTargetMutation,
  CreateTargetMutationVariables
>;
export const UpdateTargetDocument = gql`
  mutation updateTarget($targetId: ID!, $targetName: String!) {
    UpdateTarget(id: $targetId, name: $targetName) {
      ...TargetFragment
    }
  }
  ${TargetFragmentFragmentDoc}
`;
export type UpdateTargetMutationFn = Apollo.MutationFunction<
  UpdateTargetMutation,
  UpdateTargetMutationVariables
>;

/**
 * __useUpdateTargetMutation__
 *
 * To run a mutation, you first call `useUpdateTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetMutation, { data, loading, error }] = useUpdateTargetMutation({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      targetName: // value for 'targetName'
 *   },
 * });
 */
export function useUpdateTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTargetMutation,
    UpdateTargetMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTargetMutation,
    UpdateTargetMutationVariables
  >(UpdateTargetDocument, baseOptions);
}
export type UpdateTargetMutationHookResult = ReturnType<
  typeof useUpdateTargetMutation
>;
export type UpdateTargetMutationResult =
  Apollo.MutationResult<UpdateTargetMutation>;
export type UpdateTargetMutationOptions = Apollo.BaseMutationOptions<
  UpdateTargetMutation,
  UpdateTargetMutationVariables
>;
export const DeleteTargetDocument = gql`
  mutation deleteTarget($targetId: ID!) {
    DeleteTarget(id: $targetId) {
      ...TargetFragment
    }
  }
  ${TargetFragmentFragmentDoc}
`;
export type DeleteTargetMutationFn = Apollo.MutationFunction<
  DeleteTargetMutation,
  DeleteTargetMutationVariables
>;

/**
 * __useDeleteTargetMutation__
 *
 * To run a mutation, you first call `useDeleteTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTargetMutation, { data, loading, error }] = useDeleteTargetMutation({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useDeleteTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTargetMutation,
    DeleteTargetMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteTargetMutation,
    DeleteTargetMutationVariables
  >(DeleteTargetDocument, baseOptions);
}
export type DeleteTargetMutationHookResult = ReturnType<
  typeof useDeleteTargetMutation
>;
export type DeleteTargetMutationResult =
  Apollo.MutationResult<DeleteTargetMutation>;
export type DeleteTargetMutationOptions = Apollo.BaseMutationOptions<
  DeleteTargetMutation,
  DeleteTargetMutationVariables
>;
export const GetAudionautPlansDocument = gql`
  query getAudionautPlans {
    getAudionautPlans {
      ...AudionautPlanFragment
    }
  }
  ${AudionautPlanFragmentFragmentDoc}
`;

/**
 * __useGetAudionautPlansQuery__
 *
 * To run a query within a React component, call `useGetAudionautPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudionautPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudionautPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAudionautPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAudionautPlansQuery,
    GetAudionautPlansQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAudionautPlansQuery,
    GetAudionautPlansQueryVariables
  >(GetAudionautPlansDocument, baseOptions);
}
export function useGetAudionautPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAudionautPlansQuery,
    GetAudionautPlansQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAudionautPlansQuery,
    GetAudionautPlansQueryVariables
  >(GetAudionautPlansDocument, baseOptions);
}
export type GetAudionautPlansQueryHookResult = ReturnType<
  typeof useGetAudionautPlansQuery
>;
export type GetAudionautPlansLazyQueryHookResult = ReturnType<
  typeof useGetAudionautPlansLazyQuery
>;
export type GetAudionautPlansQueryResult = Apollo.QueryResult<
  GetAudionautPlansQuery,
  GetAudionautPlansQueryVariables
>;
export const GetStripeCouponDocument = gql`
  query getStripeCoupon($coupon: String!) {
    getCouponByCode(couponId: $coupon) {
      ...AudionautCouponFragment
    }
  }
  ${AudionautCouponFragmentFragmentDoc}
`;

/**
 * __useGetStripeCouponQuery__
 *
 * To run a query within a React component, call `useGetStripeCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCouponQuery({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useGetStripeCouponQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStripeCouponQuery,
    GetStripeCouponQueryVariables
  >
) {
  return Apollo.useQuery<GetStripeCouponQuery, GetStripeCouponQueryVariables>(
    GetStripeCouponDocument,
    baseOptions
  );
}
export function useGetStripeCouponLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeCouponQuery,
    GetStripeCouponQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetStripeCouponQuery,
    GetStripeCouponQueryVariables
  >(GetStripeCouponDocument, baseOptions);
}
export type GetStripeCouponQueryHookResult = ReturnType<
  typeof useGetStripeCouponQuery
>;
export type GetStripeCouponLazyQueryHookResult = ReturnType<
  typeof useGetStripeCouponLazyQuery
>;
export type GetStripeCouponQueryResult = Apollo.QueryResult<
  GetStripeCouponQuery,
  GetStripeCouponQueryVariables
>;
export const CreateAudionautPlanDocument = gql`
  mutation createAudionautPlan(
    $name: String!
    $description: String!
    $priceInCent: Int!
    $pricePerUnitInCent: Int!
    $audionautUnitInSec: Int!
    $trialPeriodNumOfDays: Int!
    $active: Boolean!
  ) {
    createAudionautPlan(
      name: $name
      description: $description
      priceInCent: $priceInCent
      pricePerUnitInCent: $pricePerUnitInCent
      audionautUnitInSec: $audionautUnitInSec
      trialPeriodNumOfDays: $trialPeriodNumOfDays
      active: $active
    ) {
      ...AudionautPlanFragment
    }
  }
  ${AudionautPlanFragmentFragmentDoc}
`;
export type CreateAudionautPlanMutationFn = Apollo.MutationFunction<
  CreateAudionautPlanMutation,
  CreateAudionautPlanMutationVariables
>;

/**
 * __useCreateAudionautPlanMutation__
 *
 * To run a mutation, you first call `useCreateAudionautPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAudionautPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAudionautPlanMutation, { data, loading, error }] = useCreateAudionautPlanMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priceInCent: // value for 'priceInCent'
 *      pricePerUnitInCent: // value for 'pricePerUnitInCent'
 *      audionautUnitInSec: // value for 'audionautUnitInSec'
 *      trialPeriodNumOfDays: // value for 'trialPeriodNumOfDays'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateAudionautPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAudionautPlanMutation,
    CreateAudionautPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateAudionautPlanMutation,
    CreateAudionautPlanMutationVariables
  >(CreateAudionautPlanDocument, baseOptions);
}
export type CreateAudionautPlanMutationHookResult = ReturnType<
  typeof useCreateAudionautPlanMutation
>;
export type CreateAudionautPlanMutationResult =
  Apollo.MutationResult<CreateAudionautPlanMutation>;
export type CreateAudionautPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateAudionautPlanMutation,
  CreateAudionautPlanMutationVariables
>;
export const EditAudionautPlanByIdDocument = gql`
  mutation editAudionautPlanById(
    $id: ID!
    $name: String!
    $description: String!
    $priceInCent: Int!
    $pricePerUnitInCent: Int!
    $audionautUnitInSec: Int!
    $trialPeriodNumOfDays: Int!
    $active: Boolean!
  ) {
    editAudionautPlan(
      id: $id
      name: $name
      description: $description
      priceInCent: $priceInCent
      pricePerUnitInCent: $pricePerUnitInCent
      audionautUnitInSec: $audionautUnitInSec
      trialPeriodNumOfDays: $trialPeriodNumOfDays
      active: $active
    ) {
      ...AudionautPlanFragment
    }
  }
  ${AudionautPlanFragmentFragmentDoc}
`;
export type EditAudionautPlanByIdMutationFn = Apollo.MutationFunction<
  EditAudionautPlanByIdMutation,
  EditAudionautPlanByIdMutationVariables
>;

/**
 * __useEditAudionautPlanByIdMutation__
 *
 * To run a mutation, you first call `useEditAudionautPlanByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAudionautPlanByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAudionautPlanByIdMutation, { data, loading, error }] = useEditAudionautPlanByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priceInCent: // value for 'priceInCent'
 *      pricePerUnitInCent: // value for 'pricePerUnitInCent'
 *      audionautUnitInSec: // value for 'audionautUnitInSec'
 *      trialPeriodNumOfDays: // value for 'trialPeriodNumOfDays'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useEditAudionautPlanByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAudionautPlanByIdMutation,
    EditAudionautPlanByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    EditAudionautPlanByIdMutation,
    EditAudionautPlanByIdMutationVariables
  >(EditAudionautPlanByIdDocument, baseOptions);
}
export type EditAudionautPlanByIdMutationHookResult = ReturnType<
  typeof useEditAudionautPlanByIdMutation
>;
export type EditAudionautPlanByIdMutationResult =
  Apollo.MutationResult<EditAudionautPlanByIdMutation>;
export type EditAudionautPlanByIdMutationOptions = Apollo.BaseMutationOptions<
  EditAudionautPlanByIdMutation,
  EditAudionautPlanByIdMutationVariables
>;
export const GetCurrentUserOrganisationCurrentPeriodSubscriptionDocument = gql`
  query getCurrentUserOrganisationCurrentPeriodSubscription {
    getCurrentUserOrganisationCurrentPeriodSubscription {
      ...AudionautSubscriptionFragment
    }
  }
  ${AudionautSubscriptionFragmentFragmentDoc}
`;

/**
 * __useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryVariables
  >(GetCurrentUserOrganisationCurrentPeriodSubscriptionDocument, baseOptions);
}
export function useGetCurrentUserOrganisationCurrentPeriodSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryVariables
  >(GetCurrentUserOrganisationCurrentPeriodSubscriptionDocument, baseOptions);
}
export type GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryHookResult =
  ReturnType<
    typeof useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery
  >;
export type GetCurrentUserOrganisationCurrentPeriodSubscriptionLazyQueryHookResult =
  ReturnType<
    typeof useGetCurrentUserOrganisationCurrentPeriodSubscriptionLazyQuery
  >;
export type GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryResult =
  Apollo.QueryResult<
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
    GetCurrentUserOrganisationCurrentPeriodSubscriptionQueryVariables
  >;
export const GetSubscriptionHistoryDocument = gql`
  query getSubscriptionHistory {
    getCurrentUserOrganisationSubscriptionHistory {
      ...AudionautSubscriptionFragment
    }
  }
  ${AudionautSubscriptionFragmentFragmentDoc}
`;

/**
 * __useGetSubscriptionHistoryQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionHistoryQuery,
    GetSubscriptionHistoryQueryVariables
  >
) {
  return Apollo.useQuery<
    GetSubscriptionHistoryQuery,
    GetSubscriptionHistoryQueryVariables
  >(GetSubscriptionHistoryDocument, baseOptions);
}
export function useGetSubscriptionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionHistoryQuery,
    GetSubscriptionHistoryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetSubscriptionHistoryQuery,
    GetSubscriptionHistoryQueryVariables
  >(GetSubscriptionHistoryDocument, baseOptions);
}
export type GetSubscriptionHistoryQueryHookResult = ReturnType<
  typeof useGetSubscriptionHistoryQuery
>;
export type GetSubscriptionHistoryLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionHistoryLazyQuery
>;
export type GetSubscriptionHistoryQueryResult = Apollo.QueryResult<
  GetSubscriptionHistoryQuery,
  GetSubscriptionHistoryQueryVariables
>;
export const CancelCurrentSubscriptionDocument = gql`
  mutation cancelCurrentSubscription {
    cancelCurrentActiveSubscription {
      ...AudionautSubscriptionFragment
    }
  }
  ${AudionautSubscriptionFragmentFragmentDoc}
`;
export type CancelCurrentSubscriptionMutationFn = Apollo.MutationFunction<
  CancelCurrentSubscriptionMutation,
  CancelCurrentSubscriptionMutationVariables
>;

/**
 * __useCancelCurrentSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelCurrentSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCurrentSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCurrentSubscriptionMutation, { data, loading, error }] = useCancelCurrentSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelCurrentSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelCurrentSubscriptionMutation,
    CancelCurrentSubscriptionMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelCurrentSubscriptionMutation,
    CancelCurrentSubscriptionMutationVariables
  >(CancelCurrentSubscriptionDocument, baseOptions);
}
export type CancelCurrentSubscriptionMutationHookResult = ReturnType<
  typeof useCancelCurrentSubscriptionMutation
>;
export type CancelCurrentSubscriptionMutationResult =
  Apollo.MutationResult<CancelCurrentSubscriptionMutation>;
export type CancelCurrentSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CancelCurrentSubscriptionMutation,
    CancelCurrentSubscriptionMutationVariables
  >;
export const GetAllInvoicesDocument = gql`
  query getAllInvoices {
    getAccountInvoices {
      amountOwed
      invoices {
        ...InvoicesFragment
      }
    }
  }
  ${InvoicesFragmentFragmentDoc}
`;

/**
 * __useGetAllInvoicesQuery__
 *
 * To run a query within a React component, call `useGetAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllInvoicesQuery,
    GetAllInvoicesQueryVariables
  >
) {
  return Apollo.useQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(
    GetAllInvoicesDocument,
    baseOptions
  );
}
export function useGetAllInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllInvoicesQuery,
    GetAllInvoicesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(
    GetAllInvoicesDocument,
    baseOptions
  );
}
export type GetAllInvoicesQueryHookResult = ReturnType<
  typeof useGetAllInvoicesQuery
>;
export type GetAllInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetAllInvoicesLazyQuery
>;
export type GetAllInvoicesQueryResult = Apollo.QueryResult<
  GetAllInvoicesQuery,
  GetAllInvoicesQueryVariables
>;
export const GetCurrentSubscriptionInfoDocument = gql`
  query getCurrentSubscriptionInfo {
    getCustomerSubscriptionInfo {
      audionautUnitsLeft
      hasActiveMeteredSubscription
      hasDefaultPaymentMethod
    }
  }
`;

/**
 * __useGetCurrentSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useGetCurrentSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSubscriptionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSubscriptionInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentSubscriptionInfoQuery,
    GetCurrentSubscriptionInfoQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentSubscriptionInfoQuery,
    GetCurrentSubscriptionInfoQueryVariables
  >(GetCurrentSubscriptionInfoDocument, baseOptions);
}
export function useGetCurrentSubscriptionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentSubscriptionInfoQuery,
    GetCurrentSubscriptionInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentSubscriptionInfoQuery,
    GetCurrentSubscriptionInfoQueryVariables
  >(GetCurrentSubscriptionInfoDocument, baseOptions);
}
export type GetCurrentSubscriptionInfoQueryHookResult = ReturnType<
  typeof useGetCurrentSubscriptionInfoQuery
>;
export type GetCurrentSubscriptionInfoLazyQueryHookResult = ReturnType<
  typeof useGetCurrentSubscriptionInfoLazyQuery
>;
export type GetCurrentSubscriptionInfoQueryResult = Apollo.QueryResult<
  GetCurrentSubscriptionInfoQuery,
  GetCurrentSubscriptionInfoQueryVariables
>;
export const PayOutstandingInvoiceDocument = gql`
  mutation payOutstandingInvoice(
    $methodId: String
    $ownerName: String
    $default: Boolean
  ) {
    payOutstandingInvoices(
      stripePaymentMethodId: $methodId
      paymentMethodCardOwnerName: $ownerName
      savePaymentMethodForFuturePayment: $default
    )
  }
`;
export type PayOutstandingInvoiceMutationFn = Apollo.MutationFunction<
  PayOutstandingInvoiceMutation,
  PayOutstandingInvoiceMutationVariables
>;

/**
 * __usePayOutstandingInvoiceMutation__
 *
 * To run a mutation, you first call `usePayOutstandingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayOutstandingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payOutstandingInvoiceMutation, { data, loading, error }] = usePayOutstandingInvoiceMutation({
 *   variables: {
 *      methodId: // value for 'methodId'
 *      ownerName: // value for 'ownerName'
 *      default: // value for 'default'
 *   },
 * });
 */
export function usePayOutstandingInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayOutstandingInvoiceMutation,
    PayOutstandingInvoiceMutationVariables
  >
) {
  return Apollo.useMutation<
    PayOutstandingInvoiceMutation,
    PayOutstandingInvoiceMutationVariables
  >(PayOutstandingInvoiceDocument, baseOptions);
}
export type PayOutstandingInvoiceMutationHookResult = ReturnType<
  typeof usePayOutstandingInvoiceMutation
>;
export type PayOutstandingInvoiceMutationResult =
  Apollo.MutationResult<PayOutstandingInvoiceMutation>;
export type PayOutstandingInvoiceMutationOptions = Apollo.BaseMutationOptions<
  PayOutstandingInvoiceMutation,
  PayOutstandingInvoiceMutationVariables
>;
export const CreatePlanSubscriptionWithCouponDocument = gql`
  mutation createPlanSubscriptionWithCoupon($planId: String!, $coupon: String) {
    createSubscription(audionautPlanId: $planId, couponCode: $coupon) {
      ...AudionautSubscriptionFragment
    }
  }
  ${AudionautSubscriptionFragmentFragmentDoc}
`;
export type CreatePlanSubscriptionWithCouponMutationFn =
  Apollo.MutationFunction<
    CreatePlanSubscriptionWithCouponMutation,
    CreatePlanSubscriptionWithCouponMutationVariables
  >;

/**
 * __useCreatePlanSubscriptionWithCouponMutation__
 *
 * To run a mutation, you first call `useCreatePlanSubscriptionWithCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanSubscriptionWithCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanSubscriptionWithCouponMutation, { data, loading, error }] = useCreatePlanSubscriptionWithCouponMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useCreatePlanSubscriptionWithCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanSubscriptionWithCouponMutation,
    CreatePlanSubscriptionWithCouponMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePlanSubscriptionWithCouponMutation,
    CreatePlanSubscriptionWithCouponMutationVariables
  >(CreatePlanSubscriptionWithCouponDocument, baseOptions);
}
export type CreatePlanSubscriptionWithCouponMutationHookResult = ReturnType<
  typeof useCreatePlanSubscriptionWithCouponMutation
>;
export type CreatePlanSubscriptionWithCouponMutationResult =
  Apollo.MutationResult<CreatePlanSubscriptionWithCouponMutation>;
export type CreatePlanSubscriptionWithCouponMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePlanSubscriptionWithCouponMutation,
    CreatePlanSubscriptionWithCouponMutationVariables
  >;
export const CreatePlanSubscriptionDocument = gql`
  mutation createPlanSubscription($planId: String!) {
    createSubscription(audionautPlanId: $planId) {
      ...AudionautSubscriptionFragment
    }
  }
  ${AudionautSubscriptionFragmentFragmentDoc}
`;
export type CreatePlanSubscriptionMutationFn = Apollo.MutationFunction<
  CreatePlanSubscriptionMutation,
  CreatePlanSubscriptionMutationVariables
>;

/**
 * __useCreatePlanSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreatePlanSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanSubscriptionMutation, { data, loading, error }] = useCreatePlanSubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCreatePlanSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanSubscriptionMutation,
    CreatePlanSubscriptionMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePlanSubscriptionMutation,
    CreatePlanSubscriptionMutationVariables
  >(CreatePlanSubscriptionDocument, baseOptions);
}
export type CreatePlanSubscriptionMutationHookResult = ReturnType<
  typeof useCreatePlanSubscriptionMutation
>;
export type CreatePlanSubscriptionMutationResult =
  Apollo.MutationResult<CreatePlanSubscriptionMutation>;
export type CreatePlanSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanSubscriptionMutation,
  CreatePlanSubscriptionMutationVariables
>;
export const ChangeAutoPayStatusDocument = gql`
  mutation changeAutoPayStatus($status: Boolean!) {
    updateSubscriptionAutopay(chargeAutomatically: $status) {
      ...OrganisationDataFragment
    }
  }
  ${OrganisationDataFragmentFragmentDoc}
`;
export type ChangeAutoPayStatusMutationFn = Apollo.MutationFunction<
  ChangeAutoPayStatusMutation,
  ChangeAutoPayStatusMutationVariables
>;

/**
 * __useChangeAutoPayStatusMutation__
 *
 * To run a mutation, you first call `useChangeAutoPayStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAutoPayStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAutoPayStatusMutation, { data, loading, error }] = useChangeAutoPayStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeAutoPayStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeAutoPayStatusMutation,
    ChangeAutoPayStatusMutationVariables
  >
) {
  return Apollo.useMutation<
    ChangeAutoPayStatusMutation,
    ChangeAutoPayStatusMutationVariables
  >(ChangeAutoPayStatusDocument, baseOptions);
}
export type ChangeAutoPayStatusMutationHookResult = ReturnType<
  typeof useChangeAutoPayStatusMutation
>;
export type ChangeAutoPayStatusMutationResult =
  Apollo.MutationResult<ChangeAutoPayStatusMutation>;
export type ChangeAutoPayStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeAutoPayStatusMutation,
  ChangeAutoPayStatusMutationVariables
>;
export const ValidateEmailVerificationCodeDocument = gql`
  mutation ValidateEmailVerificationCode($code: String!) {
    ValidateEmailVerificationCode(code: $code)
  }
`;
export type ValidateEmailVerificationCodeMutationFn = Apollo.MutationFunction<
  ValidateEmailVerificationCodeMutation,
  ValidateEmailVerificationCodeMutationVariables
>;

/**
 * __useValidateEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useValidateEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailVerificationCodeMutation, { data, loading, error }] = useValidateEmailVerificationCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateEmailVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateEmailVerificationCodeMutation,
    ValidateEmailVerificationCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    ValidateEmailVerificationCodeMutation,
    ValidateEmailVerificationCodeMutationVariables
  >(ValidateEmailVerificationCodeDocument, baseOptions);
}
export type ValidateEmailVerificationCodeMutationHookResult = ReturnType<
  typeof useValidateEmailVerificationCodeMutation
>;
export type ValidateEmailVerificationCodeMutationResult =
  Apollo.MutationResult<ValidateEmailVerificationCodeMutation>;
export type ValidateEmailVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    ValidateEmailVerificationCodeMutation,
    ValidateEmailVerificationCodeMutationVariables
  >;
export const SendEmailVerificationDocument = gql`
  mutation SendEmailVerification($userId: ID, $userEmail: String) {
    SendEmailVerification(userId: $userId, userEmail: $userEmail) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type SendEmailVerificationMutationFn = Apollo.MutationFunction<
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables
>;

/**
 * __useSendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationMutation, { data, loading, error }] = useSendEmailVerificationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useSendEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailVerificationMutation,
    SendEmailVerificationMutationVariables
  >
) {
  return Apollo.useMutation<
    SendEmailVerificationMutation,
    SendEmailVerificationMutationVariables
  >(SendEmailVerificationDocument, baseOptions);
}
export type SendEmailVerificationMutationHookResult = ReturnType<
  typeof useSendEmailVerificationMutation
>;
export type SendEmailVerificationMutationResult =
  Apollo.MutationResult<SendEmailVerificationMutation>;
export type SendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables
>;
export const LogInDocument = gql`
  query LogIn($email: String!, $password: String!) {
    LogIn(email: $email, password: $password)
  }
`;

/**
 * __useLogInQuery__
 *
 * To run a query within a React component, call `useLogInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogInQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInQuery(
  baseOptions: Apollo.QueryHookOptions<LogInQuery, LogInQueryVariables>
) {
  return Apollo.useQuery<LogInQuery, LogInQueryVariables>(
    LogInDocument,
    baseOptions
  );
}
export function useLogInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogInQuery, LogInQueryVariables>
) {
  return Apollo.useLazyQuery<LogInQuery, LogInQueryVariables>(
    LogInDocument,
    baseOptions
  );
}
export type LogInQueryHookResult = ReturnType<typeof useLogInQuery>;
export type LogInLazyQueryHookResult = ReturnType<typeof useLogInLazyQuery>;
export type LogInQueryResult = Apollo.QueryResult<
  LogInQuery,
  LogInQueryVariables
>;
export const SendPasswordResetRequestDocument = gql`
  query SendPasswordResetRequest($email: String!) {
    SendPasswordResetRequest(email: $email)
  }
`;

/**
 * __useSendPasswordResetRequestQuery__
 *
 * To run a query within a React component, call `useSendPasswordResetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPasswordResetRequestQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >
) {
  return Apollo.useQuery<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >(SendPasswordResetRequestDocument, baseOptions);
}
export function useSendPasswordResetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >(SendPasswordResetRequestDocument, baseOptions);
}
export type SendPasswordResetRequestQueryHookResult = ReturnType<
  typeof useSendPasswordResetRequestQuery
>;
export type SendPasswordResetRequestLazyQueryHookResult = ReturnType<
  typeof useSendPasswordResetRequestLazyQuery
>;
export type SendPasswordResetRequestQueryResult = Apollo.QueryResult<
  SendPasswordResetRequestQuery,
  SendPasswordResetRequestQueryVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($code: String!, $password: String!) {
    ResetPassword(code: $code, password: $password)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      code: // value for 'code'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization(
    $name: String!
    $email: String!
    $phone: String!
    $password: String!
  ) {
    createOrganizationAccount(
      name: $name
      email: $email
      password: $password
      phone: $phone
      active: true
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, baseOptions);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const CreateBillingAddressDocument = gql`
  mutation createBillingAddress(
    $firstName: String!
    $lastName: String!
    $isDefault: Boolean!
    $address: String!
    $addressLine2: String
    $city: String!
    $state: String!
    $country: String!
    $countryCode: String!
    $zipCode: String!
  ) {
    createBillingAddress(
      firstName: $firstName
      lastName: $lastName
      isDefault: $isDefault
      address: $address
      addressLine2: $addressLine2
      city: $city
      state: $state
      country: $country
      countryCode: $countryCode
      zipCode: $zipCode
    ) {
      ...BillingAddressFragment
    }
  }
  ${BillingAddressFragmentFragmentDoc}
`;
export type CreateBillingAddressMutationFn = Apollo.MutationFunction<
  CreateBillingAddressMutation,
  CreateBillingAddressMutationVariables
>;

/**
 * __useCreateBillingAddressMutation__
 *
 * To run a mutation, you first call `useCreateBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingAddressMutation, { data, loading, error }] = useCreateBillingAddressMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      isDefault: // value for 'isDefault'
 *      address: // value for 'address'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      countryCode: // value for 'countryCode'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useCreateBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBillingAddressMutation,
    CreateBillingAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateBillingAddressMutation,
    CreateBillingAddressMutationVariables
  >(CreateBillingAddressDocument, baseOptions);
}
export type CreateBillingAddressMutationHookResult = ReturnType<
  typeof useCreateBillingAddressMutation
>;
export type CreateBillingAddressMutationResult =
  Apollo.MutationResult<CreateBillingAddressMutation>;
export type CreateBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateBillingAddressMutation,
  CreateBillingAddressMutationVariables
>;
export const EditBillingAddressDocument = gql`
  mutation editBillingAddress(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $isDefault: Boolean!
    $address: String!
    $addressLine2: String
    $city: String!
    $state: String!
    $country: String!
    $countryCode: String!
    $zipCode: String!
  ) {
    editBillingAddress(
      id: $id
      firstName: $firstName
      lastName: $lastName
      isDefault: $isDefault
      address: $address
      addressLine2: $addressLine2
      city: $city
      state: $state
      country: $country
      countryCode: $countryCode
      zipCode: $zipCode
    ) {
      ...BillingAddressFragment
    }
  }
  ${BillingAddressFragmentFragmentDoc}
`;
export type EditBillingAddressMutationFn = Apollo.MutationFunction<
  EditBillingAddressMutation,
  EditBillingAddressMutationVariables
>;

/**
 * __useEditBillingAddressMutation__
 *
 * To run a mutation, you first call `useEditBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBillingAddressMutation, { data, loading, error }] = useEditBillingAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      isDefault: // value for 'isDefault'
 *      address: // value for 'address'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      countryCode: // value for 'countryCode'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useEditBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditBillingAddressMutation,
    EditBillingAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    EditBillingAddressMutation,
    EditBillingAddressMutationVariables
  >(EditBillingAddressDocument, baseOptions);
}
export type EditBillingAddressMutationHookResult = ReturnType<
  typeof useEditBillingAddressMutation
>;
export type EditBillingAddressMutationResult =
  Apollo.MutationResult<EditBillingAddressMutation>;
export type EditBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  EditBillingAddressMutation,
  EditBillingAddressMutationVariables
>;
export const DeleteBillingAddressDocument = gql`
  mutation deleteBillingAddress($id: ID!) {
    deleteBillingAddress(id: $id)
  }
`;
export type DeleteBillingAddressMutationFn = Apollo.MutationFunction<
  DeleteBillingAddressMutation,
  DeleteBillingAddressMutationVariables
>;

/**
 * __useDeleteBillingAddressMutation__
 *
 * To run a mutation, you first call `useDeleteBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingAddressMutation, { data, loading, error }] = useDeleteBillingAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBillingAddressMutation,
    DeleteBillingAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteBillingAddressMutation,
    DeleteBillingAddressMutationVariables
  >(DeleteBillingAddressDocument, baseOptions);
}
export type DeleteBillingAddressMutationHookResult = ReturnType<
  typeof useDeleteBillingAddressMutation
>;
export type DeleteBillingAddressMutationResult =
  Apollo.MutationResult<DeleteBillingAddressMutation>;
export type DeleteBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteBillingAddressMutation,
  DeleteBillingAddressMutationVariables
>;
export const GetAllReferralLinksDocument = gql`
  query getAllReferralLinks {
    GetAllReferredLinks {
      ...ReferredLink
    }
  }
  ${ReferredLinkFragmentDoc}
`;

/**
 * __useGetAllReferralLinksQuery__
 *
 * To run a query within a React component, call `useGetAllReferralLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReferralLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReferralLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllReferralLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllReferralLinksQuery,
    GetAllReferralLinksQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAllReferralLinksQuery,
    GetAllReferralLinksQueryVariables
  >(GetAllReferralLinksDocument, baseOptions);
}
export function useGetAllReferralLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllReferralLinksQuery,
    GetAllReferralLinksQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllReferralLinksQuery,
    GetAllReferralLinksQueryVariables
  >(GetAllReferralLinksDocument, baseOptions);
}
export type GetAllReferralLinksQueryHookResult = ReturnType<
  typeof useGetAllReferralLinksQuery
>;
export type GetAllReferralLinksLazyQueryHookResult = ReturnType<
  typeof useGetAllReferralLinksLazyQuery
>;
export type GetAllReferralLinksQueryResult = Apollo.QueryResult<
  GetAllReferralLinksQuery,
  GetAllReferralLinksQueryVariables
>;
export const GetReferralLinkByCodeDocument = gql`
  query getReferralLinkByCode($code: String!) {
    GetReferredLinkByCode(code: $code) {
      ...ReferredLink
    }
  }
  ${ReferredLinkFragmentDoc}
`;

/**
 * __useGetReferralLinkByCodeQuery__
 *
 * To run a query within a React component, call `useGetReferralLinkByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralLinkByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralLinkByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetReferralLinkByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReferralLinkByCodeQuery,
    GetReferralLinkByCodeQueryVariables
  >
) {
  return Apollo.useQuery<
    GetReferralLinkByCodeQuery,
    GetReferralLinkByCodeQueryVariables
  >(GetReferralLinkByCodeDocument, baseOptions);
}
export function useGetReferralLinkByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferralLinkByCodeQuery,
    GetReferralLinkByCodeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetReferralLinkByCodeQuery,
    GetReferralLinkByCodeQueryVariables
  >(GetReferralLinkByCodeDocument, baseOptions);
}
export type GetReferralLinkByCodeQueryHookResult = ReturnType<
  typeof useGetReferralLinkByCodeQuery
>;
export type GetReferralLinkByCodeLazyQueryHookResult = ReturnType<
  typeof useGetReferralLinkByCodeLazyQuery
>;
export type GetReferralLinkByCodeQueryResult = Apollo.QueryResult<
  GetReferralLinkByCodeQuery,
  GetReferralLinkByCodeQueryVariables
>;
export const GetReferredLinkByIdDocument = gql`
  query getReferredLinkById($id: ID!) {
    GetReferredLink(id: $id) {
      ...ReferredLink
    }
  }
  ${ReferredLinkFragmentDoc}
`;

/**
 * __useGetReferredLinkByIdQuery__
 *
 * To run a query within a React component, call `useGetReferredLinkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferredLinkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferredLinkByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReferredLinkByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReferredLinkByIdQuery,
    GetReferredLinkByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetReferredLinkByIdQuery,
    GetReferredLinkByIdQueryVariables
  >(GetReferredLinkByIdDocument, baseOptions);
}
export function useGetReferredLinkByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferredLinkByIdQuery,
    GetReferredLinkByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetReferredLinkByIdQuery,
    GetReferredLinkByIdQueryVariables
  >(GetReferredLinkByIdDocument, baseOptions);
}
export type GetReferredLinkByIdQueryHookResult = ReturnType<
  typeof useGetReferredLinkByIdQuery
>;
export type GetReferredLinkByIdLazyQueryHookResult = ReturnType<
  typeof useGetReferredLinkByIdLazyQuery
>;
export type GetReferredLinkByIdQueryResult = Apollo.QueryResult<
  GetReferredLinkByIdQuery,
  GetReferredLinkByIdQueryVariables
>;
export const GetReferredLinksByUserIdDocument = gql`
  query getReferredLinksByUserId($userId: ID!) {
    GetMyReferrals(userId: $userId) {
      ...ReferredLink
    }
  }
  ${ReferredLinkFragmentDoc}
`;

/**
 * __useGetReferredLinksByUserIdQuery__
 *
 * To run a query within a React component, call `useGetReferredLinksByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferredLinksByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferredLinksByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReferredLinksByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReferredLinksByUserIdQuery,
    GetReferredLinksByUserIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetReferredLinksByUserIdQuery,
    GetReferredLinksByUserIdQueryVariables
  >(GetReferredLinksByUserIdDocument, baseOptions);
}
export function useGetReferredLinksByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferredLinksByUserIdQuery,
    GetReferredLinksByUserIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetReferredLinksByUserIdQuery,
    GetReferredLinksByUserIdQueryVariables
  >(GetReferredLinksByUserIdDocument, baseOptions);
}
export type GetReferredLinksByUserIdQueryHookResult = ReturnType<
  typeof useGetReferredLinksByUserIdQuery
>;
export type GetReferredLinksByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetReferredLinksByUserIdLazyQuery
>;
export type GetReferredLinksByUserIdQueryResult = Apollo.QueryResult<
  GetReferredLinksByUserIdQuery,
  GetReferredLinksByUserIdQueryVariables
>;
export const SendReferralInviteDocument = gql`
  query sendReferralInvite($userId: ID!, $email: String!, $name: String!) {
    SendEmailReferralInvite(userId: $userId, email: $email, name: $name)
  }
`;

/**
 * __useSendReferralInviteQuery__
 *
 * To run a query within a React component, call `useSendReferralInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendReferralInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendReferralInviteQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSendReferralInviteQuery(
  baseOptions: Apollo.QueryHookOptions<
    SendReferralInviteQuery,
    SendReferralInviteQueryVariables
  >
) {
  return Apollo.useQuery<
    SendReferralInviteQuery,
    SendReferralInviteQueryVariables
  >(SendReferralInviteDocument, baseOptions);
}
export function useSendReferralInviteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendReferralInviteQuery,
    SendReferralInviteQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SendReferralInviteQuery,
    SendReferralInviteQueryVariables
  >(SendReferralInviteDocument, baseOptions);
}
export type SendReferralInviteQueryHookResult = ReturnType<
  typeof useSendReferralInviteQuery
>;
export type SendReferralInviteLazyQueryHookResult = ReturnType<
  typeof useSendReferralInviteLazyQuery
>;
export type SendReferralInviteQueryResult = Apollo.QueryResult<
  SendReferralInviteQuery,
  SendReferralInviteQueryVariables
>;
export const SensReferalRewardEmailDocument = gql`
  query sensReferalRewardEmail($email: String!, $name: String!) {
    SendEmailReferralGift(referredByEmail: $email, referredName: $name)
  }
`;

/**
 * __useSensReferalRewardEmailQuery__
 *
 * To run a query within a React component, call `useSensReferalRewardEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensReferalRewardEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensReferalRewardEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSensReferalRewardEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    SensReferalRewardEmailQuery,
    SensReferalRewardEmailQueryVariables
  >
) {
  return Apollo.useQuery<
    SensReferalRewardEmailQuery,
    SensReferalRewardEmailQueryVariables
  >(SensReferalRewardEmailDocument, baseOptions);
}
export function useSensReferalRewardEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SensReferalRewardEmailQuery,
    SensReferalRewardEmailQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SensReferalRewardEmailQuery,
    SensReferalRewardEmailQueryVariables
  >(SensReferalRewardEmailDocument, baseOptions);
}
export type SensReferalRewardEmailQueryHookResult = ReturnType<
  typeof useSensReferalRewardEmailQuery
>;
export type SensReferalRewardEmailLazyQueryHookResult = ReturnType<
  typeof useSensReferalRewardEmailLazyQuery
>;
export type SensReferalRewardEmailQueryResult = Apollo.QueryResult<
  SensReferalRewardEmailQuery,
  SensReferalRewardEmailQueryVariables
>;
export const GetReferralLevelDocument = gql`
  query getReferralLevel($userId: ID!) {
    GetReferralLevel(userId: $userId) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetReferralLevelQuery__
 *
 * To run a query within a React component, call `useGetReferralLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralLevelQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReferralLevelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReferralLevelQuery,
    GetReferralLevelQueryVariables
  >
) {
  return Apollo.useQuery<GetReferralLevelQuery, GetReferralLevelQueryVariables>(
    GetReferralLevelDocument,
    baseOptions
  );
}
export function useGetReferralLevelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferralLevelQuery,
    GetReferralLevelQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetReferralLevelQuery,
    GetReferralLevelQueryVariables
  >(GetReferralLevelDocument, baseOptions);
}
export type GetReferralLevelQueryHookResult = ReturnType<
  typeof useGetReferralLevelQuery
>;
export type GetReferralLevelLazyQueryHookResult = ReturnType<
  typeof useGetReferralLevelLazyQuery
>;
export type GetReferralLevelQueryResult = Apollo.QueryResult<
  GetReferralLevelQuery,
  GetReferralLevelQueryVariables
>;
export const SetReferralLinkDocument = gql`
  mutation setReferralLink($userId: ID!, $referralLinkId: ID!) {
    UseReferredLink(userId: $userId, referralLinkId: $referralLinkId)
  }
`;
export type SetReferralLinkMutationFn = Apollo.MutationFunction<
  SetReferralLinkMutation,
  SetReferralLinkMutationVariables
>;

/**
 * __useSetReferralLinkMutation__
 *
 * To run a mutation, you first call `useSetReferralLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReferralLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReferralLinkMutation, { data, loading, error }] = useSetReferralLinkMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      referralLinkId: // value for 'referralLinkId'
 *   },
 * });
 */
export function useSetReferralLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetReferralLinkMutation,
    SetReferralLinkMutationVariables
  >
) {
  return Apollo.useMutation<
    SetReferralLinkMutation,
    SetReferralLinkMutationVariables
  >(SetReferralLinkDocument, baseOptions);
}
export type SetReferralLinkMutationHookResult = ReturnType<
  typeof useSetReferralLinkMutation
>;
export type SetReferralLinkMutationResult =
  Apollo.MutationResult<SetReferralLinkMutation>;
export type SetReferralLinkMutationOptions = Apollo.BaseMutationOptions<
  SetReferralLinkMutation,
  SetReferralLinkMutationVariables
>;
export const CreateRewardCouponDocument = gql`
  mutation createRewardCoupon(
    $couponId: String!
    $referralLinkId: ID!
    $name: String!
    $description: String!
    $percentage: Int!
    $validTill: String!
    $audionautPlanId: String!
    $maxRedemptions: Int = 1
  ) {
    createReferredCoupon(
      couponId: $couponId
      referralLinkId: $referralLinkId
      name: $name
      description: $description
      percentage: $percentage
      validTill: $validTill
      audionautPlanId: $audionautPlanId
      maxRedemptions: $maxRedemptions
    ) {
      ...AudionautCouponFragment
    }
  }
  ${AudionautCouponFragmentFragmentDoc}
`;
export type CreateRewardCouponMutationFn = Apollo.MutationFunction<
  CreateRewardCouponMutation,
  CreateRewardCouponMutationVariables
>;

/**
 * __useCreateRewardCouponMutation__
 *
 * To run a mutation, you first call `useCreateRewardCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRewardCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRewardCouponMutation, { data, loading, error }] = useCreateRewardCouponMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *      referralLinkId: // value for 'referralLinkId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      percentage: // value for 'percentage'
 *      validTill: // value for 'validTill'
 *      audionautPlanId: // value for 'audionautPlanId'
 *      maxRedemptions: // value for 'maxRedemptions'
 *   },
 * });
 */
export function useCreateRewardCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRewardCouponMutation,
    CreateRewardCouponMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateRewardCouponMutation,
    CreateRewardCouponMutationVariables
  >(CreateRewardCouponDocument, baseOptions);
}
export type CreateRewardCouponMutationHookResult = ReturnType<
  typeof useCreateRewardCouponMutation
>;
export type CreateRewardCouponMutationResult =
  Apollo.MutationResult<CreateRewardCouponMutation>;
export type CreateRewardCouponMutationOptions = Apollo.BaseMutationOptions<
  CreateRewardCouponMutation,
  CreateRewardCouponMutationVariables
>;
export const UpdateSubscriptionWithCouponDocument = gql`
  mutation updateSubscriptionWithCoupon($planId: String!, $couponId: String!) {
    UpdateSubscriptionWithACoupon(
      AudionautPlanID: $planId
      couponID: $couponId
    ) {
      ...OrganisationDataFragment
    }
  }
  ${OrganisationDataFragmentFragmentDoc}
`;
export type UpdateSubscriptionWithCouponMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionWithCouponMutation,
  UpdateSubscriptionWithCouponMutationVariables
>;

/**
 * __useUpdateSubscriptionWithCouponMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionWithCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionWithCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionWithCouponMutation, { data, loading, error }] = useUpdateSubscriptionWithCouponMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useUpdateSubscriptionWithCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionWithCouponMutation,
    UpdateSubscriptionWithCouponMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateSubscriptionWithCouponMutation,
    UpdateSubscriptionWithCouponMutationVariables
  >(UpdateSubscriptionWithCouponDocument, baseOptions);
}
export type UpdateSubscriptionWithCouponMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionWithCouponMutation
>;
export type UpdateSubscriptionWithCouponMutationResult =
  Apollo.MutationResult<UpdateSubscriptionWithCouponMutation>;
export type UpdateSubscriptionWithCouponMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSubscriptionWithCouponMutation,
    UpdateSubscriptionWithCouponMutationVariables
  >;
export const DeleteReferralLinkDocument = gql`
  mutation deleteReferralLink($referralLinkId: ID!) {
    DeleteReferredLink(referralLinkId: $referralLinkId)
  }
`;
export type DeleteReferralLinkMutationFn = Apollo.MutationFunction<
  DeleteReferralLinkMutation,
  DeleteReferralLinkMutationVariables
>;

/**
 * __useDeleteReferralLinkMutation__
 *
 * To run a mutation, you first call `useDeleteReferralLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferralLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferralLinkMutation, { data, loading, error }] = useDeleteReferralLinkMutation({
 *   variables: {
 *      referralLinkId: // value for 'referralLinkId'
 *   },
 * });
 */
export function useDeleteReferralLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReferralLinkMutation,
    DeleteReferralLinkMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteReferralLinkMutation,
    DeleteReferralLinkMutationVariables
  >(DeleteReferralLinkDocument, baseOptions);
}
export type DeleteReferralLinkMutationHookResult = ReturnType<
  typeof useDeleteReferralLinkMutation
>;
export type DeleteReferralLinkMutationResult =
  Apollo.MutationResult<DeleteReferralLinkMutation>;
export type DeleteReferralLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteReferralLinkMutation,
  DeleteReferralLinkMutationVariables
>;
export const UpdateReferralLevelDocument = gql`
  mutation updateReferralLevel($userId: ID!, $percentage: Int!) {
    UpdateReferralLevel(userId: $userId, level: $percentage) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateReferralLevelMutationFn = Apollo.MutationFunction<
  UpdateReferralLevelMutation,
  UpdateReferralLevelMutationVariables
>;

/**
 * __useUpdateReferralLevelMutation__
 *
 * To run a mutation, you first call `useUpdateReferralLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralLevelMutation, { data, loading, error }] = useUpdateReferralLevelMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      percentage: // value for 'percentage'
 *   },
 * });
 */
export function useUpdateReferralLevelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferralLevelMutation,
    UpdateReferralLevelMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateReferralLevelMutation,
    UpdateReferralLevelMutationVariables
  >(UpdateReferralLevelDocument, baseOptions);
}
export type UpdateReferralLevelMutationHookResult = ReturnType<
  typeof useUpdateReferralLevelMutation
>;
export type UpdateReferralLevelMutationResult =
  Apollo.MutationResult<UpdateReferralLevelMutation>;
export type UpdateReferralLevelMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferralLevelMutation,
  UpdateReferralLevelMutationVariables
>;
export const UpdateReferredStatusDocument = gql`
  mutation updateReferredStatus($referralLinkId: ID!, $status: String!) {
    UpdateReferredStatus(referralLinkId: $referralLinkId, linkStatus: $status) {
      ...ReferredLink
    }
  }
  ${ReferredLinkFragmentDoc}
`;
export type UpdateReferredStatusMutationFn = Apollo.MutationFunction<
  UpdateReferredStatusMutation,
  UpdateReferredStatusMutationVariables
>;

/**
 * __useUpdateReferredStatusMutation__
 *
 * To run a mutation, you first call `useUpdateReferredStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferredStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferredStatusMutation, { data, loading, error }] = useUpdateReferredStatusMutation({
 *   variables: {
 *      referralLinkId: // value for 'referralLinkId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateReferredStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferredStatusMutation,
    UpdateReferredStatusMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateReferredStatusMutation,
    UpdateReferredStatusMutationVariables
  >(UpdateReferredStatusDocument, baseOptions);
}
export type UpdateReferredStatusMutationHookResult = ReturnType<
  typeof useUpdateReferredStatusMutation
>;
export type UpdateReferredStatusMutationResult =
  Apollo.MutationResult<UpdateReferredStatusMutation>;
export type UpdateReferredStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferredStatusMutation,
  UpdateReferredStatusMutationVariables
>;
export const GetNoteDocument = gql`
  query getNote($id: ID, $rID: String, $orderBy: String, $offset: String) {
    GetNote(id: $id, rID: $rID, orderBy: $orderBy, offset: $offset) {
      ...NoteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *      rID: // value for 'rID'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNoteQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    baseOptions
  );
}
export function useGetNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    baseOptions
  );
}
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<
  GetNoteQuery,
  GetNoteQueryVariables
>;
export const AddNoteDocument = gql`
  mutation AddNote(
    $comment: String!
    $rID: String!
    $attachments: [NoteAttachmentFile!]
  ) {
    AddNote(comment: $comment, rID: $rID, attachments: $attachments) {
      ...NoteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;
export type AddNoteMutationFn = Apollo.MutationFunction<
  AddNoteMutation,
  AddNoteMutationVariables
>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      rID: // value for 'rID'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNoteMutation,
    AddNoteMutationVariables
  >
) {
  return Apollo.useMutation<AddNoteMutation, AddNoteMutationVariables>(
    AddNoteDocument,
    baseOptions
  );
}
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = Apollo.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = Apollo.BaseMutationOptions<
  AddNoteMutation,
  AddNoteMutationVariables
>;
export const GetFileDocument = gql`
  query GetFile($folder: String!, $filename: String!) {
    GetFile(fileLocations: [{ folder: $folder, filename: $filename }]) {
      filename
      content
    }
  }
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      folder: // value for 'folder'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetFileQuery(
  baseOptions: Apollo.QueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
  return Apollo.useQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export function useGetFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
  return Apollo.useLazyQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileQueryResult = Apollo.QueryResult<
  GetFileQuery,
  GetFileQueryVariables
>;
export const GetCurrentUserOrganisationDocument = gql`
  query getCurrentUserOrganisation {
    getCurrentUserOrganisation {
      ...OrganisationDataFragment
    }
  }
  ${OrganisationDataFragmentFragmentDoc}
`;

/**
 * __useGetCurrentUserOrganisationQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserOrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserOrganisationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserOrganisationQuery,
    GetCurrentUserOrganisationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserOrganisationQuery,
    GetCurrentUserOrganisationQueryVariables
  >(GetCurrentUserOrganisationDocument, baseOptions);
}
export function useGetCurrentUserOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserOrganisationQuery,
    GetCurrentUserOrganisationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserOrganisationQuery,
    GetCurrentUserOrganisationQueryVariables
  >(GetCurrentUserOrganisationDocument, baseOptions);
}
export type GetCurrentUserOrganisationQueryHookResult = ReturnType<
  typeof useGetCurrentUserOrganisationQuery
>;
export type GetCurrentUserOrganisationLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserOrganisationLazyQuery
>;
export type GetCurrentUserOrganisationQueryResult = Apollo.QueryResult<
  GetCurrentUserOrganisationQuery,
  GetCurrentUserOrganisationQueryVariables
>;
export const GenerateStripeClientSecretDocument = gql`
  query generateStripeClientSecret {
    generateStripeClientSecret
  }
`;

/**
 * __useGenerateStripeClientSecretQuery__
 *
 * To run a query within a React component, call `useGenerateStripeClientSecretQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateStripeClientSecretQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateStripeClientSecretQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateStripeClientSecretQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenerateStripeClientSecretQuery,
    GenerateStripeClientSecretQueryVariables
  >
) {
  return Apollo.useQuery<
    GenerateStripeClientSecretQuery,
    GenerateStripeClientSecretQueryVariables
  >(GenerateStripeClientSecretDocument, baseOptions);
}
export function useGenerateStripeClientSecretLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateStripeClientSecretQuery,
    GenerateStripeClientSecretQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GenerateStripeClientSecretQuery,
    GenerateStripeClientSecretQueryVariables
  >(GenerateStripeClientSecretDocument, baseOptions);
}
export type GenerateStripeClientSecretQueryHookResult = ReturnType<
  typeof useGenerateStripeClientSecretQuery
>;
export type GenerateStripeClientSecretLazyQueryHookResult = ReturnType<
  typeof useGenerateStripeClientSecretLazyQuery
>;
export type GenerateStripeClientSecretQueryResult = Apollo.QueryResult<
  GenerateStripeClientSecretQuery,
  GenerateStripeClientSecretQueryVariables
>;
export const CreatePaymentMethodDocument = gql`
  mutation createPaymentMethod(
    $stripePaymentMethodId: String!
    $isDefault: Boolean!
    $holderName: String!
  ) {
    createPaymentMethod(
      stripePaymentMethodId: $stripePaymentMethodId
      isDefault: $isDefault
      cardOwnerName: $holderName
    ) {
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragmentFragmentDoc}
`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *      isDefault: // value for 'isDefault'
 *      holderName: // value for 'holderName'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >(CreatePaymentMethodDocument, baseOptions);
}
export type CreatePaymentMethodMutationHookResult = ReturnType<
  typeof useCreatePaymentMethodMutation
>;
export type CreatePaymentMethodMutationResult =
  Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const EditPaymentMethodDocument = gql`
  mutation editPaymentMethod($id: ID!, $isDefault: Boolean!) {
    editPaymentMethod(id: $id, isDefault: $isDefault) {
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragmentFragmentDoc}
`;
export type EditPaymentMethodMutationFn = Apollo.MutationFunction<
  EditPaymentMethodMutation,
  EditPaymentMethodMutationVariables
>;

/**
 * __useEditPaymentMethodMutation__
 *
 * To run a mutation, you first call `useEditPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPaymentMethodMutation, { data, loading, error }] = useEditPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useEditPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPaymentMethodMutation,
    EditPaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    EditPaymentMethodMutation,
    EditPaymentMethodMutationVariables
  >(EditPaymentMethodDocument, baseOptions);
}
export type EditPaymentMethodMutationHookResult = ReturnType<
  typeof useEditPaymentMethodMutation
>;
export type EditPaymentMethodMutationResult =
  Apollo.MutationResult<EditPaymentMethodMutation>;
export type EditPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  EditPaymentMethodMutation,
  EditPaymentMethodMutationVariables
>;
export const DeletePaymentMethodDocument = gql`
  mutation deletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id)
  }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >(DeletePaymentMethodDocument, baseOptions);
}
export type DeletePaymentMethodMutationHookResult = ReturnType<
  typeof useDeletePaymentMethodMutation
>;
export type DeletePaymentMethodMutationResult =
  Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const GetPermissionsDocument = gql`
  query GetPermissions {
    GetPermissions {
      ...PermissionFragment
    }
  }
  ${PermissionFragmentFragmentDoc}
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    baseOptions
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    baseOptions
  );
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const GetUserProfileDocument = gql`
  query GetUserProfile($email: String!) {
    GetUser(email: $email) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    baseOptions
  );
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    baseOptions
  );
}
export type GetUserProfileQueryHookResult = ReturnType<
  typeof useGetUserProfileQuery
>;
export type GetUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileLazyQuery
>;
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
export const GetCurrentUserProfileDocument = gql`
  query GetCurrentUserProfile {
    GetCurrentUserProfile {
      id
      email
      firstName
      lastName
      profilePicture
      phone
      departmentName
      FindOutAudionaut
      groups {
        ...GroupFragment
      }
      work {
        address
        phone
      }
      organisation {
        id
        name
      }
      department {
        id
        name
      }
    }
  }
  ${GroupFragmentFragmentDoc}
`;

/**
 * __useGetCurrentUserProfileQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >(GetCurrentUserProfileDocument, baseOptions);
}
export function useGetCurrentUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >(GetCurrentUserProfileDocument, baseOptions);
}
export type GetCurrentUserProfileQueryHookResult = ReturnType<
  typeof useGetCurrentUserProfileQuery
>;
export type GetCurrentUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserProfileLazyQuery
>;
export type GetCurrentUserProfileQueryResult = Apollo.QueryResult<
  GetCurrentUserProfileQuery,
  GetCurrentUserProfileQueryVariables
>;
export const GetDepartmentDocument = gql`
  query getDepartment {
    GetDepartment {
      id
      name
    }
  }
`;

/**
 * __useGetDepartmentQuery__
 *
 * To run a query within a React component, call `useGetDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDepartmentQuery,
    GetDepartmentQueryVariables
  >
) {
  return Apollo.useQuery<GetDepartmentQuery, GetDepartmentQueryVariables>(
    GetDepartmentDocument,
    baseOptions
  );
}
export function useGetDepartmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepartmentQuery,
    GetDepartmentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDepartmentQuery, GetDepartmentQueryVariables>(
    GetDepartmentDocument,
    baseOptions
  );
}
export type GetDepartmentQueryHookResult = ReturnType<
  typeof useGetDepartmentQuery
>;
export type GetDepartmentLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentLazyQuery
>;
export type GetDepartmentQueryResult = Apollo.QueryResult<
  GetDepartmentQuery,
  GetDepartmentQueryVariables
>;
export const GetOrganisationDocument = gql`
  query getOrganisation {
    GetOrganisation {
      id
      name
    }
  }
`;

/**
 * __useGetOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganisationQuery,
    GetOrganisationQueryVariables
  >
) {
  return Apollo.useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(
    GetOrganisationDocument,
    baseOptions
  );
}
export function useGetOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationQuery,
    GetOrganisationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOrganisationQuery,
    GetOrganisationQueryVariables
  >(GetOrganisationDocument, baseOptions);
}
export type GetOrganisationQueryHookResult = ReturnType<
  typeof useGetOrganisationQuery
>;
export type GetOrganisationLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationLazyQuery
>;
export type GetOrganisationQueryResult = Apollo.QueryResult<
  GetOrganisationQuery,
  GetOrganisationQueryVariables
>;
export const UpdateUserDataDocument = gql`
  mutation UpdateUserData(
    $id: ID!
    $firstName: String
    $lastName: String
    $active: Boolean
    $profilePicture: String
    $email: String
    $password: String
    $departmentName: String
    $FindOutAudionaut: String
  ) {
    UpdateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      active: $active
      profilePicture: $profilePicture
      email: $email
      password: $password
      departmentName: $departmentName
      FindOutAudionaut: $FindOutAudionaut
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserDataMutationFn = Apollo.MutationFunction<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      active: // value for 'active'
 *      profilePicture: // value for 'profilePicture'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      departmentName: // value for 'departmentName'
 *      FindOutAudionaut: // value for 'FindOutAudionaut'
 *   },
 * });
 */
export function useUpdateUserDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDataMutation,
    UpdateUserDataMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserDataMutation,
    UpdateUserDataMutationVariables
  >(UpdateUserDataDocument, baseOptions);
}
export type UpdateUserDataMutationHookResult = ReturnType<
  typeof useUpdateUserDataMutation
>;
export type UpdateUserDataMutationResult =
  Apollo.MutationResult<UpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;
export const UpdateUserOrganisationDocument = gql`
  mutation UpdateUserOrganisation($userID: ID!, $organisationID: ID) {
    UpdateUserOrganisation(userID: $userID, organisationID: $organisationID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserOrganisationMutationFn = Apollo.MutationFunction<
  UpdateUserOrganisationMutation,
  UpdateUserOrganisationMutationVariables
>;

/**
 * __useUpdateUserOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateUserOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOrganisationMutation, { data, loading, error }] = useUpdateUserOrganisationMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      organisationID: // value for 'organisationID'
 *   },
 * });
 */
export function useUpdateUserOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserOrganisationMutation,
    UpdateUserOrganisationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserOrganisationMutation,
    UpdateUserOrganisationMutationVariables
  >(UpdateUserOrganisationDocument, baseOptions);
}
export type UpdateUserOrganisationMutationHookResult = ReturnType<
  typeof useUpdateUserOrganisationMutation
>;
export type UpdateUserOrganisationMutationResult =
  Apollo.MutationResult<UpdateUserOrganisationMutation>;
export type UpdateUserOrganisationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserOrganisationMutation,
  UpdateUserOrganisationMutationVariables
>;
export const UpdateUserDeparmentDocument = gql`
  mutation UpdateUserDeparment($userID: ID!, $departmentID: ID) {
    UpdateUserDepartment(userID: $userID, departmentID: $departmentID) {
      id
    }
  }
`;
export type UpdateUserDeparmentMutationFn = Apollo.MutationFunction<
  UpdateUserDeparmentMutation,
  UpdateUserDeparmentMutationVariables
>;

/**
 * __useUpdateUserDeparmentMutation__
 *
 * To run a mutation, you first call `useUpdateUserDeparmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDeparmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDeparmentMutation, { data, loading, error }] = useUpdateUserDeparmentMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      departmentID: // value for 'departmentID'
 *   },
 * });
 */
export function useUpdateUserDeparmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDeparmentMutation,
    UpdateUserDeparmentMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserDeparmentMutation,
    UpdateUserDeparmentMutationVariables
  >(UpdateUserDeparmentDocument, baseOptions);
}
export type UpdateUserDeparmentMutationHookResult = ReturnType<
  typeof useUpdateUserDeparmentMutation
>;
export type UpdateUserDeparmentMutationResult =
  Apollo.MutationResult<UpdateUserDeparmentMutation>;
export type UpdateUserDeparmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDeparmentMutation,
  UpdateUserDeparmentMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $active: Boolean!
    $profilePicture: String!
    $departmentName: String!
    $password: String!
    $organisationID: ID!
    $phone: String!
  ) {
    AddUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      active: $active
      profilePicture: $profilePicture
      departmentName: $departmentName
      password: $password
      organisationID: $organisationID
      phone: $phone
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      active: // value for 'active'
 *      profilePicture: // value for 'profilePicture'
 *      departmentName: // value for 'departmentName'
 *      password: // value for 'password'
 *      organisationID: // value for 'organisationID'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    baseOptions
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($userID: ID!) {
    DeleteUser(id: $userID)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    baseOptions
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const AddUserGroupDocument = gql`
  mutation AddUserGroup($userID: ID!, $groupID: [ID]!) {
    AddUserGroup(userID: $userID, groupID: $groupID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type AddUserGroupMutationFn = Apollo.MutationFunction<
  AddUserGroupMutation,
  AddUserGroupMutationVariables
>;

/**
 * __useAddUserGroupMutation__
 *
 * To run a mutation, you first call `useAddUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserGroupMutation, { data, loading, error }] = useAddUserGroupMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      groupID: // value for 'groupID'
 *   },
 * });
 */
export function useAddUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserGroupMutation,
    AddUserGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    AddUserGroupMutation,
    AddUserGroupMutationVariables
  >(AddUserGroupDocument, baseOptions);
}
export type AddUserGroupMutationHookResult = ReturnType<
  typeof useAddUserGroupMutation
>;
export type AddUserGroupMutationResult =
  Apollo.MutationResult<AddUserGroupMutation>;
export type AddUserGroupMutationOptions = Apollo.BaseMutationOptions<
  AddUserGroupMutation,
  AddUserGroupMutationVariables
>;
export const UpdateUserGroupDocument = gql`
  mutation UpdateUserGroup(
    $userID: ID!
    $groupID: [ID!]!
    $groupIDNew: [ID!]!
  ) {
    DeleteUserGroup(userID: $userID, groupID: $groupID) {
      ...UserFragment
    }
    AddUserGroup(userID: $userID, groupID: $groupIDNew) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      groupID: // value for 'groupID'
 *      groupIDNew: // value for 'groupIDNew'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >(UpdateUserGroupDocument, baseOptions);
}
export type UpdateUserGroupMutationHookResult = ReturnType<
  typeof useUpdateUserGroupMutation
>;
export type UpdateUserGroupMutationResult =
  Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const DeleteUserGroupDocument = gql`
  mutation DeleteUserGroup($userID: ID!, $groupID: [ID!]!) {
    DeleteUserGroup(userID: $userID, groupID: $groupID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      groupID: // value for 'groupID'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >(DeleteUserGroupDocument, baseOptions);
}
export type DeleteUserGroupMutationHookResult = ReturnType<
  typeof useDeleteUserGroupMutation
>;
export type DeleteUserGroupMutationResult =
  Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;
export const GetGroupDocument = gql`
  query getGroup {
    GetGroup {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>
) {
  return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    baseOptions
  );
}
export function useGetGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupQuery,
    GetGroupQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    baseOptions
  );
}
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<
  typeof useGetGroupLazyQuery
>;
export type GetGroupQueryResult = Apollo.QueryResult<
  GetGroupQuery,
  GetGroupQueryVariables
>;
export const GetAllUsersDocument = gql`
  query GetAllUsers($organisationID: ID!) {
    GetUser(organisationID: $organisationID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    baseOptions
  );
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    baseOptions
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersLazyQuery
>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const AddRoleDocument = gql`
  mutation AddRole(
    $name: String!
    $description: String!
    $visibleLevel: Int!
    $permissionInputs: [PermissionInput]
  ) {
    AddRole(
      name: $name
      description: $description
      visibleLevel: $visibleLevel
      permissionInputs: $permissionInputs
    ) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;
export type AddRoleMutationFn = Apollo.MutationFunction<
  AddRoleMutation,
  AddRoleMutationVariables
>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      visibleLevel: // value for 'visibleLevel'
 *      permissionInputs: // value for 'permissionInputs'
 *   },
 * });
 */
export function useAddRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRoleMutation,
    AddRoleMutationVariables
  >
) {
  return Apollo.useMutation<AddRoleMutation, AddRoleMutationVariables>(
    AddRoleDocument,
    baseOptions
  );
}
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<
  AddRoleMutation,
  AddRoleMutationVariables
>;
export const GetRoleDocument = gql`
  query GetRole {
    GetRole {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    baseOptions
  );
}
export function useGetRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    baseOptions
  );
}
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<
  GetRoleQuery,
  GetRoleQueryVariables
>;
export const UpdateRoleDocument = gql`
  mutation UpdateRole(
    $id: ID!
    $name: String!
    $description: String!
    $visibleLevel: Int!
    $permissionInputs: [PermissionInput]
  ) {
    UpdateRole(
      id: $id
      name: $name
      description: $description
      visibleLevel: $visibleLevel
      permissionInputs: $permissionInputs
    ) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      visibleLevel: // value for 'visibleLevel'
 *      permissionInputs: // value for 'permissionInputs'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >
) {
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    baseOptions
  );
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
  Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const UpdatePermissionDocument = gql`
  mutation UpdatePermission(
    $roleId: ID!
    $permissionInputs: [PermissionInput]
  ) {
    UpdateRole(id: $roleId, permissionInputs: $permissionInputs) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      permissionInputs: // value for 'permissionInputs'
 *   },
 * });
 */
export function useUpdatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >(UpdatePermissionDocument, baseOptions);
}
export type UpdatePermissionMutationHookResult = ReturnType<
  typeof useUpdatePermissionMutation
>;
export type UpdatePermissionMutationResult =
  Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;
export const DeleteRoleDocument = gql`
  mutation DeleteRole($id: ID!) {
    DeleteRole(id: $id)
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >
) {
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(
    DeleteRoleDocument,
    baseOptions
  );
}
export type DeleteRoleMutationHookResult = ReturnType<
  typeof useDeleteRoleMutation
>;
export type DeleteRoleMutationResult =
  Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;
export const AddGroupDocument = gql`
  mutation AddGroup($name: String!, $description: String!) {
    AddGroup(name: $name, description: $description) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type AddGroupMutationFn = Apollo.MutationFunction<
  AddGroupMutation,
  AddGroupMutationVariables
>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupMutation,
    AddGroupMutationVariables
  >
) {
  return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(
    AddGroupDocument,
    baseOptions
  );
}
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<
  AddGroupMutation,
  AddGroupMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation UpdateGroup($id: ID!, $name: String, $description: String) {
    UpdateGroup(id: $id, name: $name, description: $description) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    baseOptions
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation DeleteGroup($id: ID!) {
    DeleteGroup(id: $id)
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    baseOptions
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
  Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const AddGroupRoleDocument = gql`
  mutation AddGroupRole($groupID: ID!, $roleID: [ID]!) {
    AddGroupRole(groupID: $groupID, roleID: $roleID) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type AddGroupRoleMutationFn = Apollo.MutationFunction<
  AddGroupRoleMutation,
  AddGroupRoleMutationVariables
>;

/**
 * __useAddGroupRoleMutation__
 *
 * To run a mutation, you first call `useAddGroupRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupRoleMutation, { data, loading, error }] = useAddGroupRoleMutation({
 *   variables: {
 *      groupID: // value for 'groupID'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useAddGroupRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupRoleMutation,
    AddGroupRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    AddGroupRoleMutation,
    AddGroupRoleMutationVariables
  >(AddGroupRoleDocument, baseOptions);
}
export type AddGroupRoleMutationHookResult = ReturnType<
  typeof useAddGroupRoleMutation
>;
export type AddGroupRoleMutationResult =
  Apollo.MutationResult<AddGroupRoleMutation>;
export type AddGroupRoleMutationOptions = Apollo.BaseMutationOptions<
  AddGroupRoleMutation,
  AddGroupRoleMutationVariables
>;
export const DeleteGroupRoleDocument = gql`
  mutation DeleteGroupRole($groupID: ID!, $roleID: [ID!]!) {
    DeleteGroupRole(groupID: $groupID, roleID: $roleID) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type DeleteGroupRoleMutationFn = Apollo.MutationFunction<
  DeleteGroupRoleMutation,
  DeleteGroupRoleMutationVariables
>;

/**
 * __useDeleteGroupRoleMutation__
 *
 * To run a mutation, you first call `useDeleteGroupRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupRoleMutation, { data, loading, error }] = useDeleteGroupRoleMutation({
 *   variables: {
 *      groupID: // value for 'groupID'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteGroupRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupRoleMutation,
    DeleteGroupRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteGroupRoleMutation,
    DeleteGroupRoleMutationVariables
  >(DeleteGroupRoleDocument, baseOptions);
}
export type DeleteGroupRoleMutationHookResult = ReturnType<
  typeof useDeleteGroupRoleMutation
>;
export type DeleteGroupRoleMutationResult =
  Apollo.MutationResult<DeleteGroupRoleMutation>;
export type DeleteGroupRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupRoleMutation,
  DeleteGroupRoleMutationVariables
>;
export const GetCurrentUserPermissionsDocument = gql`
  query GetCurrentUserPermissions($type: PermissionType) {
    GetCurrentUserPermissions(type: $type) {
      ...PermissionFragment
    }
  }
  ${PermissionFragmentFragmentDoc}
`;

/**
 * __useGetCurrentUserPermissionsQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPermissionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetCurrentUserPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >(GetCurrentUserPermissionsDocument, baseOptions);
}
export function useGetCurrentUserPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >(GetCurrentUserPermissionsDocument, baseOptions);
}
export type GetCurrentUserPermissionsQueryHookResult = ReturnType<
  typeof useGetCurrentUserPermissionsQuery
>;
export type GetCurrentUserPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserPermissionsLazyQuery
>;
export type GetCurrentUserPermissionsQueryResult = Apollo.QueryResult<
  GetCurrentUserPermissionsQuery,
  GetCurrentUserPermissionsQueryVariables
>;
export const ApplyDataPermissionsDocument = gql`
  mutation ApplyDataPermissions {
    ApplyPermissions
  }
`;
export type ApplyDataPermissionsMutationFn = Apollo.MutationFunction<
  ApplyDataPermissionsMutation,
  ApplyDataPermissionsMutationVariables
>;

/**
 * __useApplyDataPermissionsMutation__
 *
 * To run a mutation, you first call `useApplyDataPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyDataPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyDataPermissionsMutation, { data, loading, error }] = useApplyDataPermissionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useApplyDataPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyDataPermissionsMutation,
    ApplyDataPermissionsMutationVariables
  >
) {
  return Apollo.useMutation<
    ApplyDataPermissionsMutation,
    ApplyDataPermissionsMutationVariables
  >(ApplyDataPermissionsDocument, baseOptions);
}
export type ApplyDataPermissionsMutationHookResult = ReturnType<
  typeof useApplyDataPermissionsMutation
>;
export type ApplyDataPermissionsMutationResult =
  Apollo.MutationResult<ApplyDataPermissionsMutation>;
export type ApplyDataPermissionsMutationOptions = Apollo.BaseMutationOptions<
  ApplyDataPermissionsMutation,
  ApplyDataPermissionsMutationVariables
>;
