import React from "react";
import { createStyles, Theme } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

const generalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    chartTinny: {
      display: "flex",
      width: "130px",
      height: "24px",
      margin: "auto",
      alignItems: "center",
      background: "rgba(90, 227, 194, 0.1)",
      borderRadius: "12px",
    },
    actionsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      [theme.breakpoints.down(540)]: {
        "& button": {
          margin: "unset",
        },
      },
    },
    sentimentChip: {
      width: "113px",
      height: "22px",
      display: "flex",
      borderRadius: "11px",
      alignItems: "center",
      justifyContent: "center",
    },
    status: {
      "&.DONE": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(90, 227, 194, 0.1)",
        color: "#5AE391",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
      "&.PENDING": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(118, 121, 135, 0.1)",
        color: "rgba(118, 121, 135, 1)",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
      "&.ERROR": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(254, 99, 119, 0.1)",
        color: "rgba(254, 99, 119, 1)",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
      "&.PROCESSING": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(250, 181, 117, 0.1)",
        color: "rgba(250, 181, 117, 1)",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
      "&.paid": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(90, 227, 194, 0.1)",
        color: "#5AE391",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
    },
    linkInCell: {
      color: "#A23A74",
      fontSize: "12px",
      lineHeight: "15px",
      textDecorationLine: "underline",
    },
    ellipsisText: {
      maxWidth: "300px",
      width: "calc(100vw / 7)",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  })
);

export default {
  generalStyles,
};
