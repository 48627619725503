/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import {
  PermissionData,
  PermissionInput,
  RoleData,
  PermissionType,
} from "graphql/types-and-hooks";
import React from "react";

// Components
import Pane from "components/pane/pane.component";
import TextField from "components/text-field/text-field.component";
import DateTimeField from "components/datetime-field/datetime-field.component";
import PermissionDetails from "components/permission-details/permission-details.component";
import Checkbox from "@material-ui/core/Checkbox";
import {
  useAppPermissionValidator,
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// Utils
import { createEmptyRoleData } from "graphql/rbac.utils";

// Assets
import SC from "./role-form-fields.styles";

interface Props {
  roleData: RoleData;
  permissionList: PermissionData[];
  isEditable: boolean;
  resetData: boolean;
  onRoleChange: (roleData: RoleData) => void;
  onPermissionsChange: (
    permissionInput: PermissionInput[],
    permissionType: PermissionType
  ) => void;
  cleanPermissionsChangedList: boolean;
}

const RoleFormFields: React.FC<Props> = ({
  roleData,
  permissionList,
  isEditable,
  resetData,
  onRoleChange,
  onPermissionsChange,
  cleanPermissionsChangedList,
}) => {
  const [permissions, setPermissions] = React.useState(permissionList);

  const visibility = roleData.visibleLevel !== 0;
  const [visibilityEnable, setVisibilityEnable] = React.useState(visibility);

  const [permissionsData, permissionsApplication] = React.useMemo(() => {
    const permsData: PermissionData[] = [];
    const permsApp: PermissionData[] = [];
    permissions?.forEach((opt) => {
      if (opt.type === PermissionType.Data) permsData.push(opt);
      else permsApp.push(opt);
    });
    return [permsData, permsApp];
  }, [permissions]);

  const isApp = permissionsApplication.some((perm) => perm.enabled === true);
  const defaultValue = isApp ? PermissionType.Application : PermissionType.Data;

  const [permissionTypeActive, setPermissionTypeActive] =
    React.useState(defaultValue);

  React.useEffect(() => {
    if (resetData) {
      setPermissions(permissionList);
    }
  }, [resetData, permissionList, onPermissionsChange]);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newRoleData = { ...roleData };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newRoleData[name] = value;
    onRoleChange(newRoleData);
  };

  const handleRadioOption = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPermissionTypeActive(event.target.value as PermissionType);
    },
    []
  );

  const handleParentPermissionsChange = (
    parentPermissionsList: PermissionData[],
    permissionType: PermissionType
  ) => {
    setPermissions((oldPermissions) => [
      ...oldPermissions.filter((perm) => perm.type !== permissionType),
      ...parentPermissionsList,
    ]);
  };

  const handlePermissions = (
    permissionsObject: PermissionData[],
    parent: string
  ) => {
    const optionIndex = permissions.map((opt) => opt.id).indexOf(parent);
    const newRoleData = roleData ? { ...roleData } : createEmptyRoleData();
    const newPermissions = { ...permissions };
    newPermissions[optionIndex] = {
      ...newPermissions[optionIndex],
      children: permissionsObject as PermissionData[],
    };

    const newPermissionsObject = Object.values(
      newPermissions
    ) as PermissionData[];
    newRoleData.permissions = newPermissionsObject;
    setPermissions(newPermissionsObject);
    onRoleChange(newRoleData);
  };

  const handleVisibilityStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setVisibilityEnable(checked);
    const newRoleData = { ...roleData };
    newRoleData.visibleLevel = checked ? 1 : 0;
    onRoleChange(newRoleData);
  };

  const handlePermissionsChangedList = (
    permissionsChangedList: PermissionInput[],
    permissionType: PermissionType
  ) => {
    onPermissionsChange(permissionsChangedList, permissionType);
  };

  const appPermissionValidator = useAppPermissionValidator();

  return (
    <Pane style={{ backgroundColor: "#fff", padding: "0px" }}>
      <SC.Grid
        container
        spacing={2}
        MarginTop={0}
        xl={12}
        lg={12}
        md={12}
        sm={12}
      >
        <SC.Grid item xl={6} lg={6} md={12} sm={12}>
          <SC.Box disabledMarginTop>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              value={roleData?.name}
              fullWidth
              required
              disabled={!isEditable}
              onChange={handleTextFieldChange}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} sm={12}>
          <SC.Box disabledMarginTop>
            <DateTimeField
              value={roleData?.creationDate}
              label="Creation Date"
              disabled
              fullWidth
              required
              onChange={() => {}}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xl={6} lg={6} md={12} sm={12}>
          <SC.DescriptionBox>
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              value={roleData?.description}
              fullWidth
              multiline
              rows={3}
              rowsMax={3}
              disabled={!isEditable}
              onChange={handleTextFieldChange}
            />
          </SC.DescriptionBox>
        </SC.Grid>
        <AppPermissionValidator
          appPermission={APP_PERMISSION.ADMIN_SET_VISIBILITY_LEVEL}
        >
          <SC.Grid
            item
            xl={6}
            lg={6}
            md={12}
            sm={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <SC.InputLabel style={{ flex: 1 }}>
              Restrict Visibility
            </SC.InputLabel>
            <Checkbox
              checked={visibilityEnable}
              onChange={handleVisibilityStatus}
              disabled={!isEditable}
            />
          </SC.Grid>
        </AppPermissionValidator>
        <SC.Grid item xl={6} lg={6} md={12} sm={12} MarginTop={0}>
          <SC.RadioGroup row defaultValue={defaultValue}>
            <SC.FormControlLabel
              value={PermissionType.Application}
              control={
                <SC.Radio disabled={!isEditable} onChange={handleRadioOption} />
              }
              label="App Permissions"
            />
            <AppPermissionValidator
              appPermission={APP_PERMISSION.ADMIN_VIEW_DATA_PERMISSIONS}
            >
              <SC.FormControlLabel
                value={PermissionType.Data}
                control={
                  <SC.Radio
                    disabled={!isEditable}
                    onChange={handleRadioOption}
                  />
                }
                label="Data Permissions"
              />
            </AppPermissionValidator>
          </SC.RadioGroup>
          {permissionTypeActive === PermissionType.Data &&
            appPermissionValidator?.(
              APP_PERMISSION.ADMIN_VIEW_DATA_PERMISSIONS
            ) && (
              <SC.Box disabledMarginTop>
                <PermissionDetails
                  permissions={permissionsData}
                  isEditable={
                    isEditable &&
                    appPermissionValidator?.(
                      APP_PERMISSION.ADMIN_EDIT_DATA_PERMISSIONS
                    )
                  }
                  permissionType={PermissionType.Data}
                  resetData={resetData}
                  onChange={handlePermissions}
                  onParentPermissionsChange={handleParentPermissionsChange}
                  onPermissionsChange={handlePermissionsChangedList}
                  cleanPermissionsChangedList={cleanPermissionsChangedList}
                />
              </SC.Box>
            )}
          {permissionTypeActive === PermissionType.Application && (
            <SC.Box disabledMarginTop>
              <PermissionDetails
                permissions={permissionsApplication}
                isEditable={isEditable}
                permissionType={PermissionType.Application}
                resetData={resetData}
                onChange={handlePermissions}
                onParentPermissionsChange={handleParentPermissionsChange}
                onPermissionsChange={handlePermissionsChangedList}
                cleanPermissionsChangedList={cleanPermissionsChangedList}
              />
            </SC.Box>
          )}
        </SC.Grid>
      </SC.Grid>
    </Pane>
  );
};

export default RoleFormFields;
