import { createStyles, makeStyles, Box, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemActive: {
      background: `${theme.generalStylesContainer.layers.secondary.backgroundColor}A3 !important`,
    },
  })
);

const ChatTopicItemContainer = withStyles((theme: Theme) => ({
  root: {
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    padding: "15px 8px",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: `${theme.generalStylesContainer.layers.secondary.backgroundColor}A3`,
    },
    "& .title": {
      display: "flex",
      marginBottom: "13px",
      "& .query": {
        flex: "1",
        textAlign: "start",
        "& svg": {
          fontSize: "18px",
          marginRight: "10px",
        },
        fontSize: theme.fontGeneralStyles.medium.fontSize,
        lineHeight: theme.fontGeneralStyles.medium.lineHeight,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "& .date": {
        fontSize: theme.fontGeneralStyles.medium.fontSize,
        lineHeight: theme.fontGeneralStyles.medium.lineHeight,
      },
    },
    "& .content": {
      display: "flex",
      "& .response": {
        fontSize: theme.fontGeneralStyles.small.fontSize,
        lineHeight: theme.fontGeneralStyles.small.lineHeight,
        textAlign: "justify",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "& .delete": {
        display: "flex",
        alignItems: "end",
        justifyContent: "end",
        "& svg": {
          fontSize: "18px",
          marginBottom: "10px",
        },
      },
    },
  },
}))(Box);

export default {
  useStyles,
  ChatTopicItemContainer,
};
