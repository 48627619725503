/* eslint-disable */
import { ActionTypes } from './types';

export const updateFilters = (newFilterParams) => ({
  type: ActionTypes.FILTER.UPDATE,
  payload: newFilterParams,
});

export const updateGroupFilters = (filterGroupParams) => ({
  type: ActionTypes.FILTER.GROUP.UPDATE,
  payload: filterGroupParams,
});

export const updateFilterUsersBy = (newFilterParams) => ({
  type: ActionTypes.FILTER.USERS.BY,
  payload: newFilterParams
});

export const updateFilterEventsBy = (newFilterParams) => ({
  type: ActionTypes.FILTER.EVENTS.BY,
  payload: newFilterParams
});

export const updateFilterGroupsBy = (newFilterParams) => ({
  type: ActionTypes.FILTER.GROUPS.BY,
  payload: newFilterParams
});

export const updateFilterIdentityProvidersBy = (newFilterParams) => ({
  type: ActionTypes.FILTER.IDENTITY_PROVIDERS.BY,
  payload: newFilterParams
});

export const updateFilterLabelingJobsBy = (newFilterParams) => ({
  type: ActionTypes.FILTER.LABELING_JOBS.BY,
  payload: newFilterParams
});

export const setSearchText = (searchText) => ({
  type: ActionTypes.FILTER.SEARCH_TEXT.SET,
  payload: searchText
});
export const updateFilterTrainingJobsBy = (newFilterParams) => ({
  type: ActionTypes.FILTER.TRAINING_JOBS.BY,
  payload: newFilterParams
});
