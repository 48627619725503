/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import {
  Box,
  BoxProps,
  Button,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { isMobileResolution } from "commons/utils/device-info.util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationContainer: {
      minWidth: "fit-content",
      display: "flex",
      [theme.breakpoints.down(540)]: {
        width: "100%",
        minWidth: "unset",
        padding: "0px 8px",
        "& .MuiTablePagination-toolbar": {
          padding: "unset",
        },
      },
    },
    pagination: {
      title: {
        "& h1": {
          textAlign: "left",
        },
        pagination: {
          flex: "1",
        },
      },
      "& p": {
        color: theme.palette.primary.main,
      },
      "& .MuiSelect-root": {
        color: theme.palette.primary.main,
      },
      "& svg": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down(540)]: {
        "& .MuiToolbar-root": {
          minHeight: "unset",
          columnGap: "5px",
          flexWrap: "wrap",
          justifyContent: "end",
        },
        "& .MuiTablePagination-selectRoot": {
          margin: "unset",
        },
      },
    },
  })
);

const paginationBtnsUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paginationBtns: {
      display: "flex",
      "& button": {
        color: "#B8CAC6",
      },
      [theme.breakpoints.down(540)]: {
        marginLeft: "10px",
        columnGap: "5px",
        "& button": {
          padding: "unset",
        },
      },
    },
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
  reset: boolean;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const classes = paginationBtnsUseStyles();
  const { count, page, rowsPerPage, onPageChange, reset } = props;
  const firstPageButtonRef = React.useRef<HTMLButtonElement>(null);

  // console.debug("=====> TablePaginationAction -> reset value: ", reset);

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  React.useEffect(() => {
    if (reset && firstPageButtonRef && firstPageButtonRef.current) {
      firstPageButtonRef.current.click();
    }
  }, [reset]);

  return (
    <Box className={classes.paginationBtns}>
      <IconButton
        ref={firstPageButtonRef}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon color="primary" />
        ) : (
          <FirstPageIcon color="primary" />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight color="primary" />
        ) : (
          <KeyboardArrowLeft color="primary" />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        color="primary"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft color="primary" />
        ) : (
          <KeyboardArrowRight color="primary" />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        color="primary"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon color="primary" />
        ) : (
          <LastPageIcon color="primary" />
        )}
      </IconButton>
    </Box>
  );
}

interface PaginatorProps extends BoxProps {
  totalItems: number;
  updateItems?: any;
  updatePage?: any;
  getNewItems?: any;
  reset?: boolean;
  hidden?: boolean;
  externalNewPage?: number;
  externalNewRowPerPage?: number;
}

const Paginator: React.FC<PaginatorProps> = ({
  totalItems,
  updateItems,
  updatePage,
  getNewItems,
  reset = false,
  hidden = false,
  externalNewPage = null,
  externalNewRowPerPage = null,
  ...props
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // console.debug("=====> handleChangePage: ", newPage);
    setPage(newPage);

    if (getNewItems) getNewItems(newPage, rowsPerPage);
    else updatePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // console.debug("=====>handleChangeRowsPerPage: ", event.target.value);
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);

    if (getNewItems) getNewItems(0, newRowsPerPage);
    else updateItems(newRowsPerPage);
  };

  React.useEffect(() => {
    if (externalNewPage !== null) {
      setPage(externalNewPage);
    }
  }, [externalNewPage]);

  React.useEffect(() => {
    if (externalNewRowPerPage !== null) {
      setRowsPerPage(externalNewRowPerPage);
    }
  }, [externalNewRowPerPage]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props} hidden={hidden} className={classes.paginationContainer}>
      <TablePagination
        component="div"
        className={classes.pagination}
        style={{ flex: "1" }}
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={1}
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        /* eslint-disable react/jsx-props-no-spreading */
        ActionsComponent={(subProps) => (
          <TablePaginationActions {...subProps} reset={reset} />
        )}
        labelRowsPerPage={isMobileResolution() ? "" : "Rows per page"}
      />
    </Box>
  );
};

export default Paginator;
