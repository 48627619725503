/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies */

// Dependencies
import React from "react";

// Components
import SelectableList from "components/selectable-list/selectable-list.component";
import TreeViewList from "components/tree-view-list/tree-view-list.component";

// Assets
import {
  PermissionData,
  PermissionInput,
  PermissionType,
} from "graphql/types-and-hooks";
import SC from "./permission-details.styles";

interface PermissionDetailsProps {
  permissions: PermissionData[];
  isEditable: boolean;
  permissionType: PermissionType;
  resetData: boolean;
  onChange: (permissions: PermissionData[], parent: string) => void;
  onParentPermissionsChange: (
    permissions: PermissionData[],
    permissionType: PermissionType
  ) => void;
  onPermissionsChange: (
    permissionsList: PermissionInput[],
    permissionType: PermissionType
  ) => void;
  cleanPermissionsChangedList: boolean;
}

export const PermissionDetails: React.FC<PermissionDetailsProps> = ({
  permissions,
  isEditable,
  permissionType,
  resetData,
  onChange,
  onParentPermissionsChange,
  onPermissionsChange,
  cleanPermissionsChangedList,
}) => {
  const [optionsList, setOptionsList] = React.useState(permissions);
  const initialSelectedItem = optionsList[0]?.id ?? "";
  const initialTreeList = optionsList[0]?.children ?? [];
  const [optionsTreeList, setOptionsTreeList] = React.useState<
    PermissionData[]
  >(initialTreeList ?? []);
  // eslint-disable-next-line prefer-const
  let [permissionsChangedList, setPermissionsChangedList] = React.useState<
    PermissionInput[]
  >([]);
  const [currentOption, setCurrentOption] = React.useState(
    initialSelectedItem as string
  );
  const [parentIsActive, setParentIsActive] = React.useState(
    optionsList[0]?.enabled ?? false
  );

  React.useEffect(() => {
    if (resetData) {
      setPermissionsChangedList([]);
      setOptionsList(permissions);
      setOptionsTreeList(permissions[0].children ?? []);
    }
    if (cleanPermissionsChangedList) {
      setPermissionsChangedList([]);
    }
  }, [permissions, resetData, cleanPermissionsChangedList]);

  React.useEffect(() => {
    onPermissionsChange(permissionsChangedList, permissionType);
  }, [onPermissionsChange, permissionsChangedList]);

  React.useEffect(() => {}, [permissionsChangedList]);

  const checkItem = (optionTree: PermissionData, state: boolean) => {
    const newOptionTree = { ...optionTree };
    newOptionTree.enabled = state;
    const newId = newOptionTree.id;

    const isDifferent = optionTree.enabled !== state;

    const permissionInput: PermissionInput = {
      id: newId,
      enabled: state,
    };
    const optionIndex = permissionsChangedList.findIndex(
      (opt) => opt.id === newId
    );

    if (isDifferent)
      if (optionIndex !== -1) {
        setPermissionsChangedList((oldPermissions) =>
          oldPermissions.filter((perm) => perm.id !== newId)
        );
      } else {
        setPermissionsChangedList((oldPermissions) => [
          ...oldPermissions,
          permissionInput,
        ]);
      }

    return newOptionTree;
  };

  const searchItem = (optionsTree: PermissionData[], value: string) => {
    const option = optionsTree?.filter((opt) => opt.id === value);
    const optionIndex = optionsTree.findIndex((opt) => opt.id === value);
    let newOption: PermissionData = {};
    if (option.length !== 0) {
      newOption = checkItem(option[0], !option[0].enabled);
    }

    const newOptionsTree = { ...optionsTree };
    newOptionsTree[optionIndex] = newOption;

    return newOptionsTree;
  };

  const handleSelectedTreeOption = (selectedValue: string) => {
    const newOptionsTreeList = searchItem(optionsTreeList, selectedValue);
    const newOptionsTreeListValues = Object.values(
      newOptionsTreeList
    ) as PermissionData[];
    const optionIndex = optionsList.findIndex(
      (opt) => opt.id === currentOption
    );

    optionsList[optionIndex] = {
      ...optionsList[optionIndex],
      children: newOptionsTreeListValues,
    };

    setOptionsTreeList(newOptionsTreeListValues);
    onChange(newOptionsTreeListValues, currentOption);
  };

  const handleSelectedListOption = async (selectedValue: string) => {
    setCurrentOption(selectedValue);
    const parentOption = optionsList.find(
      (opt: PermissionData) => opt.id === selectedValue
    );
    setParentIsActive(!!parentOption?.enabled);
    const children = parentOption?.children as PermissionData[];
    setOptionsTreeList(children);
  };

  const handleSelectedCheckBoxFromList = async (selectedValue: string) => {
    setCurrentOption(selectedValue);
    const parentOption = optionsList.find(
      (opt: PermissionData) => opt.id === selectedValue
    );
    setParentIsActive(!parentOption?.enabled);

    const newOptionsList = optionsList.map((opt: PermissionData) => ({
      ...opt,
      enabled: opt.id === selectedValue ? !opt.enabled : opt.enabled,
    }));

    const optionSelected = newOptionsList.find(
      (opt: PermissionData) => opt.id === selectedValue
    );

    const optionState = optionSelected?.enabled;
    const permissionObject: PermissionInput = {
      id: selectedValue,
      enabled: optionState,
    };
    const optionIndex = permissionsChangedList.findIndex(
      (opt) => opt.id === selectedValue
    );
    if (optionIndex !== -1)
      setPermissionsChangedList((oldPermissions) =>
        oldPermissions.filter((perm) => perm.id !== selectedValue)
      );
    else
      setPermissionsChangedList([...permissionsChangedList, permissionObject]);

    const optionIndexList = optionsList.findIndex(
      (opt) => opt.id === selectedValue
    );
    const children = parentOption?.children as PermissionData[];
    const newChildren = children.map((child) => checkItem(child, false));
    newOptionsList[optionIndexList].children = newChildren;
    setOptionsList(newOptionsList);

    setOptionsTreeList(newChildren);
    onParentPermissionsChange(newOptionsList, permissionType);
    // onChange(newChildren, currentOption);
  };

  return (
    <SC.Grid item xl={6} lg={12} md={12} xs={12} MarginTop={0}>
      <SC.Grid
        item
        xl={6}
        lg={6}
        md={12}
        xs={12}
        scrollable
        paddingright="25px"
        style={{ flexDirection: "column" }}
      >
        <SC.InputLabelTab>Tab</SC.InputLabelTab>
        <SelectableList
          items={optionsList}
          isEditable={isEditable}
          initialSelectedItem={initialSelectedItem ?? ""}
          getItemKey={(item) => item.id ?? ""}
          getItemLabel={(item) => item.name ?? ""}
          getItemChecked={(item) => item.enabled as boolean}
          onListChange={handleSelectedListOption}
          onCheckBoxChange={handleSelectedCheckBoxFromList}
        />
      </SC.Grid>
      <SC.Grid
        item
        xl={6}
        lg={6}
        md={12}
        xs={12}
        scrollable
        style={{ flexDirection: "column" }}
      >
        <SC.InputLabelPermissions>Permissions</SC.InputLabelPermissions>
        <TreeViewList
          items={optionsTreeList}
          isEditable={isEditable && parentIsActive}
          getItemKey={(item) => item.id ?? ""}
          getItemLabel={(item) => item.name ?? ""}
          getItemChecked={(item) => item.enabled as boolean}
          getItemChildren={(item) => item.children ?? []}
          onChange={handleSelectedTreeOption}
        />
      </SC.Grid>
    </SC.Grid>
  );
};
export default PermissionDetails;
