import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const MediasCarouselContainer = withStyles(() => ({
  root: {
    maxWidth: "1250px",
    width: "100%",
  },
}))(Box);

export default {
  MediasCarouselContainer,
};
