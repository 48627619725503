// Dependencies
import { withStyles, Checkbox as CheckboxMui } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import ActionButton from "components/action-button/action-button.component";
import Table from "components/table/table.component";

const MainContainer = withStyles({})(Container);

const UserTable = withStyles({
  leftPane: {
    width: "30%",
    minWidth: "30%",
  },
})(Table) as typeof Table;

const Checkbox = withStyles((theme) => ({
  root: {
    padding: theme.table.IconButton.padding,
  },
}))(CheckboxMui);

const CustomActionButton = withStyles(() => ({
  root: {
    marginLeft: "10px",
  },
}))(ActionButton);

export default {
  UserTable,
  MainContainer,
  Checkbox,
  CustomActionButton,
};
