// Dependencies
import {
  withStyles,
  Box,
  IconButton as IconButtonBase,
} from "@material-ui/core";

const TablePaginationAction = withStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    "& .MuiTablePagination-caption": {
      color: "red",
    },
  },
}))(Box);

const IconButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      fontSize: theme.table.toolbarActions.fontSize,
    },
    "&:not(.Mui-disabled) .MuiSvgIcon-root": {
      color: theme.table.tablePagination.actionsIconColor,
    },
  },
}))(IconButtonBase);

export default {
  TablePaginationAction,
  IconButton,
};
