/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { LABEL_CLASSES } = ActionTypes;

const INITIAL_STATE = {
  // read classes
  isReadData: false,
  onReadError: null,
  onReadSuccess: []
};


const labelClassesReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Read LabelClasses from db
    case LABEL_CLASSES.READ.START:
      return {
        ...state,
        isReadData: true,
        onUpdateError: null
      };
    case LABEL_CLASSES.READ.SUCCESS: {
      return {
        ...state,
        onReadSuccess: action.payload,
        isReadData: false,
      };
    }
    case LABEL_CLASSES.READ.FAILURE:
      return {
        ...state,
        isReadData: false,
        onReadError: action.payload,
      };
    default: return state;
  }
};


export default labelClassesReducerFn;

