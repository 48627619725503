/* eslint-disable @typescript-eslint/ban-ts-comment */
// Dependencies
import React from "react";

// Component
import SelectableListItem from "components/selectable-list-item/selectable-list-item.component";

// Assets
import SC from "./selectable-list.styles";

export interface SelectableListProps<T extends Record<string, unknown>> {
  items: T[];
  isEditable: boolean;
  initialSelectedItem: string;
  getItemKey: (item: T) => string;
  getItemLabel: (item: T) => string;
  getItemChecked: (item: T) => boolean;
  onListChange: (selectedValue: string) => void;
  onCheckBoxChange: (selectedValue: string) => void;
}

export const SelectableList = <T extends Record<string, unknown>>({
  items = [],
  isEditable,
  initialSelectedItem,
  getItemKey,
  getItemLabel,
  getItemChecked,
  onListChange,
  onCheckBoxChange,
}: SelectableListProps<T>): JSX.Element => {
  const [selectedValue, setSelectedValue] = React.useState(initialSelectedItem);

  const handlerListItemOnClick: React.MouseEventHandler<HTMLLIElement> &
    React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
    (
      event:
        | React.MouseEvent<HTMLLIElement, MouseEvent>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setSelectedValue(event.currentTarget.id);
      onListChange(event.currentTarget.id);
    },
    [onListChange]
  );

  const handlerCheckBoxClick: React.MouseEventHandler<HTMLButtonElement> =
    React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        // @ts-ignore
        onCheckBoxChange(event.target.id);
        // @ts-ignore
        setSelectedValue(event.target.id);
      },
      [onCheckBoxChange]
    );

  return (
    <SC.Box>
      <SC.List dense>
        {items?.map((item: T) => {
          const label = getItemLabel(item);
          const key = getItemKey(item);
          return (
            <SelectableListItem
              key={key}
              name={key}
              label={label}
              checked={getItemChecked(item)}
              isSelected={selectedValue === key}
              isEditable={isEditable}
              onClick={handlerListItemOnClick}
              onCheckBoxClick={handlerCheckBoxClick}
            />
          );
        })}
      </SC.List>
    </SC.Box>
  );
};

export default SelectableList;
