/* eslint-disable */
import { ActionTypes } from '../actions/types';

const appInitialState = {
  modes: [],
  // selectedMode: undefined,
  // selectedModeOption: undefined,
};

const modebarReducerFn = (state = appInitialState, action) => {
  switch (action.type) {
    case ActionTypes.MODEBAR.SET:
      return {
        modes: action.payload.modes,
        // selectedMode: action.payload.initialMode,
        // selectedModeOption: action.payload.initialModeOption,
      };
    default:
      return state;
  }
};

export default modebarReducerFn;
