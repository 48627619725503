import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core";

const chartOptions: any = {
  1: {
    xAxis: {
      type: "value",
      show: false,
    },
    yAxis: {
      type: "category",
      data: [],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        color: "#000",
      },
    },
    grid: {
      top: 0,
      left: 0,
      bottom: 0,
      width: "90%",
      height: "250px",
      containLabel: true,
    },
    series: [
      {
        type: "bar",
        showBackground: false,
        itemStyle: {
          borderRadius: [0, 25, 25, 0],
        },
        barWidth: 15,
        label: {
          show: true,
          position: "right",
          formatter: (data: any) => {
            return `${data.value}%`;
          },
        },
        // labelLayout: function () {
        //   return {
        //     x: myChart.getWidth() - (myChart.getWidth() * 10) / 100,
        //   };
        // },
        data: [],
      },
    ],
  },
  2: {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    series: [
      {
        name: "Sentiment",
        type: "gauge",
        radius: "100%",
        startAngle: 180,
        endAngle: 0,
        title: {
          show: true,
          backgroundColor: "transparent",
        },
        detail: {
          formatter: "{value}%",
          offsetCenter: [0, "-20%"],
        },
        data: [
          {
            value: 70,
            name: "POSITIVE",
            detail: {
              color: "#000",
              fontFamily: "Gothic A1",
              fontSize: 60,
              fontWeight: "bold",
              lineHeight: 75,
            },
            title: {
              color: "#000",
              fontFamily: "Gothic A1",
              fontSize: 22,
              fontWeight: "bold",
              lineHeight: 27,
              opacity: 0.6,
            },
          },
        ],
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 20,
            color: [[1, "#BDBDBD"]],
            opacity: 0.2,
          },
        },
        progress: {
          show: true,
          roundCap: true,
          width: 20,
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: "#77F6C1", // color at 0%
                },
                {
                  offset: 0.8,
                  color: "#8FECF8", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
      },
    ],
  },
  3: {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    series: [
      {
        name: "Sentiment",
        type: "gauge",
        radius: "100%",
        startAngle: 0,
        endAngle: 360,
        center: ["15px", "50%"],
        title: {
          show: true,
          backgroundColor: "transparent",
        },
        detail: {
          formatter: "{value}%",
          offsetCenter: ["30px", "2px"],
        },
        data: [
          {
            value: 0,
            name: "",
            detail: {
              color: "#5AE391",
              fontFamily: "Gothic A1",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 75,
            },
            title: {
              color: "#5AE391",
              fontFamily: "Gothic A1",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 27,
              offsetCenter: ["80px", "2px"],
            },
          },
        ],
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 2,
            color: [[1, "#BDBDBD"]],
            opacity: 0.2,
          },
        },
        progress: {
          show: true,
          roundCap: true,
          width: 2,
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: "#77F6C1", // color at 0%
                },
                {
                  offset: 0.8,
                  color: "#8FECF8", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
      },
    ],
  },
  4: {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    series: [
      {
        name: "Sentiment",
        type: "gauge",
        radius: "100%",
        startAngle: 180,
        endAngle: 0,
        title: {
          show: true,
          backgroundColor: "transparent",
        },
        detail: {
          formatter: "{value}%",
          offsetCenter: [0, "-20%"],
        },
        data: [
          {
            value: 70,
            name: "POSITIVE",
            detail: {
              color: "#000",
              fontFamily: "Gothic A1",
              fontSize: 60,
              fontWeight: "bold",
              lineHeight: 75,
            },
            title: {
              color: "#000",
              fontFamily: "Gothic A1",
              fontSize: 22,
              fontWeight: "bold",
              lineHeight: 27,
              opacity: 0.6,
            },
          },
        ],
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 20,
            color: [[1, "#BDBDBD"]],
            opacity: 0.2,
          },
        },
        progress: {
          show: true,
          roundCap: true,
          width: 20,
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: "#8A3C84", // color at 0%
                },
                {
                  offset: 0.53,
                  color: "#D83551", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
      },
    ],
  },
  5: {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    series: [
      {
        name: "",
        type: "gauge",
        radius: "100%",
        startAngle: 0,
        endAngle: 360,
        center: ["50%", "50%"],
        title: {
          show: true,
          backgroundColor: "transparent",
        },
        detail: {
          formatter: "{value}%",
          offsetCenter: [0, 0],
        },
        data: [
          {
            value: 0,
            name: "",
            detail: {
              color: "#5AE391",
              fontFamily: "Gothic A1",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 75,
            },
            title: {
              color: "#5AE391",
              fontFamily: "Gothic A1",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 27,
              offsetCenter: ["75px", 0],
            },
          },
        ],
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 5,
            color: [[1, "#BDBDBD"]],
            opacity: 0.2,
          },
        },
        progress: {
          show: true,
          roundCap: true,
          width: 5,
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: "#77F6C1", // color at 0%
                },
                {
                  offset: 0.8,
                  color: "#8FECF8", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
      },
    ],
  },
};

const useChartStyles = makeStyles((theme: Theme) =>
  createStyles({
    customClass: {
      "& div:last-child": {
        "& br": {
          display: "none",
        },
      },
    },
  })
);

export default {
  chartOptions,
  useChartStyles,
};
