/* eslint-disable react/jsx-boolean-value */
import React from "react";

import { Box, CardMedia, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmContainer: {
      display: "flex",
      flexDirection: "column",
    },
    sectionCard: {
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      marginBottom: "20px",
      padding: "16px 14px",
      cursor: "pointer",
      "& .cardTitle": {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        marginBottom: "18px",
      },
      "& .cardText": {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "28px",
      },
      "&:hover": {
        background: "rgba(218, 212, 232, 0.25)",
        border: `1px solid ${theme.palette.primary.main}`,
      },
      "&:last-child": {
        marginBottom: "0px",
      },
    },
    sectionTitle: {
      // fontFamily: "Gothic A1, sans-serif",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "20px",
      // color: "#000",
      marginBottom: "16px",
    },
    planItem: {
      boxSizing: "border-box",
      marginBottom: "20px",
      "& .group-1500": {
        padding: "15px 14px",
        borderRadius: "10px",
        backgroundColor:
          theme.generalStylesContainer.layers.content.backgroundColor,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      },
      "& .flex-col": {
        display: "flex",
        flexDirection: "column",
      },
      "& .flex-row": {
        display: "flex",
        flexDirection: "row",
      },
      "& .group-828": {
        marginBottom: "19px",
        boxSizing: "border-box",
        width: "100%",
      },
      "& .group-854": {
        flex: "1",
        boxSizing: "border-box",
        height: "fit-content",
        [theme.breakpoints.down(540)]: {
          alignItems: "center",
        },
      },
      "& .flex-hcenter": {
        justifyContent: "center",
      },
      "& p": {
        margin: "0px",
      },
      "& .rectangle-441": {
        display: "flex",
        alignItems: "center",
        backgroundColor: `${theme.palette.primary.main}7B`,
        width: "208px",
        height: "30px",
        borderRadius: "10px",
        marginBottom: "18px",
      },
      "& .txt-558": {
        fontSize: "28px",
        // fontFamily: "Gothic A1, sans-serif",
        fontWeight: 700,
        // color: "rgba(0, 0, 0, 1)",
        width: "auto",
        height: "29px",
        wordWrap: "break-word",
      },
      "& .txt-5583": {
        fontSize: "22px",
        color: "rgba(204, 204, 204, 1)",
      },
      "& .group-876": {
        // padding: "11px 9px",
        // boxSizing: "border-box",
        // borderRadius: "38px",
        backgroundColor: `${theme.palette.primary.main}7B`,
        // width: "62px",
        // height: "fit-content",
        display: "flex",
        height: "62px",
        width: "62px",
        borderRadius: "38px",
        margin: "auto",
        marginTop: "0px",
        marginBottom: "20px",
        "& svg": {
          margin: "auto",
          fontSize: "42px",
        },
      },
      "& .group ": {
        width: "100%",
        height: "100%",
      },
      "& .txt-574": {
        width: "100%",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "160%",
        height: "17px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& .txt-950": {
        fontSize: "14px",
        fontWeight: 800,
        letterSpacing: "2.1px",
        textAlign: "center",
        width: "208px",
        height: "17px",
        wordWrap: "break-word",
        textTransform: "uppercase",
      },
      [theme.breakpoints.down(540)]: {
        "& .txt-574": {
          height: "unset",
          whiteSpace: "break-spaces",
          textAlign: "center",
        },
      },
    },
    confirmBtn: {
      height: theme.buttonSize.small.height,
      width: "316px",
      borderRadius: theme.buttonSize.small.borderRadius,
      fontWeight: theme.buttonSize.small.fontWeight,
      backgroundColor: theme.button.primary.backgroundColor,
      color: theme.button.primary.color,
      textTransform: theme.button.primary.textTransform,
      margin: "auto",
      marginBottom: "11px",
      "&:hover": {
        color: theme.button.primary.hoverColor,
        backgroundColor: theme.button.primary.hoverBackground,
      },
      "&:disabled": {
        color: theme.button.primary.disabledColor,
        backgroundColor: theme.button.primary.disabledBackground,
      },
      "& span": {
        textTransform: "capitalize",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "bold",
      },
      "&.chatPro": {
        width: "unset !important",
        padding: "0px 30px",
        "& span": {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down(540)]: {
        width: "100%",
      },
    },
    backBtn: {
      height: theme.buttonSize.small.height,
      width: theme.buttonSize.small.width,
      borderRadius: theme.buttonSize.small.borderRadius,
      fontWeight: theme.buttonSize.small.fontWeight,
      backgroundColor: theme.button.secondary.backgroundColor,
      color: theme.button.secondary.color,
      textTransform: theme.button.secondary.textTransform,
      "&:hover": {
        color: theme.button.secondary.hoverColor,
        backgroundColor: "transparent !important",
      },
      "&:disabled": {
        color: theme.button.secondary.disabledColor,
        backgroundColor: "transparent",
      },
      border: "none",
      textDecoration: "underline",
      margin: "auto !important",
      "& span": {
        textTransform: "capitalize",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "bold",
      },
      [theme.breakpoints.down(540)]: {
        width: "100%",
        marginBottom: "10px !important",
      },
    },
    footerEle: {
      display: "flex",
      flexDirection: "column",
      marginTop: "50px",
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 600,
        textAlign: "center",
      },
    },
  })
);

const PromoCouponContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "88px",
    "& .errorCoupon": {
      "& label": {
        color: theme.palette.error.main,
        background: "#FFFFFF",
      },
      "& fieldset": {
        borderColor: theme.palette.error.main,
        // boxShadow: "0px 0px 4px rgba(254, 99, 119, 0.9)",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
        // boxShadow: "0px 0px 4px rgba(254, 99, 119, 0.9)",
      },
      "& .MuiFormHelperText-root": {
        height: "20px",
        color: theme.palette.error.main,
      },
    },
    [theme.breakpoints.down(540)]: {
      gap: "10px",
    },
  },
}))(Box);

const AmountContainer = withStyles(() => ({
  root: {
    display: "flex",
    gap: "10px",
    alignItems: "baseline",
    "& .strikethrough": {
      position: "relative",
      fontSize: "22px !important",
      color: "#CB9CA6",
      "&:before": {
        position: "absolute",
        content: "''",
        left: "0",
        top: "45%",
        right: "0",
        borderTop: "3px solid",
        borderColor: "inherit",
        webkitTransform: "skewY(-10deg)",
        mozTransform: "skewY(-10deg)",
        transform: "skewY(-10deg)",
      },
    },
  },
}))(Box);

const ChatProContainer = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    background: "#FFF",
    rowGap: "26px",
  },
}))(Box);

const PlanName = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(151, 59, 123, 0.1)",
    width: "208px",
    height: "30px",
    borderRadius: "10px",
    "& p": {
      fontSize: "14px",
      fontFamily: "Gothic A1, sans-serif",
      fontWeight: 800,
      letterSpacing: "2.1px",
      color: "rgba(151, 59, 123, 1)",
      textAlign: "center",
      width: "208px",
      height: "17px",
      wordWrap: "break-word",
      textTransform: "uppercase",
    },
  },
}))(Typography);

const IconPlanContainer = withStyles(() => ({
  root: {
    backgroundColor: "rgba(151, 59, 123, 0.1)",
    display: "flex",
    height: "62px",
    width: "62px",
    padding: "6px 18px",
    borderRadius: "38px",
    margin: "auto",
    marginBottom: "3px",
    "& svg": {
      margin: "auto",
      fontSize: "42px",
    },
  },
}))(Box);

const ImagePNG = withStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
  },
}))(CardMedia);

const PlanAmount = withStyles(() => ({
  root: {
    fontSize: "28px",
    fontFamily: "Gothic A1, sans-serif",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    width: "auto",
    wordWrap: "break-word",
    "&.bigger": {
      fontSize: "35px",
    },
  },
}))(Typography);

const AmountCurrency = withStyles(() => ({
  root: {
    fontSize: "22px",
    color: "rgba(204, 204, 204, 1)",
    "&.bigger": {
      fontSize: "25px",
    },
  },
}))(Box);

const ConfirmTexts = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.fontGeneralStyles.default.fontSize,
    lineHeight: theme.fontGeneralStyles.medium.lineHeight,
    fontWeight: 500,
    "&.medium": {
      fontSize: theme.fontGeneralStyles.medium.fontSize,
      lineHeight: "25px",
    },
    "&.bold": {
      fontWeight: 700,
    },
    "&.center-text": {
      textAlign: "center",
    },
    "&.main": {
      marginBottom: "19px",
    },
  },
}))(Typography);

const ConfirmBullet = withStyles(() => ({
  root: {
    display: "flex",
    columnGap: "8px",
    paddingLeft: "12px",
    marginBottom: "47px",
    "& svg": {
      color: "#B8CAC6",
    },
  },
}))(Box);

export default {
  useStyles,
  PromoCouponContainer,
  AmountContainer,
  ChatProContainer,
  PlanName,
  IconPlanContainer,
  ImagePNG,
  PlanAmount,
  AmountCurrency,
  ConfirmTexts,
  ConfirmBullet,
};
