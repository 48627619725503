import React from "react";
import { StyledComponentProps } from "@mui/material";
import { Box, Typography } from "@material-ui/core";

import SC from "./generic-empty-messages.styles";

interface GenericEmptyMessagesProps {
  title: string;
  description: string;
  show: boolean;
  description2?: string;
  component?: any;
  title2?: string;
  titleComponent?: any;
}

const GenericEmptyMessages: React.FC<
  GenericEmptyMessagesProps & StyledComponentProps
> = ({
  title,
  description,
  show,
  description2,
  component = null,
  title2,
  titleComponent = null,
  classes,
}) => {
  return (
    <SC.MessageContainer
      hidden={!show}
      className={`noResultsMain ${classes ? classes.root : ""}`}
    >
      <SC.MessageTitle color="primary">
        {title}
        {titleComponent ?? null}
        {title2 ?? ""}
      </SC.MessageTitle>
      <SC.MessageDescription component="div" color="secondary">
        {description}
        {component ?? null}
        {description2 ?? ""}
      </SC.MessageDescription>
    </SC.MessageContainer>
  );
};

export default GenericEmptyMessages;
