import { Typography } from "@material-ui/core";
import React from "react";

// Assets
import SC from "./signin-form.styles";

interface SigninFormProps {
  handleSubmit: any;
  credentials: any;
  handleOnChange: any;
  setRememberMe: any;
  rememberMe: boolean;
}

const SigninForm: React.FC<SigninFormProps> = (props) => {
  const {
    handleSubmit,
    credentials,
    handleOnChange,
    setRememberMe,
    rememberMe,
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCheckChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(!rememberMe);
  };

  return (
    <SC.Form onSubmit={handleSubmit}>
      <SC.EmailField
        autoFocus
        name="email"
        label="Email Address"
        placeholder="Enter your Email Address"
        type="text"
        variant="outlined"
        fullWidth
        value={credentials.email}
        InputProps={{
          endAdornment: (
            <SC.EmailInputAdornment position="end">
              <SC.PersonOutlineOutlinedIcon />
            </SC.EmailInputAdornment>
          ),
        }}
        onChange={handleOnChange}
      />
      <SC.PasswordFieldContainer>
        <SC.PasswordField
          name="password"
          label="password"
          placeholder="Enter Your Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          value={credentials.password}
          InputProps={{
            endAdornment: (
              <SC.PasswordInputAdornment position="end">
                <SC.IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <SC.VisibilityOffIcon />
                  ) : (
                    <SC.VisibilityIcon />
                  )}
                </SC.IconButton>
              </SC.PasswordInputAdornment>
            ),
          }}
          onChange={handleOnChange}
        />
        <SC.ForgotPasswordContainer>
          <SC.Link to="/forgot-password">Forgot your password?</SC.Link>
        </SC.ForgotPasswordContainer>
      </SC.PasswordFieldContainer>

      <SC.FormControlLabel
        control={
          <SC.Checkbox
            checked={rememberMe}
            onChange={handleCheckChange}
            name="rememberCheck"
          />
        }
        label="Remember me"
      />
      <br />
      <SC.DividerMobile component="div" />
      <SC.Button type="submit">
        <Typography>Sign</Typography>&nbsp;in
      </SC.Button>
      <SC.NoRegisteredUserInfo>
        Not registered yet?&nbsp;
        <SC.Link to="/signup">Create account</SC.Link>
      </SC.NoRegisteredUserInfo>
    </SC.Form>
  );
};

export default SigninForm;
