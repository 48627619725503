/* eslint-disable */
import { ActionTypes } from '../actions/types';

const appInitialState = {
  selectedFrame: 2,
};

const frameReducerFn = (state = appInitialState, action) => {
  switch (action.type) {
    case ActionTypes.FRAME.SELECT:
      return {
        selectedFrame: action.frame,
      };
    default:
      return state;
  }
};

export default frameReducerFn;
