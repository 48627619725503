/* eslint-disable react/jsx-boolean-value */
import React from "react";
import TagManager from "react-gtm-module";

import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  SvgIcon,
  Divider,
} from "@material-ui/core";

import { CheckCircle, CloseOutlined } from "@material-ui/icons";

// import BillingAddressForm from "../billing-address-form/billing-address-form";
import BillingAddressFormFields from "pages/payment-management/views/components/billing_address/billing_address_form";
import PaymentMethodForm from "pages/payment-management/views/components/payment_method/payment_method_form";
import { PaymentMethodModel } from "pages/payment-management/models/payment-method";
import {
  createEmptyBillingAddress,
  createEmptyPaymentMethod,
} from "graphql/audionaut.utils";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  AudionautCoupon,
  AudionautPlanFragmentFragment,
  AudionautPlanSimplifyFragmentFragment,
  AudionautSubscriptionFragmentFragment,
  BillingAddress,
  BillingAddressFragmentFragmentDoc,
  PaymentMethod,
  PaymentMethodFragmentFragmentDoc,
  useCreateBillingAddressMutation,
  useCreatePaymentMethodMutation,
  useEditBillingAddressMutation,
  useEditPaymentMethodMutation,
  usePayOutstandingInvoiceMutation,
} from "graphql/types-and-hooks";
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import PaymentMethodCard from "pages/payment-management/views/components/payment_method_card/payment_method_card.component";
import PaymentOptionsComponent from "components/payment-options/payment-options.component";
import PaymentConfirm from "pages/user-plans/components/payment-confirm/payment-confirm";
import { Logo } from "pages/signin/signin.styles";

import { ReactComponent as Step1Icon } from "assets/icons/step_1.svg";
import { ReactComponent as Step2Icon } from "assets/icons/step_2.svg";
import { ReactComponent as Step3Icon } from "assets/icons/step_3.svg";

import SC from "./billing-modal.styles";

const { REACT_APP_STRIPE_SECRET_KEY_CORPORATUS } = process.env;

const initModalStep = (
  isWizard: boolean,
  addressDefault: boolean,
  paymentDefault: boolean
) => {
  let initStep = 1;
  if (!isWizard) initStep = 4;
  else {
    initStep = addressDefault && paymentDefault ? 3 : 1;
  }
  return initStep;
};

interface BillingModalProps {
  openModal: boolean;
  isWizard?: boolean;
  planData:
    | null
    | AudionautPlanFragmentFragment
    | AudionautSubscriptionFragmentFragment;
  createSubscription: (id?: string, coupon?: string) => void;
  handleCloseModal: any;
  currentAddressData?: BillingAddress;
  paymentMethodData?: PaymentMethod;
  updateAddressData?: boolean;
  updatePaymentMethodData?: boolean;
  amountOwed?: number;
  modalType?: number;
  chatPro?: boolean;
  couponPreloaded?: AudionautCoupon;
}

const BilingModal: React.FC<BillingModalProps> = ({
  openModal,
  isWizard = true,
  planData,
  createSubscription,
  handleCloseModal,
  currentAddressData,
  paymentMethodData,
  updateAddressData = false,
  updatePaymentMethodData = false,
  amountOwed = 0,
  modalType = 0,
  chatPro = false,
  couponPreloaded = undefined,
}) => {
  const classes = SC.useStyles();

  const stripePromise = React.useMemo(
    () =>
      REACT_APP_STRIPE_SECRET_KEY_CORPORATUS
        ? loadStripe(REACT_APP_STRIPE_SECRET_KEY_CORPORATUS)
        : null,
    []
  );

  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [paymentAvailable, setPaymentAvailable] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (openModal) {
      const firstStep = initModalStep(
        isWizard,
        currentAddressData
          ? (!!currentAddressData.id ?? false) &&
              (currentAddressData.isDefault ?? false)
          : false,
        paymentMethodData
          ? (!!paymentMethodData.stripePaymentMethodId ?? false) &&
              (paymentMethodData.isDefault ?? false)
          : false
      );
      setCurrentStep(firstStep);
    }
  }, [isWizard, currentAddressData, paymentMethodData, openModal]);

  const [paymentSingle, setPaymentSingle] = React.useState(false);

  const [billingAddressToEdit, setBillingAddressToEdit] =
    React.useState<BillingAddress | undefined>(currentAddressData);
  const [paymentMethodToEdit, setPaymentMethodToEdit] = React.useState<{
    isDefault: boolean;
    stripePaymentMethodId: string;
    holderName: string;
  }>({
    isDefault: true,
    stripePaymentMethodId: "",
    holderName: "",
  });

  const [
    createBillingAddress,
    { loading: createBillingAddressLoading, error: createBillingAddressError },
  ] = useCreateBillingAddressMutation({
    update(cache, { data }) {
      const newBillingAddress = data?.createBillingAddress;
      if (newBillingAddress) {
        cache.modify({
          fields: {
            GetBillingAddress(existingBillingAddresses = []) {
              const newAddressRef = cache.writeFragment({
                id: cache.identify(newBillingAddress),
                data: newBillingAddress,
                fragment: BillingAddressFragmentFragmentDoc,
              });
              return [...existingBillingAddresses, newAddressRef];
            },
          },
        });
      }
    },
  });

  const [
    editBillingAddress,
    {
      loading: editingBillingAddressLoading,
      error: editingBillingAddressError,
    },
  ] = useEditBillingAddressMutation({
    update(cache, { data }) {
      const editedBillingAddress = data?.editBillingAddress;
      if (editedBillingAddress) {
        cache.modify({
          fields: {
            GetBillingAddress(existingBillingAddresses = []) {
              const newAddressRef = cache.writeFragment({
                id: cache.identify(editedBillingAddress),
                data: editedBillingAddress,
                fragment: BillingAddressFragmentFragmentDoc,
              });
              return [...existingBillingAddresses, newAddressRef];
            },
          },
        });
      }
    },
  });

  const [
    createPaymentMethod,
    { loading: createPaymentMethodLoading, error: createPaymentMethodError },
  ] = useCreatePaymentMethodMutation({
    update(cache, { data }) {
      const newPaymentMethod = data?.createPaymentMethod;
      if (newPaymentMethod) {
        cache.modify({
          fields: {
            GetPaymentMethod(existingPaymentMethods = []) {
              const newPaymentMethodRef = cache.writeFragment({
                id: cache.identify(newPaymentMethod),
                data: newPaymentMethod,
                fragment: PaymentMethodFragmentFragmentDoc,
              });
              return [...existingPaymentMethods, newPaymentMethodRef];
            },
          },
        });
      }
    },
  });

  const [
    editPaymentMethod,
    { loading: editPaymentMethodLoading, error: editPaymentMethodError },
  ] = useEditPaymentMethodMutation();

  const [
    payOutsatndingInvoice,
    {
      data: outstandingInvoiceData,
      loading: outstandingInvoiceLoading,
      error: outstandingInvoiceError,
    },
  ] = usePayOutstandingInvoiceMutation();

  const loading =
    createBillingAddressLoading ||
    editingBillingAddressLoading ||
    createPaymentMethodLoading ||
    editPaymentMethodLoading;
  const errorHandler = useAppErrorHandler(
    createBillingAddressError ||
      editingBillingAddressError ||
      createPaymentMethodError ||
      editPaymentMethodError
  );

  const onSaveAddresseData = React.useCallback(
    async (billingAddressData: BillingAddress) => {
      setBillingAddressToEdit(billingAddressData);
      setCurrentStep(2);
    },
    []
  );

  const onCancelAddressForm = React.useCallback(
    async (event?: any, reason?: string) => {
      if (modalType === 0) {
        setBillingAddressToEdit(createEmptyBillingAddress());

        const { stripePaymentMethodId } = createEmptyPaymentMethod();
        setPaymentMethodToEdit({
          isDefault: true,
          stripePaymentMethodId,
          holderName: "",
        });

        setCurrentStep(1);
        handleCloseModal();
      } else {
        event.stopPropagation();
      }
    },
    [handleCloseModal, modalType]
  );

  const onSavePaymentData = React.useCallback(
    async (
      paymentData:
        | PaymentMethodModel
        | {
            isDefault: boolean;
            stripePaymentMethodId: string;
            holderName: string;
          }
    ) => {
      setPaymentMethodToEdit(
        paymentData as {
          isDefault: boolean;
          stripePaymentMethodId: string;
          holderName: string;
        }
      );
      if (paymentSingle) {
        const requestPaymentMethodData: any = paymentMethodToEdit;
        await payOutsatndingInvoice({
          variables: {
            default: requestPaymentMethodData?.isDefault,
            methodId: requestPaymentMethodData.stripePaymentMethodId,
            ownerName: requestPaymentMethodData.holderName,
          },
        });
        createSubscription();
      } else {
        setCurrentStep(3);
      }
    },
    [
      payOutsatndingInvoice,
      createSubscription,
      paymentMethodToEdit,
      paymentSingle,
    ]
  );

  const onCancelPaymentForm = React.useCallback(async () => {
    const { stripePaymentMethodId } = createEmptyPaymentMethod();
    setPaymentMethodToEdit({
      isDefault: true,
      stripePaymentMethodId,
      holderName: "",
    });
    if (paymentSingle) {
      setCurrentStep(4);
    } else {
      setCurrentStep(1);
    }
  }, [paymentSingle]);

  const onConfirmPaymentOptions = React.useCallback(
    async (response: any) => {
      if (response === "newCard") {
        setPaymentSingle(true);
        setCurrentStep(2);
      } else {
        await payOutsatndingInvoice();
        createSubscription();
      }
    },
    [payOutsatndingInvoice, createSubscription]
  );

  const onConfirmHandler = React.useCallback(
    async (coupon: string) => {
      // Here save address and payment method data and redirecto to dashboard
      // We need check if the address and payment method data is already exist to know if we need create or update the data
      try {
        const requestAddressData: any = billingAddressToEdit;
        const requestPaymentMethodData: any =
          chatPro && paymentMethodData && paymentMethodData.id
            ? paymentMethodData
            : paymentMethodToEdit;

        if (updateAddressData || (chatPro && requestAddressData.id)) {
          // Update the current address data
          await editBillingAddress({
            variables: requestAddressData,
          });
        } else {
          delete requestAddressData.id;
          await createBillingAddress({
            variables: requestAddressData,
          });
        }

        if (
          updatePaymentMethodData ||
          (chatPro && requestPaymentMethodData.id)
        ) {
          // Update the current address data
          await editPaymentMethod({
            variables: {
              id: paymentMethodData!.id!,
              isDefault: true,
            },
          });
        } else {
          await createPaymentMethod({
            variables: {
              isDefault: requestPaymentMethodData?.isDefault,
              stripePaymentMethodId:
                requestPaymentMethodData.stripePaymentMethodId,
              holderName: requestPaymentMethodData.holderName,
            },
          });
        }

        const { id, name, priceInCent } =
          planData as AudionautPlanFragmentFragment;

        TagManager.dataLayer({
          dataLayer: {
            corporatusPlanName: name,
          },
        });

        TagManager.dataLayer({
          dataLayer: {
            // transaction_id: "T_12345_1",
            value: ((priceInCent ?? 0) / 100).toFixed(2),
            tax: 0,
            shipping: 0,
            currency: "USD",
            coupon,
            items: [planData],
          },
        });

        createSubscription(id ?? "", coupon);
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
          // setInitialEditable(true);
          // setInitialSaveActive(true);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the address"
            )
          );
        }
      }
    },
    [
      billingAddressToEdit,
      paymentMethodToEdit,
      updateAddressData,
      updatePaymentMethodData,
      createPaymentMethod,
      createBillingAddress,
      editBillingAddress,
      editPaymentMethod,
      createSubscription,
      planData,
      paymentMethodData,
      errorHandler,
      chatPro,
    ]
  );

  const onCancelConfirm = React.useCallback(async () => {
    setCurrentStep(2);
  }, []);

  React.useEffect(() => {
    if (openModal && currentAddressData && paymentMethodData) {
      const { id: addressId, isDefault: addressDefault } =
        currentAddressData as { id: string; isDefault: boolean };
      const {
        id: paymentId,
        stripePaymentMethodId,
        cardOwnerName,
        isDefault: paymentDefault,
      } = paymentMethodData as {
        id: string;
        stripePaymentMethodId: string;
        cardOwnerName: string;
        isDefault: boolean;
      };

      if (addressId) {
        setBillingAddressToEdit(currentAddressData);
        // setCurrentStep(2);
      }
      if (paymentId) {
        setPaymentMethodToEdit({
          stripePaymentMethodId: stripePaymentMethodId ?? "",
          holderName: cardOwnerName ?? "",
          isDefault: paymentDefault ?? false,
        });
      }

      setPaymentAvailable(
        !!addressId && addressDefault && !!paymentId && paymentDefault
      );

      // if (
      //   currentAddressData &&
      //   currentAddressData!.id &&
      //   paymentMethodData &&
      //   paymentMethodData!.id
      // ) {
      //   setCurrentStep(3);
      // }
    }
  }, [
    setCurrentStep,
    currentAddressData,
    paymentMethodData,
    openModal,
    setPaymentAvailable,
  ]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onCancelAddressForm}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      BackdropProps={{
        style: {
          backgroundColor: modalType ? "#000" : "",
        },
      }}
      disableEscapeKeyDown={!!modalType}
      className={`${classes.dialogContainer} ${
        chatPro && paymentAvailable ? classes.chatPro : ""
      }`}
    >
      <ProgressIndicator open={loading || !planData} />
      <SC.ModaleHeader
        hidden={chatPro && paymentAvailable}
        style={{
          background: modalType ? "#161616" : "",
        }}
      >
        <SC.CorporatusLogo />
        {/* {isWizard ? (
          <>
            <DialogTitle
              className={classes.modalTitle2}
              id="customized-dialog-title"
              hidden={!modalType}
            >
              <Logo />
            </DialogTitle>
            <DialogTitle
              className={classes.modalTitle}
              id="customized-dialog-title"
              hidden={!!modalType}
              color="primary"
            >
              Billing information
            </DialogTitle>
          </>
        ) : null}
        {!isWizard && currentStep !== 2 ? (
          <DialogTitle
            className={classes.modalTitle}
            id="customized-dialog-title"
            color="primary"
          >
            Make a payment
          </DialogTitle>
        ) : null}
        {!isWizard && currentStep === 2 ? (
          <DialogTitle
            className={classes.modalTitle}
            id="customized-dialog-title"
          >
            Add New Payment Method
          </DialogTitle>
        ) : null} */}
        <SC.IconCloseButton
          hidden={!!modalType}
          aria-label="close-modal"
          onClick={onCancelAddressForm}
          color="primary"
        >
          <CloseOutlined />
        </SC.IconCloseButton>
      </SC.ModaleHeader>
      <DialogContent className={classes.modalContent}>
        <Box component="div" className={classes.formContainer}>
          <Box
            hidden={!isWizard || (chatPro && paymentAvailable)}
            component="div"
          >
            <Typography
              className={`loginLbl ${currentStep !== 1 ? classes.hide : ""}`}
              style={{ marginBottom: "23px" }}
              color="primary"
            >
              Billing Address
            </Typography>
            <Typography
              className={`loginLbl ${
                currentStep !== 2 || paymentSingle ? classes.hide : ""
              }`}
              style={{ marginBottom: "23px" }}
              color="primary"
            >
              Payment Information
            </Typography>
            <Typography
              className={`loginLbl ${currentStep !== 3 ? classes.hide : ""}`}
              style={{ marginBottom: "23px" }}
              color="primary"
            >
              Confirm Payment
            </Typography>
          </Box>
          <Box
            hidden={!isWizard || (chatPro && paymentAvailable)}
            className={classes.steps}
          >
            {currentStep < 2 ? (
              <SvgIcon component={Step1Icon} />
            ) : (
              <CheckCircle style={{ color: "#B8CAC6" }} />
            )}
            <Divider />
            {currentStep < 3 ? (
              <SvgIcon component={Step2Icon} />
            ) : (
              <CheckCircle style={{ color: "#B8CAC6" }} />
            )}
            <Divider />
            <SvgIcon component={Step3Icon} />
          </Box>
          <Box
            hidden={!isWizard || (chatPro && paymentAvailable)}
            className={classes.stepsLbs}
          >
            <Typography color="primary">
              Billing
              <br />
              Address
            </Typography>
            <div />
            <Typography color="primary">
              Payment
              <br />
              Information
            </Typography>
            <div />
            <Typography color="primary">
              Confirm
              <br />
              Payment
            </Typography>
          </Box>
          {currentAddressData ? (
            <BillingAddressFormFields
              billingAddress={currentAddressData}
              onSubmit={onSaveAddresseData}
              onCancel={onCancelAddressForm}
              isWizard
              customClasses={classes.addressForm}
              show={currentStep === 1}
              modalType={modalType}
            />
          ) : null}
          {updatePaymentMethodData ? (
            <PaymentMethodCard
              show={currentStep === 2}
              paymentMethod={paymentMethodData}
              customClasses={classes.addressForm}
              isWizard
              onSubmit={onSavePaymentData}
              onCancel={onCancelPaymentForm}
            />
          ) : (
            <Elements stripe={stripePromise}>
              <PaymentMethodForm
                paymentMethodData={paymentMethodData}
                onSubmit={onSavePaymentData}
                onCancel={onCancelPaymentForm}
                customClasses={classes.addressForm}
                show={currentStep === 2}
                isWizard={!paymentSingle}
                isPaymentSingle={paymentSingle}
                amountOwed={amountOwed}
              />
            </Elements>
          )}
          <PaymentOptionsComponent
            onConfirmHandler={onConfirmPaymentOptions}
            show={currentStep === 4}
            amountOwed={amountOwed}
            paymentMethodData={paymentMethodData}
          />
          {isWizard ? (
            <PaymentConfirm
              show={currentStep === 3}
              currentPlan={planData as AudionautPlanFragmentFragment}
              billingAddress={billingAddressToEdit}
              paymentMethod={paymentMethodToEdit}
              onConfirmHandler={onConfirmHandler}
              onCancel={onCancelConfirm}
              onSetCurrentStep={setCurrentStep}
              isPaymentSingle={chatPro && paymentAvailable}
              coupon={couponPreloaded}
            />
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BilingModal;
