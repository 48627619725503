/* eslint-disable prettier/prettier */
import React from "react";

// Assets
import SC from "./check-email.styles";

interface CheckYourEmailProps {
  email: string;
  resendEmailHandler: React.FormEventHandler;
}

const CheckYourEmail: React.FC<CheckYourEmailProps> = (props) => {
  const { email, resendEmailHandler } = props;

  return (
    <SC.Form>
      <SC.Text>
        <b>
          If you have an account with us, a password reset link has been sent to
        </b>
        <br />
        {email}
      </SC.Text>
      <SC.Footer>
        Didn’t receive the email?
        <SC.Button
          type="submit"
          variant="outlined"
          onClick={resendEmailHandler}
        >
          Click to resend
        </SC.Button>
      </SC.Footer>
    </SC.Form>
  );
};

export default CheckYourEmail;
