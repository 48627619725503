import React from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  Snackbar,
  Slide,
  Paper,
} from "@material-ui/core";
import { Alert, AlertProps } from "components/alert/alert.component";

import AddIcon from "@material-ui/icons/Add";

import {
  useCreateTargetsGroupMutation,
  useGetAllTargetsGroupsQuery,
  useDeleteTargetsGroupMutation,
} from "graphql/types-and-hooks";
import { NEW_TARGETS_GROUP_FRAGMENT_GQL } from "graphql/audionaut.utils";

import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import Paginator from "components/paginator/paginator.component";
import ExportButton from "components/export-button/export-button.component";
import ButtonPrimary from "components/button-primary/button-primary.component";
import ButtonSecondary from "components/button-secondary/button-secondary.component";
import { isMobileResolution } from "commons/utils/device-info.util";

import { modalExportDataInterface } from "commons/models/audionaut.types";

import SC from "./target-groups.styles";

const TargetGroupsPage: React.FC = () => {
  const classes = SC.useStyles();

  const [createTargetsGroup, { loading: createTargetsGroupLoading }] =
    useCreateTargetsGroupMutation({
      update(cache, { data }) {
        const newTargetsGroup = data?.CreateTargetsGroup;
        if (newTargetsGroup) {
          cache.modify({
            fields: {
              GetTargetsGroup(existingTargetsGroup = []) {
                const newTargetsGroupRef = cache.writeFragment({
                  id: cache.identify(newTargetsGroup),
                  data: newTargetsGroup,
                  fragment: NEW_TARGETS_GROUP_FRAGMENT_GQL,
                });
                return [...existingTargetsGroup, newTargetsGroupRef];
              },
            },
          });
        }
      },
    });

  const {
    data: allTargetsGroupData,
    loading: targetsGroupDataLoading,
    error: errorTargetsGroupDataLogin,
    refetch: refetchTargetsGroups,
  } = useGetAllTargetsGroupsQuery({
    fetchPolicy: "no-cache",
  });

  const [deleteTargetGroup, { loading: deleteLoading }] =
    useDeleteTargetsGroupMutation();

  const allTargetsGroup = React.useMemo(
    () => allTargetsGroupData?.GetTargetsGroups ?? [],
    [allTargetsGroupData]
  );
  // const targetsGroupData = GetAllTargetsGroupsDocument();
  // const errorHandler = useAppErrorHandler(errorTargetsGroupDataLogin);

  const loading = targetsGroupDataLoading || createTargetsGroupLoading;

  // const initTargets = targetsGroupData?.GetTargetsGroups;
  const [targetsGroup, setTargetsGroup] = React.useState<any[]>([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openAddGroup, setOpenAddGroup] = React.useState(false);
  const [newGroupName, setNewGroupName] = React.useState<any>({});
  const [snackBarMessage, setSnackBarMessage] =
    React.useState<AlertProps & { delay?: number }>();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Delete dialog state and handlers
  const handleUpdatesTargets = React.useCallback(async () => {
    refetchTargetsGroups();
  }, [refetchTargetsGroups]);

  const handleClickToggleAddGroup: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenAddGroup(!openAddGroup);
    }, [openAddGroup, setOpenAddGroup]);

  const handleAddGroupSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      const newGroup = {
        nameGroup: newGroupName.target.value,
        editable: true,
      };

      const response = await createTargetsGroup({
        variables: newGroup,
      });
      const groupId = response.data!.CreateTargetsGroup!.id;

      setSnackBarMessage({
        message: "The group has been added successfully",
        severity: "success",
      });

      handleUpdatesTargets();
      handleClickToggleAddGroup(event);
    },
    [
      newGroupName,
      createTargetsGroup,
      handleUpdatesTargets,
      handleClickToggleAddGroup,
    ]
  );

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const exportOptsHandler = React.useCallback(
    (exportOpts: modalExportDataInterface) => {
      // console.debug("=====> Request Excel export tables: ", exportOpts);
    },
    []
  );

  React.useEffect(() => {
    if (!loading) {
      setTargetsGroup(allTargetsGroup!);
      if (allTargetsGroup && allTargetsGroup.length > 0) {
        const existDefaultGroup = allTargetsGroup.findIndex((group) => {
          return group?.name === "Default" && !group?.editable;
        });
        const defaultGroups = allTargetsGroup.filter(
          (group) => group?.name === "Default"
        );
        if (existDefaultGroup === -1) {
          const newGroup = {
            nameGroup: "Default",
            editable: false,
          };

          createTargetsGroup({
            variables: newGroup,
          }).then(() => {
            handleUpdatesTargets();
          });
        } else if (defaultGroups.length > 1) {
          deleteTargetGroup({
            variables: {
              groupId: defaultGroups[defaultGroups.length - 1]!.id!,
            },
          });
        }
      } else {
        const newGroup = {
          nameGroup: "Default",
          editable: false,
        };

        createTargetsGroup({
          variables: newGroup,
        }).then(() => {
          handleUpdatesTargets();
        });
      }
    }
  }, [
    allTargetsGroup,
    setTargetsGroup,
    createTargetsGroup,
    handleUpdatesTargets,
    deleteTargetGroup,
    loading,
  ]);

  React.useEffect(() => {
    refetchTargetsGroups();
  }, [refetchTargetsGroups]);

  return (
    <SC.TGContainer>
      <ProgressIndicator open={loading} />
      <SC.TGContent>
        <Snackbar
          open={!!snackBarMessage}
          autoHideDuration={snackBarMessage?.delay ?? 3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackBarMessage?.severity}
            message={snackBarMessage?.message}
          />
        </Snackbar>
        <SC.TGHeader component="div">
          <SC.TGHeaderTitle component="div">
            {/* <Typography component="h1">Groups</Typography> */}
            <h1>Groups</h1>
            {/* uncommnet when the export backend functionality is available */}
            {/* <ExportButton
              items={targetsGroup}
              handleFormData={exportOptsHandler}
            /> */}
            <AppPermissionValidator
              appPermission={APP_PERMISSION.TARGET_ADDEDIT_TARGET_GROUPS}
            >
              <ButtonPrimary
                className={classes.updateBtn}
                onClick={handleClickToggleAddGroup}
                startIcon={<AddIcon />}
              >
                {isMobileResolution() ? "" : "Add"}
              </ButtonPrimary>
            </AppPermissionValidator>
            <Dialog
              id="addGroupDialog"
              open={openAddGroup}
              onClose={handleClickToggleAddGroup}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Create new group target
              </DialogTitle>
              <Box component="form" onSubmit={handleAddGroupSubmit}>
                <SC.GroupDialogContent>
                  <SC.GroupTextField
                    autoFocus
                    margin="dense"
                    id="nameTarget"
                    label="Group Name"
                    type="text"
                    placeholder="group name"
                    variant="outlined"
                    fullWidth
                    onChange={setNewGroupName}
                    required
                  />
                </SC.GroupDialogContent>
                <SC.GroupDialogActions>
                  <ButtonSecondary onClick={handleClickToggleAddGroup}>
                    Cancel
                  </ButtonSecondary>
                  <ButtonPrimary type="submit">Add</ButtonPrimary>
                </SC.GroupDialogActions>
              </Box>
            </Dialog>
          </SC.TGHeaderTitle>
          <SC.GroupPaginator
            totalItems={targetsGroup.length}
            updateItems={setRowsPerPage}
            updatePage={setPage}
          />
        </SC.TGHeader>
        <SC.TGBody component="div" key="groups-table">
          <List>
            {targetsGroup
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((targetGroup, key) => (
                <ListItem key={`tg-${targetGroup.id}`}>
                  <SC.TGListItem
                    group={targetGroup}
                    indexGroup={targetGroup.id}
                    updateTargets={handleUpdatesTargets}
                    showToast={setSnackBarMessage}
                  />
                </ListItem>
              ))}
          </List>
        </SC.TGBody>
      </SC.TGContent>
      <Box hidden={targetsGroup.length > 1} className={classes.adviseMessage}>
        <Slide direction="up" in={targetsGroup.length === 1}>
          <Paper elevation={4}>
            <GenericEmptyMessages
              show
              title="Grouping your videos makes it easier for you to find them when you need them."
              description="To start creating groups, click the + Add button. Give your new group a memorable name that helps you remember exactly what it's about!"
            />
          </Paper>
        </Slide>
      </Box>
    </SC.TGContainer>
  );
};

export default TargetGroupsPage;
