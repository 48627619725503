/* eslint-disable */
// Dependencies
import axios from 'axios';

// Utils
import HttpError, { HTTP_STATUS_CODES } from './http-error.util';


export const HEADERS = Object.freeze({
  CONTENT_TYPE: {
    APP_JSON: 'application/json',
  },
  ACCEPT: {
    APP_JSON: 'application/json',
  }
});


class Api {
  apiUrl = '';

  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  apiEndpoint = endpoint => `${this.apiUrl}/${endpoint}`;

  apiOptions = (options = {}, query = {}) => {
    const {
      method = 'GET',
      headers = {
        'Content-Type': HEADERS.CONTENT_TYPE.APP_JSON
      },
      body = {}
    } = options;

    const newOptions = {
      method,
      headers,
      params: query,
      data: body
    };

    return newOptions;
  };

  apiFetch = (endpoint, options = {}, query = {}) => {
    const fetchEndpoint = this.apiEndpoint(endpoint);
    const fetchOptions = this.apiOptions(options, query);

    return new Promise((resolve, reject) => {
      axios(fetchEndpoint, fetchOptions)
        .then((response) => {
          const { status } = response;

          if (status === HTTP_STATUS_CODES.OK) {
            resolve(response.data);
          } else {
            throw new Error('Unsupported 2xx HTTP reponse!');
          }
        })
        .catch((error) => {
          if (error.isAxiosError) {
            const { response, request, message } = error;
            if (response) {
              reject(new HttpError(response.status, response.data));
            } else if (request) {
              reject(new HttpError(request.status, message));
            } else {
              reject(error);
            }
          } else {
            reject(error);
          }
        });
    });
  };
}

export default Api;
