/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";

// GraphQL
import {
  OrganisationData,
  DepartmentData,
  GroupData,
  useGetOrganisationQuery,
  useGetDepartmentQuery,
  useGetGroupQuery,
} from "graphql/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Components
import {
  PlansCouponsForm,
  PlansCouponsFormProps,
} from "./plans-coupons-form.component";

type PlansCouponsFormContainerProps = Omit<
  PlansCouponsFormProps,
  "organisations" | "departments" | "groups"
>;

const PlansCouponsFormContainer: React.FC<PlansCouponsFormContainerProps> = (
  props
) => {
  // const { data: departmentsData, error: departmentsError } =
  //   useGetDepartmentQuery();
  // const { data: organisationsData, error: organisationsError } =
  //   useGetOrganisationQuery();
  // const { data: groupsData, error: groupsError } = useGetGroupQuery();

  // useAppErrorHandler(departmentsError || organisationsError || groupsError);

  return <PlansCouponsForm {...props} />;
};

export default PlansCouponsFormContainer;
