/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Checkbox,
  OutlinedInput,
  Box as BoxMui,
  GridProps,
  BoxProps,
} from "@material-ui/core/";

const generalStyles = makeStyles((theme) => ({
  profileHeader: {
    display: "flex",
    alignItems: "end",
    paddingBottom: "20px",
    "& .nameLbl": {
      fontSize: "20px",
      lineHeight: "25px",
      fontWeight: "bold",
      textAlign: "left",
    },
    "& .companyLbl": {
      fontSize: "15px",
      lineHeight: "19px",
      fontWeight: "bold",
      color: "#DCDCDC",
      textAlign: "left",
    },
  },
  formContainer: {
    marginTop: "20px",
    marginBottom: "18px",
  },
  subtitle: {
    paddingTop: "18px",
    paddingBottom: "18px",
    fontSize: "20px",
    lineHeight: "25px",
    fontWeight: "bold",
    textAlign: "left",
  },
  hide: {
    display: "none",
  },
}));

interface CustomGridProps extends GridProps {
  disabledMarginTop?: boolean;
}

const useGridStyles = makeStyles((theme) => ({
  root: {
    zIndex: 0,
    textAlign: "center",
    // backgroundColor: "#FFFFFF",
    marginTop: (props: CustomGridProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(2),
  },
}));

const Grid = (props: CustomGridProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useGridStyles(props);

  return <GridMui className={classes.root} {...muiProps} />;
};

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const useBoxStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props: CustomBoxProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(4),
    [theme.breakpoints.down(540)]: {
      marginTop: "unset !important",
    },
  },
}));

const Box = (props: CustomBoxProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useBoxStyles(props);

  return <BoxMui className={classes.root} {...muiProps} />;
};

const InputLabel = withStyles({
  root: {
    textAlign: "left",
    transform: "translate(12px, 10px) scale(0.75)",
  },
})(InputLabelMui);

const InputLabelCenter = withStyles({
  root: {
    textAlign: "center",
  },
})(InputLabelMui);

export default {
  Grid,
  InputLabel,
  InputLabelCenter,
  Box,
  OutlinedInput,
  Checkbox,
  generalStyles,
};
