/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { USER } = ActionTypes;

const INITIAL_STATE = {
  // Update user
  isUpdatingData: false,
  onUpdateError: null,
  onUpdateSuccess: null,
  // Delete user
  isDeletingData: false,
  onDeleteError: null,
  onDeleteSuccess: null,
  // Link user to groups
  isAddingUserGroups: false,
  onUserGroupsAddError: null,
  onUserGroupsAddSuccess: null,
  // Unlink user from groups
  isDeletingUserGroups: false,
  onUserGroupsDeleteError: null,
  onUserGroupsDeleteSuccess: null
};


const usersReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Update user
    case USER.UPDATE.START:
      return {
        ...state,
        isUpdatingData: true,
        onUpdateError: null,
        onUpdateSuccess: null
      };
    case USER.UPDATE.SUCCESS: {
      return {
        ...state,
        onUpdateSuccess: action.payload,
        isUpdatingData: false,
      };
    }
    case USER.UPDATE.FAILURE:
      return {
        ...state,
        isUpdatingData: false,
        onUpdateError: action.payload,
      };
    case USER.UPDATE.CLEAR:
      return {
        ...state,
        onUpdateError: null,
        onUpdateSuccess: null
      };
    // Delete user
    case USER.DELETE.START:
      return {
        ...state,
        isDeletingData: true,
        onDeleteError: null
      };
    case USER.DELETE.SUCCESS: {
      return {
        ...state,
        onDeleteSuccess: action.payload,
        isDeletingData: false
      };
    }
    case USER.DELETE.FAILURE:
      return {
        ...state,
        isDeletingData: false,
        onDeleteError: action.payload
      };
    case USER.DELETE.CLEAR:
      return {
        ...state,
        onDeleteError: null,
        onDeleteSuccess: null
      };
    // Link user to groups
    case USER.GROUPS.ADD.START:
      return {
        ...state,
        isAddingUserGroups: true,
        onUserGroupsAddError: null
      };
    case USER.GROUPS.ADD.SUCCESS:
      return {
        ...state,
        onUserGroupsAddSuccess: action.payload,
        isAddingUserGroups: false
      };
    case USER.GROUPS.ADD.FAILURE:
      return {
        ...state,
        onUserGroupsAddError: action.payload,
        isAddingUserGroups: false
      };
    case USER.GROUPS.ADD.CLEAR:
      return {
        ...state,
        onUserGroupsAddError: null,
        onUserGroupsAddSuccess: null
      };
    // Unlink user from groups
    case USER.GROUPS.DELETE.START:
      return {
        ...state,
        isDeletingUserGroups: true,
        onUserGroupsDeleteError: null
      };
    case USER.GROUPS.DELETE.SUCCESS:
      return {
        ...state,
        onUserGroupsDeleteSuccess: action.payload,
        isDeletingUserGroups: false
      };
    case USER.GROUPS.DELETE.FAILURE:
      return {
        ...state,
        onUserGroupsDeleteError: action.payload,
        isDeletingUserGroups: false
      };
    case USER.GROUPS.DELETE.CLEAR:
      return {
        ...state,
        onUserGroupsDeleteError: null,
        onUserGroupsDeleteSuccess: null
      };
    default: return state;
  }
};


export default usersReducerFn;
