import {
  TableCell,
  TableContainer as TableContainerBase,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

interface DataSubTable {
  id: string;
  videoName: string;
  name: string;
  url: string;
  mediasCount: number;
  totalMedias: number;
  videos: string;
  views: string;
  subscribers: string;
  update: string;
  created: string;
  creation: string;
  actions: string;
  image: string;
  status: string;
  scheduled: number;
  duration: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof DataSubTable;
  label: string;
  numeric: boolean;
  align: any;
  customClasses?: string;
}
const channelsHeadCells: HeadCell[] = [
  {
    id: "videoName",
    numeric: false,
    disablePadding: true,
    label: "Channel Name",
    align: "left",
  },
  {
    id: "url",
    numeric: true,
    disablePadding: false,
    label: "URL",
    align: "left",
  },
  {
    id: "videos",
    numeric: true,
    disablePadding: false,
    label: "Videos",
    align: "center",
  },
  {
    id: "subscribers",
    numeric: true,
    disablePadding: false,
    label: "Subscribers",
    align: "center",
  },
  {
    id: "update",
    numeric: true,
    disablePadding: false,
    label: "Last Updates",
    align: "center",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    align: "center",
  },
];
const playlistsHeadCells: HeadCell[] = [
  {
    id: "videoName",
    numeric: false,
    disablePadding: true,
    label: "Playlist Name",
    align: "left",
  },
  {
    id: "url",
    numeric: true,
    disablePadding: false,
    label: "URL",
    align: "left",
  },
  {
    id: "videos",
    numeric: true,
    disablePadding: false,
    label: "Videos",
    align: "center",
  },
  {
    id: "update",
    numeric: true,
    disablePadding: false,
    label: "Last Updates",
    align: "center",
  },
  {
    id: "views",
    numeric: true,
    disablePadding: false,
    label: "Views",
    align: "center",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    align: "center",
  },
];
const videosHeadCells: HeadCell[] = [
  {
    id: "videoName",
    numeric: false,
    disablePadding: true,
    label: "Video Name",
    align: "left",
  },
  {
    id: "url",
    numeric: true,
    disablePadding: false,
    label: "URL",
    align: "left",
  },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "Duration",
    align: "center",
    customClasses: "centerText",
  },
  {
    id: "creation",
    numeric: true,
    disablePadding: false,
    label: "Date Creation",
    align: "left",
    customClasses: "centerText",
  },
  {
    id: "views",
    numeric: true,
    disablePadding: false,
    label: "Views",
    align: "center",
    customClasses: "centerText",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    align: "center",
    customClasses: "centerText",
  },
];

const hashtagsHeadCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Video Name",
    align: "left",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Date Creation",
    align: "left",
    customClasses: "centerText",
  },
  {
    id: "mediasCount",
    numeric: true,
    disablePadding: false,
    label: "Videos",
    align: "center",
  },
  // {
  //   id: "views",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Views",
  //   align: "center",
  //   customClasses: "centerText",
  // },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    align: "center",
    customClasses: "centerText",
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTableTitle: {
      minHeight: "35px",
      padding: "0px",
      [theme.breakpoints.down(540)]: {
        flexDirection: "column",
      },
    },
    title: {
      fontSize: theme.fontGeneralStyles.medium.fontSize,
      lineHeight: theme.fontGeneralStyles.medium.lineHeight,
      fontWeight: 800,
      flex: "1 1 100%",
      paddingLeft: "16px",
      paddingRight: "16px",
      [theme.breakpoints.down(540)]: {
        width: "100%",
        fontSize: theme.fontGeneralStyles.small.fontSize,
        lineHeight: theme.fontGeneralStyles.small.lineHeight,
        padding: "10px 16px 0px",
        marginBottom: "5px",
      },
    },
    table: {
      minWidth: 750,
    },
    headerLabel: {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      fontWeight: "bold",
      "&.centerText": {
        textAlign: "center",
      },
      [theme.breakpoints.down(540)]: {
        padding: "10px 8px !important",
        fontSize: theme.fontGeneralStyles.default.fontSize,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
      },
    },
    subTableHeader: {
      borderBottom: "1px solid #EAEAEA",
      "& th:first-child": {
        paddingLeft: "7px",
      },
    },
    paddingLeft0: {
      "& td": {
        paddingLeft: "0px",
      },
      "& th": {
        paddingLeft: "0px !important",
      },
      "& span": {
        paddingLeft: "0px",
      },
    },
    cellBodyStyle: {
      fontSize: "12px",
      lineHeight: "15px",
      color: "#444444",
      borderBottom: "0px",
      "& .newPage": {
        backgroundColor: "#F0E7F4 !important",
        "& div": {
          backgroundColor: "#8B35B0 !important",
        },
      },
    },
    nameCell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "16px",
      paddingBottom: "16px",
      verticalAlign: "unset !important",
    },
    noimg: {
      color: "#FFFFFF",
      backgroundColor: "#B8CAC6",
    },
    cellAvatar: {
      height: "30px",
      width: "30px",
    },
    linkInCell: {
      color: "#A23A74",
      fontSize: "12px",
      lineHeight: "15px",
      textDecorationLine: "underline",
    },
    popOverDatePicker: {
      "& .MuiPaper-root": {
        width: "372px",
        height: "417px",
        boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
        borderRadius: "10px",
        padding: "21px 31px",
        "& .MuiTextField-root": {
          width: "100%",
          top: "-10px",
          "& .MuiFormLabel-root": {
            position: "absolute",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#B8CAC6",
            background: "#FFFFFF",
            top: "7px",
            left: "5px",
            zIndex: "1",
            padding: "1px 5px",
          },
          "& .MuiInput-root": {
            border: "2px solid #7E6FA2",
            boxSizing: "border-box",
            borderRadius: "4px",
            padding: "10px",
            fontSize: "18px",
            lineHeight: "22px",
            color: "#B8CAC6",
            "& input": {
              padding: "0px",
            },
          },
          "& .MuiInput-root:after": {
            borderBottom: "none",
          },
          "& .MuiInput-root:before": {
            borderBottom: "none",
          },
        },
      },
    },
  })
);

const TableContainer = withStyles(() => ({
  root: {
    maxHeight: "330px",
  },
}))(TableContainerBase);

const TableCellSticky = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      position: "sticky",
      left: "31px",
      zIndex: "11",
      "&.stickyCell": {
        maxWidth: "120px",
        borderRight: "1px solid #E1E1E1",
        boxShadow: "7px 0 5px -4px rgba(0,0,0,0.1)",
      },
      "&.checkbox": {
        left: "0px !important",
        maxWidth: "31px",
      },
    },
  },
}))(TableCell);

export default {
  useStyles,
  channelsHeadCells,
  playlistsHeadCells,
  videosHeadCells,
  hashtagsHeadCells,
  TableContainer,
  TableCellSticky,
};
