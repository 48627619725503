import { withStyles, Theme, Button } from "@material-ui/core";

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
      opacity: "0.5",
    },
    "& svg": {
      color: theme.button.primary.color,
    },
    [theme.breakpoints.down(540)]: {
      height: "30px",
      width: "120px",
    },
  },
}))(Button);

export default ButtonPrimary;
