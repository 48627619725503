import React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Button, FormControlLabel, Box, Radio } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldSetContainer: {
      marginTop: "35px",
    },
  })
);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.medium.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
}))(Button);

const FormControlLabelOption = withStyles((theme) => ({
  root: {
    border: "1px solid #C6CACC",
    borderRadius: "4px",
    marginRight: "0px",
    marginLeft: "0px",
    marginBottom: "27px",
    "& .MuiRadio-root": {
      padding: "11px",
    },
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "32px",
    },
  },
}))(FormControlLabel);

const ResumeAmount = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    marginBottom: "37px",
    "& p": {
      fontSize: "16px",
      lineHeight: "32px",
      fontWeight: 500,
      "&:first-child": {
        flex: 1,
        fontWeight: 700,
      },
    },
  },
}))(Box);

const FooterOptions = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "right",
  },
}))(Box);

const RadioIcon = withStyles((theme) => ({
  root: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    border: "1.5px solid #979697",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
  },
}))(Box);

const CheckedRadioIcon = withStyles((theme) => ({
  root: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    border: "1.5px solid #B8CAC6",
    backgroundColor: "#FFF",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage: "radial-gradient(#B8CAC6,#B8CAC6 28%,transparent 32%)",
      content: "''",
      marginTop: "-2px",
      marginLeft: "-3px",
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
  },
}))(Box);

export default {
  useStyles,
  ButtonPrimary,
  FormControlLabelOption,
  ResumeAmount,
  FooterOptions,
  RadioIcon,
  CheckedRadioIcon,
};
