/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
// Dependencies
import React from "react";
import { Snackbar, Box } from "@material-ui/core";

// Components
import { TableColumn } from "components/table/table.component";
import { Alert, AlertProps } from "components/alert/alert.component";
import UserForm from "components/user-form/user-form.container";
import { UserFilteringLeftPane } from "components/user-filtering-left-pane/user-filtering-left-pane.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { ActionButton } from "components/action-button/action-button.component";
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// GraphQL
import {
  UserData,
  GroupData,
  useGetAllUsersQuery,
  useGetGroupQuery,
  useCreateUserMutation,
  useAddUserGroupMutation,
  useUpdateUserDataMutation,
  useUpdateUserOrganisationMutation,
  useUpdateUserDeparmentMutation,
  useUpdateUserGroupMutation,
  useDeleteUserMutation,
  Maybe,
  PermissionType,
  useGetCurrentUserOrganisationQuery,
  useGetAllUsersLazyQuery,
  useSendEmailVerificationMutation,
} from "graphql/types-and-hooks";
import { createEmptyUserData, NEW_USER_FRAGMENT_GQL } from "graphql/rbac.utils";

// Utils
import {
  useAppErrorHandler,
  isServerError,
  UIError,
  UIErrorCodes,
} from "errors/app.errors";

// Assets
import DeleteIcon from "@material-ui/icons/Delete";
// import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import { Reference, StoreObject } from "@apollo/client";
import SC from "./user-management.styles";

const UserManagementPage: React.FC = () => {
  const initialUserDataEmpty = createEmptyUserData();
  const [initialUserData, setInitialUserData] =
    React.useState(initialUserDataEmpty);
  const [openLeftPane, setOpenLeftPane] = React.useState(false);
  const [openAddUserForm, setOpenAddUserForm] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [saveActive, setSaveActive] = React.useState(false);
  const [currentOrganisation, setCurrentOrganization] = React.useState({
    id: "",
    name: "",
    email: "",
  });
  const [users, setUsers] = React.useState<UserData[]>([]);

  const [createUser, { loading: createUserLoading }] = useCreateUserMutation({
    update(cache, { data }) {
      const newUser = data?.AddUser[0];
      if (newUser) {
        cache.modify({
          fields: {
            GetUser(existingUsers = []) {
              const newUserRef = cache.writeFragment({
                id: cache.identify(newUser),
                data: newUser,
                fragment: NEW_USER_FRAGMENT_GQL,
              });
              return [...existingUsers, newUserRef];
            },
          },
        });
      }
    },
  });

  const [addUserGroup, { loading: addUserGroupLoading }] =
    useAddUserGroupMutation();

  const [updateUserData, { loading: updateUserDataLoading }] =
    useUpdateUserDataMutation();
  const [updateUserDepartment, { loading: updateUserDepartmentLoading }] =
    useUpdateUserDeparmentMutation();
  const [updateUserOrganisation, { loading: updateUserOrganisationLoading }] =
    useUpdateUserOrganisationMutation();
  const [updateUserGroup, { loading: updateUserGroupLoading }] =
    useUpdateUserGroupMutation();

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation({
    update(cache, { data }) {
      const userToDeleteId = data?.DeleteUser;
      cache.modify({
        fields: {
          GetUser(existingUsers, { readField }) {
            return existingUsers.filter(
              (user: Reference | StoreObject | undefined) =>
                userToDeleteId !== readField("id", user)
            );
          },
        },
      });
    },
  });

  const {
    data: currentOrganisationData,
    loading: currentOrganisationLoading,
    error: currentOrganisationError,
  } = useGetCurrentUserOrganisationQuery();

  const [
    getUsersData,
    {
      data: usersData,
      loading: usersDataLoading,
      error: errorUsersDataLoading,
    },
  ] = useGetAllUsersLazyQuery();

  const {
    data: groupsData,
    loading: groupsDataLoading,
    error: errorGroupsDataLoading,
  } = useGetGroupQuery();

  const [
    sendVerifyEmail,
    {
      data: verifyEmailData,
      loading: verifyEmailLoading,
      error: verifyEmailError,
    },
  ] = useSendEmailVerificationMutation();

  const errorHandler = useAppErrorHandler(
    errorUsersDataLoading ||
      errorGroupsDataLoading ||
      currentOrganisationError ||
      verifyEmailError
  );

  // const users = React.useMemo(() => usersData?.GetUser ?? [], [usersData]);
  const [filteredUsers, setFilteredUsers] = React.useState<UserData[]>();

  const loading =
    createUserLoading ||
    addUserGroupLoading ||
    updateUserDataLoading ||
    updateUserDepartmentLoading ||
    updateUserOrganisationLoading ||
    updateUserGroupLoading ||
    deleteUserLoading ||
    usersDataLoading ||
    groupsDataLoading ||
    currentOrganisationLoading ||
    verifyEmailLoading;

  const handlerCreateUserFormOnSubmit = React.useCallback(
    async (userData: any) => {
      try {
        // const groups = userData.groups?.map((group: any) => group?.id);
        const response = await createUser({
          variables: {
            email: userData.email!,
            firstName: userData.firstName!,
            lastName: userData.lastName!,
            phone: userData.phone!,
            active: userData.active!,
            profilePicture: userData.profilePicture!,
            departmentName: userData.departmentName!,
            password: userData.newPassword!,
            organisationID: currentOrganisation.id,
          },
        });
        // const userId = response.data!.AddUser[0]!.id;

        // await updateUserOrganisation({
        //   variables: {
        //     userID: userId!,
        //     organisationID: userData?.organisation?.id,
        //   },
        // });

        // await updateUserDepartment({
        //   variables: {
        //     userID: userId!,
        //     departmentID: userData?.department?.id,
        //   },
        // });

        // if (groups?.length !== 0) {
        //   await addUserGroup({
        //     variables: {
        //       userID: userId!,
        //       groupID: groups,
        //     },
        //   });
        // }

        setOpenAddUserForm(false);
        setSnackBarMessage({
          message: "The user has been created successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
          // setInitialUserData(userData);
          // setSaveActive(true);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the user"
            )
          );
        }
      }
    },
    [
      // addUserGroup,
      createUser,
      currentOrganisation,
      // updateUserDepartment,
      // updateUserOrganisation,
      errorHandler,
    ]
  );

  const handlerEditUserFormOnSubmit = React.useCallback(
    async (userData: any) => {
      try {
        // const userId = userData.id!;
        // const OriginalUserData = users.find((user) => user.id === userId);
        // const initialCompanyId = OriginalUserData?.organisation?.id!;
        // const initialDepartmentId = OriginalUserData?.department?.id!;
        // const initialGroups = OriginalUserData?.groups?.map(
        //   (group) => group?.id
        // );
        // const groupsSelected = userData.groups?.map((group) => group?.id);

        await updateUserData({
          variables: {
            id: userData?.id!,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            profilePicture: userData?.profilePicture,
            email: userData?.email,
            active: userData?.active,
            password: userData.newPassword,
            departmentName: userData.departmentName,
          },
        });

        // if (initialCompanyId !== userData?.organisation?.id!)
        //   await updateUserOrganisation({
        //     variables: {
        //       userID: userData?.id!,
        //       organisationID: userData?.organisation?.id,
        //     },
        //   });

        // if (initialDepartmentId !== userData?.department?.id!)
        //   await updateUserDepartment({
        //     variables: {
        //       userID: userData?.id!,
        //       departmentID: userData?.department?.id,
        //     },
        //   });

        // if (!isEqual(initialGroups, groupsSelected))
        //   await updateUserGroup({
        //     variables: {
        //       userID: userData?.id!,
        //       groupID: initialGroups as string[],
        //       groupIDNew: groupsSelected as string[],
        //     },
        //   });

        setSnackBarMessage({
          message: "The profile has been updated successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updating the profile"
            )
          );
        }
      }
    },
    [
      errorHandler,
      updateUserData,
      // updateUserDepartment,
      // updateUserGroup,
      // updateUserOrganisation,
      // users,
    ]
  );

  const handlerDeleteUser = React.useCallback(
    async (userId: Maybe<string> | undefined) => {
      try {
        const OriginalUserData = users.find((user: any) => user.id === userId);

        await deleteUser({
          variables: {
            userID: OriginalUserData?.id!,
          },
        });

        setSnackBarMessage({
          message: "The user has been deleted successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the user"
            )
          );
        }
      }
    },
    [deleteUser, errorHandler, users]
  );

  const handlerSendVerificationEmail = React.useCallback(
    async (userId: Maybe<string> | undefined) => {
      try {
        const OriginalUserData = users.find((user: any) => user.id === userId);

        sendVerifyEmail({
          variables: {
            userId: OriginalUserData?.id,
            userEmail: OriginalUserData?.email,
          },
        });

        setSnackBarMessage({
          message: "The verification email has been sent successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the user"
            )
          );
        }
      }
    },
    [sendVerifyEmail, errorHandler, users]
  );

  const handleTableActions = (action: TableToolbarAction) => {
    if (action === "filter-results") {
      if (openLeftPane) setOpenLeftPane(false);
      else setOpenLeftPane(true);
    }

    if (action === "add") {
      if (openAddUserForm) setOpenAddUserForm(false);
      else {
        setOpenAddUserForm(true);
        setSaveActive(false);
        setInitialUserData(initialUserDataEmpty);
      }
    }
  };

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handleCancelAddUser = () => {
    setSaveActive(false);
    setOpenAddUserForm(false);
  };

  const columns: TableColumn<UserData>[] = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        align: "left",
        width: 200,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        align: "left",
        width: 150,
      },
      {
        Header: "Organization",
        accessor: (user) => user.organisation?.name,
        align: "left",
        width: 150,
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        width: 250,
      },
      {
        Header: "Groups",
        accessor: (user) =>
          user.groups?.map((group, i, arr) => {
            if (arr.length - 1 === i) {
              return group?.name;
            }
            return `${group?.name}, `;
          }),
        align: "left",
        width: 200,
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: ({ value }) => <SC.Checkbox checked={value ?? false} />,
        align: "center",
        width: 40,
      },
      {
        id: "actions",
        Header: ["Actions"],
        accessor: (user) => user,
        Cell: ({ value }: { value: UserData }) => {
          const { id, emailVerified } = value;
          return (
            <>
              <ActionButton
                key="Delete"
                title="Delete"
                icon={<DeleteIcon />}
                handler={() => handlerDeleteUser(id)}
                displayPopover
                popoverMessage="Are you sure you want to delete this item??
You won't be able to recover them"
                popoverButtons
              />
              {!emailVerified ? (
                <SC.CustomActionButton
                  key="VerifyEmail"
                  title="Send Email"
                  icon={<EmailIcon />}
                  handler={() => handlerSendVerificationEmail(id)}
                  displayPopover
                  popoverMessage="Are you sure you send the verification email?"
                  popoverButtons
                />
              ) : null}
            </>
          );
        },

        align: "center",
        width: 40,
      },
    ],
    [handlerDeleteUser, handlerSendVerificationEmail]
  );

  const appPermissionValidator = useAppPermissionValidator();
  const addUserForm = openAddUserForm ? (
    <UserForm
      initialUserData={initialUserData}
      initialEditable
      saveActive={saveActive}
      isProfile={false}
      onSubmit={handlerCreateUserFormOnSubmit}
      onCancel={handleCancelAddUser}
    />
  ) : undefined;

  const onlyCheckbox = false;

  React.useEffect(() => {
    if (
      currentOrganisationData &&
      currentOrganisationData.getCurrentUserOrganisation
    ) {
      setCurrentOrganization({
        id: currentOrganisationData.getCurrentUserOrganisation.id!,
        name: currentOrganisationData.getCurrentUserOrganisation.name!,
        email: currentOrganisationData.getCurrentUserOrganisation.email!,
      });

      getUsersData({
        variables: {
          organisationID:
            currentOrganisationData.getCurrentUserOrganisation.id!,
        },
      });
    }
  }, [currentOrganisationData, getUsersData]);

  React.useEffect(() => {
    if (usersData && usersData.GetUser) {
      setUsers(usersData.GetUser!);
    }
  }, [usersData]);

  return (
    <Box flex="1" height="100%">
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>

      <ProgressIndicator open={loading} />

      <SC.UserTable
        title="Users"
        persistenceId="798b733f-ef60-40de-876e-d85e039165b9"
        data={filteredUsers ?? users}
        columns={columns}
        onAction={handleTableActions}
        actionsOnLeft={
          appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)
            ? ["add"]
            : undefined
        }
        actionsOnRight={["filter-results"]}
        leftPanel={
          <UserFilteringLeftPane
            users={users}
            groups={groupsData?.GetGroup as GroupData[]}
            onFilter={setFilteredUsers}
            onlyCheckbox={onlyCheckbox}
          />
        }
        topPanel={addUserForm}
        renderExpandedRowSubComponent={(row) => (
          <UserForm
            initialUserData={row.original}
            initialEditable={false}
            onSubmit={handlerEditUserFormOnSubmit}
            onCancel={() => null}
            isProfile
          />
        )}
      />
    </Box>
  );
};

export default UserManagementPage;
