import React from "react";

import {
  withStyles,
  makeStyles,
  TablePagination,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  DialogProps,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  MuiThemeProvider,
  createTheme,
  createStyles,
  Tooltip,
  Chip,
  CardMedia,
  Theme,
  Divider,
  Typography,
  IconButton,
  Popover,
  SvgIcon,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import VisibilityIcon from "@material-ui/icons/Visibility";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

import WaveSurfer from "wavesurfer.js";
import Cursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
import MarkersPlugin from "wavesurfer.js/dist/plugin/wavesurfer.markers";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";

import { averageNumber, secToStrTime } from "commons/utils/number-format";

import MultimediaMetadataItem from "components/multimedia-metadata-item/multimedia-metadata-item.component";
import AudionautCharts from "components/charts/charts.component";
import ButtonPrimary from "components/button-primary/button-primary.component";
import clsx from "clsx";

import PlayIcon from "assets/icons/play_icon.png";
import PauseIcon from "assets/icons/pause_icon.png";
import { ReactComponent as ConfindenceIcon } from "assets/icons/confidence_icon.svg";
import { ReactComponent as PositiveIcon } from "assets/icons/positive_icon.svg";
import { ReactComponent as NegativeIcon } from "assets/icons/negative_icon.svg";

import { OsMediaFragment } from "graphql/open-search/types-and-hooks";
import { OsMediaProFragment } from "graphql/search/qanda-pro-types-and-hooks";

import { isMobileResolution } from "commons/utils/device-info.util";
import { MultimediaMetadataItemInterface } from "components/multimedia-metadata-item/multimedia-metadata-item.utils";

import SC from "./search-result-item.styles";

interface ItemSearchProps {
  item: OsMediaFragment | OsMediaProFragment;
  isPro: boolean;
  type?: "search" | "widget";
  onClick?: (param1: OsMediaFragment | OsMediaProFragment) => void;
  // selectMediaId: any;
  // addTargetDialog: any;
  // showDetail: any;
  // isQAndA: boolean;
  // isItemDetail: boolean;
}

const audioElements: any[] = [];

const GenerateItemSearch: React.FC<ItemSearchProps> = ({
  item,
  isPro,
  type = "search",
  onClick = null,
  // selectMediaId,
  // addTargetDialog,
  // showDetail,
  // isQAndA,
  // isItemDetail,
}) => {
  const classes = SC.useItemSearchStyles();
  const itemId = React.useMemo(() => uuidv4(), []);
  // const {
  //   mediaId,
  //   documentId,
  //   mediaName,
  //   entities,
  //   sentence,
  //   sentiments,
  //   answer,
  //   context,
  //   score,
  //   title,
  //   author,
  //   source,
  //   targetStatus,
  //   created,
  //   meta,
  // } = item;

  const {
    title,
    author,
    source,
    transcription,
    description,
    webUrl,
    audioUrl,
    startTime,
    endTime,
    thumbnail,
    views = 0,
    likes = 0,
    tags = [],
    negativePercentageScore,
    positivePercentageScore,
    joy,
    trust,
    fear,
    surprise,
    sadness,
    disgust,
    anger,
    created,
    modelAnswerText,
    modelAnswer,
    confidenceInPercentage,
    speechSegment,
    audioLength,
    authors,
    abstract,
    full_keywords: fullKeywords,
    year,
    pdf_url: pdfUrl,
    page,
  } = item as OsMediaFragment &
    OsMediaProFragment & {
      authors: string;
      abstract: string;
      full_keywords: string;
      year: string;
      pdf_url: string;
      page: string;
    };

  const multimediaMetadata: MultimediaMetadataItemInterface & {
    authors: string;
    abstract: string;
    fullKeywords: string[];
    year: string;
    pdfUrl: string;
    page: string;
  } = React.useMemo(
    () => ({
      author: author ?? "",
      created: created ?? "",
      description: isPro ? modelAnswer ?? "" : description ?? "",
      isVideo: true,
      showTitleCard: type === "search",
      source: source as string,
      title: title as string,
      type,
      endTime: endTime ?? 0,
      startTime: startTime ?? 0,
      thumbnail: thumbnail ?? null,
      webUrl: webUrl ?? null,
      authors: authors ?? "",
      abstract: abstract ?? "",
      fullKeywords: fullKeywords
        ? fullKeywords.split(",").filter((keyword) => !!keyword.trim())
        : [],
      year: year ?? "",
      pdfUrl: pdfUrl ?? "",
      page: page ?? "",
    }),
    [
      author,
      created,
      modelAnswer,
      description,
      source,
      title,
      endTime,
      startTime,
      thumbnail,
      webUrl,
      isPro,
      type,
      authors,
      abstract,
      fullKeywords,
      year,
      pdfUrl,
      page,
    ]
  );

  const [targetExists, setTargetExists] = React.useState(true);

  // const [audioOptions, setAudioOptions] = React.useState({
  //   id: 1,
  //   name: title,
  //   writer: author,
  //   src: "https://cdn.pixabay.com/audio/2022/08/23/audio_d16737dc28.mp3",
  // });

  // temp audio element implementation
  const provisionalAudioRef = React.useRef({} as HTMLAudioElement);
  const [provPlaying, setProvPlaying] = React.useState<boolean>(false);

  // wavesurfer test implementation

  const [transcripShort, setTranscripShort] = React.useState(() => {
    const currentDescription =
      abstract && abstract?.length > (isMobileResolution() ? 149 : 249);
    // if (type === "search" && isPro) {
    //   currentDescription = speechSegment && speechSegment?.length > (isMobileResolution() ? 149 : 249);
    // } else {
    //   currentDescription = description && description?.length > (isMobileResolution() ? 149 : 249);
    // }
    return currentDescription;
  });

  const answerPro = React.useMemo(() => {
    let newAnswerPro = "";
    if (modelAnswer && speechSegment) {
      const answerArray = speechSegment.split(modelAnswer);
      newAnswerPro = answerArray.join(`<b>${modelAnswer}</b>`);
    }
    return newAnswerPro;
  }, [modelAnswer, speechSegment]);

  const [histogramReady, setHistogramReady] = React.useState<boolean>(false);
  const [progressTime, setProgressTime] = React.useState<string>("00:00:00");
  const [totalDuration, setTotalDuration] = React.useState<string>("00:00:00");
  const [playing, setPlaying] = React.useState<boolean>(false);
  const [timeRange, setTimeRange] = React.useState<{
    start: number;
    stop: number;
  }>({
    start: startTime ?? 0,
    stop: endTime ?? 0,
  });

  const [anchorTagsEl, setAnchorTagsEl] =
    React.useState<HTMLButtonElement | null>(null);
  const openTags = Boolean(anchorTagsEl);

  const waveAudioRef = React.useRef({} as WaveSurfer);
  const audioContainerRef = React.useRef({} as HTMLElement);
  // const mediaEle = document.querySelector("video");

  const handleOpenTags = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorTagsEl(event.currentTarget);
  };
  const handleCloseTags = () => {
    setAnchorTagsEl(null);
  };

  const handleClick = (ev: any) => {
    // console.debug("=====> waveAudioRef: ", waveAudioRef.current.isPlaying());
    if (!waveAudioRef.current.isPlaying()) {
      // waveAudioRef.current.regions.list.regionMarked.play();
      // waveAudioRef.current.play(timeRange.start, timeRange.stop);
      waveAudioRef.current.play();
      // console.debug("=====> range time: ", timeRange);
      // waveAudioRef.current.play();
      setPlaying(waveAudioRef.current.isPlaying());
      return;
    }
    waveAudioRef.current.pause();
    // console.debug("=====> Paused!!!");
    setPlaying(waveAudioRef.current.isPlaying());
  };

  // React.useEffect(() => {
  //   const audioElement = document.querySelector(
  //     `#waveform_${itemId}`
  //   ) as HTMLElement;

  //   audioContainerRef.current = audioElement;

  //   const waveform = WaveSurfer.create({
  //     container: audioElement,
  //     height: 66,
  //     waveColor: "#BDBDBD",
  //     progressColor: "#D93851",
  //     barWidth: 1.2,
  //     barGap: 1.4,
  //     barRadius: 1.2,
  //     barHeight: 0.3,
  //     cursorColor: "#000",
  //     cursorWidth: 2,
  //     responsive: true,
  //     plugins: [
  //       Cursor.create({
  //         showTime: true,
  //         opacity: 1,
  //         customShowTimeStyle: {
  //           "background-color": "#000",
  //           color: "#fff",
  //           padding: "2px",
  //           "font-size": "10px",
  //         },
  //       }),
  //       // RegionsPlugin.create({
  //       //   regionsMinLength: 2,
  //       //   regions: [
  //       //     {
  //       //       id: "regionMarked",
  //       //       start: startTime ?? 0,
  //       //       end: endTime ?? totalDuration,
  //       //       loop: false,
  //       //       drag: false,
  //       //       resize: false,
  //       //       showTooltip: false,
  //       //     },
  //       //   ],
  //       //   dragSelection: {
  //       //     slop: 5,
  //       //   },
  //       // }),
  //     ],
  //   });

  //   const pathToFile = `https://cdn.pixabay.com/audio/2022/08/23/audio_d16737dc28.mp3`;
  //   if (audioUrl) {
  //     waveform.load(audioUrl);

  //     audioElements.push(waveform);

  //     waveAudioRef.current = waveform;
  //   }
  //   return () => {
  //     waveform.destroy();
  //     audioElements.splice(0, audioElements.length);
  //   };
  // }, [
  //   audioContainerRef,
  //   item,
  //   itemId,
  //   audioUrl,
  //   totalDuration,
  //   startTime,
  //   endTime,
  // ]);

  React.useEffect(() => {
    if (waveAudioRef) {
      // console.debug("WaveSurfer instance: ", waveAudioRef);

      const { current } = waveAudioRef;

      // console.debug("=====> Current wavesurfer instance: ", current);

      if (current && current.on) {
        current.on("ready", () => {
          const totalTime: number = current.getDuration();
          // console.debug(
          //   "=====> Histogram ready!!!",
          //   current.isReady,
          //   totalTime
          // );

          setHistogramReady(current.isReady);
          setTotalDuration(secToStrTime(totalTime));
          setTimeRange((oldTimeRange) => ({
            ...oldTimeRange,
            stop: Number(totalTime.toFixed(1)),
          }));
        });

        current.on("audioprocess", (process: number) => {
          // update the progress time state
          // console.debug("=====> Current process: ", process);
          setProgressTime(secToStrTime(process));
          setPlaying(current.isPlaying());
          if (secToStrTime(process) === totalDuration) {
            setTimeRange((oldTimeRange) => ({
              ...oldTimeRange,
              start: startTime ?? 0,
            }));
            setPlaying(false);
          }
          // setTimeRange((oldTimeRange) => ({
          //   ...oldTimeRange,
          //   start:
          //     Number(process.toFixed(1)) >= oldTimeRange.stop
          //       ? startTime ?? 0
          //       : process,
          // }));
        });

        current.on("pause", () => {
          // console.debug(
          //   "=====> on Pause: ",
          //   waveAudioRef.current.getCurrentTime()
          // );
          const currentSec = waveAudioRef.current.getCurrentTime();
          setTimeRange((oldTimeRange) => ({
            ...oldTimeRange,
            start:
              Number(currentSec.toFixed(1)) >= oldTimeRange.stop
                ? startTime ?? 0
                : currentSec,
          }));
          setPlaying(false);
        });
      }
    }
  }, [waveAudioRef, startTime, endTime, totalDuration, setTimeRange]);

  // End of wavesurfer js

  // const [mediaThumb, setMediaThumb] = React.useState("");

  // const mediaExtraMetadata: any = React.useMemo(async () => {
  //   let extraMetadata: any = null;
  //   try {
  //     extraMetadata = await getLinkPreview(url);
  //   } catch (error) {
  //     console.error("=====> Error to get medi info: ", error);
  //   }
  //   console.debug("=====> EXTRAMETADATA: ", extraMetadata);

  //   if (extraMetadata && extraMetadata.images) {
  //     setMediaThumb(extraMetadata.images[0]);
  //     setAudioOptions((oldAudioOptions) => ({
  //       img: extraMetadata.images[0],
  //       ...oldAudioOptions,
  //     }));
  //   }

  //   return extraMetadata ?? { images: [""] };
  // }, [url]);
  // getLinkPreview(url).then((data) => console.debug(data));

  // This code fagment is a temporal solution until we have able the mediaId property in the Q&A service response
  // let mediaIdSource = mediaId;
  // if (meta) {
  //   const { source: sourceTemp } = JSON.parse(meta);
  //   mediaIdSource = sourceTemp;
  // }
  // --------------------------------

  // const {
  //   data,
  //   loading: targetLoading,
  //   error,
  // } = useGetTargetBySourceIdQuery({
  //   variables: {
  //     sourceId: mediaId ?? mediaIdSource,
  //   },
  //   fetchPolicy: "no-cache",
  // });

  // React.useEffect(() => {
  //   if (targetStatus !== undefined) {
  //     setTargetExists(targetStatus);
  //   } else {
  //     setTargetExists(!!data);
  //   }
  // }, [data, targetStatus]);

  // Provisional management of audio player
  const provisionalAudioClick = (ev: any) => {
    // console.debug("=====> provisionalAudioRef: ", provisionalAudioRef.current);
    if (provisionalAudioRef.current && !provPlaying) {
      // console.debug("=====> range time: ", timeRange);
      provisionalAudioRef.current.play();
      // waveAudioRef.current.play();
      setProvPlaying(!provPlaying);
      return;
    }
    provisionalAudioRef.current.pause();
    // console.debug("=====> Paused!!!");
    setProvPlaying(!provPlaying);
  };

  React.useEffect(() => {
    if (!!provisionalAudioRef && !!provisionalAudioRef.current) {
      // console.debug(
      //   "=====> current time range: ",
      //   timeRange,
      //   provisionalAudioRef.current.duration
      // );
      provisionalAudioRef.current.currentTime = timeRange.start;

      // provisionalAudioRef.current.ontimeupdate = (event: any) => {
      // console.debug(
      //   "=====> current time: ",
      //   provisionalAudioRef.current.currentTime
      // );
      // if (provisionalAudioRef.current.currentTime >= timeRange.stop) {
      //   provisionalAudioRef.current.pause();
      //   provisionalAudioRef.current.currentTime = timeRange.start;
      // }
      // };
    }
  }, [provisionalAudioRef, timeRange]);

  return (
    <SC.GSResultItem
      className={type === "widget" ? "widget-item" : "itemSerch"}
      onClick={() => {
        if (onClick) {
          onClick(item);
        } else {
          console.debug("=====> Callback undefined.");
        }
      }}
    >
      <Box className="content">
        <MultimediaMetadataItem metadata={multimediaMetadata} />
        {/* <SC.AudioContent hidden>
          <IconButton onClick={handleClick}>
            <img
              src={playing ? PauseIcon : PlayIcon}
              alt="playing-icon"
              style={{ width: "auto", height: "26px" }}
            />
          </IconButton>
          <Typography>{progressTime}</Typography>
          <Box flex={1}>
            <Box id={`waveform_${itemId}`} />
          </Box>
          <Typography>{totalDuration}</Typography>
        </SC.AudioContent> */}
        <SC.AudioContent hidden>
          {/* hidden={type !== "search"}> */}
          {/* <IconButton onClick={provisionalAudioClick}>
            <img
              src={playing ? PauseIcon : PlayIcon}
              alt="playing-icon"
              style={{ width: "auto", height: "26px" }}
            />
          </IconButton> */}
          <audio controls ref={provisionalAudioRef} style={{ width: "100%" }}>
            <source src={audioUrl ?? ""} type="audio/flac" />
            <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            />
            <track
              src="captions_es.vtt"
              kind="captions"
              srcLang="es"
              label="spanish_captions"
            />
            Your browser does not support the audio element.
          </audio>
        </SC.AudioContent>
        <SC.AnswerContainer
          onClick={() => {
            if (type === "search") {
              if (
                !isPro &&
                abstract &&
                abstract?.length > (isMobileResolution() ? 149 : 249)
              ) {
                setTranscripShort(!transcripShort);
              }
              if (
                isPro &&
                answerPro &&
                answerPro?.length > (isMobileResolution() ? 149 : 249)
              ) {
                setTranscripShort(!transcripShort);
              }
            }
          }}
        >
          <SC.AnswerEl className={type}>
            <Typography className="abstract-title bold">Abstract</Typography>
            <Box hidden={type !== "search"}>
              {isPro ? (
                <Typography>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: (transcripShort
                        ? `${answerPro?.substr(
                            0,
                            isMobileResolution() ? 150 : 250
                          )} ...`
                        : answerPro) as string,
                    }}
                  />
                </Typography>
              ) : (
                <Box
                  component="p"
                  className="abstract-content"
                  dangerouslySetInnerHTML={{
                    __html: transcripShort
                      ? `${abstract?.substr(
                          0,
                          isMobileResolution() ? 150 : 250
                        )} ... <b>See More<b/>`
                      : abstract,
                  }}
                />
              )}
            </Box>
            <Box hidden={type !== "widget"}>
              <Typography>{description}</Typography>
            </Box>
          </SC.AnswerEl>
        </SC.AnswerContainer>
        {/* <p>
          {isQAndA && !isItemDetail ? (
            <Typography style={{ height: "fit-content" }}>
              <b>{answer}</b>
            </Typography>
          ) : null}
          {!isQAndA
            ? sentence
            : `${
                context && context.length > 299 && !isItemDetail
                  ? context.substr(0, 300)
                  : context
              } ...`} */}
        {/* Consectetur&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Pronoun" placement="top" arrow>
              <Chip className={`color-1 ${classes.chipRoot}`} label="I" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Verb" placement="top" arrow>
              <Chip className={`color-2 ${classes.chipRoot}`} label="like" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;adipiscing&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Determinant" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="the" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;duis tristique sollicitudin&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Product" placement="top" arrow>
              <Chip
                className={`color-5 ${classes.chipRoot}`}
                label="McLaren 720s"
              />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;nibh sit amet commodo nulla facilisi nullam&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Pronoun" placement="top" arrow>
              <Chip className={`color-1 ${classes.chipRoot}`} label="I" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;vehicula ipsum a arcu cursus&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Color" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="for" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;vitae congue&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Verb" placement="top" arrow>
              <Chip className={`color-2 ${classes.chipRoot}`} label="like" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;adipiscing&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Determinant" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="the" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;elit duis tristique&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Verb" placement="top" arrow>
              <Chip className={`color-7 ${classes.chipRoot}`} label="color" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Color" placement="top" arrow>
              <Chip className={`color-6 ${classes.chipRoot}`} label="green" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;nibh sit&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Pronoun" placement="top" arrow>
              <Chip className={`color-1 ${classes.chipRoot}`} label="I" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;amet&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Determinant" placement="top" arrow>
              <Chip className={`color-3 ${classes.chipRoot}`} label="the" />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;commodo nulla facilisi nullam for&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Product" placement="top" arrow>
              <Chip
                className={`color-5 ${classes.chipRoot}`}
                label="McLaren 720s"
              />
            </Tooltip>
          </MuiThemeProvider>
          &nbsp;ipsum a arcu cursus vitae congue like adipiscing elit duis
          tristique sollicitud&nbsp;
          <MuiThemeProvider theme={tooltipStyles}>
            <Tooltip title="Color" placement="top" arrow>
              <Chip className={`color-6 ${classes.chipRoot}`} label="green" />
            </Tooltip>
          </MuiThemeProvider> */}
        {/* </p> */}
        {/* <div
          className={`play-image ${
            isQAndA && !isItemDetail ? "chart-wrapper" : classes.hide
          }`}
        >
          {!isQAndA ? (
            <>
              <CardMedia component="img" image={playImage} />
              <span>3:05.02</span>
            </>
          ) : (
            <AudionautCharts
              type={5}
              customStyle={{
                height: "100%",
                width: "auto",
                minWidth: "50px",
                minHeight: "50px",
              }}
              data={{
                score: Number(Math.floor(score * 100)),
                isPositive: true,
              }}
            />
          )}
        </div> */}
      </Box>
      {/* <Divider /> */}
      <SC.ItemFooter className="footer">
        <Box className={classes.extractedContent}>
          {multimediaMetadata.fullKeywords.map((keyword) => {
            const randomNum = Math.floor(Math.random() * 4);
            const currentColor = SC.ColorsChips[randomNum];
            return keyword ? (
              <SC.FooterChip className={`${currentColor}`} label={keyword} />
            ) : null;
          })}
          {/* {isPro ? (
            <SC.FooterChip
              className="confindence big"
              label={`${confidenceInPercentage?.toFixed(1) ?? 0}% ${
                isMobileResolution() ? "" : "Confidence"
              }`}
              icon={<SvgIcon component={ConfindenceIcon} />}
            />
          ) : null}
          <SC.FooterChip
            className="positive big"
            label={`${positivePercentageScore?.toFixed(1) ?? 0}% ${
              isMobileResolution() ? "" : "Positive"
            }`}
            icon={<SvgIcon component={PositiveIcon} />}
          />
          <SC.FooterChip
            className="negative big"
            label={`${negativePercentageScore?.toFixed(1) ?? 0}% ${
              isMobileResolution() ? "" : "Negative"
            }`}
            icon={<SvgIcon component={NegativeIcon} />}
          />
          <SC.FooterChip
            className="views small"
            label={`${averageNumber(Number(views))}`}
            icon={<VisibilityIcon />}
          />
          <SC.FooterChip
            className="likes small"
            label={`${averageNumber(Number(likes))}`}
            icon={<ThumbUpIcon />}
          />
          <SC.FooterChip
            className="tags small"
            label={`${tags ? tags.length : 0} ${
              isMobileResolution() ? "" : "Tags"
            }`}
            icon={<SC.TagIcon />}
            onClick={handleOpenTags as any}
          />
          <SC.TagsPopover
            id={`tags-${itemId}`}
            open={openTags}
            anchorEl={anchorTagsEl}
            onClose={handleCloseTags}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography>{tags?.join(", ")}</Typography>
          </SC.TagsPopover> */}
          {/* {isQAndA ? (
            <>
              <Chip className="sentiment-color" label={`Author: ${author}`} />
              <Chip className="entity-color" label={source} />{" "}
            </>
          ) : (
            <>
              <Chip
                className={clsx("sentiment-color", {
                  [classes.hide]: !sentiments,
                })}
                label={`Sentiment Score: ${
                  sentiments ? sentiments.split(",").length : 0
                }`}
              />
              <Chip
                className={clsx("entity-color", {
                  [classes.hide]: !entities,
                })}
                label={`${entities ? entities.split(",").length : 0} Entities`}
              />
              <Chip
                className={clsx("word-color", {
                  [classes.hide]: !sentence,
                })}
                label={`${
                  sentence
                    ? sentence.trimStart().trimEnd().split(" ").length
                    : 0
                } Words`}
              />
            </>
          )} */}
        </Box>
        {/* <ButtonPrimary
          disabled={targetExists}
          onClick={(event: any) => {
            selectMediaId(mediaId ?? mediaIdSource);
            addTargetDialog(null);
          }}
        >
          {targetExists ? "Targeted" : "Target"}
        </ButtonPrimary> */}
      </SC.ItemFooter>
    </SC.GSResultItem>
  );
};

export default GenerateItemSearch;
