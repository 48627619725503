/* eslint-disable no-param-reassign */
import React from "react";
import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Slide,
  Snackbar,
  Typography,
} from "@material-ui/core";

import { WatchQueryFetchPolicy } from "@apollo/client";

import {
  useCreateTargetMutation,
  useGetAllTargetsGroupsQuery,
} from "graphql/types-and-hooks";

import {
  useGetBehaviorResultsQuery,
  useGetBehaviorsCountQuery,
  useDeleteBehaviorQueryMutation,
  BehaviorResultsFragment,
  BehaviorMediaResultsFragment,
  GetBehaviorResultsQueryVariables,
} from "graphql/behaviors/types-and-hooks";
import { BEHAVIOR_QUERY_DELETED_FRAGMENT_GQL } from "graphql/behaviors/behaviors.utils";
import {
  BehaviorDataInterface,
  BehaviorDataParsedInterface,
  ResultsDataModifiedInterface,
  TableHeadersInterface,
  BehaviorPaginationVars,
} from "graphql/behaviors/behaviors.types";

import { CLIENT_NAME } from "graphql/client";
import {
  BehaviorToAddTGInterface,
  NEW_TARGET_FRAGMENT_GQL,
} from "graphql/audionaut.utils";
import { isMobileResolution } from "commons/utils/device-info.util";

import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";
import Alert, { AlertProps } from "components/alert/alert.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import Paginator from "components/paginator/paginator.component";

import CachedIcon from "@material-ui/icons/Cached";
import PuzzleIcon from "assets/icons/puzzle_icon.png";

import SC from "./behaviors-results.styles";

const tableHeaders: TableHeadersInterface[] = [
  {
    text: isMobileResolution() ? "Name" : "Behavior Name",
    key: "behaviorName",
  },
  {
    text: isMobileResolution() ? "Medias" : "Media Results",
    key: "mediaResults",
    class: "headCentered",
  },
  {
    text: isMobileResolution() ? "New Medias" : "New Media results",
    key: "newMediaResults",
    class: "headCentered",
  },
  {
    text: isMobileResolution() ? "Sentiment" : "Sentiment Score",
    key: "scoreSentiment",
    class: "headCentered",
  },
  {
    text: isMobileResolution() ? "Creation" : "Creation Date",
    key: "created",
    class: "headCentered",
  },
  {
    text: "Duration",
    key: "duration",
    class: "headCentered",
  },
  {
    text: "Actions",
    key: "actions",
    class: "headCentered",
  },
];

const BehaviorsResultsPage: React.FC = () => {
  const classes = SC.behaviorsResultsStyles();

  const [refreshBehaviors, setRefreshBehaviors] = React.useState(false);
  const [cacheOpts, setCacheOpts] =
    React.useState<WatchQueryFetchPolicy | undefined>("cache-first");

  const [behaviorsList, setBehaviorsList] = React.useState<
    BehaviorDataParsedInterface[]
  >([]);

  const [behaviorTarget, setBehaviorTarget] =
    React.useState<BehaviorToAddTGInterface>({
      sourceId: "",
      mediasIds: [],
    });
  const [openAddTarget, setOpenAddTarget] = React.useState(false);
  const [deletedItem, setDeletedItem] = React.useState(false);

  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();

  const [behaviorPaginationOpts, setBehaviorPaginationOpts] =
    React.useState<GetBehaviorResultsQueryVariables>({
      index: 0,
      total: 5,
    });

  const {
    data: behaviorCountData,
    loading: behaviorCountLoading,
    error: behaviorCountError,
    refetch: behaviorCountRefetch,
  } = useGetBehaviorsCountQuery({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
    },
  });

  const {
    data: allBehaviorsResults,
    loading: behaviorsResultsLoading,
    error: errorBehaviorsResultsError,
    refetch: refetchBrahviorResults,
  } = useGetBehaviorResultsQuery({
    variables: behaviorPaginationOpts,
    context: {
      clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
    },
    fetchPolicy: cacheOpts,
    notifyOnNetworkStatusChange: true,
  });

  const totalBehaviors = React.useMemo(() => {
    let total = 0;
    if (behaviorCountData && behaviorCountData.getBehaviors) {
      const { behaviorsCount } = behaviorCountData.getBehaviors;
      total = behaviorsCount ?? 0;
    }
    return total;
  }, [behaviorCountData]);

  const handleUpdateBehaviorResults = React.useCallback(async () => {
    refetchBrahviorResults();
  }, [refetchBrahviorResults]);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handleClickToggleAddTarget: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenAddTarget(!openAddTarget);
    }, [openAddTarget, setOpenAddTarget]);

  const [createTarget, { loading: createTargetLoading }] =
    useCreateTargetMutation({
      update(cache, { data }) {
        const newTarget = data?.CreateTarget;
        if (newTarget) {
          cache.modify({
            fields: {
              GetTarget(existingTarget = []) {
                const newTargetRef = cache.writeFragment({
                  id: cache.identify(newTarget),
                  data: newTarget,
                  fragment: NEW_TARGET_FRAGMENT_GQL,
                });
                return [...existingTarget, newTargetRef];
              },
            },
          });
        }
      },
    });

  const {
    data: targetsGroupData,
    loading: targetsGroupDataLoading,
    error: errorTargetsGroupDataLogin,
  } = useGetAllTargetsGroupsQuery({});
  const allTargetsGroup = React.useMemo(
    () => targetsGroupData?.GetTargetsGroups ?? [],
    [targetsGroupData]
  );

  const [deleteBehaviorQuery, { loading: deleteBehaviorQueryLoading }] =
    useDeleteBehaviorQueryMutation({
      context: {
        clientName: CLIENT_NAME.AUDIONAUTBEHAVIORSAPI,
      },
      update(cache, { data }) {
        const behaviorQuery = data?.deleteBehavior;
        if (behaviorQuery) {
          cache.modify({
            fields: {
              GetBehaviorQuery(behaviorDeleted = []) {
                const deleteBehaviorRef = cache.writeFragment({
                  id: cache.identify(behaviorQuery),
                  data: behaviorQuery,
                  fragment: BEHAVIOR_QUERY_DELETED_FRAGMENT_GQL,
                });
                return [...behaviorDeleted, deleteBehaviorRef];
              },
            },
          });
        }
      },
    });

  const loading =
    behaviorsResultsLoading ||
    targetsGroupDataLoading ||
    deleteBehaviorQueryLoading;
  const errorHandler = useAppErrorHandler(errorTargetsGroupDataLogin);

  const handlerDeleteItem: React.FormEventHandler<any> = React.useCallback(
    async (behaviorData: any) => {
      try {
        await deleteBehaviorQuery({
          variables: {
            id: behaviorData?.behaviorId as unknown as string,
          },
        });

        setSnackBarMessage({
          message: `The behavior query ${behaviorData.behaviorName} has been deleted successfully.`,
          severity: "success",
        });
        setDeletedItem(true);
        handleUpdateBehaviorResults();
        behaviorCountRefetch();
      } catch (error) {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while deleting behavior"
          )
        );
      }
    },
    [
      deleteBehaviorQuery,
      handleUpdateBehaviorResults,
      behaviorCountRefetch,
      errorHandler,
    ]
  );

  const handlerAddTarget: React.FormEventHandler<any> = React.useCallback(
    async (newTarget: any) => {
      try {
        await createTarget({
          variables: newTarget,
        });

        handleUpdateBehaviorResults();
        setSnackBarMessage({
          message: `The behavior query ${newTarget.targetName} has been deleted successfully.`,
          severity: "success",
        });
      } catch (error) {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while adding new target"
          )
        );
      }
    },
    [createTarget, handleUpdateBehaviorResults, errorHandler]
  );

  React.useEffect(() => {
    if (
      (allBehaviorsResults && allBehaviorsResults.getBehaviors) ||
      refreshBehaviors
    ) {
      setCacheOpts("cache-first");
      const {
        getBehaviors: { behaviors },
      } = allBehaviorsResults as {
        getBehaviors: { behaviors: BehaviorResultsFragment[] };
      };
      setBehaviorsList(
        behaviors.map((behaviorData: BehaviorResultsFragment) => {
          const { negativeSentimentRate, positiveSentimentRate } = behaviorData;

          let scoreSentiment = "0";

          if (!!negativeSentimentRate && !!positiveSentimentRate) {
            scoreSentiment =
              negativeSentimentRate > positiveSentimentRate
                ? (negativeSentimentRate * 100).toFixed(1)
                : (positiveSentimentRate * 100).toFixed(1);
          }
          return { ...behaviorData, scoreSentiment };
        }) as BehaviorDataParsedInterface[]
      );
    } else {
      setBehaviorsList([]);
    }
  }, [allBehaviorsResults, setBehaviorsList, refreshBehaviors]);

  const handleUpdateItems = (newPage: number, newRowsPerPage: number) => {
    setBehaviorPaginationOpts({
      total: newRowsPerPage,
      index: newRowsPerPage * newPage,
    });
  };

  React.useEffect(() => {
    try {
      if (refreshBehaviors) {
        // setBehaviorsList([]);
        setCacheOpts("no-cache");
        setBehaviorPaginationOpts((oldVars) => {
          return {
            ...oldVars,
            index: 0,
          };
        });

        handleUpdateBehaviorResults();
      }
    } catch (error) {
      errorHandler(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          "An error has ocurred while getting media info"
        )
      );
    }
  }, [refreshBehaviors, handleUpdateBehaviorResults, errorHandler]);

  return (
    <SC.BRContainer>
      <ProgressIndicator open={createTargetLoading} />
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
      <SC.BRContent>
        <SC.BRHeader component="div">
          <SC.TitleContainer component="div" display="flex" flex="1">
            <h1>Results</h1>
            <SC.ButtonPrimary
              disabled={behaviorsResultsLoading}
              startIcon={<CachedIcon />}
              className="refresh-btn"
              onClick={() => {
                setRefreshBehaviors(true);
                setTimeout(() => {
                  setRefreshBehaviors(false);
                }, 1000);
              }}
            >
              {!isMobileResolution() ? "Refresh" : ""}
            </SC.ButtonPrimary>
          </SC.TitleContainer>
          <Paginator
            totalItems={totalBehaviors}
            getNewItems={handleUpdateItems}
            reset={refreshBehaviors}
          />
        </SC.BRHeader>
        <SC.BRBody component="div" className={classes.brBodyContainer}>
          <Box component="div" className={classes.boxBody}>
            <Box className={classes.listHeader}>
              <img
                src={PuzzleIcon}
                alt="puzzle-icon"
                style={{
                  width: isMobileResolution() ? "25px" : "39px",
                  height: isMobileResolution() ? "25px" : "39px",
                }}
              />
              <Typography className="title-list">Behavior</Typography>
            </Box>
            {loading ? (
              <CircularProgress className={classes.loader} />
            ) : (
              <SC.BRTable
                title=""
                headers={tableHeaders}
                items={behaviorsList}
                ItemComponent={SC.BehaviorItemSubTable}
                thClass={classes.tableHeader}
                tbClass={classes.tableBody}
                tableClass={classes.tableContainer}
                toggleModalState={handleClickToggleAddTarget}
                setTargetData={setBehaviorTarget}
                deleteItemHandler={handlerDeleteItem}
                extraSpaces={1}
                lastExtraSpaces
                sortEnable
                deletedItem={deletedItem}
              />
            )}
          </Box>
        </SC.BRBody>
        <Box
          hidden={behaviorsList.length > 0}
          className={classes.adviseMessage}
        >
          <Slide
            direction="up"
            in={!behaviorsResultsLoading && behaviorsList.length === 0}
          >
            <Paper elevation={4}>
              <GenericEmptyMessages
                show
                title="Find each of your saved behaviors"
                description="Build some behaviors on Analyze >  "
                description2=" to see Behaviors Results."
                component={
                  <Link key="audio-source-link" href="/analyze/behaviors">
                    Behaviors
                  </Link>
                }
              />
            </Paper>
          </Slide>
        </Box>
      </SC.BRContent>
      <SC.BRAddNewTarget
        targetData={behaviorTarget}
        openAddTarget={openAddTarget}
        setOpenAddTarget={handleClickToggleAddTarget}
        handleSubmit={handlerAddTarget}
        groups={allTargetsGroup}
      />
    </SC.BRContainer>
  );
};

export default BehaviorsResultsPage;
