/* eslint-disable prettier/prettier */
import React from "react";
import { useHistory } from "react-router-dom";

import SC from "./reset-password-result.styles";

const ResultPasswordResult: React.FC = () => {
  const history = useHistory();

  const buttonHandler = () => {
    history.push("/login");
  };

  return (
    <SC.Form>
      <SC.Text>Your password has been reset successfully</SC.Text>
      <SC.Button type="button" color="primary" onClick={buttonHandler}>
        Log in
      </SC.Button>
    </SC.Form>
  );
};

export default ResultPasswordResult;
