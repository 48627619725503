/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { USER } = ActionTypes;

const INITIAL_STATE = {
  users: [],
  isReceivingData: false,
  error: null
};

const getUsersReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER.UPDATE.STATUS: {
      const { userID, newStatus } = action.payload;
      return {
        ...state,
        users: state.users.map(user => (user.ID === userID ? { ...user, status: newStatus } : user))
      };
    }
    case USER.READ.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case USER.READ.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        users: data && data.GetUser,
        isReceivingData: false
      };
    }
    case USER.READ.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    case USER.SET.DATA:
      return {
        ...state,
        users: action.payload
      };
    default: return state;
  }
};

export default getUsersReducerFn;
