import React, { ChangeEvent } from "react";
import { Typography, Box, Snackbar, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, AlertProps } from "components/alert/alert.component";
import { TableColumn } from "components/table/table.component";
import { HistoryItemModel } from "graphql/corporatus/corporatus_rest_graphql.types";
import { createEmptyHistoryItem } from "graphql/audionaut.utils";
import SCGENERALSEARCH from "components/general-search/general-search.styles";
import SC from "./chat-history.styles";

const { REACT_APP_API_REST_HISTORY_SERVER_URL, HISTORY_TOKEN } = process.env;

interface TableData {
  email: string;
  session_id: string;
  query: string;
  response: string;
}

const ChatHistoryPage: React.FC = () => {
  const classes = SC.useStyles();
  const [historyList, setHistoryList] = React.useState<HistoryItemModel[]>([]);
  const [filteredData, setFilteredList] = React.useState<HistoryItemModel[]>(
    []
  );
  const [historyLoading, setHistoryLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [openHistoryModal, setHistoryModal] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [historyInfo, setHistoryInfo] = React.useState<HistoryItemModel>(() =>
    createEmptyHistoryItem()
  );
  const [shortValue, setShortValue] = React.useState(true);
  const [shortValueResource, setShortValueResource] = React.useState(true);
  const getHistory = React.useCallback(async () => {
    try {
      const topicsTable = await fetch(
        `${REACT_APP_API_REST_HISTORY_SERVER_URL}chat_history`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer O3IGNECRD5KNJCN38L9JGIVBVU8G6W0L1Y0BOCTS`,
          },
        }
      ).then((response) => response.json());
      setHistoryList(topicsTable);
      // setFilteredList(topicsTable);
    } catch (error) {
      console.error("=====> Error Obtaining Topics: ", error);
    }
  }, []);

  // State variables for filters
  const [filters, setFilters] = React.useState<TableData>({
    email: "",
    session_id: "",
    query: "",
    response: "",
  });

  const handleFilterChange = React.useCallback(
    (arg0: any) => {
      const { name, value } = (arg0 as ChangeEvent<HTMLInputElement>).target;
      if (name === "email" && value !== "") {
        setFilters({ email: value, session_id: " ", query: "", response: "" });
      } else {
        setFilters({ ...filters, [name]: value });
      }
    },
    [filters]
  );

  const filterValues = React.useCallback(async () => {
    try {
      const filterHistoryList = await fetch(
        `${REACT_APP_API_REST_HISTORY_SERVER_URL}chat_history?email=${encodeURIComponent(
          filters.email
        )}&query=${encodeURIComponent(filters.query)}&session_id=${
          filters.session_id
        }&response=${filters.response}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer O3IGNECRD5KNJCN38L9JGIVBVU8G6W0L1Y0BOCTS`,
          },
        }
      ).then((response) => response.json());
      setHistoryList(filterHistoryList);
    } catch (error) {
      console.error("=====> Error Obtaining Topics: ", error);
    }
  }, [filters]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleFilterChange(e as any); // Call handleFilterChange when Enter key is pressed
        filterValues();
      }
    },
    [filterValues, handleFilterChange]
  );

  const doSearchHandler: React.FormEventHandler = React.useCallback(
    (event: any) => {
      event.preventDefault();
      // doSearch(valueSearch);
      console.log("Form submitted without redirection!");
    },
    []
  );

  const columns: TableColumn<HistoryItemModel>[] = React.useMemo(
    () => [
      {
        Header: "Rid",
        accessor: "rid",
        align: "left",
        width: 50,
        Cell: ({ row, value }) => (
          <Box height="50px">
            <Box my="auto">{value}</Box>
          </Box>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        width: 80,
        Cell: ({ row, value }) => (
          <Box height="50px" minWidth="60px">
            <Box my="auto">{value}</Box>
          </Box>
        ),
      },
      {
        Header: "Session Id",
        accessor: "session_id",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box display="flex" whiteSpace="wrap">
            <Box my="auto">{value}</Box>
          </Box>
        ),
      },
      {
        Header: "Query",
        accessor: "query",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box display="flex" whiteSpace="wrap">
            <Box my="auto">{value}</Box>
          </Box>
        ),
      },
      {
        Header: "Response",
        accessor: "response",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => {
          return (
            <Box
              display="flex"
              whiteSpace="wrap"
              onClick={() => {
                // console.debug(shortValue);
                setShortValue(!shortValue);
              }}
            >
              <Box my="auto">
                {shortValue ? `${value.substring(0, 100)} ... ` : value}
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Resource",
        accessor: "resource",
        align: "left",
        width: 130,
        Cell: ({ row, value }) => {
          let currentCell: any = "";
          if (value.length) {
            currentCell = shortValueResource ? (
              <Box
                display="flex"
                whiteSpace="wrap"
                onClick={() => {
                  setShortValueResource(!shortValueResource);
                }}
              >
                <Box my="auto" sx={{ display: "block" }}>
                  {`${value[0].full_citation.substring(0, 100)} ...`}
                </Box>
              </Box>
            ) : (
              value.map((item) => (
                <Box
                  display="flex"
                  whiteSpace="wrap"
                  onClick={() => {
                    setShortValueResource(!shortValueResource);
                  }}
                >
                  <Box my="auto" sx={{ display: "block" }}>
                    {item.full_citation}
                  </Box>
                </Box>
              ))
            );
          }
          return currentCell;
        },
      },
    ],
    [shortValue, shortValueResource]
  );

  /*
  React.useEffect(() => {
    if (filters.email.length) {
      filterValues();
    }
  }, [filterValues, filters]);
  */

  React.useEffect(() => {
    getHistory();
  }, [getHistory]);

  React.useEffect(() => {
    if (historyList && count < 1) {
      setHistoryLoading(true);
      setCount(1);
    } else {
      setHistoryLoading(false);
    }
  }, [count, historyList]);

  return (
    <SC.TopicContainer>
      <Box width="100%">
        <SC.HeaderPage>
          <Typography variant="h1" color="primary">
            Chat History
          </Typography>
        </SC.HeaderPage>
        <SC.PapersListConatiner>
          <SC.PapersTable
            hiddenTitle
            hiddenFooter
            loading={historyLoading}
            title="history"
            data={historyList}
            columns={
              columns as unknown as TableColumn<Record<string, unknown>>[]
            }
            onAction={(action) => {
              console.debug("=====> on action: ", action);
            }}
            topPanel={
              <SC.SearchInputContainer>
                {Object.keys(filters).map((key) => (
                  <SC.GeneralSearch component="form" onSubmit={doSearchHandler}>
                    <SC.Input
                      name={key}
                      key={key}
                      value={filters[key as keyof TableData] || ""}
                      placeholder={`Filter ${key}`}
                      isActive={isVisible}
                      disabled={false}
                      className="active"
                      multiline={false}
                      // onChange={handleFilterChange}
                      // onChange={(e) =>
                      //  handleInputChange(
                      //    key as keyof TableData,
                      //    e.target.value
                      //  )
                      // }
                      onChange={handleFilterChange}
                      onKeyDown={handleKeyDown}
                      startAdornment={<SearchIcon color="primary" />}
                    />
                  </SC.GeneralSearch>
                ))}
              </SC.SearchInputContainer>
            }
          />
        </SC.PapersListConatiner>
      </Box>
    </SC.TopicContainer>
  );
};

export default ChatHistoryPage;
