/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Theme,
  Card,
  CardContent,
  Typography,
  TableContainer,
  IconButton,
  Collapse,
  TableSortLabel,
  Divider,
  CardActions,
  AppBar,
  TableContainerProps,
  Grid,
} from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import NumberFormat from "react-number-format";

import StorefrontIcon from "@material-ui/icons/Storefront";
import AddIcon from "@material-ui/icons/Add";
import MembershipCard from "assets/icons/membership_card.png";
import MembershipCalendar from "assets/icons/membership_calendar.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { withStyles, createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import _ from "lodash";
import { Redirect } from "react-router-dom";

import {
  useChangeAutoPayStatusMutation,
  useGetAccountUsageReportQuery,
} from "graphql/types-and-hooks";
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";

import Paginator from "components/paginator/paginator.component";
import TableCellGenericItem from "components/tableCellGenericItem/tableCellGenericItem.component";
import { getFormattedDate } from "commons/utils/date-format";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import { Primary } from "stories/Button.stories";
import { isMobileResolution } from "commons/utils/device-info.util";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      title: {
        "& h1": {
          textAlign: "left",
        },
        pagination: {
          flex: "1",
        },
      },
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    sectionContainer: {
      marginBottom: "20px",
    },
    sectionTitle: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "bold",
      textAlign: "left",
    },
    headerImage: {
      display: "flex",
      height: "36px",
      width: "36px",
      background: "#FF99994D",
      borderRadius: "18px",
      color: "#973B7B",
      marginRight: "8px",
      "& img": {
        height: "22px",
        width: "22px",
        margin: "auto",
      },
    },
    headerPlan: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "bold",
      },
    },
    manageBtn: {
      display: "flex",
      justifyContent: "end",
      "& button": {
        border: "2px solid #929292",
        boxSizing: "border-box",
        borderRadius: "116px",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#767676",
      },
    },
    tableContainer: {
      maxHeight: "330px",
      "& .tableTitle": {
        textAlign: "left",
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#000000",
        paddingLeft: "80px",
      },
      [theme.breakpoints.down(540)]: {
        maxHeight: "unset !important",
        width: "calc(100vw - 72px)",
        overflowY: "hidden",
      },
    },
    tableHeader: {
      "& tr": {
        "& th": {
          backgroundColor:
            theme.generalStylesContainer.layers.content.backgroundColor,
          "& p, span, svg": {
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "16px !important",
            lineHeight: "20px !important",
            color: `${theme.palette.primary.main} !important`,
          },
          "&.extraSpace": {
            borderBottom: "none",
          },
        },
        "& th:first-child": {
          paddingLeft: "0px",
        },
      },
    },
    tableBody: {
      "& tr": {
        "& td": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "18px",
          border: "none",
          height: "55px",
          "& p": {
            color: theme.palette.primary.main,
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
          },
          "& .collapseIcon": {
            color: theme.palette.secondary.main,
            padding: "0px",
            [theme.breakpoints.down(540)]: {
              width: "20px",
              height: "20px",
            },
          },
        },
        "& td:first-child": {
          paddingLeft: "0px",
        },
      },
    },
  })
);

const SBContainer = withStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    // display: "flex",
    overflow: "hidden",
  },
}))(Box);

const SBContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "30px 32px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px",
    },
  },
}))(Box);

const SBHeader = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    minHeight: "130px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "5px",
    "& h1": {
      fontSize: "26px",
      lineHeight: "32px",
      fontWeight: "bold",
    },
    [theme.breakpoints.down(540)]: {
      minHeight: "60px",
    },
  },
}))(Box);

const SBBody = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    background: theme.generalStylesContainer.layers.content.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
    padding: "16px",
  },
}))(Box);

const SBTableHead = withStyles(() => ({
  root: {
    height: "46px",
    paddingTop: "16px",
    paddingLeft: "14px",
    paddingRight: "14px",
    "& th": {
      borderBottom: "1px solid #EAEAEA",
      "& p": {
        fontFamily: "Gothic A1 !important",
        fontStyle: "normal !important",
        fontWeight: "800 !important",
        fontSize: "18px !important",
        lineHeight: "22px !important",
      },
    },
  },
}))(TableHead);

const SBTableBody = withStyles(() => ({
  root: {
    "& td": {
      border: "none",
      fontFamily: "Gothic A1 !important",
      fontStyle: "normal !important",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      lineHeight: "15px !important",
    },
    "& tr:nth-child(even)": {
      background: "#9280BB0A",
    },
  },
}))(TableBody);

const TableBase = withStyles((theme: Theme) => ({
  root: {
    minWidth: "700px",
    overflowX: "auto",
  },
}))(Table);

const TableCellSticky = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      position: "sticky",
      left: "0",
      zIndex: "10",
      "&.stickyCell": {
        maxWidth: "120px",
        borderRight: "1px solid #E1E1E1",
        boxShadow: "7px 0 5px -4px rgba(0,0,0,0.1)",
      },
    },
  },
}))(TableCell);

const TableCellCollapsable = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      padding: "0px !important",
    },
  },
}))(TableCell);

const GridContainer = withStyles((theme: Theme) => ({
  root: {
    position: "sticky",
    width: "calc(100vw - 72px)",
    left: "0px",
    padding: "10px 0px",
    borderBottom: "2px solid rgba(224, 224, 224, 1)",
    "& .item": {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      margin: "unset",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      "&:nth-last-child(-n + 2) ": {
        borderBottom: "unset !important",
      },
    },
    "&:last-child": {
      borderBottom: "unset",
    },
  },
}))(Grid);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface BRTableProps {
  title: string;
  ItemComponent: any;
  headers: any[];
  items: any[];
  thClass: string;
  tbClass: string;
  tableClass: string;
  toggleModalState?: any;
  setTargetData?: any;
  extracell?: boolean;
  extraSpaces: number;
  lastExtraSpaces?: boolean;
  sortEnable: boolean;
}
const BRTable: React.FC<BRTableProps> = ({
  title,
  ItemComponent,
  headers,
  items,
  thClass,
  tbClass,
  tableClass,
  toggleModalState,
  setTargetData,
  extracell,
  extraSpaces,
  lastExtraSpaces,
  sortEnable,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("name");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer className={tableClass}>
      {title ? (
        <Typography className="tableTitle" color="primary">
          {title}
        </Typography>
      ) : null}
      <TableBase
        stickyHeader
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={thClass}>
          <TableRow style={{ height: 55 }}>
            {_.times(extraSpaces, (i) => (
              <TableCellSticky
                width="20px"
                padding="none"
                className="extraSpace"
              />
            ))}
            {/* <TableCell width="20px" /> */}
            {extracell === undefined || extracell ? (
              <TableCellSticky width="20px" />
            ) : null}
            {headers.map((headItem: any, index: number) =>
              index === 0 ? (
                <TableCellSticky
                  colSpan={1}
                  className={`${headItem.class} stickyCell`}
                  align={headItem.alignText}
                  width="120px"
                  style={{
                    left: `${extraSpaces * 20 + (extracell ? 36 : 0)}px`,
                  }}
                  color="primary"
                >
                  <TableSortLabel
                    active={orderBy === headItem.key}
                    direction={orderBy === headItem.key ? order : "asc"}
                    onClick={(event: any) => {
                      handleRequestSort(event, headItem.key);
                    }}
                    color="primary"
                  >
                    {headItem.text}
                  </TableSortLabel>
                  {/* <Typography>{headItem.text}</Typography> */}
                </TableCellSticky>
              ) : (
                <TableCell
                  colSpan={1}
                  className={headItem.class}
                  align={headItem.alignText}
                  color="primary"
                >
                  <TableSortLabel
                    active={orderBy === headItem.key}
                    direction={orderBy === headItem.key ? order : "asc"}
                    onClick={(event: any) => {
                      handleRequestSort(event, headItem.key);
                    }}
                  >
                    {headItem.text}
                  </TableSortLabel>
                  {/* <Typography>{headItem.text}</Typography> */}
                </TableCell>
              )
            )}
            {lastExtraSpaces
              ? _.times(extraSpaces, (i) => (
                  <TableCell width="20px" padding="none" />
                ))
              : null}
          </TableRow>
        </TableHead>
        <SBTableBody className={tbClass}>
          {items && items.length > 0
            ? stableSort(items, getComparator(order, orderBy)).map(
                (item: any, index) => {
                  const {
                    id,
                    lines,
                    amount,
                    amountPaid,
                    description,
                    quantity,
                    createdAt: date,
                    status: paymentStatus,
                  } = item;
                  const { paymentIntent } = item;

                  const newLines: any[] = [];
                  if (lines && lines.length) {
                    lines.forEach((line) => {
                      newLines.push({ ...line });
                    });
                    if (amountPaid) {
                      newLines[0].amount = amountPaid;
                    }
                  }

                  const data: { [key: string]: any } = {
                    id,
                    lines: newLines,
                    paymentIntent,
                    amount,
                    description,
                    quantity,
                    createdAt: date,
                    status: paymentStatus,
                    name: "-",
                    cardData: "-",
                  };

                  if (paymentIntent) {
                    const { paymentMethods } = paymentIntent;
                    if (paymentMethods && paymentMethods.length > 0) {
                      const { cardBrand, cardLast4, cardOwnerName } =
                        paymentMethods[0];

                      data.name = cardOwnerName;
                      data.cardData = `${cardBrand} ${cardLast4}`;
                    }
                  }

                  return (
                    <ItemComponent
                      subscriptionData={data}
                      parentHeaders={headers}
                      extraSpaces={extraSpaces}
                    />
                  );
                }
              )
            : null}
        </SBTableBody>
      </TableBase>
    </TableContainer>
  );
};

const headersColap: any[] = [
  {
    text: "Description",
    key: "description",
    type: "string",
    alignText: "left",
  },
  {
    text: "Quantity",
    key: "quantity",
    type: "number",
    alignText: "center",
  },
  {
    text: "Amount",
    key: "amount",
    type: "currency",
    alignText: "center",
  },
];

interface RowItemProps {
  subscriptionData: any;
  extraSpaces: number;
  parentHeaders: any;
}
const SBRowItem: React.FC<RowItemProps> = ({
  subscriptionData,
  extraSpaces,
  parentHeaders,
}) => {
  const classes = useStyles();
  const { id, audionautPlan, lines, amountPaid } = subscriptionData;

  const currentSubscriptionData = {
    ...audionautPlan,
    ...subscriptionData,
  };

  delete currentSubscriptionData.audionautPlan;

  const [linesPerPage, setLinesPerPage] = React.useState(5);
  const [linesPage, setLinesPage] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow
        id={`row-${id}`}
        style={{
          boxShadow:
            isMobileResolution() && lines && lines.length > 0
              ? "5px 5px 10px rgba(0,0,0,0.1)"
              : "none",
        }}
      >
        {_.times(extraSpaces, (i) => (
          <TableCellSticky
            width="20px"
            padding="none"
            // className={classes.borderLess}
          />
        ))}
        {lines && lines.length > 0 ? (
          <TableCellSticky
            width="20px"
            style={{ paddingLeft: "0px" }}
            color="primary"
          >
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              className="collapseIcon"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCellSticky>
        ) : null}
        {parentHeaders.map((item: any, index: number) =>
          index === 0 ? (
            <TableCellSticky
              className="stickyCell"
              colSpan={1}
              align={item.alignText}
              style={{
                left: `${
                  extraSpaces * 20 + (lines && lines.length > 0 ? 36 : 0)
                }px`,
              }}
              color="primary"
            >
              {currentSubscriptionData[item.key] !== undefined ? (
                <TableCellGenericItem
                  value={currentSubscriptionData[item.key]}
                  valueKey={item.key}
                  type={item.type}
                  format={item.format ?? ""}
                />
              ) : (
                <Typography>-</Typography>
              )}
            </TableCellSticky>
          ) : (
            <TableCell colSpan={1} align={item.alignText} color="primary">
              {currentSubscriptionData[item.key] !== undefined ? (
                <TableCellGenericItem
                  value={currentSubscriptionData[item.key]}
                  valueKey={item.key}
                  type={item.type}
                  format={item.format ?? ""}
                />
              ) : (
                <Typography>-</Typography>
              )}
            </TableCell>
          )
        )}
      </TableRow>
      {lines && lines.length > 0 ? (
        <TableRow
          style={{
            display: expanded ? "table-row" : "none",
            background: "transparent",
          }}
        >
          <TableCellCollapsable
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={parentHeaders.length + 2}
          >
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {!isMobileResolution() ? (
                <>
                  <Paginator
                    totalItems={lines.length}
                    updateItems={setLinesPerPage}
                    updatePage={setLinesPage}
                    hidden={lines.length <= 5}
                  />
                  <BRTable
                    title=""
                    headers={headersColap}
                    items={
                      lines.length > linesPerPage
                        ? lines.slice(
                            linesPage * linesPerPage,
                            linesPage * linesPerPage + linesPerPage
                          )
                        : lines
                    }
                    ItemComponent={SBRowItem}
                    thClass={classes.tableHeader}
                    tbClass={classes.tableBody}
                    tableClass={classes.tableContainer}
                    extraSpaces={2}
                    extracell={false}
                    lastExtraSpaces={false}
                    sortEnable
                  />
                </>
              ) : (
                lines.map((line) => (
                  <GridContainer container spacing={1}>
                    {headersColap.map((item) => (
                      <>
                        <Grid
                          container
                          item
                          xs={6}
                          md={6}
                          spacing={3}
                          className="item"
                        >
                          {item.text}
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          md={6}
                          spacing={3}
                          className="item"
                        >
                          {line[item.key]}
                        </Grid>
                      </>
                    ))}
                  </GridContainer>
                ))
              )}
              {/* <div>{JSON.stringify(content)}</div> */}
            </Collapse>
          </TableCellCollapsable>
        </TableRow>
      ) : null}
    </>
  );
};

const useStylesPlanItem = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      height: "100%",
      minWidth: "350px",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      [theme.breakpoints.down(540)]: {
        minWidth: "unset",
        borderRadius: "0px 0px 10px 10px",
        boxShadow: "0px 7px 10px rgba(0, 0, 0, 0.1)",
      },
    },
    itemTitle: {
      flex: "1",
      color: "#000 !important",
      fontSize: "18px !important",
      fontStyle: "normal !important",
      fontWeight: "bold",
      fontFamily: "Gothic A1 !important",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    hide: {
      display: "none",
    },
    headerPlan: {
      display: "flex",
      alignItems: "center",
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "bold",
      },
    },
    editPlan: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: "#FEFEFE",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "#973B7B",
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
    },
    titlePlanContainer: {
      paddingBottom: "6px",
    },
    titlePlan: {
      display: "flex",
      height: "30px",
      width: "208px",
      background: `${theme.palette.primary.main}A3`,
      borderRadius: "10px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        margin: "auto",
      },
    },
    planContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "8px 8px 16px 8px !important",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
    },
    headerImage: {
      display: "flex",
      height: "36px",
      width: "36px",
      background: "rgba(151, 59, 123, 0.1)",
      borderRadius: "18px",
      marginRight: "8px",
      "& img": {
        height: "22px",
        width: "22px",
        margin: "auto",
      },
    },
    planImage: {
      display: "flex",
      height: "62px",
      width: "62px",
      background: `${theme.palette.primary.main}A3`,
      borderRadius: "38px",
      "& svg": {
        fontSize: "42px",
        margin: "auto",
      },
    },
    planAmountRow: {
      display: "flex",
      justifyContent: "space-between",
      "&:first-child": {
        marginBottom: "12px",
        "& p:first-child": {
          paddingTop: "0px",
        },
        "& div": {
          paddingTop: "8px",
        },
      },
      "&:last-child": {
        alignItems: "center",
        "& p:first-child": {
          paddingTop: "0px",
          fontSize: "16px",
          lineHeight: "25px",
          fontWeight: "700",
        },
        "& .amount": {
          fontSize: "35px !important",
          lineHeight: "35px !important",
        },
      },
      [theme.breakpoints.down(540)]: {
        "&:first-child": {
          "& p:first-child": {
            paddingRight: "10px",
          },
        },
      },
    },
    planAmount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      fontSize: "35px",
      lineHeight: "44px",
      fontWeight: "bold",
      "& .amount": {
        fontSize: "25px !important",
        lineHeight: "25px !important",
        fontWeight: 800,
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "25px",
        lineHeight: "25px",
        fontWeight: 800,
      },
      [theme.breakpoints.down(540)]: {
        flexDirection: "column",
        justifyContent: "start",
      },
    },
    planDescription: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: "normal",
      textAlign: "left",
      paddingTop: "4px",
    },
    deletePlan: {
      background: theme.button.secondary.backgroundColor,
      border: `2px solid ${theme.button.secondary.backgroundColor}`,
      color: theme.button.secondary.color,
      marginRight: "20px",
    },
    planBtn: {
      height: "38px",
      boxSizing: "border-box",
      borderRadius: "116px",
      textTransform: "capitalize",
      background: theme.button.primary.backgroundColor,
      color: theme.button.primary.color,
      "&:hover": {
        background: theme.button.primary.hoverBackground,
        color: theme.button.primary.hoverColor,
      },
    },
    planFooter: {
      display: "flex",
      justifyContent: "end",
    },
    addPlanContainer: {
      padding: "17px 14px 16px 14px",
      border: "none !important",
    },
    addPlanItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      border: "2px dashed #929292",
      borderRadius: "10px",
      color: "#767676",
      cursor: "pointer",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "normal",
        color: "#767676",
      },
    },
    planType: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      marginBottom: "6px",
      paddingLeft: "6px",
      paddingRight: "15px",
    },
    planInfo: {
      width: "calc(100% - 16px)",
      border: "1px solid #EFEFEF",
      borderRadius: "10px",
      padding: "14px 7px 16px 15px",
      margin: "auto",
      marginBottom: "30px !important",
      "& hr": {
        marginTop: "16px",
        marginBottom: "12px",
      },
    },
    planDates: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "16px",
      paddingLeft: "8px",
      paddingRight: "8px",
      "& div:first-child": {
        textAlign: "left",
      },
      "& div:last-child": {
        textAlign: "right",
      },
      "& div": {
        "& p:first-child": {
          fontSize: "16px",
          lineHeight: "32px",
          fontWeight: 700,
        },
        "& p:last-child": {
          fontSize: "14px",
          lineHeight: "28px",
          fontWeight: 500,
        },
      },
    },
  })
);

const ButtonSecondary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.secondary.backgroundColor,
    // fontSize: theme.button.secondary.fontSize,
    // lineHeight: theme.button.secondary.lineHeight,
    color: theme.button.secondary.color,
    border: theme.button.secondary.border,
    textTransform: theme.button.secondary.textTransform,
    "&:hover": {
      color: theme.button.secondary.hoverColor,
      backgroundColor: theme.button.secondary.hoverBackground,
      border: theme.button.secondary.hoverBorder,
    },
    "&:disabled": {
      color: theme.button.secondary.disabledColor,
      backgroundColor: theme.button.secondary.disabledBackground,
      border: theme.button.secondary.disabledBorder,
    },
  },
}))(Button);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
}))(Button);

interface PlanItemProps {
  planData: any | undefined;
  cancelSubscriptionHandler: any | undefined;
  totalMinutesUsage: number;
}
const PlanItem: React.FC<PlanItemProps> = ({
  planData,
  cancelSubscriptionHandler,
  totalMinutesUsage = 0,
}) => {
  const classes = useStylesPlanItem();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [redirectToPlans, setRedirectToPlans] = React.useState(false);

  const totalAmountExtra = React.useMemo(() => {
    let totalAmount = 0;
    if (
      totalMinutesUsage &&
      planData &&
      planData.audionautPlan.pricePerUnitInCent
    ) {
      const planMinutes = planData.audionautPlan.audionautUnitInSec / 60;
      if (totalMinutesUsage > planMinutes) {
        totalAmount =
          (totalMinutesUsage - planMinutes) *
          (planData.audionautPlan.pricePerUnitInCent / 100);
      }
    }
    return totalAmount;
  }, [totalMinutesUsage, planData]);

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  return (
    <Card
      className={clsx(classes.itemContainer, {
        [classes.addPlanContainer]: !planData,
      })}
    >
      {planData ? (
        <>
          <CardContent className={classes.planContainer}>
            <Box hidden={isMobileResolution()} className={classes.headerPlan}>
              <Box className={classes.headerImage}>
                <img src={MembershipCard} alt="membership-icon" />
              </Box>
              <Typography color="primary">Current Subscription Plan</Typography>
            </Box>
            <Box className={classes.planType}>
              <Box className={classes.titlePlanContainer}>
                <Box className={classes.titlePlan}>
                  <Typography color="primary">
                    {planData.audionautPlan.name}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.planImage} color="primary">
                <StorefrontIcon color="primary" />
              </Box>
            </Box>
            <Box className={classes.planInfo}>
              <Box className={classes.planAmountRow}>
                <Typography className={classes.planDescription} color="primary">
                  {`This plan includes ${Math.floor(
                    planData.audionautPlan.audionautUnitInSec / 60
                  )} mins of audio,`}
                  <br />
                  <NumberFormat
                    value={planData.audionautPlan.pricePerUnitInCent / 100}
                    displayType="text"
                    thousandSeparator
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                  />
                  {` USD per minute after`}
                </Typography>
                <Box className={classes.planAmount}>
                  <Typography className="amount" color="primary">
                    <NumberFormat
                      value={planData.audionautPlan.priceInCent / 100}
                      displayType="text"
                      thousandSeparator
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                    />
                  </Typography>
                  <Typography className="currency" color="secondary">
                    USD/MO
                  </Typography>
                </Box>
              </Box>
              <Box hidden className={classes.planAmountRow}>
                <Typography className={classes.planDescription} color="primary">
                  Dolars per minutes extra
                </Typography>
                <Box className={classes.planAmount}>
                  <Typography className="amount" color="primary">
                    <NumberFormat
                      value={totalAmountExtra}
                      displayType="text"
                      thousandSeparator
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                    />
                  </Typography>
                  <Typography className="currency" color="secondary">
                    USD/MIN
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box className={classes.planAmountRow}>
                <Typography className={classes.planDescription} color="primary">
                  Total
                </Typography>
                <Box className={classes.planAmount}>
                  <Typography className="amount" color="primary">
                    <NumberFormat
                      value={
                        planData.audionautPlan.priceInCent / 100 +
                        totalAmountExtra
                      }
                      displayType="text"
                      thousandSeparator
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                    />
                  </Typography>
                  <Typography className="currency" color="secondary">
                    USD
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.planDates}>
              <Box>
                <Typography color="primary">Subscription Day</Typography>
                <Typography color="secondary">
                  {getFormattedDate(planData.startsAt.replaceAll("\\", ""))}
                </Typography>
              </Box>
              <Box>
                <Typography color="primary">Next Billing On</Typography>
                <Typography color="secondary">
                  {getFormattedDate(planData.endsAt.replaceAll("\\", ""))}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.planFooter}>
              <Button
                className={`${classes.deletePlan} ${classes.planBtn}`}
                variant="outlined"
                onClick={handleClickToggleDelete}
                color="primary"
              >
                Cancel Subscription
              </Button>
              {/* <Button
                className={classes.planBtn}
                onClick={() => {
                  setRedirectToPlans(true);
                }}
              >
                Change Plan
              </Button> */}
            </Box>
          </CardContent>
          {redirectToPlans ? <Redirect to="/user-plans" /> : null}
          <Dialog
            id="delete-dialog"
            open={openDelete}
            onClose={() => {
              setOpenDelete(!openDelete);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" color="primary">
              Cancel Subscription
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" color="primary">
                Are you sure you want to cance subscription {planData.name}?,
                Action taken cannot be reversed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonSecondary
                onClick={() => {
                  setOpenDelete(!openDelete);
                }}
                color="primary"
              >
                Close
              </ButtonSecondary>
              <ButtonPrimary
                onClick={() => {
                  cancelSubscriptionHandler();
                  setOpenDelete(!openDelete);
                }}
                color="primary"
                autoFocus
              >
                Cancel
              </ButtonPrimary>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <CardContent className={classes.addPlanItem}>
          <AddIcon />
          <Typography>There isn&apos;t subscription active</Typography>
        </CardContent>
      )}
    </Card>
  );
};

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible: string;
}
interface Props extends SwitchProps {
  classes: Styles;
}
const EnableSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 44,
      height: 24,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#86EBC2",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#86EBC2",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 22,
      height: 22,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStylesMonthResume = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      height: "100%",
      minWidth: "350px",
      display: "flex",
      flexDirection: "column",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      [theme.breakpoints.down(540)]: {
        minWidth: "unset",
        borderRadius: "0px 0px 10px 10px",
        boxShadow: "0px 7px 10px rgba(0, 0, 0, 0.1)",
      },
    },
    itemTitle: {
      flex: "1",
      fontSize: "18px !important",
      fontStyle: "normal !important",
      fontWeight: "bold",
    },
    itemHeader: {
      minHeight: "46px",
      paddingTop: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
    },
    headerDivider: {
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    deleteBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#CD3658",
      color: "#FFF",
      margin: "auto",
      marginLeft: "10px !important",
      "&:hover": {
        background: "#D83551",
      },
    },
    hide: {
      display: "none",
    },
    headerPlan: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "bold",
      },
    },
    editPlan: {
      minWidth: "20px",
      maxWidth: "30px",
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      background: "#FEFEFE",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "#973B7B",
      border: "none",
      display: "flex",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
      "& svg": {
        margin: "auto",
      },
    },
    titlePlan: {
      display: "flex",
      height: "30px",
      width: "208px",
      background: `${theme.palette.primary.main}A3`,
      borderRadius: "10px",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#973B7B",
        margin: "auto",
      },
    },
    planContainer: {
      height: "100%",
      flex: "1",
      display: "flex",
      flexDirection: "column",
      padding: "8px 8px 0px 8px !important",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
    },
    headerImage: {
      display: "flex",
      height: "36px",
      width: "36px",
      background: "#C147724D",
      borderRadius: "18px",
      color: "#973B7B",
      marginRight: "8px",
      "& img": {
        height: "22px",
        width: "22px",
        margin: "auto",
      },
    },
    planImage: {
      display: "flex",
      height: "62px",
      width: "62px",
      background: `${theme.palette.primary}A3`,
      borderRadius: "38px",
      color: "#973B7B",
      "& svg": {
        margin: "auto",
      },
    },
    planAmount: {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "35px",
      lineHeight: "44px",
      fontWeight: "bold",
      marginBottom: "17px",
      "& .amount": {
        fontSize: "50px",
        lineHeight: "95px",
        fontWeight: 800,
        marginRight: "10px",
      },
      "& .currency": {
        fontSize: "18px",
        lineHeight: "35px",
        fontWeight: 800,
      },
    },
    planDescription: {
      fontSize: "15px",
      lineHeight: "30px",
      fontWeight: 800,
      textAlign: "left",
      marginBottom: "16px",
    },
    deletePlan: {
      background: theme.button.secondary.backgroundColor,
      border: `2px solid ${theme.button.secondary.border}`,
      color: `${theme.button.secondary.color} !important`,
      marginRight: "20px",
    },
    planBtn: {
      height: "38px",
      width: "162px",
      boxSizing: "border-box",
      borderRadius: "116px",
      textTransform: "capitalize",
      background: theme.button.primary.backgroundColor,
      color: theme.button.primary.color,
      "&:hover": {
        background: theme.button.primary.hoverBackground,
        color: theme.button.primary.hoverColor,
      },
    },
    planFooter: {
      display: "flex",
      justifyContent: "end",
    },
    addPlanContainer: {
      padding: "17px 14px 16px 14px",
      border: "none !important",
    },
    addMonthResume: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      border: "2px dashed #929292",
      borderRadius: "10px",
      color: "#767676",
      cursor: "pointer",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "normal",
        color: "#767676",
      },
    },
    planType: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      marginBottom: "18px",
    },
    planInfo: {
      width: "calc(100% - 16px)",
      flex: "1",
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      marginBottom: "44px !important",
      border: "1px solid #EFEFEF",
      borderRadius: "10px",
      padding: "12px 16px",
    },
    planDates: {
      display: "flex",
      justifyContent: "space-between",
      "& div:first-child": {
        textAlign: "left",
      },
      "& div:last-child": {
        textAlign: "right",
      },
      "& div": {
        "& p:first-child": {
          fontSize: "16px",
          lineHeight: "32px",
          fontWeight: 700,
        },
        "& p:last-child": {
          fontSize: "14px",
          lineHeight: "28px",
          fontWeight: 500,
        },
      },
    },
    rangeDate: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 500,
      textAlign: "left",
    },
    extraMinutes: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 700,
    },
    monthCardFooter: {
      display: "flex",
      justifyContent: "space-between",
    },
    cardFooter: {
      padding: "0px 31px 29px",
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 700,
      },
    },
    overPlanMins: {
      color: "#773E90 !important",
    },
  })
);
interface MonthResumeProps {
  planData: any | undefined;
  currentStatus: boolean;
  setCurrentStatus: any;
  totalMinutesUsage: number;
}
const MonthResume: React.FC<MonthResumeProps> = ({
  planData,
  currentStatus,
  setCurrentStatus,
  totalMinutesUsage = 0,
}) => {
  const classes = useStylesMonthResume();

  const [changeAutoPay] = useChangeAutoPayStatusMutation();
  const errorHandler = useAppErrorHandler();

  const totalMinutesExtra = React.useMemo(() => {
    let minutesExtra = 0;
    if (totalMinutesUsage && planData && planData.audionautPlan) {
      const plansMinutes = planData.audionautPlan.audionautUnitInSec / 60;
      if (totalMinutesUsage > plansMinutes) {
        minutesExtra = totalMinutesUsage - plansMinutes;
      }
    }
    return minutesExtra;
  }, [totalMinutesUsage, planData]);

  const handleChangeSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const {
        target: { checked },
      } = event;

      setCurrentStatus(checked);

      await changeAutoPay({
        variables: {
          status: checked,
        },
      });
    } catch (error) {
      errorHandler(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          "An error has ocurred while stripe tryed confirm card setup"
        )
      );
    }
  };

  return (
    <Card
      className={clsx(classes.itemContainer, {
        [classes.addPlanContainer]: !planData,
      })}
    >
      {planData ? (
        <>
          <CardContent className={classes.planContainer}>
            <Box hidden={isMobileResolution()} className={classes.headerPlan}>
              <Box className={classes.headerImage}>
                <img src={MembershipCalendar} alt="calendar-icon" />
              </Box>
              <Typography color="primary">This Month So Far</Typography>
            </Box>
            <Box className={classes.planInfo}>
              <Typography className={classes.planDescription} color="primary">
                Minutes of processed Audio
              </Typography>
              <Box className={classes.planAmount}>
                <Typography
                  className={`amount ${
                    totalMinutesExtra > 0 ? classes.overPlanMins : ""
                  }`}
                  color="primary"
                >
                  <NumberFormat
                    value={totalMinutesUsage}
                    decimalScale={2}
                    displayType="text"
                    thousandSeparator
                  />
                </Typography>
                <Typography className="currency" color="secondary">
                  MINUTES
                </Typography>
              </Box>
              <Box className={classes.monthCardFooter}>
                <Typography className={classes.rangeDate} color="secondary">
                  {getFormattedDate(planData.startsAt)} to{" "}
                  {getFormattedDate(planData.endsAt)}.
                </Typography>
                <Typography
                  className={`${classes.extraMinutes} ${
                    totalMinutesExtra > 0 ? classes.overPlanMins : ""
                  }`}
                  color="primary"
                >
                  Extra minutes: {totalMinutesExtra} mins
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions className={classes.cardFooter}>
            <Typography color="secondary">
              Rebills on first day of the month
            </Typography>
          </CardActions>
        </>
      ) : (
        <CardContent className={classes.addMonthResume}>
          <AddIcon />
          <Typography>There isn&apos;t subscription active</Typography>
        </CardContent>
      )}
    </Card>
  );
};

const NoResults = withStyles(() => ({
  root: {
    boxShadow: "unset",
  },
}))(GenericEmptyMessages);

const TabsContainer = withStyles((theme: Theme) => ({
  root: {
    background: theme.generalStylesContainer.layers.content.backgroundColor,
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    paddingLeft: "14px",
    paddingRight: "14px",
  },
}))(AppBar);

export default {
  useStyles,
  SBContainer,
  SBContent,
  SBHeader,
  SBBody,
  SBTableHead,
  SBTableBody,
  SBRowItem,
  PlanItem,
  MonthResume,
  BRTable,
  NoResults,
  TabsContainer,
};
