/* eslint-disable */
/**
 * Action Types
 */
const ActionTypes = {
  FILTER: {
    UPDATE: "FILTER_UPDATE",
    GROUP: {
      UPDATE: "FILTER_GROUP_UPDATE",
    },
    GRAPH: {
      UPDATE: "FILTER_GRAPH_UPDATE",
    },
    USERS: {
      BY: "FILTER_USERS_BY",
    },
    EVENTS: {
      BY: "FILTER_EVENTS_BY",
    },
    GROUPS: {
      BY: "FILTER_GROUPS_BY",
    },
    IDENTITY_PROVIDERS: {
      BY: "FILTER_IDENTITY_PROVIDERS_BY",
    },
    LABELING_JOBS: {
      BY: "LABELING_JOBS_BY",
    },
    SEARCH_TEXT: {
      SET: "SEARCH_TEXT_SET",
    },
    TRAINING_JOBS: {
      BY: "TRAINING_JOBS_BY",
    },
  },
  MODEBAR: {
    SET: "MODEBAR_SET",
    SELECT: "MODEBAR_SELECT",
  },
  SELECTED_OPTIONS: {
    SET_INITIAL_DATA: "SET_INITIAL_DATA",
    CHANGE_OPTIONS: "CHANGE_OPTIONS",
  },
  FRAME: {
    SELECT: "SELECT_FRAME",
  },
  AUTH: {
    AUTH: {
      SUCCESS: "AUTH_AUTH_SUCCESS",
      FAILURE: "AUTH_AUTH_FAILURE",
    },
    LOGOUT: "AUTH_LOGOUT",
  },
  ROUTE: {
    TAB: "TAB_UPDATE",
  },
  HELP_API_DOC: {
    START: "HELP_API_DOC_START",
    SUCCESS: "HELP_API_DOC_SUCCESS",
    FAILURE: "HELP_API_DOC_FAILURE",
  },
  FETCH_API_SCHEMA: {
    START: "FETCH_API_SCHEMA_START",
    SUCCESS: "FETCH_API_SCHEMA_SUCCESS",
    FAILURE: "FETCH_API_SCHEMA_FAILURE",
  },
  FETCH_GRAPHQL_DATA: {
    START: "FETCH_GRAPHQL_DATA_START",
    SUCCESS: "FETCH_GRAPHQL_DATA_SUCCESS",
    FAILURE: "FETCH_GRAPHQL_DATA_FAILURE",
    CLEAN: "FETCH_GRAPHQL_DATA_CLEAN",
  },
  SLIDY: {
    INDEX: "SELECT_INDEX",
  },
  ORGANIZATIONS: {
    FETCH: {
      START: "ORGANIZATIONS_FETCH_START",
      SUCCESS: "ORGANIZATIONS_FETCH_SUCCESS",
      FAILURE: "ORGANIZATIONS_FETCH_FAILURE",
    },
  },
  ROLES: {
    SET: {
      SELECTED: "ROLES_SET_SELECTED",
      UPDATED: "ROLES_SET_UPDATED",
      DELETED: "ROLES_SET_DELETED",
      PERMISSIONS: {
        UPDATED: "ROLES_SET_PERMISSIONS_UPDATED",
      },
    },
    CREATE: {
      START: "ROLES_CREATE_START",
      SUCCESS: "ROLES_CREATE_SUCCESS",
      FAILURE: "ROLES_CREATE_FAILURE",
      CLEAR: "ROLES_CREATE_CLEAR",
    },
    FETCH: {
      START: "ROLES_FETCH_START",
      SUCCESS: "ROLES_FETCH_SUCCESS",
      FAILURE: "ROLES_FETCH_FAILURE",
    },
    UPDATE: {
      START: "ROLES_UPDATE_START",
      SUCCESS: "ROLES_UPDATE_SUCCESS",
      FAILURE: "ROLES_UPDATE_FAILURE",
      CLEAR: "ROLES_UPDATE_CLEAR",
    },
    DELETE: {
      START: "ROLES_DELETE_START",
      SUCCESS: "ROLES_DELETE_SUCCESS",
      FAILURE: "ROLES_DELETE_FAILURE",
      CLEAR: "ROLES_DELETE_CLEAR",
    },
    FILTER: {
      STATE: "ROLES_FILTER_STATE",
    },
    PERMISSIONS: {
      FETCH: {
        START: "ROLES_PERMISSIONS_FETCH_START",
        SUCCESS: "ROLES_PERMISSIONS_FETCH_SUCCESS",
        FAILURE: "ROLES_PERMISSIONS_FETCH_FAILURE",
      },
    },
  },
  USER: {
    CREATE: {
      START: "USER_CREATE_START",
      SUCCESS: "USER_CREATE_SUCCESS",
      FAILURE: "USER_CREATE_FAILURE",
      CLEAR: "USER_CREATE_CLEAR",
    },
    READ: {
      START: "USER_READ_START",
      SUCCESS: "USER_READ_SUCCESS",
      FAILURE: "USER_READ_FAILURE",
    },
    UPDATE: {
      START: "USER_UPDATE_START",
      SUCCESS: "USER_UPDATE_SUCCESS",
      FAILURE: "USER_UPDATE_FAILURE",
      CLEAR: "USER_UPDATE_CLEAR",
      STATUS: "USER_UPDATE_STATUS",
    },
    DELETE: {
      START: "USER_DELETE_START",
      SUCCESS: "USER_DELETE_SUCCESS",
      FAILURE: "USER_DELETE_FAILURE",
      CLEAR: "USER_DELETE_CLEAR",
    },
    SET: {
      DATA: "USER_SET_DATA",
    },
    EDIT: "UPDATE_USER_FIELDS",
    // Link/Unlink user to groups
    GROUPS: {
      ADD: {
        START: "USER_GROUPS_ADD_START",
        SUCCESS: "USER_GROUPS_ADD_SUCCESS",
        FAILURE: "USER_GROUPS_ADD_FAILURE",
        CLEAR: "USER_GROUPS_ADD_CLEAR",
      },
      DELETE: {
        START: "USER_GROUPS_DELETE_START",
        SUCCESS: "USER_GROUPS_DELETE_SUCCESS",
        FAILURE: "USER_GROUPS_DELETE_FAILURE",
        CLEAR: "USER_GROUPS_DELETE_CLEAR",
      },
    },
  },
  GROUPS: {
    // Set Groups Data
    SET: {
      DATA: "GROUPS_SET_DATA",
    },
    // CRUD
    CREATE: {
      START: "GROUPS_CREATE_START",
      SUCCESS: "GROUPS_CREATE_SUCCESS",
      FAILURE: "GROUPS_CREATE_FAILURE",
      CLEAR: "GROUPS_CREATE_CLEAR",
    },
    FETCH: {
      START: "GROUPS_FETCH_START",
      SUCCESS: "GROUPS_FETCH_SUCCESS",
      FAILURE: "GROUPS_FETCH_FAILURE",
    },
    UPDATE: {
      START: "GROUPS_UPDATE_START",
      SUCCESS: "GROUPS_UPDATE_SUCCESS",
      FAILURE: "GROUPS_UPDATE_FAILURE",
      CLEAR: "GROUPS_UPDATE_CLEAR",
    },
    DELETE: {
      START: "GROUPS_DELETE_START",
      SUCCESS: "GROUPS_DELETE_SUCCESS",
      FAILURE: "GROUPS_DELETE_FAILURE",
      CLEAR: "GROUPS_DELETE_CLEAR",
    },
    // Link/Unlink group to/from roles
    ROLES: {
      ADD: {
        START: "GROUPS_ROLES_ADD_START",
        SUCCESS: "GROUPS_ROLES_ADD_SUCCESS",
        FAILURE: "GROUPS_ROLES_ADD_FAILURE",
        CLEAR: "GROUPS_ROLES_ADD_CLEAR",
      },
      DELETE: {
        START: "GROUPS_ROLES_DELETE_START",
        SUCCESS: "GROUPS_ROLES_DELETE_SUCCESS",
        FAILURE: "GROUPS_ROLES_DELETE_FAILURE",
        CLEAR: "GROUPS_ROLES_DELETE_CLEAR",
      },
    },
  },
  IDENTITY_PROVIDERS: {
    // Set Identity Providers Data
    SET: {
      DATA: "IDENTITY_PROVIDERS_SET_DATA",
    },
    // CRUD
    CREATE: {
      START: "IDENTITY_PROVIDERS_CREATE_START",
      SUCCESS: "IDENTITY_PROVIDERS_CREATE_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_CREATE_FAILURE",
      CLEAR: "IDENTITY_PROVIDERS_CREATE_CLEAR",
    },
    FETCH: {
      START: "IDENTITY_PROVIDERS_FETCH_START",
      SUCCESS: "IDENTITY_PROVIDERS_FETCH_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_FETCH_FAILURE",
    },
    UPDATE: {
      START: "IDENTITY_PROVIDERS_UPDATE_START",
      SUCCESS: "IDENTITY_PROVIDERS_UPDATE_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_UPDATE_FAILURE",
      CLEAR: "IDENTITY_PROVIDERS_UPDATE_CLEAR",
    },
    DELETE: {
      START: "IDENTITY_PROVIDERS_DELETE_START",
      SUCCESS: "IDENTITY_PROVIDERS_DELETE_SUCCESS",
      FAILURE: "IDENTITY_PROVIDERS_DELETE_FAILURE",
      CLEAR: "IDENTITY_PROVIDERS_DELETE_CLEAR",
    },
  },
  PROTOCOLS: {
    FETCH: {
      START: "PROTOCOLS_FETCH_START",
      SUCCESS: "PROTOCOLS_FETCH_SUCCESS",
      FAILURE: "PROTOCOLS_FETCH_FAILURE",
    },
  },
  ORG_FILTER: {
    UPDATE: "ORG_FILTER_UPDATE",
    SET: "ORG_FILTER_SET",
  },
  TB_STORES: {
    // Set TB_STORES Data
    SET: {
      DATA: "TB_STORES_SET_DATA",
    },
    // CRUD
    FETCH: {
      START: "TB_STORES_FETCH_START",
      SUCCESS: "TB_STORES_FETCH_SUCCESS",
      FAILURE: "TB_STORES_FETCH_FAILURE",
    },
    CREATE: {
      START: "TB_STORES_CREATE_START",
      SUCCESS: "TB_STORES_CREATE_SUCCESS",
      FAILURE: "TB_STORES_CREATE_FAILURE",
      CLEAR: "TB_STORES_CREATE_CLEAR",
    },
    UPDATE: {
      START: "TB_STORES_UPDATE_START",
      SUCCESS: "TB_STORES_UPDATE_SUCCESS",
      FAILURE: "TB_STORES_UPDATE_FAILURE",
      CLEAR: "TB_STORES_UPDATE_CLEAR",
    },
    DELETE: {
      START: "TB_STORES_DELETE_START",
      SUCCESS: "TB_STORES_DELETE_SUCCESS",
      FAILURE: "TB_STORES_DELETE_FAILURE",
      CLEAR: "TB_STORES_DELETE_CLEAR",
    },
  },
  LOOKER: {
    // GET LOOKS INFO
    FETCH_LOOKS_DATA: {
      START: "LOOKS_FETCH_START",
      SUCCESS: "LOOKS_FETCH_SUCCESS",
      FAILURE: "LOOKS_FETCH_FAILURE",
    },
    // GET DASBOARDS INFO
    FETCH_DASHBOARDS_DATA: {
      START: "DASHBOARDS_FETCH_START",
      SUCCESS: "DASHBOARDS_FETCH_SUCCESS",
      FAILURE: "DASHBOARDS_FETCH_FAILURE",
    },
    // CREATE SSO URL
    CREATE_SSO_URL: {
      START: "SSO_URL_START",
      SUCCESS: "SSO_URL_SUCCESS",
      FAILURE: "SSO_URL_FAILURE",
    },
    // GET SPECIFIC LOOK INFO
    FETCH_LOOK_BY_ID: {
      START: "LOOK_BY_ID_FETCH_START",
      SUCCESS: "LOOK_BY_ID_FETCH_SUCCESS",
      FAILURE: "LOOK_BY_ID_FETCH_FAILURE",
    },
    // GET SPECIFIC DASHBOARD INFO
    FETCH_DASHBOARD_BY_ID: {
      START: "DASHBOARD_BY_ID_FETCH_START",
      SUCCESS: "DASHBOARD_BY_ID_FETCH_SUCCESS",
      FAILURE: "DASHBOARD_BY_ID_FETCH_FAILURE",
    },
  },
  LABELJOB: {
    ADDED: "LABELJOB.ADDED",
    UPDATE: {
      START: "LABELJOB_UPDATE_START",
      SUCCESS: "LABELJOB_UPDATE_SUCCESS",
      ERROR: "LABELJOB_UPDATE_ERROR",
    },
  },
  JOBS: {
    CREATE: {
      START: "JOBS_CREATE_START",
      SUCCESS: "JOBS_CREATE_SUCCESS",
      FAILURE: "JOBS_CREATE_FAILURE",
      CLEAR: "JOBS_CREATE_CLEAR",
    },
    READ: {
      UPDATE: "JOBS_READ_UPDATE",
      START: "JOBS_READ_START",
      SUCCESS: "JOBS_READ_SUCCESS",
      FAILURE: "JOBS_READ_FAILURE",
    },
    UPDATE: {
      START: "JOBS_UPDATE_START",
      SUCCESS: "JOBS_UPDATE_SUCCESS",
      FAILURE: "JOBS_UPDATE_FAILURE",
      CLEAR: "JOBS_UPDATE_CLEAR",
      LOCAL: {
        USER: "JOBS_UPDATE_LOCAL_USER",
      },
    },
    DELETE: {
      START: "JOBS_DELETE_START",
      SUCCESS: "JOBS_DELETE_SUCCESS",
      FAILURE: "JOBS_DELETE_FAILURE",
      CLEAR: "JOBS_DELETE_CLEAR",
    },
    SET: {
      DATA: "JOBS_SET_DATA",
    },
    EDIT: "UPDATE_JOBS_FIELDS",
    START: {
      SUCCESS: "JOB_START_SUCCESS",
    },
    QUIT: {
      SUCCESS: "JOB_QUIT_SUCCESS",
    },
  },
  JOB_FILTER: {
    UPDATE: "JOB_FILTER_UPDATE",
    SET: "JOB_FILTER_SET",
    RESET: "JOB_FILTER_RESET",
  },
  TRAINING_JOB_CREATION_FILTER: {
    UPDATE: "TRAINING_JOB_CREATION_FILTER_UPDATE",
    SET: "TRAINING_JOB_CREATION_FILTER_SET",
    RESET: "JTRAINING_JOB_CREATION_FILTER_RESET",
  },
  TRAINED_MODELS: {
    READ: {
      START: "TRAINED_MODELS_READ_START",
      SUCCESS: "TRAINED_MODELS_READ_SUCCESS",
      FAILURE: "TRAINED_MODELS_READ_FAILURE",
    },
    FILTER: {
      UPDATE: "TRAINED_MODELS_FILTER_UPDATE",
      SET: "TRAINED_MODELS_FILTER_SET",
      RESET: "TRAINED_MODELS_FILTER_RESET",
    },
    FILTERED: {
      SET: "TRAINED_MODELS_FILTERED_SET",
    },
  },
  JOBS_FOR_TRAINING: {
    READ: {
      START: "JOBS_FOR_TRAINING_READ_START",
      SUCCESS: "JOBS_FOR_TRAINING_READ_SUCCESS",
      FAILURE: "JOBS_FOR_TRAINING_READ_FAILURE",
    },
    READ_IMAGES: {
      START: "JOBS_FOR_TRAINING_READ_IMAGES_START",
      SUCCESS: "JOBS_FOR_TRAINING_READ_IMAGES_SUCCESS",
      FAILURE: "JOBS_FOR_TRAINING_READ_IMAGES_FAILURE",
    },
    EXCLUDED_IMAGES: {
      SET: "JOBS_FOR_TRAINING_EXCLUDED_IMAGES_SET",
    },
    TF_PARAMS: {
      SET: "JOBS_FOR_TRAINING_TF_PARAMS_SET",
    },
    PIPELINE_CONFIG: {
      SET: "JOBS_FOR_TRAINING_PIPELINE_CONFIG_SET",
    },
    MODEL_OPTIONS: {
      SET: "JOBS_FOR_TRAINING_MODEL_OPTIONS_SET",
    },
    CLASS_MAP: {
      SET: "JOBS_FOR_TRAINING_CLASS_MAP_SET",
    },
    LABEL_COUNTS: {
      READ: {
        START: "JOBS_FOR_TRAINING_LABEL_COUNTS_READ_START",
        SUCCESS: "JOBS_FOR_TRAINING_LABEL_COUNTS_READ_SUCCESS",
        FAILURE: "JOBS_FOR_TRAINING_LABEL_COUNTS_READ_FAILURE",
      },
    },
    NAME_INFO: {
      SET: "JOBS_FOR_TRAINING_NAME_INFO_SET",
    },
    MACHINE_TYPE: {
      SET: "JOBS_FOR_TRAINING_MACHINE_TYPE_SET",
    },
  },
  JOBS_FOR_TRAINING_FILTERED: {
    SET: "JOBS_FOR_TRAINING_FILTERED_SET",
    UPDATE: "JOBS_FOR_TRAINING_FILTERED_UPDATE",
  },
  LABEL_CLASSES: {
    READ: {
      START: "LABEL_CLASSES_READ_START",
      SUCCESS: "LABEL_CLASSES_READ_SUCCESS",
      FAILURE: "LABEL_CLASSES_READ_FAILURE",
    },
  },
  TRAINING_JOBS: {
    CREATION: {
      IMG_SLIDER: {
        NEXT: "TRAINING_JOBS_CREATION_IMG_SLIDER_NEXT",
        BEFORE: "TRAINING_JOBS_CREATION_IMG_SLIDER_BEFORE",
        LOAD_MORE: {
          START: "TRAINING_JOBS_CREATION_IMG_SLIDER_LOAD_MORE_START",
          ON_SUCCESS: "TRAINING_JOBS_CREATION_IMG_SLIDER_LOAD_MORE_SUCCESS",
          ON_ERROR: "TRAINING_JOBS_CREATION_IMG_SLIDER_LOAD_MORE_ERROR",
        },
        LABEL_EDITOR: {
          SET_IMAGE: "TRAINING_JOBS_CREATION_IMG_SLIDER_LABEL_EDITOR_SET_IMAGE",
          CLOSE: "TRAINING_JOBS_CREATION_IMG_SLIDER_LABEL_EDITOR_CLOSE",
          UPDATE_IMAGE_REGIONS:
            "TRAINING_JOBS_CREATION_IMG_SLIDER_LABEL_EDITOR_UPDATE_IMAGE_REGIONS",
        },
        SHOW_ONLY_OVERLAPPED: {
          SET: "TRAINING_JOBS_CREATION_IMG_SLIDER_SHOW_ONLY_OVERLAPPED_SET",
        },
      },
    },
    CREATE: {
      START: "TRAINING_JOBS_CREATE_START",
      SUCCESS: "TRAINING_JOBS_CREATE_SUCCESS",
      FAILURE: "TRAINING_JOBS_CREATE_FAILURE",
      CLEAR: "TRAINING_JOBS_CREATE_CLEAR",
    },
    UPDATE: "TRAINING_JOBS_UPDATE",
    ADD_NEW: "TRAINING_JOBS_ADD_NEW",
    MESSAGES: {
      CREATE_TRAINING_SUCCESS:
        "Training Created Successfully, review the status on tensor board",
      CREATE_TRAINING_ERROR:
        "Error on creating Training Job, try later or contact the admin",
      UPDATE_LABELS_SUCCESS: "Labels updated succesfully",
      IMGSLIDER: { LOAD_MORE_ERROR: "Error on load more images, try later" },
      READ_IMAGES_ERROR: "Error loading images data, try later",
      READ_IMAGES_SUCCESS: "Images Data loaded successfully",
      READ_IMAGES_START: "Reading Image Data from database",
      LABEL_COUNTS: {
        READ_START: "Reading Label Counts",
        READ_ERROR: "Error on Read Label Counts",
      },
    },
    READ: {
      START: "TRAINING_JOBS_READ_START",
      SUCCESS: "TRAINING_JOBS_READ_SUCCESS",
      FAILURE: "TRAINING_JOBS_READ_FAILURE",
    },
  },
  MACHINE_TYPES: {
    READ: {
      START: " MACHINE_TYPES_READ_START",
      SUCCESS: " MACHINE_TYPES_READ_SUCCESS",
      FAILURE: " MACHINE_TYPES_READ_FAILURE",
    },
    FILTER: {
      UPDATE: " MACHINE_TYPES_FILTER_UPDATE",
      SET: " MACHINE_TYPES_FILTER_SET",
      RESET: " MACHINE_TYPES_FILTER_RESET",
    },
    FILTERED: {
      SET: " MACHINE_TYPES_FILTERED_SET",
    },
  },
  NAVIGATION: {
    BLOCK: {
      SET: "NAVIGATION_BLOCKED_SET",
    },
  },
  GDRIVE_DOCUMENTS: {
    UPDATE: "GDRIVE_DOCUMENTS_UPDATE",
    DICT: "GDRIVE_DOCUMENTS_DICT",
    OPERATION: {
      START: "GDRIVE_DOCUMENTS_OPERATION_START",
      END: "GDRIVE_DOCUMENTS_OPERATION_END",
      FAILURE: "GDRIVE_DOCUMENTS_OPERATION_FAILURE",
      CLEAN_DATA: "GDRIVE_DOCUMENTS_OPERATION_CLEAN_DATA",
      CLEAN_ERROR: "GDRIVE_DOCUMENTS_OPERATION_CLEAN_ERROR",
    },
  },
  GDRIVE_VERSIONS: {
    ADD: "GDRIVE_VERSIONS_ADD",
    UPDATE: "GDRIVE_VERSIONS_UPDATE",
    LIST: "GDRIVE_VERSIONS_LIST",
    OPERATION: {
      START: "GDRIVE_VERSIONS_OPERATION_START",
      END: "GDRIVE_VERSIONS_OPERATION_END",
      FAILURE: "GDRIVE_VERSIONS_OPERATION_FAILURE",
      CLEAN_DATA: "GDRIVE_VERSIONS_OPERATION_CLEAN_DATA",
      CLEAN_ERROR: "GDRIVE_VERSIONS_OPERATION_CLEAN_ERROR",
    },
  },
};

export {
  ActionTypes
};
