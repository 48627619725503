/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { MACHINE_TYPES } = ActionTypes;
const INITIAL_STATE = {
  // read classes

  isReadMachineTypes: false,
  onReadMachineTypesError: null,
  onReadMachineTypesSuccess: [],
  filteredMachine: []
};


const machineTypesReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) { // Read TRAINED MODELS from db
    case MACHINE_TYPES.READ.START:
      return {
        ...state,
        isReadMachineTypes: true,
        onReadMachineTypesError: null
      };
    case MACHINE_TYPES.READ.SUCCESS: {
      return {
        ...state,
        onReadMachineTypesSuccess: action.payload,
        isReadMachineTypes: false,
        filteredModels: action.payload
      };
    }
    case MACHINE_TYPES.READ.FAILURE:
      return {
        ...state,
        isReadMachineTypes: false,
        onReadMachineTypesError: action.payload,
      };
    case MACHINE_TYPES.FILTERED.SET:
      return {
        ...state,
        filteredMachine: action.payload,
      };
    default: return state;
  }
};
export default machineTypesReducerFn;
