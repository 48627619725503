/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";

export type VisibilityTableSchema = {
  TIN_NO: string;
  FIRST_NAME: string;
  MIDDLE_NAME: string;
  SUR_NAME: string;
  EMAIL_ID: string;
  BIRTH_DT: string;
};

export const VisibilityTableColumns: TableColumn<VisibilityTableSchema>[] = [
  {
    Header: "TIN",
    accessor: "TIN_NO",
    align: "left",
    Cell: ({ value }) => {
      return (
        <Link to={`/individuals/${value}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "First Name",
    accessor: "FIRST_NAME",
    align: "left",
  },
  {
    Header: "Middle Name",
    accessor: "MIDDLE_NAME",
    align: "left",
  },
  {
    Header: "Surname",
    accessor: "SUR_NAME",
    align: "left",
  },
  {
    Header: "Date of Birth",
    accessor: "BIRTH_DT",
    align: "left",
  },
  {
    Header: "Email Address",
    accessor: "EMAIL_ID",
    align: "right",
  },
];
