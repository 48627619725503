/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
// Dependencies
import React from "react";
import { Box, Snackbar } from "@material-ui/core";
import { Reference, StoreObject } from "@apollo/client";
import _ from "lodash";

// Components
import Table, { TableColumn } from "components/table/table.component";
import GroupForm from "components/group-form/group-form.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { Alert, AlertProps } from "components/alert/alert.component";
import { ActionButton } from "components/action-button/action-button.component";
import { GroupFilteringLeftPane } from "components/group-filtering-left-pane/group-filtering-left-pane.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// GraphQL
import {
  useGetAllUsersQuery,
  GroupData,
  useGetGroupQuery,
  useGetRoleQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useAddGroupRoleMutation,
  useDeleteGroupRoleMutation,
  RoleData,
  useAddUserGroupMutation,
  useDeleteUserGroupMutation,
  Maybe,
  UserData,
  useGetAllUsersLazyQuery,
  useGetCurrentUserOrganisationQuery,
} from "graphql/types-and-hooks";
import {
  createEmptyGroupData,
  NEW_GROUP_FRAGMENT_GQL,
} from "graphql/rbac.utils";

// Utils
import { RtcsFormattedDate } from "utils/internationalization";
import {
  useAppErrorHandler,
  isServerError,
  UIError,
  UIErrorCodes,
} from "errors/app.errors";

// Assets
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const GroupManagementPage: React.FC = () => {
  const emptyGroupData = createEmptyGroupData();
  const [initialGroupData, setInitialGroupData] =
    React.useState(emptyGroupData);
  const [openAddGroupForm, setOpenAddGroupForm] = React.useState(false);
  const [openLeftPane, setOpenLeftPane] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [initialSaveActive, setInitialSaveActive] = React.useState(false);
  const [currentOrganisation, setCurrentOrganization] = React.useState({
    id: "",
    name: "",
    email: "",
  });

  const {
    data: groupsList,
    loading: groupListLoading,
    error: errorGroupsDataLoading,
  } = useGetGroupQuery({
    variables: {},
  });

  const {
    data: currentOrganisationData,
    loading: currentOrganisationLoading,
    error: currentOrganisationError,
  } = useGetCurrentUserOrganisationQuery();

  const [
    getUsersData,
    {
      data: usersData,
      loading: usersDataLoading,
      error: errorUsersDataLoading,
    },
  ] = useGetAllUsersLazyQuery();

  const {
    data: rolesList,
    loading: rolesDataLoading,
    error: errorRolesDataLoading,
  } = useGetRoleQuery({
    variables: {},
  });

  const errorHandler = useAppErrorHandler(
    errorGroupsDataLoading ||
      errorUsersDataLoading ||
      errorRolesDataLoading ||
      currentOrganisationError
  );

  const [addGroup, { loading: addGroupLoading }] = useAddGroupMutation({
    update(cache, { data }) {
      const newGroup = data?.AddGroup[0];
      if (newGroup) {
        cache.modify({
          fields: {
            GetGroup(existingGroups = []) {
              const newGroupRef = cache.writeFragment({
                id: cache.identify(newGroup),
                data: newGroup,
                fragment: NEW_GROUP_FRAGMENT_GQL,
              });
              return [...existingGroups, newGroupRef];
            },
          },
        });
      }
    },
  });
  const [addGroupRole, { loading: addGroupRoleLoading }] =
    useAddGroupRoleMutation();
  const [deleteGroupRole, { loading: deleteGroupRoleLoading }] =
    useDeleteGroupRoleMutation();
  const [updateGroup, { loading: updateGroupLoading }] =
    useUpdateGroupMutation();

  const [deleteGroup, { loading: deleteGroupLoading }] = useDeleteGroupMutation(
    {
      update(cache, { data }) {
        const groupToDeleteId = data?.DeleteGroup;
        cache.modify({
          fields: {
            GetGroup(existingGroups, { readField }) {
              return existingGroups.filter(
                (group: Reference | StoreObject | undefined) =>
                  groupToDeleteId !== readField("id", group)
              );
            },
          },
        });
      },
    }
  );

  const [addUserGroup, { loading: addUserGroupLoading }] =
    useAddUserGroupMutation();

  const [deleteUserGroup, { loading: deleteUserGroupLoading }] =
    useDeleteUserGroupMutation();

  const users = React.useMemo(() => usersData?.GetUser ?? [], [usersData]);
  const groups = React.useMemo(() => groupsList?.GetGroup ?? [], [groupsList]);
  const roles = rolesList?.GetRole;

  const [filteredGroups, setFilteredGroups] = React.useState<GroupData[]>();

  const loading =
    groupListLoading ||
    usersDataLoading ||
    rolesDataLoading ||
    addGroupLoading ||
    updateGroupLoading ||
    deleteGroupLoading ||
    addGroupRoleLoading ||
    addUserGroupLoading ||
    deleteGroupRoleLoading ||
    deleteUserGroupLoading ||
    currentOrganisationLoading;

  const handlerAddGroupOnSubmit = React.useCallback(
    async (
      groupData: GroupData,
      initialUsersBelongToGroup: UserData[],
      usersBelongToGroup: UserData[]
    ) => {
      const rolesSelected = groupData.roles?.map((role) => role?.id);
      try {
        const response = await addGroup({
          variables: {
            name: groupData?.name ?? "",
            description: groupData?.description ?? "",
          },
        });
        const groupId = response.data!.AddGroup[0]!.id;

        if (rolesSelected?.length !== 0) {
          await addGroupRole({
            variables: {
              groupID: groupId!,
              roleID: rolesSelected as string[],
            },
          });
        }

        usersBelongToGroup.forEach(async (user) => {
          await addUserGroup({
            variables: {
              userID: user.id!,
              groupID: [groupId!],
            },
          });
        });

        setSnackBarMessage({
          message: "The group has been created successfully",
          severity: "success",
        });

        setOpenAddGroupForm(false);
      } catch (error) {
        if (isServerError(error)) {
          errorHandler(error);
          // setInitialEditable(true);
          // setInitialSaveActive(true);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the group"
            )
          );
        }
      }
    },
    [addGroup, addGroupRole, addUserGroup, errorHandler]
  );

  const handlerEditGroupOnSubmit = React.useCallback(
    async (
      groupData: GroupData,
      initialUsersBelongToGroup: UserData[],
      usersBelongToGroup: UserData[]
    ) => {
      const groupId = groupData.id!;
      const OriginalGroupData = groups.find((group) => group?.id === groupId);
      const initialRoles = OriginalGroupData?.roles?.map((role) => role?.id);
      const rolesSelected = groupData.roles?.map((role) => role?.id);
      const usersBelongToGroupIds = usersBelongToGroup.map((group) => group.id);
      const initialUsersBelongToGroupIds = initialUsersBelongToGroup.map(
        (group) => group.id
      );

      try {
        await updateGroup({
          variables: {
            id: groupId!,
            name: groupData.name,
            description: groupData.description,
          },
        });

        const rolesToAdd = _.difference(
          rolesSelected,
          initialRoles as string[]
        );

        const rolesToDelete = _.difference(
          initialRoles,
          rolesSelected as string[]
        );

        rolesToAdd.forEach((role) => {
          addGroupRole({
            variables: {
              groupID: groupId,
              roleID: [role as string],
            },
          });
        });

        rolesToDelete.forEach((role) => {
          deleteGroupRole({
            variables: {
              groupID: groupId,
              roleID: [role as string],
            },
          });
        });

        const usersToAdd = _.difference(
          usersBelongToGroupIds,
          initialUsersBelongToGroupIds
        );

        const usersToDelete = _.difference(
          initialUsersBelongToGroupIds,
          usersBelongToGroupIds
        );

        usersToAdd.forEach((user) => {
          addUserGroup({
            variables: {
              userID: user as string,
              groupID: [groupId!],
            },
          });
        });

        usersToDelete.forEach((user) => {
          deleteUserGroup({
            variables: {
              userID: user as string,
              groupID: [groupId]!,
            },
          });
        });

        setSnackBarMessage({
          message: "The group has been updated successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updating the group"
            )
          );
        }
      }
    },
    [
      addGroupRole,
      addUserGroup,
      deleteGroupRole,
      deleteUserGroup,
      errorHandler,
      groups,
      updateGroup,
    ]
  );

  const handlerDeleteGroup = React.useCallback(
    async (groupId: Maybe<string> | undefined) => {
      try {
        await deleteGroup({
          variables: {
            id: groupId ?? "",
          },
        });

        setSnackBarMessage({
          message: "The group has been deleted successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the group"
            )
          );
        }
      }
    },
    [deleteGroup, errorHandler]
  );

  const handlerCloneGroup = React.useCallback(
    async (groupData: GroupData) => {
      const newInitialGroupData = initialGroupData;
      newInitialGroupData.name = `Copy of ${groupData.name}`;
      newInitialGroupData.description = groupData.description;
      newInitialGroupData.roles = groupData.roles;
      newInitialGroupData.id = groupData.id;

      setInitialGroupData(newInitialGroupData);
      setInitialSaveActive(true);
      setOpenAddGroupForm(true);
    },
    [initialGroupData]
  );
  const handleTableActions = (action: TableToolbarAction) => {
    if (action === "filter-results") {
      if (openLeftPane) setOpenLeftPane(false);
      else setOpenLeftPane(true);
    }
    if (action === "add") {
      if (openAddGroupForm) {
        setOpenAddGroupForm(false);
      } else {
        setOpenAddGroupForm(true);
      }
      setInitialGroupData(emptyGroupData);
    }
  };

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handleCancelAddGroup = () => {
    setOpenAddGroupForm(false);
    setInitialSaveActive(false);
    setInitialGroupData(emptyGroupData);
  };

  const columns: TableColumn<GroupData>[] = React.useMemo(
    () => [
      { Header: "Name", accessor: "name", align: "left", width: 310 },
      {
        Header: "Creation Date",
        accessor: ({ creationDate }) => (
          <RtcsFormattedDate
            value={_.isNil(creationDate) ? undefined : creationDate}
          />
        ),
        align: "left",
        width: 310,
      },
      {
        Header: "Roles",
        accessor: (group) => group.roles?.map((rol) => rol?.name),
        align: "left",
        width: 310,
      },
      {
        id: "actions",
        Header: "Actions",
        accessor: "id",
        Cell: ({ row, value }) => (
          <>
            <ActionButton
              key="Delete"
              title="Delete"
              icon={<DeleteIcon />}
              handler={() => handlerDeleteGroup(value)}
              displayPopover
              popoverMessage="Are you sure you want to delete this item??
You won't be able to recover them"
              popoverButtons
            />
            <ActionButton
              key="Clone"
              title="Clone"
              icon={<FileCopyIcon />}
              handler={() => handlerCloneGroup(row.original)}
            />
          </>
        ),

        align: "left",
      },
    ],
    [handlerCloneGroup, handlerDeleteGroup]
  );

  const appPermissionValidator = useAppPermissionValidator();
  const addGroupForm = openAddGroupForm ? (
    <GroupForm
      initialGroupData={initialGroupData}
      initialEditable
      initialSaveActive={initialSaveActive}
      usersList={users ?? []}
      rolesList={(roles as RoleData[]) ?? []}
      onSubmit={handlerAddGroupOnSubmit}
      onCancel={handleCancelAddGroup}
    />
  ) : undefined;

  React.useEffect(() => {
    if (
      currentOrganisationData &&
      currentOrganisationData.getCurrentUserOrganisation
    ) {
      setCurrentOrganization({
        id: currentOrganisationData.getCurrentUserOrganisation.id!,
        name: currentOrganisationData.getCurrentUserOrganisation.name!,
        email: currentOrganisationData.getCurrentUserOrganisation.email!,
      });

      getUsersData({
        variables: {
          organisationID:
            currentOrganisationData.getCurrentUserOrganisation.id!,
        },
      });
    }
  }, [currentOrganisationData, getUsersData]);

  return (
    <Box flex="1" height="100%">
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>

      <ProgressIndicator open={loading} />

      <Table
        title="Groups"
        persistenceId="f487f577-c645-4748-ae3d-d3554f4eba71"
        data={filteredGroups ?? (groups as GroupData[])}
        columns={columns}
        onAction={handleTableActions}
        actionsOnLeft={
          appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)
            ? ["add"]
            : undefined
        }
        actionsOnRight={["filter-results"]}
        leftPanel={
          <GroupFilteringLeftPane
            groups={(groups as GroupData[]) ?? []}
            onFilter={setFilteredGroups}
          />
        }
        topPanel={addGroupForm}
        renderExpandedRowSubComponent={(row) => (
          <GroupForm
            initialGroupData={row.original}
            initialEditable={false}
            usersList={users ?? []}
            rolesList={(roles as RoleData[]) ?? []}
            onSubmit={handlerEditGroupOnSubmit}
            onCancel={() => null}
          />
        )}
      />
    </Box>
  );
};

export default GroupManagementPage;
