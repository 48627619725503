import { Box, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ButtonPrimary from "components/button-primary/button-primary.component";
import IconButtonGeneric from "components/icon-button/icon-button.component";

const CarouselContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& .alice-carousel__dots": {
      marginTop: "0px",
      "& .alice-carousel__dots-item": {
        backgroundColor: "#000",
        opacity: "0.203",
        "&.__active": {
          opacity: "0.697",
        },
      },
    },
    [theme.breakpoints.down(540)]: {
      height: "100%",
    },
  },
}))(Box);

const NavigationButton = withStyles((theme: Theme) => ({
  root: {
    height: "68px",
    width: "68px",
    position: "absolute",
    zIndex: 2,
    margin: "unset",
    background: "#5D3881",
    borderRadius: "0px",
    opacity: "0.3",
    color: "#FFF",
    "&.prev-button": {
      left: "50px",
      "&.captions": {
        left: "30px !important",
      },
    },
    "&.next-button": {
      right: "50px",
      "&.captions": {
        right: "30px !important",
      },
    },
    "&.disabled": {
      opacity: "0.1 !important",
    },
    "&.widget": {
      background: "#000000",
      opacity: "0.509",
      top: "234px",
      "&.prev-button": {
        left: "25px",
      },
      "&.next-button": {
        right: "25px",
      },
    },
    "&.captions": {
      background: `${theme.palette.primary.main}66`,
      color: theme.palette.primary.main,
      height: "30px",
      width: "30px",
      borderRadius: "50%",
      opacity: "1",
    },
    [theme.breakpoints.down(540)]: {
      // position: "sticky",
      background: "transparent",
      color: theme.palette.primary.main,
      opacity: "1",
      "&.prev-button": {
        left: "0px",
      },
      "&.next-button": {
        right: "0px",
      },
    },
  },
}))(IconButtonGeneric);

const CarouselItem = withStyles(() => ({
  root: {
    width: "unset !important",
    height: "unset !important",
    padding: "13px 23px 8px",
    background: "#D9D2F2",
    "& span": {
      display: "flex",
      flexDirection: "column",
      color: "#8C68B4",
    },
    "&.active": {
      background: "#8C68B4 !important",
      "& span": {
        color: "#FFF !important",
      },
    },
    "&:hover": {
      "& span": {
        color: "#FFF !important",
      },
    },
  },
}))(ButtonPrimary);

const TopicTitle = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.fontGeneralStyles.medium.lineHeight,
    fontWeight: 700,
  },
}))(Typography);

const TotalVideos = withStyles((theme: Theme) => ({
  root: {
    fontSize: "13px",
    fontWeight: 700,
  },
}))(Typography);

export default {
  CarouselContainer,
  NavigationButton,
  CarouselItem,
  TopicTitle,
  TotalVideos,
};
