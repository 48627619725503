import { Box, withStyles, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalTitle: {
      color: theme.palette.primary.main,
      fontSize: "16px",
      lineHeight: "27px",
      fontWeight: 700,
    },
    modalTitle2: {
      padding: "unset",
    },
    formContainer: {
      background: theme.generalStylesContainer.layers.primary.backgroundColor,
      borderRadius: "0px 0px 10px 10px",
      padding: "0px",
      "& .loginLbl": {
        fontSize: "26px",
        lineHeight: "32px",
        fontWeight: "bold",
        marginBottom: "30px",
        textAlign: "center",
      },
      "& .passwordInput": {
        marginBottom: "30px",
        "& .inputStyle": {
          marginBottom: "0px",
          "& .MuiOutlinedInput-root": {
            paddingRight: "14px",
          },
          "& .MuiInputAdornment-positionEnd": {
            marginLeft: "0px",
          },
        },
        "& p": {
          textAlign: "end",
          // color: "#B8CAC6",
          fontSize: "12px",
          lineHeight: "14px",
        },
      },
      "& .inputStyle": {
        marginBottom: "44px",
        "& .MuiOutlinedInput-root": {
          height: "56px",
          paddingRight: "0px",
          "& .MuiOutlinedInput-input": {
            // color: "rgba(0, 0, 0, 0.87) !important",
            fontSize: "16px",
            lineHeight: "19px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            // border: "1px solid rgba(0, 0, 0, 0.36)",
            borderRadius: "4px",
            boxSizing: "border-box",
          },
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: "34px",
        },
      },
      "& .checkRemember": {
        marginBottom: "70px",
        float: "left",
        [theme.breakpoints.down("md")]: {
          marginBottom: "60px",
        },
      },
      "& .loginBtn": {
        width: "100%",
        height: "48px",
        borderRadius: "116px",
        // border: "2px solid #B8CAC6",
        // background: "#B8CAC6",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "bold",
        // color: "#FFFFFF",
        marginBottom: "24px",
      },
      "& .noRegistered": {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "20px",
        // color: "#757575",
        textAlign: "center",
        "& a": {
          textDecoration: "underline",
          // color: "#B8CAC6",
          marginLeft: "3px",
        },
      },
      "& .emailIcon": {
        margin: "0px !important",
        padding: "12px",
        // color: "#B8CAC6",
      },
      "& .passwordVisibility": {
        // color: "#D3CFDB",
      },
      "& .hide": {
        display: "none",
      },
    },
    passwordRules: {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.54)",
      marginBottom: "48px",
      textAlign: "left",
    },
    steps: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      paddingLeft: "56px",
      paddingRight: "56px",
      "& svg:first-child": {
        marginRight: "1px",
      },
      "& .MuiDivider-root": {
        height: "2px",
        flex: "1",
        backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.down(540)]: {
        padding: "0px 30px",
      },
    },
    stepsLbs: {
      display: "flex",
      marginBottom: "30px",
      textAlign: "center",
      "& p": {
        width: "133px",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: 500,
        // color: "#757575",
      },
      "& div": {
        flex: "1",
      },
    },
    footerText: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 600,
      // color: "#757575",
      "& button": {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: 800,
        textTransform: "inherit",
        // color: "#B8CAC6",
        border: "none",
        padding: "0px",
        paddingLeft: "5px",
        "&:hover": {
          background: "unset",
        },
      },
    },
    disabled: {
      // background: "#35C330 !important",
    },
    hide: {
      display: "none",
    },
    addressForm: {
      paddingBottom: "0px",
      borderBottom: "none",
      "& .customFormTitle": {
        display: "none",
      },
      "& .customFieldsContainer": {
        border: "none",
        padding: "unset",
        "& .customFormContainer": {
          marginRight: "0px",
        },
      },
      "& .customAB": {
        flexDirection: "column",
        marginTop: "50px",
        "& .nextBtn": {
          width: "316px",
          margin: "auto !important",
          marginBottom: "10px !important",
          backgroundColor: theme.button.primary.backgroundColor,
          color: theme.button.primary.color,
          "&:hover": {
            color: theme.button.primary.hoverColor,
            backgroundColor: theme.button.primary.hoverBackground,
          },
          "&:disabled": {
            color: theme.button.secondary.disabledColor,
            backgroundColor: theme.button.primary.disabledBackground,
          },
          [theme.breakpoints.down(540)]: {
            width: "100%",
          },
        },
        "& .backBtn": {
          height: theme.buttonSize.small.height,
          width: theme.buttonSize.small.width,
          borderRadius: theme.buttonSize.small.borderRadius,
          fontWeight: theme.buttonSize.small.fontWeight,
          backgroundColor: theme.button.secondary.backgroundColor,
          color: theme.button.secondary.color,
          textTransform: theme.button.secondary.textTransform,
          "&:hover": {
            color: theme.button.secondary.hoverColor,
            backgroundColor: theme.button.secondary.hoverBackground,
          },
          "&:disabled": {
            color: theme.button.secondary.disabledColor,
            backgroundColor: theme.button.secondary.disabledBackground,
          },
          border: "none",
          textDecoration: "underline",
          margin: "auto !important",
          [theme.breakpoints.down(540)]: {
            width: "100%",
          },
        },
      },
    },
    modalContent: {
      background: theme.generalStylesContainer.layers.primary.backgroundColor,
      padding: "21px 30px 38px 30px",
    },
    chatPro: {
      "& .MuiPaper-root": {
        maxWidth: "420px !important",
        borderRadius: "10px",
      },
      "& .MuiDialogContent-root": {
        padding: "24px 45px 27px",
      },
    },
    dialogContainer: {
      "& .MuiPaper-root": {
        background: theme.generalStylesContainer.layers.primary.backgroundColor,
      },
    },
    topicDescription: {
      fontSize: "15px",
      lineHeight: "30px",
      fontWeight: 400,
      textAlign: "left",
      marginBottom: "16px",
    },
  })
);

const ModaleHeader = withStyles({
  root: {
    display: "flex",
    "& div": {
      flex: "1",
    },
    "& button": {
      height: "50px",
      width: "50px",
    },
  },
})(Box);

export default {
  ModaleHeader,
  useStyles,
};
