/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';
// Constants
import {
  JOBS_COLUMN_ID,
  FILTER_INITIAL_DATE_RANGE
} from '../../commons/constants';

const { TRAINING_JOB_CREATION_FILTER, TRAINING_JOBS } = ActionTypes;
const jobFilterInitialState = [
  {
    id: JOBS_COLUMN_ID.ID,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.COMMENTS,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.STATUS,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.STORE,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.CREATETIME,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.DATE_RANGE_START,
    value: [FILTER_INITIAL_DATE_RANGE.startDate]
  },
  {
    id: JOBS_COLUMN_ID.DATE_RANGE_END,
    value: [FILTER_INITIAL_DATE_RANGE.endDate]
  },
  {
    id: JOBS_COLUMN_ID.TECHNICIAN,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.DEVICE,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.ENDTIME,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.LABELED_CLASS_TYPE,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.CLASS_TYPE,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.IMAGE_DATE_RANGE,
    value: []
  }
];

const trainingJobCreationFilterReducerFn = (state = jobFilterInitialState, action) => {
  switch (action.type) {
    case TRAINING_JOB_CREATION_FILTER.UPDATE: {
      const jobFilter = state;
      let newJobFilter = jobFilter;
      const newFilterOption = action.payload;
      if (newFilterOption.id === JOBS_COLUMN_ID.ID) {
        const StoreFilter = jobFilter.find(f => f.id === JOBS_COLUMN_ID.STORE).value;
        const DeviceFilter = jobFilter.find(f => f.id === JOBS_COLUMN_ID.DEVICE).value;
        newFilterOption?.jobInfo?.store?.forEach(store => {
          if (StoreFilter.indexOf(store) === -1) {
            StoreFilter.push(store);
          }
        });
        newFilterOption?.jobInfo?.device?.forEach(device => {
          if (DeviceFilter.indexOf(device) === -1) {
            DeviceFilter.push(device);
          }
        });
        newJobFilter = newJobFilter.filter(f => ![JOBS_COLUMN_ID.STORE, JOBS_COLUMN_ID.DEVICE].includes(f.id));
        newJobFilter.push({
          id: JOBS_COLUMN_ID.STORE,
          value: StoreFilter
        });
        newJobFilter.push({
          id: JOBS_COLUMN_ID.DEVICE,
          value: DeviceFilter
        });
      }
      newJobFilter = newJobFilter.filter(f => f.id !== newFilterOption.id);
      newJobFilter.push(newFilterOption);
      return newJobFilter;
    }
    case TRAINING_JOB_CREATION_FILTER.SET: {
      return {
        ...state
      };
    }
    case TRAINING_JOBS.CREATE.CLEAR:
    case TRAINING_JOB_CREATION_FILTER.RESET: {
      return [
        ...jobFilterInitialState
      ];
    }
    default:
      return state;
  }
};

export default trainingJobCreationFilterReducerFn;
