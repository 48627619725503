import { Box, withStyles, Theme, Link, Typography } from "@material-ui/core";
import { browserName } from "react-device-detect";

const FooterContainer = withStyles((theme: Theme) => ({
  root: {
    width:
      browserName.toLowerCase() === "firefox"
        ? "-moz-available"
        : "-webkit-fill-available",
    height: "25px",
    position: "fixed",
    display: "flex",
    background: theme.generalStylesContainer.footer.backgroundColor,
    bottom: "0px",
    [theme.breakpoints.down(540)]: {
      position: "relative",
      height: "unset",
      flexDirection: "column",
    },
  },
}))(Box);

const FooterContent = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "1030px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    columnGap: "50px",
    background: "transparent",
    margin: "auto",
    "& p": {
      color: theme.generalStylesContainer.footer.color,
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
    },
    [theme.breakpoints.down(540)]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      padding: "10px 10px 50px",
    },
  },
}))(Box);

const FooterLink = withStyles((theme: Theme) => ({
  root: {
    color: theme.generalStylesContainer.footer.color,
    fontSize: theme.fontGeneralStyles.small.fontSize,
    lineHeight: theme.fontGeneralStyles.small.lineHeight,
    "&:hover": {
      color: theme.generalStylesContainer.hoverColor,
    },
    [theme.breakpoints.down(540)]: {
      textAlign: "left",
      marginBottom: "10px",
    },
  },
}))(Link);

const FooterRigthReserved = withStyles((theme: Theme) => ({
  root: {
    color: theme.generalStylesContainer.footer.color,
    fontSize: theme.fontGeneralStyles.medium.fontSize,
    lineHeight: theme.fontGeneralStyles.medium.lineHeight,
    [theme.breakpoints.down(540)]: {
      position: "absolute",
      bottom: "0px",
      marginBottom: "20px",
      textAlign: "left",
    },
  },
}))(Typography);

export default {
  FooterContainer,
  FooterContent,
  FooterLink,
  FooterRigthReserved,
};
