import { withStyles, IconButton as IconButtonMui } from "@material-ui/core";

const IconButton = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    padding: "12px",
    color: theme.theming.gray.color,
    [theme.breakpoints.down(540)]: {
      "& svg": {
        height: "20px",
        width: "20px",
      },
    },
  },
}))(IconButtonMui);

export default {
  IconButton,
};
