const handleExportToCSV = (data: any, columns: any): void => {
  // Extract the column header, and object key accessor from the columns obj
  const headers: string[] = [];
  const accessors: string[] = [];

  const CSVRows: string[] = [];

  columns.map((column: any) => {
    headers.push(column.Header);
    accessors.push(column.accessor);
    return undefined;
  });

  CSVRows.push(headers.join(","));

  // eslint-disable-next-line no-restricted-syntax
  for (const row of data) {
    const values = accessors.map((accessor: any) => {
      return `"${row[accessor]}"`;
    });
    CSVRows.push(values.join(","));
  }

  const csv = CSVRows.join("\n");

  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", "data.csv");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default handleExportToCSV;
