// Dependencies
import React, { ReactElement, RefObject } from "react";
import { StyledComponentProps } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";

// Assets
import SC from "./general-search.styles";

interface SearchGeneralProps {
  doSearch: (arg0: string) => void;
  clearInputE: boolean;
  hideSearchBar: boolean;
  placeholder: string;
  isMultilines: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  hideClearButton?: boolean;
  disabled?: boolean;
  inputRef?: RefObject<HTMLElement>;
  showTutoIcon?: boolean;
  autoTyping?: string;
  customClass?: string;
  inputSearch?: boolean;
  name?: string;
}

const GeneralSearch: React.FC<SearchGeneralProps & StyledComponentProps> = ({
  doSearch,
  clearInputE,
  hideSearchBar,
  placeholder,
  isMultilines,
  startIcon = null,
  endIcon = null,
  classes,
  hideClearButton = false,
  disabled = false,
  inputRef = null,
  showTutoIcon = false,
  autoTyping = "",
  customClass = "",
  name = "",
}) => {
  const [valueSearch, setValueSearch] = React.useState("");
  const [isVisible, setIsVisible] = React.useState(true);
  const [clearInput, setClearInput] = React.useState(false);
  const inputElement = React.useRef<HTMLInputElement>(null);
  const typingIndex = React.useRef<number>(0);

  React.useEffect(() => {
    // doSearch(valueSearch);
    if (valueSearch.length !== 0) {
      setClearInput(true);
    } else {
      setClearInput(false);
      // DoSearch();
    }
  }, [valueSearch, doSearch]);

  React.useEffect(() => {
    if (clearInputE) {
      setClearInput(false);
      setValueSearch("");
    }
  }, [clearInputE]);

  React.useEffect(() => {
    if (autoTyping.length > typingIndex.current) {
      setTimeout(() => {
        typingIndex.current += 1;
        setValueSearch(autoTyping.slice(0, typingIndex.current));
      }, Math.floor(Math.random() * (150 - 90) + 90));
    } else {
      typingIndex.current = 0;
    }
  }, [autoTyping, valueSearch]);

  const handleOpen = () => {
    if (inputElement && inputElement.current) {
      inputElement.current.focus();
    }
    setIsVisible(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
  };

  const doSearchHandler: React.FormEventHandler = React.useCallback(
    (event: any) => {
      event.preventDefault();
      doSearch(valueSearch);
    },
    [valueSearch, doSearch]
  );

  const clearValueSearch = () => {
    setClearInput(false);

    if (inputElement && inputElement.current) {
      inputElement.current.focus();
    }
    setValueSearch("");
    doSearch("");
  };

  const closeInput = () => {
    if (valueSearch.length === 0) {
      setIsVisible(true);
    }
  };

  return (
    <SC.GeneralSearch
      component="form"
      onSubmit={doSearchHandler}
      className={`${classes ? classes.root : ""} ${customClass}`}
    >
      {!hideSearchBar ? (
        <SC.Input
          name={name}
          value={valueSearch}
          onChange={handleChange}
          placeholder={placeholder}
          isActive={isVisible}
          inputRef={inputElement}
          ref={inputRef}
          onBlur={closeInput}
          disabled={disabled}
          className={valueSearch.length > 0 ? "active" : ""}
          startAdornment={<>{startIcon}</>}
          endAdornment={
            <>
              {endIcon}
              <SC.ButtonClear
                hidden={hideClearButton}
                onClick={clearValueSearch}
                clearActive={clearInput}
              >
                <CloseIcon />
              </SC.ButtonClear>
            </>
          }
          multiline={isMultilines}
          maxRows={3}
        />
      ) : null}
    </SC.GeneralSearch>
  );
};

export default GeneralSearch;
