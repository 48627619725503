import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      padding: "13px 20px",
      borderRadius: theme.containers.general.borderRadius,
      "&.error": {
        color: theme.containers.error.color,
        background: theme.containers.error.background,
        border: theme.containers.error.border,
      },
      "&.warning": {
        color: theme.containers.warning.color,
        background: theme.containers.warning.background,
        border: theme.containers.warning.border,
      },
      "& svg": {
        heigh: "30px",
        width: "30px",
      },
      "& button": {
        color: theme.containers.warning.color,
        background: theme.containers.warning.background,
        border: theme.containers.warning.border,
      },
      [theme.breakpoints.down(540)]: {
        flexDirection: "column",
        gap: "10px",
        marginBottom: "10px",
        "& button": {
          width: "100%",
        },
      },
    },
    messageText: {
      flex: "1",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
    },
  })
);

const ActionButton = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    textTransform: theme.button.secondary.textTransform,
  },
}))(Button);

export default {
  useStyles,
  ActionButton,
};
