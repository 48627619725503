// Dependencies
import {
  withStyles,
  Box,
  Button as ButtonBase,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiDialogActions from "@material-ui/core/DialogActions";

const PlansCouponsForm = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    paddingBottom: "24px",
    [theme.breakpoints.down(540)]: {
      padding: "10px",
      border: "1px solid #E1E1E1",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    },
  },
}))(Box);

const FormActions = withStyles({
  root: {
    justifyContent: "rigth",
    marginTop: "40px",
    padding: "0px",
  },
})(MuiDialogActions);

const Button = withStyles((theme: Theme) => ({
  root: {
    height: "38px",
    minWidth: "144px",
    borderRadius: "116px",
    "& span": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "500" as "normal",
    },
  },
  contained: {
    color: theme.button.primary.color,
    backgroundColor: theme.button.primary.backgroundColor,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
  },
  label: {
    textTransform: "capitalize",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "bold",
  },
}))(ButtonBase);

export default {
  PlansCouponsForm,
  FormActions,
  EditIcon,
  CancelCircleIcon,
  CheckCircle,
  ErrorOutlineIcon,
  Button,
};
