import React from "react";

import { withStyles, Theme, useTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

import { isMobileResolution } from "commons/utils/device-info.util";

const drawerWidth = 264;

const ToggleIcon = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.generalStylesContainer.drawer.backgroundColor,
    borderRadius: "unset !important",
    bottom: "0px",
    width: "inherit",
    height: "40px",
    margin: "auto !important",
    justifyContent: "unset !important",
    "&:hover": {
      backgroundColor: `${theme.generalStylesContainer.drawer.active.backgroundColor} !important`,
      paddingLeft: "16px",
      paddingRight: "16px",
      justifyContent: "unset !important",
      "& span:first-child": {
        backgroundColor: "#000000",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
      },
    },
  },
}))(IconButton);

const MobileToggleIcon = withStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    zIndex: 1301,
    width: "62px",
    height: "60px",
    background: "transparent",
    borderRadius: "unset !important",
    top: "0px",
    margin: "auto !important",
    justifyContent: "unset !important",
    "&:hover": {
      paddingLeft: "16px",
      paddingRight: "16px",
      justifyContent: "unset !important",
      "& span:first-child": {
        width: "25px",
        height: "25px",
        borderRadius: "50%",
      },
    },
    "& .open-icon": {
      fill: theme.generalStylesContainer.color,
      stroke: theme.generalStylesContainer.color,
      "& path": {
        strokeWidth: "0px",
      },
    },
    [theme.breakpoints.down(540)]: {
      position: "fixed",
    },
  },
}))(IconButton);

const IconOpened = withStyles((theme: Theme) => ({
  root: {
    fill: theme.generalStylesContainer.color,
    width: "25px",
    height: "25px",
  },
}))(isMobileResolution() ? MenuIcon : OpenInNewIcon);

const IconClosedEle = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      style={{
        [theme.breakpoints.down(540)]: {
          position: "relative",
          left: `${drawerWidth - 62}px`,
        },
      }}
      viewBox="0 0 30 30"
    >
      <path
        d="M26.25 16.5C25.8682 16.5 25.5 16.8284 25.5 17.2499V25.4999H4.50002V4.50002H12.75C13.1482 4.50002 13.5 4.14829 13.5 3.75004C13.5 3.3518 13.125 3 12.75 3H4.50002C3.67124 3 3 3.67124 3 4.50002V25.5C3 26.3287 3.67124 27 4.50002 27H25.5C26.3287 27 27 26.3288 27 25.5V17.2387C27 16.8233 26.6955 16.5 26.25 16.5Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M17.7497 13.2675L25.2469 13.2675C25.6607 13.2675 25.9966 12.9316 25.9966 12.5178C25.9966 12.104 25.6607 11.7681 25.2469 11.7681L19.552 11.7681L27.0492 4.27159C27.3401 3.98069 27.3401 3.50912 27.0492 3.21821C26.7583 2.92731 26.2868 2.92731 25.9966 3.21821L18.4994 10.7154L18.4994 5.02058C18.4994 4.60672 18.1635 4.27088 17.7497 4.27088C17.3359 4.27088 17 4.60678 17 5.02058L17 12.5178C16.9999 12.9211 17.3463 13.2675 17.7497 13.2675Z"
        fill="white"
        stroke="white"
      />
    </SvgIcon>
  );
};

const IconClosed = withStyles((theme: Theme) => ({
  root: {
    width: "25px",
    height: "25px",
    "& path": {
      fill: theme.generalStylesContainer.color,
      stroke: theme.generalStylesContainer.color,
    },
    [theme.breakpoints.down(540)]: {
      strokeWidth: "0px",
      position: "relative",
      left: `${drawerWidth - 62}px`,
    },
  },
}))(isMobileResolution() ? MenuOpenIcon : IconClosedEle);

export default {
  ToggleIcon,
  IconOpened,
  IconClosed,
  MobileToggleIcon,
};
