/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { TB_STORES } = ActionTypes;

const TB_STORES_INITIAL_STATE = {
  // Update TB Stores
  isUpdatingTbStores: false,
  onUpdateTbStoresError: null,
  onUpdateTbStoresSuccess: null,
  // Delete TB Stores
  isDeletingTbStores: false,
  onDeleteTbStoresError: null,
  onDeleteTbStoresSuccess: null,
};

const mutationsTbStoresReducerFn = (state = TB_STORES_INITIAL_STATE, action) => {
  switch (action.type) {
    // Update TB Stores
    case TB_STORES.UPDATE.START:
      return {
        ...state,
        isUpdatingTbStores: true,
        onUpdateTbStoresError: null
      };
    case TB_STORES.UPDATE.SUCCESS: {
      const { UpdateTbStore } = action.payload;
      return {
        ...state,
        onUpdateTbStoresSuccess: UpdateTbStore,
        isUpdatingTbStores: false,
      };
    }
    case TB_STORES.UPDATE.FAILURE:
      return {
        ...state,
        isUpdatingTbStores: false,
        onUpdateTbStoresError: action.payload,
      };
    case TB_STORES.UPDATE.CLEAR:
      return {
        ...state,
        onUpdateTbStoresError: null,
        onUpdateTbStoresSuccess: null
      };
    // Delete TB Stores
    case TB_STORES.DELETE.START:
      return {
        ...state,
        isDeletingTbStores: true,
        onDeleteTbStoresError: null
      };
    case TB_STORES.DELETE.SUCCESS: {
      const { DeleteTbStores } = action.payload;
      return {
        ...state,
        onDeleteTbStoresSuccess: DeleteTbStores,
        isDeletingTbStores: false
      };
    }
    case TB_STORES.DELETE.FAILURE:
      return {
        ...state,
        isDeletingTbStores: false,
        onDeleteTbStoresError: action.payload
      };
    case TB_STORES.DELETE.CLEAR:
      return {
        ...state,
        onDeleteTbStoresError: null,
        onDeleteTbStoresSuccess: null
      };
    default: return state;
  }
};


export default mutationsTbStoresReducerFn;
