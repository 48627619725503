import React from "react";
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";

import {
  GetVideosFromMasterByIdQueryVariables,
  GetVideosQueryVariables,
  useGetChannelsQuery,
  useGetHashTagsInfoQuery,
  useGetPlaylistsQuery,
  useGetVideosQuery,
} from "graphql/explore/types-and-hooks";
import { CLIENT_NAME } from "graphql/client";
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";

import {
  ChannelModel,
  HashtagModel,
  PlaylistModel,
  VideoModel,
} from "commons/models/audio-source.model";

import { isMobileResolution } from "commons/utils/device-info.util";

import AudioSourceSubitem from "components/audio-source-subitem/audio-source-subitem.component";
import CustomCheckBox from "components/custom-checkbox/custom-checkbox.component";
import Paginator from "../paginator/paginator.component";

import SC from "./audio-source-table.styles";

interface DataSubTable {
  id: string;
  videoName: string;
  url: string;
  videos: string;
  views: string;
  subscribers: string;
  update: string;
  creation: string;
  actions: string;
  image: string;
  status: string;
  scheduled: number;
  duration: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface PlatformTableProps {
  title: string;
  addingNewItem: boolean;
  source: string;
  reloadVideos: boolean;
  deleteItem: any;
  category: string;
  itemAdded:
    | ChannelModel[]
    | PlaylistModel[]
    | VideoModel[]
    | HashtagModel[]
    | undefined;
  itemIdDeleted: string | undefined;
  setExistsVideos: (arg0: boolean) => void;
  loadingCategory: (arg0: boolean) => void;
  totalItems: number;
}

const PlatformTable: React.FC<PlatformTableProps> = ({
  title,
  addingNewItem = false,
  source: sourceName,
  reloadVideos,
  deleteItem,
  category,
  itemAdded,
  itemIdDeleted,
  setExistsVideos,
  loadingCategory,
  totalItems: initTotalItems = 0,
}) => {
  const classes = SC.useStyles();

  let headCells = SC.channelsHeadCells;
  if (title === "Playlists") headCells = SC.playlistsHeadCells;
  if (title === "Videos") headCells = SC.videosHeadCells;
  if (title === "Hashtags") headCells = SC.hashtagsHeadCells;

  const totalItems = React.useRef(initTotalItems);

  const [itemsList, setItemsList] = React.useState<any[]>([]);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof DataSubTable>("videos");

  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedAll, setSelectedAll] = React.useState(false);

  const [resetPagination, setResetPagination] = React.useState(false);

  const [paginationVariables, setPaginationVariables] =
    React.useState<GetVideosQueryVariables>({
      source: sourceName,
      total: 5,
      index: 0,
    });

  const {
    data: platformVideosData,
    loading: videosLoading,
    error: videosError,
    refetch: refetchVideos,
  } = useGetVideosQuery({
    variables: paginationVariables,
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
    // fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: category !== "MEDIA",
  });

  const {
    data: platformPlaylistsData,
    loading: playlistsLoading,
    error: playlistsError,
    refetch: refetchPlaylists,
  } = useGetPlaylistsQuery({
    variables: paginationVariables,
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
    // fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: category !== "PLAYLIST",
  });

  const {
    data: platformChannelsData,
    loading: channelsLoading,
    error: channelsError,
    refetch: refetchChannels,
  } = useGetChannelsQuery({
    variables: paginationVariables,
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
    // fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: category !== "CHANNEL",
  });

  const {
    data: platformHashtagsData,
    loading: hashtagsLoading,
    error: hashtagsError,
    refetch: refetchHashtags,
  } = useGetHashTagsInfoQuery({
    variables: paginationVariables,
    context: {
      clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
    },
    // fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: category !== "HASHTAG",
  });

  const loading =
    videosLoading || channelsLoading || playlistsLoading || hashtagsLoading;

  const errorHandler = useAppErrorHandler(
    videosError || playlistsError || channelsError || hashtagsError
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = itemsList.map((n: any) => n.id);
      setSelected(newSelecteds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  const handleSelectItem = (checkstatus: boolean, itemId: string) => {
    const currentSelects = [...selected];

    if (checkstatus) {
      currentSelects.push(itemId);
    } else {
      const currentIndex = currentSelects.findIndex((id) => id === itemId);
      currentSelects.splice(currentIndex, 1);
    }
    setSelected(currentSelects);
    setSelectedAll(
      itemsList.length > 0 && currentSelects.length === itemsList.length
    );
  };

  const handleUpdateItems = (newPage: number, newRowsPerPage: number) => {
    setPaginationVariables({
      source: sourceName,
      total: newRowsPerPage,
      index: newRowsPerPage * newPage,
    });
  };

  React.useEffect(() => {
    switch (category) {
      case "MEDIA":
        if (
          !loading &&
          platformVideosData &&
          platformVideosData.getVideos?.length
        ) {
          setItemsList(platformVideosData.getVideos);
          setExistsVideos(true);
        }
        break;
      case "PLAYLIST":
        if (
          !loading &&
          platformPlaylistsData &&
          platformPlaylistsData.getPlaylists?.length
        ) {
          setItemsList(platformPlaylistsData.getPlaylists);
          setExistsVideos(true);
        }
        break;
      case "CHANNEL":
        if (
          !loading &&
          platformChannelsData &&
          platformChannelsData.getChannels?.length
        ) {
          setItemsList(platformChannelsData.getChannels);
          setExistsVideos(true);
        }
        break;
      case "HASHTAG":
        if (
          !loading &&
          platformHashtagsData &&
          platformHashtagsData.getHashtags?.length
        ) {
          setItemsList(platformHashtagsData.getHashtags);
          setExistsVideos(true);
        }
        break;
      default:
        // console.debug("=====> Category unknown");
        break;
    }
  }, [
    platformVideosData,
    platformChannelsData,
    platformPlaylistsData,
    platformHashtagsData,
    category,
    setExistsVideos,
    loading,
  ]);

  React.useEffect(() => {
    try {
      let isLastPageEmpty = false;
      setResetPagination(reloadVideos);
      if (reloadVideos) {
        setItemsList([]);
        setPaginationVariables((oldVars) => {
          return {
            ...oldVars,
            index: 0,
          };
        });
      } else if (itemIdDeleted) {
        totalItems.current -= 1;
        setItemsList((oldItems) => {
          const currentItems = [...oldItems];
          const itemIndex = currentItems.findIndex(
            (item) => item.id === itemIdDeleted
          );

          if (itemIndex > -1) {
            currentItems.splice(itemIndex, 1);
          }

          isLastPageEmpty = !currentItems.length;

          return currentItems;
        });
      }

      if (isLastPageEmpty) {
        setPaginationVariables((oldPaginationVars) => {
          const { index, total } = oldPaginationVars;

          let newIndex = index;
          if (index && total && index - total >= total) {
            newIndex = index - total;
          }

          return {
            ...oldPaginationVars,
            index: newIndex,
          };
        });
      } else {
        switch (category) {
          case "MEDIA":
            refetchVideos();
            break;
          case "PLAYLIST":
            refetchPlaylists();
            break;
          case "CHANNEL":
            refetchChannels();
            break;
          case "HASHTAG":
            refetchHashtags();
            break;
          default:
            // console.debug("=====> Category unknown");
            break;
        }
      }
    } catch (error) {
      errorHandler(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          "An error has ocurred while getting media info"
        )
      );
    }
  }, [
    reloadVideos,
    itemIdDeleted,
    sourceName,
    category,
    refetchChannels,
    refetchPlaylists,
    refetchVideos,
    refetchHashtags,
    errorHandler,
  ]);

  React.useEffect(() => {
    switch (category) {
      case "MEDIA":
        loadingCategory(videosLoading);
        break;
      case "PLAYLIST":
        loadingCategory(playlistsLoading);
        break;
      case "CHANNEL":
        loadingCategory(channelsLoading);
        break;
      case "HASHTAG":
        loadingCategory(hashtagsLoading);
        break;
      default:
        // console.debug("=====> Category unknown");
        break;
    }
  }, [
    category,
    loadingCategory,
    videosLoading,
    playlistsLoading,
    channelsLoading,
    hashtagsLoading,
  ]);

  React.useEffect(() => {
    if (addingNewItem && !!itemAdded) {
      totalItems.current += 1;
      setItemsList((oldItems) => [...itemAdded, ...oldItems]);
    }
  }, [addingNewItem, itemAdded]);

  return (
    <Box hidden={itemsList.length === 0}>
      <Toolbar className={classes.subTableTitle}>
        <Typography className={classes.title} variant="h6" component="div">
          {title}
        </Typography>
        <Paginator
          totalItems={totalItems.current}
          getNewItems={handleUpdateItems}
          reset={resetPagination}
        />
      </Toolbar>
      <SC.TableContainer>
        <Table
          className={classes.table}
          stickyHeader
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead className={classes.subTableHeader}>
            <TableRow>
              <SC.TableCellSticky padding="checkbox" className="checkbox">
                <CustomCheckBox
                  checked={selectedAll}
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all medias" }}
                />
              </SC.TableCellSticky>
              {headCells.map((headCell, index) =>
                index === 0 ? (
                  <SC.TableCellSticky
                    key={headCell.id}
                    className={`stickyCell ${classes.headerLabel} ${headCell.customClasses}`}
                    align={headCell.align}
                    sortDirection={orderBy === headCell.id ? order : false}
                    colSpan={1}
                  >
                    {headCell.label}
                    {/* <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                              >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                  <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                      ? "sorted descending"
                                      : "sorted ascending"}
                                  </span>
                                ) : null}
                              </TableSortLabel> */}
                  </SC.TableCellSticky>
                ) : (
                  <TableCell
                    key={headCell.id}
                    hidden={isMobileResolution() && headCell.id === "url"}
                    className={`${classes.headerLabel} ${headCell.customClasses}`}
                    align={headCell.align}
                    sortDirection={orderBy === headCell.id ? order : false}
                    colSpan={1}
                  >
                    {headCell.label}
                    {/* <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                              >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                  <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                      ? "sorted descending"
                                      : "sorted ascending"}
                                  </span>
                                ) : null}
                              </TableSortLabel> */}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {addingNewItem || loading ? (
              <TableRow
                key={`itemLoader-${title}-${sourceName}`}
                style={{ height: "50px" }}
              >
                <TableCell colSpan={8} className={classes.cellBodyStyle}>
                  <LinearProgress
                    className={
                      videosLoading ||
                      playlistsLoading ||
                      channelsLoading ||
                      hashtagsLoading
                        ? "newPage"
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </TableCell>
              </TableRow>
            ) : null}
            {itemsList.map((row: any, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const indexEven = index % 2 !== 0;

              return (
                <AudioSourceSubitem
                  data={row}
                  selected={selected}
                  selectItem={handleSelectItem}
                  deleteItem={deleteItem}
                  id={labelId}
                  even={indexEven}
                  type={row.category ?? "CHANNEL"}
                  avatar={!!row.channelId}
                  itemDeleted={itemIdDeleted === row.id}
                />
              );
            })}
            {/* {stableSort(
              itemsList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ),
              getComparator(order, orderBy)
            ).map((row: any, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const indexEven = index % 2 !== 0;

              return (
                <AudioSourceSubitem
                  data={itemsList}
                  selected={selected}
                  selectItem={handleSelectItem}
                  deleteItem={deleteItem}
                  id={labelId}
                  even={indexEven}
                  type={row.category ?? "CHANNEL"}
                  avatar={!!row.channelId}
                />
              );
            })} */}
          </TableBody>
        </Table>
      </SC.TableContainer>
    </Box>
  );
};

export default PlatformTable;
