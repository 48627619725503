import {
  withStyles,
  Card as CardBase,
  CardContent,
  Typography,
  Snackbar,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";

// Components
import Alert from "components/alert/alert.component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalCustom: {
      "& .MuiDialog-paper": {
        width: "100%",
        fontFamily: "Gothic A1",
        fontStyle: "normal",
        color: "#000",
        maxWidth: "500px",
        "& div": {
          padding: "20px",
          paddingTop: "14px",
        },
        "& .title": {
          margin: "0px",
          marginBottom: "20px",
          padding: "0px",
          paddingBottom: "16px",
          "& h2": {
            color: "#000",
            fontSize: "16px",
            lineHeight: "27px",
            fontWeight: 700,
          },
        },
        "& .MuiDialogContent-root": {
          padding: "0px",
          marginBottom: "65px",
          "& p": {
            fontSize: "14px",
            lineHeight: "27px",
            fontWeight: 400,
          },
        },
        "& .createBtn": {
          background: "#88A6A0",
          height: "38px",
          width: "144px !important",
          borderRadius: "116px",
          color: "#FFF",
          margin: "auto",
          "& span": {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 600,
            textTransform: "capitalize",
          },
        },
      },
    },
  })
);

const Card = withStyles(() => ({
  root: {
    width: "50%",
    margin: "auto",
  },
}))(CardBase);

const Message = withStyles(() => ({
  root: {},
}))(Typography);

export default {
  Card,
  CardContent,
  Message,
  Snackbar,
  Alert,
  useStyles,
};
