// Dependencies
import {
  withStyles,
  Accordion,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails,
  Typography,
  IconButton,
  List,
} from "@material-ui/core";

const AccordionItem = withStyles({
  root: {
    listStyle: "none",
    boxShadow: "none !important",
  },
})(Accordion);

const AccordionSummary = withStyles({
  content: {
    alignItems: "center",
  },
})(AccordionSummaryBase);

const Label = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
}))(Typography);

export default {
  AccordionItem,
  AccordionSummary,
  AccordionDetails,
  Label,
  IconButton,
  List,
};
