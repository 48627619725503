import { Avatar, Box, Collapse, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { withStyles } from "@material-ui/styles";

import { Player } from "@lottiefiles/react-lottie-player";

import chatProBkg from "assets/images/chat-pro-bkg.png";

const MessageContainer = withStyles((theme: Theme) => ({
  root: {
    display: "inline-flex",
    width: "fit-content",
    columnGap: "24px",
    "& .message-content": {
      // maxWidth: "903px",
      borderRadius: "23px",
      fontSize: theme.fontGeneralStyles.medium.fontSize,
      lineHeight: theme.fontGeneralStyles.medium.lineHeight,
      padding: "16px",
      color: theme.generalStylesContainer.chat.local.color,
      maxWidth: "calc(100% - 64px)",
      "& .message": {
        display: "flex",
        alignItems: "center",
        columnGap: "20px",
        "& p": {
          wordBreak: "break-all",
          textAlign: "left",
        },
        "& .text": {
          textAlign: "left",
        },
      },
      "& button": {
        padding: "unset",
      },
      [theme.breakpoints.down(540)]: {
        maxWidth: "unset",
      },
    },
    "&.local": {
      alignSelf: "end",
      flexDirection: "row-reverse",
      "& .message-content": {
        backgroundColor:
          theme.generalStylesContainer.layers.secondary.backgroundColor,
      },
    },
    "&.remote": {
      "& .message-content": {
        backgroundColor:
          theme.generalStylesContainer.layers.primary.backgroundColor40,
        color: theme.generalStylesContainer.chat.remote.color,
        border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
        padding: "16px 0px 0px",
        // padding: "0px 18px 16px 22px",
        "& .message": {
          marginBottom: "28px",
          padding: "0px 18px 0px 22px",
        },
        [theme.breakpoints.down(540)]: {
          minWidth: "unset",
        },
      },
      [theme.breakpoints.down(540)]: {
        paddingRight: "20px",
      },
    },
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexDirection: "column",
      rowGap: "14px",
    },
    "&.full-width": {
      "& .message-content": {
        padding: "16px 0px 0px !important",
        "& .medias-container": {
          borderRadius: "unset",
        },
      },
    },
    [theme.breakpoints.down(540)]: {
      columnGap: "14px",
    },
  },
}))(Box);

const ItemAvatar = withStyles(() => ({
  root: {
    "&.remote": {
      border: "2px solid #B8CAC6",
    },
  },
}))(Avatar);

const LikesContainer = withStyles((theme: Theme) => ({
  root: {
    // display: "none",
    width: "100%",
    height: "28px",
    display: "flex",
    justifyContent: "start",
    // marginBottom: "8px",
    columnGap: "12px",
    "& button": {
      color: theme.palette.primary.main,
    },
  },
}))(Box);

const MessageSkeleton = withStyles((theme: Theme) => ({
  root: {
    width: "70%",
  },
}))(Skeleton);

const LoadingMessage = withStyles((theme: Theme) => ({
  root: {
    maxHeight: theme.fontGeneralStyles.large.lineHeight,
    "& svg": {
      maxHeight: theme.fontGeneralStyles.large.lineHeight,
    },
  },
}))(Player);

const MoreMediasButton = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    gap: "15px",
    alignItems: "center",
    padding: "17px 18px 19px 22px",
    "& svg": {
      color: theme.generalStylesContainer.color,
      "&:hover": {
        color: theme.generalStylesContainer.hoverColor,
      },
    },
  },
}))(Box);

const MediasCollapsable = withStyles((theme: Theme) => ({
  root: {
    width: "calc(100% + 2px)",
    marginLeft: "-1px",
    padding: "0px 16px 16px",
    background: "rgba(146, 128, 187, 0.05)",
    borderRadius: "0px 0px 20px 20px",
    "&.chat-pro": {
      border: "1px solid #E6DECD",
      borderTop: "unset",
      // background: theme.generalStylesContainer.chatProBkg,
      backgroundImage: theme.generalStylesContainer.chatProBkgImg,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down(540)]: {
      padding: "0px 10px 10px",
    },
  },
}))(Collapse);

const MoreMediasContainer = withStyles((theme: Theme) => ({
  root: {
    width: "calc(100% + 2px)",
    marginLeft: "-1px",
    cursor: "pointer",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    borderRadius: "0px 0px 22px 22px",
    borderTop: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    "&.chat-pro": {
      background: theme.generalStylesContainer.chatProBkg,
      border: "1px solid #E6DECD",
      borderBottom: "unset",
      "& svg": {
        color: "#E6DECD",
        "&:hover": {
          color: "#E6DECD",
        },
      },
    },
  },
}))(Box);

export default {
  MessageContainer,
  ItemAvatar,
  LikesContainer,
  LoadingMessage,
  MoreMediasButton,
  MediasCollapsable,
  MoreMediasContainer,
};
