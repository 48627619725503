/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Checkbox,
  OutlinedInput,
  Box as BoxMui,
  GridProps,
  BoxProps,
} from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";

const generalStyles = makeStyles((theme) => ({
  profileHeader: {
    display: "flex",
    alignItems: "end",
    paddingBottom: "20px",
    "& .nameLbl": {
      fontSize: "20px",
      lineHeight: "25px",
      fontWeight: "bold",
      textAlign: "left",
    },
    "& .companyLbl": {
      fontSize: "15px",
      lineHeight: "19px",
      fontWeight: "bold",
      color: "#DCDCDC",
      textAlign: "left",
    },
  },
  formContainer: {
    marginTop: "20px",
    marginBottom: "18px",
  },
  subtitle: {
    paddingTop: "18px",
    paddingBottom: "18px",
    fontSize: "20px",
    lineHeight: "25px",
    fontWeight: "bold",
    textAlign: "left",
  },
  hide: {
    display: "none",
  },
  dateField: {
    display: "flex",
    justifyContent: "center",
    border: "1px solid #C6CACC",
    borderRadius: "4px",
    boxSizing: "border-box",
    color: "#6D6D6D",
    margin: "0px",
    height: "56px",
    padding: "18px 12px",
    "& div:before": {
      border: "none",
      borderBottom: "none !important",
    },
    "& div:after": {
      borderBottom: "none !important",
    },
    "& div input": {
      color: "#6D6D6D",
      padding: "0px",
    },
    "& div button": {
      padding: "0px",
    },
    "& fieldset": {
      border: "none",
    },
    "& label": {
      background: "#FFF",
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  emailChecked: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60px",
    width: "100%",
    background: "#77F6C10A",
    border: "2px solid #77F6C1",
    color: "#40DA99",
    marginTop: "32px",
    borderRadius: "6px",
    "& svg": {
      fontSize: "24px",
      marginRight: "10px",
    },
    "& p": {
      color: "#40DA99",
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  footText: {
    color: "#0000008A !important",
    fontWeight: "normal !important" as "normal",
    fontSize: "12px !important",
    lineHeight: "14px !important",
    textAlign: "left",
    marginTop: "5px",
  },
  status: {
    display: "flex",
    "& p": {
      fontSize: "14px !important",
      fontWeight: "normal !important" as "normal",
      lineHeight: "19px !important",
      "&:first-child": {
        color: "#40DA99 !important",
      },
      "&:last-child": {
        color: "#9C9C9C",
      },
    },
    "& .separator": {
      height: "14px",
      background: "#929292",
      width: "1px",
      padding: "0px",
      marginLeft: "13px",
      marginRight: "13px",
    },
  },
  formTitle: {
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",
  },
  description: {
    maxHeight: "auto !important",
    marginTop: "32px",
  },
}));

interface CustomGridProps extends GridProps {
  disabledMarginTop?: boolean;
}

const useGridStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    // backgroundColor: "#FFFFFF",
    marginTop: (props: CustomGridProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(2),
  },
}));

const Grid = (props: CustomGridProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useGridStyles(props);

  return <GridMui className={classes.root} {...muiProps} />;
};

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const useBoxStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props: CustomBoxProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(4),
    maxHeight: "56px",
  },
}));

const Box = (props: CustomBoxProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useBoxStyles(props);

  return <BoxMui className={classes.root} {...muiProps} />;
};

const InputLabel = withStyles({
  root: {
    textAlign: "left",
    transform: "translate(12px, 10px) scale(0.75)",
  },
})(InputLabelMui);

const InputLabelCenter = withStyles({
  root: {
    textAlign: "center",
  },
})(InputLabelMui);

const TextFieldPlan = withStyles(() => ({
  root: {
    height: "100%",
    "& div": {
      height: "100%",
      "& input": {
        width: "100%",
        height: "100%",
        // color: "rgba(0, 0, 0, 0.87)",
        border: "none",
        padding: "17px 13px 19px",
        "&:focus-visible": {
          outline: "none !important",
        },
      },
    },
  },
}))(TextField);

export default {
  Grid,
  InputLabel,
  InputLabelCenter,
  Box,
  OutlinedInput,
  Checkbox,
  TextFieldPlan,
  generalStyles,
};
