import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SnackbarCloseReason,
  Typography,
} from "@material-ui/core";
import {
  ErrorBoundary as ErrorBoundaryBase,
  FallbackProps,
} from "react-error-boundary";
import { logOut } from "graphql/authentication";

// Errors
import {
  ServerErrorCodes,
  useAppError,
  useAppErrorHandler,
} from "errors/app.errors";

// Assets
import { NetworkErrorCodes } from "errors/network.errors";
import SC from "./error-boundary.styles";

const ErrorBoundary: React.FC = ({ children }) => {
  const localClasses = SC.useStyles();
  const appErrorData = useAppError();
  const appErrorHandler = useAppErrorHandler();

  const [openAdviseTokenExpired, setOpenAdviseTokenExpired] =
    React.useState(false);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: SnackbarCloseReason): void => {
      if (reason === "clickaway") return;
      appErrorHandler(undefined);
    },
    [appErrorHandler]
  );

  const fallbackRenderHandler = React.useCallback(
    ({ error, resetErrorBoundary }: FallbackProps) => (
      // <SC.Card>
      //   <SC.CardContent>
      //     <SC.Message>
      //       Please contact support@audionaut.ai for assistance.
      //     </SC.Message>
      //   </SC.CardContent>
      // </SC.Card>
      <SC.Snackbar open onClose={handleCloseSnack}>
        <SC.Alert
          severity="error"
          message="Please contact corporatus@datyra.com for assistance"
          onClose={handleCloseSnack}
        />
      </SC.Snackbar>
    ),
    [handleCloseSnack]
  );

  const handleCloseAdvise = (response: any) => {
    appErrorHandler(undefined);
    setOpenAdviseTokenExpired(false);
  };

  React.useEffect(() => {
    if (appErrorData) {
      const { code } = appErrorData;
      if (
        (code === ServerErrorCodes.INVALID_JWT ||
          code === NetworkErrorCodes.HTTP_UNAUTHORIZED) &&
        !openAdviseTokenExpired
      ) {
        logOut();
        setOpenAdviseTokenExpired(true);
      }
    }
  }, [appErrorData, openAdviseTokenExpired, appErrorHandler]);

  return (
    <ErrorBoundaryBase fallbackRender={fallbackRenderHandler}>
      {children}
      <SC.Snackbar
        open={
          !!appErrorData &&
          appErrorData.code !== ServerErrorCodes.INVALID_JWT &&
          appErrorData.code !== NetworkErrorCodes.HTTP_UNAUTHORIZED
        }
        onClose={handleCloseSnack}
      >
        <SC.Alert
          severity="error"
          message={appErrorData?.message}
          onClose={handleCloseSnack}
        />
      </SC.Snackbar>

      <Dialog
        open={openAdviseTokenExpired}
        onClose={handleCloseAdvise}
        aria-labelledby="form-dialog-title"
        className={localClasses.modalCustom}
      >
        <Box component="div">
          <DialogTitle className="title">
            Oops! Looks like your session timed out.
          </DialogTitle>
          <DialogContent>
            <Typography>Please Sign in again. Thank you!</Typography>
          </DialogContent>
          <DialogActions className="buttons">
            <Button className="createBtn" onClick={handleCloseAdvise}>
              Exit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ErrorBoundaryBase>
  );
};

export default ErrorBoundary;
