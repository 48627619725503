import React from "react";

// Assets
import SC from "./reset-password-form.styles";

interface ResetPasswordFormProps {
  handleSubmit: any;
  credentials: any;
  handleOnChange: any;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { handleSubmit, credentials, handleOnChange } = props;

  const [showPassword1, setShowPassword1] = React.useState<boolean>(false);
  const [showPassword2, setShowPassword2] = React.useState<boolean>(false);

  const [formStates, setFormState] = React.useState<any>({
    newPassword: {
      touched: false,
      dirty: false,
      isValid: false,
      errorMsg: "",
    },
    confirmPassword: {
      touched: false,
      dirty: false,
      isValid: false,
      errorMsg: "",
    },
  });

  const validateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const { [name]: currentField } = formStates;

    currentField.errorMsg = "";

    switch (name) {
      case "newPassword":
        currentField.dirty = true;
        if (value) {
          const digitReg = /(?=.*\d)/;
          const charsReg = /((?=.*\W){1})/;
          // const charsReg = /((?=.*[@#$%^&+=]){1})/;

          currentField.isValid =
            value.length > 7 && digitReg.test(value) && charsReg.test(value);

          currentField.errorMsg = currentField.errorMsg.concat(
            !(value.length > 7) ? "Must be at least 8 characters long and " : ""
          );
          currentField.errorMsg = currentField.errorMsg.concat(
            !digitReg.test(value) ? "must contain at least one number and " : ""
          );
          currentField.errorMsg = currentField.errorMsg.concat(
            !charsReg.test(value) ? "at least one special character" : ""
          );

          if (formStates.confirmPassword.touched) {
            formStates.confirmPassword.isValid =
              value === credentials.confirmPassword;
            formStates.confirmPassword.errorMsg =
              value !== credentials.confirmPassword
                ? "Passwords Must Match"
                : "";
          }
        } else {
          currentField.isValid = false;
          currentField.errorMsg = "Password field is required";
        }
        break;
      case "confirmPassword":
        currentField.dirty = true;
        currentField.isValid = value === credentials.newPassword;
        currentField.errorMsg =
          value !== credentials.newPassword ? "Passwords Must Match" : "";
        break;
      default:
        break;
    }

    setFormState((oldFormState: any) => ({
      ...oldFormState,
      [name]: currentField,
    }));
  };

  const handleClickShowPassword = (index: number) => {
    if (index === 1) {
      setShowPassword1(!showPassword1);
    } else {
      setShowPassword2(!showPassword2);
    }
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <SC.Form onSubmit={handleSubmit}>
      <SC.Text>
        Your new password must be different to previous used passwords
      </SC.Text>
      <SC.PasswordField
        autoFocus
        name="newPassword"
        label="New Password"
        type={showPassword1 ? "text" : "password"}
        fullWidth
        variant="outlined"
        value={credentials.newPassword}
        InputProps={{
          endAdornment: (
            <SC.PasswordInputAdornment position="start">
              <SC.IconButton
                onClick={() => {
                  handleClickShowPassword(1);
                }}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword1 ? (
                  <SC.VisibilityOffIcon />
                ) : (
                  <SC.VisibilityIcon />
                )}
              </SC.IconButton>
            </SC.PasswordInputAdornment>
          ),
        }}
        onChange={(event: any) => {
          handleOnChange(event);
          validateField(event);
        }}
        onFocus={() => {
          formStates.newPassword.touched = true;
        }}
        helperText={
          formStates.newPassword.touched &&
          formStates.newPassword.dirty &&
          !formStates.newPassword.isValid
            ? formStates.newPassword.errorMsg
            : ""
        }
        required
      />

      {/* <Typography className={localClasses.passwordRules}>
        Must be at least 8 characters long and must contain at least one number
        and at least one special character
      </Typography> */}
      <SC.ConfirmPasswordField
        autoFocus
        name="confirmPassword"
        label="Confirm Password"
        type={showPassword2 ? "text" : "password"}
        fullWidth
        variant="outlined"
        value={credentials.confirmPassword}
        InputProps={{
          endAdornment: (
            <SC.PasswordInputAdornment position="start">
              <SC.IconButton
                onClick={() => {
                  handleClickShowPassword(2);
                }}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword2 ? (
                  <SC.VisibilityOffIcon />
                ) : (
                  <SC.VisibilityIcon />
                )}
              </SC.IconButton>
            </SC.PasswordInputAdornment>
          ),
        }}
        onChange={(event: any) => {
          handleOnChange(event);
          validateField(event);
        }}
        onFocus={() => {
          formStates.confirmPassword.touched = true;
        }}
        helperText={
          formStates.confirmPassword.touched &&
          formStates.confirmPassword.dirty &&
          !formStates.confirmPassword.isValid
            ? formStates.confirmPassword.errorMsg
            : ""
        }
        required
      />
      <SC.DividerMobile component="div" />
      <SC.Button
        type="submit"
        color="primary"
        disabled={
          !formStates.newPassword.isValid || !formStates.confirmPassword.isValid
        }
      >
        Reset Password
      </SC.Button>
    </SC.Form>
  );
};

export default ResetPasswordForm;
