/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  makeStyles,
  withStyles,
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Box as BoxMui,
  GridProps,
  BoxProps,
  AppBar as AppBarMui,
  Tab as TabMui,
  TabProps,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core/";

interface CustomGridProps extends GridProps {
  MarginTop?: number;
  scrollable?: boolean;
  padding?: number;
}

const useGridStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: (props: CustomGridProps) =>
      props.MarginTop || props.MarginTop === 0
        ? props.MarginTop
        : theme.spacing(2),
    overflow: (props: CustomGridProps) => (props.scrollable ? "auto" : "unset"),
    padding: (props: CustomGridProps) =>
      props.padding ? props.padding : "unset",
  },
}));

const Grid = (props: CustomGridProps): JSX.Element => {
  const { MarginTop, scrollable, padding, ...muiProps } = props;
  const classes = useGridStyles(props);

  return <GridMui className={classes.root} {...muiProps} />;
};

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const useBoxStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props: CustomBoxProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(4),
  },
}));

const Box = (props: CustomBoxProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useBoxStyles(props);

  return <BoxMui className={classes.root} {...muiProps} />;
};

interface CustomTabProps extends TabProps {
  isselected?: boolean;
}

const useTabStyles = makeStyles(() => ({
  root: {
    backgroundColor: (props: CustomTabProps) =>
      props.isselected ? "lightgray" : "inherit",
  },
}));

const Tab = (props: CustomTabProps): JSX.Element => {
  const { isselected, ...muiProps } = props;
  const classes = useTabStyles(props);

  return <TabMui className={classes.root} {...muiProps} />;
};

const InputLabel = withStyles({
  root: {
    textAlign: "left",
    // transform: "translate(12px, 10px) scale(1)",
  },
})(InputLabelMui);

const AppBar = withStyles({
  root: {
    width: 320,
    boxShadow: "none",
  },
})(AppBarMui);

const DescriptionBox = withStyles({
  root: {
    marginTop: 0,
    height: "100%",
  },
})(BoxMui);

export default {
  Grid,
  Box,
  InputLabel,
  AppBar,
  Tab,
  FormControlLabel,
  Radio,
  RadioGroup,
  DescriptionBox,
};
