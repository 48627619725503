import React from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  downloadImage,
  getImageCaption,
} from "graphql/corporatus/corporatus_rest_graphql";
import { CLIENT_NAME } from "graphql/client";

import SC from "./generic-image.styles";

const { REACT_APP_API_REST_CORPORATUS_SERVER_URL } = process.env;

interface GenericImageInterface {
  title: string;
  url: string;
  key: string;
  itemClicked: () => void;
  height?: number;
  overlay?: boolean;
}

const GenericImage: React.FC<GenericImageInterface> = ({
  title,
  url,
  key,
  itemClicked,
  height = 140,
  overlay,
}) => {
  const [loadingImage, setLoadingImage] = React.useState<boolean>(true);

  const [caption, setCaption] = React.useState<string>("");
  const [imageSafeUrl, setImageSafeUrl] = React.useState<string>("");

  const [
    getImageCaptionRequest,
    {
      data: imageCaptionData,
      loading: imageCaptionLoading,
      error: imageCaptionError,
    },
  ] = useMutation(getImageCaption, {
    context: {
      clientName: CLIENT_NAME.CORPORATUSAPIREST,
      noAuthNeeded: true,
    },
  });

  const [
    downloadImageRequest,
    {
      data: downloadImageData,
      loading: downloadImageLoading,
      error: downloadImageError,
    },
  ] = useMutation(downloadImage, {
    fetchPolicy: "no-cache",
    context: {
      clientName: CLIENT_NAME.CORPORATUSAPIREST,
      noAuthNeeded: true,
    },
  });

  // React.useEffect(() => {
  //   if (url) {
  //     console.debug("=====> Get image caption: ", url, caption, imageSafeUrl);
  //     if (!caption) {
  //       getImageCaptionRequest({
  //         variables: {
  //           imagePath: url,
  //         },
  //       });
  //     }

  //     if (!imageSafeUrl) {
  //       try {
  //         fetch(
  //           `${REACT_APP_API_REST_CORPORATUS_SERVER_URL}/captions/get_image?minio_object_name=corporatus/${url}`,
  //           {
  //             method: "GET",
  //             headers: {
  //               "Content-Type": "image/jpeg",
  //             },
  //           }
  //         )
  //           .then((response) => response.blob())
  //           .then((blob) => {
  //             // console.debug("=====> RESPONSE: ", blob);
  //             // // Create blob link to download
  //             setImageSafeUrl(window.URL.createObjectURL(new Blob([blob])));
  //             setLoadingImage(false);
  //             // const link = document.createElement('a');
  //             // link.href = url;
  //             // link.setAttribute(
  //             //   'download',
  //             //   `FileName.pdf`,
  //             // );

  //             // // Append to html link element page
  //             // document.body.appendChild(link);

  //             // // Start download
  //             // link.click();

  //             // // Clean up and remove the link
  //             // link.parentNode.removeChild(link);
  //           });
  //       } catch (error) {
  //         setLoadingImage(false);
  //         console.error("=====> Error al obtener imagen: ", error);
  //       }
  //     }
  //   }
  // }, [getImageCaptionRequest, url, caption, imageSafeUrl]);

  React.useEffect(() => {
    // console.debug("=====> imageCaption: ", imageCaptionData);
    if (
      imageCaptionData &&
      imageCaptionData.imageCaption &&
      imageCaptionData.imageCaption.caption
    ) {
      setCaption(imageCaptionData.imageCaption.caption);
    }
  }, [imageCaptionData]);

  // React.useEffect(() => {
  //   // if (imageCaptionData) {
  //   //   // console.debug("=====> imageCaption: ", imageCaptionData);
  //   //   setCaption(imageCaptionData.imageCaption.caption.caption);
  //   // }
  //   console.debug("=====> image: ", downloadImageData);
  //   if (!downloadImageData) {
  //     downloadImageRequest({
  //       variables: {
  //         imagePath: `corporatus/${url}`,
  //       },
  //     });
  //   }
  // }, [url, downloadImageRequest, downloadImageData]);

  return (
    <SC.ImageContainer key={key} onClick={itemClicked}>
      {/* <CardActionArea> */}
      {!url ? (
        <Skeleton variant="rect" width="100%" height={118} />
      ) : (
        <CardMedia
          component="img"
          alt={`${title}-image`}
          height={height}
          image={url}
          title={title}
          className="image-component"
        />
      )}
      <SC.OverlayTitle hidden={!overlay || !url}>
        <Typography component="p" color="primary" className="caption-text">
          {title}
        </Typography>
      </SC.OverlayTitle>
      <SC.DescriptionContent hidden={overlay}>
        {/* <Typography gutterBottom variant="p">
            {title}
          </Typography> */}
        {!title ? (
          <Box>
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        ) : (
          <Typography component="p" color="primary" className="caption-text">
            {title}
          </Typography>
        )}
      </SC.DescriptionContent>
      {/* </CardActionArea> */}
    </SC.ImageContainer>
  );
};

export default GenericImage;
