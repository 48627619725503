/* eslint-disable prettier/prettier */
import React from "react";
import TagManager from "react-gtm-module";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { Redirect } from "react-router-dom";
import { useCreateOrganizationMutation } from "graphql/types-and-hooks";
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import { Snackbar } from "@material-ui/core";
import { Alert, AlertProps } from "components/alert/alert.component";

import SC from "pages/signin/signin.styles";
import AuthContainer from "components/auth-container/auth-container.component";

import SignupForm from "./signup-form.component";
import VerifyEmail from "./signup-verify-email.component";

export type UserInfoModel = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
};

export type OrganizationInfoModel = {
  name: string;
  type: string;
  phone: string;
};

export const SignupScreen: React.FC = () => {
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [emailSent, setEmailSent] = React.useState<boolean>(false);

  // const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [userInfo, setUserInfo] = React.useState<UserInfoModel>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [organizationInfo, setOrganizationInfo] =
    React.useState<OrganizationInfoModel>({
      name: "",
      type: "",
      phone: "",
    });

  const [
    createOrganization,
    {
      loading: createTargetsGroupLoading,
      error: signupError,
      data: signupResponse,
    },
  ] = useCreateOrganizationMutation({
    fetchPolicy: "no-cache",
    context: {
      noAuthNeeded: true,
    },
  });

  const errorHandler = useAppErrorHandler();

  const handleUserInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    let target: { name; value };

    if (event && !event.target) {
      target = {
        name: "phone",
        value: event,
      };
    } else {
      target = event.target;
    }
    const { name, value } = target;
    setUserInfo((oldUserInfo) => ({
      ...oldUserInfo,
      [name]: value,
    }));
  };

  const handleOrganizationInfo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let target: { name; value };

    if (event && !event.target) {
      target = {
        name: "phone",
        value: event,
      };
    } else {
      target = event.target;
    }

    const { name, value } = target;
    setOrganizationInfo((oldOrganizationInfo) => ({
      ...oldOrganizationInfo,
      [name]: value,
    }));
  };

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const onSubmit = React.useCallback(async () => {
    try {
      errorHandler();
      createOrganization({
        variables: {
          name: userInfo.firstname,
          email: userInfo.email,
          phone: userInfo.phone,
          password: userInfo.password,
        },
      });
    } catch (error: any) {
      TagManager.dataLayer({
        dataLayer: {
          event: "userRegisterFail",
        },
      });

      if (isServerError(error)) {
        errorHandler(error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while creating the organization"
          )
        );
      }
    }
  }, [createOrganization, userInfo, errorHandler]);

  React.useEffect(() => {
    if (signupResponse) {
      // console.debug(signupResponse);
      if (signupResponse.createOrganizationAccount) {
        TagManager.dataLayer({
          dataLayer: {
            event: "userRegistered",
          },
        });

        const { id } = signupResponse.createOrganizationAccount as {
          id: string;
          email: string;
        };
        setEmailSent(!!id);
      }

      setSnackBarMessage({
        message: "Account created successfull, Can you do login now!",
        severity: "success",
      });

      if (signupError) {
        TagManager.dataLayer({
          dataLayer: {
            event: "userRegisterFail",
          },
        });
      }
    }
  }, [signupResponse, signupError]);

  return (
    <AuthContainer
      title={!emailSent ? "Sign up" : "Verify your email"}
      content={
        !emailSent ? (
          <SignupForm
            handleSubmit={onSubmit}
            userInfo={userInfo}
            organizationInfo={organizationInfo}
            handleUserInfoChanged={handleUserInfo}
            handleOrganizationInfoChanged={handleOrganizationInfo}
          />
        ) : (
          <VerifyEmail />
        )
      }
      loading={createTargetsGroupLoading}
    >
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
    </AuthContainer>
  );
};

export default SignupScreen;
