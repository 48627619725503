// Dependencies
import { withStyles, Paper } from "@material-ui/core";

const Pane = withStyles((theme) => ({
  root: {
    backgroundColor: theme.pane.backgroundColor,
    padding: theme.spacing(4), // = 8px * 4
  },
}))(Paper);

export default {
  Pane,
};
