import React from "react";

import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Grid,
  Collapse,
  IconButton,
} from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { Alert, AlertProps } from "components/alert/alert.component";

import PlansCouponsFormContainer from "components/plans-coupons-form/plans-coupons-form.container";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";

import {
  useGetAudionautPlansQuery,
  useCreateAudionautPlanMutation,
  useEditAudionautPlanByIdMutation,
  AudionautPlan,
} from "graphql/types-and-hooks";

import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import {
  PlanModel,
  CreatePlanModel,
  UpdatePlanModel,
} from "components/plans-coupons-form-fields/data/models/plans-model";
import { createEmptyPlan } from "graphql/audionaut.utils";
import { isMobileResolution } from "commons/utils/device-info.util";

// import { Reference, StoreObject } from "@apollo/client";

// // Utils
// import { useAppErrorHandler } from "errors/app.errors";

import SC from "./plans-discounts.styles";

const PlansDiscountsPage: React.FC = () => {
  const classes = SC.useStyles();

  const {
    data: currentPlans,
    loading: plansLoading,
    error: plansError,
    refetch: currentPlansRefetch,
  } = useGetAudionautPlansQuery({
    fetchPolicy: "no-cache",
  });

  const [
    createAudionautPlan,
    { loading: createPlanLoading, error: createPlanError },
  ] = useCreateAudionautPlanMutation();

  const [
    editAudionautPlan,
    { loading: editPlanLoading, error: editPlanError },
  ] = useEditAudionautPlanByIdMutation();

  const loading = plansLoading || createPlanLoading || editPlanLoading;

  const errorHandler = useAppErrorHandler(
    plansError || createPlanError || editPlanError
  );

  const AudionautPlans = React.useMemo(
    () =>
      currentPlans?.getAudionautPlans &&
      currentPlans.getAudionautPlans.length > 0
        ? currentPlans.getAudionautPlans
        : [],
    [currentPlans]
  );

  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [expandedPlan, setExpandedPlan] = React.useState(false);
  // const [expandedCoupon, setExpandedCoupon] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [planCouponToggleActivePlan, setPlanCouponToggleActivePlan] =
    React.useState<PlanModel | null>(null);
  const [editableForm, setEditableForm] = React.useState(false);

  const [planToEdit, setPlanToEdit] = React.useState<PlanModel | AudionautPlan>(
    createEmptyPlan()
  );

  const handleClickToggleActivePlan = (planCoupon?: PlanModel) => {
    if (planCoupon) setPlanCouponToggleActivePlan(planCoupon);
    setOpenDelete(!openDelete);
  };
  const handleToggleActivePlan = async () => {
    // setOpen(false);
    const currentData: UpdatePlanModel = {
      ...(planCouponToggleActivePlan as UpdatePlanModel),
      active: !planCouponToggleActivePlan?.active,
    };

    await editAudionautPlan({
      variables: currentData,
    });

    setExpandedPlan(false);
    currentPlansRefetch();

    setSnackBarMessage({
      message: `The plan has been ${
        currentData.active ? "activate" : "deactivate"
      } successfully`,
      severity: "warning",
    });

    setPlanCouponToggleActivePlan(null);

    handleClickToggleActivePlan();
  };

  const handleExpandPlanClick = () => {
    setExpandedPlan(!expandedPlan);
  };
  // const handleExpandCouponClick = () => {
  //   setExpandedCoupon(!expandedCoupon);
  // };

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handlerCreatePlansCouponsFormOnSubmit = React.useCallback(
    async (plansCouponsData: CreatePlanModel | any) => {
      const currentData: CreatePlanModel = {
        ...(plansCouponsData as CreatePlanModel),
        active: true,
        trialPeriodNumOfDays: plansCouponsData.trialPeriodNumOfDays ?? 0,
      };

      try {
        const response = await createAudionautPlan({
          variables: currentData,
        });

        setExpandedPlan(false);
        currentPlansRefetch();

        setSnackBarMessage({
          message: "The plan has been created successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the plan subscription"
            )
          );
        }
      }
    },
    [createAudionautPlan, setExpandedPlan, currentPlansRefetch, errorHandler]
  );

  const handlerEditPlansCouponsFormOnSubmit = React.useCallback(
    async (plansCouponsData: PlanModel | any) => {
      const currentData: UpdatePlanModel = {
        ...(plansCouponsData as UpdatePlanModel),
        trialPeriodNumOfDays: plansCouponsData.trialPeriodNumOfDays ?? 0,
      };

      const response = await editAudionautPlan({
        variables: currentData,
      });

      setExpandedPlan(false);
      currentPlansRefetch();
    },
    [editAudionautPlan, setExpandedPlan, currentPlansRefetch]
  );

  return (
    <SC.TGContainer>
      <ProgressIndicator open={loading} />
      <SC.TGContent>
        <Snackbar
          open={!!snackBarMessage}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackBarMessage?.severity}
            message={snackBarMessage?.message}
          />
        </Snackbar>
        <SC.TGHeader component="div">
          <Box
            hidden={expandedPlan && isMobileResolution()}
            component="div"
            display="flex"
            flex="1"
          >
            <Typography variant="h1" color="primary">
              Plans
            </Typography>
          </Box>
          <Box hidden={!expandedPlan || !isMobileResolution()}>
            <Typography variant="h1" color="primary">
              <IconButton
                aria-label="back"
                size="small"
                onClick={() => {
                  setExpandedPlan(false);
                }}
              >
                <ArrowBackIosIcon color="primary" />
              </IconButton>
              {planToEdit.id ? "Edit " : "New "}
              Plan
            </Typography>
          </Box>
        </SC.TGHeader>
        <SC.TGBody component="div" key="groups-table">
          <Box>
            {!isMobileResolution() ? (
              <Typography
                variant="h1"
                className={classes.sectionTitle}
                color="primary"
              >
                Plans
              </Typography>
            ) : null}
            <Box hidden={expandedPlan}>
              {isMobileResolution() ? (
                <>
                  <SC.PlanItem
                    editItem={() => {
                      setEditableForm(true);
                      setPlanToEdit(createEmptyPlan());
                      handleExpandPlanClick();
                    }}
                  />
                  <br />
                </>
              ) : null}
              {isMobileResolution() ? (
                <CarouselGeneric
                  type="plans"
                  items={AudionautPlans?.map((plan: any) => (
                    <SC.PlanItem
                      planData={plan as AudionautPlan}
                      editItem={() => {
                        setPlanToEdit(plan);
                        handleExpandPlanClick();
                      }}
                      toggleActiveItem={handleClickToggleActivePlan}
                    />
                  ))}
                  hideDots={false}
                  customPaddingLeft={20}
                  customPaddingRight={20}
                />
              ) : (
                <Grid container spacing={3} xs={12} md={12} lg={12}>
                  {/* <Grid item lg={4} md={6} xs={12}> */}
                  {currentPlans?.getAudionautPlans &&
                  currentPlans.getAudionautPlans.length > 0
                    ? currentPlans?.getAudionautPlans.map((plan) => (
                        <Grid
                          key={`${plan?.id}-item`}
                          item
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <SC.PlanItem
                            planData={plan as AudionautPlan}
                            editItem={() => {
                              setPlanToEdit(plan!);
                              handleExpandPlanClick();
                            }}
                            toggleActiveItem={handleClickToggleActivePlan}
                          />
                        </Grid>
                      ))
                    : null}
                  <Grid key="createPlan-item" item lg={4} md={6} xs={12}>
                    <SC.PlanItem
                      editItem={() => {
                        setEditableForm(true);
                        setPlanToEdit(createEmptyPlan());
                        handleExpandPlanClick();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
            <Collapse in={expandedPlan} timeout="auto" unmountOnExit>
              <PlansCouponsFormContainer
                initialItemData={planToEdit}
                initialEditable={editableForm}
                onSubmit={
                  !planToEdit.id
                    ? handlerCreatePlansCouponsFormOnSubmit
                    : handlerEditPlansCouponsFormOnSubmit
                }
                onCancel={() => {
                  setExpandedPlan(false);
                }}
                isPlan
              />
            </Collapse>
          </Box>
          {/* <Box>
            <Typography className={classes.sectionTitle}>Discount</Typography>
            <Grid container spacing={3} xs={12}>
              <Grid key="item1" item xs={4}>
                <SC.CouponItem
                  couponData={{}}
                  editItem={handleExpandCouponClick}
                />
              </Grid>
              <Grid key="item2" item xs={4}>
                <SC.CouponItem editItem={handleExpandCouponClick} />
              </Grid>
            </Grid>
            <Collapse in={expandedCoupon} timeout="auto" unmountOnExit>
              <PlansCouponsFormContainer
                initialUserData={{}}
                initialEditable={false}
                onSubmit={handlerEditPlansCouponsFormOnSubmit}
                onCancel={() => null}
                isPlan={false}
              />
            </Collapse>
          </Box> */}
        </SC.TGBody>
      </SC.TGContent>
      <Dialog
        id="delete-dialog"
        open={openDelete}
        onClose={() => {
          handleClickToggleActivePlan();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${
          planCouponToggleActivePlan?.active ? "Deactivate" : "Activate"
        } Item`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you want ${
              planCouponToggleActivePlan?.active ? "deactivate" : "activate"
            } item?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SC.ButtonSecondary
            onClick={() => {
              handleClickToggleActivePlan();
            }}
          >
            Cancel
          </SC.ButtonSecondary>
          <SC.ButtonPrimary onClick={handleToggleActivePlan} autoFocus>
            {`${
              planCouponToggleActivePlan?.active ? "Deactivate" : "Activate"
            }`}
          </SC.ButtonPrimary>
        </DialogActions>
      </Dialog>
    </SC.TGContainer>
  );
};

export default PlansDiscountsPage;
