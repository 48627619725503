import React from "react";

import SC from "./chat-date-divider.styles";

interface ChatDateDividerInterface {
  element: string | React.ReactElement;
}

const ChatDivider: React.FC<ChatDateDividerInterface> = ({ element }) => {
  return (
    <SC.Container>
      <SC.CustomDivider />
      <SC.DateLabel>{element}</SC.DateLabel>
      <SC.CustomDivider />
    </SC.Container>
  );
};

export default ChatDivider;
