import React from "react";
import { Box, Grid, Link, Typography, Paper, Slide } from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Data temp just for test filter
import {
  useGetAccountUsageReportQuery,
  useGetAccountUsageReportLazyQuery,
  SubscriptionUsage,
  useGetCurrentUserProfileQuery,
} from "graphql/types-and-hooks";
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";
// End temp data

import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import { isMobileResolution } from "commons/utils/device-info.util";

import { getFormattedDate } from "commons/utils/date-format";

import AudioIcon from "assets/icons/audio_resume_icon.png";
import SentencesIcon from "assets/icons/sentences_resume_icon.png";
import EntitiesIcon from "assets/icons/entities_resume_icon.png";

import { HandleGlobalEvents } from "../../commons/utils/handle-global-events";

import SC from "./summary-statistics.styles";

const { REACT_APP_API_REST_CORPORATUS_SERVER_URL } = process.env;
const { REACT_APP_API_REST_CORPORATUS_APIKEY } = process.env;

// const sentimentsStatics: any = {
//   disgust: {
//     label: "Disgust",
//     color: "#FE6377",
//   },
//   anger: {
//     label: "Anger",
//     color: "#FF2958",
//   },
//   fear: {
//     label: "Fear",
//     color: "#FD7B84",
//   },
//   sadness: {
//     label: "Sadness",
//     color: "#FCA29A",
//   },
//   anticipation: {
//     label: "Anticipation",
//     color: "#8DE9CB",
//   },
//   surprise: {
//     label: "Surprice",
//     color: "#9DF0E9",
//   },
//   trust: {
//     label: "Trust",
//     color: "#86EBC2",
//   },
//   joy: {
//     label: "Joy",
//     color: "#96E8D7",
//   },
// };

const SubscriptionUsagePage: React.FC = () => {
  const classes = SC.useStyles();

  const [startDate, setStartDate] = React.useState<Date>(() => {
    const pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - (pastDate.getDate() - 1));
    return pastDate;
  });
  const [endDate, setEndDate] = React.useState<Date>(new Date());

  const [showDetail1, setShowDetail1] = React.useState(false);
  const [showDetail2, setShowDetail2] = React.useState(false);

  const [generalInfo, setGeneralInfo] = React.useState<SubscriptionUsage>({
    totalDurationOfAudioIngestedInSec: 0,
    totalEntitiesInTheAudioIngested: 0,
    totalWordsInTheAudioIngested: 0,
    // Sentiments: {
    //   sentiments: {},
    // },
    // ROI: {
    //   sentiments: {},
    // },
  });
  // const [sentimentsData, setSentimentsData] = React.useState({
  //   labels: [],
  //   values: [],
  // });
  // const [ROIData, setROIData] = React.useState({
  //   labels: [],
  //   values: [],
  // });
  const [initSummaryData, setInitSummaryData] = React.useState(false);

  const [handleGobalEventsInstance, setHandleGobalEventsInstance] =
    React.useState<any>(null);

  const { data } = useGetCurrentUserProfileQuery();
  const initialUserData = data?.GetCurrentUserProfile;

  const [
    getAccountUsageReportData,
    {
      data: subscriptionUsageData,
      loading: subscriptionUsageLoading,
      error: subscriptionUsageError,
    },
  ] = useGetAccountUsageReportLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      startsAt: `${getFormattedDate(
        startDate.toDateString(),
        "YYYY-MM-DD"
      )} 00:00:00`,
      endsAt: `${getFormattedDate(
        endDate.toDateString(),
        "YYYY-MM-DD"
      )} 23:59:00`,
    },
  });

  const {
    data: subscriptionUsageDataOnce,
    loading: subscriptionUsageLoadingOnce,
    error: subscriptionUsageErrorOnce,
  } = useGetAccountUsageReportQuery({
    variables: {
      startsAt: `${getFormattedDate(
        startDate.toDateString(),
        "YYYY-MM-DD"
      )} 00:00:00`,
      endsAt: `${getFormattedDate(
        endDate.toDateString(),
        "YYYY-MM-DD"
      )} 23:59:00`,
    },
  });

  // const subscriptionUsageResponse: SubscriptionUsage = React.useMemo(
  //   () =>
  //     (subscriptionUsageDataOnce?.getAccountUsageReport ||
  //       subscriptionUsageData?.getAccountUsageReport) ?? {
  //       audio: "",
  //       sentences: "",
  //       entities: "",
  //       Sentiments: {
  //         sentiments: {},
  //       },
  //       ROI: {
  //         sentiments: {},
  //       },
  //     },
  //   [subscriptionUsageDataOnce, subscriptionUsageData]
  // );
  // const targetsGroupData = GetAllTargetsGroupsDocument();

  const errorHandler = useAppErrorHandler(
    subscriptionUsageError || subscriptionUsageErrorOnce
  );
  const loading = !initSummaryData
    ? subscriptionUsageLoadingOnce
    : subscriptionUsageLoading;

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date && endDate) {
      if (new Date(date).getTime() >= new Date(endDate).getTime()) {
        const futureDate = new Date(date);
        futureDate.setDate(futureDate.getDate() + 1);
        setEndDate(futureDate);
      }
    }
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    if (date && startDate) {
      if (new Date(date).getTime() <= new Date(startDate).getTime()) {
        const pastDate = new Date(date);
        pastDate.setDate(pastDate.getDate() - 1);
        setStartDate(pastDate);
      }
    }
  };

  // const handleShowMore1 = () => {
  //   setShowDetail1(!showDetail1);
  // };
  // const handleShowMore2 = () => {
  //   setShowDetail2(!showDetail2);
  // };

  const handleClickUpdateSummary: React.FormEventHandler =
    React.useCallback(() => {
      try {
        getAccountUsageReportData({
          variables: {
            startsAt: `${getFormattedDate(
              startDate.toDateString(),
              "YYYY-MM-DD"
            )} 00:00:00`,
            endsAt: `${getFormattedDate(
              endDate.toDateString(),
              "YYYY-MM-DD"
            )} 23:59:00`,
          },
        });
      } catch (error) {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while adding new target"
          )
        );
      }
    }, [getAccountUsageReportData, endDate, startDate, errorHandler]);

  React.useEffect(() => {
    if (!initSummaryData) {
      let subscriptionUsageResponse: SubscriptionUsage = {
        totalDurationOfAudioIngestedInSec: 0,
        totalEntitiesInTheAudioIngested: 0,
        totalWordsInTheAudioIngested: 0,
        // Sentiments: {
        //   sentiments: {},
        // },
        // ROI: {
        //   sentiments: {},
        // },
      };

      if (
        subscriptionUsageDataOnce &&
        subscriptionUsageDataOnce.getAccountUsageReport
      ) {
        subscriptionUsageResponse =
          subscriptionUsageDataOnce.getAccountUsageReport;
        setInitSummaryData(true);
      }

      // if (isValidQuery) {
      setGeneralInfo(subscriptionUsageResponse);
      // if (subscriptionUsageResponse.Sentiments) {
      //   const { sentiments }: any = subscriptionUsageResponse.Sentiments;
      //   if (sentiments) {
      //     const labelsTemp: any = [];
      //     const valuesTemp: any = [];
      //     Object.keys(sentimentsStatics).forEach((key: string) => {
      //       labelsTemp.push(key);
      //       valuesTemp.push({
      //         value: sentiments[key],
      //         itemStyle: {
      //           color: sentimentsStatics[key].color,
      //         },
      //       });
      //       setSentimentsData({
      //         labels: labelsTemp,
      //         values: valuesTemp,
      //       });
      //     });
      //   }
      // }
      // if (subscriptionUsageResponse.ROI) {
      //   const { sentiments }: any = subscriptionUsageResponse.ROI;
      //   if (sentiments) {
      //     const labelsTemp: any = [];
      //     const valuesTemp: any = [];
      //     Object.keys(sentimentsStatics).forEach((key: any) => {
      //       labelsTemp.push(key);
      //       valuesTemp.push({
      //         value: sentiments[key],
      //         itemStyle: {
      //           color: sentimentsStatics[key].color,
      //         },
      //       });
      //       setROIData({
      //         labels: labelsTemp,
      //         values: valuesTemp,
      //       });
      //     });
      //   }
      // }
      // }
    }
  }, [setGeneralInfo, subscriptionUsageDataOnce, initSummaryData]);

  React.useEffect(() => {
    let subscriptionUsageResponse: SubscriptionUsage = {
      totalDurationOfAudioIngestedInSec: 0,
      totalEntitiesInTheAudioIngested: 0,
      totalWordsInTheAudioIngested: 0,
      // Sentiments: {
      //   sentiments: {},
      // },
      // ROI: {
      //   sentiments: {},
      // },
    };

    if (subscriptionUsageData && subscriptionUsageData.getAccountUsageReport) {
      subscriptionUsageResponse = subscriptionUsageData.getAccountUsageReport;
    }

    setGeneralInfo(subscriptionUsageResponse);
  }, [setGeneralInfo, subscriptionUsageData]);

  React.useEffect(() => {
    const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();
    setHandleGobalEventsInstance(currentHandleGlobalEvents);
    currentHandleGlobalEvents.checkingStylesSummary();
  }, []);

  return (
    <SC.GSContainer>
      <iframe
        title="Real-Time Corporatus DB Metrics"
        src={`${REACT_APP_API_REST_CORPORATUS_SERVER_URL}/dashboard/?api-key=${REACT_APP_API_REST_CORPORATUS_APIKEY}`}
        height="100%"
        width="100%"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        allowFullScreen
      >
        {" "}
      </iframe>
      <SC.GSContainerResults hidden>
        <Box component="div" className={classes.headerSS}>
          <h1>SUMMARY STATISTICS</h1>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SC.DatepickerContainer container>
              {/* <FormControl variant="outlined" className={classes.dateField}>
                <InputLabel id="datasetId">Age</InputLabel>
                <Select
                  labelId="data-set-component"
                  id="dataSetSelect"
                  value={age}
                  onChange={handleChange}
                  label="dataSet"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl> */}
              {/* <KeyboardDatePicker
                variant="inline"
                format="MMM dd, yyyy"
                margin="normal"
                id="startDate"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                keyboardIcon={<ArrowDropDownIcon />}
                InputProps={{ readOnly: true }}
                className={classes.dateField}
              /> */}
              <KeyboardDatePicker
                variant={isMobileResolution() ? "inline" : "dialog"}
                format="MMM dd, yyyy"
                margin="normal"
                id="startDate"
                label="Start Date"
                inputVariant="outlined"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                keyboardIcon={<ArrowDropDownIcon />}
                InputProps={{ readOnly: true }}
                className={classes.dateField}
                autoOk
              />
              <KeyboardDatePicker
                variant={isMobileResolution() ? "inline" : "dialog"}
                format="MMM dd, yyyy"
                margin="normal"
                id="endDate"
                label="End Date"
                inputVariant="outlined"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                keyboardIcon={<ArrowDropDownIcon />}
                InputProps={{ readOnly: true }}
                className={classes.dateField}
                autoOk
              />
              <SC.ButtonPrimary onClick={handleClickUpdateSummary}>
                Go
              </SC.ButtonPrimary>
            </SC.DatepickerContainer>
          </MuiPickersUtilsProvider>
          {/* <Box className={classes.datesContainer}>          
            {<form className={classes.datePickerContainer} noValidate>
              <TextField
                id="startDate"
                type="date"
                defaultValue="DataSet"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <form className={classes.datePickerContainer} noValidate>
              <TextField
                id="endDate"
                type="date"
                defaultValue="month day, year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>}
          </Box> */}
        </Box>
        {loading ? (
          <ProgressIndicator open={loading} />
        ) : (
          <Box component="div" className={classes.SSContent}>
            <Box hidden={!isMobileResolution()}>
              <SC.SummaryCarouselGeneric
                type="summary"
                items={[
                  <SC.GSSummaryItem>
                    <Box component="div" className={classes.headerCard}>
                      <img src={AudioIcon} alt="audio-resume-icon" />
                      <span className="title">Total Audio</span>
                      {/* <Box>
                          <SvgIcon component={MoreHorizIcon} />
                        </Box> */}
                    </Box>
                    <Box className="content">
                      <Box className="main">
                        <div>
                          <span>
                            {Math.floor(
                              (generalInfo.totalDurationOfAudioIngestedInSec ??
                                0) / 60
                            )}
                          </span>
                        </div>
                      </Box>
                      <h5 className="footer">Minutes</h5>
                    </Box>
                  </SC.GSSummaryItem>,
                  <SC.GSSummaryItem>
                    <Box component="div" className={classes.headerCard}>
                      <img src={SentencesIcon} alt="sentences-resume-icon" />
                      <span className="title">Total Sentences</span>
                      {/* <Box>
                          <SvgIcon component={MoreHorizIcon} />
                        </Box> */}
                    </Box>
                    <Box className="content">
                      <Box className="main">
                        <div>
                          <span>
                            {generalInfo.totalWordsInTheAudioIngested}
                          </span>
                        </div>
                      </Box>
                      <h5 className="footer">Words</h5>
                    </Box>
                  </SC.GSSummaryItem>,
                  <SC.GSSummaryItem>
                    <Box component="div" className={classes.headerCard}>
                      <img src={EntitiesIcon} alt="entities-resume-icon" />
                      <span className="title">Total Entities</span>
                      {/* <Box>
                          <SvgIcon component={MoreHorizIcon} />
                        </Box> */}
                    </Box>
                    <Box className="content">
                      <Box className="main">
                        <div>
                          <span>
                            {generalInfo.totalEntitiesInTheAudioIngested}
                          </span>
                        </div>
                      </Box>
                      <h5 className="footer">Entities</h5>
                    </Box>
                  </SC.GSSummaryItem>,
                ]}
                customPaddingLeft={10}
                customPaddingRight={10}
              />
            </Box>
            <SC.GSSummary
              hidden={isMobileResolution()}
              className={classes.flexRow}
              component="div"
            >
              <SC.GSSummaryItem>
                <Box component="div" className={classes.headerCard}>
                  <img src={AudioIcon} alt="audio-resume-icon" />
                  <span className="title">Total Audio</span>
                  {/* <Box>
                    <SvgIcon component={MoreHorizIcon} />
                  </Box> */}
                </Box>
                <Box className="content">
                  <Box className="main">
                    <div>
                      <span>
                        {Math.floor(
                          (generalInfo.totalDurationOfAudioIngestedInSec ?? 0) /
                            60
                        )}
                      </span>
                    </div>
                  </Box>
                  <h5 className="footer">Minutes</h5>
                </Box>
              </SC.GSSummaryItem>
              <SC.GSSummaryItem>
                <Box component="div" className={classes.headerCard}>
                  <img src={SentencesIcon} alt="sentences-resume-icon" />
                  <span className="title">Total Sentences</span>
                  {/* <Box>
                    <SvgIcon component={MoreHorizIcon} />
                  </Box> */}
                </Box>
                <Box className="content">
                  <Box className="main">
                    <div>
                      <span>{generalInfo.totalWordsInTheAudioIngested}</span>
                    </div>
                  </Box>
                  <h5 className="footer">Words</h5>
                </Box>
              </SC.GSSummaryItem>
              <SC.GSSummaryItem>
                <Box component="div" className={classes.headerCard}>
                  <img src={EntitiesIcon} alt="entities-resume-icon" />
                  <span className="title">Total Entities</span>
                  {/* <Box>
                    <SvgIcon component={MoreHorizIcon} />
                  </Box> */}
                </Box>
                <Box className="content">
                  <Box className="main">
                    <div>
                      <span>{generalInfo.totalEntitiesInTheAudioIngested}</span>
                    </div>
                  </Box>
                  <h5 className="footer">Entities</h5>
                </Box>
              </SC.GSSummaryItem>
            </SC.GSSummary>
            {/* <SC.GSSummary className={classes.flexRow} component="div">
              <SC.GSSummaryItem className={classes.chartContainer}>
                <Box component="div" className={classes.headerCard}>
                  <img
                    src={SentimentsIcon}
                    alt="sentiments-resume-icon"
                  />
                  <span className="title">Averange Sentiment</span>
                  <Box>
                    <SvgIcon component={MoreHorizIcon} />
                  </Box>
                </Box> */}
            {/* <Box className={`content ${classes.containerCardImg}`}> */}
            {/* <CardMedia
                    component="img"
                    className={classes.cardImg}
                    image={chartImage}
                  /> */}
            {/* <AudionautCharts
                    type={2}
                    customStyle={{
                      height: "100%",
                      width: "auto",
                      minWidth: "330px",
                      minHeight: "236px",
                    }}
                    data={{
                      score: Number(generalInfo.Sentiments?.total),
                      isPositive: true,
                    }}
                  />
                </Box> */}
            {/* {showDetail1 && (
                  <Box className={`content ${classes.containerCardImg}`}> */}
            {/* <CardMedia
                      component="img"
                      className={classes.cardImg}
                      image={chartDetail}
                    /> */}
            {/* <AudionautCharts
                      type={1}
                      data={{
                        values: sentimentsData.values,
                        labels: sentimentsData.labels,
                      }}
                    />
                  </Box>
                )}
                <Button className={classes.moreBtn} onClick={handleShowMore1}>
                  {showDetail1 ? "Less" : "More"}
                </Button>
              </SC.GSSummaryItem>
              <SC.GSSummaryItem className={classes.chartContainer}>
                <Box component="div" className={classes.headerCard}>
                  <img
                    src={ROIIcon}
                    alt="roi-resume-icon"
                  />
                  <span className="title">ROI</span>
                  <Box>
                    <SvgIcon component={MoreHorizIcon} />
                  </Box>
                </Box> */}
            {/* <Box className={`content ${classes.containerCardImg}`}> */}
            {/* <CardMedia
                    component="img"
                    className={classes.cardImg}
                    image={chartImage}
                  /> */}
            {/* <AudionautCharts
                    type={4}
                    customStyle={{
                      height: "100%",
                      width: "100%",
                      minHeight: "236px",
                    }}
                    data={{
                      score: Number(generalInfo.ROI?.total),
                      isPositive: true,
                    }}
                  />
                </Box> */}
            {/* {showDetail2 && (
                  <Box className={`content ${classes.containerCardImg}`}> */}
            {/* <CardMedia
                      component="img"
                      className={classes.cardImg}
                      image={chartDetail}
                    /> */}
            {/* <AudionautCharts
                      type={1}
                      data={{ values: ROIData.values, labels: ROIData.labels }}
                    />
                  </Box>
                )}
                <Button className={classes.moreBtn} onClick={handleShowMore2}>
                  {showDetail2 ? "Less" : "More"}
                </Button>
              </SC.GSSummaryItem> */}
            {/* </SC.GSSummary> */}
          </Box>
        )}
        <Box
          hidden={
            generalInfo &&
            generalInfo.totalDurationOfAudioIngestedInSec !== 0 &&
            generalInfo.totalEntitiesInTheAudioIngested !== 0 &&
            generalInfo.totalWordsInTheAudioIngested !== 0
          }
          className={classes.adviseMessage}
        >
          <Slide
            direction="up"
            in={
              !loading &&
              !subscriptionUsageLoading &&
              (!generalInfo ||
                (generalInfo.totalDurationOfAudioIngestedInSec === 0 &&
                  generalInfo.totalEntitiesInTheAudioIngested === 0 &&
                  generalInfo.totalWordsInTheAudioIngested === 0))
            }
          >
            <Paper elevation={4}>
              <SC.NoResults
                show
                title="Hello "
                titleComponent={
                  <Typography>
                    {initialUserData ? initialUserData!.firstName : "User"}
                  </Typography>
                }
                title2=", welcome to your Summary Statistics!"
                description="Start seeing insights into what people are saying about your brand, products, or services.
                Go to Explore > "
                description2=" and ingest some audio."
                component={
                  <Link key="audio-source-link" href="explore/audio-sources">
                    Audio Sources
                  </Link>
                }
              />
            </Paper>
          </Slide>
        </Box>
      </SC.GSContainerResults>
    </SC.GSContainer>
  );
};

export default SubscriptionUsagePage;
