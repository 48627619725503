/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
// Dependencies
import React from "react";
import { Snackbar, Box } from "@material-ui/core";
import { isEqual } from "lodash";

// Components
import { TableColumn } from "components/table/table.component";
import { Alert, AlertProps } from "components/alert/alert.component";
import OrganizationForm from "components/organization-form/organization-form.component";
import { UserFilteringLeftPane } from "components/user-filtering-left-pane/user-filtering-left-pane.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { ActionButton } from "components/action-button/action-button.component";
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// GraphQL
import {
  UserData,
  GroupData,
  useGetAllUsersLazyQuery,
  useGetGroupQuery,
  useAddUserGroupMutation,
  useUpdateUserDataMutation,
  useUpdateUserOrganisationMutation,
  useUpdateUserDeparmentMutation,
  useUpdateUserGroupMutation,
  useDeleteUserMutation,
  Maybe,
  useCreateOrganizationMutation,
  useGetCurrentUserOrganisationQuery,
} from "graphql/types-and-hooks";
import { createEmptyUserData, NEW_USER_FRAGMENT_GQL } from "graphql/rbac.utils";

// Utils
import {
  useAppErrorHandler,
  isServerError,
  UIError,
  UIErrorCodes,
} from "errors/app.errors";

// Assets
import DeleteIcon from "@material-ui/icons/Delete";
// import EditIcon from "@material-ui/icons/Edit";
import { Reference, StoreObject } from "@apollo/client";
import SC from "./organization-management.styles";

const OrganizationMagamentPage: React.FC = () => {
  const initialUserDataEmpty = createEmptyUserData();
  const [initialUserData, setInitialUserData] =
    React.useState(initialUserDataEmpty);
  const [openLeftPane, setOpenLeftPane] = React.useState(false);
  const [openAddOrganizationForm, setOpenAddOrganizationForm] =
    React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [saveActive, setSaveActive] = React.useState(false);

  const [createOrganization, { loading: createOrganizationLoading }] =
    useCreateOrganizationMutation({
      update(cache, { data }) {
        const newOrganization = data?.createOrganizationAccount;
        if (newOrganization) {
          cache.modify({
            fields: {
              GetUser(existingOrganizations = []) {
                const newUserRef = cache.writeFragment({
                  id: cache.identify(newOrganization),
                  data: newOrganization,
                  fragment: NEW_USER_FRAGMENT_GQL,
                });
                return [...existingOrganizations, newUserRef];
              },
            },
          });
        }
      },
    });

  const [addUserGroup, { loading: addUserGroupLoading }] =
    useAddUserGroupMutation();

  const [updateUserData, { loading: updateUserDataLoading }] =
    useUpdateUserDataMutation();
  const [updateUserDepartment, { loading: updateUserDepartmentLoading }] =
    useUpdateUserDeparmentMutation();
  const [updateUserOrganisation, { loading: updateUserOrganisationLoading }] =
    useUpdateUserOrganisationMutation();
  const [updateUserGroup, { loading: updateUserGroupLoading }] =
    useUpdateUserGroupMutation();

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation({
    update(cache, { data }) {
      const userToDeleteId = data?.DeleteUser;
      cache.modify({
        fields: {
          GetUser(existingUsers, { readField }) {
            return existingUsers.filter(
              (user: Reference | StoreObject | undefined) =>
                userToDeleteId !== readField("id", user)
            );
          },
        },
      });
    },
  });

  const {
    data: currentOrganisationData,
    loading: currentOrganisationLoading,
    error: currentOrganisationError,
  } = useGetCurrentUserOrganisationQuery();

  const [
    getUsersData,
    {
      data: usersData,
      loading: usersDataLoading,
      error: errorUsersDataLoading,
    },
  ] = useGetAllUsersLazyQuery();

  const {
    data: groupsData,
    loading: groupsDataLoading,
    error: errorGroupsDataLoading,
  } = useGetGroupQuery();

  const errorHandler = useAppErrorHandler(
    errorUsersDataLoading || errorGroupsDataLoading
  );

  const users = React.useMemo(() => usersData?.GetUser ?? [], [usersData]);
  const [filteredUsers, setFilteredUsers] = React.useState<UserData[]>();

  const loading =
    createOrganizationLoading ||
    addUserGroupLoading ||
    updateUserDataLoading ||
    updateUserDepartmentLoading ||
    updateUserOrganisationLoading ||
    updateUserGroupLoading ||
    deleteUserLoading ||
    usersDataLoading ||
    groupsDataLoading;

  const handlerCreateOrganizationFormOnSubmit = React.useCallback(
    async (userData: UserData) => {
      try {
        const response = await createOrganization({
          variables: {
            email: userData.email!,
            name: userData.firstName!,
            phone: userData.phone!,
            password: userData.password!,
          },
        });

        setOpenAddOrganizationForm(false);
        setSnackBarMessage({
          message: "The user has been created successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the user"
            )
          );
        }
      }
    },
    [createOrganization, errorHandler]
  );

  const handlerEditOrganizationFormOnSubmit = React.useCallback(
    async (userData: UserData) => {
      try {
        // const userId = userData.id!;
        // const OriginalUserData = users.find((user) => user.id === userId);
        // const initialCompanyId = OriginalUserData?.organisation?.id!;
        // const initialDepartmentId = OriginalUserData?.department?.id!;
        // const initialGroups = OriginalUserData?.groups?.map(
        //   (group) => group?.id
        // );
        // const groupsSelected = userData.groups?.map((group) => group?.id);

        // await updateUserData({
        //   variables: {
        //     id: userData?.id!,
        //     firstName: userData?.firstName,
        //     lastName: userData?.lastName,
        //     profilePicture: userData?.profilePicture,
        //     email: userData?.email,
        //     active: userData?.active,
        //   },
        // });

        // if (initialCompanyId !== userData?.organisation?.id!)
        //   await updateUserOrganisation({
        //     variables: {
        //       userID: userData?.id!,
        //       organisationID: userData?.organisation?.id,
        //     },
        //   });

        // if (initialDepartmentId !== userData?.department?.id!)
        //   await updateUserDepartment({
        //     variables: {
        //       userID: userData?.id!,
        //       departmentID: userData?.department?.id,
        //     },
        //   });

        // if (!isEqual(initialGroups, groupsSelected))
        //   await updateUserGroup({
        //     variables: {
        //       userID: userData?.id!,
        //       groupID: initialGroups as string[],
        //       groupIDNew: groupsSelected as string[],
        //     },
        //   });

        setSnackBarMessage({
          message: "The profile has been updated successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updating the profile"
            )
          );
        }
      }
    },
    [
      errorHandler,
      // updateUserData,
      // updateUserDepartment,
      // updateUserGroup,
      // updateUserOrganisation,
      // users,
    ]
  );

  const handlerDeleteUser = React.useCallback(
    async (userId: Maybe<string> | undefined) => {
      try {
        const OriginalUserData = users.find((user) => user.id === userId);

        await deleteUser({
          variables: {
            userID: OriginalUserData?.id!,
          },
        });

        setSnackBarMessage({
          message: "The user has been deleted successfully",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the user"
            )
          );
        }
      }
    },
    [deleteUser, errorHandler, users]
  );

  const handleTableActions = (action: TableToolbarAction) => {
    if (action === "filter-results") {
      if (openLeftPane) setOpenLeftPane(false);
      else setOpenLeftPane(true);
    }

    if (action === "add") {
      if (openAddOrganizationForm) setOpenAddOrganizationForm(false);
      else {
        setOpenAddOrganizationForm(true);
        setSaveActive(false);
        setInitialUserData(initialUserDataEmpty);
      }
    }
  };

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handleCancelAddUser = () => {
    setSaveActive(false);
    setOpenAddOrganizationForm(false);
  };

  const columns: TableColumn<UserData>[] = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        align: "left",
        width: 200,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        align: "left",
        width: 150,
      },
      {
        Header: "Organization",
        accessor: (user) => user.organisation?.name,
        align: "left",
        width: 150,
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        width: 250,
      },
      {
        Header: "Groups",
        accessor: (user) =>
          user.groups?.map((group, i, arr) => {
            if (arr.length - 1 === i) {
              return group?.name;
            }
            return `${group?.name}, `;
          }),
        align: "left",
        width: 200,
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: ({ value }) => <SC.Checkbox checked={value ?? false} />,
        align: "center",
        width: 40,
      },
      {
        id: "actions",
        Header: ["Actions"],
        accessor: "id",
        Cell: ({ value }) => (
          <>
            <ActionButton
              key="Delete"
              title="Delete"
              icon={<DeleteIcon />}
              handler={() => handlerDeleteUser(value)}
              displayPopover
              popoverMessage="Are you sure you want to delete this item??
You won't be able to recover them"
              popoverButtons
            />
          </>
        ),

        align: "center",
        width: 40,
      },
    ],
    [handlerDeleteUser]
  );

  const appPermissionValidator = useAppPermissionValidator();
  const addOrganizationForm = openAddOrganizationForm ? (
    <OrganizationForm
      initialUserData={initialUserData}
      initialEditable
      saveActive={saveActive}
      isProfile={false}
      onSubmit={handlerCreateOrganizationFormOnSubmit}
      onCancel={handleCancelAddUser}
      organisations={[]}
      departments={[]}
      groups={[]}
    />
  ) : undefined;

  const onlyCheckbox = false;

  React.useEffect(() => {
    if (
      currentOrganisationData &&
      currentOrganisationData.getCurrentUserOrganisation
    ) {
      getUsersData({
        variables: {
          organisationID:
            currentOrganisationData.getCurrentUserOrganisation.id!,
        },
      });
    }
  }, [currentOrganisationData, getUsersData]);

  return (
    <Box flex="1" height="100%">
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>

      <ProgressIndicator open={loading} />

      <SC.UserTable
        title="Organizations"
        persistenceId="798b733f-ef60-40de-876e-d85e039165b9"
        data={filteredUsers ?? users}
        columns={columns}
        onAction={handleTableActions}
        actionsOnLeft={
          appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)
            ? ["add"]
            : undefined
        }
        actionsOnRight={["filter-results"]}
        leftPanel={
          <UserFilteringLeftPane
            users={users}
            groups={groupsData?.GetGroup as GroupData[]}
            onFilter={setFilteredUsers}
            onlyCheckbox={onlyCheckbox}
          />
        }
        topPanel={addOrganizationForm}
        renderExpandedRowSubComponent={(row) => (
          <OrganizationForm
            initialUserData={row.original}
            initialEditable={false}
            onSubmit={handlerEditOrganizationFormOnSubmit}
            onCancel={() => null}
            isProfile
            organisations={[]}
            departments={[]}
            groups={[]}
          />
        )}
      />
    </Box>
  );
};

export default OrganizationMagamentPage;
