// Dependencies
import {
  withStyles,
  Box,
  Button as ButtonBase,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiDialogActions from "@material-ui/core/DialogActions";

const UserForm = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    padding: "20px",
    paddingBottom: "16px",
    borderRadius: "10px",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down(540)]: {
      margin: "unset",
    },
  },
}))(Box);

const FormActions = withStyles((theme) => ({
  root: {
    justifyContent: "rigth",
    marginTop: "50px",
    "& .secondaryBtn": {
      backgroundColor: theme.button.secondary.backgroundColor,
      color: theme.button.secondary.color,
      textTransform: theme.button.secondary.textTransform,
      border: theme.button.secondary.border,
      "&:hover": {
        color: theme.button.secondary.hoverColor,
        backgroundColor: theme.button.secondary.hoverBackground,
      },
      "&:disabled": {
        color: theme.button.secondary.disabledColor,
        backgroundColor: theme.button.secondary.disabledBackground,
      },
    },
    [theme.breakpoints.down(540)]: {
      marginTop: "20px",
    },
  },
}))(MuiDialogActions);

const Button = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: theme.buttonSize.small.width,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
}))(ButtonBase);

export default {
  UserForm,
  FormActions,
  EditIcon,
  CancelCircleIcon,
  CheckCircle,
  ErrorOutlineIcon,
  Button,
};
