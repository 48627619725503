import { TextField, withStyles, Theme } from "@material-ui/core";

const CustomTextField = withStyles((theme: Theme) => ({
  root: {
    "& label": {
      color: "#8D8D8D",
      fontSize: "12px",
      lineHeight: "15px",
      fonWeight: 400,
    },
    "& input": {
      color: "#8D8D8D",
      fontSize: "16px",
      lineHeght: "20px",
      fontWeight: 400,
      "&::placeholder": {
        color: "#8D8D8D",
      },
    },
    "& fieldset": {
      border: "1px solid #C6CACC",
    },
    "&:hover": {
      "& label": {
        color: "#B8CAC6 !important",
      },
      "& input": {
        color: "#B8CAC6",
        "&::placeholder": {
          color: "#B8CAC6",
        },
      },
      "& fieldset": {
        borderColor: "#B8CAC6",
      },
    },
    "&:ative": {
      "& label": {
        color: "#B8CAC6",
      },
      "& fieldset": {
        borderWidth: "2px",
      },
    },
  },
}))(TextField);

export default CustomTextField;
