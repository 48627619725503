/* eslint-disable prettier/prettier */
// Dependencies
import {
  withStyles,
  Box,
  Button as ButtonBase,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiDialogActions from "@material-ui/core/DialogActions";

const PaymentMethodForm = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // paddingBottom: "24px",
    // background: "#FFFFFF",
    // borderBottom: "1px solid #EAEAEA",
    [theme.breakpoints.down(540)]: {
      background: "transparent",
    },
  },
}))(Box);

const FormActions = withStyles({
  root: {
    justifyContent: "rigth",
    marginTop: "40px",
    padding: "0px",
  },
})(MuiDialogActions);

const Button = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: "144px",
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    margin: "auto",
    marginLeft: "0px !important",
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
  contained: {
    color: "#FFFFFF",
    backgroundColor: "#B8CAC6",
  },
  label: {
    textTransform: "capitalize",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "bold",
  },
}))(ButtonBase);

export default {
  PaymentMethodForm,
  FormActions,
  EditIcon,
  CancelCircleIcon,
  CheckCircle,
  ErrorOutlineIcon,
  Button,
};
