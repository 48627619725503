/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
// Dependencies
import React from "react";
import {
  makeStyles,
  ListItem as ListItemMui,
  ListItemProps,
  ListItemIcon,
  ListItemText,
  ButtonBaseProps,
  Checkbox,
  Tooltip,
} from "@material-ui/core";

type CustomListItemProps = ListItemProps &
  ButtonBaseProps & {
    isSelected?: boolean;
    isTreeItem?: boolean;
  };

const useListItemStyles = makeStyles((theme) => ({
  root: {
    color: (props: CustomListItemProps) =>
      props.isSelected ? theme.selectableList.textColor : "inherit",
    paddingTop: (props: CustomListItemProps) =>
      props.isTreeItem ? theme.selectableList.paddingTopTreeItem : "none",
    paddingBottom: (props: CustomListItemProps) =>
      props.isTreeItem ? theme.selectableList.paddingBottomTreeItem : "none",
    backgroundColor: (props: CustomListItemProps) =>
      props.isSelected ? theme.selectableList.isSelected : "inherit",
    "&:hover": {
      backgroundColor: (props: CustomListItemProps) =>
        props.isSelected
          ? theme.selectableList.hoverColorSelected
          : theme.selectableList.hoverColor,
    },
    "&:after": {
      color: (props: CustomListItemProps) =>
        props.isSelected ? theme.selectableList.isSelected : "none",
      borderLeft: (props: CustomListItemProps) =>
        props.isSelected ? theme.selectableList.afterBorderLeft : "none",
      borderTop: (props: CustomListItemProps) =>
        props.isSelected ? theme.selectableList.afterBorderTop : "none",
      borderBottom: (props: CustomListItemProps) =>
        props.isSelected ? theme.selectableList.afterBorderBottom : "none",
      content: '""',
      position: "absolute",
      right: "-2rem",
    },
  },
}));

const ButtonListItem: React.ComponentType<ListItemProps & ButtonBaseProps> =
  ListItemMui as React.ComponentType<ListItemProps & ButtonBaseProps>;

const ListItem = (props: CustomListItemProps): JSX.Element => {
  const { isSelected, isTreeItem, ...muiProps } = props;
  const classes = useListItemStyles(props);

  return <ButtonListItem className={classes.root} {...muiProps} />;
};

export default {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Tooltip,
};
