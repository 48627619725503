/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { ORGANIZATIONS } = ActionTypes;

const INITIAL_STATE = {
  organizations: [],
  isReceivingData: false,
  error: null
};

const organizationsReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORGANIZATIONS.FETCH.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case ORGANIZATIONS.FETCH.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        organizations: data && data.GetOrganization,
        isReceivingData: false
      };
    }
    case ORGANIZATIONS.FETCH.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    default: return state;
  }
};

export default organizationsReducerFn;
