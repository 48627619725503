// Dependencies
import React from "react";

import { useThemeStore } from "commons/utils/user-data";

import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Assets
import themes from "./theme";
import "@fontsource/poppins"; // Defaults to weight 400.
import "@fontsource/poppins/500.css";

const ThemeProvider: React.FC = ({ children }) => {
  const globalTheme = useThemeStore((state: any) => state.theme);
  const [currentTheme, setCurrentTheme] = React.useState<Theme>(
    responsiveFontSizes(createTheme(themes.corporatus.ligth))
  );

  React.useEffect(() => {
    setCurrentTheme(
      responsiveFontSizes(
        createTheme(
          globalTheme === "ligth"
            ? themes.corporatus.ligth
            : themes.corporatus.dark
        )
      )
    );
  }, [globalTheme]);

  return (
    <MuiThemeProvider theme={currentTheme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {children}
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
