import React from "react";
import { Box, Typography } from "@material-ui/core";

import DeleteIconButton from "components/delete-icon-button/delete-icon-button.component";

import { ChatHistoricItem } from "graphql/corporatus/corporatus_rest_graphql.types";
import { getFormattedDate } from "commons/utils/date-format";

import SC from "./chat-topic-item.styles";

export interface TopicItemModel {
  id: string;
  name: string;
  lastResponse: string;
  lastDate: string;
}

interface ChatTopicItemInterface {
  item: ChatHistoricItem;
  onOpen: (param1: ChatHistoricItem) => void;
  isActive: boolean;
  handlerDeleteItem: (param1: ChatHistoricItem) => void;
}

const ChatTopicItem: React.FC<ChatTopicItemInterface> = ({
  item,
  onOpen,
  isActive,
  handlerDeleteItem,
}) => {
  const classes = SC.useStyles();

  return (
    <SC.ChatTopicItemContainer
      className={`${isActive ? classes.itemActive : ""}`}
      onClick={() => {
        onOpen(item);
      }}
    >
      <Box className="title">
        <Typography color="primary" className="query">
          {/* <ChatBubbleOutlineIcon color="primary" /> */}
          {item.query}
        </Typography>
        <Typography className="date" color="secondary">
          {getFormattedDate(item.created_at.split("-")[0], "DD MMM")}
        </Typography>
      </Box>
      <Box className="content">
        <Typography className="response" color="secondary">
          {item.response ? item.response.substring(0, 50) : ""}
        </Typography>
        <Box className="delete">
          {isActive ? (
            <DeleteIconButton
              itemId={item.session_id}
              itemName={item.query ?? ""}
              type=""
              deleteItem={handlerDeleteItem}
              data={item}
              itemDeleted={false}
            />
          ) : null}
        </Box>
      </Box>
    </SC.ChatTopicItemContainer>
  );
};

export default ChatTopicItem;
