/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
// Components
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// GraphQL
import { CLIENT_NAME } from "graphql/client";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// GraphQL
import { useUraIndividualsQuery } from "graphql/hasura/types-and-hooks";
import LeftPaneContainer from "./left-pane/left-pane.component";

// Styles
import SC from "./visibility.styles";

// Schema
import {
  VisibilityTableColumns,
  VisibilityTableSchema,
} from "./visibility.schema";

export const IndividualPage = () => {
  const {
    data: usersData,
    loading: usersDataLoading,
    error: errorUsersDataLoading,
  } = useUraIndividualsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
  });

  useAppErrorHandler(errorUsersDataLoading);

  const users = React.useMemo(
    () => usersData?.ura_RegIndividual || [],
    [usersData]
  );

  const columns = useMemo(() => VisibilityTableColumns, []);

  if (usersDataLoading) {
    return <ProgressIndicator open />;
  }

  return (
    <SC.Box mt={3}>
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Hide Visibility"
        buttonText="Save Changes"
        // todo when working on save functionality
        onSaveTableChanges={() => alert("feature under construction")}
        renderVisibilityRowComponent
        // todo when working on save functionality
        onRowSelect={(values) => alert("feature under construction")}
        columns={columns}
        // todo, to remove any
        data={users as VisibilityTableSchema[]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("feature under construction")}
        leftPanel={<LeftPaneContainer />}
        persistenceId="289d962b-3655-4c36-848a-2e7f0895ef13"
      />
    </SC.Box>
  );
};

export default IndividualPage;
