import { withStyles } from "@material-ui/core";

import {
  Container,
  Content,
  Logo,
  Title as OriginTitle,
} from "pages/signin/signin.styles";

export const Title = withStyles(() => ({
  root: {
    fontSize: "30px",
    lineHeight: "37px",
    paddingBottom: "8px",
  },
}))(OriginTitle);

export default {
  Container,
  Content,
  Logo,
  Title,
};
