/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';


const { AUTH } = ActionTypes;

const INITIAL_STATE = {
  tokenInfo: null,
  error: null
};

const authReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH.AUTH.SUCCESS:
      return {
        ...state,
        error: null,
        tokenInfo: action.payload
      };

    case AUTH.LOGOUT:
      return {
        ...INITIAL_STATE
      };

    default: return state;
  }
};


export default authReducerFn;
