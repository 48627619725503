import { withStyles, Typography } from "@material-ui/core";

import {
  Form as FormBase,
  Button as ButtonBase,
} from "components/signin-form/signin-form.styles";

export const Form = withStyles({
  root: {
    paddingLeft: "129px",
    paddingRight: "129px",
  },
})(FormBase);

export const Text = withStyles(() => ({
  root: {
    color: "#000",
    fontSize: "16px",
    lineHeight: "22px",
    paddingTop: "33px",
    paddingBottom: "87px",
    fontWeight: 600,
  },
}))(Typography);

export const Button = withStyles({
  root: {
    textTransform: "none",
    marginBottom: "0px",
  },
})(ButtonBase);

export default {
  Form,
  Text,
  Button,
};
