import React, { ReactElement } from "react";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import SC from "./auth-container.styles";

interface AutrhContainerInterface {
  title: string;
  content: ReactElement;
  loading: boolean;
}

const AuthContainer: React.FC<AutrhContainerInterface> = ({
  title,
  content,
  loading,
}) => {
  return (
    <SC.Container>
      <ProgressIndicator open={loading} />
      <SC.Content>
        <SC.Logo />
        <SC.Title>{title}</SC.Title>
        {content}
      </SC.Content>
    </SC.Container>
  );
};

export default AuthContainer;
