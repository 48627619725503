/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import _ from "lodash";

export const CapitalizeFirstCharacter = (str: string) => {
  const arr = str.split(" ");

  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }

  return arr.join(" ");
};

export const isNotNull = (value: any) => {
  return value ?? "";
};

export const getYoutubeVideoId = (url: string): string => {
  const mediaQueryStr = url.split("?")[1];
  const queryParams = new URLSearchParams(`?${mediaQueryStr}`);
  return queryParams.get("v") ?? "-";
};
