import React from "react";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import GeneralSearch from "components/general-search/general-search.component";
import Table from "components/table/table.component";

interface HeadTopic {
  topic: string;
  caltech_scraper: boolean;
  escholarship_scraper: boolean;
  liberty_scraper: boolean;
  uvic_scraper: boolean;
  created: string;
}
// const now: Date
const date = new Date();
const topicsHeadCells: HeadTopic[] = [
  {
    topic: "Topic",
    caltech_scraper: false,
    escholarship_scraper: false,
    liberty_scraper: false,
    uvic_scraper: false,
    created: date.toString(),
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 750,
    },
    headerLabel: {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      fontWeight: "bold",
      "&.centerText": {
        textAlign: "center",
      },
      [theme.breakpoints.down(540)]: {
        padding: "10px 8px !important",
        fontSize: theme.fontGeneralStyles.default.fontSize,
        lineHeight: theme.fontGeneralStyles.default.lineHeight,
      },
    },
    subTableHeader: {
      borderBottom: "1px solid #EAEAEA",
      "& th:first-child": {
        paddingLeft: "7px",
      },
    },
  })
);

const PapersTable = withStyles((theme: Theme) => ({
  root: {
    "& thead": {
      "& th": {
        "& span": {
          fontSize: theme.fontGeneralStyles.default.fontSize,
          lineHeight: theme.fontGeneralStyles.default.lineHeight,
        },
      },
    },
  },
}))(Table);

const PapersListConatiner = withStyles((theme: Theme) => ({
  root: {
    flex: "1",
    minWidth: "70%",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    borderRadius: "8px",
    padding: "12px",
  },
}))(Box);

const HeaderPage = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    minHeight: "95px",
    display: "flex",
    background: theme.generalStylesContainer.layers.main.backgroundColor,
    marginBottom: "5px",
    "& h1": {
      fontSize: "26px",
      lineHeight: "32px",
      fontWeight: "bold",
    },
    "&.figures-header": {
      width: "calc(30% - 70px)",
      zIndex: "10",
    },
    [theme.breakpoints.down(540)]: {
      minHeight: "60px",
    },
  },
}))(Box);

const SearchInputContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "end",
  },
}))(Box);

const TopicContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "97%",
    padding: "20px",
    overflowY: "auto",
  },
}))(Box);

const SearchInput = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "300px",
    padding: "0px",
    "& .MuiInputBase-root": {
      width: "100%",
      padding: "0px 8px",
      border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
      borderRadius: "4px",
    },
  },
}))(GeneralSearch);

const TopicListConatiner = withStyles((theme: Theme) => ({
  root: {
    flex: "1",
    minWidth: "70%",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    borderRadius: "8px",
    padding: "12px",
    marginBottom: "20px",
  },
}))(Box);

const FiguresContainer = withStyles((theme: Theme) => ({
  root: {
    display: "none",
    transition: "visibility 0s linear 0.33s",
    "&.show-figures": {
      display: "block",
      width: "30%",
      borderLeft: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
      paddingLeft: "20px",
      marginLeft: "20px",
      overflowY: "auto",
    },
  },
}))(Box);

const FiguresContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "130px",
    gap: "8px",
    [theme.breakpoints.down(540)]: {
      height: "calc(100% - 60px)",
      top: "60px",
    },
  },
}))(Box);

const PaperTitle = withStyles((theme: Theme) => ({
  root: {
    "& svg": {
      fontSize: "30px",
      marginRight: "5px",
      marginTop: "5px",
    },
    "& a": {
      fontSize: theme.fontGeneralStyles.default.fontSize,
      lineHeight: theme.fontGeneralStyles.default.lineHeight,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
}))(Box);

export default {
  useStyles,
  PapersTable,
  HeaderPage,
  PapersListConatiner,
  SearchInputContainer,
  SearchInput,
  topicsHeadCells,
  TopicListConatiner,
  FiguresContainer,
  FiguresContent,
  PaperTitle,
  TopicContainer,
};
