import {
  withStyles,
  IconButton as IconButtonMui,
  Typography,
} from "@material-ui/core";

const Label = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    fontWeight: "bold",
    fontSize: theme.iconButton.fontSize,
  },
}))(Typography);

const IconButton = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(540)]: {
      margin: "unset !important",
    },
  },
}))(IconButtonMui);

export default {
  IconButton,
  Label,
};
