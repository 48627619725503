/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';
// Constants
import {
  JOBS_COLUMN_ID,
  FILTER_INITIAL_DATE_RANGE
} from '../../commons/constants';

export const EDITED_BY_USER_ID = 'edited_By_user';

const { JOB_FILTER, AUTH } = ActionTypes;
const jobFilterInitialState = [
  {
    id: JOBS_COLUMN_ID.ID,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.COMMENTS,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.STATUS,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.STORE,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.CREATETIME,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.TECHNICIAN,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.DEVICE,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.UPDATEDTIME,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.CLASS_TYPE,
    value: []
  },
  {
    id: JOBS_COLUMN_ID.IMAGE_DATE_RANGE,
    value: []
  }, {
    id: JOBS_COLUMN_ID.DATE_RANGE_START,
    value: [FILTER_INITIAL_DATE_RANGE.startDate]
  },
  {
    id: JOBS_COLUMN_ID.DATE_RANGE_END,
    value: [FILTER_INITIAL_DATE_RANGE.endDate]
  },
  {
    id: JOBS_COLUMN_ID.END_DATE_RANGE_END,
    value: [FILTER_INITIAL_DATE_RANGE.endDate]
  },
  {
    id: JOBS_COLUMN_ID.END_DATE_RANGE_START,
    value: [FILTER_INITIAL_DATE_RANGE.startDate]
  },
  {
    id: EDITED_BY_USER_ID,
    value: [false]
  }
];

const jobFilterReducerFn = (state = jobFilterInitialState, action) => {
  switch (action.type) {
    case JOB_FILTER.UPDATE: {
      const jobFilter = state;
      const newFilterOption = action.payload;
      let newJobFilter = jobFilter.filter(f => f.id !== newFilterOption.id);
      if (!newFilterOption.isFromCode) {
        newJobFilter = newJobFilter.filter(f => f.id !== 'edited_By_user');
        newJobFilter.push({
          id: EDITED_BY_USER_ID,
          value: [true]
        });
      }
      newJobFilter.push(newFilterOption);
      return newJobFilter;
    }
    case JOB_FILTER.SET: {
      return {
        ...state
      };
    }
    case AUTH.AUTH.SUCCESS:
    case JOB_FILTER.RESET: {
      return [
        ...jobFilterInitialState
      ];
    }
    default:
      return state;
  }
};

export default jobFilterReducerFn;
