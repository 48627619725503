import React from "react";

import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  SvgIcon,
  Divider,
  IconButton,
} from "@material-ui/core";
import Alert, { AlertProps } from "components/alert/alert.component";
import { CheckCircle, CloseOutlined } from "@material-ui/icons";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import TopicsFormFields from "components/topic-form/topic_form";
import { TopicItemModel } from "graphql/corporatus/corporatus_rest_graphql.types";
import { createEmptyTopicItem } from "graphql/audionaut.utils";
import SC from "./topics-modal.styles";

const { REACT_APP_API_REST_TOPICS_SERVER_URL } = process.env;
interface TopicsModalProps {
  onSubmit: any;
  topicInfo: TopicItemModel;
  openModal: boolean;
  modalType?: number;
  handleCloseModal: any;
}

const TopicsModal: React.FC<TopicsModalProps> = ({
  onSubmit,
  topicInfo,
  openModal,
  modalType = 0,
  handleCloseModal,
}) => {
  const classes = SC.useStyles();
  // const [topicInfo, setTopicInfo] = React.useState<TopicItemModel>(() =>
  //  createEmptyTopicItem()
  // );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      BackdropProps={{
        style: {
          backgroundColor: modalType ? "#000" : "",
        },
      }}
      disableEscapeKeyDown={!!modalType}
    >
      <SC.ModaleHeader
        style={{
          background: modalType ? "#161616" : "",
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Topic</DialogTitle>
        <IconButton
          hidden={!!modalType}
          aria-label="close-modal"
          onClick={handleCloseModal}
          color="primary"
        >
          <CloseOutlined />
        </IconButton>
      </SC.ModaleHeader>
      <DialogContent className={classes.modalContent}>
        <Typography className={classes.topicDescription} color="secondary">
          Leave the box unchecked to run the scraper, the checked box means that
          scraper will not be run.
        </Typography>
        <Box component="div" className={classes.formContainer}>
          <TopicsFormFields
            onSubmit={onSubmit}
            onCancel={handleCloseModal}
            topicInfo={topicInfo}
            isWizard
            customClasses={classes.addressForm}
            show
            modalType={modalType}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TopicsModal;
