/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { GDRIVE_VERSIONS } = ActionTypes;

const INITIAL_STATE = {
  versionsByDocId: {},
  loading: false,
  error: null,
};

const gdriveVersionsReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GDRIVE_VERSIONS.OPERATION.START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GDRIVE_VERSIONS.OPERATION.END:
      return {
        ...state,
        loading: false,
      };
    case GDRIVE_VERSIONS.OPERATION.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GDRIVE_VERSIONS.OPERATION.CLEAN_ERROR:
      return {
        ...state,
        error: null,
      };
    case GDRIVE_VERSIONS.ADD:
      return {
        ...state,
        versionsByDocId: {
          ...state.versionsByDocId,
          [action.payload.documentId]:
            [action.payload.version, ...state.versionsByDocId[action.payload.documentId]]
          ,
        }
      };
    case GDRIVE_VERSIONS.UPDATE:
      return {
        ...state,
        versionsByDocId: {
          ...state.versionsByDocId,
          [action.payload.documentId]:
            state.versionsByDocId[action.payload.documentId]
              .map(v => (
                v.id !== action.payload.version.id
                  ? v
                  : ({ ...v, ...action.payload.version }))),
        }
      };
    case GDRIVE_VERSIONS.LIST:
      return {
        ...state,
        versionsByDocId: {
          ...state.versionsByDocId,
          [action.payload.documentId]: [...action.payload.versions]
        },
      };
    case GDRIVE_VERSIONS.OPERATION.CLEAN_DATA:
      return {};
    default:
      return {
        ...state,
      };
  }
};

export default gdriveVersionsReducerFn;
