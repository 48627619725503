// Dependencies
import { withStyles, Box, Button as ButtonBase } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiDialogActions from "@material-ui/core/DialogActions";

const GroupForm = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    paddingBottom: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
  },
})(Box);

const FormActions = withStyles({
  root: {
    justifyContent: "rigth",
    marginTop: "40px",
    padding: "0px",
  },
})(MuiDialogActions);

const Button = withStyles((theme) => ({
  root: {
    height: "48px",
    width: "144px",
    fontSize: theme.table.toolbarActions.fontSize,
    color: theme.table.toolbarActions.iconColor,
    borderWidth: "2px",
    borderColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      borderColor: theme.table.toolbarActions.iconColor,
    },
    borderRadius: "116px",
  },
  contained: {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(ButtonBase);

export default {
  GroupForm,
  FormActions,
  EditIcon,
  CancelCircleIcon,
  CheckCircle,
  ErrorOutlineIcon,
  Button,
};
