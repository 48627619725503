import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  SvgIcon,
} from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import { useMutation } from "@apollo/client";
import { CLIENT_NAME } from "graphql/client";
import {
  getPaperImages,
  getPaperImagesByPages,
} from "graphql/corporatus/corporatus_rest_graphql";

import { OsMediaFragment } from "graphql/open-search/types-and-hooks";
import { OsMediaProFragment } from "graphql/search/qanda-pro-types-and-hooks";

import CloseIcon from "@material-ui/icons/Close";

import GenericImage from "components/generic-image/generic-image.component";
import IconButtonGeneric from "components/icon-button/icon-button.component";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";

import { ReactComponent as discussIcon } from "../../assets/icons/discuss-icon.svg";

import SC from "./figures-list.styles";

const { REACT_APP_API_REST_CORPORATUS_SERVER_URL } = process.env;

interface FiguresListInterface {
  images: { secureUrl: string; caption: string }[];
  onDiscussClick: (param1: any) => void;
}

const FiguresListComponet: React.FC<FiguresListInterface> = ({
  images,
  onDiscussClick,
}) => {
  const [currentImages, setCurrentImages] = React.useState<
    { secureUrl: string; caption: string }[]
  >([]);
  const [showImages, setShowImages] = React.useState<boolean>(false);
  const [openImagesCarousel, setOpenImagesCarousel] =
    React.useState<boolean>(false);

  const [imagesShowed, setImagesShowed] = React.useState<any[]>([]);
  const [paperImages, setPaperImages] = React.useState<any>([]);
  const [initCarouselIndex, setInitCarouselIndex] = React.useState<number>(0);

  const [currentSlide, setCurrentSlide] = React.useState<any>(null);

  React.useEffect(() => {
    // console.debug("====> images to show: ", images);
    // setCurrentImages([]);
    setCurrentImages(images);
  }, [images]);

  return (
    <Box display="flex" height="100%" padding="20px">
      <SC.FiguresContainer
        component="div"
        className={showImages ? "show-figures" : ""}
      >
        <SC.FiguresContent component="div">
          {/* <Box
            hidden={!paperImagesLoading}
            style={{
              backgroundColor: "rgba(0,0,0,.1)",
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress style={{ margin: "auto" }} />
          </Box> */}
          <SC.NoResults
            show={images.length === 0}
            title="No images"
            description="The sources pages doesn't have any image to show you."
          />
          {currentImages.map((image: any, index: number) => {
            // console.debug("====> ImageData: ", image);
            const { secureUrl, caption } = image;
            // const name = url.split("/").pop();
            return (
              <GenericImage
                key={uuidv4()}
                url={secureUrl}
                title={caption}
                itemClicked={() => {
                  setInitCarouselIndex(index);
                  setOpenImagesCarousel(true);
                }}
                overlay
              />
            );
          })}
        </SC.FiguresContent>
      </SC.FiguresContainer>
      <SC.FiguresDialog
        open={openImagesCarousel}
        aria-labelledby="images-carousel"
        className="dialog-custom"
      >
        <Box component="div">
          <SC.ModalTitle>
            <Typography component="p">Figures</Typography>
            <IconButtonGeneric
              name=""
              type="button"
              icon={<CloseIcon color="primary" />}
              onClick={() => {
                setInitCarouselIndex(0);
                setOpenImagesCarousel(false);
              }}
            />
          </SC.ModalTitle>
          <DialogContent>
            <CarouselGeneric
              items={images.map((image: any) => {
                // console.debug("====> ImageData: ", images);
                const { secureUrl, caption } = image;
                return (
                  <GenericImage
                    key={uuidv4()}
                    url={secureUrl}
                    title={caption}
                    itemClicked={() => {}}
                    height={400}
                  />
                );
              })}
              type="captions"
              startIndex={initCarouselIndex}
              onCurrentSlide={setCurrentSlide}
            />
          </DialogContent>
          <SC.ModalAction>
            <SC.DiscussButton
              onClick={() => {
                onDiscussClick(images[currentSlide]);
                setInitCarouselIndex(0);
                setOpenImagesCarousel(false);
              }}
            >
              <SvgIcon component={discussIcon} />
              discuss this graph
            </SC.DiscussButton>
          </SC.ModalAction>
        </Box>
      </SC.FiguresDialog>
    </Box>
  );
};

export default FiguresListComponet;
