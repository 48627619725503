/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { GROUPS } = ActionTypes;

const INITIAL_STATE = {
  // Create group
  isCreatingData: false,
  onCreateError: null,
  onCreateSuccess: null,
  // Get all groups
  groups: [],
  isReceivingData: false,
  error: null,
  // Update group
  isUpdatingData: false,
  onUpdateError: null,
  onUpdateSuccess: null,
  // Delete group
  isDeletingData: false,
  onDeleteError: null,
  onDeleteSuccess: null,
  // Link group to roles
  isAddingGroupRoles: false,
  onGroupRolesAddError: null,
  onGroupRolesAddSuccess: null,
  // Unlink group from roles
  isDeletingGroupRoles: false,
  onGroupRolesDeleteError: null,
  onGroupRolesDeleteSuccess: null
};

const groupsReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Set Groups Data
    case GROUPS.SET.DATA:
      return {
        ...state,
        groups: action.payload
      };
    // Create Group
    case GROUPS.CREATE.START:
      return {
        ...state,
        isCreatingData: true,
        onCreateError: null
      };
    case GROUPS.CREATE.SUCCESS:
      return {
        ...state,
        onCreateSuccess: action.payload,
        isCreatingData: false
      };
    case GROUPS.CREATE.FAILURE:
      return {
        ...state,
        isCreatingData: false,
        onCreateError: action.payload
      };
    case GROUPS.CREATE.CLEAR:
      return {
        ...state,
        onCreateError: null,
        onCreateSuccess: null
      };
    // Get All Groups
    case GROUPS.FETCH.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case GROUPS.FETCH.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        groups: data && data.GetGroupRole,
        isReceivingData: false
      };
    }
    case GROUPS.FETCH.FAILURE:
      return {
        ...state,
        isReceivingData: false,
        error: action.payload
      };
    // Update Group
    case GROUPS.UPDATE.START:
      return {
        ...state,
        isUpdatingData: true,
        onUpdateError: null
      };
    case GROUPS.UPDATE.SUCCESS:
      return {
        ...state,
        onUpdateSuccess: action.payload,
        isUpdatingData: false
      };
    case GROUPS.UPDATE.FAILURE:
      return {
        ...state,
        isUpdatingData: false,
        onUpdateError: action.payload
      };
    case GROUPS.UPDATE.CLEAR:
      return {
        ...state,
        onUpdateError: null,
        onUpdateSuccess: null
      };
    // Delete Group
    case GROUPS.DELETE.START:
      return {
        ...state,
        isDeletingData: true,
        onDeleteError: null
      };
    case GROUPS.DELETE.SUCCESS:
      return {
        ...state,
        onDeleteSuccess: action.payload,
        isDeletingData: false
      };
    case GROUPS.DELETE.FAILURE:
      return {
        ...state,
        isDeletingData: false,
        onDeleteError: action.payload
      };
    case GROUPS.DELETE.CLEAR:
      return {
        ...state,
        onDeleteError: null,
        onDeleteSuccess: null
      };
    // Link Group to Roles
    case GROUPS.ROLES.ADD.START:
      return {
        ...state,
        isAddingGroupRoles: true,
        onGroupRolesAddError: null
      };
    case GROUPS.ROLES.ADD.SUCCESS:
      return {
        ...state,
        onGroupRolesAddSuccess: action.payload,
        isAddingGroupRoles: false
      };
    case GROUPS.ROLES.ADD.FAILURE:
      return {
        ...state,
        onGroupRolesAddError: action.payload,
        isAddingGroupRoles: false
      };
    case GROUPS.ROLES.ADD.CLEAR:
      return {
        ...state,
        onGroupRolesAddError: null,
        onGroupRolesAddSuccess: null
      };
    // Unlink Group from Roles
    case GROUPS.ROLES.DELETE.START:
      return {
        ...state,
        isDeletingGroupRoles: true,
        onGroupRolesDeleteError: null
      };
    case GROUPS.ROLES.DELETE.SUCCESS:
      return {
        ...state,
        onGroupRolesDeleteSuccess: action.payload,
        isDeletingGroupRoles: false
      };
    case GROUPS.ROLES.DELETE.FAILURE:
      return {
        ...state,
        onGroupRolesDeleteError: action.payload,
        isDeletingGroupRoles: false
      };
    case GROUPS.ROLES.DELETE.CLEAR:
      return {
        ...state,
        onGroupRolesDeleteError: null,
        onGroupRolesDeleteSuccess: null
      };
    default: return state;
  }
};

export default groupsReducerFn;
