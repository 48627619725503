import { InMemoryCache } from "@apollo/client";

// Reactive Vars
import { appErrorDataVar } from "errors/app.errors";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        appErrorData: {
          read() {
            return appErrorDataVar();
          },
        },
      },
    },
    PermissionData: { keyFields: false },
  },
});

export default cache;
