// Dependencies
import { withStyles, Box as BoxBase } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

const Box = withStyles({})(BoxBase);

export default {
  Box,
  RoomIcon,
};
