import { Box, Grid, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ButtonPrimary from "components/button-primary/button-primary.component";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";

const CatalogContainer = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "1625px",
    width: "100%",
    height: "100vh",
    padding: "50px",
    background: "transparent",
  },
}))(Box);

const TopicsCarousel = withStyles(() => ({
  root: {
    height: "76px",
    marginBottom: "76px",
  },
}))(CarouselGeneric);

const GridContainer = withStyles(() => ({
  root: {
    rowGap: "44px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
}))(Grid);

const GridItem = withStyles(() => ({
  root: {
    background: "transparent",
    boxShadow: "unset !important",
    border: "unset !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
}))(Grid);

const MoreVideosContainer = withStyles(() => ({
  root: {
    display: "flex",
    paddingTop: "193px",
    paddingBottom: "50px",
  },
}))(Box);

const MoreVideosButton = withStyles((theme: Theme) => ({
  root: {
    width: theme.buttonSize.large.width,
    height: theme.buttonSize.large.height,
    textTransform: "uppercase",
    margin: "auto",
  },
}))(ButtonPrimary);

export default {
  CatalogContainer,
  TopicsCarousel,
  GridContainer,
  GridItem,
  MoreVideosContainer,
  MoreVideosButton,
};
