// Dependencies
import { withStyles, Box } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import AutofillField from "components/autofill-field/autofill-field.component";

const drawerWidth = 240;

const SearchBoxFixed = withStyles({
  root: {
    width: "100%",
  },
})(AutofillField) as typeof AutofillField;

const LeftPaneContainer = withStyles({
  root: {
    display: "flex",
  },
})(Box);

const DrawerBody = withStyles({
  root: { width: drawerWidth, flexShrink: 0 },
  paper: { width: drawerWidth },
})(Drawer);

export default {
  SearchBoxFixed,
  LeftPaneContainer,
  DrawerBody,
};
