import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: 'Query';
  getRecommendations?: Maybe<Recommendations>;
};


export type QueryGetRecommendationsArgs = {
  n?: Maybe<Scalars['Int']>;
  media?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Recommendations = {
  __typename?: 'Recommendations';
  recommendations?: Maybe<Array<Maybe<Media>>>;
  nullMedias?: Maybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  audionautID?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  platformID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
};

export type GetMediasRecommendationsQueryVariables = Exact<{
  mediasIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  total?: Maybe<Scalars['Int']>;
}>;


export type GetMediasRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getRecommendations?: Maybe<(
    { __typename?: 'Recommendations' }
    & { recommendations?: Maybe<Array<Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'audionautID' | 'url' | 'source' | 'platformID' | 'title' | 'author'>
    )>>> }
  )> }
);


export const GetMediasRecommendationsDocument = gql`
    query getMediasRecommendations($mediasIds: [String], $total: Int) {
  getRecommendations(n: $total, media: $mediasIds) {
    recommendations {
      audionautID
      url
      source
      platformID
      title
      author
    }
  }
}
    `;

/**
 * __useGetMediasRecommendationsQuery__
 *
 * To run a query within a React component, call `useGetMediasRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediasRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediasRecommendationsQuery({
 *   variables: {
 *      mediasIds: // value for 'mediasIds'
 *      total: // value for 'total'
 *   },
 * });
 */
export function useGetMediasRecommendationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMediasRecommendationsQuery, GetMediasRecommendationsQueryVariables>) {
        return Apollo.useQuery<GetMediasRecommendationsQuery, GetMediasRecommendationsQueryVariables>(GetMediasRecommendationsDocument, baseOptions);
      }
export function useGetMediasRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediasRecommendationsQuery, GetMediasRecommendationsQueryVariables>) {
          return Apollo.useLazyQuery<GetMediasRecommendationsQuery, GetMediasRecommendationsQueryVariables>(GetMediasRecommendationsDocument, baseOptions);
        }
export type GetMediasRecommendationsQueryHookResult = ReturnType<typeof useGetMediasRecommendationsQuery>;
export type GetMediasRecommendationsLazyQueryHookResult = ReturnType<typeof useGetMediasRecommendationsLazyQuery>;
export type GetMediasRecommendationsQueryResult = Apollo.QueryResult<GetMediasRecommendationsQuery, GetMediasRecommendationsQueryVariables>;