import React from "react";

// GraphQL
import {
  useGetCurrentUserPermissionsQuery,
  GetCurrentUserPermissionsQuery,
  PermissionType,
} from "graphql/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

export const APP_PERMISSION = {
  HOME: "Home",
  HOME_VIEW: "Home.View Home",

  EXPLORE: "Explore",
  EXPLORE_VIEW: "Explore.View Explore",
  EXPLORE_VIEW_AUDIOS: "Explore.View Audios",
  EXPLORE_ADD_AUDIOS: "Explore.Add Audios",
  EXPLORE_DELETE_AUDIOS: "Explore.Delete Audios",

  ANALYZE: "Analyze",
  ANALYZE_VIEW: "Analyze.View Analyze",
  ANALYZE_USE_SEARCH: "Analyze.Use Search",
  ANALYZE_VIEW_BEHAVIORS_SETTINGS: "Analyze.View Behaviors Settings",
  ANALYZE_EDIT_BEHAVIORS_SETTINGS: "Analyze.Edit Behaviors Settings",
  ANALYZE_SEE_BEHAVIORS_RESULTS: "Analyze.See Behaviors Results",

  TARGET: "Target",
  TARGET_VIEW: "Target.View Target",
  TARGET_TARGET_MEDIA: "Target.Target Media",
  TARGET_VIEW_TARGET_GROUPS: "Target.View Target Groups",
  TARGET_ADDEDIT_TARGET_GROUPS: "Target.Add/Edit Target Groups",

  SUBSCRIPTION_MANAGEMENT: "Subscription Management",
  SUBSCRIPTION_MANAGEMENT_VIEW:
    "Subscription Management.View Subscription Management",
  SUBSCRIPTION_MANAGEMENT_MANAGE_AUDIONAUT_PLAN:
    "Subscription Management.Manage Account Audionaut Plan",
  SUBSCRIPTION_MANAGEMENT_VIEW_PAYMENT_METHODS:
    "Subscription Management.View Payment Methods",
  SUBSCRIPTION_MANAGEMENT_ADDEDIT_PAYMENT_METHODS:
    "Subscription Management.Add/Edit Payment Methods",
  SUBSCRIPTION_MANAGEMENT_VIEW_BILLING_ADDRESSES:
    "Subscription Management.View Billing Addresses",
  SUBSCRIPTION_MANAGEMENT_ADDEDIT_BILLING_ADDRESSES:
    "Subscription Management.Add/Edit Billing Addresses",

  ADMIN: "Administration",
  ADMIN_EXPORT: "Administration.Export",
  ADMIN_ADDEDIT_USERS: "Administration.Add/Edit Users",
  ADMIN_VIEW_DATA_PERMISSIONS: "Administration.View Data Permissions",
  ADMIN_EDIT_DATA_PERMISSIONS: "Administration.Edit Data Permissions",
  ADMIN_SET_VISIBILITY_LEVEL: "Administration.Set Visibility Level",
} as const;

export type AppPermissionKey = keyof typeof APP_PERMISSION;
export type AppPermission = typeof APP_PERMISSION[AppPermissionKey];
export type PermissionsQuery =
  GetCurrentUserPermissionsQuery["GetCurrentUserPermissions"];

export const isAppPermissionValid = (
  permissionsList: PermissionsQuery,
  appPermission: AppPermission
): boolean => {
  const permissionIDs = appPermission.split(".");
  let appPermissionsIsValid = false;

  const parentPermission = permissionsList.find((permission) => {
    const categoryName = permissionIDs[0];
    return permission?.name === categoryName;
  });

  if (parentPermission?.enabled && permissionIDs.length > 1) {
    const childPermission = parentPermission?.children?.find((permission) => {
      const permissionName = permissionIDs[1];
      return permission.name === permissionName;
    });

    if (childPermission) {
      appPermissionsIsValid = childPermission.enabled ?? false;
    } else {
      // Invalid App Permission
      appPermissionsIsValid = false;
    }
  } else {
    appPermissionsIsValid = parentPermission?.enabled ?? false;
  }

  return appPermissionsIsValid;
};

export function useAppPermissionValidator():
  | ((appPermission: AppPermission) => boolean)
  | undefined {
  const { data, error } = useGetCurrentUserPermissionsQuery({
    variables: {
      type: PermissionType.Application,
    },
  });

  useAppErrorHandler(error);

  if (!data) return undefined;

  return (appPermission: AppPermission): boolean =>
    isAppPermissionValid(data.GetCurrentUserPermissions, appPermission);
}

export interface AppPermissionValidatorProps {
  appPermission: AppPermission;
  loadingComponent?: JSX.Element;
}

export const AppPermissionValidator: React.FC<AppPermissionValidatorProps> = ({
  appPermission,
  loadingComponent = null,
  children,
}): JSX.Element => {
  const validator = useAppPermissionValidator();

  if (!validator) return <>{loadingComponent}</>;

  return <>{validator(appPermission) ? children : null}</>;
};

export default AppPermissionValidator;
