// Dependencies
import React from "react";
import { AlertProps as AlertPropsBase } from "@material-ui/lab/Alert";

// Assets
import SC from "./alert.styles";

export interface AlertProps extends AlertPropsBase {
  message?: string;
}

export const Alert: React.FC<AlertProps> = ({ message, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SC.AlertCustom elevation={6} variant="filled" {...props}>
    {message}
  </SC.AlertCustom>
);

export default Alert;
