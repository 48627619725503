/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint no-underscore-dangle: 0 */
// Dependencies
import React, { useMemo } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import TagManager from "react-gtm-module";

import { v4 as uuidv4 } from "uuid";
import { useUserDataStore } from "commons/utils/user-data";

// Pages
import MainPage from "pages/main/main.component";
import SignInComponent from "pages/signin/signin.component";
import ForgotPasswordScreen from "pages/forgot-password/forgot-password.component";
import ResetPasswordScreen from "pages/reset-password/reset-password.component";
import SignupScreen from "pages/signup/signup.component";
import PublicSearchPage from "pages/public-search/public-search.component";
import MediasCatalog from "pages/medias-catalog/medias-catalog.component";
import MediasCarousel from "pages/medias-carousel/medias-carousel.component";

// Components
import ErrorBoundary from "components/error-boundary/error-boundary.component";

// Graphql
import auth from "graphql/authentication";

import {
  useGetCurrentSubscriptionInfoQuery,
  useGetCurrentUserOrganisationCurrentPeriodSubscriptionLazyQuery,
  useGetReferralLinkByCodeLazyQuery,
  useUpdateReferredStatusMutation,
} from "graphql/types-and-hooks";
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import { HandleGlobalEvents } from "commons/utils/handle-global-events";

import EmailVerification from "../../pages/email-verification/email-verification.component";

import SC from "./app.styles";

const { REACT_APP_GTM_ID_CORPORATUS } = process.env;

const AppRoutes: React.FC = () => {
  const { isLoggedIn } = auth.useAuthenticatioData();
  const setGlobalUserData = useUserDataStore((state: any) => state.setUserData);
  const userData = useUserDataStore((state: any) => state.userData);
  const userDataLocal = useMemo(() => userData, [userData]);

  const queryParms = useLocation().search;
  const planName = new URLSearchParams(queryParms).get("plan");
  const planUpgrade = new URLSearchParams(queryParms).get("upgrade");
  const referralCode = new URLSearchParams(queryParms).get("code");

  const planPreselected = React.useMemo(() => {
    return !!localStorage.getItem("selectedPlan") &&
      localStorage.getItem("selectedPlan") !== "chatFree"
      ? localStorage.getItem("selectedPlan")
      : "";
  }, []);
  const [currentPlanSelected, setCurrrentPlanSelected] =
    React.useState(planPreselected);

  const tagManagerArgs = useMemo(
    () => ({
      gtmId: REACT_APP_GTM_ID_CORPORATUS ?? "",
    }),
    []
  );

  const [
    getCurrentPeriodSubscription,
    {
      data: currentPeriodSubscriptionData,
      loading: currentPeriodSubscriptionLoading,
      error: currentPeriodSubscriptionError,
    },
  ] = useGetCurrentUserOrganisationCurrentPeriodSubscriptionLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [
    getReferralLink,
    {
      data: getReferralLinkData,
      loading: getReferralLinkLoading,
      error: getReferralLinkError,
    },
  ] = useGetReferralLinkByCodeLazyQuery();
  const [updateReferredStatus] = useUpdateReferredStatusMutation();

  const errorHandler = useAppErrorHandler(currentPeriodSubscriptionError);

  React.useEffect(() => {
    if (isLoggedIn && !userDataLocal.uuid) {
      try {
        getCurrentPeriodSubscription();

        const referralCode2 = localStorage.getItem("referralCode");
        if (referralCode2) {
          getReferralLink({
            variables: {
              code: referralCode2,
            },
          });
        }
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the group"
            )
          );
        }
      }

      setGlobalUserData({ ...userDataLocal, uuid: uuidv4() });
      // console.debug("======> start app!!!", userDataLocal);
    }
  }, [
    isLoggedIn,
    getCurrentPeriodSubscription,
    errorHandler,
    setGlobalUserData,
    userDataLocal,
    getReferralLink,
  ]);

  // This will run one time after the component mounts
  React.useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      // do something else
      TagManager.initialize(tagManagerArgs);
      window.removeEventListener("load", onPageLoad);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      // Remove the event listener when component unmounts
      // window.removeEventListener('load', onPageLoad);
    }
  }, [tagManagerArgs]);

  React.useEffect(() => {
    if (planName) {
      // console.debug("=====> Plan Name: ", planName);
      localStorage.setItem("selectedPlan", planName);
      setCurrrentPlanSelected(planName);
    }
  }, [planName]);

  React.useEffect(() => {
    if (planUpgrade) {
      localStorage.setItem("planUpgrade", planUpgrade);
    }
  }, [planUpgrade]);

  React.useEffect(() => {
    if (referralCode) {
      localStorage.setItem("referralCode", referralCode);
    }
  }, [referralCode]);

  React.useEffect(() => {
    if (getReferralLinkData && getReferralLinkData.GetReferredLinkByCode) {
      const { id } = getReferralLinkData.GetReferredLinkByCode;
      updateReferredStatus({
        variables: {
          referralLinkId: id ?? "",
          status: "Signed Up",
        },
      });
    }
  }, [getReferralLinkData, updateReferredStatus]);

  return (
    <Switch>
      <Route path="/widget">
        <MediasCarousel />
      </Route>
      <Route path="/catalog">
        <MediasCatalog />
      </Route>
      <Route path="/login">
        {isLoggedIn ? <Redirect to="/" /> : <SignInComponent />}
      </Route>
      <Route path="/signup">
        {isLoggedIn ? <Redirect to="/" /> : <SignupScreen />}
      </Route>
      <Route path="/forgot-password">
        {isLoggedIn ? <Redirect to="/" /> : <ForgotPasswordScreen />}
      </Route>
      <Route path="/reset-password/:code">
        {isLoggedIn ? <Redirect to="/" /> : <ResetPasswordScreen />}
      </Route>
      <Route path="/email-verification/:code">
        {isLoggedIn ? <Redirect to="/" /> : <EmailVerification />}
      </Route>

      <Route path="/">
        {/* <MainPage planPreSelected={currentPlanSelected} /> */}
        {isLoggedIn ? (
          <MainPage planPreSelected={currentPlanSelected} />
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
      <Route path="/public-search">
        {isLoggedIn ? <PublicSearchPage /> : <Redirect to="/login" />}
        {/* <PublicSearchPage /> */}
      </Route>
    </Switch>
  );
};

const App: React.FC = () => {
  const appRef = React.useRef<HTMLBodyElement>(null);

  const [removeStylesTimes, setRemovedStylesTimes] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState<any>(-1);

  const clearStylesInjected1 = React.useCallback(
    (currentRef, currentIntervalId) => {
      // console.debug(
      //   "=====> clearStylesInjected1!!!",
      //   currentRef && currentRef.current
      //     ? currentRef.current.attributes.getNamedItem("style")
      //     : currentRef
      // );
      // console.debug("======> interval id: ", currentIntervalId);
      if (
        currentRef &&
        currentRef.current &&
        currentRef.current.attributes &&
        currentRef.current.attributes.getNamedItem("style") &&
        currentRef.current.attributes.getNamedItem("style")?.value
      ) {
        currentRef.current.attributes.removeNamedItem("style");
        // clearInterval(currentIntervalId);
        // setIntervalId(-1);
        // console.debug("=====> cleaning the interval id 1", currentIntervalId);
      }

      setRemovedStylesTimes((oldTime) => {
        const newTime = oldTime + 1;
        if (newTime > 4) {
          clearInterval(currentIntervalId);
          setIntervalId(-1);
          // console.debug("=====> cleaning the interval id 2", currentIntervalId);
        }
        return newTime;
      });
    },
    []
  );

  React.useEffect(() => {
    // console.debug("=====> This is mounted APPComponent!!!");
    if (appRef && appRef.current) {
      const newIntervalId = setInterval(() => {
        clearStylesInjected1(appRef, newIntervalId);
      }, 3000);
      setIntervalId(newIntervalId);
      // console.debug("====> newIntervalId", newIntervalId);

      const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();

      currentHandleGlobalEvents.checkinginlineStyles$.subscribe((check) => {
        // console.debug("=====> Re checking the injected styles appRef: ", check);
        if (check) {
          if (intervalId === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected1(appRef, newIntervalId2);
            }, 3000);
            setIntervalId(newIntervalId2);
          }
        }
      });
      currentHandleGlobalEvents.checkingStylesSummary$.subscribe((check) => {
        // console.debug("=====> Re checking the injected styles appRef: ", check);
        if (check) {
          if (intervalId === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected1(appRef, newIntervalId2);
            }, 3000);
            setIntervalId(newIntervalId2);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRef]);

  return (
    <SC.App ref={appRef}>
      <ErrorBoundary>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ErrorBoundary>
    </SC.App>
  );
};

export default App;
