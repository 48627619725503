/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  Box as BoxMui,
  BoxProps,
  Button as ButtonBase,
  Box,
} from "@material-ui/core/";

const CardBox = withStyles({
  root: {
    "& .cardPreview": {
      display: "flex",
      flexDirection: "column",
      height: "145px",
      width: "250px",
      margin: "auto",
      padding: "11px 16px",
      background: "linear-gradient(109.58deg, #B8CAC6 0%, #65DFBD 100%)",
      borderRadius: "5px",
    },
    "& .cardTitle": {
      display: "flex",
      justifyContent: "space-between",
      "& p": {
        fontFamily: "'Sarabun'",
        fontSize: "13px",
        lineHeight: "21px",
        fontWeight: "normal",
        letterSpacing: "0.1em",
        color: "#FFF",
        textAlign: "right",
      },
    },
    "& .cardNumber": {
      display: "flex",
      flex: "1",
      alignItems: "center",
      fontFamily: "'Sarabun'",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      justifyContent: "center",
      letterSpacing: "3px",
      color: "#FFFFFF",
    },
    "& .cardFooter": {
      display: "flex",
      justifyContent: "space-between",
      "& .expires": {
        "& p": {
          textAlign: "right",
        },
      },
      "& .cardHolder, .expires": {
        "& p": {
          fontFamily: "'Sarabun'",
          textTransform: "uppercase",
          color: "#FFF",
          textAlign: "left",
        },
        "& .title": {
          fontWeight: "lighter",
          fontSize: "10px",
          lineHeight: "14px",
          letterSpacing: "0.4px",
        },
        "& .value": {
          fontWeight: "normal",
          fontSize: "13px",
          lineHeight: "20px",
          letterSpacing: "0.1px",
        },
      },
    },
  },
})(Box);

const FormActions = withStyles({
  root: {
    display: "flex",
    justifyContent: "rigth",
    marginTop: "40px",
    padding: "0px",
  },
})(Box);

const Button = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    width: "144px",
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    margin: "auto",
    marginLeft: "0px !important",
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
  contained: {
    color: "#FFFFFF",
    backgroundColor: "#B8CAC6",
  },
  label: {
    textTransform: "capitalize",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "bold",
  },
}))(ButtonBase);

// const InputLabel = withStyles({
//   root: {
//     textAlign: "left",
//     transform: "translate(12px, 10px) scale(0.75)",
//   },
// })(InputLabelMui);

export default {
  CardBox,
  FormActions,
  Button,
};
