/* eslint-disable react/jsx-props-no-spreading */
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& h1": {
        flex: "1",
      },
      [theme.breakpoints.down(540)]: {
        position: "absolute",
        width: "calc(100vw - 40px)",
        marginBottom: "10px",
        maxHeight: "30px",
        "& h1": {
          fontSize: theme.fontGeneralStyles.large.fontSize,
          lineHeight: theme.fontGeneralStyles.large.lineHeight,
          fontWeight: 600,
          margin: "unset",
        },
      },
    },
    updateBtn: {
      height: "30px",
      borderRadius: "15px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      "&:hover": {
        background: "#8773B1",
      },
    },
    behaviorName: {
      "& div": {
        background: "transparent",
        marginBottom: "10px",
        "&:hover": {
          background: "transparent",
        },
      },
      "& input": {
        color: "#000000",
        fontSize: theme.fontGeneralStyles.medium.fontSize,
        lineHeight: theme.fontGeneralStyles.medium.lineHeight,
        fontWeight: 800,
        fontStyle: "normal",
        fontFamily: "Gothic A1",
      },
      "& .MuiFilledInput-input": {
        padding: "18px 13px",
        border: "1px solid rgba(0, 0, 0, 0.36)",
        borderRadius: "4px",
      },
      [theme.breakpoints.down(540)]: {
        "& input": {
          fontSize: theme.fontGeneralStyles.small.fontSize,
          lineHeight: theme.fontGeneralStyles.small.lineHeight,
          fontWeight: 600,
        },
        "& .MuiFilledInput-input": {
          padding: "15px 10px",
        },
      },
    },
    loader: {
      margin: "auto",
    },
    adviseMessage: {
      flex: "1",
      "& .MuiPaper-root": {
        height: "100%",
        paddingBottom: "30px",
        boxShadow: "none",
        background: "transparent",
      },
    },
  })
);

const ASContainer = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    [theme.breakpoints.down(540)]: {
      overflowX: "auto",
    },
  },
}))(Box);

const ASContent = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    padding: "30px 30px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      width: "fit-content",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
  },
}))(Box);

const BehaviorBg = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    padding: "20px 16px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginTop: "50px",
    "& .header": {
      display: "contents",
      "& span.subTitle": {
        fontSize: "16px",
        textAlign: "left",
        fontWeight: "400",
        color: "#B8CAC6",
        marginBottom: "10px",
      },
      "& .leading": {
        padding: "3px 10px",
        background: "rgba(142, 92, 163, 0.05)",
        border: "1px solid #8E5CA3",
        margin: "20px 0px",
        borderRadius: "4px",
        marginRight: "auto",
        marginLeft: "0",
        display: "flex",
        alignItems: "center",
        "& span.text": {
          fontSize: "16px",
          fontWeight: "400",
          color: "#504372",
        },
      },
    },
    "& .content": {
      width: "100%",
      height: "100%",
      "& .query-builder": {
        margin: "unset !important",
      },
      "& .group-container": {
        "& .group": {
          padding: "12px 18px 12px 20px",
          background: "rgba(149, 169, 232, 0.03)",
          border: "1px solid #95A9E8",
          borderRadius: "4px",
          "& .group--header": {
            marginBottom: "16px !important",
            "& .group--conjunctions": {
              margin: "unset !important",
              "& .MuiButtonGroup-root": {
                "& button": {
                  boxSizing: "border-box",
                  borderRadius: "4px",
                  fontFamily: "Gothic A1",
                  fontStyle: "normal",
                  fontWeight: "800",
                  fontSize: "14px",
                  lineHeight: "17px",
                  height: "30px",
                  width: "46px",
                  "& .MuiButton-containedPrimary": {
                    background: "#869DE5",
                    border: "1px solid #869DE5",
                    color: "#FFFFFF",
                  },
                },
              },
            },
            "& .group--actions": {
              opacity: "unset",
              "& button": {
                height: "30px",
                width: "135px",
                background: "#F5F7FD",
                border: "1px solid #869DE5",
                boxSizing: "border-box",
                borderRadius: "4px",
                fontFamily: "Gothic A1",
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "14px",
                lineHeight: "17px",
                color: "#514372",
                "&:first-child": {
                  marginRight: "14px",
                },
              },
              "& button.MuiIconButton-root": {
                width: "unset",
                background: "transparent",
                border: "none",
                color: "#8E5CA3",
              },
            },
          },
          "& .group--children": {
            padding: "0px !important",
            "& .group-or-rule-container": {
              marginBottom: "16px",
            },
            "& .group-or-rule-container:first-child .group-or-rule::before": {
              top: "-32px",
              height: "calc(50% + 32px)",
            },
            "& .group-or-rule-container .group-or-rule::after": {
              height: "calc(50% + 16px)",
            },
            "& .rule": {
              background: "rgba(142, 92, 163, 0.05)",
              border: "1px solid #8E5CA3",
              borderRadius: "4px",
              minHeight: "50px",
              alignItems: "center",
              "& .rule--body": {
                textAlign: "start",
                "& .rule--field, .rule--operator": {
                  "& .MuiInput-formControl": {
                    border: "1px solid #8E5CA3",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    "& .MuiInput-underline": {
                      "& ::before": {
                        border: "none !important",
                      },
                      "& :hover:not(.Mui-disabled):before": {
                        border: "none !important",
                      },
                    },
                    "& input, .MuiSelect-root": {
                      paddingLeft: "18px",
                    },
                    "& .MuiAutocomplete-endAdornment": {
                      "& button:last-child": {
                        paddingRight: "6px",
                        color: "#8E5CA3",
                      },
                    },
                  },
                },
                "& .rule--value": {
                  "& .MuiInputBase-root": {
                    background: "#FFFFFF",
                    border: "1px solid #8E5CA3",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  },
                  "& .rule--widget--DATETIME": {
                    "& .widget--sep": {
                      fontFamily: "Gothic A1",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      textAlign: "center",
                      color: "#514372",
                    },
                    "& .widget--widget": {
                      maxWidth: "200px",
                      "& .MuiInputBase-root": {
                        paddingRight: "7px",
                      },
                      "& .MuiInputAdornment-root": {
                        "& button": {
                          color: "#50437299",
                          paddingRight: "0px",
                        },
                      },
                    },
                  },
                },
                "& .ant-select-selector": {
                  fontFamily: "Gothic A1",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#514372",
                },
                "& .ant-select-arrow": {
                  color: "#8E5CA3",
                },
              },
              "& .rule--header": {
                opacity: "1",
                "& .MuiIconButton-root": {
                  color: "#8E5CA3",
                },
              },
            },
          },
        },
      },
    },
    [theme.breakpoints.down(540)]: {
      flex: "1",
      height: "calc(100% - 40px)",
      marginTop: "40px",
      padding: "10px",
      "& .content": {
        overflowY: "auto",
        height: "100%",
        "& .group-container": {
          "& .group": {
            padding: "10px !important",
            "& .group--header": {
              "& .group--actions": {
                gap: "10px",
                "& button": {
                  width: "100px !important",
                  margin: "unset !important",
                  fontSize: `${theme.fontGeneralStyles.xSmall.fontSize} !important`,
                  lineHeight: `${theme.fontGeneralStyles.xSmall.lineHeight} !important`,
                },
              },
            },
          },
        },
      },
    },
  },
}))(Box);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    margin: "auto",
    marginLeft: "0px !important",
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
    [theme.breakpoints.down(540)]: {
      height: "30px",
    },
  },
}))(Button);

const BehaviorNameContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down(540)]: {
      position: "sticky",
      top: "0px",
      background: "#FFF",
      zIndex: "2",
    },
  },
}))(Box);

const NoResults = withStyles(() => ({
  root: {
    height: "100%",
    width: "calc(100% - 60px)",
    margin: "auto",
    marginTop: "0px",
    marginBottom: "30px",
  },
}))(GenericEmptyMessages);

export default {
  useStyles,
  ASContainer,
  ASContent,
  BehaviorBg,
  ButtonPrimary,
  BehaviorNameContainer,
  NoResults,
};
