/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React, { useMemo } from "react";
import TagManager from "react-gtm-module";

import {
  ClickAwayListener,
  Popper,
  MenuList,
  Typography,
  Box,
  Divider,
  MenuItem,
  Popover,
  SvgIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { ReactComponent as billingIcon } from "assets/icons/payments-icon.svg";
import { ReactComponent as ReferralIcon } from "assets/icons/gift_icon.svg";

import { useUserDataStore } from "commons/utils/user-data";
import { isMobileResolution } from "commons/utils/device-info.util";

import {
  useGetCurrentSubscriptionInfoQuery,
  useGetCurrentUserProfileQuery,
} from "graphql/types-and-hooks";
import { logOut } from "../../graphql/authentication";

// Assets
import SC from "./user-menu.styles";

interface UserMenuInterface {
  collapsed?: boolean;
}

const UserMenu: React.FC<UserMenuInterface> = ({ collapsed = true }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const {
    data,
    loading: getUserProfileLoading,
    error,
  } = useGetCurrentUserProfileQuery({
    fetchPolicy: "no-cache",
  });

  const {
    data: currentSubscriptionInfoData,
    loading: currentSubscriptionInfoLoading,
    error: currentSubscriptionInfoError,
  } = useGetCurrentSubscriptionInfoQuery();

  const userHasActiveSubscription = React.useMemo(
    () =>
      currentSubscriptionInfoData &&
      currentSubscriptionInfoData?.getCustomerSubscriptionInfo !== null &&
      currentSubscriptionInfoData?.getCustomerSubscriptionInfo
        ?.hasActiveMeteredSubscription,
    [currentSubscriptionInfoData]
  );

  const setGlobalUserData = useUserDataStore((state: any) => state.setUserData);
  const userData = useUserDataStore((state: any) => state.userData);
  const userDataLocal = useMemo(() => userData, [userData]);

  // useGetUserProfileQuery({
  //   variables: {
  //     // TODO: The email should be extracted from the JWT token claims.
  //     email: "owner@audionaut.ai",
  //   },
  // });
  // const initialUserData = data?.GetUser[0];

  const initialUserData = data?.GetCurrentUserProfile;

  const handleOpenMenu = (): void => {
    setOpen(true);
  };

  const handleCloseMenu = (event: React.ChangeEvent<any>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const HandleLogout = (event: React.ChangeEvent<any>) => {
    logOut();
    setGlobalUserData({
      profilePicture: "",
    });
  };

  React.useEffect(() => {
    // console.debug("=====> User Data: ", userDataLocal, data);
    if (
      data &&
      data.GetCurrentUserProfile &&
      !userDataLocal.id &&
      !userDataLocal.profilePicture
    ) {
      setGlobalUserData({ ...userDataLocal, ...data.GetCurrentUserProfile });
      TagManager.dataLayer({
        dataLayer: {
          userId: data.GetCurrentUserProfile.id,
          email: data.GetCurrentUserProfile.email,
          userName: data.GetCurrentUserProfile.firstName,
          organizationName: data.GetCurrentUserProfile.organisation?.name,
        },
      });
    }
  }, [data, setGlobalUserData, userDataLocal]);

  return (
    <SC.UserMenu
      className={`${collapsed ? "single-menu" : ""} ${
        isMobileResolution() ? "mobile-user-menu" : ""
      }`}
    >
      <SC.ButtonIcon
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={collapsed ? "single-icon" : ""}
        onClick={() => {
          if (collapsed || isMobileResolution()) {
            handleOpenMenu();
          }
        }}
      >
        {initialUserData?.profilePicture ? (
          <SC.UserAvatar src={initialUserData?.profilePicture} />
        ) : (
          <SC.UserIcon />
        )}
        <Box>
          <SC.UserNameElem
            component="p"
            hidden={isMobileResolution() || collapsed}
          >
            {initialUserData?.firstName}
          </SC.UserNameElem>

          <SC.UserEmail
            component="p"
            hidden={isMobileResolution() || collapsed}
          >
            {initialUserData?.email}
          </SC.UserEmail>
        </Box>
        <SC.MenuIcon
          hidden={isMobileResolution() || collapsed}
          startIcon={<MoreHorizOutlinedIcon />}
          onClick={handleOpenMenu}
        />
      </SC.ButtonIcon>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: isMobileResolution() ? "bottom" : "top",
          horizontal: isMobileResolution() ? "left" : "right",
        }}
        transformOrigin={{
          vertical: isMobileResolution() ? "top" : "bottom",
          horizontal: isMobileResolution() ? "right" : "left",
        }}
      >
        <SC.MenuChildContainer>
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              style={{ padding: "unset" }}
            >
              <SC.UsernameItem>
                {initialUserData?.profilePicture ? (
                  <SC.UserAvatar src={initialUserData?.profilePicture} />
                ) : (
                  <SC.UserIcon />
                )}
                <Box>
                  <SC.UserNameElem component="p">
                    {initialUserData?.firstName}
                  </SC.UserNameElem>

                  <SC.UserEmail component="p" className="email">
                    {initialUserData?.email}
                  </SC.UserEmail>
                </Box>
              </SC.UsernameItem>
              <Divider style={{ marginTop: "16px", marginBottom: "9px" }} />
              <SC.Option
                onClick={handleCloseMenu}
                component={Link}
                to="/my-profile"
              >
                <PersonIcon /> My Account
              </SC.Option>
              <SC.Option
                hidden={!userHasActiveSubscription}
                onClick={handleCloseMenu}
                component={Link}
                to="/subscription/manage"
              >
                <SvgIcon component={billingIcon} /> Billing
              </SC.Option>
              <SC.Option
                onClick={handleCloseMenu}
                component={Link}
                to="/referrals"
              >
                <SvgIcon component={ReferralIcon} /> Referrals
              </SC.Option>
              <Divider style={{ marginTop: "9px", marginBottom: "16px" }} />
              <SC.Option onClick={HandleLogout} component={Link} to="/">
                <ExitToAppIcon /> Log out
              </SC.Option>
              {/* <SC.Option
                    onClick={handleCloseMenu}
                    component={Link}
                    to="/undefined2"
                  >
                    Provide Feedback
                  </SC.Option> */}
            </MenuList>
          </ClickAwayListener>
        </SC.MenuChildContainer>
      </Popover>
      <Popper
        hidden
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="right-end"
      >
        {({ TransitionProps, placement }) => (
          <SC.TransitionAnimation
            {...TransitionProps}
            isActive={placement === "right-end"}
          >
            <SC.MenuChildContainer>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  style={{ paddingLeft: "2px", paddingRight: "2px" }}
                >
                  <SC.UsernameItem>
                    <SC.UserNameElem
                      component="p"
                      hidden={!isMobileResolution()}
                    >
                      {initialUserData?.firstName}
                    </SC.UserNameElem>
                  </SC.UsernameItem>
                  <SC.Option
                    onClick={handleCloseMenu}
                    component={Link}
                    to="/my-profile"
                  >
                    View Profile
                  </SC.Option>
                  <SC.Option onClick={HandleLogout} component={Link} to="/">
                    Sign Out
                  </SC.Option>
                  {/* <SC.Option
                    onClick={handleCloseMenu}
                    component={Link}
                    to="/undefined2"
                  >
                    Provide Feedback
                  </SC.Option> */}
                </MenuList>
              </ClickAwayListener>
            </SC.MenuChildContainer>
          </SC.TransitionAnimation>
        )}
      </Popper>
    </SC.UserMenu>
  );
};

export default UserMenu;
