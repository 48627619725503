import React, { useCallback, useEffect } from "react";
import {
  createStyles,
  Theme,
  useTheme,
  makeStyles,
  createTheme,
} from "@material-ui/core/styles";
import {
  Box,
  Divider,
  Chip,
  CardMedia,
  Drawer,
  Tooltip,
  IconButton,
  SvgIcon,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  CircularProgress,
  ListItem,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateNext from "@material-ui/icons/NavigateNext";

import { UserFilteringLeftPane } from "components/user-filtering-left-pane/user-filtering-left-pane.component";
import AudionautCharts from "components/charts/charts.component";

import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

// Data temp just for test filter
import {
  UserData,
  GroupData,
  useGetAllUsersQuery,
  useGetGroupQuery,
  useCreateTargetMutation,
  useGetTargetBySourceIdQuery,
  useGetAllTargetsGroupsQuery,
} from "graphql/types-and-hooks";
import {
  createEmptyTarget,
  NEW_TARGET_FRAGMENT_GQL,
} from "graphql/audionaut.utils";

import { useSearchEntityQuery } from "graphql/search/types-and-hooks";
// import { useQuestionsAndAnswerQuery } from "graphql/search/qanda-types-and-hooks";
import { CLIENT_NAME } from "graphql/client";
// End temp data

import playImage from "assets/images/play_audio.png";
import chartImage from "assets/images/Chart.png";
import { ReactComponent as FilterAlt } from "assets/icons/filter_alt-black-24dp.svg";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import Paginator from "components/paginator/paginator.component";
import ScrollToTopButton from "components/scrollToTop-button/scrollToTop-button.component";

import { getFormattedDate } from "commons/utils/date-format";
import { isMobileResolution } from "commons/utils/device-info.util";

import { ReactComponent as totalAudioIcon } from "../../assets/icons/total_audio.svg";
import { ReactComponent as totalEntitiesIcon } from "../../assets/icons/total_entities.svg";
import { ReactComponent as totalWordsIcon } from "../../assets/icons/total_words.svg";
import { ReactComponent as averangeSentimentsIcon } from "../../assets/icons/averange_sentiment.svg";

import SC from "./general-search.styles";

// let allQuestions: string[] = [];
// let rowsTemp: any[] = [];
// let responsesCount = 0;

const metadataMock = {
  title: "Video title",
  author: "Author name",
  source: "YouTube",
};

interface GeneralSearchPageProps {
  menuOpened: boolean;
  searchTerm: string;
  finishSearch: any;
  isModal: boolean;
}

const GeneralSearchPage: React.FC<GeneralSearchPageProps> = ({
  menuOpened,
  searchTerm = "",
  finishSearch,
  isModal,
}) => {
  const classes = SC.useStyles();
  const refChatContainer = React.useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalResults, setTotalResults] = React.useState(0);

  const [rows, setRows] = React.useState<any[]>([]);
  const [searchParams, setSearchParams] = React.useState<{
    search: string;
    total: number;
    index: number;
  }>({
    search: searchTerm,
    total: rowsPerPage,
    index: page,
  });

  const [openFilter, setOpenFilter] = React.useState(false);
  const [showItemDetail, setShowItemDetail] = React.useState(false);
  const [itemDetail, setItemDetail] = React.useState<any>({
    id: "",
    documentId: "",
    sourceId: "",
    mediaName: "",
    entities: "",
    sentence: "",
    sentiments: "",
    answer: "",
    context: "",
    score: "",
  });
  const [resultResume, setResultResume] = React.useState<{
    audioTime: number;
    wordTotal: number;
    entitiesTotal: number;
    sentimentAverange: number;
    score: number;
  }>({
    audioTime: 0,
    wordTotal: 0,
    entitiesTotal: 0,
    sentimentAverange: 0,
    score: 0,
  });

  // const [loading, setLoading] = React.useState(false);

  const [openAddTarget, setOpenAddTarget] = React.useState(false);
  const [addSourceId, setAddSourceId] = React.useState<any>(null);

  const handleClickToggleAddTarget: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      if (event && event.data && event.data.CreateTarget) {
        // Update the target item status
        const currentTarget = event.data.CreateTarget;
        const rowsUpdated = rows.map((x: any) => {
          const currentItem = x;

          let { mediaId } = currentItem;
          if (!mediaId && currentItem.meta) {
            const { source } = JSON.parse(currentItem.meta);
            mediaId = source;
          }
          if (
            currentItem.id === currentTarget.sourceId ||
            mediaId === currentTarget.sourceId
          ) {
            currentItem.targetStatus = true;
          }
          return currentItem;
        });
        setRows(rowsUpdated);
      }
      setOpenAddTarget(!openAddTarget);
    },
    [openAddTarget, setOpenAddTarget, rows]
  );

  // const {
  //   data: answersData,
  //   loading: answerLoading,
  //   error: answerError,
  // } = useQuestionsAndAnswerQuery({
  //   context: {
  //     clientName: CLIENT_NAME.AUDIONAUTSQANDA,
  //   },
  //   fetchPolicy: "no-cache",
  //   skip: !searchTerm.includes("?"),
  //   variables: {
  //     question: searchTerm,
  //   },
  // });

  const {
    data: searchResultData,
    error: searchError,
    loading: searchLoading,
  } = useSearchEntityQuery({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTSEARCH,
    },
    fetchPolicy: "no-cache",
    variables: searchParams,
  });
  // const searchResultData: any = React.useMemo(
  //   () => resultSearch?.queryElastic ?? [],
  //   [resultSearch]
  // );

  const [
    createTarget,
    { loading: createTargetLoading, error: createTargetError },
  ] = useCreateTargetMutation({
    update(cache, { data }) {
      const newTarget = data?.CreateTarget;
      if (newTarget) {
        cache.modify({
          fields: {
            GetTarget(existingTarget = []) {
              const newTargetRef = cache.writeFragment({
                id: cache.identify(newTarget),
                data: newTarget,
                fragment: NEW_TARGET_FRAGMENT_GQL,
              });
              return [...existingTarget, newTargetRef];
            },
          },
        });
      }
    },
  });

  const {
    data: targetsGroupData,
    loading: targetsGroupDataLoading,
    error: targetsGroupDataError,
  } = useGetAllTargetsGroupsQuery({
    fetchPolicy: "no-cache",
  });
  const allTargetsGroup = React.useMemo(
    () => targetsGroupData?.GetTargetsGroups ?? [],
    [targetsGroupData]
  );

  const loading = searchLoading || targetsGroupDataLoading;

  const errorHandler = useAppErrorHandler(
    searchError || createTargetError || targetsGroupDataError
  );

  useEffect(() => {
    let currentResult: any = [];

    if (
      searchResultData &&
      searchResultData.queryElastic &&
      searchResultData.queryElastic.results
    ) {
      const {
        queryElastic: { results, totalResults: totalItems },
      } = searchResultData;

      let wordTotal = 0;
      let entitiesTotal = 0;
      let sentences = 0;
      results.forEach((result: any) => {
        if (
          result &&
          result?.entities &&
          result?.entities.includes("[") &&
          result?.entities.includes("]")
        ) {
          const entities = result?.entities
            .substr(1, result?.entities.length - 2)
            .split(",")
            .map((word: string) => {
              return word.split("'")[1];
            });
          entitiesTotal += entities.length;
        } else {
          entitiesTotal += 1;
        }
        if (result?.sentence && typeof result?.sentence === "object") {
          sentences += result.sentence!.length;
        } else {
          sentences += 1;
        }
        wordTotal += result.sentence.split(" ").length;
      });

      setResultResume((oldResume) => ({
        ...oldResume,
        wordTotal,
        entitiesTotal,
      }));

      setTotalResults(totalItems ?? 0);

      currentResult = results;
    }

    // if (searchTerm.includes("?") && answersData && answersData.queryQa) {
    //   if (responsesCount < allQuestions.length) {
    //     currentResult = [...rowsTemp, ...answersData.queryQa];
    //   } else {
    //     currentResult = answersData.queryQa;
    //     rowsTemp = currentResult;
    //   }

    //   responsesCount += 1;

    //   if (responsesCount === allQuestions.length) {
    //     allQuestions = [];
    //     responsesCount = 0;
    //     rowsTemp = [];
    //   }
    // }
    setRows(currentResult);
  }, [searchResultData, searchTerm]);

  useEffect(() => {
    if (searchTerm !== searchParams.search) {
      setResultResume((oldResume) => ({
        ...oldResume,
        wordTotal: 0,
        entitiesTotal: 0,
      }));

      setTotalResults(0);

      setSearchParams((oldSearchParams) => ({
        ...oldSearchParams,
        search: searchTerm,
      }));
    }
  }, [searchParams, searchTerm]);

  const handleShowFilter = () => {
    setOpenFilter(!openFilter);
  };

  const {
    data: groupsData,
    loading: groupsDataLoading,
    error: errorGroupsDataLoading,
  } = useGetGroupQuery();

  const [filteredUsers, setFilteredUsers] = React.useState<UserData[]>();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowItemDetail = (detail: any) => {
    const newDetail = detail;
    setItemDetail(newDetail);
    setShowItemDetail(!showItemDetail);
  };

  const handleUpdateItems = (newPage: number, newRowsPerPage: number) => {
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);

    setSearchParams({
      search: searchTerm,
      total: newRowsPerPage,
      index: newRowsPerPage * newPage,
    });
  };

  const onlyCheckbox = true;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <ProgressIndicator open={createTargetLoading} />
      <SC.GSAddNewTarget
        sourceId={addSourceId}
        openAddTarget={openAddTarget}
        setOpenAddTarget={handleClickToggleAddTarget}
        handleSubmit={createTarget}
        groups={allTargetsGroup}
      />
      <SC.GSContainer
        ref={refChatContainer}
        className={clsx(classes.root, {
          [classes.modalStyle]: isModal,
          [classes.partialWidth]:
            !menuOpened && isModal && !isMobileResolution(),
          [classes.fullWidth]: menuOpened && isModal,
        })}
      >
        <Drawer
          variant="permanent"
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openFilter,
            [classes.drawerClose]: !openFilter,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openFilter,
              [classes.drawerClose]: !openFilter,
            }),
          }}
        >
          <UserFilteringLeftPane
            users={[]}
            groups={groupsData?.GetGroup as GroupData[]}
            onFilter={setFilteredUsers}
            onlyCheckbox={onlyCheckbox}
          />
        </Drawer>
        <SC.GSContainerResults
          className={clsx(classes.itemDetailContainer, {
            [classes.hide]: !showItemDetail,
          })}
        >
          <SC.GSResultItem className={classes.itemDetailHeader}>
            <Box className="header">
              <Box component="div" className="titles">
                <span className="title">
                  <IconButton
                    aria-label="back-icon"
                    component="span"
                    onClick={() => {
                      setShowItemDetail(!showItemDetail);
                    }}
                    style={{
                      padding: "0px",
                      marginRight: "10px",
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  {!searchTerm.includes("?")
                    ? itemDetail.mediaName
                    : itemDetail.title}
                </span>
                <Typography className="date">
                  {itemDetail.created !== null
                    ? getFormattedDate(itemDetail.created)
                    : "-"}
                </Typography>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className="breadcrumb"
                  separator={<NavigateNext fontSize="small" />}
                >
                  <Link underline="hover" href="/">
                    Analize
                  </Link>
                  <Link
                    underline="hover"
                    href="/"
                    onClick={() => {
                      setShowItemDetail(!showItemDetail);
                    }}
                  >
                    Search
                  </Link>
                  <Typography>
                    {!searchTerm.includes("?")
                      ? itemDetail.mediaName
                      : itemDetail.title}
                  </Typography>
                </Breadcrumbs>
              </Box>
              <div className="play-image">
                {!searchTerm.includes("?") ? (
                  <Box hidden={!itemDetail.media}>
                    <CardMedia component="img" image={playImage} />
                    <span>3:05.02</span>
                  </Box>
                ) : (
                  <AudionautCharts
                    type={5}
                    customStyle={{
                      height: "100%",
                      width: "auto",
                      minWidth: "50px",
                      minHeight: "50px",
                    }}
                    data={{
                      score: (Number(itemDetail.score) * 100).toFixed(1),
                      isPositive: true,
                    }}
                  />
                )}
              </div>
            </Box>
          </SC.GSResultItem>
          {itemDetail.id || itemDetail.documentId || itemDetail.mediaId ? (
            <SC.GenerateItemSearch
              item={itemDetail}
              selectMediaId={setAddSourceId}
              addTargetDialog={handleClickToggleAddTarget}
              showDetail={handleShowItemDetail}
              isQAndA={searchTerm.includes("?")}
              isItemDetail
            />
          ) : null}
          {/* <SC.GSResultItem className={classes.itemDetailContent}>
            <Box className="content">
              <p>{!isQAndA ? itemDetail?.sentence : itemDetail.context}</p>
            </Box>
            <Divider />
            <Box className="footer">
              {!isQAndA ? (
                <>
                  <Chip
                    className="sentiment-color"
                    label={`Sentiment Score: ${
                      itemDetail.detail?.sentiments.split(",").length
                    }`}
                  />
                  <Chip
                    className="entity-color"
                    label={`${itemDetail?.entities.split(",").length} Entities`}
                  />
                  <Chip
                    className="word-color"
                    label={`${itemDetail?.sentence.split(" ").length} Words`}
                  />
                </>
              ) : (
                <>
                  <Chip
                    className="sentiment-color"
                    label={`Author: ${itemDetail.author}`}
                  />
                  <Chip className="entity-color" label={itemDetail.source} />
                </>
              )}
            </Box>
            <Button
              component="button"
              // className={clsx(classes.targetBtn, {
              //   [classes.hide]: targetExists,
              // })}
              // onClick={(event: any) => {
              //   selectMediaId(mediaId);
              //   addTargetDialog(event);
              // }}
            >
              Target
            </Button>
          </SC.GSResultItem> */}
        </SC.GSContainerResults>
        <SC.GSContainerResults
          id="SearchContainerId"
          className={clsx({
            [classes.hide]: showItemDetail,
          })}
        >
          <SC.ScrollTopRefElem id="back-to-top-anchor-search" />
          <h1>Results: {totalResults}</h1>
          <Box className={classes.paginationContainer}>
            <Paginator
              totalItems={totalResults}
              updateItems={setRowsPerPage}
              updatePage={setPage}
              getNewItems={handleUpdateItems}
              externalNewPage={page}
              externalNewRowPerPage={rowsPerPage}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title="Filter">
              <IconButton
                aria-label="filter-icon"
                component="span"
                onClick={handleShowFilter}
                className={clsx(classes.iconFilter, {
                  [classes.iconFilterActive]: openFilter,
                  [classes.iconFilterInactive]: !openFilter,
                })}
              >
                <SvgIcon component={FilterAlt} />
              </IconButton>
            </Tooltip>
          </Box>
          {/* <Divider /> */}
          {!searchTerm.includes("?") ? (
            <>
              <Box hidden={!isMobileResolution()}>
                <SC.TopicsCarousel
                  type="search"
                  items={[
                    <SC.GSSummaryItem>
                      <Box className="cardHeader">
                        <SvgIcon component={totalAudioIcon} />
                        <span className="title">Total Audio</span>
                      </Box>
                      <Box className="content">
                        <Box className="main">
                          <div>
                            <span>{resultResume.audioTime}</span>
                          </div>
                        </Box>
                        <h5 className="footer">Minutes</h5>
                      </Box>
                    </SC.GSSummaryItem>,
                    <SC.GSSummaryItem>
                      <Box className="cardHeader">
                        <SvgIcon component={totalWordsIcon} />
                        <span className="title">Total Words</span>
                      </Box>
                      <Box className="content">
                        <Box className="main">
                          <div>
                            <span>{resultResume.wordTotal}</span>
                          </div>
                        </Box>
                        <h5 className="footer">Words</h5>
                      </Box>
                    </SC.GSSummaryItem>,
                    <SC.GSSummaryItem>
                      <Box className="cardHeader">
                        <SvgIcon component={totalEntitiesIcon} />
                        <span className="title">Total Entities</span>
                      </Box>
                      <Box className="content">
                        <Box className="main">
                          <div>
                            <span>{resultResume.entitiesTotal}</span>
                          </div>
                        </Box>
                        <h5 className="footer">Entities</h5>
                      </Box>
                    </SC.GSSummaryItem>,
                    <SC.GSSummaryItem>
                      <Box className="cardHeader">
                        <SvgIcon component={averangeSentimentsIcon} />
                        <span className="title">Average Sentiment</span>
                      </Box>
                      <Box className="content">
                        <Box className="main">
                          <div>
                            <span>{resultResume.sentimentAverange}</span>
                          </div>
                        </Box>
                        <h5 className="footer">Positive</h5>
                      </Box>
                    </SC.GSSummaryItem>,
                  ]}
                  customPaddingLeft={10}
                  customPaddingRight={10}
                />
              </Box>
              <SC.GSSummary hidden={isMobileResolution()}>
                <SC.GSSummaryItem>
                  <Box className="cardHeader">
                    <SvgIcon component={totalAudioIcon} />
                    <span className="title">Total Audio</span>
                  </Box>
                  <Box className="content">
                    <Box className="main">
                      <div>
                        <span>{resultResume.audioTime}</span>
                      </div>
                    </Box>
                    <h5 className="footer">Minutes</h5>
                  </Box>
                </SC.GSSummaryItem>
                <SC.GSSummaryItem>
                  <Box className="cardHeader">
                    <SvgIcon component={totalWordsIcon} />
                    <span className="title">Total Words</span>
                  </Box>
                  <Box className="content">
                    <Box className="main">
                      <div>
                        <span>{resultResume.wordTotal}</span>
                      </div>
                    </Box>
                    <h5 className="footer">Words</h5>
                  </Box>
                </SC.GSSummaryItem>
                <SC.GSSummaryItem>
                  <Box className="cardHeader">
                    <SvgIcon component={totalEntitiesIcon} />
                    <span className="title">Total Entities</span>
                  </Box>
                  <Box className="content">
                    <Box className="main">
                      <div>
                        <span>{resultResume.entitiesTotal}</span>
                      </div>
                    </Box>
                    <h5 className="footer">Entities</h5>
                  </Box>
                </SC.GSSummaryItem>
                <SC.GSSummaryItem>
                  <Box className="cardHeader">
                    <SvgIcon component={averangeSentimentsIcon} />
                    <span className="title">Average Sentiment</span>
                  </Box>
                  <Box className="content">
                    <Box className="main">
                      <div>
                        <span>{resultResume.sentimentAverange}</span>
                      </div>
                    </Box>
                    <h5 className="footer">Positive</h5>
                  </Box>
                </SC.GSSummaryItem>
                {/* <SC.GSSummaryItem>
                  <span className="title">Audionaut Score</span>
                  <Box className={`content ${classes.containerCardImg}`}>
                    <CardMedia
                      component="img"
                      className={classes.cardImg}
                      image={chartImage}
                    />
                  </Box>
                </SC.GSSummaryItem> */}
              </SC.GSSummary>
            </>
          ) : null}
          <Box
            component="div"
            className={clsx(classes.loader, {
              [classes.partialWidth]: !menuOpened && isModal,
              [classes.fullWidth]: menuOpened && isModal,
              [classes.hide]: !loading,
            })}
          >
            <CircularProgress className={classes.circularProgress} />
          </Box>
          <TableContainer
            hidden={rows.length === 0}
            component={Paper}
            className={`${classes.tableContainer} ${
              loading ? classes.hide : null
            }`}
          >
            <Table aria-label="custom pagination table">
              <TableBody>
                {rows.length > 0
                  ? rows.map((row: any, index: number) => {
                      const eleKey = `${row.mediaId}-${index}`;
                      return (
                        <TableRow key={`item-${eleKey}`}>
                          <TableCell component="th" scope="row">
                            <SC.GenerateItemSearch
                              item={row}
                              selectMediaId={setAddSourceId}
                              addTargetDialog={handleClickToggleAddTarget}
                              showDetail={handleShowItemDetail}
                              isQAndA={searchTerm.includes("?")}
                              isItemDetail={false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 ? (
            <Paginator
              totalItems={totalResults}
              updateItems={setRowsPerPage}
              updatePage={setPage}
              getNewItems={handleUpdateItems}
              externalNewPage={page}
              externalNewRowPerPage={rowsPerPage}
            />
          ) : null}
          <SC.NoResults
            show={rows.length === 0 && !loading}
            title={
              searchTerm
                ? "No Results Found"
                : "Run your questions through search functionality "
            }
            description={
              searchTerm
                ? "Check your search spelling or use more generic search terms"
                : "Start ingesting audio into "
            }
            description2={
              !searchTerm ? " to use the search functionality." : ""
            }
            component={
              !searchTerm ? (
                <Link key="audio-source-link" href="explore/audio-sources">
                  Audio Sources
                </Link>
              ) : null
            }
          />
          <SC.ScrollToTopElem position="absolute">
            <ScrollToTopButton
              containerId="SearchContainerId"
              querySelector="back-to-top-anchor-search"
              refContainer={refChatContainer.current}
            />
          </SC.ScrollToTopElem>
        </SC.GSContainerResults>
      </SC.GSContainer>
    </>
  );
};

export default GeneralSearchPage;
