import React from "react";
import { Box, CardMedia, Typography } from "@material-ui/core";

import { generateYoutubeEmbedURL } from "commons/utils/url-management";
import { getFormattedDate } from "commons/utils/date-format";
import NoVideo from "assets/images/no-video.png";

import { isMobileResolution } from "commons/utils/device-info.util";
import { MultimediaMetadataItemInterface } from "./multimedia-metadata-item.utils";

import SC from "./multimedia-metadata-item.styles";

const MultimediaMetadataItem: React.FC<{
  metadata: MultimediaMetadataItemInterface & {
    authors?: string;
    year?: string;
    pdfUrl?: string;
    page?: string;
  };
}> = ({ metadata }) => {
  const classes = SC.useStyles();

  const {
    author,
    created,
    description,
    isVideo,
    showTitleCard,
    source,
    title,
    type,
    endTime,
    startTime,
    webUrl,
    thumbnail,
    authors,
    year,
    pdfUrl,
    page,
  } = metadata;

  const [descShort, setDescShort] = React.useState(
    description && description?.length > 249
  );

  return (
    <>
      <SC.CardTitle>
        {/* {showTitleCard ? <Typography variant="h6">{source}</Typography> : null} */}
        {type !== "catalog" ? (
          <>
            {/* <SC.MediaTitle variant="a">{title}</SC.MediaTitle> */}
            <SC.MediaTitleLink
              href={pdfUrl ?? undefined}
              className={classes.textClipped}
              variant="h5"
              target="blank"
              title={title}
            >
              {title}
            </SC.MediaTitleLink>
            <Typography variant="h6" className="authors padding-left">
              {authors}
            </Typography>
            <Typography
              variant="h6"
              className="padding-left"
            >{`${page}.`}</Typography>
          </>
        ) : (
          <SC.MediaTitleLink
            href={webUrl ?? undefined}
            className={classes.textClipped}
            variant="h5"
            target="blank"
            title={title}
          >
            {title}
          </SC.MediaTitleLink>
        )}
        <SC.MediaExtras hidden={type !== "catalog"}>
          <Typography variant="h6">{`${author} `}</Typography>
          {created ? (
            <Typography variant="h6">{`${getFormattedDate(
              created
            )}`}</Typography>
          ) : (
            "no date"
          )}
        </SC.MediaExtras>
      </SC.CardTitle>
      <SC.MediaContent
        hidden
        className={type === "widget" ? "widget-media-content" : ""}
      >
        {isVideo && !!webUrl && source !== "PODCAST" ? (
          <CardMedia
            hidden={isMobileResolution()}
            width="294"
            height="170"
            className={type === "widget" ? "big-video" : ""}
            component="iframe"
            src={
              source === "YOUTUBE"
                ? generateYoutubeEmbedURL(webUrl, startTime, endTime)
                : webUrl
            }
          />
        ) : (
          <CardMedia
            hidden={isMobileResolution()}
            component="img"
            image={NoVideo}
            className={type === "catalog" ? "catalog-image" : ""}
          />
        )}
        {/* {thumbnail ? (
          <CardMedia
            width="294"
            height="170"
            component="image"
            image={thumbnail}
          />
        ) : null} */}
        <SC.MediaDescription
          className="widget-media-desc"
          hidden={type === "catalog"}
        >
          <Box
            hidden={type !== "search"}
            onClick={() => {
              if (description && description?.length > 249) {
                setDescShort(!descShort);
              }
            }}
          >
            <Typography>
              {descShort && description && description.length > 250
                ? `${description?.substr(0, 250)} ...`
                : description}
            </Typography>
          </Box>
          <SC.MediaExtras>
            <Typography variant="h6">{`${
              type === "search"
                ? author
                : source.charAt(0).toUpperCase() + source.slice(1).toLowerCase()
            } `}</Typography>
            {created ? (
              <Typography variant="h6">{`${getFormattedDate(
                created
              )}`}</Typography>
            ) : (
              "no date"
            )}
          </SC.MediaExtras>
        </SC.MediaDescription>
      </SC.MediaContent>
    </>
  );
};

export default MultimediaMetadataItem;
