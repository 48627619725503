import {
  Box,
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Typography,
  CardMedia,
  Chip,
  Popover,
} from "@material-ui/core";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";

const ColorsChips = ["color1", "color2", "color3", "color4"];

const useItemSearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipRoot: {
      height: "fit-content",
      lineHeight: "1.2",
      "& span": {
        color: "#1C1C1C",
        padding: "0px 5px",
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    extractedContent: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1",
      alignItems: "center",
      gap: "12px",
      [theme.breakpoints.down("md")]: {
        gridRowGap: "10px",
        gridColumnGap: "10px",
      },
    },
    hide: {
      display: "none !important",
    },
    chartPopover: {
      "& div:last-child": {
        "& br": {
          display: "Hidden",
        },
      },
    },
  })
);

const GSResultItem = withStyles((theme: Theme) => ({
  root: {
    backgroundColor:
      theme.generalStylesContainer.layers.content.backgroundColor,
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
    padding: "25px",
    paddingBottom: "22px",
    // height: "200px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& .header": {
      display: "contents",
      "& .title": {
        fontSize: "18px",
        fontWeight: "800",
        lineHeight: "23px",
        textAlign: "left",
        marginBottom: "8px",
      },
      "& .date": {
        fontSize: "11px",
        lineHeight: "13px",
        textAlign: "left",
        fontWeight: "400",
        color: "#ABABAB",
      },
    },
    "& .content": {
      // height: "70px",
      flex: "1",
      // display: "flex",
      marginBottom: "10px",
      // "& p": {
      //   flex: "1",
      //   height: "100%",
      //   textAlign: "left",
      //   fontSize: "16px",
      //   fontWeight: "noormal",
      //   color: "#1C1C1C",
      //   paddingRight: "50px",
      //   overflow: "hidden",
      //   "& p": {
      //     height: "fit-content",
      //     marginBottom: "5px",
      //   },
      //   "& em": {
      //     fontStyle: "normal",
      //   },
      //   "& .green": {
      //     color: "#60C3A6",
      //   },
      //   "& .purple": {
      //     color: "#945AA9",
      //   },
      //   "& .color-1": {
      //     background: "#D4DCF3",
      //   },
      //   "& .color-2": {
      //     background: "#F4E2FB",
      //   },
      //   "& .color-3": {
      //     background: "#DFCEEE6",
      //   },
      //   "& .color-4": {
      //     background: "#FCF6C6",
      //   },
      //   "& .color-5": {
      //     background: "#EADEEE",
      //   },
      //   "& .color-6": {
      //     background: "#CFEDE4",
      //   },
      //   "& .color-7": {
      //     background: "#C6DBEE",
      //   },
      //   "& .MuiChip-root": {
      //     height: "auto",
      //     "& span": {
      //       padding: "0px 5px",
      //       fontSize: "16px",
      //       fontWeight: "noormal",
      //       color: "#1C1C1C",
      //     },
      //   },
      // },
      "& .red": {
        background: "red",
      },
      "& .play-image": {
        minWidth: "50px",
        width: "50px",
        marginTop: "1em",
        "& span": {
          color: "#D83651",
          fontSize: "12px",
          fontWeight: "normal",
        },
      },
    },
    "&.widget-item": {
      background: "unset",
      border: "unset",
      boxShadow: "unset",
    },
    [theme.breakpoints.down(540)]: {
      padding: "15px",
    },
  },
}))(Box);

const VideoPreview = withStyles(() => ({
  root: {
    height: "170px",
    width: "259px",
  },
}))(CardMedia);

const AudioContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.theming.gray1.color,
    padding: "8px 0px",
    marginBottom: "8px",
    columnGap: "5px",
    "& p": {
      fontSize: "8px",
      lineHeight: "10px",
    },
    // temps styles to the provisional audio player
    "& audio": {
      "& ::-webkit-media-controls-panel": {
        backgroundColor: "transparent",
      },
      "&::-webkit-media-controls-play-button": {
        backgroundColor:
          "linear-gradient(258.06deg, rgba(255, 255, 255, 0.4) 14.43%, rgba(240, 133, 81, 0.4) 14.58%, rgba(231, 104, 81, 0.4) 19.58%, rgba(224, 82, 81, 0.4) 24.87%, rgba(220, 65, 81, 0.4) 30.62%, rgba(217, 56, 81, 0.4) 37.08%, rgba(216, 53, 81, 0.4) 45.78%, rgba(201, 54, 91, 0.4) 53.73%, rgba(162, 58, 116, 0.4) 68.95%, rgba(119, 62, 144, 0.4) 83.44%, rgba(119, 62, 144, 0.4) 86.85%);",
        borderRadius: "50%",
      },
      "&::-webkit-media-controls-current-time-display": {
        color: "#959595",
      },
      "&::-webkit-media-controls-time-remaining-display": {
        color: "#959595",
      },
      "&::-webkit-media-controls-mute-button": {
        display: "none",
      },
      "&::-webkit-media-controls-volume-slider-container": {
        display: "none",
      },
    },
  },
}))(Box);

const AnswerEl = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px",
    border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
    borderRadius: "7px",
    textAlign: "start",
    color: theme.generalStylesContainer.chat.remote.color,
    "& .author": {
      display: "ruby",
    },
    // "& b": {
    //   background: "#AA8BEC40",
    //   borderRadius: "15px",
    //   fontWeight: "inherit",
    //   padding: "4px",
    //   marginLeft: "3px",
    //   marginRight: "3px",
    // },
    "&.widget": {
      height: "200px",
      overflowY: "auto",
    },
    "& .bold": {
      fontWeight: 800,
    },
    "& .abstract-title": {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
    },
    "& .abstract-content": {
      fontSize: theme.fontGeneralStyles.small.fontSize,
      lineHeight: theme.fontGeneralStyles.small.lineHeight,
      margin: "unset",
    },
  },
}))(Box);

const ItemFooter = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginTop: "10px",
    "& .MuiChip-root": {
      // marginRight: "30px",
      height: "20px",
      minWidth: "125px",
      fontSize: "11px",
      fontWeight: "500",
    },
    "& .sentiment-color": {
      backgroundColor: "#df71713d",
    },
    "& .entity-color": {
      backgroundColor: "#aa8bec3d",
    },
    "& .word-color": {
      backgroundColor: "#71c5df3d",
    },
  },
}))(Box);

const FooterChip = withStyles((theme: Theme) => ({
  root: {
    height: "33px !important",
    flexFlow: "row-reverse",
    columnGap: "5px",
    padding: "11px 12px",
    color: theme.generalStylesContainer.chat.chip.color,
    width: "fit-content",
    "& span": {
      display: "list-item",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 600,
      padding: "unset",
    },
    "& svg": {
      fontSize: "18px",
      margin: "unset",
    },
    "&.confindence": {
      background: "#6C86D733",
    },
    "&.positive": {
      background: "#5AE3C233",
    },
    "&.negative": {
      background: "#E37B5A33",
    },
    "&.views": {
      background: "#B1D1D459",
      "& svg": {
        color: "#B1D1D4",
      },
    },
    "&.likes": {
      background: "#AA8BEC4D",
      "& svg": {
        color: "#AA8BEC",
      },
    },
    "&.tags": {
      background: "#FAB57540",
      "& svg": {
        color: "#FAB575",
      },
    },
    "&.color1": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor1,
      "& svg": {
        color: "#D9C5A6",
      },
    },
    "&.color2": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor2,
      "& svg": {
        color: "#5AE3C2",
      },
    },
    "&.color3": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor3,
      "& svg": {
        color: "#E37B5A",
      },
    },
    "&.color4": {
      background:
        theme.generalStylesContainer.chat.chip.container.backgroundColor4,
      "& svg": {
        color: "#B1D1D4",
      },
    },
    "&.small": {
      maxWidth: "123px",
      width: "100%",
    },
    "&.big": {
      maxWidth: "160px",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "unset !important",
      maxWidth: "calc(100vw - 155px)",
      width: "fit-content !important",
      padding: "0px 10px",
      marginRight: "unset !important",
      ...theme.commons.textClipped,
    },
  },
}))(Chip);

const TagIcon = withStyles(() => ({
  root: {},
}))(LocalOfferIcon);

const TagsPopover = withStyles((theme: Theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "200px",
      background: "#484848",
      color: "#FFF",
      padding: "4px 7px",
      fontSize: theme.fontGeneralStyles.xSmall.fontSize,
      lineHeight: theme.fontGeneralStyles.xSmall.lineHeight,
      margin: "10px 0px",
      borderRadius: "4px !important",
    },
  },
}))(Popover);

const AnswerContainer = withStyles(() => ({
  root: {
    border: "unset",
    cursor: "pointer",
  },
}))(Box);

export default {
  useItemSearchStyles,
  GSResultItem,
  AnswerEl,
  AudioContent,
  VideoPreview,
  ItemFooter,
  FooterChip,
  TagIcon,
  TagsPopover,
  AnswerContainer,
  ColorsChips,
};
