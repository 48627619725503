import React from "react";
import {
  Checkbox,
  useTheme,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

// Assets
import SC from "components/payment-billing-address/billing-address-form-fields.styles";
import SCAddressForm from "pages/payment-management/views/components/billing_address/billing_address_form.styles";
import { TopicItemModel } from "graphql/corporatus/corporatus_rest_graphql.types";
import { createEmptyTopicItem } from "graphql/audionaut.utils";
import { components } from "react-select";
import SCTOPIC from "./topic_form.styles";

const { REACT_APP_API_REST_TOPICS_SERVER_URL } = process.env;

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;

export interface TopicsFormProps {
  topicInfo: TopicItemModel;
  onSubmit: any;
  onCancel?: () => void;
  isWizard?: boolean;
  customClasses?: string;
  show?: boolean;
  modalType?: number;
}

const TopicsFormFields: React.FC<TopicsFormProps> = ({
  onSubmit,
  topicInfo,
  onCancel,
  isWizard = false,
  customClasses = "",
  show = true,
  modalType = 0,
}) => {
  const theme = useTheme();
  const classes = SC.generalStyles();
  const [topicInfoUpdate, setTopicInfoUpdate] =
    React.useState<TopicItemModel>(topicInfo);
  const handleCancelCreate = React.useCallback(() => {
    setTopicInfoUpdate(createEmptyTopicItem());
    if (onCancel) onCancel();
  }, [onCancel]);

  const buttons = [
    {
      title: "Cancel",
      handler: handleCancelCreate,
      isShown: true,
      btnClasses: "secondaryBtn",
    },
    {
      title: "Save",
      type: "submit",
      isShown: true,
      btnClasses: "",
    },
  ];

  const onSaveTopic = React.useCallback(async (topicData: TopicItemModel) => {
    try {
      await fetch(`${REACT_APP_API_REST_TOPICS_SERVER_URL}/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(topicData),
      }).then((response) => response.json());
    } catch (error) {
      console.error("=====> Error Obtaining Topics: ", error);
    }
  }, []);

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      onSubmit(topicInfoUpdate);
    },
    [onSubmit, topicInfoUpdate]
  );

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setTopicInfoUpdate((oldTopicInfo) => ({
      ...oldTopicInfo,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { name } = event.target;

    setTopicInfoUpdate((oldTopicInfo) => ({
      ...oldTopicInfo,
      [name]: checked,
    }));
  };

  return (
    <SC.PaymentMethodForm
      hidden={!show}
      component="form"
      className={customClasses}
      onSubmit={handlerOnSubmit}
    >
      <SC.Box
        className={`${classes.fieldsContainer} customFieldsContainer`}
        style={{ maxHeight: "unset !important" }}
      >
        <SC.Grid
          container
          spacing={2}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          className={`${classes.formContainer} customFormContainer`}
        >
          <SC.Grid item xs={12}>
            <SC.Box disabledMarginTop>
              <SCAddressForm.CustomTextField
                name="topic"
                label="Topic"
                placeholder="Topic"
                variant="outlined"
                fullWidth
                required
                value={topicInfoUpdate.topic}
                onChange={handleTextFieldChange}
              />
            </SC.Box>
          </SC.Grid>
          <SCTOPIC.TopicGrid item xs={12}>
            <SC.Box disabledMarginTop>
              <FormControlLabel
                control={
                  <Checkbox
                    name="escholarship_scraper"
                    checked={topicInfoUpdate.escholarship_scraper}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <Typography style={{ color: "#ffffff" }}>
                    Escholarship Scraper
                  </Typography>
                }
              />
            </SC.Box>
          </SCTOPIC.TopicGrid>
          <SCTOPIC.TopicGrid item xs={12}>
            <SC.Box disabledMarginTop>
              <FormControlLabel
                control={
                  <Checkbox
                    name="caltech_scraper"
                    checked={topicInfoUpdate.caltech_scraper}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <Typography style={{ color: "#ffffff" }}>
                    Caltech Scraper
                  </Typography>
                }
              />
            </SC.Box>
          </SCTOPIC.TopicGrid>
          <SCTOPIC.TopicGrid item xs={12}>
            <SC.Box disabledMarginTop>
              <FormControlLabel
                control={
                  <Checkbox
                    name="liberty_scraper"
                    checked={topicInfoUpdate.liberty_scraper}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <Typography style={{ color: "#ffffff" }}>
                    Liberty Scraper
                  </Typography>
                }
              />
            </SC.Box>
          </SCTOPIC.TopicGrid>
          <SCTOPIC.TopicGrid item xs={12}>
            <SC.Box disabledMarginTop>
              <FormControlLabel
                control={
                  <Checkbox
                    name="uvic_scraper"
                    checked={topicInfoUpdate.uvic_scraper}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <Typography style={{ color: "#ffffff" }}>
                    Uvic Scraper
                  </Typography>
                }
              />
            </SC.Box>
          </SCTOPIC.TopicGrid>
        </SC.Grid>
        <SC.FormActions className="customTopic">
          {buttons.map(
            ({ isShown, type, handler, title, btnClasses }) =>
              isShown && (
                <SC.Button
                  key={title}
                  type={type as ButtonType}
                  onClick={handler}
                  className={`${classes.type1Btns} ${btnClasses}`}
                >
                  {title}
                </SC.Button>
              )
          )}
        </SC.FormActions>
      </SC.Box>
    </SC.PaymentMethodForm>
  );
};

export default TopicsFormFields;
