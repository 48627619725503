/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";
import { Checkbox, useTheme, FormControlLabel } from "@material-ui/core";

import { BillingAddress } from "graphql/types-and-hooks";

// Assets
import SC from "components/payment-billing-address/billing-address-form-fields.styles";
import SCAddressForm from "./billing_address_form.styles";

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;

export interface BillingAddressFormProps {
  billingAddress: BillingAddress;
  onSubmit: (billingAddress: BillingAddress) => void;
  onCancel?: () => void;
  isWizard?: boolean;
  customClasses?: string;
  show?: boolean;
  modalType?: number;
}

const BillingAddressFormFields: React.FC<BillingAddressFormProps> = ({
  billingAddress,
  onSubmit,
  onCancel,
  isWizard = false,
  customClasses = "",
  show = true,
  modalType = 0,
}) => {
  const theme = useTheme();
  const classes = SC.generalStyles();

  const [addressInfo, setAddressInfo] =
    React.useState<BillingAddress>(billingAddress);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setAddressInfo((oldAddressInfo) => ({
      ...oldAddressInfo,
      [name]: value,
    }));
  };

  const handleDefaultChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { name } = event.target;

    setAddressInfo((oldAddressInfo) => ({
      ...oldAddressInfo,
      [name]: checked,
    }));
  };

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      if (isWizard) {
        onSubmit(addressInfo);
      } else if (addressInfo !== billingAddress) {
        onSubmit(addressInfo);
      }
    },
    [isWizard, billingAddress, addressInfo, onSubmit]
  );

  const handlerCancelOnClick = React.useCallback(() => {
    // setIsEditable(false);
    // setUserData(initialUserData);
    if (onCancel) onCancel();
  }, [onCancel]);

  const buttons = [
    {
      title: "Cancel",
      handler: handlerCancelOnClick,
      isShown: true,
      btnClasses: "secondaryBtn",
    },
    {
      title: "Save",
      type: "submit",
      isShown: true,
      btnClasses: "",
    },
  ];

  const buttons2 = [
    {
      title: "Next",
      variant: "contained",
      type: "submit",
      isShown: true,
      btnClasses: "nextBtn",
    },
    {
      title: "back",
      variant: "outlined",
      handler: handlerCancelOnClick,
      isShown: !modalType,
      btnClasses: "backBtn",
    },
  ];

  return (
    <>
      <SC.PaymentMethodForm
        hidden={!show}
        component="form"
        onSubmit={handlerOnSubmit}
        className={customClasses}
      >
        <SC.Box
          className={`${classes.fieldsContainer} customFieldsContainer`}
          style={{ maxHeight: "unset !important" }}
        >
          <SC.Grid
            container
            spacing={2}
            xl={12}
            lg={12}
            md={12}
            xs={12}
            className={`${classes.formContainer} customFormContainer`}
          >
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={addressInfo.firstName}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  placeholder="Last Name"
                  fullWidth
                  required
                  value={addressInfo.lastName}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="address"
                  label="Address"
                  variant="outlined"
                  placeholder="Address"
                  fullWidth
                  required
                  value={addressInfo.address}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="addressLine2"
                  label="Address Line 2"
                  variant="outlined"
                  placeholder="Address Line 2"
                  fullWidth
                  value={addressInfo.addressLine2}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="city"
                  label="City"
                  variant="outlined"
                  placeholder="City"
                  fullWidth
                  required
                  value={addressInfo.city}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="state"
                  label="State"
                  variant="outlined"
                  placeholder="State"
                  fullWidth
                  required
                  value={addressInfo.state}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="country"
                  label="Country"
                  placeholder="Country"
                  variant="outlined"
                  fullWidth
                  required
                  value={addressInfo.country}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
            <SC.Grid item xl={6} lg={6} md={12} xs={12}>
              <SC.Box disabledMarginTop>
                <SCAddressForm.CustomTextField
                  name="zipCode"
                  label="ZIP Code"
                  variant="outlined"
                  placeholder="ZIP Code"
                  fullWidth
                  required
                  value={addressInfo.zipCode}
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
            </SC.Grid>
          </SC.Grid>
          <SC.Box className={classes.checkContainer} disabledMarginTop>
            <FormControlLabel
              control={
                <Checkbox
                  name="isDefault"
                  color="primary"
                  checked={addressInfo.isDefault!}
                  onChange={handleDefaultChange}
                  disabled={isWizard}
                />
              }
              label="Set as default"
              color="primary"
            />
          </SC.Box>
          <SC.FormActions className={isWizard ? "customAB" : ""}>
            {!isWizard
              ? buttons.map(
                  ({ isShown, type, handler, title, btnClasses }) =>
                    isShown && (
                      <SC.Button
                        key={title}
                        type={type as ButtonType}
                        onClick={handler}
                        className={`${classes.type1Btns} ${btnClasses}`}
                      >
                        {title}
                      </SC.Button>
                    )
                )
              : buttons2.map(
                  ({ isShown, variant, type, handler, title, btnClasses }) =>
                    isShown && (
                      <SC.Button
                        key={title}
                        variant={variant as ButtonVariant}
                        type={type as ButtonType}
                        onClick={handler}
                        className={btnClasses}
                      >
                        {title}
                      </SC.Button>
                    )
                )}
          </SC.FormActions>
        </SC.Box>
      </SC.PaymentMethodForm>
    </>
  );
};

export default BillingAddressFormFields;
