import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Theme,
  withStyles,
} from "@material-ui/core";

const ImageContainer = withStyles((theme: Theme) => ({
  root: {
    position: "relative",
    background: theme.generalStylesContainer.layers.content.backgroundColor,
    // border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
    // padding: "8px",
    boxShadow: "unset",
    flex: "1 0 calc(50% - 8px)",
    cursor: "pointer",
    "& .image-component": {
      background: "transparent",
      objectFit: "contain",
    },
  },
}))(Card);

const DescriptionContent = withStyles((theme: Theme) => ({
  root: {
    padding: "8px 0px 0px !important",
    "& .caption-text": {
      color: theme.generalStylesContainer.color,
      fontSize: theme.fontGeneralStyles.medium.fontSize,
      lineHeight: theme.fontGeneralStyles.medium.lineHeight,
      fontWeight: 800,
      textAlign: "left",
    },
  },
}))(CardContent);

const OverlayTitle = withStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#1F545CB2",
    top: "0px",
    padding: "8px",
    "& .caption-text": {
      color: "#FFE492",
      fontSize: theme.fontGeneralStyles.xSmall.fontSize,
      lineHeight: theme.fontGeneralStyles.xSmall.lineHeight,
      fontWeight: 800,
    },
  },
}))(Box);

export default { ImageContainer, DescriptionContent, OverlayTitle };
