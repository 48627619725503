// Dependencies
import Alert from "@material-ui/lab/Alert";
import { withStyles, createStyles, makeStyles } from "@material-ui/styles";

const AlertCustom = withStyles(() => ({
  root: {
    minWidth: "350px",
  },
}))(Alert);

export default {
  AlertCustom,
};
