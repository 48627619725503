import React from "react";
import { BehaviorSubject } from "rxjs";

export class HandleGlobalEvents {
  private static instance: HandleGlobalEvents;

  public checkinginlineStyles$: BehaviorSubject<any>;

  public checkingStylesSummary$: BehaviorSubject<any>;

  private constructor() {
    // console.debug("=====> Init Service StreamResponseService");
    this.checkinginlineStyles$ = new BehaviorSubject(false);
    this.checkingStylesSummary$ = new BehaviorSubject(false);
  }

  public static getInstance(): HandleGlobalEvents {
    if (!HandleGlobalEvents.instance) {
      HandleGlobalEvents.instance = new HandleGlobalEvents();
    }

    return HandleGlobalEvents.instance;
  }

  public checkingInjectedStyles() {
    this.checkinginlineStyles$.next(true);
  }

  public checkingStylesSummary() {
    this.checkingStylesSummary$.next(true);
  }
}
