/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";
import { Box, TextField, Typography, useTheme } from "@material-ui/core";
import { formatCurrency } from "commons/utils/number-format";

// Components
import NumberFormatCustom from "components/input-number-format/input-number-format.component";
import { isMobileResolution } from "commons/utils/device-info.util";

import { PlanModel } from "./data/models/plans-model";

// Assets
import SC from "./plans-coupons-form-fields.styles";

interface Props {
  itemData: PlanModel | any;
  isEditable: boolean;
  onChange: (itemData: PlanModel | any) => void;
  isPlan: boolean;
}

const PlansCouponsFormFields: React.FC<Props> = ({
  itemData,
  isEditable,
  onChange,
  isPlan,
}) => {
  const classes: any = SC.generalStyles();

  const [descriptionText, setDescriptionText] = React.useState<string>(
    itemData.description
      ? itemData.description
      : `This plan includes 0 mins of audio, ${formatCurrency(
          0,
          "usd"
        )} per minute after`
  );
  const [userDescription, setUserDescription] = React.useState<boolean>(false);
  const [minsDescription, setMinsDescription] = React.useState<string>(
    "This plan includes 0 mins of audio"
  );
  const [priceDescription, setPriceDescription] = React.useState<string>(
    `${formatCurrency(0, "usd")} per minute after`
  );

  // description: `This plan includes ${
  //   plansCouponsData.audionautUnitInSec! / 60
  // } mins of audio, $${
  //   plansCouponsData.pricePerUnitInCent! / 100
  // }.00 USD per minute after`,

  // const handleActiveStatus = () => {
  //   const newUserData = { ...itemData };
  //   newUserData.active = !newUserData.active;
  //   onChange(newUserData);
  // };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newItemData: PlanModel | any = {
      ...itemData,
    };

    let priceDescriptionTemp = "";
    let minsDescriptionTemp = "";

    switch (name) {
      case "name":
        newItemData.name = value;
        break;
      case "price":
        newItemData.priceInCent = Number(value) * 100;
        break;
      case "totalAudioTime":
        newItemData.audionautUnitInSec = Number(value) * 60;
        minsDescriptionTemp = `This plan includes ${value} mins of audio`;
        setMinsDescription(minsDescriptionTemp);
        break;
      case "pricePerMinute":
        newItemData.pricePerUnitInCent = Number(value) * 100;
        priceDescriptionTemp = `${formatCurrency(
          Number(value),
          "usd"
        )} per minute after`;
        setPriceDescription(priceDescriptionTemp);
        break;
      case "trialPeriodNumOfDays":
        newItemData.trialPeriodNumOfDays = Number(value);
        break;
      case "description":
        newItemData.description = value;
        setDescriptionText(value);
        setUserDescription(true);
        break;
      default:
        // console.log("Field doesn't mapping yet!!", name);
        break;
    }

    if (
      !userDescription &&
      (name === "pricePerMinute" || name === "totalAudioTime")
    ) {
      newItemData.description = `${
        name === "totalAudioTime" ? minsDescriptionTemp : minsDescription
      }, ${
        name === "pricePerMinute" ? priceDescriptionTemp : priceDescription
      }`;
      setDescriptionText(newItemData.description);
    }

    onChange(newItemData);
  };

  return (
    <>
      <Box hidden={isMobileResolution()}>
        <Typography className={classes.formTitle} color="primary">
          {isPlan ? "Add Plan" : "Add Coupon"}
        </Typography>
      </Box>
      <SC.Grid
        container
        spacing={2}
        disabledMarginTop
        className={classes.formContainer}
      >
        <SC.Grid item xs={12} md={6} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <SC.TextFieldPlan
              name="name"
              label={isPlan ? "Plan Name" : "Coupon Name"}
              placeholder={isPlan ? "Plan Name" : "Coupon Name"}
              variant="outlined"
              fullWidth
              required
              value={itemData.name}
              onChange={handleTextFieldChange}
            />
          </SC.Box>
          <SC.Box>
            <TextField
              name={isPlan ? "price" : "discountPercentage"}
              label={isPlan ? "Price" : "Discount Percentage"}
              variant="outlined"
              placeholder={isPlan ? "$0.00 USD" : "0%"}
              fullWidth
              required
              value={
                itemData.priceInCent !== null
                  ? Number(itemData.priceInCent) / 100
                  : ""
              }
              onChange={handleTextFieldChange}
            />
          </SC.Box>
          <SC.Box>
            <SC.TextFieldPlan
              name="pricePerMinute"
              label="Price per minute after"
              variant="outlined"
              placeholder="$0.00 USD"
              fullWidth
              required
              value={
                itemData.pricePerUnitInCent !== null
                  ? Number(itemData.pricePerUnitInCent) / 100
                  : ""
              }
              // value="0"
              onChange={handleTextFieldChange}
            />
          </SC.Box>
          <SC.Box>
            <SC.TextFieldPlan
              name="totalAudioTime"
              label="Total audio time"
              variant="outlined"
              placeholder="0 minutes"
              fullWidth
              required
              value={
                itemData.audionautUnitInSec !== null
                  ? Number(itemData.audionautUnitInSec) / 60
                  : ""
              }
              onChange={handleTextFieldChange}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xs={12} md={6} disabledMarginTop>
          {/* <SC.Box disabledMarginTop className={classes.description}>
            <SC.TextFieldPlan
              name="description"
              label={isPlan ? "Plan Description" : "Coupon Description"}
              placeholder="Set a description"
              variant="outlined"
              fullWidth
              required
              // value={itemData.workName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
              multiline
              rows={6}
            />
          </SC.Box> */}
          {!isPlan ? (
            <SC.Box>
              <SC.TextFieldPlan
                name="validityDate"
                label="Validity Date "
                variant="outlined"
                placeholder="Corporatus Plan"
                fullWidth
                required
                // value={itemData.}
                onChange={handleTextFieldChange}
              />
            </SC.Box>
          ) : (
            <>
              <SC.Box disabledMarginTop>
                <SC.TextFieldPlan
                  name="trialPeriodNumOfDays"
                  label="Trial days"
                  variant="outlined"
                  placeholder="0"
                  fullWidth
                  value={
                    itemData.trialPeriodNumOfDays !== null
                      ? itemData.trialPeriodNumOfDays
                      : ""
                  }
                  // value="0"
                  onChange={handleTextFieldChange}
                />
              </SC.Box>
              <SC.Box className={classes.description}>
                <SC.TextFieldPlan
                  name="description"
                  label={isPlan ? "Plan Description" : "Coupon Description"}
                  placeholder="Set a description"
                  variant="outlined"
                  fullWidth
                  required
                  value={descriptionText}
                  onChange={handleTextFieldChange}
                  multiline
                  rows={6}
                />
              </SC.Box>
            </>
          )}
        </SC.Grid>
      </SC.Grid>
    </>
  );
};

export default PlansCouponsFormFields;
