// Dependencies
import {
  withStyles,
  Box,
  Button as ButtonBase,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiDialogActions from "@material-ui/core/DialogActions";

const OrganizationForm = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    paddingBottom: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    [theme.breakpoints.down(540)]: {
      position: "sticky",
      width: "calc(100vw - 40px)",
      left: "0px",
    },
  },
}))(Box);

const FormActions = withStyles({
  root: {
    justifyContent: "rigth",
    marginTop: "40px",
    padding: "0px",
  },
})(MuiDialogActions);

const Button = withStyles((theme: Theme) => ({
  root: {
    height: "48px",
    width: "144px",
    color: "#FFFFFF",
    backgroundColor: "#B8CAC6",
    border: "2px solid #B8CAC6 !important",
    borderRadius: "116px",
    "&:hover": {
      backgroundColor: "#8773B1",
    },
    [theme.breakpoints.down(540)]: {
      width: "100%",
    },
  },
  contained: {
    color: "#FFFFFF",
    backgroundColor: "#B8CAC6",
  },
  label: {
    textTransform: "capitalize",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "bold",
  },
}))(ButtonBase);

export default {
  OrganizationForm,
  FormActions,
  EditIcon,
  CancelCircleIcon,
  CheckCircle,
  ErrorOutlineIcon,
  Button,
};
