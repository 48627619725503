import React from "react";
import { BehaviorSubject } from "rxjs";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import TagManager from "react-gtm-module";

import { OsMediaProFragment } from "graphql/search/qanda-pro-types-and-hooks";
import { OsMediaFragment } from "../../graphql/open-search/types-and-hooks";
import { getSearchRequestConfig } from "../../graphql/corporatus/corporatus_rest_graphql.utils";
import {
  SRSData,
  SRSDataSource,
  SearchRequestConfig,
  SearchResponseStreamModel,
} from "../../graphql/corporatus/corporatus_rest_graphql.types";
import {
  ChatItemInterface,
  MessageTypes,
} from "../../pages/public-search/public-search.utils";

const { REACT_APP_API_REST_CORPORATUS_SERVER_URL } = process.env;

export class StreamResponseService {
  private static instance: StreamResponseService;

  public response$: BehaviorSubject<any>;

  public imagesPersisted$: BehaviorSubject<any>;

  // BehaviorSubject<{sessionId: string; loading: boolean; secureImages: string;}[]>;

  public isStreaming: boolean;

  public currentHistory: ChatItemInterface[];

  public tutoShowed: boolean;

  private imagesShowed: { sessionId: string; sourceIndex: number } | null;

  private imagesData: {
    [key: string]: { secureUrl: string | null; caption: string | null }[][];
  } | null = null;

  private constructor() {
    // console.debug("=====> Init Service StreamResponseService");

    this.isStreaming = false;
    this.response$ = new BehaviorSubject(null);
    this.imagesPersisted$ = new BehaviorSubject(null);
    this.currentHistory = [];
    this.tutoShowed = false;
    this.imagesShowed = null;
  }

  public static getInstance(): StreamResponseService {
    if (!StreamResponseService.instance) {
      StreamResponseService.instance = new StreamResponseService();
    }

    return StreamResponseService.instance;
  }

  public setCurrentHistory(history: ChatItemInterface[]) {
    this.currentHistory = history;
  }

  public streamResponse = (
    query: string,
    plan: string,
    url: string,
    apyKey: string,
    type: "ws" | "sse" = "ws",
    history?: {
      query: string;
      response: string;
    }[],
    response?: string,
    caption?: string,
    responseIndex?: number
  ) => {
    // console.debug("====> Start query request: ", query);
    const self = this;
    const manageResponse = (streamResponse) => {
      // console.debug("======> Manage response: ", streamResponse);
      if (streamResponse) {
        const {
          data,
          sources,
          is_last_event: isLastEvent,
          event_id: eventId,
        } = streamResponse;

        const currentMessage = {
          message:
            eventId === 1
              ? data
              : this.response$.value.message.concat(data ?? ""),
          created:
            eventId === 1
              ? new Date().toDateString()
              : this.response$.value.created,
          type: MessageTypes.REMOTE,
          detail: isLastEvent ? (sources as OsMediaFragment[]) : [],
          isLastEvent,
        };

        this.response$.next(currentMessage);

        if (isLastEvent) {
          this.isStreaming = false;
          this.currentHistory[
            responseIndex === undefined || responseIndex === null
              ? this.currentHistory.length - 1
              : responseIndex
          ] = currentMessage;
          this.response$.next(null);

          if (!sources || sources.length === 0) {
            TagManager.dataLayer({
              dataLayer: {
                event: "userQuestionNoSources",
              },
            });
          }

          TagManager.dataLayer({
            dataLayer: {
              event: "userQuestionSuccess",
            },
          });
        }
      }
    };

    // Send a requeste by WebSocket
    const wsSendMessage = (
      question: string,
      config: SearchRequestConfig,
      historic?: {
        query: string;
        response: string;
      }[],
      disResponse?: string,
      disCaption?: string
    ) => {
      const options = {
        question,
        config,
        history: historic,
        response: disResponse,
        caption: disCaption,
      };

      if (!historic) {
        delete options.history;
      }

      if (!response || !caption) {
        delete options.response;
        delete options.caption;
      }

      // console.debug("=====> Start connection with WS", options);
      const ws = new WebSocket(`${url}?api_key=${apyKey}`);
      // console.debug("=====> WebSocket instance: ", ws);

      ws.onopen = (event) => {
        // console.debug("====> WS onopen: ", event, ws.readyState);
        const message = JSON.stringify(options);

        ws.send(message);
        // console.debug("=====> Message sent: ", message);
      };
      ws.onmessage = (event) => {
        // console.debug("====> On Message: ", event, ws.readyState);
        const { data } = event;

        // console.debug("=====> Response: ", data, data.event_id);

        const WSResponse = JSON.parse(data) as SRSData;
        // console.debug("=====> Response: ", response, response.event_id);
        manageResponse(WSResponse);
        self.isStreaming = true;
      };

      ws.onerror = (error) => {
        console.error("=====> WS Error: ", error);
        this.isStreaming = false;
      };

      ws.onclose = (event) => {
        // console.warn("=====> OnClose: Socket closed -> ", event);
        this.isStreaming = false;
      };
    };

    // Send a requeste by Server-Sent events (SSE)
    const fetchData = async () => {
      await fetchEventSource(`${url}/model/corporatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apyKey}`,
        },
        body: JSON.stringify({
          question: query,
          config: getSearchRequestConfig(true, ""),
        }),
        async onopen(res) {
          // if (res.ok && res.status === 200) {
          //   console.log("Connection made ", res);
          // } else
          if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            console.log("Client side error ", res);
            // setDisabled(false);
            self.isStreaming = false;
          }
        },
        onmessage(event) {
          // console.log(event.data);
          // console.debug("======> message: ", event);
          const { data } = event as unknown as SearchResponseStreamModel;

          const SSEResponse = JSON.parse(data) as SRSData;
          manageResponse(SSEResponse);
          self.isStreaming = true;
          // const parsedData = JSON.parse(event.data);
          // setData((data) => [...data, parsedData]);
        },
        onclose() {
          console.log("Connection closed by the server");
          // setDisabled(false);
          self.isStreaming = false;
        },
        onerror(err) {
          console.log("There was an error from server", err);
          // setDisabled(false);
          self.isStreaming = false;
        },
      });
    };

    if (type === "ws") {
      let wsOptions: {
        question: string;
        config: SearchRequestConfig;
        history?: {
          query: string;
          response: string;
        }[];
        response?: string;
        caption?: string;
      } = {
        question: query,
        config: getSearchRequestConfig(true, plan),
      };

      if (history) {
        wsOptions = {
          ...wsOptions,
          history,
        };
      }

      if (response && caption) {
        wsOptions = {
          ...wsOptions,
          response,
          caption,
        };
      }

      wsSendMessage(
        query,
        getSearchRequestConfig(true, plan),
        history,
        response,
        caption
      );
    } else fetchData();
  };

  public assingSourcesImages(sessionId: string, sourceIndex: number) {
    if (this.imagesData && this.imagesData[sessionId]) {
      this.imagesShowed = {
        sessionId,
        sourceIndex,
      };
      this.imagesPersisted$.next(this.imagesData[sessionId][sourceIndex] ?? []);
    }
  }

  public clearSourcesImages() {
    this.imagesShowed = null;
    this.imagesPersisted$.next(null);
  }

  // Logic to generate the images url secure
  private getImageData = async (
    sessionId: string,
    sourceIndex: number,
    imageData
  ) => {
    // console.debug("====> Trying to get the image data: ", imageData);
    let success = true;

    if (this.imagesData && this.imagesData[sessionId]) {
      if (!this.imagesData[sessionId][sourceIndex]) {
        this.imagesData[sessionId][sourceIndex] = [];
      }

      this.imagesData[sessionId][sourceIndex].push({
        secureUrl: null,
        caption: null,
      });
    }

    if (
      this.imagesShowed &&
      this.imagesShowed.sessionId === sessionId &&
      this.imagesShowed.sourceIndex === sourceIndex
    ) {
      this.assingSourcesImages(sessionId, sourceIndex);
    }

    try {
      // setImagesShowed((oldImagesShowed) => {
      //   const dommieImage = { url: null, title: null };
      //   const imagesShowedTemp = [...oldImagesShowed];
      //   imagesShowedTemp.push(dommieImage);
      //   return imagesShowedTemp;
      // });
      const blobImage = await fetch(
        `${REACT_APP_API_REST_CORPORATUS_SERVER_URL}/captions/get_image?minio_object_name=corporatus/${imageData.url}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "image/jpeg",
          },
        }
      ).then((response) => response.blob());
      const safeImageUrl = window.URL.createObjectURL(new Blob([blobImage]));
      // console.debug("=====> Image url secure", safeImageUrl);

      const captionResponse = await fetch(
        `${REACT_APP_API_REST_CORPORATUS_SERVER_URL}/openclip/generate_caption_minio/?url_minio=${imageData.url}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((response) => response.json());
      // console.debug("=====> captionResponse: ", captionResponse);

      // setSourcesImages((oldImagesShowed) => {
      //   // const currentImages = [...oldImagesShowed];
      //   const newImageData = { ...imageData };
      //   newImageData.url = safeImageUrl;
      //   newImageData.title = captionResponse.caption;

      //   // const dommieImageindex = dommieImagesShowed.findIndex(
      //   //   (image) => !image.url
      //   // );

      //   // if (dommieImageindex > -1) {
      //   //   dommieImagesShowed[dommieImageindex] = newImageData;
      //   // } else {
      //   //   dommieImagesShowed.push(newImageData);
      //   // }
      //   // currentImages.push(newImageData);

      //   return [...oldImagesShowed, newImageData];
      // });

      if (this.imagesData && this.imagesData[sessionId]) {
        const dommieIndex = this.imagesData[sessionId][sourceIndex].findIndex(
          (image) => !image.secureUrl
        );
        if (dommieIndex !== -1) {
          this.imagesData[sessionId][sourceIndex][dommieIndex] = {
            secureUrl: safeImageUrl,
            caption: captionResponse.caption,
          };
        }
      }
      // console.debug("======> Images data updated: ", this.imagesData);
    } catch (error) {
      // setLoadingImage(false);
      success = false;
      console.error("=====> Error while trying get images 1: ", error);

      if (this.imagesData && this.imagesData[sessionId]) {
        const dommieIndex = this.imagesData[sessionId][sourceIndex].findIndex(
          (image) => !image.secureUrl
        );

        // console.debug(
        //   "=====> Current images data: ",
        //   this.imagesData[sessionId][sourceIndex],
        //   dommieIndex
        // );

        if (dommieIndex !== -1) {
          this.imagesData[sessionId][sourceIndex].splice(dommieIndex, 1);
        }
      }
      // setImagesShowed((oldImagesShowed) => {
      //   let dommieImagesShowed = [...oldImagesShowed];
      //   const dommieImageindex = dommieImagesShowed.findIndex(
      //     (image) => !image.url
      //   );

      //   if (dommieImageindex > -1) {
      //     dommieImagesShowed = dommieImagesShowed.splice(dommieImageindex, 1);
      //   }

      //   return [...dommieImagesShowed];
      // });
    }
    // setImageDataResponses(imageDataResponses + 1);

    if (
      this.imagesShowed &&
      this.imagesShowed.sessionId === sessionId &&
      this.imagesShowed.sourceIndex === sourceIndex
    ) {
      this.assingSourcesImages(sessionId, sourceIndex);
    }

    return success;
  };

  public getPapersImagesURLS = (
    sessionId: string,
    sourceIndex: number,
    paperSources: OsMediaFragment[] | OsMediaProFragment[] | SRSDataSource[]
  ) => {
    try {
      // console.debug("=====> Start reuqest to get images urls");

      if (!this.imagesData) {
        this.imagesData = {};
      }

      if (this.imagesData && !this.imagesData[sessionId]) {
        this.imagesData[sessionId] = [];
      }

      paperSources.forEach(async (source) => {
        const { bucket_path: bucketPath, page } = source;

        // console.debug("=====> Getting a images urls: ", bucketPath);
        // await getPaperImagesRequest({
        //   variables: {
        //     filePath: bucketPath,
        //     pages: page ? page.replaceAll(" ", "") : "",
        //   },
        // });

        const paperImagesResponse = await fetch(
          `${REACT_APP_API_REST_CORPORATUS_SERVER_URL}/captions/process_pdf_minio_v2?minio_object_name=${bucketPath}&pages_doc=${
            page ? page.replaceAll(" ", "") : ""
          }`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((response) => response.json());
        // console.debug("=====> paper image response: ", paperImagesResponse);

        if (
          paperImagesResponse &&
          paperImagesResponse.message &&
          paperImagesResponse.message.pages
        ) {
          const pagesData = paperImagesResponse.message.pages;
          let newData = [];
          Object.keys(pagesData).forEach((itemKey) => {
            const data = pagesData[itemKey];
            const images = Object.keys(data.sheets).map((sheetKey) => ({
              page: itemKey,
              imageSheet: data.file,
              url: data.sheets[sheetKey].file,
            }));

            newData = newData.concat(images as any);
          });

          // console.debug("=====> new data: ", newData, newData.length);
          // setPaperImages(newData);
          // setImagesShowed(newData.length > 10 ? newData.slice(0, 10) : newData);
          newData.forEach((imageData) => {
            this.getImageData(sessionId, sourceIndex, imageData);
          });
        }
      });
      // console.debug("=====> End reuqest to get images urls");
    } catch (error) {
      console.error("=====> Error while trying get the images urls", error);
    }
  };
}
