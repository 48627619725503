import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import SC from "./messages-notify.styles";

interface MessagesNotifyComponentProps {
  message: string;
  type: string;
  show: boolean;
  actionMessage?: string;
  successHandler?: any;
  cancelHandler?: any;
}

const MessagesNotifyComponent: React.FC<MessagesNotifyComponentProps> = ({
  message,
  type,
  show = false,
  actionMessage,
  successHandler = null,
  cancelHandler = null,
}) => {
  const classes = SC.useStyles();

  return (
    <Box hidden={!show} className={`${classes.container} ${type}`}>
      {type === "error" ? <WarningIcon /> : null}
      <Typography className={classes.messageText}>{message}</Typography>
      {successHandler ? (
        <SC.ActionButton onClick={successHandler}>
          {actionMessage}
        </SC.ActionButton>
      ) : null}
      {cancelHandler ? (
        <SC.ActionButton onClick={successHandler}>Cancel</SC.ActionButton>
      ) : null}
    </Box>
  );
};

export default MessagesNotifyComponent;
