import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/**
 * A class used to represent the Query
 * ...
 * Attributes
 * ----------
 * query_elastic: List
 *     List of documents found in Elasticsearch
 */
export type Query = {
  __typename?: 'Query';
  queryElastic?: Maybe<Answers>;
};


/**
 * A class used to represent the Query
 * ...
 * Attributes
 * ----------
 * query_elastic: List
 *     List of documents found in Elasticsearch
 */
export type QueryQueryElasticArgs = {
  searchString: Scalars['String'];
  fromPagination?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type Answers = {
  __typename?: 'answers';
  results?: Maybe<Array<Maybe<Document_Elastic>>>;
  totalResults?: Maybe<Scalars['Int']>;
};

/**
 * A class used to represent an Document in Eslasticsearch
 * ...
 *
 * Attributes
 * ----------
 * media_id: str
 *     ID to identify the sentence
 * media_name: str
 *     Name of the Media
 * sentence: str
 *     Text that forms the sentence
 * created: str
 *     Date when the media was added
 * total_words: int
 *     Number of words in the sentence
 */
export type Document_Elastic = {
  __typename?: 'document_elastic';
  mediaId?: Maybe<Scalars['String']>;
  mediaName?: Maybe<Scalars['String']>;
  sentence?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  totalWords?: Maybe<Scalars['Int']>;
};

export type SearchResponseFragment = (
  { __typename?: 'document_elastic' }
  & Pick<Document_Elastic, 'mediaId' | 'mediaName' | 'sentence' | 'created'>
);

export type SearchEntityQueryVariables = Exact<{
  search: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
}>;


export type SearchEntityQuery = (
  { __typename?: 'Query' }
  & { queryElastic?: Maybe<(
    { __typename?: 'answers' }
    & Pick<Answers, 'totalResults'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'document_elastic' }
      & SearchResponseFragment
    )>>> }
  )> }
);

export const SearchResponseFragmentDoc = gql`
    fragment SearchResponse on document_elastic {
  mediaId
  mediaName
  sentence
  created
}
    `;
export const SearchEntityDocument = gql`
    query searchEntity($search: String!, $total: Int = 10, $index: Int = 0) {
  queryElastic(searchString: $search, fromPagination: $index, size: $total) {
    results {
      ...SearchResponse
    }
    totalResults
  }
}
    ${SearchResponseFragmentDoc}`;

/**
 * __useSearchEntityQuery__
 *
 * To run a query within a React component, call `useSearchEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEntityQuery({
 *   variables: {
 *      search: // value for 'search'
 *      total: // value for 'total'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useSearchEntityQuery(baseOptions: Apollo.QueryHookOptions<SearchEntityQuery, SearchEntityQueryVariables>) {
        return Apollo.useQuery<SearchEntityQuery, SearchEntityQueryVariables>(SearchEntityDocument, baseOptions);
      }
export function useSearchEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchEntityQuery, SearchEntityQueryVariables>) {
          return Apollo.useLazyQuery<SearchEntityQuery, SearchEntityQueryVariables>(SearchEntityDocument, baseOptions);
        }
export type SearchEntityQueryHookResult = ReturnType<typeof useSearchEntityQuery>;
export type SearchEntityLazyQueryHookResult = ReturnType<typeof useSearchEntityLazyQuery>;
export type SearchEntityQueryResult = Apollo.QueryResult<SearchEntityQuery, SearchEntityQueryVariables>;