import React from "react";
import {
  Typography,
  Box,
  Button,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Alert, AlertProps } from "components/alert/alert.component";
import { isMobileResolution } from "commons/utils/device-info.util";
import Table, { TableColumn } from "components/table/table.component";
import { TopicItemModel } from "graphql/corporatus/corporatus_rest_graphql.types";
import { createEmptyTopicItem } from "graphql/audionaut.utils";
import IconButtonGeneric from "components/icon-button/icon-button.component";
import TopicsModal from "./topics-modal/topics-modal.component";
import SC from "./crud-topics.styles";

const { REACT_APP_API_REST_TOPICS_SERVER_URL } = process.env;
const CrudTopicsPage: React.FC = () => {
  const classes = SC.useStyles();
  const [showImages, setShowImages] = React.useState<boolean>(false);
  const [topicsList, setTopicsList] = React.useState<TopicItemModel[]>([]);
  const [itemsList, setItemsList] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [topicsLoading, setTopicsLoading] = React.useState(false);
  const [editingRecordId, setEditingRecordId] = React.useState<string | "">("");
  const [count, setCount] = React.useState(0);
  const [openTopicModal, setTopicModal] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [topicInfo, setTopicInfo] = React.useState<TopicItemModel>(() =>
    createEmptyTopicItem()
  );

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handleOpenTopicModal = () => {
    setTopicModal(true);
  };
  const handleCloseTopicModal = () => {
    setTopicModal(false);
  };

  const getTopics = React.useCallback(async () => {
    try {
      const topicsTable = await fetch(
        `${REACT_APP_API_REST_TOPICS_SERVER_URL}/read`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((response) => response.json());
      setTopicsList(JSON.parse(topicsTable));
    } catch (error) {
      console.error("=====> Error Obtaining Topics: ", error);
    }
  }, []);

  const handlerDeleteRecord = React.useCallback(
    async (topic) => {
      try {
        if (topic !== null) {
          await fetch(`${REACT_APP_API_REST_TOPICS_SERVER_URL}/update`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              properties_update: ["active"],
              values_update: ["False"],
              where_properties: ["topic"],
              where_values: [`"${topic}"`],
            }),
          }).then((response) => response.json());
        } else {
          await fetch(`${REACT_APP_API_REST_TOPICS_SERVER_URL}/update`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              properties_update: ["active"],
              values_update: ["False"],
              where_properties: ["topic"],
              where_values: [`${topic}`],
            }),
          }).then((response) => response.json());
        }
        getTopics();
      } catch (error) {
        console.error("=====> Error Obtaining Topics: ", error);
      }
    },
    [getTopics]
  );

  const onSaveTopic = React.useCallback(async (topicData: TopicItemModel) => {
    try {
      await fetch(`${REACT_APP_API_REST_TOPICS_SERVER_URL}/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(topicData),
      }).then((response) => {
        if (response.status === 200) {
          setSnackBarMessage({
            message: "The topic has been created successfully",
            severity: "success",
          });
        } else {
          setSnackBarMessage({
            message: "An error occurred while creating the topic",
            severity: "error",
          });
        }
      });
    } catch (error) {
      setSnackBarMessage({
        message: "An error occurred while creating the topic",
        severity: "error",
      });
      console.error("=====> Error Obtaining Topics: ", error);
    }
  }, []);

  const handlerOnSubmit = React.useCallback(
    async (topicData) => {
      // event.preventDefault();
      onSaveTopic(topicData);
      handleCloseTopicModal();
      setTopicsList((oldTopicList) => {
        const currentTopicList = [...oldTopicList];
        currentTopicList.push(topicData);
        return currentTopicList;
      });
    },
    [onSaveTopic]
  );

  const columns: TableColumn<TopicItemModel>[] = React.useMemo(
    () => [
      {
        Header: "Index",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => <Box display="flex"> {row.id} </Box>,
      },
      {
        Header: "Topic",
        accessor: "topic",
        align: "left",
        width: 210,
        Cell: ({ row, value }) => (
          <Box display="flex" width="200px" height="50px">
            <Box my="auto">{value}</Box>
          </Box>
        ),
      },
      {
        Header: "Caltech Scraper",
        accessor: "caltech_scraper",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100px"
            height="50px"
          >
            <Checkbox
              checked={value}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
          </Box>
        ),
      },
      {
        Header: "Escholarship Scraper",
        accessor: "escholarship_scraper",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100px"
            height="50px"
          >
            <Checkbox
              checked={value}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
          </Box>
        ),
      },
      {
        Header: "Liberty Scraper",
        accessor: "liberty_scraper",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100px"
            height="50px"
          >
            <Checkbox
              checked={value}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
          </Box>
        ),
      },
      {
        Header: "Uvic Scraper",
        accessor: "uvic_scraper",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100px"
            height="50px"
          >
            <Checkbox
              checked={value}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
          </Box>
        ),
      },
      {
        Header: "Actions",
        align: "left",
        width: 100,
        Cell: ({ row, value }) => (
          <Box display="flex">
            <Box display="flex">
              <IconButtonGeneric
                name=""
                type="button"
                icon={<DeleteIcon color="primary" />}
                onClick={() => handlerDeleteRecord(row.values.topic)}
                // () =>
                // console.debug(
                //  "=====> ImageSearchIcon: Start paper processing to get the images and captions",
                //  value,
                //  row.id
                // )
              />
            </Box>
          </Box>
        ),
      },
    ],
    [handlerDeleteRecord]
  );

  React.useEffect(() => {
    getTopics();
  }, [getTopics]);

  React.useEffect(() => {
    if (topicsList && count < 1) {
      // console.debug("=====> papersListData: ", papersListData);
      setTopicsLoading(true);
      setCount(1);
    } else {
      setTopicsLoading(false);
    }
  }, [count, topicsList]);
  return (
    <SC.TopicContainer>
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>
      <Box width="100%">
        <SC.HeaderPage>
          <Typography variant="h1" color="primary">
            Topics
          </Typography>
        </SC.HeaderPage>
        <SC.PapersListConatiner>
          <SC.PapersTable
            hiddenTitle
            hiddenFooter
            loading={topicsLoading}
            title="Topics"
            data={topicsList}
            columns={
              columns as unknown as TableColumn<Record<string, unknown>>[]
            }
            onAction={(action) => {
              console.debug("=====> on action: ", action);
            }}
            topPanel={
              <SC.SearchInputContainer>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenTopicModal}
                >
                  Create Topic
                </Button>
              </SC.SearchInputContainer>
            }
          />
        </SC.PapersListConatiner>
      </Box>
      <TopicsModal
        onSubmit={handlerOnSubmit}
        openModal={openTopicModal}
        topicInfo={topicInfo}
        handleCloseModal={handleCloseTopicModal}
      />
    </SC.TopicContainer>
  );
};

export default CrudTopicsPage;
