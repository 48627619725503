/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Theme,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AddIcon from "@material-ui/icons/Add";
import waveBorder from "assets/images/wave-border.png";

import { withStyles, createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import {
  useUpdateTargetsGroupMutation,
  useDeleteTargetsGroupMutation,
  useUpdateTargetMutation,
  useDeleteTargetMutation,
} from "graphql/types-and-hooks";
import { Reference, StoreObject } from "@apollo/client";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

const paymentMethodStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      title: {
        "& h1": {
          textAlign: "left",
        },
        pagination: {
          flex: "1",
        },
      },
    },
    itemContainer: {
      // margin: "auto",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
      padding: "11px 11px 6px 11px",
      borderRadius: "10px",
      border: `1px solid ${theme.generalStylesContainer.layers.content.borderColor}`,
      boxSizing: "border-box",
      marginBottom: "30px",
    },
    addPaymentContainer: {
      height: "148px",
      maxHeight: "148px",
      maxWidth: "402px",
      background: theme.generalStylesContainer.layers.content.backgroundColor,
      padding: "10px",
      border: "none !important",
      [theme.breakpoints.down(540)]: {
        maxWidth: "unset !important",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1) !important",
        border: `1px solid ${theme.generalStylesContainer.layers.content.backgroundColor} !important`,
      },
    },
    addPaymentMethodItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      border: `2px dashed ${theme.generalStylesContainer.color}`,
      borderRadius: "13px",
      // color: "#767676",
      cursor: "pointer",
      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "normal",
        // color: "#767676",
      },
    },
    updateBtn: {
      margin: "auto",
      marginRight: "10px !important",
    },
    deleteBtn: {
      margin: "auto",
      marginLeft: "10px !important",
    },
    emptyPaymentMethodText: {
      fontSize: "16px",
      lineHeight: "20px",
      fontStyle: "italic",
      textAlign: "center",
      margin: "auto",
      padding: "30px",
    },
    sectionContainer: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      marginTop: "30px",
      [theme.breakpoints.down(540)]: {
        marginTop: "0px !important",
        background: "transparent !important",
        border: "unset !important",
        boxShadow: "unset !important",
        padding: "unset !important",
      },
    },
    sectionTitle: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "bold",
      textAlign: "left",
      marginBottom: "20px",
    },
    debitCreditBtn: {
      height: "63px",
      width: "243px",
      border: "1px solid #B8CAC6",
      boxSizing: "border-box",
      borderRadius: "10px",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "19px",
      textAlign: "center",
      color: "#000",
      marginBottom: "26px",
    },
    manageBtn: {
      display: "flex",
      justifyContent: "end",
      "& button": {
        border: "2px solid #929292",
        boxSizing: "border-box",
        borderRadius: "116px",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#767676",
      },
    },
    headerImage: {
      display: "flex",
      height: "36px",
      width: "36px",
      background: "#81EBD166;",
      borderRadius: "18px",
      color: "#973B7B",
      marginRight: "8px",
      "& img": {
        height: "22px",
        width: "22px",
        margin: "auto",
      },
    },
    headerPlan: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "bold",
      },
    },
    formTitle: {
      textAlign: "left",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "bold",
    },
    fullHeight: {
      minHeight: "100vh",
    },
    addressItemsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    gridItem: {
      height: "100%",
      maxHeight: "225px",
    },
    gridPaymentItem: {
      maxHeight: "216px",
    },
  })
);

const SBBody = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    // overflow: "auto",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    padding: "16px",
  },
}))(Box);

const PMContainer = withStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    // display: "flex",
    overflow: "hidden",
  },
}))(Box);

const PMContent = withStyles((theme: Theme) => ({
  root: {
    // display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "30px 32px",
    overflow: "auto",
    "& h1": {
      textAlign: "left",
    },
    [theme.breakpoints.down(540)]: {
      padding: "20px !important",
    },
  },
}))(Box);

const PMHeader = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    minHeight: "130px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(540)]: {
      minHeight: "60px",
    },
  },
}))(Box);

const PMBody = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "calc(100% - 160px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    background: theme.generalStylesContainer.layers.primary.backgroundColor,
    border: `1px solid ${theme.generalStylesContainer.layers.primary.borderColor}`,
    padding: "16px",
  },
}))(Box);

const PMTableHead = withStyles(() => ({
  root: {
    height: "46px",
    paddingTop: "16px",
    paddingLeft: "14px",
    paddingRight: "14px",
    "& th": {
      borderBottom: "1px solid #EAEAEA",
      "& p": {
        fontFamily: "Gothic A1 !important",
        fontStyle: "normal !important",
        fontWeight: "800 !important",
        fontSize: "18px !important",
        lineHeight: "22px !important",
        color: "#000 !important",
      },
    },
  },
}))(TableHead);

const PMTableBody = withStyles(() => ({
  root: {
    "& td": {
      fontSize: "12px !important",
      lineHeight: "15px !important",
    },
    "& tr:nth-child(even)": {
      background: "#9280BB0A",
    },
  },
}))(TableBody);

const ButtonPrimary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.primary.backgroundColor,
    // fontSize: theme.button.primary.fontSize,
    // lineHeight: theme.button.primary.lineHeight,
    color: theme.button.primary.color,
    textTransform: theme.button.primary.textTransform,
    "&:hover": {
      color: theme.button.primary.hoverColor,
      backgroundColor: theme.button.primary.hoverBackground,
    },
    "&:disabled": {
      color: theme.button.primary.disabledColor,
      backgroundColor: theme.button.primary.disabledBackground,
    },
  },
}))(Button);

const ButtonSecondary = withStyles((theme) => ({
  root: {
    height: theme.buttonSize.small.height,
    borderRadius: theme.buttonSize.small.borderRadius,
    fontWeight: theme.buttonSize.small.fontWeight,
    backgroundColor: theme.button.secondary.backgroundColor,
    // fontSize: theme.button.secondary.fontSize,
    // lineHeight: theme.button.secondary.lineHeight,
    color: theme.button.secondary.color,
    border: theme.button.secondary.border,
    textTransform: theme.button.secondary.textTransform,
    "&:hover": {
      color: theme.button.secondary.hoverColor,
      backgroundColor: theme.button.secondary.hoverBackground,
      border: theme.button.secondary.hoverBorder,
    },
    "&:disabled": {
      color: theme.button.secondary.disabledColor,
      backgroundColor: theme.button.secondary.disabledBackground,
      border: theme.button.secondary.disabledBorder,
    },
  },
}))(Button);

interface RowItemProps {
  id: string;
  name: string;
  parentId: string;
  indexRow: string;
  updateTargets: any;
  showToast: any;
}
const PMRowItem: React.FC<RowItemProps> = ({
  id,
  name,
  parentId,
  indexRow,
  updateTargets,
  showToast,
}) => {
  const classes = paymentMethodStyles();

  // Delete dialog state and handlers
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [newTargetName, setNewTargetName] = React.useState<any>({});

  const [deleteTarget, { loading: deleteTargetLoading }] =
    useDeleteTargetMutation({
      update(cache, { data }) {
        const targetToDeleteId = data?.DeleteTarget;
        cache.modify({
          fields: {
            GetTarget(existingTarget, { readField }) {
              return existingTarget.filter(
                (target: Reference | StoreObject | undefined) =>
                  targetToDeleteId !== readField("id", target)
              );
            },
          },
        });
      },
    });
  const [updateTarget, { loading: updateTargetLoading }] =
    useUpdateTargetMutation();

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const handleClickToggleUpdate: React.FormEventHandler =
    React.useCallback(async () => {
      setOpenUpdate(!openUpdate);
    }, [openUpdate, setOpenUpdate]);

  const handleDeleteTarget = async (index: string) => {
    const targetDeleted = await deleteTarget({
      variables: {
        targetId: index,
      },
    });

    showToast({
      message: "The target has been deleted successfully",
      severity: "warning",
    });

    updateTargets();
    handleClickToggleDelete();
  };
  const handleSubmit: React.FormEventHandler = React.useCallback(
    async (event: any) => {
      event.preventDefault();

      const targetUpdated = await updateTarget({
        variables: {
          targetId: indexRow,
          targetName: newTargetName.target.value,
        },
      });

      showToast({
        message: "The target has been updated successfully",
        severity: "success",
      });

      updateTargets();
      handleClickToggleUpdate(event);
    },
    [
      indexRow,
      newTargetName,
      updateTargets,
      handleClickToggleUpdate,
      updateTarget,
      showToast,
    ]
  );

  return (
    <TableRow id={`row-${indexRow}`} style={{ height: 55 }}>
      <TableCell>{name}</TableCell>
      <TableCell>{id}</TableCell>
      <TableCell width={300} align="center">
        <ButtonPrimary
          className={classes.updateBtn}
          onClick={handleClickToggleUpdate}
        >
          Update
        </ButtonPrimary>
        <Dialog
          id={`dialog-${indexRow}`}
          open={openUpdate}
          onClose={handleClickToggleUpdate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <Box component="form" onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                Please typing a new target name:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="nameTarget"
                label="Name"
                type="text"
                placeholder={name}
                fullWidth
                onChange={setNewTargetName}
              />
              <TextField
                margin="dense"
                id="mediaId"
                label="Media ID"
                type="text"
                value={id}
                fullWidth
                disabled
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickToggleUpdate} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button className={classes.deleteBtn} onClick={handleClickToggleDelete}>
          Delete
        </Button>
        <Dialog
          id={`dialog-${indexRow}`}
          open={openDelete}
          onClose={handleClickToggleDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete target</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want delete the target {name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickToggleDelete} color="primary">
              Cancel
            </Button>
            <Button
              onClick={(event) => handleDeleteTarget(indexRow)}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

const NoResults = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "unset",
    margin: "auto",
    border: "none",
    MozBoxShadow: "none",
    [theme.breakpoints.down(540)]: {
      padding: "20px",
      margin: "unset",
      border: "1px solid #E1E1E1 !important",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    },
  },
}))(GenericEmptyMessages);

export default {
  paymentMethodStyles,
  PMContainer,
  PMContent,
  PMHeader,
  PMBody,
  PMTableHead,
  PMTableBody,
  SBBody,
  PMRowItem,
  NoResults,
};
