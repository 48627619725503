/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";

// Components
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// GraphQL
import {
  DepartmentData,
  GroupData,
  OrganisationData,
  UserData,
} from "graphql/types-and-hooks";
import { createEmptyOrganizationData } from "graphql/rbac.utils";

import OrganizationFormFields from "../organization-form-fields/organization-form-fields.component";
// Assets
import SC from "./organization-form.styles";

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;

export interface OrganizationFormProps {
  initialUserData?: any;
  initialEditable: boolean;
  saveActive?: boolean;
  isProfile?: boolean;
  organisations: OrganisationData[];
  departments: DepartmentData[];
  groups: GroupData[];
  onSubmit: (userData: any) => void;
  onCancel: () => void;
}

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  initialUserData = createEmptyOrganizationData(),
  initialEditable,
  saveActive,
  isProfile = false,
  organisations,
  departments,
  groups,
  onSubmit,
  onCancel,
}) => {
  const [userData, setUserData] = React.useState(initialUserData);
  const [isEditable, setIsEditable] = React.useState(initialEditable);

  React.useEffect(() => {
    setUserData(initialUserData);
  }, [initialUserData]);

  const handlerEditOnClick = React.useCallback(() => {
    setIsEditable(true);
  }, []);

  const handlerCancelOnClick = React.useCallback(() => {
    setIsEditable(false);
    setUserData(initialUserData);
    onCancel();
  }, [initialUserData, onCancel]);

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();

      setIsEditable(initialEditable);
      if (initialUserData !== userData) onSubmit(userData);
    },
    [initialEditable, initialUserData, onSubmit, userData]
  );

  const handleOnChange = React.useCallback((newUserData: any) => {
    setUserData(newUserData);
  }, []);

  const buttons = [
    {
      title: "Save",
      variant: "contained",
      type: "submit",
      isShown: isEditable,
    },
    {
      title: "Cancel",
      variant: "outlined",
      handler: handlerCancelOnClick,
      isShown: isEditable,
    },
  ];

  const appPermissionValidator = useAppPermissionValidator();

  if (appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)) {
    buttons.push({
      title: "Edit",
      variant: "outlined",
      handler: handlerEditOnClick,
      isShown: !isEditable,
    });
  }

  return (
    <SC.OrganizationForm component="form" onSubmit={handlerOnSubmit}>
      <OrganizationFormFields
        userData={userData}
        isEditable={isEditable}
        isProfile={isProfile}
        organisations={organisations}
        departments={departments}
        groups={groups}
        onChange={handleOnChange}
      />
      <SC.FormActions>
        {buttons.map(
          ({ isShown, variant, type, handler, title }) =>
            isShown && (
              <SC.Button
                key={title}
                color="primary"
                variant={variant as ButtonVariant}
                type={type as ButtonType}
                onClick={handler}
              >
                {title}
              </SC.Button>
            )
        )}
      </SC.FormActions>
    </SC.OrganizationForm>
  );
};

export default OrganizationForm;
