// Dependencies
import { withStyles, Box as BoxMui, List } from "@material-ui/core";

const Box = withStyles({
  root: {
    paddingRight: "2rem",
    height: "auto",
    overflowY: "auto",
  },
})(BoxMui);

export default {
  Box,
  List,
};
