// Dependencies
import {
  withStyles,
  Box as ToolbarActions,
  Tooltip,
  IconButton as IconButtonBase,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addBtn: {
      width: "112px",
      height: "45px",
      borderRadius: "21px",
      background: "#B8CAC6",
      color: "#FFF",
      margin: "auto",
      marginRight: "10px !important",
      textTransform: "capitalize",
      fontFamily: "Gothic A1",
      fontSize: "20px",
      lineHeight: "25px",
      fontWeight: "bold",
      "&:hover": {
        background: "#8773B1",
      },
      [theme.breakpoints.down(540)]: {
        minWidth: "unset",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        "& .MuiButton-startIcon": {
          margin: "unset",
        },
      },
    },
  })
);

const IconButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      fontSize: theme.table.toolbarActions.fontSize,
    },
    "&:not(.Mui-disabled) .MuiSvgIcon-root": {
      color: theme.table.toolbarActions.iconColor,
    },
  },
}))(IconButtonBase);

export default {
  useStyles,
  ToolbarActions,
  Tooltip,
  IconButton,
};
