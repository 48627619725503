/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { JOBS_FOR_TRAINING_FILTERED } = ActionTypes;
const initialState = [

];

const jobsForTrainingCreationFilteredReducerFn = (state = initialState, action) => {
  switch (action.type) {
    case JOBS_FOR_TRAINING_FILTERED.UPDATE:
    case JOBS_FOR_TRAINING_FILTERED.SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default jobsForTrainingCreationFilteredReducerFn;
