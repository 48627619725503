import React, { ReactElement, RefObject, useEffect } from "react";

import {
  Avatar,
  Box,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";

import { Adsense } from "@ctrl/react-adsense";

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import { useMutation } from "@apollo/client";
import { CLIENT_NAME } from "graphql/client";

import { getPaperImagesByPages } from "graphql/corporatus/corporatus_rest_graphql";

import GenerateItemSearch from "components/search-result-item/search-result-item.component";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";

import { OsMediaFragment } from "graphql/open-search/types-and-hooks";
import { OsMediaProFragment } from "graphql/search/qanda-pro-types-and-hooks";
import { SRSDataSource } from "graphql/corporatus/corporatus_rest_graphql.types";

import { useUserDataStore } from "commons/utils/user-data";
import { isMobileResolution } from "commons/utils/device-info.util";
import AudionautAvatar from "assets/images/logo.svg";
import ChatDivider from "components/chat-date-divider/chat-date-divider.component";

import { StreamResponseService } from "../../commons/services/handle-stream-response";

import SC from "./chat-item.styles";
import SCPublicSearch from "../../pages/public-search/public-search.styles";

const { REACT_APP_API_REST_CORPORATUS_SERVER_URL } = process.env;

enum MessageTypes {
  REMOTE = "remote",
  LOCAL = "local",
}

interface ChatItemInterface {
  created: string;
  message: string;
  type: MessageTypes.REMOTE | MessageTypes.LOCAL;
  detail?: OsMediaFragment[] | OsMediaProFragment[] | SRSDataSource[];
  sessionId?: string;
  isRegenerate?: boolean;
  isLastEvent?: boolean;
}

interface GenericCollapseInterface {
  item: ChatItemInterface;
  isPro: boolean;
  elemRef: RefObject<HTMLElement>;
  customClass?: string;
  isStream?: boolean;
  onClickSource?: (param1: OsMediaFragment | OsMediaProFragment) => void;
  onShowResources?: (param1: string, param2: number, param3: boolean) => void;
  onRegenerateResponse?: (param1: number) => void;
  itemIndex?: number;
  isLastItem?: boolean;
}

const ChatItem: React.FC<GenericCollapseInterface> = ({
  item,
  isPro,
  elemRef,
  customClass,
  isStream = false,
  onClickSource = null,
  onShowResources = null,
  onRegenerateResponse = null,
  itemIndex = 0,
  isLastItem,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [expandedMoreMedias, setExpandedMoreMedias] = React.useState(false);
  const [mediasViewed, setMediasViewed] = React.useState<boolean>(false);
  const [mainMessage, setMainMessage] = React.useState<string>("");
  const [imageDataResponses, setImageDataResponses] = React.useState<number>(0);

  const [sources, setSources] = React.useState<
    OsMediaFragment[] | OsMediaProFragment[] | SRSDataSource[]
  >([]);
  const [sourcesImages, setSourcesImages] = React.useState<
    (
      | {
          imageSheet: string;
          page: string;
          title: string;
          url: string;
        }
      | never
    )[]
  >([]);

  const typingIndex = React.useRef<number>(0);
  const userData = useUserDataStore((state: any) => state.userData);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setExpandedMoreMedias(false);
    }
    if (!mediasViewed) {
      setMediasViewed(true);
    }

    if (onShowResources) {
      onShowResources(item.sessionId ?? "", itemIndex, !expanded);
    }
  };

  const handleExpandMoreMedias = () => {
    setExpandedMoreMedias(!expandedMoreMedias);
  };

  React.useEffect(() => {
    // get all bucket path and pages to get the images
    // console.debug("=====> current sources: ", sources, item.isRegenerate);
    if (sources.length && !item.isRegenerate) {
      const currentStreamResponseService = StreamResponseService.getInstance();
      currentStreamResponseService.getPapersImagesURLS(
        item.sessionId ?? "",
        itemIndex,
        sources
      );
    }
  }, [sources, item, itemIndex]);

  React.useEffect(() => {
    // console.debug(
    //   "=====> autotyping...",
    //   item.message,
    //   typingIndex.current,
    //   isStream
    // );
    if (isStream && item.type === MessageTypes.REMOTE) {
      const { message } = item;
      // console.debug("======> current Message: ", item);

      if (message.length - typingIndex.current > 10) {
        typingIndex.current = message.length;
      }

      // console.debug(
      //   "=====> current lengths: ",
      //   message.length,
      //   typingIndex.current
      // );

      if (item.message.length > typingIndex.current) {
        setTimeout(() => {
          typingIndex.current += 1;
          // console.debug(
          //   "======> set new message part: ",
          //   item,
          //   item.message,
          //   typingIndex.current
          // );
          setMainMessage(item.message.slice(0, typingIndex.current));
        }, Math.floor(Math.random() * (100 - 30) + 30));
      }

      if (!item.isRegenerate && item.detail && item.detail.length > 0) {
        setSources(item.detail);
      }

      // if (item.isLastEvent) {
      //   typingIndex.current = 0;
      // }
    } else {
      setMainMessage(item.message);
      if (!item.isRegenerate && item.detail && item.detail.length > 0) {
        setSources(item.detail);
      }
      typingIndex.current = 0;
      // console.debug("======> set complete message", item, typingIndex.current);
    }
  }, [isStream, item, setMainMessage, mainMessage]);

  React.useEffect(() => {}, [expanded]);

  useEffect(() => {
    if (imageDataResponses > 0) {
      if (imageDataResponses === sources.length) {
        // console.debug(
        //   "=====> All sources images data gotten!!!",
        //   sourcesImages
        // );
        setImageDataResponses(0);
      }
    }
  }, [imageDataResponses, sources, sourcesImages]);

  return (
    <>
      <SC.MessageContainer
        className={`${item.type} ${customClass} ${
          item.type === MessageTypes.REMOTE && expanded ? "full-width" : ""
        }`}
        ref={elemRef}
      >
        <SC.ItemAvatar
          className={item.type}
          src={
            item.type === MessageTypes.REMOTE
              ? AudionautAvatar
              : userData.profilePicture ?? ""
          }
        />
        <Box component="div" className="message-content">
          <Box component="div" className="message">
            {mainMessage ? (
              <>
                <Box
                  hidden={item.type !== MessageTypes.REMOTE}
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: mainMessage.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                  }}
                />
                <Box hidden={item.type === MessageTypes.REMOTE}>
                  <Typography>{mainMessage}</Typography>
                </Box>
              </>
            ) : (
              // <Typography className="text">{mainMessage}</Typography>
              <Player
                autoplay
                loop
                src="https://lottie.host/f23e4f70-a89c-446a-981d-81e24a6b7fe4/WM6ZWahQTi.json"
                style={{ height: "30px" }}
              >
                <Controls visible={false} />
              </Player>
            )}
          </Box>
          {item.type === MessageTypes.REMOTE ? (
            <>
              <SC.MoreMediasContainer
                hidden={
                  item.type !== MessageTypes.REMOTE ||
                  (item.detail && item.detail?.length === 0)
                }
                onClick={handleExpandClick}
                className={`medias-container${isPro ? " chat-pro" : ""}`}
              >
                <SC.MoreMediasButton className={`${isPro ? " chat-pro" : ""}`}>
                  <IconButton>
                    <Box hidden={mediasViewed}>
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/ef655485-5675-452b-94ec-03f7186d6731/XyHV3Oylmt.json"
                        style={{ height: "30px" }}
                      >
                        <Controls visible={false} />
                      </Player>
                    </Box>
                    <Box hidden={!mediasViewed}>
                      <Box hidden={expanded} display="flex">
                        <KeyboardArrowDownIcon />
                      </Box>{" "}
                      <Box hidden={!expanded} display="flex">
                        <KeyboardArrowUpIcon />
                      </Box>
                    </Box>
                  </IconButton>
                  {!isMobileResolution() ? (
                    <Typography>
                      Click here to explore the references to your question.
                    </Typography>
                  ) : null}
                </SC.MoreMediasButton>
              </SC.MoreMediasContainer>
              <SC.MediasCollapsable
                in={expanded}
                timeout="auto"
                unmountOnExit
                className={isPro ? "chat-pro" : ""}
              >
                {sources.length
                  ? sources.map(
                      (searchItem: OsMediaFragment | OsMediaProFragment) => (
                        <GenerateItemSearch
                          item={searchItem}
                          isPro={isPro}
                          onClick={(
                            itemClicked: OsMediaFragment | OsMediaProFragment
                          ) => {
                            if (onClickSource) {
                              onClickSource(itemClicked);
                            }
                          }}
                        />
                      )
                    )
                  : // <>
                    //   {isPro ? (
                    //     item.detail.map(
                    //       (searchItem: OsMediaFragment | OsMediaProFragment) => (
                    //         <GenerateItemSearch item={searchItem} isPro={isPro} />
                    //       )
                    //     )
                    //   ) : (
                    //     <GenerateItemSearch item={item.detail[0]} isPro={isPro} />
                    //   )}
                    //   {!isPro && item.detail.length > 1 ? (
                    //     <>
                    //       <ChatDivider
                    //         element={
                    //           <>
                    //             <IconButton
                    //               hidden={item.detail.length === 1}
                    //               onClick={handleExpandMoreMedias}
                    //             >
                    //               {!expandedMoreMedias ? (
                    //                 <KeyboardArrowDownIcon />
                    //               ) : (
                    //                 <KeyboardArrowUpIcon />
                    //               )}
                    //             </IconButton>
                    //             {!expandedMoreMedias ? "Other" : "Hide"} Answers
                    //           </>
                    //         }
                    //       />
                    //       <Collapse
                    //         in={expandedMoreMedias}
                    //         timeout="auto"
                    //         unmountOnExit
                    //       >
                    //         {item.detail
                    //           .slice(1, item.detail.length)
                    //           .map(
                    //             (
                    //               searchItem: OsMediaFragment | OsMediaProFragment
                    //             ) => (
                    //               <GenerateItemSearch
                    //                 item={searchItem}
                    //                 isPro={isPro}
                    //               />
                    //             )
                    //           )}
                    //       </Collapse>
                    //     </>
                    //   ) : null}
                    // </>
                    "No Results"}
                {sources.length ? (
                  <SC.LikesContainer component="div">
                    <IconButton
                      hidden
                      type="button"
                      className="active"
                      onClick={() => {
                        // console.debug("====> Click like button");
                      }}
                    >
                      <ThumbUpAltIcon />
                    </IconButton>
                    <IconButton
                      hidden
                      type="button"
                      onClick={() => {
                        // console.debug("====> Click like button");
                      }}
                    >
                      <ThumbDownOutlinedIcon />
                    </IconButton>
                    <IconButton
                      hidden={!isLastItem}
                      type="button"
                      onClick={() => {
                        if (onRegenerateResponse) {
                          onRegenerateResponse(itemIndex);
                          handleExpandClick();
                        }
                      }}
                    >
                      <RefreshOutlinedIcon />
                    </IconButton>
                  </SC.LikesContainer>
                ) : null}
              </SC.MediasCollapsable>
            </>
          ) : null}
        </Box>
      </SC.MessageContainer>
      {item.type === MessageTypes.REMOTE ? (
        <SCPublicSearch.AdSenseContainer
          style={{
            maxHeight: "100px !important",
            width: "calc(100% - 64px)",
            background: "transparent",
            padding: "unset",
            paddingLeft: "64px",
          }}
        >
          <Adsense
            className="adsbygoogle"
            client="ca-pub-6458368253572204"
            slot="8791689159"
            format="auto"
            adTest="on"
            responsive="true"
            style={{
              maxHeight: "100px !important",
              height: "90px",
              width: "728px",
              display: "inline-block",
              bottom: "0px",
              margin: "auto",
            }}
          />
        </SCPublicSearch.AdSenseContainer>
      ) : null}
    </>
  );
};

export default ChatItem;
