import React, { useRef, useImperativeHandle } from "react";
import { ThemeProvider, useTheme } from "@mui/material";
import { useThemeStore } from "commons/utils/user-data";
import { Theme, createTheme } from "@material-ui/core/styles";
import themes from "components/theme-provider/theme";

interface StripeInputProps {
  component: any;
  inputRef: any;
}

const StripeInput: React.FC<StripeInputProps> = ({
  component: Component,
  inputRef,
  ...props
}) => {
  const globalTheme = useThemeStore((state: any) => state.theme);
  const [currentTheme, setCurrentTheme] = React.useState<Theme>(
    createTheme(themes.corporatus.ligth)
  );

  const elementRef: any = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  React.useEffect(() => {
    setCurrentTheme(
      createTheme(
        globalTheme === "ligth"
          ? themes.corporatus.ligth
          : themes.corporatus.dark
      )
    );
  }, [globalTheme]);
  return (
    <Component
      onReady={(element: any) => {
        elementRef.current = element;
      }}
      // eslint-disable-next-line
      {...props}
      options={{
        style: {
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap",
            },
          ],
          base: {
            color: currentTheme.palette.primary.main,
            fontFamily: "Merriweather",
            "::placeholder": {
              color: currentTheme.authGeneralStyles.fontStyles.placeholder,
            },
          },
          invalid: {
            color: currentTheme.palette.error.main,
          },
        },
      }}
    />
  );
};
export default StripeInput;
