// Dependencies
import React from "react";
import { CardActionArea } from "@material-ui/core";
import clsx from "clsx";
// import { useHistory } from "react-router-dom";

// Assets
import fullLogoCorporatus from "assets/icons/corporatus.svg";
import logoCorporatus from "assets/icons/logo.svg";
import fullLogoCorporatusBlack from "assets/icons/corporatus_black.svg";
import SC from "./banner-graphic.styles";

interface BannerGraphicProps {
  goHome: any;
  open: boolean;
  blackLogo?: boolean;
}

const BannerGraphic: React.FC<BannerGraphicProps> = ({
  goHome,
  open,
  blackLogo = false,
}) => {
  const classes = SC.useStyles();
  const currentFullLogo = React.useMemo(
    () => (blackLogo ? fullLogoCorporatusBlack : fullLogoCorporatus),
    [blackLogo]
  );

  return (
    <SC.BannerGraphic onClick={goHome}>
      <CardActionArea className={!open ? classes.paddingLogo : ""}>
        <SC.Logo
          image={open ? currentFullLogo : logoCorporatus}
          title="Logo Audionauts"
        />
      </CardActionArea>
    </SC.BannerGraphic>
  );
};

export default BannerGraphic;
