/* eslint-disable */
// Action Types
import { ActionTypes } from '../actions/types';

const { IDENTITY_PROVIDERS } = ActionTypes;

const INITIAL_STATE = {
  // Create identity provider
  isCreatingData: false,
  onCreateError: null,
  onCreateSuccess: null,
  // Get all identity providers
  identityProviders: [],
  isReceivingData: false,
  error: null,
  // Update identity provider
  isUpdatingData: false,
  onUpdateError: null,
  onUpdateSuccess: null,
  // Delete identity provider
  isDeletingData: false,
  onDeleteError: null,
  onDeleteSuccess: null
};

const identityProvidersReducerFn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Set Identity Providers Data
    case IDENTITY_PROVIDERS.SET.DATA:
      return {
        ...state,
        identityProviders: action.payload
      };
    // Create Identity Provider
    case IDENTITY_PROVIDERS.CREATE.START:
      return {
        ...state,
        isCreatingData: true,
        onCreateError: null
      };
    case IDENTITY_PROVIDERS.CREATE.SUCCESS:
      return {
        ...state,
        onCreateSuccess: action.payload,
        isCreatingData: false
      };
    case IDENTITY_PROVIDERS.CREATE.FAILURE:
      return {
        ...state,
        isCreatingData: false,
        onCreateError: action.payload
      };
    case IDENTITY_PROVIDERS.CREATE.CLEAR:
      return {
        ...state,
        onCreateError: null,
        onCreateSuccess: null
      };
    // Get All Identity Providers
    case IDENTITY_PROVIDERS.FETCH.START:
      return {
        ...state,
        isReceivingData: true,
        error: null
      };
    case IDENTITY_PROVIDERS.FETCH.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        identityProviders: data && data.GetIdentityProvider,
        isReceivingData: false
      };
    }
    case IDENTITY_PROVIDERS.FETCH.FAILURE:
      return {
        ...state,
        error: action.payload,
        isReceivingData: false
      };
    // Update Identity Provider
    case IDENTITY_PROVIDERS.UPDATE.START:
      return {
        ...state,
        isUpdatingData: true,
        onUpdateError: null
      };
    case IDENTITY_PROVIDERS.UPDATE.SUCCESS:
      return {
        ...state,
        isUpdatingData: false,
        onUpdateSuccess: action.payload
      };
    case IDENTITY_PROVIDERS.UPDATE.FAILURE:
      return {
        ...state,
        isUpdatingData: false,
        onUpdateError: action.payload
      };
    case IDENTITY_PROVIDERS.UPDATE.CLEAR:
      return {
        ...state,
        onUpdateError: null,
        onUpdateSuccess: null
      };
    // Delete Identity Provider
    case IDENTITY_PROVIDERS.DELETE.START:
      return {
        ...state,
        isDeletingData: true,
        onDeleteError: null
      };
    case IDENTITY_PROVIDERS.DELETE.SUCCESS:
      return {
        ...state,
        onDeleteSuccess: action.payload,
        isDeletingData: false
      };
    case IDENTITY_PROVIDERS.DELETE.FAILURE:
      return {
        ...state,
        isDeletingData: false,
        onDeleteError: action.payload
      };
    case IDENTITY_PROVIDERS.DELETE.CLEAR:
      return {
        ...state,
        onDeleteError: null,
        onDeleteSuccess: null
      };
    default: return state;
  }
};

export default identityProvidersReducerFn;
