/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React, { useState } from "react";
import { IconProps, IconButtonProps, Button } from "@material-ui/core/";

import ButtonSecondary from "components/button-secondary/button-secondary.component";
import ButtonPrimary from "components/button-primary/button-primary.component";

import SC from "./popover-button.styles";

export interface PopoverButtonProps extends IconButtonProps {
  btnLabel?: string;
  btnIcon?: IconProps;
  content?: string | JSX.Element;
  showPopover?: boolean;
  confirmButton?: string;
  cancelButton?: string;
  onClickHandler?: () => void;
  popoverHandler: () => void;
  onClosePopover?: () => void;
  buttonClasses?: string;
  popoverClasses?: string;
}

export const PopoverButton: React.FC<PopoverButtonProps> = ({
  btnLabel,
  btnIcon,
  content,
  showPopover = true,
  confirmButton = "",
  cancelButton = "",
  onClickHandler = null,
  popoverHandler,
  onClosePopover = null,
  buttonClasses = "",
  popoverClasses = "",
  ...iconButtonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const open = Boolean(anchorEl);
  const popOverId = open ? "popover" : undefined;

  const callHandler = () => {
    popoverHandler();
    setAnchorEl(null);
  };

  const onClosePopoverHandler = React.useCallback(() => {
    if (onClosePopover) {
      onClosePopover();
    }
    setAnchorEl(null);
  }, [onClosePopover]);

  const onClickBtnHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClickHandler) {
        onClickHandler();
      }
      if (showPopover) {
        setAnchorEl(e.currentTarget);
      }
    },
    [showPopover, onClickHandler]
  );

  return (
    <>
      <SC.IconButton
        {...iconButtonProps}
        onClick={onClickBtnHandler}
        className={buttonClasses}
      >
        {btnIcon ?? null} {btnLabel ?? ""}
      </SC.IconButton>
      <SC.Popover
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopoverHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SC.Paper className={popoverClasses}>
          {/* <SC.Message></SC.Message> */}
          {content}
          {cancelButton ? (
            <ButtonSecondary key="cancel" onClick={() => setAnchorEl(null)}>
              {cancelButton}
            </ButtonSecondary>
          ) : null}
          {confirmButton ? (
            <ButtonPrimary key="confirm" onClick={callHandler}>
              {confirmButton}
            </ButtonPrimary>
          ) : null}
        </SC.Paper>
      </SC.Popover>
    </>
  );
};

export default PopoverButton;
