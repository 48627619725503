/* eslint-disable import/prefer-default-export */
import moment from "moment";

const DEFAULT_FORMAT = "MMMM DD, YYYY";

export const getFormattedDate = (date: string, format?: string) => {
  let formattedDate;
  const expReg = /^[0-9]+$/;
  const inDate = !expReg.test(date) ? date : Number(date);

  if (inDate) {
    formattedDate = moment(new Date(inDate)).format(format ?? DEFAULT_FORMAT);
  }
  return formattedDate;
};
