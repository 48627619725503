import {
  Paper,
  Zoom,
  withStyles,
  Box,
  Theme,
  createStyles,
  makeStyles,
  createTheme,
  Card,
  CardMedia,
  CardActions,
  Typography,
  Backdrop,
} from "@material-ui/core";
import Popper from "@mui/material/Popper/Popper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipContainer: {
      maxWidth: "755px",
      width: "100%",
      background: theme.generalStylesContainer.layers.main.backgroundColor,
      borderRadius: "10px !important",
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10) !important",
      padding: "32px 60px 27px",
      '&[data-popper-placement="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "0 100%",
        },
      },
      '&[data-popper-placement="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-1.3em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "100% 0",
        },
      },
      '&[data-popper-placement="right"] $arrow': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "100% 100%",
        },
      },
      '&[data-popper-placement="left"] $arrow': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "0 0",
        },
      },
      [theme.breakpoints.down("lg")]: {
        maxWidth: "555px",
        padding: "18px 20px 18px",
      },
      [theme.breakpoints.down(540)]: {
        width: "calc(100% - 40px)",
        marginLeft: "20px",
      },
    },
    arrow: {
      overflow: "hidden",
      position: "absolute",
      width: "2em",
      height: "1.4em" /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: "border-box",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10) !important",
        backgroundColor:
          theme.generalStylesContainer.layers.secondary.backgroundColor,
        transform: "rotate(45deg)",
      },
    },
  })
);

const TooltipContainer = withStyles((theme: Theme) => ({
  root: {
    background: theme.generalStylesContainer.layers.secondary.backgroundColor,
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  },
}))(Popper);

const TooltipArrow = withStyles((theme: Theme) => ({
  root: {},
}))(Box);

const TooltipContent = withStyles(() => ({
  root: {
    background: "transparent !important",
    border: "unset !important",
    boxShadow: "unset !important",
    padding: "0px !important",
  },
}))(Paper);
const TransitionAnimation = withStyles(() => ({ root: {} }))(Zoom);

const BackdropTooltip = withStyles(() => ({
  root: {
    zIndex: 2,
  },
}))(Backdrop);

export default {
  useStyles,
  TooltipArrow,
  TooltipContainer,
  TooltipContent,
  TransitionAnimation,
  BackdropTooltip,
};
