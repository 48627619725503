/* eslint-disable */
// Dependencies
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// Root Reducer
import rootReducer from './root-reducer';

// Middlewares
import authMiddleware from './middlewares/auth.middleware';

// Utils
// import { loadState, saveState } from '../localStorage';

// Middleware: Redux Persist Config
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'jobFilter',
    'frame',
    'auth',
    'route',
    'help',
    'jsonSchema',
    'slidy',
    'lookerLooksAndDashboards'
  ],
  blacklist: [
  ],
  stateReconciler: autoMergeLevel2
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Persisting state through localStorage
// const persistedState = loadState();

const middlewares = [
  thunk,
  authMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

// Redux: Store
const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

// Middleware: Redux Persist Persister
const persistor = persistStore(store);

// store.subscribe(() => {
//   saveState({
//     frame: store.getState().frame,
//     modebar: store.getState().modebar
//   });
// });

export { store, persistor };
