import React from "react";
import { FormControl, Radio, RadioGroup, Typography } from "@material-ui/core";

import TableCellGenericItem from "components/tableCellGenericItem/tableCellGenericItem.component";

import SC from "./payment-options.styles";

interface PaymentOptionsComponentProps {
  onConfirmHandler: any;
  paymentMethodData: any;
  show: boolean;
  amountOwed: number;
}

const PaymentOptionsComponent: React.FC<PaymentOptionsComponentProps> = ({
  onConfirmHandler,
  paymentMethodData,
  show,
  amountOwed,
}) => {
  const classes = SC.useStyles();

  const [paymentOption, setPaymentOption] = React.useState("card");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentOption((event.target as HTMLInputElement).value);
  };

  const handleConfirm = React.useCallback(async () => {
    onConfirmHandler(paymentOption);
  }, [paymentOption, onConfirmHandler]);

  return (
    <FormControl
      className={classes.fieldSetContainer}
      component="fieldset"
      fullWidth
      hidden={!show}
    >
      <RadioGroup
        aria-label="payment-options"
        name="payment"
        value={paymentOption}
        onChange={handleChange}
      >
        <SC.FormControlLabelOption
          value="card"
          control={
            <Radio
              icon={<SC.RadioIcon component="span" />}
              checkedIcon={<SC.CheckedRadioIcon component="span" />}
            />
          }
          label={`Mastercard: **** **** **** ${paymentMethodData.cardLast4}`}
        />
        <SC.FormControlLabelOption
          value="newCard"
          control={
            <Radio
              icon={<SC.RadioIcon component="span" />}
              checkedIcon={<SC.CheckedRadioIcon component="span" />}
            />
          }
          label="Add credit or debit card"
        />
      </RadioGroup>
      <SC.ResumeAmount>
        <Typography>Amount due:</Typography>
        <TableCellGenericItem value={amountOwed} type="currency" valueKey="" />
        {/* <Typography>$3,000.00 USD</Typography> */}
      </SC.ResumeAmount>
      <SC.FooterOptions>
        <SC.ButtonPrimary onClick={handleConfirm}>
          {paymentOption === "card" ? "Make a payment" : "Add a Payment Method"}
        </SC.ButtonPrimary>
      </SC.FooterOptions>
    </FormControl>
  );
};

export default PaymentOptionsComponent;
