import React from "react";
import { Typography } from "@material-ui/core";

import SC from "./footer.styles";

interface FooterInterface {
  hidden: boolean;
}

const Footer: React.FC<FooterInterface> = ({ hidden = false }) => {
  return (
    <SC.FooterContainer hidden={hidden}>
      <SC.FooterContent>
        <SC.FooterLink href="/">Business</SC.FooterLink>
        <SC.FooterLink href="https://datyra.com/">About</SC.FooterLink>
        <SC.FooterRigthReserved>
          © 2024 Corporatus All Rights Reserved
        </SC.FooterRigthReserved>
        <SC.FooterLink href="https://datyra.com//">
          Privacy Policy
        </SC.FooterLink>
        <SC.FooterLink href="mailto:n@datyra.com">Help</SC.FooterLink>
      </SC.FooterContent>
    </SC.FooterContainer>
  );
};

export default Footer;
