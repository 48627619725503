import React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Box, TableCell } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingLeft0: {
      "& td": {
        paddingLeft: "0px",
      },
      "& th": {
        paddingLeft: "0px !important",
      },
      "& span": {
        paddingLeft: "0px",
      },
    },
    rowEven: {
      background: "rgba(146, 128, 187, 0.04)",
    },
    cellBodyStyle: {
      fontSize: "12px",
      lineHeight: "15px",
      color: "#444444",
      borderBottom: "0px",
      "&.centerText": {
        textAlign: "center",
      },
      "&:first-child": {
        paddingLeft: "7px",
      },
    },
    nameCell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      lineHeight: "23px",
      paddingTop: "16px",
      paddingBottom: "16px",
      verticalAlign: "unset !important",
      [theme.breakpoints.down(540)]: {
        padding: "10px 8px !important",
      },
    },
    nameAvatar: {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
    noimg: {
      color: "#FFFFFF",
      backgroundColor: "#B8CAC6",
    },
    cellAvatar: {
      height: "30px",
      width: "30px",
      [theme.breakpoints.down(540)]: {
        height: "20px",
        width: "20px",
      },
    },
    linkInCell: {
      color: "#A23A74",
      fontSize: "12px",
      lineHeight: "15px",
      textDecorationLine: "underline",
    },
    ellipsisText: {
      maxWidth: "300px",
      width: "calc(100vw / 7)",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down(450)]: {
        width: "calc(100vw / 5)",
      },
    },
    gray: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#ABABAB",
    },
    popOverDatePicker: {
      "& .MuiPaper-root": {
        boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
        borderRadius: "10px",
        "& .MuiTextField-root": {
          width: "100%",
          top: "-10px",
          "& .MuiFormLabel-root": {
            position: "absolute",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#B8CAC6",
            background: "#FFFFFF",
            top: "7px",
            left: "5px",
            zIndex: "1",
            padding: "1px 5px",
          },
          "& .MuiInput-root": {
            border: "2px solid #7E6FA2",
            boxSizing: "border-box",
            borderRadius: "4px",
            padding: "10px",
            fontSize: "18px",
            lineHeight: "22px",
            color: "#B8CAC6",
            "& input": {
              padding: "0px",
            },
          },
          "& .MuiInput-root:after": {
            borderBottom: "none",
          },
          "& .MuiInput-root:before": {
            borderBottom: "none",
          },
        },
        "& .MuiToolbar-root": {
          height: "50px",
          marginLeft: "33px",
          marginRight: "33px",
          marginTop: "21px",
          background: "transparent",
          border: "2px solid #7E6FA2",
          borderRadius: "4px",
          padding: "10px",
          paddingRight: "50px",
          minHeight: "unset",
          "& .MuiGrid-root .MuiButtonBase-root h6.MuiPickersToolbarText-toolbarBtnSelected":
            {
              background: "#B8CAC6",
              color: "#FFFFFF !important",
              fontSize: "12px",
            },
          "& div.MuiGrid-root": {
            flex: "1",
            alignItems: "center",
          },
          "& div.MuiGrid-root div h3": {
            color: "#B8CAC6",
            fontSize: "18px",
          },
          "& div div.MuiGrid-root:first-child": {
            justifyContent: "left",
            flexDirection: "row-reverse",
            flex: "2",
            maxWidth: "unset",
            "& div:first-child": {
              marginLeft: "5px",
            },
          },
          "& div div.MuiGrid-root:last-child": {
            flexDirection: "row-reverse",
            msFlexDirection: "row",
            border: "1px solid #7E6FA2",
            minWidth: "70px",
            borderRadius: "4px",
            marginLeft: "5px",
            "& h6": {
              width: "33px",
              fontSize: "12px",
            },
          },
          "& .MuiButton-label": {
            "& h4": {
              color: "#B8CAC6",
              fontSize: "18px",
              lineHeight: "22px",
            },
            "& h6": {
              color: "#B8CAC6",
              fontSize: "18px",
              lineHeight: "22px",
            },
            "& h3": {
              color: "#B8CAC6",
              fontSize: "18px",
              lineHeight: "22px",
            },
          },
        },
        "& .MuiPaper-root .MuiTabs-root": {
          display: "none",
        },
        "& .MuiPickersBasePicker-pickerView": {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          marginTop: "19px",
          marginLeft: "31px",
          marginRight: "31px",
          marginBottom: "21px",
          "& .MuiPickersCalendarHeader-switchHeader": {
            "& .MuiSvgIcon-root": {
              fill: "#D0D1D2",
            },
            "& .MuiTypography-root": {
              fontSize: "18px",
              lineHeight: "22px",
              color: "#B8CAC6",
            },
          },
        },
        "& .MuiPickersCalendarHeader-daysHeader": {
          "& .MuiTypography-root": {
            fontSize: "12px",
            lineHeight: "15px",
            color: "#B8CAC6",
          },
        },
        "& .MuiPickersCalendar-week": {
          " & .MuiTypography-root": {
            color: "#928BA0",
          },
          "& .MuiPickersDay-daySelected .MuiTypography-root": {
            color: "#FFFFFF",
          },
        },
      },
    },
    actionsCalendar: {
      position: "absolute",
      height: "25px",
      top: "33px",
      right: "0px",
      display: "flex",
      width: "fit-content",
      alignItems: "center",
      justifyContent: "end",
      paddingRight: "44px",
      "& .containerBtns": {
        display: "flex",
        width: "66px",
        height: "25px",
        border: "1px solid #7E6FA2",
        boxSizing: "border-box",
        borderRadius: "4px",
      },
      "& button": {
        minWidth: "unset",
        maxWidth: "32px",
        color: "#B8CAC6",
        flex: "1",
        borderRadius: "0px",
      },
      "& button:active": {
        background: "#7E6FA2",
        color: "#FFFFFF",
      },
      "& svg": {
        color: "#7E6FA2",
      },
    },
    status: {
      "&.DONE": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(90, 227, 194, 0.1)",
        color: "#5AE391",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
      "&.PENDING": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(118, 121, 135, 0.1)",
        color: "rgba(118, 121, 135, 1)",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
      "&.ERROR": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(254, 99, 119, 0.1)",
        color: "rgba(254, 99, 119, 1)",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
      "&.PROCESSING": {
        fontSize: "12px",
        lineHeight: "15px",
        background: "rgba(250, 181, 117, 0.1)",
        color: "rgba(250, 181, 117, 1)",
        width: "auto",
        height: "23px",
        borderRadius: "10px",
      },
    },
    hide: {
      display: "none",
    },
    cellActions: {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  })
);

const TableCellSticky = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(540)]: {
      display: "table-cell",
      textAlign: "center",
      verticalAlign: "middle !important",
      position: "sticky",
      left: "31px",
      zIndex: "10",
      background: "#FFF",
      "&.stickyCell": {
        maxWidth: "120px",
        borderRight: "1px solid #E1E1E1",
        boxShadow: "7px 0 5px -4px rgba(0,0,0,0.1)",
      },
      "&.checkbox": {
        left: "0px !important",
        maxWidth: "31px",
      },
      "&.rowEven": {
        background: "#FBFAFC",
      },
      "& div": {
        textAlign: "start",
      },
    },
  },
}))(TableCell);

const AvatarContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    [theme.breakpoints.down(540)]: {
      columnGap: "5px",
    },
  },
}))(Box);

export default {
  useStyles,
  TableCellSticky,
  AvatarContainer,
};
