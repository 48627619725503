/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Checkbox,
  OutlinedInput,
  Box as BoxMui,
  GridProps,
  BoxProps,
} from "@material-ui/core/";
import { NormalizedCache } from "@apollo/client";

const formStyles = makeStyles((theme) => ({
  profileHeader: {
    display: "flex",
    alignItems: "end",
    paddingBottom: "20px",
    "& .nameLbl": {
      fontSize: "20px",
      lineHeight: "25px",
      fontWeight: "bold",
      textAlign: "left",
    },
    "& .companyLbl": {
      fontSize: "15px",
      lineHeight: "19px",
      fontWeight: "bold",
      color: "#DCDCDC",
      textAlign: "left",
    },
  },
  formContainer: {
    marginTop: "20px",
    marginBottom: "18px",
  },
  subtitle: {
    paddingTop: "18px",
    paddingBottom: "18px",
    fontSize: "20px",
    lineHeight: "25px",
    fontWeight: "bold",
    textAlign: "left",
  },
  hide: {
    display: "none",
  },
  dateField: {
    display: "flex",
    justifyContent: "center",
    border: "1px solid #C6CACC",
    borderRadius: "4px",
    boxSizing: "border-box",
    color: "#6D6D6D",
    margin: "0px",
    height: "56px",
    padding: "18px 12px",
    "& div:before": {
      border: "none",
      borderBottom: "none !important",
    },
    "& div:after": {
      borderBottom: "none !important",
    },
    "& div input": {
      color: "#6D6D6D",
      padding: "0px",
    },
    "& div button": {
      padding: "0px",
    },
    "& fieldset": {
      border: "none",
    },
    "& label": {
      background: "#FFF",
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  emailChecked: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60px",
    width: "100%",
    background: "#77F6C10A",
    border: "2px solid #77F6C1",
    color: "#40DA99",
    marginTop: "32px",
    borderRadius: "6px",
    "& svg": {
      fontSize: "24px",
      marginRight: "10px",
    },
    "& p": {
      color: "#40DA99",
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  footText: {
    color: "#0000008A !important",
    fontWeight: "normal !important" as "normal",
    fontSize: "12px !important",
    lineHeight: "14px !important",
    textAlign: "left",
    marginTop: "5px",
  },
  status: {
    display: "flex",
    "& p": {
      fontSize: "14px !important",
      fontWeight: "normal !important" as "normal",
      lineHeight: "19px !important",
      "&:first-child": {
        color: "#40DA99 !important",
      },
      "&:last-child": {
        color: "#9C9C9C",
      },
    },
    "& .separator": {
      height: "14px",
      background: "#929292",
      width: "1px",
      padding: "0px",
      marginLeft: "13px",
      marginRight: "13px",
    },
  },
  formTitle: {
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",
  },
  description: {
    maxHeight: "auto !important",
  },
  fieldsContainer: {
    border: "1px solid #B8CAC6",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1))",
    borderRadius: "10px",
    padding: "16px",
    [theme.breakpoints.down(540)]: {
      background: "transparent",
    },
  },
  checkContainer: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    "& p": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "normal",
    },
  },
  cardPreview: {
    display: "flex",
    flexDirection: "column",
    height: "145px",
    width: "250px",
    padding: "11px 16px",
    background: "linear-gradient(109.58deg, #B8CAC6 0%, #65DFBD 100%)",
    borderRadius: "5px",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      fontFamily: "'Sarabun'",
      fontSize: "13px",
      lineHeight: "21px",
      fontWeight: "normal",
      letterSpacing: "0.1em",
      color: "#FFF",
      textAlign: "right",
    },
  },
  cardNumber: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    fontFamily: "'Sarabun'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    justifyContent: "center",
    letterSpacing: "3px",
    color: "#FFFFFF",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    "& .expires": {
      "& p": {
        textAlign: "right",
      },
    },
    "& .cardHolder, .expires": {
      "& p": {
        fontFamily: "'Sarabun'",
        textTransform: "uppercase",
        color: "#FFF",
        textAlign: "left",
      },
      "& .title": {
        fontWeight: "lighter",
        fontSize: "10px",
        lineHeight: "14px",
        letterSpacing: "0.4px",
      },
      "& .value": {
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
      },
    },
  },
  invalid: {
    "& label": {
      color: "#FE6377",
    },
    "& fieldset": {
      border: "2px solid #FE6377",
    },
    "& .MuiFormHelperText-root": {
      color: "#FE6377",
    },
  },
  type1Btns: {
    margin: "unset !important",
    marginRight: "16px !important",
    "&.secondaryBtn": {
      height: theme.buttonSize.small.height,
      borderRadius: theme.buttonSize.small.borderRadius,
      fontWeight: theme.buttonSize.small.fontWeight,
      backgroundColor: theme.button.secondary.backgroundColor,
      // fontSize: theme.button.secondary.fontSize,
      // lineHeight: theme.button.secondary.lineHeight,
      color: theme.button.secondary.color,
      border: theme.button.secondary.border,
      textTransform: theme.button.secondary.textTransform,
      "&:hover": {
        color: theme.button.secondary.hoverColor,
        backgroundColor: theme.button.secondary.hoverBackground,
        border: theme.button.secondary.hoverBorder,
      },
      "&:disabled": {
        color: theme.button.secondary.disabledColor,
        backgroundColor: theme.button.secondary.disabledBackground,
        border: theme.button.secondary.disabledBorder,
      },
    },
  },
  resumeAmount: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  paymentSingleBtns: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0px !important",
    "& .nextBtn": {
      width: theme.buttonSize.large.width,
      margin: "unset",
    },
    "& .backBtn": {
      height: theme.buttonSize.small.height,
      width: theme.buttonSize.small.width,
      borderRadius: theme.buttonSize.small.borderRadius,
      fontWeight: theme.buttonSize.small.fontWeight,
      backgroundColor: theme.button.secondary.backgroundColor,
      color: theme.button.secondary.color,
      textTransform: theme.button.secondary.textTransform,
      border: theme.button.secondary.border,
      margin: "unset",
      "&:hover": {
        color: theme.button.secondary.hoverColor,
        backgroundColor: "transparent !important",
      },
      "&:disabled": {
        color: theme.button.secondary.disabledColor,
        backgroundColor: "transparent",
      },
    },
  },
}));

interface CustomGridProps extends GridProps {
  disabledMarginTop?: boolean;
}

const useGridStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    // backgroundColor: "#FFFFFF",
    marginTop: (props: CustomGridProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(2),
    marginBottom: "20px",
  },
}));

const Grid = (props: CustomGridProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useGridStyles(props);

  return <GridMui className={classes.root} {...muiProps} />;
};

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const useBoxStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props: CustomBoxProps) =>
      props.disabledMarginTop ? 0 : theme.spacing(4),
    maxHeight: "56px",
  },
}));

const Box = (props: CustomBoxProps): JSX.Element => {
  const { disabledMarginTop, ...muiProps } = props;
  const classes = useBoxStyles(props);

  return <BoxMui className={classes.root} {...muiProps} />;
};

const InputLabel = withStyles({
  root: {
    textAlign: "left",
    transform: "translate(12px, 10px) scale(0.75)",
  },
})(InputLabelMui);

const InputLabelCenter = withStyles({
  root: {
    textAlign: "center",
  },
})(InputLabelMui);

export default {
  Grid,
  InputLabel,
  InputLabelCenter,
  Box,
  OutlinedInput,
  Checkbox,
  formStyles,
};
