// Dependencies
import React from "react";
import { PaperProps } from "@material-ui/core/Paper";

// Assets
import SC from "./pane.styles";

export type PaneProps = PaperProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export const Pane: React.FC<PaneProps> = (props) => <SC.Pane {...props} />;

export default Pane;

Pane.defaultProps = {
  elevation: 0,
};
