/* eslint-disable prettier/prettier */
import React from "react";
import { useParams, Redirect, useLocation } from "react-router-dom";

import {
  Typography,
  Box,
  Snackbar,
  Grid,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Alert, AlertProps } from "components/alert/alert.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import {
  useAppErrorHandler,
  isServerError,
  UIError,
  UIErrorCodes,
} from "errors/app.errors";

import AppPermissionValidator, {
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

import {
  BillingAddress,
  useGetCurrentUserOrganisationQuery,
  useCreateBillingAddressMutation,
  BillingAddressFragmentFragmentDoc,
  useEditBillingAddressMutation,
  useDeleteBillingAddressMutation,
} from "graphql/types-and-hooks";
import { createEmptyBillingAddress } from "graphql/audionaut.utils";

import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import BillingAddressFormFields from "pages/payment-management/views/components/billing_address/billing_address_form";
import BillingAddressItem from "pages/payment-management/views/components/billing_address/billing_address_item";

import SC from "pages/payment-management/payment-management.styles";
import { isMobileResolution } from "commons/utils/device-info.util";
import CarouselGeneric from "components/carousel-generic/carousel-generic.component";

const BillingAddressScreen: React.FC = () => {
  const classes = SC.paymentMethodStyles();

  const { search } = useLocation();
  const currentParam = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [editingAddress, setEditingAddress] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [billingAddressToEdit, setBillingAddressToEdit] =
    React.useState<BillingAddress>(() => createEmptyBillingAddress());

  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const {
    data: currentUserOrganisation,
    loading: currentUserOrganisationLoading,
    error: currentUserOrganisationError,
    refetch: userOrganisationRefetch,
  } = useGetCurrentUserOrganisationQuery({
    fetchPolicy: "no-cache",
  });

  const billingAddresses = React.useMemo(
    () =>
      currentUserOrganisation?.getCurrentUserOrganisation.billingAddresses ??
      [],
    [currentUserOrganisation]
  );

  const [
    createBillingAddress,
    { loading: createBillingAddressLoading, error: createBillingAddressError },
  ] = useCreateBillingAddressMutation({
    update(cache, { data }) {
      const newBillingAddress = data?.createBillingAddress;
      if (newBillingAddress) {
        cache.modify({
          fields: {
            GetBillingAddress(existingBillingAddresses = []) {
              const newAddressRef = cache.writeFragment({
                id: cache.identify(newBillingAddress),
                data: newBillingAddress,
                fragment: BillingAddressFragmentFragmentDoc,
              });
              return [...existingBillingAddresses, newAddressRef];
            },
          },
        });
      }
    },
  });

  const [
    editBillingAddress,
    {
      loading: editingBillingAddressLoading,
      error: editingBillingAddressError,
    },
  ] = useEditBillingAddressMutation({
    update(cache, { data }) {
      const editedBillingAddress = data?.editBillingAddress;
      if (editedBillingAddress) {
        cache.modify({
          fields: {
            GetBillingAddress(existingBillingAddresses = []) {
              const newAddressRef = cache.writeFragment({
                id: cache.identify(editedBillingAddress),
                data: editedBillingAddress,
                fragment: BillingAddressFragmentFragmentDoc,
              });
              return [...existingBillingAddresses, newAddressRef];
            },
          },
        });
      }
    },
  });

  const [
    deleteBillingAddress,
    { loading: deleteBillingAddressLoading, error: deleteBillingAddressError },
  ] = useDeleteBillingAddressMutation();

  const errorHandler = useAppErrorHandler(
    currentUserOrganisationError ||
      createBillingAddressError ||
      editingBillingAddressError ||
      deleteBillingAddressError
  );

  const loading =
    editingBillingAddressLoading ||
    deleteBillingAddressLoading ||
    currentUserOrganisationLoading ||
    createBillingAddressLoading ||
    deleteBillingAddressLoading;

  const onNewAddressAdded = React.useCallback(
    async (billingAddressData: BillingAddress) => {
      try {
        const requestData: any = billingAddressData;
        delete requestData.id;

        await createBillingAddress({
          variables: requestData,
        });

        setSnackBarMessage({
          message: "Address has been created successfully",
          severity: "success",
        });

        userOrganisationRefetch();
        setShowEdit(false);
        const redirectTo = currentParam.get("redirectTo");
        if (redirectTo) {
          setTimeout(() => {
            // setSnackBarMessage(undefined);
            setSnackBarMessage({
              message: "Redirecting to plans page!",
              severity: "warning",
            });
          }, 1000);
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
          // setInitialEditable(true);
          // setInitialSaveActive(true);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the address"
            )
          );
        }
      }
    },
    [createBillingAddress, errorHandler, userOrganisationRefetch, currentParam]
  );

  const handleUpdateBillingAddress = React.useCallback(
    async (billingAddressData) => {
      try {
        await editBillingAddress({
          variables: billingAddressData,
        });

        setBillingAddressToEdit(createEmptyBillingAddress());
        setEditingAddress(false);
        userOrganisationRefetch();
        setShowEdit(false);

        setSnackBarMessage({
          message: "Address has been updated successfully",
          security: "success",
        });

        const redirectTo = currentParam.get("redirectTo");
        if (redirectTo) {
          setTimeout(() => {
            // setSnackBarMessage(undefined);
            setSnackBarMessage({
              message: "Redirecting to plans page!",
              severity: "warning",
            });
          }, 1000);
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updationg the address"
            )
          );
        }
      }
    },
    [editBillingAddress, errorHandler, userOrganisationRefetch, currentParam]
  );

  const handleDeleteBillingAddress = React.useCallback(
    async (itemId: string) => {
      try {
        await deleteBillingAddress({
          variables: {
            id: itemId,
          },
        });

        userOrganisationRefetch();

        setSnackBarMessage({
          message: "Billing address deleted successfully",
          severity: "warning",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the address item"
            )
          );
        }
      }
    },
    [deleteBillingAddress, errorHandler, userOrganisationRefetch]
  );

  const handleCancelEdit = React.useCallback(() => {
    if (editingAddress) {
      setBillingAddressToEdit(createEmptyBillingAddress());
      setEditingAddress(false);
    }
    setShowEdit(false);
  }, [editingAddress]);

  return (
    <SC.PMContainer>
      <ProgressIndicator open={loading} />
      <SC.PMContent>
        <Snackbar
          open={!!snackBarMessage}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackBarMessage?.severity}
            message={snackBarMessage?.message}
          />
        </Snackbar>
        <SC.PMHeader component="div">
          <Box component="div" display="flex" flex="1">
            <Typography
              hidden={showEdit && isMobileResolution()}
              variant="h1"
              color="primary"
            >
              Billing Address
            </Typography>
            <Typography
              hidden={!showEdit || !isMobileResolution()}
              variant="h1"
              color="primary"
            >
              <IconButton
                aria-label="back"
                size="small"
                onClick={handleCancelEdit}
              >
                <ArrowBackIosIcon color="primary" />
              </IconButton>
              {billingAddressToEdit.id ? "Edit " : "New "}
              Billing Address
            </Typography>
          </Box>
        </SC.PMHeader>
        <SC.SBBody
          className={clsx(classes.sectionContainer)}
          component="div"
          key="groups-table"
        >
          {!showEdit ? (
            <>
              <AppPermissionValidator
                appPermission={
                  APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_ADDEDIT_BILLING_ADDRESSES
                }
              >
                <Box hidden={isMobileResolution()}>
                  <Typography
                    className={classes.formTitle}
                    style={{ marginBottom: "30px" }}
                    color="primary"
                  >
                    Add Billing Address
                  </Typography>
                </Box>

                <Card
                  className={clsx(
                    classes.itemContainer,
                    classes.addPaymentContainer
                  )}
                >
                  <CardContent
                    className={classes.addPaymentMethodItem}
                    onClick={() => {
                      setShowEdit(!showEdit);
                    }}
                  >
                    <AddIcon color="primary" />
                    <Typography color="primary">Add Billing Address</Typography>
                  </CardContent>
                </Card>
              </AppPermissionValidator>
            </>
          ) : null}

          <Box hidden={showEdit && isMobileResolution()}>
            <Typography
              className={classes.formTitle}
              style={{ marginBottom: "20px" }}
              color="primary"
            >
              Billing Addresses
            </Typography>
          </Box>

          {!showEdit ? (
            <>
              <Box
                hidden={!isMobileResolution() || billingAddresses.length === 0}
              >
                <CarouselGeneric
                  type="plans"
                  items={
                    billingAddresses.map((billingAddress: any) => {
                      const billingAddressData: BillingAddress = billingAddress;

                      return (
                        <BillingAddressItem
                          billingAddress={billingAddressData}
                          editItemHandler={() => {
                            setEditingAddress(true);
                            setBillingAddressToEdit(billingAddressData);
                            setShowEdit(true);
                          }}
                          setAsDefault={handleUpdateBillingAddress}
                          deleteItem={handleDeleteBillingAddress}
                        />
                      );
                    }) as any[]
                  }
                  hideDots={billingAddresses.length === 1}
                  customPaddingLeft={billingAddresses.length > 1 ? 50 : 0}
                  customPaddingRight={billingAddresses.length > 1 ? 50 : 0}
                  startIndex={
                    billingAddresses.findIndex(
                      (address) => address?.isDefault
                    ) + 1
                  }
                />
              </Box>

              <Box
                hidden={isMobileResolution() || billingAddresses.length === 0}
              >
                <Grid container spacing={3} xs={12}>
                  {billingAddresses.map((billingAddress: any) => {
                    const billingAddressData: BillingAddress = billingAddress;

                    return (
                      <Grid
                        key={`${billingAddress.id}-item`}
                        item
                        lg={4}
                        md={6}
                        xs={12}
                        className={classes.gridItem}
                      >
                        <BillingAddressItem
                          billingAddress={billingAddressData}
                          editItemHandler={() => {
                            setEditingAddress(true);
                            setBillingAddressToEdit(billingAddressData);
                            setShowEdit(true);
                          }}
                          setAsDefault={handleUpdateBillingAddress}
                          deleteItem={handleDeleteBillingAddress}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>

              <Box hidden={billingAddresses.length > 0}>
                <SC.NoResults
                  show
                  title="No billing addresses"
                  description="We don't have any billing address registered."
                />
              </Box>
            </>
          ) : (
            <BillingAddressFormFields
              billingAddress={billingAddressToEdit!}
              onSubmit={
                editingAddress ? handleUpdateBillingAddress : onNewAddressAdded
              }
              onCancel={handleCancelEdit}
            />
          )}
        </SC.SBBody>
      </SC.PMContent>
      {redirect ? <Redirect to={currentParam.get("redirectTo")!} /> : null}
    </SC.PMContainer>
  );
};

export default BillingAddressScreen;
