/* eslint-disable import/prefer-default-export */
import { getYoutubeVideoId } from "../../utils/string";

export const generateYoutubeEmbedURL = (
  url: string,
  start?: number | null,
  end?: number | null
) => {
  return `https://www.youtube.com/embed/${getYoutubeVideoId(url)}${
    start && start > 0 && end && end > 0
      ? `?start=${Math.floor(start)}&end=${Math.ceil(end)}`
      : ""
  }`;
};
