import { withStyles } from "@material-ui/styles";
import { Box, Theme } from "@material-ui/core";
import ButtonPrimary from "components/button-primary/button-primary.component";
import GeneralSearch from "components/general-search/general-search.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

const TopicsListContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
    height: "100%",
    maxWidth: "371px",
  },
}))(Box);

const TopicsListContent = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    gap: "8px",
    flex: "1",
  },
}))(Box);

const NewChatButton = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
}))(ButtonPrimary);

const SearchInput = withStyles((theme: Theme) => ({
  root: {
    flex: "unset !important",
    width: "100%",
    maxHeight: "36px",
    padding: "unset",
    "& .MuiInputBase-root": {
      width: "100%",
      height: "100%",
      padding: "0px 6px",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "8px",
    },
  },
}))(GeneralSearch);

const NoResults = withStyles(() => ({
  root: {
    boxShadow: "unset",
  },
}))(GenericEmptyMessages);

export default {
  TopicsListContainer,
  TopicsListContent,
  NewChatButton,
  SearchInput,
  NoResults,
};
