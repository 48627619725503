/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import {
  Typography,
  useTheme,
  createStyles,
  makeStyles,
  Theme,
  Box,
  IconButton,
  TablePagination,
  Snackbar,
  Grid,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Alert, AlertProps } from "components/alert/alert.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { Redirect } from "react-router-dom";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PaymentHistory from "assets/icons/payment_history.png";
import MembershipCalendar from "assets/icons/membership_calendar.png";

import {
  useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
  useGetCurrentUserOrganisationQuery,
  useGetAllInvoicesQuery,
  useCancelCurrentSubscriptionMutation,
  OrganisationData,
  useGetAccountUsageReportQuery,
  InvoicesFragmentFragment,
  AudionautPlanFragmentFragment,
  AudionautSubscriptionFragmentFragment,
} from "graphql/types-and-hooks";

import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import MembershipCard from "assets/icons/membership_card.png";

import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import BilingModal from "pages/user-plans/components/billing-modal/billing-modal.component";
import MessagesNotifyComponent from "components/messages-notify/messages-notify.component";
import Paginator from "components/paginator/paginator.component";

import { getFormattedDate } from "commons/utils/date-format";
import { isMobileResolution } from "commons/utils/device-info.util";

import SC from "./subscription-billing.styles";

type Mutable = {
  -readonly [key in keyof AudionautSubscriptionFragmentFragment]: AudionautSubscriptionFragmentFragment[key];
};

const tableHeaders: any[] = [
  {
    text: "Recipient",
    key: "name",
    type: "text",
  },
  {
    text: "Date",
    key: "createdAt",
    type: "date",
    format: "MMMM DD, YYYY",
  },
  {
    text: "Payment Method",
    key: "cardData",
    type: "text",
  },
  {
    text: "Status",
    key: "status",
    type: "status",
  },
  // {
  //   text: "Status",
  //   key: "status",
  // },
];

const SubscriptionBillingPage: React.FC = () => {
  const classes = SC.useStyles();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [redirect, setRedirect] = React.useState(false);
  const [openBillingModal, setOpenBillingModal] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState<any>({
    id: "",
    stripePaymentMethodId: "",
    cardLast4: "",
    cardBrand: "",
    cardExpMonth: 1,
    cardExpYear: 2022,
    cardFingerprint: "",
    isDefault: false,
    createdAt: "",
    updatedAt: "",
    organisation: null,
  });

  const [startDate, setStartDate] = React.useState<Date>(() => {
    const pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - (pastDate.getDate() - 1));
    return pastDate;
  });
  const [endDate, setEndDate] = React.useState<Date>(new Date());

  const {
    data: currentSubscriptionData,
    loading: currentSubscriptionLoading,
    error: currentSubscriptionError,
  } = useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery({
    fetchPolicy: "no-cache",
  });

  const [
    cancelSubscription,
    { loading: cancelSubscriptionLoading, error: cancelSubscriptionError },
  ] = useCancelCurrentSubscriptionMutation();

  const {
    data: currentUserOrganisation,
    loading: getCurrentUserOrganisationLoading,
    error: getCurrentUserOrganisationError,
    refetch: currentUserOrganisationRefetch,
  } = useGetCurrentUserOrganisationQuery({
    fetchPolicy: "no-cache",
  });

  const {
    data: currentInvoicesData,
    loading: currentInvoicesLoading,
    error: currentInvociesError,
  } = useGetAllInvoicesQuery();

  const {
    data: subscriptionUsageDataOnce,
    loading: subscriptionUsageLoadingOnce,
    error: subscriptionUsageErrorOnce,
  } = useGetAccountUsageReportQuery({
    variables: {
      startsAt: `${getFormattedDate(
        startDate.toDateString(),
        "YYYY-MM-DD"
      )} 00:00:00`,
      endsAt: `${getFormattedDate(
        endDate.toDateString(),
        "YYYY-MM-DD"
      )} 23:59:00`,
    },
  });

  const totalMinsUsage = React.useMemo(() => {
    let totalAmount = 0;
    if (
      subscriptionUsageDataOnce &&
      subscriptionUsageDataOnce.getAccountUsageReport
    ) {
      totalAmount =
        subscriptionUsageDataOnce.getAccountUsageReport
          .totalDurationOfAudioIngestedInSec! / 60;
    }
    return totalAmount;
  }, [subscriptionUsageDataOnce]);

  const loading = currentSubscriptionLoading || cancelSubscriptionLoading;

  const errorHandler = useAppErrorHandler(
    currentSubscriptionError || cancelSubscriptionError
  );

  const currentSubscription = React.useMemo(
    () =>
      (currentSubscriptionData?.getCurrentUserOrganisationCurrentPeriodSubscription as Mutable) ??
      null,
    [currentSubscriptionData]
  );
  const userOrganization: OrganisationData | null = React.useMemo(
    () => currentUserOrganisation?.getCurrentUserOrganisation ?? null,
    [currentUserOrganisation]
  );

  const currentInvoices = React.useMemo(
    () =>
      currentInvoicesData
        ? currentInvoicesData!.getAccountInvoices
        : {
            amountOwed: 0,
            invoices: [],
          },
    [currentInvoicesData]
  );

  const paymentSoon = React.useMemo(() => {
    let showAdvise = false;
    if (currentSubscription && currentSubscription.endsAt) {
      const date1: any = new Date(endDate);
      const date2: any = new Date(currentSubscription.endsAt);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      showAdvise = diffDays < 5;
    }
    return showAdvise;
  }, [endDate, currentSubscription]);

  const [currentStatus, setCurrentStatus] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const handlerCancelSubscription = React.useCallback(async () => {
    try {
      const result = await cancelSubscription();

      setSnackBarMessage({
        message: "The current subscription has been canceled successfully",
        severity: "success",
      });

      setTimeout(() => {
        // setRedirect(true);
        window.location.href = "/";
      }, 3000);
    } catch (error: any) {
      if (isServerError(error)) {
        errorHandler(error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while canceling the current subscription"
          )
        );
      }
    }
  }, [cancelSubscription, errorHandler]);

  const createSubscription = React.useCallback(
    async (id?: string) => {
      try {
        // implement the payOutstandingInvoices mutation
        // const response = await createPlanSubscription({
        //   variables: {
        //     planId: id,
        //   },
        // });

        setSnackBarMessage({
          message:
            "The plan subscription has been updated successfully, Redirection to home...",
          severity: "success",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the plan subscription"
            )
          );
        }
      }
    },
    [errorHandler]
  );

  const handleBillingModal = () => {
    setOpenBillingModal(!openBillingModal);
  };

  React.useEffect(() => {
    if (userOrganization) {
      setCurrentStatus(userOrganization!.subscriptionAutopay!);
      if (
        userOrganization.paymentMethods &&
        userOrganization.paymentMethods.length > 0
      ) {
        const { paymentMethods } = userOrganization;
        const defaultPaymentMethod = paymentMethods.filter((method: any) => {
          return method?.isDefault;
        });

        if (defaultPaymentMethod.length > 0)
          setPaymentMethod(defaultPaymentMethod[0]);
      }
    }
  }, [userOrganization]);

  React.useEffect(() => {
    if (
      currentSubscription &&
      currentSubscription.audionautPlan &&
      currentInvoices &&
      currentInvoices.invoices &&
      currentInvoices.invoices.length > 0
    ) {
      const {
        audionautPlan: { priceInCent },
        stripeSubscriptionId: currentSSId,
      } = currentSubscription as {
        audionautPlan: AudionautPlanFragmentFragment;
        stripeSubscriptionId: string;
      };
      const { amountPaid, stripeSubscriptionId: invoiceSSId } = currentInvoices
        .invoices[0] as InvoicesFragmentFragment;

      if (currentSSId === invoiceSSId && priceInCent !== amountPaid) {
        currentSubscription.audionautPlan.priceInCent = amountPaid;
      }
    }
  }, [currentSubscription, currentInvoices]);

  return (
    <SC.SBContainer>
      <ProgressIndicator open={loading} />
      <SC.SBContent>
        <Snackbar
          open={!!snackBarMessage}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackBarMessage?.severity}
            message={snackBarMessage?.message}
          />
        </Snackbar>
        <SC.SBHeader component="div">
          <Box component="div" display="flex" flex="1">
            <Typography variant="h1" color="primary">
              Subscription and Billing Details
            </Typography>
          </Box>
          <MessagesNotifyComponent
            message="Your payment deadline is approaching, make your payment now to prevent most of your platform functionality from being disabled."
            type="warning"
            show={paymentSoon}
            actionMessage="Pay now"
            successHandler={handleBillingModal}
          />
        </SC.SBHeader>
        <Box className={classes.sectionContainer}>
          <Box hidden={!isMobileResolution()}>
            <SC.TabsContainer position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label="Subscription Plan"
                  icon={<img src={MembershipCard} alt="membership-icon" />}
                  fullWidth
                  color="primary"
                />
                {/* <Tab
                  label="This Month So Far"
                  icon={<img src={MembershipCalendar} alt="calendar-icon" />}
                  color="primary"
                /> */}
              </Tabs>
            </SC.TabsContainer>
            <Box hidden={value !== 0}>
              <SC.PlanItem
                planData={currentSubscription}
                cancelSubscriptionHandler={handlerCancelSubscription}
                totalMinutesUsage={totalMinsUsage}
              />
            </Box>
            <Box hidden={value !== 1}>
              <SC.MonthResume
                planData={currentSubscription}
                setCurrentStatus={setCurrentStatus}
                currentStatus={currentStatus}
                totalMinutesUsage={totalMinsUsage}
              />
            </Box>
          </Box>

          <Box hidden={isMobileResolution()}>
            <Grid
              container
              spacing={3}
              lg={12}
              md={12}
              xs={12}
              style={{ margin: "0px" }}
            >
              <Grid
                key="item1"
                item
                lg={12}
                md={12}
                xs={12}
                style={{ paddingLeft: "0px" }}
              >
                <SC.PlanItem
                  planData={currentSubscription}
                  cancelSubscriptionHandler={handlerCancelSubscription}
                  totalMinutesUsage={totalMinsUsage}
                />
              </Grid>
              {/* hidden because in corporatus in not necesasry */}
              {/* <Grid
                key="item2"
                item
                lg={6}
                md={12}
                xs={12}
                style={{ paddingRight: "0px" }}
              >
                <SC.MonthResume
                  planData={currentSubscription}
                  setCurrentStatus={setCurrentStatus}
                  currentStatus={currentStatus}
                  totalMinutesUsage={totalMinsUsage}
                />
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Paginator
          totalItems={
            currentInvoices && currentInvoices.invoices
              ? currentInvoices.invoices.length
              : 0
          }
          updateItems={setRowsPerPage}
          updatePage={setPage}
        />
        <SC.SBBody
          className={classes.sectionContainer}
          component="div"
          key="groups-table"
        >
          <Box>
            <Box className={classes.headerPlan}>
              <Box className={classes.headerImage}>
                <img src={PaymentHistory} alt="history-icon" />
              </Box>
              <Typography className={classes.sectionTitle}>
                Payment History
              </Typography>
            </Box>
            {currentInvoices!.invoices!.length > 0 ? (
              <SC.BRTable
                title=""
                headers={tableHeaders}
                items={currentInvoices!.invoices!.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )}
                ItemComponent={SC.SBRowItem}
                thClass={classes.tableHeader}
                tbClass={classes.tableBody}
                tableClass={classes.tableContainer}
                extraSpaces={0}
                extracell
                lastExtraSpaces
                sortEnable
              />
            ) : (
              <SC.NoResults
                show
                title="No invoices registered"
                description=""
              />
            )}
          </Box>
        </SC.SBBody>
      </SC.SBContent>
      <BilingModal
        openModal={openBillingModal}
        isWizard={false}
        planData={currentSubscription}
        createSubscription={createSubscription}
        handleCloseModal={handleBillingModal}
        paymentMethodData={paymentMethod}
        amountOwed={currentInvoices!.amountOwed!}
        // updateAddressData={updateAddressData}
        // updatePaymentMethodData={updatePaymentMethodData}
      />
      {redirect ? <Redirect to="/" /> : null}
    </SC.SBContainer>
  );
};

export default SubscriptionBillingPage;
