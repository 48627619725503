import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Link,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { Link as LinkNavigate } from "react-router-dom";

import NumberFormat from "react-number-format";

import ActionButton from "components/action-button/action-button.component";
import AudionautCharts from "components/charts/charts.component";

import ButtonSecondary from "components/button-secondary/button-secondary.component";
import ButtonPrimary from "components/button-primary/button-primary.component";
import AddTargetBtn from "components/target-button/targetButton.component";
import DeleteIconButton from "components/delete-icon-button/delete-icon-button.component";

import { getFormattedDate } from "commons/utils/date-format";

import SC from "./tableCellGenericItem.styles";

const secToTime: any = (duration: any) => {
  let seconds: any = Math.floor(duration % 60);
  let minutes: any = Math.floor((duration / 60) % 60);
  let hours: any = Math.floor((duration / (60 * 60)) % 24);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

interface TableCellGenericItemProps {
  value: any;
  valueKey: string;
  type?: string;
  format?: string;
  customClasses?: string;
  data?: any;
  modalEvent?: any;
  handleData?: any;
  targetData?: any;
  handlerDeleteItem?: any;
  deletedItem?: boolean;
}

const TableCellGenericItem: React.FC<TableCellGenericItemProps> = ({
  value,
  valueKey,
  type,
  format,
  customClasses,
  data,
  modalEvent,
  handleData,
  targetData,
  handlerDeleteItem,
  deletedItem = false,
}) => {
  const classes = SC.generalStyles();
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const [anchorTagsEl, setAnchorTagsEl] =
    React.useState<HTMLButtonElement | null>(null);

  const openTags = Boolean(anchorTagsEl);
  const popoverId = openTags ? `tags-popover` : undefined;

  let currentValue = React.useMemo(() => value, [value]);

  if (type === "date") {
    currentValue = getFormattedDate(value.replaceAll("\\", ""), format);
  } else if (type === "time") {
    currentValue = secToTime(value);
  }

  // if (valueKey === "mediaUrl") {
  //   customClasses = "mediaURLField";
  // }

  const handleClickToggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  const handleOpenTags = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorTagsEl(event.currentTarget);
  };

  const handleCloseTags = () => {
    setAnchorTagsEl(null);
  };

  let valueComponent = (
    <Typography className={customClasses}>{currentValue}</Typography>
  );

  if (type === "currency") {
    valueComponent = (
      <>
        <Typography>
          <NumberFormat
            value={currentValue / 100}
            displayType="text"
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
          />{" "}
          USD
        </Typography>
      </>
    );
  } else if (type === "status") {
    valueComponent = (
      <Button className={`${classes.status} ${value}`}>
        {value.charAt(0) + value.slice(1).toLowerCase()}
      </Button>
    );
  }

  if (valueKey === "mediaTitle") {
    valueComponent = (
      <Box component="div" className={classes.ellipsisText}>
        {value}
      </Box>
    );
  } else if (valueKey === "mediaUrl") {
    valueComponent = (
      <Link href={value} target="_blank" className={classes.linkInCell}>
        <Box component="div" className={classes.ellipsisText}>
          {value}
        </Box>
      </Link>
    );
  } else if (valueKey === "created" || valueKey === "creationDate") {
    const valuestr = getFormattedDate(value);
    valueComponent = (
      <Typography className={customClasses}>{valuestr}</Typography>
    );
  } else if (valueKey === "duration") {
    const valuestr = secToTime(value);
    valueComponent = (
      <Typography className={customClasses}>{valuestr}</Typography>
    );
  } else if (valueKey === "scoreSentiment") {
    let isPositive = true;
    if (data) {
      isPositive =
        data.positiveSentimentRate > data.negativeSentimentRate ||
        data.positiveSentimentRate === data.negativeSentimentRate;
    }

    valueComponent = (
      <Box
        className={`${classes.chartTinny} ${customClasses}`}
        style={{ background: isPositive ? "#5AE3C21A" : "#FE63771A" }}
      >
        <AudionautCharts
          type={3}
          customStyle={{ height: "14px", width: "130px" }}
          data={{ score: value, isPositive }}
        />
      </Box>
    );
  } else if (valueKey === "actions") {
    valueComponent = (
      <Box className={classes.actionsContainer}>
        <AddTargetBtn
          behaviorData={data}
          togleModal={modalEvent}
          setTargetData={handleData}
          targetData={targetData}
          customClasses={customClasses}
        />
        <LinkNavigate to={`/analyze/behaviors?id=${data.behaviorId}`}>
          <ActionButton
            key="Edit"
            title="Edit"
            icon={<EditIcon />}
            handler={() => {}}
          />
        </LinkNavigate>
        <DeleteIconButton
          itemId={data.id}
          itemName={data.behaviorName ?? ""}
          type=""
          deleteItem={handlerDeleteItem}
          data={data}
          itemDeleted={deletedItem}
        />
      </Box>
    );
  } else if (valueKey === "sentiment") {
    valueComponent = (
      <Box
        className={`bgd-${value}  ${classes.sentimentChip} ${customClasses}`}
      >
        <Typography>{value}</Typography>
      </Box>
    );
  } else if (valueKey === "tags") {
    const customClassesTemp = JSON.parse(customClasses!);
    valueComponent = (
      <>
        {data && data.length > 0 ? (
          <>
            <Button
              type="button"
              className={customClassesTemp.tagsBtn}
              onClick={handleOpenTags}
            >
              {data.length > 0
                ? `${data.join(", ").substring(0, 20)} ...`
                : "-"}
            </Button>
            <Popover
              id={popoverId}
              open={openTags}
              anchorEl={anchorTagsEl}
              onClose={handleCloseTags}
              className={customClassesTemp.tagsPopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography>{data.join(", ")}</Typography>
            </Popover>
          </>
        ) : (
          "-"
        )}
      </>
    );
  }

  return valueComponent;
};

export default TableCellGenericItem;
